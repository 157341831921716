<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
                <span *ngIf="activeCategory==1" class="color-grey">Summary For: </span>
                <span *ngIf="activeCategory==2" class="color-grey">Store For: </span>
                <span *ngIf="activeCategory==3" class="color-grey">Product Store Roles For: </span>
                <span *ngIf="activeCategory==4" class="color-grey">Promotions For: </span>
                <span *ngIf="activeCategory==5" class="color-grey">Catalogs For: </span>
                <span *ngIf="activeCategory==6" class="color-grey">Product Store Websites For: </span>
                <span *ngIf="activeCategory==7" class="color-grey">Product Store Shipment Settings For: </span>
                <span *ngIf="activeCategory==8" class="color-grey">Product Store Shipment Settings For: </span>
                <span *ngIf="activeCategory==9" class="color-grey">Product Store Payment Settings For: </span>
                <span *ngIf="activeCategory==10" class="color-grey">Product Store Financial Account Settings For:
                </span>
                <span *ngIf="activeCategory==11" class="color-grey">Facility For: </span>
                <span *ngIf="activeCategory==12" class="color-grey">Product Store Email Settings For: </span>
                <span *ngIf="activeCategory==13" class="color-grey">Product Store Survey Settings For: </span>
                <span *ngIf="activeCategory==14" class="color-grey">Product Store Keyword Overrides For: </span>
                <span *ngIf="activeCategory==15" class="color-grey">Product Store Segments For: </span>
                <span *ngIf="activeCategory==16" class="color-grey">Product Store Vendor Payments For: </span>
                <span *ngIf="activeCategory==17" class="color-grey">Product Store Vendor Shipments For: </span>
           
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Summary</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Store</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Role(s)</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                <a>Web Sites</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)">
                                <a>Shipping Estimates</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)">
                                <a>Payments</a>
                            </li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategory(10)">
                                <a>Fin Accts</a>
                            </li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="changeactiveCategory(11)">
                                <a>Facility</a>
                            </li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="changeactiveCategory(12)">
                                <a>Emails</a>
                            </li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="changeactiveCategory(13)">
                                <a>Surveys</a>
                            </li>
                            <li [ngClass]="activeCategory==14?'active':''" (click)="changeactiveCategory(14)">
                                <a>Override</a>
                            </li>
                            <li [ngClass]="activeCategory==15?'active':''" (click)="changeactiveCategory(15)">
                                <a>Segments</a>
                            </li>
                            <li [ngClass]="activeCategory==16?'active':''" (click)="changeactiveCategory(16)">
                                <a>Vendor Payments</a>
                            </li>
                            <li [ngClass]="activeCategory==17?'active':''" (click)="changeactiveCategory(17)">
                                <a>Vendor Shipments</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;"
                                                            (click)="changeactiveCategory(2)">Update</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                    </span>
                                                </div>
                                                <form>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>View Cart On Add</p>
                                                                </div>
                                                                <div class="col-lg-3" *ngIf="this.ProductStoreById">
                                                                    <span>{{this.ProductStoreById.viewCartOnAdd}}</span>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p>CustomerRole</p>
                                                                </div>
                                                                <div class="col-lg-3" *ngIf="this.ProductStoreById">
                                                                    <span>{{this.ProductStoreById.requireCustomerRole}}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Company Name</p>
                                                                </div>
                                                                <div class="col-lg-3" *ngIf="this.ProductStoreById">
                                                                    <span>{{this.ProductStoreById.companyName}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Header Declined Status</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.headerDeclinedStatus}}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Require Inventory</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.requireInventory}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Check Inventory</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.checkInventory}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
             
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Auto Approve Invoice</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.autoApproveInvoice}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>AddToCartRemoveIncompat</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.addToCartRemoveIncompat}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>EnableAutoSuggestionList</p>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <span>{{this.ProductStoreById.enableAutoSuggestionList}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>DefaultSalesChannelEnumId</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.defaultSalesChannelEnumId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Auto Approve Order</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.autoApproveOrder}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Allow Password</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.allowPassword}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Show Tax Is Exempt</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.showTaxIsExempt}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Auto Save Cart</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.autoSaveCart}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Auto Save Cart</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.autoSaveCart}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Inventory Facility Id</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.inventoryFacilityId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Auto Save Cart</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.autoSaveCart}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Header Approved Status</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.headerApprovedStatus}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Default Time Zone String</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.defaultTimeZoneString}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Prorate Taxes</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.prorateTaxes}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>ShowCheckoutGiftOptions</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.showCheckoutGiftOptions}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Manual Auth Is Capture</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.manualAuthIsCapture}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Item Declined Status</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.itemDeclinedStatus}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Visual Theme Id</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.visualThemeId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>StoreCreditAccountEnumId</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.storeCreditAccountEnumId}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>ShowOutOfStockProducts</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.showOutOfStockProducts}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Allocate Inventory</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.allocateInventory}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>ItemApprovedStatus</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.itemApprovedStatus}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Allow Comment</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.allowComment}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Store Credit Valid Days</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.storeCreditValidDays}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                              
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>SplitPayPrefPerShpGrp</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.splitPayPrefPerShpGrp}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Auth Fraud Message</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.authFraudMessage}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Managed By Lot</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.managedByLot}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>ReqShipAddrForDigItems</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.reqShipAddrForDigItems}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>One Inventory Facility</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.oneInventoryFacility}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Order Number Prefix</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.orderNumberPrefix}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Auto Invoice Digital Items</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.autoInvoiceDigitalItems}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Subtitle</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.subtitle}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Reserve Order Enum Id</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.reserveOrderEnumId}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Is Demo Store</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.isDemoStore}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>Retry Failed Auths</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.retryFailedAuths}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>SelectPaymentTypePerItem</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.selectPaymentTypePerItem}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>BalanceResOnOrderCreation</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.balanceResOnOrderCreation}}</span>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.ProductStoreById">
                                                                    <p>BalanceResOnOrderCreation</p>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <span>{{this.ProductStoreById.balanceResOnOrderCreation}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                      

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100">
                                            <div class="w3-card-4 classCard">
                                                <div class="container-fluid">

                                                    <ul class="tabbing-section tabbing-accordians tabClass"
                                                        style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                                        <li [ngClass]="customerCategoryPopup==1?'active':''"><a>
                                                                Product Store</a></li>
                                                        <li [ngClass]="customerCategoryPopup==2?'active':''"><a>
                                                                Inventory</a></li>
                                                        <li [ngClass]="customerCategoryPopup==3?'active':''"><a>
                                                                Shopping Cart</a></li>
                                                        <li [ngClass]="customerCategoryPopup==4?'active':''"><a>
                                                                Shipping</a></li>

                                                        <li [ngClass]="customerCategoryPopup==5?'active':''"><a>
                                                                Payments</a></li>

                                                        <li [ngClass]="customerCategoryPopup==6?'active':''"><a>
                                                                Orders</a></li>

                                                        <li [ngClass]="customerCategoryPopup==7?'active':''"><a>
                                                                Localization</a></li>

                                                        <li [ngClass]="customerCategoryPopup==8?'active':''"><a>
                                                                Orders Status
                                                            </a></li>

                                                        <li [ngClass]="customerCategoryPopup==9?'active':''"><a>
                                                                Messages</a></li>

                                                        <li [ngClass]="customerCategoryPopup==10?'active':''"><a>
                                                                Tax</a></li>

                                                        <li [ngClass]="customerCategoryPopup==11?'active':''"><a>
                                                                Visitors</a></li>

                                                        <li [ngClass]="customerCategoryPopup==12?'active':''"><a>
                                                                Upload</a></li>

                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0; margin-top: 5px !important;"
                                                            (click)="newProduct()">New Product Store</button>

                                                    </ul>
                                                </div>
                                                <div class="create-new-leade mt-2">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form [formGroup]="editProductStore" class="w-100">
                                                                <div
                                                                    class="port-header-contact create-lead font-medium mb-0 text-white">
                                                                    <div class="w-100">
                                                                        <div class="panel-group" id="accordion"
                                                                            role="tablist" aria-multiselectable="true">

                                                                            <div *ngIf="customerCategoryPopup==1">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Product Store
                                                                                    </h4>


                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Product
                                                                                                        Store ID<span
                                                                                                            style="color: red;">*</span></label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <input type="text"
                                                                                                        class="form-control"
                                                                                                        formControlName="productStoreID"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Product Store ID">
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Primary
                                                                                                        Store Group ID
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <p-dropdown
                                                                                                        filter="true"
                                                                                                        [options]="ProductStoreGroupListArray"
                                                                                                        formControlName="primaryStoreGroupID"
                                                                                                        optionlabel="label"
                                                                                                        placeholder="Enter Primary Store Group ID">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Store
                                                                                                        Name<span
                                                                                                            style="color: red;">*</span></label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <input type="text"
                                                                                                        class="form-control"
                                                                                                        formControlName="storeName"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Store Name">
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Title</label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <input type="text"
                                                                                                        class="form-control"
                                                                                                        formControlName="title"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Title">
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Sub-Title</label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <input type="text"
                                                                                                        class="form-control"
                                                                                                        formControlName="subTitle"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Sub-Title">
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Company
                                                                                                        Name</label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <input type="text"
                                                                                                        class="form-control"
                                                                                                        formControlName="companyName"
                                                                                                        id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Enter Company Name">
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">Is
                                                                                                        Demo Store
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <p-dropdown
                                                                                                        filter="true"
                                                                                                        [options]="yesNoArray"
                                                                                                        formControlName="isDemoStore"
                                                                                                        optionlabel="label"
                                                                                                        placeholder="Enter Is Demo Store">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-3 form-group classInput">
                                                                                                    <label
                                                                                                        for="exampleInputEmail1">For
                                                                                                        controlling the
                                                                                                        look and feel of
                                                                                                        the web-based
                                                                                                        store
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="col-lg-2 form-group">
                                                                                                    <p-dropdown
                                                                                                        filter="true"
                                                                                                        [options]="LookAndFeelWebBasedStoreArray"
                                                                                                        formControlName="controlling"
                                                                                                        optionlabel="label"
                                                                                                        placeholder="Enter For controlling the look and feel of the web-based store">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                        <div class="main-submit-button"
                                                                                            style="margin-left: 72% !important;">
                                                                                            <button type="submit"
                                                                                                class="btn btn-secondary submit-btn"
                                                                                                (click)="customerActiveCategoryFunction(2)">Continue</button>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div *ngIf="customerCategoryPopup==2">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Inventory
                                                                                    </h4>
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Inventory
                                                                                                    Facility ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="InventoryFacilityIdArray"
                                                                                                    formControlName="inventoryFacilityID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Inventory Facility ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">One
                                                                                                    Inventory Facility
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="oneInventoryFacility"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter One Inventory Facility">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Is
                                                                                                    Immediately
                                                                                                    Fulfilled
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="isFulfil"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Is Immediately Fulfilled">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Check
                                                                                                    Inventory
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="checkInventory"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Check Inventory">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Require
                                                                                                    Inventory
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="reqInventory"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Require Inventory">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Requirement
                                                                                                    Method Enum ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="RequirementMethodEnumIdArray"
                                                                                                    formControlName="reqEnumID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Requirement Method Enum ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Reserve
                                                                                                    Inventory
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="reserveInventory"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Reserve Inventory">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Reserve
                                                                                                    Order Enum ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="ReserveOrderEnumIdArray"
                                                                                                    formControlName="reserveEnumID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Reserve Order Enum ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Balance
                                                                                                    Res On Order
                                                                                                    Creation
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="resOrderCreation"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Balance Res On Order Creation">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Show
                                                                                                    Out of Stock
                                                                                                    Products
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="showStockProducts"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Show Out of Stock Products">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Lots
                                                                                                    Managing
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="lotsManaging"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Lots Managing">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right:9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(1)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(3)">Continue</button>

                                                                                    </div>
                                                                                </div>

                                                                            </div>


                                                                            <div *ngIf="customerCategoryPopup==3">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Shopping Cart
                                                                                    </h4>


                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">View
                                                                                                    Cart on Add
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="cartOnAdd"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter View Cart on Add">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Save Cart
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="autoSaveCart"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auto Save Cart">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Add
                                                                                                    to Cart Replace
                                                                                                    Upsell
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="replaceUpsell"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Add to Cart Replace Upsell">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Add
                                                                                                    to Cart Remove
                                                                                                    Incompat
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="removeIncompat"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Add to Cart Remove Incompat">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Show
                                                                                                    Checkout Gift
                                                                                                    Options
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="giftOptions"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Show Checkout Gift Options">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Prod
                                                                                                    Search Exclude
                                                                                                    Variants
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="excludeVariants"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Prod Search Exclude Variants">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Allow
                                                                                                    Order Decimal
                                                                                                    Quantity
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="decimalQuantity"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Allow Order Decimal Quantity">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(2)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(4)">Continue</button>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div *ngIf="customerCategoryPopup==4">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Shipping
                                                                                    </h4>


                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Prorate
                                                                                                    Shipping
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="prorateShipping"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Prorate Shipping">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Req.
                                                                                                    Ship Addr. for Dig
                                                                                                    Items
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="addrDigItems"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Req. Ship Addr. for Dig Items">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Select
                                                                                                    Payment Type Per
                                                                                                    Item
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="paymentType"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Select Payment Type Per Item">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Ship
                                                                                                    if Capture Fails
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="captureFails"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Ship if Capture Fails">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Split
                                                                                                    Pay Pref Per Ship
                                                                                                    Grp
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="splitPay"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Split Pay Pref Per Ship Grp">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(3)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(5)">Continue</button>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div *ngIf="customerCategoryPopup==5">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Payments
                                                                                    </h4>
                                                                                </div>
                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Pay
                                                                                                    to Party ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="partyIdArray"
                                                                                                    formControlName="payPartyID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Pay to Party ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Store
                                                                                                    Credit Account Enum
                                                                                                    ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="ScoreCreditAccountListArray"
                                                                                                    formControlName="storeEnumID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Store Credit Account Enum ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Manual
                                                                                                    Audio Is Capture
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="manualAudioCapture"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Manual Audio Is Capture">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Retry
                                                                                                    Failed Auths
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="retryAuths"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Retry Failed Auths">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Days
                                                                                                    To Cancel Non
                                                                                                    Pay</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="daystoCancelnonPay"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Days To Cancel Non Pay">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Order CC Try Exp
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="ccTryExp"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auto Order CC Try Exp">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Order CC Try Other
                                                                                                    Cards
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="ccOtherCards"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auto Order CC Try Other Cards">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Order CC Try Later
                                                                                                    NSF
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="ccLaterNSF"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auto Order CC Try Later NSF">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Order CC Try Later
                                                                                                    Max</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="ccLaterMax"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Auto Order CC Try Later Max">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Show
                                                                                                    Credit Valid
                                                                                                    Days</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="creditValidDays"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Show Credit Valid Days">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Set
                                                                                                    Owner upon Issuance
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="setIssuance"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Set Owner upon Issuance">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>



                                                                                    </div>
                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(4)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(6)">Continue</button>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div *ngIf="customerCategoryPopup==6">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Orders
                                                                                    </h4>
                                                                                </div>

                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Order
                                                                                                    Number
                                                                                                    Prefix</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="orderNumberPrefix"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Order Number Prefix">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Default
                                                                                                    Sales Channel Enum
                                                                                                    ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="DefaultSalesChannelEnumListArray"
                                                                                                    formControlName="channelEnumID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Default Sales Channel Enum ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Explore
                                                                                                    Order Items
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="exploreItems"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Explore Order Items">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Check
                                                                                                    GC Balance
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="gcBalance"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Check GC Balance">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Invoice Digital
                                                                                                    Items
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="invoiceDigitalItems"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auto Invoice Digital Items">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Approve Invoice
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="approveInvoice"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auto Approve Invoice">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auto
                                                                                                    Approve Order
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="approveOrder"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auto Approve Order">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Req.
                                                                                                    Return Inventory
                                                                                                    Recieve
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="returnInventoryRecieve"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Req. Return Inventory Recieve">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(5)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(7)">Continue</button>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div *ngIf="customerCategoryPopup==7">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Localization
                                                                                    </h4>
                                                                                </div>

                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Default
                                                                                                    Locale
                                                                                                    Storage</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="localeStorage"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Default Locale Storage">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Default
                                                                                                    Currency UOM ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="currencyArray"
                                                                                                    formControlName="currencyUomID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Default Currency UOM ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Default
                                                                                                    Time Zone</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="timeZone"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Default Time Zone">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(6)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(8)">Continue</button>

                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                            <div *ngIf="customerCategoryPopup==8">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Orders Status

                                                                                    </h4>
                                                                                </div>

                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Header
                                                                                                    Approved Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="HeaderApprovedStatusArray"
                                                                                                    formControlName="headerApprovedStatus"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Header Approved Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Item
                                                                                                    Approved Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="ItemApprovedStatusArray"
                                                                                                    formControlName="itemStatus"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Item Approved Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Digital
                                                                                                    Item Approved Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="ItemApprovedStatusArray"
                                                                                                    formControlName="digitalItemStatus"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Digital Item Approved Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Header
                                                                                                    Declined Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="HeaderApprovedStatusArray"
                                                                                                    formControlName="headerDeclinedStatus"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Header Declined Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Item
                                                                                                    Declined Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="ItemApprovedStatusArray"
                                                                                                    formControlName="itemDeclinedStatus"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Item Declined Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Header
                                                                                                    Cancel Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="HeaderApprovedStatusArray"
                                                                                                    formControlName="headerCancelStatus"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Header Cancel Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Item
                                                                                                    Cancel Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="ItemApprovedStatusArray"
                                                                                                    formControlName="itemCancelStatus"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Item Cancel Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(7)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(9)">Continue</button>

                                                                                    </div>


                                                                                </div>

                                                                            </div>


                                                                            <div *ngIf="customerCategoryPopup==9">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Messages
                                                                                    </h4>
                                                                                </div>

                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auth
                                                                                                    Declined
                                                                                                    Message</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="authDeclinedMessage"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Auth Declined Message">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auth
                                                                                                    Fraud
                                                                                                    Message</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="authFraudMessage"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Auth Fraud Message">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auth
                                                                                                    Error
                                                                                                    Message</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="authErrorMessage"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Auth Error Message">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(8)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(10)">Continue</button>

                                                                                    </div>

                                                                                </div>

                                                                            </div>


                                                                            <div *ngIf="customerCategoryPopup==10">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Tax
                                                                                    </h4>
                                                                                </div>

                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Prorate
                                                                                                    Taxes
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="prorateTax"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Prorate Taxes">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Show
                                                                                                    Prices with VAT Tax
                                                                                                    Included
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="pricesVATIncluded"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Show Prices with VAT Tax Included">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Show
                                                                                                    Tax is Exempt
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="taxExempt"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Show Tax is Exempt">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">VAT
                                                                                                    Tax Auth Geo ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="VatTaxAuthGeoIdArray"
                                                                                                    formControlName="vatGeoID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter VAT Tax Auth Geo ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">VAT
                                                                                                    Tax Auth Party ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="partyIdArray"
                                                                                                    formControlName="vatPartyID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter VAT Tax Auth Party ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(9)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(11)">Continue</button>

                                                                                    </div>

                                                                                </div>

                                                                            </div>


                                                                            <div *ngIf="customerCategoryPopup==11">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Visitors
                                                                                    </h4>
                                                                                </div>

                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Auth
                                                                                                    Approve Reviews
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="approveReviews"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Auth Approve Reviews">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Allow
                                                                                                    Password
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="allowPassword"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Allow Password">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Default
                                                                                                    Password</label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="defaultPassword"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Default Password">
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Use
                                                                                                    Primary Email
                                                                                                    Username
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="usePrimaryUsername"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Use Primary Email Username">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Require
                                                                                                    Customer Role
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="reqCustomerRole"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Require Customer Role">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Enable
                                                                                                    Auto Suggestion List
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="enableSuggestion"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Enable Auto Suggestion List">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(10)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn"
                                                                                            (click)="customerActiveCategoryFunction(12)">Continue</button>

                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                            <div *ngIf="customerCategoryPopup==12">
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Upload
                                                                                    </h4>
                                                                                </div>

                                                                                <div class="panel-body">
                                                                                    <div
                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Enable
                                                                                                    Digital Product
                                                                                                    Upload
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="enableProductUpload"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Enable Digital Product Upload">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Digital
                                                                                                    Product Upload
                                                                                                    Category ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="DigProdUploadCategoryListArray"
                                                                                                    formControlName="productUploadCategoryID"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Digital Product Upload Category ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Allow
                                                                                                    Comment
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="allowComment"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Allow Comment">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-3 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Allocate
                                                                                                    Inventory
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-2 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNoArray"
                                                                                                    formControlName="allocateInventory"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter Allocate Inventory">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="main-submit-button"
                                                                                        style="margin-right: 9%;">
                                                                                        <button type="submit"
                                                                                            (click)="customerActiveCategoryFunction(11)"
                                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                                        <button type="submit"
                                                                                            (click)="updateProductStore()"
                                                                                            class="btn btn-secondary submit-btn">Update</button>


                                                                                    </div>

                                                                                </div>

                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Product Store </p>
                                                    <span>
                                                        
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" 
                                                        data-toggle="modal" data-target="#editProductStorePopup">New Product Store</button>

                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" (click)="newProduct()">New Product Store</button>
                                                </span>
                                                </div>
                                                <form class="w-100" [formGroup]="editProductStore">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product Store ID<span style="color: red;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="productStoreID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Store ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Store Group ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ProductStoreGroupListArray" formControlName="primaryStoreGroupID" optionlabel="label" placeholder="Enter Primary Store Group ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Store Name<span style="color: red;">*</span></label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="storeName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Store Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Title</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="title" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Sub-Title</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="subTitle" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub-Title">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Company Name</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="companyName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Company Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Is Demo Store
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="isDemoStore" optionlabel="label" placeholder="Enter Is Demo Store">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">For controlling the look and feel of the web-based store
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="LookAndFeelWebBasedStoreArray" formControlName="controlling" optionlabel="label" placeholder="Enter For controlling the look and feel of the web-based store">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Inventory
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Inventory Facility ID<span style="color: red;">*</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="InventoryFacilityIdArray" formControlName="inventoryFacilityID" optionlabel="label" placeholder="Enter Inventory Facility ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">One Inventory Facility
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="oneInventoryFacility" optionlabel="label" placeholder="Enter One Inventory Facility">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Is Immediately Fulfilled
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="isFulfil" optionlabel="label" placeholder="Enter Is Immediately Fulfilled">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Check Inventory
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="checkInventory" optionlabel="label" placeholder="Enter Check Inventory">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Require Inventory
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reqInventory" optionlabel="label" placeholder="Enter Require Inventory">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Requirement Method Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="RequirementMethodEnumIdArray" formControlName="reqEnumID" optionlabel="label" placeholder="Enter Requirement Method Enum ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Reserve Inventory
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reserveInventory" optionlabel="label" placeholder="Enter Reserve Inventory">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Reserve Order Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ReserveOrderEnumIdArray" formControlName="reserveEnumID" optionlabel="label" placeholder="Enter Reserve Order Enum ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Balance Res On Order Creation
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="resOrderCreation" optionlabel="label" placeholder="Enter Balance Res On Order Creation">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Out of Stock Products
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="showStockProducts" optionlabel="label" placeholder="Enter Show Out of Stock Products">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Lots Managing
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="lotsManaging" optionlabel="label" placeholder="Enter Lots Managing">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Shopping Cart
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">View Cart on Add
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="cartOnAdd" optionlabel="label" placeholder="Enter View Cart on Add">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Save Cart
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="autoSaveCart" optionlabel="label" placeholder="Enter Auto Save Cart">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Add to Cart Replace Upsell
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="replaceUpsell" optionlabel="label" placeholder="Enter Add to Cart Replace Upsell">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Add to Cart Remove Incompat
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="removeIncompat" optionlabel="label" placeholder="Enter Add to Cart Remove Incompat">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Checkout Gift Options
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="giftOptions" optionlabel="label" placeholder="Enter Show Checkout Gift Options">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Prod Search Exclude Variants
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="excludeVariants" optionlabel="label" placeholder="Enter Prod Search Exclude Variants">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Allow Order Decimal Quantity
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="decimalQuantity" optionlabel="label" placeholder="Enter Allow Order Decimal Quantity">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Shipping
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Prorate Shipping
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="prorateShipping" optionlabel="label" placeholder="Enter Prorate Shipping">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Req. Ship Addr. for Dig Items
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="addrDigItems" optionlabel="label" placeholder="Enter Req. Ship Addr. for Dig Items">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Select Payment Type Per Item
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="paymentType" optionlabel="label" placeholder="Enter Select Payment Type Per Item">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Ship if Capture Fails
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="captureFails" optionlabel="label" placeholder="Enter Ship if Capture Fails">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Split Pay Pref Per Ship Grp
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="splitPay" optionlabel="label" placeholder="Enter Split Pay Pref Per Ship Grp">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Payments
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Pay to Party ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="partyIdArray" formControlName="payPartyID" optionlabel="label" placeholder="Enter Pay to Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Store Credit Account Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ScoreCreditAccountListArray" formControlName="storeEnumID" optionlabel="label" placeholder="Enter Store Credit Account Enum ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Manual Audio Is Capture
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="manualAudioCapture" optionlabel="label" placeholder="Enter Manual Audio Is Capture">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Retry Failed Auths
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="retryAuths" optionlabel="label" placeholder="Enter Retry Failed Auths">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Days To Cancel Non Pay</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="daystoCancelnonPay" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Days To Cancel Non Pay">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Exp
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccTryExp" optionlabel="label" placeholder="Enter Auto Order CC Try Exp">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Other Cards
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccOtherCards" optionlabel="label" placeholder="Enter Auto Order CC Try Other Cards">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Later NSF
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccLaterNSF" optionlabel="label" placeholder="Enter Auto Order CC Try Later NSF">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Later Max</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="ccLaterMax" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auto Order CC Try Later Max">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Credit Valid Days</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="creditValidDays" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Show Credit Valid Days">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Set Owner upon Issuance
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray"  formControlName="setIssuance" optionlabel="label" placeholder="Enter Set Owner upon Issuance">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Orders
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Order Number Prefix</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="orderNumberPrefix" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Number Prefix">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Default Sales Channel Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="DefaultSalesChannelEnumListArray" formControlName="channelEnumID" optionlabel="label" placeholder="Enter Default Sales Channel Enum ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Explore Order Items
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="exploreItems" optionlabel="label" placeholder="Enter Explore Order Items">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Check GC Balance
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="gcBalance" optionlabel="label" placeholder="Enter Check GC Balance">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Invoice Digital Items
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="invoiceDigitalItems" optionlabel="label" placeholder="Enter Auto Invoice Digital Items">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Approve Invoice
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveInvoice" optionlabel="label" placeholder="Enter Auto Approve Invoice">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Approve Order
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveOrder" optionlabel="label" placeholder="Enter Auto Approve Order">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Req. Return Inventory Recieve
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="returnInventoryRecieve" optionlabel="label" placeholder="Enter Req. Return Inventory Recieve">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Localization
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Default Locale Storage</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="localeStorage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Locale Storage">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Default Currency UOM ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="currencyArray" formControlName="currencyUomID" optionlabel="label" placeholder="Enter Default Currency UOM ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Default Time Zone</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="timeZone" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Time Zone">
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Orders Status
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Header Approved Status
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerApprovedStatus" optionlabel="label" placeholder="Enter Header Approved Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Item Approved Status
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemStatus" optionlabel="label" placeholder="Enter Item Approved Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Digital Item Approved Status
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="digitalItemStatus" optionlabel="label" placeholder="Enter Digital Item Approved Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Header Declined Status
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerDeclinedStatus" optionlabel="label" placeholder="Enter Header Declined Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Item Declined Status
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemDeclinedStatus" optionlabel="label" placeholder="Enter Item Declined Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Header Cancel Status
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerCancelStatus" optionlabel="label" placeholder="Enter Header Cancel Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Item Cancel Status
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemCancelStatus" optionlabel="label" placeholder="Enter Item Cancel Status">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Messages
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auth Declined Message</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="authDeclinedMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Declined Message">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auth Fraud Message</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="authFraudMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Fraud Message">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auth Error Message</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="authErrorMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Error Message">
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Tax
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Prorate Taxes
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="prorateTax" optionlabel="label" placeholder="Enter Prorate Taxes">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Prices with VAT Tax Included
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="pricesVATIncluded" optionlabel="label" placeholder="Enter Show Prices with VAT Tax Included">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Tax is Exempt
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="taxExempt" optionlabel="label" placeholder="Enter Show Tax is Exempt">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">VAT Tax Auth Geo ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="VatTaxAuthGeoIdArray" formControlName="vatGeoID" optionlabel="label" placeholder="Enter VAT Tax Auth Geo ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">VAT Tax Auth Party ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="partyIdArray" formControlName="vatPartyID" optionlabel="label" placeholder="Enter VAT Tax Auth Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Visitors
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auth Approve Reviews
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveReviews" optionlabel="label" placeholder="Enter Auth Approve Reviews">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Allow Password
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="allowPassword" optionlabel="label" placeholder="Enter Allow Password">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Default Password</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="defaultPassword" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Password">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Use Primary Email Username
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="usePrimaryUsername" optionlabel="label" placeholder="Enter Use Primary Email Username">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Require Customer Role
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reqCustomerRole" optionlabel="label" placeholder="Enter Require Customer Role">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Enable Auto Suggestion List
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="enableSuggestion" optionlabel="label" placeholder="Enter Enable Auto Suggestion List">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-12 form-group classInput">
                                                                    <label for="exampleInputEmail1">Upload
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Enable Digital Product Upload
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="enableProductUpload" optionlabel="label" placeholder="Enter Enable Digital Product Upload">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Digital Product Upload Category ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="DigProdUploadCategoryListArray" formControlName="productUploadCategoryID" optionlabel="label" placeholder="Enter Digital Product Upload Category ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Allow Comment
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="allowComment" optionlabel="label" placeholder="Enter Allow Comment">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Allocate Inventory
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="allocateInventory" optionlabel="label" placeholder="Enter Allocate Inventory">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-10 col-10 main-submit-button">
                                                        <button type="submit" (click)="updateProductStore()"  class="btn btn-secondary submit-btn">Update</button>
                                                    </div>
                                                </form>
                                            </div> -->
                                        </div>
                                        <div *ngIf="activeCategory==3" class="w-100">

                                            <div class="w3-card-4  w-100">
                                                <div class="accordion classCard" id="myAccordion">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                <span class="headingAccords"> Find Product Store Roles</span>
                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                    style="margin: 0;" (click)="newProduct()">New Product
                                                                    Store</button>
                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                    style="margin: 0;" (click)="addRoleData()"
                                                                    data-toggle="modal" data-target="#addRolepopup">Add
                                                                    Roles</button>
                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                                    padding-bottom: 11px !important;">
                                                                </button>									
                    
                                                            </div>
                        
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                            <div class="card-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Party ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2 form-group">
                                                                                <p-dropdown [options]="partyIdArray"
                                                                                    [(ngModel)]="advanceSearch.partyId"
                                                                                    [ngModelOptions]="{standlone:true}" name="partyId"
                                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                    placeholder="Enter Party Id" filter="true"
                                                                                    optionlabel="label">
                
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Role Type ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2 form-group">
                                                                                <p-dropdown [options]="allroleArray"
                                                                                    [(ngModel)]="advanceSearch.roleTypeId"
                                                                                    [ngModelOptions]="{standlone:true}" name="partyId"
                                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                    placeholder="Enter Party Id" filter="true"
                                                                                    optionlabel="label">
                
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">From Date</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" name="fromDate" class="form-control"
                                                                                    [(ngModel)]="advanceSearch.fromDate"
                                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                    placeholder="Enter From Date">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Through Date</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" name="throughDate"
                                                                                    class="form-control" id="exampleInputEmail1"
                                                                                    [(ngModel)]="advanceSearch.ThroughDate"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Through Date">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Sequence Num
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2 form-group">
                                                                                <input type="text" class="form-control"
                                                                                    [(ngModel)]="advanceSearch.sequenceNum"
                                                                                    [ngModelOptions]="{standalone:true}" name="seqNum"
                                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                    placeholder="Enter Sequence Num">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                <div class="col-lg-10 col-10 main-submit-button">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)="advanceSearchContacts(false)">Find</button>
                                                                    <button type="submit" (click)="reset();"
                                                                        class="btn btn-danger ml-2">Reset</button>
                                                                </div>
        
        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <!-- <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Find Product Store Roles</p>
                                                    <span>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="addRoleData()"
                                                            data-toggle="modal" data-target="#addRolepopup">Add
                                                            Roles</button>
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Party ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2 form-group">
                                                                <p-dropdown [options]="partyIdArray"
                                                                    [(ngModel)]="advanceSearch.partyId"
                                                                    [ngModelOptions]="{standlone:true}" name="partyId"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Party Id" filter="true"
                                                                    optionlabel="label">

                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Role Type ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2 form-group">
                                                                <p-dropdown [options]="allroleArray"
                                                                    [(ngModel)]="advanceSearch.roleTypeId"
                                                                    [ngModelOptions]="{standlone:true}" name="partyId"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Party Id" filter="true"
                                                                    optionlabel="label">

                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">From Date</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" name="fromDate" class="form-control"
                                                                    [(ngModel)]="advanceSearch.fromDate"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter From Date">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Through Date</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date" name="throughDate"
                                                                    class="form-control" id="exampleInputEmail1"
                                                                    [(ngModel)]="advanceSearch.ThroughDate"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Through Date">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Sequence Num
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2 form-group">
                                                                <input type="text" class="form-control"
                                                                    [(ngModel)]="advanceSearch.sequenceNum"
                                                                    [ngModelOptions]="{standalone:true}" name="seqNum"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Sequence Num">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-10 col-10 main-submit-button">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="advanceSearchContacts(false)">Find</button>
                                                    <button type="submit" (click)="reset();"
                                                        class="btn btn-danger ml-2">Reset</button>
                                                </div>
                                            </div> -->
                                            
                                            <div class="w3-card-4 classCard mt-2 w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Search Results</p>
                                                    <span>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStore" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role Type ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.data.partyId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.roleTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.sequenceNum}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    <span data-toggle="modal"
                                                                                        data-target="#addRolepopup"
                                                                                        (click)="updateRole(product)">Update</span>&nbsp;
                                                                                    <span
                                                                                        (click)="storeRemoveRole(product)">Delete</span>

                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==4" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Promos </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="addPromoStore()"
                                                            data-toggle="modal" data-target="#addPromopopup">Add Store
                                                            Promo</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ActiveOrInActive_All"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Product Promo ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Manual Only
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  
                                                                                        (click)="promos_Page(product.dataNode.productPromoId)">
                                                                                        {{product.dataNode.productPromoId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.manualOnly}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    <a  class="account-button" 
                                                                                        (click)="updatePromo(product)"
                                                                                        data-toggle="modal"
                                                                                        data-target="#addPromopopup">Update</a>
                                                                                </span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==5" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Catalogs </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            (click)="addCatalogData()"
                                                            data-target="#addCatalogspopup">Add Catalogs</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStoreCatalog"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Product Store ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date Time
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Catalog [ID]
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date Time
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  
                                                                                        (click)="catalog_Page(product.productStoreList.productStoreId)">
                                                                                        {{product.productStoreList.productStoreId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStoreList.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStoreList.sequenceNum}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStoreList.prodCatalogId}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStoreList.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    <a  class="account-button" 
                                                                                        data-toggle="modal"
                                                                                        (click)="updateCatalog(product)"
                                                                                        data-target="#addCatalogspopup">Update</a>&nbsp;<a
                                                                                        (click)="deleteProductStoreCatalog(product)"
                                                                                         class="account-button" >Remove</a>
                                                                                </span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==6" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Websites </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="createWebsitecontent()">New
                                                            Website</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#editwebPopup">Add New Website Store</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="productStoreWebsiteById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    WebSite ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Host
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Port
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  
                                                                                        (click)="updateWebsitecontent(product)">
                                                                                        {{product.webSiteId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.httpHost}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.httpPort}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    <a  class="account-button" 
                                                                                        data-toggle="modal"
                                                                                        data-target="#"
                                                                                        (click)="removeStoreUpdateWebSite(product)">Delete</a>&nbsp;

                                                                                </span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w3-card-4 classCard mt-2 w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Set Store on Website </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#editwebPopup">Update</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Website ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    <a  class="account-button" 
                                                                                        data-toggle="modal"
                                                                                        data-target="#">Update</a>&nbsp;<a
                                                                                         class="account-button" >Remove</a>
                                                                                </span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==7" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Ship Setup </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                            style="margin: 0;" data-toggle="modal" data-target="#addStoreShipPopup">Add Store Ship Setup</button> -->
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStoreShipmentSetupById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Method
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Comp. Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Min Wt.
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Max Wt.
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Min Size
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Max Size
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Min Tot
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Max Tot
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Allow USPS Addr
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Req. USPS Addr
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Allow Comp. Addr
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Req. Comp. Addr
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Incl. No Charge Items
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Incl. Feat. Group
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Excl. Feat. Group
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Incl. Geo ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Excl. Geo ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Shipment Service Name (deprecated
                                                                                    use)
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Config Properties (deprecated use)
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Shipment Custom Method
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Shipment Gateway Config ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Seq. Num.
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Allowance Percent
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Minimum Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>



                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  
                                                                                        data-target="#addStoreShipPopup"
                                                                                        data-toggle="modal"
                                                                                        (click)="updateShipping(product)">
                                                                                        {{product.shipmentMeth.productStoreShipMethId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.shipmentMethodTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.partyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.companyPartyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.minWeight}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.maxWeight}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.minSize}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.maxSize}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.minTotal}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.maxTotal}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.allowUspsAddr}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.requireUspsAddr}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.allowCompanyAddr}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.requireCompanyAddr}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.includeNoChargeItems}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.includeFeatureGroup}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.excludeFeatureGroup}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.includeGeoId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.excludeGeoId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.serviceName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.configProps}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <!-- <span > 
                                                                                {{product.customMethod.description ? product.customMethod.description : ""}}</span>   -->
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <!-- <span >
                                                                                {{product.shipGatewayConfig.description ? product.shipGatewayConfig.description : ""}}</span>  -->
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.sequenceNumber}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.allowancePercent}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMeth.minimumPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="storeRemoveShipMeth(product)">Delete</span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Add Product Store Ship Setup</p>

                                                </div>
                                                <form [formGroup]="ProductStoreShipForm">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Carrier Shipment
                                                                        Method
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown
                                                                        [options]="carrierShipmentMethodListArray"
                                                                        formControlName="CarrierShipmentMethod"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Carrier Shipment Method"
                                                                        filter="true" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div class="col-lg-10 col-10 main-submit-button">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        data-toggle="modal" data-target="#addStoreShipPopup"
                                                        (click)="prepareCreateShipMeth()">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==8" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Shipment Cost Estimates </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#addShipmentMultiTabPopup">New Shipment
                                                            Estimate</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStoreShipmentEstiById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Method
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Carrier Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Product Store Ship Meth ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role Type ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    To Geo
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Geo
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Weight Break ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Unit Of Measure
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Weight Unit Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Quantity Break ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Unit Of Measure
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Quantity Unit Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Price Break ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Unit Of Measure
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Price Unit Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Order Flat Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Order Price Percent
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Order Item Flat Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Shipping Price Percent
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Product Feature Group ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Oversize Unit
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Oversize Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Feature Percent
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Feature Price
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.shipmentList.shipmentCostEstimateId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentMethodType.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.carrierPartyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.productStoreShipMethId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.partyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.roleTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.geoIdTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.geoIdFrom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.weightBreakId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.weightUnitPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.quantityBreakId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.quantityUnitPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.priceBreakId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.priceUomId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.priceUnitPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.orderFlatPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.orderPricePercent}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.orderItemFlatPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.shippingPricePercent}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.productFeatureGroupId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.oversizeUnit}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.oversizePrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.featurePercent}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.shipmentList.featurePrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="storeRemoveShipRate(product)">Delete</span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="w3-card-4 mt-3 classCardView w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#updateSurveyPopup">Update</button>
                                                        <!--<button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                    <div class="color-black container">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Shipment Cost Estimate
                                                                                ID</p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Shipment Method Type ID
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">From Geo </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">To Geo </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Party ID </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Role Type ID </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="mt-2">
                                                            <h4>Flat charges</h4>
                                                        </div>


                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Flat Base Percent</p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Flat Base Price</p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Flat Item Price </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Flat Shipping Percent
                                                                            </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="mt-2">
                                                            <h4>Features Group Charges </h4>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Feature Group</p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Per-Feature Percent </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Per-Feature Price </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="mt-2">
                                                            <h4>Oversize Surcharge </h4>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Oversize Unit</p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Oversize Surcharge </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="mt-2">
                                                            <h4>Weight Charge</h4>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Weight Break ID </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Unit Of Measure </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Per Unit Price </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="mt-2">
                                                            <h4>Quantity Charge </h4>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Quantity Break ID </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Unit Of Measure </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Per Unit Price</p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="mt-2">
                                                            <h4>
                                                                Price charge
                                                            </h4>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Price Break ID </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Unit Of Measure </p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Per Unit Price</p>
                                                                            <span
                                                                                class="color-grey pl-20 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==9" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Product Store Payment Setup </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="addPayment()"
                                                            data-toggle="modal" data-target="#addPaymentSetupPopup">Add
                                                            Payment Setup</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStorePaymentListById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>


                                                                                    Payment Method Type
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Service Type
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Service Name
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Custom Method
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Payment Gateway Config ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Payment Props
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Apply To All
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.productStorePaymentList.paymentMethodTypeId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStorePaymentList.paymentServiceTypeEnumId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStorePaymentList.paymentService}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStorePaymentList.paymentCustomMethodId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStorePaymentList.paymentGatewayConfigId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStorePaymentList.paymentPropertiesPath}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.productStorePaymentList.applyToAllProducts}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn" data-toggle="modal"
                                                                                    data-target="#addPaymentSetupPopup"
                                                                                    (click)="updatePayment(product)"   class="account-button"  >Update</span>&nbsp;
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="storeRemovePaySetting(product)"   class="account-button"  >Delete</span>
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==10" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Product Store Financial Account Settings</p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" (click)="finOpen()"
                                                            class="btn btn-secondary submit-btn ml-2" style="margin: 0;"
                                                            data-toggle="modal" data-target="#addFinAcctPopup">Add Fin
                                                            Acct Settings</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table
                                                                    [value]="ProductStoreFinAccountSettingsListById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Fin Account Type ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Require Pin Code
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Validate the Gift Card's financial
                                                                                    Account
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Account Code Length
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Pin Code Length
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Account Valid Days
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Auth Valid Days
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Purchase Survey ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Purch Survey Send To
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Purch Survey Copy Me
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Allow Auth To Negative
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Min Balance
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Replenish Threshold
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Replenish Method Enum ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.finAccountTypeId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.requirePinCode}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.validateGCFinAcct}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.accountCodeLength}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.pinCodeLength}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.accountValidDays}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.authValidDays}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.purchaseSurveyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.purchSurveySendTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.purchSurveyCopyMe}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.allowAuthToNegative}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.minBalance}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.replenishThreshold}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.replenishMethodEnumId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn" data-toggle="modal"
                                                                                    data-target="#addFinAcctPopup"
                                                                                    (click)="updateFin(product)"  class="account-button"  >Update</span>&nbsp;
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="RemoveProductStoreFinAccountSettings(product)"  class="account-button"  >Delete</span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==11" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Product Store Facility Settings </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#addFacilityPopup">Add Facility</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="StoreFacilitySettingById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Facility ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.data.facilityId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.sequenceNum}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    <a  class="account-button" 
                                                                                        (click)="deleteProductStoreFacility(product)">Remove</a>
                                                                                </span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==12" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Email Setup </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="addEmailData()"
                                                            data-toggle="modal" data-target="#addEmailPopup">Add Email
                                                            Setup</button>
                                                    </span>
                                                </div>


                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStoreEmailSetupById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>


                                                                                    Email Type
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Body Screen Location
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Attachment Screen Location (XSL-FO)
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Address
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Cc Address
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Bcc Address
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Subject
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Content Type
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.emailType}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.bodyScreenLocation}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.xslfoAttachScreenLocation}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.fromAddress}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.ccAddress}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.bccAddress}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.subject}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.contentType}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn" data-toggle="modal"
                                                                                    data-target="#addEmailPopup"
                                                                                    (click)="updateAddress(product)"  class="account-button"  >Update</span>&nbsp;
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="removeProductStoreEmail(product)"  class="account-button"  >Delete</span>
                                                                            </td>




                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==13" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Surveys </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#addSurveyPopup">Add Survey</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductSurveyListById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Type
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Name
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Surveys
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Product
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Category
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Sequence Num
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.node.surveyApplTypeId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node.groupName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.surveyData.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node.productCategoryId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node.sequenceNum}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    <a (click)="deleteProductStoreSurveyAppl(product)"
                                                                                         class="account-button" >Delete</a>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==14" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Product Store Keyword Override </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="addOveride()"
                                                            data-toggle="modal" data-target="#addKeywordPopup">Add
                                                            Keyword</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStoreKeywordOvrdById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Keyword
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Target
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Target Type
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.node.keyword}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node.target}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node.targetTypeEnumId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn" data-toggle="modal"
                                                                                    data-target="#addKeywordPopup"
                                                                                    (click)="updateEvent(product)"  class="account-button"  >Update</span>&nbsp;
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="deleteProductStoreKeywordOvrd(product)"  class="account-button"  >Delete</span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==15" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">View Product Store Segments </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#addSegmentPopup">New Segment</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ViewProductStoreSegmentsId"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Segment ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Type ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Description
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.segmentGroupId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.segmentGroupTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                     class="account-button"  >
                                                                                    &nbsp;<a
                                                                                        (click)="deleteSegmentGroupById(product)"
                                                                                         class="account-button" >Remove</a>
                                                                                </span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==16" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Product Store Vendor Payments </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#addVendorPopup">Edit Vendor Payments</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductStoreVendorPaymentListById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Vendor Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Payment Method Type
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Credit Card Enum ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.psVendorPayment.vendorPartyId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.pmType}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.enumData}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="deleteProductStoreVendorPayment(product)"  class="account-button"  >Delete</span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==17" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Product Store Vendor Shipments </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="newProduct()">New Product
                                                            Store</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#addVendorShipmentPopup">Add Vendor
                                                            Shipments</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="ProductVendorStoreShipmentListById"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>


                                                                                    Carrier Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">

                                                                                    Vendor Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Shipment Method Type ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.pvShipment.carrierPartyId}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.pvShipment.vendorPartyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.pvShipment.shipmentMethodTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span *ngIf="!this.Hidebtn"
                                                                                    (click)="deleteDataSource(product)"  class="account-button"  >Delete</span>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addRolepopup" tabindex="-1" role="dialog" aria-labelledby="addRolepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Rules</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonMM>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Rules</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addRole">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID<span
                                                                style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="partyIdArray" filter="true"
                                                            formControlName="partyID" optionlabel="label"
                                                            placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="allroleArray" filter="true"
                                                            formControlName="roleTypeID" optionlabel="label"
                                                            placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.showRole" type="submit" (click)="storeCreateRole()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button *ngIf="this.showRole" type="submit" (click)="storeUpdateRole()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addPromopopup" tabindex="-1" role="dialog" aria-labelledby="addPromopopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Store Promo</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonJJ>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Store Promo</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPromo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Promo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="StorePromoListArray" filter="true"
                                                            formControlName="promoID" optionlabel="label"
                                                            placeholder="Enter Product Promo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Manual Only
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="yesNoArray" filter="true"
                                                            formControlName="manualOnly" optionlabel="label"
                                                            placeholder="Enter Manual Only">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!showPromo" (click)="createProductStorePromoAppl()"
                                                type="submit" class="btn btn-secondary submit-btn">Add</button>
                                            <button *ngIf="showPromo" (click)="updateProductStorePromoAppl()"
                                                type="submit" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addCatalogspopup" tabindex="-1" role="dialog" aria-labelledby="addCatalogspopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Catalog</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonLL>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Catalog</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCatalog">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Catalog
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="catListArray" filter="true"
                                                            formControlName="catalog" optionlabel="label"
                                                            placeholder="Enter Catalog">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" formControlName="seqNum" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.showCat" type="submit"
                                                (click)="createProductStoreCatalog()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button *ngIf="this.showCat" type="submit"
                                                (click)="updateProductStoreCatalog()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addWebsitePopup" tabindex="-1" role="dialog" aria-labelledby="addWebsitePopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add WebSite</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add WebSite</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addWebsite">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Web Site ID<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" disabled
                                                            formControlName="websiteID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Web Site ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Site Name<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="siteName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Site Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Http Host
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="httpHost" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Http Host">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Http Port
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="httpPort" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Http Port">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Enable Https
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="enableHttps"
                                                            optionlabel="label" placeholder="Enter Enable Https">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Webapp Path
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="webappPath" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Webapp Path">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Standard Content Prefix
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="standardPrefix" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Standard Content Prefix">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Secure Content Prefix
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="securePrefix" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Secure Content Prefix">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Domain Cookie
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="domainCookie" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Domain Cookie">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Visual Theme Set
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="visualTheme"
                                                            optionlabel="label" placeholder="Enter Visual Theme Set">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Store ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="productStoreID"
                                                            optionlabel="label" placeholder="Enter Product Store ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Product Store Change
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="allowPSChange"
                                                            optionlabel="label"
                                                            placeholder="Enter Allow Product Store Change">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Hosted Path Alias
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="pathAlias" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Hosted Path Alias">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Deafult
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="isDefault"
                                                            optionlabel="label" placeholder="Enter Is Default">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Display Maintenance Page
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="maintenancePage"
                                                            optionlabel="label"
                                                            placeholder="Enter Display Maintenance Page">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editwebPopup" tabindex="-1" role="dialog" aria-labelledby="editwebPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Store on Website</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton15>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Store on Website</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addStoreWeb">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Store Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="storeName"
                                                            [options]="storeWebsiteListArray" optionlabel="label"
                                                            placeholder="Enter Store Name">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-10 main-submit-button" style="margin-left:3%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="storeUpdateWebSite()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addStoreShipPopup" tabindex="-1" role="dialog" aria-labelledby="addStoreShipPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Ship Setup</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonAB>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Ship Setup</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addStoreShip">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="RoleTypeID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Role Type ID	">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Method Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ShipmentMethodTypeID"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Shipment Method Type ID">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="PartyID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Party ID	">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="SequenceNum" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num	">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min Size
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="MinSize" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Min Size	">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max Size
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="MaxSize" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Max Size	">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min Wt.
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="MinWt"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Min Wt.">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max Wt.
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="MaxWt"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Max Wt.	">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min Tot.
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="MinTot"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Min Tot.">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max Tot.
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="MaxTot"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Max Tot.	">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow USPS Addr
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="AllowUSPSAddr"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Enter Allow USPS Addr">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. USPS Addr
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ReqUSPSAddr"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Req. USPS Addr">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Comp. Addr
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="AllowCompAddr"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Allow Comp. Addr">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Comp. Addr
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ReqCompAddr"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Req. Comp. Addr">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comp. Party ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="CompPartyID"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Enter Comp. Party ID	">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Include Free Ship Items
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IncludeFreeShipItems"
                                                            [options]="yesNoArray" optionlabel="label"
                                                            placeholder="Enter Include Free Ship Items">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Include Geo


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IncludeGeo"
                                                            [options]="GeoIdArray" optionlabel="label"
                                                            placeholder="Enter Include Geo	">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Exclude Geo

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ExcludeGeo"
                                                            [options]="GeoIdArray" optionlabel="label"
                                                            placeholder="Enter Exclude Geo	">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Include Feature Group
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="IncludeFeatureGroup"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Include Feature Group">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Exclude Feature Group
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ExcludeFeatureGroup"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Exclude Feature Group">

                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Service Name
                                                            (deprecated use)
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ShipmentServiceName"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Shipment Service Name (deprecated use)">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Config Properties (deprecated
                                                            use)
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ConfigProperties" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Config Properties (deprecated use)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Custom Method
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ShipmentCustomMethod"
                                                            [options]="shipmentCustomMethodArray" optionlabel="label"
                                                            placeholder="Enter Shipment Custom Method 	">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Gateway Config ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="ShipmentGatewayConfigID"
                                                            [options]="shipmentGatewayConfigArray" optionlabel="label"
                                                            placeholder="Enter Shipment Gateway Config ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="showShip">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Store Ship Meth ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ShipMethID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Product Store Ship Meth ID">

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!showShip"
                                                (click)="storeCreateShipMeth()">Submit</button>
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="showShip"
                                                (click)="storeUpdateShipMeth()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade" id="addShipmentPopup" tabindex="-1" role="dialog" aria-labelledby="addShipmentPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Shipment Estimate</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonLM>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Shipment Estimate</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newShipment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Method
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="ProductStoreShipMethIdArray" filter="true" formControlName="shipmentMethod" optionlabel="label" placeholder="Enter Shipment Method">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Geo
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown  [options]="GeoIdArray" filter="true" formControlName="fromGeo" optionlabel="label" placeholder="Enter From Geo">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Geo
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="GeoIdArray" filter="true" formControlName="toGeo" optionlabel="label" placeholder="Enter To Geo">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="partyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="roleTypeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role Type ID">
                                                    </div>
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Charges
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Base Percent
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="basePercent" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Flat Base Percent">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Base Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="basePrice" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Flat Base Price">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Item Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="itemPrice" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Flat Item Price">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Shipping Percent
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="shippingPercent" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Flat Shipping Percent">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Feature Group
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="featureGroup" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Feature Group">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Pre-Feature Percent
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="preFeaturePercent" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Pre-Feature Percent">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Pre-Feature Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="preFeaturePrice" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Pre-Feature Price">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Oversize Unit
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="oversizeUnit" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Oversize Unit">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Oversize Surchurge
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="oversizeSurchurge" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Oversize Surchurge">
                                                    </div>
                                                    <div class="col-lg-7 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                    </div>
                                                    <div class="col-lg-9 form-group">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min. Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minWT" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Min. Weight">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max. Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxWT" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max. Weight">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Weight Break ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="WeightBreakIdListArray" filter="true" formControlName="wtBreakID" optionlabel="label" placeholder="Enter Weight Break ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Unit of Measure
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="UnitOfMeasureArray" filter="true" formControlName="pressureUnitWT" optionlabel="label" placeholder="Enter Unit of Pressure">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Unit Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="unitPriceWT" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Per Unit Price">
                                                    </div>
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                    </div>
                                                    <div class="col-lg-9 form-group">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min. Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Min. Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max. Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max. Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity Break ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="qtyBreakID" optionlabel="label" placeholder="Enter Quantity Break ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Unit of Measure
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="UnitOfMeasureArray" filter="true" formControlName="measureUnitQty" optionlabel="label" placeholder="Enter Unit of Measure">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Unit Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="unitPriceQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Per Unit Price">
                                                    </div>
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                    </div>
                                                    <div class="col-lg-9 form-group">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min. Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minPrice" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Min. Price">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max. Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxPrice" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max. Price">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Price Break ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="priceBreakID" optionlabel="label" placeholder="Enter Price Break ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Unit of Measure
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="UnitOfMeasureArray" filter="true" formControlName="measureUnitPrice" optionlabel="label" placeholder="Enter Unit of Measure">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Unit Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="unitPricePR" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Per Unit Price">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="storeCreateShipRate()" type="submit" class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="addShipmentMultiTabPopup" tabindex="-1" role="dialog"
    aria-labelledby="addShipmentMultiTabPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Shipment Estimate</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonLM>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Shipment Estimate</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newShipmentMutliTabForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <!-- <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Method
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="ProductStoreShipMethIdArray" filter="true" formControlName="shipmentMethod" optionlabel="label" placeholder="Enter Shipment Method">
                                                        </p-dropdown>
                                                    </div> -->

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Cost Estimate ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            formControlName="shipmentCostEstimateID" optionlabel="label"
                                                            placeholder="Enter Shipment Cost Estimate ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Method Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="shipmentMethodTypeID"
                                                            optionlabel="label"
                                                            placeholder="Enter Shipment Method Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Geo
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="GeoIdArray" filter="true"
                                                            formControlName="fromGeo" optionlabel="label"
                                                            placeholder="Enter From Geo">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">To Geo
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="GeoIdArray" filter="true"
                                                            formControlName="toGeo" optionlabel="label"
                                                            placeholder="Enter To Geo">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="partyID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Party ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="roleTypeID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Role Type ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Base Percent
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="basePercent" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Flat Base Percent">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Base Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="basePrice" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Flat Base Price">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Item Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="itemPrice" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Flat Item Price">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Flat Shipping Percent
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="shippingPercent" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Flat Shipping Percent">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Feature Group
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="featureGroup" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Feature Group">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Pre-Feature Percent
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="preFeaturePercent" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Pre-Feature Percent">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Pre-Feature Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="preFeaturePrice" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Pre-Feature Price">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Oversize Unit
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="oversizeUnit" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Oversize Unit">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Oversize Surchurge
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="oversizeSurchurge" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Oversize Surchurge">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Weight Break ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="WeightBreakIdListArray" filter="true"
                                                            formControlName="wtBreakID" optionlabel="label"
                                                            placeholder="Enter Weight Break ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Unit of Measure
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="UnitOfMeasureArray" filter="true"
                                                            formControlName="pressureUnitWT" optionlabel="label"
                                                            placeholder="Enter Unit of Pressure">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Unit Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="unitPriceWT" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Per Unit Price">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Min. Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minQty"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Min. Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max. Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxQty"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Max. Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity Break ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="qtyBreakID"
                                                            optionlabel="label" placeholder="Enter Quantity Break ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Unit of Measure
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="UnitOfMeasureArray" filter="true"
                                                            formControlName="measureUnitQty" optionlabel="label"
                                                            placeholder="Enter Unit of Measure">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Unit Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="unitPriceQty" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Per Unit Price">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Price Break ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="priceBreakID"
                                                            optionlabel="label" placeholder="Enter Price Break ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Unit of Measure
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="costEstimatedUomArray" filter="true"
                                                            formControlName="measureUnitPrice" optionlabel="label"
                                                            placeholder="Enter Unit of Measure">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Per Unit Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="unitPricePR" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Per Unit Price">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="storeCreateShipRate()" type="submit"
                                                class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addPaymentSetupPopup" tabindex="-1" role="dialog"
    aria-labelledby="addPaymentSetupPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Payment Setup</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonHH>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Payment Setup</a></li>

                            </ul>
                        </div>
                        <div class="">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPaymentSetup">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Method Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="PaymentMethodTypeListDataArray"
                                                            filter="true" formControlName="methodType"
                                                            optionlabel="label" placeholder="Enter Payment Method Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Service Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="ServiceTypeListArray" filter="true"
                                                            formControlName="serviceType" optionlabel="label"
                                                            placeholder="Enter Service Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Service Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="serviceName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Service Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Custom Method
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="CustomMethodListPaymentArray"
                                                            filter="true" formControlName="custMethod"
                                                            optionlabel="label" placeholder="Enter Custom Method">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Gateway Config ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="PaymentGatewayConfigIdListArray"
                                                            filter="true" formControlName="gatewayConfigID"
                                                            optionlabel="label"
                                                            placeholder="Enter Payment Gateway Config ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Props
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="paymentProps" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Payment Props">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Apply to All Products
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="yesNoArray" filter="true"
                                                            formControlName="applyProducts" optionlabel="label"
                                                            placeholder="Enter Apply to All Products">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.showPay" type="submit"
                                                (click)="storeCreatePaySetting()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button *ngIf="this.showPay" type="submit" (click)="storeUpdatePaySetting()"
                                                class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFinAcctPopup" tabindex="-1" role="dialog" aria-labelledby="addFinAcctPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Financial Account Settings</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonH>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Financial Account Settings</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFinancial">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Financial Account Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="FinAccountTypeIdListArray"
                                                            formControlName="financialID" optionlabel="label"
                                                            placeholder="Enter Financial Account Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Require Pin Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="requirePinCode" optionlabel="label"
                                                            placeholder="Enter Require Pin Code">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Validate the Gift Card's
                                                            Financial Account
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="validateGift" optionlabel="label"
                                                            placeholder="Enter Validate the Gift Card's Financial Account">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Account Code Length
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="codeLength" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Account Code Length">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Pin Code Length
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="pinCodeLength" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Pin Code Length">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Account Valid Days
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="accValidDays" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Account Valid Days">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auth Valid Days
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="authValidDays" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Auth Valid Days">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purchase Survey ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="purchaseSurveyID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Purchase Survey ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purchase Survey Send To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="purchSurveyTo" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Purchase Survey Send To">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purchase Survey Copy Me
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="purchSurveyCopyMe" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Purchase Survey Copy Me">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Auth to Negative
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="allowAuthNegative" optionlabel="label"
                                                            placeholder="Enter Allow Auth to Negative">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Minimum Balance
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="minBalance" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Minimum Balance">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Replenish Threshold
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="replenishThreshold" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Replenish Threshold">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Replenish Method Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            [options]="ReplenishEnumTypeIdListArray"
                                                            formControlName="replenishEnumID" optionlabel="label"
                                                            placeholder="Enter Replenish Method Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.showFin"
                                                (click)="CreateProductStoreFinAccountSettings()" type="submit"
                                                class="btn btn-secondary submit-btn">Add</button>
                                            <button *ngIf="this.showFin"
                                                (click)="updateProductStoreFinAccountSettings()" type="submit"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFacilityPopup" tabindex="-1" role="dialog" aria-labelledby="addFacilityPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Facility</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonG>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Facility</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFacility">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="FacilityIdListValueArray"
                                                            formControlName="facilityID" optionlabel="label"
                                                            placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="addProductStoreFacility()"
                                                class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addEmailPopup" tabindex="-1" role="dialog" aria-labelledby="addEmailPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Product Promo</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonRA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Product Promo</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addEmailSetup">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Email Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="EmailTypeListArray"
                                                            formControlName="emailType" optionlabel="label"
                                                            placeholder="Enter Email Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Body Screen location
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="bodyScreenLocation" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Body Screen location">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Attachment Screen Location
                                                            (XSL-FO)
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="attachScreenLocation"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Attachment Screen Location (XSL-FO)">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Address
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="fromAddress" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter From Address">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">CC Address
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ccAddress" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter CC Address">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">BCC Address
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="bccAddress" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter BCC Address">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="subject" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Subject">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Content Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="contentType" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Content Type">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.showEmail" type="submit"
                                                (click)="createProductStoreEmail()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button *ngIf="this.showEmail" type="submit"
                                                (click)="updateProductStoreEmail()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addSurveyPopup" tabindex="-1" role="dialog" aria-labelledby="addSurveyPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Survey</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonE>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Survey</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addSurvey">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="SurveyTypeListArray" filter="true"
                                                            formControlName="type" optionlabel="label"
                                                            placeholder="Enter Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Group Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="groupName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Group Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Surveys
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="SurveyListValueArray" filter="true"
                                                            formControlName="surveys" optionlabel="label"
                                                            placeholder="Enter Surveys">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="productListArray"
                                                            formControlName="productID" optionlabel="label"
                                                            placeholder="Enter Product ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Category ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="categoryListArray"
                                                            formControlName="categoryID" optionlabel="label"
                                                            placeholder="Enter Category ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Survey Template Path
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="surveyPath" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Survey Template Path">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Result Template Path
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="resultPath" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Result Template Path">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductStoreSurveyAppl()"
                                                class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addKeywordPopup" tabindex="-1" role="dialog" aria-labelledby="addKeywordPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Keyword Override</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonD>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Keyword Override</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addKeyword">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Keyword<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="keyword" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Keyword">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Target<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="target"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Target">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Target Type Enum [ID]
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="targetEnum"
                                                            [options]="TargetTypeEnumIdListArray" optionlabel="label"
                                                            placeholder="Enter Target Type Enum [ID]">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!this.show" type="submit"
                                                (click)="createProductStoreKeywordOvrd()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button *ngIf="this.show" type="submit"
                                                (click)="updateProductStoreKeywordOvrd()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addSegmentPopup" tabindex="-1" role="dialog" aria-labelledby="addSegmentPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Segment Group</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonC>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Segment Group</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addSegment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Segment Group Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="SegmentGroupTypeIDArray"
                                                            formControlName="segmentTypeID"
                                                            placeholder="Enter Segment Group Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product store ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductStoreIDArray"
                                                            formControlName="productStoreID" optionlabel="label"
                                                            placeholder="Enter Product store ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createSegmentGroup();"
                                                class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addVendorPopup" tabindex="-1" role="dialog" aria-labelledby="addVendorPopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Vendor Payments</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Vendor Payments</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addVendorPayment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Vendor Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            optionlabel="label" formControlName="partyID"
                                                            placeholder="Enter Vendor Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Payment Method Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="PaymentMethodTypeListArray"
                                                            optionlabel="label" formControlName="paymentType"
                                                            placeholder="Enter Payment Method Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Credit Card Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CreditCardEnumIdListArray"
                                                            optionlabel="label" formControlName="creditCardEnumID"
                                                            placeholder="Enter Credit Card Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductStoreVendorPayment()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addVendorShipmentPopup" tabindex="-1" role="dialog"
    aria-labelledby="addVendorShipmentPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Store Vendor Shipments</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Store Vendor Shipments</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addVendorShipment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Carrier Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="carrierPartyID"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Enter Carrier Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Vendor Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="vendorPartyID"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Enter Vendor Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Method Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="shipmentTypeID"
                                                            [options]="ShipmentMethodTypeIdListArray"
                                                            optionlabel="label"
                                                            placeholder="Enter Shipment Method Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductStoreVendorShipment()"
                                                class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="editProductStorePopup" tabindex="-1" role="dialog" aria-labelledby="editProductStorePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Product Store</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonH>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Product Store</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editProductStore">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Store ID<span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="productStoreID" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Product Store ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Primary Store Group ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductStoreGroupListArray"
                                                            formControlName="primaryStoreGroupID" optionlabel="label"
                                                            placeholder="Enter Primary Store Group ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Store Name<span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="storeName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Store Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Title</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="title"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Title">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sub-Title</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="subTitle" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Sub-Title">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Company Name</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="companyName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Company Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Demo Store
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="isDemoStore" optionlabel="label"
                                                            placeholder="Enter Is Demo Store">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">For controlling the look and
                                                            feel of the web-based store
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            [options]="LookAndFeelWebBasedStoreArray"
                                                            formControlName="controlling" optionlabel="label"
                                                            placeholder="Enter For controlling the look and feel of the web-based store">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Inventory
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Inventory Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="InventoryFacilityIdArray"
                                                            formControlName="inventoryFacilityID" optionlabel="label"
                                                            placeholder="Enter Inventory Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">One Inventory Facility
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="oneInventoryFacility" optionlabel="label"
                                                            placeholder="Enter One Inventory Facility">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Immediately Fulfilled
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="isFulfil" optionlabel="label"
                                                            placeholder="Enter Is Immediately Fulfilled">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Check Inventory
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="checkInventory" optionlabel="label"
                                                            placeholder="Enter Check Inventory">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Require Inventory
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="reqInventory" optionlabel="label"
                                                            placeholder="Enter Require Inventory">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Requirement Method Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            [options]="RequirementMethodEnumIdArray"
                                                            formControlName="reqEnumID" optionlabel="label"
                                                            placeholder="Enter Requirement Method Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserve Inventory
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="reserveInventory" optionlabel="label"
                                                            placeholder="Enter Reserve Inventory">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reserve Order Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ReserveOrderEnumIdArray"
                                                            formControlName="reserveEnumID" optionlabel="label"
                                                            placeholder="Enter Reserve Order Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Balance Res On Order Creation
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="resOrderCreation" optionlabel="label"
                                                            placeholder="Enter Balance Res On Order Creation">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Show Out of Stock Products
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="showStockProducts" optionlabel="label"
                                                            placeholder="Enter Show Out of Stock Products">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Lots Managing
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="lotsManaging" optionlabel="label"
                                                            placeholder="Enter Lots Managing">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Shopping Cart
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">View Cart on Add
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="cartOnAdd" optionlabel="label"
                                                            placeholder="Enter View Cart on Add">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Save Cart
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="autoSaveCart" optionlabel="label"
                                                            placeholder="Enter Auto Save Cart">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Add to Cart Replace Upsell
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="replaceUpsell" optionlabel="label"
                                                            placeholder="Enter Add to Cart Replace Upsell">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Add to Cart Remove Incompat
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="removeIncompat" optionlabel="label"
                                                            placeholder="Enter Add to Cart Remove Incompat">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Show Checkout Gift Options
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="giftOptions" optionlabel="label"
                                                            placeholder="Enter Show Checkout Gift Options">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Prod Search Exclude Variants
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="excludeVariants" optionlabel="label"
                                                            placeholder="Enter Prod Search Exclude Variants">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Order Decimal Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="decimalQuantity" optionlabel="label"
                                                            placeholder="Enter Allow Order Decimal Quantity">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipping
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Prorate Shipping
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="prorateShipping" optionlabel="label"
                                                            placeholder="Enter Prorate Shipping">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Ship Addr. for Dig Items
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="addrDigItems" optionlabel="label"
                                                            placeholder="Enter Req. Ship Addr. for Dig Items">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Select Payment Type Per Item
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="paymentType" optionlabel="label"
                                                            placeholder="Enter Select Payment Type Per Item">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Ship if Capture Fails
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="captureFails" optionlabel="label"
                                                            placeholder="Enter Ship if Capture Fails">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Split Pay Pref Per Ship Grp
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="splitPay" optionlabel="label"
                                                            placeholder="Enter Split Pay Pref Per Ship Grp">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Payments
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Pay to Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            formControlName="payPartyID" optionlabel="label"
                                                            placeholder="Enter Pay to Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Store Credit Account Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            [options]="ScoreCreditAccountListArray"
                                                            formControlName="storeEnumID" optionlabel="label"
                                                            placeholder="Enter Store Credit Account Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Manual Audio Is Capture
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="manualAudioCapture" optionlabel="label"
                                                            placeholder="Enter Manual Audio Is Capture">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Retry Failed Auths
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="retryAuths" optionlabel="label"
                                                            placeholder="Enter Retry Failed Auths">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Days To Cancel Non Pay</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="daystoCancelnonPay" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Days To Cancel Non Pay">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Order CC Try Exp
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="ccTryExp" optionlabel="label"
                                                            placeholder="Enter Auto Order CC Try Exp">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Order CC Try Other Cards
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="ccOtherCards" optionlabel="label"
                                                            placeholder="Enter Auto Order CC Try Other Cards">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Order CC Try Later NSF
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="ccLaterNSF" optionlabel="label"
                                                            placeholder="Enter Auto Order CC Try Later NSF">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Order CC Try Later
                                                            Max</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="ccLaterMax" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Auto Order CC Try Later Max">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Show Credit Valid Days</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="creditValidDays" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Show Credit Valid Days">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Set Owner upon Issuance
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="setIssuance" optionlabel="label"
                                                            placeholder="Enter Set Owner upon Issuance">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Orders
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Number Prefix</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="orderNumberPrefix" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Order Number Prefix">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Sales Channel Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            [options]="DefaultSalesChannelEnumListArray"
                                                            formControlName="channelEnumID" optionlabel="label"
                                                            placeholder="Enter Default Sales Channel Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Explore Order Items
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="exploreItems" optionlabel="label"
                                                            placeholder="Enter Explore Order Items">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Check GC Balance
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="gcBalance" optionlabel="label"
                                                            placeholder="Enter Check GC Balance">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Invoice Digital Items
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="invoiceDigitalItems" optionlabel="label"
                                                            placeholder="Enter Auto Invoice Digital Items">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Approve Invoice
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="approveInvoice" optionlabel="label"
                                                            placeholder="Enter Auto Approve Invoice">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auto Approve Order
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="approveOrder" optionlabel="label"
                                                            placeholder="Enter Auto Approve Order">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Return Inventory Recieve
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="returnInventoryRecieve" optionlabel="label"
                                                            placeholder="Enter Req. Return Inventory Recieve">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Localization
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Locale Storage</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="localeStorage" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Default Locale Storage">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Currency UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="currencyArray"
                                                            formControlName="currencyUomID" optionlabel="label"
                                                            placeholder="Enter Default Currency UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Time Zone</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="timeZone" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Default Time Zone">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Orders Status
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Header Approved Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="HeaderApprovedStatusArray"
                                                            formControlName="headerApprovedStatus" optionlabel="label"
                                                            placeholder="Enter Header Approved Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Item Approved Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray"
                                                            formControlName="itemStatus" optionlabel="label"
                                                            placeholder="Enter Item Approved Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Digital Item Approved Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray"
                                                            formControlName="digitalItemStatus" optionlabel="label"
                                                            placeholder="Enter Digital Item Approved Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Header Declined Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="HeaderApprovedStatusArray"
                                                            formControlName="headerDeclinedStatus" optionlabel="label"
                                                            placeholder="Enter Header Declined Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Item Declined Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray"
                                                            formControlName="itemDeclinedStatus" optionlabel="label"
                                                            placeholder="Enter Item Declined Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Header Cancel Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="HeaderApprovedStatusArray"
                                                            formControlName="headerCancelStatus" optionlabel="label"
                                                            placeholder="Enter Header Cancel Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Item Cancel Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray"
                                                            formControlName="itemCancelStatus" optionlabel="label"
                                                            placeholder="Enter Item Cancel Status">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Messages
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auth Declined Message</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="authDeclinedMessage"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Auth Declined Message">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auth Fraud Message</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="authFraudMessage" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Auth Fraud Message">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auth Error Message</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="authErrorMessage" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Auth Error Message">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Tax
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Prorate Taxes
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="prorateTax" optionlabel="label"
                                                            placeholder="Enter Prorate Taxes">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Show Prices with VAT Tax
                                                            Included
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="pricesVATIncluded" optionlabel="label"
                                                            placeholder="Enter Show Prices with VAT Tax Included">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Show Tax is Exempt
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="taxExempt" optionlabel="label"
                                                            placeholder="Enter Show Tax is Exempt">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">VAT Tax Auth Geo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="VatTaxAuthGeoIdArray"
                                                            formControlName="vatGeoID" optionlabel="label"
                                                            placeholder="Enter VAT Tax Auth Geo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">VAT Tax Auth Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            formControlName="vatPartyID" optionlabel="label"
                                                            placeholder="Enter VAT Tax Auth Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Visitors
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Auth Approve Reviews
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="approveReviews" optionlabel="label"
                                                            placeholder="Enter Auth Approve Reviews">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Password
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="allowPassword" optionlabel="label"
                                                            placeholder="Enter Allow Password">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Password</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="defaultPassword" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Default Password">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Primary Email Username
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="usePrimaryUsername" optionlabel="label"
                                                            placeholder="Enter Use Primary Email Username">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Require Customer Role
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="reqCustomerRole" optionlabel="label"
                                                            placeholder="Enter Require Customer Role">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Enable Auto Suggestion List
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="enableSuggestion" optionlabel="label"
                                                            placeholder="Enter Enable Auto Suggestion List">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInput">
                                                        <label for="exampleInputEmail1">Upload
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Enable Digital Product Upload
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="enableProductUpload" optionlabel="label"
                                                            placeholder="Enter Enable Digital Product Upload">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Digital Product Upload Category
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true"
                                                            [options]="DigProdUploadCategoryListArray"
                                                            formControlName="productUploadCategoryID"
                                                            optionlabel="label"
                                                            placeholder="Enter Digital Product Upload Category ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allow Comment
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="allowComment" optionlabel="label"
                                                            placeholder="Enter Allow Comment">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allocate Inventory
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="yesNoArray"
                                                            formControlName="allocateInventory" optionlabel="label"
                                                            placeholder="Enter Allocate Inventory">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>