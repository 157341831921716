import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-add-icalendar-fixed-asset',
  templateUrl: './add-icalendar-fixed-asset.component.html',
  styleUrls: ['./add-icalendar-fixed-asset.component.css']
})
export class AddIcalendarFixedAssetComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 4;
  addiCalender: any;
  addFixedAsset: any;
  personId: any;
  FixedAssetIdListArray: any[]=[];
  workEffortId: any;
  ICalendarFixedAssetAssigndata: any[]=[];
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  FromDate: string;
  ThruDate: string;
  show: boolean;
  FromDateEdit: string;
  ThroughDate: string;
  fromDateValue: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    private _location: Location,
    
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
        readonly _FormBuilder: FormBuilder, private _Router : Router
  ) {
    this.addiCalender = this._FormBuilder.group({
      calendarOwner: "",
      calendarName: "",
      calendarVisibility: "",
      fromDate: "",
      toDate: "",
    });
    this.addFixedAsset = this._FormBuilder.group({
      fixedAssetID: "",
      fromDate: "",
      throughDate: "",
      comments: "",
    });
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"]; 
     
      
    })
    this.getFixedAssetIdList();
    this.geICalendarFixedAssetAssign();
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  
  updateICalendarFixedAssetAssign(): void {
    this.spinner.show();
    const fromDate=this.addFixedAsset.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addFixedAsset.get('throughDate').value;
    this.ThruDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
   
  "comments": this.addFixedAsset.value.comments,
  "fixedAssetId": this.addFixedAsset.value.fixedAssetID,
  "fromDate": this.fromDateValue,
  "thruDate": this.ThruDate,
  "workEffortId": this.workEffortId
       
         
     }
     this.accountsService.updateICalendarFixedAssetAssign(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Updated Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addFixedAsset.reset();
         this.geICalendarFixedAssetAssign();
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  createICalendarFixedAssetAssign(): void {
    this.spinner.show();
    const fromDate=this.addFixedAsset.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addFixedAsset.get('throughDate').value;
    this.ThruDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
   
  "comments": this.addFixedAsset.value.comments,
  "fixedAssetId": this.addFixedAsset.value.fixedAssetID,
  "fromDate": this.FromDate,
  "thruDate": this.ThruDate,
  "workEffortId": this.workEffortId
       
         
     }
     this.myContactsService.createICalendarFixedAssetAssign(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addFixedAsset.reset();
         this.geICalendarFixedAssetAssign();
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
    
  
  geICalendarFixedAssetAssign(){
    this.spinner.show();
    this.myContactsService.geICalendarFixedAssetAssign(this.workEffortId).subscribe((res:any)=> {
      this.ICalendarFixedAssetAssigndata = res.data;
      this.spinner.hide();
    
    })   
   }
  openiCalendar(){
    this._Router.navigate(["/psa/work-effort/iCalendar-view/EditiCalendar"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  openChildren(){
    this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarChildren"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  openParties(){
    this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarParties"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  openFixedAssets(){
    this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarFixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  openData(){
    this._Router.navigate(["/psa/work-effort/iCalendar-view/editiCalendarData"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  openHelp(){
    this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarHelp"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  updateEvent(product){
    this.show=true;
    this.addFixedAsset.reset();
    this.spinner.show();
   
      this.fromDateValue=product.fromDate;
      setTimeout(() => {
        const fromdate=product.abc.fromDate;
        this.FromDateEdit=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const thrudate=product.abc.thruDate;
        this.ThroughDate=this.datePipe.transform(thrudate,"yyyy-MM-dd");
        const formValue = this.addFixedAsset;
        formValue.patchValue({
          fixedAssetID:product.abc.fixedAssetId,
          fromDate: this.FromDateEdit,
          throughDate: this.ThroughDate,
          comments: product.abc.comments,

        })
      
      }, 2000);
  
  }
  getFixedAssetIdList() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdList().subscribe(res => {
      this.personId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.personId) {
        this.FixedAssetIdListArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
   }
}
