import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-tax-groups',
  templateUrl: './sales-tax-groups.component.html',
  styleUrls: ['./sales-tax-groups.component.css']
})
export class SalesTaxGroupsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
