import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FinancialService } from 'src/app/services/financial.service';
import * as moment from 'moment';
import Swal from 'sweetalert2'
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
@Component({
  selector: 'app-fix-assest-summary-page',
  templateUrl: './fix-assest-summary-page.component.html',
  styleUrls: ['./fix-assest-summary-page.component.css']
})
export class FixAssestSummaryPageComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  show: boolean;
  createFixAssetForm: FormGroup;
  fixedAssetTypes: any[];
  classEnumId: any[];
  calendar: any[];
  partyIds: any[];
  activeCategory = 2;
  productIds: any[];
  fixAssetIds: any[];
  uomIds: any[];
  roleTypeIds: any[];
  facilityId: any[];
  facilityLocation: any[];
  fixAssetId: string;
  fixAsset: any;
  editMode: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search = '';
 
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  fixedAssetId: string;

  productForm: FormGroup;
 
  productTypeIds: any[];
 
  assignmentForm: FormGroup;

  statusIds: any[];
  assignment: any;
  productData: any;
  activeCategoryMain = 2;
  financialList: any[] = []
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  assetProducts: any[];
  assetAssignments: any[];
  editModeProduct: boolean;
  editAssigment: boolean;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FinancialService: FinancialService,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
 
    readonly  _Router: Router,

    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService
  ) {
    this.createFixAssetForm = this._FormBuilder.group({
      acquireOrderId: [''], acquireOrderItemSeqId: [''],actualEndOfLife: [''],
      calendarId: [''],classEnumId: [''], dateAcquired: [''],dateLastServiced: [''],dateNextService: [''],
      depreciation: [''],expectedEndOfLife: [''],fixedAssetId: [''],fixedAssetName: [''],
      fixedAssetTypeId: ['COMPUTER_HARDWARE'],
      instanceOfProductId: [''],locatedAtFacilityId: [''],locatedAtLocationSeqId: [''],
      parentFixedAssetId: [''],partyId: [''],productionCapacity: [''],purchaseCost: [''],
      roleTypeId: [''],
      salvageValue: [''],
      serialNumber: [''],
      uomId: ['']
    });
    this.fixedAssetTypes = [];
    this.classEnumId = [];
    this.calendar = [];
    this.partyIds = [];
    this.productIds = [];
    this.fixAssetIds = [];
    this.uomIds = [];
    this.roleTypeIds = [];
    this.editMode = false;
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.fixedAssetId = '';
    this.assetProducts = [];
    this.assetAssignments = [];
    this.fixedAssetId = '';
    this.productForm = this._FormBuilder.group({
      comments: [''],
      fixedAssetProductTypeId: [''],
      fromDate: [''],
      productId: [''],
      quantity: [''],
      quantityUomId: [''],
      sequenceNum: [''],
      thruDate: ['']
    });
    this.editMode = false;
    this.productTypeIds = [];
    this.uomIds = [];
    this.assignmentForm = this._FormBuilder.group({
      allocatedDate: [''],
      comments: [''],
      fromDate: [''],
      partyId: [''],
      roleTypeId: [''],
      statusId: [''],
      thruDate: ['']
    });
    this.partyIds = [];
    this.roleTypeIds = [];
    this.statusIds = [];
  }
  financial = [{
    'value': 1
  }]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.fixedAssetId = this._ActivatedRoute.snapshot.queryParams.fixedAssetId;
    this.getFixAssetById();
    this.getAssetProducts();
    this.getFixedAssignments();
    this.getUomIdsData();
    this.getProducts();
    this.getProductTypeIds();
    this.editModeProduct=false;
    this.editAssigment=false;
    this.getRoleTypeIdsData();this.getPartyIdsData();this.getStatusIds();
  
    this.getInitialData();this.getProductIds(); this.getPartyIds();this.getFixAssetIds();this.getUomIds();
    this.getRoleTypeIds();this.getFacilityIds();
    this.createFixAssetForm.controls.locatedAtFacilityId.valueChanges.subscribe(data => {
      if (data) {
        this.getFacilityLocations(data);
      }
    });
  }
  getRoleTypeIdsData(): void {
    this.spinner.show();
    this._FinancialService.getRoleTypeIds('all')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.roleTypeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.roleTypeId
            };
          });
        }
      });
     
  }
  getPartyIdsData(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        }
      });
     
  }
  getStatusIds(): void {
    this.spinner.show();
    this._FinancialService.getInitialAssignmentData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.statusIds = data.data.status.map(value => {
            return {
              label: value.description,
              value: value.statusId
            };
          })
        }
      });
     
  }
  onUpdateAssignmentValue() {
    this.spinner.show();
    this._FinancialService.updateFixedAssetAssignment(this.fixedAssetId, {
      ...this.assignment, ...this.assignmentForm.value, ...{
        allocatedDate: this.assignmentForm.value.allocatedDate ? moment(this.assignmentForm.value.allocatedDate).format('YYYY-MM-DD HH:mm:ss') : '',
        fromDate: this.assignment.fromDate ? moment(this.assignment.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.assignmentForm.value.thruDate ? moment(this.assignmentForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.assignmentForm.controls.fromDate.disable();
          this.assignmentForm.controls.fromDate.updateValueAndValidity();
          this.assignmentForm.controls.partyId.disable();
          this.assignmentForm.controls.partyId.updateValueAndValidity();
          this.assignmentForm.controls.roleTypeId.disable();
          this.assignmentForm.controls.roleTypeId.updateValueAndValidity();
          this.closebuttonB.nativeElement.click();
          this.getFixedAssignments();
        }
      });
     
  }
  onSubmitAssignment() {
    this.spinner.show();
    this._FinancialService.createFixedAssetAssignment(this.fixedAssetId, {
      ...this.assignmentForm.value, ...{
        allocatedDate: this.assignmentForm.value.allocatedDate ? moment(this.assignmentForm.value.allocatedDate).format('YYYY-MM-DD HH:mm:ss') : '',
        fromDate: this.assignmentForm.value.fromDate ? moment(this.assignmentForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.assignmentForm.value.thruDate ? moment(this.assignmentForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          this.closebuttonB.nativeElement.click();
          this.getFixedAssignments();
        }
      });

  }
  getProducts(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, {
      internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains'
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productIds = data.data.map(value => {
            return {
              label: value.productId,
              value: value.productId
            };
          });
        }
      });
     
  }
  getProductTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getProductInitialData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productTypeIds = data.data.fixedAssetProductTypes.map(value => {
            return {
              label: value.description,
              value: value.fixedAssetProductTypeId
            };
          });
        }
      });
     
  }
  getUomIdsData(): void {
    this.spinner.show();
    this._AccountingApService.getTermType()
      .then(data => {
        this.spinner.hide();
        this.uomIds = data.data.uom.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
     
  }

  onUpdateProductData() {
    this.spinner.show();
    this._FinancialService.updateProduct(this.fixedAssetId, {
      ...this.productData, ...this.productForm.value, ...{
        fromDate: this.productData.fromDate ? moment(this.productData.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.productForm.value.thruDate ? moment(this.productForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.productForm.controls.productId.enable();
          this.productForm.controls.productId.updateValueAndValidity();
          this.productForm.controls.fromDate.enable();
          this.productForm.controls.fromDate.updateValueAndValidity();
          this.productForm.controls.quantityUomId.enable();
          this.productForm.controls.quantityUomId.updateValueAndValidity();
          this.productForm.controls.fixedAssetProductTypeId.enable();
          this.productForm.controls.fixedAssetProductTypeId.updateValueAndValidity();
          this.productForm.controls.quantity.enable();
          this.productForm.controls.quantity.updateValueAndValidity();
          this.productForm.controls.comments.enable();
          this.productForm.controls.comments.updateValueAndValidity();
          this.productForm.controls.sequenceNum.enable();
          this.productForm.controls.sequenceNum.updateValueAndValidity();
          this.closebuttonA.nativeElement.click();
          this.getAssetProducts();
        }
      });
     
  }
  onSubmitProduct() {
    this.spinner.show();
    this._FinancialService.createProduct(this.fixedAssetId, {
      ...this.productForm.value, ...{
        fromDate: this.productForm.value.fromDate ? moment(this.productForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.productForm.value.thruDate ? moment(this.productForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this._ToastrService.success('Created');
          this.closebuttonA.nativeElement.click();
          this.getAssetProducts();
        }
      });
   
  }
  edit(){
    if (this.fixedAssetId) {
      this._FinancialService.getFixAssetById(this.fixedAssetId)
        .then(data => {
          if (data.success) {
            this.fixAsset = data.data;
            this.createFixAssetForm.patchValue({
              ...data.data, ...{
                dateAcquired: data.data.dateAcquired ? moment(data.data.dateAcquired).format('YYYY-MM-DD') : '',
                dateLastServiced: data.data.dateLastServiced ? moment(data.data.dateLastServiced).format('YYYY-MM-DD') : '',
                dateNextService: data.data.dateNextService ? moment(data.data.dateNextService).format('YYYY-MM-DD') : '',
                actualEndOfLife: data.data.actualEndOfLife ? moment(data.data.actualEndOfLife).format('YYYY-MM-DD') : '',
                expectedEndOfLife: data.data.expectedEndOfLife ? moment(data.data.expectedEndOfLife).format('YYYY-MM-DD') : '',
              }
            });
            this.createFixAssetForm.controls.fixedAssetId.disable();
            this.createFixAssetForm.controls.fixedAssetId.updateValueAndValidity();this.editMode = true;
            this.getFacilityLocations(data.data.locatedAtFacilityId);
          }
        });
    }
  }
  getFacilityLocations(facilityId: string): void {
    this.spinner.show();
    this._WarehouseService.getFacilityLocations(facilityId, { pageNo: 1, pageSize: 200 }, {
      aisle: '',
      area: '',
      level: '',
      locationSeqId: '',
      position: '',
      section: ''
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.facilityLocation = data.data.map(value => {
            return {
              label: value.locationSeqId,
              value: value.locationSeqId
            };
          });
        }
      });
     
  }
  getFacilityIds(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilityId = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getUomIds(): void {
    this.spinner.show();
    this._AccountingApService.getTermType()
      .then(data => {
        this.spinner.hide();
        this.uomIds = data.data.uom.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
     
  }
  getRoleTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getRoleTypeIds('all')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.roleTypeIds = data.data.map(value => {
            return {
              label: value.description,
              value: value.roleTypeId
            };
          });
        }
      });
     
  }
  getFixAssetIds(): void {
    this.spinner.show();
    this._FinancialService.getAssets({ pageNo: 1, pageSize: 200 }, { assetType: '', fixedAssetId: '', fixedAssetIdSearchType: 'Contains', fixedAssetName: '', fixedAssetNameSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.fixAssetIds = data.data.map(value => {
            return {
              label: value.fixedAssetName,
              value: value.fixedAssetId
            }
          });
        }
      });
     
  }
  getInitialData(): void {
    this.spinner.show();
    this._FinancialService.getInitialAssetData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.calendar = data.data.calendar.map(value => {
            return {
              label: value.description,
              value: value.calendarId
            };
          });
          this.classEnumId = data.data.classEnumId.map(value => {
            return {
              label: value.description,
              value: value.enumId
            };
          });
          this.fixedAssetTypes = data.data.fixedAssetTypes.map(value => {
            return {
              label: value.description,
              value: value.fixedAssetTypeId
            };
          });
        }
      });
     
  }
  getProductIds(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productIds = data.data.map(value => {
            return {
              label: value.productId,
              value: value.productId
            };
          });
        }
      });
     
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        }
      });
     
  }
  onUpdate() {
    this.spinner.show();
    this._FinancialService.updateFixAsset({
      ...this.fixAsset, ...this.createFixAssetForm.value, ...{
        dateAcquired: this.createFixAssetForm.value.dateAcquired ? moment(this.createFixAssetForm.value.dateAcquired).format('YYYY-MM-DD HH:mm:ss') : '',
        dateLastServiced: this.createFixAssetForm.value.dateLastServiced ? moment(this.createFixAssetForm.value.dateLastServiced).format('YYYY-MM-DD HH:mm:ss') : '',
        dateNextService: this.createFixAssetForm.value.dateNextService ? moment(this.createFixAssetForm.value.dateNextService).format('YYYY-MM-DD HH:mm:ss') : '',
        actualEndOfLife: this.createFixAssetForm.value.actualEndOfLife ? moment(this.createFixAssetForm.value.actualEndOfLife).format('YYYY-MM-DD HH:mm:ss') : '',
        expectedEndOfLife: this.createFixAssetForm.value.expectedEndOfLife ? moment(this.createFixAssetForm.value.expectedEndOfLife).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.createFixAssetForm.controls.fixedAssetId.enable();
          this.createFixAssetForm.controls.fixedAssetId.updateValueAndValidity();
        
          this.closebutton.nativeElement.click();
          this.getFixAssetById();
        }
      });
 
  }

  cancelSubmit(){
    this.router.navigate(["/financial/fix-assest-list"])
  }  
  getFixAssetById(): void {
    this.spinner.show();
    this._FinancialService.getFixAssetById(this.fixedAssetId)
      .then(data => {
        if (data.success) {
          this.fixAsset = data.data;
          this.spinner.hide();
        }
      });
     
  }
  getAssetProducts(): void {
    this.spinner.show();
    this._FinancialService.getAssetProducts(this.fixedAssetId)
      .then(data => {
        if (data.success) {
          this.assetProducts = data.data;
          this.spinner.hide();
        }
      });
    
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  removeProduct(fixedAssetProductTypeId: string, fromDate: string, productId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._FinancialService.removeProduct({
          fixedAssetId: this.fixedAssetId,
          fixedAssetProductTypeId, fromDate: moment(new Date(fromDate).getTime()).format('YYYY-MM-DD HH:mm:ss'), productId
        })
          .then(data => {
            if (data.success) {
              this.getAssetProducts();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }})}
  onUpdateProduct(fixedAssetProductTypeId: string, fromDate: string, productId: string): void {
    this.router.navigate(["/financial/create-product"], { queryParams: { fixedAssetId: this.fixedAssetId, fixedAssetProductTypeId, fromDate: new Date(fromDate).getTime(), productId } });
  }
  createProduct() {
    this.router.navigate(["/financial/create-product"], { queryParams: { fixedAssetId: this.fixedAssetId } });
  }
  createAssign() {
    this.router.navigate(["/financial/create-fix-assignment"], { queryParams: { fixedAssetId: this.fixedAssetId } });
  }
  getFixedAssignments(): void {
    this.spinner.show();
    this._FinancialService.getAssetAssignments(this.fixedAssetId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.assetAssignments = data.data;
        }
      });
     
  }
  onUpdateAssignment(fromDate: string, partyId: string, roleTypeId: string): void {
    this.router.navigate(["/financial/create-fix-assignment"], { queryParams: { fixedAssetId: this.fixedAssetId, fromDate: new Date(fromDate).getTime(), partyId, roleTypeId } });
  }
  removeAssignment(fromDate: string, partyId: string, roleTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._FinancialService.removeAssignment({
          fixedAssetId: this.fixedAssetId, fromDate: fromDate ? moment(new Date(fromDate).getTime()).format('YYYY-MM-DD HH:mm:ss') : '', partyId, roleTypeId
        })
          .then(data => {
            if (data.success) { 
              this.spinner.hide();
              
              this.getFixedAssignments();
            } });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}}) }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
