import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class LeadPersonalServices {

  constructor(readonly http: HttpClient) { }

  updatePartyGroup(id,formData):Observable<any>{
  
    const partyGroupForm = URLS.updatePartyGroup.replace(":partyId",id);
   
      return this.http.put(partyGroupForm,formData);
  }
  getCrmStatus():Observable<any>{
    const getCrmStatus = URLS.getCRMStatus;
    return this.http.get(getCrmStatus);
  }
  getCurrency(): Observable<any> {
    const getCurrency = URLS.getCurrency;
    return this.http.get(getCurrency);
  }

 }


