<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"> Task

                </span>
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="projectMain()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="myTasks()">
                                <a>My Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="myTime()">
                                <a>My Time</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="projects()">
                                <a>Projects</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="tasks()">
                                <a>Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="resources()">
                                <a>Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="timesheet()">
                                <a>Timesheet</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="skillType()">
                                <a>Skill Type</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requestList()">
                                <a>Request List</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="">
                                        
                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords"> Find Task</span>
                                                           

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 86%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">Task
                                                                                ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [(ngModel)]="advanceSearch.TaskID" name="TaskID"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder=" Enter Task ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">Task
                                                                                Name
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                
                                                                            <input type="email" class="form-control"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [(ngModel)]="advanceSearch.TaskName"
                                                                                id="exampleInputEmail1" name="TaskName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Task Name">
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                                                              
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">Current
                                                                                Status ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [(ngModel)]="advanceSearch.CurrentStatusID"
                                                                                id="exampleInputEmail1" name="CurrentStatusID"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Current Status ID">
                
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">Party
                                                                                ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [options]="PartyIdArray"
                                                                                [(ngModel)]="advanceSearch.partyID"
                                                                                placeholder="Enter Party ID" name="partyID">
                                                                            </p-dropdown>
                
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">Project
                                                                                Name
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [(ngModel)]="advanceSearch.ProjectName"
                                                                                name="ProjectName" aria-describedby="emailHelp"
                                                                                placeholder="Enter Project Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">From Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" class="form-control"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [(ngModel)]="advanceSearch.fromDate" name="fromDate"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                placeholder="Enter From Date">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                             
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">To Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" class="form-control" name="toDate"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                [(ngModel)]="advanceSearch.toDate"
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                placeholder="Enter To Date">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12  main-submit-button" style="margin-left: -13%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                        (click)=" getFindTask()">Find</button>
                                                                    <button type="submit" class="btn btn-danger ml-2 "
                                                                        (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard w-100">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Find Task</p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightFormG">
                                                            <label for="exampleInputEmail1">Task
                                                                ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [(ngModel)]="advanceSearch.TaskID" name="TaskID"
                                                                aria-describedby="emailHelp"
                                                                placeholder=" Enter Task ID">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormH">
                                                            <label for="exampleInputEmail1">Task
                                                                Name
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <input type="email" class="form-control"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [(ngModel)]="advanceSearch.TaskName"
                                                                id="exampleInputEmail1" name="TaskName"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Task Name">
                                                        </div>
                                                    </div>
                                                </div>

                                              

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightFormG">
                                                            <label for="exampleInputEmail1">Current
                                                                Status ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [(ngModel)]="advanceSearch.CurrentStatusID"
                                                                id="exampleInputEmail1" name="CurrentStatusID"
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Current Status ID">

                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormH">
                                                            <label for="exampleInputEmail1">Party
                                                                ID
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <p-dropdown filter="true" optionlabel="label"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [options]="PartyIdArray"
                                                                [(ngModel)]="advanceSearch.partyID"
                                                                placeholder="Enter Party ID" name="partyID">
                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                              
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightFormG">
                                                            <label for="exampleInputEmail1">Project
                                                                Name
                                                            </label>
                                                        </div>
                                                        <div class="col-lg-2">

                                                            <input type="email" class="form-control"
                                                                id="exampleInputEmail1"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [(ngModel)]="advanceSearch.ProjectName"
                                                                name="ProjectName" aria-describedby="emailHelp"
                                                                placeholder="Enter Project Name">
                                                        </div>
                                                        <div class="col-lg-3 form-group rightFormH">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" class="form-control"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [(ngModel)]="advanceSearch.fromDate" name="fromDate"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                placeholder="Enter From Date">
                                                        </div>
                                                    </div>
                                                </div>
                                             
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightFormG">
                                                            <label for="exampleInputEmail1">To Date</label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" class="form-control" name="toDate"
                                                                [ngModelOptions]="{standalone:true}"
                                                                [(ngModel)]="advanceSearch.toDate"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                placeholder="Enter To Date">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12  main-submit-button" style="margin-left: -13%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)=" getFindTask()">Find</button>
                                                    <button type="submit" class="btn btn-danger ml-2 "
                                                        (click)="reset()">Reset</button>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    
                                    <div class="">
                                        <div class="w3-card-4 classCard w-100 mt-2 mb-2">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="FindTaskList" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>

                                                                                </p-checkbox>
                                                                                <span>Task ID </span>


                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Phase Name
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Task Name
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Current Status ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'120px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Priority
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Estimated Start Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Estimated Completion Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Actual Start Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Actual Completion Date
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Planned Hours
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Actual Hours
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>

                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"
                                                                                    (click)="detailPage(product.node1.workEffortId,product.node1.workEffortId,product.node4.description)">
                                                                                    {{product.node1.workEffortId}}
                                                                                </span>
                                                                            </div>
                                                                        </td>

                                                                        <td
                                                                            [ngStyle]="{'width':'170px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                            <span
                                                                              >
                                                                                </span>
                                                                        </td>
                                                                        <td
                                                                            [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                            <span     class="account-second-button"
                                                                             (click)="projectSummary(product.node1.workEffortId)">{{product.node1.workEffortName}}</span>
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <span
                                                                                >{{product.node4.description}}</span>

                                                                        </td>

                                                                        <td [ngStyle]="{'width':'120px'}">
                                                                            {{product.node1.priority}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.node1.estimatedStartDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.node1.estimatedCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.node1.actualStartDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.node1.actualCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                        </td>

                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>