import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {URLS} from '../../app.constant';

@Injectable({
  providedIn: 'root'
})
export class JobInterviewService {

  constructor(readonly http: HttpClient) { }
  getJobInterviewParameter():Observable<any>{
    const getInterviewParametre = URLS.JobInterviewParameters;
    return this.http.get(getInterviewParametre);
  }
  updateJobInterview(formData,id):Observable<any>{
    const updateJobInterview = URLS.updateJobInterview.replace(":jobInterviewId",id);
    return this.http.put(updateJobInterview,formData);
  }
  getInterviewById(id):Observable<any>{
    const interviewId = URLS.getJobInterviewById.replace(":jobInterviewId",id);
    return this.http.get(interviewId);
  }
  getJobInterviewList(data,job):Observable<any>{
    const jobInterview = URLS.getJobInterview + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo;
    return this.http.post(jobInterview,job);
  }
  createJobInterview(formData):Observable<any>{
    const createJobInterview = URLS.createJobInterview;
    return this.http.post(createJobInterview,formData);
  }
  deleteJobInterview(id):Observable<any>{
    const deleteInterviewJob = URLS.deleteInterviewJob.replace("jobInterviewId",id);
    return this.http.delete(deleteInterviewJob);
  }


 

}
