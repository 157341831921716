<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Party Invitation GroupAssoc
 
                </span>
                <span class="color-black pl-1" > >Add Party Invitation GroupAssoc
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Add Party Invitation GroupAssoc
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show" ><a>
                        Update Party Party Invitation GroupAssoc
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createGroup">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" *ngIf="!this.show">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    To Party ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="partyIdArray" filter="true"
                                                 formControlName="ToPartyID"
                                                placeholder="Enter To Party ID"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                          
                                            </div>
                                          
                                           
                                        </div>
                                      </div>
                                   
                                  
                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 18%;">
                               
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                 
                                <button type="submit" *ngIf="this.show" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>




