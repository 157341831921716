<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
          <span class="color-grey breadcrumbb" >  Add Permission (from list) to Security Group</span>
          <button type="submit"  (click)="cancel()"  class="btn btn-danger buttonclass">Cancel</button>
      </div>
        <div class=" bg-white color-grey create-new-table">
          <div class="w3-card-4 classCard" >
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass">
  
              <li [ngClass]="activeCategory==2?'active':''" ><a>
                Add Permission (from list) to Security Group</a></li>

            </ul>
          </div>
          <div>
            <div class="container-fluid">
              <div class="row">
                  <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">  
                      <div class="panel-group"  role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">
                         
                          <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections"> <div class="panel-body">
                              <div class="mt-2">
                                <div class="">
                                  <div class="row">
                                    <form class="w-100" [formGroup]="createGroup">
                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                          <div class="row">
                                              <div class="col-lg-3 form-group rightForm">
                                                <label class="common-labels">
                                                  Permission ID	<span
                                                  style="color:red">*</span></label>
                                              </div>
                                              <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Permission ID" formControlName="permissionId" filter="true" [options]="getAllPermisionDataArray" optionlabel="label">
                                                   
                                                </p-dropdown>
                                                <small *ngIf="createGroup.controls['permissionId'].invalid && (createGroup.controls['permissionId'].dirty || createGroup.controls['permissionId'].touched)">
                                                  <p class="validate-field" *ngIf="createGroup.controls['permissionId'].errors.required">
                                                      Permission ID is required!</p></small>
                                              </div>
                                              <div class="col-lg-3 form-group rightFormA">
                                                <label class="common-labels">From Date</label>
                                              </div>
                                              <div class="col-lg-2">
                                                <input type="date"  class="form-control" formControlName="fromDate"
                                                placeholder="Enter Name">
                                              </div>
                                          </div>
                                      </div>
                                   
                                      <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                              <label class="common-labels">Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                              <input type="date"  class="form-control" formControlName="thruDate"
                                              placeholder="Enter Description">
                                            </div>
                                            <div class="col-lg-2"></div>
                                           
                                          
                                        </div>
                                    </div>
                                        
                                      
                                      </div>
                                      <div class="submit-btn-sales main-submit-button" style="padding-right: 315px;margin-top:-8px">
                                        <button    type="submit" (click)="submit()" class="btn btn-secondary submit-btn">Submit</button>
                                        
                                       
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
  
                      </div>
                    </div>
                  </div>
  
            </div>
  
              </div>
            </div>
            </div>
          </div>
        </div>
      </div> -->
<div class="modal fade" id="listPermission" tabindex="-1" role="dialog" aria-labelledby="listModalCenterTitle"
    aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content cssModelLength  create-new-leade">
      <div class="modal-header content-head">
              
        <span class="color-black pl-1">Add Permission (from list) to Security Group</span>
        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" #closebutton>&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex">
        <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
            <div class="container-fluid">
              <ul class="tabbing-section tabbing-accordians tabClass">
                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Add Permission (from list) to Security Group</a>
                </li>
              </ul>
            </div>
            <div class="container-fluid">
              <div class="row">
                <div class="w-100  create-lead font-medium mb-0 text-white" style="border:0px;">
                  <div class="w-100">  
                    <div class="panel-group"  role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections"> <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100" [formGroup]="createGroup">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                      <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                          <label class="common-labels">
                                               Permission ID	<span
                                                  style="color:red">*</span>
                                          </label>
                                        </div>
                                        <div class="col-lg-3 custom-dropdown">
                                          <p-dropdown placeholder="Enter Permission ID" formControlName="permissionId" filter="true" [options]="getAllPermisionDataArray" optionlabel="label">
                                          </p-dropdown>
                                          <small *ngIf="createGroup.controls['permissionId'].invalid && (createGroup.controls['permissionId'].dirty || createGroup.controls['permissionId'].touched)">
                                            <p class="validate-field" *ngIf="createGroup.controls['permissionId'].errors.required">
                                                      Permission ID is required!</p></small>
                                        </div>
                                        <div class="col-lg-2 form-group rightFormA">
                                          <label class="common-labels">From Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                          <input type="date"  class="form-control" formControlName="fromDate"
                                            placeholder="Enter Name">
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                      <div class="row">
                                        <div class="col-lg-4 form-group rightForm">
                                          <label class="common-labels">Through Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                          <input type="date"  class="form-control" formControlName="thruDate"
                                              placeholder="Enter Description">
                                        </div>
                                        <div class="col-lg-2"></div>
                                      </div>
                                    </div>
                                  </div>
                                    <div class="submit-btn-sales main-submit-button" style="padding-right: 315px;margin-top:-8px">
                                      <button    type="submit" (click)="submit()" class="btn btn-secondary submit-btn">Submit</button>
                                          
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      <ngx-spinner></ngx-spinner> 
  