
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LeadPersonalModel } from './lead-personal-model';

@Injectable({
  providedIn: 'root'
})

export class LeadPersonalFormService {
  lead_personal_form: FormGroup;

  constructor(public _formBuilder: FormBuilder,
    public leadPersonalModel: LeadPersonalModel
  ) { }

  createEventForm(data: any): void {
    if (!this.lead_personal_form) {
      this.lead_personal_form = this._formBuilder.group(this.leadPersonalModel.mapDataToModel(data));
    }
  }

}







