import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  @ViewChild('closebutton')closebutton;
  @ViewChild('closebutton2')closebutton2;
  @ViewChild('closebutton3')closebutton3;
  @ViewChild('closebutton4')closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;

  // surveyTable:any;
  creatSurveyForm: FormGroup;
  surveyDataAll: any;
  yesNoArray: { label: string; value: string; }[];
  contentIdsArray: any[];
  findSurveyForm: FormGroup;
  surveyIdValue=true;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    readonly _CategoryService: CategoryService,
    readonly router: Router,
    private _location: Location,
  ) 
  {
    this.yesNoArray=[
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.findSurveyForm = this._FormBuilder.group({
      surveyID:"",
      surveyName:"",
      description:"",
      comments:"",
      submitCaption:"",
      responseService:"",
      yesNo:"",
      allowMultiple:"",
      allowUpdate:"",
      secureContentPrefix:"",
    });
    this.creatSurveyForm = this._FormBuilder.group({
      surveyID:"",
      surveyName:"",
      description:"",
      comments:"",
      submitCaption:"",
      responseService:"",
      yesNo:"",
      allowMultiple:"",
      allowUpdate:"",
    });
   }

  ngOnInit(): void {
    this.getContentId();
    this.findSurvey();

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  createSurvey(): void {
    this.spinner.show();
    const requestData ={
      "allowMultiple": this.creatSurveyForm.value.allowMultiple,
      "allowUpdate": this.creatSurveyForm.value.allowUpdate,
      "comments": this.creatSurveyForm.value.comments,
      "description": this.creatSurveyForm.value.description,
      "isAnonymous": this.creatSurveyForm.value.yesNo,
      "responseService": this.creatSurveyForm.value.responseService,
      "submitCaption": this.creatSurveyForm.value.submitCaption,
      "surveyId": '',
      "surveyName": this.creatSurveyForm.value.surveyName,
      }
     
    this.accountsService.createSurvey(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.findSurvey();
        this.closebutton2.nativeElement.click();
        this.creatSurveyForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  resetSurvey(){
    this.creatSurveyForm.reset();
    this.findSurveyForm.reset();
  }
  surveyDetailpage(id){
    this.router.navigate(['/content/survey/survey-detail-page'], { queryParams: { surveyId: id } });
  }
  getContentId(): void {
    this.spinner.show();
    this._CategoryService.getCatalogIdList()
        .then(data => {
            this.spinner.hide();
            if (data.success) {
                this.contentIdsArray = data.data.map(content => {
                    return {
                        label: content.contentId,
                        value: content.contentId
                    };
                });
            }
        })
       
}
  findSurvey(): void {
    this.spinner.show();
    const requestData ={
      "acroFormContentId": this.findSurveyForm.value.secureContentPrefix?this.findSurveyForm.value.secureContentPrefix:'',
      "allowMultiple": this.findSurveyForm.value.allowMultiple?this.findSurveyForm.value.allowMultiple:'',
      "allowUpdate": this.findSurveyForm.value.allowUpdate?this.findSurveyForm.value.allowUpdate:'',
      "comments": this.findSurveyForm.value.comments?this.findSurveyForm.value.comments:'',
      "description": this.findSurveyForm.value.description?this.findSurveyForm.value.description:'',
      "isAnonymous": this.findSurveyForm.value.yesNo?this.findSurveyForm.value.yesNo:'',
      "responseService": this.findSurveyForm.value.responseService?this.findSurveyForm.value.responseService:'',
      "submitCaption": this.findSurveyForm.value.submitCaption?this.findSurveyForm.value.submitCaption:'',
      "surveyId": this.findSurveyForm.value.surveyID?this.findSurveyForm.value.surveyID:'',
      "surveyName": this.findSurveyForm.value.surveyName?this.findSurveyForm.value.surveyName:'',
      }
    this.accountsService.findSurvey(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.surveyDataAll = res.data[0].surveyData;
        this.creatSurveyForm.reset();
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
