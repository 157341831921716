import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-main-company',
  templateUrl: './main-company.component.html',
  styleUrls: ['./main-company.component.css']
})
export class MainCompanyComponent implements OnInit {
  company: string;

  constructor(readonly router: Router,
    readonly spinner:NgxSpinnerService) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
  }
  companyOverView(){
    this.company='Company';
    this.router.navigate(["/hr/hrm-main"], { queryParams: { partyId: this.company } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
