<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">Product</span>
              
 
                <span class="">
 
                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>
 
                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>
       
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="mb-5">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="">
                            <div class="">
                                <form class="w-100">
                                    <div class="w3-card-4 classCardView mt-2 w-100">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            Edit Product Promotion Code
                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newpopup">New Product Promo</button>
                                        </div>
                                        <form class="w-100" [formGroup]="editPromo">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-5 form-group classInput">
                                                            <label for="exampleInputEmail1">View
                                                            <button (click)="getProductPromotionList()" type="button" class="btn btn-secondary submit-btn ml-2" >All</button>
                                                            <button (click)="findProductPromo()" type="button" class="btn btn-secondary submit-btn ml-2" >User Entered Only</button>
                                                            <button (click)="findProductPromoImport();" type="button" class="btn btn-secondary submit-btn ml-2" >Imported Only</button>
                                                        </label>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Product Promo Code ID
                                                        </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="text" class="form-control" formControlName="promoCodeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Promo Code ID">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 col-5 main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createPromo()">Edit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="w3-card-4 classCardView mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Product Promotions List
                                                </h4>
                                            </div>
                                        </header>

                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="AssignedTaskList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">

                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                            Product Promo ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Promo Name
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Promo Text
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Require Code
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Created Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}" (click)="detailPage(product.genericValue.productPromoId)">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span  class="account-button"  >
                                                                            {{product.genericValue.productPromoId}}
                                                                            </span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.genericValue.promoName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.genericValue.promoText}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.genericValue.requireCode}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.genericValue.createdDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>

                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                    <h4
                                                                        class="ml-2">
                                                                        No Record
                                                                        Found</h4>
                                                                </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newpopup" tabindex="-1" role="dialog" aria-labelledby="newpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Product Promo</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Product Promo</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newProductPromo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Promo Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="promoName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Promo Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Promo Text
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="promoText" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Promo Text">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">User Entered
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue" optionlabel="label" formControlName="userEntered" optionlabel="label" placeholder="Enter User Entered">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Promo Show to Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue" optionlabel="label" formControlName="promoShow" optionlabel="label" placeholder="Enter Promo Show to Customer">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Req. Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="arrayValue" optionlabel="label" formControlName="reqCode" optionlabel="label" placeholder="Enter Req. Code">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Use Limit Per Order
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="limitOrder" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Limit Per Order">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Limit Per Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="limitCust" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Limit Per Customer">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Use Limit Per Promotion
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="limitPromo" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Limit Per Promotion">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billback Factor
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="billbackFactor" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Billback Factor">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Override Org Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" formControlName="orgPartyID" optionlabel="label" placeholder="Enter Override Org Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="createdDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Created By User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="createdByUser" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created By User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="modifiedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Modified Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Last Modified By User
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="modifiedByUser" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Modified By User">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button" style="margin-left: 10% !important;">
                                            <button (click)="createProductPromo()" type="submit" class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
