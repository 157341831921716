<div class="container-fluid main-container-wrapper-new">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
        <span class="color-black pl-1 titlePanels">Financial Overview
        </span>
        <span class="d-flex">
          <!-- 
              <button type="submit" (click)="homeButton();"
                  class=" btn btn-outline-secondary mr-2">Home</button> -->

          <button type="submit" (click)="backWindow();" class="btn btn-danger buttonclass ">Back</button>
        </span>

      </div>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col-md-6 mb-5 pr-2">


      <div class=" mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Payable</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/financial/main-payable">
                      Payables Summary


                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/payable/invoices">
                      Enter Bills

                    </span>

                  </div>
                  <div class="article">

                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/payable/payment-group"
                      class="">
                      Make Group Payments

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/payable/payment-payables">
                      Make Payments


                    </span>


                  </div>




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Receivable
            </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/financial/main-receivable">
                      Receivable Summary

                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class=""
                      routerLink="/financial/receiveable/invoices">
                      Sales Invoice

                    </span>


                  </div>

                  <div class="article">

                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/receiveable/payments"
                      class="">
                      Receive Payments
                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/receiveable/payment-group">
                      Receive Group Payments
                    </span>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Payroll</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/main-find-paycheck">
                      Find Paycheck
                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/main-create-paycheck">
                      Create Paycheck

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/main-configuration"
                      class="">
                      Paycheck Configuration

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/employees-profile">
                      Employees
                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/contractor"
                      class="">
                      Contract Reference

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/main-reports">
                      Reports
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Transactions</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/authorize-transaction">
                      Authorize
                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/capture">
                      Capture
                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/payment-gateway-response"
                      class="">
                      Gateway Responses
                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/manual-electronic-transaction">
                      Manual Electronic Transaction
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Agreements </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/payable/customer-agreeement">
                      Show Commission Agreements



                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class=""
                      routerLink="/financial/receiveable/customer-agreeement">
                      Show Customer Agreements

                    </span>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Billing Accounts
            </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/payable/billing-account">
                      Show Customer Billing Account
                    </span>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     

      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Tax
            </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/financial/tax-authority">
                      Create Tax Authority


                    </span>


                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">General ledger Setting
            </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/global-gl-setting-list">
                      Charts of Account
                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/gl-custom-time-period-list">
                      Custom Time Period

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/global-gl-cost"
                      class="">
                      Cost
                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/gl-payment-method-type">
                      Payment Method Type

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/gl-invoice-type-list"
                      class="">
                      Invoice Item Type

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/global-gl-rate-list">
                      Rates

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/gl-foreign-exchange-rate"
                      class="">
                      Foreign Exchange Rates

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/gl-account-category-list">
                      Gl Account Category


                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/gl-cost-center"
                      class="">
                      Cost Center

                    </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="col-md-6" style="padding-left: 0;">
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Invoices</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/payable/commission-invoice">
                      Show Commission Invoices


                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/payable/invoices">
                      Show Purchase Invoice

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/payable/credit-memo"
                      class="">
                      Show Credit Memo

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/receiveable/invoices">
                      Show Sales Invoice

                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Payments</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/payable/payment-payables">
                      AP Payment


                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/payable/payment-group">
                      AP Payment Group

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/receiveable/payments"
                      class="">
                      AR Payment

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/receiveable/payment-group">
                      AR Payment Group

                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Accounting</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/main-accounting">
                      Main

                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/main-accounting">
                      Accounting
                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/main-accounting"
                      class="">
                      Invoices
                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/financial/main-accounting">
                      Payments
                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/main-accounting"
                      class="">
                      Payments Groups

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/financial/main-accounting">
                      Biling-Accounts

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/main-accounting"
                      class="">
                      Accounting Agreements

                    </span>
                    <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/financial/main-accounting">
                      Reports
                    </span>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Reports</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/payable/payables-report">
                      Payables Report

                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/receiveable/receive-reports">
                      Receivables Report

                    </span>
                  </div>
                  <div class="article">
                    <span style="cursor: pointer; color: #0000FF;" routerLink="/financial/ogcl-report-list"
                      class="">
                      Organisation Report

                    </span>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Budgets</p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/budget-list">
                      Find Budget

                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/budget-list">
                      Create New Budget

                    </span>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Bank Account
            </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/financial-account">
                      Show All Financial Account
                    </span>
                   
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Organizations GL Settings
            </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class=""
                      routerLink="/financial/organization-gl-setting">
                      Create New Accounting Company


                    </span>
                    <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/financial/organization-gl-setting">
                      Configure
                    </span>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="w3-card-4 classCard w-100" style="height: auto;">
          <div
            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
            <p class="h-text">Fixed Assets
            </p>
          </div>
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <div class="col-lg-12 col-12">
              <div class="form-group">
                <div class="card own-account-table" style="background-color: #fafafa !important;">
                  <div class="article">
                    <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/financial/fix-assest-list">
                      Show All Fixed Assets


                    </span>


                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>