import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.css']
})
export class InvoiceStatusComponent implements OnInit {

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  basicStatus:any;
  addNewBasicStatusForm:FormGroup;
  financeBasicInvoice: any;
  financeBasicInvoiceById: any;
  show: boolean;
  statusArray: { label: string; value: string; }[];
  addNewCustomStatusForm: FormGroup;
  showCustom: boolean;
  financeCustomInvoiceById: any;
  financeCustomInvoice: any;
 
  
  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.addNewBasicStatusForm = this._FormBuilder.group({
      displayAs:"",
      chooseStatus:"",
      description:"",
    });

    this.addNewCustomStatusForm = this._FormBuilder.group({
      displayAs:"",
      chooseStatus:"",
      description:"",
    });
    this.statusArray = [
      {
        label : 'Approved' ,
        value : 'Approved'
      },
      {
        label : 'Prepared' ,
        value : 'Prepared'
      },
      {
        label : 'Submitted' ,
        value : 'Submitted'
      },
      {
        label : 'Exported' ,
        value : 'Exported'
      },
      {
        label : 'Cancelled' ,
        value : 'Cancelled'
      },
      {
        label : 'Closed' ,
        value : 'Closed'
      },
      {
        label : 'Not Prepared' ,
        value : 'Not Prepared'
      },
     
    ]
  }

  ngOnInit(): void {
    this.show = false;
    this.showCustom = false;
    this.getFinanceBasicInvoice();
    this.getFinanceCustomInvoice();
  }
  resetCustom(){
    this.showCustom = false;
    this.addNewCustomStatusForm.reset();
  }
  getFinanceCustomInvoiceById(product) {
    this.showCustom = true;
    this.spinner.show();
    this.myContactsService.getFinanceCustomInvoiceById(product.financeCustomInvoiceId).subscribe(res => {
      this.financeCustomInvoiceById = res.data[0].getFinanceCustomInvoiceById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.addNewCustomStatusForm ;
        formValue.patchValue({
          displayAs: this.financeCustomInvoiceById.displayAs ,
      chooseStatus: this.financeCustomInvoiceById.status ,
      description: this.financeCustomInvoiceById.description ,
        })
      }, 2000);
    })
  }
  UpdateFinanceCustomInvoice() {
    this.spinner.show();
    const requestData = {
      "description": this.addNewCustomStatusForm.value.description ,
      "displayAs":this.addNewCustomStatusForm.value.displayAs ,
      "financeCustomInvoiceId": this.financeCustomInvoiceById.financeCustomInvoiceId ,
      "status": this.addNewCustomStatusForm.value.chooseStatus ,
    }
    this.myContactsService.UpdateFinanceCustomInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addNewBasicStatusForm.reset();
        this.getFinanceCustomInvoice();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createFinanceCustomInvoice() {
    this.spinner.show();
    const requestData = 
      {
          "description": this.addNewCustomStatusForm.value.description ,
          "displayAs":this.addNewCustomStatusForm.value.displayAs ,
          "financeCustomInvoiceId": "",
          "status": this.addNewCustomStatusForm.value.chooseStatus ,
      }
    this.myContactsService.createFinanceCustomInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addNewCustomStatusForm.reset();
        this.getFinanceCustomInvoice();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFinanceCustomInvoice() {
    this.spinner.show();
    this.myContactsService.getFinanceCustomInvoice().subscribe(res => {
      this.financeCustomInvoice = res.data[0].getFinanceCustomInvoice;
      this.spinner.hide();
    })
  }
  DeleteFinanceCustomInvoice(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.DeleteFinanceCustomInvoice(product.financeCustomInvoiceId).subscribe(res => {
          this.getFinanceCustomInvoice();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  reset(){
    this.show = false;
    this.addNewBasicStatusForm.reset();
  }
  getFinanceBasicInvoiceById(product) {
    this.show = true;
    this.spinner.show();
    this.myContactsService.getFinanceBasicInvoiceById(product.financeBasicInvoiceId).subscribe(res => {
      this.financeBasicInvoiceById = res.data[0].getFinanceBasicInvoiceById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.addNewBasicStatusForm ;
        formValue.patchValue({
          displayAs: this.financeBasicInvoiceById.displayAs ,
      chooseStatus: this.financeBasicInvoiceById.status ,
      description: this.financeBasicInvoiceById.description ,
        })
      }, 2000);
    })
  }
  updateFinanceBasicInvoice() {
    this.spinner.show();
    const requestData = {
      "description": this.addNewBasicStatusForm.value.description ,
      "displayAs":this.addNewBasicStatusForm.value.displayAs ,
      "financeBasicInvoiceId": this.financeBasicInvoiceById.financeBasicInvoiceId ,
      "status": this.addNewBasicStatusForm.value.chooseStatus ,
    }
    this.myContactsService.updateFinanceBasicInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addNewBasicStatusForm.reset();
        this.getFinanceBasicInvoice();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createFinanceBasicInvoice() {
    this.spinner.show();
    const requestData = 
      {
          "description": this.addNewBasicStatusForm.value.description ,
          "displayAs":this.addNewBasicStatusForm.value.displayAs ,
          "financeBasicInvoiceId": "",
          "status": this.addNewBasicStatusForm.value.chooseStatus ,
      }
    this.myContactsService.createFinanceBasicInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addNewBasicStatusForm.reset();
        this.getFinanceBasicInvoice();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFinanceBasicInvoice() {
    this.spinner.show();
    this.myContactsService.getFinanceBasicInvoice().subscribe(res => {
      this.financeBasicInvoice = res.data[0].getFinanceBasicInvoice;
      this.spinner.hide();
    })
  }
  deleteFinanceBasicInvoice(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.deleteFinanceBasicInvoice(product.financeBasicInvoiceId).subscribe(res => {
          this.getFinanceBasicInvoice();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
