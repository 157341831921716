
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Work Orders</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Work Orders List
                                            </h4>

                                            <button type="submit" (click)="toNewWorkOrder()" class="btn btn-secondary submit-btn mr-2">
                                                New Work Orders
                                            </button>
                                            
                                        </div>
                                    </header>
                                
                                        <div class="col-lg-12 ">
                                            <div class="row d-flex justify-content-between" style="margin-top: 1%;align-items: center;">
                                            
                                                    <div>
                                                        <form class="w-100" [formGroup]="workOrderFilter">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12" style="margin-right: 59px;">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <p-dropdown filter="true"
                                                                            [options]="workAppointmentArray"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Appointment Type"
                                                                            formControlName="workAppointment"
                                                                            (ngModelChange)="workOrderVal($event)">
                                                                        </p-dropdown>
                                                                            
                                                                        
                                                                        </div>  
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                        </form>
                                                    </div>
                                            </div>  
                                
                                        
                                        </div>
                                
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="FsmAssignmentFilter" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                            </p-checkbox>
                                
                                                                            Work Orders ID	 
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Customers Name
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Assigned Tech	</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Appointment</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Problem Id
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Address
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                
                                
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                            </p-checkbox>
                                                                            <span class="account-button"
                                                                            data-toggle="modal" data-target="#workOrderpopup" (click)="onUpdate(product.workOrderId)">
                                                                                {{product.workOrderId}}</span>
                                
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.customerName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.technicianName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.appointmentType}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.problemId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.address1}}
                                                                    </td>
                                                                    
                                
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">No
                                                                                Record Found
                                                                            </h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_data">
                                                            View per page
                                
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="modal fade" id="workOrderpopup" tabindex="-1" role="dialog" aria-labelledby="workOrderpopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Summary</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonLL>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Summary</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div *ngIf="this.assignmentByIdNew" class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Order ID :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                       {{assignmentByIdNew.workOrderId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Customer Id :</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        {{assignmentByIdNew.customerId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Customer Name :</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        {{assignmentByIdNew.customerName}}
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Technician Id :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.technicianId}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Name :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.technicianName}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Problem Id :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.problemId}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Problem Status :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.problemStatus}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Country Geo Id :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.countryGeoId}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">City :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.city}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">Due By :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.dueBy|date}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority :
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{assignmentByIdNew.priority}}

                                                     
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>