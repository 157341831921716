<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">


            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">


                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">


                                                        <div class="panel-group">

                                                            <div class="w3-card-4 classCardView mt-2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Edit AVS Override
                                                                    </h4>
                                                                </div>




                                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-2 form-group classInput">
                                                                                <label for="exampleInputEmail1">AVS String
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" name="AVSString" aria-describedby="emailHelp" placeholder="Enter AVS String">

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -72%;">
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                                                    </div>
                                                                    <br>
                                                                    <div class="col-lg-12  main-submit-button" style="margin-left: -87%;">
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Cancel/Done</button>&nbsp;
                                                                    </div>






                                                                </div>






                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>