<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Device Settings</span>

                    </div>                    

                    
                    <div class="col-lg-12">
                        <div class="row mt-3" style="margin-top: -7px !important; margin-bottom: -1% !important;margin-left: 0% !important; ">
                            <p>Use this page to configure settings used by all mobile clients in your system.</p>  

                            
                        </div>

                    </div>


                    <div class="row " style="width: 100%; margin-top: 2%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black; font-weight: bold !important;">
                                        GPS Settings </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="classInput">
                                                                <label>Update GPS fix every</label>

                                                            </div>  
                                                            <label class="">20</label> 
                                                            <label class="ml-2">minutes</label>  
                                                            <a class="ml-3" href="#">Edit</a>                                                       

                                                            

                                                        </div>

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 2%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black; font-weight: bold !important;">
                                        Polling Settings </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="classInput">
                                                                <label>Request data from server every</label>

                                                            </div>  
                                                            <label>15</label> 
                                                            <label class="ml-2">minutes</label>  
                                                            <a class="ml-3" href="#">Edit</a>                                                       

                                                            

                                                        </div>

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="row " style="width: 100%; margin-top: 2%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black; font-weight: bold !important;">
                                        Auto Start Settings </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="classInput">
                                                                <label>Automatically start application when device
                                                                    is restarted?
                                                                </label>

                                                            </div>  
                                                            <label>Enabled </label>  
                                                            <a class="ml-3" href="#">Disabled Autostart</a>                                                       

                                                            

                                                        </div>

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

            
                    

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        <div class="modal fade" id="TeamMemberPopup" tabindex="-1" role="dialog" aria-labelledby="TeamMemberPopup" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content cssModelLength">
                    <div class="modal-header">
                        <span class="color-black pl-1">Add Team Members</span>
                        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" #closebutton2>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex">
                        <div class=" bg-white color-grey w-100">
                            <div class="w3-card-4 classCardPopUp">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                        <li>
                                            <a>Add Team Members</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="addTeamMember">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">New Team Member</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="newTeamMember"  [options]="teamMemberArray"
                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                placeholder="Enter New Team Member">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Role</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" [options]="RoleArray" formControlName="role" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.show" (click)="addTeamMemberInviewTeam()">Create</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.show" (click)="updateTeamMemberInviewTeam()">Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!-- <ngx-spinner></ngx-spinner> -->






