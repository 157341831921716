<div class="container-fluid main-container-wrapper-new">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Dashboard</span>
               <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backSubmit()">Back</button>
            </div>
        </div>
    </div>
   
    <div class="row mb-5">
        <div class="col-md-6 pr-2">
           
            <div class=" mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 110px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">Business Intelligence</h4>
                        
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/business-intelligence/main" 
                                      >
                                        Business Intelligence Integrated Framework

                                        </span>
                 
                                      
                                 

                                    
                                    </div>
                                  

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          
            

           
          
        </div>
        <div class="col-md-6" style="padding-left: 0;">
            <div class="mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 110px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">Report Builder</h4>
                      
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" 
                                        routerLink="/business-intelligence/report-builder">
                                            Star Schema


                                        </span>
                                       
                                    </div>
                                 

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
           
         
          

          
        </div>

        <div class="col-md-6 mb-5  pr-2">
           
            <div class=" mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 110px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Crm
                        </p>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/marketing/reports-marketing" 
                                      >
                                        Reports

                                        </span>
                 
                                      
                                 

                                    
                                    </div>
                                  

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          
            

           
          
        </div>
        <div class="col-md-6" style="padding-left: 0;">
            <div class="mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 110px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Financial
                        </p>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" 
                                        routerLink="/financial/payable/payables-report">
                                        Financial Reports
                                        </span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    

<ngx-spinner></ngx-spinner>