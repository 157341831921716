import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-unposted-account-transaction',
  templateUrl: './unposted-account-transaction.component.html',
  styleUrls: ['./unposted-account-transaction.component.css']
})
export class UnpostedAccountTransactionComponent implements OnInit {
  financialList:any[]=[];
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  partyId: any;
  unPostedData: any;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
   
   
    readonly _FormBuilder: FormBuilder,

   
    readonly router: Router
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    });
    this.getUnPostedAcctgTransactions();
  }
  
  referToPayment(id){
    this.router.navigate(['/financial/payable/payment-payables/summary-payable-payment'],{ queryParams: { paymentId:id} });
  }
  referToSummary(id){
    this.router.navigate(['/financial/detail-accounting-summary'],{ queryParams: { partyId:id} });
  }
  getUnPostedAcctgTransactions() {
    this.spinner.show();
    
    this.accountsService.getUnPostedAcctgTransactions(this.finSize).subscribe(res => {
      this.unPostedData = res.data;
      this.spinner.hide();
    })
   
  }
  updateEditTransactin(id){
    this.router.navigate(['/financial/create-accounting-transaction'],{ queryParams: { partyId:  this.partyId,acctran:id } });
  }
  setup(){
    this.router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:this.partyId } });
  }
  accounting(){
    this.router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:this.partyId } });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
