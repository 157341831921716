import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';import { Location } from '@angular/common';


import Swal from 'sweetalert2'
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-detail-week-list',
  templateUrl: './detail-week-list.component.html',
  styleUrls: ['./detail-week-list.component.css']
})
export class DetailWeekListComponent implements OnInit {
  calendarWeekId: any;
  description: any;
  activeCategory=1;
  show: boolean;
  activeCategoryMain=2;
  products3: any;
  total=0;
  rowNumber=0;
  @ViewChild('closebutton') closebutton;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= "";
  createWeek: FormGroup;

  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly productionService:ProductionService,
private _location: Location,
readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly router: Router) {
      this.createWeek = this._FormBuilder.group({
        CalendarWeekID: [''],
        Description: [''],
        MondayStartTime: [''],
        MondayCapacity: [''],
        TuesdayStartTime: [''],
        TuesdayCapacity: [''],
        WednesdayStartTime: [''],
        WednesdayCapacity: [''],
        ThursdayStartTime: [''],
        ThursdayCapacity: [''],
        FridayStartTime: [''],
        FridayCapacity: [''],
        SaturdayStartTime: [''],
        SaturdayCapacity: [''],
        SundayStartTime: [''],
        SundayCapacity: [''],
  
      });
     }

  ngOnInit(): void {
  
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.calendarWeekId = params["calendarWeekId"];
      this.description = params["description"];
     
    });
    this.show=true;
  }
  getProdWeekById() {
    this.spinner.show();
    this.productionService.getProdWeekById(this.calendarWeekId).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.createWeek;
        formValue.patchValue({
          CalendarWeekID:res.data.calendarWeekId,
          Description: res.data.description,
          MondayStartTime: res.data.mondayStartTime,
          MondayCapacity: res.data.mondayCapacity,
          TuesdayStartTime: res.data.tuesdayStartTime,
          TuesdayCapacity: res.data.tuesdayCapacity,
          WednesdayStartTime: res.data.wednesdayStartTime,
          WednesdayCapacity: res.data.wednesdayCapacity,
          ThursdayStartTime: res.data.thursdayStartTime,
          ThursdayCapacity: res.data.thursdayCapacity,
          FridayStartTime: res.data.fridayStartTime,
          FridayCapacity: res.data.fridayCapacity,
          SaturdayStartTime: res.data.saturdayStartTime,
          SaturdayCapacity: res.data.saturdayCapacity,
          SundayStartTime: res.data.sundayStartTime,
          SundayCapacity: res.data.sundayCapacity,
        })
      
      }, 2000);
    })
  
  }

  onUpdate() {
    this.spinner.show();
    const requestData = {
      "calendarWeekId":  this.createWeek.value.CalendarWeekID,
      "description":  this.createWeek.value.Description,
      "fridayCapacity": this.createWeek.value.FridayCapacity,
      "fridayStartTime":  this.createWeek.value.FridayStartTime,
      "mondayCapacity":  this.createWeek.value.MondayCapacity,
      "mondayStartTime":  this.createWeek.value.MondayStartTime,
      "saturdayCapacity":  this.createWeek.value.SaturdayCapacity,
      "saturdayStartTime":  this.createWeek.value.SaturdayStartTime,
      "sundayCapacity":  this.createWeek.value.SundayCapacity,
      "sundayStartTime":  this.createWeek.value.SundayStartTime,
      "thursdayCapacity":  this.createWeek.value.ThursdayCapacity,
      "thursdayStartTime":  this.createWeek.value.ThursdayStartTime,
      "tuesdayCapacity":  this.createWeek.value.TuesdayCapacity,
      "tuesdayStartTime":  this.createWeek.value.TuesdayStartTime,
      "wednesdayCapacity":  this.createWeek.value.WednesdayCapacity,
      "wednesdayStartTime":  this.createWeek.value.WednesdayStartTime,
    }
    this.productionService.updateProdWeek(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this.router.navigate(['/production/week-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }  });}
      homeButton(){
        this.router.navigate(['/production/main-production'])
      
      }
      cancelSubmit(){
        this._location.back();
      }
}
