<div class="container-fluid main-container-wrapper" style="margin-top: 60px !important;">
    <!-- <div class="row">
        <div class="col-12"> -->
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Customer</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" >
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
>
                            <!-- <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategory(0)">
                                <a>Technician</a>
                            </li> -->
    
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Customer List</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Customer 
                                    
                                    </a>
                            </li>
                            <div class="col-lg-4 d-flex justify-content-between" style="white-space: nowrap;margin-top: 0.5%; ">

                                <button type="button" class="btn btn-secondary submit-btn" style="margin-left:auto!important;"   (click)="toNewWorkOrder()">New Work Orders</button>
                
                                <!-- <button type="button" class="btn btn-secondary submit-btn "  (click)="toNewWorkOrder()">New Work Orders</button> -->
                             
                            </div>
    
                         
                            
    
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid " style="padding: 0px 0px;">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                          
                                            
                                                <div class="w3-card-4 classCard w-100 " style="width: 98% !important; margin-left: 1% !important; margin-bottom: 1% !important;">
                                                    <div  class="w-100">
                
                                                        <div class="all-users-infomation font-13 font-medium own-user-select"
                                                            style="margin-top: 0%;">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="filterDataResut" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                            </p-checkbox>
                                                
                                                                                            Customer Id
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Customer Name
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Customer Type
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Billing Address
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            City	</div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Work Zone</div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                  
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                            
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                   
                                                
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span class="account-button"
                                                                                           
                                                                                            (click)="referToSummary(product.fsmCustomer.customerId)">
                                                                                                {{product.fsmCustomer.customerId}}</span>
                                                
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fsmCustomer.customerName}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fsmCustomer.customerType}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fsmCustomer.billingAddressSame}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fsmCustomer.city}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.fsmCustomer.workZone}}
                                                                                    </td>
                                                                                   
                                                                                    <td [ngStyle]="{'width':'180px'}">                                                                      
                                                                                        <!-- <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                                                          data-toggle="modal" data-target="#newTechnecianPopup">update</small> -->
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                                                             pointer; " (click)="deleteFsmCustomer(product)">Delete</small>
                                                                                       
                                                                                    </td>
                                                
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No
                                                                                                Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                
                                                    </div>
                
                                                 
                                                </div>
                                           
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100">
                                            <div class="w3-card-4 classCard1 w-100" style="height:430px ! important">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    
                
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2" 
                                                     data-toggle="modal" data-target="#newCustomerPopup" (click)="resetCustomer()">New
                                                        Customer</button>
                                                </div>
                
                                                <div class="">
                                                    <div class="">
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <form class="w-100">
                                                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                        <div class="w-100 d-flex">
                                                                            <div class="bg-white color-grey" style="width: 55%;">
                                                                                <div class="w3-card-4 classCard">
                                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                                                    style="background:#cce0e95e!important;">
                                                                                        <p class="h-text"
                                                                                            style="margin: 0px 15px -1px -2px !important;">
                                                                                            Customer</p>
                                                                                        
                
                                                                                    </div>
                
                                                                                    <form class=" w-100" [formGroup]="customerForm" style="margin-top: 28px;margin-bottom: 38px;">
                
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-2 classInput" style="margin-left: -2px;">
                                                                                                        <label>Customer Id</label>
                                                                                                </div>
                
                                                                                                <div class="col-lg-4" style="margin-left: -14px;">
                                                                                                    <input type="email" formControlName="customerId" class="form-control" id="exampleInputEmail1" 
                                                                                                    aria-describedby="emailHelp" placeholder="Enter Customer Id">
                           
                                                                                                </div>
                                                                                                <div class="col-lg-3 classInput">                                                                    
                                                                                                    <label>Customer Name</label>                                                                                                                                                                     
                                                                                                </div>
                
                                                                                                <div class="col-lg-3">
                                                                                                    <input type="email" formControlName="customerName" class="form-control" id="exampleInputEmail1" 
                                                                                                    aria-describedby="emailHelp" placeholder="Enter Customer Name">
                                                                                                   
                
                                                                                                </div>
                
                                                                                                <div class="mt-3 col-lg-12" style="margin-left: 69%;">
                                                                                                    <div >
                                                                                                        <button type="submit"
                                                                                                            class="btn btn-secondary submit-btn ml-2" (click)="findFsmCustomer()">
                                                                                                            Search
                                                                                                        </button>
                                                                                                        <button type="submit"
                                                                                                        class="btn btn-danger ml-2" (click)="reset()">
                                                                                                        Reset
                                                                                                    </button>
                                                                                                    </div>
                
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                
                                                                                        <!-- <div class="col-lg-12">
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-6">
                                                                                                    <label style="margin-left: 10%;">Duck
                                                                                                        Donuts</label>
                                                                                                </div>
                
                                                                                                <div class="col-lg-6">
                                                                                                    <a type="button" class="btn btn-link"
                                                                                                        style=" color: black; margin-left: 78%;">Edit</a>
                
                
                                                                                                </div>
                
                                                                                            </div>
                
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-12">
                                                                                                    <input type="radio">
                                                                                                </div>
                
                
                                                                                            </div>  
                
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-6">
                                                                                                    <label style="margin-left: 10%;">
                                                                                                        Michael Roberts (555) 5555
                                                                                                        555</label>
                                                                                                </div>
                
                                                                                                <div class="col-lg-6">
                                                                                                    <label>Default Work Zone</label>
                
                                                                                                </div>
                
                                                                                            </div>
                                                                                        </div> -->
                                                                                    </form>
                
                                                                                </div>
                
                                                                            </div>
                
                
                                                                            <div class="bg-white color-grey" style="width: 43%; margin-left: 14px;">
                                                                                <div class="w3-card-4 classCard w-100" style="height:347px ! important">
                                                                                    <div class="d-flex bd-highlight port-header-contact-import mt-0 create-lead font-medium mb-0 suppliers-wrapper"
                                                                                       style="background:#F5F7FA!important;">
                                                                                        <p class="h-text "
                                                                                            style="margin: 0px 15px -1px -2px !important;">
                                                                                            Details
                                                                                        </p>
                
                                                                                        
                
                                                                                    </div>
                                                                                    <form class="w-100" [formGroup]="customerDetailsForm">
                
                                                                                        <div class="col-lg-12 mt-3" *ngIf="showNoRecord">
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-5 noRecord">
                                                                                                    <label>No Record Found</label>
                                                                                                </div>
                                                                                            </div>
                
                                                                                        </div>
                                                                                            <div class="col-lg-12 mt-3">
                                                                                                <div class="row d-flex">
                                                                                                    <div class="col-lg-5 classInput">
                                                                                                        <label>Customer Id</label>
                                                                                                    </div>
                
                                                                                                    <div class="col-lg-5">
                                                                                                        <input type="text" class="form-control"
                                                                                                            formControlName="customerId" [readonly]="readOnlyCodeNew" placeholder="Select a Site">
                
                                                                                                    </div>
                
                                                                                                   
                
                                                                                                </div>
                
                                                                                            </div>
                
                                                                                            <div class="col-lg-12 mt-1">
                                                                                                <div class="row d-flex">
                                                                                                    <div class="col-lg-5 classInput">
                                                                                                        <label>Customer Name</label>
                                                                                                    </div>
                
                                                                                                    <div class="col-lg-5">
                                                                                                        <input
                                                                                                        type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                            formControlName="customerName" placeholder="Enter Name">
                
                                                                                                    </div>
                
                                                                                                
                
                                                                                                    
                
                                                                                                </div>
                
                                                                                            </div>
                
                                                                                            <div class="col-lg-12 mt-1">
                                                                                                <div class="row d-flex">
                                                                                                    <div class="col-lg-5 classInput">
                                                                                                        <label>Customer Email
                                                                                                        </label>
                                                                                                    </div>
                
                                                                                                    <div class="col-lg-5">
                                                                                                        <input
                                                                                                        type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                            formControlName="emailResidential" placeholder="Enter Email">
                
                                                                                                    </div>
                                                                                                
                
                                                                                                    
                
                                                                                                </div>
                
                                                                                            </div>
                
                                                                                            <div class="col-lg-12 mt-1">
                                                                                                <div class="row d-flex">
                                                                                                    <div class="col-lg-5 classInput">
                                                                                                        <label>City</label>
                                                                                                    </div>
                
                                                                                                    <div class="col-lg-5">
                                                                                                        <input
                                                                                                    
                                                                                                        type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                            formControlName="city"  placeholder="Enter City">
                
                                                                                                    </div>
                
                                                                                                 
                
                                                                                                </div>
                
                                                                                            </div>
                
                                                                                            <div class="col-lg-12 mt-1">
                                                                                                <div class="row d-flex">
                                                                                                    <div class="col-lg-5 classInput">
                                                                                                        <label>Address1</label>
                                                                                                    </div>
                
                                                                                                    <div class="col-lg-5">
                                                                                                        <input
                                                                                                        type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                            formControlName="address1"  placeholder="Enter Address1">
                
                                                                                                    </div>
                
                                                                                                   
                
                                                                                                </div>
                
                                                                                            </div>
                
                                                                                            
                
                                                                                               
                
                                                                                           
                                                                                    </form>
                
                
                                                                                    <!-- <div class="col-lg-2 main-submit-button" style="margin-left: 62%;">
                                                                                        <button  type="submit" class="btn btn-secondary submit-btn" (click)="updateFsmCustomerResidential()">Update</button>
                                                            
                                                                                    </div> -->
                                                                                    <div class="col-lg-2 main-submit-button" style="margin-left: 62%;">
                                                                                        <button  type="submit" class="btn btn-secondary submit-btn"  data-toggle="modal" data-target="#newCustomerPopup" (click)="updateFsmCustomerPop()">Edit</button>
                                                            
                                                                                    </div>
                
                                                                                </div>
                
                
                
                                                                            </div>
                
                                                                        </div>
                
                
                
                
                                                                    </div>
                
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                
                
                
                                            </div>
                
                                            <!-- <div class="col-lg-12 d-flex justify-content-end" style="margin-top: 2%;">
                
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                                    Cancel
                                                </button>
                
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                                    Previous
                                                </button>
                
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                                    Next
                                                </button>
                
                                            </div> -->
                
                                        </div>
                
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div>
    </div> -->
</div>

<!-- 
<div class="container-fluid main-container-wrapper" style="margin-top: 60px !important;">
   
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Customer</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" style="margin-left:0%!important;">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategory(0)">
                                <a>Customer List</a>
                            </li>
    
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Customer</a>
                            </li>

                            <div class="col-lg-4 d-flex justify-content-between" style="white-space: nowrap;margin-top: 0.5%; ">

                                <button type="button" class="btn btn-secondary submit-btn" style="margin-left:215% !important;"   (click)="toNewWorkOrder()">New Work Orders</button>
                
                               <button type="button" class="btn btn-secondary submit-btn "  (click)="toNewWorkOrder()">New Work Orders</button> 
                             
                            </div>
                          
                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==0" class="w-100">
                                        <div class="w3-card-4 classCard1 w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="filterDataResut" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </p-checkbox>
                                    
                                                                                Customer Id
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Customer Name
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Customer Type
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Billing Address
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                City	</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Work Zone</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                      
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action
                                                                
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                       
                                    
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span class="account-button"
                                                                               
                                                                                (click)="referToSummary(product.fsmCustomer.customerId)">
                                                                                    {{product.fsmCustomer.customerId}}</span>
                                    
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fsmCustomer.customerName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fsmCustomer.customerType}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fsmCustomer.billingAddressSame}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fsmCustomer.city}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.fsmCustomer.workZone}}
                                                                        </td>
                                                                       
                                                                        <td [ngStyle]="{'width':'180px'}">                                                                      
                                                                           <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                                              data-toggle="modal" data-target="#newTechnecianPopup">update</small> 
                                                                            <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                                                 pointer; " (click)="deleteFsmCustomer(product)">Delete</small>
                                                                           
                                                                        </td>
                                    
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page
                                    
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
            
            
            
                                   
                                        </div>
            
                                        
            
                                    </div>
                                   
                                    <div *ngIf="activeCategory==1" class="w-100">
                                        <div class="w3-card-4 classCard1 w-100" style="height:430px ! important">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mt-0 create-lead font-medium mb-0 suppliers-wrapper">
                                                
            
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" 
                                                 data-toggle="modal" data-target="#newCustomerPopup" (click)="resetCustomer()">New
                                                    Customer</button>
                                            </div>
            
                                            <div class="">
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                    <div class="w-100 d-flex">
                                                                        <div class="bg-white color-grey" style="width: 55%;">
                                                                            <div class="w3-card-4 classCard">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mt-0 create-lead font-medium mb-0 suppliers-wrapper"
                                                                                style="background:#F5F7FA!important;">
                                                                                    <p class="h-text color-black"
                                                                                        style="margin: 0px 15px -1px -2px !important;">
                                                                                        Customer</p>
                                                                                    
            
                                                                                </div>
            
                                                                                <form class=" w-100" [formGroup]="customerForm" style="margin-top: 28px;margin-bottom: 38px;">
            
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-2 classInput" style="margin-left: -2px;">
                                                                                                    <label>Customer Id</label>
                                                                                            </div>
            
                                                                                            <div class="col-lg-4" style="margin-left: -14px;">
                                                                                                <input type="email" formControlName="customerId" class="form-control" id="exampleInputEmail1" 
                                                                                                aria-describedby="emailHelp" placeholder="Enter Customer Id">
                       
                                                                                            </div>
                                                                                            <div class="col-lg-3 classInput">                                                                    
                                                                                                <label>Customer Name</label>                                                                                                                                                                     
                                                                                            </div>
            
                                                                                            <div class="col-lg-3">
                                                                                                <input type="email" formControlName="customerName" class="form-control" id="exampleInputEmail1" 
                                                                                                aria-describedby="emailHelp" placeholder="Enter Customer Name">
                                                                                               
            
                                                                                            </div>
            
                                                                                            <div class="mt-3 col-lg-12" style="margin-left: 69%;">
                                                                                                <div >
                                                                                                    <button type="submit"
                                                                                                        class="btn btn-secondary submit-btn ml-2" (click)="findFsmCustomer()">
                                                                                                        Search
                                                                                                    </button>
                                                                                                    <button type="submit"
                                                                                                    class="btn btn-danger ml-2" (click)="reset()">
                                                                                                    Reset
                                                                                                </button>
                                                                                                </div>
            
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
            
                                                                                     <div class="col-lg-12">
                                                                                        <div class="row d-flex">
                                                                                            <div class="col-lg-6">
                                                                                                <label style="margin-left: 10%;">Duck
                                                                                                    Donuts</label>
                                                                                            </div>
            
                                                                                            <div class="col-lg-6">
                                                                                                <a type="button" class="btn btn-link"
                                                                                                    style=" color: black; margin-left: 78%;">Edit</a>
            
            
                                                                                            </div>
            
                                                                                        </div>
            
                                                                                        <div class="row d-flex">
                                                                                            <div class="col-lg-12">
                                                                                                <input type="radio">
                                                                                            </div>
            
            
                                                                                        </div>  
            
                                                                                        <div class="row d-flex">
                                                                                            <div class="col-lg-6">
                                                                                                <label style="margin-left: 10%;">
                                                                                                    Michael Roberts (555) 5555
                                                                                                    555</label>
                                                                                            </div>
            
                                                                                            <div class="col-lg-6">
                                                                                                <label>Default Work Zone</label>
            
                                                                                            </div>
            
                                                                                        </div>
                                                                                    </div> 
                                                                                </form>
            
                                                                            </div>
            
                                                                        </div>
            
            
                                                                        <div class="bg-white color-grey" style="width: 43%; margin-left: 14px;">
                                                                            <div class="w3-card-4 classCard w-100" style="height:347px ! important">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mt-0 create-lead font-medium mb-0 suppliers-wrapper"
                                                                                style="background:#F5F7FA!important;">
                                                                                    <p class="h-text color-black"
                                                                                        style="margin: 0px 15px -1px -2px !important;">
                                                                                        Details
                                                                                    </p>
            
            
                                                                                </div>
                                                                                <form class="w-100" [formGroup]="customerDetailsForm">
            
                                                                                    <div class="col-lg-12 mt-3" *ngIf="showNoRecord">
                                                                                        <div class="row d-flex">
                                                                                            <div class="col-lg-5 noRecord">
                                                                                                <label>No Record Found</label>
                                                                                            </div>
                                                                                        </div>
            
                                                                                    </div>
                                                                                        <div class="col-lg-12 mt-3">
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-5 classInput">
                                                                                                    <label>Customer Id</label>
                                                                                                </div>
            
                                                                                                <div class="col-lg-5">
                                                                                                    <input type="text" class="form-control"
                                                                                                        formControlName="customerId" [readonly]="readOnlyCodeNew" placeholder="Select a Site">
            
                                                                                                </div>
            
                                                                                               
            
                                                                                            </div>
            
                                                                                        </div>
            
                                                                                        <div class="col-lg-12 mt-1">
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-5 classInput">
                                                                                                    <label>Customer Name</label>
                                                                                                </div>
            
                                                                                                <div class="col-lg-5">
                                                                                                    <input
                                                                                                    type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                        formControlName="customerName" placeholder="Enter Name">
            
                                                                                                </div>
            
                                                                                            
            
                                                                                                
            
                                                                                            </div>
            
                                                                                        </div>
            
                                                                                        <div class="col-lg-12 mt-1">
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-5 classInput">
                                                                                                    <label>Customer Email
                                                                                                    </label>
                                                                                                </div>
            
                                                                                                <div class="col-lg-5">
                                                                                                    <input
                                                                                                    type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                        formControlName="emailResidential" placeholder="Enter Email">
            
                                                                                                </div>
                                                                                            
            
                                                                                                
            
                                                                                            </div>
            
                                                                                        </div>
            
                                                                                        <div class="col-lg-12 mt-1">
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-5 classInput">
                                                                                                    <label>City</label>
                                                                                                </div>
            
                                                                                                <div class="col-lg-5">
                                                                                                    <input
                                                                                                
                                                                                                    type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                        formControlName="city"  placeholder="Enter City">
            
                                                                                                </div>
            
                                                                                             
            
                                                                                            </div>
            
                                                                                        </div>
            
                                                                                        <div class="col-lg-12 mt-1">
                                                                                            <div class="row d-flex">
                                                                                                <div class="col-lg-5 classInput">
                                                                                                    <label>Address1</label>
                                                                                                </div>
            
                                                                                                <div class="col-lg-5">
                                                                                                    <input
                                                                                                    type="text" [readonly]="readOnlyCodeNew" class="form-control"
                                                                                                        formControlName="address1"  placeholder="Enter Address1">
            
                                                                                                </div>
            
                                                                                               
            
                                                                                            </div>
            
                                                                                        </div>
            
                                                                                        
            
                                                                                           
            
                                                                                       
                                                                                </form>
            
            
                                                                                <div class="col-lg-2 main-submit-button" style="margin-left: 62%;">
                                                                                    <button  type="submit" class="btn btn-secondary submit-btn" (click)="updateFsmCustomerResidential()">Update</button>
                                                        
                                                                                </div> 
                                                                                <div class="col-lg-2 main-submit-button" style="margin-left: 62%;">
                                                                                    <button  type="submit" class="btn btn-secondary submit-btn"   data-toggle="modal" data-target="#newCustomerPopup" (click)="updateFsmCustomerPop()">Edit</button>
                                                        
                                                                                </div>
            
                                                                            </div>
            
            
            
                                                                        </div>
            
                                                                    </div>
            
            
            
            
                                                                </div>
            
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
            
            
            
                                        </div>
            
                                        <div class="col-lg-12 d-flex justify-content-end" style="margin-top: 2%;">
            
                                            <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                                Cancel
                                            </button>
            
                                            <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                                Previous
                                            </button>
            
                                            <button type="submit" class="btn btn-secondary submit-btn ml-2">
                                                Next
                                            </button>
            
                                        </div> 
            
                                    </div> 

                                       
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div> -->



<div class="modal fade" id="newCustomerPopup1" tabindex="-1" role="dialog" aria-labelledby="newCustomerPopup1" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">New Customer</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>New Customer</a>
                                </li>
                            </ul>
                            
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="customerCommercialDetailsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                           

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Customer Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="FMS_CUSTYPEArray"
                                                        optionlabel="label"
                                                        placeholder="Select Customer Type"
                                                        formControlName="customerType">
                                                    </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Customer Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="customerName" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Customer Name">
                                                       
                                                    </div>

                                                    

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Display Customer As</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="displayCustomerAs" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" >
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Tax</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="tax" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Tax">
                                                       
                                                    </div>

                                                    
                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Tax Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="taxCode" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Tax Code">
                                                       
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Job Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="jobName" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Job Name">
                                                       
                                                    </div>

                                                    
                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Service Package</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="servicePackage" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Service Package">
                                                       
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Address 1</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="address1" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Address 1">
                                                       
                                                    </div>

                                                    
                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Address 2</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="address2" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Address 2">
                                                       
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">City</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="city" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter City">
                                                        
                                                    </div>

                                                    
                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">State Province Geo ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="FSMStateProvIdArray"
                                                        optionlabel="label"
                                                        placeholder="Enter State Provice Geo ID"
                                                        formControlName="stateProvinceGeoId">
                                                    </p-dropdown>
                                                      
                                                       
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Country Geo ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="FSMCountryGeoIdArray"
                                                        optionlabel="label"
                                                        placeholder="Enter Country Geo ID"
                                                        formControlName="countryGeoIdNew">
                                                    </p-dropdown>
                                                        
                                                       
                                                    </div>


                                                   

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Postal Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="postalCode" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Postal Code">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Work Zone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="workZone" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Work Zone">
                                                       
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Job Tax</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="jobTax" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Job Tax">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Job Tax Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="jobTaxCode" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Job Tax Code">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Primary Contact First Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryContactFirstName" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Contact First Name">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Primary Contact Last Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryContactLastName" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Contact Last Name">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Primary Contact Display As</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryContactDisplayAs" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Contact Display As">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Primary Contact Primary Phone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryContactPrimaryPhone" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Contact Primary Phone">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Primary Contact Alternate Primary Phone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryContactAlternatePrimaryPhone" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Contact Alternate Primary Phone">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Primary Contact Fax</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryContactFax" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Contact Fax">
                                                       
                                                    </div>

                                                </div>

                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Primary Contact Email</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryContactEmail" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Contact Email">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Billing Address Invoicalbe PO</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="billingAddressInvoiceAblePO" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Billing Address Invoicalbe PO">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Billing Address Same</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="billingAddressSame" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Billing Address Same">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Additional Information Test</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="additionalInformationTest" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Additional Information Test">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Additional Information Notes</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="additionalInformationNotes" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Additional Information Notes">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Additional Information Source </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="additionalInformationSource" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Additional Information Source">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Additional Information Make</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="additionalInformationMake" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Additional Information Make">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Additional Information Reference </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="additionalInformationReference" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Additional Information Reference">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                   

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Additional Information Colour</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="additionalInformationColour" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Additional Information Colour">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">First Name Resedential</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="firstNameResidential" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter First Name Resedential">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                   

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Last Name Resedential</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="lastNameResidential" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Last Name Resedential">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Primary Phone Resedential</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="primaryPhoneResidential" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Primary Phone Resedential">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                   

                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Alternate Primary Phone Resedential</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="alternatePrimaryPhoneResidential" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Alternate Primary Phone Resedential">
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Fax Resedential</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="faxResidential" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Fax Resedential">
                                                       
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                   
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Enter Email Resedential</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"formControlName="emailResidential" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Email Resedential">
                                                       
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Current Password</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="currentPassword" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                       
                                                    </div>


                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 34%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createFsmCustomerResidential()">Add</button>
                                                <!-- <button type="submit" class="btn btn-secondary submit-btn">Cancel</button> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 


<div class="modal fade" id="updateProblemPopup" tabindex="-1" role="dialog" aria-labelledby="newTechnecianPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                
                <span class="color-black pl-1">Update </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                
                                <li >
                                    <a>Update</a>
                                </li>
                            </ul>
                            
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                   
                                    <form class="w-100" [formGroup]="editProbForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Category</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                            <p-dropdown filter="true"
                                                            [options]="ExpertiseAreaAllArray"
                                                            optionlabel="label"
                                                            placeholder="category"
                                                            formControlName="category">
                                                            </p-dropdown>                                             
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Problem Status</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="problemStatusArray"
                                                        optionlabel="label"
                                                        placeholder="problemStatus"
                                                        formControlName="problemStatus">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="priorityArray"
                                                        optionlabel="label"
                                                        placeholder="priority"
                                                        formControlName="priority">
                                                        </p-dropdown>
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Payment Mode</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="paymentProcessSettingArray"
                                                        optionlabel="label"
                                                        placeholder="paymentMode"
                                                        formControlName="paymentMode">
                                                        </p-dropdown>

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <textarea id="" cols="10" rows="5" formControlName="dec" class="form-control"></textarea>
                                                       
                                                    </div>

       

                                                    
                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 50%;">
                                                <button  type="submit" class="btn btn-secondary submit-btn" (click)="updateFsmProblem()">Update</button>
                    
                                            </div>
                                        </div>
                                    </form>

                                   
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updateAssignmentPopup" tabindex="-1" role="dialog" aria-labelledby="newTechnecianPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                
                <span class="color-black pl-1">Update</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                
                                <li >
                                    <a>Update</a>
                                </li>
                            </ul>
                            
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                   
                                    <form class="w-100" [formGroup]="editAssignmentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Appointment Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="AppointmentTypeArray"
                                                            optionlabel="label"
                                                            placeholder="Scheduled" 
                                                            formControlName="appointmentType">
                                                            
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Arrival From</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="promisedArrivalFrom" class="form-control">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Arrival To</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="promisedArrivalTo" class="form-control">

                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="FsmPrioritiesIdArray"
                                                        optionlabel="label"
                                                        placeholder="Non-Urgent" 
                                                        formControlName="priority">
                                                        </p-dropdown>

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Skill</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" 
                                                        optionlabel="label"
                                                        [options]="FsmSkillIdArray"
                                                        placeholder="Add skill"
                                                        formControlName="skill">
                                                        </p-dropdown>
                                                       
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Technician Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" 
                                                        optionlabel="label"
                                                        [options]="ExpertiseAreaByTechnicianIdArray"
                                                        placeholder="technicianName"
                                                        formControlName="technicianName">
                                                        </p-dropdown>

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">ETA</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="date" class="form-control" formControlName="ETA">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Due By</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="DueBy" class="form-control">

                                                       
                                                    </div>

                                                    

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Estimated Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="estimatedDuration">

                                                       
                                                    </div>
                                                    
                                                    

                                                    
                                                </div>
                                            </div>


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 50%;">
                                                <button  type="submit" class="btn btn-secondary submit-btn" (click)="updateFsmAssignment()">Update</button>
                    
                                            </div>
                                        </div>
                                    </form>

                                   
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="newTechnecianPopup" tabindex="-1" role="dialog" aria-labelledby="newTechnecianPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                 <span *ngIf="!this.show" class="color-black pl-1"> Create New Technician
                </span> 
                <span *ngIf="!this.show" class="color-black pl-1"> Update New Technician</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryPopup==1?'active':''" >
                                    <a>
                                        General Information</a>
                                </li>
                                <li [ngClass]="activeCategoryPopup==2?'active':''" >
                                    <a>
                                        Address</a>
                                </li>

                                <li [ngClass]="activeCategoryPopup==3?'active':''" ><a>
                                        Information </a></li>
                                



                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="addNewTechnicianForm" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div *ngIf="activeCategoryPopup==1">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                General Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Technician No</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"  formControlName="technicianNo" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Technician No">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Technician Type</label>
                                                                        </div>
                    
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="FMS_TECHNICIANArray"
                                                                            optionlabel="label"
                                                                            placeholder="Select Technician Type"
                                                                            formControlName="technicianType">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Technician Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="technicianName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Technician Name">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Technician Availability</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="technicianAvailabilityArray"
                                                                            optionlabel="label"
                                                                            placeholder="Technician Availability"
                                                                            formControlName="technicianAvailability">
                                                                            </p-dropdown>                                                                                                
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">First Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="firstName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Last Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="lastName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Email
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="email" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Email ">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Social Security Number</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input
                                                                            (change)="fileProgress($event)"
                                                                            type="file"
                                                                            class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            formControlName="socialSecurityNumber"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Social Security Number">
                                                                            
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Company Registration</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="companyRegistration" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Company Registration">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Expertise Area</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="ExpertiseAreaAllArray"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Expertise Area"
                                                                            formControlName="expertiseArea">
                                                                            </p-dropdown>   
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Add ExpertiseArea</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="addExpertiseArea" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="addExpertiseArea">
                                                                            <button class="mt-2 btn btn-secondary submit-btn" (click)="createFsmExpertiseArea()">Add</button>
                                                                           
                                                                        </div>
                    
                                                                        
                    
                                                                        
                                                                    </div>
                                                                </div>

                                                                


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 18%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="changeactiveCategoryPopup(2)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategoryPopup==2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Address
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                               
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Address 1</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="address1" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Address 1">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Address 2</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="address2" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Address 2">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">City</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="city" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter City">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Country Geo ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="FSMCountryGeoIdArray"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Country Geo ID"
                                                                            formControlName="countryGeoId">
                                                                            </p-dropdown>
                                                                           
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                    
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">State Province Geo ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="FSMStateProvIdArray"
                                                                            optionlabel="label"
                                                                            placeholder="Enter State Province Geo ID"
                                                                            formControlName="stateProvinceGeoId">
                                                                            </p-dropdown>
                                                                
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Postal Code</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="postalCode" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Postal Code">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right:18%;">
                                                                <button type="submit" (click)="changeactiveCategoryPopup(1)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="changeactiveCategoryPopup(3)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="activeCategoryPopup==3">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Current Password</label>
                                                                        </div>
                    
                                                                        <div class="col-lg-2">  
                                                                            <input type="email" formControlName="currentPassword" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                                        
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Technician Status</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="this.technicianStatusArray"
                                                                            optionlabel="label"
                                                                            placeholder="technicianStatus"
                                                                            formControlName="technicianStatus">
                                                                            </p-dropdown>
                                                                           
                                                                           
                                                                        </div>
                    
                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBB">
                                                                            <label for="exampleInputEmail1">Tech Status Desc.</label>
                                                                        </div>
                    
                                                                        <div class="col-lg-2">  
                                                                            <input type="text" formControlName="technicianStatusDescription" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="technicianStatusDescription">
                                                                        
                                                                        </div>
                                                                        
                    
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 17%;">
                                                                <button type="submit" (click)="changeactiveCategoryPopup(2)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button *ngIf="this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="createTechnicianRegister()">Create</button>
                                                                <button *ngIf="!this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="updateTechnician()">Update</button>


                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                </div>
                                            </div>
                                        </div>








                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="newCustomerPopup" tabindex="-1" role="dialog" aria-labelledby="newCustomerPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.show" class="color-black pl-1">New Customer
                </span> 
                <span *ngIf="!this.show" class="color-black pl-1"> Update Customer</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="customerCategoryPopup==1?'active':''">
                                    <a>
                                        General Information</a>
                                </li>
                                <li [ngClass]="customerCategoryPopup==2?'active':''">
                                    <a>
                                        Address</a>
                                </li>

                             

                                <li [ngClass]="customerCategoryPopup==3?'active':''"><a>
                                    Primary Contact Details </a></li>

                                <li [ngClass]="customerCategoryPopup==4?'active':''"><a>
                                    Additional Information </a></li>
                            
                                <li [ngClass]="customerCategoryPopup==5?'active':''" ><a>
                                    Resedential Details </a></li>
                                



                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="customerCommercialDetailsForm" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div *ngIf="customerCategoryPopup==1">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                General Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Customer Type</label>
                                                                        </div>
                    
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="FMS_CUSTYPEArray"
                                                                            optionlabel="label"
                                                                            placeholder="Select Customer Type"
                                                                            formControlName="customerType">
                                                                        </p-dropdown>
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Customer Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="customerName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Customer Name">
                                                                           
                                                                        </div>
                    
                                                                        
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Display Customer As</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="displayCustomerAs" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Display Customer As">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Tax</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="tax" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Tax">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Tax Code</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="taxCode" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Tax Code">
                                                                           
                                                                        </div>
                    
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Job Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="jobName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Job Name">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                        
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Service Package</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="servicePackage" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Service Package">
                                                                           
                                                                        </div>
                    
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Address 1</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="address1" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Address 1">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                        
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Job Tax</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="jobTax" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Job Tax">
                                                                        
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Job Tax Code</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="jobTaxCode" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Job Tax Code">
                                                                        
                                                                        </div>

                                                    
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Work Zone</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="workZone" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Work Zone">
                                                                           
                                                                        </div>

                                                                        
                                                                    </div>
                                                                </div>

                                                                


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 11%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(2)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="customerCategoryPopup==2">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Address
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                               
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Address 1</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="address1" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Address 1">
                                                                           
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Address 2</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="address2" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Address 2">
                                                                           
                                                                        </div>
                                                                        
                    
                    
                                                                        
                    
                                                                        
                                                                        
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                    
                                                                        
                    
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">City</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="city" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter City">
                                                                            
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">State Province Geo ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="FSMStateProvIdArray"
                                                                            optionlabel="label"
                                                                            placeholder="Enter State Provice Geo ID"
                                                                            formControlName="stateProvinceGeoId">
                                                                        </p-dropdown>
                                                                          
                                                                           
                                                                        </div>
                    
                                                                        
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Country Geo ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="FSMCountryGeoIdArray"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Country Geo ID"
                                                                            formControlName="countryGeoIdNew">
                                                                        </p-dropdown>
                                                                            
                                                                           
                                                                        </div>  

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Postal Code</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="postalCode" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Postal Code">
                                                                           
                                                                        </div>
                    
                    
                                                                        
                    
                    
                                                                       
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right:11%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(1)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(3)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                    <div *ngIf="customerCategoryPopup==3">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Primary Contact Details
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Primary Contact First Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryContactFirstName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Contact First Name">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Primary Contact Last Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryContactLastName" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Contact Last Name">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Primary Contact Display As</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryContactDisplayAs" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Contact Display As">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Primary Contact Primary Phone</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryContactPrimaryPhone" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Contact Primary Phone">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Primary Contact Alternate Primary Phone</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryContactAlternatePrimaryPhone" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Contact Alternate Primary Phone">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Primary Contact Fax</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryContactFax" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Contact Fax">
                                                                           
                                                                        </div>
                    
                                                                    </div>
                    
                                                                </div>
                                                                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Primary Contact Email</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryContactEmail" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Contact Email">
                                                                           
                                                                        </div>
                    
                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Billing Address Invoicalbe PO</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="billingAddressInvoiceAblePO" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Billing Address Invoicalbe PO">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Billing Address Same</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="billingAddressSame" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Billing Address Same">
                                                                           
                                                                        </div>
                    
                                                                      
                    
                                                                        
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 11%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(2)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(4)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div *ngIf="customerCategoryPopup==4">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Additional Information
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        
                    
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Additional Information Test</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="additionalInformationTest" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Additional Information Test">
                                                                           
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Additional Information Notes</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="additionalInformationNotes" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Additional Information Notes">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        
                    
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Additional Information Source </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="additionalInformationSource" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Additional Information Source">
                                                                           
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Additional Information Make</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="additionalInformationMake" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Additional Information Make">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        
                    
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Additional Information Reference </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="additionalInformationReference" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Additional Information Reference">
                                                                           
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Additional Information Colour</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="additionalInformationColour" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Additional Information Colour">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 11%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(3)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(5)">Continue</button>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div *ngIf="customerCategoryPopup==5">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Resedential Details
                                                            </h4>


                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                
                    
                                                                
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                       
                    
                                                                       
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">First Name Resedential</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="firstNameResidential" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter First Name Resedential">
                                                                           
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Last Name Resedential</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="lastNameResidential" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Last Name Resedential">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                       
                    
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Primary Phone Resedential</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="primaryPhoneResidential" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Primary Phone Resedential">
                                                                           
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Alternate Primary Phone Resedential</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="alternatePrimaryPhoneResidential" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Alternate Primary Phone Resedential">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                       
                    
                                                                        
                    
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Fax Resedential</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="faxResidential" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Fax Resedential">
                                                                           
                                                                        </div>

                                                                        <div class="col-lg-3 form-group rightFormC">
                                                                            <label for="exampleInputEmail1">Enter Email Resedential</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"formControlName="emailResidential" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Email Resedential">
                                                                           
                                                                        </div>
                    
                                                                        
                                                                    </div>
                                                                </div>
                    
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                       
                                                                        
                                                                        <div class="col-lg-3 form-group rightFormBBB">
                                                                            <label for="exampleInputEmail1">Current Password</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"  formControlName="currentPassword" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                                           
                                                                        </div>
                    
                    
                                                                        
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 11%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(4)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button *ngIf="this.show" type="submit"  class="btn btn-secondary submit-btn" (click)="createFsmCustomerResidential()">Create</button>
                                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateFsmCustomerResidential()">Update</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                </div>
                                            </div>
                                        </div>








                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey titlePanels">Customer</span>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Customer List
                                            </h4>

                                            <button type="submit" (click)="toNewWorkOrder()" class="btn btn-secondary submit-btn mr-2">
                                                New Work Orders
                                             </button>
                                            
                                        </div>
                                    </header>
                                
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="filterDataResut" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                             
                                                                            </p-checkbox>
                                
                                                                            Customer Id
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Customer Name
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Customer Type
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Billing Address
                                                                        </div>
                                                                        <div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            City	</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Work Zone</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                  
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Action
                                                            
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                   
                                
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                            </p-checkbox>
                                                                            <span  class="account-button"  
                                                                            data-toggle="modal" data-target="#workOrderpopup"
                                                                            (click)="getFsmAssignmentByIdNewOne(product)">
                                                                                {{product.fsmCustomer.customerId}}</span>
                                
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fsmCustomer.customerName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fsmCustomer.customerType}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fsmCustomer.billingAddressSame}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fsmCustomer.city}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fsmCustomer.workZone}}
                                                                    </td>
                                                                   
                                                                    <td [ngStyle]="{'width':'180px'}">                                                                      

                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                                             pointer; " (click)="deleteFsmCustomer(product)">Delete</small>
                                                                       
                                                                    </td>
                                
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">No
                                                                                Record Found
                                                                            </h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <p class="paginate_data">
                                                            View per page
                                
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div> -->

<div class="modal fade"  id="workOrderpopup" tabindex="-1" role="dialog" aria-labelledby="workOrderpopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Summary</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonLL>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Summary</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" >
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Order ID 
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group" 
                                                 >
                                                      <span   *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].workOrderId}}</span> 
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Customer Id</label>
                                                    </div>
                                                    <div class="col-lg-2"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].customerId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Customer Name</label>
                                                    </div>
                                                    <div class="col-lg-2"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].customerName}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Customer Address
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].address1}}   
                                                        {{assignmentByIdNew.getFsmAssignment[0].address2}} 
                                                        {{assignmentByIdNew.getFsmAssignment[0].city}} 

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">State Province Geo Id
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].stateProvinceGeoId}} 

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Country Geo Id
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].countryGeoId}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].priority}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Problem Id
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].problemId}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Problem Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].problemStatus}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Due By
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].dueBy}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].priority}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].technicianName}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Id
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].technicianId}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Availability
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].technicianAvailability}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.getFsmAssignment">
                                                        {{assignmentByIdNew.getFsmAssignment[0].technicianStatus}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Expertise Area
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.dataNodeTechnician">
                                                        {{assignmentByIdNew.dataNodeTechnician[0].expertiseArea}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Number
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"    *ngIf="assignmentByIdNew.dataNodeTechnician">
                                                        {{assignmentByIdNew.dataNodeTechnician[0].technicianNumber}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"   *ngIf="assignmentByIdNew.dataNodeTechnician">
                                                        {{assignmentByIdNew.dataNodeTechnician[0].technicianType}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Email
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"  *ngIf="assignmentByIdNew.dataNodeTechnician">
                                                        {{assignmentByIdNew.dataNodeTechnician[0].email}}

                                                     
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Technician Status Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group"  *ngIf="assignmentByIdNew.dataNodeTechnician">
                                                        {{assignmentByIdNew.dataNodeTechnician[0].technicianStatusDescription}}

                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>





    
    




