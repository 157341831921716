<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white  mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/facilities/catalog/products">Product Summary</span>
                <!-- <span class="color-black pl-1"> > Product Summary</span> -->
             
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div>
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
                    <span *ngIf="activeCategory==2" class="color-grey">Product For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==4" class="color-grey">Prices For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==5" class="color-grey">Content For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==14" class="color-grey">Geos For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==15" class="color-grey">Good Identification For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==16" class="color-grey">Category Members For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==17" class="color-grey">Keywords: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==18" class="color-grey">Associations For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==19" class="color-grey">Manufacturing For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==20" class="color-grey">Costs For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==21" class="color-grey">Attributes For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==41" class="color-grey">Features For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==23" class="color-grey">Facilities For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==24" class="color-grey">Facility Locations For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==7" class="color-grey">Variants For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==6" class="color-grey">Inventory Summary For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==3" class="color-grey">Suppliers For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==39" class="color-grey">Agreements For: </span>
                    <span *ngIf="activeCategory==26" class="color-grey">GL Accounts For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==27" class="color-grey">Payment Types For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==28" class="color-grey">Maintenance For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==29" class="color-grey">Meters For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==30" class="color-grey">Subscription Resources For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==31" class="color-grey">Quick Admin For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==32" class="color-grey">Vendor For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==40" class="color-grey">Rental For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==33" class="color-grey">Configurations For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==34" class="color-grey">Work Effort For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==35" class="color-grey">Parties For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==36" class="color-grey">Orders For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==37" class="color-grey">Communication Events For: {{product.productId ? product.productId:'N/A'}}</span>
                    <span *ngIf="activeCategory==38" class="color-grey">Group Order For: {{product.productId ? product.productId:'N/A'}}</span>
                </div>
            </div>


            <div class="mt-2">
                <div class="w3-card-4 classCard">
                    <div class=" bg-white color-grey">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="margin-left: 0px!important;
                    margin-right: 0px!important;flex-wrap: wrap;padding-left: 12px;">
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Product</a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                Price</a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                    Content</a></li>
                            <li [ngClass]="activeCategory==14?'active':''" (click)="changeactiveCategoryFunction(14)"><a>
                                Geos</a></li>
                            <li [ngClass]="activeCategory==15?'active':''" (click)="changeactiveCategoryFunction(15)"><a>
                                IDs</a></li>
                            <li [ngClass]="activeCategory==16?'active':''" (click)="changeactiveCategoryFunction(16)"><a>
                                Categories</a></li>
                            <li [ngClass]="activeCategory==17?'active':''" (click)="changeactiveCategoryFunction(17)"><a>
                                Keywords</a></li>    
                            <li [ngClass]="activeCategory==18?'active':''" (click)="changeactiveCategoryFunction(18)"><a>
                                Associations</a></li>
                            <li [ngClass]="activeCategory==19?'active':''" (click)="changeactiveCategoryFunction(19)">
                                <a>Manufacturing</a>
                            </li>
                            <li [ngClass]="activeCategory==20?'active':''" (click)="changeactiveCategoryFunction(20)">
                                <a>Costs</a>
                            </li>
                            <li [ngClass]="activeCategory==21?'active':''" (click)="changeactiveCategoryFunction(21)">
                                <a>Attributes</a>
                            </li>
                            <li [ngClass]="activeCategory==41?'active':''" (click)="changeactiveCategoryFunction(41)">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCategory==23?'active':''" (click)="changeactiveCategoryFunction(23)">
                                <a>Facilities</a>
                            </li>
                            <li [ngClass]="activeCategory==24?'active':''" (click)="changeactiveCategoryFunction(24)">
                                <a>Locations</a>
                            </li>
                                <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                Inventory </a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Suppliers</a></li>
                            <li [ngClass]="activeCategory==39?'active':''" (click)="changeactiveCategoryFunction(39)">
                                <a>Agreements</a>
                            </li>
                            <li [ngClass]="activeCategory==26?'active':''" (click)="changeactiveCategoryFunction(26)">
                                <a>Accounts</a>
                            </li>
                            <li [ngClass]="activeCategory==27?'active':''" (click)="changeactiveCategoryFunction(27)">
                                <a>Payment Types</a>
                            </li>
                            <li [ngClass]="activeCategory==28?'active':''" (click)="changeactiveCategoryFunction(28)">
                                <a>Maintenance</a>
                            </li>
                            <li [ngClass]="activeCategory==29?'active':''" (click)="changeactiveCategoryFunction(29)">
                                <a>Meters</a>
                            </li>
                            
                            <li [ngClass]="activeCategory==30?'active':''" (click)="changeactiveCategoryFunction(30)">
                                <a>Subscription Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==31?'active':''" (click)="changeactiveCategoryFunction(31)">
                                <a>Quick Admin</a>
                            </li>

                            <li [ngClass]="activeCategory==32?'active':''" (click)="changeactiveCategoryFunction(32)">
                                <a>Vendor</a>
                            </li>
                            <li *ngIf="product.productTypeId =='ASSET_USAGE' || product.productTypeId =='ASSET_USAGE_OUT_IN'" [ngClass]="activeCategory==40?'active':''" (click)="changeactiveCategoryFunction(40)">
                                <a>Rental</a>
                            </li>
                            
                            <li *ngIf="product.productTypeId =='AGGREGATED'" [ngClass]="activeCategory==33?'active':''" (click)="changeactiveCategoryFunction(33)">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCategory==34?'active':''" (click)="changeactiveCategoryFunction(34)">
                                <a>Work Effort</a>
                            </li>
                            <li [ngClass]="activeCategory==35?'active':''" (click)="changeactiveCategoryFunction(35)">
                                <a>Parties</a>
                            </li>
                            <li [ngClass]="activeCategory==36?'active':''" (click)="changeactiveCategoryFunction(36)">
                                <a>Orders</a>
                            </li>
                            <li [ngClass]="activeCategory==37?'active':''" (click)="changeactiveCategoryFunction(37)">
                                <a>Communications</a>
                            </li>
                            <li [ngClass]="activeCategory==38?'active':''" (click)="changeactiveCategoryFunction(38)">
                                <a>Group Order</a>
                            </li>
                            
                            <li *ngIf="product.productTypeId =='FINISHED_GOOD'"  [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                                    Variants </a>
                            </li>

                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid">
                            <div class="mt-2 mt-2">
                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#New_product">New Product</button>
                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#virtualProduct">New Virtual Product </button>
                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#">Product Page </button>
                            <button *ngIf="!this.Hidebtn" target="_blank" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" (click)="downloadBarCode()">Barcode </button>
                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;"  (click)="changeactiveCategoryFunction(42)">Tags </button>
                            </div>
                            <div class="row">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white w-100">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div *ngIf="activeCategory==2">
                                                <div class="header-tabs">
                                                    <h4 class="common-styling"> Product Information
                                                    </h4>
                                                    <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate">

                                                        <a   data-toggle="modal" (click)="edit();"
                                                        data-target="#exampleModalCenter">Update</a>
                                                      </span>
                                                    <img *ngIf="product?.smallImageUrl" alt="small image" class="mr-2 float-right img-thumbnail" [src]="product?.smallImageUrl">
                                                </div>

                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="col-lg-8">
                                                                    <div *ngIf="product" class="row">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Product [ID]
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.productId
                                                                                    ? product.productId:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">

                                                                                <p class="col-lg-6 col-12">Product Name
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.productName
                                                                                    ? product.productName:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Product Type
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.productTypeName
                                                                                    ? product.productTypeName:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">

                                                                                <p class="col-lg-6 col-12">Brand Name
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.brandName
                                                                                    ? product.brandName:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Change of Shipping
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.chargeShipping
                                                                                    ?
                                                                                    product.chargeShipping:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Comments </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.comments
                                                                                    ? product.comments :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">

                                                                                <p class="col-lg-6 col-12">Internal Name
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.internalName
                                                                                    ?
                                                                                    product.internalName:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Inventory Message </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.inventoryMessage
                                                                                    ?
                                                                                    product.inventoryMessage:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Variant </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.isVariant
                                                                                    ? product.isVariant:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Virtual
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.isVirtual
                                                                                    ? product.isVirtual:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Pieces Included
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.piecesIncluded
                                                                                    ?
                                                                                    product.piecesIncluded:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Release Date
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.releaseDate
                                                                                    ? (product.releaseDate |
                                                                                    date:'yyyy-MM-dd HH:mm:ss')
                                                                                    :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Introduction Date
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.introductionDate
                                                                                    ? (product.introductionDate |
                                                                                    date:'yyyy-MM-dd HH:mm:ss')
                                                                                    :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Sales Discontinaution Date
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.salesDiscontinuationDate
                                                                                    ? (product.salesDiscontinuationDate
                                                                                    | date:'yyyy-MM-dd HH:mm:ss')
                                                                                    :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Require Amount
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.requireAmount
                                                                                    ? product.requireAmount
                                                                                    :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Require Inventory
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.requireInventory
                                                                                    ? product.requireInventory
                                                                                    :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Returnable
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.returnable
                                                                                    ? product.returnable :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Include Promotions
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{product.includeInPromotions
                                                                                    ? product.includeInPromotions
                                                                                    :'N/A'}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==3">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Suppliers For:{{product.productId ? product.productId:'N/A'}}
                                                    </h4>
                                                    <span *ngIf="!this.Hidebtn" class="edit-buttons">
                                                        <a
                                                        data-toggle="modal"
                                                        data-target="#supplierModalCenter">Create New Suppliers</a>
                                                    </span>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: inherit!important;">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="SupplierByProductId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Supplier
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Supplier Product ID
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>

                                                                            </th>

                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> Minimum order quantity
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> Order qty increments
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Supplier Pref Order ID
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Available from date
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> Available Through date
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Quantity Uom ID
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Last Price
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Shipping Price
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Action
                                                                                    <p-sortIcon field="price">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    {{product.partyDesc}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.supplierProductId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.minimumOrderQuantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.orderQtyIncrements}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.supplierPrefOrder}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.availableFromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.availableThruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.quantityUomId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.lastPrice}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.shippingPrice}}
                                                                            </td>


                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <svg *ngIf="!this.Hidebtn" style="
                                                                                        cursor: pointer;
                                                                                        background-color: blue;
                                                                                    " data-toggle="modal"
                                                                                    data-target="#supplierModalCenter1" (click)="onUpdatePatch(product)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                        <g transform="translate(0 -0.004)">
                                                                                        <g
                                                                                            transform="translate(0 1.52)">
                                                                                            <path
                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                transform="translate(0 -34.137)" />
                                                                                        </g>
                                                                                        <g
                                                                                            transform="translate(4.548 0.004)">
                                                                                            <g
                                                                                                transform="translate(0 0)">
                                                                                                <path
                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                                <svg *ngIf="!this.Hidebtn" (click)="removeProductSupplier(product)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                    <defs>
                                                                                        <style>
                                                                                            .a {
                                                                                                fill: #f44336;
                                                                                            }

                                                                                            .b {
                                                                                                fill: #fafafa;
                                                                                                margin-left: 20px;
                                                                                            }
                                                                                        </style>
                                                                                    </defs>
                                                                                    <path class="a fill-color"
                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                    <path class="b"
                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                </svg>
                                                                            </td>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="activeCategory==4">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Product Price
                                                    </h4>
                                                    <!-- <span class="edit-buttons"
                                                        routerLink="/facilities/catalog/products/product-price"
                                                        [queryParams]="{productId:product.productId}">
                                                        <a>Create</a>
                                                    </span> -->
                                                    <span *ngIf="!this.Hidebtn" class="edit-buttons">
                                                        <a
                                                        data-toggle="modal"
                                                        data-target="#pricePopUp">Create New Product Price</a>
                                                    </span>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="form-group">

                                                        </div>
                                                        <div class="card own-account-table">
                                                            <p-table [value]="getEditProductPrices" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>

                                                                                Price Type
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Purpose
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>

                                                                        </th>

                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Currency
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Product Store Group
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> From Date
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Through Date
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Price
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Term UOM ID
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Custom Price Calc Service
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Tax Percentage
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Tax Authority Party
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Tax Auth Geo ID
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Tax In Price
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Last Modified By
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Created At
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Action
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                {{product.productPriceTypeId}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.productPricePurposeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.currencyUomId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.productStoreGroupId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.fromDate | date }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.thruDate | date }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.price}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.termUomId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.customPriceCalcService}}
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.taxPercentage}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.taxAuthPartyId}}</td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.taxAuthGeoId}}</td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.taxInPrice}}</td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.lastModifiedDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.createdStamp | date :'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <fa-icon *ngIf="!this.Hidebtn" [icon]="faEdit" 
                                                                            data-toggle="modal"
                                                                            data-target="#pricePopUp1" (click)="updateProductPricePatch(product)"
                                                                                 class="account-button" >
                                                                            </fa-icon>
                                                                            <fa-icon *ngIf="!this.Hidebtn" [icon]="faTrash" (click)="deleteProductPriceNew(product)" class="text-danger ml-2 cursor-pointer">
                                                                            </fa-icon>
                                                                        </td>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="activeCategory==5">
                                                <div class="w3-card-4 classCard">
                                                    <div class="">
                                                        <div class=" ">
                                                            <ul class="tabbing-section tabbing-accordians tabClass" style="margin-left: 0px!important;
                                                    margin-right: 0px!important;">

                                                                <li [ngClass]="active===1?'active':''" (click)="changeActive(1)"><a>
                                                                        Content</a></li>
                                                                <li *ngIf="!this.Hidebtn" [ngClass]="active===2?'active':''" (click)="changeActive(2)"><a>
                                                                        Create New Product Content</a></li>
                                                                <li *ngIf="!this.Hidebtn" [ngClass]="active===3?'active':''" (click)="changeActive(3)"><a>
                                                                        Add Content to Product</a></li>
                                                                <li *ngIf="!this.Hidebtn" [ngClass]="active===4?'active':''" (click)="changeActive(4)"><a>
                                                                        Override Simple Fields</a></li>
                                                                <li *ngIf="!this.Hidebtn" [ngClass]="active===5?'active':''" (click)="changeActive(5)"><a>
                                                                        Add Additional Images</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4">
                                                        <ng-template [ngIf]="active===1">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="productContents" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Content
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Type
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>

                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> From Date
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Through Date
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Purchase From Date
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Purchase Through Date
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Use Count Limit
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Use Time
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Use Time UOM ID
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Use Role Type ID
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Sequence Number
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            {{product.contentId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.productContentType}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.fromDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.thruDate | date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.purchaseFromDate | date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.purchaseThruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.useCountLimit}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.useTime}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.useTimeUomId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.useRoleTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.sequenceNum}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <fa-icon *ngIf="!this.Hidebtn" [icon]="faEdit" routerLink="/facilities/catalog/products/additional-add-content" [queryParams]="{ productId: product.productId, productContentTypeId: product.productContentTypeId,fromDate :product.fromDate,contentId:product.contentId }"  class="account-button" >
                                                                                        </fa-icon>
                                                                                        <fa-icon *ngIf="!this.Hidebtn" [icon]="faTrash" (click)="deleteContent(product.fromDate, product.productContentTypeId, product.contentId)" class="text-danger ml-2 cursor-pointer">
                                                                                        </fa-icon>
                                                                                    </td>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template [ngIf]="active===2">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="mt-5 mb-0 w-100">
                                                                        <form>
                                                                            <div class="row">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCC">
                                                                                            <label for="contentType">Product
                                                                                            Content
                                                                                            Type ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="contentTypeIds" filter="true" placeholder="Product Content Type ID" [(ngModel)]="contentTypeId" [ngModelOptions]="{standalone:true}">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </form>
                                                                        <div class="main-submit-button" style="margin-right: 65%;">
                                                                            <button class="btn btn-primary cursor-pointer " (click)="prepareContent()">Prepare
                                                                                Create</button>
                                                                            <button class="btn btn-danger ml-2 cursor-pointer " (click)="resetPrepareContent()">Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template [ngIf]="active===3">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="mt-5 mb-0">
                                                                        <form [formGroup]="contentToProductForm">
                                                                            <div class="row">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputBA">
                                                                                            <label for="contentType">Content
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="contentIds" filter="true" placeholder="Content ID" formControlName="contentId">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="contentType">Product
                                                                                                Content
                                                                                                Type ID<span
                                                                                                    class="text-danger">*</span></label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="contentTypeIds" filter="true" placeholder="Product Content Type ID" formControlName="productContentTypeId">
                                                                                            </p-dropdown>
                                                                                            <small class="text-danger" *ngIf="!!contentToProductForm.controls.productContentTypeId.invalid && !!contentToProductForm.controls.productContentTypeId.touched">
                                                                                            Product Content Type ID
                                                                                            is
                                                                                            required</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputBA">
                                                                                            <label for="date">From Date<span
                                                                                                class="text-danger">*</span></label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date" class="form-control" placeholder="From Date" formControlName="fromDate">
                                                                                            <small class="text-danger" *ngIf="!!contentToProductForm.controls.fromDate.invalid && !!contentToProductForm.controls.fromDate.touched">
                                                                                            From Date is
                                                                                            required</small>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="thruDate">Thru
                                                                                                Date</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date" class="form-control" placeholder="Thru Date" formControlName="thruDate">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputBA">
                                                                                            <label for="date">Purchased From
                                                                                                Date</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date" class="form-control" placeholder="Purchased From Date" formControlName="purchaseFromDate">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="thruDate">Purchased
                                                                                                Thru
                                                                                                Date</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date" class="form-control" placeholder="Purchased Thru Date" formControlName="purchaseThruDate">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputBA">
                                                                                            <label for="countLimit">Use
                                                                                                Count
                                                                                                Limit</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="useCountLimit" placeholder="Use Count Limit">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="timeUom">Use
                                                                                                Time
                                                                                                UOM</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="useTimeIds" placeholder="Use Time UOM ID" formControlName="useTimeUomId" filter="true">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputBA">
                                                                                            <label for="timeUom">Use
                                                                                                Role</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown [options]="userRoles" formControlName="useRoleTypeId" placeholder="Use Role" filter="true">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="sequenceNumber">Sequence
                                                                                            Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="sequenceNum" placeholder="Sequence Number">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div class="main-submit-button" style="    margin-right: 24%;">
                                                                                <button (click)="submitAddToProduct()" class="btn btn-primary cursor-pointer">Add</button>
                                                                                <button (click)="cancelSubmit()" class="btn btn-danger ml-2 cursor-pointer">Cancel</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template [ngIf]="active===4">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="mt-5 mb-0">
                                                                        <form [formGroup]="simpleFieldsForm">
                                                                            <div class="row">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCD">
                                                                                            <label for="countLimit">Product
                                                                                                Name</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="productName" placeholder="Product Name">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="productDescription">Product
                                                                                            Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="description" placeholder="Product Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCD">
                                                                                            <label for="productDescription">Long
                                                                                            Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <textarea rows="5" cols="33" class="form-control" formControlName="longDescription" placeholder="Long Description"></textarea>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="productDescription">Detail
                                                                                            Screen</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="detailScreen" placeholder="Detail Screen">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCD">
                                                                                            <label for="productDescription">Small
                                                                                            Image</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="smallImageUrl" placeholder="Small Image">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="productDescription">Medium
                                                                                            Image</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="mediumImageUrl" placeholder="Medium Image">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCD">
                                                                                            <label for="productDescription">Large
                                                                                            Image</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="largeImageUrl" placeholder="Large Image">
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="productDescription">Detail
                                                                                            Image</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="detailImageUrl" placeholder="Detail Image">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCD">
                                                                                            <label for="productDescription">Original
                                                                                            Image</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control" formControlName="originalImageUrl" placeholder="Original Image">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div class="main-submit-button" style=" margin-right: 28%;">
                                                                                <button (click)="updateSimpleFields()" class="btn btn-primary cursor-pointer">Add</button>
                                                                                <button (click)="  resetSimpleFields()" class="btn btn-danger ml-2 cursor-pointer">Cancel</button>
                                                                            </div>
                                                                        </form>
                                                                        <h4 class="mt-1 text-black">Upload Image</h4>
                                                                        <hr />
                                                                        <form class="mt-5">
                                                                            <div class="row">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCD">
                                                                                            <label for="image">Image</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="file" class="custom-file-input" (change)="setImage($event)" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                                                                                            <label class="custom-file-label" for="inputGroupFile01">Choose
                                                                                                    file</label>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-2 col-12">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" (click)="setUploadImageType('small')" type="radio" name="exampleRadios" id="exampleRadios1">
                                                                                        <label class="form-check-label text-black" for="exampleRadios1">
                                                                                            Small
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-2 col-12">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="radio" (click)="setUploadImageType('medium')" name="exampleRadios" id="exampleRadios2">
                                                                                        <label class="form-check-label text-black" for="exampleRadios2">
                                                                                            Medium
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-2 col-12">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="radio" (click)="setUploadImageType('large')" name="exampleRadios" id="exampleRadios3">
                                                                                        <label class="form-check-label text-black" for="exampleRadios3">
                                                                                            Large
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-2 col-12">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="radio" (click)="setUploadImageType('detail')" name="exampleRadios" id="exampleRadios5">
                                                                                        <label class="form-check-label text-black" for="exampleRadios5">
                                                                                            Detail
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-2 col-12">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input" type="radio" (click)="setUploadImageType('original')" name="exampleRadios" id="exampleRadios4">
                                                                                        <label class="form-check-label text-black" for="exampleRadios4">
                                                                                            Original
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="main-submit-button">
                                                                                <button (click)="uploadImage()" class="btn btn-primary cursor-pointer">Upload
                                                                                    Image</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template [ngIf]="active===5">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="mt-5 mb-0">
                                                                        <form>
                                                                            <div class="row">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCC">
                                                                                            <label for="one">Additional
                                                                                                Image
                                                                                                One</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="file" class="custom-file-input" (change)="setAdditionalImage($event,'one')" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                                                                                            <label class="custom-file-label" for="inputGroupFile01">Choose
                                                                                            file</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="one">Additional
                                                                                                Image
                                                                                                Two</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="file" class="custom-file-input" (change)="setAdditionalImage($event,'two')" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                                                                                            <label class="custom-file-label" for="inputGroupFile01">Choose
                                                                                                    file</label>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div class="col-lg-3 form-group classInputCC">
                                                                                            <label for="one">Additional
                                                                                                Image
                                                                                                Three</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="setAdditionalImage($event,'three')" aria-describedby="inputGroupFileAddon01">
                                                                                            <label class="custom-file-label" for="inputGroupFile01">Choose
                                                                                                    file</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group classInputAA">
                                                                                            <label for="one">Additional
                                                                                                Image
                                                                                                Four</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="file" class="custom-file-input" (change)="setAdditionalImage($event,'four')" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                                                                                            <label class="custom-file-label" for="inputGroupFile01">Choose
                                                                                                    file</label>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>



                                                                            </div>
                                                                            <div class="main-submit-button" style="
                                                                            margin-right: 21%;">
                                                                                <button (click)=" uploadAdditionalImage()" class="btn btn-primary cursor-pointer">Upload</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==6">
                                                <div class="w3-card-4 classCard">
                                                    <div class="">
                                                        <div class="">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">

                                                                <li [ngClass]="inventoryActive===1?'active':''" (click)="inventoryActiveTab(1)"><a>
                                                                        Inventory Summary</a></li>
                                                                <li [ngClass]="inventoryActive===2?'active':''" (click)="inventoryActiveTab(2)"><a>
                                                                        Outstanding Purchase Orders</a></li>
                                                                <li [ngClass]="inventoryActive===3?'active':''" (click)="inventoryActiveTab(3)"><a>
                                                                        Inventory Items</a></li>

                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div class="mt-4">
                                                        <ng-template [ngIf]="inventoryActive===1">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="inventorySummary" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Facility
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> ATP
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>

                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> QOH
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> AQT
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Incoming Shipments
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Incoming Production Runs
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Outgoing Production Runs
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            {{product.facilityId}} -{{product.facilityName}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.totalAvailableToPromise}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.totalQuantityOnHand }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.accountingQuantityTotal}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                    </td>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template [ngIf]="inventoryActive===2">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="purchaseOrders" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Order Date
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Order ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Order Item Seq ID
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Quantity
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Cancel Quantity
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Item Status ID
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Est. Ship Date
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Est Deliver Date
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Ship Before Date
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Ship After Date
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            {{product.orderDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.orderId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.orderItemSeqId }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.quantity }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.cancelQuantity }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.itemStatusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.locationSeqId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.estimatedShipDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.estimatedDeliveryDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.shipBeforeDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.shipAfterDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template [ngIf]="inventoryActive===3">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select UserInfo">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="inventoryItems" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Item ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Item Type
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>

                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Received
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Expire
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Facility or Container ID
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Location
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Lot ID
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Bin Number
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Per Unit Price
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Initial Qty
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            ATP/QOH or Serial
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            {{product.inventoryItemId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.inventoryItemType}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.status }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.datetimeReceived |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.expireDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.facilityId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.locationSeqId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.lotId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.binNumber}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.unitCost}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        {{product.availableToPromiseTotal}}/{{product.quantityOnHandTotal}}
                                                                                    </td>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==7">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Quick Add Variants
                                                    </h4>
                                                    <!-- <span class="edit-buttons"
                                                        routerLink="/facilities/catalog/products/create-product-variant"
                                                        [queryParams]="{productId:product.productId}">
                                                        <a>Create</a></span> -->
                                                    <span class="edit-buttons">
                                                        <a
                                                        data-toggle="modal"
                                                        data-target="#variantsModalCenter">Create New Quick Add Variants</a>
                                                    </span>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="form-group">

                                                        </div>
                                                        <div class="card own-account-table">
                                                            <p-table [value]="productArray" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>

                                                                                Amount
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Style
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> New Product Id and Create!
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Sequence Number Group
                                                                                <p-sortIcon field="quantity">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Existing Variant IDs:
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                        </th>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->

                                                                                </p-checkbox>
                                                                                {{product.curProductFeatureAndAppls[0].description}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.curProductFeatureAndAppls[1].description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <input type="email">
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <input type="email" style="width: 50px !important; margin-left:75px !important" [(ngModel)]="product.curProductFeatureAndAppls[0].numberSpecified" [ngModelOptions]="{standalone: true}">
                                                                            <!-- {{product.curProductFeatureAndAppls[0].numberSpecified}} -->

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.existingVariantProductIds}}
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div *ngIf="activeCategory==14" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Geos </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addGeopopup">New Geo</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="CreateProductGeoById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                            Geo ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Geo Enum ID	
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Description	
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <!-- <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Update
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th> -->
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.geoId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productGeoEnumId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.description}}
                                                                                </td>
                                                                                <!-- <td [ngStyle]="{'width':'180px'}">
                                                                                    <span style="cursor: pointer;" (click)="updateGeo(product)" data-toggle="modal" data-target="#addGeopopup1">
                                                                                        Update
                                                                                    </span>
                                                                                         
                                                                                </td> -->
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="updateGeo(product)" data-toggle="modal" data-target="#addGeopopup1">Update</small>
                                                                                        <small (click)="deleteProductGeo(product)" style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                    <!-- <span style="cursor: pointer;" (click)="deleteProductGeo(product)">
                                                                                        Delete
                                                                                    </span> -->
                                                                                 
                                                                                    <!-- <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span> -->
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page 
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>     
                                            <div *ngIf="activeCategory==15" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Good Identifications </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addIDpopup">New Good ID</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="GoodIdentificationById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        ID Type	
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Id Value
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            
                                                                                <!-- <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Update
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th> -->
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.goodIdentificationTypeId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.idValue}}
    
                                                                                </td>
                                                                                <!-- <td [ngStyle]="{'width':'180px'}">
                                                                                    <span style="cursor: pointer;" (click)="updateGoodIdenti(product)" data-toggle="modal" data-target="#addIDpopup1">
                                                                                        Update
                                                                                    </span>
    
                                                                                </td> -->
              
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <!-- <span style="cursor: pointer;" (click)="deleteGoodIdentification(product)">
                                                                                        Delete
                                                                                    </span> -->
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="updateGoodIdenti(product)" data-toggle="modal" data-target="#addIDpopup1">Update</small>
                                                                                        <small (click)="deleteGoodIdentification(product)" style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                    <!-- <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span> -->
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div *ngIf="activeCategory==16" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Categories </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCategorypopup">New Category</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="AddProductToCategoryById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Category [ID]
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Comments
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span style="color: #0d3769 !important;cursor: pointer;width: 98px;" (click)="ProductCategories(product.productCategoryId)">
                                                                                            {{product.productCategoryId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.comments}}
                                                                                         
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
                                                                        
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.quantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="updateProductToCat(product)" data-toggle="modal" data-target="#addCategorypopup1">Update</small>
                                                                                        <small (click)="removeProductFromCategoryNew(product)" style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                    <!-- <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span> -->
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                            <div *ngIf="activeCategory==17" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Keywords: Keyword </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addKeywordpopup">New Keyword</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="KeywordTypeIdsKeyword" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Keyword
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Keyword Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Relevancy Weight
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.keyword}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.keywordTypeId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.relevancyWeight}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.statusId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" data-toggle="modal" data-target="#addKeywordpopup1" (click)="updateProductKeywPatch(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductKeyword(product.productId,product.keyword,product.keywordTypeId)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                    <!-- <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span> -->
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Keywords: Tag </p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="KeywordTypeIdsTag" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Keyword
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Keyword Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Relevancy Weight
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.keyword}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.keywordTypeId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.relevancyWeight}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.statusId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" data-toggle="modal" data-target="#addKeywordpopup1" (click)="updateProductKeywPatch(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductKeyword(product.productId,product.keyword,product.keywordTypeId)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                    <!-- <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span> -->
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>   
                                            <div *ngIf="activeCategory==18" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Associations FROM this Product to </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addAssocpopup">New Association</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductAssocById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Association Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Reason
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Instruction
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.productIdTo}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productAssocTypeId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
                                                                                    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.reason}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.instruction}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.quantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="updateProductAssocPatch(product)" data-toggle="modal" data-target="#addAssocpopup1" >Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductAssoc(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                    <!-- <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                    </span> -->
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Associatoins TO this Product From </p>
                                                        
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductAssocByIdSec" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Association Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Reason
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Instruction
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.productIdTo}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productAssocTypeId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
                                                                                    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.reason}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.instruction}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.quantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="updateProductAssocPatch(product)" data-toggle="modal" data-target="#addAssocpopup1" >Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductAssoc(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                   
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                            <div *ngIf="activeCategory==19" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Routings </p>
                                                        <!-- <span>
                                                            <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#updateRoutingpopup">Update</button>
                                                    </span> -->
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="RoutingsById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Edit
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Estimated Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Estimated Cost
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Work Effort Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                               
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"   (click)="routingDetails(product.dataNode.workEffortId)">
                                                                                        {{product.dataNode.workEffortId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.thruDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.estimatedQuantity}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.estimatedCost}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.workEffortName[0].workEffortName}}
    
                                                                                </td>
                                                                                
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Components </p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ViewProductComponentsById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        
                                                                                        Edit
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Scrap Factor
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Estimate Calc Method
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Recurrence Info ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.dataNode.productIdTo}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.sequenceNum}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.quantity}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.scrapFactor}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.estimateCalcMethod}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.recurrenceInfoId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productName[0].productName}}
    
                                                                                </td>
                                                                                
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Parent </p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ViewProductParentById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        
                                                                                        Edit
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Estimate Calc Method
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Recurrence Info ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                               
                                                                               
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.dataNode.productId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.thruDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.quantity}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.estimateCalcMethod}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.dataNode.recurrenceInfoId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productName[0].productName}}
    
                                                                                </td>
                                                                              
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==20" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Filter Product Costs </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCostpopup">New Cost</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="CostComponentByProductId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        
                                                                                        C.Component ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        C.Compo Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Feature ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Party ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Geo ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Work Effort ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Fixed Asset ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Cost Component Calc ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Cost
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Cost Uom ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.costComponentId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.costComponentTypeId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productFeatureId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.partyId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.geoId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.workEffortId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fixedAssetId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.costCompoCalcId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.cost}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.costUomId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                       
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteCostComponent(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Cost Component Calcs </p>
                                                        <span *ngIf="!this.Hidebtn">
                                                            <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCostCalcpopup">Add Cost Cal</button>
                                                            <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addAutoCalcpopup">Auto-Calculate Costs</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductCostComponentCalcById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        
                                                                                            Cost Compo. Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Cost Compo. Calc ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num	
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                
                                                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.costComponentTypeId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span (click)="detailCost(product.costComponentCalcId)"  class="account-button"  >
                                                                                        {{product.costComponentCalcId}}
                                                                                    </span>
                                                                                    
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
    
                                                                                </td>
                         
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="deleteProductCostComponentCalc(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div *ngIf="activeCategory==21" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Product Attributes </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addAttrpopup">New Attribute</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductAttributeById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Attr Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Attr Value		
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Attr Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Attr Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                       Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.attrName}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.attrValue}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.attrType}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.attrDescription}}
                                                                                </td>
                     
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="updateProductAttributePatch(product)" data-toggle="modal" data-target="#addAttrpopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductAttribute(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                    <!-- <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span> -->
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==23" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Product Facilities </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addFacilitypopup">New Facility</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductFacilityById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Facility ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Minimum Stock
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Reorder Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Days to Ship
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Replenish Method Enum ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Requirement Method Enum ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Last Inventory Count
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.facilityId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}" style="padding-left: 38px !important;">
                                                                                    {{product.minimumStock}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.reorderQuantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.daysToShip}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.replenishMethodEnumId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.requirementMethodEnumId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.lastInventoryCount}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" (click)="updateProductFacilityPatch(product)" data-toggle="modal" data-target="#addFacilitypopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductFacility(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==24" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Facility Locations </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addLocationpopup">New Facility Loaction</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductFacilityLocationById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Minimum Stock
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Move Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Facility Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Location Seq ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.minimumStock}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.moveQuantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.facilityId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.locationSeqId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" (click)="updateProductFacilityLocationPatch(product)" data-toggle="modal" data-target="#addLocationpopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductFacilityLocation(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==26" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product GL Accounts </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addGLpopup">New GL Account</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductGlAccountDetail" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Account Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Organization
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        GL Account
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.glAccountTypeId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.organizationPartyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.glAccountId}}
                                                                                </td>
                                                              
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" (click)="updateGlProductAccountPatch(product)" data-toggle="modal" data-target="#addGLpopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="removeGlProductAccount(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==27" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Payment Method Type </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addPaymentTypepopup">New Payment Method Type</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductPaymentMethodAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Payment Method Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Purpose
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.paymentMethodTypeId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productPricePurposeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" (click)="updateProductPaymentMethodTypePatch(product)" data-toggle="modal" data-target="#addPaymentTypepopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer;margin-left: 9px;" (click)="deleteProductPaymentMethodType(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==28" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Maintenance </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addMaintenancepopup">New Maintenance</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="EditProductMaints" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product Maint Type ID 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Maint Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Maint Template Work Effort ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Interval Quantity 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Interval Uom ID 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Interval Meter Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Repeat Count
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.productMaintTypeId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.maintName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.maintTemplateWorkEffortId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.intervalQuantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.intervalUomId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.intervalMeterTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.repeatCount}}
                                                                                </td>
                                                                                
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addMaintenancepopup1" (click)="updateProductDataValue(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteProductMaint(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                   
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==29" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Meters </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addMeterspopup">New Meter</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="EditProductMetersByID" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                         
                                                                                        Product Meter Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Meter Uom ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Meter Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.productMeterTypeId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                     
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.meterUomId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.meterName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" (click)="updateProductMeterPatch(product)" data-toggle="modal" data-target="#addMeterspopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteProductMeter(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="activeCategory==30" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Subscription Resources </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addSubsResourcepopup">New Resource</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="SubscriptionProductListBYId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        subs. ResourceId
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Purchase From Date 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Purch. Throu. Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Max Life Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Max Life Time UomID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Available Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Available Time Uom ID 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Use Count Limit
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Use Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Use Time Uom ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Use Role Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        subscription extension
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Time period extension
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Unit Of Meas. for subs.
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        GP On Expiry 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        GP On Expiry Uom ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">  
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.subscriptionResourceId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.purchaseFromDate | date }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.purchaseThruDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.maxLifeTime}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.maxLifeTimeUomId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.availableTime}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.availableTimeUomId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.useCountLimit}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.useTime}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.useTimeUomId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.useRoleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.automaticExtend}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.canclAutmExtTime}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.canclAutmExtTimeUomId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.gracePeriodOnExpiry}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.gracePeriodOnExpiryUomId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addSubsResourcepopup1" (click)="updateResource(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteProductSubscriptionResource(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==31" class="w-100">
                                               
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Shipping Dimension and Weights </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#editProductQuickAdmin" (click)="updateProductQuickAdminNamePatch()">Edit Product Quick Admin</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="EditProductQuickAdmin" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product Height
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Width
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Depth
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Weight
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Fl. Oz.
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        ML
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Net Wt.
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Grams
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        HZ
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        ST
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        TD
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.product.productHeight}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.product.productWidth}}
                                                                                  
                                                                                   
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                  
                                                                                    {{product.product.productDepth}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.product.productWeight}}
                                                                               
                                                                                   
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                 
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                               
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                               
                                                                              
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                              
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                              
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                  
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#shippingDimen_Weights" (click)="updateProductQuickAdminShippingPatch()">Update</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Standard Features </p>
                                                        <span>
                                                            <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addFeatureTypePopup">New Feature Type</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="StandardFeaturesSecondId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Feature Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.dataNode.productFeatureTypeId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span>
                                                                                        {{product.dataNode.description}}
                                                                                    
                                                                                    </span>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteFeatureNew(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Categories </p>
                                                            <span>
                                                                <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCategoriesPopup">Add Categories</button>
                                                            </span>
                                                            <span>
                                                                <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addPublishpopup">Publish View</button>
                                                            </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="AddProductToCategoryById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Category
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.productCategoryId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span>
                                                                                        <!-- <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer">Update</small> -->
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="removeProductFromCategoryQuick(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="activeCategory==32" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Vendor Product </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn"  type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addVendorpopup">New Vendor Product</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="VendorProductByID" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        
                                                                                        Vendor Party ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Store Group ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                               
                                                                        
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.vendorPartyId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productStoreGroupId}}
                                                                                </td>
                                                             
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteVendorProduct(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="activeCategory==33" class="w-100" id="configurationSection">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Configurations </p>
                                                        <span>
                                                            <button type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addConfigpopup">New Configuration</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductConfigsById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Config Item
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Long Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Config Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Default Config Option ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Is Mandatory
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.configItemId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.description}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.longDescription}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.configTypeId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.defaultConfigOptionId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.isMandatory}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span>
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" (click)="updateProductConfigPatch(product)" data-toggle="modal" data-target="#addConfigpopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteProductConfig(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==34" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Work Effort </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addWEpopup">New Work Effort</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="EditProductWorkEfforts" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Work Effort ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Good Std Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Estimated Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Estimated Cost
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                            {{product.Data.workEffortId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.workEffortGoodStdTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.fromDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.thruDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.statusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.estimatedQuantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.estimatedCost}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" (click)="updateProWorkEffortGoodStandardPtach(product)"  data-toggle="modal" data-target="#addWEpopup1">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="removeProWorkEffortGoodStandard(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==35" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Parties </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addAssocPartypopup">Associate Party</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="PartyToProductById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        
                                                                                        Party
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Role
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date Time
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence Num
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Comments
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.partyId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.comments}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addAssocPartypopup1" (click)="updatePartyToProductPtach(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="removePartyFromProduct(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==36" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Order(s) Found </p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductOrderById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Order ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Item Status ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Order Item Seq ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Order Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Unit Price
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Order Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.orderId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.orderStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.orderItemSeqId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.orderDate | date }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.unitPrice}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.quantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.orderTypeId}}
    
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==37" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Communication Events </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addCommpopup">New</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="CreateCommunicationEventById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Communication Event ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Subject
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Comm.Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Contact Mech Type ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Email Sent On
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th><th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        To Email Address
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th><th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Content
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.communicationEventId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.subject}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.communicationEventTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.contactMechTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.statusId}}     
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                        
                                                                                </td><td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.contactMechIdTo}}
                                                                                      
                                                                                </td><td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.content}}   
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==38" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Group Order </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addGroupOrderpopup">New Group Order</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductGroupOrderById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Group Order ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Deal Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sold Quantity
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Deal Start Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Deal End Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.groupOrderId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.reqOrderQty}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.soldOrderQty}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.statusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addGroupOrderpopup1" (click)="updateProductGroupOrderPatch(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteProductGroupOrder(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==39" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Purchases </p>
                                                        <span>
                                                 
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addAgreementpopup">New</button>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addSalesAgreementpopup">New Sales Agreement</button>
                                                    </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Manual Only
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn"  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Sales </p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Manual Only
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn"  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Commissions </p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Manual Only
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Products </p>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Manual Only
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span  class="account-button"  >
                                                                                        <a  class="account-button"  data-toggle="modal" data-target="#">Update</a>&nbsp;<a   class="account-button" >Remove</a>
                                                                                </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==40" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Asset Usage</p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addRentalPopup">New Asset Usage</button>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#ProductAssetUsage">Edit ProductAssetUsage</button>

                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="FixedAssetProductById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Fixed Asset [Fixed Asset ID]
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Fixed Asset Product Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                               
                                                                            
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.fixedAssetId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fixedAssetProductTypeId}}
                                                                                </td>
                                                                            
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
    
                                                                                </td>
                                                                                <!-- <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.statusId}}
                                                                                </td> -->
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addRentalPopup1" (click)="updateFixedAssetProductPatch(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="removeFixedAssetProduct(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==41" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Features </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addFeatureCategorypopup">New Feature From Category or Group</button>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addFeatureTypeIDpopup">New Feature with Type and ID Code</button>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addFeatureIDpopup">New Feature with ID</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="EditProductFeatures" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        UOM ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Category
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Amount
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Sequence
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Application Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.productFeatureId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.description}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.uomId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productFeatureCategoryId}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.thruDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.amount}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.sequenceNum}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#addFeatureIDpopup1" (click)="updateFeatureToProductApplicationPatch(product)">Update</small>
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="RemoveFeatureFromProduct(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">List Feature Interactions </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addFeatureInteractionpopup">New Feature Interaction</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="ProductFeatureIactn" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        
                                                                                        Product Feature ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        
                                                                                        Product Feature TO
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Interaction Type
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                               
                                                                            
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.productFeatureId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productFeatureIdTo}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.productFeatureIactnTypeId}}
    
                                                                                </td>
                           
                
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <!-- <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer">Update</small> -->
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="removeProductFeatureIactn(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">List Product Feature Appl Attrs </p>
                                                        <span>
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#addProductFeatureAttrpopup">New Feature Attributes</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="EditProductFeaturesById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product Feature ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        From Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Attr Name
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Attr Value
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                        {{product.productFeatureId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.fromDate | date}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.attrName}}
    
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.attrValue}}
    
                                                                                </td>
                                                                                
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <!-- <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer"  data-toggle="modal" data-target="#addProductFeatureAttrpopup1" (click)="updateProductFeatureApplAttrPatch(product)">Update</small> -->
                                                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer;" (click)="deleteProductFeatureApplAttr(product)">Delete</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==42" class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important;">Edit Product Tags</p>
                     
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                     <p-table [value]="EditProductTagAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
    
                                                                                        Product ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Tag
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Action
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"  >
                                                                                       {{product.Data.productId}}
                                                                                        </span>
    
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.keyword}}
    
                                                                                </td>
                             
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.Data.statusId}}
    
                                                                                </td>
                              
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <span *ngIf="!this.Hidebtn">
                                                                                        <small  style="color: #0d3769 !important;font-weight: 600;cursor:pointer" data-toggle="modal" data-target="#editProductTags" (click)="updateProductTagPatch(product)">Update</small>
                                                                                        
                                                                                    </span>
                                                                                </td>
    
                                                                            </tr>
                                                                        </ng-template>
    
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                                                   
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Product</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Product</a></li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="wordingValid(createEditProductForm.value.productTypeId)"><a>
                                Wording And Comment</a></li>

                                <li [ngClass]="activeCategory==4?'active':''" (click)="virtualValid(createEditProductForm.value.internalName)"><a>
                                Virtual Product </a></li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="primaryValid(createEditProductForm.value.virtualVariantMethodEnum)"><a>
                                Primary Category </a></li>
                                <li [ngClass]="activeCategory==6?'active':''" (click)="dateValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Dates </a></li>
                                <li [ngClass]="activeCategory==7?'active':''" (click)="inventoryValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Inventory </a></li>
                                <li [ngClass]="activeCategory==8?'active':''" (click)="ratingValid(createEditProductForm.value.inventoryItemTypeId)"><a>
                                Rating </a></li>
                                <li [ngClass]="activeCategory==9?'active':''" (click)="amountValid(createEditProductForm.value.ratingTypeEnum)"><a>
                                Amount </a></li>
                                <li [ngClass]="activeCategory==10?'active':''" (click)="measureValid(createEditProductForm.value.amountUomTypeId)"><a>
                                Measures </a></li>
                                <li [ngClass]="activeCategory==11?'active':''" (click)="shipingValid(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)"><a>
                                Shipping </a></li>
                                <li [ngClass]="activeCategory==12?'active':''" (click)="shopingValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                ShoppingCart </a></li>
                                <li [ngClass]="activeCategory==13?'active':''" (click)="shopingCartValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                Miscellaneous </a></li>



                            </ul>
                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createEditProductForm">
                                        <div *ngIf="activeCategory==2">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product Type <span
                                                            style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="productTypeIdArray" placeholder="Select Product Type" formControlName="productTypeId">
                                                                </p-dropdown>
                                                                <small class="text-danger" *ngIf="!!createEditProductForm.controls.productTypeId.invalid && !!createEditProductForm.controls.productTypeId.touched">Required</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">
                                                            Product ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID" formControlName="productId">
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 14%;">
                                                    <button type="submit" (click)="selectProduct(createEditProductForm.value.productTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==3">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">
                                                            Internal Name <span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Internal Name" formControlName="internalName">
                                                                <small class='text-danger' *ngIf="!!createEditProductForm.controls.internalName.invalid && !! createEditProductForm.controls.internalName.touched">Required</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Brand Name</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Brand Name" formControlName="brandName">
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">Product Name</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Name" formControlName="productName">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Comments </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments" formControlName="comments">
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 13%;">
                                                    <button type="submit" (click)="selectworking(createEditProductForm.value.internalName)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==4">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputC">
                                                                <label for="exampleInputEmail1">
                                                            Is VIRTUAL Product ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Is Virtual Product" formControlName="isVirtual">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">
                                                            Is VARIANT Product ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Is Variant Product" formControlName="isVariant"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputC">
                                                                <label for="exampleInputEmail1">Virtual Variant
                                                            Method<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" [options]="virtualVariantMethodArray" placeholder="Select Virtual Variant Method" formControlName="virtualVariantMethodEnum">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 9%;">
                                                    <button type="submit" (click)="selectvirtual(createEditProductForm.value.virtualVariantMethodEnum)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==5">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">
                                                            Primary Category<span style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="categories" placeholder="Select Primary Category" formControlName="primaryProductCategoryId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="main-submit-button" style="margin-right: 61%;">
                                                    <button type="submit" (click)="selectPrimary(createEditProductForm.value.primaryProductCategoryId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==6">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputGI">
                                                                <label for="exampleInputEmail1">
                                                            Introduction Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="introductionDate">

                                                                <!-- <p-calendar placeholder="Select Date" formControlName="introductionDate">
                                                                </p-calendar> -->
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Release
                                                            Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="releaseDate">
                                                                <!-- <p-calendar formControlName="releaseDate" placeholder="Select Date">
                                                                </p-calendar> -->
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputGI">
                                                                <label for="exampleInputEmail1">Sales
                                                            Discontinuation Through Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="salesDiscontinuationDate">
                                                                <!-- <p-calendar formControlName="salesDiscontinuationDate" placeholder="Select Date">
                                                                </p-calendar> -->
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Support
                                                            Discontinuation Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="supportDiscontinuationDate">
                                                                <!-- <p-calendar formControlName="supportDiscontinuationDate" placeholder="Select Date">
                                                                </p-calendar> -->
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 4%;">
                                                    <button type="submit" (click)="selectDate()" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==7">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Disc. When Inv. Not
                                                            Avail?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="salesDiscWhenNotAvail">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Requirement Method
                                                            Enum ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="requirementMethodEnumIDArray" placeholder="Select Enum ID" formControlName="requirementMethodEnumId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Inventory
                                                            Message</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Inventory Message" formControlName="inventoryMessage">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">InventoryItem Type
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="inventoryItemTypeIDArray" placeholder="Select Inventory Item Type Id" formControlName="inventoryItemTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Require
                                                            Inventory</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="requireInventory">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Lot ID</label>


                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="commandingArray" placeholder="Select Lot Id" formControlName="lotIdFilledIn">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 7%;">
                                                    <button type="submit" (click)="selecInventory(createEditProductForm.value.inventoryItemTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==8">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">
                                                            Rating Type Enum<span style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="ratingTypeEnumArray" placeholder="Select Rating Type Enum" formControlName="ratingTypeEnum">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Rating</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rating" formControlName="productRating">
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 11%;">
                                                    <button type="submit" (click)="selectRating(createEditProductForm.value.ratingTypeEnum)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==9">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">
                                                            Require Amount</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="requireAmount">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Amount Uom Type
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="amountUomTypeIDArray" placeholder="Select Amount Uom Type Id" formControlName="amountUomTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 13%;">
                                                    <button type="submit" (click)="selectAmount(createEditProductForm.value.amountUomTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==10">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Product
                                                            Height</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Height" formControlName="productHeight">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Height Uom
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Height Uom ID" formControlName="heightUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Height</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Height" formControlName="shippingHeight">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Width</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Width" formControlName="productWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Width Uom ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Width Uom ID" formControlName="widthUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Width</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Width" formControlName="shippingWidth">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Product
                                                            Depth</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Depth" formControlName="productDepth">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Depth Uom ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Depth Uom ID" formControlName="depthUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Depth</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Depth" formControlName="shippingDepth">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Diameter</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Diameter" formControlName="productDiameter">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Diameter Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Diameter Uom ID" formControlName="diameterUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Weight</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Weight" formControlName="productWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Weight Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="weightUomIDArray" placeholder="Select Weight Uom ID" formControlName="weightUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Weight</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Weight" formControlName="shippingWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Quantity
                                                            Included</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity Included" formControlName="quantityIncluded">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Quality Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="quantityUomIDArray" placeholder="Select Quality Uom ID" formControlName="quantityUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-6 col-12">
                                                        <label for="exampleInputEmail1"></label>
                                                        <div class="main-submit-button" style="    margin-right: -86%;">
                                                            <button type="submit" (click)="selectMeasure(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)" class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==11">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputG">
                                                                <label for="exampleInputEmail1">Pieces
                                                            Included</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Pieces
                                                    Included" formControlName="piecesIncluded">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">In Shipping Box
                                                            ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="inShippingBox">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputG">
                                                                <label for="exampleInputEmail1">Default Shipment Box
                                                            Type ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="defaultShipmentBoxTypeIDArray" placeholder="Select Shipment" formControlName="defaultShipmentBoxTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Charge
                                                            Shipping</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select Charge Shipping" formControlName="chargeShipping">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 3%;">
                                                    <button type="submit" (click)="selectShiping(createEditProductForm.value.defaultShipmentBoxTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==12">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputH">
                                                                <label for="exampleInputEmail1">
                                                            Allow order decimal quantity</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select Quantity" formControlName="orderDecimalQuantity">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 55%;">
                                                    <button type="submit" (click)="selectShopingCart()" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==13">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Returnable</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="returnable">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Include In
                                                            Promotions</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="includeInPromotions">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Taxable</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="taxable">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="keyword">AutoCreate Keywords</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="autoCreateKeywords">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12" *ngIf="editMode">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="createdBy">Created By
                                                        </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" [value]="product.createdByUserLogin" readonly>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="lastModifiedBy">Last Modified By
                                                        </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" [value]="product.lastModifiedByUser" readonly>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button " style="margin-right: 14%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="submitProduct()">{{editMode? 'Update' : 'Create'}}
                                                </button>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="addGeopopup" tabindex="-1" role="dialog" aria-labelledby="addGeopopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Geos</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Geos</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGeo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Geo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="VatTaxAuthGeoIdArray" formControlName="geoID" optionlabel="label" placeholder="Enter Geo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Geo Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductGeoEnumIdArray" formControlName="enumID" optionlabel="label" placeholder="Enter Product Geo Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductGeo()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addGeopopup1" tabindex="-1" role="dialog" aria-labelledby="addGeopopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Geos</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Geos</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGeo">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Geo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="VatTaxAuthGeoIdArray" formControlName="geoID" optionlabel="label" placeholder="Enter Geo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Geo Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductGeoEnumIdArray" formControlName="enumID" optionlabel="label" placeholder="Enter Product Geo Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductGeo()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addIDpopup" tabindex="-1" role="dialog" aria-labelledby="addIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Good Identifications</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Good Identifications</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGoodID">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">ID Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="GoodIdentificationTypeIdArray" formControlName="idType" optionlabel="label" placeholder="Enter ID Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">ID Value<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="idValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createGoodIdentification()" class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addIDpopup1" tabindex="-1" role="dialog" aria-labelledby="addIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Good Identifications</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Good Identifications</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGoodID1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">ID Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="GoodIdentificationTypeIdArray" formControlName="idType" optionlabel="label" placeholder="Enter ID Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">ID Value<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="idValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Value">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateGoodIdentification()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addCategorypopup" tabindex="-1" role="dialog" aria-labelledby="addCategorypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Categories</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Categories</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCategory">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Category ID<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductCategoryForStoreArray" formControlName="categoryID" optionlabel="label" placeholder="Enter Product Category ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="qty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="addProductToCategory()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addCategorypopup1" tabindex="-1" role="dialog" aria-labelledby="addCategorypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Product Categories</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Categories</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCategory">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Category ID<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductCategoryForStoreArray" formControlName="categoryID" optionlabel="label" placeholder="Enter Product Category ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="qty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductToCategory()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addKeywordpopup" tabindex="-1" role="dialog" aria-labelledby="addKeywordpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Keyword</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Keyword</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addKeyword">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Keyword<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="keyword" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Keyword">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Keyword Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getkeywordTypeIdsArray" formControlName="typeID" optionlabel="label" placeholder="Enter Keyword Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Relevancy Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="relWeight" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Relevancy Weight">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getstatusIdsArray" formControlName="statusID" optionlabel="label" placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductKeyword()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addKeywordpopup1" tabindex="-1" role="dialog" aria-labelledby="addKeywordpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Keyword</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Keyword</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addKeyword1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Keyword<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="keyword" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Keyword">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Keyword Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getkeywordTypeIdsArray" formControlName="typeID" optionlabel="label" placeholder="Enter Keyword Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Relevancy Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="relWeight" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Relevancy Weight">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getstatusIdsArray" formControlName="statusID" optionlabel="label" placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductKeyword()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addAssocpopup" tabindex="-1" role="dialog" aria-labelledby="addAssocpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Associations</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Associations</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAssoc">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product ID<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="proID" [options]="CatalogProductIdArray" optionlabel="label" placeholder="Enter Product ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product ID To<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="proIdTo" [options]="CatalogProductIdArray" optionlabel="label" placeholder="Enter Product ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Association Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="assocType" [options]="ProductAssocTypeArray" optionlabel="label" placeholder="Enter Association Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Reason
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reason" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reason">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Instruction
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="instruction" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Instruction">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="qty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductAssoc()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAssocpopup1" tabindex="-1" role="dialog" aria-labelledby="addAssocpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Associations</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton10>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Associations</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAssoc1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product ID<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="proID" [options]="CatalogProductIdArray" optionlabel="label" placeholder="Enter Product ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product ID To<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="proIdTo" [options]="CatalogProductIdArray" optionlabel="label" placeholder="Enter Product ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Association Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="assocType" [options]="ProductAssocTypeArray" optionlabel="label" placeholder="Enter Association Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Reason
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reason" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reason">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Instruction
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="instruction" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Instruction">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="qty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductAssoc()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAttrpopup" tabindex="-1" role="dialog" aria-labelledby="addAttrpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Attributes(Enter Name, Value, and Type)</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Attributes(Enter Name, Value, and Type)</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAttr">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Name<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="name" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="value" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="type" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Type">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Descripiton
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Descripiton">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductAttribute()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAttrpopup1" tabindex="-1" role="dialog" aria-labelledby="addAttrpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Attributes(Enter Name, Value, and Type)</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton12>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Attributes(Enter Name, Value, and Type)</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAttr1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Name<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="name" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="value" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="type" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Type">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Descripiton
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Descripiton">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductAttribute()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFacilitypopup" tabindex="-1" role="dialog" aria-labelledby="addFacilitypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Facility</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton13>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Facility</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFacility">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFacilityIdArray" formControlName="facilityID" optionlabel="label" placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Minimum Stock
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minStock" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Minimum Stock">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Reorder Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reorderQuantity" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reorder Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Days to Ship
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="dayToShip" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Days to Ship">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Replenish Method Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="replenishEnumID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Replenish Method Enum ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Requirement Method Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reqEnumID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Requirement Method Enum ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductFacility()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFacilitypopup1" tabindex="-1" role="dialog" aria-labelledby="addFacilitypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Facility</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton14>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Facility</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFacility1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFacilityIdArray" formControlName="facilityID" optionlabel="label" placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Minimum Stock
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minStock" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Minimum Stock">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Reorder Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reorderQuantity" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reorder Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Days to Ship
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="dayToShip" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Days to Ship">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Replenish Method Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="replenishEnumID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Replenish Method Enum ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Requirement Method Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="reqEnumID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Requirement Method Enum ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductFacility()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addLocationpopup" tabindex="-1" role="dialog" aria-labelledby="addLocationpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Facility Location</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton15>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Facility Location</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addLocation">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFacilityIdArray" formControlName="facilityID" optionlabel="label" placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Location Seq ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="LocationSeqIdArray"  formControlName="locSeqID" optionlabel="label" placeholder="Enter Location Seq ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Minimum Stock
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minStock" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Minimum Stock">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Move Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="moveQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Move Quantity">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductFacilityLocation()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addLocationpopup1" tabindex="-1" role="dialog" aria-labelledby="addLocationpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Facility Location</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton15>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Facility Location</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addLocation1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Facility ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFacilityIdArray" formControlName="facilityID" optionlabel="label" placeholder="Enter Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Location Seq ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="LocationSeqIdArray"  formControlName="locSeqID" optionlabel="label" placeholder="Enter Location Seq ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Minimum Stock
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="minStock" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Minimum Stock">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Move Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="moveQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Move Quantity">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductFacilityLocation()" class="btn btn-secondary submit-btn">Update </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addGLpopup" tabindex="-1" role="dialog" aria-labelledby="addGLpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product GL Accounts</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton17>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product GL Accounts</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGL">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Account Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="AccountTypeArray" formControlName="accType" optionlabel="label" placeholder="Enter Account Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Organization
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" [options]="OrganizationIdArray" formControlName="organization" optionlabel="label" placeholder="Enter Organization">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">GL Account
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="GLAccountIdArray" formControlName="glAcc" optionlabel="label" placeholder="Enter GL Account">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductGlAccount()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addGLpopup1" tabindex="-1" role="dialog" aria-labelledby="addGLpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product GL Accounts</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton18>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product GL Accounts</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGL1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Account Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="AccountTypeArray" formControlName="accType" optionlabel="label" placeholder="Enter Account Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Organization
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="OrganizationIdArray" formControlName="organization" optionlabel="label" placeholder="Enter Organization">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">GL Account
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="GLAccountIdArray" formControlName="glAcc" optionlabel="label" placeholder="Enter GL Account">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateGlProductAccount()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addPaymentTypepopup" tabindex="-1" role="dialog" aria-labelledby="addPaymentTypepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Payment Method Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton19>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Payment Method Type</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPaymentType">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Payment Method Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getpaymentMethodTypeArray" formControlName="paymentType" optionlabel="label" placeholder="Enter Payment Method Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Purpose
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="productPricePurposeIdArray" formControlName="purpose" optionlabel="label" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductPaymentMethodType()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addPaymentTypepopup1" tabindex="-1" role="dialog" aria-labelledby="addPaymentTypepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Payment Method Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton20>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Payment Method Type</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPaymentType1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Payment Method Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getpaymentMethodTypeArray" formControlName="paymentType" optionlabel="label" placeholder="Enter Payment Method Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Purpose
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="productPricePurposeIdArray" formControlName="purpose" optionlabel="label" placeholder="Enter Purpose">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductPaymentMethodType()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addMaintenancepopup" tabindex="-1" role="dialog" aria-labelledby="addMaintenancepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Maintenance</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton35>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Maintenance</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addMaintenance">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Maintenance Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductMaintTypeArray" formControlName="typeID" optionlabel="label" placeholder="Enter Product Maintenance Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Maintenance Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="name" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Maintenance Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Main Template Work Effort ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="WorkEffortNameValueArray"  formControlName="workEffortID" optionlabel="label" placeholder="Enter Main Template Work Effort ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Interval Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="intQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Interval Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Interval UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="intUomID" optionlabel="label" placeholder="Enter Interval UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Interval Meter Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductMeterTypeArray" formControlName="meterTypeID" optionlabel="label" placeholder="Enter Interval Meter Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Repeat Count
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="repeatCount" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Repeat Count">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductMaint()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addMaintenancepopup1" tabindex="-1" role="dialog" aria-labelledby="addMaintenancepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Maintenance</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton36>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Maintenance</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addMaintenance1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Maintenance Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductMaintTypeArray" formControlName="typeID" optionlabel="label" placeholder="Enter Product Maintenance Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Maintenance Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="name" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Maintenance Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Main Template Work Effort ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="WorkEffortNameValueArray"  formControlName="workEffortID" optionlabel="label" placeholder="Enter Main Template Work Effort ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Interval Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="intQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Interval Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Interval UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="intUomID" optionlabel="label" placeholder="Enter Interval UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Interval Meter Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductMeterTypeArray" formControlName="meterTypeID" optionlabel="label" placeholder="Enter Interval Meter Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Repeat Count
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="repeatCount" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Repeat Count">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductMaint()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addMeterspopup" tabindex="-1" role="dialog" aria-labelledby="addMeterspopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Meters</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton23>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Meters</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addMeter">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Meter Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductMeterTypeIdArray" formControlName="meterTypeID" optionlabel="label" placeholder="Enter Product Meter Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Meter UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="currencyArray" formControlName="meterUomID" optionlabel="label" placeholder="Enter Meter UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Meter Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="meterName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Meter Name">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductMeter()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addMeterspopup1" tabindex="-1" role="dialog" aria-labelledby="addMeterspopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Product Meters</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton24>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Meters</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addMeter1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Meter Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductMeterTypeIdArray" formControlName="meterTypeID" optionlabel="label" placeholder="Enter Product Meter Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Meter UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="currencyArray" formControlName="meterUomID" optionlabel="label" placeholder="Enter Meter UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Meter Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="meterName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Meter Name">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductMeter()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addSubsResourcepopup" tabindex="-1" role="dialog" aria-labelledby="addSubsResourcepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Subscription Resources</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton33>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Subscription Resources</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addSubsResource">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Subscription Resource ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="SubscriptionResourceArray" formControlName="susResourceID" optionlabel="label" placeholder="Enter Subscription Resource ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Purchase From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseFromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Purchase Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Max Life Time
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxLifeTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Max Life Time UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="lifeTimeUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time UOM ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Available Time
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="availTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Available Time UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="availUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time UOM ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Count Limit
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="countLimit" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Count Limit">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Time
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="useTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Time UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="useUomID" optionlabel="label" placeholder="Enter Use Time UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="RoleTypeIdArray" formControlName="useRoleTypeID" optionlabel="label" placeholder="Enter Use Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-8 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">The subscription will be extended at the end of the subscription period with a new order
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="catalogSubscriptionArray" formControlName="extendPeriod" optionlabel="label" placeholder="Enter The subscription will be extended at the end of the subscription period with a new order">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-8 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Time period (before the end of the thrudate) for automatic extension of the subscription
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="autoExtension" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Time period (before the end of the thrudate) for automatic extension of the subscription">
                                                    </div>
                                                    <div class="col-lg-8 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Unit Of Measure used for the automatic extension of the subscription	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="measureUnit" optionlabel="label" placeholder="Enter Unit Of Measure used for the automatic extension of the subscription	">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Grace Period on Expiry
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="graceExpiry" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Grace Period on Expiry UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="graceExpiryUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry UOM ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductSubscriptionResourceSr()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addSubsResourcepopup1" tabindex="-1" role="dialog" aria-labelledby="addSubsResourcepopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Product Subscription Resources</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton34>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Subscription Resources</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addSubsResource1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Subscription Resource ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="SubscriptionResourceArray" formControlName="susResourceID" optionlabel="label" placeholder="Enter Subscription Resource ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Purchase From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseFromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Purchase Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Max Life Time
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxLifeTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Max Life Time UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="lifeTimeUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time UOM ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Available Time
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="availTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Available Time UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="availUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time UOM ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Count Limit
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="countLimit" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Count Limit">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Time
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="useTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Time UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="useUomID" optionlabel="label" placeholder="Enter Use Time UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Use Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="RoleTypeIdArray" formControlName="useRoleTypeID" optionlabel="label" placeholder="Enter Use Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-8 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">The subscription will be extended at the end of the subscription period with a new order
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="catalogSubscriptionArray" formControlName="extendPeriod" optionlabel="label" placeholder="Enter The subscription will be extended at the end of the subscription period with a new order">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-8 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Time period (before the end of the thrudate) for automatic extension of the subscription
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="autoExtension" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Time period (before the end of the thrudate) for automatic extension of the subscription">
                                                    </div>
                                                    <div class="col-lg-8 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Unit Of Measure used for the automatic extension of the subscription	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="measureUnit" optionlabel="label" placeholder="Enter Unit Of Measure used for the automatic extension of the subscription	">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Grace Period on Expiry
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="graceExpiry" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Grace Period on Expiry UOM ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="graceExpiryUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry UOM ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductSubscriptionResource()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addCategoriesPopup" tabindex="-1" role="dialog" aria-labelledby="addPublishpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add Categories</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton60>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Aadd Categories</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editCategories">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Categories	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="EditProductQuickAdminCategoriesArray" formControlName="categories" optionlabel="label" placeholder="Enter Use Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-5 main-submit-button">
                                            <button type="submit" (click)="addCategoryQuickAdmin()"  class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFeatureTypePopup" tabindex="-1" role="dialog" aria-labelledby="addPublishpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="this.show" class="color-black pl-1"> Feature Type</span>
                <span *ngIf="!this.show" class="color-black pl-1">Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton62>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="this.show"><a>Feature Type</a></li>
                                <li *ngIf="!this.show"><a>Type</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFeatureType" *ngIf="this.show">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Types	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="EditProductQuickAdminFeatureTypeArray" formControlName="featuretype" optionlabel="label" placeholder="Enter Use Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-5 main-submit-button">
                                            <button type="submit" (click)="getStandardFeatures()"  class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                    <form class="w-100" [formGroup]="addFeatureTypeId" *ngIf="!this.show">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">{{this.addFeatureType.value.featuretype}}
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="StandardFeaturesIdArray" formControlName="featuretypeId" optionlabel="label" placeholder="Enter Use Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-5 main-submit-button">
                                            <button type="submit" (click)="applyFeatureToProductNew()"  class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editProductQuickAdmin" tabindex="-1" role="dialog" aria-labelledby="addPublishpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Product Quick Admin</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton56>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Edit Product Quick Admin</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editProductQuickAdmin">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="productId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="productName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-5 main-submit-button" style="margin-left: 41%">
                                            <button type="submit" (click)="updateProductQuickAdminName()" 
                                            class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addPublishpopup" tabindex="-1" role="dialog" aria-labelledby="addPublishpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Publish View</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton57>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Publish View</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPublish">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-5 main-submit-button">
                                            <button type="submit" (click)="quickAdminAddCategories()" class="btn btn-danger ">Delete From Site</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addVendorpopup" tabindex="-1" role="dialog" aria-labelledby="addVendorpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Vendor Product</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton25>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Vendor Product</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2" style="overflow-y: inherit !important;">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addVendor">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Vendor Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyID" optionlabel="label" placeholder="Enter Vendor Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Store Group ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductStoreGroupIDArray" formControlName="groupID" optionlabel="label" placeholder="Enter Product Store Group ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createVendorProduct()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addConfigpopup" tabindex="-1" role="dialog" aria-labelledby="addConfigpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Configurations</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton47>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Configurations</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addConfig">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Configuration Item
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ConfigItemIdArray" formControlName="configItem" optionlabel="label" placeholder="Enter Configuration Item">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Long Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="longDesc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Long Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Configuration Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="configTypeIDArray" formControlName="configTypeID" optionlabel="label" placeholder="Enter Configuration Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Default Config Option ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="optionID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Config Option ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Is Mandatory
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="catalogSubscriptionArray" formControlName="isMandatory" optionlabel="label" placeholder="Enter Is Mandatory">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductConfig()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addConfigpopup1" tabindex="-1" role="dialog" aria-labelledby="addConfigpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">UpdateProduct Configurations</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton48>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Configurations</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addConfig1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Configuration Item
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ConfigItemIdArray" formControlName="configItem" optionlabel="label" placeholder="Enter Configuration Item">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Long Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="longDesc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Long Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Configuration Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="configTypeIDArray" formControlName="configTypeID" optionlabel="label" placeholder="Enter Configuration Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Default Config Option ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="optionID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Config Option ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Is Mandatory
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="catalogSubscriptionArray" formControlName="isMandatory" optionlabel="label" placeholder="Enter Is Mandatory">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductConfig()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addWEpopup" tabindex="-1" role="dialog" aria-labelledby="addWEpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Work Effort</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton28>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Work Effort</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2" style="overflow-y: inherit !important;">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addWE">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Work Effort ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProWorkEffortIdArray" formControlName="workEffortID" optionlabel="label" placeholder="Enter Work Effort ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Work Effort Good Std Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="WorkEffortGoodStdTypeIdDropArray" formControlName="weGoodID" optionlabel="label" placeholder="Enter Work Effort Good Std Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="solicitationArray" formControlName="status" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Estimated Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="estimatedQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Estimated Cost
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="estimatedCost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Cost">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProWorkEffortGoodStandard()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addWEpopup1" tabindex="-1" role="dialog" aria-labelledby="addWEpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Work Effort</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton29>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Work Effort</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2" style="overflow-y: inherit !important;">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addWE1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Work Effort ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProWorkEffortIdArray" formControlName="workEffortID" optionlabel="label" placeholder="Enter Work Effort ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Work Effort Good Std Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="WorkEffortGoodStdTypeIdDropArray" formControlName="weGoodID" optionlabel="label" placeholder="Enter Work Effort Good Std Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="solicitationArray" formControlName="status" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Estimated Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="estimatedQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Estimated Cost
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="estimatedCost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Cost">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProWorkEffortGoodStandard()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAssocPartypopup" tabindex="-1" role="dialog" aria-labelledby="addAssocPartypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Associate Party to Product</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton26>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Associate Party to Product</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAssocParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray"  formControlName="partyID" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="RoleTypeIdArray" formControlName="roleTypeID" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="addPartyToProduct()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAssocPartypopup1" tabindex="-1" role="dialog" aria-labelledby="addAssocPartypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Associate Party to Product</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton27>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Associate Party to Product</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAssocParty1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray"  formControlName="partyID" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="RoleTypeIdArray" formControlName="roleTypeID" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updatePartyToProduct()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addCommpopup" tabindex="-1" role="dialog" aria-labelledby="addCommpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Communication Event</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton32>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Communication Event</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addComm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Parent Comm Event ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CatalogCommunicationEventIdArray" formControlName="commEventID" optionlabel="label" placeholder="Enter Parent Comm Event ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Communication Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CommTypeIDArray" formControlName="commTypeID" optionlabel="label" placeholder="Enter Communication Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CataCommEventStatusArray" formControlName="status" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party from
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyFrom" optionlabel="label" placeholder="Enter Party from">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="roleTypeID" optionlabel="label" placeholder="Enter Party To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Contact Mech Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ContactMechTypeIDArray" formControlName="mechTypeID" optionlabel="label" placeholder="Enter Contact Mech Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Contact Mech
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="FromContactMechListArray" formControlName="fromMech" optionlabel="label" placeholder="Enter From Contact Mech">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">To Contact Mech
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="FromContactMechListArray" formControlName="toMech" optionlabel="label" placeholder="Enter To Contact Mech">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Role Type ID From
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="RoleTypeIdTypeArray" formControlName="roleTypeIDFrom" optionlabel="label" placeholder="Enter Role Type ID From">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Role Type ID To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="RoleTypeIdTypeArray" formControlName="roleTypeIDTo" optionlabel="label" placeholder="Enter Role Type ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Contact List ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ContactListIdArray" formControlName="contactListID" optionlabel="label" placeholder="Enter Contact List ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Start Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="startDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Finish Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="finishDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Finish Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Subject
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="subject" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Subject">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Reason Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ReasonEnumIdsArray" formControlName="reasonEnumID" optionlabel="label" placeholder="Enter Reason Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Content Mime Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CatalogContentMimeTypeIdArray" formControlName="contentMimeTypeID" optionlabel="label" placeholder="Enter Content Mime Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Content
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="content" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Content">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Note
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="note" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Note">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Message ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="msgID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Message ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createCatalogCommunicationEvent()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addGroupOrderpopup" tabindex="-1" role="dialog" aria-labelledby="addGroupOrderpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Group Order</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton30>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Group Order</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGroupOrder">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Deal Quantity<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="dealQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Deal Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Deal Start Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="dealStartDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Deal Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Deal End Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="dealEndDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Deal End Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductGroupOrder()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addGroupOrderpopup1" tabindex="-1" role="dialog" aria-labelledby="addGroupOrderpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Group Order</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton31>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Group Order</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGroupOrder1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Deal Quantity<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="dealQty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Deal Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Deal Start Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="dealStartDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Deal Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Deal End Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="dealEndDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Deal End Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductGroupOrder()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="New_product" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Product</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton37>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Product</a></li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="wordingValid(createEditProductForm.value.productTypeId)"><a>
                                Wording And Comment</a></li>

                                <li [ngClass]="activeCategory==4?'active':''" (click)="virtualValid(createEditProductForm.value.internalName)"><a>
                                Virtual Product </a></li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="primaryValid(createEditProductForm.value.virtualVariantMethodEnum)"><a>
                                Primary Category </a></li>
                                <li [ngClass]="activeCategory==6?'active':''" (click)="dateValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Dates </a></li>
                                <li [ngClass]="activeCategory==7?'active':''" (click)="inventoryValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Inventory </a></li>
                                <li [ngClass]="activeCategory==8?'active':''" (click)="ratingValid(createEditProductForm.value.inventoryItemTypeId)"><a>
                                Rating </a></li>
                                <li [ngClass]="activeCategory==9?'active':''" (click)="amountValid(createEditProductForm.value.ratingTypeEnum)"><a>
                                Amount </a></li>
                                <li [ngClass]="activeCategory==10?'active':''" (click)="measureValid(createEditProductForm.value.amountUomTypeId)"><a>
                                Measures </a></li>
                                <li [ngClass]="activeCategory==11?'active':''" (click)="shipingValid(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)"><a>
                                Shipping </a></li>
                                <li [ngClass]="activeCategory==12?'active':''" (click)="shopingValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                ShoppingCart </a></li>
                                <li [ngClass]="activeCategory==13?'active':''" (click)="shopingCartValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                Miscellaneous </a></li>



                            </ul>
                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createEditProductForm">
                                        <div *ngIf="activeCategory==2">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product Type <span
                                                            style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="productTypeIdArray" placeholder="Select Product Type" formControlName="productTypeId">
                                                                </p-dropdown>
                                                                <small class="text-danger" *ngIf="!!createEditProductForm.controls.productTypeId.invalid && !!createEditProductForm.controls.productTypeId.touched">Required</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">
                                                            Product ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID" formControlName="productId">
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 14%;">
                                                    <button type="submit" (click)="selectProduct(createEditProductForm.value.productTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==3">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">
                                                            Internal Name <span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Internal Name" formControlName="internalName">
                                                                <small class='text-danger' *ngIf="!!createEditProductForm.controls.internalName.invalid && !! createEditProductForm.controls.internalName.touched">Required</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Brand Name</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Brand Name" formControlName="brandName">
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">Product Name</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Name" formControlName="productName">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Comments </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments" formControlName="comments">
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 13%;">
                                                    <button type="submit" (click)="selectworking(createEditProductForm.value.internalName)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==4">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputC">
                                                                <label for="exampleInputEmail1">
                                                            Is VIRTUAL Product ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Is Virtual Product" formControlName="isVirtual">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">
                                                            Is VARIANT Product ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Is Variant Product" formControlName="isVariant"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputC">
                                                                <label for="exampleInputEmail1">Virtual Variant
                                                            Method<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="virtualVariantMethodArray" placeholder="Select Virtual Variant Method" formControlName="virtualVariantMethodEnum">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 9%;">
                                                    <button type="submit" (click)="selectvirtual(createEditProductForm.value.virtualVariantMethodEnum)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==5">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">
                                                            Primary Category<span style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="categories" placeholder="Select Primary Category" formControlName="primaryProductCategoryId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="main-submit-button" style="margin-right: 61%;">
                                                    <button type="submit" (click)="selectPrimary(createEditProductForm.value.primaryProductCategoryId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==6">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputGI">
                                                                <label for="exampleInputEmail1">
                                                            Introduction Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="introductionDate">
                                                                <!-- <p-calendar placeholder="Select Date" formControlName="introductionDate">
                                                                </p-calendar> -->
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Release
                                                            Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="releaseDate">
                                                                <!-- <p-calendar formControlName="releaseDate" placeholder="Select Date">
                                                                </p-calendar> -->
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputGI">
                                                                <label for="exampleInputEmail1">Sales
                                                            Discontinuation Through Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="salesDiscontinuationDate">
                                                                <!-- <p-calendar formControlName="salesDiscontinuationDate" placeholder="Select Date">
                                                                </p-calendar> -->
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Support
                                                            Discontinuation Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                placeholder="Select Date" formControlName="supportDiscontinuationDate">
                                                                <!-- <p-calendar formControlName="supportDiscontinuationDate" placeholder="Select Date">
                                                                </p-calendar> -->
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 5%;">
                                                    <button type="submit" (click)="selectDate()" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==7">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Disc. When Inv. Not
                                                            Avail?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="salesDiscWhenNotAvail">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Requirement Method
                                                            Enum ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="requirementMethodEnumIDArray" placeholder="Select Enum ID" formControlName="requirementMethodEnumId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Inventory
                                                            Message</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Inventory Message" formControlName="inventoryMessage">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">InventoryItem Type
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="inventoryItemTypeIDArray" placeholder="Select Inventory Item Type Id" formControlName="inventoryItemTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Require
                                                            Inventory</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="requireInventory">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Lot ID</label>


                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="commandingArray" placeholder="Select Lot Id" formControlName="lotIdFilledIn">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="margin-right: 7%;">
                                                    <button type="submit" (click)="selecInventory(createEditProductForm.value.inventoryItemTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==8">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">
                                                            Rating Type Enum<span style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="ratingTypeEnumArray" placeholder="Select Rating Type Enum" formControlName="ratingTypeEnum">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Rating</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rating" formControlName="productRating">
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 11%;">
                                                    <button type="submit" (click)="selectRating(createEditProductForm.value.ratingTypeEnum)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==9">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">
                                                            Require Amount</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="requireAmount">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Amount Uom Type
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="amountUomTypeIDArray" placeholder="Select Amount Uom Type Id" formControlName="amountUomTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 13%;">
                                                    <button type="submit" (click)="selectAmount(createEditProductForm.value.amountUomTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==10">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Product
                                                            Height</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Height" formControlName="productHeight">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Height Uom
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Height Uom ID" formControlName="heightUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Height</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Height" formControlName="shippingHeight">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Width</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Width" formControlName="productWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Width Uom ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Width Uom ID" formControlName="widthUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Width</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Width" formControlName="shippingWidth">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Product
                                                            Depth</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Depth" formControlName="productDepth">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Depth Uom ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Depth Uom ID" formControlName="depthUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Depth</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Depth" formControlName="shippingDepth">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Diameter</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Diameter" formControlName="productDiameter">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Diameter Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Diameter Uom ID" formControlName="diameterUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Weight</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Weight" formControlName="productWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Weight Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="weightUomIDArray" placeholder="Select Weight Uom ID" formControlName="weightUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Weight</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Weight" formControlName="shippingWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Quantity
                                                            Included</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity Included" formControlName="quantityIncluded">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Quality Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="quantityUomIDArray" placeholder="Select Quality Uom ID" formControlName="quantityUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-6 col-12">
                                                        <label for="exampleInputEmail1"></label>
                                                        <div class="main-submit-button" style="    margin-right: -86%;">
                                                            <button type="submit" (click)="selectMeasure(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)" class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==11">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputG">
                                                                <label for="exampleInputEmail1">Pieces
                                                            Included</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Pieces
                                                    Included" formControlName="piecesIncluded">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">In Shipping Box
                                                            ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="inShippingBox">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputG">
                                                                <label for="exampleInputEmail1">Default Shipment Box
                                                            Type ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="defaultShipmentBoxTypeIDArray" placeholder="Select Shipment" formControlName="defaultShipmentBoxTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Charge
                                                            Shipping</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select Charge Shipping" formControlName="chargeShipping">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 3%;">
                                                    <button type="submit" (click)="selectShiping(createEditProductForm.value.defaultShipmentBoxTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==12">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputH">
                                                                <label for="exampleInputEmail1">
                                                            Allow order decimal quantity</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select Quantity" formControlName="orderDecimalQuantity">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 55%;">
                                                    <button type="submit" (click)="selectShopingCart()" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==13">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Returnable</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="returnable">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Include In
                                                            Promotions</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="includeInPromotions">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Taxable</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="taxable">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="keyword">AutoCreate Keywords</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="autoCreateKeywords">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12" *ngIf="editMode">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="createdBy">Created By
                                                        </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" [value]="product.createdByUserLogin" readonly>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="lastModifiedBy">Last Modified By
                                                        </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" [value]="product.lastModifiedByUser" readonly>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button " style="margin-right: 16%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="submitProductNew()">{{editMode? 'Update' : 'Create'}}
                                                </button>

                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="virtualProduct" tabindex="-1" role="dialog" aria-labelledby="addGroupOrderpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Virtual with Variants</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton40>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Create Virtual with Variants</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="virtualProduct">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Variant Product IDs</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getProductIdArray" formControlName="variantProId" optionlabel="label" placeholder=" Product IDs">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Hazmat
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="hazmatArray" formControlName="Hazmat" optionlabel="label" placeholder="Enter Content Mime Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="addProductVariants()"  class="btn btn-secondary submit-btn">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addCostpopup" tabindex="-1" role="dialog" aria-labelledby="addCostpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Cost Component</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton41>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add New Cost Component</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCost">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Cost Component Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ComponentTypeIdFirstArray" formControlName="typeID" optionlabel="label" placeholder="Enter Cost Component Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Feature ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getProductFeatureIdArray"  formControlName="featureID" optionlabel="label" placeholder="Enter Product Feature ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyID" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Geo ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CatalogGeoIdArray" formControlName="geoID" optionlabel="label" placeholder="Enter Geo ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Work Effort ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="WorkEffortIdsArray" formControlName="workEffortID" optionlabel="label" placeholder="Enter Work Effort ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Fixed Asset ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="FixedAssetIdsArray" formControlName="fixedAssetID" optionlabel="label" placeholder="Enter Fixed Asset ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Cost Component Calc ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CostComponentCalcIdArray" formControlName="calcID" optionlabel="label" placeholder="Enter Cost Component Calc ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Cost
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="cost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Currency
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="currencyUomIdsArray" formControlName="currency" optionlabel="label" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createCostComponent()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addCostCalcpopup" tabindex="-1" role="dialog" aria-labelledby="addCostCalcpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Cost Component Calc</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton42>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Cost Component Calc</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCostCalc">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Cost Component Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ComponentTypeIdComponentCalcArray" formControlName="typeID" optionlabel="label" placeholder="Enter Cost Component Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span style="color: red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Cost Component Calc ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="CostComponentCalcIdArray" formControlName="calcID" optionlabel="label" placeholder="Enter Cost Component Calc ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence Num
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductCostComponentCalc()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAutoCalcpopup" tabindex="-1" role="dialog" aria-labelledby="addAutoCalcpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Auto-Calculate Costs</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton43>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Auto-Calculate Costs</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAutoCalc">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Currency
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="currencyUomIdsArray" formControlName="currency" optionlabel="label" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Cost Component type Prefix
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="TypePrefixArray" formControlName="prefix" optionlabel="label" placeholder="Enter Cost Component type Prefix">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="calculateProductCosts()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAgreementpopup" tabindex="-1" role="dialog" aria-labelledby="addAgreementpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Agreement</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton38>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Agreement</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAgreement">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="getProductIdArray" optionLabel="productId" filter="true" placeholder="Select" formControlName="productId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="partyIdArray" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party Id To<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="partyIdArray" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Role Type Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="RoleTypeIdTypeArray" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdFrom">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Role Type Id To</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="RoleTypeIdTypeArray" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Agreement Type ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="getAgreementTypeListIdArray" optionLabel="agreementTypeId" filter="true" placeholder="Select" formControlName="agreementTypeId">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!addAgreement.controls.agreementTypeId.invalid && !!addAgreement.controls.agreementTypeId.touched">Agreement
                                                        Type ID is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Agreement Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="agreementDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="thruDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="examCreate Commission Agreement
                                                pleInputEmail1">Description<span
                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Text Data</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <textarea name="" id="exampleInputEmail1" formControlName="textData" class="form-control" cols="30" rows="10" placeholder="Enter Text"></textarea>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -17%;"><button type="submit" 
                                            (click)="submitData()" class="btn btn-secondary submit-btn">Create</button>



                                        </div>
                                    </form>
                                   
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addSalesAgreementpopup" tabindex="-1" role="dialog" aria-labelledby="addSalesAgreementpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Sales Agreement</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton39>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Sales Agreement</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addSalesAgreement">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party ID From<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyIDFrom" optionlabel="label" placeholder="Enter Party ID From">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Party ID To<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyIDTo" optionlabel="label" placeholder="Enter Party ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="desc" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Price
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="price" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Price">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Currency
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="currencyUomIdsArray" formControlName="currency" optionlabel="label" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createSalesAgreement()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="ProductAssetUsage" tabindex="-1" role="dialog" aria-labelledby="addSalesAgreementpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Product Asset Usage</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton46>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Asset Usage</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="productAssUsage">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                                                            
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Max.Nbr. of Persons for rental</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="MaxpersonsForRental" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="MaxpersonsForRental">
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Second person add.price percentage
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="secPricePercentage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="secPricePercentage">
                                                    </div>
                                                
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Nth person add.price percentage
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="nthPricePercentage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="nthPricePercentage">
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductAssetUsage()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addRentalPopup" tabindex="-1" role="dialog" aria-labelledby="addSalesAgreementpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Rental</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton44>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Rental</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addRental">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Fixed Asset ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="FixedAssetIdArray" formControlName="fixedAssId" optionlabel="label" placeholder="Enter Party ID From">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Fixed Asset Product Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="assetProductTypeArray" formControlName="fixedProdId" optionlabel="label" placeholder="Enter Party ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="cmnt" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqnence" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Price">
                                                    </div>
                                                
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="Quty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Price">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">UOM
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="UOM" optionlabel="label" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="addFixedAssetProduct()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addRentalPopup1" tabindex="-1" role="dialog" aria-labelledby="addSalesAgreementpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Rental</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton45>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Rental</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addRental1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Fixed Asset ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="FixedAssetIdArray" formControlName="fixedAssId" optionlabel="label" placeholder="Enter Party ID From">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Fixed Asset Product Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="assetProductTypeArray" formControlName="fixedProdId" optionlabel="label" placeholder="Enter Party ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="cmnt" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seqnence" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Price">
                                                    </div>
                                                
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Quantity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="Quty" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Price">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">UOM
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="UseTimeUomIdArray" formControlName="UOM" optionlabel="label" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateFixedAssetProduct()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addFeatureCategorypopup" tabindex="-1" role="dialog" aria-labelledby="addFeatureCategorypopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Feature from Category or Group</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Feature from Category or Group</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFeatureGroup">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Category
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFeatureCategoryIdArray" formControlName="category" optionlabel="label" placeholder="Enter Feature Category">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Group
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getproductFeatureGroupIdArray" formControlName="group" optionlabel="label" placeholder="Enter Feature Group">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Application Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getproductFeatureApplTypeIdArray" formControlName="appType" optionlabel="label" placeholder="Enter Feature Application Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFeatureTypeIDpopup" tabindex="-1" role="dialog" aria-labelledby="addFeatureTypeIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Feature with Type and ID Code</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton50>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Feature with Type and ID Code</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFeatureTypeID">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getproductFeatureTypeIdsArray" formControlName="featureType" optionlabel="label" placeholder="Enter Feature Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">ID Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="idCode" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ID Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Application Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getproductFeatureApplTypeIdArray" formControlName="appType" optionlabel="label" placeholder="Enter Feature Application Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seq" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="applyFeatureToProductFromTypeAndCode()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFeatureIDpopup" tabindex="-1" role="dialog" aria-labelledby="addFeatureIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Feature with ID</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton52>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Feature with ID</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFeatureID">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFeatureTypeIDArray" formControlName="ID" optionlabel="label" placeholder="Enter ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Application Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getproductFeatureApplTypeIdArray" formControlName="appType" optionlabel="label" placeholder="Enter Feature Application Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seq" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="applyFeatureToProduct()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFeatureIDpopup1" tabindex="-1" role="dialog" aria-labelledby="addFeatureIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Product Feature with ID</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton53>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Feature with ID</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFeatureID1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFeatureTypeIDArray" formControlName="ID" optionlabel="label" placeholder="Enter ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Feature Application Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getproductFeatureApplTypeIdArray" formControlName="appType" optionlabel="label" placeholder="Enter Feature Application Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Sequence
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="seq" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateFeatureToProductApplication()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addFeatureInteractionpopup" tabindex="-1" role="dialog" aria-labelledby="addFeatureInteractionpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Feature Interaction</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton49>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Feature Interaction</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFeatureInteraction">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Feature ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFeatureIdArray" formControlName="featureID" optionlabel="label" placeholder="Enter Product Feature ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Feature ID To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFeatureIdArray" formControlName="featureIdTo" optionlabel="label" placeholder="Enter Product Feature ID To">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Interaction Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="InteractionArray" formControlName="interactionType" optionlabel="label" placeholder="Enter Interaction Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="AddProductFeatureIactn()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addProductFeatureAttrpopup" tabindex="-1" role="dialog" aria-labelledby="addProductFeatureAttrpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Product Feature Attributes</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton51>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Feature Attributes</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addProductFeatureAttr">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Feature ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ProductFeatureIdArray" formControlName="featureID" optionlabel="label" placeholder="Enter Product Feature ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Name<span style="color: red">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="attrName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Attr Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="attrValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createProductFeatureApplAttr()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="pricePopUp" tabindex="-1" role="dialog" aria-labelledby="addFeatureTypeIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Price </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton54>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Price</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="productPriceForm">
                                       
                            
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Price Type</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="priceTypesArray"
                                                                formControlName="productPriceTypeId"
                                                                placeholder="Price Type" filter="true"></p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Purpose</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="purposesArray" placeholder="Purpose"
                                                                    formControlName="productPricePurposeId" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Currency UOM ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="currencyUomIdsArray"
                                                                    formControlName="currencyUomId"
                                                                    placeholder="Currency UOM ID" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Product Store Group ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="productStoresArray"
                                                                    formControlName="productStoreGroupId"
                                                                    placeholder="Product Store Group ID" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="fromDate">From Date <span
                                                                    class="text-danger">*</span></label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" placeholder="Form Date"
                                                                formControlName="fromDate">
                                                            <small class="text-danger"
                                                                *ngIf="!!productPriceForm.controls.fromDate.invalid && !!productPriceForm.controls.fromDate.touched">From
                                                                Date is required.</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="fromDate">Through Date</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" placeholder="Through Date"
                                                                formControlName="thruDate">
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="price">Price <span class="text-danger">*</span></label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control" placeholder="Price"
                                                                formControlName="price">
                                                            <small class="text-danger"
                                                                *ngIf="!!productPriceForm.controls.price.invalid && !!productPriceForm.controls.price.touched">Price
                                                                is required.</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Term UOM ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="itemUomIdsArray" formControlName="termUomId"
                                                                placeholder="Term UOM ID" filter="true"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Custom Price Calc Service</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="customPriceCalcServiceIdArray"
                                                                formControlName="customPriceCalcService"
                                                                placeholder="Custom Price Calc Service" filter="true">
                                                            </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="taxPercentage">Tax Percentage</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="taxPercentage" placeholder="Tax Percentage">
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Tax Authority Party</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="taxAuthPartyIdsArray"
                                                                formControlName="taxAuthPartyId"
                                                                placeholder="Tax Authority Party" filter="true">
                                                            </p-dropdown>
                                                            </div>
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Tax Auth Geo ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="taxAuthGeoIdsArray"
                                                                    formControlName="taxAuthGeoId" placeholder="Tax Auth Geo ID"
                                                                    filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Tax in Price</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="catalogSubscriptionArray" formControlName="taxInPrice"
                                                                    placeholder="Tax in Price" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                  
        
                                                </div>
                                                <div class="main-submit-button" style="margin-right: 18%;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn" (click)="createProductPrice()">Create</button>
        
                                                </div>
                                            </div>
                                      
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="pricePopUp1" tabindex="-1" role="dialog" aria-labelledby="addFeatureTypeIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Price </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton55>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Price</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="productPriceForm1">
                                       
                            
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Price Type</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="priceTypesArray"
                                                                formControlName="productPriceTypeId"
                                                                placeholder="Price Type" filter="true"></p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Purpose</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="purposesArray" placeholder="Purpose"
                                                                    formControlName="productPricePurposeId" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Currency UOM ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="currencyUomIdsArray"
                                                                    formControlName="currencyUomId"
                                                                    placeholder="Currency UOM ID" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Product Store Group ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="productStoresArray"
                                                                    formControlName="productStoreGroupId"
                                                                    placeholder="Product Store Group ID" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="fromDate">From Date <span
                                                                    class="text-danger">*</span></label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" placeholder="Form Date"
                                                                formControlName="fromDate">
                                                            <small class="text-danger"
                                                                *ngIf="!!productPriceForm.controls.fromDate.invalid && !!productPriceForm.controls.fromDate.touched">From
                                                                Date is required.</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="fromDate">Through Date</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" placeholder="Through Date"
                                                                formControlName="thruDate">
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="price">Price <span class="text-danger">*</span></label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control" placeholder="Price"
                                                                formControlName="price">
                                                            <small class="text-danger"
                                                                *ngIf="!!productPriceForm.controls.price.invalid && !!productPriceForm.controls.price.touched">Price
                                                                is required.</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Term UOM ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="itemUomIdsArray" formControlName="termUomId"
                                                                placeholder="Term UOM ID" filter="true"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Custom Price Calc Service</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="customPriceCalcServiceIdArray"
                                                                formControlName="customPriceCalcService"
                                                                placeholder="Custom Price Calc Service" filter="true">
                                                            </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="taxPercentage">Tax Percentage</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="taxPercentage" placeholder="Tax Percentage">
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Tax Authority Party</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="taxAuthPartyIdsArray"
                                                                formControlName="taxAuthPartyId"
                                                                placeholder="Tax Authority Party" filter="true">
                                                            </p-dropdown>
                                                            </div>
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Tax Auth Geo ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="taxAuthGeoIdsArray"
                                                                    formControlName="taxAuthGeoId" placeholder="Tax Auth Geo ID"
                                                                    filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="priceType">Tax in Price</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-2">
                                                                <p-dropdown [options]="catalogSubscriptionArray" formControlName="taxInPrice"
                                                                    placeholder="Tax in Price" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                  
        
                                                </div>
                                                <div class="main-submit-button" style="margin-right: 18%;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn" (click)="updateProductPrice()">Update</button>
        
                                                </div>
                                            </div>
                                      
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div> 
<div class="modal fade" id="supplierModalCenter" tabindex="-1" role="dialog" aria-labelledby="addFeatureTypeIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add Product Supplier</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton58>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Product Supplier</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSupplierForm">
                                       
                            
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier
                                                                    <span style="color:red">*</span>
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier"
                                                                    filter="true" [options]="supplierArray" optionlabel="label"
                                                                    formControlName="partyId">
        
                                                                </p-dropdown>
                                                                
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.partyId.invalid && !!createSupplierForm.controls.partyId.touched">
                                                                    Supplier Id is required!</small>
        
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Available From Date<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Available From Date"
                                                                    formControlName="availableFromDate">
                                                            
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.availableFromDate.invalid && !!createSupplierForm.controls.availableFromDate.touched">
                                                                    Available From Date is required!</small>
        
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Min Order Qty<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email"  class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Min Order Qty"
                                                                    formControlName="minimumOrderQuantity">
                                                              
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.minimumOrderQuantity.invalid && !!createSupplierForm.controls.minimumOrderQuantity.touched">
                                                                    Min Order Qty is required!</small>
        
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Currency Uom ID
                                                                    <span style="color:red">*</span>
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown  placeholder="Enter Currency Uom ID"
                                                                    filter="true" [options]="currencyArray" optionlabel="label"
                                                                    formControlName="currencyUomId">
        
                                                                </p-dropdown>
                      
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.currencyUomId.invalid && !!createSupplierForm.controls.currencyUomId.touched">
                                                                    Currency Uom ID is required!</small>
        
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Available Through Date
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Available Through Date"
                                                                    formControlName="availableThruDate">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Pref Order ID</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier Pref Order ID"
                                                                    filter="true" [options]="supplierPrefOrderArray"
                                                                    optionlabel="label" formControlName="supplierPrefOrderId">
        
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Rating Type ID</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier Rating Type ID"
                                                                    filter="true" formControlName="supplierRatingTypeId">
        
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Standard Lead Time Days
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Standard Lead Time Days"
                                                                    formControlName="standardLeadTimeDays">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Order Qty Increments
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Order Qty Increments"
                                                                    formControlName="orderQtyIncrements">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Units Included
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Units Included"
                                                                    formControlName="unitsIncluded">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Quantity UomId</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Quantity UomId" filter="true"
                                                                    [options]="quantityUomIDArray" optionlabel="label"
                                                                    formControlName="quantityUomId">
        
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Agreement ID
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Agreement ID"
                                                                    formControlName="agreementId">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Agreement Item Seq ID
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Agreement Item Seq ID"
                                                                    formControlName="agreementItemSeqId">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Last Price<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" placeholder="Enter Last Price"
                                                                    formControlName="lastPrice">
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.lastPrice.invalid && !!createSupplierForm.controls.lastPrice.touched">
                                                                    Last Price is required!</small>
        
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Shipping Price
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Shipping Price"
                                                                    formControlName="shippingPrice">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Product Name
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Supplier Product Name"
                                                                    formControlName="supplierProductName">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Product ID<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier Product ID"
                                                                    filter="true" formControlName="supplierProductId"
                                                                    [options]="ProductsArray" optionlabel="label">
        
                                                                </p-dropdown>
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.supplierProductId.invalid && !!createSupplierForm.controls.supplierProductId.touched">
                                                                    Supplier ProductId is required!</small>
        
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier can drop ship?</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier can drop ship"
                                                                    filter="true" [options]="drop" optionlabel="label"
                                                                    formControlName="canDropShip">
        
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Comments
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" placeholder="Enter Comments"
                                                                    formControlName="comments">
                                                            </div>
                                                        </div>
                                                    </div>
        
        
                                                </div>
                                                <div class="main-submit-button" style="margin-right: 18%;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn" (click)="onSubmit()">Add</button>
        
                                                </div>
                                            </div>
                                      
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="supplierModalCenter1" tabindex="-1" role="dialog" aria-labelledby="addFeatureTypeIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Product Supplier</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton59>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Product Supplier</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSupplierForm1">
                                       
                            
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier
                                                                    <span style="color:red">*</span>
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier"
                                                                    filter="true" [options]="supplierArray" optionlabel="label"
                                                                    formControlName="partyId">
        
                                                                </p-dropdown>
                                                                
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.partyId.invalid && !!createSupplierForm.controls.partyId.touched">
                                                                    Supplier Id is required!</small>
        
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Available From Date<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Available From Date"
                                                                    formControlName="availableFromDate">
                                                            
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.availableFromDate.invalid && !!createSupplierForm.controls.availableFromDate.touched">
                                                                    Available From Date is required!</small>
        
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Min Order Qty<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email"  class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    placeholder="Enter Min Order Qty"
                                                                    formControlName="minimumOrderQuantity">
                                                              
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.minimumOrderQuantity.invalid && !!createSupplierForm.controls.minimumOrderQuantity.touched">
                                                                    Min Order Qty is required!</small>
        
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Currency Uom ID
                                                                    <span style="color:red">*</span>
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown  placeholder="Enter Currency Uom ID"
                                                                    filter="true" [options]="currencyArray" optionlabel="label"
                                                                    formControlName="currencyUomId">
        
                                                                </p-dropdown>
                      
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.currencyUomId.invalid && !!createSupplierForm.controls.currencyUomId.touched">
                                                                    Currency Uom ID is required!</small>
        
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Available Through Date
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Available Through Date"
                                                                    formControlName="availableThruDate">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Pref Order ID</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier Pref Order ID"
                                                                    filter="true" [options]="supplierPrefOrderArray"
                                                                    optionlabel="label" formControlName="supplierPrefOrderId">
        
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Rating Type ID</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier Rating Type ID"
                                                                    filter="true" formControlName="supplierRatingTypeId">
        
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Standard Lead Time Days
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Standard Lead Time Days"
                                                                    formControlName="standardLeadTimeDays">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Order Qty Increments
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Order Qty Increments"
                                                                    formControlName="orderQtyIncrements">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Units Included
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Units Included"
                                                                    formControlName="unitsIncluded">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Quantity UomId</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Quantity UomId" filter="true"
                                                                    [options]="quantityUomIDArray" optionlabel="label"
                                                                    formControlName="quantityUomId">
        
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Agreement ID
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Agreement ID"
                                                                    formControlName="agreementId">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Agreement Item Seq ID
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Agreement Item Seq ID"
                                                                    formControlName="agreementItemSeqId">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Last Price<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" placeholder="Enter Last Price"
                                                                    formControlName="lastPrice">
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.lastPrice.invalid && !!createSupplierForm.controls.lastPrice.touched">
                                                                    Last Price is required!</small>
        
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Shipping Price
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Shipping Price"
                                                                    formControlName="shippingPrice">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Product Name
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp"
                                                                    placeholder="Enter Supplier Product Name"
                                                                    formControlName="supplierProductName">
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier Product ID<span
                                                                        style="color:red">*</span>
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier Product ID"
                                                                    filter="true" formControlName="supplierProductId"
                                                                    [options]="ProductsArray" optionlabel="label">
        
                                                                </p-dropdown>
                                                                <small class="text-danger"
                                                                    *ngIf="!!createSupplierForm.controls.supplierProductId.invalid && !!createSupplierForm.controls.supplierProductId.touched">
                                                                    Supplier ProductId is required!</small>
        
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Supplier can drop ship?</label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <p-dropdown placeholder="Enter Supplier can drop ship"
                                                                    filter="true" [options]="drop" optionlabel="label"
                                                                    formControlName="canDropShip">
        
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="col-lg-12">
                                                        <div class="row">
        
                                                            <div class="col-lg-3 form-group classInputforgeo">
                                                                <label for="exampleInputEmail1">Comments
        
                                                                </label>
                                                            </div>
        
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" placeholder="Enter Comments"
                                                                    formControlName="comments">
                                                            </div>
                                                        </div>
                                                    </div>
        
        
                                                </div>
                                                <div class="main-submit-button" style="margin-right: 18%;">
                                                    <button type="submit"
                                                        class="btn btn-secondary submit-btn" (click)="onUpdate()">Update</button>
        
                                                </div>
                                            </div>
                                      
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="variantsModalCenter" tabindex="-1" role="dialog" aria-labelledby="addSalesAgreementpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Variant</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton61>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Variant Products</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="imageForm">
                                       
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row ml-0">

                                                        <div class="col-lg-3 form-group classInputforgeo">
                                                            <label for="exampleInputEmail1">Variant Product IDs</label>
                                                        </div>

                                                        <div class="col-lg-2">
                                                            <textarea formControlName="productVariant"
                                                                name="productVariant" id="" cols="30"
                                                                rows="10"></textarea>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="main-submit-button" style=" margin-right: 55%; ">
                                            
                                                <button style="margin-left: 54%;" type="submit" (click)="onSubmitVariantCall()"
                                                    class="btn btn-secondary submit-btn">Save</button>
                                                
                                            </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="shippingDimen_Weights" tabindex="-1" role="dialog" aria-labelledby="addFeatureIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Shipping Dimension and Weights
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton63>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Shipping Dimension and Weights</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="shippingDimen_Weights">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Height</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="productHeight" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Width</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="productWidth" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product Depth</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="productDepth" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="weight" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductQuickAdminShipping()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editProductTags" tabindex="-1" role="dialog" aria-labelledby="addFeatureIDpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update Tag
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton64>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Tag</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editProductTag">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="productId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Product ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Tag</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="keyword" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="keyword">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputforgeo">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="getstatusIdsArray" formControlName="statusId" optionlabel="label" placeholder="statusId">
                                                        </p-dropdown>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="updateProductTag()" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>





<app-create-supplier-product *ngIf="showSupplierModal" (supplierModalClosed)="supplierModalClosed()"></app-create-supplier-product>
<app-product-price *ngIf="showPriceModal" (priceModalClosed)="closePriceModal()"></app-product-price>
<app-create-product-variant *ngIf="showVariantModal" (variantsModalClosed)="closeVariantsModal()"></app-create-product-variant>
<ngx-spinner></ngx-spinner>