<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span>
                    <span class="color-gry cursor-pointer titlePanels" routerLink="/financial/{{invoiceType}}/invoices">Invoices </span>
                <span class="color-black pl-1 titlePanels"> > Invoice Summary</span>
                </span>
                <span style="margin-left: 47%;">
                    <p-dropdown filter="true" [options]="invoiceStatus" optionlabel="label" [(ngModel)]="statusId"
                        (ngModelChange)="setStatus($event)" placeholder="Select Status">
                    </p-dropdown>

                    <button type="button" class="btn btn-outline-secondary ml-2"
                        routerLink="/financial/{{invoiceType}}/invoices/send-email"
                        [queryParams]="{invoiceId:invoiceId,isCommission:false}">Send
                        Email</button>
                    <button class="btn btn-outline-secondary ml-2" (click)="downloadPDF()" [disabled]="downloading">
                        <ng-container *ngIf="downloading;else noDownload">
                            PDF <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
                        </ng-container>
                        <ng-template #noDownload>
                            PDF
                        </ng-template>
                    </button>
                </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Invoice Information
                                                        </h4>
                                                        <span style="    margin-right: 2%;" class="edit-buttons">
                                                            <a  data-toggle="modal" (click)="editInvoice();"
                                                            data-target="#exampleModalCenterInvoice">Update</a></span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div *ngIf="invoiceValue" class="color-black container">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Invoice Type</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.invoiceType ? invoiceValue.invoiceType :'N/A'}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Status</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceStatusValue}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Description</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.description ? invoiceValue.description :'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Invoice Message</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.invoiceMessage ? invoiceValue.invoiceMessage :'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article" *ngIf="this.invoiceType=='receiveable'">
                                                                        <p class="col-lg-6 col-12">Supplier ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.partyIdFrom ? invoiceValue.partyIdFrom:'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article" *ngIf="this.invoiceType=='receiveable'">
                                                                        <p class="col-lg-6 col-12">Customer Id
                                                                        </p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.partyId ? invoiceValue.partyId:'N/A'}}</span>
                                                                    </div>
                                                                    <div class="article" *ngIf="this.invoiceType=='payable'">
                                                                        <p class="col-lg-6 col-12">Customer ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.partyIdFrom ? invoiceValue.partyIdFrom:'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article" *ngIf="this.invoiceType=='payable'">
                                                                        <p class="col-lg-6 col-12">Supplier ID
                                                                        </p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.partyId ? invoiceValue.partyId:'N/A'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Role Type ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.roleTypeId ? invoiceValue.roleTypeId : 'N/A'}}
                                                                           </span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Billing Account ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.billingAccountId ? invoiceValue.billingAccountId:'N/A'}}
                                                                           </span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Invoice Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.invoiceDate  ? (invoiceValue.invoiceDate|date:'yyyy-MM-d hh:mm:ss'):'N/A'}}
                                                                           </span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Due Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.dueDate  ? (invoiceValue.dueDate |date:'yyyy-MM-d hh:mm:ss') :'N/A'}}
                                                                               </span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Total
                                                                        </p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.total ? invoiceValue.total:'N/A'}}
                                                                               </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Reference Num
                                                                        </p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.referenceNumber}}
                                                                               </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Date Paid</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.paidDate  ? (invoiceValue.paidDate|date:'yyyy-MM-d hh:mm:ss') :'N/A'}}
                                                                               </span>
                                                                    </div>



                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Status
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body allUserCardDetail">

                                                        <div class="card own-account-table borderTable" *ngIf="invoiceValue">
                                                            <p-table [value]="invoiceValue.status" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                Status Date
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Status
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Change By User Login ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">

                                                                                {{product.statusDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.status}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.changeByUserLoginId}}</td>

                                                                        <!-- <td [ngStyle]="{'width':'190px'}" >
                                                                                                         <div>
                                                                                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                                                             viewBox="0 0 21.223 21.222">
                                                                                                             <g transform="translate(0 -0.004)">
                                                                                                               <g transform="translate(0 1.52)">
                                                                                                                 <path
                                                                                                                   d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                   transform="translate(0 -34.137)" />
                                                                                                               </g>
                                                                                                               <g transform="translate(4.548 0.004)">
                                                                                                                 <g transform="translate(0 0)">
                                                                                                                   <path
                                                                                                                     d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                     transform="translate(-102.409 -0.004)" />
                                                                                                                 </g>
                                                                                                               </g>
                                                                                                             </g>
                                                                                                           </svg>
                                                                                                           <svg xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16"
                                                                                                             viewBox="0 0 26 26">
                                                                                                             <defs>
                                                                                                               <style>
                                                                                                                 .a {
                                                                                                                   fill: #f44336;
                                                                                                                 }
                                                               
                                                                                                                 .b {
                                                                                                                   fill: #fafafa;
                                                                                                                   margin-left: 20px;
                                                                                                                 }
                                                                                                               </style>
                                                                                                             </defs>
                                                                                                             <path class="a fill-color"
                                                                                                               d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                             <path class="b"
                                                                                                               d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                               transform="translate(-147.576 -147.576)" />
                                                                                                           </svg>
                                                                                                         </div>
                                                                                                       </td> -->

                                                                    </tr>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                    - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Roles
                                                        </h4>
                                                        <span class="edit-buttons hCreate" *ngIf="isEditable" data-target="#role_Popup" data-toggle="modal">Create Roles</span>
                                                    </div>

                                                    <div class="panel-body allUserCardDetail">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="invoiceRoles" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                Party ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                            <div style="color: white;"> Name
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Role Type ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Percentage
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Date Time Performed
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Action
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>

                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <ng-container *ngIf="invoiceRoles.length">
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">

                                                                                    {{product.partyId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.party}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.roleTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.percentage}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.datetimePerformed |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <fa-icon *ngIf="isEditable" (click)="deleteInvoiceRole(product.partyId,product.roleTypeId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                   <h4 class="ml-2">No Record Found</h4>
                                                                               </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                                                            - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Applied Payments {{appliedAmount.appliedAmount |currency:'USD'}} Open {{appliedAmount.notAppliedAmount|currency:'USD'}}
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body allUserCardDetail">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="appliedPayments" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">

                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                Total
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Payment ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Amount Applied
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                {{product.total |currency :'USD'}}
                                                                            </div>
                                                                        </td>

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.paymentId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.amountApplied |currency :'USD'}}
                                                                        </td>
                                                                    </tr>


                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                                                                                                            - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>

                                            </div>

                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Items 
                                                        </h4>
                                                        <span class="edit-buttons hCreate" *ngIf="isEditable" data-toggle="modal" data-target="#exampleModalCenter1">Create Items</span>
                                                    </div>
                                                    <div class="panel-body allUserCardDetail">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="invoiceTerms" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color:white">
                                                                                Invoice Term ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                            <div style="color: white;"> Term Type ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Item No
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Item Value
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Term Days
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Text Value
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Uom
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Action
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <ng-container *ngIf="invoiceTerms.length">
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    {{product.invoiceTermId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.itemNo}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termValue}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.termDays}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.textValue}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.uom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <fa-icon *ngIf="isEditable" routerLink="/financial/{{invoiceType}}/invoices/create-new-invoice-term" [queryParams]="{invoiceId:invoiceId,invoiceTermId:product.invoiceTermId}"  class="account-button"  [icon]="faEdit"></fa-icon>
                                                                                <fa-icon *ngIf="isEditable" (click)="deleteInvoiceTerm(product.invoiceTermId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                                                                                                            - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Terms 
                                                        </h4>
                                                        <span class="edit-buttons hCreate" *ngIf="isEditable" data-toggle="modal" data-target="#exampleModalCenter">Create Terms</span>
                                                    </div>
                                                    <div class="panel-body allUserCardDetail">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="invoiceItems" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                Item No
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                            <div style="color: white;"> Invoice Item Type
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Override Gl Account ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Override Org Party ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Inventory Item ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Product ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Product Feature ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Parent Invoice ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Parent Invoice Item Seq ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> UOM
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Taxable Flag
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Unit Price
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Tax Authority Party
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Tax Auth Geo ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Tax Authority Rate Seq ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Sales Opportunity ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Order ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Order Item Seq ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>


                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Total
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>



                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Action
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>

                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <ng-container *ngIf="invoiceItems.length">
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    {{product.invoiceItemSeqId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.invoiceItemType}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.overrideGlAccountId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.overrideOrgPartyId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.inventoryItemId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productFeatureId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.parentInvoiceItemSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.inventoryItemId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.uomId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.taxableFlag=='0'?'No':'Yes'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.quantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.amount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.taxAuthPartyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.taxAuthGeoId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.taxAuthorityRateSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.salesOpportunityId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.total}}
                                                                            </td>




                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <fa-icon *ngIf="isEditable" routerLink="/financial/{{invoiceType}}/invoices/create-new-invoice-item" [queryParams]="{invoiceId:invoiceId,invoiceItemSeqId:product.invoiceItemSeqId}"  class="account-button"  [icon]="faEdit"></fa-icon>
                                                                                <fa-icon *ngIf="isEditable" (click)="deleteInvoiceItem(product.invoiceItemSeqId)" class="text-danger cursor-pointer" [icon]="faTrash"></fa-icon>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-container>

                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                                                                                                                                                        - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Transactions
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body allUserCardDetail">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="transactions" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">

                                                                                Acctg Trans ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name" style="width: 1rem;">
                                                                            <div style="color: white;">
                                                                                Acctg Trans Entry Seq ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Is Posted
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Fiscal Gl Type ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Acctg Trans Type ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Transaction Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Posted Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Gl Journal ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Trans Type Description
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Invoice ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Fixed Asset ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Gl Account ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Product ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Debit Credit Flag
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Amount
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Orig Amount
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Organization Party ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Gl Account Type
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'220px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Account Code
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Account Name
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> GL Account Class
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Party
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Reconcile Status ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Acctg Trans Entry Type ID
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>


                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                {{product.acctgTransId}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.acctgTransEntrySeqId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.isPosted}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.glFiscalTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.acctgTransTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.transactionDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.postedDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.glJournalId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.transTypeDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.invoiceId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.fixedAssetId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.transDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.glAccountId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.debitCreditFlag}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.amount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.origAmount}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.organizationPartyId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.glAccountTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'220px'}">
                                                                            {{product.accountCode}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.accountName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.glAccountClassDescription}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.partyId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.reconcileStatusId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.acctgTransEntryTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                   <h4 class="ml-2">No Record Found</h4>
                                                                               </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                                                                                                        - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>






                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="role_Popup" tabindex="-1" role="dialog" aria-labelledby="role_Popup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Party Role</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonN>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Add New Party Role</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form [formGroup]="createRoleForm">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-3 form-group classInput" style="
                                                                    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -8%;
                                                                    font-size: 10px;
                                                                ">
                                                                        <label for="partyId">Party ID</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                        [options]="partyIds" optionlabel="label"
                                                                        placeholder="select PartyId"
                                                                        formControlName="partyId">
                                                                    </p-dropdown>
                        
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="
                                                                    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                   
                                                                    font-size: 10px;
                                                                ">
                                                                        <label for="partyId">Role Type ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                            [options]="roleTypeIds"
                                                                            optionlabel="label"
                                                                            placeholder="select Role Type Id"
                                                                            formControlName="roleTypeId">
                                                                        </p-dropdown>
                                                                    </div>
                                            
                                                                   
                                            
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-3 form-group classInput" style="
                                                                    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -8%;
                                                                    font-size: 10px;
                                                                ">
                                                                        <label for="date">Date Time
                                                                            Performed</label>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-2">
                                                                        <p-calendar
                                                                            formControlName="datetimePerformed">
                                                                        </p-calendar>
                        
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput" style="
                                                                    text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                  
                                                                    font-size: 10px;
                                                                ">
                                                                        <label for="percentage">Percentage</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" class="form-control"
                                                                        formControlName="percentage">
                                                                    </div>
                                            
                                                                   
                                            
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="
                                                        margin-right: 26%;
                                                    ">
                                                            <button type="submit"
                                                                class="btn btn-secondary submit-btn"
                                                                (click)="submitInvoiceRole()">Submit</button>
                                                            <button class="btn btn-danger cursor-pointer ml-2"
                                                                (click)="reset()">Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenterInvoice" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterInvoice" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Update Invoice</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonNW>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top: 2%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                Update Invoice</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form [formGroup]="updateSalesInvoiceForm">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <p-calendar formControlName="invoiceDate"></p-calendar>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputAB">
                                                                        <label for="exampleInputEmail1">Due Date</label>
                                                                    </div>


                                                                    <div class="col-lg-3">
                                                                        <p-calendar formControlName="dueDate"></p-calendar>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Description</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputAB">
                                                                        <label for="exampleInputEmail1">{{invoiceUrl ==='payable' ? 'Customer':'Supplier'}} Id</label>
                                                                    </div>


                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true" [options]="partyIds" optionlabel="label" formControlName="partyIdFrom" placeholder="Select From Party ID">
                                                                        </p-dropdown>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Role Type ID</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true" [options]="roleTypeIds" optionlabel="label" formControlName="roleTypeId" placeholder="Select Role Type ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputAB">
                                                                        <label for="exampleInputEmail1">Billing Account Id</label>
                                                                    </div>


                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true" [options]="billingAccounts" optionlabel="label" formControlName="billingAccountId" placeholder="Select Billing Account Id">
                                                                        </p-dropdown>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Currency</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true" [options]="currencies" optionlabel="label" formControlName="currencyUomId" placeholder="Select Currency">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputAB">
                                                                        <label for="exampleInputEmail1">Recurrence Info ID</label>
                                                                    </div>


                                                                    <div class="col-lg-3">
                                                                        <input type="text" class="form-control" placeholder="Recurrence Info ID" formControlName="recurrenceInfoId">
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Invoice Message</label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="text" class="form-control" placeholder="Invoice Message" formControlName="invoiceMessage">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputAB">
                                                                        <label for="exampleInputEmail1">Reference Number</label>
                                                                    </div>


                                                                    <div class="col-lg-3">
                                                                        <input type="text" class="form-control" placeholder="Reference Number" formControlName="referenceNumber">
                                                                    </div>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="col-lg-12 col-12 main-submit-button " style="
                                                      margin-left: -8%;
                                                  "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submitInvoice()">Update</button>
                                                    <!--  <button class="btn btn-danger cursor-pointer ml-2"
                                                              (click)="reset()">Cancel</button> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Invoice Term</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" >
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                  background: #F5F7FA!important;">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                        {{editMode ? 'Update':'Create'}} Invoice Term</a></li>



                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">

                                                        <div role="tabpanel" aria-labelledby="headingOne">
                                                            <div class="panel-body">
                                                                <form [formGroup]="invoiceItemForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 10px;">
                                                                                    <label for="invoiceItem">Invoice Item
                                                                                Type</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="invoiceItemTypes" optionlabel="label" formControlName="invoiceItemTypeId" placeholder="Select Invoice Type">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;">
                                                                                    <label for="description">Description</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="description">
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 10px;">
                                                                                    <label for="accountId">Override Gl Account
                                                                                ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="GIAccounts" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Select Account Id">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 10px;">
                                                                                    <label for="itemId">Inventory Item
                                                                                ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="inventoryItems" optionlabel="label" formControlName="inventoryItemId" placeholder="Select Inventory Item">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 10px;">
                                                                                    <label for="productId">Product ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="productIds" optionlabel="label" formControlName="productId" placeholder="Select Product Id">
                                                                                    </p-dropdown>

                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;">
                                                                                    <label for="featureId">Product Feature
                                                                                Id</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="featureIds" optionlabel="label" formControlName="productFeatureId" placeholder="Select Product Id">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 10px;">
                                                                                    <label for="quantity">Quantity</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="quantity">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 10px;">
                                                                                    <label for="uom">Uom</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="uomIds" optionlabel="label" formControlName="uomId" placeholder="Select Uom">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 10px;">
                                                                                    <label for="unitPrice">Unit Price</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="amount">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;">
                                                                                    <label for="flag">Taxable Flag</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" formControlName="taxableFlag" [options]="decisionArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="main-submit-button" style="    margin-right: 25%;">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">{{editMode ?'Update':'Submit'}}</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Invoice Item</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton1>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                  background: #F5F7FA!important;">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                        {{editMode ? 'Update':'Create'}} Invoice Term</a></li>



                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">

                                                        <div role="tabpanel" aria-labelledby="headingOne">
                                                            <div class="panel-body">
                                                                <form [formGroup]="invoiceTermForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group rightFormG" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        
                                                                        font-size: 10px;">
                                                                                    <label for="invoiceTermId">Invoice Term
                                                                                ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="invoiceTermId">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group rightFormH" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 10px;">
                                                                                    <label for="partyId">Term Type ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="invoiceTypeIds" formControlName="termTypeId" optionlabel="label" placeholder="Select Term Type Id">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group rightFormG" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        
                                                                        font-size: 10px;">
                                                                                    <label for="itemNo">Item No</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="invoiceItemSeqId">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group rightFormH" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;">
                                                                                    <label for="termValue">Term Value</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="termValue">
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group rightFormG" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        
                                                                        font-size: 10px;">
                                                                                    <label for="termDays">Term Days</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="termDays">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group rightFormH" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 10px;">
                                                                                    <label for="termValue">Text Value</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="textValue">
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group rightFormG" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        
                                                                        font-size: 10px;">
                                                                                    <label for="termValue">Description</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" id="exampleInputEmail1" formControlName="description">

                                                                                </div>
                                                                                <div class="col-lg-3 form-group rightFormH" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;">
                                                                                    <label for="partyId">Uom</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="uomIds" optionlabel="label" formControlName="uomId" placeholder="Select Term Type Id">
                                                                                    </p-dropdown>
                                                                                </div>



                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="main-submit-button" style="
                                                            margin-right: 17%;
                                                        ">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="submitTerm()">{{editMode ?'Update':'Submit'}}</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>