
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe, Location } from '@angular/common';
@Component({
  selector: 'app-create-protected-view',
  templateUrl: './create-protected-view.component.html',
  styleUrls: ['./create-protected-view.component.css']
})
export class CreateProtectedViewComponent implements OnInit {

  show=false;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  status: string;
  partyIdArray: any[]=[];
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[]=[];
  personId: any;
  invitataionForm:FormGroup;
  fromDate: string;
  inviteId: any;
  getEstimateFrom: string;
  groupId: any;
  viewNameId: any;
  maxHits: any;
  maxHitsDuration: any;
  tarpitDuration: any;
  constructor(readonly spinner: NgxSpinnerService, 
    readonly accountsService: AccountsService, readonly toastr: ToastrService,
    private _location: Location,
    readonly datePipe: DatePipe,  readonly addSkillService: AddSkillService, readonly _FormBuilder: FormBuilder,
    readonly router: Router,  readonly _ToastrService: ToastrService,
    readonly activate:ActivatedRoute) {
      this.invitataionForm = this._FormBuilder.group({
        accessible: [''],
        considered: [''],
        maxVisit:[''],
        ViewName:[''],
      
      
      
        
      });
     }

  ngOnInit(): void {
   
    this.activate.queryParams.subscribe(params => {
      this.groupId = params["groupId"];
      this.viewNameId = params["viewNameId"];
      this.maxHits = params["maxHits"];
      this.maxHitsDuration = params["maxHitsDuration"];
      this.tarpitDuration = params["tarpitDuration"];
   
      
    });
  
    if(this.viewNameId){
     this.show=true;
     setTimeout(() => {
      const formValue = this.invitataionForm;
    formValue.patchValue({
      accessible: this.tarpitDuration,
      considered: this.maxHitsDuration,
      maxVisit:this.maxHits,
      ViewName:this.viewNameId,
    })
    }, 1000); 
    }
    else{
      this.show=false;
    }


  }
 
  
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "groupId":this.groupId,
      "maxHits": this.invitataionForm.value.maxVisit,
      "maxHitsDuration": this.invitataionForm.value.considered,
      "tarpitDuration":this.invitataionForm.value.accessible,
      "viewNameId": this.invitataionForm.value.ViewName,
    
    }
    this.accountsService.updateProtectedViewToSecurityGroup(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmit() {
    this.spinner.show();
 
    const requestData = {
      "groupId":this.groupId,
      "maxHits": this.invitataionForm.value.maxVisit,
      "maxHitsDuration": this.invitataionForm.value.considered,
      "tarpitDuration":this.invitataionForm.value.accessible,
      "viewNameId": this.invitataionForm.value.ViewName,
    
    }
    this.accountsService.addProtectedViewToSecurityGroup(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this._location.back();
  }

}
