import { Component, OnInit, OnDestroy } from '@angular/core';
import {HeaderService} from './../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService} from '../../crm/accounts/accounts.service';
import {DropdownModule} from 'primeng/dropdown';

@Component({
  selector: 'app-view-order-history',
  templateUrl: './view-order-history.component.html',
  styleUrls: ['./view-order-history.component.css']
})
export class ViewOrderHistoryComponent implements OnInit {
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  constructor(
    readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    readonly router: Router  ) {

     }
     offerShowingLineColoumns = [
      { field: 'status', header: 'Shipment Method' },
      { field: 'name', header: 'Date' },
      { field: 'email', header: 'Changed By User' },
    ];
    							
    offerShowingLineData=[{
      status:'DHL Ground	',
      name:'2020-11-30 14:56:59.437	',
      email:'Admin',
    }  ,
  
 ];
 orderItemsColumns = [
  { field: 'status', header:'Product' },
  { field: 'name', header: 'Quantity' },
  { field: 'email', header: 'Unit Price' },
  { field: 'phone', header: 'Adjustments' },
  { field: 'type', header: 'Sub Total' },
];
              
orderItemsData=[{
  status:'GZ-1000 - Tiny Gizmo',
  name:'1',
  email:'US$15.99',
  phone:'US$0.00',
  type:'US$15.99  ',
}  ,

]

  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   
  }

}
