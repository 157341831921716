import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { ProductService } from '../../new-products/product.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-product-price',
    templateUrl: './product-price.component.html',
    styleUrls: ['./product-price.component.css']
})
export class ProductPriceComponent implements OnInit {
    productId: string;
    editMode: boolean;
    productPriceForm: FormGroup;
    activeCategory: number;
    currencyUomIds: any[];
    priceTypes: any[];
    purposes: any[];
    productStores: any[];
    customPrice: any[];
    taxAuthPartyIds: any[];
    taxAuthGeoIds: any[];
    itemUomIds: any[];
    array = [{
        label: 'Y',
        value: 'Y'
    },
    {
        label: 'N',
        value: 'N'
    }];
    editValue: {
        currencyUomId: string,
        fromDate: string,
        productPricePurposeId: string,
        productPriceTypeId: string,
        productStoreGroupId: string
    };
    productPrice: any;
    @Output() priceModalClosed: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('closebutton') closebutton;

    constructor(
        readonly _FormBuilder: FormBuilder, readonly _ToastrService: ToastrService, readonly _ActivatedRoute: ActivatedRoute, readonly  _Router: Router,
        readonly spinner: NgxSpinnerService, readonly _GlobalResourceService: GlobalResourceService, readonly _ProductService: ProductService
    ) {
        this.itemUomIds = [];
        this.activeCategory = 1;
        this.productId = '';
        this.editMode = false;
        this.productPriceForm = this._FormBuilder.group({
            currencyUomId: [''],
            customPriceCalcService: [''],
            fromDate: ['', [Validators.required]],
            price: ['', [Validators.required]],
            productId: [''],
            productPricePurposeId: [''],
            productPriceTypeId: [''],
            productStoreGroupId: [''],
            taxAuthGeoId: [''],
            taxAuthPartyId: [''],
            taxInPrice: [''],
            taxPercentage: [''], termUomId: [''],  thruDate: ['']
        });
        this.currencyUomIds = [];
        this.priceTypes = [];
        this.purposes = [];
        this.productStores = [];
        this.customPrice = [];
        this.taxAuthPartyIds = [];
        this.taxAuthGeoIds = [];
        this.editValue = {
            currencyUomId: '',
            fromDate: '',
            productPricePurposeId: '',
            productPriceTypeId: '',
            productStoreGroupId: ''
        };
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.productId = this._ActivatedRoute.snapshot.queryParams.productId;
        this._ActivatedRoute.queryParams.subscribe(params => {
            this.editValue = {
                currencyUomId: params.currencyUomId,
                fromDate: params.fromDate,
                productPricePurposeId: params.productPricePurposeId,
                productPriceTypeId: params.productPriceTypeId,
                productStoreGroupId: params.productStoreGroupId
            };
        });
        if (this.editValue.productPriceTypeId) {
            this.editMode = true;
            this.getProductPrice();
        }
        this.getCustomPrice();
        this.getProductStoreGroup();
        this.getPriceType();
        this.getCurrency();
        this.getPartyIds();
        this.getPurposes();
        this.getGeoIdAndUomId();
    }
    getProductPrice(): void {
        this.spinner.show();
        this._ProductService.getProductPriceById({
            ...this.editValue, ...{
                productId: this.productId
            }
        })
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.productPriceForm.patchValue({
                        ...data.data, ...{
                            fromDate: data.data.fromDate ? moment(data.data.fromDate).format('YYYY-MM-DD') : '',
                            thruDate: data.data.thruDate ? moment(data.data.thruDate).format('YYYY-MM-DD') : ''
                        }})
                    this.productPrice = data.data;
                    this.productPriceForm.controls.productStoreGroupId.disable();
                    this.productPriceForm.controls.productStoreGroupId.updateValueAndValidity();
                    this.productPriceForm.controls.currencyUomId.disable();
                    this.productPriceForm.controls.currencyUomId.updateValueAndValidity();
                    this.productPriceForm.controls.fromDate.disable();
                    this.productPriceForm.controls.fromDate.updateValueAndValidity();
                    this.productPriceForm.controls.productPricePurposeId.disable();
                    this.productPriceForm.controls.productPricePurposeId.updateValueAndValidity();
                    this.productPriceForm.controls.productPriceTypeId.disable();      
                    this.productPriceForm.controls.productPriceTypeId.updateValueAndValidity();
                }    });    
    }
    getGeoIdAndUomId(): void {
        this.spinner.show();
        this._ProductService.getTaxGeoAndTermUomID()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.itemUomIds = data.data.termUomId.map(value => {
                        return {
                            label: value.description,
                            value: value.uomId
                        };
                    });
                    this.taxAuthGeoIds = data.data.taxGeoId.map(value => {
                        return {
                            label: value.geoName,
                            value: value.geoId
                        };
                    });
                }
            });
           
    }
    getCustomPrice(): void {
        this.spinner.show();
        this._ProductService.getCustomPrice()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.customPrice = data.data.map(value => {
                        return {
                            label: value.description,
                            value: value.customMethodId
                        };
                    });
                }
            });
            
    }
    getProductStoreGroup(): void {
        this.spinner.show();
        this._ProductService.getProductStoreGroup()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.productStores = data.data.map(value => {
                        return {
                            label: value.productStoreGroupName,
                            value: value.productStoreGroupId
                        };
                    });
                }
            });
          
    }
    getPriceType(): void {
        this.spinner.show();
        this._ProductService.getPriceType()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.priceTypes = data.data.map(value => {
                        return {
                            label: value.description,
                            value: value.productPriceTypeId
                        };
                    });
                }
            });
           
    }
    getPurposes(): void {
        this.spinner.show();
        this._ProductService.getPricePurpose()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.purposes = data.data.map(value => {
                        return {
                            label: value.description,
                            value: value.productPricePurposeId
                        };
                    });
                }
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.taxAuthPartyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
          
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomIds = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    update(): void {
        this.spinner.show();
        if (this.productPriceForm.valid) {
            this._ProductService.updateProductPrice({
                ...this.productPrice, ...this.productPriceForm.value, ...{

                    fromDate: this.editValue.fromDate,
                    thruDate: this.productPriceForm.value.thruDate ? moment(this.productPriceForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
       
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {

            if (this.productPriceForm.valid) {
                this._ProductService.createProductPrice({
                    ...this.productPriceForm.value, ...{
                        fromDate: this.productPriceForm.value.fromDate ? moment(this.productPriceForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                        thruDate: this.productPriceForm.value.thruDate ? moment(this.productPriceForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
                        productId: this.productId
                    }
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.modalClose();
                            this.spinner.hide();
                            this.cancelSubmit();
                        }
                    })
            }
        }
       
    }
    cancelSubmit(): void {
        this.productPriceForm.reset();
        this._Router.navigate([`/facilities/catalog/products/detail-product-summary/${this.productId}`]);
    }

    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.priceModalClosed.emit();
      }

    ngOnDestroy(): void {
        this.spinner.hide();
          }
}