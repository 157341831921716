import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { BillingAccountService } from '../billing-account.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-billing',
  templateUrl: './create-billing.component.html',
  styleUrls: ['./create-billing.component.css']
})
export class CreateBillingComponent implements OnInit {
  billingAccountForm: FormGroup;
  activeCategory = 2;
  currencyUomIds: any[];
  partyIds: any[];
  editMode: boolean;
  billingAccountId: string;
  billingAccount: any;
  contactMechIds: any[];
  constructor(
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _BillingAccountService: BillingAccountService,
    readonly _ToastrService: ToastrService,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder
  ) {
    this.currencyUomIds = [];



    this.partyIds = [];
    this.editMode = false;
    this.contactMechIds = [];
    this.billingAccountForm = this._FormBuilder.group({
      accountCurrencyUomId: [''],
      accountLimit: [''],
      availableBalance: [0],
      contactMechId: [''],
      description: ['', [Validators.required]],
      externalAccountId: [''],
      fromDate: [''],
      partyId: ['', [Validators.required]],
      thruDate: ['']
    });
    this.billingAccountId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.billingAccountId = this._ActivatedRoute.snapshot.queryParams.billingAccountId;
    if (this.billingAccountId) {
      this.editMode = true;
      this._BillingAccountService.getBillingAccountById(this.billingAccountId)
        .then(data => {
          this.billingAccount = data.data;
          this.billingAccountForm.patchValue({
            ...data.data, ...{
              fromDate: data.data.fromDate ? new Date(data.data.fromDate) : '',
              thruDate: data.data.thruDate ? new Date(data.data.thruDate) : ''
            }
          });
        });
    }
    this.getContactMechId();
    this.getCurrency();
    this.getPartyIds();
  }
  getContactMechId(): void {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountContactMechIdList(this.billingAccountId)
      .then(data => {
        this.spinner.hide();
        this.contactMechIds = data.data.map(value => {
          return {
            label: value.description,
            value: value.contactMechId
          };
        });
      });
     
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyUomIds = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
    
  }
  update(): void {
    this.spinner.show();
    if (this.billingAccountForm.valid) {
      this._BillingAccountService.updateBillingAccount(this.billingAccountId, {
        ...this.billingAccount, ...this.billingAccountForm.value, ...{
          fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.billingAccountForm.reset();
            this._Router.navigate(['/financial/payable/billing-account']);
          }
        });
    }
  
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.billingAccountForm.valid) {
        this._BillingAccountService.createBillingAccount({
          ...this.billingAccountForm.value, ...{
            fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
            thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.billingAccountForm.reset();
              this.spinner.hide();
              this._Router.navigate(['/financial/payable/billing-account']);
            }
          });
      }
    }
  
  }
  reset(): void {
    this.billingAccountForm.reset();
    this._Router.navigate(['/financial/payable/billing-account']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
