<div class="container" >
	<div class="d-flex justify-content-center own-login-container">
		<div class="card" style="height:408px!important;">
			<div class="card-header">
				<h3>Confirm Password

				</h3>
				<div class="d-flex justify-content-end social_icon">
					
				</div>
			</div>

			<div class="card-body" style="padding: 0.5rem!important;">
			<br>
                    <form #resetPasswordForm="ngForm">
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                            
                            </div>
                            <div class="input-group form-group">
                            <input 
                            class="form-control" placeholder="New Password"
                            type="password" required #password="ngModel" name="password" [(ngModel)]="newPassword"
                            pattern="(?=^.{6,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$">
                            <!-- { this.username} -->
                        </div> <br>
                            <div class="vaiidate-field" *ngIf="password.errors && ( password.touched || resetPasswordForm.submitted)">
                                <p [hidden]="!password.errors.required">Password is required</p>
                                <p [hidden]="!password.errors.pattern">Password should have atleast 1 small-case letter,
                                  1 Capital letter, 1 digit, 1 special character and the
                                  length should be between 6-12 characters.</p>
                              </div>
                        </div>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                            
                            </div>
                            <div class="input-group form-group">
                            <input  placeholder="Confirm Password"
                            class="form-control"
                            type="password" required #confirmPassword="ngModel"  name="confirmPassword" [(ngModel)]="confirmPasswords">
                            <!-- { this.username} -->
                        </div><br>
                            <div class="vaiidate-field" *ngIf="newPassword !== confirmPasswords">
                                <p>Password must match</p>
                              </div>
                        </div>
                        <div class="input-group form-group">
                            <input 
                            class="form-control" placeholder="Tenant Id"
                            type="text"  #tenant="ngModel" name="tenant" [(ngModel)]="tenantID">
                            <!-- { this.username} -->
                        </div> 
                    
                    
                    
                    
                        
                        <div class="form-group">
                            <button type="submit"  value="Login" (click)="submit();"
                                class="btn float-right login_btn">Save</button>
                        </div>
                    </form>
               
           
		</div>
	</div>
</div>

