import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main-detail-page',
  templateUrl: './main-detail-page.component.html',
  styleUrls: ['./main-detail-page.component.css']
})
export class MainDetailPageComponent implements OnInit {
  activeCategory = 1;

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton10') closebutton10;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  createWebSiteContentForm: FormGroup;
  createWebSiteForm: FormGroup;
  seoForm: FormGroup;
  createContactListForm: FormGroup;
  webAnalyticsConfigurationForm: FormGroup;
  autoCreateContentPublishPointsForm: FormGroup;
  pathAliasForm: FormGroup;
  createWebSitePartiesForm: FormGroup;
  createWebSitePathAliasForm: FormGroup;
  webSiteCmsContentForm: FormGroup;
  webSiteId: any;
  findWebSite: any;
  updateWebSiteForm: FormGroup;
  ProductStoreIDArray: { label: string; value: string; }[];
  VisualSetArray: { label: string; value: string; }[];
  yesNoArray: { label: string; value: string; }[];
  webSiteContactList: any;
  productContact: any;
  contactShow: boolean;
  webAnalytics: any;
  WebAnalyticsTypesArray: { label: string; value: string; }[];
  webProduct: any;
  showAnalytics: boolean;
  contactWebsite: any;
  contactWebsiteArray: any[] = [];
  WebSiteRoleList: any;
  proParty: any;
  showParty: boolean;
  partyIdArray: any[] = [];
  personId: any;
  roleTypes: any;
  roleTypesArray: any[] = [];
  catalogArray: { label: string; value: string; }[];
  generalTypesArray: { label: string; value: string; }[];
  WebSiteCMSList: any;
  dataResourceId: any;
  dataResourceIdArray: any[] = [];
  webSitePathBYId: any;
  showPath: boolean;
  pathAliasList: any;
  ContentId: any;
  ContentIdArray: any[] = [];
  proPath: any;
  WebSiteContent: any;
  WebSiteContentArray: any[] = [];
  webSiteContentList: any;
  showContent: boolean;
  productContent: any;
  website: any;
  productStoreId: any;
  ProductStoreList: any;
  ProductStoreListArray: any[]=[];
  showPop: boolean;
  showbtn: boolean;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) {
    this.createWebSiteContentForm = this._FormBuilder.group({
      webSiteID: "",
      contentID: "",
      webSiteContentTypeID: "",
      fromDate: "",
      throughDate: "",
    })

    this.seoForm = this._FormBuilder.group({
      catalog: "",
      GenerateType: "",
    })

    this.pathAliasForm = this._FormBuilder.group({
      pathAlias: "",
      WebsiteId:""
    })

    this.webSiteCmsContentForm = this._FormBuilder.group({
      DataType: "",
      NewSUBSITE: "",
      newSubSuite: "",
      name: "",
      description: "",
      mapKey: "",
      purpose: "",
      sequenceNum: "",
      dataType: "",
      decorator: "",
      template: "",
      status: "",
      isPublic: "",
      url: "",
    })

    this.createWebSitePathAliasForm = this._FormBuilder.group({
      webSiteID: "",
      pathAlias: "",
      fromDate: "",
      throughDate: "",
      aliasTo: "",
      contentID: "",
      mapKey: "",
    })
    this.createContactListForm = this._FormBuilder.group({
      webSiteID: "",
      siteName: "",
      contactListID: "",
      throughDate: ""
    })
    this.webAnalyticsConfigurationForm = this._FormBuilder.group({
      webAnalyticsTypes: "",
      webAnalyticsCode: "",
    })
    this.createWebSitePartiesForm = this._FormBuilder.group({
      partyID: "",
      roleTypeID: "",
      throughDate: "",
      sequenceNum: "",
      fromDate: "",
    })
    this.autoCreateContentPublishPointsForm = this._FormBuilder.group({
      WebSiteContentTypeID: "",
    })
    this.updateWebSiteForm = this._FormBuilder.group({
      websiteID: "",
      siteName: "",
      httpHost: "",
      httpPort: "",
      httpsHost: "",
      httpsPort: "",
      yesNo: "",
      webAppPath: "",
      standardContentPrefix: "",
      secureContentPrefix: "",
      domainCookie: "",
      visualThemeSet: "",
      productStoreID: "",
      allowProductStoreChange: "",
      hostedPathAlias: "",
      isDefault: "",
      displayMaintenancPage: "",
    });
    this.createWebSiteForm = this._FormBuilder.group({
      websiteID: "",
      siteName: "",
      httpHost: "",
      httpPort: "",
      httpsHost: "",
      httpsPort: "",
      yesNo: "",
      webAppPath: "",
      standardContentPrefix: "",
      secureContentPrefix: "",
      domainCookie: "",
      visualThemeSet: "",
      productStoreID: "",
      allowProductStoreChange: "",
      hostedPathAlias: "",
      isDefault: "",
      displayMaintenancPage: "",
    });
    this.yesNoArray = [
      {
        label: "Y",
        value: "Y"
      },
      {
        label: "N",
        value: "N"
      }
    ]
    this.VisualSetArray = [
      {
        label: "Themes to be used for backoffice applicationsY",
        value: "BACKOFFICE"
      },
      {
        label: "Themes to be used for ECommerce applications",
        value: "ECOMMERCE"
      }
    ]
    this.ProductStoreIDArray = [
      {
        label: "OFBiz E-Commerce Store",
        value: "9000"
      },
      {
        label: "OFBiz Physical Retail Store",
        value: "9100"
      },
      {
        label: "Open Travel system Demo Site",
        value: "RentalStore"
      }
    ]
    this.WebAnalyticsTypesArray = [
      {
        label: "Backend Analytics",
        value: "BACKEND_ANALYTICS"
      },
      {
        label: "Bing Analytics",
        value: "BING_ANALYTICS"
      },
      {
        label: "Google Analytics",
        value: "GOOGLE_ANALYTICS"
      },
      {
        label: "Web Analytics",
        value: "WEB_ANALYTICS"
      }
    ]
    this.catalogArray = [
      {
        label: "All",
        value: "all"
      },
      {
        label: "DemoCatalog",
        value: "DemoCatalog"
      },
      {
        label: "DemoCatalog",
        value: "DemoCatalog"
      },
    ]
    this.generalTypesArray = [
      {
        label: "Category",
        value: "category"
      },
      {
        label: "Product",
        value: "product"
      },
      {
        label: "Content",
        value: "content"
      },

    ]
  }

  ngOnInit(): void {
    
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN" || this.userPermissions.CONTENTMGR_ADMIN=="CONTENTMGR_ADMIN"){
      this.Hidebtn=false;
    }
    this.activatedRoute.queryParams.subscribe(params => [
      this.webSiteId = params["webSiteId"],
      this.productStoreId = params["productStoreId"],

    ])
    setTimeout(() => {
      const form = this.createContactListForm;
      form.patchValue({
        webSiteID: this.webSiteId,
        siteName: this.findWebSite.siteName,
      })
    }, 2000);
    setTimeout(() => {
      const form = this.webSiteCmsContentForm;
      form.patchValue({
        NewSUBSITE: "Attached to Content OFBIZ_PPOINT",
      })
      const formPath = this.pathAliasForm;
      formPath.patchValue({
        WebsiteId: this.webSiteId,
      })
    }, 1000);
   
    setTimeout(() => {
      const form = this.createWebSiteForm;
      form.patchValue({
        productStoreID:  this.productStoreId
      })
    }, 2000);

    this.FindWebSite();
    this.getWebSiteContactList();
    this.getWebAnalytics();
    this.getContactList();
    this.WebSiteRole();
    this.getPartyId();
    this.getroleTypes();
    this.dataResourceTypeId();
    this.WebSiteCMS();
    this.WebSitePathAliasAllBYId();
    this.getContentId();
    this.getContentId();
    this.ListWebSiteContent();
    this.WebSiteContentType();
    this.FindWebSitePathAlias();
    this.getProductStoreList();

  }
  generatePDFWebsite() {
    this.spinner.show();
    
    this.myContactsService.generatePDFWebsite(this.webSiteId).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "WEBSITE.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  getProductStoreList() {
    this.spinner.show();
    this.accountsService.getProductStoreList().subscribe(res => {
      this.ProductStoreList = res.data[0].productStoreList;
      this.spinner.hide();
      for (const value of this.ProductStoreList) {
        this.ProductStoreListArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })

  }
  editProStore(){
    this._Router.navigate(["/facilities/catalog/stores/editStore"], 
    { queryParams: { productStoreId: this.productStoreId } })
   }
  contentPage(id){
    this._Router.navigate(["/content/content-page/content-detail-page"],
    {queryParams : {contentId :id}})
  }
  resetContent(){
    this.createWebSiteContentForm.reset();
    this.showContent=false;
  }
  updateContent(product) {
    this.showContent = true;
    this.productContent = product;
    setTimeout(() => {
      const form = this.createWebSiteContentForm;
      form.patchValue({
        webSiteID: this.webSiteId,
        contentID: product.ListWebSiteContent.contentId,
        webSiteContentTypeID: product.ListWebSiteContent.webSiteContentTypeId,
        fromDate: this.datePipe.transform(product.fromDate, "yyyy-MM-dd"),
        throughDate: this.datePipe.transform(product.thruDate, "yyyy-MM-dd"),
      })
    }, 2000);
  }
  RemoveWebSiteContent(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          "contentId": product.ListWebSiteContent.contentId,
          "fromDate": product.fromDate,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "webSiteContentTypeId": product.ListWebSiteContent.webSiteContentTypeId,
          "webSiteId": this.webSiteId,
        }
        this.myContactsService.RemoveWebSiteContent(req).subscribe(res => {
          this.ListWebSiteContent();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  autoCreateWebSiteContent() {
    this.spinner.show();
    const requestData =
    {
      "webSiteContentTypeId": this.autoCreateContentPublishPointsForm.value.WebSiteContentTypeID,
      "webSiteId": this.webSiteId
    }
    this.myContactsService.autoCreateWebSiteContent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.autoCreateContentPublishPointsForm.reset();
        this.ListWebSiteContent();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createWebSiteContent() {
    this.spinner.show();
    const requestData =
    {
      "contentId": this.createWebSiteContentForm.value.contentID,
      "fromDate": this.datePipe.transform(this.createWebSiteContentForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "thruDate": this.datePipe.transform(this.createWebSiteContentForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "webSiteContentTypeId": this.createWebSiteContentForm.value.webSiteContentTypeID,
      "webSiteId": this.webSiteId
    }
    this.myContactsService.createWebSiteContent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.createWebSiteContentForm.reset();
        this.ListWebSiteContent();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateWebSiteContent() {
    this.spinner.show();
    const requestData =
    {

      "contentId": this.createWebSiteContentForm.value.contentID,
      "fromDate": this.productContent.fromDate,
      "partyId": "",
      "roleTypeId": "",
      "sequenceNum": "",
      "thruDate": this.datePipe.transform(this.createWebSiteContentForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "webSiteContentTypeId": this.createWebSiteContentForm.value.webSiteContentTypeID,
      "webSiteId": this.webSiteId

    }
    this.myContactsService.UpdateWebSiteContent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.createWebSiteContentForm.reset();
        this.ListWebSiteContent();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  ListWebSiteContent() {
    this.spinner.show();
    this.myContactsService.ListWebSiteContent(this.webSiteId).subscribe(res => {
      this.webSiteContentList = res.data;
      this.spinner.hide();
    })
  }

  WebSiteContentType() {
    this.spinner.show();
    this.myContactsService.WebSiteContentType().subscribe(res => {
      this.WebSiteContent = res.data.WebSiteContentType;
      this.spinner.hide();
      for (const value of this.WebSiteContent) {
        this.WebSiteContentArray.push({
          label: value.description,
          value: value.webSiteContentTypeId
        })
      }
    })
  }
  reset() {
    this.pathAliasForm.reset();
    this.FindWebSitePathAlias();
  }
  FindWebSitePathAlias() {
    this.spinner.show();
    const formData = {
      "pathAlias": this.pathAliasForm.value.pathAlias ? this.pathAliasForm.value.pathAlias : '',
      "webSiteId": this.webSiteId ? this.webSiteId : '',

    }

    this.myContactsService.FindWebSitePathAlias(formData).subscribe(res => {
      this.pathAliasList = res.data;
      this.spinner.hide();
      console.log( this.pathAliasForm.value.pathAlias,"req.pathAlias")
      console.log(this.pathAliasForm.value.WebsiteI,"req.webSiteId")

    })
  }
 
  getContentId() {
    this.spinner.show();
    this.addSkillService.getContentId().subscribe(res => {
      this.ContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContentId) {
        this.ContentIdArray.push({
          label: value.contentName,
          value: value.contentId
        })
      }
    })
  }
  getFindWebSitePathAlias() {
    this.spinner.show();
    this.myContactsService.getFindWebSitePathAlias().subscribe(res => {
      this.pathAliasList = res.data;
      this.spinner.hide();
    })
  }

  removeWebSitePathAlias(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          "fromDate":product.fromDate ,
          "pathAlias": product.WebSitePathAliasData.pathAlias,
          "view_INDEX_1": "0",
          "view_SIZE_1": "30",
          "webSiteId": this.webSiteId,
        }
        this.myContactsService.removeWebSitePathAlias(req).subscribe(res => {
          this.FindWebSitePathAlias();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updatePathAlias(product) {
    this.showPath = true;
    this.proPath = product;
    setTimeout(() => {
      const form = this.createWebSitePathAliasForm;
      form.patchValue({
        webSiteID: this.webSiteId,
        pathAlias: this.proPath.WebSitePathAliasData.pathAlias,
        throughDate:this.datePipe.transform( this.proPath.thruDate,"yyyy-MM-dd"),
        aliasTo: this.proPath.WebSitePathAliasData.aliasTo,
        contentID: this.proPath.WebSitePathAliasData.contentId,
        mapKey: this.proPath.WebSitePathAliasData.mapKey,
        fromDate:this.datePipe.transform(this.proPath.fromDate,"yyyy-MM-dd")
      })
    }, 2000);
  }
  resetPath() {
    this.showPath = false;
    this.createWebSitePathAliasForm.reset();
  }
  createWebSitePathAlias() {
    this.spinner.show();
    const requestData =
    {

      "aliasTo": this.createWebSitePathAliasForm.value.aliasTo,
      "contentId": this.createWebSitePathAliasForm.value.contentID,
      "fromDate": this.datePipe.transform(this.createWebSitePathAliasForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"), 
      "mapKey": this.createWebSitePathAliasForm.value.mapKey,
      "pathAlias": this.createWebSitePathAliasForm.value.pathAlias,
      "thruDate": this.datePipe.transform(this.createWebSitePathAliasForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "webSiteId": this.webSiteId
    }
    this.myContactsService.createWebSitePathAlias(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.createWebSitePathAliasForm.reset();
        this.FindWebSitePathAlias();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateWebSitePathAlias() {
    this.spinner.show();
    const requestData =
    {

      "aliasTo": this.createWebSitePathAliasForm.value.aliasTo,
      "contentId": this.createWebSitePathAliasForm.value.contentID,
      "fromDate":this.proPath.fromDate,
      "mapKey": this.createWebSitePathAliasForm.value.mapKey,
      "pathAlias": this.createWebSitePathAliasForm.value.pathAlias,
      "thruDate": this.datePipe.transform(this.createWebSitePathAliasForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "webSiteId": this.webSiteId,

    }
    this.myContactsService.updateWebSitePathAlias(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.createWebSitePathAliasForm.reset();
        this.FindWebSitePathAlias();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  WebSitePathAliasAllBYId() {
    this.spinner.show();
    this.myContactsService.WebSitePathAliasAllBYId(this.webSiteId).subscribe(res => {
      this.webSitePathBYId = res.data;
      this.spinner.hide();

    })
  }
  dataResourceTypeId() {
    this.spinner.show();
    this.myContactsService.dataResourceTypeId().subscribe(res => {
      this.dataResourceId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.dataResourceId) {
        this.dataResourceIdArray.push({
          label: value.description,
          value: value.dataResourceTypeId
        })
      }
    })
  }
  WebSiteCMS() {
    this.spinner.show();
    this.myContactsService.WebSiteCMS(this.webSiteId).subscribe(res => {
      this.WebSiteCMSList = res.data;
      this.spinner.hide();

    })
  }
  generateMissingSeoUrlForWebsite() {
    this.spinner.show();
    const requestData =
    {
      "prodCatalogId": this.seoForm.value.catalog,
      "typeGenerate": this.seoForm.value.GenerateType,
      "webSiteId": this.webSiteId
    }
    this.myContactsService.generateMissingSeoUrlForWebsite(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.seoForm.reset();
        // this.WebSiteRole();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getroleTypes() {
    this.spinner.show();
    this.myContactsService.ContentSearchOptions().subscribe(res => {
      this.roleTypes = res.data.roleTypes;
      this.spinner.hide();
      for (const value of this.roleTypes) {
        this.roleTypesArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  resetParty() {
    this.showParty = false;
    this.createWebSitePartiesForm.reset();
  }
  updateParty(product) {
    this.showParty = true;
    this.proParty = product;
    setTimeout(() => {
      const form = this.createWebSitePartiesForm;
      form.patchValue({
        partyID: this.proParty.WebSiteRoleAll.partyId,
        roleTypeID: this.proParty.WebSiteRoleAll.roleTypeId,
        throughDate: this.datePipe.transform(this.proParty.thruDate, "yyyy-MM-dd"),
        sequenceNum: this.proParty.WebSiteRoleAll.sequenceNum,
        fromDate: this.datePipe.transform(this.proParty.fromDate, "yyyy-MM-dd"),
      })
    }, 2000);
  }
  updateWebSiteRole() {
    this.spinner.show();
    const requestData =
    {

      "fromDate": this.proParty.fromDate,
      "partyId": this.createWebSitePartiesForm.value.partyID,
      "roleTypeId": this.createWebSitePartiesForm.value.roleTypeID,
      "sequenceNum": this.createWebSitePartiesForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.createWebSitePartiesForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "webSiteId": this.webSiteId,

    }
    this.myContactsService.updateWebSiteRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.createWebSitePartiesForm.reset();
        this.WebSiteRole();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createWebSiteRole() {
    this.spinner.show();
    const requestData =
    {

      "fromDate": this.datePipe.transform(this.createWebSitePartiesForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "partyId": this.createWebSitePartiesForm.value.partyID,
      "roleTypeId": this.createWebSitePartiesForm.value.roleTypeID,
      "sequenceNum": this.createWebSitePartiesForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.createWebSitePartiesForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
      "webSiteId": this.webSiteId
    }
    this.myContactsService.createWebSiteRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.createWebSitePartiesForm.reset();
        this.WebSiteRole();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  removeWebSiteRole(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "fromDate":product.fromDate,
          "partyId": product.WebSiteRoleAll.partyId,
          "roleTypeId": product.WebSiteRoleAll.roleTypeId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "webSiteId": this.webSiteId,
        }
        this.myContactsService.removeWebSiteRole(req).subscribe(res => {
          this.WebSiteRole();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  WebSiteRole() {
    this.spinner.show();
    this.myContactsService.WebSiteRole(this.webSiteId).subscribe(res => {
      this.WebSiteRoleList = res.data;
      this.spinner.hide();

    })
  }
  getContactList() {
    this.spinner.show();
    this.myContactsService.getContactWebsite().subscribe(res => {
      this.contactWebsite = res.data.getContactList;
      this.spinner.hide();
      for (const value of this.contactWebsite) {
        this.contactWebsiteArray.push({
          label: value.contactListName + "[" + value.contactListId + "]",
          value: value.contactListId
        })
      }
    })
  }

  deleteWebAnalyticsConfig(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "webAnalyticsTypeId": product.webAnalyticsTypeId,
          "webSiteId": this.webSiteId
        }
        this.myContactsService.deleteWebAnalyticsConfig(req).subscribe(res => {
          this.getWebAnalytics();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getWebAnalytics() {
    this.spinner.show();
    this.myContactsService.getWebAnalytics(this.webSiteId).subscribe(res => {
      this.webAnalytics = res.data.FindWebSite;
      this.spinner.hide();

    })
  }
  resetAnalytics() {
    this.showAnalytics = false;
    this.webAnalyticsConfigurationForm.reset();
  }
  updateWebAnalytics(product) {
    this.showAnalytics = true;
    this.webProduct = product;
    setTimeout(() => {
      const form = this.webAnalyticsConfigurationForm;
      form.patchValue({
        webAnalyticsTypes: this.webProduct.webAnalyticsTypeId,
        webAnalyticsCode: this.webProduct.webAnalyticsCode
      })
    }, 2000);
  }
  updateWebAnalyticsConfig() {
    this.spinner.show();
    const requestData =
    {
      "webSiteId": this.webSiteId,
      "noConditionFind": "Y",
      "webAnalyticsTypeId": this.webAnalyticsConfigurationForm.value.webAnalyticsTypes,
      "webAnalyticsCode": this.webAnalyticsConfigurationForm.value.webAnalyticsCode
    }
    this.myContactsService.updateWebAnalyticsConfig(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.webAnalyticsConfigurationForm.reset();
        this.getWebAnalytics();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createWebAnalyticsConfig() {
    this.spinner.show();
    const requestData =
    {
      "webSiteId": this.webSiteId,
      "noConditionFind": "Y",
      "webAnalyticsTypeId": this.webAnalyticsConfigurationForm.value.webAnalyticsTypes,
      "webAnalyticsCode": this.webAnalyticsConfigurationForm.value.webAnalyticsCode
    }
    this.myContactsService.createWebAnalyticsConfig(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.webAnalyticsConfigurationForm.reset();
        this.getWebAnalytics();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteWebSiteContactList(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "contactListId": product.getWebSiteContactList.contactListId,
          "fromDate": this.datePipe.transform(product.getWebSiteContactList.fromDate, "yyyy-MM-dd hh:mm:ss"),
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "webSiteId": this.webSiteId
        }
        this.myContactsService.deleteWebSiteContact(req).subscribe(res => {
          this.getWebSiteContactList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getWebSiteContactList() {
    this.spinner.show();
    this.myContactsService.getWebSiteContactList(this.webSiteId).subscribe(res => {
      this.webSiteContactList = res.data;
      this.spinner.hide();

    })
  }
  createWebSiteContact() {
    this.spinner.show();
    const requestData =
    {
      "contactListId": this.createContactListForm.value.contactListID,
      "fromDate": this.datePipe.transform(Date(), "yyyy-MM-dd hh:mm:ss"),
      "siteName": this.createContactListForm.value.siteName,
      "webSiteId": this.webSiteId
    }
    this.myContactsService.createWebSiteContact(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.createContactListForm.reset();
        this.getWebSiteContactList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  resetContact() {
    this.contactShow = false;
    this.createContactListForm.reset();
    setTimeout(() => {
      const form = this.createContactListForm;
      form.patchValue({
        webSiteID: this.webSiteId,
        siteName: this.findWebSite.siteName,
      })
    }, 2000);
  }
  updateContact(product) {
    this.productContact = product;
    this.contactShow = true;
    setTimeout(() => {
      const form = this.createContactListForm;
      form.patchValue({
        webSiteID: this.webSiteId,
        siteName: "",
        contactListID: this.productContact.getWebSiteContactList.contactListId,
        throughDate: this.datePipe.transform(this.productContact.thruDate,"yyyy-MM-dd") 
      })
    }, 2000);


  }
  updateWebSiteContactList() {
    this.spinner.show();
    const requestData =
    {
      "contactListId": this.createContactListForm.value.contactListID,
      "fromDate": this.productContact.fromDate,
      "webSiteId": this.webSiteId,
      "contactListName": this.productContact.contactListData[0].contactListName,
      "deleteButton": "",
      "thruDate":this.datePipe.transform(this.createContactListForm.value.throughDate,"yyyy-MM-dd hh:mm:ss")  
    }
    this.myContactsService.updateWebSiteContact(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.createContactListForm.reset();
        this.getWebSiteContactList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
 
  detailPage() {
    this._Router.navigate(["/facilities/catalog/stores/editStore"],
      { queryParams: { productStoreId: this.findWebSite.productStoreId } })
  }
  partyPage(partyId) {
    this._Router.navigate(["/partyQuickLink/profile-party-quick-link"],
      { queryParams: { party: partyId } })
  }
  createWebSite() {
    this.spinner.show();
    const requestData =
    {
      "allowProductStoreChange": this.createWebSiteForm.value.allowProductStoreChange,
      "cookieDomain": this.createWebSiteForm.value.domainCookie,
      "displayMaintenancePage": this.createWebSiteForm.value.displayMaintenancPage,
      "enableHttps": this.createWebSiteForm.value.yesNo,
      "hostedPathAlias": this.createWebSiteForm.value.hostedPathAlias,
      "httpHost": this.createWebSiteForm.value.httpHost,
      "httpPort": this.createWebSiteForm.value.httpPort,
      "httpsHost": this.createWebSiteForm.value.httpsHost,
      "httpsPort": this.createWebSiteForm.value.httpsPort,
      "isDefault": this.createWebSiteForm.value.isDefault,
      "productStoreId": this.createWebSiteForm.value.productStoreID,
      "secureContentPrefix": this.createWebSiteForm.value.secureContentPrefix,
      "siteName": this.createWebSiteForm.value.siteName,
      "standardContentPrefix": this.createWebSiteForm.value.standardContentPrefix,
      "visualThemeSetId": this.createWebSiteForm.value.visualThemeSet,
      "webSiteId": this.createWebSiteForm.value.websiteID,
      "webappPath": this.createWebSiteForm.value.webAppPath,
    }
    this.myContactsService.createWebSite(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.createWebSiteForm.reset();
        this.website = res.data.webSiteId;
        this._Router.navigate(["content/main/website-detail-page"],
        {queryParams : {webSiteId :this.website }})
        this.FindWebSite();
        this.getWebSiteContactList();
        this.getWebAnalytics();
        this.getContactList();
        this.WebSiteRole();
        this.getPartyId();
        this.getroleTypes();
        this.dataResourceTypeId();
        this.WebSiteCMS();
        this.WebSitePathAliasAllBYId();
        this.getContentId();
        this.getContentId();
        this.ListWebSiteContent();
        this.WebSiteContentType();
        this.FindWebSitePathAlias();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateWebSite() {
    this.spinner.show();
    const requestData =
    {
      "allowProductStoreChange": this.updateWebSiteForm.value.allowProductStoreChange,
      "cookieDomain": this.updateWebSiteForm.value.domainCookie,
      "displayMaintenancePage": this.updateWebSiteForm.value.displayMaintenancPage,
      "enableHttps": this.updateWebSiteForm.value.yesNo,
      "hostedPathAlias": this.updateWebSiteForm.value.hostedPathAlias,
      "httpHost": this.updateWebSiteForm.value.httpHost,
      "httpPort": this.updateWebSiteForm.value.httpPort,
      "httpsHost": this.updateWebSiteForm.value.httpsHost,
      "httpsPort": this.updateWebSiteForm.value.httpsPort,
      "isDefault": this.updateWebSiteForm.value.isDefault,
      "productStoreId": this.updateWebSiteForm.value.productStoreID,
      "secureContentPrefix": this.updateWebSiteForm.value.secureContentPrefix,
      "siteName": this.updateWebSiteForm.value.siteName,
      "standardContentPrefix": this.updateWebSiteForm.value.standardContentPrefix,
      "visualThemeSetId": this.updateWebSiteForm.value.visualThemeSet,
      "webSiteId": this.updateWebSiteForm.value.websiteID,
      "webappPath": this.updateWebSiteForm.value.webAppPath,
    }
    this.myContactsService.updateWebSite(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.updateWebSiteForm.reset();
        this.FindWebSite();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  FindWebSite() {
    this.spinner.show();
    this.myContactsService.FindWebSite(this.webSiteId).subscribe(res => {
      this.findWebSite = res.data.FindWebSite[0];
      this.spinner.hide();
      setTimeout(() => {
        const form = this.updateWebSiteForm;
        form.patchValue({
          websiteID: this.findWebSite.webSiteId,
          siteName: this.findWebSite.siteName,
          httpHost: this.findWebSite.httpHost,
          httpPort: this.findWebSite.httpPort,
          httpsHost: this.findWebSite.httpsHost,
          httpsPort: this.findWebSite.httpsPort,
          yesNo: this.findWebSite.enableHttps,
          webAppPath: this.findWebSite.webappPath,
          standardContentPrefix: this.findWebSite.standardContentPrefix,
          secureContentPrefix: this.findWebSite.secureContentPrefix,
          domainCookie: this.findWebSite.cookieDomain,
          visualThemeSet: this.findWebSite.visualThemeSetId,
          productStoreID: this.findWebSite.productStoreId,
          allowProductStoreChange: this.findWebSite.allowProductStoreChange,
          hostedPathAlias: this.findWebSite.hostedPathAlias,
          isDefault: this.findWebSite.isDefault,
          displayMaintenancPage: this.findWebSite.displayMaintenancePage,
        })
      }, 2000);

    })
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }

}
