import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-view-gateway-response',
  templateUrl: './view-gateway-response.component.html',
  styleUrls: ['./view-gateway-response.component.css']
})
export class ViewGatewayResponseComponent implements OnInit {
  id: any;
  gateway: any;
  budgetStatus:any[]=[]
  paymentGatewayResponse: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  payments: any;

  constructor(readonly  _Router: Router,
    
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
   ) { }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.id = params["id"];
  
    
     
    });
    this.getGatewayResponseById();
  

}
cancelSubmit(){
  this._Router.navigate(["/financial/payment-gateway-response"])
}  
getGatewayResponseById() {
  this.spinner.show();

  this.accountsService.getGatewayResponseById(this.id).subscribe((res: any) => {
    this.gateway = res.data;
    this.payments=res.data.payments
    this.paymentGatewayResponse=res.data.paymentGatewayResponse;
    this.spinner.hide();

  })
 
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null){
        result = -1;
      }
      else if (value1 != null && value2 == null){
        result = 1;
      }
      else if (value1 == null && value2 == null) {
          result = 0;
       }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
      }
      else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
  });
}
}
