import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class MyLeadService {

  constructor(readonly http: HttpClient) { }

  getLeadsList(data, formData): Observable<any> {
    const getLeadsList = URLS.getLeads + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo + "&isMyLead=" + data.isMyLead;
    return this.http.post(getLeadsList, formData);
  }

  getMyFindLeads(req): Observable<any> {
    const getMyFindLeads = URLS.getMyFindLeads;
    return this.http.post(getMyFindLeads,req);
  }
}