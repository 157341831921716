<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div *ngIf="!this.Hidebtn" class="suppliers-wrapper">
                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                            data-target="#exampleModalCenter">Create New Communication</button>&nbsp;
                    </div>
                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>

                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ml-2">Back</button>
                    </span>

                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Find Communications</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Email From unknown Parties</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Find Communications by Order</a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Find Communications by Return</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Search Options
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Communication
                                                                            Event ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <!--  <p-dropdown  [options]="opportunityIdArray" style="display: none;"
                                                                    optionlabel="label" [(ngModel)]="advanceSearch.communicationEventIdSearchType"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="communicationEventIdSearchType"
                                                                        filter="true" >
                                                                    </p-dropdown> -->
                                                                        <input type="text"
                                                                            [(ngModel)]="advanceSearch.communicationEventId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="communicationEventId"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp">
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Parent Comm
                                                                            Event ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <!--  <p-dropdown  [options]="opportunityIdArray" style="display: none;"
                                                                    optionlabel="label" [(ngModel)]="advanceSearch.parentCommEventIdSearchType"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    name="parentCommEventIdSearchType"
                                                                        filter="true" >
                                                                    </p-dropdown> -->
                                                                        <input type="text"
                                                                            [(ngModel)]="advanceSearch.parentCommEventId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="parentCommEventId"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                            From</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            filter="true"
                                                                            [(ngModel)]="advanceSearch.partyIdFrom"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyIdFrom"
                                                                            placeholder="Enter party id from">
                                                                        </p-dropdown>
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                            To</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            filter="true"
                                                                            [(ngModel)]="advanceSearch.partyIdTo"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyIdTo"
                                                                            placeholder="Enter party id to">
                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                            (Any Role)</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            filter="true"
                                                                            [(ngModel)]="advanceSearch.partyId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyId" placeholder="Enter party id">
                                                                        </p-dropdown>
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Comm.Type
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="comtypeIdArray"
                                                                            filter="true"
                                                                            [(ngModel)]="advanceSearch.communicationEventTypeId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="communicationEventTypeId"
                                                                            placeholder="Enter comm.type id">
                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Subject</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <!--   <p-dropdown  [options]="opportunityIdArray" style="display: none;"
                                                                            optionlabel="label" [(ngModel)]="advanceSearch.subjectSearchType"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="subjectSearchType"
                                                                                filter="true">
                                                                            </p-dropdown> -->
                                                                        <input type="text"
                                                                            [(ngModel)]="advanceSearch.subject"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="subject" class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp">
                                                                    </div>




                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div style="    margin-left: -24%;"
                                                            class="col-lg-12 col-12 main-submit-button ">
                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                (click)="advanceSearchOverview(false)"
                                                                class="btn btn-secondary submit-btn">Find</button>&nbsp;&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2"
                                                                (click)="resetOverview();">Reset</button>&nbsp;
                                                        </div>
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Search Results
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div
                                                                class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="getOverviewList"
                                                                                [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Subject
                                                                                                <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Comm.Type ID
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="quantity">
                                                                                            <div style="color: white;">
                                                                                                Party ID From
                                                                                                <p-sortIcon
                                                                                                    field="quantity">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Party ID To
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Status ID
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Entry Date
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>

                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Role Type Id
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>

                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>

                                                                                            </div>
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}"
                                                                                            (click)="accountDetails(product.communicationEventId)">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                             class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span
                                                                                                    class="account-button">
                                                                                                    {{product.subject?product.subject:''}}
                                                                                                </span>

                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                            {{product.communicationEventTypeId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                            {{product.partyIdFrom}}</td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                            {{product.partyIdToDesc}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                            {{product.statusId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                            {{product.entryDate|date:'yyyy-MM-dd'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                            {{product.roleTypeId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                            <button type="button"
                                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                                (click)="communicationEvents(product)">Remove</button>
                                                                                        </td>

                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Communications from Unknown origin
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group" style="width: 100%!important;">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="UnknownPartyComms"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">

                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Subject
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Entry
                                                                                        Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Status
                                                                                        ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Party ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Role
                                                                                        Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Role
                                                                                        Status ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Created
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{activity.communicationEventAndRole.subject}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.communicationEventAndRole.entryDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.communicationEventAndRole.statusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.communicationEventAndRole.partyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.communicationEventAndRole.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.communicationEventAndRole.roleStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.communicationEventAndRole.datetimeStarted|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span>
                                                                                        <small
                                                                                            style="color: #0d3769 !important;font-weight: 600;cursor:pointer;"
                                                                                            (click)="deleteCommunicationEvents(activity.communicationEventAndRole)">Delete</small>

                                                                                    </span>

                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Search Options
                                                        </h4>
                                                    </div>
                                                    <form class="w-100">

                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Order ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <!--   <p-dropdown filter="true"  style="display: none;"
                                                                  [(ngModel)]="advanceSearchOrderBy.orderId"
                                                                                      [ngModelOptions]="{standalone:true}"
                                                                                      name="orderId"
                                                                                      [options]="opportunityIdArray"
                                                                                      optionlabel="label"></p-dropdown> -->

                                                                        <input type="email" name="orderId"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            [(ngModel)]="advanceSearchOrderBy.orderId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="returnId" placeholder="Order ID">
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Communication
                                                                            Event ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <!--    <p-dropdown filter="true" style="display: none;"  [options]="opportunityIdArray"
                                                                  optionlabel="label" [(ngModel)]="advanceSearchOrderBy.communicationEventIdSearchType"
                                                                  [ngModelOptions]="{standalone:true}"
                                                                  name="communicationEventIdSearchType">
                              
                                                                  </p-dropdown> -->

                                                                        <input type="email" class="form-control"
                                                                            name="eventId" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Communication Event ID"
                                                                            [(ngModel)]="advanceSearchOrderBy.communicationEventId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="communicationEventId">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                            From</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Party ID From"
                                                                            [(ngModel)]="advanceSearchOrderBy.partyIdFrom"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyIdFrom">

                                                                        </p-dropdown>
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                            To</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Party ID To"
                                                                            [(ngModel)]="advanceSearchOrderBy.partyIdTo"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyIdTo">

                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Party ID (any
                                                                            role)</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Party ID"
                                                                            [(ngModel)]="advanceSearchOrderBy.partyId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyId">

                                                                        </p-dropdown>
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Comm. Type
                                                                            Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="comtypeIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Comm Type Id"
                                                                            [(ngModel)]="advanceSearchOrderBy.communicationEventTypeId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="communicationEventTypeId">

                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Status
                                                                            Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="stausIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Status Id"
                                                                            [(ngModel)]="advanceSearchOrderBy.statusId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="statusId">

                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button"
                                                            style="    margin-left: -26%;"><button
                                                                _ngcontent-cta-c128="" type="submit"
                                                                (click)="advanceSearchOrder(false)"
                                                                class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-danger ml-2"
                                                                (click)="resetAdvanceReset()">Reset</button>
                                                        </div>

                                                        <br>

                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Search Result
                                                                </h4>

                                                            </div>
                                                        </header>

                                                        <div
                                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select allTableUser">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">


                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="commByOrder"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">

                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Communication Event ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Subject
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">Type
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Status Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Party
                                                                                            Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Role
                                                                                            Type Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Role
                                                                                            Status Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Created
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Sent
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Order
                                                                                            Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>


                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            {{product.communicationEventId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.subject}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.communicationEventTypeDesc}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.partyIdDesc}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.roleTypeIdTo}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.entryDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.datetimeStarted|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        {{product.orderId}}
                                                                                    </td>



                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>

                                                </div>
                                                <div *ngIf="activeCategory==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Search Options
                                                        </h4>
                                                    </div>
                                                    <form class="w-100">

                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Return
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <!--   <p-dropdown filter="true"  style="display: none;"
                                                                  [(ngModel)]="advanceSearchOrderByReturn.returnIdSearchType"
                                                                                      [ngModelOptions]="{standalone:true}"
                                                                                      name="returnIdSearchType"
                                                                                      [options]="opportunityIdArray"
                                                                                      optionlabel="label"></p-dropdown> -->

                                                                        <input type="email" name="orderId"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            [(ngModel)]="advanceSearchOrderByReturn.returnId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="returnId" placeholder="Order ID">
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Communication
                                                                            Event ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <!--     <p-dropdown filter="true" style="display: none;" [options]="opportunityIdArray"
                                                                  optionlabel="label" [(ngModel)]="advanceSearchOrderByReturn.communicationEventIdSearchType"
                                                                  [ngModelOptions]="{standalone:true}"
                                                                  name="communicationEventIdSearchType">
                              
                                                                  </p-dropdown> -->

                                                                        <input type="email" class="form-control"
                                                                            name="eventId" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Communication Event ID"
                                                                            [(ngModel)]="advanceSearchOrderByReturn.communicationEventId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="communicationEventId">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                            From</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Party ID From"
                                                                            [(ngModel)]="advanceSearchOrderByReturn.partyIdFrom"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyIdFrom">

                                                                        </p-dropdown>
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                            To</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Party ID To"
                                                                            [(ngModel)]="advanceSearchOrderByReturn.partyIdTo"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyIdTo">

                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Party ID (any
                                                                            role)</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Party ID"
                                                                            [(ngModel)]="advanceSearchOrderByReturn.partyId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="partyId">

                                                                        </p-dropdown>
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Comm. Type
                                                                            Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="comtypeIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Comm Type Id"
                                                                            [(ngModel)]="advanceSearchOrderByReturn.communicationEventTypeId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="communicationEventTypeId">

                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Status
                                                                            Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="stausIdArray"
                                                                            optionlabel="label" filter="true"
                                                                            placeholder="Enter Status Id"
                                                                            [(ngModel)]="advanceSearchOrderByReturn.statusId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            name="statusId">

                                                                        </p-dropdown>
                                                                    </div>





                                                                </div>
                                                            </div>




                                                        </div>
                                                        <div style="    margin-left: -26%;"
                                                            class="col-lg-12 col-12 main-submit-button"><button
                                                                _ngcontent-cta-c128="" type="submit"
                                                                (click)="advanceSearchReturnOrder(false)"
                                                                class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-danger ml-2"
                                                                (click)="reset()">Reset</button>
                                                        </div>

                                                        <br>

                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Search Result
                                                                </h4>

                                                            </div>
                                                        </header>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select allTableUser">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="evenTByOrder"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">

                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Communication Event ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Subject
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>

                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">Type
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Status Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Party
                                                                                            Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Role
                                                                                            Type Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">Role
                                                                                            Status Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Created
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;"> Sent
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Return Id
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>

                                                                                        </div>
                                                                                    </th>


                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            {{product.communicationEventId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.subject}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.communicationEventTypeDesc}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.partyIdDesc}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.roleTypeIdTo}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.entryDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}

                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{product.datetimeStarted|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">

                                                                                        {{product.returnId}}
                                                                                    </td>


                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Communication Event
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey" style="margin-top: -8px;">


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass" style="    margin-left: -3%;
                                margin-right: -3%;">

                                        <li [ngClass]="activeCategory==1?'active':''"><a>
                                                Edit Communication Event</a></li>



                                    </ul>
                                </div>
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCommEvent">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">

                                                            Parent Comm Event ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="parentCommEventArray" optionlabel="label"
                                                            placeholder="Parent Comm Event ID" filter="true"
                                                            formControlName="parCommEventID">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">

                                                            Comm.Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Comm.Type ID"
                                                            [options]="comtypeIdArray" optionlabel="label" filter="true"
                                                            formControlName="commTypeId">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">

                                                            Status</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="stausIdArray" optionlabel="label"
                                                            placeholder="Status" filter="true" formControlName="status">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">

                                                            Party From</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown (onChange)="contactEventMech($event.value)"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Party From" filter="true"
                                                            formControlName="partyFrom">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">

                                                            Party To</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown (onChange)="contactEventMechTo($event.value)"
                                                            [options]="partyIdArray" optionlabel="label"
                                                            placeholder="Party To" filter="true"
                                                            formControlName="partyTo">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">

                                                            Contact Mech Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechTypeArray" optionlabel="label"
                                                            placeholder="Contact Mech Type ID" filter="true"
                                                            formControlName="conMechId">

                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">

                                                            From Contact Mech</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechArray" optionlabel="label"
                                                            placeholder="From Contact Mech" filter="true"
                                                            formControlName="fromContactMechId">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">

                                                            To Contact Mech</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactMechToArray" optionlabel="label"
                                                            placeholder="To Contact Mech" filter="true"
                                                            formControlName="toContactMechId">

                                                        </p-dropdown>


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">

                                                            Role Type Id From</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label"
                                                            placeholder="Role Type Id From" filter="true"
                                                            formControlName="roleTypeFrom">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">

                                                            Role Type Id To</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="roleTypeIdArray" optionlabel="label"
                                                            placeholder="Role Type Id To" filter="true"
                                                            formControlName="roleTypeTo">

                                                        </p-dropdown>


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">

                                                            Contact List ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="contactListIdArray" optionlabel="label"
                                                            placeholder="Contact List ID" filter="true"
                                                            formControlName="contactListId">

                                                        </p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Start Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Start Date"
                                                            formControlName="startdate">


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1"> Finish Date/Time

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Finish Date/Time"
                                                            formControlName="finishDate">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Subject

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Subject"
                                                            formControlName="subject">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">

                                                            Reason Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="reasonenumArray" optionlabel="label"
                                                            placeholder="Reason Enum ID" filter="true"
                                                            formControlName="reasonEnumId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">

                                                            Content Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Content Mime Type ID" filter="true"
                                                            [options]="contentMMArray" optionlabel="label"
                                                            formControlName="contentMimeTypeId">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Content

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Content"
                                                            formControlName="content">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Note

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Note"
                                                            formControlName="note">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 83%;">
                                        <button (click)="onSubmit();" type="submit"
                                            class="btn btn-secondary submit-btn">Save</button>


                                    </div>
                                </div>
                                <br><br><br>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>