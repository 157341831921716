import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-create-classification-party',
  templateUrl: './create-classification-party.component.html',
  styleUrls: ['./create-classification-party.component.css']
})
export class CreateClassificationPartyComponent implements OnInit {
  createClassification:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  @ViewChild('closebutton') closebutton;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
      this.contentIds = [];
      this.createClassification = this._FormBuilder.group({
        partyId: [''],
        ClassificationGroupID: [''],
        FromDate:[''],
        ThroughDate:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   this.getClassificationGroupId();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.partyClassId = params["partyClassId"];
      this.efromDate = params["fromDate"];
      this.ethruDate = params["thruDate"];
      
    });
   
    if(this.partyClassId) {
      this.show=true;
      const fromdate=this.efromDate
      this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
      const ethruDate=this.ethruDate
      this.getthruDate=this.datePipe.transform(ethruDate,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createClassification;
      formValue.patchValue({
        partyId: this.party,
        ClassificationGroupID:  this.partyClassId,
        FromDate:this.getEstimateFrom,
        ThroughDate: this.getthruDate
      })
      }, 3000); 
    }
    else {
      setTimeout(() => {
        const formValue = this.createClassification;
      formValue.patchValue({
        partyId: this.party,
       
      })
      }, 500); 
      this.show = false;
    }
 
  }
  
  getClassificationGroupId(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroupId().subscribe((res:any)=> {
        this.ClassificationGroupId = res.data;
        this.spinner.hide();
        for(const value of this.ClassificationGroupId){
          this.ClassificationGroupIdArray.push({
            label:value.data.description,
            value:value.data.partyClassificationGroupId
          })
        }
      
      })
     
     }
  onSubmit() {
    this.spinner.show();
    const fromDate=this.createClassification.get('FromDate').value;
    this.fromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const thruDate=this.createClassification.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.fromDate,
      "partyClassificationGroupId":this.createClassification.value.ClassificationGroupID,
      "partyId":this.party,
      "thruDate": this.thruDate
  
     
    }
    this.accountsService.createPartyClassification(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   onUpdate() {
    this.spinner.show();
   
    const thruDate=this.createClassification.get('ThroughDate').value;
    this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.efromDate,
      "partyClassificationGroupId":this.createClassification.value.ClassificationGroupID,
      "partyId":this.party,
      "thruDate": this.thruDate
  
     
    }
    this.accountsService.updatePartyClassification(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }  
   
  cancelSubmit(){
    this._location.back();
  }
  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.bsModalRef.hide();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}
