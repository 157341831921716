<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-grey titlePanels" style="cursor: pointer;"> Human Resources Management System 
                </span>
                <span class="">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="container-fluid">

                </div>

                <div>
                    <div class="container-fluid">
                        <div class="row" >
                            <form class="w-100">

                                <div class="w-100">
                                    <div class="panel-group">
                                        <div *ngIf="activeCategoryValue==2">
                                            <div style="width: 49.7%; float:left">
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Overview
                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/hrms-overview">
                                                                                    Profile
                                                                                </p>
                                                                                <span routerLink="/hr/hrms-overview"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    style="cursor: pointer;  
                                                                                      color: #0000FF;">Employements</span>


                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/hrms-overview">
                                                                                    Employee Position</p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/hrms-overview">Skills
                                                                                </span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/hrms-overview">
                                                                                    Training</p>
                                                                                <span style="    color: #0000FF;"
                                                                                    routerLink="/hr/hrms-overview"
                                                                                    class="color-grey pl-20 col-lg-10 col-12">Qualification
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <span style="    color: #0000FF;"
                                                                                    routerLink="/hr/hrms-overview"
                                                                                    class="color-grey pl-20 col-lg-10 col-12">Leave
                                                                                </span>

                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12">
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Employees
                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/employees">
                                                                                Find Employees


                                                                            </p>
                                                                            <span routerLink="/hr/employees"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                style="cursor: pointer;  
                                                                                  color: #0000FF;">Employees List </span>


                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Employment


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/find-employement">
                                                                                Find Employment


                                                                            </p>
                                                                            <span routerLink="/hr/find-employement"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                style="cursor: pointer;  
                                                                                  color: #0000FF;">Employment List
                                                                            </span>


                                                                        </div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Departments


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select pb-4">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/departments">
                                                                                Departments List


                                                                            </p>



                                                                        </div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Employee Position


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/employee-position">
                                                                                Find Employee Position



                                                                            </p>
                                                                            <span routerLink="/hr/employee-position"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                style="cursor: pointer;  
                                                                                              color: #0000FF;"> Employee
                                                                                Position List
                                                                            </span>


                                                                        </div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Performance Review


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/hrm-performance-review">
                                                                                Find Performance Review


                                                                            </p>
                                                                            <span
                                                                                routerLink="/hr/hrm-performance-review"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                style="cursor: pointer;  
                                                                                                          color: #0000FF;">Performance
                                                                                Review List
                                                                            </span>


                                                                        </div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Skills


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/skills">
                                                                                Find Skills


                                                                            </p>
                                                                            <span routerLink="/hr/skills"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                style="cursor: pointer;  
                                                                                                                      color: #0000FF;">Skills
                                                                                List
                                                                            </span>


                                                                        </div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Find Party Qualification



                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/hrm-qualification">
                                                                                Find Party Qualification


                                                                            </p>
                                                                            <span routerLink="/hr/hrm-qualification"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                style="cursor: pointer;  
                                                                                                                                  color: #0000FF;">Party
                                                                                Qualification List
                                                                            </span>


                                                                        </div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                
                                                

                                            </div>

                                            <div style="width: 49.5%; float:right">
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Requisition

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select mb-3 pb-4">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/job-requisition">
                                                                                    Job Requisition</p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/job-requisition/internal-job-posting">
                                                                                    Internal Job Posting Application
                                                                                </span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/job-requisition/job-interview">
                                                                                    Job Interview</p>
                                                                                <span style="    color: #0000FF;"
                                                                                    routerLink="/hr/job-requisition/approval"
                                                                                    class="color-grey pl-20 col-lg-10 col-12">Approval

                                                                                </span>
                                                                            </div>

                                                                            <div class="article">

                                                                                <span style="    color: #0000FF;"
                                                                                    routerLink="/hr/job-requisition/reAllocations"
                                                                                    class="color-grey pl-20 col-lg-10 col-12">Relocation

                                                                                </span>
                                                                            </div>








                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Employee Leaves
                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/employee-leave">
                                                                                    Employee Leaves</p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/employee-leave/leave-approval">
                                                                                    Leave Approval</span>
                                                                            </div>








                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Timesheets

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select py-4">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <p style="cursor: pointer;    color: #0000FF;"
                                                                            class="color-grey pl-20 col-lg-10 col-12"
                                                                            routerLink="/hr/timesheets">
                                                                            Timesheets</p>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Training

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/training-approvals">
                                                                                    Training Calendar
                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/training-approvals">
                                                                                    Training Approvals
                                                                                </span>
                                                                            </div>




                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Employee Application




                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select  m-1 py-3">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article">
                                                                            <p style="cursor: pointer;    color: #0000FF;"
                                                                                class="color-grey pl-20 col-lg-10 col-12"
                                                                                routerLink="/hr/employee-application">
                                                                                Employee Application List



                                                                            </p>



                                                                        </div>


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                </div>
                                                

                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Resume

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/hrm-resume">
                                                                                    Resume
                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/hrm-resume">
                                                                                    Resume List
                                                                                </span>
                                                                            </div>




                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Global HR Settings


                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Skill Type
                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Responsibility Types

                                                                                </span>
                                                                            </div>




                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Termination Reasons

                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Termination Types

                                                                                </span>
                                                                            </div>




                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Position Types

                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Job Interview Type

                                                                                </span>
                                                                            </div>




                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Employee Leave Type

                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Pay Grades

                                                                                </span>
                                                                            </div>




                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Training Class Types
                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/hr/global-settings">
                                                                                    Public Holiday

                                                                                </span>
                                                                            </div>




                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                




                                            </div>





                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>


                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>