<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">User Login
                </span>
                <span class="color-black pl-1"> >Add User Login
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToQuickLink()">Cancel</button>&nbsp;
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                 Add User Login
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update User Login
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100"  [formGroup]="invitataionForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                   
                                                        User Login ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter User Login Id" filter="true"
                                                [options]="userLoginIdArray"  optionlabel="label"
                                                formControlName="loginId">
                                                </p-dropdown>
                                              
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">From Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Email Address"
                                                    
                                                    formControlName="fromDate">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Through Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Last Invite Date"
                                                    formControlName="throughDate">
                                              
                                                </div>
                                               
                                               
                                            </div>
                                          </div>

                                       
                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: 60%;">
                                    <button type="submit" *ngIf="!this.show" (click)="onSubmit();"
                                        class="btn btn-secondary submit-btn">Create</button>&nbsp;
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();"
                                        class="btn btn-secondary submit-btn">Update</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>