import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
@Injectable({
  providedIn: 'root'
})
export class DetailedEmployeeSummary {

  constructor(private http: HttpClient, private _ApiService: ApiService) { }

  getAllDepartment(employess, data): Observable<any> {
    const getAllDepartment = URLS.getDepartments + "?pageNo=" + employess.pageNo + "&pageSize=" + employess.pageSize;
    return this.http.post(getAllDepartment, data)
  }
  assignDepartment(partyId, departmentId): Observable<any> {
    const assignDepartment = URLS.assignDepartment.replace(":partyId", partyId).replace(":departmentId", departmentId);
    return this.http.post(assignDepartment, "")
  }
  getPartyContentList(id): Observable<any> {
    const getPartyContent = URLS.getPartyContentLists.replace(":partyId", id);
    return this.http.get(getPartyContent);
  }
  attachPartyContent(formData, partyContentTypeId, partyId): Observable<any> {
    const getPartyContent = URLS.attachPartyContent + "?partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.post(getPartyContent, formData);
  }
  getParty(): Observable<any> {
    const getPartyContent = URLS.partyContentDropdown;
    return this.http.get(getPartyContent);
  }
  getLeadNotesList(id): Observable<any> {
    const getContactNotes = URLS.getContactNotes.replace(":partyId", id);
    return this.http.get(getContactNotes);
  }
  deleteNote(noteId): Observable<any> {
    const deleteNote = URLS.deleteContactNote.replace(":noteId", noteId);
    return this.http.delete(deleteNote);
  }
  createEmergencyContact(partyId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/hr/employee/emergencyContact/${partyId}`, formData);
  }
  updateEmergencyContact(partyId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/hr/employee/emergencyContact/${partyId}`, formData);
  }
  getEmployeeById(partyId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/employee/${partyId}`);
  }
  // Telecom Number

  createEmployeeTelecomNumber(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/hr/employee/teleNumber`, formData);
  }
  updateEmployeeTelecomNumber(formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/hr/employee/teleNumber`, formData);
  }
  getTelecomById(contactMechId: string, partyId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/employee/teleNumber/${partyId}/${contactMechId}`);
  }
}
