import { Component, OnInit } from '@angular/core';
import { CreateContactsService } from "./create-contacts.service";
import { ActivatedRoute, NavigationStart, NavigationEnd, Event as RouterEvent, NavigationCancel, NavigationError } from '@angular/router';
import { Router } from '@angular/router';
import { CreateContactService } from './reactive-form-service/create-contact-service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { from } from 'rxjs';
import { HeaderService } from '../../../header/header.service';
import { count } from 'console';
declare var $: any;
@Component({
  selector: 'app-create-contacts',
  templateUrl: './create-contacts.component.html',
  styleUrls: ['./create-contacts.component.css']
})
export class CreateContactsComponent implements OnInit {
  createUpdate: any;


  address1: string;
  address2: string;
  areaCode: string;
  city: string;
  contactListId: string;
  contactNumber: string;
  countryCode: string;
  countryGeoId: string;
  emailAddress: string;
  extension: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  stateProvinceGeoId: string;
  suffix: string;
  token: any;
  countryList: any;
  country: any = [];
  array: any = [];
  stateArray: any = [];
  public stateData: any = []
  str = '';
  selectedState: any;
  countryId: any;
  partyId: any;
  updateData: any = [];
  show: boolean = false;
  partyIds: any;
  loading = true;
  contactList: any;
  contactListArray: any = [];
  gender: any = [];
  crmStatus: any;
  crmArray: any = [];
  activeCategory = 2;
  public selectCountryGeoId = '';



  constructor(public createContact: CreateContactsService,
    readonly activatedRoute: ActivatedRoute, readonly toastr: ToastrService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    public createContactService: CreateContactService) {

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.getCountryList();

    this.createContactService.create_contact_form = null;
    this.createContactService.preparePresonalInfoForm(this.createContactService.create_contact_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["updateId"];
    })

    this.getContactList();

    if (this.partyId !== null && this.partyId !== undefined) {
      this.getContactDetail();
      this.show = true;
    }
    else {
      this.show = false;
    }


    this.getStatusCrm();
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });


    this.gender.push({
      "label": "Male",
      "value": "M"
    },
      {
        "label": "Female",
        "value": "F"
      })
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  getStatusCrm() {
    this.spinner.show();
    this.createContact.getStatusCrm().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    
  }


  getContactDetail() {
    this.spinner.show();
    this.createContact.getContacts(this.partyId).subscribe(res => {
      this.spinner.hide();
      const form = this.createContactService.create_contact_form;
      this.countryId = res.data.address.countryId;
      form.patchValue({
        address1: res.data.address.address1,
        address2: res.data.address.address2,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        suffix: res.data.suffix,
        emailAddress: res.data.email.infoString,
        contactNumber: res.data.telenumber.contactNumber,
        areaCode: res.data.telenumber.areaCode,
        countryCode: res.data.telenumber.countryCode,
        countryGeoId: res.data.address.countryId.trim(),
        comments: res.data.comments,
        gender: res.data.gender,
        statusId: res.data.statusId,
        externalId: res.data.externalId,
        city: res.data.address.city,
        postalCode: res.data.address.postalCode,
        stateProvinceGeoId: res.data.stateId.trim()
      });
     
    })
   
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }
  getContactList() {
    this.spinner.show();
    this.createContact.getContactList().subscribe(res => {
      this.contactList = res.data;
      this.spinner.hide();
      for (const value of this.contactList) {
        this.contactListArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
   
  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  onSubmit() {
    this.spinner.show();
    this.createContactService.create_contact_form.markAllAsTouched();
    const formValues = { ...this.createContactService.create_contact_form.value };
    this.createContact.createContact(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/my-contacts'])
      } else if (res.success == false) {
        this.toastr.error(res.data.message);
        this.spinner.hide();
      }
    }, (err) => {
      if (err.error.success == false) {
        if (err.error.errors) {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }

        }
        else if (err.error.success == false) {
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      }
    })
   
  }
  onUpdate() {
    this.spinner.show();
    const formValues = { ...this.createContactService.create_contact_form.value };
    this.createContact.updateContacts(formValues, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Form Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/my-contacts'])
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
  
  }
  generalInformation(firstName,lastName){
  
    if(firstName && lastName){
      this.activeCategory=3
    }
    else{
      this.toastr.error("First Name and Last Name is required");
    }
  }
  addressInfo(address,country,city,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Address1,Country,City,State and Postal Code is required");
    }
  }
  phoneInformation(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Phone Number is required");
    }
  }
 
  emailInformation(email){
    if(email){
      this.activeCategory=8
    }
    else{
      this.toastr.error("Email is required");
    }
  }
  generalInfo(firstName,lastName){

    if(firstName && lastName){
      this.activeCategory=3
    }
    else{
      this.toastr.error("Fill General Information.");
    }
  }
  addressInformation(address,country,city,state,postal){
    if(address && country  && city  && state && postal){
      this.activeCategory=4
    }
    else{
      this.toastr.error("Fill Address.");
    }
  }
  phoneInfo(phone){
    if(phone){
      this.activeCategory=5
    }
    else{
      this.toastr.error("Fill Phone Information.");
    }
  }
  emailInformationAdded(email){
    if(email){
      this.activeCategory=8
    }
    else{
      this.toastr.error("Fill Email Information.");
    }
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
