<div class="w3-card-4 classCard" style="margin-bottom: 89px;">
    <!-- <div class="container-fluid">
        <ul class="tabbing-section tabbing-accordians tabClass">

            <li [ngClass]="activeCategory==2?'active':''"><a>
               AR Dashboard</a></li>
           

        </ul>
    </div> -->

    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="heads"> AR Dashboard</div>
                <div style="padding:0%;padding-top:2%;    width: 100%;padding-bottom:25%;">

                    <div id="chart">
                        <apx-chart
                          [series]="chartOptions.series"
                          [chart]="chartOptions.chart"
                          [labels]="chartOptions.labels"
                          [responsive]="chartOptions.responsive"
                          [colors]="chartOptions.colors" 
                        >
                        </apx-chart>
                    </div>

                    <!-- <div class="chartjs-container">
                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                            [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors"
                            [legend]="pieChartLegend">
                        </canvas>
                    </div> -->
                </div>

                <!-- <div style="padding-top:40px;    width: 100%;">
                    <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                    <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                    <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                    <div id="chartdiv6"></div>
                </div>
                <div style="padding-top:40px;    width: 100%;" class="mt-5 mb-4">
                    <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                    <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                    <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                    <div id="chartdivalpha"></div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>