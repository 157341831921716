import { FormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateContactModel {

  constructor(

  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      address1: (data && data.address1) ? data.address1 : null,
      gender: (data && data.gender) ? data.gender : null,
      comments: (data && data.gender) ? data.gender : null,
      statusId: (data && data.statusId) ? data.statusId : null,
      externalId: (data && data.externalId) ? data.externalId : null,
      address2: (data && data.address2) ? data.address2 : null,
      areaCode: (data && data.areaCode) ? data.areaCode : null,
      city: (data && data.city) ? data.city : null,
      contactListId: (data && data.contactListId) ? data.contactListId : null,
      contactNumber: (data && data.contactNumber) ? data.contactNumber : null,
      countryCode: (data && data.countryCode) ? data.countryCode : null,
      countryGeoId: (data && data.countryGeoId) ? data.countryGeoId : null,
      emailAddress: (data && data.emailAddress) ? data.emailAddress : null,
      extension: (data && data.extension) ? data.extension : null,
      firstName: (data && data.firstName) ? data.firstName : null,
      lastName: (data && data.lastName) ? data.lastName : null,
      postalCode: (data && data.postalCode) ? data.postalCode : null,
      stateProvinceGeoId: (data && data.stateProvinceGeoId) ? data.stateProvinceGeoId : null,
      suffix: (data && data.suffix) ? data.suffix : null,

    });

    return this.getFormGroup(form_data);
  }


  getFormGroup(data: any): any {
   
    return ({

      comments: new FormControl(data.comments),
      gender: new FormControl(data.gender),
      statusId: new FormControl(data.statusId),
      externalId: new FormControl(data.externalId),
      address1: new FormControl(data.address1, [Validators.required]),
      address2: new FormControl(data.address2),
      areaCode: new FormControl(data.areaCode),
      city: new FormControl(data.city, [Validators.required]),
      contactListId: new FormControl(data.contactListId),
      contactNumber: new FormControl(data.contactNumber, [Validators.required]),
      countryCode: new FormControl(data.countryCode, [Validators.required]),
      countryGeoId: new FormControl(data.countryGeoId, [Validators.required]),
      emailAddress: new FormControl(data.emailAddress, [Validators.required]),
      extension: new FormControl(data.extension),
      firstName: new FormControl(data.firstName, [Validators.required]),
      lastName: new FormControl(data.lastName, [Validators.required]),
      postalCode: new FormControl(data.postalCode, [Validators.required]),
      stateProvinceGeoId: new FormControl(data.stateProvinceGeoId, [Validators.required]),
      suffix: new FormControl(data.suffix),
    });
  }

  preparePayload(payload: any): any {
    return payload;
  }
}
