import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sales-product',
  templateUrl: './sales-product.component.html',
  styleUrls: ['./sales-product.component.css']
})
export class SalesProductComponent implements OnInit {
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  promotionsDetail: any;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _OrderManagementService: OrderManagementService
  ) {

  }
  offerShowingLineColoumns = [
    { field: 'status', header: 'Product' },
    { field: 'edit', header: '' },
  ];

  offerShowingLineData = [{
    status: 'Tiny Gizmo',
    delivery: 'Indian style Nan gizmo - Usually delivered in 1 Days!',
    gzValue: 'GZ-1000',
    price: 'Your Price: US$15.99',
    edit: 'Add to Basket',
  },

  ]

  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   

    this._OrderManagementService.getPromotionById(this._ActivatedRoute.snapshot.queryParams.promoId)
      .then(data => {
        this.promotionsDetail = data.data;
      });
  }

}
