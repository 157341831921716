import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CreatePaymentServices } from 'src/app/accounting/payments/create-payments/create-payment.service';
import { CreatePaymentFormService } from "src/app/accounting/payments/create-payments/create-payment-service";
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayablePaymentService } from 'src/app/accounting/accounting-ap/payables-payment/payables-payment.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-my-portal-new-invoice',
  templateUrl: './my-portal-new-invoice.component.html',
  styleUrls: ['./my-portal-new-invoice.component.css']
})
export class MyPortalNewInvoiceComponent implements OnInit {

 

  organizationPartyId = 'Company';
  toCompanyId = 'Company'
  fromPartyId = 'DemoCustomer'
  statusId = 'Not Paid'
  fromPartyIdByApi = '';
  finAccountTransIdByApi = '';
  toPartyIdByApi = '';

  allPaymentTypeList: any = [];
  allFinAccountIdList: any = [];
  allpaymentMethodId: any = [];
  allPartyIdList: any = [];
  currencyArray: any = [];
  allStatusList: any = []
  invoiceDetailsById:any;
  public paymentId: any;
  showUpdateBtn = false;
  showCreateBtn = false;
  payablePaymentForm: FormGroup;
  incomingPaymentForm: FormGroup;
  PaymentTypeOutgoingList: any[] = [];
  invoiceTypeAndStatus: any;
  invoiceTypeAndStatusArray: any[] = [];
  salesInvoiceTypeArray: any[] = [];
  invoiceTypeArray: any[] = [];
  GIAccounts: any[];
  outGoingPaymentForm: FormGroup;
  newSalesInvoiveForm: FormGroup;
  NewPurchaseInvoiceForm:FormGroup;
  salesInvoiceType:any;
  invoiceType:any;
  invoiceId:any;
  invoiceTimeEntries:any;
  constructor(
    public createPaymentFormService: CreatePaymentFormService,
    readonly createPaymentService: CreatePaymentServices,
    readonly _FormBuilder: FormBuilder,
    readonly _PayablePaymentService: PayablePaymentService,

    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router
  ) {
    this.NewPurchaseInvoiceForm = this._FormBuilder.group({
      InvoiceType: [''],
      partyIdFrom: [''],
      partyId: [''],
    
    });
    this.newSalesInvoiveForm = this._FormBuilder.group({
      InvoiceType: [''],
      partyIdFrom: [''],
      partyId: [''],
    
    });
  }


  public OrganizationPartyIdArray: any[] = [
    {
      value: "widget_div",
      label: "Widget Business Unit"
    },
    {
      value: "company",
      label: "Your Company Name Here"
    },
  ]
  ngAfterViewInit(): void {  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.paymentId = params["paymentId"];
    });
    this.getInvoiceTypeAndStatus();
    this.getFromPartyId();
    this.getPaymentAccountId();
    this.getCurrency();
    this.getGIAccounts();
    this.getPaymentTypes();
    this.getPaymentTypeOutgoing();
    this.getPaymentType();
    this.getInvoiceTypeAndStatus();
    this.spinner.hide();
  }



  getInvoiceById() {
    this.spinner.show();
    this.createPaymentService.getInvoiceSummary(this.invoiceId).subscribe(res => {
      this.invoiceDetailsById = res.data;

      setTimeout(() => {
        const formValue = this.newSalesInvoiveForm;
        formValue.patchValue({
          InvoiceType: this.invoiceDetailsById.invoiceType,
          partyIdFrom: this.invoiceDetailsById.partyIdFrom,
          partyId: this.invoiceDetailsById.partyId,
    
        })

      }, 2000);

      this.spinner.hide();
    })
  }
  createSalesInvoice(): void {
    this.spinner.show();
    const requestData = {
      "invoiceTypeId":  this.newSalesInvoiveForm.value.InvoiceType ,
      "partyId":  this.newSalesInvoiveForm.value.partyId ,
      "partyIdFrom": this.newSalesInvoiveForm.value.partyIdFrom ,
    } 
    this.createPaymentService.createSalesInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.newSalesInvoiveForm.reset();
        this.spinner.hide();
       this.invoiceId = res.data.invoiceId;
       this.router.navigate(['/myPortal/my-portal-create-invoice'],{ queryParams: { invoiceId:this.invoiceId}});


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getInvoiceTypeAndStatus() {
    this.spinner.show();
    this.createPaymentService.getInvoiceTypeAndStatus().subscribe(res => {
      this.invoiceTypeAndStatus = res.data.invoiceStatus;
      this.spinner.hide();
      for (const value of this.invoiceTypeAndStatus) {
        this.invoiceTypeAndStatusArray.push({
          label: value.description,
          value: value.statusId
        })
      }

      this.invoiceType = res.data.invoiceType;
      this.spinner.hide();
      for (const value of this.invoiceType) {
        this.invoiceTypeArray.push({
          label: value.description,
          value: value.invoiceTypeId
        })
      }


      this.salesInvoiceType = res.data.salesInvoiceType;
      this.spinner.hide();
      for (const value of this.salesInvoiceType) {
        this.salesInvoiceTypeArray.push({
          label: value.description,
          value: value.invoiceTypeId
        })
      }
    })
  }

  createOutgoingPayment(): void {
    this.spinner.show();
    if (this.payablePaymentForm.valid) {
      const {
        amount, comments,
        overrideGlAccountId, partyIdFrom,
        partyIdTo, paymentMethodId,
        paymentRefNum, paymentTypeId
      } = this.payablePaymentForm.value;
      this._PayablePaymentService.createOutgoingPayment({
        amount, comments,
        overrideGlAccountId, partyIdFrom,
        partyIdTo, paymentMethodId,
        paymentRefNum, paymentTypeId
      })
        .then(data => {
          if (data.success) {
            this.toastr.success('Created');
            this.spinner.hide();
            this.payablePaymentForm.reset();
          }
        });

      this.spinner.hide();
    }

  }
  getPaymentTypeOutgoing() {
    this.spinner.show();
    this.createPaymentService.getPaymentTypeOutgoing().subscribe(res => {
      if (res.success) {
        const PaymentTypeOutgoing = res.data;
        for (const value of PaymentTypeOutgoing) {
          this.PaymentTypeOutgoingList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  getPaymentTypes() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const allStatusList = res.data.status;
        const finAccountIdList = res.data.finAccountId;
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        }
        for (const value of allStatusList) {
          this.allStatusList.push({
            label: value.description,
            value: value.statusId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  getGIAccounts(): void {
    this.spinner.show();
    this._PayablePaymentService.getGIAccountAndPaymentMethod()
      .then(data => {
        if (data) {
          this.GIAccounts = data.data.glAccounts.map(value => {
            return {
              label: value.glAccountId,
              value: value.glAccountId
            };
          });
        }
      });
    this.spinner.hide();
  }
  getPaymentType() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const finAccountIdList = res.data.finAccountId
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        }

      }

    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  getFromPartyId() {
    this.spinner.show();
    this.createPaymentService.getFromPartyId().subscribe(res => {
      if (res.success) {
        const allPartyId = res.data.PersonsGroups;
        for (const value of allPartyId) {
          this.allPartyIdList.push({
            label: value.partyId,
            value: value.partyId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  getPaymentAccountId() {
    this.spinner.show();
    this.createPaymentService.getPaymentAccoundId(this.organizationPartyId).subscribe(res => {
      if (res.success) {
        const paymentMethodId = res.data.paymentMethodId;
        for (const value of paymentMethodId) {
          this.allpaymentMethodId.push({
            label: value.paymentMethodId,
            value: value.paymentMethodId
          })
        }
      }
    }
    )
    this.spinner.hide();
  }

  getPaymentDetailsById() {
    this.spinner.show();
    this.createPaymentService.getFromPartyId().subscribe(res => {
      if (res.success) {
        const allPartyId = res.data.PersonsGroups;
        for (const value of allPartyId) {
          this.allPartyIdList.push({
            label: value.partyId,
            value: value.partyId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }


  onSubmit() {
    this.spinner.show();
    const partyIdTo = this.organizationPartyId
    const formValues = { ...this.createPaymentFormService.create_payment_form.value, partyIdTo };

    this.createPaymentService.createPayment(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }

  getPaymentDetailsByIdForUpdate() {
    this.spinner.show();
    this.createPaymentService.getPaymentDetailsById(this.paymentId).subscribe(res => {
      if (res.success) {

        this.fromPartyIdByApi = res.data[0].partyIdFrom;
        this.finAccountTransIdByApi = res.data[0].financialAccountTransaction;
        this.toPartyIdByApi = res.data[0].partyIdTo;

      }
    }
    )
    this.spinner.hide();
  }


  getCurrency() {
    this.spinner.show();
    this.createPaymentService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

