



<div class="container-fluid main-container-wrapper">
    <br>
  

    <div class="row">
        <div class="col-md-12">
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                   <span class="color-black pl-1 titlePanels">Payroll
                    </span>

                    <span>
                        <button type="submit" class="btn btn-secondary submit-btn mr-2"
                            (click)="toDashboard()">Home</button>
                        <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                    </span>
                </div>
                <div class="w3-card-4 classCard classCardType" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategory==3?'active':''" (click)="main()"><a>
                            Main</a></li>
                        <li [ngClass]="activeCategory==1?'active':''" (click)="Vendor()"><a>
                            Vendor Agreement</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="Customer()"><a>
                            Customer Agreements</a></li>
                        
                        <li [ngClass]="activeCategory==4?'active':''" (click)="toEmployeeContract()"><a>
                            Employee Contracts</a></li>
                       
                    </ul>
                </div>
    
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                      
                           
                        <div class="panel-group">
                            <div *ngIf="activeCategory==3">
                                
                            </div>
                            

                        </div>
                                  
                    
                    </div>
                </div>
                </div>
            </div>
        </div>
     
        
    </div>
</div>
<ngx-spinner></ngx-spinner> 

<ngx-spinner></ngx-spinner> 