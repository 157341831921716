import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-create-gift-card',
    templateUrl: './create-gift-card.component.html',
    styles: [`
    .border-card{
        border: 1px solid;
        border-color: #0d3769 !important;
        /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
    }
`]
})
export class CreateGiftCardComponent implements OnInit {
    partyId: string;
    editMode: boolean;
    giftForm: FormGroup;
    expirationMonth = [
        {
            label: '1 (Jan)',
            value: '1'
        },
        {
            label: '2 (Feb)',
            value: '2'
        },
        {
            label: '3 (Mar)',
            value: '3'
        },
        {
            label: '4 (Apr)',
            value: '4'
        },
        {
            label: '5 (May)',
            value: '5'
        },
        {
            label: '6 (Jun)',
            value: '6'
        },
        {
            label: '7 (Jul)',
            value: '7'
        },
        {
            label: '8 (Aug)',
            value: '8'
        },
        {
            label: '9 (Sep)',
            value: '9'
        },
        {
            label: '10 (Oct)',
            value: '10'
        },
        {
            label: '11 (Nov)',
            value: '11'
        },
        {
            label: '12 (Dec)',
            value: '12'
        }];
    expirationYear = [
        {
            label: '2020',
            value: '2020'
        },
        {
            label: '2021',
            value: '2021'
        },
        {
            label: '2022',
            value: '2022'
        },
        {
            label: '2023',
            value: '2023'
        },

        {
            label: '2024',
            value: '2024'
        },
        {
            label: '2025',
            value: '2025'
        },
        {
            label: '2026',
            value: '2026'
        },
        {
            label: '2027',
            value: '2027'
        },
        {
            label: '2028',
            value: '2028'
        },
        {
            label: '2029',
            value: '2029'
        },
        {
            label: '2030',
            value: '2030'
        }];
    giftCard: any;
    paymentMethodId: string;
    @ViewChild('closebutton') closebutton;
	
    constructor(
        readonly _ToastrService: ToastrService,
        readonly _CRMAccountService: CRMAccountService,
        readonly _FormBuilder: FormBuilder,private _location: Location,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner:NgxSpinnerService,
        readonly  _Router: Router,
        public bsModalRef: BsModalRef
    ) {
        this.editMode = false;
        this.giftForm = this._FormBuilder.group({
            cardNumber: ['', [Validators.required]],
            description: [''],
            expMonth: ['', Validators.required],
            expYear: ['', Validators.required],
            pinNumber: ['', Validators.required]
        });
        this.partyId = '';
        this.paymentMethodId = '';
        
    }
    ngOnInit(): void {
        this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        if (this.paymentMethodId) {
            this.getPaymentMethod();
        }
    }
    getPaymentMethod(): void {
        this.spinner.show();
        this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'GIFT_CARD')
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.giftCard = data.data.giftCardData;
                    this.giftForm.patchValue({
                        ...data.data.giftCardData, ...{
                            description: data.data.paymentMethodData.description
                        }
                    });
                    this.editMode = true;
                   
                }
            });
           
    }
    submit(): void {
        this.spinner.show();
        if (this.giftForm.valid) {
            this._CRMAccountService.createGiftCard(this.partyId, this.giftForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
        
    }
    update(): void {
        this.spinner.show();
        if (this.giftForm.valid) {

            this._CRMAccountService.updateGiftCard(this.partyId, this.paymentMethodId, {
                ...this.giftCard, ...this.giftForm.value
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
       
    }

    cancelSubmit(): void {
        this.giftForm.reset();
        this._location.back();
    }

    modalClose(): void {
        this.closebutton.nativeElement.click();
    }
}