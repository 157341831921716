import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductionService } from '../production.service';
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-detail-routing-summary',
  templateUrl: './detail-routing-summary.component.html',
  styleUrls: ['./detail-routing-summary.component.css']
})
export class DetailRoutingSummaryComponent implements OnInit {
  activeCategory=1;
  activeCategoryMain=2;
  products3: any;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  ReportTax:any[]=[];
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
   finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction:any[]=[];
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  routingId: any;
  taskAssoc: any;
  productLink: any;
  editInfo: any;
  createroute:FormGroup;
 
  id: string;
  statusId: any;
  statusIdArray: any[]=[];
  activeCategoryMainPage=2;
  show: boolean;
  createRouteAssoc:FormGroup;

  actualfromdate: string;
  actualthroughdate: string;
  taskList: any;
  taskListArray: any[]=[];
  showProduct: boolean;
  createRouteProduct:FormGroup;
 
  productListArray: any[]=[];
 
  fromdate: any;
  productid: any;
  workid: any;
 
  getEstimateFrom: string;
  getEstimateTo: string;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService, private _location: Location,
    readonly productionService:ProductionService,
    readonly _ToastrService: ToastrService,
    readonly accountsService: AccountsService,
    readonly datePipe: DatePipe,
    readonly router: Router) { 
      this.createroute = this._FormBuilder.group({
        RoutingName: [''],
        Description:[''],
        QtyToProduce:[''],
        CurrentStatusID: [''],
      
       
      });
      this.createRouteAssoc = this._FormBuilder.group({
        RoutingTaskID: [''],
        FromDate:[''],
        ThroughDate:[''],
        SequenceNum: [''],
       
      });
      this.createRouteProduct = this._FormBuilder.group({
        ProductID: [''],
        FromDate:[''],
        ThroughDate:[''],
        Quantity: [''],
        Estimatedcost:['']
      
       
      });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingId = params["routingId"];
    });
  
      this.show=true;
     this.showProduct=false;
   
    this.gettaskAssoc();
    this.getproductLink();
    this.getRoutingListById();
    this.getstatusByType();
    this.getroutingTaskList();
    this.getProducts();
  }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }

  onSubmitProduct() {
    this.spinner.show();
  
    const fromActualdate=this.createRouteProduct.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRouteProduct.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "estimatedCost": this.createRouteProduct.value.Estimatedcost,
      "estimatedQuantity":this.createRouteProduct.value.Quantity,
      "fromDate": this.actualfromdate,
      "productId": this.createRouteProduct.value.ProductID,
      "thruDate": this.actualthroughdate
    }
    this.productionService.postproductLink(this.routingId,requestData).subscribe((res: any) => {  
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.getproductLink();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    }); }
  onUpdateProduct() {
    this.spinner.show();
    const throughActualdate=this.createRouteProduct.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "estimatedCost": this.createRouteProduct.value.Estimatedcost,
      "estimatedQuantity":this.createRouteProduct.value.Quantity,
      "fromDate": this.fromdate,
      "productId": this.createRouteProduct.value.ProductID,
      "thruDate": this.actualthroughdate
    }
    this.productionService.updateproductLink(this.routingId,requestData).subscribe((res: any) => {    
    if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.getproductLink();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }   }
        else{
          this._ToastrService.error(err.error.message);
        }
    }); }
  getroutingTaskList() {
    this.spinner.show();
    
    this.productionService.getroutingTaskList().subscribe(res => {
      this.taskList = res.data.lookupResult;
      this.spinner.hide();
      for (const value of this.taskList) {
        this.taskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }

  onSubmitExisting() {
    this.spinner.show();
    const fromActualdate=this.createRouteAssoc.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRouteAssoc.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "copyTask": "N",
      "fromDate":  this.actualfromdate,
      "routingTaskId": this.createRouteAssoc.value.RoutingTaskID,
      "sequenceNum": this.createRouteAssoc.value.SequenceNum,
      "thruDate":this.actualthroughdate
    }
    this.productionService.postaddExistingRoutingTask(this.routingId,requestData).subscribe((res: any) => {  
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.gettaskAssoc();
        this.getproductLink();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }}); }
  onSubmitCopy() {
    this.spinner.show();
    const fromActualdate=this.createRouteAssoc.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRouteAssoc.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "copyTask": "Y",
      "fromDate":  this.actualfromdate,
      "routingTaskId": this.createRouteAssoc.value.RoutingTaskID,
      "sequenceNum": this.createRouteAssoc.value.SequenceNum,
      "thruDate":this.actualthroughdate
    }
    this.productionService.postaddExistingRoutingTask(this.routingId,requestData).subscribe((res: any) => {   
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.gettaskAssoc();
        this.getproductLink();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    }); }
  getRoutingListByIdEdit() {
    this.spinner.show();
  
    this.productionService.getRoutingListById(this.routingId).subscribe((res: any) => {
     
      this.spinner.hide();
      setTimeout(() => {
       
      
        const formValue = this.createroute;

        formValue.patchValue({
          RoutingName: res.data.routingName,
          Description:res.data.description,
          QtyToProduce:res.data.quantityToProduce,
          CurrentStatusID: res.data.currentStatusId,
        })
      
      }, 2000);
    })
  
  }
  getstatusByType() {
    this.spinner.show();
    this.id="ROUTING_STATUS"
    this.productionService.getstatusByType(this.id).subscribe(res => {
      this.statusId = res.data;
      this.spinner.hide();
      for (const value of this.statusId) {
        this.statusIdArray.push({
          label: value.statusCode,
          value: value.statusId
        })
      }
    })
    
  }

  onUpdate() {
  
    const requestData = {
      "currentStatusId": this.createroute.value.CurrentStatusID,
      "description": this.createroute.value.Description,
      "quantityToProduce": this.createroute.value.QtyToProduce,
      "routingId": this.routingId,
      "routingName": this.createroute.value.RoutingName,

    }

    this.productionService.updateRouting(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this.getRoutingListById();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getRoutingListById() {
    this.spinner.show();
  
    this.productionService.getRoutingListById(this.routingId).subscribe((res: any) => {
     this.editInfo=res.data;
    
      this.spinner.hide();
    
    })
  
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  gettaskAssoc() {
    this.spinner.show();
    this.productionService.gettaskAssoc(this.routingId,this.finSize).subscribe((res: any) => {
      this.taskAssoc = res.data;
      this.spinner.hide();
    })
  
  }
  getproductLink() {
    this.spinner.show();
    this.productionService.getproductLink(this.routingId,this.finSize).subscribe((res: any) => {
      this.productLink = res.data;
      this.spinner.hide();
    })
  
  }
  
  
  deleteproductLink(fromDate,productid,workeffort) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deleteproductLink(this.routingId,fromDate,productid,workeffort).subscribe((res: any) => {
          this.getproductLink();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  removeRoutingTaskAssoc(fromDate,workEffortAssocTypeId,workEffortId,workEffortIdFrom,workEffortIdTo) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate":fromDate,
          "workEffortAssocTypeId":workEffortAssocTypeId,
          "workEffortId":this.routingId,
          "workEffortIdFrom": workEffortIdFrom,
          "workEffortIdTo":workEffortIdTo
         
        }
        this.productionService.removeRoutingTaskAssoc(req).subscribe((res: any) => {
         
          this.gettaskAssoc();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  
  createTaskAssoc() {
    this.router.navigate(["production/create-routing-task-assoc"],{ queryParams: { routingId : this.routingId}})

  }
  createProductLink() {
    this.router.navigate(["production/create-routing-product-link"],{ queryParams: { routingId : this.routingId}})

  }
  updateRouting(efromdate,eproductid,eworkid) {
    this.router.navigate(["production/create-routing-product-link"],{ queryParams: { routingId : this.routingId,fromdate:efromdate,productid:eproductid,workid:eworkid}})

  }
  

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


