import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-route-task-cost',
  templateUrl: './create-route-task-cost.component.html',
  styleUrls: ['./create-route-task-cost.component.css']
})
export class CreateRouteTaskCostComponent implements OnInit {
  createCost:FormGroup;
  activeCategory=2;
  costComponentCalcList: any;
  costComponentCalcListArray: any[]=[];
  costComponentTypeList: any;
  costComponentTypeListArray: any[]=[];
  routingTaskId: any;
  startDate: string;
  fromDate: string;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
   
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createCost = this._FormBuilder.group({
        CostComponentTypeID: [''],
        CostComponentCalcID:[''],
        FromDate:[''],
        ThroughDate: [''],
 
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingTaskId = params["routingTaskId"];
    });
    this.getRoutingTaskCost();
  }
 
  
  onSubmit() {
    this.spinner.show();
    const startDate = this.createCost.get('ThroughDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createCost.get('FromDate').value;
    this.fromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "costComponentCalcId": this.createCost.value.CostComponentCalcID,
      "costComponentTypeId":this.createCost.value.CostComponentTypeID,
      "fromDate": this.fromDate,
      "thruDate":this.startDate ,
    }

    this.productionService.postRoutingTaskCost(this.routingTaskId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getRoutingTaskCost() {
    this.spinner.show();
   
    this.productionService.getRoutingTaskCost().subscribe(res => {
      this.costComponentCalcList = res.data.costComponentCalcList;
      this.costComponentTypeList=res.data.costComponentTypeList;
      this.spinner.hide();
      for (const value of this.costComponentCalcList) {
        this.costComponentCalcListArray.push({
          label: value.description,
          value: value.costComponentCalcId
        })
      }
      for (const value of this.costComponentTypeList) {
        this.costComponentTypeListArray.push({
          label: value.description,
          value: value.costComponentTypeId
        })
      }
    })
    
  }
  cancelSubmit(){
    this._Router.navigate(['/production/detail-routing-task-summary'],{ queryParams: { routingTaskId :this.routingTaskId}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


