import { Component, OnInit } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
@Component({
  selector: 'app-additional-items',
  templateUrl: './additional-items.component.html',
  styleUrls: ['./additional-items.component.css']
})
export class AdditionalItemsComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  constructor(readonly accountsService: AccountsService,
    readonly router: Router) { }

  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
 
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  addPromotions(){
    this.router.navigateByUrl('/create-agreements');
  }

}
