import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-work-effort-party-assigns',
  templateUrl: './work-effort-party-assigns.component.html',
  styleUrls: ['./work-effort-party-assigns.component.css']
})
export class WorkEffortPartyAssignsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  activeCategory = 4;
  addParty: FormGroup;
  personId: any;
  partyIdArray: any[]=[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  AvailabilityList: any;
  AvailabilityListArray: any[]=[];
  ExpectationList: any;
  ExpectationListArray: any[]=[];
  DelegateReasonList: any;
  DelegateReasonListArray: any[]=[];
  FacilityIdList: any;
  FacilityIdListArray: any[]=[];
  PartyAssignmentByIddata: any;
  workEffortId: any;
  StatusListsss: any;
  StatusListsssArray: any[]=[];
  FromDate: string;
  ThroughDate: string;
  show: boolean;
  Fromdate: string;
  updateThroughDate: any;
  updatefacilityID: any;
  updatecomments: any;
  mustRspV: any;
  updateFromdate: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    private _location: Location,
    
    readonly datePipe: DatePipe,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly myContactsService: MyContactsService,
    readonly addSkillService: AddSkillService,
        readonly _FormBuilder: FormBuilder,
    private _Router : Router

  ) {
    this.addParty = this._FormBuilder.group({
      partyID: [''],
      roleType: [''],
      fromDate: [''],
      throughDate: [''],
      statusID: [''],
      availability: [''],
      expectation: [''],
      delegateReason: [''],
      facilityID: [''],
      comments: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    });
    this.getPartyId();
    this.getRoleTypeId();
    this.getStatusListsss();
    this.getAvailabilityList();
    this.getExpectationList();
    this.getDelegateReasonList();
    this.getFacilityIdList();
    this.getPartyAssignmentById();
  }
  createPartyAssignment(): void {
    this.spinner.show();
    const fromDate=this.addParty.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addParty.get('throughDate').value;
    this.ThroughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
      "availabilityStatusId": this.addParty.value.availability,
      "comments": this.addParty.value.comments,
      "delegateReasonEnumId": this.addParty.value.delegateReason,
      "expectationEnumId": this.addParty.value.expectation,
      "facilityId": this.addParty.value.facilityID,
      "fromDate": this.FromDate,
      "partyId": this.addParty.value.partyID,
      "roleTypeId": this.addParty.value.roleType,
      "statusId": this.addParty.value.statusID,
      "thruDate": this.ThroughDate,
      "workEffortId": this.workEffortId
         
     }
     this.myContactsService.createPartyAssignment(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addParty.reset();
         this.getPartyAssignmentById();
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   homeButton(){
      this._Router.navigate(['/psa/Dashboard'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    
   updateWorkEffortPartyAssign(): void {
    this.spinner.show();
    const fromDate=this.addParty.get('fromDate').value;
    this.Fromdate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addParty.get('throughDate').value;
    this.ThroughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "availabilityStatusId": this.addParty.value.availability,
      "comments": this.addParty.value.comments,
      "delegateReasonEnumId": this.addParty.value.delegateReason,
      "expectationEnumId": this.addParty.value.expectation,
      "facilityId": this.addParty.value.facilityID,
      "fromDate": this.updateFromdate,
      "mustRsvp":this.mustRspV,
      "partyId": this.addParty.value.partyID,
      "roleTypeId": this.addParty.value.roleType,
      "statusDateTime": "",
      "statusId": this.addParty.value.statusID,
      "thruDate": this.ThroughDate,
      "workEffortId": this.workEffortId
  
   
      }
     
    this.myContactsService.updateWorkEffortPartyAssign(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getPartyAssignmentById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  updateEvent(product){
    this.show=true;
    this.addParty.reset();
    this.spinner.show();
      this.mustRspV=product.data.mustRsvp;
      this.updateThroughDate=product.data.thruDate;
      this.updatefacilityID=product.data.facilityId;
      this.updatecomments=product.data.comments;
      this.updateFromdate=product.fromDate;
      setTimeout(() => {
        const fromdate=product.data.fromDate;
        this.FromDate=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const thrudate=product.data.throughDate;
        this.ThroughDate=this.datePipe.transform(thrudate,"yyyy-MM-dd");
        const formValue = this.addParty;
        formValue.patchValue({
  
      "partyID": product.data.partyId,
      "roleType": product.data.roleTypeId,
      "fromDate": this.FromDate,
      "throughDate": this.ThroughDate,
      "statusID": product.data.statusId,
      "availability": product.data.availabilityStatusId,
      "expectation": product.data.expectationEnumId,
      "delegateReason": product.data.delegateReasonEnumId,
      "facilityID": product.data.facilityId,
      "comments": product.data.comments,
        })
      
      }, 2000);
  
  }
  getPartyAssignmentById(){
    this.spinner.show();
    this.myContactsService.getPartyAssignmentById(this.workEffortId).subscribe((res:any)=> {
      this.PartyAssignmentByIddata = res.data;
      this.spinner.hide();
    
    })   
   }
   addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  detailParties(partyId){
    this._Router.navigate(["/partyQuickLink/profile-party-quick-link"],{ queryParams: { party:partyId} })
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"]),{ queryParams: { workEffortId: this.workEffortId} }
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
   getRoleTypeId() {
    this.spinner.show();
    this.addSkillService.getRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getStatusListsss() {
    this.spinner.show();
    this.addSkillService.getStatusListsss().subscribe(res => {
      this.StatusListsss = res.data;
      this.spinner.hide();
      for (const value of this.StatusListsss) {
        this.StatusListsssArray.push({
          label: value.statusDescription,
          value: value.statusValue
        })
      }
    })
  }
  getAvailabilityList() {
    this.spinner.show();
    this.addSkillService.getAvailabilityList().subscribe(res => {
      this.AvailabilityList = res.data[0].data;
      this.spinner.hide();
      for (const value of this.AvailabilityList) {
        this.AvailabilityListArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  getExpectationList() {
    this.spinner.show();
    this.addSkillService.getExpectationList().subscribe(res => {
      this.ExpectationList = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ExpectationList) {
        this.ExpectationListArray.push({
          label: value.description,
          value: value.enumTypeId
        })
      }
    })
  }
  getDelegateReasonList() {
    this.spinner.show();
    this.addSkillService.getDelegateReasonList().subscribe(res => {
      this.DelegateReasonList = res.data[0].data;
      this.spinner.hide();
      for (const value of this.DelegateReasonList) {
        this.DelegateReasonListArray.push({
          label: value.description,
          value: value.enumTypeId
        })
      }
    })
  }
  getFacilityIdList() {
    this.spinner.show();
    this.addSkillService.getFacilityIdList().subscribe(res => {
      this.FacilityIdList = res.data;
      this.spinner.hide();
      for (const value of this.FacilityIdList) {
        this.FacilityIdListArray.push({
          label: value.node1.facilityName,
          value: value.node1.facilityId

        })
      }
    })
  }

}


