import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';
import { FormControl, FormGroup, Validators,  } from '@angular/forms';
import { Location } from '@angular/common';


import Swal from 'sweetalert2';
@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.css']
})
export class TeamDetailComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton17') closebutton17;
  @ViewChild('closebutton18') closebutton18;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addTeamMember: any;
  editTeam: any;
  TeamByPartyId: any;
  party: any;
  partyId: any;
  Role: any;
  RoleArray: any[]=[];
  show: boolean;
  TeamMemberByPartyId: any;
  updateTeamMem: any;
  Deactivated: boolean;
  personId: any;
  partyIdArray: any[]=[];
  teamMember: any;
  teamMemberArray: any[]=[];
  createCasesForm: FormGroup;
  createAccountsForm: FormGroup;
  createNotesForm: FormGroup;
  ClassificationBookmarkArray: any[]=[];
  bookmarksUploadPartner: any;
  fileUpload: FormGroup;
  addBookmark: FormGroup;
  showBookmark: boolean;
  showUpload: boolean;
  productBook: any;
  BookUpload: any;
  fileURL: any;
  bookMarksUrlPartner: any;
  ClassificationBookmark: any;
  fileData: File;
  addNote: FormGroup;
  showNote: boolean;
  noteProduct: any;
  NotePartnerList: any;
  createOrderForm: FormGroup;
  createCasesNewForm: FormGroup;
  actualfromdate: string;
  shipBeforeDate: string;
  productOrder: any;
  allOrderByData: any;
  productOpp: any;
  allOpportunityData: any;
  createCatalogForm: FormGroup;
  showLogCall: boolean;
  productLogCall: any;
  logTaskForm: any;
  logTaskCallPartnerId: any;
  logEmailForm: FormGroup;
  LogTaskEmail: any;
  showLogEmail: boolean;
  productLogEmail: any;
  allCatalogRequestData: any;
  productCatalog: any;
  OutboundArray: { label: string; value: string; }[];
  yesNoArray: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  PurposeArray: { label: string; value: string; }[];
  AvailabilityArray: { label: string; value: string; }[];
  securityArray: { label: string; value: string; }[];
  PurposeWebUrlArray: { label: string; value: string; }[];
  hours: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  SalesOpportunityArray: any[]=[];
  SalesOpportunity: any;
  Cases: any;
  CasesArray: any[]=[];
  productCasessNew: any;
  allCasessData: any;
  
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,

    readonly _Router: Router,
    private _location: Location,
  ) 
  {
    this.addTeamMember = this._FormBuilder.group({
      newTeamMember: [''],
      role: [''],
    });
    this.editTeam = this._FormBuilder.group({
      name: [''],
      comments: [''],
    })

    this.createCasesForm=this._FormBuilder.group({
      opportunityName:"",
      stage:"",
      opportunityAmount:"",
      estimatedCloseDate:"",
    })

    this.createAccountsForm=this._FormBuilder.group({
      accountName:"",
      city:"",
      email:"",
      primaryPhone:"",
    })

    this.createNotesForm=this._FormBuilder.group({
      noteInfo:"",
      createdBy:"",
      noteDateTime:"",
    })

    this.addBookmark = this._FormBuilder.group({
      classification: [''],
      url: [''],
      description: [''],
    });
    this.fileUpload = this._FormBuilder.group({
      classification: [''],
      file: [''],
      description: [''],
    });
    this.addNote = this._FormBuilder.group({
      note: ['']
    });

    this.createOrderForm = this._FormBuilder.group({
      amount: "",
      customer: "",
      orderDate: "",
      orderName: "",
      po: "",
      shipBeforeDate: "",
      status: ""
    })
    this.createCasesForm = this._FormBuilder.group({
      opportunityName: "",
      stage: "",
      opportunityAmount: "",
      estimatedCloseDate: "",
    })
    this.createCasesNewForm = this._FormBuilder.group({
      priority: "",
      reason: "",
      status: "",
      subject: "",
      type: ""
    });
    this.logTaskForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      hourFrom: [''] ,
      Duration: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] ,
    });
    this.logEmailForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      Duration: [''] ,
      hourFrom: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] 
    });
    this.PurposeWebUrlArray = [
      {
        label: 'Primary Website URL',
        value: 'PRIMARY_WEB_URL'
      },
      {
        label: 'iCalendar URL',
        value: 'ICAL_URL'
      },

    ];
    this.PurposeArray = [
      {
        label: 'Email',
        value: 'WEPT_TASK_EMAIL'
      },
      {
        label: 'Phone Call',
        value: 'WEPT_TASK_PHONE_CALL'
      },

    ];
    this.AvailabilityArray = [
      {
        label: 'Available',
        value: 'WEPA_AV_AVAILABLE'
      },
      {
        label: 'Busy',
        value: 'WEPA_AV_BUSY'
      },
      {
        label: 'Away',
        value: 'WEPA_AV_AWAY'
      },
    ];
    this.securityArray = [
      {
        label: 'General, public access',
        value: 'WES_PUBLIC'
      },
      {
        label: 'Very restricted, confidential access',
        value: 'WES_CONFIDENTIAL'
      },

    ];
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      }, {
        label: '54',
        value: '54'
      }, {
        label: '55',
        value: '55'
      }, {
        label: '56',
        value: '56'
      }, {
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
    this.OutboundArray = [
      {
        label: 'Outbound',
        value: 'Y'
      },
      {
        label: 'Inbound',
        value: 'N'
      },

    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params.party;
    })
    this.Deactivated= false;
    this.getTeamByPartyId();
    this.getTeamMemberByPartyId();
    this.getRole();
    this.getTeamMember();
    this.getPartyId();
    this.getOpportunity();
    this.getBookMarksUrlIdPartner();
    this.getBookmarksUploadPartner();
    this.getLogTaskCallPartnerId();
    this.getLogTaskEmailPartnerId();
    this.getCatalogRequestByPartyId();
    this.getClassificationBookmark();
    this.getCases();
    this.getSalesOpportunity();
    this.getCasessByPartyId();
    this.getSalesOpportunity();
    this.getSalesOpportunity();
        this.getNotePartnerBId();
        this.getOrderByPartyId();

  }

  getOpportunity() {
    this.spinner.show();
    this.myContactsService.getOpportunity(this.partyId).subscribe(res => {
      this.allOpportunityData = res.data[0].getOpportunity;
      this.spinner.hide();
    })
  }

  deleteOrder(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "orderId": product.orderId,
          "partnerId": product.partnerId
        }
        this.accountsService.deleteOrder(formData).subscribe(res => {
          this.getOrderByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateOrder() {
    this.spinner.show();
    const fromActualdate = this.createOrderForm.get('orderDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate = this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate = this.datePipe.transform(shipBeforeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "orderId": this.productOrder.orderId,
      "orderName": this.createOrderForm.value.orderName,
      "partnerId": this.productOrder.partnerId,
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.updateOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOrderPatch(product) {
    this.spinner.show();
    this.show = true;
    this.productOrder = product;
    setTimeout(() => {
      const formValue = this.createOrderForm;
      formValue.patchValue({
        amount: product.amount,
        customer: product.customer,
        orderDate: this.datePipe.transform(product.orderDate, "yyyy-MM-dd"),
        orderName: product.orderName,
        po: product.po,
        shipBeforeDate: this.datePipe.transform(product.shipBeforeDate, "yyyy-MM-dd"),
        status: product.status
      })
    }, 2000);
    this.spinner.hide();
  }
  createOrder() {
    this.spinner.show();
    const fromActualdate = this.createOrderForm.get('orderDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate = this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate = this.datePipe.transform(shipBeforeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "orderId": this.partyId,
      "orderName": this.createOrderForm.value.orderName,
      "partnerId": "",
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.createOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getOrderByPartyId() {
    this.spinner.show();
    this.accountsService.getOrderByPartyId(this.partyId).subscribe(res => {
      this.allOrderByData = res.data[0].getOrderByPartyId;
      this.spinner.hide();
    })
  }
  resetNote(){
    this.showNote = false ;
    this.addNote.reset();

  }
  updateNotes(product){
    this.showNote = true;
    this.noteProduct = product ;

    setTimeout(()=>{
      const formValue = this.noteProduct;
      formValue.patchValue({
        note: product.noteInformation,
      }
      )

    },2000
    );

  }
  deleteNotePartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {

          "noteId": product.noteId,
          "partnerId": product.partyId,
        }
        this.myContactsService.deleteNotePartner(formData).subscribe(res => {
        this.getNotePartnerBId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateNotePartner() {
    this.spinner.show();
    const requestData = {
      "noteId": this.noteProduct.noteId  ,
      "noteInformation": this.addNote.value.note,
      "partnerId": this.partyId
    }
    this.myContactsService.UpdateNotePartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.addNote.reset();
        this.getNotePartnerBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createNotePartner() {
    this.spinner.show();
    const requestData = {
      "noteId": "",
      "noteInformation": this.addNote.value.note,
      "partnerId": this.partyId
    }
    this.myContactsService.createNotePartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.addNote.reset();
        this.getNotePartnerBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getNotePartnerBId() {
    this.spinner.show();
    this.myContactsService.getNotePartnerBId(this.partyId).subscribe(res => {
      this.NotePartnerList = res.data[0].getNotePartnerAll;
      this.spinner.hide();

    })
  }
  getCasessByPartyId() {
    this.spinner.show();
    this.accountsService.getCasessByPartyId(this.partyId).subscribe(res => {
      this.allCasessData = res.data[0].getCasessByPartyId;
      this.spinner.hide();
    });
  }
  deleteCasess(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "casesId": product.casesId,
          "partyId": product.partyId
        }
        this.accountsService.deleteCasess(formData).subscribe(res => {
          this.getCasessByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createCasess() {
    this.spinner.show();
    const requestData = {
      "casesId": "",
      "partyId": this.partyId,
      "priority": this.createCasesNewForm.value.priority,
      "reason": this.createCasesNewForm.value.reason,
      "status": this.createCasesNewForm.value.status,
      "subject": this.createCasesNewForm.value.subject,
      "type": this.createCasesNewForm.value.type
    }
    this.accountsService.createCasess(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.createCasesNewForm.reset();
        this.getCasessByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateCasess() {
    this.spinner.show();
    const requestData = {
      "casesId": this.productCasessNew.casesId,
      "partyId": this.productCasessNew.partyId,
      "priority": this.createCasesNewForm.value.priority,
      "reason": this.createCasesNewForm.value.reason,
      "status": this.createCasesNewForm.value.status,
      "subject": this.createCasesNewForm.value.subject,
      "type": this.createCasesNewForm.value.type
    }
    this.accountsService.updateCasess(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.createCasesNewForm.reset();
        this.getCasessByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateCasessPatch(product) {
    this.spinner.show();
    this.show = true;
    this.productCasessNew = product;
    setTimeout(() => {
      const formValue = this.createCasesNewForm;
      formValue.patchValue({
        priority: product.priority,
        reason: product.reason,
        status: product.status,
        subject: product.subject,
        type: product.type
      })
    }, 2000);
    this.spinner.hide();
  }

  getSalesOpportunity() {
    this.spinner.show();
    this.myContactsService.getSalesOpportunity().subscribe(res => {
      this.SalesOpportunity = res.data[0].getSalesOpportunity;
      this.spinner.hide();
      for (const value of this.SalesOpportunity) {
        this.SalesOpportunityArray.push({
          label: value.opportunityName,
          value: value.salesOpportunityId
        })
      }
    })
  }
  getCases() {
    this.spinner.show();
    this.myContactsService.getCases().subscribe(res => {
      this.Cases = res.data[0].getCasesById;
      this.spinner.hide();
      for (const value of this.Cases) {
        this.CasesArray.push({
          label: value.custRequestName,
          value: value.custRequestId
        })
      }
    })
  }
  UpdateCatalogRequestPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productCatalog = product ;
      setTimeout(() => {
        const formValue = this.createCatalogForm;
        formValue.patchValue({
            activity: product.activity,
            address: product.address,
            date: this.datePipe.transform(product.date,"yyyy-MM-dd"),
            fullFilled: product.fullFilled,
            takenBy: product.takenBy
        })
      }, 2000);
      this.spinner.hide();
  }
  deleteCatalogRequest(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "catalogId": product.catalogId,
          "partnerId": product.partnerId
         
        }
        this.accountsService.deleteCatalogRequest(formData).subscribe(res => {
        this.getCatalogRequestByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getCatalogRequestByPartyId() {
    this.spinner.show();
    this.accountsService.getCatalogRequestByPartyId(this.partyId).subscribe(res => {
      this.allCatalogRequestData = res.data[0].getCatalogRequestById;
      this.spinner.hide();
    })
  }
  UpdateCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": this.productCatalog.catalogId,
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "partnerId": this.productCatalog.partnerId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.UpdateCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": "",
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "partnerId": this.partyId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.createCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  resetLogEmail(){
    this.showLogEmail = false ;
    this.logEmailForm.reset();
  }
  updateLogEmail(product) {
    this.spinner.show();
    this.showLogEmail = true ;
    this.productLogEmail = product ;
   
      setTimeout(() => {
        const formValue = this.logEmailForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,
        })
      }, 2000);
      this.spinner.hide();
  }
  createLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": "",
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partyId
    }

    this.myContactsService.createLogTaskEmailPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": this.productLogEmail.logTaskEmailId ,
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partyId,

    }

    this.myContactsService.UpdateLogTaskEmailPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getLogTaskEmailPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskEmailPartnerId(this.partyId).subscribe(res => {
      this.LogTaskEmail = res.data[0].getLogTaskEmailPartnerId;
      this.spinner.hide();
    })
  }
  deleteLogTaskEmailPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "logTaskEmailId": product.logTaskEmailId,
         
        }
        this.myContactsService.deleteLogTaskEmailPartner(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  deleteLogTaskCallPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "logTaskCasesCallId": product.logTaskCasesCallId,
          "partnerId": this.partyId
         
        }
        this.myContactsService.deleteLogTaskCallPartner(formData).subscribe(res => {
          this.getLogTaskCallPartnerId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getLogTaskCallPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskCallPartnerId(this.partyId).subscribe(res => {
      this.logTaskCallPartnerId = res.data[0].getLogTaskCallPartnerId;
      this.spinner.hide();
    })
  }
  UpdateLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":this.productLogCall.logTaskCasesCallId ,
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partyId,
 
    }

    this.myContactsService.UpdateLogTaskCallPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":"",
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partyId,
 
    }

    this.myContactsService.createLogTaskCallPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateLogCall(product) {
    this.spinner.show();
    this.showLogCall = true ;
    this.productLogCall = product ;
   
      setTimeout(() => {
        const formValue = this.logTaskForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,

         
        })
      }, 2000);
      this.spinner.hide();
  }
  resetLogCall(){
    this.showLogCall = false ;
    this.logTaskForm.reset();
  }
  deleteOpportunity(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "opportunityId": product.opportunityId,
          "partnerId": product.partnerId
        }
        this.myContactsService.deleteOpportunity(formData).subscribe(res => {
          this.getOpportunity();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  resetFrom() {
    this.show = false;
    this.createAccountsForm.reset();
    this.createCasesForm.reset();
    this.createCatalogForm.reset();
    this.createCasesNewForm.reset();
    this.createOrderForm.reset();

  }
  updateOpportunity() {
    this.spinner.show();
    const fromActualdate = this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "opportunityId": this.productOpp.opportunityId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "partnerId": this.productOpp.partnerId,
      "stage": this.createCasesForm.value.stage
    }
    this.myContactsService.updateOpportunity(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOpportunityPatch(product) {
    this.spinner.show();
    this.show = true;
    this.productOpp = product;
    setTimeout(() => {
      const formValue = this.createCasesForm;
      formValue.patchValue({
        opportunityName: product.opportunityName,
        stage: product.stage,
        opportunityAmount: product.opportunityAmount,
        estimatedCloseDate: this.datePipe.transform(product.closeDate, "yyyy-MM-dd"),
      })
    }, 2000);
    this.spinner.hide();
  }
  createOpportunities() {
    this.spinner.show();
    const fromActualdate = this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "opportunityId": this.partyId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "partnerId": "",
      "stage": this.createCasesForm.value.stage
    }
    this.myContactsService.createOpportunities(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getClassificationBookmark(){
    this.spinner.show();
    this.myContactsService.getClassificationBookmark().subscribe(res=>{
      this.ClassificationBookmark = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ClassificationBookmark) {
        this.ClassificationBookmarkArray.push({
          label: value.description,
          value: value.classificationEnumId
        })
      }
    })
  }
  deleteBookmarksUploadPartner(bookmarksUploadId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.deleteBookmarksUploadPartner(bookmarksUploadId).subscribe(res => {
          this.getBookmarksUploadPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    this.myContactsService.updateBookmarksUploadPartner(formData,this.BookUpload.bookmarksUploadId,this.fileUpload.value.classification,
    this.fileUpload.value.description,this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }  
  updateBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.updateBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookmarksUploadPartner() {
    this.spinner.show();
    this.myContactsService.getBookmarksUploadPartner(this.partyId).subscribe(res => {
      this.bookmarksUploadPartner = res.data[0].getBookmarksUploadPartner;
      this.spinner.hide();
    })
  }
  createBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.createBookmarksUploadPartner(formData,this.fileUpload.value.classification,
      this.fileUpload.value.description,this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }   
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  onSubmitFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }

  createBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.createBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookMarksUrlIdPartner() {
    this.spinner.show();
    this.myContactsService.getBookMarksUrlIdPartner(this.partyId).subscribe(res => {
      this.bookMarksUrlPartner = res.data[0].getBookMarksUrlIdPartner;
      this.spinner.hide();
    })
  }
  deleteBookMarksUrlPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "bookmarksUrlId": product.bookmarksUrlId,
          "partnerId": this.partyId
         
        }
        this.myContactsService.deleteBookMarksUrlPartner(formData).subscribe(res => {
          this.getBookMarksUrlIdPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  resetUpload(){
    this.showUpload = false ;
    this.fileUpload.reset();
  }
  resetBookUrl(){
    this.showBookmark = false ;
    this.addBookmark.reset();
  }
  updateBook(product) {
    this.spinner.show();
    this.showBookmark = true ;
    this.productBook = product ;
   
      setTimeout(() => {
        const formValue = this.addBookmark;
        formValue.patchValue({
          classification: product.classification,
          url: product.urlAddress,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdateBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
      "partnerId": this.partyId,
      "bookmarksUrlId":this.productBook.bookmarksUrlId,
      "classification": this.addBookmark.value.classification,
     
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,

    }
    this.myContactsService.UpdateBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
    
      "bookmarksUrlId":this.addBookmark.value.description,
      "classification": this.addBookmark.value.classification,
      "partnerId": this.partyId,
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,
    }
    this.myContactsService.createBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("BookmarksUrl created successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateBookUpload(product) {
    this.spinner.show();
    this.showUpload = true ;
    this.BookUpload = product ;
   
      setTimeout(() => {
        const formValue = this.fileUpload;
        formValue.patchValue({
          classification: product.classification,
          file: product.url,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }

  getTeamMember() {
    this.spinner.show();
    this.myContactsService.getTeamMember().subscribe(res => {
      this.teamMember = res.data;
      this.spinner.hide();
      for (const value of this.teamMember) {
        this.teamMemberArray.push({
          label: value.data.name,
          value: value.data.partyId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getTeamMemberByPartyId() {
    this.spinner.show();
    this.myContactsService.getTeamMemberByPartyId(this.partyId).subscribe(res => {
      this.TeamMemberByPartyId = res.data.multiNode ;
      this.spinner.hide();
    })
  }

  delete(autoId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      
        this.myContactsService.deleteTeamMemberInviewTeamByAutoId(autoId).subscribe(res => {
        this.getTeamMemberByPartyId();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }

  cancelSubmit(){
    this._Router.navigate(["/crm/Teams"])
  }

  updateTeamMemberInviewTeam(){
    this.spinner.show();
    const requestData = {
      "autoId": this.updateTeamMem.autoId,
      "securityGroupId": this.updateTeamMem.securityGroupId,
    }

    this.myContactsService.updateTeamMemberInviewTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addTeamMember.reset();
        this.getTeamMemberByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  reset(){
    this.show = false;
    this.addTeamMember.reset();
  }
  update(product){
    this.show = true;
    this.updateTeamMem = product;
    setTimeout(() => {
      const formValue = this.addTeamMember;
      formValue.patchValue({
        newTeamMember:product.teamMemberPartyId,
        role: product.securityGroupId 
      })

    }, 2000);

  }
  getRole() {
    this.spinner.show();
    this.myContactsService.getRoleTeam().subscribe(res => {
      this.Role = res.data;
      this.spinner.hide();
      for (const value of this.Role) {
        this.RoleArray.push({
          label: value.roleValue,
          value: value.roleValue
        })
      }
    })
  }
  addTeamMemberInviewTeam(){
    this.spinner.show();
    const requestData = {
      "accountTeamPartyId":  this.partyId,
      "autoId": "",
      "partyId":  this.partyId,
      "securityGroupId": this.addTeamMember.value.role ,
      "teamMemberPartyId": this.addTeamMember.value.newTeamMember 

    }
    this.myContactsService.addTeamMemberInviewTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addTeamMember.reset();
       // this.getTeamByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deactivatedTeamByPartyId(){
    this.spinner.show();

    this.myContactsService.deactivatedTeamByPartyId(this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getTeamByPartyId();
        this.Deactivated= true;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getTeamByPartyId() {
    this.spinner.show();
    this.myContactsService.getTeamByPartyId(this.partyId).subscribe(res => {
      this.TeamByPartyId = res.data.singleNode;
      setTimeout(() => {
        const formValue = this.editTeam;
        formValue.patchValue({
          name: this.TeamByPartyId.name ,
          comments:  this.TeamByPartyId.comments 
        })
  
      }, 2000);
      this.spinner.hide();

    })
  }
  updateTeam(){
    this.spinner.show();
    const requestData = {
      "comments": this.editTeam.value.comments,
      "isDeactivated": "",
      "name":  this.editTeam.value.name,
      "partyId": this.partyId
     
    }

    this.myContactsService.updateTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.editTeam.reset();
        this.getTeamByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
