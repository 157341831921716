<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Accounting
                    Transaction</span>
                <span class="color-black pl-1" *ngIf="!show"> >Add Accounting Transaction </span>
                <span class="color-black pl-1" *ngIf="show"> >Update Accounting Transaction</span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                    Add Accounting Transaction</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                    Update Accounting Transaction</a></li>

                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="accountTransaction">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                               
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Acctg Trans Type<span
                                                        style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Acctg Trans Type" filter="true"
                                                    formControlName="AcctgTransType"
                                                    [options]="accTransactionTypeArray" optionlabel="label">

                                                </p-dropdown>
                                               
                                                </div>
                                                <div class="col-lg-3 form-group classInputA" *ngIf="show">
                                                    <label for="exampleInputEmail1">Acctg Trans Id</label>
                                                </div>
                                             
                                                <div class="col-lg-2" *ngIf="show">
                                                    <label>{{this.acctran}}</label>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Fiscal Gl Type<span
                                                        style="color:red">*</span></label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Fiscal Gl Type" filter="true"
                                                    formControlName="FiscalGlType"
                                                    [options]="glFiscalTypeArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Gl Journal ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Gl Journal ID" filter="true"
                                                    formControlName="GlJournalID"
                                                    [options]="glJournalArray" optionlabel="label">

                                                </p-dropdown>
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Group Status</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Group Status" filter="true"
                                                    formControlName="GroupStatus"
                                                    [options]="groupStatusArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Fin Account Trans ID

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="FinAccountTrasID"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Fin Account Trans ID"
                                                        >
                                               
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Party ID </label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Party ID" filter="true"
                                                        formControlName="PartyID"
                                                        [options]="partyIdArray" optionlabel="label">

                                                    </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Role Type ID </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Role Type ID" filter="true"
                                                    formControlName="RoleTypeID"
                                                    [options]="allroleArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Transaction Date

                                                    </label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="date" formControlName="TransactionDate"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Transaction Date">

                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1"> Scheduled Posting Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" formControlName="ScheduledPostingDate"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Scheduled Posting Date">
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Posted Date</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="date" formControlName="PostedDate" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Posted Date">

                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Is Posted</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Is Posted" filter="true"
                                                    formControlName="IsPosted"
                                                    [options]="posted" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Inventory Item ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="email" formControlName="InventoryItemID"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Inventory Item ID">

                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Physical Inventory ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="PhysicalInventoryID"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Physical Inventory ID">
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Invoice ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Invoice ID" filter="true"
                                                    formControlName="InvoiceID"
                                                    [options]="invoiceListArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Payment ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Payment ID" filter="true"
                                                    formControlName="PaymentID"
                                                    [options]="getPaymentsListArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Product ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Product ID" filter="true"
                                                    formControlName="ProductID"
                                                    [options]="ProductsArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Work Effort ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Work Effort ID" filter="true"
                                                    formControlName="WorkEffortID"
                                                    [options]="workEffortArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Shipment ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Shipment ID" filter="true"
                                                    formControlName="ShipmentID"
                                                    [options]="FacilityShipmentsArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Fixed Asset ID" filter="true"
                                                    formControlName="FixedAssetID"
                                                    [options]="fixedAssetArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Receipt ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="email" formControlName="ReceiptID" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Receipt ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Their Acctg Trans ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="TheirAcctgTransID"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Their Acctg Trans ID">

                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Voucher Ref</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="email" formControlName="VoucheRef" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Voucher Ref">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Voucher Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" formControlName="VoucherDate" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Voucher Date">

                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Description</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="email" formControlName="Description" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Description">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Created Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" formControlName="CreatedDate" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Created Date">

                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Last Modified Date</label>
                                                </div>
                                             
                                                <div class="col-lg-2" >
                                                    <input type="date" formControlName="LastModifiedDate"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Last Modified Date">
                                                </div>
                                              
                        
                                               
                        
                                            </div>
                                        </div>
                                     

                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 63%;">
                                    <button type="submit" (click)="onUpdate();" *ngIf="show"
                                        class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" (click)="onSubmit();" *ngIf="!show"
                                        class="btn btn-secondary submit-btn">Submit</button>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>