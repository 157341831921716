import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEmployeeFormService } from '../add-new-employee/add-new-employment-service';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { EmployeeService } from '../employees/employess.service';
import { AddSkillService } from '../add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-hrm-resume',
  templateUrl: './hrm-resume.component.html',
  styleUrls: ['./hrm-resume.component.css']
})
export class HrmResumeComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  activeCategory=2;
  total=0;PartyID: any;
  partyIdArray: any[]=[];
  ContentID: any;
  actualThroughDate: string;
  resumeDate: string;
  employeeList: any;
  resumeIDArray: any[]=[];
;
  rowNumber=0;
  pageSize= 100;
  // employeeList=[{
  //   "party":"ss"
  // }]
  advanceSearch: {

    ResumeID: string;
    PartyID: string;
    ContentID: string;
    ResumeText: string;
    fromdateFrom: string;
    fromDateTo: string;
   
  };
  partyId:any;
  pageNo = 1;
  rows = 50;
  search= ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  show: boolean;
  createResume:FormGroup;
  ContentIDArray:any[]=[];
  actualfromdate: string;

  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly employeeService: EmployeeService,
    readonly _FormBuilder: FormBuilder,
    public addEmployeeFormService: AddEmployeeFormService,
    readonly addSkillService: AddSkillService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    
    ) { 
      this.createResume = this._FormBuilder.group({
        ResumeID: [''],
        ContentID: [''],
        PartyID: [''],
        ResumeDate: [''],
        ResumeText: [''],
       
      
      });
      this.advanceSearch={
        ResumeID: "",
        PartyID:"",
        ContentID:"",
        ResumeText:"",
        fromdateFrom:"",
        fromDateTo:"",
       
      };
    }

  ngOnInit(): void {
    this.show=false;
    this.getFindPartyResume();

    this.getPartyId();
    this.getContentIdList();
    this.getFindPartyResume();
    

  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  reset(){
    this.spinner.show();
    this.advanceSearch.ResumeID="";
    this.advanceSearch.PartyID="";
    this.advanceSearch.ContentID="";
    this.advanceSearch.ResumeText="";
    this.advanceSearch.fromdateFrom="";
    this.advanceSearch.fromDateTo="";
    
   
      this.accountsService.getFindPartyResume().subscribe((res:any)=> {
        this.employeeList = res.data;
        this.spinner.hide();
      
      
      }) 
  }
  pageLoad(){
    this.spinner.show();
    this.advanceSearch.ResumeID="";
    this.advanceSearch.PartyID="";
    this.advanceSearch.ContentID="";
    this.advanceSearch.ResumeText="";
    this.advanceSearch.fromdateFrom="";
    this.advanceSearch.fromDateTo="";
    
   
      this.accountsService.getFindPartyResume().subscribe((res:any)=> {
        this.employeeList = res.data;
        this.spinner.hide();
      
      
      }) 
  }
  getFindResume(){
    this.spinner.show();
    if(this.advanceSearch.ResumeID||this.advanceSearch.PartyID||
    this.advanceSearch.ContentID||this.advanceSearch.ResumeText||this.advanceSearch.fromdateFrom){
      const req = {
    
        "resumeId": this.advanceSearch.ResumeID ? this.advanceSearch.ResumeID : '',
        "partyId": this.advanceSearch.PartyID ? this.advanceSearch.PartyID : '',
        "contentId": this.advanceSearch.ContentID ? this.advanceSearch.ContentID : '',
        "resumeText": this.advanceSearch.ResumeText ? this.advanceSearch.ResumeText : '',
        "resumeDate":this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      }
        this.accountsService.getFindResume(req.resumeId,req.partyId,
          req.contentId,req.resumeText,req.resumeDate).subscribe((res:any)=> {
          this.employeeList = res.data;
          this.spinner.hide();
        
        
        })
    }
    else{
      this.getFindPartyResume();
    }
  
     
     }

  
     getFindPartyResume() {
    this.spinner.show();
    this.accountsService.getFindPartyResume().subscribe(res => {
      this.employeeList = res.data;
      this.spinner.hide();
      for(let resumeID of this.employeeList){
        this.resumeIDArray.push({
          label: resumeID.findPerfReview.resumeId,
          value: resumeID.findPerfReview.resumeId
        })
      
      }
     
    })
  }
 

   

  submitResume(){
    this.spinner.show();
    let fromActualdate = this.createResume.get('ResumeDate').value;
    this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
  
  const formdata={
    "contentId": this.createResume.value.ContentID,
    "partyId":this.createResume.value.PartyID,
    "resumeDate":this.resumeDate,
    "resumeId": this.createResume.value.ResumeID,
    "resumeText": this.createResume.value.ResumeText
  }
  
    this.accountsService.createPartyResume(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.PartyID = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyID) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getContentIdList() {
    this.spinner.show();
    this.accountsService.getContentIdList().subscribe(res => {
      this.ContentID = res.data;
      this.spinner.hide();
      for (const value of this.ContentID) {
        this.ContentIDArray.push({
          label: value.data.contentId,
          value: value.data.contentId
        })
      }
    })
  }

  employementDetail(resumeId,partyId,contentId,resumeDate,resumeText) {
    this.router.navigate(['/hr/detail-resume'],{ queryParams: { resumeId:resumeId, partyId:partyId ,
      contentId: contentId , resumeDate:resumeDate ,resumeText :resumeText
      } })
  }
  employeeProfile(partyId){
    this.router.navigate(['/hr/hrm-main'],{queryParams:{partyId:partyId}})
  }

  deletePartyResume(product){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formdata={
          "contentId": product.findPerfReview.contentId,
          "partyId": product.findPerfReview.partyId,
          "resumeId": product.findPerfReview.resumeId
        }
         //10021
        //10441
        this.accountsService.deletePartyResume(formdata).subscribe((res: any) => {
       
          this.getFindPartyResume();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})  
    }
  

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}


