import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

declare var $: any;
@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.css']
})
export class ImportExportComponent implements OnInit {
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  activeCategory=1;
  searchArray:any[]=[];
  fileData: File;
  Editform: FormGroup;
  ExportForm: FormGroup;

  fromWhere;
  personId: any;
  partyIdArray: any[]=[];
  userPermissions: any;
Hidebtn: boolean=false;
  contactsData: any;
  constructor(
    readonly createService:CreateContactsService,
    readonly router:Router,
    readonly myContactsService: MyContactsService,
    readonly toastr:ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly spinner:NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly accountsService: AccountsService,
    private _location: Location,

    ) { 
      this.ExportForm = this._FormBuilder.group({
        partyFrom: [''],
        
      });
    }

  ngOnInit(): void {
    
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
   this.getPartyId();

    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
    });
    $("#accordion").each(function(){
      var trigger= $(this).find('a')
      var acc_text = $(this).find('.collapse')
          $(trigger).click(function(){      
              $(acc_text).slideToggle();      
          });
      });

      this.getcontacts();
  }

  getcontacts() {
    this.spinner.show();
    this.myContactsService.getcontacts().subscribe(res => {
      this.contactsData = res.data;
      this.spinner.hide();
    }) 
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
backWindow(){
  this._location.back();
}
  toDashboard()
{
this.router.navigate(['/hub/Dashboard']);
}

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];    
  }
 
  getExportPartyId() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
      this.accountsService.getExportPartyId(this.ExportForm.value.partyFrom).subscribe(res => {
        const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
       link.download = 'exportParty.xlsx';
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function () {
          window.URL.revokeObjectURL(data);
         link.remove();
        }, 100);
      })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
  

  }

  // onSubmit(){}
  onSubmit(){
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
      const formData = new FormData();
      formData.append("file",this.fileData);
  
      this.createService.importContact(formData).subscribe((res:any)=>{
        if(res.success) { 
          this.spinner.hide();
          this.toastr.success(res.data.responseMessage);
  
         
  
        }     },(err)=>{
          this.toastr.error(err.message);
          this.spinner.hide();
       })
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
  
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  

  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}




