<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100">

                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 20px!important;">Service Packages</span>

                    </div> 

                <p class="">A Service Package define the list of servcies you offer your Customers.</p>


                <p class="" style="margin-top:1% !important"> You should define multiple Service Package only if you offer different services 
                    to different Customers. When you add a Customer you must pick which Service Packages
                    you offer that Customer. Each Customer can only be offered a single Service Package.
                </p>

                <a class="btn btn-link mt-2" style=" 
                margin-left: 8px !important;" data-target="#addNewServicePackagePopup" data-toggle="modal">Add a New Service Package</a>

               
                        
                                          
     
                         
                         
     
                 
                         <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: -1%;">
                             <div class="col-lg-12 col-12">
                                 <div class="form-group">
                                     <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                         <label style="background-color:rgb(206, 214, 218);color: black !important; border: 1px solid lightcyan !important;
                                         margin: 0px !important ; padding: 3px !important; font-size: 15px ; font-weight: bold;
                                         padding-left: 7px !important;">Avaialable Service Package</label>
                                         <p-table [value]="TeamMemberByPartyId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                             <ng-template pTemplate="header">
                                                 <tr style="background:rgb(206, 214, 218);">
                                                     <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                         <div class="checkbox-align" style="color: black;">
                                                             <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                 <div class="custom-checkbox ui-chkbox ui-widget">
                                                                     <div class="ui-helper-hidden-accessible">
                                                                         <input type="checkbox" name="undefined" value="undefined">
                                                                     </div>
                                                                     <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                         <span class="ui-chkbox-icon ui-clickable"></span>
                                                                     </div>
                                                                 </div>
                                                                 <!---->
                                                             </p-checkbox>
                                                             Package Name
                                                             <p-sortIcon field="code">
                                                             </p-sortIcon>
                                                         </div>
                                                         <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                             role="textbox" aria-disabled="false" aria-readonly="false">
                                                     </th>
                                                     
                                                    
     
                                                     <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                         <div style="color: black;">
                                                             Action
                                                             <p-sortIcon field="name">
                                                             </p-sortIcon>
                                                         </div>
                                                         <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                             role="textbox" aria-disabled="false" aria-readonly="false">
                                                     </th>
                                                 </tr>
                                             </ng-template>
                                             <ng-template pTemplate="body" let-product>
                                                 <tr>
                                                     <td [ngStyle]="{'width':'150px'}">
                                                         <div class="checkbox-align">
                                                             <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                 <div class="custom-checkbox ui-chkbox ui-widget">
                                                                     <div class="ui-helper-hidden-accessible">
                                                                         <input type="checkbox" name="undefined" value="undefined">
                                                                     </div>
                                                                     <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                         <span class="ui-chkbox-icon ui-clickable"></span>
                                                                     </div>
                                                                 </div>
                                                                 <!---->
                                                             </p-checkbox>
                                                             <span  class="account-button"  >
                                                                 {{product.teamMemberPartyId}}
                                                             </span>
     
                                                         </div>
                                                     </td>
                                                     <td [ngStyle]="{'width':'180px'}">{{product.securityGroupId}}
                                                     </td>
                                                     <td [ngStyle]="{'width':'180px'}">
                                                         <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" (click)="update(product)" data-target="#addNewServicePackagePopup">Edit</button>
                                                         <button type="button" class="btn btn-secondary submit-btn ml-2" (click)="delete(product.autoId)">Delete</button>
                                                         <button type="button" class="btn btn-secondary submit-btn ml-2" (click)="delete(product.autoId)" >Set Service Categories & Services</button>
                                                     
                                                        </td>
                                                 </tr>
                                             </ng-template>
                                             <ng-template pTemplate="emptymessage" let-columns>
                                                 <tr>
                                                     <td>
                                                         <span class="text-center">
                                                             <h4 class="ml-2">No Record Found</h4>
                                                         </span>
                                                     </td>
                                                 </tr>
                                             </ng-template>
                                         </p-table>
                                         <p class="paginate_data">
                                             View per page
     
                                         </p>
                                     </div>
                                 </div>
                             </div>
                         </div>
     
                        
                    
                
            </div>
        </div>
        </div>
    </div>

</div>

<div class="modal fade" id="addNewServicePackagePopup" tabindex="-1" role="dialog" aria-labelledby="addNewServicePackagePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Serevice Package</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add New Serevice Package</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addServicePackageForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Package Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                            aria-describedby="" placeholder="Enter Name"
                                                            formControlName="packagename">
                                                        
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: -14%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- <ngx-spinner></ngx-spinner> -->