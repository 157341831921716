import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FinancialService } from 'src/app/services/financial.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-create-global-setting',
  templateUrl: './create-global-setting.component.html',
  styleUrls: ['./create-global-setting.component.css']
})
export class CreateGlobalSettingComponent implements OnInit {
  show: boolean;
  createGlobalSettingForm: FormGroup;
  glAccountClass: any;
  activeCategory = 2;
  glAccountClassArray: any[] = [];
  glAccountType: any;
  glAccountTypeArray: any[] = [];
  glResourceType: any;
  glResourceTypeArray: any[] = [];
  glXbrlClass: any;
  glXbrlClassArray: any[] = [];
  parentGlAccount: any;
  parentGlAccountArray: any[] = [];
  accountCode: any;
  accountName: any;
  description: any;
  productId: any;
  externalId: any;
  glAccountClassId: any;
  glAccountTypeId: any;
  glAccountId: any;
  glXbrlClassId: any;
  parentGlAccountId: any;
  glResourceTypeId: any;
  Products: any;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  ProductsArray: any[]=[];
  constructor(
  
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
  ) {
    this.createGlobalSettingForm = this._FormBuilder.group({
      accountCode: [''],
      accountName: [''],
      description: [''],
      externalId: [''],
      glAccountClassId: [''],
      glAccountId: [''],
      glAccountTypeId: [''],
      glResourceTypeId: [''],
      glXbrlClassId: [''],
      parentGlAccountId: [''],
      productId: ['']
    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   this.getProducts();
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.accountCode = params["accountCode"];
      this.accountName = params["accountName"];
      this.description = params["description"];
      this.productId = params["productId"];
      this.externalId = params["externalId"];
      this.glAccountClassId = params["glAccountClassId"];
      this.glAccountTypeId = params["glAccountTypeId"];
      this.glAccountId = params["glAccountId"];
      this.glXbrlClassId = params["glXbrlClassId"];
      this.parentGlAccountId = params["parentGlAccountId"];
      this.glResourceTypeId = params["glResourceTypeId"];  
    });
    if( this.glAccountId){
      this.show=true;
      setTimeout(() => {
        const formValue = this.createGlobalSettingForm;
        formValue.patchValue({
          accountCode: this.accountCode,accountName: this.accountName,description:  this.description,
          externalId:  this.externalId,glAccountClassId:  this.glAccountClassId,glAccountId:this.glAccountId,
          glAccountTypeId:  this.glAccountTypeId,glResourceTypeId:  this.glResourceTypeId,glXbrlClassId:  this.glXbrlClassId,
          parentGlAccountId:  this.parentGlAccountId, productId:  this.productId
     })
      }, 3000);
    } else{
      this.show=false;
    }
    this.getGlAccountInitialData();
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {
      this.spinner.hide();
      this.glAccountClass = res.data.glAccountClass;
      this.glAccountType = res.data.glAccountType;this.glResourceType = res.data.glResourceType;
      this.glXbrlClass = res.data.glXbrlClass; this.parentGlAccount = res.data.parentGlAccount;
      for (const value of this.glAccountClass) {
        this.glAccountClassArray.push({
          label: value.description,
          value: value.glAccountClassId
        })}
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })  }
      for (const value of this.glResourceType) {
        this.glResourceTypeArray.push({
          label: value.description,
          value: value.glResourceTypeId
        })}
      for (const value of this.glXbrlClass) {
        this.glXbrlClassArray.push({
          label: value.description,
          value: value.glXbrlClassId
        }) }
      for (const value of this.parentGlAccount) {
        this.parentGlAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })}
    })}
  
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productName,
          value: value.productId
        })
      }


    })
   

  }
  cancelSubmit() {
    this._Router.navigate(['/financial/global-gl-setting-list']);
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "accountCode": this.createGlobalSettingForm.value.accountCode,
      "accountName": this.createGlobalSettingForm.value.accountName,
      "description": this.createGlobalSettingForm.value.description,
      "externalId": this.createGlobalSettingForm.value.externalId,
      "glAccountClassId": this.createGlobalSettingForm.value.glAccountClassId,
      "glAccountId": this.createGlobalSettingForm.value.glAccountId,
      "glAccountTypeId": this.createGlobalSettingForm.value.glAccountTypeId,
      "glResourceTypeId": this.createGlobalSettingForm.value.glResourceTypeId,
      "glXbrlClassId": this.createGlobalSettingForm.value.glXbrlClassId,
      "parentGlAccountId": this.createGlobalSettingForm.value.parentGlAccountId,
      "productId": this.createGlobalSettingForm.value.productId,
    }
    this.accountsService.postChartAccountData(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/global-gl-setting-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "accountCode": this.createGlobalSettingForm.value.accountCode,
      "accountName": this.createGlobalSettingForm.value.accountName,
      "description": this.createGlobalSettingForm.value.description,
      "externalId": this.createGlobalSettingForm.value.externalId,
      "glAccountClassId": this.createGlobalSettingForm.value.glAccountClassId,
      "glAccountId": this.glAccountId,
      "glAccountTypeId": this.createGlobalSettingForm.value.glAccountTypeId,
      "glResourceTypeId": this.createGlobalSettingForm.value.glResourceTypeId,
      "glXbrlClassId": this.createGlobalSettingForm.value.glXbrlClassId,
      "parentGlAccountId": this.createGlobalSettingForm.value.parentGlAccountId,
      "productId": this.createGlobalSettingForm.value.productId,  
    }
    this.accountsService.updateChartAccountData(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(['/financial/global-gl-setting-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
