import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReallocationsService } from '../reallocation/reallocations.service';
import { TrainingApprovalService } from '../training/training-approvals/training-approval.service';

@Component({
  selector: 'app-training-summary',
  templateUrl: './training-summary.component.html',
  styleUrls: ['./training-summary.component.css']
})
export class TrainingSummaryComponent implements OnInit {
  interviewId: any;
  trainingApprovalList: any;
  pageNo = 1;
  activeCategory=1;
  total=0;
  rows = 50;
  pageSize= 100;
  personId: any;
  partyIdArray: any = [];
  internalOrganization: any;
  organizationArray: any = [];
  internalArray: any = [];
  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  trainingList: any;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly spinner:NgxSpinnerService,
    readonly router:Router,
    readonly reallocationsService: ReallocationsService,
  ) { }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.interviewId = params["trainingId"];
    });
    this.getTrainingApprovalList();
  }
  cancelSubmit(){
    this.router.navigate(["/hr/training-approvals"])
  }
  getTrainingApprovalList(): void {
    this.spinner.show();
    const req={
      
        "approvalStatus": "",
        "approvalStatusSearchType": "Contains",
        "approverId": "",
        "approverIdSearchType": "Contains",
        "fromDateFrom": "",
        "fromDateFromSearchType": "Contains",
        "fromDateTo": "",
        "fromDateToSearchType": "Contains",
        "partyId": "",
        "reason": "",
        "reasonSearchType": "Contains",
        "thruDateFrom": "",
        "thruDateFromSearchType": "Contains",
        "thruDateTo": "",
        "thruDateToSearchType": "Contains",
        "trainingClassTypeId": "",
        "trainingRequestId": this.interviewId,
        "trainingRequestIdSearchType": "Contains",
        "workEffortId": "",
        "workEffortIdSearchType": "Contains"
      }
    
    this.reallocationsService.getTrainigList(this.applicationSize,req)
    .subscribe((res: any) => {
      this.trainingList = res.data[0];
      this.spinner.hide();
    })
   
    
  }
}
