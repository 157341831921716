import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FinancialService } from 'src/app/services/financial.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-accounting-quick-transaction',
  templateUrl: './create-accounting-quick-transaction.component.html',
  styleUrls: ['./create-accounting-quick-transaction.component.css']
})
export class CreateAccountingQuickTransactionComponent implements OnInit {
  show: boolean;
  activeCategory=2;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  accountTransaction:FormGroup;
  partyId: any;
  glFiscalType: any;
  glJournal: any;
  fixedAsset: any;
  groupStatus: any;
  accTransactionType: any;
  glFiscalTypeArray: any[]=[];
  glJournalArray: any[]=[];
  fixedAssetArray: any[]=[];
  groupStatusArray: any[]=[];
  accTransactionTypeArray: any[]=[];
  Products: any;
  ProductsArray: any[]=[];
  workEffortArray: any[]=[];
  FacilityShipments: any;
  FacilityShipmentsArray: any[]=[];
  PaymentsList: any;
  getPaymentsListArray: any[]=[];
  invoiceList: any;
  invoiceListArray: any[]=[];
  posted: { label: string; value: string; }[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  CreatedDate: any;
  LastModifiedDate: any;
  PostedDate: any;
  ScheduledPostingDate: any;
  VoucherDate: any;
  TransactionDate: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  constructor(
   
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly _ActivatedRoute: ActivatedRoute,) { 
    this.accountTransaction = this._FormBuilder.group({
      AcctgTransType: [''],
      FiscalGlType: [''],
      
      PartyID: [''],
      RoleTypeID: [''],
     
      InvoiceID: [''],
      PaymentID: [''],
      ProductID: [''],
      WorkEffortID: [''],
      ShipmentID: [''],
      FixedAssetID: [''],
      DebitGlAccountID: [''],
      CreditGlAccountID: [''],
      Amount: [''],
      TransactionDate: [''],
      Description: [''],
   
    });
    this.posted=[{
      'label':'Yes',
      'value':'Y'
    },
    {
      'label':'No',
      'value':'N'
    }]
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    });

    this.show=false;
    this.getProducts();
    this.getWorkEffortList();
    this.getInvoices();
    this.getPaymentsList();
    this.getFacilityShipments();
    this.initialTransData();
    this.getRoleAllType();
    this.getPartyId();
    this.getGlAccountInitialData();
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }





    })
   
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    this.spinner.hide();
  }
 
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getWorkEffortList(): void {
    this.spinner.show();
    this.accountsService.getWorkEfforts().subscribe((res) => {
      const workEfforts = res.data;
      this.spinner.hide();
      for (const value of workEfforts) {
        this.workEffortArray.push({
          label: value.workEffortName + " [" + value.workEffortId + "]",
          value: value.workEffortId,
        });
      }
    });
   
  }
  
  getInvoices() {
    this.spinner.show();
    const req = {
      "description": "",
      "descriptionSearchType": "Contains",
      "fromPartyId": "",
      "invoiceDateFrom": "",
      "invoiceDateFromSearchType": "Contains",
      "invoiceDateTo": "",
      "invoiceDateToSearchType": "Contains",
      "invoiceId": "",
      "invoiceIdSearchType": "Contains",
      "invoiceType": "PURCHASE_INVOICE",
      "statusId": "",
      "toPartyId": ""
    }
    this.accountsService.getInvoices(this.finSize, req).subscribe((res: any) => {
      this.invoiceList = res.data;
      this.spinner.hide();
      for (const value of this.invoiceList) {
        this.invoiceListArray.push({
          label: value.invoiceId,
          value: value.invoiceId
        })
      }
    })
  }
  getPaymentsList() {
    this.spinner.show();
    const req = {
      "amount": "",
  "comments": "",
  "commentsSearchType": "Contains",
  "fromPartyId": "",
  "paymentId": "",
  "paymentIdSearchType": "Contains",
  "paymentType": "",
  "status": "",
  "toPartyId": ""
    }
    this.accountsService.getPaymentsList(this.finSize, req).subscribe((res: any) => {
      this.PaymentsList = res.data;
      this.spinner.hide();
      for (const value of this.PaymentsList) {
        this.getPaymentsListArray.push({
          label: value.paymentId,
          value: value.paymentId
        })
      }
    })
  }
  getFacilityShipments() {
    this.spinner.show();
    const req = {
      "destinationFacility": "",
  "entryDateFrom": "",
  "entryDateFromSearchType": "Contains",
  "entryDateTo": "",
  "entryDateToSearchType": "Contains",
  "estimatedShipDateFrom": "",
  "estimatedShipDateFromSearchType": "Contains",
  "estimatedShipDateTo": "",
  "estimatedShipDateToSearchType": "Contains",
  "originFacility": "",
  "shipmentId": "",
  "shipmentIdSearchType": "Contains",
  "shipmentType": "",
  "statusId": [
    ""
  ] }
    this.accountsService.getFacilityShipments(this.finSize, req).subscribe((res: any) => {
      this.FacilityShipments = res.data;
      this.spinner.hide();
      for (const value of this.FacilityShipments) {
        this.FacilityShipmentsArray.push({
          label: value.shipmentId,
          value: value.shipmentId
        })
      }
    })
  }
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {
     this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productName,
          value: value.productId
        })
      }
    })
   

  }
  initialTransData() {
    this.spinner.show();
    this.accountsService.initialTransData().subscribe((res: any) => {
      this.glFiscalType = res.data.glFiscalType;
      this.glJournal = res.data.glJournal;
      this.fixedAsset = res.data.fixedAsset;
      this.groupStatus = res.data.groupStatus;
      this.accTransactionType = res.data.accTransactionType;
      this.spinner.hide();
      for (const value of this.glFiscalType) {
        this.glFiscalTypeArray.push({
          label: value.description,
          value: value.glFiscalTypeId
        }) }
      for (const value of this.glJournal) {
        this.glJournalArray.push({
          label: value.glJournalName,
          value: value.glJournalId
        }) }
      for (const value of this.fixedAsset) {
        this.fixedAssetArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        }) }
      for (const value of this.groupStatus) {
        this.groupStatusArray.push({
          label: value.description,
          value: value.statusId
        }) }
      for (const value of this.accTransactionType) {
        this.accTransactionTypeArray.push({
          label: value.description,
          value: value.acctgTransTypeId
        })  }}) }
  onSubmit() {
    this.spinner.show();
    const TransactionDate=this.accountTransaction.get('TransactionDate').value;
    this.TransactionDate=this.datePipe.transform(TransactionDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "acctgTransTypeId": this.accountTransaction.value.AcctgTransType,
      "description": this.accountTransaction.value.Description,
      "creditGlAccountId":  this.accountTransaction.value.CreditGlAccountID,
      "debitGlAccountId":  this.accountTransaction.value.DebitGlAccountID,
      "amount":this.accountTransaction.value.Amount,
      "glFiscalTypeId": this.accountTransaction.value.FiscalGlType,
      "invoiceId": this.accountTransaction.value.InvoiceID,
      "partyId": this.accountTransaction.value.PartyID,
      "paymentId": this.accountTransaction.value.PaymentID,
      "productId": this.accountTransaction.value.ProductID,
      "roleTypeId":this.accountTransaction.value.RoleTypeID,
      "shipmentId": this.accountTransaction.value.ShipmentID,
      "transactionDate":  this.TransactionDate,
      "workEffortId": this.accountTransaction.value.WorkEffortID,
    }
    this.accountsService.quickCreateAccountTransaction(this.partyId,requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
 
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:  this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
