<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">
                </span>
            </div>
            <div class="w3-card-4 classCard">

                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4 classCardView">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Search Options
                                                </h4>
                                                <span style="margin-right: 10px;">
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateDataSource"> 
                                                    Create New Data Source</button>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateDataSourceType"> 
                                                        Create New Data Source Type</button>
                                            </span>
                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Data Source Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2" style="margin-left: -8%;">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="DataSourceTypeID" aria-describedby="emailHelp" placeholder="Enter Data Source Type ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2" style="margin-left: -8%;">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="margin-left: -66%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                        </div>

                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleCreateDataSource" tabindex="-1" role="dialog" aria-labelledby="exampleCreateDataSourceTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Data Source </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>

            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Add New Data Source</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 " [formGroup]="CreateDataSourceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-left: 3%;">

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId" style="margin-left: 10%;">Data Source ID
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" style="margin-left: -10%;">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="DataSourceID" placeholder="Enter Data Source ID">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Data Source Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" style="margin-left: -10%;">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="DataSourceTypeID" [options]="DataSourceTypeArray" placeholder="Enter Data Source Type ID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1" style="margin-left: 21%;">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" style="margin-left: -10%;">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">


                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -59%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleCreateDataSourceType" tabindex="-1" role="dialog" aria-labelledby="exampleCreateDataSourceTypeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Data Source Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>

            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Add New Data Source Type</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 " [formGroup]="CreateDataSourceTypeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-left: 3%;">

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId">Data Source Type ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" style="margin-left: -10%;">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="DataSourceTypeID" placeholder="Enter Data Source Type ID">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1" style="margin-left: 21%;">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3" style="margin-left: -10%;">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">


                                                    </div>
                                                </div>
                                            </div>





                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -59%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>