import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-resp-detail-summary',
  templateUrl: './resp-detail-summary.component.html',
  styleUrls: ['./resp-detail-summary.component.css']
})
export class RespDetailSummaryComponent implements OnInit {
  activeCategory=1;
 
  tDate: any;
  empId: any;
  resId: any;
  date: any;
  comment: any;
  

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
  ) { }

  ngOnInit(): void {
 
  
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.empId = params["empId"],
      this.resId=params["res"],
      this.date=params["fDate"],
      this.tDate=params["tDate"],
      this.comment=params["comment"],
    
    ])
 
  }
  cancelSubmit(){
    this.router.navigate(["/hr/global-settings"])
  }

}
