<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12" style="padding: 0;">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
                <span class="color-grey fontheader">Image Management </span>
                <span class="d-flex">
     
                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="mb-5" style="border-left-width: 0;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="w3-card-4 classCard w-100">
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                    <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                        <a>Main</a>
                                    </li>
                                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                        <a>Gallery</a>
                                    </li>
                                    <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                        <a>Upload</a>
                                    </li>
                                    <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                        <a>Manage</a>
                                    </li>
                                    <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                        <a>Approve</a>
                                    </li>
                                    <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                        <a>Rejected</a>
                                    </li>
                                    <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)">
                                        <a>Recently Approved</a>
                                    </li>
                                    <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)">
                                        <a>Replace</a>
                                    </li>
                                    <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategory(10)">
                                        <a>Resize</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="create-new-leade mt-2">
                                <div class="">
                                    <div class="">
                                        <form class="w-100">
                                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                <div *ngIf="activeCategory==2" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Image Management Application</p>
                                                        </div>
                                                        <form class="w-100" [formGroup]="imageMgmtApp">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Choose Product ID
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="productID" placeholder="Choose Product ID"
                                                                            [options]="ProductIdArray"  filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-5 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="detailMain(this.imageMgmtApp.value.productID)">Submit</button>
                                                            </div>
                                                        </form>

                                                        <form class="w-100 col-4" *ngFor="let image of ImageUploadListMain">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            {{image.objectInfo}}
                                                                           
                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          </form>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Gallery</p>
                                                        </div>
                                                        <form class="w-100" [formGroup]="galleyProduct">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Choose Product ID
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="productID" placeholder="Choose Product ID"
                                                                            [options]="ProductIdArray" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-5 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn"  (click)="detailMain(this.galleyProduct.value.productID)">Submit</button>
                                                            </div>
                                                        </form>

                                                        <form class="w-100 col-4" *ngFor="let image of ImageUploadListGallery">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            {{image.objectInfo}}
                                                                           
                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          </form>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==4" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Upload</p>
                                                        </div>
                                                        <form class="w-100" [formGroup]="uploadProduct">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Product ID
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="productID" placeholder="Choose Product Id"
                                                                            [options]="ProductIdArray" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">Resize
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="resize" 
                                                                            [options]="resizeArray" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"  style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"    style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"   style="width: 100%;" 
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"   style="width: 100%;" 
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"    style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"    style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"    style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"    style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"    style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="file" formControlName="file"    style="width: 100%;"
                                                                            (change)="fileProgress($event)" class="classFile"
                                                                             id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                              accept="">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-10 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="addToUploadImageCustom()">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Image Upload List
                                                                </h4>
                                                            </div>
                                                        </header>

                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="ImageUploadList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Image
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Image Name
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            From Date
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Delete
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                  
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span  class="account-button"  >
                                                                                              <img src={{product.objectInfo}} alt="">      </span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.dataResourceName}}   
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.createdDate}}   
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.statusId}}   
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                       <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;">Delete</button> 
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>

                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==5" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Manage</p>
                                                        </div>
                                                        <form class="w-100" [formGroup]="manageProduct">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Choose Product ID
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="productID"
                                                                            [options]="ProductIdArray" placeholder="Choose Product ID" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-5 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                              (click)="detailManagePage(this.manageProduct.value.productID)"  >Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==6" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Approve</p>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="imgApproveList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Image
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                           
                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Caption
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Sort Order
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Public
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Image Name
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                    ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Status
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                        style="color: #0d3769;">
                                                                                        <img src={{product.objectInfo}} alt="" style="width:150% ;">  
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                          
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.description}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.sequenceNum}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.isPublic}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.dataResourceName}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.statusId}}</span>
                                                                            </td>
                                                                                </tr>
                                                                            </ng-template>

                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==7" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Rejected</p>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="imgRejectedList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Image
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                           
                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Caption
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Sort Order
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Public
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                        ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Image Name
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                    ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{ 'width': '180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Status
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                ui-corner-all" value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                        style="color: #0d3769;">
                                                                                        <img src={{product.objectInfo}} alt="" style="width:100%;">  
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                          
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.description}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.sequenceNum}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.isPublic}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.dataResourceName}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.statusId}}</span>
                                                                            </td>
                                                                                </tr>
                                                                            </ng-template>

                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==8" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Recently Approved</p>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Product ID
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span  class="account-button"  >
                                                                                                    </span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>

                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==9" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Replace</p>
                                                        </div>
                                                        <form class="w-100" [formGroup]="replaceProduct">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Choose Product ID
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="productID"
                                                                            [options]="ProductIdArray" placeholder="Choose Product ID" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-5 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                 (click)="detailReplacePage(this.replaceProduct.value.productID)">
                                                                 Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==10" class="w-100">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Thumbnail Size Existing in Product: [ID:]</p>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                            Product ID
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span  class="account-button"  >
                                                                                                    </span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>

                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Resize</p>
                                                        </div>
                                                        <form class="w-100" [formGroup]="resizeProduct">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Resize Option
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="resizeOption" placeholder="Enter Resize Option" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Choose Product ID
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="productID" placeholder="Choose Product ID" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Image Width
                                                                                    </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control" formControlName="imageWidth" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Image Width">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-5 main-submit-button" style="margin-left: 41%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>