<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12" style="padding: 0;">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Image Management Application </span>
               <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="" >
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div
                                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Gallery {{productId}}</p>
                                                </div>

                                                <div class="ml-3"> Product Id : {{productId}}</div>
                                                <form class="w-100 col-6" *ngFor="let image of ImageUploadListMain">
                                                 
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-6">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <img src={{image.objectInfo}} alt="">
                                                                   
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn mt-2">Share</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>