import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';

@Component({
  selector: 'app-detail-report-builder',
  templateUrl: './detail-report-builder.component.html',
  styleUrls: ['./detail-report-builder.component.css']
})
export class DetailReportBuilderComponent implements OnInit {
  ReportBuilderRenderData: any;
  ReportBuilderRenderDataheader:any;
  ReportBuilderRenderDatarows:any;
  starSchemaName: any;
  @ViewChild('closebutton4')closebutton4;
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  selectStarSchema: FormGroup;
  dropdownBI: { label: string; value: string; }[];
  ReportBuilderSelectData: any;
  payIdArrayWith: any;
  dato: any;
  datos:any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    readonly _CategoryService: CategoryService,
    readonly router: Router
  ) { }
  ngOnInit(): void {
     this._ActivatedRoute.queryParams.subscribe(params => {
       this.payIdArrayWith = params["payIdArrayWith"];
     });

     
   this.dato =  this.payIdArrayWith;
    console.log(this.dato,"dato");
    this.starSchemaName = localStorage.getItem('starSchemaName');  

    this.ReportBuilderRenderStarSchemaReport();

    this.datos = Object.values(this.dato[0]) ;

    console.log(this.datos,"datos")
  }
  
  ReportBuilderRenderStarSchemaReport() {
    this.spinner.show();
    const form =[
      this.datos
    ]
     this.myContactsService.ReportBuilderRenderStarSchemaReport(this.starSchemaName,form).subscribe(res => {
      this.ReportBuilderRenderData = res.data;
      this.ReportBuilderRenderDataheader = res.data.header;
      this.ReportBuilderRenderDatarows = res.data.rows;
      this.spinner.hide();

    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
