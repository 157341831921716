import { FormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeadPersonalModel {

  constructor(

  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({

      externalId: (data && data.externalId) ? data.externalId : null,
      firstName: (data && data.firstName) ? data.firstName : null,
      lastName: (data && data.lastName) ? data.lastName : null,
      partyId: (data && data.partyId) ? data.partyId : null,
      preferredCurrencyUomId: (data && data.preferredCurrencyUomId) ? data.preferredCurrencyUomId : null,
      statusId: (data && data.statusId) ? data.statusId : null,
      comments: (data && data.comments) ? data.comments : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data: any): any {
    return ({

      externalId: new FormControl(data.externalId),
      firstName: new FormControl(data.firstName),
      lastName: new FormControl(data.lastName),
      partyId: new FormControl(data.partyId),
      preferredCurrencyUomId: new FormControl(data.preferredCurrencyUomId),
      statusId: new FormControl(data.statusId),
      comments: new FormControl(data.comments),
    });
  }

  preparePayload(payload: any): any {
    return payload;
  }
}
