import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-contact',
  templateUrl: './view-contact.component.html',
  styleUrls: ['./view-contact.component.css']
})
export class ViewContactComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonBB')closebuttonBB;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC')closebuttonC;
  @ViewChild('closebutton9')closebutton9;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  yesNOArray: { label: string; value: string; }[];
  CreateNewContactListForm: any;
  ContactListTypeId: any;
  ContactListTypeIdArray: any[]=[];
  ContactMechanismTypeID: any;
  ContactMechanismTypeIDArray: any[]=[];
  MarketingCampaign: any;
  MarketingCampaignArray: any[]=[];
  VerifyEmailWebsiteId: any;
  VerifyEmailWebsiteIdArray: any[]=[];
  editContactListForm: any;
  partySearch: { contactID: string; contactTyepID: string; fromDate: string; thruDate: string; status: string; contactMech: string; };
  commSearch: { contactID: string; commID: string; status: string; fromDate: string; thruDate: string; };
  importContact: { partyID: string; partyType: string; roleType: string; mechID: string; };
  createWebsite: any;
  contactID: any;
  editValue: any;
  WebSiteContactListById: any;
  StartDate: string;
  createWebsiteEdit:FormGroup;
  siteNameEdit: any;
  fromDateEdit: any;
  thruDate: string;
  editWebsiteId: any;
  actualfromdate: string;
  activeOnly: string;
  ContactListParties: any;
  StatusList_parties: any;
  StatusList_partiesArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  CommunicationEventIdDrop: any;
  CommunicationEventIdDropArray: any[]=[];
  CommEventStatusArray: any[]=[];
  CommEventStatus: any;
  ContactList_commEvents: any;
  PreferredContactMechnaismArray: any[]=[];
  PreferredContactMechnaism: any;
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  createParty: FormGroup;
  createComm: FormGroup;
  PartyTypeId: any;
  PartyTypeIdArray: any[]=[];
  FindImportParties: any;
  show: boolean;
  actualfrompartydate: string;
  thrufromdate: string;
  getEstimateFrom: string;
  getEstimateTo: string;
  updateFromDateParty: any;
  advanceFeature: FormGroup;
  finishDateValue: string;
  startDateValue: string;
  ContentMimeTypeId: any;
  ContentMimeTypeIdArray: any[]=[];
  ContactMechanismTypeIDData: any;
  ContactMechanismTypeIDDataArray: any[]=[];
  showComm: boolean;
  datetimeStarted: string;
  datetimeEnded: string;
  CommunicationEventId: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly addSkillService: AddSkillService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { 
    this.yesNOArray = [
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.CreateNewContactListForm = this._FormBuilder.group({
      ContactListName: [''],
      ContactListTypeID: [''],
      IsPublic: [''],
      IsSingleUse: [''],
      ContactMechanismTypeID: [''],
      MarketingCampaignID: [''],
      OwnerPartyID: [''],
      VerifyEmailFrom: [''],
      VerifyEmailScreen: [''],
      VerifyEmailSubject: [''],
      VerifyEmailWebSiteID: [''],
      OptOutScreen: [''],
    });
    this.editContactListForm = this._FormBuilder.group({
      ContactListID: [''],
      ContactListName: [''],
      ContactListTypeID: [''],
      IsPublic: [''],
      IsSingleUse: [''],
      ContactMechanismTypeID: [''],
      MarketingCampaignID: [''],
      OwnerPartyID: [''],
      VerifyEmailFrom: [''],
      VerifyEmailScreen: [''],
      VerifyEmailSubject: [''],
      VerifyEmailWebSiteID: [''],
      OptOutScreen: [''],
    });
    this.createWebsite = this._FormBuilder.group({
      contactID: [''],
      contactName: [''],
      webID: [''],
    });
    this.createComm = this._FormBuilder.group({
      partyFrom: [''],
      status: [''],
      contactMechID: [''],
      contactMechFrom: [''],
      contactListID: [''],
      startDate: [''],
      finishDate: [''],
      subject: [''],
      mimeID: [''],
      fileTransfer: [''],
      note: [''],
    });
    this.createParty = this._FormBuilder.group({
      partyID: [''],
      fromDate: [''],
      thruDate: [''],
      status: [''],
      verifyCode: [''],
      contactMech: [''],
    });
    this.createWebsiteEdit = this._FormBuilder.group({
      thruDate: [''],
    });
    this.partySearch = {
      contactID: "",
      contactTyepID: "",
      fromDate: "",
      thruDate: "",
      status: "",
      contactMech: "",
    };
    this.commSearch = {
      contactID: "",
      commID: "",
      status: "",
      fromDate: "",
      thruDate: "",
    };
    this.importContact = {
      partyID: "",
      partyType: "",
      roleType: "",
      mechID: "",
    };
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.contactID = params["contactID"];
    });
    this.partySearch.contactID=this.contactID;
    this.commSearch.contactID=this.contactID;
    this.getContactListTypeId();
    this.getContactMechanismTypeID();
    this.getMarketingCampaign();
    this.getVerifyEmailWebsiteId();
    this.getContactListById();
    this.getWebSiteContactListById();
    this.findContactListParties();
    this.getStatusList_parties();
    this.getPartyId();
    this.getContentMimeTypeId();
    this.getContactMechanismTypeIDData();
    this.getCommunicationEventIdDrop();
    this.getCommEventStatus();
    this.findContactList_commEvents();
    this.getPreferredContactMechnaism();
    this.getRoleAllType();
    this.getPartyTypeId();
    this.getFindImportParties();
    setTimeout(() => {
      
      const formValue = this.createComm;
      formValue.patchValue({
       
        contactListID: this.contactID
       
      })
    
    }, 2000);
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  referToParty(id){
    this._Router.navigate(['/partyQuickLink/profile-party-quick-link'],{ queryParams:{ party: id } });
  }
  
  expireContactListParty(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "contactListId": this.contactID,
          "preferredContactMechId": product.node2.preferredContactMechId,
          "statusId": product.node2.statusId,
          "fromDate":product.fromDate,
          "partyId":product.node2.partyId,
          "thruDate":product.thruDate,

      
         
        }
        this.accountsService.expireContactListParty(req).subscribe(res => {
          this.findContactListParties();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  updateEventType(product){
      this.siteNameEdit=product.node2[0].siteName;
      this.fromDateEdit=product.fromDate;
      const thruDate=product.node1.thruDate;
      this.editWebsiteId=product.node1.webSiteId;
      this.thruDate=this.datePipe.transform(thruDate,"yyyy-MM-dd");
      setTimeout(() => {
      
        const formValue = this.createWebsiteEdit;
        formValue.patchValue({
         
          thruDate: this.thruDate
         
        })
      
      }, 2000);
  
  }
  
  updateWebSiteContactList(): void {
    const fromActualdate=this.createWebsiteEdit.get('thruDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    this.spinner.show();
    const requestData ={
      "contactListId": this.contactID,
  "deleteButton": "",
  "fromDate":this.fromDateEdit,
  "siteName":  this.siteNameEdit,
  "thruDate": this.actualfromdate,
  "webSiteId": this.editWebsiteId
   
      }
     
    this.accountsService.updateWebSiteContactList(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.createWebsiteEdit.reset();
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.getWebSiteContactListById();
       // this._Router.navigate(["/marketing/contact-list-marketing"])
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  getContactListById(){
    this.spinner.show();
  
    this.accountsService.getContactListById(this.contactID).subscribe((res:any)=> {
      this.editValue=res.data[0].data[0];
      setTimeout(() => {
       
        const formValue = this.editContactListForm;
        formValue.patchValue({
      ContactListID: this.editValue.contactListId,
      ContactListName: this.editValue.contactListName,
      ContactListTypeID: this.editValue.contactListTypeId,
      IsPublic:this.editValue.isPublic,
      IsSingleUse: this.editValue.singleUse,
      ContactMechanismTypeID:this.editValue.contactMechTypeId,
      MarketingCampaignID:this.editValue.marketingCampaignId,
      OwnerPartyID:this.editValue.ownerPartyId,
      VerifyEmailFrom: this.editValue.verifyEmailFrom,
      VerifyEmailScreen: this.editValue.verifyEmailScreen,
      VerifyEmailSubject:this.editValue.verifyEmailSubject,
      VerifyEmailWebSiteID: this.editValue.verifyEmailWebSiteId,
      OptOutScreen:this.editValue.optOutScreen,
        })
      
      }, 3000);
      this.spinner.hide();
    })  
   }
   website(){
    this.createWebsite.reset();
   }
   createWebSiteContactList(): void {
    const StartDate=new Date();
    this.StartDate=this.datePipe.transform(StartDate,"yyyy-MM-dd hh:mm:ss");
    this.spinner.show();
    const requestData ={
      "contactListId": this.contactID,
      "contactListName": this.editValue.contactListName,
      "fromDate": this.StartDate,
      "webSiteId": this.createWebsite.value.webID
   
      }
     
    this.accountsService.createWebSiteContactList(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.createWebsite.reset();
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getWebSiteContactListById();
       // this._Router.navigate(["/marketing/contact-list-marketing"])
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
   creatingContactList(): void {
    this.spinner.show();
    const requestData ={
      "contactListName": this.editContactListForm.value.ContactListName,
      "contactListTypeId":this.editContactListForm.value.ContactListTypeID,
      "contactMechTypeId": this.editContactListForm.value.ContactMechanismTypeID,
      "isPublic": this.editContactListForm.value.IsPublic,
      "marketingCampaignId":this.editContactListForm.value.MarketingCampaignID,
      "optOutScreen":this.editContactListForm.value.OptOutScreen,
      "ownerPartyId": this.editContactListForm.value.OwnerPartyID,
      "singleUse": this.editContactListForm.value.IsSingleUse,
      "verifyEmailFrom": this.editContactListForm.value.VerifyEmailFrom,
      "verifyEmailScreen": this.editContactListForm.value.VerifyEmailScreen,
      "verifyEmailSubject":this.editContactListForm.value.VerifyEmailSubject,
      "verifyEmailWebSiteId": this.editContactListForm.value.VerifyEmailWebSiteID,
      "contactListId": this.contactID,

   
      }
     
    this.accountsService.updateContactListMarket(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.getContactListById();
        this.CreateNewContactListForm.reset();
        this.spinner.hide();
        this.closebutton9.nativeElement.click();

        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  
  getPartyTypeId() {
    this.spinner.show();
    this.accountsService.getPartyTypeId().subscribe(res => {
      this.PartyTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.PartyTypeId) {
        this.PartyTypeIdArray.push({
          label: value.partyTypeId,
          value: value.partyTypeId
        })
      }
    })
  }
  getContactListTypeId() {
    this.spinner.show();
    this.myContactsService.getContactListTypeId().subscribe(res => {
      this.ContactListTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactListTypeId) {
        this.ContactListTypeIdArray.push({
          label: value.description,
          value: value.contactListTypeId
        })
      }
    })
  }
  addEffortEventReminder(){
    this.show=false;
    this.createParty.reset();
  }
  getEditContactListPartyId(product){
    this.createParty.reset();
    this.spinner.show();
   this.show=true;
     this.updateFromDateParty=product.fromDate
      setTimeout(() => {
        const fromdate=product.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=product.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
       
        const formValue = this.createParty;
        formValue.patchValue({
          partyID:product.node2.partyId,
          fromDate: this.getEstimateFrom,
          thruDate: this.getEstimateTo,
          status:product.node2.statusId,
          verifyCode:'',
          contactMech: product.node2.preferredContactMechId,
        })
      
      }, 3000);
      this.spinner.hide();
  
   }
   
   updateContactListParty(): void {
    this.spinner.show();
   
    const thruActualdate=this.createParty.get('thruDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  
  "contactListId": this.contactID,
  "fromDate":  this.updateFromDateParty,
  "optInVerifyCode": this.createParty.value.verifyCode,
  "partyId": this.createParty.value.partyID,
  "preferredContactMechId": this.createParty.value.contactMech,
  "statusId": this.createParty.value.status,
  "thruDate":  this.thrufromdate,
   
      }
     
    this.accountsService.updateContactListParty(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonBB.nativeElement.click();
        this.findContactListParties(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
   
  createContactListParty(): void {
    this.spinner.show();
    const fromActualdate=this.createParty.get('fromDate').value;
    this.actualfrompartydate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.createParty.get('thruDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  
  "contactListId": this.contactID,
  "fromDate":  this.actualfrompartydate,
  "optInVerifyCode": this.createParty.value.verifyCode,
  "partyId": this.createParty.value.partyID,
  "preferredContactMechId": this.createParty.value.contactMech,
  "statusId": this.createParty.value.status,
  "thruDate":  this.thrufromdate,
   
      }
     
    this.accountsService.createContactListParty(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonBB.nativeElement.click();
        this.findContactListParties(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  selectAllDepositWith(e) {
   
    if (e.target.ariaChecked === "true") {
      this.activeOnly='Y'
    }
    else {
      this.activeOnly='N'
    }
  }
  resetImportParties() {
    this.spinner.show();
    this.importContact.mechID = "";
    this.importContact.partyID  = "";
    this.importContact.partyType  = "";
    this.importContact.roleType  = "";
   
    const req = {
      "contactMechTypeID": "",
      "emailAddress": "",
      "partyID": "",
      "partyTypeID": "",
      "roleType": ""
    }

    this.accountsService.getFindImportParties(req).subscribe((res: any) => {
      this.FindImportParties = res.data;
      this.spinner.hide();
    })
  }
  getFindImportParties() {
 
    this.spinner.show();
  
    const req = {
      "contactMechTypeID": this.importContact.mechID ? this.importContact.mechID : '',
      "emailAddress": "",
      "partyID":this.importContact.partyID ? this.importContact.partyID : '',
      "partyTypeID":this.importContact.partyType ? this.importContact.partyType : '',
      "roleType": this.importContact.roleType ? this.importContact.roleType : '',

    }
    this.accountsService.getFindImportParties(req).subscribe((res: any) => {
      this.FindImportParties = res.data;
      this.spinner.hide();
    })
    
    this.spinner.hide();
  }

     
  resetcommEvents() {
    this.spinner.show();
    this.commSearch.commID = "";
    this.commSearch.contactID = "";
    this.commSearch.status  = "";
    
    const req = {
      "communicationEventId": "",
      "contactListId": "",
      "status": ""
    }

    this.accountsService.findContactList_commEvents(req).subscribe((res: any) => {
      this.ContactList_commEvents = res.data[0].node1;
      this.spinner.hide();
    })
  }

  findContactList_commEvents() {
 
    this.spinner.show();
 
    const req = {
      "communicationEventId": this.commSearch.commID ? this.commSearch.commID : '',
      "contactListId": this.commSearch.contactID ? this.commSearch.contactID : '',
      "status": this.commSearch.status ? this.commSearch.status : '',
    }
    this.accountsService.findContactList_commEvents(req).subscribe((res: any) => {
      this.ContactList_commEvents = res.data[0].node1;
      this.spinner.hide();
    })
    this.spinner.hide();
  }

    
  resetContact() {
    this.spinner.show();
    this.partySearch.contactID = "";
    this.partySearch.contactTyepID = "";
    this.partySearch.fromDate = "";
    this.partySearch.status = "";
    this.partySearch.contactMech = "";
    this.partySearch.thruDate = "";
    
    const req = {
      "contactListId": "",
      "hideExpired": "",
      "partyId": "",
      "preferredContactMechanism": "",
      "status": "",
    }

    this.accountsService.findContactListParties(req).subscribe((res: any) => {
      this.ContactListParties = res.data;
      this.spinner.hide();
    })
  }
  findContactListParties() {
 
    this.spinner.show();
  
    const req = {
      "contactListId":  this.partySearch.contactID ? this.partySearch.contactID : '',
      "hideExpired": this.activeOnly,
      "partyId":  this.partySearch.contactTyepID ? this.partySearch.contactTyepID : '',
      "preferredContactMechanism":  this.partySearch.contactMech ? this.partySearch.contactMech : '',
      "status":  this.partySearch.status ? this.partySearch.status : '',

    }
    this.accountsService.findContactListParties(req).subscribe((res: any) => {
      this.ContactListParties = res.data;
      this.spinner.hide();
    })
    
    this.spinner.hide();
  }
  deleteWebSiteContactList(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "contactListId":this.contactID,
          "fromDate": product.fromDate,
          "webSiteId":  product.node1.webSiteId,
         
        }
        this.accountsService.deleteWebSiteContactList(req).subscribe(res => {
          this.getWebSiteContactListById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getWebSiteContactListById() {
    this.spinner.show();
    this.accountsService.getWebSiteContactListById(this.contactID).subscribe(res => {
      this.WebSiteContactListById = res.data;
      this.spinner.hide();
     
    })
  }
  getContactMechanismTypeID() {
    this.spinner.show();
    this.myContactsService.getContactMechanismTypeID().subscribe(res => {
      this.ContactMechanismTypeID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactMechanismTypeID) {
        this.ContactMechanismTypeIDArray.push({
          label: value.description,
          value: value.contactMechTypeId
        })
      }
    })
  }
  getMarketingCampaign() {
    
    this.spinner.show();
    this.myContactsService.getMarketingCampaign().subscribe(res => {
      this.MarketingCampaign = res.data[0].node;
      this.spinner.hide();
      for (const value of this.MarketingCampaign) {
        this.MarketingCampaignArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId,
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  
  getCommunicationEventIdDrop() {
    
    this.spinner.show();
    this.accountsService.getCommunicationEventIdDrop().subscribe(res => {
      this.CommunicationEventIdDrop = res.data[0].node1;
      this.spinner.hide();
      for (const value of this.CommunicationEventIdDrop) {
        this.CommunicationEventIdDropArray.push({
          label: value.communicationEventId,
          value: value.communicationEventId,
        })
      }
    })
  }
  
  getCommEventStatus() {
    
    this.spinner.show();
    this.accountsService.getCommEventStatus().subscribe(res => {
      this.CommEventStatus = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CommEventStatus) {
        this.CommEventStatusArray.push({
          label: value.statusId,
          value: value.statusId,
        })
      }
    })
  }
  
  getPreferredContactMechnaism() {
    
    this.spinner.show();
    this.accountsService.getPreferredContactMechnaism().subscribe(res => {
      this.PreferredContactMechnaism = res.data;
      this.spinner.hide();
      for (const value of this.PreferredContactMechnaism) {
        this.PreferredContactMechnaismArray.push({
          label: value.PreferredContacMechnaism.contactMechId,
          value: value.PreferredContacMechnaism.contactMechId,
        })
      }
    })
  }
  getStatusList_parties() {
    
    this.spinner.show();
    this.accountsService.getStatusList_parties().subscribe(res => {
      this.StatusList_parties = res.data[0].node;
      this.spinner.hide();
      for (const value of this.StatusList_parties) {
        this.StatusList_partiesArray.push({
          label: value.statusId,
          value: value.statusId,
        })
      }
    })
  }
  
  getContactMechanismTypeIDData() {
    this.spinner.show();
    this.accountsService.getContactMechanismTypeIDData().subscribe(res => {
      this.ContactMechanismTypeIDData = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactMechanismTypeIDData) {
        this.ContactMechanismTypeIDDataArray.push({
          label: value.description,
          value: value.contactMechTypeId
        })
      }
    })
  }
  getContentMimeTypeId() {
    this.spinner.show();
    this.accountsService.getContentMimeTypeId().subscribe(res => {
      this.ContentMimeTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContentMimeTypeId) {
        this.ContentMimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  getVerifyEmailWebsiteId() {
    this.spinner.show();
    this.myContactsService.getVerifyEmailWebsiteId().subscribe(res => {
      this.VerifyEmailWebsiteId = res.data;
      this.spinner.hide();
      for (const value of this.VerifyEmailWebsiteId) {
        this.VerifyEmailWebsiteIdArray.push({
          label: value.siteName,
          value: value.webSiteId
        })
      }
    })
  }

 

  createContactListCommEvent(): void {
    this.spinner.show();
    const fromActualdate=this.createComm.get('startDate').value;
    this.startDateValue=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.createComm.get('finishDate').value;
    this.finishDateValue=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  
      "communicationEventTypeId":'EMAIL_COMMUNICATION',
      "contactListId": this.contactID,
      "contactMechIdFrom":  this.createComm.value.contactMechFrom,
      "contactMechTypeId": this.createComm.value.contactMechID,
      "content":  this.createComm.value.fileTransfer,
      "contentMimeTypeId":  this.createComm.value.mimeID,
      "datetimeEnded":  this.finishDateValue,
      "datetimeStarted": this.startDateValue,
      "note":  this.createComm.value.note,
      "partyIdFrom":  this.createComm.value.partyFrom,
      "statusId":  this.createComm.value.status,
      "subject": this.createComm.value.subject
   
      }
     
    this.accountsService.createContactListCommEvent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonC.nativeElement.click();
        this.findContactList_commEvents(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    updateContactListCommEvent(): void {
      this.spinner.show();
      const fromActualdate=this.createComm.get('startDate').value;
      this.startDateValue=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.createComm.get('finishDate').value;
      this.finishDateValue=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "communicationEventId":  this.CommunicationEventId,
        "communicationEventTypeId":'EMAIL_COMMUNICATION',
        "contactListId": this.contactID,
        "contactMechIdFrom":  this.createComm.value.contactMechFrom,
        "contactMechTypeId": this.createComm.value.contactMechID,
        "content":  this.createComm.value.fileTransfer,
        "contentMimeTypeId":  this.createComm.value.mimeID,
        "datetimeEnded":  this.finishDateValue,
        "datetimeStarted": this.startDateValue,
        "note":  this.createComm.value.note,
        "partyIdFrom":  this.createComm.value.partyFrom,
        "statusId":  this.createComm.value.status,
        "subject": this.createComm.value.subject
     
        }
       
      this.accountsService.updateContactListCommEvent(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebuttonC.nativeElement.click();
          this.findContactList_commEvents(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    
    partyReset(){
      this.showComm=false;
      this.createComm.reset();
    }
    updateCommunication(product){
      this.showComm=true;
      setTimeout(() => {
        this.CommunicationEventId=product.communicationEventId
        const fromdate=product.datetimeStarted
        this.datetimeStarted=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=product.datetimeEnded
        this.datetimeEnded=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.createComm;
        formValue.patchValue({
          partyFrom:product.partyIdFrom,
          status:product.statusId,
          contactMechID:product.contactMechTypeId,
          contactMechFrom:product.contactMechIdFrom,
          contactListID:this.contactID,
          startDate:this.datetimeStarted,
          finishDate:this.datetimeEnded,
          subject:product.subject,
          mimeID:product.contentMimeTypeId,
          fileTransfer:product.content,
          note:product.note,
        })
      
      }, 2000);
      
    }

    cancelSubmit(){
      this._Router.navigate(['/marketing/contact-list-marketing'])
}
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
