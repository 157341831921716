<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/financial/payable/commission-invoice"
                    class="cursor-pointer">Commission</span>
                <span class="color-black pl-1"> > {{editMode ?'Update':'Create'}} Commission Invoice</span>
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger buttonclass" 
                                        (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        {{editMode ?'Update':'Create'}} Commission Invoice</a></li>
                
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="commissionInvoiceForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Customer ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="partyIds"
                                                    formControlName="partyIdFrom" optionlabel="label"
                                                    placeholder="Select PartyID">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Currency</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="currencyUomIds"
                                                formControlName="currencyUomId" optionlabel="label"
                                                placeholder="Select Currency">
                                            </p-dropdown>
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Supplier ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="array"
                                                formControlName="partyId" optionlabel="label"
                                                placeholder="Select Party ID">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Invoice Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-calendar placeholder="Select Date" formControlName="invoiceDate">
                                                </p-calendar>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Due Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-calendar formControlName="dueDate" placeholder="Select Date">
                                                </p-calendar>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Ref Num</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" formControlName="referenceNumber"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Ref Num">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Description"
                                                formControlName="description">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Message</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="invoiceMessage" aria-describedby="emailHelp"
                                                placeholder="Message">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                  
                                  
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -30%;">
                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn "
                                        (click)="submit()">{{editMode ? 'Update':'Create'}}</button>
                                    
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 