<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Planing</span>
               <span class="">
 
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span> 
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openInventory()"><a>
                                Inventory</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openFindRequirements()" ><a>
                                Find Requirements</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openRequirements()" ><a>
                                Open Requirements</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openProductRequirements()"><a>
                                Approved Product Requirements</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openInternalRequirements()"><a>
                                Approved Internal Requirements</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="openTransferRequirements()"><a>
                                Approved Transfer Requirements</a></li>
                                        
                            <!-- <li [ngClass]="activeCategory==7?'active':''" (click)="openSalesOrderHistory()"><a>
                                Upload Sales Order History</a></li> -->
                                        
                            <li [ngClass]="activeCategory==8?'active':''" (click)="openRunMRP()" ><a>
                                Run MRP</a></li>

                            <li [ngClass]="activeCategory==9?'active':''" (click)="openViewMRP()" ><a>
                                View MRP</a></li>

                            <li [ngClass]="activeCategory==10?'active':''" (click)="openConfigureWarehouses()" ><a>
                                Configure Warehouses</a></li>
                                
                        </ul>
                    </div>
                    <div class="">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==2">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords"> Find Requirements
                                                            </span>
                                                            <button type="button" class="btn btn-outline-secondary active" 
                                                            data-target="#createPopup" data-toggle="modal" style="margin-left: 1% !important;">Create Find Requirement</button>
                                            
                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 63%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <form [formGroup]="findRequirementsForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Requirement Id
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email"  formControlName="requirementId" class="form-control" id="exampleInputEmail1" 
                                                                                    aria-describedby="emailHelp" placeholder="Enter Requirement Id">
                                                                    
                                                                            </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Requirement Type Id
                                                                                </label>
                                                                            </div>
                                    
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="requirementTypeId"  [options]="requirementTypeIdArray"
                                                                                placeholder="Choose Requirement Type Id" >
                                    
                                                                                </p-dropdown>
                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Facility Id
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="facilityId"  [options]="facilityArray"
                                                                                placeholder="Choose Facility Id" >
                                    
                                                                                </p-dropdown>
                                                                            </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Product Id                                                            </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="productId"  [options]="ProductIdArray"
                                                                                placeholder="Choose Product Id">
                                    
                                                                                </p-dropdown>
                
                                                                            </div>
                                    
                                                                        </div>
                                                                    </div>
                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Supplier</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="supplier"  [options]="suppliers"
                                                                                placeholder="Choose Supplier">
                                    
                                                                                </p-dropdown>
                                                                                
                                                                            </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Status</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                formControlName="status" [options]="requirementStatusIdArray"
                                                                                placeholder="Choose Status">
                                    
                                                                                </p-dropdown>
                                                                            </div>
                                    
                                                                        
                                                                        </div>
                                                                    </div>
                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Description</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                                                    aria-describedby="emailHelp" placeholder="Enter Description">
                                                                    
                                                                            </div>
                                    
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">From Date
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date"  formControlName="fromDate" class="form-control" id="exampleInputEmail1" 
                                                                                aria-describedby="emailHelp" >
                                                                
                                                                            </div>
                                    
                                                                        
                                                                        </div>
                                                                    </div>
                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Thru Date
                
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date"  formControlName="thruDate" class="form-control" id="exampleInputEmail1" 
                                                                                aria-describedby="emailHelp" >
                                                                
                                                                            </div>
                                    
                                                                        </div>
                                                                    </div>
                                    
                                                                </div>
                                                            </form>
    
                                                            <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="findRequirement()">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;
                                                            </div>
    
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>


                                        <!-- <div class="w3-card-4 classCardView">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                Find Requirements
                                                <button type="button" class="btn btn-outline-secondary active" 
                                                data-target="#createPopup" data-toggle="modal" style="margin-left: 70% !important;">Create Find Requirement</button>
                                            
                                            </div>
                                            <form [formGroup]="findRequirementsForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Requirement Id
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="requirementId" class="form-control" id="exampleInputEmail1" 
                                                                    aria-describedby="emailHelp" placeholder="Enter Requirement Id">
                                                    
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Requirement Type Id
                                                                </label>
                                                            </div>
                    
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="requirementTypeId"  [options]="requirementTypeIdArray"
                                                                placeholder="Choose Requirement Type Id" >
                    
                                                                </p-dropdown>

                                                            </div>
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Facility Id
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="facilityId"  [options]="facilityArray"
                                                                placeholder="Choose Facility Id" >
                    
                                                                </p-dropdown>
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product Id                                                            </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="productId"  [options]="ProductIdArray"
                                                                placeholder="Choose Product Id">
                    
                                                                </p-dropdown>

                                                            </div>
                    
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Supplier</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="supplier"  [options]="suppliers"
                                                                placeholder="Choose Supplier">
                    
                                                                </p-dropdown>
                                                                
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Status</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="status" [options]="requirementStatusIdArray"
                                                                placeholder="Choose Status">
                    
                                                                </p-dropdown>
                                                            </div>
                    
                                                        
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Description</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                                    aria-describedby="emailHelp" placeholder="Enter Description">
                                                    
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">From Date
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date"  formControlName="fromDate" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" >
                                                
                                                            </div>
                    
                                                        
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Thru Date

                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date"  formControlName="thruDate" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" >
                                                
                                                            </div>
                    
                                                        </div>
                                                    </div>
                    
                                                </div>
                                            </form>
                                            <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="findRequirement()">Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;
                                            </div>
                                        </div> -->
                                        
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs" >
                                                <h4 class="common-styling h4Margin">
                                                    Requirements Lists

                                                </h4>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                
                                                        <div class="card own-account-table">
                                                            <p-table [value]="findRequirementList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                
                                                                                Requirement ID
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Requirement Type
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Facility
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Product
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Required Date
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Status
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Supplier
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Quantity
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span  class="account-button"   (click)="detailPage(product.data.requirementId)"  >
                                                                                {{product.data.requirementId}}
                                                                                </span>
                
                                                                            </div>
                                                                        </td>
                
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.data.requirementTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.data.facilityId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.data.productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.data.requiredByDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.data.statusId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.data.groupName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.data.quantity}}
                                                                        </td>
                    

                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <button type="button" class="btn btn-secondary submit-btn" (click)="deleteRequirementPlanning(product)" >Delete</button>&nbsp;
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createPopup" tabindex="-1" role="dialog" aria-labelledby="createPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Find Requirement

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Find Requirement
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createRequirementForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Requirement ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="requirementId" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Requirement ID">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Requirement Type Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="requirementTypeId" [options]="requirementTypeIdArray"
                                                        placeholder="Choose Requirement Type Id">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Facility Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="facilityId"  [options]="facilityArray"
                                                        placeholder="Choose Facility Id">

                                                        </p-dropdown>
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Group Name</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="groupName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Group Name">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Deliverable Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="deliverableId" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Deliverable Id">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Fixed Asset Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="fixedAssetId"  [options]="fixedAssetIdArray"
                                                        placeholder="Choose Fixed Asset Id">

                                                        </p-dropdown>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Product Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="productId"  [options]="ProductIdArray"
                                                        placeholder="Choose Product Id">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Status Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="statusId"  [options]="requirementStatusIdArray"
                                                        placeholder="Choose Status Id">

                                                        </p-dropdown>
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                                      
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Requirement Start Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="requirementStartDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Required By Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="requiredByDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                      
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Estimated Budget</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="estimatedBudget" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Estimated Budget">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Quantity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="quantity" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity">
                                                      
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Use Case</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="useCase" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Use Case">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Reason</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="reason" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Reason">
                                                      
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Order Item Type Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="orderItemTypeId"  [options]="orderItemTypeIdArray"
                                                        placeholder="Enter Order Item Type Id">

                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Facility Id To</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="facilityIdTo"  [options]="facilityArray"
                                                        placeholder="Choose Facility Id To">

                                                        </p-dropdown>
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Cust Request Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="custRequestId"  [options]="CustRequestIdsArray"
                                                        placeholder="Choose Cust Request Id">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Cust Request Item Seq Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="custRequestItemSeqId" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Cust Request Item Seq Id">
                                                      
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="submit()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>