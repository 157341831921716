
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AddEmployeeModel } from './add-new-employment-model'

@Injectable({
    providedIn: 'root'
  })


export class AddEmployeeFormService {
    add_employee_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public addEmployeeModel: AddEmployeeModel
    ) { } 

  addEmployeeForm(data:any): void {
    if (!this.add_employee_form) {
        this.add_employee_form = this._formBuilder.group(this.addEmployeeModel.mapDataToModel(data));
       
    }

  }

}

 
