<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Employee Position </span>
                <span *ngIf="!show"  class="color-black pl-1"> >Create New Employee Position</span>
                <span *ngIf="show"  class="color-black pl-1"> >Update New Employee Position</span>
                  
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''" ><a>
                        Create New Employee Position </a></li>
                        <li *ngIf="show"  [ngClass]="activeCategory==2?'active':''" ><a>
                            Update New Employee Position </a></li>
                     
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="createEmployeePositionService.addEmployee_position_form" [formGroup]="createEmployeePositionService.addEmployee_position_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Employee Position ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="empPositionId" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Employee Position ID">
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Status <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Status" formControlName="statusId" filter="true" [options]="statusArray" optionlabel="label">
                                                            
                                                </p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            
                                          
                                           
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Internal Organization
                                                    <span
                                                        style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Internal Organization" formControlName="internalOrganization" filter="true" [options]="internalArray" optionlabel="label">
                                                   
                                                </p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.touched">   Organization is required!</small>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Budget ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="budgetId"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                            </div>
                                           
                                        </div>
                                    </div>
                                   

                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Budget Item Seq ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="budgetItemSeqId" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Budget Item Seq ID">
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Employee Position Type ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter EmployeePosition Type ID" formControlName="empPositionTypeId" [options]="positionArray" filter="true" optionlabel="label">
                                                   
                                                </p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Estimated From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Estimated From Date"> 
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Estimated Through Date
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Estimated Through Date"> 
                              
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Salary Flag <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="solicitation" placeholder="Enter Salary Flag" formControlName="salaryFlag" filter="true" optionlabel="label">
                                                   
                                                </p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.touched">   Salary Flag  is required!</small>
                                               
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Exempt Flag <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="solicitation" placeholder="Enter Exempt Flag" formControlName="exemptFlag" filter="true" optionlabel="label">
                                                   
                                                </p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.touched">   Exempt Flag  is required!</small>

                                              
                                            </div>
                                          
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Fulltime Flag <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="solicitation" placeholder="Enter Fulltime Flag" formControlName="fulltimeFlag" filter="true" optionlabel="label">
                                                   
                                                </p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.touched">   Fulltime Flag  is required!</small>


                                               
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Temporary Flag <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="solicitation" placeholder="Enter Temporary Flag" formControlName="temporaryFlag" filter="true" optionlabel="label">
                                                   
                                                </p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.touched">   Temporary Flag  is required!</small>


                                            </div>
                                          
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Actual From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="actualFromDate" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Actual From Date"> 
                                              
                                         
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Actual Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="actualThruDate" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Actual From Date"> 
                                              
                                         
                                            </div>
                                          
                                        </div>
                                    </div>
                                   
                                   
                                
                                </div>
                                
                                <div class="main-submit-button" style="margin-right: 25%;">
                                    <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                  
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>
            
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
