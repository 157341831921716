<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div>
          <div class="container-fluid">
            <div class="row">
                <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                  <div class="w-100">  
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div class="panel-heading" role="tab" id="headingOne">
                          <h4 class="panel-title port-header d-flex justify-content-between" style="background:#0d3769">
                            <a class=" w-100  tabs-header" style="display: flex !important; justify-content: space-between;">
                              Create  Sales Order
                              <div class="icon-wrapper sales-promos custom-properties">
                                <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse" href="#seo-collapse">
                                </span>
                                <span routerLinkActive="active" class="continue-btn" routerLink="/order/create-sales-continue">Order Item</span>
                            </div>
                            </a>
                          </h4>
                        </div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections"> <div class="panel-body">
                            <div class="mt-2">
                              <div class="">
                                <div class="row">
                                  <form class="w-100">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button"   routerLink="/order/sales-product">Details</a> 
                                        <p>Get $500 off any item in the Small Gizmos [101] category, limit 1 per order, 2 per customer, 3 for entire promotion. Discount not to exceed the price of the item.</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Free Shipping in Utah</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Details 10% off entire purchase
                                        </p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Get $500 off any item in the Small Gizmos [101] category, limit 1 per order, 2 per customer, 3 for entire promotion. Discount not to exceed the price of the item.</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Free Shipping in Utah</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Details 10% off entire purchase
                                        </p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Get $500 off any item in the Small Gizmos [101] category, limit 1 per order, 2 per customer, 3 for entire promotion. Discount not to exceed the price of the item.</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Free Shipping in Utah</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Details 10% off entire purchase
                                        </p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Get $500 off any item in the Small Gizmos [101] category, limit 1 per order, 2 per customer, 3 for entire promotion. Discount not to exceed the price of the item.</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Free Shipping in Utah</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Details 10% off entire purchase
                                        </p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Get $500 off any item in the Small Gizmos [101] category, limit 1 per order, 2 per customer, 3 for entire promotion. Discount not to exceed the price of the item.</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Free Shipping in Utah</p>
                                      </div>
                                      <div class="col-lg-12 col-12 details-offer">
                                        <a  class="account-button" >Details</a> 
                                        <p>Details 10% off entire purchase
                                        </p>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
          </div>

            </div>
          </div>
        </div>
      </div>
    </div>
