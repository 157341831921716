<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Un-posted Accounting Transactions</span>
             
            </div>
            <div  class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium submargin">
                <div class="submenu" >
                 <div >
                    <button _ngcontent-cta-c128="" 
                    type="submit" class="btn btn-secondary submit-btn buttonClass"
                    routerLinkActive="active" (click)="setup()" > Setup</button>&nbsp;
                     <button _ngcontent-cta-c128="" 
                     type="submit" class="btn btn-secondary submit-btn buttonClass"
                     routerLinkActive="active" (click)="accounting()"> Accounting</button>&nbsp;
                    
                     </div>
                          
                </div>
            </div>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                               
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                              <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Un-posted Accounting Transactions
                                                </h4>
                                             
                                              </div>
                                            </header>
                                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                          
                                            <div class="card own-account-table">
                                                <p-table [value]="unPostedData" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>

                                                                    
Acctg Trans ID<p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                             <div style="color: white;">  Transaction Date <p-sortIcon field="name"></p-sortIcon>
                                                             </div>
                                                            </th>
                                                          
                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="quantity"> <div style="color: white;">  Acctg Trans Type <p-sortIcon
                                                                    field="quantity"></p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Fiscal Gl Type <p-sortIcon field="price"></p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;"> 	Invoice ID<p-sortIcon field="price"></p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  	Payment ID<p-sortIcon field="price"></p-sortIcon>
                                                               
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Party ID<p-sortIcon field="price"></p-sortIcon>
                                                               
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Work Effort ID<p-sortIcon field="price">
                                                                </p-sortIcon>
                                                               
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">Shipment ID<p-sortIcon field="price"></p-sortIcon>
                                                               
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;">  Action<p-sortIcon field="price">
                                                                </p-sortIcon>
                                                               
                                                                </div>
                                                            </th>
                                                          
                                                         
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'180px'}"
                                                               >
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    <span (click)="referToSummary(product.acctgTransId)">
                                                                        {{product.acctgTransId}}
                                                                    </span>
                                                                   
                                                                   
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.transactionDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                               
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.acctgTransTypeId}}
                                                              </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.glFiscalTypeId}}
                                                               
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                               <span style="color: #0d3769 !important; cursor: pointer;">
                                                                {{product.invoiceId}}
                                                               </span>
                                                               
                                                              
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}"> 
                                                                <span  style="color: #0d3769 !important; cursor: pointer;" (click)="referToPayment(product.paymentId)">
                                                                    {{product.paymentId}}

                                                                </span>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}"> 
                                                                {{product.partyId}}

                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.workEffortId}}
                                                              

                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.shipmentId}}
                                                              

                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}"> 
                                                             <span style="color: #0d3769 !important; cursor: pointer;"><a style="cursor:pointer" (click)="updateEditTransactin(product.acctgTransId)">Verify Transaction</a>/ <a style="cursor:pointer" (click)="updateEditTransactin(product.acctgTransId)">Post Transaction</a></span>
                                                            </td>
                                                            
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 