import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-data-source-marketing',
  templateUrl: './data-source-marketing.component.html',
  styleUrls: ['./data-source-marketing.component.css']
})
export class DataSourceMarketingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateDataSourceForm: FormGroup;
  CreateDataSourceTypeForm: FormGroup;
  DataSourceTypeId: any;
  DataSourceTypeIdArray: any[]=[];
  DataSourceValue: any;
  show: boolean;
  DataSourceTypeValue: any;
  showValue: boolean;
  activeCategoriess=1;
  

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { 
    this.CreateDataSourceForm = this._FormBuilder.group({
      DataSourceID: [''],
      DataSourceTypeIDValue: [''],
      Description: [''],
      
    });
    this.CreateDataSourceTypeForm = this._FormBuilder.group({
      DataSourceTypeID: [''],
      Description: [''],
      
    });
  }
  

  ngOnInit(): void {
    this.getDataSourceTypeId();
    this.getDataSource();
    this.getDataSourceTypeValue();
  } 
  MobileMessaging()
  {
    this._Router.navigate(['/#']);

  } 
  SocialMedia()
  {
    this._Router.navigate(['/#']);

  }
  toCases()
  {
    this._Router.navigate(['/marketing/cases-marketing']);

  }
  toAccount()
  {
    this._Router.navigate(['/crm/new-accounts']);
  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  toEmail(){
    this._Router.navigate(['/communication-overview/Dashboard']);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  }  
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }

backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/crm/main-overview']);
}
  DataSource() {
    this._Router.navigate(["/marketing/data-source-marketing"])
  }
  DataSourceType() {
    this._Router.navigate(["/marketing/data-source-marketing"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  
  deleteDataSource(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "dataSourceId":product.dataSourceId,
         
        }
        this.accountsService.deleteDataSource(req).subscribe(res => {
          this.getDataSource();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteDataSourceType(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "dataSourceTypeId":product.dataSourceTypeId,
         
        }
        this.accountsService.deleteDataSourceType(req).subscribe(res => {
          this.getDataSourceTypeValue();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateEvent(product){
    this.show=true;
  
      setTimeout(() => {
      
        const formValue = this.CreateDataSourceForm;
        formValue.patchValue({
          DataSourceID: product.dataSourceId,
          DataSourceTypeIDValue:product.dataSourceTypeId,
          Description: product.description,
        })
      
      }, 2000);
  
  }
  updateEventType(product){
    this.showValue=true;

      setTimeout(() => {
      
        const formValue = this.CreateDataSourceTypeForm;
        formValue.patchValue({
         
          DataSourceTypeID:product.dataSourceTypeId,
          Description: product.description,
        })
      
      }, 2000);
  
  }
  
  updateDataSourceType(): void {
    this.spinner.show();

    const requestData ={
  
  "dataSourceTypeId": this.CreateDataSourceTypeForm.value.DataSourceTypeID,
  "description": this.CreateDataSourceTypeForm.value.Description,
   
      }
     
    this.accountsService.updateDataSourceType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getDataSourceTypeValue(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createDataSourceType(): void {
    this.spinner.show();

    const requestData ={
  
  "dataSourceTypeId": this.CreateDataSourceTypeForm.value.DataSourceTypeID,
  "description": this.CreateDataSourceTypeForm.value.Description,
   
      }
     
    this.accountsService.createDataSourceType(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getDataSourceTypeValue(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    updateDataSource(): void {
      this.spinner.show();
  
      const requestData ={
        "dataSourceId": this.CreateDataSourceForm.value.DataSourceID,
    "dataSourceTypeId": this.CreateDataSourceForm.value.DataSourceTypeIDValue,
    "description": this.CreateDataSourceForm.value.Description,
     
        }
       
      this.accountsService.updateDataSource(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getDataSource(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
  createDataSource(): void {
    this.spinner.show();

    const requestData ={
      "dataSourceId": this.CreateDataSourceForm.value.DataSourceID,
  "dataSourceTypeId": this.CreateDataSourceForm.value.DataSourceTypeIDValue,
  "description": this.CreateDataSourceForm.value.Description,
   
      }
     
    this.accountsService.createDataSource(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getDataSource(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  getDataSource() {
    this.spinner.show();
    this.accountsService.getDataSource().subscribe(res => {
      this.DataSourceValue = res.data;
      this.spinner.hide();
  
    })
  }
  getDataSourceTypeValue() {
    this.spinner.show();
    this.accountsService.getDataSourceTypeValue().subscribe(res => {
      this.DataSourceTypeValue = res.data[0].data;
      this.spinner.hide();
  
    })
  }
  
  getDataSourceTypeId() {
    this.spinner.show();
    this.myContactsService.getDataSourceTypeId().subscribe(res => {
      this.DataSourceTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.DataSourceTypeId) {
        this.DataSourceTypeIdArray.push({
          label: value.description,
          value: value.dataSourceTypeId
        })
      }
    })
  }
  createcontent(){
    this._Router.navigate(['/marketing/create-content']);
    
  }
  openDataResource()
  {
    this._Router.navigate(['/marketing/data-source-marketing']);
  }
  openMarketingCampaign()
  {
    this._Router.navigate(['/marketing/marketing-campaign']);
  }
  openTracking()
  {
    this._Router.navigate(['/marketing/tracking-marketing/tracking-marketing']);
  }

  openSegment()
  {
    this._Router.navigate(['/marketing/segment-marketing']);
  }

  openContactList()
  {
    this._Router.navigate(['/marketing/contact-list-marketing']);
  
  }
  openReports()
  {
    this._Router.navigate(['/marketing/reports-marketing']);
  }

  openCustomer()
  {
    this._Router.navigate(['/crm/accounts']);

  }
  openActivities()
  {
    this._Router.navigate(['/crm/events']);

  }

  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }



  addEffortEventReminder(){
    this.show=false;
    this.CreateDataSourceForm.reset();
  }
  addReminder(){
    this.showValue=false;
    this.CreateDataSourceTypeForm.reset();
  }
  dataSourceDetails(id) {
    this._Router.navigate(["marketing/view-data-source"], { queryParams: { dataSourceID: id } })
  }
  dataSourceTypeDetails(id) {
    this._Router.navigate(["marketing/view-data-source-type"], { queryParams: { dataSourceTypeID: id } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
   
  }
}
