import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from './product.service';  import { Location } from '@angular/common';
import * as moment from 'moment';
import { CategoryService } from '../new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-new-products',
  templateUrl: './new-products.component.html',
  styleUrls: ['./new-products.component.css']
})
export class NewProductsComponent implements OnInit {
  activeCategory = 2;
  productMenu: {
    productType: any[],
    virtualVariantMethod: any[],
    requirementMethodEnumID: any[],
    inventoryItemTypeID: any[],
    ratingTypeEnum: any[],
    amountUomTypeID: any[],
    measuresUOMID: any[],
    weightUomID: any[],
    quantityUomID: any[],
    defaultShipmentBoxTypeID: any[],
  };
  createEditProductForm: FormGroup;
  interrogativeArray: { label: string, value: string }[];
  commandingArray: { label: string, value: string }[];
  ratingArray: { label: string, value: string }[];
  editMode: boolean;
  product: any;
  categories: any[];
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ProductService: ProductService,
    readonly _ToastrService: ToastrService,    private _location: Location,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _CategoryService: CategoryService
  ) {
    this.createEditProductForm = this._FormBuilder.group({
      amountUomTypeId: [null], autoCreateKeywords: ['Y'], brandName: [''], chargeShipping: ['N'], comments: [''], defaultShipmentBoxTypeId: [null],
      depthUomId: [null], diameterUomId: [null], heightUomId: [null], inShippingBox: ['N'], includeInPromotions: ['Y'], internalName: ['', [Validators.required]],
      introductionDate: [new Date()], inventoryItemTypeId: [null], inventoryMessage: [''], isVariant: ['N'], isVirtual: ['N'], lotIdFilledIn: [null],
      orderDecimalQuantity: ['N'], piecesIncluded: [0], primaryProductCategoryId: [''], productDepth: [0], productDiameter: [0], productHeight: [0],
      productId: [''], productName: [''], productRating: [0], productTypeId: ['', [Validators.required]], productWeight: [0],
      productWidth: [0], quantityIncluded: [0], quantityUomId: [null], ratingTypeEnum: [null], releaseDate: [new Date()], requireAmount: ['Y'],
      requireInventory: ['Y'], requirementMethodEnumId: [null], returnable: ['Y'], salesDiscWhenNotAvail: ['Y'], salesDiscontinuationDate: [new Date()], shippingDepth: [0],
      shippingHeight: [0], shippingWeight: [0], shippingWidth: [0], supportDiscontinuationDate: [new Date()], taxable: ['Y'], virtualVariantMethodEnum: [null],
      weightUomId: [null], widthUomId: [null]
    });
    this.constructorExt();
  }
  constructorExt(): void {
    this.categories = [];
    this.interrogativeArray = [
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      }
    ];
    this.commandingArray = [
      {
        label: 'Allowed',
        value: 'Allowed'
      },
      {
        label: 'Mandatory',
        value: 'Mandatory'
      },
      {
        label: 'Forbidden',
        value: 'Forbidden'
      }
    ];
    this.editMode = false;
    this.ext();
  }
  ext(): void {
    this.productMenu = {
      productType: [],
      virtualVariantMethod: [],
      requirementMethodEnumID: [],
      inventoryItemTypeID: [],
      ratingTypeEnum: [],
      amountUomTypeID: [],
      measuresUOMID: [],
      weightUomID: [],
      quantityUomID: [],
      defaultShipmentBoxTypeID: [],
    };
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.data.subscribe(data => {
      this.product = data.product[1];
      this.productMenu = {
        productType: data.product[0].productType.map(value => { return { label: value.description, value: value.productTypeId }; }),
        virtualVariantMethod: data.product[0].virtualVariantMethod.map(value => { return { label: value.description, value: value.enumId } }),
        requirementMethodEnumID: data.product[0].requirementMethodEnumID.map(value => { return { label: value.description, value: value.enumId } }),
        inventoryItemTypeID: data.product[0].inventoryItemTypeID.map(value => { return { label: value.description, value: value.inventoryItemTypeId } }),
        ratingTypeEnum: data.product[0].ratingTypeEnum.map(value => { return { label: value.description, value: value.enumId } }),
        amountUomTypeID: data.product[0].amountUomTypeID.map(value => { return { label: value.description, value: value.uomTypeId } }),
        measuresUOMID: data.product[0].measuresUOMID.map(value => { return { label: value.description, value: value.uomId } }),
        weightUomID: data.product[0].weightUomID.map(value => { return { label: value.description, value: value.uomId } }),
        quantityUomID: data.product[0].quantityUomID.map(value => { return { label: value.description, value: value.uomId } }),
        defaultShipmentBoxTypeID: data.product[0].defaultShipmentBoxTypeID.map(value => { return { label: value.description, value: value.shipmentBoxTypeId } })
      };
    });
    if (this.product) {
      this.editMode = true;
      this.createEditProductForm.controls.productId.disable();
      this.createEditProductForm.controls.productId.updateValueAndValidity();
      this.createEditProductForm.patchValue({
        ...this.product, ...{
          salesDiscontinuationDate: new Date(this.product.salesDiscontinuationDate),
          releaseDate: new Date(this.product.releaseDate),
          introductionDate: new Date(this.product.introductionDate),
          supportDiscontinuationDate: new Date(this.product.supportDiscontinuationDate)
        }
      });  }
    this.getCategories();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getCategories(): void {
    this.spinner.show();
    this._CategoryService.getCategories({ pageNo: 1, pageSize: 200 }, { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' })
      .then(data => {
        this.spinner.hide();
        this.categories = data.data.map(value => {
          return {
            label: value.categoryName,
            value: value.productCategoryId
          };
        });
      });
      
  }
  update(): void {
    this.spinner.show();
    if (this.createEditProductForm.valid) {
      this._ProductService.updateProduct({
        ...this.product,
        ...this.createEditProductForm.value, ...{
          salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
          releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
          introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
          supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated successfully');
            this.spinner.hide();
            this._Router.navigate(['/facilities/catalog/products']);
          }
        });
    }
  
  }
  submitProduct(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.createEditProductForm.valid) {
        this._ProductService.createProduct({
          ...this.createEditProductForm.value, ...{
            salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
            releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
            introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
            supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created successfully');
              this.spinner.hide();
              this._Router.navigate(['/facilities/catalog/products']);
            }
          });
      }
    }
   
  }
  selectProduct(id) {
    
    if (id) {
      this.activeCategory = 3;
     

    }
    else {
      this._ToastrService.error("Product Type is required");
    }
  }
  selectworking(name) {
    if (name) {
      this.activeCategory = 4;
    }
    else {
      this._ToastrService.error("Internal Name is required");
    }
  }
  selectvirtual(vname) {
    if (vname) {
      this.activeCategory = 5;
    }
    else {
      this._ToastrService.error("Virtual Variant Method is required");
    }
  }
  selectPrimary(pc) {
    if (pc) {
      this.activeCategory = 6;
    }
    else {
      this._ToastrService.error("Primary Category is required");
    }
  }
  selectDate() {
    this.activeCategory = 7;
  }
  selecInventory(id) {
    if (id) {
      this.activeCategory = 8;
    }
    else {
      this._ToastrService.error("Inventory Item Type Id is required");
    }
   
  }
  selectRating(id) {
    if (id) {
      this.activeCategory = 9;
    }
    else {
      this._ToastrService.error("Rating Type Enum is required");
    }
    
  }
  selectAmount(id) {
    if (id) {
      this.activeCategory = 10;
    }
    else {
      this._ToastrService.error("Amount Uom Type ID is required");
    }
    

  }
  selectMeasure(width,height) {
    if (width && height) {
      this.activeCategory = 11;
    }
    else {
      this._ToastrService.error("Height and width Uom ID is required");
    }
   
  }
  selectShiping(id) {
    if (id) {
      this.activeCategory = 12;
    }
    else {
      this._ToastrService.error("Default Shipment Box Type ID is required");
    }
   
  }
  selectShopingCart() {
    this.activeCategory = 13;
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  wordingValid(id) {
    
    if (id) {
      this.activeCategory = 3;
     

    }
    else {
      this._ToastrService.error("Fill Product Form.");
    }
  }
  virtualValid(name) {
    if (name) {
      this.activeCategory = 4;
    }
    else {
      this._ToastrService.error("Fill Wording Form.");
    }
  }
  primaryValid(vname) {
    if (vname) {
      this.activeCategory = 5;
    }
    else {
      this._ToastrService.error("Fill Virtual Product Form.");
    }
  }
  dateValid(pc) {
    if (pc) {
      this.activeCategory = 6;
    }
    else {
      this._ToastrService.error("Fill Primary Category Form.");
    }
  }
  inventoryValid(pc) {
    if (pc) {
      this.activeCategory = 7;
    }
    else {
      this._ToastrService.error("Fill Primary Category Form.");
    }
   
  }
  ratingValid(id) {
    if (id) {
      this.activeCategory = 8;
    }
    else {
      this._ToastrService.error("Fill Inventory Form.");
    }
   
  }
  amountValid(id) {
    if (id) {
      this.activeCategory = 9;
    }
    else {
      this._ToastrService.error("Fill Rating Form.");
    }
    
  }
  measureValid(id) {
    if (id) {
      this.activeCategory = 10;
    }
    else {
      this._ToastrService.error("Fill Amount Form.");
    }
    

  }
  shipingValid(width,height) {
    if (width && height) {
      this.activeCategory = 11;
    }
    else {
      this._ToastrService.error("Fill Measure Form.");
    }
   
  }
  shopingValid(id) {
    if (id) {
      this.activeCategory = 12;
    }
    else {
      this._ToastrService.error("Fill Shipping Form.");
    }
   
  }
  shopingCartValid(id) {
    if (id) {
      this.activeCategory = 13;
    }
    else {
      this._ToastrService.error("Fill Shipping Cart Form.");
    }
   
  }
  
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
