import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';

@Component({
  selector: 'app-preferences-detail',
  templateUrl: './preferences-detail.component.html',
  styleUrls: ['./preferences-detail.component.css']
})
export class PreferencesDetailComponent implements OnInit {
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  createPortalPageList: any;
  parentPortalPageId: any;
  PreNewList: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.parentPortalPageId = params.parentPortalPageId;
    })
   this.getCreatePortalPageById();
   this.getPreNew();
  }
  getPreNew() {
    this.spinner.show();
    this.myContactsService.getPreNew(this.parentPortalPageId,this.parentPortalPageId).subscribe(res => {
      this.PreNewList = res.data;
      this.spinner.hide();

    })
  }
  cancelSubmit(){
    this._Router.navigate(["/crm/Preferences"])
  }
  getCreatePortalPageById() {
    this.spinner.show();
    this.myContactsService.getCreatePortalPageById(this.parentPortalPageId).subscribe(res => {
      this.createPortalPageList = res.data[0].getCreatePortalPage;
      this.spinner.hide();

    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
