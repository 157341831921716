import { Component, OnInit ,ViewChild ,Pipe,PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';


@Component({
  selector: 'app-newworkorder',
  templateUrl: './newworkorder.component.html',
  styleUrls: ['./newworkorder.component.css'],

})
export class NewworkorderComponent implements OnInit {
  @Pipe({name: 'reverse'})
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  activeCategory = 1;
  activeCategory2 = 5;
  activeCategory3 = 1;
  activeCategory4 = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  webSiteList:any;
  Party:string;
  RoleType:string;
  FromDate: string;
  ThroughDate: string;
  customerDetailsForm:any;
  technicianTable:any;
  selectSite:string;
  contact:string;
  contactAt:any;
  po:string;
  uin:string;
  customerName:string;
  containing:string;

  ein1:string;
  phoneNumber:string;
  priorityForm:any;
  appointmentTypeFrom:any;
  findTechnicianForm:FormGroup;
  addNewTechnicianForm:FormGroup;
  customerResedentialDetailsForm:FormGroup;
  customerCommercialDetailsForm:FormGroup
  FsmTechnicalAll: any;
  FMS_TECHNICIANArray: { label: string; value: string; }[];
  techIDForUpload: any;
  fileData: File;
  uploadFile: FormData;
  show: boolean;
  filterDataResut: any;
  GeoId: any;
  GeoIdArray: any[]=[];
  updateTechnicianPat: any;
  fromDate: any;
  newTechID: any;
  FSMCountryGeoId: any;
  FSMCountryGeoIdArray: any[]=[];
  FSMStateProvId: any;
  FSMStateProvIdArray: any[]=[];
  customerForm: FormGroup;
  FMS_CUSTYPEArray: { label: string; value: string; }[];
  filterDataResutTech: any;
  filterDataResutCustoNew: any;
  customerId: any;
  addCustoProblem: FormGroup;
  FsmProblemByCustomerId: any;
  FsmProblemByCustomerIdArray: any[]=[];
  FsmProblemByProblemId: any;
  FsmProblemByProblemIdArray: any[]=[];
  problemStatusArray: { label: string; value: string; }[];
  fsmEquipmentList: any;
  FsmEquipmentId: any;
  FsmEquipmentIdArray: any[]=[];
  addCustoProblemval: any;
  EquipmentId: any;
  FsmEquipmentServiceId: any;
  FsmEquipmentServiceIdArray: any[]=[];
  FsmEquipmentServiceById: any;
  FsmEquipmentServiceByIdArray: any[]=[];
  FsmProblemAll: any;
  editProbForm: FormGroup;
  updateFsmProblemPat: any;
  FsmPrioritiesId: any;
  FsmPrioritiesIdArray: any[]=[];
  FsmSkillId: any;
  FsmSkillIdArray: any[]=[];
  technicianAvailabilityArray: { label: string; value: string; }[];
  AppointmentTypeArray: { label: string; value: string; }[];
  Technician: any;
  technicianStatusArray: { label: string; value: string; }[];
  FsmTechnicianFind: any;
  FsmTechnicianCreate: any;
  FsmTechnicianFindData: any;
  formValuenewData: any;
  FsmTechnicianCreateData: any;
  FsmCustomerFind: any;
  FsmCustomerCreate: any;
  FsmCustomerFindData: any;
  FsmCustomerCreateData: any;
  FsmProbliemCreate: any;
  FsmProbliemCreateData: any;
  FsmProblemCreate: any;
  FsmProblemCreateData: any;
  problemId: any;
  technicianId: string;
  editAssignmentForm: FormGroup;
  FsmCustomerCommercialById: any;
  FsmCustomerBio: any;
  FsmProblemByCustomerIdData: any;
  FsmProblemBio: any;
  workOrderId: any;
  FsmAssignmentById: any;
  FsmAssignmentBio: any;
  getFsmMapAll: any;
  checknew: any;
  readOnlyCode = true;
  CustoNewResult: any;
  customerIdNew: any;
  updateFsmAssignmentPat: any;
  promisedArrivalFromPatch: string;
  promisedArrivalToPatch: string;
  estimatedDurationPatch: string;
  etapatch: string;
  dueByPatch: string;
  getScheduleAll: any;
  getNonScheduleAll: any;
  readOnlyCodeNew=true;
  customerIdVal: any;
  activeCategoryPopup= 1;
  customerCategoryPopup=1;
  priorityArray: { label: string; value: string; }[];
  paymentProcessSetting: any;
  paymentProcessSettingArray: any[]=[];
  ExpertiseAreaAll: any;
  ExpertiseAreaAllArray: any[]=[];
  ExpertiseAreaByTechnicianId: any;
  ExpertiseAreaByTechnicianIdArray: any[]=[];
  techcategory: any;
  dateArrival: any;
  milliseconds: number;
  dateArrivalTo: any;
  millisecondsTo: number;
  showSchedule: boolean;
  showAssign: boolean;
  newTech: any;
  updateTechId: any;
  showNoRecord: boolean;

  transform(value: string): string {
    return value.split('').reverse().join('');
}
 
  constructor(
    readonly _Router: Router,
      readonly _ActivatedRoute: ActivatedRoute,
      readonly accountsService: AccountsService,
      readonly headerService: HeaderService,
      readonly myContactsService: MyContactsService,
      readonly createEventServices: CreateEventServices,
      readonly datePipe: DatePipe,
      readonly spinner: NgxSpinnerService,
      readonly _FormBuilder: FormBuilder,
      readonly toastr: ToastrService,
      readonly _ToastrService: ToastrService,
      private _location: Location,
     
  ) 
  { 
    this.priorityArray = [
      {
        label: 'High',
        value: 'High'
      },
      {
        label: 'Medium',
        value: 'Medium'
      },
      {
        label: 'Low',
        value: 'Low'
      }
    ]
    this.technicianStatusArray = 
    [
      {
        label: 'Pending',
        value: 'Pending'
      },
      {
        label: 'OnHold',
        value: 'OnHold'
      },
      {
        label: 'Completed',
        value: 'Completed'
      },
    ];
    this.AppointmentTypeArray = [
      {
        label: 'Schedule',
        value: 'Schedule'
      },
      {
        label: 'Non-Schedule',
        value: 'Non-Schedule'
      }
    ];
    this.technicianAvailabilityArray = [
      {
        label: 'Assigned',
        value: 'Assigned'
      },
      {
        label: 'Unassigned',
        value: 'Unassigned'
      }
    ];
    this.FMS_TECHNICIANArray = [
      {
        label: 'FMS_TECHNICIAN',
        value: 'FMS_TECHNICIAN'
      }
    ];
    this.FMS_CUSTYPEArray = [
      {
        label: 'FMS_CUSTOMER_COM',
        value: 'FMS_CUSTOMER_COM'
      },
      {
        label: 'FMS_CUSTOMER_RES',
        value: 'FMS_CUSTOMER_RES'
      },
    ];
    this.problemStatusArray = [
      {
        label: 'Assigned Problem',
        value: 'Assigned'
      },
      {
        label: 'Unassigned Problem',
        value: 'Unassigned'
      }
    ]
    this.editProbForm = this._FormBuilder.group({
      category:[''],
      problemStatus:[''],
      dec:[''],
      paymentMode:[''],
      priority:['']
    });
    this.findTechnicianForm = this._FormBuilder.group({
      expertiseArea:[''],
      technicianId:[''],
      technicianName:[''],
      technicianAvailability:[''],
      email:['']
    })
    this.addNewTechnicianForm =this._FormBuilder.group({
      technicianNo: [''],
      technicianType: [''],
      technicianName: [''],
      technicianAvailability: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      socialSecurityNumber: [''],
      companyRegistration: [''],
      expertiseArea: [''],
      addExpertiseArea:[''],
      address1: [''],
      address2: [''],
      city: [''],
      countryGeoId: [''],
      stateProvinceGeoId: [''],
      postalCode: [''],
      currentPassword: [''],
      technicianStatus:[''],
      technicianStatusDescription:['']
    });
      this.customerDetailsForm = this._FormBuilder.group({
        customerId:[''],
        customerName:[''],
        emailResidential:[''],
        city:[''],
        address1:[''],
      });
      this.customerForm = this._FormBuilder.group({
        customerId: [''],
        customerName: ['']
      });
      this.editAssignmentForm = this._FormBuilder.group({
        appointmentType:[''],
        promisedArrivalFrom:[''],
        promisedArrivalTo:[''],
        priority: [''],
        skill: [''],
        technicianName:[''],
        ETA:[''],
        DueBy:[''],
        estimatedDuration:['']
      })
      this.appointmentTypeFrom=this._FormBuilder.group({
        appointmentType:[''],
        promisedArrivalFrom:[''],
        promisedArrivalTo:[''],
        priority: [''],
        skill: [''],
        technicianName:[''],
        estimatedDuration:[''],
        ETA:[''],
        DueBy:['']
      });
      this.customerCommercialDetailsForm= this._FormBuilder.group({
      customerID:[''],
      currentPassword:[''],
      customerType:[''],
      customerName:[''],
      displayCustomerAs:[''],
      tax:[''],
      taxCode:[''],
      jobName:[''],
      servicePackage:[''],
      address1:[''],
      address2:[''],
      city:[''],
      stateProvinceGeoId:[''],
      countryGeoIdNew:[''],
      postalCode:[''],
      workZone:[''],
      jobTax:[''],
      jobTaxCode:[''],
      primaryContactFirstName:[''],
      primaryContactLastName:[''],
      primaryContactDisplayAs:[''],
      primaryContactPrimaryPhone:[''],
      primaryContactAlternatePrimaryPhone:[''],
      primaryContactFax:[''],
      primaryContactEmail:[''],
      billingAddressInvoiceAblePO:[''],
      billingAddressSame:[''],
      additionalInformationTest:[''],
      additionalInformationNotes:[''],
      additionalInformationSource:[''],
      additionalInformationMake:[''],
      additionalInformationReference:[''],
      additionalInformationColour:[''],
      firstNameResidential:[''],
      lastNameResidential:[''],
      primaryPhoneResidential:[''],
      alternatePrimaryPhoneResidential:[''],
      faxResidential:[''],
      emailResidential:['']
    });

    this.customerResedentialDetailsForm= this._FormBuilder.group({
      fmsCustomerResidentialId:"",
      primaryPhone:"",        
      alternatePrimaryPhone:"",    
      fax:"",
      currentPassword:"",
      countryGeoId:"",
      primaryContactAlternatePrimaryPhone:"",
      firstNameResidential:"",
      lastNameResidential:"",
      primaryPhoneResidential	:"",
      alternatePrimaryPhoneResidential:"",
      emailResidential:"",
      faxResidential:"",	
      technicianId:"",
      technicianType:"",
      technicianName:"",
      technicianAvailability:"",
      firstName:"",
      lastName:"",
      email:"",
      socialSecurityNumber:"",
      companyRegistration:"",
      expertiseArea:"",
      address1:"",
      address2:"",
      city:"",
      stateProvinceGeoId:"",
      postalCode:"",
      displayCustomerAs:"",
      tax:"",     
      taxCode:"",        
      jobName:"",      
      servicePackage:"",   
      workZone:"",
      jobTax:"", 
      jobTaxCode:"",
      billingAddressInvoiceAblePO:"",
      billingAddressSame :"",    
      additionalInformationTest :"", 
      additionalInformationNotes:"",  
      additionalInformationSource :"",   
      additionalInformationMake :"",   
      additionalInformationReference :"",  
      additionalInformationColour:"",
    });
    this.addCustoProblem = this._FormBuilder.group({
      category:[''],
      problemStatus:[''],
      dec:[''],
      priority:[''],
      paymentMode:[''],
    });

}

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(res =>{
      console.log("rescheck",res)
      this.customerIdVal = res["customerIdVal"];
      // this.customerIdNew = res["filterDataResutCustoNew"];

    });
    this.show=true;
    this.showSchedule = true;
    this.showAssign = false;

    if(this.activeCategory==4){
      this.showAssign = true;
    }

    this.localStoreData();
    this.getFsmTechnicalAll();
    this.findFsmTechnician();
    this.getStateProvId();
    this.getCountryGeoId();
    this.getFsmEquipment();
    this.getFsmProblem();
    this.getFsmSkill();
    this.getFsmPriorities();
    this.getFsmProblemByCustomerId();
    this.getFsmCustomerCommercialById();
    this.getFsmAssignmentById();
    this.getSchedule();
    this.getNonSchedule();
    this.getPaymentProcessSetting();
    this.getExpertiseArea();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  resetCustomer(){
    this.show=true;
    this.customerCommercialDetailsForm.reset();
  }
  reset(){
    this.customerForm.reset();
  }
  home(){
    this._Router.navigate(['/fsm/main']);
  }
  back(){
    if(this.activeCategory==4){
        this.activeCategory=3;
    }
    else if(this.activeCategory==3){
      this.activeCategory=2;
      this.getFsmAssignmentByIdNew();
      this.appointmentTypeFrom.reset();
      this.ExpertiseAreaByTechnicianIdArray=[];

    }
    else if(this.activeCategory==2){
      this.activeCategory=1;
    }
    else if(this.activeCategory==1){
      // this.activeCategory=0;
      this._Router.navigate(['/fsm/main']);

    }
    // else if(this.activeCategory==0){
    //   this._Router.navigate(['/fsm/main']);
    // }
    else{
      this._Router.navigate(['/fsm/main']);
    }
  }
  getExpertiseArea() {
    this.spinner.show();
    this.accountsService.getExpertiseAreaNew().subscribe(res => {
      this.ExpertiseAreaAll = res.data[0].getExpertiseArea;
      this.spinner.hide();
      for (const value of this.ExpertiseAreaAll) {
        this.ExpertiseAreaAllArray.push({
          label: value.expertiseAreaName,
          value: value.expertiseAreaName
        })
      }
    });

  } 

  getPaymentProcessSetting() {
    this.spinner.show();
    this.myContactsService.getPaymentProcessSetting().subscribe(res => {
      this.paymentProcessSetting = res.data[0].getPaymentProcessSetting;
      this.spinner.hide();
      for (const value of this.paymentProcessSetting) {
        this.paymentProcessSettingArray.push({
          label: value.paymentType,
          value: value.paymentProcessId
        })
      }
    });

  }
  sendToInvoive(){
    this._Router.navigate(['fsm/invoices']);
  }
  changettabFun(){
    this.activeCategory = 1;
  }
  changettabFun1(){
    this.activeCategory = 2;
    
  }
  changettabFun2(){
    this.activeCategory = 3;
    
  }
  getSchedule() {
    this.spinner.show();
    this.accountsService.getSchedule().subscribe(res => {
      this.getScheduleAll = res.data;
      this.spinner.hide();
    })
  }
  getNonSchedule() {
    this.spinner.show();
    this.accountsService.getNonSchedule().subscribe(res => {
      this.getNonScheduleAll = res.data;
      this.spinner.hide();
    })
  }
  getFsmMap() {
    this.spinner.show();
    this.accountsService.getFsmMap().subscribe(res => {
      this.getFsmMapAll = res.data;
      this.spinner.hide();
    })
  }
  getFsmAssignmentByIdNew() {
    this.spinner.show();
    this.accountsService.getFsmAssignmentById("").subscribe(res => {
      this.FsmAssignmentById = res.data[0].getFsmAssignment;
      this.FsmAssignmentBio = this.FsmAssignmentById[0];
      this.spinner.hide();
    })
  }
  getFsmAssignmentById() {
    this.spinner.show();
    this.accountsService.getFsmAssignmentById(this.workOrderId).subscribe(res => {
      this.FsmAssignmentById = res.data[0].getFsmAssignment;
      this.FsmAssignmentBio = this.FsmAssignmentById[0];
      this.spinner.hide();
    })
  }
  getFsmProblemByCustomerId() {
    this.spinner.show();
    this.accountsService.getFsmProblemByCustomerId(this.customerIdVal).subscribe(res => {
      this.FsmProblemByCustomerIdData = res.data[0].getFsmProblem;
      this.FsmProblemBio = this.FsmProblemByCustomerIdData[0];
      this.spinner.hide();
    })
  }
  getFsmCustomerCommercialById() {
    this.spinner.show();
    this.accountsService.getFsmCustomerCommercialById(this.customerIdVal).subscribe(res => {
      this.FsmCustomerCommercialById = res.data[0].getFsmCustomerCommercialById;
      this.FsmCustomerBio = this.FsmCustomerCommercialById[0];
      this.spinner.hide();
    })
  }
  deleteFsmAssignment(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "workOrderId": product.workOrderId
        
        }
        this.accountsService.deleteFsmAssignment(req).subscribe((res: any) => {
          if (res.success) {
            this.getFsmProblem();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateFsmAssignment(): void {
    this.spinner.show();
    const requestData = {
      "address1":  this.FsmCustomerFindData[0].fsmCustomer.address1 ? this.FsmCustomerFindData[0].fsmCustomer.address1 : this.FsmCustomerCreateData.address1,
      "address2":  this.FsmCustomerFindData[0].fsmCustomer.address2 ? this.FsmCustomerFindData[0].fsmCustomer.address2 : this.FsmCustomerCreateData.address2,
      "appointmentType": this.editAssignmentForm.value.appointmentType,
      "city": this.FsmCustomerFindData[0].fsmCustomer.city ? this.FsmCustomerFindData[0].fsmCustomer.city : this.FsmCustomerCreateData.city,
      "countryGeoId": this.FsmCustomerFindData[0].fsmCustomer.countryGeoId ? this.FsmCustomerFindData[0].fsmCustomer.countryGeoId : this.FsmCustomerCreateData.countryGeoId,
      "customerId":  this.FsmCustomerFindData[0].fsmCustomer.customerId ? this.FsmCustomerFindData[0].fsmCustomer.customerId : this.customerId,
      "customerName": this.FsmCustomerFindData[0].fsmCustomer.customerName ? this.FsmCustomerFindData[0].fsmCustomer.customerName : this.FsmCustomerCreateData.customerName,
      "dueBy": this.datePipe.transform(this.editAssignmentForm.value.DueBy, "yyyy-MM-dd HH:mm:ss"),
      "estimatedDuration": this.datePipe.transform(this.editAssignmentForm.value.estimatedDuration, "yyyy-MM-dd HH:mm:ss"),
      "eta": this.datePipe.transform(this.editAssignmentForm.value.ETA, "yyyy-MM-dd HH:mm:ss"),
      "expertiseArea": this.FsmTechnicianFindData[0].data.expertiseArea ? this.FsmTechnicianFindData[0].data.expertiseArea : this.FsmTechnicianCreateData.expertiseArea,
      "postalCode": this.FsmCustomerFindData[0].fsmCustomer.postalCode ? this.FsmCustomerFindData[0].fsmCustomer.postalCode : this.FsmCustomerCreateData.postalCode,
      "priority": this.editAssignmentForm.value.priority,
      "problemId": this.problemId,
      "problemStatus": this.FsmProblemCreateData.problemStatus,
      "promisedArrivalFrom":  this.datePipe.transform(this.editAssignmentForm.value.promisedArrivalFrom, "yyyy-MM-dd HH:mm:ss"),
      "promisedArrivalTo": this.datePipe.transform(this.editAssignmentForm.value.promisedArrivalTo, "yyyy-MM-dd HH:mm:ss"),
      "skill": this.editAssignmentForm.value.skill,
      "stateProvinceGeoId": this.FsmCustomerFindData[0].fsmCustomer.stateProvinceGeoId ? this.FsmCustomerFindData[0].fsmCustomer.stateProvinceGeoId : this.FsmCustomerCreateData.stateProvinceGeoId,
      "technicianAvailability": this.FsmTechnicianFindData[0].data.technicianAvailability ? this.FsmTechnicianFindData[0].data.technicianAvailability : this.FsmTechnicianCreateData.technicianAvailability,
      "technicianId": this.editAssignmentForm.value.technicianName,
      "technicianName": this.editAssignmentForm.value.technicianName,
      "workOrderId": this.updateFsmAssignmentPat.workOrderId
    }
    this.accountsService.updateFsmAssignment(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        localStorage.setItem('workOrderId', this.updateFsmAssignmentPat.workOrderId);
        localStorage.setItem('technicianId', this.editAssignmentForm.value.technicianName);
        this.updateTechId = this.editAssignmentForm.value.technicianName;
        this.findFsmTechnicianNew();
        this.getFsmCustomerCommercialById();
        this.getFsmProblemByCustomerId();
        this.getFsmAssignmentById();
        this.closebutton4.nativeElement.click();
        this.show=true;
        this.editAssignmentForm.reset();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFsmAssignmentPatch(product) {
    this.updateFsmAssignmentPat = product ;
    this.spinner.show();
    const promisedArrivalFromPatch=product.promisedArrivalFrom;
    this.promisedArrivalFromPatch=this.datePipe.transform(promisedArrivalFromPatch,"yyyy-MM-dd");
    const promisedArrivalToPatch=product.promisedArrivalTo;
    this.promisedArrivalToPatch=this.datePipe.transform(promisedArrivalToPatch,"yyyy-MM-dd");
    const estimatedDurationPatch=product.estimatedDuration;
    this.estimatedDurationPatch=this.datePipe.transform(estimatedDurationPatch,"yyyy-MM-dd");
    const etapatch=product.eta;
    this.etapatch=this.datePipe.transform(etapatch,"yyyy-MM-dd");
    const dueByPatch=product.dueBy;
    this.dueByPatch=this.datePipe.transform(dueByPatch,"yyyy-MM-dd");

      setTimeout(() => {
        const formValue = this.editAssignmentForm;
        formValue.patchValue({
            appointmentType: this.updateFsmAssignmentPat.appointmentType,
            promisedArrivalFrom: this.promisedArrivalFromPatch,
            promisedArrivalTo: this.promisedArrivalToPatch,
            priority: this.updateFsmAssignmentPat.priority,
            skill: this.updateFsmAssignmentPat.skill,
            technicianName: this.updateFsmAssignmentPat.technicianName,
            ETA: this.etapatch,
            DueBy: this.dueByPatch,
            estimatedDuration: this.estimatedDurationPatch
        })
      }, 2000);
      this.spinner.hide();
  }
  createFsmAssignment(): void {
    this.spinner.show();
    console.log(this.millisecondsTo,"to")
    this.dateArrival = this.appointmentTypeFrom.value.promisedArrivalFrom;
    var date = new Date(this.dateArrival);
    this.milliseconds = date.getTime();

    this.dateArrivalTo = this.appointmentTypeFrom.value.promisedArrivalTo;
    var date = new Date(this.dateArrivalTo);
    this.millisecondsTo = date.getTime();
    console.log(this.millisecondsTo,"from");
    const requestData = {
      "address1":  this.FsmCustomerFindData[0].fsmCustomer.address1 ? this.FsmCustomerFindData[0].fsmCustomer.address1 : this.FsmCustomerCreateData.address1,
      "address2":  this.FsmCustomerFindData[0].fsmCustomer.address2 ? this.FsmCustomerFindData[0].fsmCustomer.address2 : this.FsmCustomerCreateData.address2,
      "appointmentType": this.appointmentTypeFrom.value.appointmentType,
      "city": this.FsmCustomerFindData[0].fsmCustomer.city ? this.FsmCustomerFindData[0].fsmCustomer.city : this.FsmCustomerCreateData.city,
      "countryGeoId": this.FsmCustomerFindData[0].fsmCustomer.countryGeoId ? this.FsmCustomerFindData[0].fsmCustomer.countryGeoId : this.FsmCustomerCreateData.countryGeoId,
      "customerId": this.FsmCustomerFindData[0].fsmCustomer.customerId ? this.FsmCustomerFindData[0].fsmCustomer.customerId : this.customerId,
      "customerName": this.FsmCustomerFindData[0].fsmCustomer.customerName ? this.FsmCustomerFindData[0].fsmCustomer.customerName : this.FsmCustomerCreateData.customerName,
      "dueBy": this.datePipe.transform(this.appointmentTypeFrom.value.DueBy, "yyyy-MM-dd HH:mm:ss"),
      "estimatedDuration": this.datePipe.transform(this.appointmentTypeFrom.value.estimatedDuration, "yyyy-MM-dd HH:mm:ss"),
      "eta": this.datePipe.transform(this.appointmentTypeFrom.value.ETA, "yyyy-MM-dd HH:mm:ss"),
      "expertiseArea": this.FsmTechnicianFindData[0].data.expertiseArea ? this.FsmTechnicianFindData[0].data.expertiseArea : this.FsmTechnicianCreateData.expertiseArea,
      "postalCode":this.FsmCustomerFindData[0].fsmCustomer.postalCode ? this.FsmCustomerFindData[0].fsmCustomer.postalCode : this.FsmCustomerCreateData.postalCode,
      "priority": this.appointmentTypeFrom.value.priority,
      "problemId": this.problemId,
      "problemStatus": this.FsmProblemCreateData.problemStatus,
      "promisedArrivalFrom": this.milliseconds,
      "promisedArrivalTo": this.millisecondsTo,
      "skill": this.appointmentTypeFrom.value.skill,
      "stateProvinceGeoId": this.FsmCustomerFindData[0].fsmCustomer.stateProvinceGeoId ? this.FsmCustomerFindData[0].fsmCustomer.stateProvinceGeoId : this.FsmCustomerCreateData.stateProvinceGeoId,
      "technicianAvailability": this.FsmTechnicianFindData[0].data.technicianAvailability ? this.FsmTechnicianFindData[0].data.technicianAvailability : this.FsmTechnicianCreateData.technicianAvailability,
      "technicianId": this.appointmentTypeFrom.value.technicianName,
      "technicianName": this.appointmentTypeFrom.value.technicianName,
      "workOrderId": ""
    }
    this.accountsService.createFsmAssignment(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.workOrderId = res.data.workOrderId;
        localStorage.setItem('workOrderId', this.workOrderId);
        this.newTech = this.appointmentTypeFrom.value.technicianName;
        localStorage.setItem('technicianId', this.appointmentTypeFrom.value.technicianName);
        this.localStoreData();
        this.findFsmTechnicianNew();
        this.getFsmCustomerCommercialById();
        this.getFsmProblemByCustomerId();
        this.getFsmAssignmentById();
        this.appointmentTypeFrom.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  findFsmTechnicianNew(): void {
    this.show=false;
    this.spinner.show();
    const requestData = {
      "email": '',
      "expertiseArea": '',
      "technicianAvailability": '',
      "technicianId": this.newTech ? this.newTech : this.updateTechId,
      "technicianName": '',
    }
    this.accountsService.findFsmTechnician(requestData).subscribe((res: any) => {
      if (res.success) {
        this.filterDataResutTech = res.data;
        this.spinner.hide();
        localStorage.setItem('FsmTechnicianFind', JSON.stringify(this.filterDataResutTech));
        this.localStoreData();
        this.findTechnicianForm.reset();
        

        
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  localStoreData(){
    this.FsmTechnicianFind = localStorage.getItem('FsmTechnicianFind');
    this.FsmTechnicianCreate = localStorage.getItem('FsmTechnicianCreate');
    this.FsmCustomerFind = localStorage.getItem('FsmCustomerFind');
    this.FsmCustomerCreate = localStorage.getItem('FsmCustomerCreate');
    this.FsmProblemCreate = localStorage.getItem('FsmProblemCreate');

    this.problemId = localStorage.getItem('problemId');
    this.technicianId = localStorage.getItem('technicianId');
    this.workOrderId = localStorage.getItem('workOrderId');
    this.customerId = localStorage.getItem('customerId');


    


    this.FsmTechnicianFindData = JSON.parse(`${this.FsmTechnicianFind}`);
    this.FsmTechnicianCreateData = JSON.parse(`${this.FsmTechnicianCreate}`);
    this.FsmCustomerFindData = JSON.parse(`${this.FsmCustomerFind}`);
    this.FsmCustomerCreateData = JSON.parse(`${this.FsmCustomerCreate}`);
    this.FsmProblemCreateData = JSON.parse(`${this.FsmProblemCreate}`);



    console.log("problemId=>",this.problemId);
    console.log("customerId=>",this.customerId);
    console.log("workOrderId=>",this.workOrderId);
    console.log("technicianId=>",this.technicianId);
    console.log("FsmTechnicianFindData=>",this.FsmTechnicianFindData);
    console.log("FsmTechnicianCreateData=>",this.FsmTechnicianCreateData);
    console.log("FsmCustomerFindData=>",this.FsmCustomerFindData);
    console.log("FsmCustomerCreateData=>",this.FsmCustomerCreateData);
    console.log("FsmProblemCreateData=>",this.FsmProblemCreateData);

  }
  getFsmSkill() {
      this.spinner.show();
      this.accountsService.getFsmSkill().subscribe(res => {
        this.FsmSkillId = res.data[0].getFsmSkill;
        this.spinner.hide();
        for (const value of this.FsmSkillId) {
          this.FsmSkillIdArray.push({
            label: value.skillDisplayAs,
            value: value.skillDisplayAs
          })
        }
      });

    }  
    getFsmPriorities() {
    this.spinner.show();
    this.accountsService.getFsmPriorities().subscribe(res => {
      this.FsmPrioritiesId = res.data[0].getFsmPriorities;
      this.spinner.hide();
      for (const value of this.FsmPrioritiesId) {
        this.FsmPrioritiesIdArray.push({
          label: value.prioritiesName,
          value: value.prioritiesName
        })
      }
    });

  }  
  deleteFsmProblem(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "problemId": product.problemId
        
        }
        this.accountsService.deleteFsmProblem(req).subscribe((res: any) => {
          if (res.success) {
            this.getFsmProblemByCustomerId();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateFsmProblem(): void {
    this.spinner.show();
    const requestData = {
      "category":  this.editProbForm.value.category,
      "customerId": this.updateFsmProblemPat.customerId,
      "description": this.editProbForm.value.dec,
      "paymentMode": this.editProbForm.value.paymentMode,
      "priority": this.editProbForm.value.priority,
      "problemId": this.updateFsmProblemPat.problemId,
      "problemStatus": this.editProbForm.value.problemStatus,
      "service": '',

    }
    this.accountsService.updateFsmProblem(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getFsmProblemByCustomerId();
        this.getFsmProblem();
        this.techcategory =this.editProbForm.value.category;
        this.getExpertiseAreaByTechnicianId();
        this.closebutton3.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateFsmProblemPatch(product) {
    this.updateFsmProblemPat = product ;
    this.spinner.show();
    this.show=false;
    setTimeout(() => {
      const formValue = this.editProbForm;
      formValue.patchValue({
          category: this.updateFsmProblemPat.category,
          problemStatus: this.updateFsmProblemPat.problemStatus,
          dec: this.updateFsmProblemPat.description,
          paymentMode: this.updateFsmProblemPat.paymentMode,
          priority: this.updateFsmProblemPat.priority
      })

    }, 2000);
    this.spinner.hide();
  }
  cancel(){
    this.activeCategory=2;
    this.appointmentTypeFrom.reset();
  }
  moveToAssign(product){
    this.problemId = product.data.problemId;
    localStorage.setItem('problemId', this.problemId);
    this.activeCategory=3;
    this.techcategory = product.data.expertiseArea;
    this.getExpertiseAreaByTechnicianId();
    console.log(this.problemId,"Non Schedule")
  }
  moveToAssigns(product){
    this.problemId = product.problemId;
    localStorage.setItem('problemId', this.problemId);
    this.activeCategory=3;
    this.techcategory =  product.category;
    this.getExpertiseAreaByTechnicianId();
    console.log(this.problemId,"--Schedule")
    console.log(this.techcategory,"--expertiseArea")
  }

  createFsmProblem(): void {
    this.spinner.show();
    const requestData = {  
      "category": this.addCustoProblem.value.category,
      "customerId": this.FsmCustomerFindData[0].fsmCustomer.customerId ? this.FsmCustomerFindData[0].fsmCustomer.customerId : this.customerId,
      "description": this.addCustoProblem.value.dec,
      "paymentMode": this.addCustoProblem.value.paymentMode,
      "priority": this.addCustoProblem.value.priority,
      "problemId": '',
      "problemStatus": this.addCustoProblem.value.problemStatus,
      "service": '',
    }
    this.accountsService.createFsmProblem(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFsmProblemByCustomerId();
        this.getFsmProblem();
        this.techcategory =this.addCustoProblem.value.category;

        this.getExpertiseAreaByTechnicianId();
        this.problemId = res.data.problemId;
        localStorage.setItem('FsmProblemCreate', JSON.stringify(this.addCustoProblem.value));
        localStorage.setItem('problemId', this.problemId);
        this.localStoreData();
        this.addCustoProblem.reset();
        this.showSchedule = false;
      
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  // method for expertiseArea
  updateexpertiseAreaPatch() {
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.addNewTechnicianForm;
      formValue.patchValue({
          expertiseArea: this.updateTechnicianPat.expertiseArea,
      })

    }, 2000);
    this.spinner.hide();
  }
  // method for expertiseArea end

  createFsmExpertiseArea(): void {
    this.spinner.show();
    const requestData = {  
      "expertiseAreaId": "",
      "expertiseAreaName": this.addNewTechnicianForm.value.addExpertiseArea
    }
    this.accountsService.createFsmExpertiseArea(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Added Successfully");
        this.spinner.hide();
        this.getExpertiseArea();
        this.addNewTechnicianForm.get('addExpertiseArea').reset();
      
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getExpertiseAreaByTechnicianId() {
    this.spinner.show();
    this.accountsService.getExpertiseAreaByTechnicianId(this.techcategory).subscribe(res => {
      this.ExpertiseAreaByTechnicianId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ExpertiseAreaByTechnicianId) {
        console.log("nameeee", value);
        console.log("nameeee", value.technicianName);
        this.ExpertiseAreaByTechnicianIdArray.push({
          label: value.technicianName,
          value: value.technicianId
        })
      }
    });

  } 
  getFsmProblem() {
    this.spinner.show();
    this.accountsService.getFsmProblem().subscribe(res => {
      this.FsmProblemAll = res.data[0].getFsmProblem;
      this.spinner.hide();
    })
  }
 
  getfilterService(e:any){

    console.log("service filter code",e);
    this.EquipmentId = e;
    console.log("EquipmentId", this.addCustoProblemval);
    this.getFsmEquipmentServiceById();
  }
  getFsmEquipmentServiceById(){
    this.FsmEquipmentServiceByIdArray = [];
    this.spinner.show();
    this.myContactsService.getFsmEquipmentServiceById(this.EquipmentId).subscribe(res => {
      this.FsmEquipmentServiceById = res.data[0].getFsmEquipmentServiceById;
      this.spinner.hide();
      for (const value of this.FsmEquipmentServiceById) {
        this.FsmEquipmentServiceByIdArray.push({
          label: value.serviceName,
          value: value.serviceName
        })
      }
    });
  }
  getFsmEquipment() {
      this.spinner.show();
      this.myContactsService.getFsmEquipment().subscribe(res => {
        this.FsmEquipmentId = res.data[0].getFsmEquipment;
        this.spinner.hide();
        for (const value of this.FsmEquipmentId) {
          this.FsmEquipmentIdArray.push({
            label: value.typeName,
            value: value.equipmentId
          })
        }
      });
 
  }  
  updateCustomer(){
    setTimeout(() => {
      const form = this.customerCommercialDetailsForm;
      form.patchValue({
        customerID: this.CustoNewResult.customerId,
        currentPassword: this.CustoNewResult.currentPassword,
        customerType: this.CustoNewResult.customerType,
        customerName: this.CustoNewResult.customerName,
        displayCustomerAs: this.CustoNewResult.displayCustomerAs,
        tax: this.CustoNewResult.tax,
        taxCode: this.CustoNewResult.taxCode,
        jobName: this.CustoNewResult.jobName,
        servicePackage: this.CustoNewResult.servicePackage,
        address1: this.CustoNewResult.address1,
        address2: this.CustoNewResult.address2,
        city: this.CustoNewResult.city,
        stateProvinceGeoId: this.CustoNewResult.stateProvinceGeoId,
        countryGeoIdNew: this.CustoNewResult.additionalInformationMake,
        postalCode: this.CustoNewResult.postalCode,
        workZone: this.CustoNewResult.workZone,
        jobTax: this.CustoNewResult.jobTax,
        jobTaxCode: this.CustoNewResult.jobTaxCode,
        primaryContactFirstName: this.CustoNewResult.primaryContactFirstName,
        primaryContactLastName: this.CustoNewResult.primaryContactLastName,
        primaryContactDisplayAs: this.CustoNewResult.primaryContactDisplayAs,
        primaryContactPrimaryPhone: this.CustoNewResult.primaryContactPrimaryPhone,
        primaryContactAlternatePrimaryPhone: this.CustoNewResult.primaryContactAlternatePrimaryPhone,
        primaryContactFax: this.CustoNewResult.primaryContactFax,
        primaryContactEmail: this.CustoNewResult.primaryContactEmail,
        billingAddressInvoiceAblePO: this.CustoNewResult.billingAddressInvoiceAblePO,
        billingAddressSame: this.CustoNewResult.billingAddressSame,
        additionalInformationTest: this.CustoNewResult.additionalInformationTest,
        additionalInformationNotes: this.CustoNewResult.additionalInformationNotes,
        additionalInformationSource: this.CustoNewResult.additionalInformationSource,
        additionalInformationMake: this.CustoNewResult.additionalInformationMake,
        additionalInformationReference: this.CustoNewResult.additionalInformationReference,
        additionalInformationColour: this.CustoNewResult.additionalInformationColour,
        firstNameResidential: this.CustoNewResult.firstNameResidential,
        lastNameResidential: this.CustoNewResult.lastNameResidential,
        primaryPhoneResidential: this.CustoNewResult.primaryPhoneResidential,
        alternatePrimaryPhoneResidential: this.CustoNewResult.alternatePrimaryPhoneResidential,
        faxResidential: this.CustoNewResult.faxResidential,
        emailResidential: this.CustoNewResult.emailResidential,
      })
    }, 2000);
   
   
  }

  updateFsmCustomerResidential(): void {
    this.spinner.show();
    const requestData = {
      "additionalInformationColour": this.customerCommercialDetailsForm.value.additionalInformationColour,
      "additionalInformationMake": this.customerCommercialDetailsForm.value.additionalInformationMake,
      "additionalInformationNotes": this.customerCommercialDetailsForm.value.additionalInformationNotes,
      "additionalInformationReference": this.customerCommercialDetailsForm.value.additionalInformationReference,
      "additionalInformationSource": this.customerCommercialDetailsForm.value.additionalInformationSource,
      "additionalInformationTest": this.customerCommercialDetailsForm.value.additionalInformationTest,
      "address1": this.customerCommercialDetailsForm.value.address1,
      "address2": this.customerCommercialDetailsForm.value.address2,
      "alternatePrimaryPhoneResidential": this.customerCommercialDetailsForm.value.alternatePrimaryPhoneResidential,
      "billingAddressInvoiceAblePO": this.customerCommercialDetailsForm.value.billingAddressInvoiceAblePO,
      "billingAddressSame": this.customerCommercialDetailsForm.value.billingAddressSame,
      "city": this.customerCommercialDetailsForm.value.city,
      "countryGeoId": this.customerCommercialDetailsForm.value.countryGeoIdNew,
      "currentPassword": this.customerCommercialDetailsForm.value.currentPassword,
      "customerId": '',
      "customerName": this.customerCommercialDetailsForm.value.customerName,
      "customerType": this.customerCommercialDetailsForm.value.customerType,
      "displayCustomerAs": this.customerCommercialDetailsForm.value.displayCustomerAs,
      "emailResidential": this.customerCommercialDetailsForm.value.emailResidential,
      "faxResidential": this.customerCommercialDetailsForm.value.faxResidential,
      "firstNameResidential": this.customerCommercialDetailsForm.value.firstNameResidential,
      "jobName": this.customerCommercialDetailsForm.value.jobName,
      "jobTax": this.customerCommercialDetailsForm.value.jobTax,
      "jobTaxCode": this.customerCommercialDetailsForm.value.jobTaxCode,
      "lastNameResidential": this.customerCommercialDetailsForm.value.lastNameResidential,
      "postalCode": this.customerCommercialDetailsForm.value.postalCode,
      "primaryContactAlternatePrimaryPhone": this.customerCommercialDetailsForm.value.primaryContactAlternatePrimaryPhone,
      "primaryContactDisplayAs": this.customerCommercialDetailsForm.value.primaryContactDisplayAs,
      "primaryContactEmail": this.customerCommercialDetailsForm.value.primaryContactEmail,
      "primaryContactFax": this.customerCommercialDetailsForm.value.primaryContactFax,
      "primaryContactFirstName": this.customerCommercialDetailsForm.value.primaryContactFirstName,
      "primaryContactLastName": this.customerCommercialDetailsForm.value.primaryContactLastName,
      "primaryContactPrimaryPhone": this.customerCommercialDetailsForm.value.primaryContactPrimaryPhone,
      "primaryPhoneResidential": this.customerCommercialDetailsForm.value.primaryPhoneResidential,
      "servicePackage": this.customerCommercialDetailsForm.value.servicePackage,
      "stateProvinceGeoId": this.customerCommercialDetailsForm.value.stateProvinceGeoId,
      "tax": this.customerCommercialDetailsForm.value.tax,
      "taxCode": this.customerCommercialDetailsForm.value.taxCode,
      "workZone": this.customerCommercialDetailsForm.value.workZone
    }
    this.accountsService.updateFsmCustomerResidential(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getFsmProblem();
        this.findFsmCustomer();
        this.updateFsmCustomerPatch(this.CustoNewResult);
        this.closebutton1.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateFsmCustomerPatch(CustoNewResult:any) {
    console.log("FsmCustomerPatchvalueOatch",CustoNewResult);
    this.spinner.show();
    this.show=false;
    setTimeout(() => {
      const formValue = this.customerDetailsForm;
      formValue.patchValue({
        customerId: CustoNewResult.customerId ? CustoNewResult.customerId:'N/A',
        customerName: CustoNewResult.customerName ? CustoNewResult.customerName:'N/A',
        emailResidential: CustoNewResult.emailResidential ? CustoNewResult.emailResidential:'N/A',
        city: CustoNewResult.city ? CustoNewResult.city:'N/A',
        address1: CustoNewResult.address1 ? CustoNewResult.address1:'N/A',
        
      })

    }, 2000);
    this.spinner.hide();
  }
  updateFsmCustomerPop(){
    this.updateFsmCustomerPatchPop(this.CustoNewResult);
  }
  updateFsmCustomerPatchPop(CustoNewResult:any) {
    console.log("FsmCustomerPatchvalueOatch",CustoNewResult);
    this.spinner.show();
    this.show=false;
    setTimeout(() => {
      const formValue = this.customerCommercialDetailsForm;
      formValue.patchValue({
        customerID: CustoNewResult.customerId,
        currentPassword: CustoNewResult.currentPassword,
        customerType: CustoNewResult.customerType,
        customerName: CustoNewResult.customerName,
        displayCustomerAs: CustoNewResult.displayCustomerAs,
        tax: CustoNewResult.tax,
        taxCode: CustoNewResult.taxCode,
        jobName: CustoNewResult.jobName,
        servicePackage: CustoNewResult.servicePackage,
        address1: CustoNewResult.address1,
        address2: CustoNewResult.address2,
        city: CustoNewResult.city,
        stateProvinceGeoId: CustoNewResult.stateProvinceGeoId,
        countryGeoIdNew: CustoNewResult.countryGeoId,
        postalCode: CustoNewResult.postalCode,
        workZone: CustoNewResult.workZone,
        jobTax: CustoNewResult.jobTax,
        jobTaxCode: CustoNewResult.jobTaxCode,
        primaryContactFirstName: CustoNewResult.primaryContactFirstName,
        primaryContactLastName: CustoNewResult.primaryContactLastName,
        primaryContactDisplayAs: CustoNewResult.primaryContactDisplayAs,
        primaryContactPrimaryPhone: CustoNewResult.primaryContactPrimaryPhone,
        primaryContactAlternatePrimaryPhone: CustoNewResult.primaryContactAlternatePrimaryPhone,
        primaryContactFax: CustoNewResult.primaryContactFax,
        primaryContactEmail: CustoNewResult.primaryContactEmail,
        billingAddressInvoiceAblePO: CustoNewResult.billingAddressInvoiceAblePO,
        billingAddressSame: CustoNewResult.billingAddressSame,
        additionalInformationTest: CustoNewResult.additionalInformationTest,
        additionalInformationNotes: CustoNewResult.additionalInformationNotes,
        additionalInformationSource: CustoNewResult.additionalInformationSource,
        additionalInformationMake: CustoNewResult.additionalInformationMake,
        additionalInformationReference: CustoNewResult.additionalInformationReference,
        additionalInformationColour: CustoNewResult.additionalInformationColour,
        firstNameResidential: CustoNewResult.firstNameResidential,
        lastNameResidential: CustoNewResult.lastNameResidential,
        primaryPhoneResidential: CustoNewResult.primaryPhoneResidential,
        alternatePrimaryPhoneResidential: CustoNewResult.alternatePrimaryPhoneResidential,
        faxResidential: CustoNewResult.faxResidential,
        emailResidential: CustoNewResult.emailResidential,
      })

    }, 2000);
    this.spinner.hide();
  }

  createFsmCustomerResidential(): void {
    this.spinner.show();
    const requestData = {
      "additionalInformationColour": this.customerCommercialDetailsForm.value.additionalInformationColour,
      "additionalInformationMake": this.customerCommercialDetailsForm.value.additionalInformationMake,
      "additionalInformationNotes": this.customerCommercialDetailsForm.value.additionalInformationNotes,
      "additionalInformationReference": this.customerCommercialDetailsForm.value.additionalInformationReference,
      "additionalInformationSource": this.customerCommercialDetailsForm.value.additionalInformationSource,
      "additionalInformationTest": this.customerCommercialDetailsForm.value.additionalInformationTest,
      "address1": this.customerCommercialDetailsForm.value.address1,
      "address2": this.customerCommercialDetailsForm.value.address2,
      "alternatePrimaryPhoneResidential": this.customerCommercialDetailsForm.value.alternatePrimaryPhoneResidential,
      "billingAddressInvoiceAblePO": this.customerCommercialDetailsForm.value.billingAddressInvoiceAblePO,
      "billingAddressSame": this.customerCommercialDetailsForm.value.billingAddressSame,
      "city": this.customerCommercialDetailsForm.value.city,
      "countryGeoId": this.customerCommercialDetailsForm.value.countryGeoIdNew,
      "currentPassword": this.customerCommercialDetailsForm.value.currentPassword,
      "customerId": '',
      "customerName": this.customerCommercialDetailsForm.value.customerName,
      "customerType": this.customerCommercialDetailsForm.value.customerType,
      "displayCustomerAs": this.customerCommercialDetailsForm.value.displayCustomerAs,
      "emailResidential": this.customerCommercialDetailsForm.value.emailResidential,
      "faxResidential": this.customerCommercialDetailsForm.value.faxResidential,
      "firstNameResidential": this.customerCommercialDetailsForm.value.firstNameResidential,
      "jobName": this.customerCommercialDetailsForm.value.jobName,
      "jobTax": this.customerCommercialDetailsForm.value.jobTax,
      "jobTaxCode": this.customerCommercialDetailsForm.value.jobTaxCode,
      "lastNameResidential": this.customerCommercialDetailsForm.value.lastNameResidential,
      "postalCode": this.customerCommercialDetailsForm.value.postalCode,
      "primaryContactAlternatePrimaryPhone": this.customerCommercialDetailsForm.value.primaryContactAlternatePrimaryPhone,
      "primaryContactDisplayAs": this.customerCommercialDetailsForm.value.primaryContactDisplayAs,
      "primaryContactEmail": this.customerCommercialDetailsForm.value.primaryContactEmail,
      "primaryContactFax": this.customerCommercialDetailsForm.value.primaryContactFax,
      "primaryContactFirstName": this.customerCommercialDetailsForm.value.primaryContactFirstName,
      "primaryContactLastName": this.customerCommercialDetailsForm.value.primaryContactLastName,
      "primaryContactPrimaryPhone": this.customerCommercialDetailsForm.value.primaryContactPrimaryPhone,
      "primaryPhoneResidential": this.customerCommercialDetailsForm.value.primaryPhoneResidential,
      "servicePackage": this.customerCommercialDetailsForm.value.servicePackage,
      "stateProvinceGeoId": this.customerCommercialDetailsForm.value.stateProvinceGeoId,
      "tax": this.customerCommercialDetailsForm.value.tax,
      "taxCode": this.customerCommercialDetailsForm.value.taxCode,
      "workZone": this.customerCommercialDetailsForm.value.workZone
    }
    this.accountsService.createFsmCustomerResidential(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.findFsmCustomerNew();
        this.closebutton1.nativeElement.click();
        this.filterDataResutCustoNew = res.data.customerId;
        this._Router.navigate(['fsm/newWorkOrder'],{ queryParams: { customerIdVal: this.filterDataResutCustoNew} });
        localStorage.setItem('FsmCustomerCreate', JSON.stringify(this.customerCommercialDetailsForm.value));
        localStorage.setItem('customerId', this.filterDataResutCustoNew);
        this.localStoreData();
        this.customerCommercialDetailsForm.reset();
      
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  findFsmCustomerNew(): void {
    this.spinner.show();
    let resData={
      "customerId": this.customerForm.value.customerId ? this.customerForm.value.customerId : '',
      "customerName": this.customerForm.value.customerName ? this.customerForm.value.customerName : '',
    }
    this.accountsService.findFsmCustomer(resData).subscribe((res: any) => {
      if (res.success) {
        this.filterDataResut = res.data;
        this.spinner.hide();
        this.customerForm.reset();
       

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
 
  findFsmCustomer(): void {
    this.spinner.show();
    let resData={
      "customerId": this.customerForm.value.customerId ? this.customerForm.value.customerId : '',
      "customerName": this.customerForm.value.customerName ? this.customerForm.value.customerName : '',
    }
    this.accountsService.findFsmCustomer(resData).subscribe((res: any) => {
      if (res.success) {
        this.filterDataResut = res.data;
        this.filterDataResutCustoNew = res.data[0].fsmCustomer.customerId;
        this.readOnlyCode = false;
        this.CustoNewResult = res.data[0].fsmCustomer;
        this.updateFsmCustomerPatch(this.CustoNewResult);
          console.log("custiOID",this.filterDataResutCustoNew);
        this._Router.navigate(['fsm/newWorkOrder'],{ queryParams: { customerIdVal: this.filterDataResutCustoNew} });
        // this._Router.navigate(['fsm/customer'],{ queryParams: { getcustomerId: this.customerForm.value.customerId, getcustomername: this.customerForm.value.customerName} });
        this.spinner.hide();
        localStorage.setItem('FsmCustomerFind', JSON.stringify(this.filterDataResut));
        this.localStoreData();
        this.customerForm.reset();
        setTimeout(() => {
          this.getFsmProblemByCustomerId();
        }, 2000);
       console.log(this.filterDataResut,"norecordfound")
        if(this.filterDataResut==[]){
           this.showNoRecord= true;
        }
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getStateProvId() {
    this.spinner.show();
    this.accountsService.getStateProvId().subscribe(res => {
      this.FSMStateProvId = res.data[0].getStateProvId;
      this.spinner.hide();
      for (const value of this.FSMStateProvId) {
        this.FSMStateProvIdArray.push({
          label: value.geoName,
          value: value.geoId
        })
      }
    })
  }
  getCountryGeoId() {
    this.spinner.show();
    this.accountsService.getCountryGeoId().subscribe(res => {
      this.FSMCountryGeoId = res.data[0].getCountryGeoId;
      this.spinner.hide();
      for (const value of this.FSMCountryGeoId) {
        this.FSMCountryGeoIdArray.push({
          label: value.geoName,
          value: value.geoId
        })
      }
    })
  }
  changeForm(){
    this.show=true;
    this.addNewTechnicianForm.reset();
  }
  deleteFsmTechnician(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "address1": product.data.address1,
          "address2": product.data.address2,
          "city": product.data.city,
          "companyRegistration": product.data.companyRegistration,
          "countryGeoId": product.data.countryGeoId,
          "email": product.data.email,
          "expertiseArea": product.data.expertiseArea,
          "firstName": product.data.firstName,
          "lastName": product.data.lastName,
          "postalCode": product.data.postalCode,
          "socialSecurityNumber":product.data.socialSecurityNumber,
          "stateProvinceGeoId": product.data.stateProvinceGeoId,
          "technicianAvailability": product.data.technicianAvailability,
          "technicianId": product.data.technicianId,
          "technicianName": product.data.technicianName,
          "technicianNumber": product.data.technicianNo,
          "technicianStatus": product.data.technicianStatus,
          "technicianStatusDescription": product.data.technicianStatusDescription,
          "technicianType": product.data.technicianType
        
        }
        this.accountsService.deleteFsmTechnician(req).subscribe((res: any) => {
          if (res.success) {
            this.findFsmTechnician();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  uploadSocialSecurityNumberUpdate(): void {
    this.spinner.show();
      if(this.fileData.name){
        const uploadFile = new FormData();
        uploadFile.append("file", this.fileData);
        this.accountsService.uploadSocialSecurityNumber(uploadFile,this.newTechID).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Security-No Updated Successfully");
            this.spinner.hide();
          }
        }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            }
          }
          else {
            this.toastr.error(err.error.message);
          }
        });
      }
  }
  updateTechnician(): void {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const requestData = {
      "address1": this.addNewTechnicianForm.value.address1,
      "address2": this.addNewTechnicianForm.value.address2,
      "city": this.addNewTechnicianForm.value.city,
      "companyRegistration": this.addNewTechnicianForm.value.companyRegistration,
      "countryGeoId": this.addNewTechnicianForm.value.countryGeoId,
      "email": this.addNewTechnicianForm.value.email,
      "expertiseArea": this.addNewTechnicianForm.value.expertiseArea,
      "firstName": this.addNewTechnicianForm.value.firstName,
      "lastName": this.addNewTechnicianForm.value.lastName,
      "postalCode": this.addNewTechnicianForm.value.postalCode,
      "socialSecurityNumber": '',
      "stateProvinceGeoId": this.addNewTechnicianForm.value.stateProvinceGeoId,
      "technicianAvailability": this.addNewTechnicianForm.value.technicianAvailability,
      "technicianId": this.updateTechnicianPat.technicianId,
      "technicianName": this.addNewTechnicianForm.value.technicianName,
      "technicianNumber": this.addNewTechnicianForm.value.technicianNo,
      "technicianStatus": this.addNewTechnicianForm.value.technicianStatus,
      "technicianStatusDescription": this.addNewTechnicianForm.value.technicianStatusDescription,
      "technicianType": this.addNewTechnicianForm.value.technicianType
    }
    this.accountsService.updateTechnician(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.newTechID = this.updateTechnicianPat.technicianId;
        this.spinner.hide();
        this.findFsmTechnician();
        this.uploadSocialSecurityNumberUpdate();
        this.closebutton.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateTechnicianPatch(product) {
    this.updateTechnicianPat = product.data ;
    this.spinner.show();
    this.show=false;
    setTimeout(() => {
      const formValue = this.addNewTechnicianForm;
      formValue.patchValue({
          technicianNo: this.updateTechnicianPat.technicianNumber,
          technicianType: this.updateTechnicianPat.technicianType,
          technicianName: this.updateTechnicianPat.technicianName,
          technicianAvailability: this.updateTechnicianPat.technicianAvailability,
          firstName: this.updateTechnicianPat.firstName,
          lastName: this.updateTechnicianPat.lastName,
          email: this.updateTechnicianPat.email,
          socialSecurityNumber: '',
          companyRegistration: this.updateTechnicianPat.companyRegistration,
          expertiseArea: this.updateTechnicianPat.expertiseArea,
          address1: this.updateTechnicianPat.address1,
          address2: this.updateTechnicianPat.address2,
          city: this.updateTechnicianPat.city,
          countryGeoId: this.updateTechnicianPat.countryGeoId,
          stateProvinceGeoId: this.updateTechnicianPat.stateProvinceGeoId,
          postalCode: this.updateTechnicianPat.postalCode,
          currentPassword: this.updateTechnicianPat.currentPassword,
          technicianStatus: this.updateTechnicianPat.technicianStatus,
          technicianStatusDescription: this.updateTechnicianPat.technicianStatusDescription
      })

    }, 2000);
    this.spinner.hide();
  }
  getGeoId() {
    this.spinner.show();
    this.myContactsService.getGeoIds().subscribe(res => {
      this.GeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.GeoId) {
        this.GeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  resetFindForm(){
    this.findTechnicianForm.reset();
    this.customerForm.reset();
  }

  findFsmTechnician(): void {
    this.show=false;
    this.spinner.show();
    const requestData = {
      "email": this.findTechnicianForm.value.email ?  this.findTechnicianForm.value.email : '',
      "expertiseArea": this.findTechnicianForm.value.expertiseArea ?  this.findTechnicianForm.value.expertiseArea : '',
      "technicianAvailability": this.findTechnicianForm.value.technicianAvailability ?  this.findTechnicianForm.value.technicianAvailability : '',
      "technicianId": this.findTechnicianForm.value.technicianId ?   this.findTechnicianForm.value.technicianId : '',
      "technicianName": this.findTechnicianForm.value.technicianName ?  this.findTechnicianForm.value.technicianName : '',
    }
    this.accountsService.findFsmTechnician(requestData).subscribe((res: any) => {
      if (res.success) {
        this.filterDataResutTech = res.data;
        this.spinner.hide();
        localStorage.setItem('FsmTechnicianFind', JSON.stringify(this.filterDataResutTech));
        this.localStoreData();
        this.findTechnicianForm.reset();
        

        
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log("file-data_recoed",this.fileData);
    console.log("file-data_recoed",this.fileData.name);

  }
  
  uploadSocialSecurityNumber(): void {
    this.spinner.show();
    if(this.fileData.name){
      const uploadFile = new FormData();
      uploadFile.append("file", this.fileData);
      this.accountsService.uploadSocialSecurityNumber(uploadFile,this.techIDForUpload).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Security Number Uploaded Successfully");
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }

  }
 
  createTechnicianRegister(): void {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const requestData = {
      "address1": this.addNewTechnicianForm.value.address1,
      "address2": this.addNewTechnicianForm.value.address2,
      "city": this.addNewTechnicianForm.value.city,
      "companyRegistration": this.addNewTechnicianForm.value.companyRegistration,
      "countryGeoId": this.addNewTechnicianForm.value.countryGeoId,
      "currentPassword": this.addNewTechnicianForm.value.currentPassword,
      "email": this.addNewTechnicianForm.value.email,
      "expertiseArea": this.addNewTechnicianForm.value.expertiseArea,
      "firstName": this.addNewTechnicianForm.value.firstName,
      "lastName": this.addNewTechnicianForm.value.lastName,
      "postalCode": this.addNewTechnicianForm.value.postalCode,
      "socialSecurityNumber": '',
      "stateProvinceGeoId": this.addNewTechnicianForm.value.stateProvinceGeoId,
      "technicianAvailability": this.addNewTechnicianForm.value.technicianAvailability,
      "technicianId": "",
      "technicianName": this.addNewTechnicianForm.value.technicianName,
      "technicianNumber": this.addNewTechnicianForm.value.technicianNo,
      "technicianStatus": this.addNewTechnicianForm.value.technicianStatus,
      "technicianStatusDescription": this.addNewTechnicianForm.value.technicianStatusDescription,
      "technicianType": this.addNewTechnicianForm.value.technicianType
    }
    this.accountsService.createTechnicianRegister(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.techIDForUpload = res.data.dataValue.technicianId;
        this.findFsmTechnician();
        this.uploadSocialSecurityNumber();
        localStorage.setItem('FsmTechnicianCreate', JSON.stringify(this.addNewTechnicianForm.value));
        localStorage.setItem('technicianId', this.techIDForUpload);
        this.addNewTechnicianForm.reset();
        this.closebutton.nativeElement.click();
        this.localStoreData();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmTechnicalAll() {
    this.spinner.show();
    this.accountsService.getFsmTechnicalAll().subscribe(res => {
      this.FsmTechnicalAll = res.data[0].getFsmTechnicalById;
      this.spinner.hide();
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  changeactiveCategory2(tab: number) {
    this.activeCategory2 = tab;
  }

  changeactiveCategory3(tab: number)
  {
    this.activeCategory3 = tab;
  
  }

  changeactiveCategory4(tab: number)
  {
    this.activeCategory4 = tab;
  
  }

  changeactiveCategoryFunction(tab: number)
  {
    this.activeCategoryPopup = tab;
  
  }

  changeactiveCategoryPopup(tab: number)
  {
    this.activeCategoryPopup = tab;
  
  }



  customerActiveCategoryFunction(tab: number)
  {
    this.customerCategoryPopup = tab;
  
  }
  

  

  

  

  toBack()
  {
    this._Router.navigate(['/fsm/main']);
  }

}
