<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">
                </span>
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="TaskList()"><a>
                                Main </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="TaskList()"><a>
                                Task List
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="Calendar()"><a>
                                Calendar</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="MyTime()"><a>
                                My Time
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="RequestList()"><a>
                                Request List
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="WorkEffort()"><a>
                                Work Effort
                            </a></li>
                        <li [ngClass]="activeCategory==7?'active':''" (click)="Timesheet()"><a>
                                Timesheet
                            </a></li>
                        <li [ngClass]="activeCategory==8?'active':''" (click)="SubmittedJobs()"><a>
                                Submitted Jobs
                            </a></li>
                        <li [ngClass]="activeCategory==9?'active':''" (click)="iCalendar()"><a>
                                iCalendar
                            </a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4 classCard">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                        flex-wrap: wrap !important;
                        display: flex !important;">
                                                <li><a>
                                                        Find Calendar Entries


                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <form [formGroup]="findCalendar">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightFormG">
                                                                <label for="exampleInputEmail1">
                                                                    Calendar Type
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" formControlName="CalendarType"
                                                                    [options]="calendartypeArray" optionlabel="label"
                                                                    placeholder="Choose Calendar Type">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">
                                                                    Party ID
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" formControlName="partyId"
                                                                    [options]="partyIdArray" optionlabel="label"
                                                                    placeholder="Choose Party ID">
                                                                </p-dropdown>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightFormG">
                                                                <label for="exampleInputEmail1">
                                                                    Event Type
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" formControlName="EventType"
                                                                    [options]="EventTypeArray" optionlabel="label"
                                                                    placeholder="Choose Event Type">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">
                                                                    Facility
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" formControlName="FacilityId"
                                                                    [options]="FacilityArray" optionlabel="label"
                                                                    placeholder="Choose Facility">
                                                                </p-dropdown>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightFormG">
                                                                <label for="exampleInputEmail1">
                                                                    Fixed Asset
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" formControlName="FixedAsset"
                                                                    [options]="FixedAssetListArray" optionlabel="label" 
                                                                    placeholder="Choose Fixed Asset">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">
                                                                    Hide events
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="checkbox">
                                                            </div>

                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -30%;">
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn"
                                                (click)="getFindEvents()">View</button>&nbsp;
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger"
                                                (click)="reset()">Reset</button>&nbsp;



                                        </div>

                                    </div>

                                    <div class="w3-card-4 classCard mt-2">
                                        <div class="row text-center d-flex align-items-center" style="padding-top:20px">
                                            <div class="col-md-4 d-flex" style="padding-left:30px">
                                                <div class="btn-group">
                                                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view"
                                                        [(viewDate)]="viewDate"
                                                        (viewDateChange)="closeOpenMonthViewDay()">
                                                        Previous
                                                    </div>
                                                    <div class="btn btn-outline-secondary" mwlCalendarToday
                                                        [(viewDate)]="viewDate">
                                                        Today
                                                    </div>
                                                    <div class="btn btn-primary" mwlCalendarNextView [view]="view"
                                                        [(viewDate)]="viewDate"
                                                        (viewDateChange)="closeOpenMonthViewDay()">
                                                        Next
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <h3 class="mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
                                                </h3>
                                            </div>
                                            <div class="col-md-4 d-flex justify-content-end"
                                                style=" padding-right:40px">
                                                <div class="btn-group">
                                                    <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                                                        [class.active]="view === CalendarView.Month">
                                                        Month
                                                    </div>
                                                    <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                                                        [class.active]="view === CalendarView.Week">
                                                        Week
                                                    </div>
                                                    <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                                                        [class.active]="view === CalendarView.Day">
                                                        Day
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div [ngSwitch]="view">
                                            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                                [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                [activeDayIsOpen]="activeDayIsOpen"
                                                (dayClicked)="dayClicked($event.day)"
                                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                                (eventTimesChanged)="eventTimesChanged($event)">
                                            </mwl-calendar-month-view>
                                            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                                                [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                                (eventTimesChanged)="eventTimesChanged($event)">
                                            </mwl-calendar-week-view>
                                            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                                [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                (eventClicked)="handleEvent('Clicked', $event.event)"
                                                (eventTimesChanged)="eventTimesChanged($event)">
                                            </mwl-calendar-day-view>
                                        </div>

                                        <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

                                        <br /><br /><br />

                                        <h3>
                                            <!-- <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span> -->
                                            <button class="btn btn-primary float-right" *ngIf="isButton"
                                                (click)="addEvent()">
                                                Add new
                                            </button>
                                            <div class="clearfix"></div>
                                        </h3>
                                        <ng-container *ngIf="isForm">
                                            <div class="port-header-contact create-lead font-medium mb-0 w-100">

                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Add/modify a calendar event</p>
                                                        <span>
                                                            <button type="submit" style="margin: 0 !important;"
                                                                *ngIf="isUpdate" (click)="cancelCalenderEvent()"
                                                                class="btn btn-secondary submit-btn mr-2">Cancel a
                                                                calendar event</button>
                                                            <button type="submit" style="margin: 0 !important;"
                                                                *ngIf="isForm" (click)="addNew()"
                                                                class="btn btn-secondary submit-btn">Add New</button>
                                                        </span>
                                                    </div>
                                                    <form [formGroup]="calendarEventForm1">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Event
                                                                            Name</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" formControlName="eventName"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Event Name">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label
                                                                            for="exampleInputEmail1">Desription</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" formControlName="desc"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Desription">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Event Type
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown formControlName="eventType"
                                                                            [options]="EventTypeArray"
                                                                            placeholder="Enter Event Type"
                                                                            filter="true">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown formControlName="status"
                                                                            [options]="CalStatusListArray"
                                                                            placeholder="Enter Status" filter="true">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Scope
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown formControlName="scope"
                                                                            [options]="ScopeListArray"
                                                                            placeholder="Enter Scope" filter="true">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Estimated Start
                                                                            Date</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="date" formControlName="startDate"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Estimated Start Date">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Estimated
                                                                            Completion date</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="date" formControlName="compDate"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Estimated Completion date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-10 main-submit-button">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="submit()">{{isUpdate ? 'Update' :
                                                                'Add'}}</button>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div class="port-header-contact create-lead font-medium mb-0 w-100"
                                                *ngIf="isUpdate">
                                                <div class="w3-card-4 classCard mt-2 w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Participants</p>
                                                        <span>
                                                        </span>
                                                    </div>
                                                    <form [formGroup]="ParticipantsForm">
                                                        <div class="row">

                                                            <div class="col-md-5 ml-4">
                                                                <div class="form-group">
                                                                    <div class="d-flex flex-column">
                                                                        <label for="training">
                                                                            Party ID</label>
                                                                        <p-dropdown [options]="partyIdArray"
                                                                            filter="true" formControlName="PartyId"
                                                                            placeholder=" Party ID"> </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5 ml-4">
                                                                <div class="form-group">
                                                                    <label for="description">Role Type ID <span
                                                                            class="text-danger">*</span></label>
                                                                    <p-dropdown [options]="CalenderRoleArray"
                                                                        filter="true" formControlName="RoleTypeID"
                                                                        placeholder="Role Type ID"> </p-dropdown>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-10">
                                                                <div class="d-flex flex-row-reverse bd-highlight">
                                                                    <button
                                                                        class="btn btn-secondary cursor-pointer ml-2 p-2 bd-highlight"
                                                                        (click)="createMyPortalParticipants()">Add</button>
                                                                    <!-- <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight" (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button> -->
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </form>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="ParticipantsList"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width': '180px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Party ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{ 'width': '180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{ 'width': '180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{ 'width': '180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Remove
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span>{{product.partyId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <span>{{product.roleTypeId}}</span>
                                                                                </td>
                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <span>{{product.statusId}}</span>
                                                                                </td>

                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <span>
                                                                                        <a style="color: #0d3769!important;"
                                                                                            (click)="deleteMyPortalAParticipants(product)"
                                                                                             class="account-button" >Remove</a>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="mt-5 mb-5">
                                               
                                                <ng-container *ngIf="isUpdate">
                                                    <div class="mt-5 mb-5">
                                                        <h4 class="text-black">Participants</h4>
                                                        <hr />
                                                        <form>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="partyId">Party Id</label>
                                                                            <div class="d-flex justify-content-between">
                                                                                <p-dropdown [options]="partyIds" filter="true" [(ngModel)]="partyId" [ngModelOptions]="{standalone:true}" placeholder="Party Id"></p-dropdown>
                                                                                <button class="btn btn-primary cursor-pointer" (click)="assignEvent()">Assign</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </ng-container>
                                            </div> -->
                                        </ng-container>


                                        <!-- <ng-container *ngIf="isUpdate">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Employee Party ID</th>
                                                            <th> Training Request ID</th>
                                                            <th>Training Class Type ID</th>
                                                            <th> Approver Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let event of assignList">
                                                            <td>
                                                                {{event.partyId}}
                                                            </td>
                                                            <td>
                                                                {{event.trainingRequestId}}
                                                            </td>
                                                            <td>
                                                                {{event.trainingClassTypeId}}
                                                            </td>
                                                            <td>{{event.approvalStatus}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="!assignList.length">
                                                            <td>No Data</td>
                                                            <td>No Data</td>
                                                            <td>No Data</td>
                                                            <td>No Data</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-container> -->

                                        <ng-template #modalContent let-close="close">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Event action occurred</h5>
                                                <button type="button" class="close" (click)="close()">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div>
                                                    Action:
                                                    <pre>{{ modalData?.action }}</pre>
                                                </div>
                                                <div>
                                                    Event:
                                                    <pre>{{ modalData?.event | json }}</pre>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-outline-secondary"
                                                    (click)="close()">
                                                    OK
                                                </button>
                                            </div>
                                        </ng-template>
                                    </div>


                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>