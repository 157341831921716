import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'

@Component({
  selector: 'app-my-portal-my-comms',
  templateUrl: './my-portal-my-comms.component.html',
  styleUrls: ['./my-portal-my-comms.component.css']
})
export class MyPortalMyCommsComponent implements OnInit {

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  constructor(readonly router:Router,private form:FormBuilder,private crmaAccountService : CRMAccountService,
    private globalResourceService:GlobalResourceService) { }

  ngOnInit(): void {
    
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
}
