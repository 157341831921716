<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Content Setup</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openPurposeOperation()"><a>
                                    Edit Content Purpose Operation</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openContentOperation()"><a>
                                    Edit Content Operation</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openAssociationPredicate()"><a>
                                    Edit Content Association Predicate</a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="openTypeAttribute()"><a>
                                    Edit Content Type Attribute</a></li>

                            <li [ngClass]="activeCategory==5?'active':''" (click)="openPurposeType()"><a>
                                    Edit Content Purpose Type</a></li>

                            <li [ngClass]="activeCategory==6?'active':''" (click)="openAssociationType()"><a>
                                    Edit Content Association Type</a></li>

                            <li [ngClass]="activeCategory==7?'active':''" (click)="openContentType()"><a>
                                    Edit Content Type</a></li>

                            <li [ngClass]="activeCategory==8?'active':''" (click)="openPermission()"><a>
                                    Permissions</a></li>



                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==8">
                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                Permissions
                                            </div>

                                            <form class="w-100" [formGroup]="permissionForm">

                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-2 form-group classInputAA">
                                                                <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                     formControlName="refresh">
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn">Refresh</button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Website</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group ">

                                                        <button _ngcontent-cta-c128="" type="submit"
                                                            class="btn btn-secondary submit-btn" style="margin-left: -5% !important;
                                                            margin-top: 2% !important;"
                                                            (click)="toCmsSubsites()">Update</button>&nbsp;
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner></ngx-spinner>