import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-comp-doc',
  templateUrl: './comp-doc.component.html',
  styleUrls: ['./comp-doc.component.css']
})
export class CompDocComponent implements OnInit {
  activeCategory:number;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  compDocForm: FormGroup;
  FindCompDocAll: any;
  ViewCompDocTemplateTreeAll: any;
  contentId: any;
  content: any;
  contentRevisionSeqId: any;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  )
  {
    this.compDocForm=this._FormBuilder.group({
      contentID:"",
      contentName:"",
      contentTypeID:"",
    })
  }

  ngOnInit(): void {
    this.FindCompDoc();
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}
  resetForm(){
    this.compDocForm.reset();
  }
  FindCompDoc(): void {
    this.spinner.show();
    const requestData ={
      "contentId": this.compDocForm.value.contentID?this.compDocForm.value.contentID:'',
      "contentName": this.compDocForm.value.contentName?this.compDocForm.value.contentName:'',
      "contentTypeId": this.compDocForm.value.contentTypeID?this.compDocForm.value.contentTypeID:'',
      }
    this.accountsService.FindCompDoc(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.FindCompDocAll = res.data;
        this.compDocForm.reset();
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }
  ViewCompDocTemplateTree() {
    this.spinner.show();
    this.accountsService.ViewCompDocTemplateTree(this.content).subscribe(res => {
      this.ViewCompDocTemplateTreeAll = res.data[0];
      this.contentId=this.ViewCompDocTemplateTreeAll.ListContentRevisions.contentId;
      this.contentRevisionSeqId=this.ViewCompDocTemplateTreeAll.ContentReview[0].contentRevisionSeqId;
      this._Router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId , compDoc: "created"  } });
      this.spinner.hide();
    })
  }
  referToEdit(id){
    this.content=id.dataNodes.contentId;
    this.ViewCompDocTemplateTree();

  }
  ViewCompDocTemplateTreeNew() {
    this.spinner.show();
    this.accountsService.ViewCompDocTemplateTree(this.content).subscribe(res => {
      this.ViewCompDocTemplateTreeAll = res.data[0];
      this.contentId=this.ViewCompDocTemplateTreeAll.ListContentRevisions.contentId;
      this.contentRevisionSeqId=this.ViewCompDocTemplateTreeAll.ContentReview[0].contentRevisionSeqId;

      this._Router.navigate(['/content/compDoc/view-tree'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
      this.spinner.hide();
    })
  }
  referToTree(id){
    this.content=id.dataNodes.contentId;
    this.ViewCompDocTemplateTreeNew();    
  }

  openCreateNewRoot()
  {
    this._Router.navigate(['/content/compDoc/new-root-compdoc-template']);
  }
  openViewWaitingApprovals()
  {
    this._Router.navigate(['/content/compDoc/view-waiting-approvals']);
  }
  openApprovals()
  {
    this.router.navigate(['/content/compDoc/approvals'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }

}
