
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreateOppourtunityModel } from './create-oppourtunity-model'

@Injectable({
    providedIn: 'root'
  })


export class CreateOppourtunityFormService {
    create_oppourtunity_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public createOppourtunityModel: CreateOppourtunityModel
    ) { } 

  prepareOppourtunityForm(data:any): void {
    if (!this.create_oppourtunity_form) {
        this.create_oppourtunity_form = this._formBuilder.group(this.createOppourtunityModel.mapDataToModel(data));
       
    }

  }

}

 

 