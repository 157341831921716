
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NewJobRequistionModel } from './new-job-requistion-model'

@Injectable({
    providedIn: 'root'
  })


export class NewJobRequistionService {
    job_requistion_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    private newJobRequistionModel: NewJobRequistionModel
    ) { } 

  jobRequistionForm(data:any): void {
    if (!this.job_requistion_form) {
        this.job_requistion_form = this._formBuilder.group(this.newJobRequistionModel.mapDataToModel(data));
    }
  }
}

 

 
