<div class="container-fluid main-container-wrapper">
    <br>

    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">General Ledger Setting

            </span>
        </div>
        <div class="w3-card-4 classCard classCardType">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="chartAccount()"><a>
                    Charts of Account</a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="timePeriod()"><a>
                    Custom Time Period</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="cost()"><a>
                        Cost</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="paymentMethod()"><a>
                        Payment Method Type</a></li>
                    <li [ngClass]="activeCategory==5?'active':''" (click)="invoiceMethod()"><a>
                            Invoice Item Type</a></li>
                    <li [ngClass]="activeCategory==6?'active':''" (click)="rate()"><a>
                            Rates</a></li>
                    <li [ngClass]="activeCategory==7?'active':''" (click)="foreignExc()"><a>
                                Foreign Exchange Rates</a></li>
                    <li [ngClass]="activeCategory==8?'active':''" (click)="accountCat()"><a>
                                Gl Account Category</a></li>
                    <li [ngClass]="activeCategory==9?'active':''" (click)="costCenter()"><a>
                                    Cost Center</a></li>

                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">


                    <div class="panel-group">
                        <div *ngIf="activeCategory==2">


                            <div class="panel-collapse">
                                <br>

                                <div class=" bg-white color-grey">

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100">
                                                    <div class="w3-card-4 classCard">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <!--new code-->
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Custom Time Period</button>
                                                            <!--new code end-->
                                                            <!-- <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                                                                        (click)="createTimePeriod()">Create New Custom Time Period</button> -->

                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCard" style="margin: 16px 16px;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Current Custom Time Period
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table" *ngIf="timeParentSetPeriodList.length!=0">
                                                                            <p-table [value]="timeParentSetPeriodList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>

                                                                                                ID
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;"> Parent
                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                            </div>

                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                            <div style="color: white;"> Org Party ID
                                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Period Type
                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Nbr
                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Period Name
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> From Date
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Through Date
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Action
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>


                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span (click)="onPeriodUpdate(product.customTimePeriodId);" class="text-primary cursor-pointer underline">
                                                                                                        {{product.customTimePeriodId}}
                                                                                                    </span>

                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.parent}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                                            {{product.organizationPartyId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            {{product.periodType}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.periodNum}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.periodName}}
                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                        </td>


                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <svg class="hover" (click)="updateTimePeriod(product.customTimePeriodId);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z" transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" (click)="onDelete(product.customTimePeriodId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg>

                                                                                        </td>

                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                        <div class="card own-account-table" *ngIf="timeParentSetPeriodList.length==0">
                                                                            <p>No Current Custom Time Period Selected; "Children" below have no Parent Period.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <div class="w3-card-4 classCard" style="margin: 16px 16px;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Child Periods List
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="timePeriodList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>

                                                                                                ID
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;"> Parent
                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                            </div>

                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                            <div style="color: white;"> Org Party ID
                                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Period Type
                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Nbr
                                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Period Name
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> From Date
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Through Date
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Action
                                                                                                <p-sortIcon field="price"></p-sortIcon>

                                                                                            </div>
                                                                                        </th>


                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span (click)="onPeriodUpdate(product.customTimePeriodId);" class="text-primary cursor-pointer underline">
                                                                                                        {{product.customTimePeriodId}}
                                                                                                    </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.parent}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                                            {{product.organizationPartyId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            {{product.periodType}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.periodNum}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.periodName}}
                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                        </td>


                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <svg class="hover" (click)="updateTimePeriod(product.customTimePeriodId);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z" transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" (click)="onDelete(product.customTimePeriodId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg>
                                                                                            <span><a style="color: #0d3769;cursor: pointer;" (click)="setCurrent(product.customTimePeriodId);">Set As Current</a></span>
                                                                                        </td>

                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1" *ngIf="!show">Add Custom Time Period </span>
                <span class="color-black pl-1" *ngIf="show">Update Custom Time Period</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                            Add Custom Time Period</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                Update Custom Time Period</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTimePeriod">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Parent" filter="true" formControlName="parentPeriodId" [options]="parentPeriodIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Period Type<span
                                                        style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Period Type" filter="true" formControlName="periodTypeId" [options]="periodTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Org Party ID
                                                        <span
                                                        style="color:red">*</span>
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="organizationPartyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Org Party ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Period Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Period Number" formControlName="periodNum">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Period Name	</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Period Name" formControlName="periodName">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Date
                                                        <span
                                                        style="color:red">*</span>
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="fromDate">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date	
                                                        <span
                                                        style="color:red">*</span>
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Period Name" formControlName="thruDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Is Closed</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Is Closed" filter="true" formControlName="isClosed" [options]="closed" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 79%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>