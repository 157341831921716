import { Component, OnInit, OnDestroy } from "@angular/core";
import { HeaderService } from "./../../header/header.service";
import { SortEvent } from "primeng/api";
import { Router } from "@angular/router";
import { AccountsService } from "../../crm/accounts/accounts.service";
import { DropdownModule } from "primeng/dropdown";
import { OrderManagementService } from '../order-management.service';
import { of } from 'rxjs';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { ToastrService } from "ngx-toastr";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { GlobalResourceService } from "src/app/services/global-resource.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import Swal from 'sweetalert2'
import { MainCatalogService } from "src/app/catalog-mgmt/main-catalog-mgmt/catalog.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-purchase-order',
  templateUrl: './create-purchase-order.component.html',
  styleUrls: ['./create-purchase-order.component.css']
})
export class CreatePurchaseOrderComponent implements OnInit {
  checkOutPaymentId: string;
  activeCategoryValueMain=3;
  purchaseForm: FormGroup;
  activeCategoryOptionSetting=1;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  orderItems: any[];
  totalAmount: any;
  activeCategoryParty=1;
  promotions: any[];
  itemTypes: any[];
  paymentMethodTypes: any[];
  productStoresListArray: any = [];
  billingAccount: any[];
  salesOrderListArray: any = [];
  userLoginArray: any = [];
  customerArray: any = [];
  supplierArray: any = [];
  createOrderSales: any = {};
  createOrderPurchase: any = {};
  organisationsArray: any = [];
  paymentMethod: string;
 
  activeCategorySalesOrder=1;
  activeCategorySales=2
  activeCategoryPurchase=2;
  supplierList: any[];
  agreementList: any[];
  addressList: any[];
  faEdit = faEdit;
  faTrash = faTrash;
  orderTermType: any[];
  orderTermForm: FormGroup;
  editMode: boolean;
  isSearchPage: boolean;
  orderTerm: any;
  orderTerms: any[];
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  isPurchase: boolean;
  isSales: boolean;
  products3: any;
  activeCategoryTerm=1;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  activeCategoryValue=2;
  partyIds: any[];
  carrierShipments: any[];
  shipmentOption: string;
  notes: {
    internalNote: string,
    shippingNote: string
  };
  shipmentCustom: {
    shipDateBefore: string,
    shipDateAfter: string,
    specialInstruction: string,
    isGift: boolean,
    giftMessage: string,
    shipAtOnce: boolean
  };
 
  isParty: boolean;
  isRole: boolean;
  additionalPartyForm: {
    additionalPartyId: string,
    additionalRoleTypeId: string
  };
  roleValue = [
    {
      label: 'EMPLOYEE',
      value: 'EMPLOYEE'
    },
    {
      label: 'Not Applicable',
      value: '_NA_'
    }];
  shippingEstimate: number
 
  shippingAddress: any;
  partyIdValue: string;
  
  poContinue: any = [];
  currencyArray: any = [];
  catalogueArray: any = [];
  workEffortArray: any = [];
  activeCategoryOrderTab=1;
  products: any[];
  show: boolean;
  showbutton: boolean=false;
  activeCategoryShip=1;
  activeCategoryComfirm=1;
  constructor(  readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly _MainCatalogService: MainCatalogService,
    readonly HeaderService: HeaderService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,private ngWizardService: NgWizardService,
    readonly _OrderManagementService: OrderManagementService,
    readonly _GlobalResourceService: GlobalResourceService,
    private _location: Location,
    ) { 
      this.isParty = false;
      this.isRole = false;
      this.partyIds = [];
      this.additionalPartyForm = {
        additionalPartyId: '',
        additionalRoleTypeId: ''
      };
      this.notes = {
        internalNote: '',
        shippingNote: ''
      };
      this.carrierShipments = [];
      this.shipmentOption = '';
      this.shipmentCustom = {
        shipDateBefore: '',
        shipDateAfter: '',
        specialInstruction: '',
        isGift: false,
        giftMessage: '',
        shipAtOnce: false
      };
      this.shippingEstimate = 0;
      this.orderTermType = [];
      this.partyIds = [];
      this.partyIdValue = '';
    this.orderTermForm = this._FormBuilder.group({
      description: [''],
      termDays: [''],
      termIndex: [''],
      termTypeId: [''],
      termValue: [''],
      textValue: ['']
    });
    this.editMode = false;
    this.isSearchPage = false;
    this.orderTerms = [];
      this.createOrderPurchase = {
        orderMode: "PURCHASE_ORDER",
        billToCustomerPartyId: "Company",
        userLoginId: "",
        supplierPartyId: "",
      };
      this.poContinue = {
        hasAgreements: "N",
        orderName: "",
        correspondingPoId: "",
        currencyUomId: "",
        CURRENT_CATALOG_ID: "",
        workEffortId: "",
        shipAfterDate: "",
        shipBeforeDate: "",
        reserveAfterDate: "",
        itemtype: ""
      };
      this.itemTypes = [];
      this.products = [];
      this.purchaseForm = this._FormBuilder.group({
        productId: [''],
        quantity: [''],
        itemDesiredDeliveryDate: [''],
        useAsDefaultDesiredDeliveryDate: [true],
        shipAfterDate: [''],
        shipBeforeDate: [''],
        reserveAfterDate: [''],
        itemComment: [''],
        useAsDefaultComment: [true]
      });
      this.orderItems = [];
      this.promotions = [];
    }
    offerShowingLineColoumns = [
      { field: 'Destination', header: 'Destination' },
      { field: 'Supplier', header: 'Supplier' },
      { field: 'ShipmentMethod', header: 'Shipment Method' },
      { field: 'Items', header: 'Items' },
      { field: 'Quantity', header: 'Quantity' },
    ];
  
    offerShowingLineData = [{
      status: 'Mr. THE PRIVILEGED ADMINISTRATOR',
      name: 'Sector A Officers  Hill Colony   Kohima , AA  797001',
      email: 'Test',
      phone: 'USPS Express	',
      type: '0',
      amount: 'GZ-1000 -	',
      edit: '1',
    }];
    orderItemsColumns = [
      { field: 'status', header: 'Product' },
      { field: 'name', header: 'Quantity' },
      { field: 'email', header: 'Unit Price' },
      { field: 'phone', header: 'Adjustments' },
      { field: 'type', header: 'Sub Total' },
    ];
  
    orderItemsData = [{
      status: 'GZ-1000 - Tiny Gizmo',
      name: '1',
      email: 'US$15.99',
      phone: 'US$0.00',
      type: 'US$15.99  ',
    }];
    reviewOrderValue: any;
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails();



    if (!window.location.pathname.includes('purchase') && !window.location.pathname.includes('sales')) {
      this.isPurchase = true;
      this.isSales = true;
    } else {
      this.isSales = window.location.pathname.includes('sales');
      this.isPurchase = window.location.pathname.includes('purchase');
    }
    this.getOrderListItems();
    this.getSalesChannelList();
   // this.setBilling();
    
    this.getUserLoginId();
    this.getCustomer("CUSTOMER");
    this.getCustomer("SUPPLIER");
    this.getOrganistaionList();
   
    
   
  
 
   // this.reviewOrder();
   

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/order/main-order']);
  }

  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
  orderTabRefresh(){
    this.activeCategoryValue=2;
  }
  getSalesChannelList(): void {
    this.spinner.show();
    this.accountsService.getSalesOrderList().subscribe((res) => {
      let salesOrderList = res.data;
      this.spinner.hide();
      for (const value of salesOrderList) {
        this.salesOrderListArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
    
  }

  getUserLoginId(): void {
    this.spinner.show();
    this.accountsService.getUserLogin().subscribe((res) => {
      let userLogin = res.data;
      this.spinner.hide();
      for (const value of userLogin) {
        if (value["groupName"] === null) {
          value["groupName"] = "";
        }
        if (value["firstName"] === null) {
          value["firstName"] = "";
        }
        if (value["middleName"] === null) {
          value["middleName"] = "";
        }
        if (value["lastName"] === null) {
          value["lastName"] = "";
        }
        let name =
          value["userLoginId"] +
          " " +
          value["groupName"] +
          " [" +
          value["firstName"] +
          " " +
          value["middleName"] +
          " " +
          value["lastName"] +
          "]";
        this.userLoginArray.push({
          label: name,
          value: value.userLoginId,
        });
      }
    });
   
  }

  getCustomer(role): void {
    this.spinner.show();
    let roleTypeId = role;
    this.accountsService.getCustomerList(roleTypeId).subscribe((res) => {
      let customerList = res.data;
      this.spinner.hide();
      for (const value of customerList) {
        if (value["groupName"] === null) {
          value["groupName"] = "";
        }
        if (value["firstName"] === null) {
          value["firstName"] = "";
        }
        if (value["middleName"] === null) {
          value["middleName"] = "";
        }
        if (value["lastName"] === null) {
          value["lastName"] = "";
        }
        let name =
          value["partyId"] +
          " " +
          value["groupName"] +
          " [" +
          value["firstName"] +
          " " +
          value["middleName"] +
          " " +
          value["lastName"] +
          "]";

        if (role === "CUSTOMER") {
          this.customerArray.push({
            label: name,
            value: value.partyId,
          });
        }
        if (role === "SUPPLIER") {
          this.supplierArray.push({
            label: name,
            value: value.partyId,
          });
        }
      }
    });
   
  }


 
  continuePurchaseOrder(): void {
    this.spinner.show();
    this.showbutton=true;
    this.accountsService.setPurchaseOrderData(this.createOrderPurchase);
    this.spinner.hide();
    this.activeCategoryValue=2
    this.getCurrency();
    this.getCatalogue();
    this.getWorkEffortList();
   // this.router.navigate(["/order/purchase-continue"]);
  }

  getOrganistaionList(): void {
    this.spinner.show();
    this.accountsService.getOrganisationList().subscribe((res) => {
      let organisationsList = res.data.internalOrganizations;
      this.spinner.hide();
      for (const value of organisationsList) {
        this.organisationsArray.push({
          label: value.partyId + '-' + value.groupName,
          value: value.partyId,
        });
      }
    });
   
  }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }
 
  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }
 
  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }
 
  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }
 
  stepChanged(args: StepChangedArgs) {
  
  }
 
  isValidTypeBoolean: boolean = true;
 
  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }
 
  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }
  
  getCurrency(): void {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe((res) => {
      let currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
    });    
  }

  getCatalogue(): void {
    this.spinner.show();
    let data = {
      catalogName: "",
      catalogNameSearchType: "Contains",
      prodCatalogId: "",
      prodCatalogIdSearchType: "Contains",
    };
    this.accountsService.getCatalogue(data).subscribe((res) => {
      let catalogue = res.data;
      this.spinner.hide();
      for (const value of catalogue) {
        this.catalogueArray.push({
          label: value.catalogName + " [" + value.prodCatalogId + "]",
          value: value.prodCatalogId,
        });
      }
    });
   
  }

  getWorkEffortList(): void {
    this.spinner.show();
    this.accountsService.getWorkEfforts().subscribe((res) => {
      let workEfforts = res.data;
      this.spinner.hide();
      for (const value of workEfforts) {
        this.workEffortArray.push({
          label: value.workEffortName + " [" + value.workEffortId + "]",
          value: value.workEffortId,
        });
      }
    });
    
  }


  continuePurchaseOrderCreation(): void {
    this.spinner.show();
    const dataPrevious = this.accountsService.getPurchaseOrderData();
    const formData = this.poContinue;
    const mergedData = {
      ...formData, ...dataPrevious, ...{
        productStoreId: '9000'
      }
    };
    mergedData.reserveAfterDate = mergedData.reserveAfterDate ? moment(mergedData.reserveAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      mergedData.shipAfterDate = mergedData.shipAfterDate ? moment(mergedData.shipAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      mergedData.shipBeforeDate = mergedData.shipBeforeDate ? moment(mergedData.shipBeforeDate).format('YYYY-MM-DD HH:mm:ss') : '',

      this.accountsService.initorderentry(mergedData).subscribe((res) => {
        this.spinner.hide();
        this.getProducts();
        this.getPromotions();
        this.getItemType();
        this.activeCategoryValueMain=3;
       // this.router.navigate(["/order/create-purchase-continue"]);
      });
      this.spinner.hide();
  }
  changeactiveCategoryFunctionOrderTab(tab: number) {
    this.activeCategoryOrderTab = tab;
  }
  getPromotions(): void {
    this.spinner.show();
    this._OrderManagementService.getAllPromotion()
      .then(data => {
        this.spinner.hide();
        this.promotions = data.data;
      });
      
  }
  getItemType(): void {
    this.spinner.show();
    this._OrderManagementService.getItemType()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.itemTypes = data.data.purchaseOrderItemTypes.map(value => {
            return {
              label: value.parentTypeId,
              value: value.orderItemTypeId
            };
          });
        }
      }); 
     
  }
  getProducts(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, {
      internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains'
    })
      .then(data => {
        this.spinner.hide();
        this.products = data.data.map(value => {
          return {
            label: value.productId,
            value: value.productId
          };
        });
      });
     
  }
  placeOrder(): void {
    this.spinner.show();
    this._OrderManagementService.addItems({
      ...this.purchaseForm.value, ...{
        itemDesiredDeliveryDate: this.purchaseForm.value.itemDesiredDeliveryDate ? moment(this.purchaseForm.value.itemDesiredDeliveryDate).format('YYYY-MM-DD HH:mm:ss') : '',
        shipAfterDate: this.purchaseForm.value.shipAfterDate ? moment(this.purchaseForm.value.shipAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
        shipBeforeDate: this.purchaseForm.value.shipBeforeDate ? moment(this.purchaseForm.value.shipBeforeDate).format('YYYY-MM-DD HH:mm:ss') : '',
        reserveAfterDate: this.purchaseForm.value.reserveAfterDate ? moment(this.purchaseForm.value.reserveAfterDate).format('YYYY-MM-DD HH:mm:ss') : '',
      }
    })
      .then(data => {
        if (data.success) {
          if (data.data.errorMessage) {
            this._ToastrService.error(data.data.errorMessage);
          } else {
            this._ToastrService.success('Order added successfully');
            this.spinner.hide();
          }
          this.totalAmount = data.data.orderItems[data.data.orderItems.length - 1];
          for (var i = 0; i <= (data.data.orderItems.length - 2); i++) {
            this.orderItems.push(data.data.orderItems[i]);
          }
          this.purchaseForm.reset();
        }
      });
      this.spinner.hide();
  }


  clearOrder(): void {
    this.spinner.show();
    this._OrderManagementService.clearOrder()
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Order Cleared');
          this.spinner.hide();
          this.activeCategoryValue=2;
          this.orderItems=[];
          this.activeCategoryValueMain=3;
          this.createOrderPurchase = {
            orderMode: "PURCHASE_ORDER",
            billToCustomerPartyId: "Company",
            userLoginId: "",
            supplierPartyId: "",
          };
          this.poContinue = {
            hasAgreements: "N",
            orderName: "",
            correspondingPoId: "",
            currencyUomId: "",
            CURRENT_CATALOG_ID: "",
            workEffortId: "",
            shipAfterDate: "",
            shipBeforeDate: "",
            reserveAfterDate: "",
            itemtype: ""
          };
        //  this.router.navigate(['/order/create-purchase-order']);
        }
      });
      this.spinner.hide();
  }
  changeactiveCategoryFunctionConfirm(tab: number) {
    this.activeCategoryComfirm = tab;
  }
  finalizeOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.finalizeOrderWithDynamicBody({
        finalizeMode: 'init'
      })
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Order finalization initiated');
            this.spinner.hide();
            switch (data.data.responseMessage) {
              case 'shipping': this.activeCategoryValueMain=4;
              this.createNewShipGroup();
              this.getPartyIds();
                break;
              case 'options': this.activeCategoryValueMain=5;
              this.getShippingMethod();
                break;
              case 'term': this.activeCategoryValueMain=6;
              this.getOrderTermType();
             this.getOrderTerms();
                break;
              case 'addparty': this.activeCategoryValue=8;
              this.getPartyIds();
                break;
              case 'purchase': this.activeCategoryValue=9;
              this.reviewOrder();
                break;
            }
          }
        });
    } else {
      this.spinner.hide();
      this._ToastrService.error('Please add product');
    }
   
  }
  recalculateOrder(): void {
    this.spinner.show();
    if (this.orderItems.length) {
      this._OrderManagementService.recalculateOrder(
        this.orderItems.map(value => {
          return {
            description: value.description,
            gwall: '',
            price: value.itemTotal,
            shipAfterDate: '',
            shipBeforeDate: '',
            updateQty: value.quantity
          };
        }))
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Recalculated');
          }
        });
    }
    this.spinner.hide();
  }
  getOrderTermType(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTermType()
      .then(data => {
        this.spinner.hide();
        this.orderTermType = data.data.map(value => {
          return {
            label: value.description,
            value: value.termTypeId
          };
        });
      })
      
  }

  getOrderTerms(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTerms()
      .then(data => {
        if (data.data) {
          this.spinner.hide();
          this.orderTerms = data.data.orderTerms;
          this.isSearchPage = true;
        }
      });
     
  }
  deleteOrderTerm(termIndex: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
          
    this._OrderManagementService.removeCart(termIndex)
    .then(data => {
      if (data.success) {
       
        this.getOrderTerms();
      }
    });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
  
  }
  makeOrderTermEditable(termIndex: string): void {
    this.spinner.show();
    this._OrderManagementService.getOrderTermByIndex(termIndex)
      .then(data => {
        this.orderTerm = data.data;
        this.editMode = true;
      });
      this.spinner.hide();
  }
  update(): void {
    this.spinner.show();
    this._OrderManagementService.createOrderTerm({
      ...this.orderTerm, ...this.orderTermForm.value
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Order item updated successfully');
          this.spinner.hide();
          this.orderTermForm.reset();
          this.getOrderTerms();
          this.isSearchPage = true;
          this.editMode = false;
        }
      });
      this.spinner.hide();
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      this._OrderManagementService.createOrderTerm(this.orderTermForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Order item created successfully');
            this.spinner.hide();
            this.orderTermForm.reset();
            this.getOrderTerms();
            this.isSearchPage = true;
          }
        });
    }
    this.spinner.hide();
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategoryShiping(tab: number) {
    this.activeCategoryShip = tab;
  }
  navigateToPayment(): void {
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeMode: 'term'
    })
      .then(data => {
        if (data.success) {
         
          switch (data.data.responseMessage) {
            case 'shipping': this.activeCategoryValueMain=4;
              this.createNewShipGroup();
              this.getPartyIds();
                break;
              case 'options': this.activeCategoryValueMain=5;
              this.getShippingMethod();
                break;
              case 'term': this.activeCategoryValueMain=6;
              this.getOrderTermType();
             this.getOrderTerms();
                break;
              case 'addparty': this.activeCategoryValue=8;
              this.getPartyIds();
                break;
              case 'purchase': this.activeCategoryValue=9;
              this.reviewOrder();
                break;
          }
        }
      });
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  createNewShipGroup(): void {
    this.spinner.show();
    this._OrderManagementService.createNewShipGroup('N')
      .then(data => {
        this.spinner.hide();
        this.agreementList = data.data.agreementList.map(value => {
          return {
            label: value.description,
            value: value.agreementId
          };
        });
        this.supplierList = data.data.suppliersList.map(value => {
          return {
            label: value.partyName,
            value: value.partyId
          };
        });
        this.addressList = data.data.listPOShipGrp;
      });
      
  }
  setShipping(event: any, geoId: string, facilityId: string): void {
   
    if (event) {
      this.shippingAddress = `${geoId}_@_${facilityId.split('[')[1].split(']')[0].trim()}`;
    } else {
      this.shippingAddress = '';
    }
  }
  finalizeOrderShip(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeMode: 'ship',
      shipToPartyId: '',
      '0_supplierPartyId': '',
      '0_supplierAgreementId': '',
      '0_shipGroupFacilityId': '',
      '0_shipping_contact_mech_id': this.shippingAddress
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          switch (data.data.responseMessage) {
            case 'shipping': this.activeCategoryValueMain=4;
            this.createNewShipGroup();
            this.getPartyIds();
              break;
            case 'options': this.activeCategoryValueMain=5;
            this.getShippingMethod();
              break;
            case 'term': this.activeCategoryValueMain=6;
            this.getOrderTermType();
           this.getOrderTerms();
              break;
            case 'addparty': this.activeCategoryValue=8;
            this.getPartyIds();
              break;
            case 'purchase': this.activeCategoryValue=9;
            this.reviewOrder();
              break;
          }
      
        }
      });
      this.spinner.hide();
  }
  getShippingMethod(): void {
    this.spinner.show();
    this._OrderManagementService.getOrderShippingMethod()
      .then(data => {
        this.spinner.hide();
         this.carrierShipments = data.data.carrierShipmentMethodList });
     
  }




  finalizeOrderOption(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      'internal_order_notes': this.notes.internalNote,
      'shippingNotes': this.notes.shippingNote,
      'finalizeMode': 'options',
      '0_shipping_method': 'STANDARD@_NA_',
      '0_may_split': this.shipmentCustom.shipAtOnce,
      'sgi0_shipBeforeDate_i18n': this.shipmentCustom.shipDateBefore ? moment(this.shipmentCustom.shipDateBefore).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipBeforeDate': this.shipmentCustom.shipDateBefore ? moment(this.shipmentCustom.shipDateBefore).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipAfterDate_i18n': this.shipmentCustom.shipDateAfter ? moment(this.shipmentCustom.shipDateAfter).format('YYYY-MM-DD HH:mm:ss') : '',
      'sgi0_shipAfterDate': this.shipmentCustom.shipDateAfter ? moment(this.shipmentCustom.shipDateAfter).format('YYYY-MM-DD HH:mm:ss') : '',
      '0_shipping_instructions': this.shipmentCustom.specialInstruction,
      '0_is_gift': this.shipmentCustom.isGift,
      '0_gift_message': this.shipmentCustom.giftMessage,
      '0_ship_estimate': this.shippingEstimate
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          switch (data.data.responseMessage) {
            case 'shipping': this.activeCategoryValueMain=4;
              this.createNewShipGroup();
              this.getPartyIds();
                break;
              case 'options': this.activeCategoryValueMain=5;
              this.getShippingMethod();
                break;
              case 'term': this.activeCategoryValueMain=6;
              this.getOrderTermType();
             this.getOrderTerms();
                break;
              case 'addparty': this.activeCategoryValue=8;
              this.getPartyIds();
                break;
              case 'purchase': this.activeCategoryValue=9;
              this.reviewOrder();
                break;
          }
       
        }
      });
      this.spinner.hide();
  }
  setOption(event: any, partyId: string, shippingEstimate: number) {
    this.shipmentOption = `${event}@${partyId}`;
    this.shippingEstimate = shippingEstimate;
  }

  finalizeOrderParty(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeReqAdditionalParty: false,
      finalizeMode: 'addpty'
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.activeCategoryValue=9;
          this.reviewOrder();
          //this.router.navigate(['/order/purchase-order-confirmation']);
        }
      });
      this.spinner.hide();
  }
  setPartyId(event: any): void {
    this.isParty = event;
    this.isRole = event;
  }

 
  addAdditionalParty(): void {
    this.spinner.show();
    this._OrderManagementService.addRemoveAdditionalParty(true, {
      additionalPartyId: this.additionalPartyForm.additionalPartyId,
      additionalRoleTypeId: [this.additionalPartyForm.additionalRoleTypeId]
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
        }
      });
      this.spinner.hide();
  }
  removeAdditionParty(additionalPartyId: string, additionalRoleTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._OrderManagementService.addRemoveAdditionalParty(false, {
          additionalPartyId, additionalRoleTypeId: [additionalRoleTypeId]
        })
          .then(data => {
            if (data.success) {
             
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
     
  }
  reviewOrder(): void {
    this.spinner.show();
    this._OrderManagementService.reviewOrder()
      .then(data => {
        this.spinner.hide();
        this.reviewOrderValue = data.data;
      });
      
  }
 
  processOrder(): void {
    this.spinner.show();
    this._OrderManagementService.processOrder()
      .then(data => {
        if (data.success) {
          if (data.data.errorMessage) {
            this._ToastrService.error(data.data.errorMessage);
          } else {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.router.navigate(['/order/purchase-order-view-page'], { queryParams: { orderId: data.data.orderId } });
          }
        }
      });
      this.spinner.hide();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
