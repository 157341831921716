<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Tax Authority Party Info



 
                </span>
                <span class="color-black pl-1" > >Add Tax Authority Party Info



 
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                        Add Tax Authority Party Info
 
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                        Update Tax Authority Party Info

                    </a></li>

                   
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createProduct">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputNGF">
                                                <label for="exampleInputEmail1">
                                        
                                                    Party ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2" *ngIf="!this.show">
                                                <p-dropdown  placeholder="Enter Party ID" filter="true"
                                            formControlName="PartyID" 
                                            [options]="partyIdArray" optionlabel="label"   >
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-2" *ngIf="this.show">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="PartyID" aria-describedby="emailHelp"
                                                placeholder="Enter Tax Percentage" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Date
                                                </label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="!this.show">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                    formControlName="fromDate" aria-describedby="emailHelp"
                                                    placeholder="Enter Tax Percentage">
                                            </div>
                                            <div class="col-lg-2" *ngIf="this.show">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                    formControlName="fromDate" aria-describedby="emailHelp"
                                                    placeholder="Enter Tax Percentage" readonly>
                                            </div>
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputNGF">
                                                <label for="exampleInputEmail1">Through Date
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                formControlName="throughDate" aria-describedby="emailHelp"
                                                placeholder="Enter Tax Percentage">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Party Tax ID
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="PartyTaxID" aria-describedby="emailHelp"
                                                placeholder="Enter Party Tax ID">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputNGF">
                                                <label for="exampleInputEmail1">
                                             
                                                    Is Exempt<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Is Exempt" filter="true"
                                            formControlName="IsExempt" 
                                            [options]="promotion" optionlabel="label"    >
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                             
                                                    Is Nexus<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Is Nexus" filter="true"
                                                formControlName="IsNexus" 
                                                [options]="promotion" optionlabel="label"    >
                                                                
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>

                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 61%;">
                               
                                <button type="submit" (click)="onSubmit();" *ngIf="!this.show"  class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" (click)="onUpdate();" *ngIf="this.show"  class="btn btn-secondary submit-btn">Update</button>
                             
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
