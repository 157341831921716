import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { format } from "highcharts";
import { Observable } from "rxjs";
import { URLS } from "../app.constant";

@Injectable({
  providedIn: 'root'
})
export class ProductionService {

  constructor(readonly http: HttpClient) { }
  getstatusByType(id): Observable<any> {
    const getstatusByType = URLS.getstatusByType + "?statusTypes=" + id;
    return this.http.get(getstatusByType);
  }
  getActualMaterial(id): Observable<any> {
    const getActualMaterial = URLS.getActualMaterial + "?productionRunId=" + id;
    return this.http.get(getActualMaterial);
  }
  
  getRoutingList(data,req): Observable<any> {
    const getRoutingList = URLS.getRoutingList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getRoutingList,req);
  }
  getRoutingListById(id): Observable<any> {
    const getRoutingListById = URLS.getRoutingListById + '/' + id;
    return this.http.get(getRoutingListById);
  }
  postproductLink(id,req): Observable<any> {
    const postproductLink = URLS.postproductLink + '/' + id;
    return this.http.post(postproductLink,req);
  }
  productionRunTaskProduce(req): Observable<any> {
    const productionRunTaskProduce = URLS.productionRunTaskProduce;
    return this.http.post(productionRunTaskProduce,req);
  }
  getProductionRunTaskProduce(id): Observable<any> {
    const productionRunTaskProduce = URLS.productionRunTaskProduce + "?workEffortId=" + id;
    return this.http.get(productionRunTaskProduce);
  }
  updateproductLink(id,req): Observable<any> {
    const postproductLink = URLS.postproductLink + '/' + id;
    return this.http.put(postproductLink,req);
  }
  postRouting(req): Observable<any> {
    const postRouting = URLS.postRouting;
    return this.http.post(postRouting,req);
  }
  updateRouting(req): Observable<any> {
    const postRouting = URLS.postRouting;
    return this.http.put(postRouting,req);
  }
  removeRoutingTaskAssoc(req): Observable<any> {
    const removeRoutingTaskAssoc = URLS.removeRoutingTaskAssoc;
    return this.http.post(removeRoutingTaskAssoc,req);
  }
  
  postaddExistingRoutingTask(id,req): Observable<any> {
    const postaddExistingRoutingTask = URLS.postaddExistingRoutingTask + '/' + id;
    return this.http.post(postaddExistingRoutingTask,req);
  }
  postProductiontasks(id,req): Observable<any> {
    const postProductiontasks = URLS.postProductiontasks + '/' + id;
    return this.http.post(postProductiontasks,req);
  }
  updateProductiontasks(id,req): Observable<any> {
    const postProductiontasks = URLS.postProductiontasks + '/' + id;
    return this.http.put(postProductiontasks,req);
  }
  
  mrpReport(id): Observable<any> {
    const mrpReport = URLS.mrpReport + "?mrpName=" + id;
    return this.http.get(mrpReport);
  }
  postProductionRun(req): Observable<any> {
    const postProductionRun = URLS.postProductionRun;
    return this.http.post(postProductionRun,req);
  }
  
  createProductionRunForShipment(req): Observable<any> {
    const createProductionRunForShipment = URLS.createProductionRunForShipment;
    return this.http.post(createProductionRunForShipment,req);
  }
  
  submitUnusedMaterials(req): Observable<any> {
    const submitUnusedMaterials = URLS.submitUnusedMaterials;
    return this.http.post(submitUnusedMaterials,req);
  }
  updateProductionRun(req): Observable<any> {
    const postProductionRun = URLS.postProductionRun;
    return this.http.put(postProductionRun,req);
  }
  
  getProductionPDF(id): Observable<any> {
    const getProductionPDF = URLS.getProductionPDF + "?productionRunId=" + id;
    return this.http.get(getProductionPDF, { responseType: "blob" });
  }
  
  packageLabelPDF(id): Observable<any> {
    const packageLabelPDF = URLS.packageLabelPDF + "?shipmentId=" + id;
    return this.http.get(packageLabelPDF, { responseType: "blob" });
  }

  shipmentPlanStockPDF(id): Observable<any> {
    const shipmentPlanStockPDF = URLS.shipmentPlanStockPDF + "?shipmentId=" + id;
    return this.http.get(shipmentPlanStockPDF, { responseType: "blob" });
  }
  
  gettaskAssoc(id,data): Observable<any> {
    const gettaskAssoc = URLS.gettaskAssoc + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(gettaskAssoc);
  }

  getProductiontasks(id,data): Observable<any> {
    const getProductiontasks = URLS.getProductiontasks + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProductiontasks);
  }
  deleteProductiontasks(id,workEffortId): Observable<any> {
    const getProductiontasks = URLS.getProductiontasks + '/' + id+ "?workEffortId=" + workEffortId;
    return this.http.delete(getProductiontasks);
  }
  getProductiontasksEditById(id,routingTaskId): Observable<any> {
    const getProductiontasksEditById = URLS.getProductiontasksEditById +  "?productionRunId=" + id + "&routingTaskId=" + routingTaskId;
    return this.http.get(getProductiontasksEditById);
  }
  startProductionRunTask(id,workEffortId): Observable<any> {
    const startProductionRunTask = URLS.startProductionRunTask +  "?productionRunId=" + id + "&workEffortId=" + workEffortId;
    return this.http.post(startProductionRunTask,'');
  }
  declareProductProduceQty(id,workEffortId): Observable<any> {
    const declareProductProduceQty = URLS.declareProductProduceQty +  "?productionRunId=" + id + "&workEffortId=" + workEffortId;
    return this.http.get(declareProductProduceQty);
  }
  
  issueProductionRunRoutingTask(id,workEffortId,failIfItemsAreNotAvailable): Observable<any> {
    const issueProductionRunRoutingTask = URLS.issueProductionRunRoutingTask +  "?productionRunId=" + id + "&workEffortId=" + workEffortId + "&failIfItemsAreNotAvailable=" + failIfItemsAreNotAvailable;
    return this.http.post(issueProductionRunRoutingTask,'');
  }
  postDeclareProductProduceQty(data): Observable<any> {
    const declareProductProduceQty = URLS.declareProductProduceQty;
    return this.http.post(declareProductProduceQty,data);
  }
  
  
  
  getProductionRunDetail(id): Observable<any> {
    const getProductionRunDetail = URLS.getProductionRunDetail +  "?productionRunId=" + id;
    return this.http.get(getProductionRunDetail);
  }
  
  getProductionMaterialRoutingTaskIds(id): Observable<any> {
    const getProductionMaterialRoutingTaskIds = URLS.getProductionMaterialRoutingTaskIds +  "?productionRunId=" + id;
    return this.http.get(getProductionMaterialRoutingTaskIds);
  }
  
  
  
  getproductLink(id,data): Observable<any> {
    const getproductLink = URLS.getproductLink + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getproductLink);
  }
  getProductionMaterial(id,data): Observable<any> {
    const getProductionMaterial = URLS.getProductionMaterial + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProductionMaterial);
  }
  getProductionFixedAsset(id,data): Observable<any> {
    const getProductionFixedAsset = URLS.getProductionFixedAsset + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProductionFixedAsset);
  }
  getProductionParty(id,data): Observable<any> {
    const getProductionParty = URLS.getProductionParty + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProductionParty);
  }
  getProductionJobassoc(id,data): Observable<any> {
    const getProductionJobassoc = URLS.getProductionJobassoc + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProductionJobassoc);
  }
  getProductionJobActualCosts(id,data): Observable<any> {
    const getProductionJobActualCosts = URLS.getProductionJobActualCosts + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&productionRunId=" + id;
    return this.http.get(getProductionJobActualCosts);
  }
  getRoutingTaskCostData(id,data): Observable<any> {
    const postRoutingTaskCost = URLS.postRoutingTaskCost + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(postRoutingTaskCost);
  }
  getRoutingTaskDelievarbleProduct(id,data): Observable<any> {
    const getRoutingTaskDelievarbleProduct = URLS.getRoutingTaskDelievarbleProduct + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getRoutingTaskDelievarbleProduct);
  }
  
  getProdfixedAsset(id,data): Observable<any> {
    const getProdfixedAsset = URLS.getProdfixedAsset + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProdfixedAsset);
  }
  getProdSkill(id,data): Observable<any> {
    const getProdSkill = URLS.getProdSkill + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProdSkill);
  }
  getExceptionDay(id,data): Observable<any> {
    const getExceptionDay = URLS.getExceptionDay + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getExceptionDay);
  }
  deleteExceptionDay(id,time): Observable<any> {
    const getExceptionDay = URLS.getExceptionDay + '/' + id+ "?exceptionDateStartTime=" + time;
    return this.http.delete(getExceptionDay);
  }
  postExceptionDay(id,data): Observable<any> {
    const getExceptionDay = URLS.getExceptionDay + '/' + id;
    return this.http.post(getExceptionDay,data);
  }
  updateExceptionDay(id,data): Observable<any> {
    const getExceptionDay = URLS.getExceptionDay + '/' + id;
    return this.http.put(getExceptionDay,data);
  }
  
  mrpSearch(data): Observable<any> {
    const mrpSearch = URLS.mrpSearch;
    return this.http.post(mrpSearch,data);
  }
  productionRunTaskReturnMaterial(data): Observable<any> {
    const productionRunTaskReturnMaterial = URLS.productionRunTaskReturnMaterial;
    return this.http.post(productionRunTaskReturnMaterial,data);
  }
  
  
  getProdCalender(data): Observable<any> {
    const getProdCalender = URLS.getProdCalender +  "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProdCalender);
  }
  getshipmentPlans(data): Observable<any> {
    const getshipmentPlans = URLS.getshipmentPlans +  "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getshipmentPlans);
  }
  
  getshipmentPlansById(id): Observable<any> {
    const getshipmentPlans = URLS.getshipmentPlans + '/' + id;
    return this.http.get(getshipmentPlans);
  }
  
  getProdWeek(data): Observable<any> {
    const getProdWeek = URLS.getProdWeek +  "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProdWeek);
  }
  postProdWeek(data): Observable<any> {
    const getProdWeek = URLS.getProdWeek;
    return this.http.post(getProdWeek,data);
  }
  updateProdWeek(data): Observable<any> {
    const getProdWeek = URLS.getProdWeek;
    return this.http.put(getProdWeek,data);
  }
  deleteProdWeek(id): Observable<any> {
    const getProdWeek = URLS.getProdWeek + "?calendarWeekId=" + id ;
    return this.http.delete(getProdWeek);
  }
  postProdCalender(data): Observable<any> {
    const getProdCalender = URLS.getProdCalender;
    return this.http.post(getProdCalender,data);
  }
  updateProdCalender(data): Observable<any> {
    const getProdCalender = URLS.getProdCalender;
    return this.http.put(getProdCalender,data);
  }
  deleteProdSkill(id,skillTypeId): Observable<any> {
    const getProdSkill = URLS.getProdSkill + '/' + id+ "?skillTypeId=" + skillTypeId ;
    return this.http.delete(getProdSkill);
  }
  deleteProdCalender(id): Observable<any> {
    const getProdCalender = URLS.getProdCalender + "?calendarId=" + id ;
    return this.http.delete(getProdCalender);
  }
  getProdWeekById(id): Observable<any> {
    const getProdWeekById = URLS.getProdWeekById + "?calendarWeekId=" + id ;
    return this.http.get(getProdWeekById);
  }
  
  
  postProdSkill(id,data): Observable<any> {
    const getProdSkill = URLS.getProdSkill + '/' + id ;
    return this.http.post(getProdSkill,data);
  }
  updateProdSkill(id,data): Observable<any> {
    const getProdSkill = URLS.getProdSkill + '/' + id ;
    return this.http.put(getProdSkill,data);
  }
  
  
  getProdParty(id,data): Observable<any> {
    const getProdParty = URLS.getProdParty + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProdParty);
  }
  getExceptionWeek(id,data): Observable<any> {
    const getExceptionWeek = URLS.getExceptionWeek + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getExceptionWeek);
  }
  getBOMData(data,req): Observable<any> {
    const getBOMData = URLS.getBOMData +  "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getBOMData,req);
  }
  
  getManufacturingRule(data): Observable<any> {
    const postManufacturingRule = URLS.postManufacturingRule +  "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(postManufacturingRule);
  }
  getProductBOMData(productAssocTypeId,productId): Observable<any> {
    const getProductBOMData = URLS.getProductBOMData +  "?productAssocTypeId=" + productAssocTypeId + "&productId=" + productId;
    return this.http.get(getProductBOMData);
  }
  getProductBOMDataDetailById(productAssocTypeId,productId,productIdTo,fromDate): Observable<any> {
    const getProductBOMDataDetailById = URLS.getProductBOMDataDetailById +  "?productAssocTypeId=" + productAssocTypeId + "&productId=" + productId + "&productIdTo=" + productIdTo + "&fromDate=" + fromDate;
    return this.http.get(getProductBOMDataDetailById);
  }
  
  getManufacturingIdByData(id): Observable<any> {
    const postManufacturingRule = URLS.postManufacturingRule + '/' + id;
    return this.http.get(postManufacturingRule);
  }
  
  getBOMInitialData(data): Observable<any> {
    const getBOMInitialData = URLS.getBOMInitialData + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getBOMInitialData);
  }
  deleteExceptionWeek(id,time): Observable<any> {
    const getExceptionWeek = URLS.getExceptionWeek + '/' + id+ "?exceptionDateStart=" + time;
    return this.http.delete(getExceptionWeek);
  }
   
  postExceptionWeek(id,data): Observable<any> {
    const getExceptionWeek = URLS.getExceptionWeek + '/' + id ;
    return this.http.post(getExceptionWeek,data);
  }
  
  postBOMData(data): Observable<any> {
    const postBOMData = URLS.postBOMData;
    return this.http.post(postBOMData,data);
  }
  
  postManufacturingRule(data): Observable<any> {
    const postManufacturingRule = URLS.postManufacturingRule;
    return this.http.post(postManufacturingRule,data);
  }
  updateManufacturingRule(data): Observable<any> {
    const postManufacturingRule = URLS.postManufacturingRule;
    return this.http.put(postManufacturingRule,data);
  }
  updateBOMData(data): Observable<any> {
    const postBOMData = URLS.postBOMData;
    return this.http.put(postBOMData,data);
  }
  getparentGroupList(): Observable<any> {
    const parentGroupList = URLS.getFacilityGroupParentGroupId;
    return this.http.get(parentGroupList);
  }
  
  getMrpFacilities(): Observable<any> {
    const getMrpFacilities = URLS.getMrpFacilities;
    return this.http.get(getMrpFacilities);
  }
  
  getproductInIds(): Observable<any> {
    const getproductInIds = URLS.getproductInIds;
    return this.http.get(getproductInIds);
  }
  getProductFeatureIds(): Observable<any> {
    const getProductFeatureIds = URLS.getProductFeatureIds;
    return this.http.get(getProductFeatureIds);
  }
  

  postProdMrp(data): Observable<any> {
    const postProdMrp = URLS.postProdMrp;
    return this.http.post(postProdMrp,data);
  }
  
  runBomSimulation(data): Observable<any> {
    const runBomSimulation = URLS.runBomSimulation;
    return this.http.post(runBomSimulation,data);
  }

  getProdMrp(): Observable<any> {
    const postProdMrp = URLS.postProdMrp;
    return this.http.get(postProdMrp);
  }
  
  getInitailBomSimualtionType(): Observable<any> {
    const getInitailBomSimualtionType = URLS.getInitailBomSimualtionType;
    return this.http.get(getInitailBomSimualtionType);
  }
  
  postActualMaterial(id,data): Observable<any> {
    const getActualMaterial = URLS.getActualMaterial + '/' + id ;
    return this.http.post(getActualMaterial,data);
  }
  updateExceptionWeek(id,data): Observable<any> {
    const getExceptionWeek = URLS.getExceptionWeek + '/' + id ;
    return this.http.put(getExceptionWeek,data);
  }
  
  deleteManufacturingRule(id): Observable<any> {
    const postManufacturingRule = URLS.postManufacturingRule + '/' + id ;
    return this.http.delete(postManufacturingRule);
  }
  
  postProdParty(id,data): Observable<any> {
    const getProdParty = URLS.getProdParty + '/' + id;
    return this.http.post(getProdParty,data);
  }
  updateProdParty(id,data): Observable<any> {
    const getProdParty = URLS.getProdParty + '/' + id;
    return this.http.put(getProdParty,data);
  }
  postProdfixedAsset(id,data): Observable<any> {
    const getProdfixedAsset = URLS.getProdfixedAsset + '/' + id;
    return this.http.post(getProdfixedAsset,data);
  }
  updateProdfixedAsset(id,data): Observable<any> {
    const getProdfixedAsset = URLS.getProdfixedAsset + '/' + id;
    return this.http.put(getProdfixedAsset,data);
  }
  deleteProdfixedAsset(id,fixedAssetTypeId): Observable<any> {
    const getProdfixedAsset = URLS.getProdfixedAsset + '/' + id+ "?fixedAssetTypeId=" + fixedAssetTypeId;
    return this.http.delete(getProdfixedAsset);
  }
  getProdCost(data): Observable<any> {
    const getProdCost = URLS.getProdCost + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProdCost);
  }
  
  deleteRoutingTaskDelievarbleProduct(id,fromDate,productId,workEffortGoodStdTypeId): Observable<any> {
    const getRoutingTaskDelievarbleProduct = URLS.getRoutingTaskDelievarbleProduct + '/' + id+ "?fromDate=" + fromDate + "&productId=" + productId + "&workEffortGoodStdTypeId=" + workEffortGoodStdTypeId;
    return this.http.delete(getRoutingTaskDelievarbleProduct);
  }
  
  deleteBOMData(fromDate,productId,productAssocTypeId,productIdTo): Observable<any> {
    const postBOMData = URLS.postBOMData +  "?fromDate=" + fromDate + "&productId=" + productId + "&productAssocTypeId=" + productAssocTypeId + "&productIdTo=" + productIdTo;
    return this.http.delete(postBOMData);
  }
  deletePostRoutingTaskCost(id,costComponentCalcId,costComponentTypeId,fromDate): Observable<any> {
    const postRoutingTaskCost = URLS.postRoutingTaskCost + '/' + id+ "?costComponentCalcId=" + costComponentCalcId + "&costComponentTypeId=" + costComponentTypeId + "&fromDate=" + fromDate;
    return this.http.delete(postRoutingTaskCost);
  }
  postRoutingTaskDelievarbleProduct(id,data): Observable<any> {
    const getRoutingTaskDelievarbleProduct = URLS.getRoutingTaskDelievarbleProduct + '/' + id;
    return this.http.post(getRoutingTaskDelievarbleProduct,data);
  }
  updateRoutingTaskDelievarbleProduct(id,data): Observable<any> {
    const getRoutingTaskDelievarbleProduct = URLS.getRoutingTaskDelievarbleProduct + '/' + id;
    return this.http.put(getRoutingTaskDelievarbleProduct,data);
  }
  
  getProductionJobContent(id,data,contentLocale,partyId,productId): Observable<any> {
    const getProductionJobContent = URLS.getProductionJobContent + '/' + id+ "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&contentLocale=" + contentLocale + "&partyId=" + partyId + "&productId=" + productId;
    return this.http.get(getProductionJobContent);
  }
  postProductionJobContent(id,data): Observable<any> {
    const getProductionJobContent = URLS.getProductionJobContent + '/' + id;
    return this.http.post(getProductionJobContent,data);
  }
  getProdCostById(id): Observable<any> {
    const postProdCost = URLS.postProdCost + '/' + id;
    return this.http.get(postProdCost);
  }
  deleteProdCostById(id): Observable<any> {
    const postProdCost = URLS.postProdCost + '/' + id;
    return this.http.delete(postProdCost);
  }
  
  postProdCost(data): Observable<any> {
    const postProdCost = URLS.postProdCost;
    return this.http.post(postProdCost,data);
  }
  updateProdCost(data): Observable<any> {
    const postProdCost = URLS.postProdCost;
    return this.http.put(postProdCost,data);
  }
  
  postProductionJobassoc(id,productionRunIdTo,workFlowSequenceTypeId): Observable<any> {
    const getProductionJobassoc = URLS.getProductionJobassoc + '/' + id+ "?productionRunIdTo=" + productionRunIdTo + "&workFlowSequenceTypeId=" + workFlowSequenceTypeId;
    return this.http.post(getProductionJobassoc,'');
  }
  deleteProductionJobContentProductId(id,contentId,fromDate,workEffortContentTypeId,workEffortId): Observable<any> {
    const getProductionJobContent = URLS.getProductionJobContent + '/' + id+ "?contentId=" + contentId + "&fromDate=" + fromDate + "&workEffortContentTypeId=" + workEffortContentTypeId + "&workEffortId=" + workEffortId;
    return this.http.delete(getProductionJobContent);
  }
  changeProductionStatusCancel(id): Observable<any> {
    const changeProductionStatusCancel = URLS.changeProductionStatusCancel + "?productionRunId=" + id;
    return this.http.post(changeProductionStatusCancel,'');
  }
  
  getProductionJobProductId(id): Observable<any> {
    const getProductionJobProductId = URLS.getProductionJobProductId + "?productionRunId=" + id;
    return this.http.get(getProductionJobProductId);
  }
  quickStartAllProductionRunTasks(id): Observable<any> {
    const quickStartAllProductionRunTasks = URLS.quickStartAllProductionRunTasks + "?productionRunId=" + id;
    return this.http.post(quickStartAllProductionRunTasks,'');
  }
  changeProductionStatusConfirm(id): Observable<any> {
    const changeProductionStatusConfirm = URLS.changeProductionStatusConfirm + "?productionRunId=" + id;
    return this.http.post(changeProductionStatusConfirm,'');
  }
  
  
  postproductionRunDeclareAndProduce(data): Observable<any> {
    const postproductionRunDeclareAndProduce = URLS.postproductionRunDeclareAndProduce;
    return this.http.post(postproductionRunDeclareAndProduce,data);
  }
  postProductionParty(id,data): Observable<any> {
    const getProductionParty = URLS.getProductionParty + '/' + id;
    return this.http.post(getProductionParty,data);
  }
  postRoutingTaskCost(id,data): Observable<any> {
    const postRoutingTaskCost = URLS.postRoutingTaskCost + '/' + id;
    return this.http.post(postRoutingTaskCost,data);
  }
  
  
  getProductionproductionRunId(): Observable<any> {
    const getProductionproductionRunId = URLS.getProductionproductionRunId;
    return this.http.get(getProductionproductionRunId);
  }
  getRoutingTaskCost(): Observable<any> {
    const getRoutingTaskCost = URLS.getRoutingTaskCost;
    return this.http.get(getRoutingTaskCost);
  }
  getProdCalenderWeekId(): Observable<any> {
    const getProdCalenderWeekId = URLS.getProdCalenderWeekId;
    return this.http.get(getProdCalenderWeekId);
  }
  
  
  
  deleteProductionFixedAsset(id,fixedAssetId,fromDate,workEffortId): Observable<any> {
    const getProductionFixedAsset = URLS.getProductionFixedAsset + '/' + id+ "?fixedAssetId=" + fixedAssetId + "&fromDate=" + fromDate + "&workEffortId=" + workEffortId;
    return this.http.delete(getProductionFixedAsset);
  }
  getProductionFixedAssetById(fixedAssetId,fromDate,workEffortId): Observable<any> {
    const getProductionFixedAssetById = URLS.getProductionFixedAssetById + "?fixedAssetId=" + fixedAssetId + "&fromDate=" + fromDate + "&workEffortId=" + workEffortId;
    return this.http.get(getProductionFixedAssetById);
  }
  changeProductionStatusQuick(productionRunId,statusId): Observable<any> {
    const changeProductionStatusQuick = URLS.changeProductionStatusQuick + "?productionRunId=" + productionRunId + "&statusId=" + statusId ;
    return this.http.post(changeProductionStatusQuick,'');
  }
  
  
  postProductionFixedAsset(id,data): Observable<any> {
    const getProductionFixedAsset = URLS.getProductionFixedAsset + '/' + id;
    return this.http.post(getProductionFixedAsset,data);
  }
  updateProductionFixedAsset(id,data): Observable<any> {
    const getProductionFixedAsset = URLS.getProductionFixedAsset + '/' + id;
    return this.http.put(getProductionFixedAsset,data);
  }
  deleteProductionMaterial(id,fromDate,productId,workEffortGoodStdTypeId,workEffortId): Observable<any> {
    const getProductionMaterial = URLS.getProductionMaterial + '/' + id+ "?fromDate=" + fromDate + "&productId=" + productId + "&workEffortGoodStdTypeId=" + workEffortGoodStdTypeId + "&workEffortId=" + workEffortId;
    return this.http.delete(getProductionMaterial);
  }
  postProductionMaterial(id,data): Observable<any> {
    const getProductionMaterial = URLS.getProductionMaterial + '/' + id;
    return this.http.post(getProductionMaterial,data);
  }
  postRoutingTasks(data): Observable<any> {
    const postRoutingTasks = URLS.postRoutingTasks;
    return this.http.post(postRoutingTasks,data);
  }
  updateRoutingTasks(data): Observable<any> {
    const postRoutingTasks = URLS.postRoutingTasks;
    return this.http.put(postRoutingTasks,data);
  }
  
  updateProductionMaterial(id,data): Observable<any> {
    const getProductionMaterial = URLS.getProductionMaterial + '/' + id;
    return this.http.put(getProductionMaterial,data);
  }
  
  getRoutingTaskDetails(id): Observable<any> {
    const getRoutingTaskDetails = URLS.getRoutingTaskDetails + '/' + id;
    return this.http.get(getRoutingTaskDetails);
  }
  getJobShopProductionList(data,req): Observable<any> {
    const getJobShopProductionList = URLS.getJobShopProductionList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getJobShopProductionList,req);
  }
  getRoutingTasks(data,req): Observable<any> {
    const getRoutingTasks = URLS.getRoutingTasks + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getRoutingTasks,req);
  }
  
  
  getproductLinkById(id,fromDate,productId,workEffortGoodStdTypeId): Observable<any> {
    const getproductLinkById = URLS.getproductLinkById + '/' + id+ "?fromDate=" + fromDate + "&productId=" + productId + "&workEffortGoodStdTypeId=" + workEffortGoodStdTypeId;
    return this.http.get(getproductLinkById);
  }
  getProductionRunDeclareAndProduce(id,data): Observable<any> {
    const postproductionRunDeclareAndProduce = URLS.postproductionRunDeclareAndProduce + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&productionRunId=" + id;
    return this.http.get(postproductionRunDeclareAndProduce);
  }
  
  getRoutingTasksinitialData(): Observable<any> {
    const getRoutingTasksinitialData = URLS.getRoutingTasksinitialData ;
    return this.http.get(getRoutingTasksinitialData);
  }
  getJobShopInitialData(): Observable<any> {
    const getJobShopInitialData = URLS.getJobShopInitialData ;
    return this.http.get(getJobShopInitialData);
  }
  getroutingTaskList(): Observable<any> {
    const getroutingTaskList = URLS.getroutingTaskList ;
    return this.http.get(getroutingTaskList);
  }
  
  
  deleteproductLink(id,fromDate,productId,workEffortGoodStdTypeId): Observable<any> {
    const deleteproductLink = URLS.deleteproductLink + '/' + id + "?fromDate=" + fromDate + "&productId=" + productId + "&workEffortGoodStdTypeId=" + workEffortGoodStdTypeId;
    return this.http.delete(deleteproductLink);
  }
}
