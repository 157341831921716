import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { WarehouseService } from '../warehouse/warehouse/warehouse.service';

import { Location } from '@angular/common';


@Component({
  selector: 'app-warehouse-dashboard-overview',
  templateUrl: './warehouse-dashboard-overview.component.html',
  styleUrls: ['./warehouse-dashboard-overview.component.css']
})
export class WarehouseDashboardOverviewComponent implements OnInit {
  activeCat = 0;
  activeCategory=0;
  constructor(
  private _location: Location,
  readonly _FormBuilder: FormBuilder,
    readonly _WarehouseService: WarehouseService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly router: Router,
    readonly _ActivatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }
  referToMain(tabNo){
    this.router.navigate(['/facilities/warehouse'],{queryParams:{id:tabNo}});

  }
  dashboardOpen(){
    this.router.navigate(["/facilities/warehouse-dashborad-overview"])
  }
  ShippingOpen(){
    this.router.navigate(["/facilities/warehouse-shipment-config"])
  }
  Main(){
    this.router.navigate(["/facilities/warehouse"])
  }
  Facility(){
    this.router.navigate(["/facilities/facility-group"])
  }
  Inventory(){
    this.router.navigate(["/facilities/inventory-labels"])
  }
  Shipments(){
    this.router.navigate(["/facilities/warehouse-shipment"])
  }
  mainOpen(){
    this.router.navigate(['/facilities/warehouse']);
  }

  facilityGroupOpen(){
    this.router.navigate(['/facilities/facility-group']);
  }

  inventoryItemOpen(){
    this.router.navigate(['/facilities/inventory-labels']);
  }
  createFacilities(): void {
    this.router.navigate([`/facilities/warehouse/create-new-facilities`]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
}
