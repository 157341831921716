
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {CreateContactModel} from './create-contact-model'

@Injectable({
    providedIn: 'root'
  })


export class CreateContactService {
    create_contact_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public createContactModel: CreateContactModel
    ) { } 

  preparePresonalInfoForm(data:any): void {
    if (!this.create_contact_form) {
        this.create_contact_form = this._formBuilder.group(this.createContactModel.mapDataToModel(data));
    }
  }
}

 

 