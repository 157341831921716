<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="classCard" style="width: 100%;margin-top: 15px;">
                <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                    <li  [ngClass]="activeCategory==1?'active':''"><a>
                        Order Notes</a></li>
  


                  </ul>
                </div>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">


                          <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div class="panel-body">
                              <div class="mt-2">
                                <div class="row">
                                  <form class="w-100" [formGroup]="noteForm">
                                    <div class="col-lg-12 col-12 main-submit-button">
                                      <button type="submit" class="btn btn-secondary submit-btn"
                                        routerLinkActive="active" (click)="navigation()">Back
                                        To Order</button>
                                    </div>

                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <div class="col-lg-12">
                                        <div class="row">
                                          <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">Note</label>
                                          </div>

                                          <div class="col-lg-4">
                                            <textarea style="height: 100%!important;width: 120%;" id="w3review"
                                              formControlName="note" rows="3" cols="60" placeholder="Note">
                                                     </textarea>
                                          </div>


                                          <div class="col-lg-3 form-group rightFormA">
                                            <label class="common-labels">Internal Note
                                            </label>
                                          </div>

                                          <div class="col-lg-2">
                                            <p-dropdown [options]="array" placeholder="Select"
                                              formControlName="internalNote"></p-dropdown>
                                          </div>

                                        </div>
                                      </div>

                                      <div class="col-lg-12 col-12 main-submit-button" style="padding-right: 23em;">
                                        <button type="submit" class="btn btn-secondary submit-btn"
                                          (click)="submit()">Submit</button>
                                      </div>
                                      </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>