
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreateQuetoModel } from './create-quotes-model'

@Injectable({
    providedIn: 'root'
  })


export class CreateQuetoFormService {
    create_quetos_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public createQuotesModel: CreateQuetoModel
    ) { } 

  prepareQuetosForm(data:any): void {
    if (!this.create_quetos_form) {
        this.create_quetos_form = this._formBuilder.group(this.createQuotesModel.mapDataToModel(data));
       
    }

  }

}

 