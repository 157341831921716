<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Invoice </span>
                <span class="color-grey search-icons">
                </span>
                <button class="btn btn-danger" style="margin-left: auto!important;" 
                                            (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians" style=" margin-top: 0px!important;
                  background: #F5F7FA!important;">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Update Invoice</a></li>
                      
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                               
                                <div >
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <form [formGroup]="updateSalesInvoiceForm">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                        margin-left: -10%;
                                                    " >
                                                            <label for="exampleInputEmail1">Invoice Date</label>
                                                        </div>
                                                     
                                                        <div class="col-lg-2">
                                                            <p-calendar formControlName="invoiceDate"></p-calendar>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                      
                                                    ">
                                                            <label for="exampleInputEmail1">Due Date</label>
                                                        </div>
                                                       
                                                      
                                                        <div class="col-lg-2" >
                                                            <p-calendar formControlName="dueDate"></p-calendar>
                                                        </div>
                                
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                        margin-left: -10%;
                                                    " >
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                     
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                            formControlName="description">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                       
                                                    ">
                                                            <label for="exampleInputEmail1">From Party ID</label>
                                                        </div>
                                                       
                                                      
                                                        <div class="col-lg-2" >
                                                            <p-dropdown filter="true" [options]="partyIds"
                                                            optionlabel="label" formControlName="partyIdFrom"
                                                            placeholder="Select From Party ID">
                                                        </p-dropdown>
                                                        </div>
                                
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                        margin-left: -10%;
                                                    ">
                                                            <label for="exampleInputEmail1">Role Type ID</label>
                                                        </div>
                                                     
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="roleTypeIds"
                                                            optionlabel="label" formControlName="roleTypeId"
                                                            placeholder="Select Role Type ID">
                                                        </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                     
                                                    ">
                                                            <label for="exampleInputEmail1">Billing Account Id</label>
                                                        </div>
                                                       
                                                      
                                                        <div class="col-lg-2" >
                                                            <p-dropdown filter="true" [options]="billingAccounts"
                                                            optionlabel="label" formControlName="billingAccountId"
                                                            placeholder="Select Billing Account Id">
                                                        </p-dropdown>
                                                        </div>
                                
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                        margin-left: -10%;
                                                    ">
                                                            <label for="exampleInputEmail1">Currency</label>
                                                        </div>
                                                     
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="currencies"
                                                                optionlabel="label" formControlName="currencyUomId"
                                                                placeholder="Select Currency">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                   
                                                    ">
                                                            <label for="exampleInputEmail1">Recurrence Info ID</label>
                                                        </div>
                                                       
                                                      
                                                        <div class="col-lg-2" >
                                                            <input type="text" class="form-control"
                                                            placeholder="Recurrence Info ID"
                                                            formControlName="recurrenceInfoId">
                                                        </div>
                                
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                        margin-left: -10%;
                                                    ">
                                                            <label for="exampleInputEmail1">Invoice Message</label>
                                                        </div>
                                                     
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control"
                                                            placeholder="Invoice Message"
                                                            formControlName="invoiceMessage">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                        padding-right: 17px!important;
                                                    
                                                    ">
                                                            <label for="exampleInputEmail1">Reference Number</label>
                                                        </div>
                                                       
                                                      
                                                        <div class="col-lg-2" >
                                                            <input type="text" class="form-control"
                                                            placeholder="Reference Number"
                                                            formControlName="referenceNumber">
                                                        </div>
                                
                                                    </div>
                                                </div>

                                             
                                            </div>
                                        </form>
                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="
                                    margin-left: -26%;
                                "><button _ngcontent-cta-c128=""
                                            type="submit" class="btn btn-secondary submit-btn"
                                            (click)="submit()">Update</button>
                                        <button class="btn btn-danger cursor-pointer ml-2"
                                            (click)="reset()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 