import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { HeaderService } from '../../header/header.service';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from "../employees/employess.service";
import { DetailedEmployeeSummary } from "./detailed-employee-summary.service";
import { ToastrService } from 'ngx-toastr';
import { CreateDeparmentsService } from '../create-departments/create-departments.service';
import { DOCUMENT } from '@angular/common';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { detailedContactsService } from 'src/app/crm/contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateDepartmentFormService } from '../create-departments/create-departments-service';
import Swal from 'sweetalert2'
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { EmpPersonalFormService } from '../update-employe-personalInfo/emp-personal-service';
import { PostalEmployeeFormService } from '../update-postal-address/update-postal-service';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { MyContactsService } from "../../crm/contacts/my-contacts/my-contacts.service";
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { ReallocationsService } from '../reallocation/reallocations.service';
import { CreateTimesheetFormService } from '../timesheet/create-timesheet/create-timesheet-service';
import { TimeSheetService } from "../timesheet/timesheet.service";
import { EmployeeApplicationService } from 'src/app/employeeApplication/employee-application/employee-application.service';
import { InternalJobService } from 'src/app/human-resource/recuitment/internal-job-posting/internal-job-posting.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { EmployementApplicationService } from "src/app/employeeApplication/employee-application.service";
import { CreateEmployeePositionService } from '../add-employee-position/add-employee-position-service';
import { EmployeePositionService } from '../employee-position/employee-position.service'
import { SkillService } from "src/app/human-resource/skills/skill.service";
import { AddSkillsService } from '../add-skills/add-skill-service';
import { NewJobRequistionsService } from 'src/app/human-resource/recuitment/new-job-requisition/new-job-requisition.service';
import { NewJobRequistionService } from 'src/app/human-resource/recuitment/new-job-requisition/new-job-requistion-service';
import { DatePipe } from '@angular/common';
import { AddLeaveService } from '../leave/add-new-leaves/add-new-leaves-service';
import { EmployeeLeaveService } from '../leave/employee-leave/employee-leave.service';
import { AddLeaveEmpService } from '../leave/add-new-leaves/add-new-leaves.service';
import { JobRequistionService } from '../recuitment/job-requisition/job-requisition.service';
import {DeparmentsService} from "../departments/departments.service";
    
import { Location } from '@angular/common';
@Component({
  selector: 'app-detailed-employee-summary',
  templateUrl: './detailed-employee-summary.component.html',
  styleUrls: ['./detailed-employee-summary.component.css']
})
export class DetailedEmployeeSummaryComponent implements OnInit {
  faEdit = faEdit;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  partyId: any;
  partyIds: any;

  crmStatus: any;
  crmArray: any[] = [];
  currencyList: any[];
  products3: any;
  initialStage: any;
  activeCategory = 1;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 3;
  public employeeDetails: any;
  public employeeDetail: any;
  employeeDetailArray: any = [];
  departmentList: any;
  departmantArray: any = [];
  departmentId: any;
  department: any;
  demoName: any;
  demoId: any;
  employeeDetailArrayEmail: any = [];
  employeeDetailArrayPhone: any = [];
  employeeDetailRelatedAccount: any;
  contentList: any;
  Editform: FormGroup;
  fileData: File;
  partyContentTypeId: any;
  partyContentData: any;
  partyContentArray: any = [];
  leadNoteId: any;

  pageNo = 1;
  contactInformation: any[];
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  empTraining: any;
  emergencyContact: any[];
  imageView: any;
  imagePic: boolean;

  updateId: any;
  show = false;
  countryId: any;
  array: any = [];
  stateData: any;
  stateArray: any = [];
  internalOrganization: any;
  organizationArray: any = [];
  solicitation: any = [];

// Added 
  employeeList: any[] = [];
  actualfromdate: string;
  advanceSearch: {

    PartyID: string;
    PartyQualTypeID: string;
    QualificationDesc: string;
    Title: string;
    fromdateFrom: string;
    fromDateTo: string;
    throughDateFrom: string,
    throughDateTo: string,
    StatusID: string,
    VerifStatusID: string,
  };


// End Code 
  contactMechId: string;

  contactForm: FormGroup;
  editMode: boolean;
  contact: any;
  arrayA = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactPurpose: any[];

  fileUploadForm: FormGroup;
  accountData: any;
  accountArray: any = [];
  contactArray: any[] = [];
  contactId:any;
  groupNameSearchType: any = 'Contains'
  contactMechTypeId: any = '';
  groupName: any = '';
  partyIdSearchType: any = 'Contains';
  crmAccount: any = [];
  model: any = {};

  noteName: any;
  textArea: any;
  params: string;
  noteId: any;
  partyIdForCreate: any;
  contactName: any;
  contactPartyId: any;
  noteNameForUpdate: any;
  noteForUpdate: any;

  showUpdateBtn = false;
  showCreateBtn = false;
  emergencyContactForm: FormGroup;

  arrayB = [
      {
          label: 'Primary',
          value: 'PRIMARY_CONTACT'
      },
      {
          label: 'Secondary',
          value: 'SECONDARY_CONTACT'
      }
  ];

  editModeA: boolean=false;
  updateValue: {
      personName: string,
      relation: string,
      contactNumber: string,
      contactTypeId:string
  };
  leavesForm: FormGroup;
  createQualification: FormGroup;
  VerifStatusID: any;
  VerifStatusIDArray: any[]=[];
  PartyQualTypeId: any;
  PartyQualTypeIdArray: any[]=[];
  partyIdArray: any[]=[];
  PartyId: any;
  PerfReviewData: any;
  createReview: FormGroup;
  actualthroughdate: string;
  id: string;
  allroleArray: any[]=[];
  allrole: any;
  PaymentIDArray: any[]=[];
  PerfReviewArray: any[]=[];
  PerfReview: any;
  PaymentID: any;
  positionType: any;
  positionArray: any[]=[];
  newDepartmentForm: FormGroup;
  newTimeSheetForm: FormGroup;
  statusList: any;
  statusArray: any[]=[];
  userLoginArray: any[]=[];
  EmploymentFormSignature: FormGroup;
  createEmploymentForm: FormGroup;
  signatureURL: any;
  employmentApplication: any;
  empTypeId: any;
  personId: any;
  appSource: any;
  appSourceArray: any[]=[];
  internalJobPostList: any;
  search = "";
  public jobPost = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  available: any;
  AvailabilityArray: any[]=[];
  appIdArray: any[]=[];
  updateethrdateStatus: any;

  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  estifromdate: any;
  estithroughdate: any;
  employeePosition: any;
  Flag:any=[];
  opportunityIdArray:any=[];
  dateIdArray:any=[];
  status: any;
  getSkillLists: any;
  skillArray: any[]=[];
  skillList: any;
  activeCategoryTabs=1;
  jobRequisitionForm: FormGroup;
  GenderNew:any[]=[];
  qualification: any;
  qualArray: any[]=[];
  enumArray: any[]=[];
  skillTypeArray: any[]=[];
  getSkill: any;
  yesNoArray: { label: string; value: string; }[];
  internalArray :any[]=[];
  DepartmentArray :any[]=[];
  Department :any;
  transportationArray: { label: string; value: string; }[];
  RelatedCustomerForm:FormGroup;
  companyData: any;
  companyArray: any[]=[];  
  showError: any;
  updateefromdate: string;
  updateetodate: string;
  leaveReason: any;
  leaveReasonArray: any[]=[];
  leaveTypeId: any;
  leaveId: any = [];
  leaveTypeIds: any;
  EditEmployeeSkillList: any;
  EditPerfReviewList:any;
  EditEmployeePositionList: any;
  EditPartyQualList: any;
  EditEmploymentAppList: any;
  EditEmployeeLeavesList: any;
  editTimeSheetList: any;
  EditDepartmentList:any;
  EditListRelatedAccountList:any;
  constructor(readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly detailedEmployeeSummary: DetailedEmployeeSummary,
    readonly activatedRoute: ActivatedRoute,
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly employeeService: EmployeeService,
private _location: Location,
readonly spinner: NgxSpinnerService,
    readonly _detailedContactsService: detailedContactsService,
    @Inject(DOCUMENT) readonly document: Document,
    readonly _GlobalResourceService: GlobalResourceService,
    public empPersonalFormService: EmpPersonalFormService,
    public addEmployeeFormService: PostalEmployeeFormService,
    public createDepartmentFormService:CreateDepartmentFormService,
    readonly toastr: ToastrService,
    readonly _DetailedEmployeeSummary: DetailedEmployeeSummary,
    readonly _CRMAccountService: CRMAccountService,
    readonly _FormBuilder: FormBuilder,
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,
    readonly _EmployeeService: EmployeeService,
    readonly formBuilder: FormBuilder,
    readonly MyContactsService: MyContactsService,
    readonly datePipe: DatePipe,  
    readonly employeeLeaveService: EmployeeLeaveService,
    public addLeaveService: AddLeaveService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly reallocationsService: ReallocationsService,
    public createTimesheetFormService:CreateTimesheetFormService,
    readonly timeSheetService: TimeSheetService,
    readonly employeeApplicationService: EmployeeApplicationService,
    readonly internalJobService: InternalJobService,
    readonly employementApplicationService: EmployementApplicationService,
    readonly deparmentsService:DeparmentsService,
    public createEventFormService: CreateEventFormService,
    readonly createEmployeePositionService: CreateEmployeePositionService,
    readonly employeePositionService: EmployeePositionService,
    readonly createDeparmentsService:CreateDeparmentsService,
    public addSkillService: AddSkillService,
    readonly skillService: SkillService,
    readonly jobRequistionService: JobRequistionService,
    public addSkillsService:AddSkillsService,
    public newJobRequistionService: NewJobRequistionService,
    readonly newJobRequistionsService: NewJobRequistionsService,

) {
    this.emergencyContact = [];
    this.contactInformation = [];

    this.solicitation = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }

    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
    this.Flag = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }

    ];

    this.GenderNew = [{ label: "Male", value: "M" }, { label: "Female", value: "F" }];


   

   
    this.contactMechId = '';



    this.partyId = '';
    this.editMode = false;
    this.contactForm = this._FormBuilder.group({
      allowSolicitation: [''],
      areaCode: [''],
      askForName: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      contactNumber: [''],
      countryCode: [''],
      extension: [''],
      partyId: [''],
      preContactMechTypeId: ['TELECOM_NUMBER']
    });
    this.contactPurpose = [];
    this.contactMechId = '';
    this.partyId = '';
    this.emergencyContactForm = this._FormBuilder.group({
        contactNumber: [''],
        contactTypeId: [''],
        personName: [''],
        relation: ['']
    });
    this.updateValue = {
        personName: '',
        relation: '',
        contactNumber: '',
        contactTypeId:''
    };

    // Added by RA start Code
    this.leavesForm=this._FormBuilder.group({
      partyId:"",
      leaveTypeId:"",
      leaveReasonType:"",
      fromDate:"",
      thruDate:"",
      approverPartyId:"",
      description:"",
      leaveStatus:"",
    })

    this.createQualification = this._FormBuilder.group({
      QualificationDesc: [''],
      Title: [''],
      StatusID: [''],
      VerifStatusID: [''],
      ThroughDate: [''],
      PartyID: [''],
      fromDate: [''],
      PartyQualTypeID: [''],
      Comments: ['']

    });

    this.createReview = this._FormBuilder.group({
      EmployeePartyID: [''],
      PerfReviewID: [''],
      ManagerPartyID: [''],
      ManagerRoleTypeID: [''],
      PaymentID: [''],
      EmplPositionID: [''],
      fromDate: [''],
      ThroughDate: [''],
      Comments:['']
    
    });

    this.newDepartmentForm=this._FormBuilder.group({
      departmentName:"",
      active:"",
      companyNameId:"",
    })

    this.newTimeSheetForm=this._FormBuilder.group({
      partyId:"",
      clientPartyId:"",
      fromDate:"",
      thruDate:"",
      statusId:"",
      comments:"",
      approvedByUserLoginId:"",
    })

    this.createEmploymentForm = this._FormBuilder.group({
      crime: [''],
      name: [''],
      socailSecurityNumber: [''],
      address: [''],
      telephoneNo: [''],
      OtherPhonenumber: [''],
      reason: [''],
      empDate: ['', [Validators.required]],


      PositionAppliedFor: [''],
      DateAvailable: ['', [Validators.required]],
      referred: [''],
      payRate: [''],
      available: [''],


      SchoolAddress: [''],
      degree: [''],
      SchoolAddressTwo: [''],
      degreeTwo: [''],
      SchoolAddressThree: [''],
      degreeThree: [''],
      SchoolAddressFour: [''],
      degreeFour: [''],


      ProfessionalLicense: [''],
      Expiration: ['', [Validators.required]],
      applicationFilled: [''],
      explain: [''],
      transportation: [''],
      license: [''],
      LicenseNumber: [''],
      StateofIssue: [''],
      ExpirationDate: ['', [Validators.required]],


      nameOne: [''],
      nameTwo: [''],
      addressOne: [''],
      secondAddress: [''],
      TelephoneNumber: [''],
      TelephoneNumberTwo: [''],


      Employer: [''],
      TypeofBusiness: [''],
      EmpAddress: [''],
      Phone: [''],
      TitlesDuties: [''],
      RatePay: [''],
      PeriodEmployed: [''],
      ReasonforLeaving: [''],
      SupervisorName: [''],


      position: [''],
      Department: [''],
      dateHire: ['', [Validators.required]],
      Salary: [''],
      Comments: [''],

      Signature: [''],
      signDate: [''],

    });

    this.EmploymentFormSignature = this._FormBuilder.group({
      Signature: [''],
      signDate: [''],

    });

    this.jobRequisitionForm = this._FormBuilder.group({
      jobLocation:"",
      age:"",
      noOfResources:"",
      gender:"",
      durationMonths:"",
      qualification:"",
      examTypeEnumId:"",
      skillTypeId:"",
      experienceInMonths:"",
      experienceInYears:"",
    });

    this.transportationArray = [
      {
        label: 'Own Vehicle',
        value: 'Own Vehicle'
      },
      {
        label: 'Public Transport',
        value: 'Public Transport'
      },
    ];
  }

  // End Code 

  ngAfterViewInit(): void { this.spinner.show(); }

  ngOnInit(): void {
    this.getAllDepartment();
    this.activatedRoute.queryParams.subscribe(params => {
      this.employeeDetails = params["employeeDetailId"];
      this.getEmployeeDetail();
      this.getPartyContent();
      this.getPartyContentsList();
      this.getEmployeeDetailRelatedAccount();
      this.getLeadNote();
      this.getEmployeeTrainingData();
      this.getEmployeePersonalImage();

    })
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
    this.empPersonalFormService.emp_personal_form = null;
    this.empPersonalFormService.createEventForm(this.empPersonalFormService.emp_personal_form);
    this.getCurrency();
    this.getEmpStatus();
    this.getEmployeeDetail();

    this.getCountryList();
    this.getInternalOrganization();
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateId = params["employeeDetailId"];
      this.contactMechId = params.contactMechId;
    });
    if (this.contactMechId) {
      this.show = true;
      this.getEmployeeDetailPostal();
    }
    else {
      this.show = false;
    }

    this.addEmployeeFormService.postal_employee_form = null;
    this.addEmployeeFormService.addEmployeeForm(this.addEmployeeFormService.postal_employee_form);


    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
    if (this.contactMechId) {
      this.editMode = true;
      this.spinner.show();


      this._EmployeeService.getEmployeeId(this.partyId)
        .subscribe(data => {

          if (data.success) {
            this.spinner.hide();
            data.data.contactInformation.filter(value => {
              if (value.contactMech.contactMechId.trim() === this.contactMechId.trim()) {
                this.contact = {
                  allowSolicitation: value.partyContactMech.allowSolicitation ? value.partyContactMech.allowSolicitation : 'N',
                  areaCode: value.telecomNumber.areaCode,
                  askForName: [''],
                  contactMechId: value.contactMech.contactMechId.trim(),
                  contactMechPurposeTypeId: value.partyContactMechPurposes[0].contactMechPurposeTypeId,
                  contactMechTypeId: value.contactMech.contactMechTypeId,
                  contactNumber: value.telecomNumber.contactNumber,
                  countryCode: value.telecomNumber.countryCode,
                  extension: value.partyContactMech.extension,
                  partyId: this.partyId,
                  preContactMechTypeId: 'TELECOM_NUMBER'
                };
                this.contactForm.patchValue(this.contact);

                this.contactForm.controls.contactMechPurposeTypeId.disable();
                this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
              }
            });
          }
        });
      this.getContactDetails();

    }
    this.getContactPurpose();

    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["personId"];
    });

    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments: [''],
      contactPartyId: [''],
      partyId: ['']
    });
    this.RelatedCustomerForm = this.formBuilder.group({
      accountPartyId: [''],
      comments: [''],
     
    });


    let contactPartyIdLocal;
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate= params["contactIdd"];
      if(this.employeeDetails) 
      {
        contactPartyIdLocal=this.employeeDetails;
      }
      this.contactName= params["contactName"];
      if(this.employeeDetails)
      {
        this.showCreateBtn=true;   
      }
      })

    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"]; 
      this.partyId = params["contactId"];
      if(this.partyId) 
      {
        contactPartyIdLocal=this.partyId;
      }
      
      this.noteNameForUpdate=params["noteName"];
      this.noteForUpdate=params["note"];
      
      if(this.noteId && this.partyId){
        this.showUpdateBtn=true;
      }
    });
    this.contactPartyId=contactPartyIdLocal;

    this.fileUploadForm = this.formBuilder.group({
      noteName: [''],
      note: ['']
    });


    this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
    this._ActivatedRoute.queryParams.subscribe(data => {
       
       this.updateValue.personName = data.personName;
       this.updateValue.relation = data.relation;
       this.updateValue.contactNumber = data.contactNumber;
       this.updateValue.contactTypeId = data.contactTypeId;
        if (data.personName) {
            this.emergencyContactForm.patchValue(data);
            this.editMode = true;
        }
        else{
            this.editMode = false;
        }
    });
    this.addLeaveService.add_leave_form = null;
    this.addLeaveService.addLeaveForm(this.addLeaveService.add_leave_form);
    setTimeout(() => {
      const form = this.addLeaveService.add_leave_form;
form.patchValue({
  partyId: this.employeeDetails
})
    }, 1000);
    this.addSkillsService.add_skill_form = null;
    this.addSkillsService.addSkillForm(this.addSkillsService.add_skill_form);
    this.createDepartmentFormService.job_requistion_form=null;
    this.createDepartmentFormService.jobRequistionForm(this.createDepartmentFormService.job_requistion_form)
    this.show=false;
    this.createTimesheetFormService.create_timesheet_form=null;
    this.createTimesheetFormService.createTimesheetForm(this.createTimesheetFormService.create_timesheet_form);
    this.getInternalOrganizations();
    this.getDepartment();
   // this.getPartyQualList();
    this.getAllAccount();
    this.getLeaveReasonIdNew();
    this.getPartyIdNew();
    this.getPartyQualType(); 
    this.getStatusVerifId(); 
    this.getPositionTypeNew(); 
    this.getPaymentID(); 
    this.getStatusList(); 
    this.statusIdNew(); 
    this.getPartyId(); 
    this.getRoleAllType(); 
    this.getUserLoginId(); 
    this.leavereasonNew(); 
    this.getEmpAccountRelated();
    this.EditEmployeeLeaves();
    this.EditEmploymentApp();
    this.EditPartyQual();
    this.EditEmployeePosition();
    this.EditEmployeeSkills();
    this.editTimeSheet();
    this.EditPerfReview();
    this.getContactList();
    this.EditDepartment();
    this.EditListRelatedAccounts();
    this.getSkillListView();

  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])

  }
  cancelSubmit(){
    this._location.back();
  }


  //////////////////////////////FUNCTIONS//////////////////////////////////////////
// Added by RA
// Start code
EditListRelatedAccounts() {
  this.spinner.show();
  this.MyContactsService.EditListRelatedAccounts(this.employeeDetails).subscribe(res => {
    this.EditListRelatedAccountList = res.data;
    this.spinner.hide();
  })

}
detailDepartmentPage(id){
  this.router.navigate(["/hr/departments/detail-summary"],{queryParams:{updateId:id}})
 
}

onDeleteDepartment(id){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.deparmentsService.deleteDept(id).subscribe((res:any)=> {
        this.document.location.reload();
    },(err) => {
      this.toastr.error(err.error.message);
   })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
   
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}
EditDepartment() {
  this.spinner.show();
  this.MyContactsService.EditDepartment(this.employeeDetails).subscribe(res => {
    this.EditDepartmentList = res.data[0].EditDepartment;
    this.spinner.hide();
  })

}

updateTimesheetDetail(id) {
  this.router.navigate(['/hr/timesheets/detail-timesheet-summary'], { queryParams: { timesheetid: id } })
}
getContactList() {
  this.spinner.show();
  this.MyContactsService.getContactsLists().subscribe(res => {
    this.contactId = res.data;
    this.spinner.hide();
    for (const value of this.contactId) {
      this.contactArray.push({
        label: value.partyId,
        value: value.partyId
      })
    }
  })

}

onDeleteLeave(partyId, leaveTypeId, fromDate) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.employeeLeaveService.deleteLeave(partyId, leaveTypeId, fromDate).subscribe(res => {
        this.EditEmployeeLeaves();
      }, (err) => {
        this.toastr.error(err.error.message);
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
   
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })


}
EditEmployeeLeaves() {
  this.spinner.show();
  this.MyContactsService.EditEmployeeLeaves(this.employeeDetails).subscribe(res => {
    this.EditEmployeeLeavesList = res.data;
    this.spinner.hide();
  })

}
EditEmploymentApp() {
  this.spinner.show();
  this.MyContactsService.EditEmploymentApp(this.employeeDetails).subscribe(res => {
    this.EditEmploymentAppList = res.data[0].EditEmploymentApp;
    this.spinner.hide();
  })

}
EditPartyQual() {
  this.spinner.show();
  this.MyContactsService.EditPartyQual(this.employeeDetails).subscribe(res => {
    this.EditPartyQualList = res.data[0].EditPerfReview
    ;
    this.spinner.hide();
  })

}
qualificationDetail(partyId, partyQualTypeId, fromDate, qualificationDesc, title, statusId, verifStatusId, thruDate) {
  this.router.navigate(['/hr/detail-qualification'], {
    queryParams: {
      partyId: partyId, partyQualTypeId: partyQualTypeId,
      fromDate: fromDate, qualificationDesc: qualificationDesc, title: title, statusId: statusId,
      verifStatusId: verifStatusId, thruDate: thruDate
    }
  })
}

editTimeSheet() {
  this.spinner.show();
  this.MyContactsService.editTimeSheet(this.employeeDetails).subscribe(res => {
    this.editTimeSheetList = res.data[0].editTimeSheet;
    this.spinner.hide();
  })

}
detailEmployeePosition(id) {
  this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: id} })
}
EditEmployeePosition() {
  this.spinner.show();
  this.MyContactsService.EditEmployeePosition(this.employeeDetails).subscribe(res => {
    this.EditEmployeePositionList = res.data[0].EditEmployeePosition;
    this.spinner.hide();
  })

}
EditEmployeeSkills() {
  this.spinner.show();
  this.MyContactsService.EditEmployeeSkills(this.employeeDetails).subscribe(res => {
    this.EditEmployeeSkillList = res.data[0].EditEmployeeSkills;
    this.spinner.hide();
  })

}
detailSkillPages(id, skid, rating, skill, year) {
  this.router.navigate(["/hr/skills/skill-summary"], { queryParams: { updateId: id, skid: skid, rate: rating, skill: skill, year: year } })
}
onDeleteSkills(partyId, skillTypeId) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {
       
        this.EditEmployeeSkills();
        this.router.navigate(["/hr/skills"])
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )  } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  }) }
EditPerfReview() {
  this.spinner.show();
  this.MyContactsService.EditPerfReview(this.employeeDetails).subscribe(res => {
    this.EditPerfReviewList = res.data[0].EditPerfReview;
    this.spinner.hide();
  })

}
employeeProfile(id){
  this.router.navigate(['/hr/detail-performce-review'], { queryParams: { previewId: id } })

}
deletePartyQual(product) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      let req = {
        "fromDate": product.fromDate,
        "partyId": product.partyId,
        "partyQualTypeId": product.partyQualTypeId,
        "qualificationDesc": product.qualificationDesc,
        "statusId": product.statusId,
        "thruDate": product.thruDate,
        "title": product.title,
        "verifStatusId": product.verifStatusId,
      }
      this.accountsService.deletePartyQual(req).subscribe((res: any) => {
       this.EditPartyQual();
      }, (err) => {
        this.toastr.error(err.error.message);
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}

onSubmitTimeSheet() {
  this.spinner.show();
  let fromdate=this.createTimesheetFormService.create_timesheet_form.get('fromDate').value;
  this.updateefromdate=this.datePipe.transform(fromdate,"yyyy-MM-dd hh:mm:ss");

  let todate=this.createTimesheetFormService.create_timesheet_form.get('thruDate').value;
  this.updateetodate=this.datePipe.transform(todate,"yyyy-MM-dd hh:mm:ss");
  let formData={
    approvedByUserLoginId: this.createTimesheetFormService.create_timesheet_form.value.approvedByUserLoginId,
    clientPartyId:this.createTimesheetFormService.create_timesheet_form.value.clientPartyId,
    comments: this.createTimesheetFormService.create_timesheet_form.value.comments,
    fromDate:  this.updateefromdate,
    partyId: this.createTimesheetFormService.create_timesheet_form.value.partyId,
    statusId: this.createTimesheetFormService.create_timesheet_form.value.statusId,
    thruDate: this.updateetodate,
  }

  this.timeSheetService.createTimeSheet(formData).subscribe((res:any)=>{
    if(res.success) {
      this.toastr.success(res.data.successMessage);
      this.spinner.hide();
//this.getTimesheetList();
      this.createTimesheetFormService.create_timesheet_form.reset();
      this.closebutton8.nativeElement.click();
    }
    else if(res.success == false) {
      this.spinner.hide();
      this.toastr.error("Error");
    }
  },(err) => {
    this.spinner.hide();
    for(const data of err.error.errors){
      this.toastr.error(data.fieldName + ' ' + data.fieldError)
    }
 })

}


getLeaveReasonIdNew() {
  this.spinner.show();
  this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
    this.leaveTypeIds = res.data.leaveTypeId;
    this.spinner.hide();
    for (const value of this.leaveTypeIds) {
      this.leaveId.push({
        label: value.description,
        value: value.leaveTypeId
      })
    }
  })

}
onDeleteJob(id) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.jobRequistionService.onDelete(id).subscribe((res: any) => {
        if (res.success) {
         // this.getJobRequistionList();
        }

        else if (res.success == false) {
          this.toastr.error(res.message);
        }
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )  } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }})}
deletePartyResume(product){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      const formdata={
        "contentId": product.findPerfReview.contentId,
        "partyId": product.findPerfReview.partyId,
        "resumeId": product.findPerfReview.resumeId
      }
       //10021
      //10441
      this.accountsService.deletePartyResume(formdata).subscribe((res: any) => {
     
       
      }, (err) => {
        this.toastr.error(err.error.message);
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
   
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }})  
  }

employementDetail(partyId, partyQualTypeId, fromDate, qualificationDesc, title, statusId, verifStatusId, thruDate) {
  this.router.navigate(['/hr/detail-qualification'], {
    queryParams: {
      partyId: partyId, partyQualTypeId: partyQualTypeId,
      fromDate: fromDate, qualificationDesc: qualificationDesc, title: title, statusId: statusId,
      verifStatusId: verifStatusId, thruDate: thruDate
    }
  })
}


navigateJob(id) {
  this.router.navigate(["/hr/job-requisition/create-internal-posting"], { queryParams: { jobRequistionId: id } })
}

leavereasonNew() {
  this.spinner.show();
  this.addLeaveEmpService.getLeaveReason().subscribe(res => {
    this.leaveReason = res.data.leaveTypeId;
    this.spinner.hide();
    for (const value of this.leaveReason) {
      this.leaveReasonArray.push({
        label: value.description,
        value: value.emplLeaveReasonTypeId
      })
    }
  })

}

onSubmitLeave() {
  this.spinner.show();
  const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
  this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

  const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
  this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

  const formDate = {
    approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
    description: this.addLeaveService.add_leave_form.value.description,
    emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
    fromDate: this.updateefromdate,
    leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
    partyId: this.addLeaveService.add_leave_form.value.partyId,
    thruDate: this.updateetodate,
    leaveStatus: this.addLeaveService.add_leave_form.value.leaveStatus,
    
  }
  this.addLeaveEmpService.AddLeave(formDate).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success(res.data.successMessage);
      this.closebutton11.nativeElement.click();
      this.addLeaveService.add_leave_form.reset();
      this.spinner.hide();
    
    }
    else if (res.success == false) {
      this.spinner.hide();
      this.toastr.error(res.message.debugMessage);
    }
  }, (err) => {
    this.spinner.hide();
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + " " + value.fieldError);
    }
    
  })
 
 
}


getAllAccount(){
  this.spinner.show();
  const req={
    "contactMechTypeId": "",
    "groupName": "",
    "groupNameSearchType": "Contains",
    "partyId": "",
    "partyIdSearchType": "Contains"
  }
  let all = "true"
  this.createDeparmentsService.getAllAccount(this.employessSize,req,all).subscribe((res:any)=> {
    this.companyData = res.data;
    this.spinner.hide();
    for(const value of this.companyData) {
      this.companyArray.push({
        label:value.groupName,
        value:value.partyId
      })
    }
  })
 
}
onSubmitDepartment(){
  this.spinner.show();
  this.createDepartmentFormService.job_requistion_form.markAllAsTouched();
  const formData={...this.createDepartmentFormService.job_requistion_form.value};
  this.createDeparmentsService.createDepartMent(formData).subscribe(res=> {
    if(res.success) {
      this.toastr.success(res.data.successMessage);
      this.spinner.hide();
      //this.getdepartmentsList();
      this.createDepartmentFormService.job_requistion_form.reset();
      this.closebutton7.nativeElement.click();
      
    }
    else if(res.success == false) {
      this.spinner.hide();
      this.toastr.error("error");
    }
  },(err) => {
    this.spinner.hide();
    this.showError=(err.error.errors[0])
    this.toastr.error(this.showError.fieldName + " " +this.showError.fieldError)

 })

}
onSubmitContact() {
  this.spinner.show();


  let formData = {
    "accountPartyId": this.employeeDetails,
    "comments":this.RelatedCustomerForm.value.comments,
    "contactPartyId": this.RelatedCustomerForm.value.accountPartyId,
    "partyId":  this.employeeDetails
  }


  this.MyContactsService.RelatedAccount(formData).subscribe((res: any) => {
    if (res.success) {
      this.spinner.hide();
      this.toastr.success("Created Successfully");
      this.closebutton4.nativeElement.click();
      this.RelatedCustomerForm.reset();


    }

    else if (res.success == false) {
      this.toastr.error(res.message);
      this.spinner.hide();
    }
  }, (err) => {
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + " " + value.fieldError);
    }
    this.spinner.hide();
  })
}


getDepartment() {

  this.spinner.show();
  this.accountsService.getDepartment().subscribe(res => {
    this.Department = res.data;
    this.spinner.hide();
    for (const value of this.Department) {
      this.DepartmentArray.push({
        label: value.departmentName,
        value: value.departmentId
      })
    }
  })
}

getInternalOrganizations() {
  this.spinner.show();
  this.addEmployeePositionService.getInternalOrganization().subscribe(res => {
    this.internalOrganization = res.data;
    this.spinner.hide();
    for (const value of this.internalOrganization) {
      this.internalArray.push({
        label: value.groupName,
        value: value.partyId
      })
    }
   
  })
}

 

  onSubmits() {
    this.spinner.show();

    let fromActualdate = this.createQualification.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    let formData = {

      "fromDate": this.createQualification.value.fromDate,
      "partyId": this.employeeDetails,
      "partyQualTypeId": this.createQualification.value.PartyQualTypeID,
      "qualificationDesc": this.createQualification.value.QualificationDesc,
      "statusId": "CTNT_PUBLISHED",
      "thruDate": this.createQualification.value.ThroughDate,
      "title": this.createQualification.value.Title,
      "verifStatusId": "IXF_COMPLETE",
    }


    this.accountsService.createPartyQual(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton10.nativeElement.click();
        // this.router.navigate(["/hr/detail-qualification"], { queryParams: { partyID:this.createQualification.value.EmployeePartyID,fromdate:this.actualfromdate} })


      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })
  }

  getStatusVerifId() {
    this.spinner.show();
    this.accountsService.getStatusVerifId().subscribe(res => {
      this.VerifStatusID = res.data;
      this.spinner.hide();
      for (const value of this.VerifStatusID) {
        this.VerifStatusIDArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }


  getPartyQualType() {
    this.spinner.show();
    this.accountsService.getPartyQualType().subscribe(res => {
      this.PartyQualTypeId = res.data;
      this.spinner.hide();
      for (const value of this.PartyQualTypeId) {
        this.PartyQualTypeIdArray.push({
          label: value.partyQualTypeId,
          value: value.partyQualTypeId
        })
      }
    })
  }

  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.PartyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  onSubmitPerformanceReview() { 
    this.spinner.show();
    const fromActualdate=this.createReview.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createReview.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "comments": this.createReview.value.Comments,
  "emplPositionId": this.createReview.value.EmplPositionID,
  "employeePartyId": this.createReview.value.EmployeePartyID,
  // "employeeRoleTypeId": this.createReview.value.PartyTaxID,
  "employeeRoleTypeId":"EMPLOYEE",
  "fromDate": this.actualfromdate ,
  "fromDate_i18n": "",
  "managerPartyId":this.employeeDetails,
  "managerRoleTypeId": this.createReview.value.ManagerRoleTypeID,
  "paymentId": this.createReview.value.PaymentID,
  "perfReviewId": this.createReview.value.PerfReviewID,
  "thruDate": this.actualthroughdate,
  "thruDate_i18n": ""

    }
    this.accountsService.createPerfReview(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.EditPerfReview();

       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }

  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
     
     getPerfReview() {
      this.spinner.show();
      this.accountsService.getPerfReview().subscribe(res => {
        this.PerfReview = res.data;
        this.spinner.hide();
        for (const value of this.PerfReview) {
          this.PerfReviewArray.push({
            label: value.data.perfReviewId,
            value: value.data.perfReviewId
          })
        }
      })
    }

    getPaymentID() {
      this.spinner.show();
      this.accountsService.getPaymentID().subscribe(res => {
        this.PaymentID = res.data;
        this.spinner.hide();
        for (const value of this.PaymentID) {
          this.PaymentIDArray.push({
            label: value.data.paymentId,
            value: value.data.paymentId
          })
        }
      })
    }

    getPositionType() {
      this.spinner.show();
      let emplPosition = {
        actualFromDateFrom: "",
        actualFromDateTo: "",
        actualThruDateFrom: "",
        actualThruDateTo: "",
        budgetId: "",
        budgetIdSearchType: "Contains",
        budgetItemSeqId: "",
        budgetItemSeqIdSearchType: "Contains",
        emplPositionId: "",
        emplPositionIdSearchType: "Contains",
        emplPositionTypeId: "",
        emplPositionTypeIdSearchType: "Contains",
        estimatedStartDateFrom: "",
        estimatedStartDateTo: "",
        estimatedThruDateFrom: "",
        estimatedThruDateTo: "",
        exemptFlag: "",
        fulltimeFlag: "",
        partyId: "",
        partyIdSearchType: "Contains",
        salaryFlag: "",
        statusId: "",
        statusIdSearchType: "Contains",
        temporaryFlag: ""
      }
      this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {
  
        this.positionType = res.data;
        this.spinner.hide();
        for (const value of this.positionType) {
          this.positionArray.push({
            label: value.emplPositionId,
            value: value.emplPositionId
          })
        }
      })
     
    }

    getStatusList(){
      this.spinner.show();
      this.timeSheetService.getStatusList().subscribe(res=> {
        this.statusList = res.data;
        this.spinner.hide();
        for(const data of this.statusList) {
          this.statusArray.push({
            label:data.description,
            value:data.statusId
          })
        }
      })
  
    }

    getUserLoginId(): void {
      this.spinner.show();
      this.timeSheetService.getUserLogin().subscribe((res) => {
        let userLogin = res.data;
        this.spinner.hide();
        for (const value of userLogin) {
          if (value["groupName"] === null) {
            value["groupName"] = "";
          }
          if (value["firstName"] === null) {
            value["firstName"] = "";
          }
          if (value["middleName"] === null) {
            value["middleName"] = "";
          }
          if (value["lastName"] === null) {
            value["lastName"] = "";
          }
          let name =
            value["userLoginId"] +
            " " +
            value["groupName"] +
            " [" +
            value["firstName"] +
            " " +
            value["middleName"] +
            " " +
            value["lastName"] +
            "]";
          this.userLoginArray.push({
            label: name,
            value: value.userLoginId,
          });
        }
      }); 
    
    }

    onSubmitSign() {
      this.spinner.show();
      const formData = new FormData();
      formData.append("file", this.fileData);
  
      this.accountsService.attachFile(formData).subscribe((res: any) => {
        if (res.success) {
          this.spinner.hide();
          this.toastr.success(res.data.responseMessage);
          this.signatureURL = res.data.URL;
          this.closebutton3.nativeElement.click();
  
  
        }
      }, (err) => {
        this.toastr.error(err.message);
        this.spinner.hide();
      })
  
    }

    findApplicationEmployment() {
      this.accountsService.findApplicationEmployment().subscribe((res: any) => {
        this.spinner.show();
        this.employmentApplication = res.data;
        this.spinner.hide();
      })
    }
    findApplicationEmploymentId() {
      this.accountsService.findApplicationEmploymentId(this.empTypeId).subscribe((res: any) => {
        this.spinner.show();
        this.employmentApplication = res.data;
        this.spinner.hide();
      })
    }

    onSubmitEmployment() {
      this.spinner.show();
  
       const fromActualdate=this.createEmploymentForm.get('empDate').value;
       let empDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
  
       const Available =this.createEmploymentForm.get('DateAvailable').value;
       let DateAvailable =this.datePipe.transform(Available,"yyyy-MM-dd hh:mm:ss");
  
      const expiration =this.createEmploymentForm.get('Expiration').value;
      let expirationDate =this.datePipe.transform(expiration,"yyyy-MM-dd hh:mm:ss");
  
      const expirationOne =this.createEmploymentForm.get('ExpirationDate').value;
      let expirationDate1 =this.datePipe.transform(expirationOne,"yyyy-MM-dd hh:mm:ss");
  
      const dateHire =this.createEmploymentForm.get('dateHire').value;
      let hireDate =this.datePipe.transform(dateHire,"yyyy-MM-dd hh:mm:ss");
  
  
      const formData = {
        "name": this.createEmploymentForm.value.name,
        "empTypeId": this.empTypeId,
        "address": this.createEmploymentForm.value.address,
        "socialSecurityNumber": this.createEmploymentForm.value.socailSecurityNumber,
        "telephoneNumber": this.createEmploymentForm.value.telephoneNo,
        "phoneNumber": this.createEmploymentForm.value.OtherPhonenumber,
        // "date": this.createEmploymentForm.value.empDate,
        "date": empDate,
        "felonyCrime": this.createEmploymentForm.value.crime,
        "description": this.createEmploymentForm.value.reason,
  
  
        "positionAppliedFor": this.createEmploymentForm.value.PositionAppliedFor,
        // "availableDate": this.createEmploymentForm.value.DateAvailable,
        "availableDate": DateAvailable,
        "desirePayRate": this.createEmploymentForm.value.payRate,
        "availability": this.createEmploymentForm.value.available,
        "referredToUs": this.createEmploymentForm.value.referred,
  
        "schoolAddress1": this.createEmploymentForm.value.SchoolAddress,
        "schoolAddress2": this.createEmploymentForm.value.SchoolAddressTwo,
        "schoolAddress3": this.createEmploymentForm.value.SchoolAddressThree,
        "schoolAddress4": this.createEmploymentForm.value.SchoolAddressFour,
        "degree1": this.createEmploymentForm.value.degree,
        "degree2": this.createEmploymentForm.value.degreeTwo,
        "degree3": this.createEmploymentForm.value.degreeThree,
        "degree4": this.createEmploymentForm.value.degreeFour,
  
        "professionalLicense": this.createEmploymentForm.value.ProfessionalLicense,
        // "expirationDate": this.createEmploymentForm.value.Expiration,
        "expirationDate": expirationDate,
        "applicationHereBefore": this.createEmploymentForm.value.applicationFilled,
        "explain": this.createEmploymentForm.value.explain,
        "meansOfTransportation": this.createEmploymentForm.value.transportation,
        "driverLicense": this.createEmploymentForm.value.license,
        "driverLicenseNumber": this.createEmploymentForm.value.LicenseNumber,
        "stateOfIssue": this.createEmploymentForm.value.StateofIssue,
        // "expirationDate1": this.createEmploymentForm.value.ExpirationDate,
        "expirationDate1": expirationDate1,
        "referencesAddress1": this.createEmploymentForm.value.addressOne,
        "referencesAddress2": this.createEmploymentForm.value.secondAddress,
        "referencesName1": this.createEmploymentForm.value.nameOne,
        "referencesName2": this.createEmploymentForm.value.nameTwo,
  
        "referencesTelephoneNumber2": this.createEmploymentForm.value.TelephoneNumberTwo,
        "referencestTelephoneNumber1": this.createEmploymentForm.value.TelephoneNumber,
  
        "employer": this.createEmploymentForm.value.Employer,
        "typeOfBusiness": this.createEmploymentForm.value.TypeofBusiness,
        "employmentAddress": this.createEmploymentForm.value.EmpAddress,
        "employmentPhone": this.createEmploymentForm.value.Phone,
        "titlesAndDuties": this.createEmploymentForm.value.TitlesDuties,
        "ratePay": this.createEmploymentForm.value.RatePay,
        "periodEmployed": this.createEmploymentForm.value.PeriodEmployed,
        "reasonForLeaving": this.createEmploymentForm.value.ReasonforLeaving,
        "supervisorName": this.createEmploymentForm.value.SupervisorName,
  
  
        "comments": this.createEmploymentForm.value.Comments,
        "departments": this.createEmploymentForm.value.Department,
        "position": this.createEmploymentForm.value.position,
        // "hireDate": this.createEmploymentForm.value.dateHire,
        "hireDate": hireDate,
        "salary": this.createEmploymentForm.value.Salary,
        "signatureUrl": this.signatureURL,
  
      }
      this.accountsService.createApplicationEmployment(formData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.findApplicationEmployment();
  
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
  
    }
  
  
    getInternalJobDropdwonNew() {
      this.spinner.show();
      const jobList = {
        applicationDateFrom: "",
        applicationDateTo: "",
        applicationId: "",
        applyingPartyId: "",
        approverPartyId: "",
        jobRequisitionId: "",
        statusId: "",
        applicationDateFromSearchType: "Equals",
        applicationDateToSearchType: "Equals"
      }
      this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
        this.internalJobPostList = res.data;
        this.spinner.hide();
        for (const value of this.internalJobPostList) {
          this.appIdArray.push({
            label: value.applicationId,
            value: value.applicationId
          })
        }
      })
  
    }
    getAvailabilty() {
      this.spinner.show();
      this.accountsService.getAvailabilty().subscribe(res => {
        this.available = res.data[0].data;
        this.spinner.hide();
        for (const value of this.available) {
          this.AvailabilityArray.push({
            label: value.availability,
            value: value.availability
          })
        }
      })
    }
  
  
    getPositionTypeNew() {
      this.spinner.show();
      const emplPosition = {
        actualFromDateFrom: "",
        actualFromDateTo: "",
        actualThruDateFrom: "",
        actualThruDateTo: "",
        budgetId: "",
        budgetIdSearchType: "Contains",
        budgetItemSeqId: "",
        budgetItemSeqIdSearchType: "Contains",
        emplPositionId: "",
        emplPositionIdSearchType: "Contains",
        emplPositionTypeId: "",
        emplPositionTypeIdSearchType: "Contains",
        estimatedStartDateFrom: "",
        estimatedStartDateTo: "",
        estimatedThruDateFrom: "",
        estimatedThruDateTo: "",
        exemptFlag: "",
        fulltimeFlag: "",
        partyId: "",
        partyIdSearchType: "Contains",
        salaryFlag: "",
        statusId: "",
        statusIdSearchType: "Contains",
        temporaryFlag: ""
      }
      this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {
  
        this.positionType = res.data;
        this.spinner.hide();
        for (const value of this.positionType) {
          this.positionArray.push({
            label: value.emplPositionId,
            value: value.emplPositionId
          })
        }
      })
  
    }
    getPartyIdNew() {
      this.spinner.show();
      this.addSkillService.getPartyId().subscribe(res => {
        this.personId = res.data.PersonsGroups;
        this.spinner.hide();
        for (const value of this.personId) {
          this.partyIdArray.push({
            label: value.partyId,
            value: value.partyId
          })
        }
      })
  
    }
    statusIdNew() {
      this.spinner.show();
      this.employeeApplicationService.getStatusList().subscribe(res => {
        this.statusList = res.data;
        this.spinner.hide();
        for (const value of this.statusList) {
          this.statusArray.push({
            label: value.description,
            value: value.statusId
          })
        }
      })
  
    }
    onSubmitEmplyeeApplicatoin() {
      this.spinner.show();
      const thrudate = this.createEventFormService.employee_application_form.get('applicationDate').value;
      this.updateethrdateStatus = this.datePipe.transform(thrudate, "yyyy-MM-dd hh:mm:ss");
      const formDate = {
        applicationDate: this.updateethrdateStatus,
        applicationId: this.createEventFormService.employee_application_form.value.applicationId,
        applyingPartyId: this.createEventFormService.employee_application_form.value.applyingPartyId,
        emplPositionId: this.createEventFormService.employee_application_form.value.emplPositionId,
        employmentAppSourceTypeId: this.createEventFormService.employee_application_form.value.employmentAppSourceTypeId,
        referredByPartyId: this.createEventFormService.employee_application_form.value.referredByPartyId,
        statusId: this.createEventFormService.employee_application_form.value.statusId
      }
  
      this.employeeApplicationService.createEmploymentApplication(formDate).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.responseMessage);
          this.spinner.hide();
          this.createEventFormService.employee_application_form.reset();
          this.getEmployeeApplication();
          this.closebutton.nativeElement.click();
          this.router.navigate(['/hr/employee-application'])
        }
        else if (res.success == false) {
          this.toastr.error("Error");
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        for (const data of err.error.errors) {
          this.toastr.error(data.fieldName + ' ' + data.fieldError)
        }
      })
  
    }
    appSourceTypeListNew() {
      this.spinner.show();
      this.employeeApplicationService.getAppsourceList().subscribe(res => {
        this.appSource = res.data;
        this.spinner.hide();
        for (const data of this.appSource) {
          this.appSourceArray.push({
            label: data.description,
            value: data.employmentAppSourceTypeId
          })
        }
      })
  
    }
    detailPage(empTypeId) {
      this.router.navigate(["/hr/employee-application-summary"], { queryParams: { empTypeId: empTypeId } })
  
    }
    appSourceTypeList() {
      this.spinner.show();
      this.employeeApplicationService.getAppsourceList().subscribe(res => {
        this.appSource = res.data;
        this.spinner.hide();
        for (const data of this.appSource) {
          this.appSourceArray.push({
            label: data.description,
            value: data.employmentAppSourceTypeId
          })
        }
      })
  
    }
    getInternalJobDropdwon() {
      this.spinner.show();
      const jobList = {
        applicationDateFrom: "",
        applicationDateTo: "",
        applicationId: "",
        applyingPartyId: "",
        approverPartyId: "",
        jobRequisitionId: "",
        statusId: "",
        applicationDateFromSearchType: "Equals",
        applicationDateToSearchType: "Equals"
      }
      this.internalJobService.getInternalJobList(this.jobPost, jobList).subscribe((res: any) => {
        this.internalJobPostList = res.data;
        this.spinner.hide();
        for (const value of this.internalJobPostList) {
          this.appIdArray.push({
            label: value.applicationId,
            value: value.applicationId
          })
        }
      })
  
    }
    
    statusId() {
      this.spinner.show();
      this.employeeApplicationService.getStatusList().subscribe(res => {
        this.statusList = res.data;
        this.spinner.hide();
        for (const value of this.statusList) {
          this.statusArray.push({
            label: value.description,
            value: value.statusId
          })
        }
      })
  
    }

    getEmployeeApplication() {
      this.spinner.show();
      const data = {
        "applicationDateFrom": "",
        "applicationDateFromSearchType": "Contains",
        "applicationDateTo": "",
        "applicationDateToSearchType": "Contains",
        "applicationId": "",
        "applyingPartyId": "",
        "emplPositionId": "",
        "employmentAppSourceTypeId": "",
        "referredByPartyId": "",
        "statusID": [
          ""
        ]
      }
      this.employementApplicationService.getEmpJobList(this.applicationSize, data).subscribe(res => {
        this.employmentApplication = res.data;
        this.spinner.hide();
      })
    }
  
    onSubmitEmployeePosition() {
      this.spinner.show();
      let partyid = this.createEmployeePositionService.addEmployee_position_form.get('internalOrganization').value;
  
      this.createEmployeePositionService.addEmployee_position_form.get('partyId').setValue(partyid);
      let fromdate = this.createEmployeePositionService.addEmployee_position_form.get('fromDate').value;
      this.estifromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");
      let throughdate = this.createEmployeePositionService.addEmployee_position_form.get('thruDate').value;
      this.estithroughdate = this.datePipe.transform(throughdate, "yyyy-MM-dd hh:mm:ss");
  
      let fromActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualFromDate').value;
      this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
      let throughActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualThruDate').value;
      this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
  
      let formData = {
        "actualFromDate": this.actualfromdate,
        "actualThruDate": this.actualthroughdate,
        "budgetId": this.createEmployeePositionService.addEmployee_position_form.value.budgetId,
        "budgetItemSeqId": this.createEmployeePositionService.addEmployee_position_form.value.budgetItemSeqId,
        "empPositionId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionId,
        "empPositionTypeId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionTypeId,
        "exemptFlag": this.createEmployeePositionService.addEmployee_position_form.value.exemptFlag,
        "fromDate": this.estifromdate,
        "fulltimeFlag": this.createEmployeePositionService.addEmployee_position_form.value.fulltimeFlag,
        "partyId": this.createEmployeePositionService.addEmployee_position_form.value.partyId,
        "salaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.salaryFlag,
        "statusId": this.createEmployeePositionService.addEmployee_position_form.value.statusId,
        "temporaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.temporaryFlag,
        "thruDate": this.estithroughdate,
        "internalOrganization": this.createEmployeePositionService.addEmployee_position_form.value.internalOrganization
      }
  
    
      this.addEmployeePositionService.createEmployeePosition(formData).subscribe((res: any) => {
        if (res.success) {
          this.spinner.hide();
          this.toastr.success("Form Submitted Successfully");
          this.getEmployeePosition();
          this.createEmployeePositionService.addEmployee_position_form.reset();
          this.closebutton.nativeElement.click();
          // this.router.navigate(['/hr/employee-position']);
        }
  
        else if (res.success == false) {
          this.toastr.error(res.message);
          this.spinner.hide();
        }
      }, (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
      })
  
  
    }

    getEmployeePosition() {
      this.spinner.show();
      const emplPosition = {
        actualFromDateFrom: "",
        actualFromDateTo: "",
        actualThruDateFrom: "",
        actualThruDateTo: "",
        budgetId: "",
        budgetIdSearchType: "Contains",
        budgetItemSeqId: "",
        budgetItemSeqIdSearchType: "Contains",
        emplPositionId: "",
        emplPositionIdSearchType: "Contains",
        emplPositionTypeId: "",
        emplPositionTypeIdSearchType: "Contains",
        estimatedStartDateFrom: "",
        estimatedStartDateTo: "",
        estimatedThruDateFrom: "",
        estimatedThruDateTo: "",
        exemptFlag: "",
        fulltimeFlag: "",
        partyId: "",
        partyIdSearchType: "Contains",
        salaryFlag: "",
        statusId: "",
        statusIdSearchType: "Contains",
        temporaryFlag: ""
      }
      this.employeePositionService.getEmployeePosition(this.employessSize, emplPosition).subscribe((res: any) => {
        this.employeePosition = res.data;
        this.spinner.hide();
      })
     
    }

    
    getStatus() {
      this.spinner.show();
      this.addEmployeePositionService.getStatus().subscribe(res => {
        this.status = res.data.emplPositionStatus;
        this.spinner.hide();
        for (const value of this.status) {
          this.statusArray.push({
            label: value.description,
            value: value.statusId
          })
        }
      })
    }

    detail(id) {
      this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: id} })
    }
  
    onUpdateEmployeePosition(id) {
      this.router.navigate(["hr/employee-position/add-employee-position"], { queryParams: { updateId: id } })
    }

    getSkillListtype() {
      this.spinner.show();
      this.addSkillService.getSkillTypeList().subscribe(res=> {
        this.getSkillLists = res.data;
        this.spinner.hide();
        for(const value of this.getSkillLists) {
          this.skillArray.push({
            label:value.description,
            value:value.skillTypeId
          })
        }
       
      })
     
    }

    getSkillListView() {
      this.spinner.show();
      this.addSkillService.getSkillTypeList().subscribe(res => {
        this.getSkillLists = res.data;
        this.spinner.hide();
        for (const value of this.getSkillLists) {
          this.skillArray.push({
            label: value.description,
            value: value.skillTypeId
          })
        }
        
      })
     
    }

    onSubmitSkill() {
      this.spinner.show();
      const formData = {...this.addSkillsService.add_skill_form.value};
      this.addSkillService.createSkills(formData).subscribe((res:any)=> {
        if(res.success) {
          this.spinner.hide();
          this.toastr.success("Skill Subbmitted Successfully");
          this.addSkillsService.add_skill_form.reset();
          this.getSkillList();
          this.closebutton9.nativeElement.click();
          // this.router.navigate(['/hr/skills'])
        }
  
        else if(res.success == false) {
          this.toastr.error(res.message);
          this.spinner.hide();
        }
      },(err) => {
        for(const value of err.error.errors){
          this.toastr.error(value.fieldName + " " + value.fieldError);
          }
          this.spinner.hide();
     })
  
    }

    getSkillList() {
      this.spinner.show();
      const req = {
        "pageNo": this.pageNo,
        "pageSize": this.pageSize
      }
      let skillList = {
        partyId: "",
        rating: "",
        skillLevel: "",
        skillTypeId: "",
        yearsExperience: ""
      }
  
  
      this.skillService.getSkillList(req, skillList).subscribe(res => {
        this.skillList = res.data;
        this.spinner.hide();
      })
     
    }

    
    detailPages(id, skid, rating, skill, year) {
      this.router.navigate(["/hr/skills/skill-summary"], { queryParams: { updateId: id, skid: skid, rate: rating, skill: skill, year: year } })
    }
    detailPageLeave(epartyId, eleaveTypeId, efromDate, ethruDate, eAppId, eDescription, eReasonid) {
      this.router.navigate(["/hr/employee-leave/leave-summary"], { queryParams: { partyId: epartyId , leaveTypeId: eleaveTypeId, fromDate: efromDate, thruDate: ethruDate, AppId: eAppId, Description: eDescription, Reasonid: eReasonid, approval: false } })
    }
    changeactiveCategoryFunctions(tab: number) {
      this.activeCategoryTabs = tab;
    }

    getQualificationNew() {
      this.spinner.show();
      this.newJobRequistionsService.getQualification().subscribe(res => {
        this.qualification = res.data;
        this.spinner.hide();
        for (const qual of this.qualification.qualifications) {
          this.qualArray.push({
            label: qual.description,
            value: qual.partyQualTypeId
          })
        }
        for (const exam of this.qualification.examEnumtype) {
          this.enumArray.push({
            label: exam.description,
            value: exam.enumId
          })
        }
      })
      
    }

    getSkillTypeListNew() {
      this.spinner.show();
      this.newJobRequistionsService.getSkillTypeList().subscribe(res => {
        this.getSkill = res.data;
        this.spinner.hide();
        for (const value of this.getSkill) {
          this.skillTypeArray.push({
            label: value.description,
            value: value.skillTypeId
          })
        }
      })
     
    }
    
  

  // End Code 



  update(): void {
    this.spinner.show();
    if (this.emergencyContactForm.valid) {
        this._DetailedEmployeeSummary.updateEmergencyContact(this.updateId, {
            ...this.updateValue, ...this.emergencyContactForm.value
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.emergencyContactForm.reset();
                    this.closebutton6.nativeElement.click();
                    this.getEmployeeDetail();

                }
            });
    }
    this.spinner.hide();
}
submit(): void {
    this.spinner.show();
    if (this.emergencyContactForm.valid) {
        this._DetailedEmployeeSummary.createEmergencyContact(this.updateId, this.emergencyContactForm.value)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.spinner.hide();
                    this.emergencyContactForm.reset();
                    this.closebutton6.nativeElement.click();
                    this.getEmployeeDetail();

                }
            });
    }
    this.spinner.hide();
}

  navigate() {
    this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId:this.partyId}});
  }

  navigateFromCreate()
  {
    this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId: this.employeeDetails}});
  }
  
  onSubmitNote() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value }
    this.employeeService.createContactNote(form,this.employeeDetails).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.fileUploadForm.reset();
        this.getLeadNote();
      }
    }, (err) => {
     
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  onUpdateNote()
  {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value}
    this.employeeService.updateContactNote(form,this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.fileUploadForm.reset();
        this.getLeadNote();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();

  }


  getEmpAccountRelated() {
    
    this.spinner.show();
    const req = {

      "contactMechTypeId": this.contactMechTypeId,
      "groupName": this.groupName,
      "groupNameSearchType": this.groupNameSearchType,
      "partyId": this.employeeDetails,
      "partyIdSearchType": this.partyIdSearchType

    }
    const all = "true"
    this.employeeService.getMyAccountRelatedStatus(req, all).subscribe(res => {
      this.crmAccount = res.data;
      this.spinner.hide();
      for (const value of this.crmAccount) {
        this.accountArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }



  getContactDetails(): void {
    this.spinner.show();
    this._DetailedEmployeeSummary.getTelecomById(this.contactMechId, this.partyId)
      .then(data => {
        this.spinner.hide();
        this.contact = {
          areaCode: data.data.areaCode,
          askForName: data.data.askForName,
          countryCode: data.data.countryCode,
          contactNumber: data.data.contactNumber,
          contactMechId: data.data.contactMechId,
          allowSolicitation: data.data.allowSolicitation,
          contactMechPurposeTypeId: data.data.contactMechPurposeTypeId,
          contactMechTypeId: data.data.contactMechTypeId,
          extension: data.data.extension,
          partyId: data.data.partyId,
          preContactMechTypeId: 'TELECOM_NUMBER'
        };
        this.contactForm.patchValue(this.contact);
        this.contactForm.controls.contactMechPurposeTypeId.disable();
        this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
      });

  }
  getContactPurpose(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'TELECOM_NUMBER')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.contactPurpose = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });

  }
  updateTelecom(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.updateEmployeeTelecomNumber({
        ...this.contact, ...this.contactForm.value
      })
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this._ToastrService.success('Updated');
            this.closebutton3.nativeElement.click();
            this.contactForm.reset();
            this.getEmployeeDetailPostal();

          }
        });
    }
  }
  submitTelecom(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.createEmployeeTelecomNumber({
        ...this.contactForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.contactForm.reset();
            this.spinner.hide();
            this.closebutton3.nativeElement.click();
            this.getEmployeeDetailPostal();


          }
        });
    }
    this.spinner.hide();
  }

  onSubmitPostal() {
    this.spinner.show();
    let formData = { ...this.addEmployeeFormService.postal_employee_form.value }

    this.employeeService.creatPostalAddress({
      ...formData, ...{
        partyId: this.updateId
      }
    }).then((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getEmployeeDetailPostal();
        this.addEmployeeFormService.reset();
      }
      else if (res.success == false) {
        this.toastr.error(res.message);
      }
    });
    this.spinner.hide();
  }

  getEmployeeDetailPostal() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.updateId).subscribe(res => {
      this.spinner.hide();
      res.data.contactInformation.pop();
      res.data.contactInformation.filter(value => {
        if (value.contactMech.contactMechId === this.contactMechId) {
          this.countryId = value.postalAddress.countryGeoId;
          this.getStateList();
          const formValue = this.addEmployeeFormService.postal_employee_form;
          formValue.patchValue({
            address1: value.postalAddress.address1,
            address2: value.postalAddress.address2,
            partyId: this.updateId,
            countryGeoId: value.postalAddress.countryGeoId,
            postalCode: value.postalAddress.postalCode,
            countryCode: value.postalAddress.countryGeoId,
            city: value.postalAddress.city,
            stateProvinceGeoId: value.postalAddress.stateProvinceGeoId,
            contactMechId: this.contactMechId,
          });
        }

      });

    })
  }



  getCountryList() {
    this.spinner.show();
    this.employeeService.getCountry().subscribe(res => {
      this.spinner.hide();
      var country = res.data.countryList;
      country.forEach(element => {
        this.array.push({
          label: element,
          value: element.split(":").pop().trim()
        })

      })

    })


  }



  onCountryChange(event) {
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }

  getStateList() {
    this.spinner.show();
    this.employeeService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];

        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })

  }
  getInternalOrganization() {
    this.spinner.show();
    this.employeeService.internalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.organizationArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })

  }
 

  onUpdatePostal() {
    this.spinner.show();
    const formData = { ... this.addEmployeeFormService.postal_employee_form.value };
    this.employeeService.updatePostalAddress({
      ...formData, ...{
        partyId: this.updateId
      }
    }).then((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        // this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.updateId } });
      } else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message);
        this.closebutton2.nativeElement.click();
        this.getEmployeeDetailPostal();
        this.addEmployeeFormService.reset();

      }
    });

  }

  onUpdate() {
    this.spinner.show();
    const formData = { ... this.empPersonalFormService.emp_personal_form.value };
    this.employeeService.updateEmpInfo(this.employeeDetails, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.getEmployeeDetail();
        this.closebutton.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })

  }


  getEmpStatus() {
    this.spinner.show();
    this.employeeService.getStatus().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyList = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });

  }
  getEmployeeDetailEdit() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.employeeDetails).subscribe(res => {
      this.spinner.hide();
      const formValue = this.empPersonalFormService.emp_personal_form;
      formValue.patchValue({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        partyId: res.data.partyId,
        statusId: res.data.statusId,
        description: res.data.comments,
        externalId: res.data.externalId,
        comments: res.data.comments,
        preferredCurrencyUomId: res.data.preferredCurrencyUomId
      })

    })

  }
 
  uploadImage() {
    this.router.navigate(['/hr/upload-hr-image'], { queryParams: { demoId: this.demoId } });
  }

  getEmployeePersonalImage() {
    this.spinner.show();
    this.accountsService.getEmployeePersonalImage(this.employeeDetails).subscribe(res => {

      if (res.data.imageUrl == 'null') {
        this.imagePic = false;
        this.imageView = "";
      }
      else {
        this.imagePic = true;
        this.imageView = res.data;
        this.spinner.hide();
      }
      this.spinner.hide();
    });

  }
  getLeadNote() {
    this.spinner.show();
    this.detailedEmployeeSummary.getLeadNotesList(this.employeeDetails).subscribe(res => {
      this.leadNoteId = res.data;
      this.spinner.hide();
    });

  }
  getPartyContent() {
    this.spinner.show();
    this.detailedEmployeeSummary.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        })
      }
    })

  }
  onSubmit() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;
    this.detailedEmployeeSummary.attachPartyContent(formData, this.partyContentTypeId, this.employeeDetails)
      .subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.getPartyContentsList();
        }
      }, (err) => {
        this.toastr.error(err.message);
      });
    this.spinner.hide();
  }
  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._detailedContactsService.deleteContent(contentId, fromDate, partyContent, partyId).subscribe((res: any) => {
          if (res.success) {
            this.getPartyContentsList();
          }
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  export(id: any, contentName: any) {
    this.accountsService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
  getPartyContentsList() {
    this.spinner.show();
    this.detailedEmployeeSummary.getPartyContentList(this.employeeDetails).subscribe(res => {
      this.contentList = res.data;
      this.spinner.hide();
    });

  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }


  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.employeeDetails).subscribe(res => {
      this.employeeDetail = res.data;
      this.spinner.hide();
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      if (res.data.emergencyContacts != 'no emergency contacts found') {
        this.emergencyContact = res.data.emergencyContacts;
      }
      this.demoName = res.data.firstName + " " + res.data.lastName;
      this.demoId = res.data.partyId;
      this.employeeDetailArray.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayEmail.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayPhone.push({
        data: this.employeeDetail
      })

    })

  }


  getEmployeeDetailRelatedAccount() {
    this.spinner.show();
    this.employeeService.getEmployeeAccountRelated(this.employeeDetails).subscribe(res => {
      this.employeeDetailRelatedAccount = res.data.relatedCompanies;
      this.spinner.hide();
    })

  }
  getEmployeeTrainingData() {
    this.spinner.show();
    this.employeeService.getEmployeeAccountRelated(this.employeeDetails).subscribe(res => {
      this.empTraining = res.data.personTraining;
      this.spinner.hide();
    })

  }
  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.detailedEmployeeSummary.deleteNote(noteId).subscribe(res => {
          if (res.success) {
            this.getLeadNote();
          }
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getAllDepartment() {
    this.spinner.show();
    const req = {
      "companyId": "",
      "companyIdSearchType": "Contains",
      "departmentId": "",
      "departmentIdSearchType": "Contains",
      "departmentName": "",
      "departmentNameSearchType": "Contains"
    }
    this.detailedEmployeeSummary.getAllDepartment(this.employessSize, req).subscribe((res: any) => {
      this.departmentList = res.data;
      this.spinner.hide();
      for (const value of this.departmentList) {
        this.departmantArray.push({
          label: value.departmentName,
          value: value.departmentId
        })
      }
    })

  }
  onChange(event) {
    this.departmentId = event
  }
  assign() {
    this.spinner.show();
    this.detailedEmployeeSummary.assignDepartment(this.employeeDetail.partyId, this.departmentId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.document.location.reload();
        this.spinner.hide();
      }
      else {
        this.toastr.error("error");
        this.spinner.hide();
      }
    })

  }
  updatePerson() {
    this.router.navigate(['/hr/employees/update-personal-info'], { queryParams: { personId: this.employeeDetails } })
  }

  contactInformationPerson() {
    this.router.navigate(['/hr/employees/contact-personal-info'], { queryParams: { personId: this.employeeDetails } })
  }
  updateData(partyId: string, type: string) {
    switch (type) {
      case 'POSTAL_ADDRESS':
        this.router.navigate(['/hr/employees/update-address'], { queryParams: { employeeDetailId: this.employeeDetails, contactMechId: partyId } })
        break;
      case 'EMAIL_ADDRESS':
        this.router.navigate(['/hr/employees/update-email'], { queryParams: { personId: this.employeeDetails, cid: partyId } })
        break;
      case 'TELECOM_NUMBER':
        this.router.navigate(['/hr/employees/contact'], { queryParams: { partyId: this.employeeDetails, contactMechId: partyId } })
    }


  }
  createNote() {
    this.router.navigate(['/hr/employees/emp-notes'], { queryParams: { contactIdd: this.employeeDetails } })
  }

  empAccountRelate() {
    this.router.navigate(['/hr/employees/add-emp-account'], { queryParams: { personId: this.employeeDetails } })
  }
  updateTelephone(epartyId, ecid) {

    this.router.navigate(['/hr/employees/update-phone-number'], { queryParams: { personId: epartyId, 'cid': ecid } })
  }
  updateEmail(epartyId, ecid) {

    this.router.navigate(['/hr/employees/update-email'], { queryParams: { personId: epartyId, 'contactMechId': ecid } })
  }

  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['/hr/employees/emp-notes'], { queryParams: { noteId: enoteId, contactId: this.employeeDetails, noteName: enoteName, note: enote } })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
}
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
