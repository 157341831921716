import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-detail-timesheet',
  templateUrl: './detail-timesheet.component.html',
  styleUrls: ['./detail-timesheet.component.css']
})
export class DetailTimesheetComponent implements OnInit {
  workEffortName: any;
  workEffortNameArray: any[] = [];
  rateTypeID: any;
  rateTypeIDArray: any[] = [];
  selectedDate: string;
  updateTimesheet: FormGroup;
  addnewtimsheet: FormGroup;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 7;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  partyId: any;
  pageNo = 1;
  sprintTable: any;
  productBacklogForm: FormGroup;
  productForm: FormGroup;
  partyName: any;
  timesheetForm: FormGroup;
  Party: any;
  partIdDes: any;
  requiredDate: string;
  PartyIdArray: any[] = [];
  PartyId: any;
  TimeSheetProjectList: any;
  timeSheetLists: any;
  TasksNamesList: any;
  TasksNamesListArray: any[]=[];
  rateTypes: any;
  rateTypesArray: any[]=[];
  constructor(public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        private _location: Location,
        readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {
    this.updateTimesheet = this._FormBuilder.group({
      taskName: [''],
      rateTypeID: [''],
      planHrs: [''],
      mon: [''],
      tue: [''],
      wed: [''],
      thu: [''],
      fri: [''],
      sat: [''],
      sun: [''],
      actualHours: [''],
      actTotal: [''],

    });
    this.timesheetForm = this._FormBuilder.group({
      Party: [''],
      SelectDate: [''],


    });
    this.addnewtimsheet = this._FormBuilder.group({
      selectDate: [''],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params.partyId;
      this.partyName = params.party;

    })


    this.timeSheetList();
    this.getRateTypeId();
    this.getworkEffortName();
    this.getPartyList();
    this.getTasksNames();
    this.getTasksNames();
    this.getRateData();
  }
  getRateData() {
    this.spinner.show();
    this.myContactsService.getRateData().subscribe(res => {

      this.rateTypes = res.data.rateTypes;

      this.spinner.hide();

     
      for (const value of this.rateTypes) {
        this.rateTypesArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getTasksNames() {
    this.spinner.show();
    this.myContactsService.getTasksNames().subscribe(res => {
      this.TasksNamesList = res.data;
      this.spinner.hide();
      for (const value of this.TasksNamesList) {
        this.TasksNamesListArray.push({
          label: value.node.workEffortName,
          value: value.node.workEffortId
        })
      }
    })
  }
  MyStatusToComplete(statusId,timesheetId) {
    this.spinner.show();
    const sdate = this.timesheetForm.get('SelectDate').value;
    this.requiredDate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
   
    const formData = {
      "partyId":this.timesheetForm.value.Party,
      "requiredDate":this.requiredDate
    }
    this.myContactsService.MyStatusToComplete(statusId,timesheetId).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
     //   this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  timeSheetList() {
    this.spinner.show();
    this.myContactsService.timeSheetList().subscribe(res => {
      this.timeSheetLists = res.data.filterData;
      this.spinner.hide();
    })
  }
  getPartyList() {
    this.spinner.show();
    this.myContactsService.getPartyList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  createWeeklyProTimesheet() {
    this.spinner.show();
    for (const r of this.Party) {
      this.partIdDes = r.description;
    }
    const sdate = this.timesheetForm.get('SelectDate').value;
    this.requiredDate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "partyId": this.timesheetForm.value.Party,
      "requiredDate": this.requiredDate
    }
    this.myContactsService.createWeeklyProTimesheet(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this._Router.navigate(["/psa/project/detail-timesheet"], { queryParams: { partyId: this.timesheetForm.value.Party, party: this.partIdDes } })

         this.timeSheetList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getworkEffortName() {
    this.spinner.show();
    this.myContactsService.getworkEffortName().subscribe(res => {
      this.workEffortName = res.data[0].name;
      this.spinner.hide();
      for (const value of this.workEffortName) {
        this.workEffortNameArray.push({
          label: value.workEffortName,
          value: value.workEffortId
        })
      }
    })
  }
  
  getRateTypeId() {
    this.spinner.show();
    this.myContactsService.getrateTypeId().subscribe(res => {
      this.rateTypeID = res.data;
      this.spinner.hide();
      for (const value of this.rateTypeID) {
        this.rateTypeIDArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
    })
  }
  createAddNewWeeklyTimesheet() {
    this.spinner.show();
    const requestData = {
      "checkComplete": "",
      "commanTimeSheetId": this.updateTimesheet.value.taskName,
      "fri": this.updateTimesheet.value.fri,
      "fromDate": "",
      "hours": this.updateTimesheet.value.planHrs,
      "mon": this.updateTimesheet.value.mon,
      "rateTypeID": this.updateTimesheet.value.rateTypeID,
      "sat": this.updateTimesheet.value.sat,
      "statusId": this.updateTimesheet.value.taskName,
      "sun": this.updateTimesheet.value.sun,
      "taskName": this.updateTimesheet.value.taskName,
      "thruDate": "",
      "thur": this.updateTimesheet.value.thu,
      "tue": this.updateTimesheet.value.tue,
      "wed": this.updateTimesheet.value.wed

    }
    this.myContactsService.createAddNewWeeklyTimesheet(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.timeSheetList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }

  createWeeklyTimesheet() {
    this.spinner.show();
    const sdate = this.addnewtimsheet.get('selectDate').value;
    this.selectedDate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
    const formData = {
      "fromDate": "",
      "partyId": "admin",
      "thruDate": this.selectedDate
    }
    this.myContactsService.createWeeklyTimesheet(formData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        // this.closebutton7.nativeElement.click();
        this.timeSheetList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }


  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }

  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
