<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               
                   <span class="color-black pl-1 titlePanels">Employees Position</span>
                   <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
                

            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords">Find Employee Position

                                                    </span>

                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2"  data-toggle="modal" data-target="#exampleModalCenter">Create New Employee Position</button>


                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 56%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>	
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Internal Organization ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Internal Organization ID">
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Emp Position Id</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.emplPositionIdSearchType" [ngModelOptions]="{standlone:true}" name="emplPositionId" [options]="opportunityIdArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.emplPositionId" name="position" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Emp Position ID">
                                                                </div>
                
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Status ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.statusIdSearchType" [ngModelOptions]="{standlone:true}" name="statusIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.statusId" name="status" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status ID">
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Budget ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.budgetIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="budgetIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.budgetId" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Budget Item Seq ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.budgetItemSeqIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="budgetItemSeqIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.budgetItemSeqId" name="budgetItemSeqId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget Item Seq ID">
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Emp Position Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.emplPositionTypeIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="emplPositionTypeIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.emplPositionTypeId" name="emplPositionTypeId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Emp Position Type ID">
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Estimated From Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedFromDate" name="lastName" [ngModelOptions]="{standlone:true}" filter="true" name="emplPositionTypeIdSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedStartDateFrom" name="estimatedStartDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                                <div class="col-lg-2 ">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.estimatedStartDateTo" name="estimatedStartDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Estimated Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDate" [ngModelOptions]="{standlone:true}" filter="true" name="estimatedThroughDate" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateFrom" name="estimatedThruDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                                <div class="col-lg-2 ">
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.estimatedThruDateTo" name="estimatedThruDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Salary Flag</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="Flag" placeholder="Enter Salary Flag" [ngModelOptions]="{standlone:true}" [(ngModel)]="advanceSearch.salaryFlag" filter="true" name="salaryFlag" optionlabel="label" id="exampleInputEmail1" aria-describedby="emailHelp">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Exempt Flag</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="Flag" [(ngModel)]="advanceSearch.exemptFlag" name="exemptFlag" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Exempt Flag" filter="true" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Fulltime Flag</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="Flag" [(ngModel)]="advanceSearch.fulltimeFlag" name="fulltimeFlag" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fulltime Flag" filter="true" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Temporary Flag</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="Flag" placeholder="Enter Temporary Flag" [(ngModel)]="advanceSearch.temporaryFlag" name="temporaryFlag" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Temporary Flag"
                                                                        filter="true" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Actual From Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.actualFromDAte" [ngModelOptions]="{standlone:true}" filter="true" name="actualFromDAte" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.actualFromDateFrom" name="actualFromDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                                <div class="col-lg-2 ">
                                                                    <input type="date" [(ngModel)]="advanceSearch.actualFromDateTo" name="actualFromDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Actual Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.actualThrDate" [ngModelOptions]="{standlone:true}" filter="true" name="actualFromDAte" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.actualThruDateFrom" name="actualThruDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                                <div class="col-lg-2 ">
                                                                    <input type="date" [(ngModel)]="advanceSearch.actualThruDateTo" name="actualThruDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button " style="
                                                        margin-left: -25%;"><button style="width:122px" type="submit" (click)="advanceSearchContacts(false)" class="btn btn-secondary submit-btn">Find
                                                                Position</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>


                                <!-- <div class="w3-card-4 classCard">
                                    <div class="port-header-contact create-lead font-medium mb-0" style="    background: #cce0e95e;
                                    margin-top: 0%;">
                                        <div class="suppliers-wrapper" style="    margin-top: 14px;">
                                            <p class="h-text">Find Employee Position</p>
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="margin-left:200px;" data-toggle="modal" data-target="#exampleModalCenter">Create New Employee Position</button>
                                            
                                        </div>
                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Internal Organization ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.partyIdSearchType" [ngModelOptions]="{standalone:true}" name="party" [options]="opportunityIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Internal Organization ID">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Emp Position Id</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.emplPositionIdSearchType" [ngModelOptions]="{standlone:true}" name="emplPositionId" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.emplPositionId" name="position" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Emp Position ID">
                                                </div>



                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Status ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.statusIdSearchType" [ngModelOptions]="{standlone:true}" name="statusIdSearchType" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.statusId" name="status" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status ID">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Budget ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.budgetIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="budgetIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.budgetId" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Budget Item Seq ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.budgetItemSeqIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="budgetItemSeqIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.budgetItemSeqId" name="budgetItemSeqId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget Item Seq ID">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Emp Position Type ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.emplPositionTypeIdSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="emplPositionTypeIdSearchType" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" [(ngModel)]="advanceSearch.emplPositionTypeId" name="emplPositionTypeId" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Emp Position Type ID">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Estimated From Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedFromDate" name="lastName" [ngModelOptions]="{standlone:true}" filter="true" name="emplPositionTypeIdSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedStartDateFrom" name="estimatedStartDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                                <div class="col-lg-2 ">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.estimatedStartDateTo" name="estimatedStartDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Estimated Through Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDate" [ngModelOptions]="{standlone:true}" filter="true" name="estimatedThroughDate" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateFrom" name="estimatedThruDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                                <div class="col-lg-2 ">
                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.estimatedThruDateTo" name="estimatedThruDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Salary Flag</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="Flag" placeholder="Enter Salary Flag" [ngModelOptions]="{standlone:true}" [(ngModel)]="advanceSearch.salaryFlag" filter="true" name="salaryFlag" optionlabel="label" id="exampleInputEmail1" aria-describedby="emailHelp">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Exempt Flag</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="Flag" [(ngModel)]="advanceSearch.exemptFlag" name="exemptFlag" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Exempt Flag" filter="true" optionlabel="label">

                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Fulltime Flag</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="Flag" [(ngModel)]="advanceSearch.fulltimeFlag" name="fulltimeFlag" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fulltime Flag" filter="true" optionlabel="label">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Temporary Flag</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="Flag" placeholder="Enter Temporary Flag" [(ngModel)]="advanceSearch.temporaryFlag" name="temporaryFlag" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Temporary Flag"
                                                        filter="true" optionlabel="label">
                                                    </p-dropdown>
                                                </div>



                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Actual From Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.actualFromDAte" [ngModelOptions]="{standlone:true}" filter="true" name="actualFromDAte" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.actualFromDateFrom" name="actualFromDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                                <div class="col-lg-2 ">
                                                    <input type="date" [(ngModel)]="advanceSearch.actualFromDateTo" name="actualFromDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Actual Through Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.actualThrDate" [ngModelOptions]="{standlone:true}" filter="true" name="actualFromDAte" [options]="dateIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.actualThruDateFrom" name="actualThruDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                                <div class="col-lg-2 ">
                                                    <input type="date" [(ngModel)]="advanceSearch.actualThruDateTo" name="actualThruDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div class="col-lg-12 col-12 main-submit-button " style="
                                margin-left: -25%;"><button style="width:122px" type="submit" (click)="advanceSearchContacts(false)" class="btn btn-secondary submit-btn">Find
                                        Position</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Employees Position List
                                            </h4>

                                        </div>
                                    </header>
                                    <div>


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">


                                                    <div class="card own-account-table">
                                                        <p-table [value]="employeePosition" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Position ID
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                        <div style="color: white;">Status Id
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                        <div style="color: white;">Party ID
                                                                            <p-sortIcon field="category"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                        <div style="color: white;">Budget ID
                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Budget Item
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Emp Position Type ID
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Estimated From Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Estimated Through Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Salary Flag
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Exempt Flag
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Temporary Flag
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Fulltime Flag
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Actual From Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Actual Through Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <!-- <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price"><div style="color: white;">Action <p-sortIcon
                                                                        field="price"></p-sortIcon></div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th> -->
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'170px','text-decoration':'none'}" routerLinkActive="active">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span class="account-button" (click)="detail(product.emplPositionId)">
                                                                            {{product.emplPositionId}}
                                                                        </span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.status}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.partyId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.budgetId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.budgetItemSeqId}}</td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.emplPositionType}}</td>

                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.estimatedFromDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.estimatedThruDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.salaryFlag}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">{{product.exemptFlag}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.temporaryFlag}}</td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.fulltimeFlag}}</td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.actualFromDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.actualThruDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <div>
                                                                            <!-- <svg class="hover"
                                                                            (click)="onUpdate(product.emplPositionId)"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16"
                                                                            viewBox="0 0 21.223 21.222">
                                                                            <g transform="translate(0 -0.004)">
                                                                                <g transform="translate(0 1.52)">
                                                                                    <path
                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                        transform="translate(0 -34.137)" />
                                                                                </g>
                                                                                <g transform="translate(4.548 0.004)">
                                                                                    <g transform="translate(0 0)">
                                                                                        <path
                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg> -->

                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1">Create New Employee Position</span>
                <span *ngIf="show" class="color-black pl-1">Update New Employee Position</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                Create New Employee Position </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Update New Employee Position </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createEmployeePositionService.addEmployee_position_form" [formGroup]="createEmployeePositionService.addEmployee_position_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee Position ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="empPositionId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Employee Position ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Status <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status" formControlName="statusId" filter="true" [options]="statusArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Organization
                                                            <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Internal Organization" formControlName="internalOrganization" filter="true" [options]="internalArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.touched">   Organization is required!</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Budget ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="budgetId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Budget Item Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="budgetItemSeqId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget Item Seq ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Employee Position Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter EmployeePosition Type ID" formControlName="empPositionTypeId" [options]="positionArray" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated From Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Estimated Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Through Date">

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Salary Flag <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Salary Flag" formControlName="salaryFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.touched">   Salary Flag  is required!</small>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Exempt Flag <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Exempt Flag" formControlName="exemptFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.touched">   Exempt Flag  is required!</small>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Fulltime Flag <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Fulltime Flag" formControlName="fulltimeFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.touched">   Fulltime Flag  is required!</small>



                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Temporary Flag <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Temporary Flag" formControlName="temporaryFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.touched">   Temporary Flag  is required!</small>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualFromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual From Date">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Actual Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualThruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual From Date">


                                                    </div>

                                                </div>
                                            </div>



                                        </div>

                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>