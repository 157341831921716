import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-organization-gl-setting',
  templateUrl: './create-organization-gl-setting.component.html',
  styleUrls: ['./create-organization-gl-setting.component.css']
})
export class CreateOrganizationGlSettingComponent implements OnInit {
  createOrganization:FormGroup;
  activeCategory=2;
  activeCategoryMain=2;
  orgId: any;
  orgIdArray: any[]=[];
  show: boolean;
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly _ToastrService: ToastrService,

    
  ) {
    this.createOrganization = this._FormBuilder.group({
      organizationPartyId: [''],
      
    });
   }
   ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getGlAccountInitialData();
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getOrganizationDropdown().subscribe((res: any) => {
      this.spinner.hide();
      this.orgId = res.data.partyRoleAndPartyDetail;
     
      for (const value of this.orgId) {
        this.orgIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
     
    })
   
  }
  AccountingCompany(){
    this._Router.navigate(['/financial/create-organization-gl-setting']);
  }
  AccountingInfo(){
    this._Router.navigate(['/financial/organization-gl-setting']);
  }
  onSubmit() {
    this.show=true;
    this.orgId=this.createOrganization.value.organizationPartyId;
    if( this.orgId==""){
      this._ToastrService.error("Organization Party Id is blank");
      this.show=false;

    }
    else{
      this.show=false;
      this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId: this.orgId,show:this.show } });
    }
    
  

  }
  
  
  reset(){
    this._Router.navigateByUrl('/financial/organization-gl-setting');
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
