import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-tracking-code',
  templateUrl: './edit-tracking-code.component.html',
  styleUrls: ['./edit-tracking-code.component.css']
})
export class EditTrackingCodeComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  CreateTrackingCodeForm: any;
  TrackingCodeTypeId: any;
  TrackingCodeTypeIdArray: any[]=[];
  MarketingCampaignIdsList: any;
  MarketingCampaignIdsListArray: any[]=[];
  ProductCatalogIdArray: any[]=[];
  ProductCatalogId: any;
  actualfromdate: string;
  thrufromdate: string;
  trackingCodeID: any;
  EditCodeTypeId: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) { 
    this.CreateTrackingCodeForm = this._FormBuilder.group({
      TrackingCodeID: [''],
      Description: [''],
      Comments: [''],
      TrackingCodeTypeID: [''],
      MarketingCampaignID: [''],
      DefaultProductCatalogID: [''],
      FromDate: [''],
      ThroughDate: [''],
      RedirectURL: [''],
      OverrideLogoURL: [''],
      OverrideCSSURL: [''],
      TrackableLifetime: [''],
      BillableLifetime: [''],
      GroupID: [''],
      SubGroupID: [''],
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.trackingCodeID = params["trackingCodeID"];
    });
    this.getTrackingCodeType();
    this.getMarketingCampaignIdsList();
    this.getdefaultProductCatalogId();
    this.getTrackingCodeById();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  updateTrackingCode(): void {

    this.spinner.show();
    const fromActualdate=this.CreateTrackingCodeForm.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.CreateTrackingCodeForm.get('ThroughDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
  
    const requestData ={

      "billableLifetime": this.CreateTrackingCodeForm.value.BillableLifetime,
      "comments": this.CreateTrackingCodeForm.value.Comments,
      "description":  this.CreateTrackingCodeForm.value.Description,
      "fromDate": this.actualfromdate,
      "groupId":  this.CreateTrackingCodeForm.value.GroupID,
      "marketingCampaignId":  this.CreateTrackingCodeForm.value.MarketingCampaignID,
      "overrideCss": this.CreateTrackingCodeForm.value.OverrideCSSURL,
      "overrideLogo": this.CreateTrackingCodeForm.value.OverrideLogoURL,
      "prodCatalogId":  this.CreateTrackingCodeForm.value.DefaultProductCatalogID,
      "redirectUrl":  this.CreateTrackingCodeForm.value.RedirectURL,
      "subgroupId":  this.CreateTrackingCodeForm.value.SubGroupID,
      "thruDate":  this.thrufromdate,
      "trackableLifetime":  this.CreateTrackingCodeForm.value.TrackableLifetime,
      "trackingCodeId": this.CreateTrackingCodeForm.value.TrackingCodeID,
      "trackingCodeTypeId": this.CreateTrackingCodeForm.value.TrackingCodeTypeID,
      
      }
     
    this.accountsService.updateTrackingCode(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(["/marketing/tracking-marketing"])
       // this.closebutton.nativeElement.click();
       // this.getTrackingCodeList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    getTrackingCodeById() {
      this.spinner.show();
      this.accountsService.getTrackingCodeById( this.trackingCodeID).subscribe(res => {
        this.EditCodeTypeId = res.data[0].node1;
        this.spinner.hide();
        const fromdate=this.EditCodeTypeId.fromDate
    this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
    const toEstdate=this.EditCodeTypeId.thruDate
    this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      setTimeout(() => {
      
        const formValue = this.CreateTrackingCodeForm;
        formValue.patchValue({
          TrackingCodeID: this.EditCodeTypeId.trackingCodeId,
      Description:this.EditCodeTypeId.description,
      Comments:this.EditCodeTypeId.comments,
      TrackingCodeTypeID: this.EditCodeTypeId.trackingCodeTypeId,
      MarketingCampaignID:this.EditCodeTypeId.marketingCampaignId,
      DefaultProductCatalogID:this.EditCodeTypeId.prodCatalogId,
      FromDate:this.getEstimateFrom,
      ThroughDate:this.getEstimateTo,
      RedirectURL:this.EditCodeTypeId.redirectUrl,
      OverrideLogoURL:this.EditCodeTypeId.overrideLogo,
      OverrideCSSURL: this.EditCodeTypeId.overrideCss,
      TrackableLifetime: this.EditCodeTypeId.trackableLifetime,
      BillableLifetime: this.EditCodeTypeId.billableLifetime,
      GroupID:this.EditCodeTypeId.groupId,
      SubGroupID:this.EditCodeTypeId.subgroupId,
        })
      
      }, 2000);
      })
    }
  getTrackingCodeType() {
    this.spinner.show();
    this.myContactsService.getTrackingCodeType().subscribe(res => {
      this.TrackingCodeTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.TrackingCodeTypeId) {
        this.TrackingCodeTypeIdArray.push({
          label: value.description,
          value: value.trackingCodeTypeId
        })
      }
    })
  }
  getMarketingCampaignIdsList() {
    this.spinner.show();
    this.myContactsService.getMarketingCampaignIdsList().subscribe(res => {
      this.MarketingCampaignIdsList = res.data[1].data;
      this.spinner.hide();
      for (const value of this.MarketingCampaignIdsList) {
        this.MarketingCampaignIdsListArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId
        })
      }
    })
  }
  getdefaultProductCatalogId() {
    this.spinner.show();
    this.myContactsService.defaultProductCatalogId().subscribe(res => {
      this.ProductCatalogId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ProductCatalogId) {
        this.ProductCatalogIdArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  cancelSubmit(){
    this._Router.navigate(['/marketing/tracking-marketing/tracking-marketing'])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
