import { Component, OnInit } from '@angular/core';
import { MainCatalogService } from '../main-catalog-mgmt/catalog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from './new-product-catalog-service';
import { ToastrService } from 'ngx-toastr';  import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-new-product-catalog',
  templateUrl: './new-product-catalog.component.html',
  styleUrls: ['./new-product-catalog.component.css']
})
export class NewProductCatalogComponent implements OnInit {
  catalogId: any;
  activeCategory = 2;
  show= false;
  catalogArray: any = [];
  productCatalogId: any;
  catalogForm: FormGroup;
  editMode: boolean;
  catalog: any;
  url: string;
  constructor(
    readonly mainCatalogService: MainCatalogService,
    readonly activatedRoute: ActivatedRoute,    private _location: Location,
    public catalogService: CatalogService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _FormBuilder: FormBuilder
  ) {
    this.url='/facilities/catalog/catalogs'
    this.catalogForm = this._FormBuilder.group({
      catalogName: ['', [Validators.required]],
      contentPathPrefix: [''],
      headerLogo: [''],
      prodCatalogId: [''],
      purchaseAllowPermReqd: ['N'],
      styleSheet: [''],
      templatePathPrefix: [''],
      useQuickAdd: ['Y'],
      viewAllowPermReqd: ['N']
    });
    this.catalogArray = [{
      label: 'Yes',
      value: 'Y'
    },
    {
      label: 'No',
      value: 'N'
    }];
    this.editMode = false;
  }
  
  ngOnInit(): void {
    this.catalogService.catalog_form = null;
    this.catalogService.catalogForm(this.catalogService.catalog_form);
    this.productCatalogId = this.activatedRoute.snapshot.queryParams.prodCatalogId;
    if (this.productCatalogId) {
      this.editMode = true;
  
      this.catalogForm.controls.prodCatalogId.disable();
      this.catalogForm.controls.prodCatalogId.updateValueAndValidity();
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.catalogId = params['catalogId'];
    });
    if (this.productCatalogId) {
      this.show = true;
    }
    else {
      this.show = false;
    }
    this.getCatalogList();

  }
  getCatalogList(): void {
   
    if (this.editMode) {
      this.mainCatalogService.getCatalogById(this.productCatalogId).subscribe(res => {
        this.catalog = res.data;
       
        this.catalogForm.patchValue({
          catalogName: res.data.catalogName,
          prodCatalogId: res.data.prodCatalogId,
          styleSheet: res.data.styleSheet,
          templatePathPrefix: res.data.templatePathPrefix,
          contentPathPrefix: res.data.contentPathPrefix,
          useQuickAdd: res.data.useQuickAdd,
          viewAllowPermReqd: res.data.viewAllowPermReqd,
          purchaseAllowPermReqd: res.data.purchaseAllowPermReqd,
          headerLogo: res.data.headerLogo
        });
      });
    }
  
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.mainCatalogService.updateCatalog({ ...this.catalog, ...this.catalogForm.value }).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.router.navigate([this.url]);
        }

      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     } else {
      this.mainCatalogService.createCatalog(this.catalogForm.value).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.router.navigate([this.url]);
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });} }

}
