import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { FeaturesService } from '../features.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from '../../new-products/product.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-detailed-summary-feature-category',
    templateUrl: './detailed-summary-feature-category.component.html',
    styleUrls: ['./detailed-summary-feature-category.component.css']
})
export class DetailedSummaryFeatureCategoryComponent implements OnInit {
    activeCategoryInv=2;
    productFeatureForm: FormGroup;
    featureTypeIds: any[];
    featureMaintenanceId: string;
    productUOMIds: any[];
    featureMaintenanceData: any;
    id: any;
    productFeatureCategoryId: string;
  
    faEdit = faEdit;
    @ViewChild('closebutton') closebutton;
    featureCategoryForm: FormGroup;
    featureCategoryId: string;
    editMode: boolean;
    parentCategory: any[];
    featureCategory: any;
    total: number;
    rowNumber: number;
    pageNo: number;
    activeCategory = 1;
    pageSize: number;
    rows: number;
    featureId: string;
   
    featureMaintenance: any;
    featureCategories: any;
    url: string;
    userPermissions: any;
    Hidebtn: boolean=false;
    HideCrudbtn: boolean=false;
    constructor(
        readonly _ProductService: ProductService,  
        
  private _location: Location,

        readonly _FeaturesService: FeaturesService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router,
        readonly _FormBuilder: FormBuilder,
        readonly _ToastrService: ToastrService
    ) 
    
    {

      this.url='/facilities/catalog/feature-category/detailed-summary-feature-category'
        this.productFeatureForm = this._FormBuilder.group({
            abbrev: [''],
            defaultAmount: [0],
            defaultSequenceNum: [0],
            description: ['', [Validators.required]],
            idCode: [''],
            numberSpecified: [0],
            productFeatureCategoryId: [''],
            productFeatureTypeId: ['', [Validators.required]],
            uomId: ['']
        });


        this.featureTypeIds = [];
        this.featureCategories = [];
        this.editMode = false;
        this.featureMaintenanceId = '';
        this.productUOMIds = [];
        this.productFeatureCategoryId = '';
    
        this.url='/facilities/catalog/feature-category/new-feature-maintenance'
        this.featureId = '';
        this.total = 0;
        this.rowNumber = 0;
        this.pageNo = 1;
        this.pageSize = 100;
        this.rows = 50;
        this.featureCategoryForm = this._FormBuilder.group({
            description: ['', [Validators.required]],
            parentCategoryId: ['']
          });
          this.featureCategoryId = '';
          this.editMode = false;
          this.parentCategory = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
 
        this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
        if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
          this.Hidebtn=false;
        }
        else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
          this.Hidebtn=true;
        }
        else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
          this.HideCrudbtn=true;
        }
      this.featureMaintenanceId = this._ActivatedRoute.snapshot.queryParams.featureMaintenanceId;
      this.id = this._ActivatedRoute.snapshot.queryParams.id;

      if (this.featureMaintenanceId && this.id) {
          this.editMode = true;
          this.getFeatureMaintenance();
      }
      else {
          setTimeout(() => {
              const formValue = this.productFeatureForm;
              formValue.patchValue({
                  productFeatureCategoryId: this.featureMaintenanceId

              })
          }, 3000);
      }
        
      
      
      this.featureId = this._ActivatedRoute.snapshot.queryParams.featureId;
        this.getFeatureMaintenance();
        this.getFeatureCategories();
        if (this.featureId) {
            this.editMode = true;
            this._FeaturesService.getFeatureCategoryById(this.featureId)
              .then(data => {
                if (data.success) {
                  this.featureCategory = data.data;
                  this.featureCategoryForm.patchValue({
                    description: data.data.description,
                    parentCategoryId: data.data.parentCategoryId
                  });
                  this.featureCategoryForm.controls.parentCategoryId.disable();
                  this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
                }
              });
            
          }
          this.parentType();
          this.getCategories();
          this.getUOMId();

          this.getParentCategory();
          this.getFeatureCategoriesList();
    }
    homeButton(){
      this._Router.navigate(['/myPortal/my-portal-my-profile'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    getFeatureMaintenance1(): void {
      this.spinner.show();
      if (this.id) {
          this._FeaturesService.getFeatureMaintenance(this.id)
              .then(data => {
                  this.spinner.hide();
                  this.featureMaintenanceData = data.data;
                  this.productFeatureForm.patchValue({
                      ...data.data, ...{
                          numberSpecified: data.data.quantity,
                          defaultSequenceNum: data.data.IDSeqNum
                      }
                  });
                 
              });
      }
     
  }
  parentType(): void {
      this.spinner.show();
      this._FeaturesService.getProductParentType()
          .then(data => {
              this.spinner.hide();
              this.featureTypeIds = data.data.parentTypes.map(value => {
                  return {
                      label: value.description,
                      value: value.productFeatureTypeId
                  }
              });
          });
         
  }
  getCategories(): void {
      this.spinner.show();
      this._FeaturesService.getFeatureCategories({ pageNo: 1, pageSize: 100 }, '')
          .then(data => {
              this.spinner.hide();
              this.featureCategories = data.data.map(value => {
                  return {
                      label: value.description,
                      value: value.parentCategoryId
                  };
              });
          });
         
  }
  getUOMId(): void {
      this.spinner.show();
      this._ProductService.getProductMenu()
          .then(data => {
              this.spinner.hide();
              this.productUOMIds = data.data.quantityUomID.map(value => {
                  return {
                      label: value.description,
                      value: value.uomId
                  };
              });
          });
         
  }
  submit1(): void {
      this.spinner.show();
      if (this.productFeatureForm.valid) {
          if (this.editMode) {
              this._FeaturesService.updateFeatureMaintenance({
                  ...this.productFeatureForm.value
              }, this.id)
                  .then(data => {
                      if (data.success) {
                          this.productFeatureCategoryId = this.productFeatureForm.get('productFeatureCategoryId').value;
                          this._ToastrService.success('Updated');
                          this.spinner.hide();
                          this.productFeatureForm.reset();
                          this.productFeatureForm.controls.productFeatureTypeId.enable();
                          this.productFeatureForm.controls.productFeatureTypeId.updateValueAndValidity();
                          this._Router.navigate([this.url], { queryParams: { featureId: this.productFeatureCategoryId ? this.productFeatureCategoryId : this.featureMaintenanceId } });
                      }
                  });
                 
          } else { this._FeaturesService.createFeatureMaintenance(this.productFeatureForm.value)
                  .then(data => {
                      if (data.success) {
                          this._ToastrService.success('Created');
                          this.spinner.hide();
                          this.productFeatureCategoryId = this.productFeatureForm.get('productFeatureCategoryId').value;
                          this.productFeatureForm.reset();
                          this._Router.navigate([this.url], { queryParams: { featureId: this.productFeatureCategoryId ? this.productFeatureCategoryId : this.featureMaintenanceId } });
                      } });} } else {
          this.spinner.hide();
          this._ToastrService.error('Please fill required fields');} }

          reset(): void {
            this.productFeatureForm.reset();
            this._Router.navigate([this.url], { queryParams: { featureId: this.featureMaintenanceId } });
        }


    getFeatureCategoriesList(): void {
        this.spinner.show();
        const searchFilter = {
    
          "description": "",
          "descriptionSearchType": "Contains",
          "productFeatureCategoryId": this.featureId,
          "productFeatureCategoryIdSearchType": "Contains"
    
        }
        this._FeaturesService.getFeatureCategoriesList({ pageNo: 1, pageSize: 200 }, searchFilter)
          .then(data => {
    
            if (data.success) {
              setTimeout(() => {
                this.featureCategory = data.data[0];
                const formValue = this.featureCategoryForm;
                formValue.patchValue({
                  description: this.featureCategory.description,
                  parentCategoryId: this.featureCategory.productFeatureCategoryId,
                })
                this.featureCategoryForm.controls.parentCategoryId.disable();
                this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
              }, 3000);
              this.spinner.hide();
            }
          });
      }
      getParentCategory(): void {
        this.spinner.show();
        this._FeaturesService.getFeatureParentCategory()
          .then(data => {
            this.spinner.hide();
            this.parentCategory = data.data.map(value => {
              return {
                label: value.description,
                value: value.productFeatureCategoryId
              };
            });
          });
         
      }
      submit(): void {
        this.spinner.show();
        if (this.editMode) {
          if (this.featureCategoryForm.valid) {
            this._FeaturesService.updateFeatureCategory({
              ...this.featureCategory, ...this.featureCategoryForm.value
            },  this.featureId)
              .then(data => {
                if (data.success) {
                  this.featureCategoryForm.reset();
                  this.editMode = false;
                  this._ToastrService.success('Updated');
                  this.closebutton.nativeElement.click();
                  this._Router.navigate(["facilities/catalog/feature-category"])
                  this.spinner.hide();
                  this.featureCategoryForm.controls.parentCategoryId.disable();
                  this.featureCategoryForm.controls.parentCategoryId.updateValueAndValidity();
                
                } });
          } } else {
          if (this.featureCategoryForm.valid) {
            this._FeaturesService.createFeatureCategory(this.featureCategoryForm.value)
              .then(data => {
                if (data.success) {
                  this._ToastrService.success('Created');
                  this.closebutton.nativeElement.click();
                  this._Router.navigate(["facilities/catalog/feature-category"])
                  this.spinner.hide();
                  this.featureCategoryForm.reset();
                 
                }
              });
          }
        } }
  
    getFeatureCategories(): void {
        this.spinner.show();
        const req = {
            description: "",
            descriptionSearchType: "Contains",
            productFeatureCategoryId: this.featureId,
            productFeatureCategoryIdSearchType: "Contains"
        }

        this._FeaturesService.getFeatureCategoriesList({ pageNo: 1, pageSize: 200 },req)
            .then(data => {
                this.featureCategories = data.data[0];
                this.spinner.hide();
            });

    }
    getFeatureMaintenance(): void {
        this.spinner.show();
        this._FeaturesService.getFeatureCategoriesById({ pageNo: 1, pageSize: 100 }, this.featureId)
            .then(data => {
                this.featureMaintenance = data.data;
                this.spinner.hide();
            });

    }
    edit(id: string): void {
        this._Router.navigate([this.url], { queryParams: { id, featureMaintenanceId: this.featureId } });
    }
    create() {
        this._Router.navigate([this.url], { queryParams: { featureMaintenanceId: this.featureId } });
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
    }
    ngOnDestroy(): void {
        this.spinner.hide();
    }
}