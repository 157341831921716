

<div class="w3-card-4 classCard" >
    <!-- <div class="container-fluid">
        <ul class="tabbing-section tabbing-accordians tabClass">

            <li [ngClass]="activeCategory==2?'active':''"><a>
               HRM Dashboard</a></li>
           

        </ul>
    </div> -->

    <div>
        <div class="container-fluid">
            <div class="row">
                
                <div style=" width: 100%;">
                    <div class="heads">  HRM Dashboard</div>

                    <div id="chart">
                        <apx-chart
                          [series]="chartOptions.series"
                          [chart]="chartOptions.chart"
                          [dataLabels]="chartOptions.dataLabels"
                          [plotOptions]="chartOptions.plotOptions"
                          [title]="chartOptions.title"
                          [legend]="chartOptions.legend"
                        ></apx-chart>
                      </div>
                      
                 
                    <!-- <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                    <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                    <script src="https://cdn.amcharts.com/lib/4/themes/material.js"></script>
                    <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                    <div id="chartdiv4"></div> -->
                </div>
              
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 
