<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;" (click)="cancelSubmit()">Skills </span>
                <span class="color-black pl-1"> > Add Party Skill</span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="cancelSubmit()">Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''" ><a>
                        Add Party Skill </a></li>
                        <li *ngIf="show"  [ngClass]="activeCategory==2?'active':''" ><a>
                            Update Party Skill </a></li>
                     
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="addSkillsService.add_skill_form" [formGroup]="addSkillsService.add_skill_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Party Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="partyId" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter year of experience">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Skill Type ID <span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="skillTypeId" filter="true" [options]="skillArray" optionlabel="label" placeholder="Select Skill Type Id">
                                                           
                                                </p-dropdown>
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Years Experience</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="yearsExperience" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter year of experience">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Rating </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="rating" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Rating">
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Skill Level</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="skillLevel" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter skill Level">
                                            </div>
                                         
                                        </div>
                                    </div>

                                   
                                </div>
                                <div class="main-submit-button" style="margin-right: 30%;">
                                    <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                    <button type="submit" *ngIf="show" (click)="updateSkills()" class="btn btn-secondary submit-btn">Update</button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>
            
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">Add Party Skill</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close"
        (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden" #closebutton>&times;</span>
    </button>
</div>
<div class="modal-body d-flex">
    <div class=" bg-white color-grey" style="width: 100%;">
        <div class="w3-card-4 classCardPopUp" style="width: 100%;">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">

                    <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                            Add Party Skill </a></li>
                    <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                            Update Party Skill </a></li>
                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form *ngIf="addSkillsService.add_skill_form" [formGroup]="addSkillsService.add_skill_form"
                            class="w-100">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">Party Id</label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" formControlName="partyId" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter year of experience">
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">Skill Type ID <span
                                                    style="color:red">*</span></label>
                                        </div>

                                        <div class="col-lg-3">
                                            <p-dropdown formControlName="skillTypeId" filter="true"
                                                [options]="skillArray" optionlabel="label"
                                                placeholder="Select Skill Type Id">

                                            </p-dropdown>
                                        </div>



                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">Years Experience</label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" formControlName="yearsExperience" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter year of experience">
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">Rating </label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" formControlName="rating" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Rating">
                                        </div>



                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">Skill Level</label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" formControlName="skillLevel" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter skill Level">
                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div class="main-submit-button" style="margin-right: 20%;">
                                <button type="submit" *ngIf="!show" (click)="onSubmit()"
                                    class="btn btn-secondary submit-btn">Create</button>
                                <button type="submit" *ngIf="show" (click)="updateSkills()"
                                    class="btn btn-secondary submit-btn">Update</button>

                            </div>
                        </form>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>