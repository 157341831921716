import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fsm-financial',
  templateUrl: './fsm-financial.component.html',
  styleUrls: ['./fsm-financial.component.css']
})
export class FsmFinancialComponent implements OnInit {

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) { }

  ngOnInit(): void {
  }

  toBasicInvoiceSettings()
  {
    this._Router.navigate(['/fsm/fsmSetting/basic-invoice-settings'])

  }


  toPostingInvoices()
  {
    this._Router.navigate(['/fsm/fsmSetting/posting-invoices'])
  }

  toSalesTaxItems()
  {
    this._Router.navigate(['/fsm/fsmSetting/sales-tax-items'])

  }


  toSalesTaxGroups()
  {
    this._Router.navigate(['/fsm/fsmSetting/sales-tax-groups'])

  }

  toSalesTaxCodes()
  {
    this._Router.navigate(['/fsm/fsmSetting/sales-tax-codes'])

  }


  toInvoiceStatus()
  {
    this._Router.navigate(['/fsm/fsmSetting/invoice-status'])

  }

  toPriceList()
  {
    this._Router.navigate(['/fsm/fsmSetting/price-list'])

  }

  toPriceListGroups()
  {
    this._Router.navigate(['/fsm/fsmSetting/price-list-groups'])

  }

  toPrintsTemplates()
  {
    this._Router.navigate(['/fsm/fsmSetting/prints-templates'])

  }

  toPaymentProcessing()
  {
    this._Router.navigate(['/fsm/fsmSetting/payment-processing'])

  }

  toQBMSIntegration()
  {
    this._Router.navigate(['/fsm/fsmSetting/qbms-integration'])

  }

  toNewWorkOrder()
  {
    this._Router.navigate(['fsm/newWorkOrder'])
  }

  toCustomer()
  {
    this._Router.navigate(['fsm/customer'])
  }

  toReports()
  {
    this._Router.navigate(['/fsm/reports'])
  }

  toInvoices()
  {
    this._Router.navigate(['/fsm/invoices'])

  }

  toWorkOrder()
  {
    this._Router.navigate(['/fsm/main'])
  }

  toPeople()
  {
    this._Router.navigate(['/fsm/people'])
  }

  toTimeSheet()
  {
    this._Router.navigate(['/fsm/timesheet'])
  }

  toSetting()
  {
    this._Router.navigate(['/fsm/fsmSetting/work-order'])
  }


}
