<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/facilities/warehouse">Facilities </span>
                <span class="color-black pl-1"> > Facility Summary</span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>
            </div>
            <div class="w3-card-4 classCard">
            <div class="container-fluid scrolling-container">
                <ul class="tabbing-section scolling-tabss tabbing-accordians tabClass" style="padding-left: 9px;">
                    <li class="litab" [ngClass]="activeCategory==1?'active':''"
                        (click)="changeactiveCategoryFunction(1)"><a>
                            Contact Information </a></li>
                    <li class="litab" [ngClass]="activeCategory==2?'active':''"
                        (click)="changeactiveCategoryFunction(2)"><a>
                            Locations</a></li>
                    <li class="litab" [ngClass]="activeCategory==3?'active':''"
                        (click)="changeactiveCategoryFunction(3)"><a>
                            Inventory</a></li>
                    <li class="litab" [ngClass]="activeCategory==4?'active':''"
                        (click)="changeactiveCategoryFunction(4)"><a>
                            Inventory Items </a></li>
                    <li class="litab" [ngClass]="activeCategory==5?'active':''"
                        (click)="changeactiveCategoryFunction(5)"><a>
                            Inventory Items Details </a></li>
                    <li class="litab" [ngClass]="activeCategory==6?'active':''"
                        (click)="changeactiveCategoryFunction(6)"><a>
                            Receive Inventory </a></li>
                    <li class="litab" [ngClass]="activeCategory==7?'active':''"
                        (click)="changeactiveCategoryFunction(7)"><a>
                            Physical Inventory</a></li>
                    <br>
                    <li class="litab" [ngClass]="activeCategory==8?'active':''"
                        (click)="changeactiveCategoryFunction(8)"><a>
                            Inventory Xfers </a></li>
                    <li class="litab" [ngClass]="activeCategory==9?'active':''"
                        (click)="changeactiveCategoryFunction(9)"><a>
                            Receive Return</a></li>
                    <li class="litab" [ngClass]="activeCategory==10?'active':''"
                        (click)="changeactiveCategoryFunction(10)"><a>
                            Picking </a></li>
                    <li class="litab" [ngClass]="activeCategory==11?'active':''"
                        (click)="changeactiveCategoryFunction(11)"><a>
                            Stock Moves </a></li>
                    <li class="litab" [ngClass]="activeCategory==12?'active':''"
                        (click)="changeactiveCategoryFunction(12)"><a>
                            Manage Picklists </a></li>
                    <li class="litab" [ngClass]="activeCategory==13?'active':''"
                        (click)="changeactiveCategoryFunction(13)"><a>
                            Verify Pick</a></li>
                    <li class="litab" [ngClass]="activeCategory==14?'active':''"
                        (click)="changeactiveCategoryFunction(14)"><a>
                            Packing</a></li>
                </ul>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div *ngIf="activeCategory==1">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Contact Information
                                                    </h4>
                                                    <!-- <span class="edit-buttons" 
                                                        routerLink="/facilities/warehouse/create-new-contact-information"
                                                        [queryParams]="{facilityId:facilityId}"
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter"
                                                        >Create</span> -->
                                                    <span class="edit-buttons"
                                                    data-toggle="modal" (click)="openModal()"
                                                    data-target="#contactModalCenter">Create New Contact Information</span>
                                                </div>
                                                <div class="panel-body">

                                                    <div class="card own-account-table mt-2">
                                                        <p-table [value]="contactInformationList" [paginator]="true"
                                                            [rows]="rows" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr>
                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                        [ngStyle]="{'width':'190px'}">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}"></th>
                                                                    <th [ngStyle]="{'width':'190px'}"></th>
                                                                    <th [ngStyle]="{'width':'190px'}"></th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr *ngIf="contactInformationList.length> 0">
                                                                    <td [ngStyle]="{'width':'190px'}"
                                                                        [ngStyle]="{'width':'190px'}">
                                                                        <div class="checkbox-align">
                                                                            <span
                                                                                 class="account-button" >{{product.contactMech.description}}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <span>
                                                                            {{product.contactMech.infoString}}</span>
                                                                        <span
                                                                            *ngIf="product.contactMech.contactMechTypeId=='POSTAL_ADDRESS'">
                                                                            {{product.contactMech.address1}}
                                                                            {{product.contactMech.stateProvinceGeoId}}{{product.contactMech.postalCode}}
                                                                            {{product.contactMech.city}}
                                                                            {{product.contactMech.countryGeoId}}</span>
                                                                        <span
                                                                            *ngIf="product.contactMech.contactMechTypeId=='TELECOM_NUMBER'">
                                                                            {{product.contactMech.contactNumber}}</span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        Updated Effective Through:
                                                                        {{product.contactMech.fromDate| date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        Updated Date :
                                                                        {{product.facilityContactMechPurposes.length ?  (product.facilityContactMechPurposes[0].lastUpdatedStamp| date:'yyyy-MM-dd hh:mm:ss' ):'N/A'}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <div  *ngIf="product.contactMech.thruDate==null">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                (click)="editContact(product.contactMech.contactMechId)"
                                                                                width="16" height="16"
                                                                                viewBox="0 0 21.223 21.222">
                                                                                <g transform="translate(0 -0.004)">
                                                                                    <g transform="translate(0 1.52)">
                                                                                        <path
                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                            transform="translate(0 -34.137)" />
                                                                                    </g>
                                                                                    <g
                                                                                        transform="translate(4.548 0.004)">
                                                                                        <g transform="translate(0 0)">
                                                                                            <path
                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                (click)="expireContact(product.contactMech.contactMechId)"
                                                                                class="ml-20" width="16" height="16"
                                                                                viewBox="0 0 26 26">
                                                                                <defs>
                                                                                    <style>
                                                                                        .a {
                                                                                            fill: #f44336;
                                                                                        }

                                                                                        .b {
                                                                                            fill: #fafafa;
                                                                                            margin-left: 20px;
                                                                                        }
                                                                                    </style>
                                                                                </defs>
                                                                                <path class="a fill-color"
                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                <path class="b"
                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                    transform="translate(-147.576 -147.576)" />
                                                                            </svg>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">No Record Found</h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                        <!-- <p class="paginate_data">
                                                                                                                                                                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                        <p class="paginate_data">
                                                            View per page </p>

                                                    </div>


                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==2">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Locations
                                                    </h4>
                                                    <span class="edit-buttons"
                                                        routerLink="/facilities/warehouse/create-new-facility-location"
                                                        [queryParams]="{facilityId:facilityId}"
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter">Create New Locations</span>
                                                        <!-- <span class="edit-buttons"
                                                        data-toggle="modal"
                                                        data-target="#locationModalCenter">Create</span> -->
                                                </div>
                                                <div class="panel-body">

                                                    <div class="" style="width: 100%;">
                                                        <div class="container-fluid">
                                                            <ul class="">
                                    
                                                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                                                    location</a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form [formGroup]="facilityLocationForm">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-lg-3 form-group classInput modal-input">
                                                                                        <label for="exampleInputEmail1">Location
                                                                                            SeqID</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">

                                                                                        <p-dropdown filter="true"
                                                                                        optionlabel="label"
                                                                                        [options]="LocationSeqIdArray"
                                                                                        formControlName="locationSeqId"
                                                                                        placeholder="Select Location SeqID">
                                                                                        </p-dropdown>

                                                                                     
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">Area</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        formControlName="area" id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp" placeholder="Area">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                         
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-lg-3 form-group classInput modal-input">
                                                                                        <label for="exampleInputEmail1">Aisle </label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        formControlName="aisle" id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Aisle">
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">Section</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        formControlName="section"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Section">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                           
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                  
                                                                                    <div class="col-lg-3 form-group classInput modal-input">
                                                                                        <label for="exampleInputEmail1">Level</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1" formControlName="level"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Level">
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                        <label for="exampleInputEmail1">Position</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        formControlName="position"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Position">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                         
                                                                          
                                                                          
                                                                        </div>
                                                                    </form>
                                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -20%;"><button
                                                                            type="submit" (click)="getFacilityLocation()"
                                                                            class="btn btn-secondary submit-btn">Find</button></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <ng-container *ngIf="facilityLocations.length">
                                                        <div class="card own-account-table mt-2">
                                                            <p-table [value]="facilityLocations" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                                Facility <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;"> Location Seq Id <p-sortIcon
                                                                                    field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price"><div style="color: white;">  Type</div>

                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price"><div style="color: white;">Area</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Aisle</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Section</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Level</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Position</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Created Date</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Action</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                                <span
                                                                                     class="account-button" >{{product.facilityId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.locationSeqId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.type}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.area}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.aisle}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.section}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.level}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.position}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    routerLink="/facilities/warehouse/create-new-facility-location"
                                                                                    [queryParams]="{facilityId:facilityId,locationSeqId:product.locationSeqId}"
                                                                                    width="16" height="16"
                                                                                    viewBox="0 0 21.223 21.222">
                                                                                    <g transform="translate(0 -0.004)">
                                                                                        <g
                                                                                            transform="translate(0 1.52)">
                                                                                            <path
                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                transform="translate(0 -34.137)" />
                                                                                        </g>
                                                                                        <g
                                                                                            transform="translate(4.548 0.004)">
                                                                                            <g
                                                                                                transform="translate(0 0)">
                                                                                                <path
                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    (click)="deleteFacilityLocation(product.locationSeqId)"
                                                                                    class="ml-20" width="16" height="16"
                                                                                    viewBox="0 0 26 26">
                                                                                    <defs>
                                                                                        <style>
                                                                                            .a {
                                                                                                fill: #f44336;
                                                                                            }

                                                                                            .b {
                                                                                                fill: #fafafa;
                                                                                                margin-left: 20px;
                                                                                            }
                                                                                        </style>
                                                                                    </defs>
                                                                                    <path class="a fill-color"
                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                    <path class="b"
                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                </svg>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                                                    View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                View per page </p>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="modal fade" id="locationModalCenter" tabindex="-1" role="dialog" aria-labelledby="locationModalCenterTitle"
                                                    aria-hidden="true">
                                                    <div class="modal-dialog modal-lg" role="document">
                                                        <div class="modal-content cssModelLength">
                                                            <div class="modal-header">
                                                            
                                                                <span class="color-black pl-1"> Create new facility location</span>
                                                                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true" #closebutton>&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body d-flex">
                                                                <div class=" bg-white color-grey" style="width: 100%;">
                                                                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                                                        <div class="container-fluid">
                                                                            <ul class="tabbing-section tabbing-accordians tabClass">
                                                    
                                                                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                                                                    location</a></li>
                                                                            </ul>
                                                                        </div>
                                                                        <div class="create-new-leade mt-2">
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form [formGroup]="createFacilityLocationForm">
                                                                                        <div
                                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                  
                                                                                                    <div class="col-lg-3 form-group classInput modal-input">
                                                                                                        <label for="exampleInputEmail1">
                                                                                                            Type</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true"
                                                                                                            optionlabel="label"
                                                                                                            formControlName="type"
                                                                                                            placeholder="Select Type">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                                        <label for="exampleInputEmail1">Area</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control"
                                                                                                        formControlName="area" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp" placeholder="Area">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                  
                                                                                                    <div class="col-lg-3 form-group classInput modal-input">
                                                                                                        <label for="exampleInputEmail1">Aisle </label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control"
                                                                                                        formControlName="aisle" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Aisle">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                                        <label for="exampleInputEmail1">Section</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control"
                                                                                                        id="exampleInputEmail1"
                                                                                                        formControlName="section"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Section">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                           
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                                                  
                                                                                                    <div class="col-lg-3 form-group classInput modal-input">
                                                                                                        <label for="exampleInputEmail1">Level</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control"
                                                                                                        id="exampleInputEmail1" formControlName="level"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Level">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                                                        <label for="exampleInputEmail1">Position</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" class="form-control"
                                                                                                        id="exampleInputEmail1"
                                                                                                        formControlName="position"
                                                                                                        aria-describedby="emailHelp"
                                                                                                        placeholder="Position">
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                         
                                                                                          
                                                                                          
                                                                                        </div>
                                                                                    </form>
                                                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -20%;"><button
                                                                                            type="submit" (click)="getFacilityLocation()"
                                                                                            class="btn btn-secondary submit-btn">Create</button></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==3">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Inventory
                                                    </h4>
                                                </div>
                                                <br>
                                                <div class="panel-body">
                                                    <div class="suppliers-wrapper col-lg-12 col-12 mb-20">

                                                        <button type="button"
                                                            (click)="generatePdfs('')"
                                                            class="btn btn-outline-secondary" style="margin-left:-1% !important ;">Print(PDF)
                                                        </button>


                                                        <button type="button"
                                                        (click)="exportInventoryItems('')"
                                                        class="btn btn-outline-secondary">Export
                                                    </button>


                                                        <button type="button"
                                                            [ngClass]="{'active':inventoryTab==='inventory'}"
                                                            (click)="toggleInventoryTab('inventory')"
                                                            class="btn btn-outline-secondary">Inventory</button>

                                                        <button type="button" style="    width: 17%!important;" class="btn btn-outline-secondary"
                                                            [ngClass]="{'active':inventoryTab==='inventoryItem'}"
                                                            (click)="toggleInventoryTab('inventoryItem')">Inventory
                                                            Item
                                                            Totals
                                                        </button>
                                                        <button type="button" style="    width: 17%!important;" class="btn btn-outline-secondary"
                                                            [ngClass]="{'active':inventoryTab==='inventoryGrand'}"
                                                            (click)="toggleInventoryTab('inventoryGrand')">Inventory
                                                            Item
                                                            Grand
                                                            Totals </button>
                                                        <button type="button" style="    width: 17%!important;" class="btn btn-outline-secondary"
                                                            (click)="exportInventoryItems()">Export
                                                            Inventory Item
                                                            Totals </button>
                                                        <button type="button" style="    width: 17%!important;" class="btn btn-outline-secondary"
                                                            [ngClass]="{'active':inventoryTab==='inventoryAverage'}"
                                                            (click)="toggleInventoryTab('inventoryAverage')">Inventory
                                                            Average
                                                            Costs </button>
                                                    </div>
                                                    <ng-container [ngSwitch]="inventoryTab">
                                                        <ng-container *ngSwitchCase="'inventory'">
                                                            <form [formGroup]="facilityInventoryForm">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label for="exampleInputEmail1">Product
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="products"
                                                                                    optionlabel="label"
                                                                                    formControlName="productId"
                                                                                    placeholder="Product Id">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Internal
                                                                                    Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="internalName"
                                                                                aria-describedby="emailHelp"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label for="exampleInputEmail1">Product
                                                                                    Type</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="productType"
                                                                                    optionlabel="label"
                                                                                    formControlName="productTypeId"
                                                                                    placeholder="Product Type">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label
                                                                                    for="exampleInputEmail1">Category</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                [options]="categories"
                                                                                optionlabel="label"
                                                                                formControlName="searchInProductCategoryId"
                                                                                placeholder="Category">
                                                                            </p-dropdown>
                                                                            </div>
                                                                        
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label
                                                                                    for="exampleInputEmail1">Supplier</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="suppliers"
                                                                                    optionlabel="label"
                                                                                    formControlName="productSupplierId"
                                                                                    placeholder="Supplier">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Status
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true"
                                                                                    [options]="statusIds"
                                                                                    optionlabel="label"
                                                                                    formControlName="statusId"
                                                                                    placeholder="Status Id">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label for="exampleInputEmail1">QOH
                                                                                    minus Min
                                                                                    Stock
                                                                                    less than</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                
                                                                                formControlName="offsetQOHQty">
                                                                            </div>
                                                                              
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">ATP
                                                                                    minus Min
                                                                                    Stock
                                                                                    less than</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="offsetATPQty"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label for="exampleInputEmail1">Show
                                                                                    Products
                                                                                    Per
                                                                                    Page</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                formControlName="viewSize"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                >
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Months
                                                                                    In Past
                                                                                    Limit</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="monthsInPastLimit"
                                                                                aria-describedby="emailHelp"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputT">
                                                                                <label for="exampleInputEmail1">From
                                                                                    Date Sell
                                                                                    Through</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-calendar placeholder="Select Date"
                                                                                    formControlName="fromDateSellThrough">
                                                                                </p-calendar>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Through
                                                                                    Date
                                                                                    Sell
                                                                                    Through</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-calendar placeholder="Select Date"
                                                                                formControlName="thruDateSellThrough">
                                                                            </p-calendar>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                
                                                               
                                                                </div>
                                                            </form>
                                                            <div class="col-lg-12 col-12 main-submit-button " style="
                                                            margin-left: -20%; margin-bottom: 6%!important;">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="searchInventory()">Find</button>
                                                            </div>
                                                            <ng-container *ngIf="inventories.length">
                                                                <div class="card own-account-table mt-2">
                                                                    <p-table [value]="inventories" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                        Product ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                              
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Total ATP</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Total QOH</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">   Ordered Quantity</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Minimum Stock</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                             
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Days To Ship</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  QOH minus Min Stock</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> ATP minus Min Stock</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Quantity UomId</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Usage</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Default Price</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> List Price</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Whole Sale Price</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  From Date Sell Through</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Sell Through Initial Inventory</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Sell Through Inventory Sold</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">   Sell Through Percentage</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                        <span
                                                                                             class="account-button" 
                                                                                            (click)="navigateFormInventoryPage(product.productId)">{{product.productId}}</span>
                                                                                        <!-- (click)="scroll('inventoryItem',true,'collapseFive')" -->
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.totalAvailableToPromise}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.totalQuantityOnHand}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.quantityOnOrder}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.minimumStock}}
                                                                                </td>
                                                                                <!-- <td [ngStyle]="{'width':'190px'}" >
                                                                                        {{product.reorderQuantity}}
                                                                                    </td> -->
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.daysToShip}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.offsetQOHQtyAvailable}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.offsetATPQtyAvailable}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.quantityUom}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.usageQuantity}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.defaultPrice}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.listPrice}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.wholeSalePrice}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <!-- <p class="paginate_data">
                                                                                                                                                                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                    <p class="paginate_data">
                                                                        View per page </p>

                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'inventoryItem'">
                                                            <div class="card own-account-table mt-2">
                                                                <p-table [value]="inventoryItems" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                    Product ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> QOH</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> ATP</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Cost Price</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Retail Price</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Total Cost Price</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;"> Total Retail Price</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.productId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.quantityOnHand}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.availableToPromise}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.costPrice | number : '1.0-2' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.retailPrice | number : '1.0-2' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.totalCostPrice | number : '1.0-2' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.totalRetailPrice | number : '1.0-2' }}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                                                                                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                    View per page </p>

                                                            </div>

                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'inventoryGrand'">
                                                            <div class="card own-account-table mt-2">
                                                                <p-table [value]="inventoryGrands" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                    QOH Total Qty
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  ATP Total Qty</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                         
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Total Cost Price</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Total Retail Price</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.qohGrandTotal}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.atpGrandTotal}}
                                                                            </td>
                                                                            <!-- <td [ngStyle]="{'width':'190px'}" >
                                                                                    {{product.availableToPromise}}
                                                                                </td> -->
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.totalCostPriceGrandTotal | number : '1.0-2' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.totalRetailPriceGrandTotal | number : '1.0-2' }}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                                                                                                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                    View per page </p>

                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'inventoryAverage'">
                                                            <div class="card own-account-table mt-2">
                                                                <p-table [value]="inventoryAverageCosts"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    Product ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Total QOH</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Average Cost</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Total Cost</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.productId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.totalQuantityOnHand}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productAverageCost}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.totalInventoryCost}}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                                                                                                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                    View per page </p>

                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==4">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Inventory Items
                                                    </h4>

                                                    <span class="edit-buttons"
                                                    routerLink="/facilities/warehouse/create-new-inventory-item"
                                                    [queryParams]="{facilityId:facilityId}"
                                                    routerLinkActive="active" style="margin-left: 76%!important;">Create New Inventory Items</span>

                                                    <span class="edit-buttons"
                                                        routerLink="/facilities/warehouse/search-by-label"
                                                        [queryParams]="{facilityId:facilityId}"
                                                        routerLinkActive="active">Search by Labels</span>
                                                        
                                                </div>

                                                <div class="create-new-leade mt-2">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form [formGroup]="inventoryItemFindForm">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputA modal-input">
                                                                                <label for="exampleInputEmail1">Facility
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <ng-select [items]="facilityIds"
                                                                                bindLabel="facilityName"
                                                                                [(ngModel)]="facilityId"
                                                                                bindValue="facilityId"
                                                                                formControlName="facilityId"
                                                                                class="ng-select-width"></ng-select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputA modal-input">
                                                                                <label for="exampleInputEmail1">Date Time
                                                                                    Received</label>
                                                                            </div>
                                                                            <div class="col-lg-2"  style="display:none;">
                                                                                <p-dropdown filter="true" name="party"
                                                                                [options]="greaterArray"
                                                                                formControlName="datetimeReceivedFromSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="date" class="form-control"
                                                                                placeholder="Select Date" class="ml-1"
                                                                                formControlName="datetimeReceivedFrom">
                                                                            </div>
                                                                            <div class="col-lg-2" style="display:none;">
                                                                                <p-dropdown filter="true" class="ml-1"
                                                                                    name="party" [options]="smallerArray"
                                                                                    formControlName="datetimeReceivedToSearchType"
                                                                                    optionlabel="label">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="date" class="form-control"
                                                                                    placeholder="Select Date" class="ml-1"
                                                                                    formControlName="datetimeReceivedTo">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputA modal-input">
                                                                                <label for="exampleInputEmail1">Product
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="party"
                                                                                        [options]="opportunityIdArray"
                                                                                        formControlName="productIdSearchType"
                                                                                        optionlabel="label">
                                                                                    </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" class="form-control modal-input-height"
                                                                                        id="exampleInputEmail1"
                                                                                        formControlName="productId"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Product ID">
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Internal
                                                                                    Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="party"
                                                                                        [options]="opportunityIdArray"
                                                                                        formControlName="internalNameSearchType"
                                                                                        optionlabel="label">
                                                                                    </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" id="exampleInputEmail1"
                                                                                        class="form-control modal-input-height"
                                                                                        formControlName="internalName"
                                                                                        aria-describedby="emailHelp"
                                                                                        placeholder="Internal Name">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputA modal-input">
                                                                                <label for="exampleInputEmail1">Inventory Item
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="party"
                                                                                [options]="opportunityIdArray"
                                                                                formControlName="inventoryItemIdSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" id="exampleInputEmail1"
                                                                                class="form-control modal-input-height"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="inventoryItemId"
                                                                                placeholder="Inventory Item ID">
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Serial
                                                                                    Number</label>
                                                                            </div>
                                                                            <div class="col-lg-2" style="display: none;">
                                                                                <p-dropdown filter="true" name="party"
                                                                                [options]="opportunityIdArray"
                                                                                formControlName="serialNumberSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" class="form-control modal-input-height"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp"
                                                                                        formControlName="serialNumber"
                                                                                        placeholder="Serial Number">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputA modal-input">
                                                                                <label for="exampleInputEmail1">Soft
                                                                                    Identifier</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" class="form-control modal-input-height"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="softIdentifier"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Soft Identifier">
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Status
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown [options]="statusIds"
                                                                                    placeholder="Select Status"
                                                                                    formControlName="statusIds" filter="true">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInputA modal-input">
                                                                                <label for="exampleInputEmail1">Manufacturer
                                                                                    Party
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown [options]="partyIds"
                                                                                    placeholder="Select Party ID"
                                                                                    formControlName="partyId"></p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-2 form-group classInputBA">
                                                                                <label for="exampleInputEmail1">Lot ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="email" class="form-control modal-input-height"
                                                                                id="exampleInputEmail1" formControlName="lotId"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Lot ID">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                </div>
                                                            </form>
                                                            <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -10%; margin-bottom: 4% !important;"><button
                                                                type="submit" (click)="getInventoryItems()"
                                                                class="btn btn-secondary submit-btn">Find</button></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="panel-body">
                                                    
                                                     
                                                    

                                                    <ng-container *ngIf="inventoryItemsFind.length">
                                                        <div class="card own-account-table mt-2">
                                                            <p-table [value]="inventoryItemsFind" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                Inventory Item ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  InventoryItem Type ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Status ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Date Time Received</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Expire Date Product ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Product ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Internal Name</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Party ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Location Seq ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Type</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Lot ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Bin Number</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Serial Number</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Soft Identifier</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Quantity On Hand Total</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Created Date</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Transfer</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                            
                                                                            <span  class="account-button"  (click)="detailInventoryItem(this.facilityId,product.inventoryItemId)">
                                                                                {{product.inventoryItemId}}
                                                                            </span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.inventoryItemTypeId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.statusId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.datetimeReceived |date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.expireDate  ? (product.expireDate|date:'yyyy-MM-d hh:mm:ss') :'N/A'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.productId }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.internalName }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.partyId }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.locationSeqId }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.locationSeqIdType}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.lotId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.binNumber}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.serialNumber}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.softIdentifier}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.availableToPromiseTotal}}/
                                                                            {{product.quantityOnHandTotal}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <fa-icon [icon]="faEdit"
                                                                                class="text-primary mr-2"
                                                                                routerLink="/facilities/warehouse/create-new-inventory-item"
                                                                                [queryParams]="{facilityId:facilityId,inventoryItemId:product.inventoryItemId}">
                                                                            </fa-icon>
                                                                            <span  class="account-button" 
                                                                                routerLink="/facilities/warehouse/create-new-inventory-transfer"
                                                                                [queryParams]="{facilityId:facilityId,inventoryItemId:product.inventoryItemId}">Transfer</span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                                                                                                                                                                                                                View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                View per page </p>

                                                        </div>
                                                    </ng-container>

                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==5">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Inventory Items Details
                                                    </h4>

                                                </div>
                                                <div class="panel-body">
                                                    <form [formGroup]="inventoryItemDetailsForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputB">
                                                                        <label for="exampleInputEmail1">Facility
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select [items]="facilityIds"
                                                                        bindLabel="facilityName"
                                                                        [(ngModel)]="facilityId"
                                                                        bindValue="facilityId"
                                                                        formControlName="facilityId"
                                                                        class="ng-select-width"></ng-select>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Soft
                                                                            Identifier</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        formControlName="softIdentifier"
                                                                        aria-describedby="emailHelp" >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputB">
                                                                        <label for="exampleInputEmail1">Effective
                                                                            Date</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" name="party"
                                                                                [options]="greaterArray"
                                                                                formControlName="effectiveDateFromSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar placeholder="Select Date"
                                                                                formControlName="effectiveDateFrom">
                                                                            </p-calendar>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" name="party"
                                                                        [options]="smallerArray"
                                                                        formControlName="effectiveDateToSearchType"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-calendar placeholder="Select Date"
                                                                        formControlName="effectiveDateTo">
                                                                    </p-calendar>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputB">
                                                                        <label for="exampleInputEmail1">Product
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" name="party"
                                                                                [options]="opportunityIdArray"
                                                                                formControlName="productIdSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="productId"
                                                                                aria-describedby="emailHelp"
                                                                                >
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Inventory Item
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" name="party"
                                                                        [options]="opportunityIdArray"
                                                                        formControlName="inventoryItemIdSearchType"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="inventoryItemId"
                                                                                >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputB">
                                                                        <label for="exampleInputEmail1">Serial
                                                                            Number</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" name="party"
                                                                                [options]="opportunityIdArray"
                                                                                formControlName="serialNumberSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        formControlName="serialNumber"
                                                                        >
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Order ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="orderIds"
                                                                        optionlabel="value" formControlName="orderId"
                                                                        placeholder="Order Id"></p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputB">
                                                                        <label for="exampleInputEmail1">Shipment
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="shipments"
                                                                        optionlabel="value" formControlName="shipmentId"
                                                                        placeholder="Shipment Id"></p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Return
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="returnIds"
                                                                        optionlabel="label"
                                                                        formControlName="returnId"
                                                                        placeholder="Return Id">
                                                                    </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputB">
                                                                        <label for="exampleInputEmail1">Work Effort
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select [items]="workEfforts"
                                                                        bindLabel="workEffortName"
                                                                        placeholder="Work Effort Id"
                                                                        bindValue="workEffortId"
                                                                        formControlName="workEffortId"
                                                                        class="ng-select-width"></ng-select>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Reason Enum
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                            [options]="reasonEnumIds"
                                                                            optionlabel="label"
                                                                            formControlName="reasonEnumId"
                                                                            placeholder="Reason Enum Id">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputB">
                                                                        <label for="exampleInputEmail1">Report
                                                                            Type</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <ng-select [items]="reportTypes"
                                                                        bindLabel="label" placeholder="Report Type"
                                                                        bindValue="value" class="ng-select-width">
                                                                    </ng-select>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Quantity On Hand
                                                                            Diff</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" name="party"
                                                                                [options]="greaterArray"
                                                                                formControlName="quantityOnHandDiffSearchType"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="quantityOnHandDiff"
                                                                                placeholder="0">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                       
                                                       

                                                        </div>
                                                    </form>
                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -30%; margin-bottom: 6%!important;"><button
                                                            type="submit" (click)="getInventoryItemDetails()"
                                                            class="btn btn-secondary submit-btn">Find</button></div>
                                                    <ng-container *ngIf="inventoryItemsDetails.length">
                                                        <div class="card own-account-table mt-2">
                                                            <p-table [value]="inventoryItemsDetails"
                                                                dataKey="inventoryItemId" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox> 
                                                                                Inventory Item ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Product ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> Quantity On Hand Total</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Available To Promise Total</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Serial Number</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Soft Identifier</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"></div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"></div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"></div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"></div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"></div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"></div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                            <div style="color: white;"> 

                                                                            IISD ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Effective Date</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;"> QOHD</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Available To Promise Diff</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Reason Enum ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">Description</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Order Id</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Order Item Seq Id</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Shipment Id</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Shipment Item Seq Id</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Work Effort Id</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Return Id</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Return Item Seq Id</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product
                                                                    let-expanded="expanded" let-rowIndex="rowIndex">
                                                                    <tr class="ui-widget-header"
                                                                        *ngIf="rowGroupMetaData[product.inventoryItemId].index === rowIndex">
                                                                        <td [ngStyle]="{'width':'190px'}"
                                                                            class="cursor-pointer">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                            <span [pRowToggler]="product" (click)="changeactiveCategoryFunction(4)"
                                                                                style="font-weight:bold;cursor: pointer; color: blue;">{{product.inventoryItemId}}</span>

                                                                                <div [ngStyle]="{'width':'190px'}">
                                                                                    <span>{{product.inventoryItemDetailSeqId}}</span>
                                                                                </div>
                                                                        </td>

                                                                        


                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.productId}}

                                                                            <div [ngStyle]="{'width':'190px'}">
                                                                                <span>{{product.effectiveDate ? (product.effectiveDate |date:'yyyy-MM-d hh:mm:ss'):'N/A'}}</span>
                                                                            </div>
                                                                        </td>

                                                                        

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.accountingQuantityTotal}}

                                                                            <div [ngStyle]="{'width':'190px'}">
                                                                                <span> {{product.quantityOnHandDiff }}</span>
                                                                             </div>
                                                                        </td>

                                                                       

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.availableToPromiseTotal}}

                                                                            <div [ngStyle]="{'width':'190px'}">
                                                                                <span> {{product.availableToPromiseDiff}}</span>
                                                                             </div>
                                                                        </td>

                                                                        

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.serialNumber}}

                                                                            <div [ngStyle]="{'width':'190px'}">
                                                                                <span>{{product.reasonEnumId}}</span>
                                                                            </div>
                                                                        </td>

                                                                        

                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.softIdentifier }}

                                                                            <div [ngStyle]="{'width':'190px'}">
                                                                                <span>{{product.orderId}}</span>
                                                                            </div>
                                                                        </td>

                                                                        
                                                                    </tr>
                                                                </ng-template>
                                                                <!-- <ng-template pTemplate="rowexpansion" let-product>
                                                                    <p-table class="mt-4"
                                                                        [value]="inventoryItemsDetails"
                                                                        dataKey="inventoryItemDetailSeqId">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                    <div style="color: white;"> 

                                                                                    Inventory Item Detail Seq ID</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Effective Date</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">   Quantity On Hand Diff</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">    Available To Promise Diff</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">   Reason Enum ID</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Order Id</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">   Order Item Seq Id</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Shipment Id</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Shipment Item Seq Id</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">   Work Effort Id</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">   Return Id</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">  Return Item Seq Id</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-rowData>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                              </p-checkbox>
                                                                                        <span
                                                                                             class="account-button" >{{rowData.inventoryItemDetailSeqId}}</span>
                                                                                    </div>

                                                                                    <div>

                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{rowData.effectiveDate ? (rowData.effectiveDate |date:'yyyy-MM-d hh:mm:ss'):'N/A'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{rowData.quantityOnHandDiff }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{rowData.availableToPromiseDiff}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{rowData.reasonEnumId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{rowData.orderId}}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record
                                                                                            Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template> 
                                                                    </p-table>
                                                                </ng-template> -->
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page -
                                                                {{(pageSize > total) ? total : pageSize}} of
                                                                {{total}} </p>
                                                            <p class="paginate_data">
                                                                View per page </p>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==6">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Receive Inventory
                                                    </h4>

                                                </div>
                                                <div class="panel-body">
                                                    <ng-container *ngIf="!isProductIdSelected; else productIdSelected">
                                                        <form [formGroup]="receiveInventoryPreForm">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInputC">
                                                                            <label for="exampleInputEmail1">Purchase
                                                                                Order
                                                                                Number</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="orderIds"
                                                                            optionlabel="value"
                                                                            formControlName="purchasedOrderNumber"
                                                                            placeholder="Order Number">
                                                                        </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputBA">
                                                                            <label for="exampleInputEmail1">Product
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="receiveProductsMasterValue"
                                                                            optionLabel="productId"
                                                                            formControlName="productId"
                                                                            placeholder="Product Id">
                                                                        </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               
                                                                
                                                            
                                                            </div>
                                                        </form>
                                                    </ng-container>
                                                    <ng-template #productIdSelected>
                                                        <form [formGroup]="receiveInventoryForm">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="productId">Product
                                                                                ID</label>
                                                                            <input type="text" class="form-control"
                                                                                [value]="receiveInventoryPreForm.get('productId').value.productId"
                                                                                readOnly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column"></div>
                                                                        <label for="productName">Product
                                                                            Name</label>
                                                                        <input type="text" class="form-control"
                                                                            [value]="receiveInventoryPreForm.get('productId').value.productName"
                                                                            readOnly>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-md-4">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-column">
                                                                                <label for="productDescription">Product
                                                                                    Description</label>
                                                                                <p>{{receiveInventoryPreForm.get('productId').value.description}}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="itemDescription">Item
                                                                            Description</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="itemDescription"
                                                                            disabled="true">

                                                                        <!-- [ngModelOptions]="{standalone: true}"
                                                                                [(ngModel)]="productIdDescription" -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="inventoryItemType">Inventory
                                                                            Item
                                                                            Type</label>
                                                                        <ng-select [items]="inventoryItemType"
                                                                            bindLabel="inventoryItemTypeId"
                                                                            placeholder="Inventory Item Type"
                                                                            bindValue="inventoryItemTypeId"
                                                                            formControlName="inventoryItemTypeId">
                                                                        </ng-select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="owner">Owner</label>
                                                                            <p-dropdown placeholder="Select Owner"
                                                                                formControlName="ownerPartyId"
                                                                                [options]="partyIds">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <!-- <ng-select [items]=""
                                                                            placeholder="Owner" bindLabel="partyId"
                                                                            bindValue="partyId"
                                                                            >
                                                                        </ng-select> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="supplier">Supplier</label>

                                                                            <p-dropdown filter="true"
                                                                                [options]="suppliers"
                                                                                optionlabel="label"
                                                                                formControlName="partyId"
                                                                                placeholder="Supplier">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="dateReceived">Date
                                                                                Received</label>
                                                                            <p-calendar placeholder="Select Date"
                                                                                formControlName="datetimeReceived">
                                                                            </p-calendar>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="lotId">Lot ID</label>
                                                                        <input type="text" class="form-control"
                                                                            placeholder="Lot Id"
                                                                            formControlName="lotId">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="facilityLocation">Facility
                                                                                Location</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="facilityLocationBinding"
                                                                                formControlName="locationSeqId"
                                                                                optionlabel="label"
                                                                                placeholder="Facility Location">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="rejectedReason">Rejected
                                                                                Reason</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="reasonEnumIds"
                                                                                optionlabel="label"
                                                                                formControlName="rejectionId"
                                                                                placeholder="Rejection Reason">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="rejectedReason">Quantity
                                                                            Rejected</label>
                                                                        <input type="text" class="form-control"
                                                                            placeholder="Quantity Rejected"
                                                                            formControlName="quantityRejected">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="rejectedReason">Quantity
                                                                            Accepted</label>
                                                                        <input type="text" class="form-control"
                                                                            placeholder="Quantity Accepted"
                                                                            formControlName="quantityAccepted">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="rejectedReason">Per Unit
                                                                            Price</label>
                                                                        <input type="text" class="form-control"
                                                                            placeholder="Per Unit Price"
                                                                            formControlName="unitCost">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </ng-template>
                                                    <div class="col-lg-12 col-12 main-submit-button "  style="    margin-left: -23%;">
                                                        <button type="submit" (click)="receivingForm()"
                                                            class="btn btn-secondary submit-btn">Receive
                                                            Products</button>
                                                        <button class="btn btn-danger ml-2"
                                                            (click)="isProductIdSelected=false">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==7">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Physical Inventory
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                              
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" [options]="products"
                                                                    optionlabel="label"
                                                                    [(ngModel)]="physicalInventoryFind.productId"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    placeholder="Product Id">
                                                                </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputBA">
                                                                    <label for="exampleInputEmail1">Internal
                                                                        Name</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1"
                                                                    [(ngModel)]="physicalInventoryFind.internalName"
                                                                    [ngModelOptions]="{standalone:true}"
                                                                    aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                       
                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button "  style="    margin-left: -29%; "><button
                                                            type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="getPhysicalInventory()">Find</button></div>
                                                    <ng-container *ngIf="physicalInventories.length">
                                                        <div class="card own-account-table mt-2">
                                                            <p-table [value]="physicalInventories" [paginator]="true"
                                                                [rows]="rows" scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox" binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                                Inventory Item ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Product ID</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Internal Name</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Item ATP</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Item QOH</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">   Product ATP</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Product QOH</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="price">
                                                                            <div style="color: white;">  Action</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                <span
                                                                                     class="account-button" >{{product.inventoryItemId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.productId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.internalName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.availableToPromiseTotal}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.quantityOnHandTotal}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.productATP }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.productQOH }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <span
                                                                                (click)="createPhysical(product.inventoryItemId,product.facilityId,product.productId,product.internalName,product.availableToPromiseTotal,product.quantityOnHandTotal,product.productATP,product.productQOH);"
                                                                                 class="account-button" >
                                                                                <fa-icon [icon]="faEdit"></fa-icon>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                            <!-- <p class="paginate_data">
                                                                    View per page </p> -->
                                                        </div>
                                                    </ng-container>

                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==8">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Inventory Xfers
                                                    </h4>
                                                    <!-- <span class="edit-buttons"
                                                        routerLink="/facilities/warehouse/create-new-inventory-transfer"
                                                        [queryParams]="{facilityId:facilityId}">Create</span> -->
                                                        <span class="edit-buttons"
                                                        (click)="InventoryModal()" data-toggle="modal"
                                                        data-target="#inventoryModalCenter">Create New Inventory Xfers</span>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="suppliers-wrapper col-lg-12 col-12 mb-20 mt-1">
                                                        <button type="button" class="btn btn-outline-secondary"
                                                            [ngClass]="{'active':inventoryTransferTab==='activeOnly'}"
                                                            (click)="getTransferInventory('activeOnly')">Active
                                                            Only</button>
                                                        <button type="button" class="btn btn-outline-secondary"
                                                            [ngClass]="{'active':inventoryTransferTab==='all'}"
                                                            (click)="getTransferInventory('all')">All</button>
                                                        <button type="button" class="btn btn-outline-secondary" style="width: 21%!important;"
                                                            [ngClass]="{'active':inventoryTransferTab==='complete'}"
                                                            (click)="getTransferInventory('complete')">Complete
                                                            Requested
                                                            Transfers</button>
                                                    </div>



                                                    <div class="card own-account-table mt-2">
                                                        <div class="card-header">
                                                            <h4 class="text-primary">Inventory Transfers for
                                                                {{facilityId}}
                                                                To:
                                                                {{facilityId}}</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="mt-5">
                                                                <p-table [value]="inventoryTransfers.toTransfers"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    Inventory Transfer
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Inventory Item ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Facility ID To</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Facility Name</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Location Seq Id</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Product Id</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Product Name</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Serial Number</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  ATP/QOH</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Created Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Location Seq Id To</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Send Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Received Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Status Id</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Action</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.inventoryTransferId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.inventoryItemId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.facilityId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.facilityName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.locationSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productId }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productName }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.serialNumber}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.availableToPromiseTotal}}/{{product.quantityOnHandTotal}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp | date :'yyyy-MM-dd HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.locationSeqIdTo}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.sendDate ? (product.sendDate | date:'yyyy-MM-d hh:mm:ss'):'N/A'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.receiveDate ? (product.receiveDate | date:'yyyy-MM-d hh:mm:ss'):'N/A'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.status}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <fa-icon
                                                                                     class="account-button" 
                                                                                    [icon]="faEdit"
                                                                                    routerLink="/facilities/warehouse/create-new-inventory-transfer"
                                                                                    [queryParams]="{facilityId:facilityId,inventoryTransferId:product.inventoryTransferId}">
                                                                                </fa-icon>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                View per page -
                                                                                                {{(pageSize > total) ? total : pageSize}} of
                                                                                                {{total}} </p>
                                                                                            <p class="paginate_data">
                                                                                                View per page </p> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card own-account-table mt-2">
                                                        <div class="card-header">
                                                            <h4 class="text-primary">From: {{facilityId}}</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="mt-5">
                                                                <label for="to"></label>
                                                                <p-table [value]="inventoryTransfers.fromTransfers"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    Inventory Transfer
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Inventory Item ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Facility ID To</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Facility Name</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Location Seq Id</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Product Id</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Product Name</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Serial Number</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   ATP/QOH</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Created Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Location Seq Id To</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Send Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Received Date</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   {{inventoryTransferTab==='complete' ?'Select' :'Status Id'}}
                                                                               </div> <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">    Action</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product
                                                                        let-rowIndex="rowIndex">
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.inventoryTransferId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.inventoryItemId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.facilityId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.facilityName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.locationSeqId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productId }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productName }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.serialNumber}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.availableToPromiseTotal}}/{{product.quantityOnHandTotal}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp | date :'yyyy-MM-dd HH:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.locationSeqIdTo}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.sendDate ? (product.sendDate | date:'yyyy-MM-d hh:mm:ss'):'N/A'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.receiveDate ? (product.receiveDate | date:'yyyy-MM-d hh:mm:ss'):'N/A'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <ng-container
                                                                                    *ngIf="inventoryTransferTab==='complete'; else status">
                                                                                    <div class="form-check">
                                                                                        <input class="form-check-input"
                                                                                            type="checkbox" value=""
                                                                                            (change)="storeValueForCompleteRequest(product.inventoryItemId,product.inventoryTransferId,$event)"
                                                                                            [id]="'defaultCheck1'+rowIndex">
                                                                                    </div>
                                                                                </ng-container>
                                                                                <ng-template #status>
                                                                                    {{product.status}}
                                                                                </ng-template>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <fa-icon
                                                                                     class="account-button" 
                                                                                    [icon]="faEdit"
                                                                                    routerLink="/facilities/warehouse/create-new-inventory-transfer"
                                                                                    [queryParams]="{facilityId:facilityId,inventoryTransferId:product.inventoryTransferId}">
                                                                                </fa-icon>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                View per page -
                                                                {{(pageSize > total) ? total : pageSize}} of
                                                                {{total}} </p>
                                                            <p class="paginate_data">
                                                                View per page </p> -->
                                                                <button class="btn btn-secondary cursor-pointer"
                                                                    *ngIf="inventoryTransferTab==='complete'"
                                                                    (click)="submitToCompleteTransfer()">Submit</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==9">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Receive Return
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                              
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Return
                                                                        Number</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                    [(ngModel)]="receiveReturnNumber"
                                                                    [ngModelOptions]="{standalone:true}" placeholder="Return Number">
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                       
                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button "  style="
                                                    margin-left: -71%;">
                                                        <button type="submit" (click)="receiveReturn();"
                                                            class="btn btn-secondary submit-btn">Receive
                                                            Products</button></div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==10">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Inventory Xfers
                                                    </h4>
                                                    <span class="edit-buttons"
                                                        routerLink="/facilities/warehouse/review-order"
                                                        [queryParams]="{facilityId:facilityId}">Review Orders
                                                        Not
                                                        Pick-Packed</span>
                                                </div>
                                                <div class="panel-body">
                                                    <div
                                                        class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                       <span class="color-grey">Search Options To Groups</span>
                                                    </div>
                                                    <form class="w-100" [formGroup]="searchPickingOrderForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group row">
                                                                    <label for="exampleInputEmail1">Group by
                                                                        shipping method</label>&nbsp;
                                                                    <p-checkbox value="Y"
                                                                        formControlName="groupByShippingMethod">
                                                                    </p-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group row">
                                                                    <label for="exampleInputEmail1">Group by
                                                                        warehouse area
                                                                    </label>&nbsp;
                                                                    <p-checkbox value="Y"
                                                                        formControlName="groupByWarehouseArea">
                                                                    </p-checkbox>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-12">
                                                                <div class="form-group row">
                                                                    <label for="exampleInputEmail1">Group by order
                                                                        items
                                                                    </label>&nbsp;
                                                                    <p-checkbox value="Y"
                                                                        formControlName="groupByNoOfOrderItems">
                                                                    </p-checkbox>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                              
                                                                <div class="col-lg-2 form-group classInputC">
                                                                    <label for="groupFirst">Group First</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="maxNumberOfOrders">
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <button type="submit" (click)="searchOrderPicking()"
                                                                        class="btn btn-secondary submit-btn ml-2">Submit</button>&nbsp;
                                                                </div>
                                                            </div>
                                                        </div>
                                                      
                                                        <div
                                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div
                                                                        class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                       <span class="color-grey">Find Order To
                                                                            Pick</span>
                                                                    </div>
                                                                    <div class="card own-account-table">
                                                                        <p-table id="table" [paginator]="true"
                                                                            [value]="orderForPicking" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>

                                                                                            Order Nbr <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="name"> <div style="color: white;">  Ready
                                                                                        To Pick <p-sortIcon
                                                                                            field="name">
                                                                                        </p-sortIcon></div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">   Need Stock Move<p-sortIcon
                                                                                            field="category">
                                                                                        </p-sortIcon></div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">     Action<p-sortIcon
                                                                                            field="category">
                                                                                        </p-sortIcon></div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                            {{product.orderId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        {{product.readyToPick}}</td>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        {{product.needStockMove}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        <div
                                                                                            *ngIf="product.needStockMove==='N'">
                                                                                            <button
                                                                                                (click)="createPickList(product.orderId)"
                                                                                                class="btn btn-primary cursor-pointer">Create
                                                                                                PickList</button>
                                                                                            <fa-icon [icon]="faPrint"
                                                                                                (click)="printPickSheet(product.orderId)"
                                                                                                class="text-primary cursor-pointer ml-2">
                                                                                            </fa-icon>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <!-- <p class="paginate_data">
                                                                                                        View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==11">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Stock Moves
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <form [formGroup]="stockProcessForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputAF">
                                                                        <label for="exampleInputEmail1">Product
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="products"
                                                                        optionlabel="label" formControlName="productId"
                                                                        placeholder="Product Id">
                                                                    </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">From
                                                                            Location</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                        [options]="facilityLocationBinding"
                                                                        optionlabel="label"
                                                                        formControlName="locationSeqId"
                                                                        placeholder="Form Location">
                                                                    </p-dropdown>
                                                                    </div>
                                                                 
                                                                </div>
                                                            </div>
                                                         
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInputAF">
                                                                        <label for="exampleInputEmail1">To
                                                                            Location</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true"
                                                                        [options]="facilityLocationBinding"
                                                                        optionlabel="label"
                                                                        formControlName="targetLocationSeqId"
                                                                        placeholder="To Location">
                                                                    </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputBA">
                                                                        <label for="exampleInputEmail1"> Move
                                                                            Quantity</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        formControlName="quantityMoved"
                                                                        aria-describedby="emailHelp" >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                       
                                                       
                                                        
                                                        </div>
                                                        <button style=" margin-left: 55%;"
                                                            class="btn btn-secondary cursor pointer main-submit-button update-button"
                                                            (click)="quickStockMove()">Quick
                                                            Stock Move</button>
                                                    </form>
                                                    <div class="card own-account-table mt-2">
                                                        <div class="card-header">
                                                            <h4 class="text-primary"></h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="card own-account-table mt-2">
                                                                <p-table [value]="stockMove" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                   
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    Product ID <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Product <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price"> <div style="color: white;">  From
                                                                                Location</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price"> <div style="color: white;">  QOH
                                                                              </div>  <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   ATP</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">    To Location</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   QOH</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   ATP</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Minimum Stock</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Move Quantity</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Do you confirm?</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Select all</div>
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    (click)="productValueSelectAll($event)"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product
                                                                        let-rowIndex="rowIndex">
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.product.productId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.product.productName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.facilityLocationFrom.aisleId}}:
                                                                                {{product.facilityLocationFrom.areaId}}:
                                                                                {{product.facilityLocationFrom.sectionId}}:
                                                                                {{product.facilityLocationFrom.levelId}}:
                                                                                {{product.facilityLocationFrom.positionId}}({{product.facilityLocationFrom.locationType}})[{{product.facilityLocationFrom.locationSeqId}}]
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.quantityOnHandTotalFrom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.availableToPromiseTotalFrom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.facilityLocationTo.aisleId}}:
                                                                                {{product.facilityLocationTo.areaId}}:
                                                                                {{product.facilityLocationTo.sectionId}}:
                                                                                {{product.facilityLocationTo.levelId}}:
                                                                                {{product.facilityLocationTo.positionId}}({{product.facilityLocationTo.locationType}})[{{product.facilityLocationTo.locationSeqId}}]
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.quantityOnHandTotalTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.availableToPromiseTotalTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.targetProductFacilityLocation.minimumStock}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.targetProductFacilityLocation.moveQuantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <input type="text"
                                                                                    id="column{{rowIndex+1}}"
                                                                                    [(ngModel)]="product.totalQuantity"
                                                                                    [ngModelOptions]="{standalone: true}" />

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    (click)="productValue(product)"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <!-- <p class="paginate_data">
                                                                                                                                                                        View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                <p class="paginate_data">
                                                                    View per page </p>

                                                            </div>

                                                            <button class="btn btn-secondary cursor-pointer mt-4"
                                                                (click)="completeStockMove()">Submit</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==12">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Manage Picklists
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <ng-template ngFor let-pickList [ngForOf]="managePickList"
                                                        let-index="index">
                                                        <form>
                                                            <div class=" bg-white color-grey">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-4 col-12 ">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">Picklist
                                                                                <span
                                                                                     class="account-button">{{pickList.picklist.picklistId}}</span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-12 ">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">Date:</label>&nbsp;
                                                                            <h4>{{pickList.picklist.createdDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-4 col-12">
                                                                        <div class="form-group">
                                                                            <label
                                                                                for="exampleInputEmail1">created/modified
                                                                                by:</label>&nbsp;
                                                                            <h4>{{pickList.picklist.createdByUserLogin}}/{{pickList.picklist.lastModifiedByUserLogin}}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button ">
                                                                        <button _ngcontent-cta-c128="" type="submit"
                                                                            (click)="printPickPackReport(pickList.picklist.picklistId)"
                                                                            class="btn btn-secondary submit-btn cursor-pointer">Pick/Packing
                                                                            Reports</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class=" bg-white color-grey">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                    <div class="col-lg-12 ">
                                                                        <div class="form-group">
                                                                          <div class="row"  >
                                                                                <label for="exampleInputEmail1"
                                                                                    class="col-sm-2 col-form-label">Picklist
                                                                                    Status</label>
                                                                                <div class="more-filters-option row">
                                                                                    <div class="col-md-6">
                                                                                        <p-dropdown filter="true"
                                                                                            [options]="pickList.picklistStatusInfo"
                                                                                            optionLabel="statusId"
                                                                                            optionValue="statusId"
                                                                                            (onChange)="setStatus($event)"
                                                                                            placeholder="Select Status">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                </div>
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    (click)="pickListStatusUpdate(pickList.picklist.picklistId)"
                                                                                    class="btn btn-secondary submit-btn cursor-pointer">Update</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                
                                                                     
                                                                <div class="col-lg-12" *ngIf="pickList.picklistRoleInfoList">
                                                                    <div class="form-group row" *ngFor="let product of pickList.picklistRoleInfoList;">
                                                                        <label for="exampleInputEmail1"
                                                                            class="col-sm-2 col-form-label">Party
                                                                            </label>
                                                                            <label for="exampleInputEmail1"
                                                                            class="col-sm-2 col-form-label">  {{product.partyNameView.firstName}} {{product.partyNameView.lastName}}
                                                                            </label>

                                                                            <label for="exampleInputEmail1"
                                                                            class="col-sm-2 col-form-label">Role
                                                                            </label>
                                                                            <label for="exampleInputEmail1"
                                                                            class="col-sm-2 col-form-label"> {{product.roleType.description}}
                                                                            </label>

                                                                            <label for="exampleInputEmail1"
                                                                            class="col-sm-2 col-form-label">From Date
                                                                            </label>
                                                                            <label for="exampleInputEmail1"
                                                                            class="col-sm-2 col-form-label"> {{product.picklistRole.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </label>
                                                                      
                                                                        
                                                                    </div>

                                                                </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">Assign
                                                                                Picker</label>
                                                                            <div class="more-filters-option row">
                                                                                <div class="col-md-6">
                                                                                    <p-dropdown filter="true"
                                                                                        (onChange)="setPartyId($event)"
                                                                                        [options]="assignPickerList"
                                                                                        placeholder="Select Assign">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="createAssignPickList(pickList.picklist.picklistId)"
                                                                                class="btn btn-secondary submit-btn cursor-pointer">Add</button>
                                                                        </div>

                                                                    </div>

                                                                 
                                                                 
                                                                <div class="col-lg-12" *ngFor="let product of pickList.picklistStatusHistoryInfoList;">
                                                                    <div class="form-group row" *ngIf="product.statusItemTo!=null">
                                                                        <label for="exampleInputEmail1"
                                                                            class="col-sm-2 col-form-label">Status
                                                                            </label>
                                                                            <label for="exampleInputEmail1"
                                                                            class="col-sm-8 col-form-label"> Change from {{product.statusItem.description}} to {{product.statusItemTo.description}} on {{product.statusItemTo.lastUpdatedStamp|date:'yyyy-MM-dd hh:mm:ss'}} by {{product.picklistStatusHistory.changeByUserLoginId}}
                                                                            </label>
                                                                      
                                                                        
                                                                    </div>

                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class=" bg-white color-grey">
                                                                <div *ngIf="pickList.picklistBinInfoList"
                                                                    class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">Bin
                                                                                Num:</label>&nbsp;
                                                                            <h4>{{pickList.picklistBinInfoList[0].picklistBin.picklistBinId}}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">
                                                                                Primary
                                                                                Order ID:</label>&nbsp;
                                                                            <h4>{{pickList.picklistBinInfoList[0].picklistBin.primaryOrderId}}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">Primary
                                                                                Ship Group Seq ID:</label>&nbsp;
                                                                            <h4>{{pickList.picklistBinInfoList[0].picklistBin.primaryShipGroupSeqId}}
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">Update
                                                                                Bin Num:</label>&nbsp;
                                                                            <h4> 45</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">
                                                                                Location Nbr:</label>&nbsp;
                                                                            <div class="col-md-3">
                                                                                <input type="text" class="form-control"
                                                                                    [value]="pickList.picklistBinInfoList[0].picklistBin.binLocationNumber"
                                                                                    readOnly
                                                                                    placeholder="Enter Location number">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="form-group row">
                                                                            <label for="exampleInputEmail1"
                                                                                class="col-sm-2 col-form-label">PickList</label>
                                                                            <div class="more-filters-option row">
                                                                                <div class="col-md-6">
                                                                                    <p-dropdown filter="true"
                                                                                        [options]="pickListStatusInfo"
                                                                                        placeholder="Select">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                                (click)="updatePickListBinItem(pickList.picklistBinInfoList[0].picklistBin.binLocationNumber,pickList.picklistBinInfoList[0].picklistBin.picklistBinId,pickList.picklist.picklistId)"
                                                                                class="btn btn-secondary submit-btn cursor-pointer">Update</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div
                                                            class="all-users-infomation border-top-grey font-13 font-medium own-user-select">
                                                            <div class="form-group" *ngIf="pickList.picklistBinInfoList">
                                                                <div class="card own-account-table">
                                                                    <p-table [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [value]="pickList.picklistBinInfoList[0].picklistItemInfoList"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                        Order ID <p-sortIcon
                                                                                            field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name"> <div style="color: white;">  Ship
                                                                                    Group
                                                                                    ID <p-sortIcon field="name">
                                                                                    </p-sortIcon></div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category"> <div style="color: white;">  Order
                                                                                    Item<p-sortIcon field="category">
                                                                                    </p-sortIcon></div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity">
                                                                                    <div style="color: white;">   Product
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon></div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">  Inventory Item<p-sortIcon
                                                                                        field="category">
                                                                                    </p-sortIcon></div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">   Location
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon></div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">    Quantity
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon></div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price"> <div style="color: white;">  Action
                                                                                 </div>   <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined"
                                                                                                    value="undefined"></div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                        {{product.orderItem.orderId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.picklistBin.primaryShipGroupSeqId}}
                                                                                </td>
                                                                                <td>{{product.orderItem.orderItemSeqId}}
                                                                                </td>
                                                                                <td>{{product.orderItem.productId}}
                                                                                </td>
                                                                                <td>{{product.inventoryItemAndLocation.inventoryItemId}}
                                                                                </td>
                                                                                <td>{{product.inventoryItemAndLocation.locationSeqId}}
                                                                                </td>
                                                                                <td>{{product.orderItem.quantity}}
                                                                                </td>

                                                                                <td>
                                                                                    <fa-icon [icon]="faTrash"
                                                                                        (click)="deletePickListOrderId(product.inventoryItemAndLocation.inventoryItemId,product.orderItem.orderId,product.orderItem.orderItemSeqId,pickList.picklistBinInfoList[0].picklistBin.picklistBinId,pickList.picklistBinInfoList[0].picklistBin.primaryShipGroupSeqId)"
                                                                                        class="text-danger">
                                                                                    </fa-icon>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==13">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Verify Pick
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                       

                                                      
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-2 form-group classInputV">
                                                                        <label for="exampleInputEmail1">Order
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="orderIds"
                                                                        optionlabel="label"
                                                                        [(ngModel)]="orderIdVerifyPick"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        placeholder="Order Number">
                                                                    </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <button _ngcontent-cta-c128=""
                                                                        class="btn btn-secondary submit-btn"
                                                                        (click)="verifyOrderNumber()">Verify
                                                                        Order</button>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Picklist
                                                                            Bin</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input [ngStyle]="{'width':'138px'}" type="text"
                                                                            class="form-control" [(ngModel)]="Picklist"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            placeholder="Picklist Bin">
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <button _ngcontent-cta-c128=""
                                                                        (click)="verifyOrderNumberPick()"
                                                                        class="btn btn-secondary submit-btn">Verify
                                                                        Order</button>
                                                                    </div>
                                                                 
                                                                </div>
                                                            </div>
                                                       

                                                   

                                                      



                                                        <ng-container *ngIf="isVerify">

                                                            <div class="card own-account-table mt-2">
                                                                <div class="card-header">
                                                                    <h4 class="text-primary">Order ID /
                                                                        {{orderIdVerifyPick}}</h4>
                                                                </div>
                                                                <div class="card-body">
                                                                    <div class=" bg-white color-grey">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                            <div class="col-lg-4 col-12 ">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Ship-To
                                                                                        Address:</label>&nbsp;
                                                                                    <h4>{{postalAddress.address1}}
                                                                                        {{postalAddress.city}}
                                                                                        ,
                                                                                        {{postalAddress.stateProvinceGeoId}}
                                                                                        {{postalAddress.postalCode}}{{postalAddress.countryGeoId}}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-4 col-12">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Carrier
                                                                                        Shipment
                                                                                        Method:</label>&nbsp;
                                                                                    <h4>{{orderItemShipGroup.carrierPartyId}}{{orderItemShipGroup.shipmentMethodTypeId}}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-4 col-12">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Instructions:</label>&nbsp;
                                                                                    <h4>(None)</h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class=" bg-white color-grey">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                            <div class="mt-5">
                                                                                <div class="d-flex flex-row">
                                                                                    <label for="product">Product
                                                                                        #</label>&nbsp;
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [ngStyle]="{'width':'250px'}"
                                                                                        [(ngModel)]="verifyProductId"
                                                                                        [ngModelOptions]="{standalone:true}">&nbsp;
                                                                                    <label>@</label>&nbsp;
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [(ngModel)]="quantity"
                                                                                        [ngStyle]="{'width':'250px'}"
                                                                                        [ngModelOptions]="{standalone:true}">&nbsp;
                                                                                    <button
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="verifySingleItem()">Verify
                                                                                        Item</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class=" bg-white color-grey">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                            <div class="mt-5">
                                                                                <p-table [value]="verifyPicks"
                                                                                    [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div
                                                                                                    class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                                    Item Nbr
                                                                                                    <p-sortIcon
                                                                                                        field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">   Product ID</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">  Internal Name</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">   Ordered Quantity</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">  Verified Quantity</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">  Qty To Verify</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">   Select All</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    type="checkbox"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product
                                                                                        let-rowIndex="rowIndex">
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                <div
                                                                                                    class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button" >{{product.orderItemSeqId}}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.productId}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.itemDescription}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.quantity}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.selectedAmount}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.quantity }}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                <div class="form-check">
                                                                                                    <input
                                                                                                        class="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        value=""
                                                                                                        (change)="bulkItem($event,'USA',product.orderItemSeqId,product.productId,product.quantity)"
                                                                                                        [id]="'defaultCheck1'+rowIndex">
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button ">
                                                                        <button class="btn btn-secondary submit-btn"
                                                                            (click)="verifyBulkProductItem()">Verify
                                                                            Items</button>&nbsp;
                                                                        <button class="btn btn-secondary submit-btn"
                                                                            (click)="cancelBulkProductItem()">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container>

                                                            <div class="card own-account-table mt-2">
                                                                <div class="card-header">
                                                                    <h4 class="text-primary">Verified Items : </h4>
                                                                </div>
                                                                <div class="card-body">

                                                                    <div class=" bg-white color-grey">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                                            <div class="mt-5">
                                                                                <p-table [value]="verifiedItems"
                                                                                    [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%"
                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [totalRecords]="total"
                                                                                    [scrollable]="true"
                                                                                    (sortFunction)="customSort($event)"
                                                                                    [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div
                                                                                                    class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                    Item Nbr
                                                                                                    <p-sortIcon
                                                                                                        field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">   Product ID</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">   Inventory Item Nbr</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>

                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="price">
                                                                                                <div style="color: white;">   Verified Quantity</div>
                                                                                                <input
                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;"
                                                                                                    value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox"
                                                                                                    aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>



                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product
                                                                                        let-rowIndex="rowIndex">
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                <div
                                                                                                    class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                                    <span
                                                                                                         class="account-button" >{{product.orderItemSeqId}}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.productId}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.itemDescription}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.quantity}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.selectedAmount}}
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'190px'}">
                                                                                                {{product.quantity }}
                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button ">
                                                                        <button class="btn btn-secondary submit-btn"
                                                                            (click)="verifyBulkProductItem()">Complete</button>&nbsp;
                                                                        <button class="btn btn-secondary submit-btn"
                                                                            (click)="cancelBulkProductItem()">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==14">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Packing
                                                    </h4>
                                                </div>
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-2 form-group classInputV">
                                                                        <label for="exampleInputEmail1">Order
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="orderIds"
                                                                            optionlabel="label"
                                                                            [(ngModel)]="orderIdPacking"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            placeholder="Order Number">
                                                                        </p-dropdown>
                                                                    </div>

                                                                    <!-- <div class="col-lg-2">
                                                                        <input type="text" class="form-control"
                                                                        [(ngModel)]="extraShipping.shipGroupSeqId">
                                                                         
                                                                    </div> -->

                                                                    <div class="col-lg-2">
                                                                        <button _ngcontent-cta-c128=""
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="packOrder()">Pack Order</button>&nbsp;
                                                                    </div>
                                                                    <div class="col-lg-2 form-group classInputBA">
                                                                        <label for="exampleInputEmail1">Picklist
                                                                            Bin</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input [ngStyle]="{'width':'138px'}" type="text"
                                                                            class="form-control" placeholder="Picklist Bin">
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <button _ngcontent-cta-c128=""
                                                                        class="btn btn-secondary submit-btn">Pack
                                                                        Order</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                         
                                                    


                                                    </div>
                                                    <ng-container *ngIf="isPack">
                                                        <div class="card mt-2 text-black">
                                                            <div class="card-header updateBG">
                                                                <h4>Order ID : {{orderIdPacking}}</h4>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="col-md-4">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-column">
                                                                                <label>Ship-To-Address</label>
                                                                                <h4>To:
                                                                                    {{packingData.postalAddressMap.postalAddress.attnName}},
                                                                                    {{packingData.postalAddressMap.postalAddress.address1}},
                                                                                    {{packingData.postalAddressMap.postalAddress.city}},
                                                                                    {{packingData.postalAddressMap.postalAddress.postalCode}}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-column">
                                                                                <label>Carrier Shipment
                                                                                    Method</label>
                                                                                <h4>{{packingData.postalAddressMap.carrier}},
                                                                                    {{packingData.postalAddressMap.shipmentMethodTypeDescriptions}}
                                                                                </h4>
                                                                                <label>Estimated Ship Cost for Ship
                                                                                    Group</label>
                                                                                <h4>${{packingData.postalAddressMap.shipmentCostEstimateForShipGroup}}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-column">
                                                                                <label>Instructions</label>
                                                                                <h4>{{packingData.postalAddressMap.shippingInstructions}}
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-row">
                                                                                <label for="product">Product
                                                                                    #</label>&nbsp;
                                                                                <input type="text"
                                                                                    class="form-control ml5"
                                                                                    [(ngModel)]="packItemProductId"
                                                                                    [ngModelOptions]="{standalone:true}">
                                                                                <label>@</label>&nbsp;
                                                                                <input type="text"
                                                                                    class="form-control ml5"
                                                                                    [(ngModel)]="quantityPack"
                                                                                    [ngModelOptions]="{standalone:true}">&nbsp;
                                                                                <button _ngcontent-cta-c128=""
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="createPackItem()">Pack
                                                                                    Item</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="d-flex flex-row">
                                                                            <label for="package"> Current package
                                                                                sequence</label>
                                                                            <button _ngcontent-cta-c128=""
                                                                                class="btn btn-secondary submit-btn">Next
                                                                                Package</button>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="col-md-12 col-12">
                                                                        <p-table [value]="packingData?.itemInfos"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                            Item Nbr
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">  Product ID</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">  Internal Name</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">  Ordered Quantity</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">   Quantity Shipped</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">  Packed Qty</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">   Qty To Pack</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            type="checkbox"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">   Packed Weight (lbs)</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            type="checkbox"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">   Package</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            type="checkbox"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">   Packages</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            type="checkbox"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product
                                                                                let-rowIndex="rowIndex">
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'190px'}"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                            <span
                                                                                                 class="account-button" >{{product.orderItemSeqId}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.productId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.productInternalName}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.quantityOrdered}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.shippedQuantity}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.packedQuantity }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            [id]="'name'+rowIndex"
                                                                                            [value]="product.inputQty">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            [id]="'weight'+rowIndex">
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            [id]="'package'+rowIndex"
                                                                                            [value]="product.orderItemQuantity">
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                    </div>
                                                                    <div class="col-md-12 col-12">




                                                                        <button class="btn btn-primary cursor-pointer"
                                                                            (click)="bulkPackProcess(packingData?.itemInfos)">Pack
                                                                            Item </button>
                                                                        <button
                                                                            class="btn btn-primary cursor-pointer ml-2"
                                                                            (click)="clearAllPack()">Clear(All)</button>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group">
                                                                            <label for="additional">Additional
                                                                                Shipping Charge:</label>
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="extraShipping.additionalShipping"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="form-group">
                                                                            <label for="additional">Handling
                                                                                Instructions:</label>
                                                                            <input type="text" class="form-control"
                                                                                [(ngModel)]="extraShipping.handlingInstruction"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <button class="btn btn-secondary submit-btn"
                                                                            style="margin-top: 27px;"
                                                                            (click)="completePack()">Complete</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <ng-container *ngIf="isInventoryPack">
                                                            <div class="mb-2">
                                                                <h4>Packages:
                                                                    {{packingData?.packagesMap.packedLines.length}}</h4>
                                                                <p-table [value]="packingData?.packagesMap.packedLines"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                    Item Nbr
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Product ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Product Description</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Inventory Item Nbr</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Packed Qty</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Packed Weight (lbs) (Package)
                                                                             </div>   <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    type="checkbox"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Package Nbr</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    type="checkbox"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Action</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    type="checkbox"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product
                                                                        let-rowIndex="rowIndex">
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.orderItemSeqId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productDescription}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.inventoryItemId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.packedQuantity }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.packedQuantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <button
                                                                                    (click)="clearLinePack(product.inventoryItemId, product.orderItemSeqId, product.productId)"
                                                                                    class="btn btn-primary cursor-pointer">Clear</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                            <div class="mt-2">
                                                                <h4>Packages:
                                                                    {{packingData?.packagesMap.packedLines.length}}</h4>
                                                                <p-table [value]="packingData?.packagesMap.packedLines"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    Item Nbr
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Product ID</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Product Description</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">  Inventory Item Nbr</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Packed Qty</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Packed Weight (lbs) (Package)
                                                                             </div>   <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    type="checkbox"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Package Nbr</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    type="checkbox"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="price">
                                                                                <div style="color: white;">   Action</div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    type="checkbox"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    style="visibility:hidden;" value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product
                                                                        let-rowIndex="rowIndex">
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}"
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                    <span
                                                                                         class="account-button" >{{product.orderItemSeqId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.productDescription}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.inventoryItemId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.packedQuantity }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.packedQuantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <button
                                                                                    (click)="clearLinePack(product.inventoryItemId, product.orderItemSeqId, product.productId)"
                                                                                    class="btn btn-primary cursor-pointer">Clear</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
        </div>
    </div>
</div>
<app-create-new-contact-information *ngIf="showContactModal" (modalClosed)="onModalClosed()"></app-create-new-contact-information>
<app-create-inventory-transfer *ngIf="showInventoryModal" (InventoryModalClosed)="InventoryModalClosed()"></app-create-inventory-transfer>

<div class="modal fade" id="createNewInventoryPopup" tabindex="-1" role="dialog" aria-labelledby="createNewInventoryPopup"aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
            
                <span class="color-black pl-1"> Create New Inventory Items</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Create New Inventory Item</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createNewInventoryItemForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Inventory Item ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party"
                                                        
                                                        formControlName="inventoryItemID"
                                                        optionlabel="label"
                                                        placeholder="Select Inventory Item ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group cclassInputBA modal-input">
                                                        <label for="exampleInputEmail1">InventoryItem Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" name="party"
                                                        
                                                        formControlName="inventoryItemTypeID"
                                                        optionlabel="label"
                                                        placeholder="Select InventoryItem Type ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group cclassInputBA">
                                                        <label for="exampleInputEmail1">Product ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" name="party"
                                                        [options]="products"
                                                        formControlName="productID"
                                                        optionlabel="label"
                                                        placeholder="Select Product ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Party ID	

                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="party"
                                                                
                                                                formControlName="partyID"
                                                                optionlabel="label"
                                                                placeholder="Select Party ID">
                                                            </p-dropdown>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Owner Party ID	

                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" name="party"
                                                        
                                                        formControlName="ownerPartyID"
                                                        optionlabel="label"
                                                        placeholder="Select Owner Party ID">
                                                    </p-dropdown>
                                                    </div>
                                                    
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Status ID	
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="party"
                                                        [options]="statusIds"
                                                        formControlName="statusID"
                                                        optionlabel="label"
                                                        placeholder="Select Status ID">
                                                    </p-dropdown>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Date Time Received	
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="dateTimeReceived"
                                                        aria-describedby="emailHelp"
                                                        >
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Date Time Manufactured	
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="dateTimeManufactured"
                                                        aria-describedby="emailHelp"
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Expire Date	
                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="expireDate"
                                                        aria-describedby="emailHelp"
                                                        >
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Facility ID	</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <ng-select [items]="facilityIds"
                                                        bindLabel="facilityName"
                                                        [(ngModel)]="facilityId"
                                                        bindValue="facilityId"
                                                        formControlName="facilityId"
                                                        class="ng-select-width"></ng-select>
                                                        <p-dropdown filter="true" name="party"
                                                        
                                                        formControlName="facilityID"
                                                        optionlabel="label"
                                                        placeholder="Select Facility ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Container ID		
                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="containerID"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Container ID">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Lot ID		</label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="lotID"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Lot ID">

                                                    
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Uom ID		
                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="uomID"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Uom ID">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Bin Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="binNumber"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Bin Number">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Facility Location	

                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party"
                                                        
                                                        formControlName="facilityLocation"
                                                        optionlabel="label"
                                                        placeholder="Select Facility Location">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="comments"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Accounting Quantity Total		

                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="accountingQuantityTotal"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Accounting Quantity Total">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Serial Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="serialNumber"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Serial Number">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Soft Identifier			

                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="softIdentifier"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Soft Identifier">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Activation Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="activationNumber"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Activation Number">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Activation Valid Through			

                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="activationValidThrough"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Activation Valid Through">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Unit Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="unitCost"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Unit Cost">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-3 form-group classInputA modal-input">
                                                        <label for="exampleInputEmail1">Currency				

                                                        
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="currency"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Currency">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInputBA">
                                                        <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control modal-input-height"
                                                        id="exampleInputEmail1"
                                                        formControlName="fixedAssetID"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Fixed Asset ID">
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>
                                    </form>
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -9% !important; margin-bottom: 4% !important;"><button
                                        type="submit"
                                        class="btn btn-secondary submit-btn">Create</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 