import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Location } from '@angular/common';
@Component({
  selector: 'app-user-detail-summary',
  templateUrl: './user-detail-summary.component.html',
  styleUrls: ['./user-detail-summary.component.css']
})
export class UserDetailSummaryComponent implements OnInit {
  partyId: any;
  @ViewChild('closebutton') closebutton;
  createUser: FormGroup;
  activeCategory = 3;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  roleList: any[]=[];
  loginId: any;
  userSummary: any;
  userEmail: string;
  securityGroup: any;
  arraySecurity: any[]=[];
  enabled: any;
  curentPassword: any;
  url: string;
  show: boolean;
  showAddRoleModal: boolean = false;
  showNewRoleModal: boolean = false;

  constructor(readonly accountsService: AccountsService,
private _location: Location,
readonly headerService : HeaderService,
    readonly router: Router,  readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,readonly _FormBuilder: FormBuilder,
    readonly activatedRoute: ActivatedRoute) { 
      this.url='/users/user-list'
      this.createUser = this._FormBuilder.group({
        firstName: ['',[Validators.required]],
        lastName: ['',[Validators.required]],
        email: ['',[Validators.required]],
        password: ['',[Validators.required]],
        groupId: ['',[Validators.required]],
       
        
      });
    }
   
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['partyId'];
      this.loginId = params['login'];
    });
    const userEmal: string = localStorage.getItem('email');
      this.userEmail=userEmal
    this.getPartyRole();
    this.getUserIdSummary();
    this.getUserIdSummaryEdit();
    this.findGroupId();
    this.show=true;
    
  }

  homeButton(){
    this.router.navigate(['/users/company-information-import'])

 }
 cancelSubmit(){
   this._location.back();
 }
 

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  
  getUserIdSummary(){
    
    this.accountsService.getUserId(this.partyId, this.loginId).subscribe((res:any)=> {
      this.userSummary = res.data;
    
      
  
    })
   
   }
 
  getPartyRole(){
   
    this.accountsService.getPartyRole(this.user, this.partyId).subscribe((res:any)=> {
   
      this.roleList = res.data;
      
  
    })
   
   }
   customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
   deleteRoleTypeId(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      this.accountsService.deleteRoleTypeId(this.partyId,id).subscribe((res:any)=> {
        this.toastr.success("Delete Successfully");
        this.getPartyRole();
    },(err) => {
      this.toastr.error(err.error.message);
   })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
}

change(){
  //this.router.navigate(['/users/add-role'], { queryParams: { partyId:  this.partyId,login:this.loginId } });
  this.showAddRoleModal = true;
 
}
changeRole(){
  //this.router.navigate(['/users/new-role'], { queryParams: { partyId:  this.partyId,login:this.loginId } });
  this.showNewRoleModal = true;
}
updateLogin(partyId,loginId){
  this.router.navigate(['/users/create-user'], { queryParams: { partyId:partyId,loginId:loginId } });
}
getUserIdSummaryEdit(){
  this.spinner.show();
  this.accountsService.getUserId(this.partyId, this.loginId).subscribe((res:any)=> {
    setTimeout(() => {
      this.enabled=res.data.enabled;
      this.curentPassword=res.data.currentPassword 
      const formValue = this.createUser;
      formValue.patchValue({
    firstName: res.data.firstName,
    lastName: res.data.lastName,
    email:res.data.email,
    password: res.data.password,
    groupId: res.data.groupId,


      })
    
    }, 2000);
    this.spinner.hide();

  })
 
 }

update(): void {
  this.spinner.show();
  const requestData ={
    "enabled": this.enabled==true?'Y':'N',
      "email": this.createUser.value.email,
      "firstName": this.createUser.value.firstName,
      "groupId":this.createUser.value.groupId,
      "lastName":this.createUser.value.lastName,
      "password": this.curentPassword,
      "partyId": this.partyId,
      "userLoginId":  this.loginId
    }
  this.accountsService.updateUser(requestData).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success("Updated Successfully");
      this.spinner.hide();
      this.createUser.reset();
      this.getUserIdSummary();
      this.getUserIdSummaryEdit();
      this.closebutton.nativeElement.click();
    }
  }, (err) => {
    this.spinner.hide();
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
  });
}
findGroupId(){
  this.spinner.show();
 let  req={
    
      "description": "",
      "descriptionSearchType": "Contains",
      "groupId": "",
      "groupIdSearchType": "Contains"
    
   }
  this.accountsService.findGroupId(this.user,req).subscribe((res:any)=> {
    this.securityGroup = res.data;
    this.spinner.hide();
    for(const value of this.securityGroup){
      this.arraySecurity.push({
        label:value.groupId,
        value:value.groupId
      })
    }

  })
 
 }
 addRoleModalClosed(){
  this.showAddRoleModal = false;
 }
 newRoleModalClosed(){
  this.showNewRoleModal = false; 
 }

}
