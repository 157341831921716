import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { WarehouseService } from '../warehouse/warehouse.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { DatePipe } from '@angular/common';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';


@Component({
  selector: 'app-search-by-labels',
  templateUrl: './search-by-labels.component.html',
  styleUrls: ['./search-by-labels.component.css']
})
export class SearchByLabelsComponent implements OnInit {
  activeCategory=2;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  createSearchLablesForm: FormGroup;
  SearchInventoryItemsByLabelArray: any[]=[];
  inventoryItemsByLabels: any;

  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly _WarehouseService: WarehouseService,
    readonly _ToastrService: ToastrService,
    readonly  _Router: Router,
    readonly accountsService: AccountsService,
    readonly datePipe: DatePipe,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
  ) 
  {
    this.createSearchLablesForm=this._FormBuilder.group({
      ded:"",
    })
   }

  ngOnInit(): void {
    this.SearchInventoryItemsByLabelsDropDownValue();
  }

  SearchInventoryItemsByLabelsDropDownValue() {
    this.spinner.show();
    this.accountsService.SearchInventoryItemsByLabelsDropDownValue().subscribe(res => {
      const eventPurpose = res.data;
      this.spinner.hide();
       for (const value of eventPurpose) {
        this.SearchInventoryItemsByLabelArray.push({
          label: value.data.description,
          value: value.data.inventoryItemLabelId
        })
      } 
    })
   
  }

  SearchInventoryItemsByLabels(): void {
    this.spinner.show();
    this.accountsService.SearchInventoryItemsByLabels(this.createSearchLablesForm.value.ded).subscribe((res: any) => { 
      if (res.success) {
        this.inventoryItemsByLabels=res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this._ToastrService.error(err.error.message);
      }
    }); 
  }

  


  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
}

changeactiveCategoryFunction(tab:number)
{
  this.activeCategory=tab;
}

cancelSubmit()
{
  this._Router.navigate(['/facilities/warehouse/detailed-summary-facilities']);
}

toBackInventory()
{
  this._Router.navigate(['/facilities/warehouse/detailed-summary-facilities']);

}

}
