import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-party-dashboard',
  templateUrl: './party-dashboard.component.html',
  styleUrls: ['./party-dashboard.component.css']
})
export class PartyDashboardComponent implements OnInit {
  activeCategory=2;
  activeCategoryValue=2;
  constructor(
    readonly _Router: Router,
    private _location: Location,
  ) { }

  ngOnInit(): void {
  }

  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/partyQuickLink/party-dashboard']);
}
} 
