import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from '@angular/common';
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-routing-task',
  templateUrl: './routing-task.component.html',
  styleUrls: ['./routing-task.component.css']
})
export class RoutingTaskComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  advanceSearch: {

    RoutingID: string,
    routingSearchId:string,
    RoutingName:string,
    routingNameType: string,
    FixedAssetID: string,



  };
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList=[{
    "product":"1"
  }];
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  RoutingTasks: any;
  fixedAssetIdArray: any[]=[];
  fixedAssetId: any;
  createroute: FormGroup;
  activeCategory = 2;
  estimateCalcMethod: any;
  routingTaskType: any;

  estimateCalcMethodArray: any[]=[];
  routingTaskTypeArray: any[]=[];
  routingTaskId: any;
  show: boolean;
  
  constructor(readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly productionService:ProductionService,
    readonly _ToastrService: ToastrService, private _location: Location,
    readonly router: Router) {
      this.createroute = this._FormBuilder.group({
        RoutingTaskName: [''],
        TaskType: [''],
        Description: [''],
        FixedAssetID: [''],
        EstimatedSetupTime: [''],
        EstimatedUnitRunTime: [''],
        EstimateCalcMethod: [''],
        ReservPersons: [''],
  
  
  
      });
    this.advanceSearch = {

      RoutingID: '',
      routingSearchId: 'Contains',
      RoutingName: '',
      routingNameType: 'Contains',
      FixedAssetID: '',
 
    };
  
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show=false;
    this.getRoutingTasks();
    this.getRoutingTasksinitialData();
    this.getRoutingTasksinitialDataValue();
    
  }
  getRoutingTasksinitialDataValue() {
    this.spinner.show();
    this.productionService.getRoutingTasksinitialData().subscribe(res => {
      this.fixedAssetId = res.data.fixedAssetId;
      this.estimateCalcMethod = res.data.estimateCalcMethod;
      this.routingTaskType = res.data.routingTaskType;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
      for (const value of this.estimateCalcMethod) {
        this.estimateCalcMethodArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
      for (const value of this.routingTaskType) {
        this.routingTaskTypeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
    
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "currentStatusId": "",
      "description": this.createroute.value.Description,
      "estimateCalcMethod": this.createroute.value.EstimateCalcMethod,
      "estimatedMilliSeconds": this.createroute.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createroute.value.EstimatedSetupTime,
      "fixedAssetId": this.createroute.value.FixedAssetID,
      "reservPersons": this.createroute.value.ReservPersons,
      "workEffortId":"",
      "workEffortName": this.createroute.value.RoutingTaskName,
      "workEffortPurposeTypeId": this.createroute.value.TaskType,
      "workEffortTypeId": "",
    }
    this.productionService.postRoutingTasks(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.getRoutingTasks();
      
      }}, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });}
  routingDetails(id) {
    this.router.navigate(["production/detail-routing-task-summary"],{ queryParams: { routingTaskId : id}})
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getRoutingTasksinitialData() {
    this.spinner.show();
   
    this.productionService.getRoutingTasksinitialData().subscribe(res => {
      this.fixedAssetId = res.data.fixedAssetId;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
    
  }
  getRoutingTasks() {
    this.spinner.show();
    const req={
      "fixedAssetId": "",
      "workEffortId": "",
      "workEffortIdSearchType": "Contains",
      "workEffortName": "",
      "workEffortNameSearchType": "Contains"
    }
    this.productionService.getRoutingTasks(this.finSize,req).subscribe((res: any) => {
      this.RoutingTasks = res.data;
      this.spinner.hide();
    })
  
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.RoutingName = "";
    this.advanceSearch.RoutingID = "";
    this.advanceSearch.FixedAssetID="";
   
    this.RoutingTasks = []
    const req = {
      "fixedAssetId": "",
      "workEffortId": "",
      "workEffortIdSearchType": "Contains",
      "workEffortName": "",
      "workEffortNameSearchType": "Contains"

    }
    this.productionService.getRoutingTasks(this.finSize,req).subscribe((res: any) => {
      this.RoutingTasks = res.data;
      this.spinner.hide();

    })
   
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.RoutingTasks = [];
    const req = {
      "fixedAssetId": this.advanceSearch.FixedAssetID ? this.advanceSearch.FixedAssetID : '',
      "workEffortId": this.advanceSearch.RoutingID ? this.advanceSearch.RoutingID : '',
      "workEffortIdSearchType":this.advanceSearch.routingSearchId ? this.advanceSearch.routingSearchId : '',
      "workEffortName":this.advanceSearch.RoutingName ? this.advanceSearch.RoutingName : '',
      "workEffortNameSearchType": this.advanceSearch.routingNameType ? this.advanceSearch.routingNameType : '',
   

    }
    this.productionService.getRoutingTasks(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.RoutingTasks = resp.data;
        } 
      });
     
  }
  createRoute() {
    this.router.navigate(["production/add-route-task"])

  }
  updateRouting(id) {
    this.router.navigate(["production/add-route-task"],{ queryParams: { routingTaskId : id}})

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }
  

}


