<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Reports Marketing</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" style="margin-left:-2%!important;">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategoriess==9?'active':''" (click)="toLead()"><a>
                                    Leads </a></li>
                            <!-- <li [ngClass]="activeCategoriess==10?'active':''" (click)="openMarketingCampaign()"><a>
                                Convert Lead</a></li> -->
                            <li [ngClass]="activeCategoriess==11?'active':''" (click)="toContact()"><a>
                                    Contacts </a></li>
                            <li [ngClass]="activeCategoriess==12?'active':''" (click)="toAccount()"><a>
                                    Accounts</a></li>
                            <li [ngClass]="activeCategoriess==1?'active':''" (click)="openDataResource()"><a>
                                    Data Resource </a></li>
                            <li [ngClass]="activeCategoriess==2?'active':''" (click)="openMarketingCampaign()"><a>
                                    Marketing Campaign</a></li>
                            <li [ngClass]="activeCategoriess==3?'active':''" (click)="openTracking()"><a>
                                    Tracking</a></li>
                            <li [ngClass]="activeCategoriess==4?'active':''" (click)="openSegment()"><a>
                                    Segment</a></li>
                            <li [ngClass]="activeCategoriess==5?'active':''" (click)="openContactList()"><a>
                                    Contact List</a></li>
                                    <li [ngClass]="activeCategoriess==6?'active':''" (click)="createcontent()"><a>
                                        Create Content</a></li>
                            <li [ngClass]="activeCategoriess==13?'active':''" (click)="toOpportunity()"><a>
                                    Opportunities</a></li>
                            <li [ngClass]="activeCategoriess==14?'active':''" (click)="toTasks()"><a>
                                    Tasks</a></li>
                            <li [ngClass]="activeCategoriess==15?'active':''" (click)="toCases()"><a>
                                    Cases</a></li>
                            <li [ngClass]="activeCategoriess==16?'active':''" (click)="toForecast()"><a>
                                    Forecast</a></li>
                            <li [ngClass]="activeCategoriess==17?'active':''" (click)="toDocuments()"><a>
                                    Documents</a></li>
                            <li [ngClass]="activeCategoriess==18?'active':''" (click)="toNotes()"><a>
                                    Notes</a></li>
                            <li [ngClass]="activeCategoriess==19?'active':''" (click)="toCalendar()"><a>
                                    Calendar</a></li>
                            <li [ngClass]="activeCategoriess==20?'active':''" (click)="openReports()"><a>
                                    Reports</a></li>
                            <li [ngClass]="activeCategoriess==21?'active':''" (click)="toEmail()"><a>
                                    Emails marketing</a></li>
                            <li [ngClass]="activeCategoriess==22?'active':''" (click)="MobileMessaging()"><a>
                                    Mobile Messaging</a></li>
                            <li [ngClass]="activeCategoriess==23?'active':''" (click)="SocialMedia()"><a>
                                    Social Media Marketing </a></li>


                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div >
                                            <div class="create-new-leade">
                                                <div class="">
                                                    <div class="w3-card-4 classCardView mt-2 w-100">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            Reports
                                                        </div>
                                                        <div class="create-new-leade">
                                                            <div class="container-fluid" style="padding: 0;">
                                                                <div *ngIf="this.show"
                                                                    class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                    <div style="width: 49%; float: left;">
                                                                        <div class="w3-card-4 classCardView">
                                                                            <div class="header-tabs"
                                                                                style="background:#F5F7FA !important;">
                                                                                <h4 class="common-styling h4Margin">
                                                                                    Tracking Code Report
                                                                                </h4>
                                                                            </div>
                                                                            <form [formGroup]="trackingcodereport">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">From
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="FromDate"
                                                                                                    placeholder="Enter From Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">Through
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="ThroughDate"
                                                                                                    placeholder="Enter Through Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Tracking
                                                                                                    Code
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-4 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    formControlName="TrackingCode"
                                                                                                    optionlabel="label"
                                                                                                    [options]="TrackingCodeArray"
                                                                                                    placeholder="Enter Tracking Code">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-lg-9 main-submit-button">
                                                                                        <button
                                                                                            (click)="getTrackingCodeReport()"
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn">Run
                                                                                            Tracking Code
                                                                                            Report</button>&nbsp;
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div class="w3-card-4 classCardView mt-2">
                                                                            <div class="header-tabs"
                                                                                style="background:#F5F7FA !important;">
                                                                                <h4 class="common-styling h4Margin">
                                                                                    Email Status Report
                                                                                </h4>
                                                                            </div>
                                                                            <form [formGroup]="EmailStatusReport">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">From
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="FromDate"
                                                                                                    placeholder="Enter From Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">Through
                                                                                                    Date
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="ThroughDate"
                                                                                                    placeholder="Enter Through Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">To
                                                                                                    Party ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    formControlName="Status"
                                                                                                    optionlabel="label"
                                                                                                    [options]="PartyIDFromArray"
                                                                                                    placeholder="Enter To Party ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">From
                                                                                                    Party ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    formControlName="Status"
                                                                                                    optionlabel="label"
                                                                                                    [options]="PartyIDFromArray"
                                                                                                    placeholder="Enter From Party ID">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-4 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    formControlName="Status"
                                                                                                    optionlabel="label"
                                                                                                    [options]="EmailStatusReportsArray"
                                                                                                    placeholder="Enter Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Role
                                                                                                    Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-lg-4 form-group">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    formControlName="RoleStatus"
                                                                                                    optionlabel="label"
                                                                                                    [options]="RoleStatusArray"
                                                                                                    placeholder="Enter Role Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-lg-9 main-submit-button">
                                                                                        <button
                                                                                            (click)="getEmailReport()"
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn">Run
                                                                                            Email Status
                                                                                            Report</button>&nbsp;
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        style="width: 49.8%; float: right; margin-top: -56px;">
                                                                        <div class="w3-card-4 classCardView">
                                                                            <div class="header-tabs"
                                                                                style="background:#F5F7FA !important;">
                                                                                <h4 class="common-styling h4Margin">
                                                                                    Marketing Campaign Report
                                                                                </h4>
                                                                            </div>
                                                                            <form [formGroup]="MarketingCampaignReport">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">From
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="FromDate"
                                                                                                    placeholder="Enter From Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">Through
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="ThroughDate"
                                                                                                    placeholder="Enter Through Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Marketing
                                                                                                    Campaign
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <p-dropdown
                                                                                                    name="MarketingCampaign"
                                                                                                    id="exampleInputEmail1"
                                                                                                    [options]="MarketCampaignIdsArray"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Marketing Campaign"
                                                                                                    filter="true"
                                                                                                    optionlabel="label">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-lg-10  main-submit-button">
                                                                                        <button
                                                                                            (click)="getMarketCampaignReport()"
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn">Run
                                                                                            Marketing Campaign
                                                                                            Report</button>&nbsp;
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div class="w3-card-4 classCardView mt-2">
                                                                            <div class="header-tabs"
                                                                                style="background:#F5F7FA !important;">
                                                                                <h4 class="common-styling h4Margin">
                                                                                    Party Status Report
                                                                                </h4>
                                                                            </div>
                                                                            <form [formGroup]="PartyStatusReport">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">From
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="FromDate"
                                                                                                    placeholder="Enter From Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">Status
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="StatusDate"
                                                                                                    placeholder="Enter Status Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="productId">Through
                                                                                                    Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    formControlName="ThroughDate"
                                                                                                    placeholder="Enter Through Date">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Contact
                                                                                                    Lists</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <p-dropdown
                                                                                                    name="ContactLists"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    [options]="ContactListNameArray"
                                                                                                    placeholder="Enter Contact Lists"
                                                                                                    filter="true"
                                                                                                    optionlabel="label">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-5 form-group classInput">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Status</label>
                                                                                            </div>
                                                                                            <div class="col-lg-4">
                                                                                                <p-dropdown
                                                                                                    name="Status"
                                                                                                    id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    [options]="PartyStatusReportsArray"
                                                                                                    placeholder="Enter Status"
                                                                                                    filter="true"
                                                                                                    optionlabel="label">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-lg-10  main-submit-button">
                                                                                        <button
                                                                                            (click)="getPartyReport()"
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn">Run
                                                                                            Party Status
                                                                                            Report</button>&nbsp;
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="this.trackCode"
                                                                    class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs"
                                                                            style="background:#F5F7FA !important;">
                                                                            <h4 class="common-styling h4Margin">
                                                                                Tracking Code Report From Through
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table
                                                                                            [value]="TrackingCodeReport"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>

                                                                                                            Tracking
                                                                                                            Code
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Visits
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Orders
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Amount of
                                                                                                            Orders
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Conversion
                                                                                                            Rate
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                 class="account-button"  >
                                                                                                                {{product.datas.trackingCodeId}}</span>

                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.Visits}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.Orders}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.AmountOfOrders}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.ConversionRate}}
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>

                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="this.emailReport"
                                                                    class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                    <div class="w3-card-4 classCardView w-100">
                                                                        <div class="header-tabs"
                                                                            style="background:#F5F7FA !important;">
                                                                            <h4 class="common-styling h4Margin">
                                                                                Email Status Report
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="EmailReport"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>


                                                                                                            Communication
                                                                                                            Event ID
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Communication
                                                                                                            Event Type
                                                                                                            ID
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Status
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            To Party ID
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Role Status
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            From Party
                                                                                                            ID
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Entered Date
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Subject
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                 class="account-button"  >
                                                                                                                {{product.data.communicationEventId}}</span>

                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data.communicationEventTypeId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data.statusId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data.partyIdTo}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data.roleStatusId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data.partyIdFrom}}
                                                                                                    </td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data.entryDate|date:'yyyy-MM-dd
                                                                                                        hh:mm:ss'}}
                                                                                                    </td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data.subject}}
                                                                                                    </td>


                                                                                                </tr>
                                                                                            </ng-template>

                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="this.partyStatusReport"
                                                                    class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs"
                                                                            style="background:#F5F7FA !important;">
                                                                            <h4 class="common-styling h4Margin">
                                                                                Party Status Report
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="PartyReport"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>


                                                                                                            Contact List
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Party ID
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            From Date
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Status Date
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Status
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>



                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                 class="account-button"  >
                                                                                                                {{product.datas.contactListId}}</span>

                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.datas.partyId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.fromDate}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.statusDate}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.data1.statusId}}
                                                                                                    </td>




                                                                                                </tr>
                                                                                            </ng-template>

                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="this.marketCampaignId"
                                                                    class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs"
                                                                            style="background:#F5F7FA !important;">
                                                                            <h4 class="common-styling h4Margin">
                                                                                Marketing Campaign Report From Through
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table
                                                                                            [value]="MarketCampaignReport"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="code">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>

                                                                                                            Marketing
                                                                                                            Campaign Id
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Visits
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Orders
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Amount of
                                                                                                            Orders
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Conversion
                                                                                                            Rate
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>

                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                 class="account-button"  >
                                                                                                                {{product.datas.marketingCampaignId}}</span>

                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.Visits}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.Orders}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.AmountOfOrders}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'180px'}">
                                                                                                        {{product.ConversionRate}}
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>

                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- 
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Reports
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div *ngIf="this.show" class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div style="width: 49%; float: left;">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Tracking Code Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="trackingcodereport">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">From Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">Through Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ThroughDate" placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Tracking Code	
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-4 form-group">
                                                                        <p-dropdown filter="true" formControlName="TrackingCode" optionlabel="label" [options]="TrackingCodeArray" placeholder="Enter Tracking Code">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-9 main-submit-button">
                                                                <button (click)="getTrackingCodeReport()" type="submit" class="btn btn-secondary submit-btn">Run Tracking Code Report</button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Email Status Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="EmailStatusReport">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">From Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">Through Date
                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ThroughDate" placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">To Party ID
                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown filter="true" formControlName="Status" optionlabel="label" [options]="PartyIDFromArray" placeholder="Enter To Party ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">From Party ID
                                                                </label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown filter="true" formControlName="Status" optionlabel="label" [options]="PartyIDFromArray" placeholder="Enter From Party ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-4 form-group">
                                                                        <p-dropdown filter="true" formControlName="Status" optionlabel="label" [options]="EmailStatusReportsArray" placeholder="Enter Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Role Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-4 form-group">
                                                                        <p-dropdown filter="true" formControlName="RoleStatus" optionlabel="label" [options]="RoleStatusArray" placeholder="Enter Role Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-9 main-submit-button">
                                                                <button (click)="getEmailReport()" type="submit" class="btn btn-secondary submit-btn">Run Email Status Report</button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div style="width: 49.8%; float: right; margin-top: -56px;">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Marketing Campaign Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="MarketingCampaignReport">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">From Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">Through Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ThroughDate" placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Marketing Campaign
                                                                    </label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown name="MarketingCampaign" id="exampleInputEmail1" [options]="MarketCampaignIdsArray" aria-describedby="emailHelp" placeholder="Enter Marketing Campaign" filter="true" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-10  main-submit-button">
                                                                <button (click)="getMarketCampaignReport()" type="submit" class="btn btn-secondary submit-btn">Run Marketing Campaign Report</button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Party Status Report
                                                        </h4>
                                                    </div>
                                                    <form [formGroup]="PartyStatusReport">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">From Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">Status Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="StatusDate" placeholder="Enter Status Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="productId">Through Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ThroughDate" placeholder="Enter Through Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Contact Lists</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown name="ContactLists" id="exampleInputEmail1" aria-describedby="emailHelp" [options]="ContactListNameArray" placeholder="Enter Contact Lists" filter="true" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown name="Status" id="exampleInputEmail1" aria-describedby="emailHelp" [options]="PartyStatusReportsArray" placeholder="Enter Status" filter="true" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-10  main-submit-button">
                                                                <button (click)="getPartyReport()" type="submit" class="btn btn-secondary submit-btn">Run Party Status Report</button>&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.trackCode" class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs" >
                                                    <h4 class="common-styling h4Margin">
                                                        Tracking Code Report From Through
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="TrackingCodeReport" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>

                                                                                    Tracking Code
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Visits
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Orders
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Amount of Orders
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Conversion Rate
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  >
                                                                                            {{product.datas.trackingCodeId}}</span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.Visits}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.Orders}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.AmountOfOrders}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.ConversionRate}}
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4
                                                                                        class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.emailReport" class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w3-card-4 classCardView w-100">
                                                <div class="header-tabs" >
                                                    <h4 class="common-styling h4Margin">
                                                        Email Status Report
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="EmailReport" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>


                                                                                    Communication Event ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Communication Event Type ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    To Party ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role Status
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Party ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Entered Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Subject
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  >
                                                                                                {{product.data.communicationEventId}}</span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.communicationEventTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.statusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.partyIdTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.roleStatusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.partyIdFrom}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data.subject}}
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4
                                                                                            class="ml-2">
                                                                                            No Record
                                                                                            Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.partyStatusReport" class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs" >
                                                    <h4 class="common-styling h4Margin">
                                                        Party Status Report
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="PartyReport" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>


                                                                                    Contact List
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>



                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  >
                                                                                                    {{product.datas.contactListId}}</span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.datas.partyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.fromDate}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.statusDate}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.data1.statusId}}
                                                                            </td>




                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                            <h4
                                                                                                class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.marketCampaignId" class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w3-card-4 classCardView">
                                                <div class="header-tabs" >
                                                    <h4 class="common-styling h4Margin">
                                                        Marketing Campaign Report From Through
                                                    </h4>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="MarketCampaignReport" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>

                                                                                    Marketing Campaign Id
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Visits
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Orders
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Amount of Orders
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Conversion Rate
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  >
                                                                                                        {{product.datas.marketingCampaignId}}</span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.Visits}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.Orders}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.AmountOfOrders}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.ConversionRate}}
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                                <h4
                                                                                                    class="ml-2">
                                                                                                    No Record
                                                                                                    Found</h4>
                                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->