import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-commission-balances',
  templateUrl: './commission-balances.component.html',
  styleUrls: ['./commission-balances.component.css']
})
export class CommissionBalancesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
