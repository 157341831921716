<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                   <span class="color-black pl-1 titlePanels" *ngIf="Person">The Profile of {{Person.personInformation.personalTitle}}
                        {{Person.personInformation.firstName}}
                        {{Person.personInformation.middleName}}
                        {{Person.personInformation.lastName}}
                        {{"[" + this.taxAuthPartyId + "]"}}</span>
                        <span class="d-flex">

                            <button type="submit" (click)="homeButton();"
                                class=" btn btn-outline-secondary mr-2">Home</button>
                    
                            <button type="submit" (click)="cancelSubmit();"
                                class="btn btn-danger buttonclass ">Back</button>
                        </span>
                    
                </div>
            
            <div class="">
              
                <div class=" bg-white color-grey">
                    <div class="create-new-leade ">
                        <div class="container-fluid m-0 p-0">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="w3-card-4 classCardA ">

                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                        <li [ngClass]="activeCategoryA==1?'active':''" (click)="MyProfile()"><a>
                                                            Profile

                                                        </a></li>
                                                        <li [ngClass]="activeCategoryA==2?'active':''" (click)="Preferences()">
                                                            <a>
                                                            Preferences
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==3?'active':''" (click)="Roles()">
                                                            <a>
                                                            Role(s)</a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==4?'active':''" (click)="Project()">
                                                            <a>
                                                            Projects
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==5?'active':''" (click)="TasksProfile()">
                                                            <a>
                                                            Tasks
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==6?'active':''" (click)="Rates()">
                                                            <a>
                                                            Rates
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==7?'active':''" (click)="Revisions()">
                                                            <a>
                                                            Revisions
                                                        </a>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div class="create-new-leade ">
                                                        <div class="container-fluid">


                                                            <div class="panel-group">




                                                                <div>

                                                                    <div class=" bg-white color-grey">
                                                                        <div class="create-new-leade mt-2">
                                                                            <div class="container-fluid">
                                                                                <div class="row">
                                                                                    <form class="w-100">


                                                                                        <div class="w3-card-4 classCard">
                                                                                            <div class="container-fluid">
                                                                                                <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                            flex-wrap: wrap !important;
                                                                            display: flex !important;">
                                                                                                    <li><a>
                                                                                                    Edit Party Rates

                                                                    <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                    data-toggle="modal" data-target="#exampleModalRate">Create New Party Rates
                                                                    </button>
 

                                                                                                    </a>
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalRate" tabindex="-1" role="dialog" aria-labelledby="exampleModalRateTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Rates</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryB==2?'active':''"><a>
                    Edit Party Rates
                </a></li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Rate Type<span style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="rateType" placeholder="Enter Rate Type" name="Parent Type ID" [options]="rateTypesArray" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Period Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <label>Rate amount per Hour</label>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Rate Amount</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="rateAmount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate Amount">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Product Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="currency" placeholder="Enter Default Rate" [options]="currencyArray" optionlabel="label">
                                                        </p-dropdown>


                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Percentage Used</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="percentageUsed" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Percentage Used">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Default Rate</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="defaultRate" placeholder="Enter Default Rate" [options]="yesNOArray" optionlabel="label" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 55%;">

                                        <button type="submit" (click)="onSubmitRate();" class="btn btn-secondary submit-btn">Create</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>