<div class="container-fluid main-container-wrapper">
    
           <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
              <span class="color-black pl-1 titlePanels">Payments</span>
           </div>
        
           <div class=" bg-white color-grey">
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
                <div class="row">
                   
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="port-header-contact create-lead font-medium mb-0">
                                <div class="suppliers-wrapper">
                                    <button type="button" class="btn btn-outline-secondary" routerLinkActive="active" routerLink="/accounting/payments/create-payments">Create</button>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Payment Id</label>
                                    </div>
                                 
                                    <div class="col-lg-2" style="display: none;">
                                        <p-dropdown filter="true" (ngModelChange)="onPaymentId(paymentIdSearchType)"
                                                [(ngModel)]="paymentIdSearchType" [ngModelOptions]="{standlone:true}"
                                                [options]="opportunityIdArray" optionlabel="label">
                                            </p-dropdown>
                                    </div>
                                    <div class="col-lg-2">
                                        <input type="email"[(ngModel)]="paymentId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                    </div>

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Comments</label>
                                    </div>
                                   
                                    <div class="col-lg-2" style="display: none;">
                                        <p-dropdown filter="true" (ngModelChange)="onComments(commentsSearchType)"
                                        [(ngModel)]="commentsSearchType" [ngModelOptions]="{standlone:true}"
                                        [options]="opportunityIdArray" optionlabel="label">
                                    </p-dropdown>
                                    </div>
                                    <div class="col-lg-2">
                                        <input type="email"[(ngModel)]="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  >
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Reference Number</label>
                                    </div>
                                 
                                    <div class="col-lg-2" style="display: none;">
                                        <p-dropdown filter="true" (ngModelChange)="onReferenceNo(referenceNoSearchType)"
                                                [(ngModel)]="referenceNoSearchType" [ngModelOptions]="{standlone:true}"
                                                [options]="opportunityIdArray" optionlabel="label">
                                            </p-dropdown>
                                    </div>
                                    <div class="col-lg-2">
                                        <input type="email"[(ngModel)]="referenceNo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  >
                                    </div>

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Payment Type</label>
                                    </div>
                                   
                                    <div class="col-lg-2" >
                                        <p-dropdown  [options]="allPaymentTypeList"   optionlabel="label"></p-dropdown>
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">From Party Id</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                    </div>

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Amount</label>
                                    </div>
                                   
                                    <div class="col-lg-2" >
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Status</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  [options]="allStatusList"   optionlabel="label"></p-dropdown>
                                    </div>

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">Payment Gateway Response ID</label>
                                    </div>
                                   
                                    <div class="col-lg-2">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                    </div>


                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">To Party Id</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12 col-12 main-submit-button ">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getAllPaymentList()">Find</button></div>
                         <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Payment List</label>
  
                                  <div class="card own-account-table">
                                    <p-table [value]="allPaymentsList"  [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"  [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th pSortableColumn="code">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted"><div class="custom-checkbox ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div><div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div></div><!----></p-checkbox>
                                                        Payment Id <p-sortIcon field="code"></p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                  </th>
                                                <th pSortableColumn="name">Payment Type <p-sortIcon field="name"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="category">	Status <p-sortIcon field="category"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="quantity">	Comments <p-sortIcon field="quantity"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"> From Party <p-sortIcon field="price"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"> To Party <p-sortIcon field="price"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"> Effective Date <p-sortIcon field="price"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"> Amount <p-sortIcon field="price"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"> Outstanding amount <p-sortIcon field="price"></p-sortIcon>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price">Action
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'170px'}"
                                                            (click)="paymentDetail(product.paymentId)">
                                                            <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                    styleclass="custom-checkbox" binary="true"
                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                type="checkbox" name="undefined"
                                                                                value="undefined"></div>
                                                                        <div role="checkbox"
                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span
                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                {{product.paymentId}}
                                                            </div>
                                                </td>
                                                <td>{{product.paymentType}}</td>
                                                <td>{{product.status}}</td>
                                                <td>{{product.comments}}</td>
                                                <td>{{product.fromParty}}</td>
                                                <td>{{product.toParty}}</td>
                                                <td>{{product.createdStamp | date:'yyyy-MM-dd'}}</td>
                                                <td>{{product.amount}}</td>
                                                <td>{{product.outstandingAmount}}</td>
                                                <td><div>
                                                    <svg xmlns="http://www.w3.org/2000/svg"  (click)="onUpdatePayment(product.paymentId)" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z" transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                   
                                                </div></td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                        <p class="paginate_data">
                                            View per page  
                                         </p>
                                </div>
                                </div>
                              </div>
                        </div>
  
                </div>
            </div>
        </div>
           </div>
  </div>
  <ngx-spinner></ngx-spinner> 