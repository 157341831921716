
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AddFormModel } from './create-internal-job-model';

@Injectable({
  providedIn: 'root'
})


export class AddFormService {
  add_job_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public addFormModel: AddFormModel
  ) { }

  addJobForm(data: any): void {
    if (!this.add_job_form) {
      this.add_job_form = this._formBuilder.group(this.addFormModel.mapDataToModel(data));

    }

  }

}



