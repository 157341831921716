import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2'
import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
@Component({
  selector: 'app-address-match-map',
  templateUrl: './address-match-map.component.html',
  styleUrls: ['./address-match-map.component.css']
})
export class AddressMatchMapComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  pageNo = 1;
  activeCategory=1;
  searchArray:any[]=[];
  AddressMatchMap: any;
  advanceSearch: {
    address1: string,
    address2: string,
    city: string,
    firstName:string,
    lastName: string,
    postalCode:string,
    stateProvinceGeoId: string,

  };
  match: string;
  countryId: string;
  stateData: any;
  stateArray: any[]=[];
  createClassification:FormGroup;
  fileUpload:FormGroup;
  activeCategorymain=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  fileData: File;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor( readonly _CategoryService: CategoryService, 
    readonly  _Router: Router,
    private _location: Location,
    readonly _FormBuilder: FormBuilder, 
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    public createContact: CreateContactsService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    ) {
      this.advanceSearch={
        address1:"",
        address2:"",
        city: "",
        firstName:"",
        lastName:"",
        postalCode:"",
        stateProvinceGeoId: "",
    
      };

      this.contentIds = [];
      this.createClassification = this._FormBuilder.group({
        matchKey: [''],
        matchValue: [''],
        sequenceNum:['']
       
        
      });
     }
    ngOnInit(): void {
     
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
      this.getAddressMatchMap();
      this.getStateList();

      this.fileUpload = new FormGroup({
        file: new FormControl('', [
          Validators.required
        ]),
      });
    }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/security/security-dashboard']);

  }
    getStateList() {
      this.spinner.show();
      this.countryId='USA'
      this.createContact.getState(this.countryId).subscribe(res => {
        this.stateData = res.data.stateList;
        this.spinner.hide();
        this.stateData.forEach(stateLists => {
          var splitState = stateLists.split(":");
          var stateIndex = splitState[0];
          this.stateArray.push({
            label: stateIndex,
            value: stateLists.split(":").pop().trim()
          })
        })
      })
     
    }
    getAddressMatchMap(){
      this.spinner.show();
     
        this.accountsService.getAddressMatchMap().subscribe((res:any)=> {
          this.AddressMatchMap = res.data;
          this.spinner.hide();
        
        
        })
       
       }
       
       clearAddressMatchMap() {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this data.!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.accountsService.clearAddressMatchMap().subscribe(res => {
              this.getAddressMatchMap(); 
            })
            Swal.fire(
              'Deleted!',
              'Deleted Successfully.',
              'success'
            )
         
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your data is safe :)',
              'error'
            )
          }
        })
      
    
      }
       removeAddressMatchMap(mapkey,mapValue) {
        if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
          Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
              this.accountsService.removeAddressMatchMap(mapkey,mapValue).subscribe(res => {
                this.getAddressMatchMap(); 
              })
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })
        }
        else if(this.userPermissions.PARTYMGR_VIEW){
          this._ToastrService.error("Permission Denied"); 
        }
    
      
    
      }
 
  reset(){
    this.spinner.show();
    this.advanceSearch={
      address1:"",
      address2:"",
      city: "",
      firstName:"",
      lastName:"",
      postalCode:"",
      stateProvinceGeoId: "",
  
    };
    this.accountsService.getAddressMatchMap().subscribe((res:any)=> {
      this.AddressMatchMap = res.data;
      this.spinner.hide();
    
    
    })
  }
  advanceSearchDeposit(isPopUp: boolean): void {
    this.spinner.show();
    this.AddressMatchMap = [];
    const req = {
      
      address1:this.advanceSearch.address1 ? this.advanceSearch.address1 : '',
      address2:this.advanceSearch.address2 ? this.advanceSearch.address2 : '',
      city: this.advanceSearch.city ? this.advanceSearch.city : '',
      firstName:this.advanceSearch.firstName ? this.advanceSearch.firstName : '',
      lastName:this.advanceSearch.lastName ? this.advanceSearch.lastName : '',
      postalCode:this.advanceSearch.postalCode ? this.advanceSearch.postalCode : '',
      stateProvinceGeoId: this.advanceSearch.stateProvinceGeoId ? this.advanceSearch.stateProvinceGeoId : '',
     

    }
    this.match='true';
    this.accountsService.findAddressMatch(this.match, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.AddressMatchMap = resp.data;
        } else {
        
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          
        }
      });
     
  }
  import(){
    this._Router.navigate(['/security/import-address']);
  }
  
  detailAddress(mapKey,mapValue,sequenceNum){
    this._Router.navigate(['/security/detail-address-map'],{ queryParams: { mapKey:mapKey,mapValue:mapValue,sequenceNum:sequenceNum}});
  }
  create(){
    this._Router.navigate(['/security/create-address-match-map']);
  }
  onSubmit() {
    this.spinner.show();
    let req={
      "mapKey":this.createClassification.value.matchKey,
      "mapValue":this.createClassification.value.matchValue,
      "sequenceNum": this.createClassification.value.sequenceNum
    }
    this.accountsService.createAddressMatchMap(req).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
        this.closebutton.nativeElement.click();
        this.getAddressMatchMap();

      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this._location.back();
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  onUpload(){
    const formData = new FormData();
    formData.append("file",this.fileData);
    this.accountsService.uploadCsvMatch(formData).subscribe((res:any)=>{
      if(res.success) {
        this._ToastrService.success(res.data.responseMessage);
        this._location.back();

      }     },(err)=>{
        this._ToastrService.error(err.message);
     })
  }
}
