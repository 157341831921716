<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
            
                   <span class="color-black pl-1 titlePanels">User List</span>
                   <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
           

            </div>

            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords"> Search</span>
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create New User</button>
                                                    <button type="button" (click)="importUser()" class="btn btn-outline-secondary text-white bg-brown">Import Users</button>
                                                    <button type="button" (click)="exportUser()" class="btn btn-danger text-white ">Export</button>
                        
                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 57%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>									
        
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">User Id</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="loginIdArray" [(ngModel)]="advanceSearch.userId" [ngModelOptions]="{standlone:true}" name="userId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Id" filter="true" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Party Id</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="partyIdArray" [(ngModel)]="advanceSearch.partyId" [ngModelOptions]="{standlone:true}" name="partyId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id" filter="true" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">First Name</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" style="width: 100%;" class="form-control address" [(ngModel)]="advanceSearch.firstName" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Last Name</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" style="width: 100%;" [(ngModel)]="advanceSearch.lastName" name="lastName" class="form-control address" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                </div>
                                                            </div>
                                                        </div>
                
                
                                                    </div>

                                                    <div class="col-lg-4 col-12 main-submit-button " style="margin-left: 36%;"><button _ngcontent-cta-c128="" type="submit" (click)="advanceSearchContacts(false)" class="btn btn-danger ml-2">Find</button> &nbsp;
                                                        <button _ngcontent-cta-c128="" (click)="reset();" type="submit" class="btn btn-secondary submit-btn">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <div class="suppliers-wrapper" *ngIf="this.adminSecurity=='ENTERPRISE'||this.adminSecurity=='FULLADMIN'">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New User</button>
                                              
                                            <button type="button" (click)="createUser()" class="btn btn-outline-secondary active">Create New
                                                Users</button>
                                            <button type="button" (click)="importUser()" class="btn btn-outline-secondary text-white bg-brown">Import Users</button>
                                            <button type="button" (click)="exportUser()" class="btn btn-danger text-white ">Export</button>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">User Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="loginIdArray" [(ngModel)]="advanceSearch.userId" [ngModelOptions]="{standlone:true}" name="userId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Id" filter="true" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Party Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="partyIdArray" [(ngModel)]="advanceSearch.partyId" [ngModelOptions]="{standlone:true}" name="partyId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id" filter="true" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" style="width: 100%;" class="form-control address" [(ngModel)]="advanceSearch.firstName" name="firstName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" style="width: 100%;" [(ngModel)]="advanceSearch.lastName" name="lastName" class="form-control address" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-4 col-12 main-submit-button " style="margin-left: 36%;"><button _ngcontent-cta-c128="" type="submit" (click)="advanceSearchContacts(false)" class="btn btn-danger ml-2">Find</button> &nbsp;
                                        <button _ngcontent-cta-c128="" (click)="reset();" type="submit" class="btn btn-secondary submit-btn">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                User List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="userList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Party Id
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                    <div style="color: white;">First Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'180px'}">
                                                                    <div style="color: white;">Last Name
                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'300px'}">
                                                                    <div style="color: white;">User Login Id
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'300px'}">
                                                                    <div style="color: white;">Group Id
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'300px'}">
                                                                    <div style="color: white;">Email id
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'180px'}">
                                                                    <div style="color: white;">Action
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td style="cursor: pointer;" [ngStyle]="{'width':'180px'}">
                                                                    <div class="checkbox-align" (click)="detailsummary(product.partyId,product.userLoginId)">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <a class="account-button" >
                                                                        {{product.partyId}}</a>


                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">{{product.firstName}}</td>
                                                                <td [ngStyle]="{'width':'180px'}">{{product.lastName}}</td>
                                                                <td [ngStyle]="{'width':'300px'}">{{product.userLoginId}}</td>
                                                                <td [ngStyle]="{'width':'300px'}">{{product.groupId}}</td>
                                                                <td [ngStyle]="{'width':'300px'}">{{product.email}}</td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    <div class="edit-wrapper" *ngIf="this.userEmail!=product.email">

                                                                        <span class="edit-tooltip" *ngIf="product.enabled === true" placement="bottom" pTooltip=Disable>
                                                                    <div class="custom-control custom-switch" (change)="toggleVisibility($event, product)" >
                                                                      <input type="checkbox"   
                                                                        data-md-icheck 
                                                                        class="custom-control-input" 
                                                                        id={{product.userLoginId}} 
                                                                        [checked]="product.enabled === true">
                                                                      <label class="custom-control-label" for={{product.userLoginId}}></label>
                                                                    </div>
                                                                  </span>
                                                                        <span class="edit-tooltip" *ngIf="product.enabled === false" placement="bottom" pTooltip=Enable>
                                                                    <div class="custom-control custom-switch" (change)="toggleVisibility($event, product)" >
                                                                      <input type="checkbox"   
                                                                        data-md-icheck 
                                                                        class="custom-control-input" 
                                                                        id={{product.userLoginId}} 
                                                                        [checked]="product.enabled === true">
                                                                      <label class="custom-control-label" for={{product.userLoginId}}></label>
                                                                    </div>
                                                                  </span>






                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!this.show" class="color-black pl-1"> Create-User</span>
                <span *ngIf="this.show" class="color-black pl-1"> Update-User</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create User</a></li>
                                <li *ngIf="this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update User</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">First Name<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control address" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name" formControlName="firstName">
                                                        <small class="text-danger" *ngIf="!!createUser.controls.firstName.invalid && !!createUser.controls.firstName.touched"> First Name is required!</small>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Name<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control address" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name" formControlName="lastName">
                                                        <small class="text-danger" *ngIf="!!createUser.controls.lastName.invalid && !!createUser.controls.lastName.touched"> Last Name is required!</small>


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">E-mail<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control address" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email"
                                                            formControlName="email">
                                                        <small *ngIf="createUser.controls['email'].invalid && (createUser.controls['email'].dirty || createUser.controls['email'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="createUser.controls['email'].errors.required">
                                                                E-mail is required!</p>
                                                            <p class="validate-field"
                                                                *ngIf="createUser.controls['email'].errors.pattern">
                                                                E-mail is not valid!</p>
                                                    </small>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="!this.show">
                                                        <label for="exampleInputEmail1">Password<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password" formControlName="password">
                                                        <small class="text-danger" *ngIf="!!createUser.controls.password.invalid && !!createUser.controls.password.touched"> Password is required!</small>


                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Group Id<span
                                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Group Id" formControlName="groupId" filter="true" [options]="arraySecurity" optionlabel="label">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createUser.controls.groupId.invalid && !!createUser.controls.groupId.touched"> Group Id is required!</small>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 77%;">
                                        <button *ngIf="!this.show" type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Submit</button>
                                        <button *ngIf="this.show" type="submit" (click)="update()" class="btn btn-secondary submit-btn update-button">Update</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>