<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Payroll</span>

               <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2"
                    (click)="toDashboard()">Home</button>
                <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategorys==1?'active':''" (click)="findPaycheck()"><a>
                                Find Paycheck</a></li>
                            <li [ngClass]="activeCategorys==2?'active':''" (click)="createPaychecks()" ><a>
                                Create Paycheck</a></li>
                            <li [ngClass]="activeCategorys==3?'active':''" (click)="paycheckConfiguration()" ><a>
                                Paycheck Configuration</a></li>
                            <li [ngClass]="activeCategorys==4?'active':''"  (click)="employeess()"><a>
                                Employees</a></li>
                            
                            <li [ngClass]="activeCategory==5?'active':''"  (click)="contractReferencees()"><a>
                                Contract Reference</a></li>
            
                            <li [ngClass]="activeCategorys==6?'active':''" (click)="reports()"><a>
                                Reports</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategorys==3">
                                        <div class="col-12">
                                            <div class="w3-card-4 classCard mt-4">
                                                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                    <div class="container-fluid pr-0">
                                                        <ul class="tabbing-section tabbing-accordians tabClass w-100">
                                                            Paycheck Configuration
                                                            <!-- <li [ngClass]="activeCategory==1?'active':''" style="font-size: 14px; font-weight: bold;">
                                                                <a>
                                                                    </a>
                                                            </li> -->
                                
                                
                                                        </ul>
                                                    </div>
                                
                                                </div>
                                
                                                <form [formGroup]="payCheckform">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">
                                                                        Paycheck Type</label>
                                                                </div>
                                
                                
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                    style="width: 132px !important;height: 20px !important;"
                                                                        formControlName="payCheckType" aria-describedby="emailHelp">
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                
                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -74%;    margin-top: -2%;">
                                                        <button type="submit" style="color: white;
                                                    height: 21px;font-size: 11px;min-width: 76px;height: 21px;padding-left: 1.7%;"
                                                            class="btn btn-secondary submit-btn" (click)="toggleShow()">Submit</button>
                                
                                                        &nbsp;
                                                    </div>
                                                    <div class="col-lg-12" style="margin-bottom: 3%;">
                                                        <div class="row">
                                                            <div class="col-lg-6" style="margin-left: 2.5%;font-size: 12px;">
                                                                <p style="font-size: 11px; ">Note : Please enter Paycheck type to continue</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                
                                            </div>
                                
                                            <!-- <div class=" bg-white color-grey mt-2" *ngIf="isShown"> -->
                                                <div class=" bg-white color-grey mt-2">
                                                <div class="port-header-contact create-lead font-medium mb-0">
                                                    <div class="suppliers-wrapper">
                                
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard">
                                                    <div class="container-fluid">
                                                        <ul class="tabbing-section tabbing-accordians tabClass">
                                                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)">
                                                                <a>
                                                                    Deduction</a>
                                                            </li>
                                                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)">
                                                                <a>
                                                                    Allowance</a>
                                                            </li>
                                                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)">
                                                                <a>
                                                                    Costing
                                                                </a>
                                                            </li>
                                
                                                        </ul>
                                                    </div>
                                
                                
                                
                                                    <div class="create-new-leade mt-2" style="margin-bottom: 5% !important;">
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <form class="w-100">
                                                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                                        <div class="w-100">
                                                                            <div class="panel-group" id="accordion" role="tablist"
                                                                                aria-multiselectable="true">
                                
                                
                                                                                <div *ngIf="activeCategory==1">
                                                                                    <!-- <div class="header-tabs">
                                                                                        <h4 class=" common-styling">
                                                                                            Allowance
                                                                                        </h4>
                                
                                                                                    </div> -->
                                
                                                                                    <div class="panel-body panel">
                                                                                        <form class="w-100">
                                                                                            <div
                                                                                                class="all-users-infomation font-13 font-medium  own-user-select">
                                                                                                <div class="col-lg-12 col-12">
                                                                                                    <div class="form-group table-responsive">
                                                                                                        <table class="table align-middle">
                                                                                                            <thead>
                                                                                                                <tr class="table-rows"
                                                                                                                    style="background: #0d3769;">
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                                                                        S.No
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                                        Paycheck and
                                                                                                                        Witholdings
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Pay to Party
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Amount
                                
                                                                                                                    </th>
                                
                                
                                
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Action
                                
                                
                                                                                                                    </th>
                                
                                
                                
                                                                                                                </tr>
                                
                                                                                                                <!-- <tr class="table-rows"
                                                                                                                    style="background: #fff;">
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: #000;font-size: 12px;min-width:155px;font-weight: bold;">
                                                                                                                        Gross Amount
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="padding: 0px !important;">
                                                                                                                        <div class="row">
                                                                                                                            <div class="col-lg-6">
                                                                                                                                <input type="text"
                                                                                                                                    [(ngModel)]="PaycheckExpenses.PayChecks.amount"
                                                                                                                                    class="form-control"
                                                                                                                                    [ngModelOptions]="{standalone: true}">
                                                                                                                            </div>
                                
                                                                                                                            <div class="col-lg-6"
                                                                                                                                style="margin-left: -22%;">
                                                                                                                                <p-dropdown
                                                                                                                                    [options]="currencyArray"
                                                                                                                                    filter="true"
                                                                                                                                    [(ngModel)]="PaycheckExpenses.PayChecks.currencyUomId"
                                                                                                                                    appendTo="body"
                                                                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                                                                    class="dropdowns"
                                                                                                                                    optionlabel="label">
                                                                                                                                </p-dropdown>
                                                                                                                            </div>
                                
                                
                                
                                                                                                                        </div>
                                
                                                                                                                    </th>
                                
                                
                                
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 11px;min-width: 76px;padding: 0px 20px !important;">
                                                                                                                        <button type="submit"
                                                                                                                            style="width: 61px !important;"
                                                                                                                            (click)="updatePayCheck(PaycheckExpenses)"
                                                                                                                            class="btn btn-secondary submit-btn">Update
                                                                                                                        </button>
                                
                                
                                                                                                                    </th>
                                
                                
                                
                                                                                                                </tr> -->
                                                                                                            </thead>
                                
                                                                                                            <tbody>
                                
                                                                                                                <tr class="table-rows"
                                                                                                                    class="align-bottom"
                                                                                                                    *ngFor="let product of PaycheckAndWithHoldingsTable;let i = index;"
                                                                                                                    style="height: 11px !important;">
                                                                                                                    <td style="    color: black;
                                                                                                                            font-weight: 100;
                                                                                                                            font-size: 12px;
                                                                                                                          
                                                                                                                          ">
                                
                                                                                                                        {{i+1}}
                                
                                                                                                                    </td>
                                                                                                                    <td style="    color: black;
                                                                                                                              font-weight: 100;
                                                                                                                              font-size: 12px;
                                                                                                                              min-width: 155px;">
                                
                                
                                                                                                                        <span
                                                                                                                            *ngIf="product.paycheckAndWitholding">{{product.paycheckAndWitholding}}</span>
                                                                                                                        <span
                                                                                                                            *ngIf="product.abc1">{{product.abc1}}</span>
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                
                                                                                                                        <p-dropdown filter="true"
                                                                                                                            appendTo="body"
                                                                                                                            [(ngModel)]="product.partyIdTo"
                                                                                                                            class="dropdowns"
                                                                                                                            [options]="PaytoPartyArray"
                                                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <input type="text"
                                                                                                                            [(ngModel)]="product.amount"
                                                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                                                            class="form-control">
                                
                                                                                                                    </td>
                                
                                
                                                                                                                    <td
                                                                                                                        style="color: white;font-size: 11px;min-width: 76px;padding: 10px 20px !important;">
                                                                                                                        <button type="submit"
                                                                                                                            style="padding: 0px 12px !important;"
                                                                                                                            (click)="updatePaycheckHoldings(product)"
                                                                                                                            class="btn btn-secondary submit-btn">Update
                                                                                                                        </button>
                                                                                                                    </td>
                                
                                                                                                                </tr>
                                
                                                                                                                <tr class="align-bottom"
                                                                                                                    [formGroup]="PaycheckWithHoldingForm">
                                
                                                                                                                    <td>
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                
                                                                                                                        <input type="text" style="width: 180px !important;"
                                                                                                                            formControlName="PaycheckWitholdings"
                                                                                                                            class="form-control">
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                
                                                                                                                        <p-dropdown filter="true"
                                                                                                                            appendTo="body"
                                                                                                                            formControlName="payToParty"
                                                                                                                            class="dropdowns"
                                                                                                                            [options]="PaytoPartyArray"
                                                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <input type="text"
                                                                                                                            formControlName="Amount"
                                                                                                                            class="form-control">
                                                                                                                    </td>
                                
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <button type="submit" style="margin-left: 7%;"
                                                                                                                            (click)="onSubmitPaycheckWithHolding()"
                                                                                                                            class="btn btn-secondary submit-btn">Create
                                                                                                                        </button>
                                                                                                                    </td>
                                
                                                                                                                </tr>
                                
                                                                                                                <!-- <tr class="table-rows"
                                                                                                                    class="align-bottom"
                                                                                                                    style="height: 11px !important;">
                                
                                                                                                                    <td>
                                                                                                                    </td>
                                
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        Net Pay
                                                                                                                    </td>
                                
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                    </td>
                                
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                    
                                                                                                                        {{this.PayCheck}}
                                                                                                                    </td>
                                
                                                                                                                    <td>
                                
                                                                                                                    </td>
                                                                                                                </tr>  -->
                                
                                
                                                                                                            </tbody>
                                                                                                        </table>
                                
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                
                                                                                    <p class="paginate_data">
                                                                                        View per page
                                
                                                                                    </p>
                                
                                                                                </div>
                                                                                <div *ngIf="activeCategory==2">
                                
                                                                                    <br>
                                                                                    <div>
                                
                                                                                    </div>
                                                                                    <div class="panel-body panel">
                                                                                        <form class="w-100">
                                                                                            <div
                                                                                                class="all-users-infomation font-13 font-medium  own-user-select">
                                                                                                <div class="col-lg-12 col-12">
                                                                                                    <div class="form-group table-responsive">
                                                                                                        <table class="table align-middle">
                                                                                                            <thead>
                                                                                                                <tr style="background: #0d3769;">
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                                                                        S.No
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                                        Paycheck
                                                                                                                        Expenses
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Pay to Party
                                
                                                                                                                    </th>
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Amount
                                
                                                                                                                    </th>
                                
                                
                                
                                                                                                                    <th
                                                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                                        Action
                                
                                
                                                                                                                    </th>
                                
                                
                                
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                
                                                                                                                <tr class="align-bottom"
                                                                                                                    *ngFor="let product of PaycheckExpensesTable;let i = index;">
                                                                                                                    <td style="    color: black;
                                                                                                                        font-weight: 100;
                                                                                                                        font-size: 12px;
                                                                                                                      
                                                                                                                      ">
                                
                                                                                                                        {{i+1}}
                                
                                                                                                                    </td>
                                                                                                                    <td style="    color: black;
                                                                                                                              font-weight: 100;
                                                                                                                              font-size: 12px;
                                                                                                                              min-width: 155px;">
                                
                                                                                                                        {{product.payCheckExpense.paycheckExpenses}}
                                
                                                                                                                    </td>
                                
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <p-dropdown filter="true"
                                                                                                                            appendTo="body"
                                                                                                                            [(ngModel)]="product.payCheckExpense.partyIdTo"
                                                                                                                            class="dropdowns"
                                                                                                                            [options]="PaytoPartyArray"
                                                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                                                                                                    </td>
                                
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <input type="text"
                                                                                                                            [(ngModel)]="product.payCheckExpense.amount"
                                                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                                                            class="form-control">
                                                                                                                    </td>
                                
                                                                                                                    <td
                                                                                                                        style="color: white;font-size: 11px;min-width: 76px;">
                                                                                                                        <button type="submit"
                                                                                                                            (click)="updatePaycheckExpenses(product)"
                                                                                                                            class="btn btn-secondary submit-btn">Update
                                                                                                                        </button>
                                                                                                                    </td>
                                                                                                                </tr>
                                
                                
                                                                                                                <tr class="align-bottom"
                                                                                                                    [formGroup]="payCheckExpenseForm">
                                                                                                                    <td>
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                
                                                                                                                        <input type="text"
                                                                                                                            formControlName="paycheckExpense"
                                                                                                                            class="form-control">
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <p-dropdown filter="true"
                                                                                                                            appendTo="body"
                                                                                                                            formControlName="payToParty"
                                                                                                                            class="dropdowns"
                                                                                                                            [options]="PaytoPartyArray"
                                                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                                                            optionlabel="label">
                                                                                                                        </p-dropdown>
                                
                                
                                                                                                                    </td>
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <input type="text"
                                                                                                                            formControlName="amount"
                                                                                                                            class="form-control">
                                                                                                                    </td>
                                
                                                                                                                    <td style="min-width: 155px;">
                                                                                                                        <button type="submit"
                                                                                                                            (click)="onSubmitPaycheckExpence()"
                                                                                                                            class="btn btn-secondary submit-btn">Create
                                                                                                                        </button>
                                                                                                                    </td>
                                
                                                                                                                </tr>
                                
                                                                                                            </tbody>
                                                                                                        </table>
                                
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                                <div *ngIf="activeCategory==3">
                                
                                                                                    <br>
                                                                                    <form action="" [formGroup]="costingForm">
                                                                                        <div class="row divA">
                                
                                
                                                                                            <div class="col-12">
                                
                                                                                                <div class="container-fluid pr-0">
                                
                                                                                                    <p style="color: #000; font-size: 12px;">
                                                                                                        Advantages </p>
                                                                                                    <br>
                                
                                                                                                    <span
                                                                                                        style="color: grey; font-size: 12px;padding-left: 7px;">Configure
                                                                                                        Default Value
                                                                                                        for Belgiam
                                                                                                        Advantages
                                                                                                    </span>
                                
                                
                                
                                                                                                </div>
                                
                                                                                                <div class="panel-body panel ">
                                
                                                                                                    <div
                                                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Paid Time Off</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="paidTimeOff"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        [(ngModel)]="this.LocalizationTable.paidTimeOff"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Commision on
                                                                                                                        Target</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        formControlName="commisionOnTarget"
                                                                                                                        [(ngModel)]="this.LocalizationTable.commisionOnTarget"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Fuel Card</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="fuelCard"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        [(ngModel)]="this.LocalizationTable.fuelCard"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                
                                                                                                            </div>
                                                                                                        </div>
                                
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Representation
                                                                                                                        Fees</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="representationFees"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        [(ngModel)]="this.LocalizationTable.representationFees"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Internet</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="internet"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        [(ngModel)]="this.LocalizationTable.internet"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Mobile</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="mobile"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        [(ngModel)]="this.LocalizationTable.mobile"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                
                                                                                                            </div>
                                                                                                        </div>
                                
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Internet</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="net"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                        Meal Vouchers</label>
                                                                                                                </div>
                                
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="mealVouchers"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        [(ngModel)]="this.LocalizationTable.mealVouchers"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-lg-12">
                                                                                                            <div class="row">
                                
                                                                                                                <div
                                                                                                                    class="col-lg-6 form-group classInput">
                                                                                                                    <label for="exampleInputEmail1">
                                
                                                                                                                        Eco Vouchers
                                                                                                                    </label>
                                                                                                                </div>
                                
                                                                                                                <div class="col-lg-4 classInputB">
                                                                                                                    <input type="email"
                                                                                                                        class="form-control"
                                                                                                                        formControlName="echoVouchers"
                                                                                                                        id="exampleInputEmail1"
                                                                                                                        [(ngModel)]="this.LocalizationTable.echoVouchers"
                                                                                                                        aria-describedby="emailHelp">
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                
                                                                                        </div>
                                                                                        <div class="row divB">
                                                                                            <div class="col-12">
                                
                                                                                                <div class="container-fluid pr-0">
                                
                                                                                                    <p style="color: #000; font-size: 12px;">
                                                                                                        ONSS </p>
                                                                                                    <br>
                                
                                                                                                    <span
                                                                                                        style="color: grey; font-size: 12px;padding-left: 7px;">Configure
                                                                                                        ONSS codes</span>
                                
                                
                                
                                                                                                </div>
                                
                                                                                                <div class="panel-body panel ">
                                                                                                    <form class="w-100" [formGroup]="costingForm">
                                                                                                        <div
                                                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="row">
                                
                                                                                                                    <div
                                                                                                                        class="col-lg-6 form-group classInput">
                                                                                                                        <label for="exampleInputEmail1">
                                                                                                                            ONSS Company ID</label>
                                                                                                                    </div>
                                
                                
                                                                                                                    <div
                                                                                                                        class="col-lg-6 classInput__new_changes">
                                                                                                                        <input type="email"
                                                                                                                            class="form-control"
                                                                                                                            formControlName="onssCompanyId"
                                                                                                                            id="exampleInputEmail1"
                                                                                                                            [(ngModel)]="this.LocalizationTable.onssCompanyId"
                                                                                                                            aria-describedby="emailHelp">
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="row">
                                                                                                                    <div
                                                                                                                        class="col-lg-6 form-group classInput">
                                                                                                                        <label for="exampleInputEmail1">
                                                                                                                            ONSS Registration
                                                                                                                            Number</label>
                                                                                                                    </div>
                                
                                
                                                                                                                    <div
                                                                                                                        class="col-lg-6 classInput__new_changes">
                                                                                                                        <input type="email"
                                                                                                                            class="form-control"
                                                                                                                            id="exampleInputEmail1"
                                                                                                                            formControlName="onssRegistrationNumber"
                                                                                                                            [(ngModel)]="this.LocalizationTable.onssRegistrationNumber"
                                                                                                                            aria-describedby="emailHelp">
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="col-lg-12">
                                                                                                                <div class="row">
                                
                                                                                                                    <div
                                                                                                                        class="col-lg-6 form-group classInput">
                                                                                                                        <label for="exampleInputEmail1">
                                                                                                                            DMFA Employer Class</label>
                                                                                                                    </div>
                                
                                
                                                                                                                    <div
                                                                                                                        class="col-lg-6 classInput__new_changes">
                                                                                                                        <input type="email"
                                                                                                                            class="form-control"
                                                                                                                            formControlName="dmfaEmployerClass"
                                                                                                                            id="exampleInputEmail1"
                                                                                                                            [(ngModel)]="this.LocalizationTable.dmfaEmployerClass"
                                                                                                                            aria-describedby="emailHelp">
                                                                                                                    </div>
                                
                                                                                                                </div>
                                                                                                            </div>
                                
                                
                                                                                                        </div>
                                                                                                        <div class="col-lg-12 col-12 main-submit-button "
                                                                                                            style="    margin-left: -58%;">
                                
                                                                                                            <a
                                                                                                                style="color: rgb(17, 102, 102);font-size: 12px;">
                                                                                                                <span
                                                                                                                    style="height: 10px; width: 15px;">
                                                                                                                    <svg aria-hidden="true" width="16"
                                                                                                                        height="16" focusable="false"
                                                                                                                        data-prefix="fas"
                                                                                                                        data-icon="arrow-right"
                                                                                                                        class="svg-inline--fa fa-arrow-right fa-w-14"
                                                                                                                        role="img"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                        viewBox="0 0 448 512">
                                                                                                                        <path fill="currentColor"
                                                                                                                            d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                                                                                                        </path>
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                                Work address DMFA codes</a>
                                
                                
                                                                                                            <!-- <button type="submit"
                                                                                                            style="border-radius: 0px !important;color: white;
                                                                                                                    height: 21px;font-size: 11px;min-width: 76px;padding-left: 1.7%;"
                                                                                                            class="btn btn-secondary submit-btn">Submit
                                                                                                        </button> -->
                                
                                                                                                        </div>
                                
                                                                                                        <div
                                                                                                            style="color: white;font-size: 11px;min-width: 76px;padding: 0px 20px !important;margin-left: 35%;">
                                                                                                            <button type="submit"
                                                                                                                style="padding: 0px 12px !important;"
                                                                                                                (click)="onSubmit(product)"
                                                                                                                class="btn btn-secondary submit-btn">Submit
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                </div>
                                                                                            </div>
                                
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="w3-card-4 classCard mt-4">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategorys==1?'active':''" (click)="findPaycheck()"><a>
                                Find Paycheck</a></li>
                            <li [ngClass]="activeCategorys==2?'active':''" (click)="createPaychecks()" ><a>
                                Create Paycheck</a></li>
                            <li [ngClass]="activeCategorys==3?'active':''" (click)="paycheckConfiguration()" ><a>
                                Paycheck Configuration</a></li>
                            <li [ngClass]="activeCategorys==4?'active':''"  (click)="employeess()"><a>
                                Employees</a></li>
                            
                            <li [ngClass]="activeCategory==5?'active':''"  (click)="contractReferencees()"><a>
                                Contract Reference</a></li>
            
                            <li [ngClass]="activeCategorys==6?'active':''" (click)="reports()"><a>
                                Reports</a></li>
                        </ul>
                    </div>

                <form [formGroup]="payCheckform">
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12">
                            <div class="row">

                                <div class="col-lg-3 form-group classInputA">
                                    <label for="exampleInputEmail1">
                                        Paycheck Type</label>
                                </div>


                                <div class="col-lg-2">
                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                    style="width: 132px !important;height: 20px !important;"
                                        formControlName="payCheckType" aria-describedby="emailHelp">
                                </div>
                            </div>
                        </div>
                       

                    </div>
                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -74%;    margin-top: -2%;">
                        <button type="submit" style="color: white;
                    height: 21px;font-size: 11px;min-width: 76px;height: 21px;padding-left: 1.7%;"
                            class="btn btn-secondary submit-btn" (click)="toggleShow()">Submit</button>

                        &nbsp;
                    </div>
                    <div class="col-lg-12" style="margin-bottom: 3%;">
                        <div class="row">
                            <div class="col-lg-6" style="margin-left: 2.5%;font-size: 12px;">
                                <p style="font-size: 11px; ">Note : Please enter Paycheck type to continue</p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>

            <!-- <div class=" bg-white color-grey mt-2" *ngIf="isShown"> -->
                <div class=" bg-white color-grey mt-2">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)">
                                <a>
                                    Deduction</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)">
                                <a>
                                    Allowance</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)">
                                <a>
                                    Costing
                                </a>
                            </li>

                        </ul>
                    </div>



                    <div class="create-new-leade mt-2" style="margin-bottom: 5% !important;">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">


                                                <div *ngIf="activeCategory==1">
                                                    <!-- <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Allowance
                                                        </h4>

                                                    </div> -->

                                                    <div class="panel-body panel">
                                                        <form class="w-100">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium  own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group table-responsive">
                                                                        <table class="table align-middle">
                                                                            <thead>
                                                                                <tr class="table-rows"
                                                                                    style="background: #0d3769;">
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                                        S.No

                                                                                    </th>
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                        Paycheck and
                                                                                        Witholdings

                                                                                    </th>
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                        Pay to Party

                                                                                    </th>
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                        Amount

                                                                                    </th>



                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                        Action


                                                                                    </th>



                                                                                </tr>

                                                                                <!-- <tr class="table-rows"
                                                                                    style="background: #fff;">
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;padding: 14px 20px !important;">


                                                                                    </th>
                                                                                    <th
                                                                                        style="color: #000;font-size: 12px;min-width:155px;font-weight: bold;">
                                                                                        Gross Amount

                                                                                    </th>
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">


                                                                                    </th>
                                                                                    <th
                                                                                        style="padding: 0px !important;">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-6">
                                                                                                <input type="text"
                                                                                                    [(ngModel)]="PaycheckExpenses.PayChecks.amount"
                                                                                                    class="form-control"
                                                                                                    [ngModelOptions]="{standalone: true}">
                                                                                            </div>

                                                                                            <div class="col-lg-6"
                                                                                                style="margin-left: -22%;">
                                                                                                <p-dropdown
                                                                                                    [options]="currencyArray"
                                                                                                    filter="true"
                                                                                                    [(ngModel)]="PaycheckExpenses.PayChecks.currencyUomId"
                                                                                                    appendTo="body"
                                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                                    class="dropdowns"
                                                                                                    optionlabel="label">
                                                                                                </p-dropdown>
                                                                                            </div>



                                                                                        </div>

                                                                                    </th>



                                                                                    <th
                                                                                        style="color: white;font-size: 11px;min-width: 76px;padding: 0px 20px !important;">
                                                                                        <button type="submit"
                                                                                            style="width: 61px !important;"
                                                                                            (click)="updatePayCheck(PaycheckExpenses)"
                                                                                            class="btn btn-secondary submit-btn">Update
                                                                                        </button>


                                                                                    </th>



                                                                                </tr> -->
                                                                            </thead>

                                                                            <tbody>

                                                                                <tr class="table-rows"
                                                                                    class="align-bottom"
                                                                                    *ngFor="let product of PaycheckAndWithHoldingsTable;let i = index;"
                                                                                    style="height: 11px !important;">
                                                                                    <td style="    color: black;
                                                                                            font-weight: 100;
                                                                                            font-size: 12px;
                                                                                          
                                                                                          ">

                                                                                        {{i+1}}

                                                                                    </td>
                                                                                    <td style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">


                                                                                        <span
                                                                                            *ngIf="product.paycheckAndWitholding">{{product.paycheckAndWitholding}}</span>
                                                                                        <span
                                                                                            *ngIf="product.abc1">{{product.abc1}}</span>

                                                                                    </td>
                                                                                    <td style="min-width: 155px;">

                                                                                        <p-dropdown filter="true"
                                                                                            appendTo="body"
                                                                                            [(ngModel)]="product.partyIdTo"
                                                                                            class="dropdowns"
                                                                                            [options]="PaytoPartyArray"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>

                                                                                    </td>
                                                                                    <td style="min-width: 155px;">
                                                                                        <input type="text"
                                                                                            [(ngModel)]="product.amount"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            class="form-control">

                                                                                    </td>


                                                                                    <td
                                                                                        style="color: white;font-size: 11px;min-width: 76px;padding: 10px 20px !important;">
                                                                                        <button type="submit"
                                                                                            style="padding: 0px 12px !important;"
                                                                                            (click)="updatePaycheckHoldings(product)"
                                                                                            class="btn btn-secondary submit-btn">Update
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>

                                                                                <tr class="align-bottom"
                                                                                    [formGroup]="PaycheckWithHoldingForm">

                                                                                    <td>

                                                                                    </td>
                                                                                    <td style="min-width: 155px;">

                                                                                        <input type="text" style="width: 180px !important;"
                                                                                            formControlName="PaycheckWitholdings"
                                                                                            class="form-control">

                                                                                    </td>
                                                                                    <td style="min-width: 155px;">

                                                                                        <p-dropdown filter="true"
                                                                                            appendTo="body"
                                                                                            formControlName="payToParty"
                                                                                            class="dropdowns"
                                                                                            [options]="PaytoPartyArray"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>

                                                                                    </td>
                                                                                    <td style="min-width: 155px;">
                                                                                        <input type="text"
                                                                                            formControlName="Amount"
                                                                                            class="form-control">
                                                                                    </td>

                                                                                    <td style="min-width: 155px;">
                                                                                        <button type="submit" style="margin-left: 7%;"
                                                                                            (click)="onSubmitPaycheckWithHolding()"
                                                                                            class="btn btn-secondary submit-btn">Create
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>

                                                                                <!-- <tr class="table-rows"
                                                                                    class="align-bottom"
                                                                                    style="height: 11px !important;">

                                                                                    <td>
                                                                                    </td>

                                                                                    <td style="min-width: 155px;">
                                                                                        Net Pay
                                                                                    </td>

                                                                                    <td style="min-width: 155px;">
                                                                                    </td>

                                                                                    <td style="min-width: 155px;">
                                                                                    
                                                                                        {{this.PayCheck}}
                                                                                    </td>

                                                                                    <td>

                                                                                    </td>
                                                                                </tr>  -->


                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <p class="paginate_data">
                                                        View per page

                                                    </p>

                                                </div>
                                                <div *ngIf="activeCategory==2">

                                                    <br>
                                                    <div>

                                                    </div>
                                                    <div class="panel-body panel">
                                                        <form class="w-100">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium  own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group table-responsive">
                                                                        <table class="table align-middle">
                                                                            <thead>
                                                                                <tr style="background: #0d3769;">
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                                        S.No

                                                                                    </th>
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                        Paycheck
                                                                                        Expenses

                                                                                    </th>
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                        Pay to Party

                                                                                    </th>
                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                        Amount

                                                                                    </th>



                                                                                    <th
                                                                                        style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                        Action


                                                                                    </th>



                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                <tr class="align-bottom"
                                                                                    *ngFor="let product of PaycheckExpensesTable;let i = index;">
                                                                                    <td style="    color: black;
                                                                                        font-weight: 100;
                                                                                        font-size: 12px;
                                                                                      
                                                                                      ">

                                                                                        {{i+1}}

                                                                                    </td>
                                                                                    <td style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">

                                                                                        {{product.payCheckExpense.paycheckExpenses}}

                                                                                    </td>

                                                                                    <td style="min-width: 155px;">
                                                                                        <p-dropdown filter="true"
                                                                                            appendTo="body"
                                                                                            [(ngModel)]="product.payCheckExpense.partyIdTo"
                                                                                            class="dropdowns"
                                                                                            [options]="PaytoPartyArray"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </td>

                                                                                    <td style="min-width: 155px;">
                                                                                        <input type="text"
                                                                                            [(ngModel)]="product.payCheckExpense.amount"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            class="form-control">
                                                                                    </td>

                                                                                    <td
                                                                                        style="color: white;font-size: 11px;min-width: 76px;">
                                                                                        <button type="submit"
                                                                                            (click)="updatePaycheckExpenses(product)"
                                                                                            class="btn btn-secondary submit-btn">Update
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>


                                                                                <tr class="align-bottom"
                                                                                    [formGroup]="payCheckExpenseForm">
                                                                                    <td>

                                                                                    </td>
                                                                                    <td style="min-width: 155px;">

                                                                                        <input type="text"
                                                                                            formControlName="paycheckExpense"
                                                                                            class="form-control">

                                                                                    </td>
                                                                                    <td style="min-width: 155px;">
                                                                                        <p-dropdown filter="true"
                                                                                            appendTo="body"
                                                                                            formControlName="payToParty"
                                                                                            class="dropdowns"
                                                                                            [options]="PaytoPartyArray"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>


                                                                                    </td>
                                                                                    <td style="min-width: 155px;">
                                                                                        <input type="text"
                                                                                            formControlName="amount"
                                                                                            class="form-control">
                                                                                    </td>

                                                                                    <td style="min-width: 155px;">
                                                                                        <button type="submit"
                                                                                            (click)="onSubmitPaycheckExpence()"
                                                                                            class="btn btn-secondary submit-btn">Create
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>

                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3">

                                                    <br>
                                                    <form action="" [formGroup]="costingForm">
                                                        <div class="row divA">


                                                            <div class="col-12">

                                                                <div class="container-fluid pr-0">

                                                                    <p style="color: #000; font-size: 12px;">
                                                                        Advantages </p>
                                                                    <br>

                                                                    <span
                                                                        style="color: grey; font-size: 12px;padding-left: 7px;">Configure
                                                                        Default Value
                                                                        for Belgiam
                                                                        Advantages
                                                                    </span>



                                                                </div>

                                                                <div class="panel-body panel ">

                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Paid Time Off</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="paidTimeOff"
                                                                                        id="exampleInputEmail1"
                                                                                        [(ngModel)]="this.LocalizationTable.paidTimeOff"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Commision on
                                                                                        Target</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        id="exampleInputEmail1"
                                                                                        formControlName="commisionOnTarget"
                                                                                        [(ngModel)]="this.LocalizationTable.commisionOnTarget"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Fuel Card</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="fuelCard"
                                                                                        id="exampleInputEmail1"
                                                                                        [(ngModel)]="this.LocalizationTable.fuelCard"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Representation
                                                                                        Fees</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="representationFees"
                                                                                        id="exampleInputEmail1"
                                                                                        [(ngModel)]="this.LocalizationTable.representationFees"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Internet</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="internet"
                                                                                        id="exampleInputEmail1"
                                                                                        [(ngModel)]="this.LocalizationTable.internet"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Mobile</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="mobile"
                                                                                        id="exampleInputEmail1"
                                                                                        [(ngModel)]="this.LocalizationTable.mobile"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Internet</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="net"
                                                                                        id="exampleInputEmail1"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Meal Vouchers</label>
                                                                                </div>


                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="mealVouchers"
                                                                                        id="exampleInputEmail1"
                                                                                        [(ngModel)]="this.LocalizationTable.mealVouchers"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div
                                                                                    class="col-lg-6 form-group classInput">
                                                                                    <label for="exampleInputEmail1">

                                                                                        Eco Vouchers
                                                                                    </label>
                                                                                </div>

                                                                                <div class="col-lg-4 classInputB">
                                                                                    <input type="email"
                                                                                        class="form-control"
                                                                                        formControlName="echoVouchers"
                                                                                        id="exampleInputEmail1"
                                                                                        [(ngModel)]="this.LocalizationTable.echoVouchers"
                                                                                        aria-describedby="emailHelp">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row divB">
                                                            <div class="col-12">

                                                                <div class="container-fluid pr-0">

                                                                    <p style="color: #000; font-size: 12px;">
                                                                        ONSS </p>
                                                                    <br>

                                                                    <span
                                                                        style="color: grey; font-size: 12px;padding-left: 7px;">Configure
                                                                        ONSS codes</span>



                                                                </div>

                                                                <div class="panel-body panel ">
                                                                    <form class="w-100" [formGroup]="costingForm">
                                                                        <div
                                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-6 form-group classInput">
                                                                                        <label for="exampleInputEmail1">
                                                                                            ONSS Company ID</label>
                                                                                    </div>


                                                                                    <div
                                                                                        class="col-lg-6 classInput__new_changes">
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            formControlName="onssCompanyId"
                                                                                            id="exampleInputEmail1"
                                                                                            [(ngModel)]="this.LocalizationTable.onssCompanyId"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                                                    <div
                                                                                        class="col-lg-6 form-group classInput">
                                                                                        <label for="exampleInputEmail1">
                                                                                            ONSS Registration
                                                                                            Number</label>
                                                                                    </div>


                                                                                    <div
                                                                                        class="col-lg-6 classInput__new_changes">
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            formControlName="onssRegistrationNumber"
                                                                                            [(ngModel)]="this.LocalizationTable.onssRegistrationNumber"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">

                                                                                    <div
                                                                                        class="col-lg-6 form-group classInput">
                                                                                        <label for="exampleInputEmail1">
                                                                                            DMFA Employer Class</label>
                                                                                    </div>


                                                                                    <div
                                                                                        class="col-lg-6 classInput__new_changes">
                                                                                        <input type="email"
                                                                                            class="form-control"
                                                                                            formControlName="dmfaEmployerClass"
                                                                                            id="exampleInputEmail1"
                                                                                            [(ngModel)]="this.LocalizationTable.dmfaEmployerClass"
                                                                                            aria-describedby="emailHelp">
                                                                                    </div>

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                        <div class="col-lg-12 col-12 main-submit-button "
                                                                            style="    margin-left: -58%;">

                                                                            <a
                                                                                style="color: rgb(17, 102, 102);font-size: 12px;">
                                                                                <span
                                                                                    style="height: 10px; width: 15px;">
                                                                                    <svg aria-hidden="true" width="16"
                                                                                        height="16" focusable="false"
                                                                                        data-prefix="fas"
                                                                                        data-icon="arrow-right"
                                                                                        class="svg-inline--fa fa-arrow-right fa-w-14"
                                                                                        role="img"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 448 512">
                                                                                        <path fill="currentColor"
                                                                                            d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z">
                                                                                        </path>
                                                                                    </svg>
                                                                                </span>
                                                                                Work address DMFA codes</a>


                                                                            <!-- <button type="submit"
                                                                            style="border-radius: 0px !important;color: white;
                                                                                    height: 21px;font-size: 11px;min-width: 76px;padding-left: 1.7%;"
                                                                            class="btn btn-secondary submit-btn">Submit
                                                                        </button> -->

                                                                        </div>

                                                                        <div
                                                                            style="color: white;font-size: 11px;min-width: 76px;padding: 0px 20px !important;margin-left: 35%;">
                                                                            <button type="submit"
                                                                                style="padding: 0px 12px !important;"
                                                                                (click)="onSubmit(product)"
                                                                                class="btn btn-secondary submit-btn">Submit
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>