<div class="container-fluid main-container-wrapper-new">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Dashboard</span>
            </div>
        </div>
    </div>
   
    <!-- <div class="row">
        <div class="col-md-7">
           
            <div class=" mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 320px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Inventory</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <div class="table-responsive">
                                        <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                       
                                            <tbody>
                                              <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                <td colspan="2" style="width: 61%;">Inventory</td>
                                                <td></td>
                                            
                                              </tr>
                                              <tr>
                                          
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    Required Stock Moves:
                                                </td>
                                            
                                                <td>
                                                    None
                                                </td>
                                               
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                   Requested Inventory Transfers:
                                                </td>
                                                <td>
                                                   None
    
                                                </td>
                                                
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                   Number of Back Orders:
                                                </td>
                                                <td>
                                                   None
    
                                                </td>
                                                
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    Number of Products Back Orders:
                                                </td>
                                                <td>
                                                    None
    
                                                </td>
                                               
                                              </tr>
                                            </tbody>
                                          </table>
                                    </div>
                              
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 320px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Shipping</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <div class="table-responsive">
                                        <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                       
                                            <tbody>
                                              <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                <td colspan="2" style="width: 61%;">Shipping</td>
                                                <td></td>
                                            
                                              </tr>
                                              <tr>
                                          
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    Orders Ready to Ship:
                                                </td>
                                            
                                                <td>
                                                    0
                                                </td>
                                               
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                   Open Picklists:
                                                </td>
                                                <td>
                                                   0
    
                                                </td>
                                                
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    Picklists to be Shipped
                                                </td>
                                                <td>
                                                   0
    
                                                </td>
                                                
                                              </tr>
                                              
                                            </tbody>
                                          </table>
                                    </div>
                              
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2" style="margin-bottom: 11% !important;">
                <div class="w3-card-4 classCard w-100">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Manufacturing</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <div class="table-responsive">
                                        <table class="table table-striped" style="overflow: hidden;white-space: nowrap;">
                                       
                                            <tbody>
                                              <tr style="background: #0d3769;color:#FFFFFF;font-size: 12px;font-weight: 700;">
                                                <td colspan="2" style="width: 61%;">Manufacturing</td>
                                                <td></td>
                                            
                                              </tr>
                                              <tr>
                                          
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    New Production Runs:
                                                </td>
                                            
                                                <td>
                                                    0
                                                </td>
                                               
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                   Scheduled Production Runs:
                                                </td>
                                                <td>
                                                   0
    
                                                </td>
                                                
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    Confirmed Production Runs:
                                                </td>
                                                <td>
                                                   0
    
                                                </td>
                                                
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    Active Production Runs:
                                                </td>
                                                <td>
                                                    None
    
                                                </td>
                                               
                                              </tr>
                                              <tr>
                                               
                                                <td colspan="2" style="width: 61%;">
                                                    Last Updated at 01/Nov/2022
                                                </td>
                                                <td>
                                                    <button class="btn btn-secondary">Refresh</button>
    
                                                </td>
                                               
                                              </tr>
                                            </tbody>
                                          </table>
                                    </div>
                              
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
        <div class="col-md-5" style="padding-left: 0;">
            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 320px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Manufacturing Queue</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p>No Data found!</p>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
       
    </div>
</div>     -->


<ngx-spinner></ngx-spinner>