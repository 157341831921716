<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <br>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                Add Related Contacts </a></li>
                    </ul>
                </div>
                <div class="panel-group">

                    <div *ngIf="activeCategory==1">

                        <div class="panel-body">
                            <form [formGroup]="fileUploadForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Contact Party Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="contactPartyId" [options]="contactArray"
                                                    optionlabel="label" id="exampleInputEmail1" filter="true"
                                                    placeholder="Select Contact Id"></p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <textarea id="w3review" formControlName="comments" rows="8" cols="50">
                                        </textarea>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="proper">
                                    <button type="submit" (click)="onSubmit()"
                                        class="btn btn-secondary submit-btn">Save</button>
                                    <button type="submit" (click)="navigate()" class="btn btn-danger">Cancel</button>
                                </div>
                                <br>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>