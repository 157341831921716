import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-detail-bill-summary',
  templateUrl: './detail-bill-summary.component.html',
  styleUrls: ['./detail-bill-summary.component.css']
})
export class DetailBillSummaryComponent implements OnInit {
  productId: any;
  @ViewChild('closebutton') closebutton;
  internalName: any;
  productAssocTypeDesc: any;

  activeCategory=2;
  productAssocTypeId: any;
  BOM: any;
  createBom:FormGroup;

  total=0;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  financialList = [{
    "product": "1"
  }];
  bomTypes: any;
  formulas: any;
  bomTypesArray: any[]=[];
  formulasArray: any[]=[];
  productListArray: any[]=[];
  taskList: any;
  taskListArray: any[]=[];
  fromDate: string;
  ThroughDate: string;

  show: boolean;
  assocFromProducts: any;
  fromDateEdit: any;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  bomSimulation: any;
  assocToProducts: any;
  date: Date;

  buttonBOM=false;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    private _location: Location,

    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createBom = this._FormBuilder.group({
        BomTypeA: [''],
        ProductIDA:[''],
        ToProductIDA:[''],
        BomType: [''],
        ProductID: [''],
        ToProductID:[''],
        FromDate:[''],
        ThroughDate: [''],
        SequenceNum: [''],
        Reason:[''],
        Instruction:[''],
        Quantity: [''],
        ScrapFactor:[''],
        Formula:[''],
        RoutingTask: [''],  
      });
    }
   
  ngOnInit(): void {
 
    this.activatedRoute.queryParams.subscribe(params=> [
      this.productId = params["productId"],
      this.internalName=params["internalName"],
      this.productAssocTypeDesc=params["productAssocTypeDesc"],
      this.productAssocTypeId=params["productAssocTypeId"],
      this.BOM=params["BOM"],
 
    
    
    ])
 
    if(this.BOM){
      this.buttonBOM=true;
    }
    this.getBOMInitialData();
    this.getProducts();
    this.getroutingTaskList();
    if(this.productId){
      this.show=false;
      this.getProductBOMData();
      setTimeout(() => {   
         const formValue = this.createBom;
         formValue.patchValue({
        BomType:this.productAssocTypeId,
        ProductID: this.productId,
        BomTypeA: this.productAssocTypeId,
        ProductIDA:this.productId,
         })
       }, 2000);
    }else{
      this.show=false;
      setTimeout(() => {
        const formValue = this.createBom;
        formValue.patchValue({
          BomType:'MANUF_COMPONENT',
          BomTypeA:'MANUF_COMPONENT',
        })
      
      }, 2000);
    }
   
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  
  deleteBOMData(fromDate,productId,productAssocTypeId,productIdTo) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) { 
        this.productionService.deleteBOMData(fromDate,productId,productAssocTypeId,productIdTo).subscribe((res: any) => { 
          this.getProductBOMData();
        }, (err) => {
          this._ToastrService.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        ) } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}
  showProductBOMData(){
    this.spinner.show();
    this.show=false;
    this.productionService.getProductBOMData(this.createBom.value.BomTypeA,this.createBom.value.ProductIDA).subscribe((res: any) => {
      this.assocFromProducts = res.data.assocFromProducts;
      this.assocToProducts=res.data.assocToProducts;
     
      this.spinner.hide();
    })
  }
  createBomSimulation(){
    this.closebutton.nativeElement.click();
    this._Router.navigate(['/production/create-bom-simulation'],{ queryParams: { productId :this.productId}})
    
  }
    getProductBOMData(){
      this.spinner.show();
    
      this.productionService.getProductBOMData(this.productAssocTypeId,this.productId).subscribe((res: any) => {
        this.assocFromProducts = res.data.assocFromProducts;
        this.assocToProducts=res.data.assocToProducts;
       
        this.spinner.hide();
      })
    }
    getProductBOMDataDetailById(productId,productAssocTypeId,fromDate,productIdTo){
      this.spinner.show();
      this.show=true;
      this.productionService.getProductBOMDataDetailById(productAssocTypeId,productId,productIdTo,fromDate).subscribe((res: any) => {
        setTimeout(() => {
          this.fromDateEdit=res.data.fromDate
           const fromdate=res.data.fromDate
           this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
           const estimatedCompletionDate=res.data.thruDate
           this.estimatedCompletionDate=this.datePipe.transform(estimatedCompletionDate,"yyyy-MM-dd"); 
           const formValue = this.createBom;
           formValue.patchValue({
          BomType:res.data.productAssocTypeId,
          ProductID: res.data.productId,
          ToProductID:res.data.productIdTo,
          FromDate:this.getEstimateFrom,
          ThroughDate: this.estimatedCompletionDate,
          SequenceNum: res.data.sequenceNum,
          Reason:res.data.reason,
          Instruction:res.data.instruction,
          Quantity: res.data.quantity,
          ScrapFactor:res.data.scrapFactor,
          Formula:res.data.estimateCalcMethod,
          RoutingTask: res.data.routingWorkEffortId,
          BomTypeA: res.data.productAssocTypeId,
          ProductIDA:res.data.productId,
           }) }, 2000); this.spinner.hide();
      })}
    onSubmit() {
      this.spinner.show();
     
    
      const FromDate = this.createBom.get('FromDate').value;
      this.fromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
      const ThroughDate = this.createBom.get('ThroughDate').value;
      this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
   
      const requestData = {
        "estimateCalcMethod":  this.createBom.value.Formula,
        "fromDate":  this.fromDate,
        "instruction":  this.createBom.value.Instruction,
        "productAssocTypeId":  this.createBom.value.BomType,
        "productId":  this.createBom.value.ProductID,
        "productIdTo": this.createBom.value.ToProductID,
        "quantity":  this.createBom.value.Quantity,
        "reason":  this.createBom.value.Reason,
        "routingWorkEffortId":  this.createBom.value.RoutingTask,
        "scrapFactor":  this.createBom.value.ScrapFactor,
        "sequenceNum":  this.createBom.value.SequenceNum,
        "thruDate": this.ThroughDate
      }
      this.productionService.postBOMData(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.spinner.hide();
          this._ToastrService.success("Created Successfully");
          this.closebutton.nativeElement.click();
          this._Router.navigate(['/production/bill-of-material-list']);
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
    homeButton(){
      this.router.navigate(['/production/main-production'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    scroll(el: HTMLElement) {
      el.scrollIntoView({behavior: 'smooth'});
  }
    customSort(event: SortEvent): void {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null) {
              result = -1;
          }
          else if (value1 != null && value2 == null) {
              result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
          }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
  }
    onUpdate() {
      this.spinner.show();
      const ThroughDate = this.createBom.get('ThroughDate').value;
      this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
      const requestData = {
        "estimateCalcMethod":  this.createBom.value.Formula,
        "fromDate": this.fromDateEdit,
        "instruction":  this.createBom.value.Instruction,
        "productAssocTypeId":  this.createBom.value.BomType,
        "productId":  this.createBom.value.ProductID,
        "productIdTo": this.createBom.value.ToProductID,
        "quantity":  this.createBom.value.Quantity,
        "reason":  this.createBom.value.Reason,
        "routingWorkEffortId":  this.createBom.value.RoutingTask,
        "scrapFactor":  this.createBom.value.ScrapFactor,
        "sequenceNum":  this.createBom.value.SequenceNum,
        "thruDate": this.ThroughDate
      }
      this.productionService.updateBOMData(requestData).subscribe((res: any) => {
        if (res.success) {
          this.spinner.hide();
          this._ToastrService.success("Updated Successfully");
          this.show=false;
          this.closebutton.nativeElement.click();
          this._Router.navigate(['/production/bill-of-material-list']);
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      })
    }
    getroutingTaskList() {
      this.spinner.show();
      
      this.productionService.getroutingTaskList().subscribe(res => {
        this.taskList = res.data.lookupResult;
        this.spinner.hide();
        for (const value of this.taskList) {
          this.taskListArray.push({
            label: value.workEffortId,
            value: value.workEffortId
          })
        }
      })
      
    }
    getProducts(): void {
      this.spinner.show();
      const data = {
        internalName: "",
        internalNameSearchType: "Contains",
        productId: "",
        productIdSearchType: "Contains",
      };
      this.accountsService.getProductsList(data).subscribe((res) => {
        const productList = res.data;
        this.spinner.hide();
        this.productListArray = res.data.map(value => {
          return {
            label: value.productId,
            value: value.productId
          };
        });
     
      });
    
    }
    getBOMInitialData() {
      this.spinner.show();
      this.productionService.getBOMInitialData(this.finSize).subscribe(res => {
        this.bomTypes = res.data.bomTypes;
        this.formulas = res.data.formulas;
        this.spinner.hide();
        for (const value of this.bomTypes) {
          this.bomTypesArray.push({
            label: value.description,
            value: value.productAssocTypeId
          })
        }
        for (const value of this.formulas) {
          this.formulasArray.push({
            label: value.description,
            value: value.customMethodId
          })
        }
      })
      this.date=new Date();
      let latest_date =this.datePipe.transform(this.date, 'yyyy-MM-dd');
      const formValue = this.createBom;
      formValue.patchValue({
     FromDate:latest_date
      })   
    }
  // cancelSubmit(){
  //   this.router.navigate(["/production/bill-of-material-list"])
  // }

}
