import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { WarehouseService } from '../warehouse/warehouse.service';

@Injectable()
export class CreateNewFacilitiesResolver implements Resolve<any>{

    constructor(readonly _WarehouseService: WarehouseService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        if (route.queryParams.facilityId) {
            return this._WarehouseService.getFacilityById(route.queryParams.facilityId).then(data => data.data);
        }
    }
}