<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Payroll</span>

               <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2"
                    (click)="toDashboard()">Home</button>
                <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
            </span>
            

            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="findPaycheck()"><a>
                                Find Paycheck</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="createPaychecks()" ><a>
                                Create Paycheck</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="paycheckConfiguration()" ><a>
                                Paycheck Configuration</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="employeess()"><a>
                                Employees</a></li>

                            <li [ngClass]="activeCategory==5?'active':''"  (click)="contractReferencees()"><a>
                                Contract Reference</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''" (click)="reports()"><a>
                                Reports</a></li>

                        </ul>
                    </div>

                <div class=" mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="contractorForm">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <!-- <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                            data-target="#exampleModalCenter">Create New Fixed Asset</button>  -->
                                        Contract Reference
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">


                                                <div class="col-lg-3 form-group  rightForm" >
                                                    <label for="exampleInputEmail1">Employee</label>
                                                </div>

                                                <div class="col-lg-2  dropdown-new">
                                                    <p-dropdown filter="true" formControlName="Employee" [options]="EmployeeArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>


                                                <div class="col-lg-3 form-group  rightFormA">
                                                    <label for="exampleInputEmail1">Start Date</label>
                                                </div>



                                                <div class="col-lg-2  dropdown-new ">
                                                    <input type="date" class="form-control small-input" id="exampleInputEmail1" name="Amount" formControlName="StartDate" aria-describedby="emailHelp">

                                                </div>




                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group  rightForm">
                                                    <label for="exampleInputEmail1">Department

                                                    </label>
                                                </div>

                                                <div class="col-lg-2  dropdown-new ">
                                                    <p-dropdown filter="true" formControlName="Department" [options]="DepartmentArray" optionlabel="label">

                                                    </p-dropdown>

                                                </div>

                                                <div class="col-lg-3 form-group  rightFormA">
                                                    <label for="exampleInputEmail1">End Date

                                                    </label>
                                                </div>

                                                <div class="col-lg-2  dropdown-new ">
                                                    <input type="date" class="form-control small-input" id="exampleInputEmail1" name="Amount" formControlName="endDate" aria-describedby="emailHelp">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group  rightForm">
                                                    <label for="exampleInputEmail1">Job Position </label>
                                                </div>

                                                <div class="col-lg-2  dropdown-new ">
                                                    <p-dropdown filter="true" formControlName="JobPosition" [options]="JobPositionArray" name="PaymentMethod" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group  rightFormA">
                                                    <label for="exampleInputEmail1">Working Schedule</label>

                                                </div>

                                                <div class="col-lg-2  dropdown-new ">
                                                    <p-dropdown filter="true" formControlName="WorkingSchedule" [options]="WorkingScheduleArray" name="PaymentMethod" optionlabel="label">

                                                    </p-dropdown>

                                                </div>
                                                <div class="col-lg-2">
                                                    <span data-toggle="modal" data-target="#exampleModalCenter">
                                                        <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="16"
                                                            (click)="getWorkSchedulePage();" height="16"
                                                            viewBox="0 0 21.223 21.222">
                                                            <g transform="translate(0 -0.004)">
                                                                <g transform="translate(0 1.52)">
                                                                    <path
                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                        transform="translate(0 -34.137)" />
                                                                </g>
                                                                <g transform="translate(4.548 0.004)">
                                                                    <g transform="translate(0 0)">
                                                                        <path
                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                            transform="translate(-102.409 -0.004)" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group  rightForm">
                                                    <label for="exampleInputEmail1">Company
                                                    </label>
                                                </div>
                                                <div class="col-lg-2  dropdown-new ">
                                                    <p-dropdown filter="true" formControlName="Company" name="Company" [options]="CompanyArray" optionlabel="label">

                                                    </p-dropdown>

                                                </div>

                                                <div class="col-lg-3 form-group  rightFormA">
                                                    <label for="exampleInputEmail1">HR Responsible
                                                    </label>
                                                </div>

                                                <div class="col-lg-2 dropdown-new ">
                                                    <p-dropdown filter="true" name="Responsible" optionlabel="label" [options]="ResponsibleArray" formControlName="Responsible">

                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group  rightForm">
                                                    <label for="exampleInputEmail1">Contract Type
                                                    </label>
                                                </div>

                                                <div class="col-lg-2  dropdown-new ">
                                                    <p-dropdown filter="true" formControlName="ContractType" [options]="ContractTypeArray" name="ContractType" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group  rightFormA">
                                                    <label for="exampleInputEmail1">Analytic Account
                                                    </label>
                                                </div>

                                                <div class="col-lg-2  dropdown-new ">
                                                    <p-dropdown filter="true" formControlName="Analytic" [options]="AnalyticArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group  rightForm">
                                                    <label for="exampleInputEmail1">Salary Structure Type</label>
                                                </div>
                                                <div class="col-lg-2  dropdown-new ">
                                                    <p-dropdown filter="true" formControlName="SalaryStructure" [options]="SalaryStructureArray" name="SalaryStructure" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="margin-left: 63%;height: 21px;font-size: 11px; width: 172px; padding-bottom: 3%;">
                                        <button type="button" class="btn btn-outline-secondary" (click)="onCreateContract()">Create </button>

                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div class=" mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="contractorDetailsForm">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <!-- <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                            data-target="#exampleModalCenter">Create New Fixed Asset</button>  -->
                                        Contract Details
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInputB">
                                                    <label for="exampleInputEmail1">Contract Template</label>
                                                </div>

                                                <div class="col-lg-2 dropdown-new">
                                                    <p-dropdown filter="true" formControlName="ContractTemplate" [options]="ContractTemplateArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInputB">
                                                    <label for="exampleInputEmail1">New Contract Document Template

                                                    </label>
                                                </div>

                                                <div class="col-lg-2 dropdown-new">
                                                    <p-dropdown filter="true" formControlName="NewDocumentTemplate" [options]="newContractDocumentTemplateArray" optionlabel="label">

                                                    </p-dropdown>

                                                </div>
                                            </div>
                                        </div>

                                       

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInputB">
                                                    <label for="exampleInputEmail1">Contract Update Document
                                                        Template
                                                    </label>

                                                </div>

                                                <div class="col-lg-2 dropdown-new">
                                                    <p-dropdown filter="true" formControlName="ContractUpdateDocument" [options]="ContractUpdateDocumentArray" name="PaymentMethod" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12 mb-3">
                                            <div class="row">

                                                <div class="col-lg-12 classInputB" style="font-size: 20px; color: #666666;">
                                                    Notes
                                                </div>
                                                <div class="col-lg-12 dropdown-new" style="color: #666666;margin-left: 2%;">
                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" style="border-radius:0px;height: 20px !important; margin-top: 1%; border: none; border-bottom: 1px solid #666666;" aria-describedby="emailHelp">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: 72%;height: 21px;font-size: 11px; width: 172px;">
                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="onCreateContractDetails()">Create
                                        </button>&nbsp;

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <br>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Open: Working Schedule</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Open: Working Schedule </a></li>


                            </ul>
                        </div>

                        <div class=" ">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="WorkingScheduleForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInputD">

                                                        <input type="email" formControlName="workingSchedule" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style="border-radius: 0px;width: 93% !important;">
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-12 mt-3">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Company</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdown-new  classInputF">
                                                        <p-dropdown optionlabel="label" formControlName="Company" [options]="CompanyArray" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Full Time </label>
                                                    </div>
                                                    <div class="col-lg-3 classInputF">
                                                        <input type="email" formControlName="FullTime" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Average Hour per Day
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 classInputF">
                                                        <input type="email" formControlName="AverageHourDay" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                                    </div>



                                                </div>
                                            </div>






                                        </div>

                                        <div class="main-submit-button" style="margin-right: 7%;">
                                        </div>

                                        <div class=" mt-2">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                            <div class="w-100">
                                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">


                                                                    <div class="w3-card-4 classCard">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling ml-2">
                                                                                Working Schedule
                                                                            </h4>

                                                                        </div>

                                                                        <div class="panel-body panel">
                                                                            <form class="w-100">
                                                                                <div class=" font-13 font-medium border-top-grey own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group table-responsive">
                                                                                            <table class="table align-middle">
                                                                                                <thead>
                                                                                                    <tr class="table-rows" style="background: #0d3769;">
                                                                                                        <th style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                                                            Working Schedule Name

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                            Company

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Full Time

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Average Hour per Day

                                                                                                        </th>



                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Action


                                                                                                        </th>



                                                                                                    </tr>

                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    <tr class="table-rows" *ngFor="let product of WorkingScheduleTable" style="min-width: 155px;height: 11px !important;" class="align-bottom">
                                                                                                        <td style="    color: black;
                                                                                            
                                                                                          
                                                                                          ">

                                                                                                            <input type="text" [(ngModel)]="product.workingSchedule" [ngModelOptions]="{standalone:true}" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">
                                                                                                            <p-dropdown filter="true" [(ngModel)]="product.partyId" [options]="CompanyArray" class="dropdowns" [ngModelOptions]="{standalone:true}" optionlabel="label">
                                                                                                            </p-dropdown>




                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.fullTime" type="text" [ngModelOptions]="{standalone:true}" class="form-control">


                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.averageHourPerDay" type="text" [ngModelOptions]="{standalone:true}" class="form-control">

                                                                                                        </td>


                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;padding: 12px 20px !important;">
                                                                                                            <button type="submit" (click)="updateWorkingSchedule(product)" class="btn btn-secondary submit-btn mt-2">Update
                                                                                                            </button>
                                                                                                        </td>

                                                                                                    </tr>

                                                                                                    <tr class="table-rows" class="align-bottom" [formGroup]="workingSchedule" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                        
                                                                                      
                                                                                      ">

                                                                                                            <input type="text" formControlName="WorkingScheduleName" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                          font-weight: 100;
                                                                                          font-size: 12px;
                                                                                          min-width: 155px;">
                                                                                                            <p-dropdown filter="true" formControlName="Company" [options]="CompanyArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>




                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="FullTime" type="text" class="form-control">


                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="AverageHourperDay" type="text" class="form-control">

                                                                                                        </td>


                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;padding: 20px 20px !important;">
                                                                                                            <button type="submit" (click)="onWorkingSchedule()" class="btn btn-secondary submit-btn ">Create
                                                                                                            </button>
                                                                                                        </td>

                                                                                                    </tr>


                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>



                                                                    </div>



                                                                    <div class="w3-card-4 classCard mt-4">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling ml-2">
                                                                                Working Hours


                                                                            </h4>


                                                                        </div>
                                                                        <br>
                                                                        <div>

                                                                        </div>
                                                                        <div class="panel-body panel">
                                                                            <form class="w-100">
                                                                                <div class=" font-13 font-medium border-top-grey own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group table-responsive">
                                                                                            <table class="table align-middle">
                                                                                                <thead>
                                                                                                    <tr class="table-rows" style="background: #0d3769;height: 12px !important;">
                                                                                                        <th style="color: white;font-size: 12px;min-width: 185px;padding: 14px 20px !important;">
                                                                                                            Name

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                            Day of Week

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Day Period

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Work From

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Work To

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Starting Date

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            End Date

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Work Entry Type

                                                                                                        </th>



                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Action


                                                                                                        </th>



                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    <tr class="align-bottom" *ngFor="let product of workingHoursTable" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                    font-weight: 100;
                                                                                    font-size: 12px;
                                                                                  
                                                                                  ">

                                                                                                            <input [ngModelOptions]="{standalone:true}" type="text" [(ngModel)]="product.data.name" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                          font-weight: 100;
                                                                                          font-size: 12px;
                                                                                          min-width: 155px;">
                                                                                                            <p-dropdown [ngModelOptions]="{standalone:true}" filter="true" [(ngModel)]="product.data.dayOfWeek" [options]="DayOfWeekArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>



                                                                                                        </td>

                                                                                                        <td class="dropdown-table" style="min-width: 155px;">
                                                                                                            <p-dropdown [(ngModel)]="product.data.dayPeriod" filter="true" [ngModelOptions]="{standalone:true}" [options]="DayPeriodArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.workFrom" type="text" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.workTo" type="text" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.startDate" type="date" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.endDate" type="date" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td class="dropdown-table" style="min-width: 155px;">
                                                                                                            <p-dropdown [(ngModel)]="product.data.workEntryType" filter="true" [ngModelOptions]="{standalone:true}" [options]="workEntryArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>

                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;">
                                                                                                            <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="16" height="16" (click)="updateWorkingHours(product)" viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>

                                                                                                            <svg (click)="deleteWorkingHours()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48">
                                                                                                                <g
                                                                                                                    fill="none">
                                                                                                                    <path
                                                                                                                        d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z"
                                                                                                                        fill="#000" />
                                                                                                                </g>
                                                                                                            </svg>


                                                                                                        </td>



                                                                                                    </tr>



                                                                                                    <tr class="align-bottom" [formGroup]="createWorkingHour" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                    font-weight: 100;
                                                                                    font-size: 12px;
                                                                                  
                                                                                  ">

                                                                                                            <input type="text" formControlName="names" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                          font-weight: 100;
                                                                                          font-size: 12px;
                                                                                          min-width: 155px;">
                                                                                                            <p-dropdown filter="true" formControlName="dayOfWeek" [options]="DayOfWeekArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>



                                                                                                        </td>

                                                                                                        <td class="dropdown-table" style="min-width: 155px;">
                                                                                                            <p-dropdown filter="true" formControlName="dayPeriod" [options]="DayPeriodArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="workFrom" type="text" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="WorkTo" type="text" class="form-control">
                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="startDate" type="date" class="form-control">
                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="endDate" type="date" class="form-control">
                                                                                                        </td>

                                                                                                        <td class="dropdown-table " style="min-width: 155px;">
                                                                                                            <p-dropdown formControlName="WorkEntryType" filter="true" [options]="workEntryArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>



                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;padding-top: 20px;">
                                                                                                            <button type="submit" (click)="oncreateWorkingHours()" class="btn btn-secondary submit-btn">Create
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr class="align-bottom" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                              
                                                                              ">


                                                                                                        </td>
                                                                                                        <td style="    color: black;
                                                                                      font-weight: 100;
                                                                                      font-size: 12px;
                                                                                      min-width: 155px;">




                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">


                                                                                                        </td>



                                                                                                        <td style="color: rgb(0, 0, 0);font-size: 12px;min-width: 76px;">
                                                                                                            Total Hours :

                                                                                                            <span>{{this.TotalHours}}</span>

                                                                                                        </td>
                                                                                                    </tr>


                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                    <div class="main-submit-button" style="margin-left:2%;position: static;">
                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="onsubmitWorkingSchedule()">Save</button>
                                        <button type="submit" class="btn btn-default submit-btn ml-2" style="color: #000; background-color: #fff;border: 1px solid #666666;" (click)="discardPopUp()">Discard</button>


                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>