import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from '../accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { CreateEventFormService } from '../events/create-events/create-event-service';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { HeaderService } from 'src/app/header/header.service';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-new-internal-note',
  templateUrl: './new-internal-note.component.html',
  styleUrls: ['./new-internal-note.component.css']
})
export class NewInternalNoteComponent implements OnInit {
  activeCategory=2;
  products3: any;
  rows = 50;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search = '';
  RoleList:any[]=[]
  createComm:FormGroup;
  createCommRole:FormGroup;
  createContent:FormGroup
  party: { label: string; value: string; }[];
  communicationEventId: any;
  personId: any;
  partyIdArray: any[]=[];
  roleTypeIdPre: any;
  roleTypeIdPreArray: any[]=[];  
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  eventRoles: any;
  parentContentIdArray: any[]=[];
  parentContentId: any;
  existingContentIds: any;
  existingContentIdsArray: any[]=[];
  fileData: File;
  communicationContentUploadById: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(
    readonly accountsService: AccountsService,
    private _location: Location,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    public createEventFormService: CreateEventFormService,
    readonly myContactsService: MyContactsService,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly _Router: Router,
    readonly headerService: HeaderService,
  ) { 
    this.party = [
      {
        label:'THE PRIVILEGED ADMINISTRATOR ',
        value: 'admin'
      }
    ]
    this.createComm = this._FormBuilder.group({
      content: [''],
      partyTo:[''],
      Subject:['']
 
    });
    this.createCommRole = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
   
 
    });
    this.createContent = this._FormBuilder.group({
      contentID: [''],
      parentContent: [''],
   
 
    });
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.communicationEventId = params.commEventId;
    })
    this.getPartyId();
    this.getRoleTypeIdPre();
    this.getEventRoles();
    this.getexistingContentIds();
    this.parentContentIdList();
    this.getCommunicationContentUploadById();
  }
  cancelSubmit(){
    this._location.back();
  }
  deleteUpload(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.myContactsService.deleteUpload(product.communicationContentUploadId).subscribe(res => {
          this.getCommunicationContentUploadById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getCommunicationContentUploadById() {
    this.spinner.show();
    this.myContactsService.getCommunicationContentUploadById(this.communicationEventId).subscribe(res => {
      this.communicationContentUploadById = res.data[0].getCommunicationContentUploadById;
      this.spinner.hide();
    })
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  createUpload() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
      const formData = new FormData();
      formData.append("file", this.fileData);
  
      this.myContactsService.createUpload(formData, this.createContent.value.contentID, this.createContent.value.parentContent).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.createContent.reset()
          this.spinner.hide();
          this.getCommunicationContentUploadById();
  
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
        this.spinner.hide();
  
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
   

  }
  parentContentIdList(){
    this.spinner.show();
    this.myContactsService.getparentContentIdList().subscribe(res=>{
      this.parentContentId = res.data;
      this.spinner.hide();
      for (const value of this.parentContentId) {
        this.parentContentIdArray.push({
          label: value.contentIdTo,
          value: value.contentId
        })
      }
    })
  }
  getexistingContentIds(){
    this.spinner.show();
    this.myContactsService.getexistingContentIds().subscribe(res=>{
      this.existingContentIds = res.data;
      this.spinner.hide();
      for (const value of this.existingContentIds) {
        this.existingContentIdsArray.push({
          label: value.description,
          value: value.contentId
        })
      }
    })
  }
  deleteCommunicationEventRole(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.myContactsService.deleteCommunicationEventRole(this.communicationEventId,product.partyId,product.roleTypeId).subscribe(res => {
          this.getEventRoles();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getEventRoles(){
    this.spinner.show();
      this.myContactsService.getEventRoles(this.user,this.communicationEventId).subscribe((res:any)=> {
        this.eventRoles = res.data[0].getCommunicationEventOrders;
        this.spinner.hide();
      
      })
     
     }
  createCommunicationEventRole(): void {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
    this.spinner.show();
      const requestData = {
        "partyId":  this.createCommRole.value.partyID,
        "roleTypeId":   this.createCommRole.value.roleTypeID,
      }
      this.myContactsService.createCommunicationEventRole(requestData,this.communicationEventId).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.createCommRole.reset();
          this.spinner.hide();
          this.getEventRoles();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
 
  }
  getRoleTypeIdPre(){
    this.spinner.show();
    this.myContactsService.getRoleTypeIdPre().subscribe(res=>{
      this.roleTypeIdPre = res.data[0].node;
      this.spinner.hide();
      for (const value of this.roleTypeIdPre) {
        this.roleTypeIdPreArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  sendSaveCommunicationEventSave(): void {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
      const requestData = {
        "communicationEventId": this.communicationEventId,
        "communicationEventTypeId": "COMMENT_NOTE",
        "content":this.createComm.value.content,
        "contentMimeTypeId": "text/plain",
        "datetimeStarted": this.datePipe.transform(new Date(),'yyyy-MM-dd hh:mm:ss'),
        "dummy": "",
        "form": "list",
        "my": "My",
        "parentCommEventId": "",
        "partyIdFrom": this.createComm.value.partyTo,
        "partyIdTo": this.createComm.value.partyTo,
        "portalPageId": "",
        "save": "",
        "send": "",
        "statusId": "COM_PENDING",
  
      }
      this.myContactsService.sendSaveCommunicationEventSave(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Saved Successfully");
          this.createComm.reset();
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }
   
  }
  sendSaveCommunicationEvent(): void {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();

      const requestData = {
        "communicationEventId": this.communicationEventId,
        "communicationEventTypeId": "COMMENT_NOTE",
        "content":this.createComm.value.content,
        "contentMimeTypeId": "text/plain",
        "datetimeStarted": this.datePipe.transform(new Date(),'yyyy-MM-dd hh:mm:ss'),
        "dummy": "",
        "form": "list",
        "my": "My",
        "parentCommEventId": "",
        "partyIdFrom": this.createComm.value.partyTo,
        "partyIdTo": this.createComm.value.partyTo,
        "portalPageId": "",
        "save": "",
        "send": "",
        "statusId": "COM_ENTERED",
        "subject": this.createComm.value.subject,
  
        
  
      }
  
      this.myContactsService.sendSaveCommunicationEvent(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.createComm.reset();
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this.toastr.error("Permission Denied"); 
    }

   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
