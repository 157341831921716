<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                   <span class="color-black pl-1 titlePanels" *ngIf="Person">The Profile of {{Person.personInformation.personalTitle}}
                        {{Person.personInformation.firstName}}
                        {{Person.personInformation.middleName}}
                        {{Person.personInformation.lastName}} 
                        {{"[" + this.taxAuthPartyId + "]"}}</span>
                        <span class="d-flex">

                            <button type="submit" (click)="homeButton();"
                                class=" btn btn-outline-secondary mr-2">Home</button>
                    
                            <button type="submit" (click)="cancelSubmit();"
                                class="btn btn-danger buttonclass ">Back</button>
                        </span>
                    

                </div>
            <div class="">
               
                <div class=" bg-white color-grey">
                    <div class="create-new-leade">
                        <div class="container-fluid m-0 p-0">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="w3-card-4 classCardA ">

                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                        <li [ngClass]="activeCategoryA==1?'active':''" (click)="MyProfile()"><a>
                                                            Profile

                                                        </a></li>
                                                        <li [ngClass]="activeCategoryA==2?'active':''" (click)="Preferences()">
                                                            <a>
                                                            Preferences
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==3?'active':''" (click)="Roles()">
                                                            <a>
                                                            Role(s)</a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==4?'active':''" (click)="Project()">
                                                            <a>
                                                            Projects
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==5?'active':''" (click)="TasksProfile()">
                                                            <a>
                                                            Tasks
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==6?'active':''" (click)="Rates()">
                                                            <a>
                                                            Rates
                                                        </a>
                                                        </li>
                                                        <li [ngClass]="activeCategoryA==7?'active':''" (click)="Revisions()">
                                                            <a>
                                                            Revisions
                                                        </a>
                                                        </li>


                                                    </ul>
                                                </div>
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div class="create-new-leade ">
                                                        <div class="container-fluid">


                                                            <div class="panel-group">

                                                                <div>
                                                                    <div class=" bg-white color-grey">
                                                                        <div class="create-new-leade ">
                                                                            <div class="container-fluid m-0 p-0">
                                                                                <div class="row">
                                                                                    <form class="w-100">


                                                                                        <br>
                                                                                        <div class="create-new-leade">
                                                                                            <div class="container-fluid ">
                                                                                                <div class="row">

                                                                                                    <form class="w-100">

                                                                                                        <div>
                                                                                                            <div class="w3-card-4 classCard">
                                                                                                                <div class="container-fluid">
                                                                                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                                                                                                flex-wrap: wrap !important;
                                                                                                display: flex !important;">
                                                                                                                        <li><a>
                                                                                                                        Add Task
 

                                                                                                                        </a>
                                                                                                                        </li>

                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                                <form [formGroup]="AddTaskForm">


                                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                                                                        <div class="col-lg-12">
                                                                                                                            <div class="row">
                                                                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                                                                    <label for="exampleInputEmail1">
                                                                                                                                    Task Name


                                                                                                                                </label>
                                                                                                                                </div>
                                                                                                                                <div class="col-lg-2">
                                                                                                                                    <p-dropdown filter="true" formControlName="TaskName" 
                                                                                                                                    optionlabel="label" placeholder="Enter 
                                                                                                                                    Task Name">
                                                                                                                                    </p-dropdown>



                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                        </div>





                                                                                                                    </div>
                                                                                                                    <div class="col-lg-12  main-submit-button" style="
                                                                                                                                           margin-left: -66%;">
                                                                                                                        <button _ngcontent-cta-c128="" type="submit" 
                                                                                                                        class="btn btn-secondary submit-btn">Add</button>&nbsp;


                                                                                                                    </div>
                                                                                                                </form>

                                                                                                            </div>

                                                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                                                <header class="w3-container w3-blue">
                                                                                                                    <div class="header-tabs">
                                                                                                                        <h4 class=" common-styling h4Margin">

                                                                                                                        </h4>

                                                                                                                    </div>
                                                                                                                </header>
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                                    <div class="col-lg-12 col-12">
                                                                                                                        <div class="form-group">

                                                                                                                            <div class="card own-account-table">
                                                                                                                                <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                                    <ng-template pTemplate="header">
                                                                                                                                        <tr style="background: #0d3769;">
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <!---->
                                                                                                                                                    </p-checkbox>


                                                                                                                                                    Task Name
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">

                                                                                                                                                    Task Type

                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Sprint

                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Product Backlog
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Task Current Status ID
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Actual Start Date
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Actual Hours
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>

                                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                                <div style="color: white;">
                                                                                                                                                    Plan Hours
                                                                                                                                                </div>
                                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                            </th>





                                                                                                                                        </tr>

                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                                                        <tr>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div class="checkbox-align">
                                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                        <!---->
                                                                                                                                                    </p-checkbox>
                                                                                                                                                    {{product.workEffortId}}
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.workEffortName}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.toPartyId}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.toPartyId}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.toPartyId}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.toPartyId}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.toPartyId}}
                                                                                                                                            </td>
                                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.toPartyId}}
                                                                                                                                            </td>

                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span class="text-center">
                                                                                                                            <h4
                                                                                                                                class="ml-2">
                                                                                                                                No
                                                                                                                                Record
                                                                                                                                Found
                                                                                                                            </h4>
                                                                                                                        </span>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                </p-table>

                                                                                                                                <p class="paginate_data">
                                                                                                                                    View per page
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>
                                                                                                    </form>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profile1" tabindex="-1" role="dialog" aria-labelledby="profileUpdate" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Edit Personal Information</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateProfile">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="partyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Salutation
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="salutation" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Salutation">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">First Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="firstName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Middle Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="middleName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Middle Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Last Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="lastName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name ">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Personal Title
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="personalTitle" id="exampleInputEmail1" aria-describedby="emailHelp" value="Mr. " placeholder="Enter Personal Title">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Suffix
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="suffix" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Suffix">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Nickname
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="nickname" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Nickname">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">First Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="firstNameLocal" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name Local">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Middle Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="middleNameLocal" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Middle Name Local">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Last Name Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="lastNameLocal" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name Local">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Other Local
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="otherLocal" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Other Local">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Member ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="memberID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Member ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Gender
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="gender" optionlabel="label" placeholder="Enter Gender">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Birth Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="birthDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Birth Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Decreased Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="decreasedDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Decreased Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Height
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="height" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Height">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Weight
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="weight" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Weight">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Mother's Maiden Name 
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="mothersMaidenName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Mother's Maiden Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Old Maritial Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="oldMaritialStatus" optionlabel="label" placeholder="Enter Old Maritial Status">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Maritial Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="maritialStatusEnumID" optionlabel="label" placeholder="Enter Maritial Status Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Social Security Number
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="socialSecurityNumber" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Social Securtiy Number">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Passport Number
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="passportNumber" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Passport Number">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Passport Expire Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="passportExpiryDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Passport Expiry Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Total Years Work Experience
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="totalExperience" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Total Years Work Experience">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Employee Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="employeeStatusEnumID" optionlabel="label" placeholder="Enter Employee Status Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Residence Status Enum ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="residenceStatusEnumID" optionlabel="label" placeholder="Enter Residence Status Enum ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Occupation
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="occupation" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Occupation">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Years With Employer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="yearswithEmployer" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Years With Employer">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Months With Employer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="monthsWithEmployer" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Months With Employer">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Existing Customer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="existingCustomer" optionlabel="label" placeholder="Enter Existing Customer">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Card ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="cardID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Card ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">US Citizen
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="usCitizen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter US Citizenship">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Ethnicity
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="ethnicity" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Ethnicity">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Account No.
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="accountNo" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Number">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Billing Rate Level
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="billingRateLevel" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Billing Rate Level">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Preferred Currency Uom ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="preferredCurrencyUomID" optionlabel="label" placeholder="Enter Preferred Currency Uom ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">External ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="externalID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Status ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="statusID" optionlabel="label" placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateUserLogIn" tabindex="-1" role="dialog" aria-labelledby="createUsername" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Create User Login</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createUserLogIn">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">User Login ID
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="userLoginID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Login ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Current Password
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="currentPassword" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Current Password">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Current Password Verify
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="currentPasswordVerify" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Verify Current Password">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Password Hint
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="passwordHint" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password Hint">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Require Password Change
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="requirePasswordChange" optionlabel="label" placeholder="Enter Password Change Requirement">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">External Auth ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="externalAuthID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Auth ID">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button1">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileListRelatedAccounts" tabindex="-1" role="dialog" aria-labelledby="listRelatedAccount" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Add Related Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addRelatedAccount">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Account Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="accountPartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Party ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button3">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileContactInformation" tabindex="-1" role="dialog" aria-labelledby="contactInformation" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Create New Contact Information</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNewContactInformation">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Select Contact Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="selectContactType" optionlabel="label" placeholder="Enter Contact Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profilePartyAttribute" tabindex="-1" role="dialog" aria-labelledby="partyAttribute" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a> Party Attribute</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="partyAttributes">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Attr Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="attrName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attribute Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Attr Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="attrValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attribute Value">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Attr Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="attrDescription" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attribute Description">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button2">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileAddCheckAcc" tabindex="-1" role="dialog" aria-labelledby="addCheckAcc" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Check Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCheckAccount">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Name Account <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Name Account" id="exampleInputEmail1" formControlName="nameOnAccount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Company Name On Account</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Company Name On Account" id="exampleInputEmail1" formControlName="companyNameOnAccount">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Bank Name <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Bank Name" id="exampleInputEmail1" formControlName="bankName">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Routing Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Routing Number" id="exampleInputEmail1" formControlName="routingNumber">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Payment Method Type <span class="text-danger">*</span>
                                          </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Type" filter="true" id="exampleInputEmail1" formControlName="paymentMethodTypeId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Account Type <span class="text-danger">*</span>
                                          </label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Type" filter="true" id="exampleInputEmail1" formControlName="accountType">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Account Number <span class="text-danger">*</span></label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Account Number" id="exampleInputEmail1" formControlName="accountNumber">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Description <span class="text-danger">*</span></label>

                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 main-submit-button4">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateBillingAcc" tabindex="-1" role="dialog" aria-labelledby="createBillingAcc" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Edit Billing Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createBillingAccount">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Account Limit" id="exampleInputEmail1" formControlName="accountLimit">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Currency Uom ID" filter="true" id="exampleInputEmail1" formControlName="accountCurrencyUomId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Contact Mech ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Contact Mech ID" id="exampleInputEmail1" formControlName="contactMechId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" placeholder="From Date" id="exampleInputEmail1" formControlName="fromDate">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" placeholder="Through Date" formControlName="thruDate">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Description
                                              </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Description" id="exampleInputEmail1" formControlName="description">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">External Account ID
                                              </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="External Account ID" id="exampleInputEmail1" formControlName="externalAccountId">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Party ID <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Party ID" filter="true" id="exampleInputEmail1" formControlName="partyId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p> $55 <span> This is the amount which can be used
                                                  for new orders.</span></p>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 main-submit-button5">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateCreditCard" tabindex="-1" role="dialog" aria-labelledby="createCreditCard" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add New Credit Card</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNewCreditCard">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Company Name Card</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Company Name Card" id="exampleInputEmail1" formControlName="companyNameOnCard">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Prefix Card</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Prefix Card" filter="true" id="exampleInputEmail1" formControlName="titleOnCard">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">First Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="First Name" id="exampleInputEmail1" formControlName="firstNameOnCard">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Middle Name Card</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Middle Name Card" id="exampleInputEmail1" formControlName="middleNameOnCard">
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Last Name Card <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Last Name Card" id="exampleInputEmail1" formControlName="lastNameOnCard">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Suffix On Card
                                              </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Suffix On Card" filter="true" id="exampleInputEmail1" formControlName="suffixOnCard">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Card Type
                                              </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Card Type" filter="true" id="exampleInputEmail1" formControlName="cardType">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Card Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="cardNumber" aria-describedby="emailHelp" placeholder="Card Number">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Expiration Date <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Month" filter="true" id="exampleInputEmail1" formControlName="expMonth">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown class="ml-2" placeholder="Year" filter="true" id="exampleInputEmail1" formControlName="expYear">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput3">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInput3">
                                                            <label for="">Billing Address</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 main-submit-button5">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateGiftCard" tabindex="-1" role="dialog" aria-labelledby="createGiftCard" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Create Gift Card</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createGiftCard">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Card Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="cardNumber" aria-describedby="emailHelp" placeholder="Card Number">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">PIN number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Pin Number" id="exampleInputEmail1" formControlName="pinNumber">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Expiration Date <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Month" filter="true" id="exampleInputEmail1" formControlName="expMonth">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown class="ml-2" placeholder="Year" filter="true" id="exampleInputEmail1" formControlName="expYear">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput3">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 main-submit-button5">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="profileCreateEFTAccount" tabindex="-1" role="dialog" aria-labelledby="eftAccount" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Profile </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add New EFT Account</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createEFTAcc">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Name Account <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Name Account" id="exampleInputEmail1" formControlName="nameOnAccount">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Company Name On Account</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Company Name On Account" id="exampleInputEmail1" formControlName="companyNameOnAccount">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Bank Name <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Bank Name" id="exampleInputEmail1" formControlName="bankName">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Routing Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Routing Number" id="exampleInputEmail1" formControlName="routingNumber">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Account Type <span class="text-danger">*</span>
                                              </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Account Type" filter="true" id="exampleInputEmail1" formControlName="accountType">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Account Number <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" placeholder="Account Number" id="exampleInputEmail1" formControlName="accountNumber">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="exampleInputEmail1">Description <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="description" aria-describedby="emailHelp" placeholder="Description">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput2">
                                                        <label for="">Billing Address</label>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 main-submit-button6">
                                            <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>