
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoginModel } from './login-model';

@Injectable({
  providedIn: 'root'
})


export class LoginReactiveService {
  login_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public loginModel: LoginModel
  ) { }

  loginForm(data: any): void {
    if (!this.login_form) {
      this.login_form = this._formBuilder.group(this.loginModel.mapDataToModel(data));

    }

  }

}












