import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerAgreementsService {
  constructor(readonly http: HttpClient, readonly _ApiService: ApiService) { }

  createAgreementAccnAR(formData): Observable<any> {
    const createAgreementAccnAR = URLS.createAgreementAccnAR;
    return this.http.post(createAgreementAccnAR, formData);
  }

  getProducts(formData): Observable<any> {
    const getProducts = URLS.getProducts;
    return this.http.post(getProducts, formData);
  }

  getRoleTypes(): Observable<any> {
    const getRoleTypes = URLS.getRoleTypes;
    return this.http.get(getRoleTypes);
  }

  getAgreementTypeList(): Observable<any> {
    const getAgreementTypeList = URLS.getAgreementTypeList;
    return this.http.get(getAgreementTypeList);
  }

  getAgreementList(formData): Observable<any> {
    const getAgreementList = URLS.getAgreementList;
    return this.http.post(getAgreementList, formData);
  }

  getAgreementById(id): Observable<any> {
    const getAgreementById = URLS.getAgreementById + `/${id}`;
    return this.http.get(getAgreementById);
  }

  getAgreementTermList(id): Observable<any> {
    const getAgreementTermList = URLS.getAgreementTermList + "?agreementId=" + id;
    return this.http.get(getAgreementTermList);
  }

  getTermTypeIdList(): Observable<any> {
    const getTermTypeIdList = URLS.getTermTypeIdList;
    return this.http.get(getTermTypeIdList);
  }

  getInvoiceItemTypeList(): Observable<any> {
    const getInvoiceItemTypeList = URLS.getInvoiceItemTypeList;
    return this.http.get(getInvoiceItemTypeList);
  }

  createAgreementTerm(formData): Observable<any> {
    const createAgreementTerm = URLS.createAgreementTerm;
    return this.http.post(createAgreementTerm, formData);
  }
  cancelAgreement(agreementId: string): Promise<any> {
    return this.http.post(`/phyCorp/control/api/v1/accounting/agreement/${agreementId}`, {}).toPromise();
  }
  updateAgreement(agreementId: string, formData: any): Promise<any> {
    return this.http.put(`/phyCorp/control/api/v1/accounting/agreement/${agreementId}`, formData).toPromise();
  }
  getAgreementTermById(agreementId: string, agreementTermId: string): Promise<any> {
    return this.http.get(`/phyCorp/control/api/v1/accounting/agreementTerms/${agreementTermId}?agreementId=${agreementId}`).toPromise();
  }
  updateAgreementTerm(agreementTermId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/agreementTerms/${agreementTermId}`, formData);
  }
  deleteAgreementTerm(agreementId: string, agreementTermId: string): Promise<any> {
    return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/accounting/agreementTerms/${agreementTermId}?agreementId=${agreementId}`);
  }
}