<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Find</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView  w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Find 
                                    <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#exampleCreateNewContactList">Create New Contact List
                                    </button> -->
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4 classCard w-100">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Summary</p>
                                                        <span>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                style="margin: 0;" data-toggle="modal"
                                                                data-target="#updatePopup" >Update</button>
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                        <div  class="color-black container">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6">Sorted By</p>
                                                                            <!-- <span>
                                                                                <svg (click)="onDelete()" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                    <defs>
                                                                                        <style>
                                                                                            .a {
                                                                                                fill: #f44336;
                                                                                            }

                                                                                            .b {
                                                                                                fill: #fafafa;
                                                                                                margin-left: 20px;
                                                                                            }
                                                                                        </style>
                                                                                    </defs>
                                                                                    <path
                                                                                        class="a fill-color"
                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                    <path class="b"
                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                </svg>
                                                                            </span> -->
                                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                                {{this.sortedBy}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCard mt-3">
                                                        
                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="contentSearchResults" [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [responsive]="true" [totalRecords]="total" [scrollable]="true"
                                                                        styleClass="p-datatable-customers"
                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined"
                                                                                                        value="undefined"></div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Content ID <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                
                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}" >
                                                                                    <div style="color: white;">Keyword
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                             
                                                                                
                                                                                
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined"
                                                                                                        value="undefined"></div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"
                                                                                            >{{product.contentKeyword[0].contentId}}</span>
                                                                                    </div>
                                                                                </td>
                        
                        
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.contentKeyword[0].keyword}}
                                                                                </td>
                                                                                
                                                                              
                                                        
                        
                                                                            </tr>
                        
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal fade" id="updatePopup" tabindex="-1" role="dialog" aria-labelledby="updatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Search Contents</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Search Contents</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="searchContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Sorted By</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="sortedBy" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Sorted By">
                                               
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                          
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>
