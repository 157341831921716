<div class="container-fluid main-container-wrapper-new">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Professional Service Automation 
            </span>
               <span class="d-flex">
 
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div> 
        </div>
    </div>
   
    <div class="row mb-5">
        <div class="col-md-6 mb-5 pr-2">
           
            <div class=" mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 190px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">My Profile</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" [routerLink]="['/psa/my-profile']" 
                                        [queryParams]="{ taxAuthPartyId: this.userLoginId}">
                                            My Profile
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" [routerLink]="['/psa/preferences-my-profile']" 
                                        [queryParams]="{ taxAuthPartyId: this.userLoginId}">
                                        Preference List

                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" [routerLink]="['/psa/roles']" 
                                        [queryParams]="{ taxAuthPartyId: this.userLoginId}">
                                        Member Roles

                                        </span>
                                 

                                    
                                    </div>
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" [routerLink]="['/psa/project-my-profile']" 
                                        [queryParams]="{ taxAuthPartyId: this.userLoginId}">
                                        Add Project

                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" [routerLink]="['/psa/tasks-my-profile']" 
                                        [queryParams]="{ taxAuthPartyId: this.userLoginId}">
                                        Add Task

                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" [routerLink]="['/psa/rates-my-profile']" 
                                        [queryParams]="{ taxAuthPartyId: this.userLoginId}">
                                        Edit Party Rates
                                        </span>
                                    </div>
                                    <div class="article">
                                        
                                        <span style="cursor: pointer;    color: #0000FF;" class="" [routerLink]="['/psa/revisions-my-profile']" 
                                        [queryParams]="{ taxAuthPartyId: this.userLoginId}">
                                        Find Revision

                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          
            <div class=" mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 190px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Administration</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/admin-scrum">
                                            Find Product Backlog Item

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/admin-scrum">
                                            Find TimeSheet

                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/admin-scrum">
                                            Find Time Entry Not yet Billed

                                        </span>
                                    </div>
                                    <div class="article">
                                     
                                        <span style="cursor: pointer; color: #0000FF;" routerLink="/psa/admin-scrum" class="">
                                            Default Tasks List

                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/admin-scrum">
                                            Find Scrum Member

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/admin-scrum">
                                            Find Product Statistics
                                        </span>
             
                                    </div>


                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/admin-scrum">
                                            Add New Product Backlog Item
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/admin-scrum">
                                            Create Task for Product Backlog

                                        </span>
                                        
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 190px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Scrum</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/product-backlog">
                                            Find Product

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/sprints">
                                            Sprints List 

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" routerLink="/psa/tasks" class="">
                                            Find Task

                                        </span>
             
                                    </div>
                                   
                                    <div class="article">
                                      
                                        <span style="cursor: pointer; color: #0000FF;" routerLink="/psa/find-task-revision" class="">
                                            Find Revision


                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/my-work">
                                            My Work

                                         </span>
                                         <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/open-test">
                                            Open Test

                                        </span>
                                      
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

           
          
        </div>
        <div class="col-md-6" style="padding-left: 0;">
            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 190px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Project</p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/project/project">
                                            Project Summary


                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/project/my-tasks">
                                            Open Tasks Assigned to me


                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/project/my-time">
                                            My Time
                                        </span>
                                    </div>
                                    <div class="article">
                                       
                                        <span style="cursor: pointer; color: #0000FF;" routerLink="/psa/project/projects" class="">
                                            Find Project
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/project/tasks-project">
                                            Find Task
                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/project/resources">
                                            Add a Resource


                                        </span>
                                    </div>

                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/project/timesheet-project">
                                            Find TimeSheet
                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/project/request-list">
                                            Accepted Customer Request list


                                        </span>
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/project/skill-type">
                                            Add Skill Type

                                        </span>
                                      
                                     
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div class="w3-card-4 classCard w-100" style="height: 190px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Work Effort </p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/work-effort/task-list">
                                            Work Effort Main

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/work-effort/task-list">
                                            View Activity and Task List

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/work-effort/calendar">
                                            Find Calendar Entries

                                        </span>
                                    </div>
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/work-effort/request-list-effort">
                                            My Current Timesheets

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/work-effort/request-list-effort">
                                            My Request List

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/work-effort/work-effort-effort">
                                            Work Effort Effort
                                        </span>
                                    </div>
                                    
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class="" routerLink="/psa/work-effort/timesheet-effort">
                                            Find Timesheet

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/work-effort/submitted-jobs">
                                            Submitted Jobs

                                        </span>
                                        <span style="cursor: pointer; color: #0000FF;" class="" routerLink="/psa/work-effort/icalendar">
                                            Find iCalendars

                                        </span>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
         
          

          
        </div>
       
       
    </div>
</div>    


<ngx-spinner></ngx-spinner>