import { environment } from './../environments/environment';

export const URLS = {
  loginApi: '/phyCorp/control/api/v1/auth/login',
  userList: '/phyCorp/control/api/v1/users',
  resetPassword: '/phyCorp/control/api/v1/users/resetPassword',
  addPaymentMethodToOrder: '/phyCorp/control/api/v1/order/addPaymentMethodToOrder',
  changePassword: '/phyCorp/control/api/v1/users/changePassword',
  contactList: '/phyCorp/control/api/v1/crm/contacts?',
  createContact: '/phyCorp/control/api/v1/crm/createContact',
  getState: '/phyCorp/control/api/v1/resources/states/accState',
  getCountry: '/phyCorp/control/api/v1/resources/countries',
  getDataByPartyId: '/phyCorp/control/api/v1/crm/contacts/partyId',
  updateContact: '/phyCorp/control/api/v1/crm/updateContact',
  getAccountsList: '/phyCorp/control/api/v1/crm/accounts',
  getAccountById: '/phyCorp/control/api/v1/crm/accounts/:partyId',
  getAccountByRelatedId: '/phyCorp/control/api/v1/crm/contacts/:partyId',
  getEmployementById: '/phyCorp/control/api/v1/hr/employee/employment/:partyId',
  createAccount: '/phyCorp/control/api/v1/crm/createAccount',
  updateAccount: '/phyCorp/control/api/v1/crm/updateAccount',
  crmMainPageData:'/phyCorp/control/api/v1/sfa/communicationEvents/lookup',
  getLeads: '/phyCorp/control/api/v1/crm/getLeads',
  exportContacts: '/phyCorp/control/api/v1/crm/contacts/exportContacts',
  exportAccounts: '/phyCorp/control/api/v1/crm/accounts/exportAccounts',
  getLeadDetails: '/phyCorp/control/api/v1/crm/getLeads/:personId',
  createLead: '/phyCorp/control/api/v1/crm/create/lead',
  getOppurtunityDto: '/phyCorp/control/api/v1/crm/getperson/:oppourtunityId',
  createOpportunity: '/phyCorp/control/api/v1/crm/createoppurtunity',
  createQuote: '/phyCorp/control/api/v1/crm/createQuote',
  getQuoteList: '/phyCorp/control/api/v1/crm/getquote',
  getQuote: '/phyCorp/control/api/v1/crm/getquote/:quoteId',
  getOppourtunityList: '/phyCorp/control/api/v1/crm/getopportunitylist',
  getOppourtunitySummary: '/phyCorp/control/api/v1/crm/getoppurtunity/:oppurtunityId',
  getCurrency: '/phyCorp/control/api/v1/resources/currency',
  updateQuote: '/phyCorp/control/api/v1/crm/update/:quoteId',
  updateProduct: '/phyCorp/control/api/v1/crm/:oppurId',
  createEvent: '/phyCorp/control/api/v1/crm/event',
  initialStage: '/phyCorp/control/api/v1/crm/marketingCampaignInitialStageSourceType',
  getEventList: '/phyCorp/control/api/v1/crm/event',
  eventDetail: '/phyCorp/control/api/v1/crm/event/:workEffortId',
  updateEvent: '/phyCorp/control/api/v1/crm/event/:workEffortId',
  deleteEvent: '/phyCorp/control/api/v1/crm/event/:workEffortId',
  searchOpportunity: '/phyCorp/control/api/v1/crm/searchOpportunity',
  advancedSearhContacts: '/phyCorp/control/api/v1/crm/contacts/search',
  getEventPurpose: '/phyCorp/control/api/v1/resources/eventPurpose',
  getEmployeesList: '/phyCorp/control/api/v1/hr/employee',
  getEmployeeById: '/phyCorp/control/api/v1/hr/employee/:partyId',
  createEmployee: '/phyCorp/control/api/v1/hr/employee',
  getEmplPositions: '/phyCorp/control/api/v1/hr/empPosition/getEmpPosition',
  createEmpPosition: '/phyCorp/control/api/v1/hr/empPosition',
  getEmplPositionById: '/phyCorp/control/api/v1/hr/empPosition/:empPositionId',
  getPersonsPartyId: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  updateEmployeePosition: '/phyCorp/control/api/v1/hr/empPosition/:empPositionId',
  getFulfillment: '/phyCorp/control/api/v1/hr/empPosition/:empPositionId',
  getResponsibilityTypeId: '/phyCorp/control/api/v1/hr/empPosition/getResponsibilityTypeId',
  getSkills: '/phyCorp/control/api/v1/hr/skills/getSkills',
  createSkills: '/phyCorp/control/api/v1/hr/skills',
  getSkillTypeList: '/phyCorp/control/api/v1/hr/skills/skillType',
  addSkills: '/phyCorp/control/api/v1/hr/skills',
  updateSkill: '/phyCorp/control/api/v1/hr/skills/updateSkill',
  deleteSkill: '/phyCorp/control/api/v1/hr/skills/:partyId/:skillTypeId',
  getPositionReportedTo: '/phyCorp/control/api/v1/hr/reportingStructure/reportedTo',
  getPositionReportsTo: '/phyCorp/control/api/v1/hr/reportingStructure/reportsTo',
  getJobRequisitions: '/phyCorp/control/api/v1/hr/recruitment/getJobRequisitions',
  deleteJobRequisition: '/phyCorp/control/api/v1/hr/recruitment/:jobRequisitionId',
  updateJobRequisition: '/phyCorp/control/api/v1/hr/recruitment/updateJobRequisitions',
  createJobRequisition: '/phyCorp/control/api/v1/hr/recruitment/createJob',
  getInternalJobPosting: '/phyCorp/control/api/v1/hr/internalJobPosting/getInternalJobPosting',
  createInternalJobPost: '/phyCorp/control/api/v1/hr/internalJobPosting',
  getInternPostingById: '/phyCorp/control/api/v1/hr/internalJobPosting/:applicationId',
  deleteInternalJobPost: '/phyCorp/control/api/v1/hr/internalJobPosting/:applicationId',
  getPersonsGroups: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  updateInternalJobPost: '/phyCorp/control/api/v1/hr/internalJobPosting/:applicationId',
  getDepartments: '/phyCorp/control/api/v1/hr/department/lookup',
  getAllAccounts: '/phyCorp/control/api/v1/crm/accounts',
  createDepartment: '/phyCorp/control/api/v1/hr/department',
  updateDepartment: '/phyCorp/control/api/v1/hr/department/:departmentId',
  deleteDepartment: '/phyCorp/control/api/v1/hr/department/',
  internalOrganization: '/phyCorp/control/api/v1/resources/internalOrganization',
  trainingApprovalList: '/phyCorp/control/api/v1/hr/training/assignTraining',
  overViewApproveTraining:'/phyCorp/control/api/v1/hr/training/trainingApproval',
  getEmployeeLeave: '/phyCorp/control/api/v1/hr/employeeLeave/search',
  createEmployeeLeave: '/phyCorp/control/api/v1/hr/employeeLeave',
  leaveTypeId: '/phyCorp/control/api/v1/hr/globalSettings/leaveType',
  leaveReasonType: '/phyCorp/control/api/v1/hr/globalSettings/leaveReasonType',
  deleteLeave: '/phyCorp/control/api/v1/hr/employeeLeave/api/v1/hr/employeeLeave/:partyId/:leaveTypeId/:fromDate',
  getQualification: '/phyCorp/control/api/v1/resources/getQualificationAndExamType',
  updateEmployeeLeave: '/phyCorp/control/api/v1/hr/employeeLeave/api/v1/hr/employeeLeave/:partyId/:leaveTypeId/:fromDate',
  quotoTypeId: '/phyCorp/control/api/v1/crm/productStoreSaleChannelQuoteTypeAndStatus',
  getTimeSheetList: '/phyCorp/control/api/v1/timesheet',
  createTimeSheet: '/phyCorp/control/api/v1/timesheet',
  getTimeSheetStatusList: '/phyCorp/control/api/v1/timesheet/status',
  deleteTimesheetRole: '/phyCorp/control/api/v1/timesheet/timesheetRole/:timesheetId/:roleTypeId/:partyId',
  updateTimeSheet: '/phyCorp/control/api/v1/timesheet/:timesheetId',
  updateTimeSheetwork: '/phyCorp/control/api/v1/workEffort/:timesheetId',
  getLeadSource: '/phyCorp/control/api/v1/resources/leadSource',
  assignDepartment: '/phyCorp/control/api/v1/hr/employee/assignDepartment/:partyId/:departmentId',
  deleteSkillType: '/phyCorp/control/api/v1/hr/skills/skillType/:skillTypeId',
  creaSkillType: '/phyCorp/control/api/v1/hr/skills/skillType',
  getJobInterviewTypes: '/phyCorp/control/api/v1/hr/globalSettings/jobInterviewType',
  createJobInterviewType: '/phyCorp/control/api/v1/hr/globalSettings/jobInterviewType',
  updateSkillType: '/phyCorp/control/api/v1/hr/skills/skillType/:skillTypeId',
  updateJobInterviewType: '/phyCorp/control/api/v1/hr/globalSettings/jobInterviewType/:jobInterviewTypeId',
  deleteJobInterviewType: '/phyCorp/control/api/v1/hr/globalSettings/jobInterviewType/:jobInterviewTypeId',
  getLeaveTypes: '/phyCorp/control/api/v1/hr/globalSettings/leaveType',
  createLeaveType: '/phyCorp/control/api/v1/hr/globalSettings/leaveType',
  updateLeaveType: '/phyCorp/control/api/v1/hr/globalSettings/leaveType/:leaveTypeId',
  deleteLeaveType: '/phyCorp/control/api/v1/hr/globalSettings/leaveType/:leaveTypeId',
  getLeaveReasonTypes: '/phyCorp/control/api/v1/hr/globalSettings/leaveReasonType',
  createLeaveReasonType: '/phyCorp/control/api/v1/hr/globalSettings/leaveReasonType',
  updateLeaveReasonType: '/phyCorp/control/api/v1/hr/globalSettings/leaveReasonType/:leaveReasonTypeId',
  deleteLeaveReasonType: '/phyCorp/control/api/v1/hr/globalSettings/leaveReasonType/:leaveReasonTypeId',
  EmploymentAppList: '/phyCorp/control/api/v1/hr/employmentApplication',
  getTrainingTypeList: '/phyCorp/control/api/v1/hr/trainingClassType',
  createTrainingType: '/phyCorp/control/api/v1/hr/trainingClassType',
  updateTrainingType: '/phyCorp/control/api/v1/hr/trainingClassType/:trainingClassTypeId',
  //       : '/phyCorp/control/api/v1/hr/globalSettings/positionType',
  deleteTrainingType: '/phyCorp/control/api/v1/hr/trainingClassType/:trainingClassTypeId',
  createPositionType: '/phyCorp/control/api/v1/hr/globalSettings/positionType',
  deletePositionType: '/phyCorp/control/api/v1/hr/globalSettings/positionType/:positionTypeId',
  updatePositionType: '/phyCorp/control/api/v1/hr/globalSettings/positionType/:positionTypeId',
  publicHolidays: '/phyCorp/control/api/v1/hr/globalSettings/publicHoliday',
  createPublicHolidays: '/phyCorp/control/api/v1/hr/globalSettings/publicHoliday',
  updatePublicHoliday: '/phyCorp/control/api/v1/hr/globalSettings/publicHoliday/:holidayId',
  deletePublicHoliday: '/phyCorp/control/api/v1/hr/globalSettings/publicHoliday/:holidayId',
  getRelocationList: '/phyCorp/control/api/v1/hr/internalJobPosting/relocation',
  getStatusIdsList: '/phyCorp/control/api/v1/hr/employmentApplication/statusIds',
  AppSourceTypeList: '/phyCorp/control/api/v1/hr/employmentApplication/employmentAppSourceTypes',
  addEmploymentApp: '/phyCorp/control/api/v1/hr/employmentApplication',
  updateEmpApplication: '/phyCorp/control/api/v1/hr/employmentApplication/:applicationId',
  delEmploymentApp: '/phyCorp/control/api/v1/hr/employmentApplication/:applicationId',
  getResponsibilityTypes: '/phyCorp/control/api/v1/hr/globalSettings/responsibilityType',
  addResponsibilityType: '/phyCorp/control/api/v1/hr/globalSettings/responsibilityType',
  updateResponType: '/phyCorp/control/api/v1/hr/globalSettings/responsibilityType/:responsibilityTypeId',
  deleteResponsType: '/phyCorp/control/api/v1/hr/globalSettings/responsibilityType/:responsibilityTypeId',
  getRelocationDetails: '/phyCorp/control/api/v1/hr/internalJobPosting/relocation',
  getTrainingDetail: '/phyCorp/control/api/v1/hr/training/trainingApproval',

  ValidResponsibility: '/phyCorp/control/api/v1/hr/responsibilities',
  delValidResponsibility: '/phyCorp/control/api/v1/hr/responsibilities/:responsibilityTypeId/:emplPositionTypeId/:fromDate',
  createResponsibility: '/phyCorp/control/api/v1/hr/responsibilities',
  personsAndPartyGroups: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  updateResponsibility: '/phyCorp/control/api/v1/hr/responsibilities',
  catalogList: '/phyCorp/control/api/v1/catalog/productCatalog',
  getProductCatalogs: '/phyCorp/control/api/v1/catalog/productCatalog',
  getProductCatalogsById: '/phyCorp/control/api/v1/catalog/productCatalog/:catalogId',
  getProductCategoryById: '/phyCorp/control/api/v1/catalog/categories/:productCategoryId',
  ProductCategoryTypeList: '/phyCorp/control/api/v1/catalog/categories/productCategoryType',
  createProductCategory: '/phyCorp/control/api/v1/catalog/categories',
  createProductCatalog: '/phyCorp/control/api/v1/catalog/productCatalog',
  updateProductCatalog: '/phyCorp/control/api/v1/catalog/productCatalog',
  createContentToCategory: '/phyCorp/control/api/v1/catalog/contentToCategory',
  updateCategory: '/phyCorp/control/api/v1/catalog/categories/:productCategoryId',
  getProductCategoryList: '/phyCorp/control/api/v1/catalog/categories',
  getStatus: '/phyCorp/control/api/v1/resources/emplPosition',
  getPositionTypes: '/phyCorp/control/api/v1/hr/globalSettings/positionType',
  emplPosition: '/phyCorp/control/api/v1/resources/emplPosition',
  getJobInterview: '/phyCorp/control/api/v1/hr/jobInterview/getJobInterview',
  createJobInterview: '/phyCorp/control/api/v1/hr/jobInterview',
  JobInterviewParameters: '/phyCorp/control/api/v1/resources/jobInterviewParameters',
  getJobInterviewById: '/phyCorp/control/api/v1/hr/jobInterview/:jobInterviewId',
  updateJobInterview: '/phyCorp/control/api/v1/hr/jobInterview/:jobInterviewId',
  getProductCatalogStores: '/phyCorp/control/api/v1/catalog/productCatalog/store/:catalogId',
  storeDropdown: '/phyCorp/control/api/v1/crm/productStoreSaleChannelQuoteTypeAndStatus',
  createCatalogStore: '/phyCorp/control/api/v1/catalog/productCatalog/store/:catalogId',
  updateCatalogStore: '/phyCorp/control/api/v1/catalog/productCatalog/store/:catalogId',
  deleteCatalogStore: '/phyCorp/control/api/v1/catalog/productCatalog/store/:catalogId/:productStoreId/:fromDate',
  uploadContacts: '/phyCorp/control/api/v1/crm/contacts/import',
  assignContact: '/phyCorp/control/api/v1/crm/contacts/assignContact',
  importAccount: '/phyCorp/control/api/v1/crm/accounts/import',
  createContactNote: '/phyCorp/control/api/v1/crm/contacts/partyNote/:partyId',
  deleteContactNote: '/phyCorp/control/api/v1/crm/contacts/partyNote/:noteId',
  updateContactNote: '/phyCorp/control/api/v1/crm/contacts/partyNote/:noteId',
  getContactNotes: '/phyCorp/control/api/v1/crm/contacts/partyNote/:partyId',
  contactInformation: '/phyCorp/control/api/v1/resources/contactList',
  RelatedAccountContact: '/phyCorp/control/api/v1/crm/contactRelatedAccount',
  getContactList: '/phyCorp/control/api/v1/resources/contactList',
  WorkEffortsAssignedToMe: '/phyCorp/control/api/v1/crm/event/assignedToMe',
  WorkEffortsAssignedByMe: '/phyCorp/control/api/v1/crm/event/assignedByMe',
  getWorkEffortsSummary: '/phyCorp/control/api/v1/crm/:workEffortId',
  updateLeadPartyId: '/phyCorp/control/api/v1/crm/lead/partyGroup/:partyId',
  updatePerson: '/phyCorp/control/api/v1/crm/lead/partyGroup/:partyId',
  updateEmailAddress: '/phyCorp/control/api/v1/hr/employee/email',
  updatePostalAddress: '/phyCorp/control/api/v1/hr/employee/postalAddress',
  updateTelocomNumber: '/phyCorp/control/api/v1/hr/employee/teleNumber',
  partyContentDropdown: '/phyCorp/control/api/v1/resources/contentPurposeRoleType',
  attachPartyContent: '/phyCorp/control/api/v1/crm/partyContent',
  getPartyContentList: '/phyCorp/control/api/v1/crm/partyContent/:partyId',
  createLeadSource: '/phyCorp/control/api/v1/crm/leadSource',
  getLeadSourceList: '/phyCorp/control/api/v1/crm/leadSource',
  getMergeContacts: '/phyCorp/control/api/v1/crm/mergeContacts',
  getCRMStatus: '/phyCorp/control/api/v1/resources/CRMStatus',
  convertLead: '/phyCorp/control/api/v1/crm/convertLead',
  mergeContacts: '/phyCorp/control/api/v1/crm/mergeContacts',
  closeOpportunity: '/phyCorp/control/api/v1/crm/closeOpportunity/:opportunityId',
  createCalendarEvent: '/phyCorp/control/api/v1/hr/training',
  getCalendarEventList: '/phyCorp/control/api/v1/hr/training',
  completeEvent: '/phyCorp/control/api/v1/crm/event/completeEvent/:workEffortId',
  EmployeeLeaveApprovals: '/phyCorp/control/api/v1/hr/employeeLeave/leaveApprovals',
  particularEmployeeLeave: '/phyCorp/control/api/v1/hr/employeeLeave/api/v1/hr/employeeLeave/:partyId/:leaveTypeId/:fromDate',
  updateEmployeeLead: '/phyCorp/control/api/v1/hr/employee/:partyId',
  updateContactInfo: '/phyCorp/control/api/v1/crm/updateContact',
  getPartyContentLists: '/phyCorp/control/api/v1/crm/partyContent/:partyId',
  deletePartyContent: '/phyCorp/control/api/v1/crm/partyContent',
  downloadPartyContent: '/phyCorp/control/api/v1/crm/partyContent/view',
  getFacilityGroupParentGroupId: '/phyCorp/control/api/v1/facility/facilityGroup/parentGroups',
  FacilityGroupList: '/phyCorp/control/api/v1/facility/facilityGroup',
  getFacilityGroupTypeId: '/phyCorp/control/api/v1/facility/facilityGroup/facilityGroupTypes',
  createFacilityGroup: '/phyCorp/control/api/v1/facility/facilityGroup',
  inventoryItemLabelList: '/phyCorp/control/api/v1/facility/inventoryItemLabel',
  getInventoryItemLabelTypeId: '/phyCorp/control/api/v1/facility/inventoryItemLabel/inventoryItemLabelTypes',
  createInventoryItemLabel: '/phyCorp/control/api/v1/facility/inventoryItemLabel',
  getInventoryItemLabelTypeList: '/phyCorp/control/api/v1/facility/inventoryItemLabelType',
  getParentTypeId: '/phyCorp/control/api/v1/facility/inventoryItemLabelType/parentTypeId',
  createInventoryItemLabelType: '/phyCorp/control/api/v1/facility/inventoryItemLabelType',
  getShipmentTypeIdList: '/phyCorp/control/api/v1/facility/shipment/shipmentTypes',
  getStatusId: '/phyCorp/control/api/v1/facility/shipment/shipmentStatusIds',
  getFacilityShipments: '/phyCorp/control/api/v1/facility/shipment/search',
  getInvoices: '/phyCorp/control/api/v1/ar/invoice/search',
  getOrderIds: '/phyCorp/control/api/v1/facility/inventoryItem/orderIds',
  createFacilityShipment: '/phyCorp/control/api/v1/facility/shipment',
  updateFacilityShipment: '/phyCorp/control/api/v1/facility/shipment',
  getReasonVarainceId: '/phyCorp/control/api/v1/facility/physicalInventoryVariance/varianceReasons',
  getReturnViewOrder: '/phyCorp/control/api/v1/facility/receiveReturn',
  updatePhysiaclInventory: '/phyCorp/control/api/v1/facility/physicalInventoryVariance',
  getFacilityShipmentByIdView: '/phyCorp/control/api/v1/facility/shipment/view',
  generateShipmentManifestPdf: '/phyCorp/control/api/v1/facility/shipment/shipmentManifestPdf',
  createAgreementAccnAR: '/phyCorp/control/api/v1/accounting/agreement',
  getProducts: '/phyCorp/control/api/v1/catalog/product/products',
  getRoleTypes: '/phyCorp/control/api/v1/catalog/carrierShipmentMethod/roleType',

  getPayments: '/phyCorp/control/api/v1/accounting/payments/search',
  getPaymentMethodType: '/phyCorp/control/api/v1/accounting/payments/paymentMethodType',
  createIncomingPayment: '/phyCorp/control/api/v1/accounting/payments',
  getPaymentDataByPymntId: '/phyCorp/control/api/v1/accounting/payments/:paymentId',
  getAgreementTermList: '/phyCorp/control/api/v1/accounting/agreementTerms',
  getTermTypeIdList: '/phyCorp/control/api/v1/accounting/agreementTerms/termTypeIds',
  getInvoiceItemTypeList: '/phyCorp/control/api/v1/accounting/agreementTerms/invoiceItemTypes',
  createAgreementTerm: '/phyCorp/control/api/v1/accounting/agreementTerms',
  getListEmployee: '/phyCorp/control/api/v1/hr/employee/lookup',
  getExportEmployee: '/phyCorp/control/api/v1/hr/employee/export',
  postImportEmployee: '/phyCorp/control/api/v1/hr/employee/import',
  getMyContactList: '/phyCorp/control/api/v1/crm/contacts/myContact',
  importContact: '/phyCorp/control/api/v1/crm/contacts/import',
  updateEmployeePersonal: '/phyCorp/control/api/v1/hr/employee/:partyId',
  updatePostalAddres: '/phyCorp/control/api/v1/hr/employee/postalAddress',
  exportInventory: '/phyCorp/control/api/v1/facility/inventory/inventoryItemTotalsExport?',

  getMyAccountRelated: '/phyCorp/control/api/v1/crm/accounts',
  postMyAccountRelated: '/phyCorp/control/api/v1/crm/contactRelatedAccount',
  getAccountRelatedInfo: '/phyCorp/control/api/v1/crm/contacts/partyId',
  getLeadByPartyId: '/phyCorp/control/api/v1/crm/getLeads/:partyId',
  getTelephoneNumberPartyId: '/phyCorp/control/api/v1/hr/employee/teleNumber/contactMechId ',
  updateTelephoneNumberPartyId: '/phyCorp/control/api/v1/hr/employee/teleNumber',
  getEmailPartyId: '/phyCorp/control/api/v1/hr/employee/contactMechId ',
  updateEmailPartyId: '/phyCorp/control/api/v1/hr/employee/email',
  getAccountByPartyId: '/phyCorp/control/api/v1/crm/accounts/:partyId',
  createPositionFullfillment: '/phyCorp/control/api/v1/hr/empPosition/positionFulfill/empPositionId',
  deletePositionFullfillment: '/phyCorp/control/api/v1/hr/empPosition/positionFulfill/empPositionId/partyId',
  getMyPartyGroupID: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  getEmpPositionResponsibility: '/phyCorp/control/api/v1/hr/empPosition/responsibilityTypeId',
  createPositionResponsibility: '/phyCorp/control/api/v1/hr/empPosition/emplPositionRes/emplPositionId',
  deletePositionResponsibility: '/phyCorp/control/api/v1/hr/empPosition/emplPositionRes/emplPositionId/responsibilityTypeId',
  getProductsList: '/phyCorp/control/api/v1/catalog/product/products',
  getOrderList: '/phyCorp/control/api/v1/order/listItems',
  getPartyIdData: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  getBillingAccountData: '/phyCorp/control/api/v1/accounting/billingAccount/search',
  getSalesOrderList: '/phyCorp/control/api/v1/order/salesChannel',
  getProductCatalogStoresList: '/phyCorp/control/api/v1/order/listItems',
  getStatusList: '/phyCorp/control/api/v1/resources/statusByType',
  getUserLogin: '/phyCorp/control/api/v1/resources/userloginIds',
  getCountryList: '/phyCorp/control/api/v1/resources/countries',
  getShippingMethods: '/phyCorp/control/api/v1/catalog/carrierShipmentMethod',
  findOrdersList: '/phyCorp/control/api/v1/order/lookup',
  existingContentIds: '/phyCorp/control/api/v1/sfa/communicationEvents/content/existingContentIds',
  getCustomerList: '/phyCorp/control/api/v1/order/findOrder/customerSupplier',
  getCurrencyData: '/phyCorp/control/api/v1/resources/currency',
  getCatalogue: '/phyCorp/control/api/v1/catalog/productCatalog/catalogs',
  getWorkEffortList:'/phyCorp/control/api/v1/crm/event',
  initorderentry:'/phyCorp/control/api/v1/order/initorderentry',
  clearCart:'/phyCorp/control/api/v1/order/clearOrder',
  addToOrder:'/phyCorp/control/api/v1/order/orderItems',
  createPositionReportStructureTo: '/phyCorp/control/api/v1/hr/reportingStructure/reportsTo',
  createPositionReportStructureBy: '/phyCorp/control/api/v1/hr/reportingStructure/reportedTo',
  statusJob: '/phyCorp/control/api/v1/hr/internalJobPosting/jobApprovalStatus',
  deleteInterviewJob: '/phyCorp/control/api/v1/hr/jobInterview/jobInterviewId',
  getLeaveStatus: '/phyCorp/control/api/v1/hr/employeeLeave/leaveStatus',
  getApplicationEmployeeList: '/phyCorp/control/api/v1/hr/employmentApplication/search',
  getApplicationTimesheetList: '/phyCorp/control/api/v1/timesheet/search',
  getRoleList: '/phyCorp/control/api/v1/timesheet/role',
  postRole: '/phyCorp/control/api/v1/timesheet/timesheetRole',
  getRole: '/phyCorp/control/api/v1/timesheet/timesheetRole/timesheetId',
  deleteRole: '/phyCorp/control/api/v1/timesheet/timesheetRole/timesheetId/roleTypeId/partyId',
  getTraining: '/phyCorp/control/api/v1/hr/training/trainingApproval',
  getTrainingClassType: '/phyCorp/control/api/v1/hr/trainingClassType',
  getReturnHeader: '/phyCorp/control/api/v1/order/return/returnHeaderTypeIds',
  getReturnStatusId: '/phyCorp/control/api/v1/order/return/statusIds',
  createOrderReturn: '/phyCorp/control/api/v1/order/return',
  getDestinationFacility: '/phyCorp/control/api/v1/facility',
  getOrderReturn: '/phyCorp/control/api/v1/order/return/search',
  updateReturnOrder: '/phyCorp/control/api/v1/order/return/returnId',
  getOrderReturnHistory: '/phyCorp/control/api/v1/order/return/returnHistory',
  generatePdfOrderReturnHistory: '/phyCorp/control/api/v1/order/return/generatePdf',
  getReasonItemStatus: '/phyCorp/control/api/v1/order/return/reasonAndTypeAndItemStatus',
  getOrderDropdown: '/phyCorp/control/api/v1/order/return/returnItems',
  getOrderAnnualReturnHistory:'/phyCorp/control/api/v1/order/return/loadOrderItemsForReturn',
  postReturnItemList:'/phyCorp/control/api/v1/order/return/createReturnItems',
  deleteAdjustmentId:'/phyCorp/control/api/v1/order/return/removeReturnAdjustment',
  deleteRemoveItem:'/phyCorp/control/api/v1/order/return/removeReturnItem',
  returnAccepted:'/phyCorp/control/api/v1/order/return/acceptReturn',
  getUserDetails:'/phyCorp/control/api/v1/users/lookup',
  createUser:'/phyCorp/control/api/v1/users',
  findGroupId:'/phyCorp/control/api/v1/securityGroup/lookup',
  importUserData:'/phyCorp/control/api/v1/users/importUsers',
  getPartyRole:'/phyCorp/control/api/v1/partyRoles',
  deleteRoleTypeId:'/phyCorp/control/api/v1/partyRoles',
  getRoleMainType:'/phyCorp/control/api/v1/partyRoles/roleTypes',
  getRoleParentTypeID:'/phyCorp/control/api/v1/partyRoles/roleTypes/:parentTypeId',
  postRoleTypeSave:'/phyCorp/control/api/v1/partyRoles',
  postRoleTypeData:'/phyCorp/control/api/v1/partyRoles/roleTypes',
  getSecurityGroupList:'/phyCorp/control/api/v1/securityGroup/lookup',
  saveSecurityGroup:'/phyCorp/control/api/v1/securityGroup',
  getAllPermision:'/phyCorp/control/api/v1/securityGroup/permissions',
  getSecurityGroupPermissionApi:'/phyCorp/control/api/v1/securityGroup/permissions/:securityGroupId',
  getExportUser:'/phyCorp/control/api/v1/users/exportUsers',
  getUserProfile:'/phyCorp/control/api/v1/users/profile',
  getFinancailAccount:'/phyCorp/control/api/v1/accounting/financialAccount/search',
  deleteFinancailAccount:'/phyCorp/control/api/v1/accounting/financialAccount/:finAccountId ',
  getFinAccountTypeList:'/phyCorp/control/api/v1/accounting/financialAccount/finAccountType',
  getFinAccountStatusList:'/phyCorp/control/api/v1/accounting/financialAccount/statusIds',
  postFinancialAccount:'/phyCorp/control/api/v1/accounting/financialAccount',
  updateFinancailAccount: '/phyCorp/control/api/v1/accounting/financialAccount/:finAccountId',
  getGioAccountId: '/phyCorp/control/api/v1/accountingPayable/outgoingPayment/glAccountsAndPaymentMethod',
  getFinancialRoleAccount:'/phyCorp/control/api/v1/accounting/financialAccount/role',
  getFinancialAccountReconciliations:'/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/search',
  getFinancialAccountIntialData:'/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/initialData',
  createReconciliation:'/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/:finAccountId',
  getReconciliationSummary:'/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/summary/',
  removeTransFromReconciliation:'/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/finTrans',
  cancelReconciliation:'/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/cancelReconciliation',
  searchFinancialTransaction:'/phyCorp/control/api/v1/accounting/financialAccount/transaction/search',
  financialTransactionStatus:'/phyCorp/control/api/v1/accounting/financialAccount/transaction/statusIds',
  finAccountTransTypeId:'/phyCorp/control/api/v1/accounting/financialAccount/transaction/finAccountTransTypeId',
  getTransglReconciliationID:'/phyCorp/control/api/v1/accounting/financialAccount/transaction/glReconciliationID',
  reconcileAll:'/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/reconcileAll', 
  createFinancialAccountTransaction:'/phyCorp/control/api/v1/accounting/financialAccount/transaction',
  financialOrderId:'/phyCorp/control/api/v1/order/lookup',
  postDepositOrWithdraw:'/phyCorp/control/api/v1/accounting/financialAccount/depositOrWithdraw/:finAccountId',
  depositOrWithdrawInitailData:'/phyCorp/control/api/v1/accounting/financialAccount/depositOrWithdraw/initialData',
  cancelFinancialAccountTransaction:'/phyCorp/control/api/v1/accounting/financialAccount/transaction/:finAccountTransId',   
  searchDepositOrWithdraw:'/phyCorp/control/api/v1/accounting/financialAccount/depositOrWithdraw/search',
  searchDepositSlip:'/phyCorp/control/api/v1/accounting/financialAccount/depositOrWithdraw/depositSlip/lookup',
  searchFixedAssets:'/phyCorp/control/api/v1/accounting/fixedAssets/search',
  fixedAssetsInitialData:'/phyCorp/control/api/v1/accounting/fixedAssets/initialData',
  getglobalGLSetting:'/phyCorp/control/api/v1/accounting/globalGLSetting/contacts',
  getCustomTimePeriod:'/phyCorp/control/api/v1/accounting/customTimePeriod',
  postCustomTimePeriod:'/phyCorp/control/api/v1/accounting/customTimePeriod',
  getCustomTimePeriodDropdownValue:'/phyCorp/control/api/v1/accounting/customTimePeriod/initialData',
  deleteCustomTimePeriod:'/phyCorp/control/api/v1/accounting/customTimePeriod/:customTimePeriodId',
  getGlAccountInitialData:'/phyCorp/control/api/v1/accounting/globalGLSetting/initialData',
  postChartAccountData:'/phyCorp/control/api/v1/accounting/globalGLSetting',
  downloadPDFChartAccountData:'/phyCorp/control/api/v1/accounting/globalGLSetting/generatePdf',
  assignGLAccount:'/phyCorp/control/api/v1/accounting/globalGLSetting/assignGLAccount',
  getGlCost:'/phyCorp/control/api/v1/accounting/cost',
  deleteGlCost:'/phyCorp/control/api/v1/accounting/cost/:costComponentCalcId',
  getInitialDataCost:'/phyCorp/control/api/v1/accounting/cost/initialData',
  getInvoiceItemType:'/phyCorp/control/api/v1/accounting/globalGLSetting/invoiceItemTypes',
  getPaymentMethodTypeGlSetting:'/phyCorp/control/api/v1/accounting/globalGLSetting/paymentMethodType',
  updatePaymentMethodTypeGlSetting:'/phyCorp/control/api/v1/accounting/globalGLSetting/paymentMethodType',
  getGlSetingsRateList:'/phyCorp/control/api/v1/accounting/rateAmount',
  deleteGlSetingsRateList:'/phyCorp/control/api/v1/accounting/rateAmount/expireRateAmount',
  getRatesInitialData:'/phyCorp/control/api/v1/accounting/rateAmount/initialData',
  getForeignExchangeRates:'/phyCorp/control/api/v1/accounting/rateAmount/foreignExchangeRate',
  getGlAccountCategories:'/phyCorp/control/api/v1/accounting/glAccountCategory/search',
  getGlAccountCategoriesInitialData:'/phyCorp/control/api/v1/accounting/glAccountCategory/initialData',
  postGlAccountCategories:'/phyCorp/control/api/v1/accounting/glAccountCategory',
  getCostCenters:'/phyCorp/control/api/v1/accounting/cost/costCenter',
  postCostCenters:'/phyCorp/control/api/v1/accounting/cost/costCenter',
  batchDepositWithdrawPayments:'/phyCorp/control/api/v1/accounting/financialAccount/depositOrWithdraw/batchDepositWithdraw',
  getAssignedAccount:'/phyCorp/control/api/v1/accounting/globalGLSetting/assignedGlAccount/:organizationPartyId',
  glAccountCategoryMember:'/phyCorp/control/api/v1/accounting/glAccountCategory/glAccountCategoryMember/:glAccountCategoryId',
  deleteglAccountCategoryMember:'/phyCorp/control/api/v1/accounting/glAccountCategory/glAccountCategoryMember/:glAccountCategoryId',
  getVendors:'/phyCorp/control/api/v1/accounting/vendor/search',
  getVendorsInitialdata:'/phyCorp/control/api/v1/accounting/vendor/initialData',
  postVendors:'/phyCorp/control/api/v1/accounting/vendor',
  updateVendors:'/phyCorp/control/api/v1/accounting/vendor/updateVendor',
  getVendorListById:'/phyCorp/control/api/v1/accounting/vendor/:partyId',
  getVendorPartyIdListById:'/phyCorp/control/api/v1/accounting/vendor/vendorParty/:partyId',
  getSupplierByProductId:'/phyCorp/control/api/v1/catalog/productCatalog/supplier/:productId',
  getSupplierProductInitialData:'/phyCorp/control/api/v1/catalog/productCatalog/supplier/initialData',
  quantitySuppierUomId:'/phyCorp/control/api/v1/catalog/product/menues',
  removeProductSupplier:'/phyCorp/control/api/v1/catalog/productCatalog/supplier/remove',
  editSupplierProductInitialData:'/phyCorp/control/api/v1/catalog/productCatalog/supplier/summary',
  getNavigateAccount:'/phyCorp/control/api/v1/accounting/globalGLSetting/navigateAccount',
  getGlAccountSummary:'/phyCorp/control/api/v1/accounting/globalGLSetting/:glAccountId',
  getCrmListDetail:'/phyCorp/control/api/v1/sfa/communicationEvents/:partyId',
  getCrmOverViewDetail:'/phyCorp/control/api/v1/sfa/communicationEvents/overview/:communicationEventId',
  getCrmInitialCommData:'/phyCorp/control/api/v1/sfa/communicationEvents/initialData',
  getCrmEventPurpose:'/phyCorp/control/api/v1/sfa/communicationEvents/purposeType',
  getCrmEventPurposeListData:'/phyCorp/control/api/v1/sfa/communicationEvents/purpose/:communicationEventId',
  getCrmEventRole:'/phyCorp/control/api/v1/sfa/communicationEvents/role/:communicationEventId',
  getCrmEventRoleTypeList:'/phyCorp/control/api/v1/sfa/communicationEvents/role/type',
  postCommunicationNew:'/phyCorp/control/api/v1/sfa/communicationEvents',
  getCommunicationEventByOrder:'/phyCorp/control/api/v1/sfa/communicationEvents/return/communicationEventByReturn',
  userEnableDisable:'/phyCorp/control/api/v1/users/disableUser',
  getCommunicationByOrder:'/phyCorp/control/api/v1/sfa/communicationEvents/communicationEventByOrder',
  contactEventMech:'/phyCorp/control/api/v1/sfa/communicationEvents/contactMechData',
  getCrmEventProductList:'/phyCorp/control/api/v1/sfa/communicationEvents/product/:communicationEventId',
  getCrmEventReturnList:'/phyCorp/control/api/v1/sfa/communicationEvents/return/:communicationEventId',
  getCrmEventOrderList:'/phyCorp/control/api/v1/sfa/communicationEvents/order/:communicationEventId',
  getCrmEventContentList:'/phyCorp/control/api/v1/sfa/communicationEvents/content/:communicationEventId',
  getOrganizationDropdown: '/phyCorp/control/api/v1/company/allOrganisationId',
  postOrganization: '/phyCorp/control/api/v1/company',
  getOrganizationMain: '/phyCorp/control/api/v1/company/allCompanies',
  getOrganizationMainChartOfAccount: '/phyCorp/control/api/v1/company/glAccountOrganisation',
  getJournal: '/phyCorp/control/api/v1/company/journal/getGlJournal',
  removeJournal: '/phyCorp/control/api/v1/company/journal/removeGlJournal',
  postJournal: '/phyCorp/control/api/v1/company/journal/createGlJournal',
  getListGlAccountDefault: '/phyCorp/control/api/v1/company/accountTypeDefault/getAccountTypeDefault',
  removeGlAccountTypeDefault: '/phyCorp/control/api/v1/company/accountTypeDefault/removeGlAccountTypeDefault',
  getInitialDataAccountTypeDefault: '/phyCorp/control/api/v1/company/accountTypeDefault/getInitialDataAccountTypeDefault',
  createGlAccountTypeDefault: '/phyCorp/control/api/v1/company/accountTypeDefault/createGlAccountTypeDefault',
  getAccountCompanyInitialData: '/phyCorp/control/api/v1/company/initialData',
  getAccountCompanyDeatilById: '/phyCorp/control/api/v1/company/detailCompany',
  updateOrganization: '/phyCorp/control/api/v1/company/updateAccountCompany',
  postAssignGLAccount: '/phyCorp/control/api/v1/company/assignGLAccount',
  updateJournal: '/phyCorp/control/api/v1/company/journal/updateGlJournal',
  getAllDocTypeTemplate: '/phyCorp/control/api/v1/company/journal/allDocTypeTemplate',
  postAllDocTypeTemplate: '/phyCorp/control/api/v1/company/journal/createDocTypeTemplate',
  expirePartyPrefDocTypeTp: '/phyCorp/control/api/v1/company/journal/expirePartyPrefDocTypeTpl',
  getAllDocTypeTemplateInitialData: '/phyCorp/control/api/v1/company/journal/initialDataDocTypeTemplate',
  getProductGlAccount: '/phyCorp/control/api/v1/company/productGlAccount/productGlAccount',
  postProductGlAccount: '/phyCorp/control/api/v1/company/productGlAccount/createProductGlAccount',
  removeProductGlAccount: '/phyCorp/control/api/v1/company/productGlAccount/removeProductGlAccount',
  getProductGlAccountById: '/phyCorp/control/api/v1/company/productGlAccount/detailProductGlAccount',
  updateProductGlAccount: '/phyCorp/control/api/v1/company/productGlAccount/updateProductGlAccount',
  getProductCategoryGlAccount: '/phyCorp/control/api/v1/company/productCategoryGlAccount',
  postProductCategoryGlAccount: '/phyCorp/control/api/v1/company/productCategoryGlAccount/createProductCategoryGiAccount',
  removeProductCategoryGlAccount: '/phyCorp/control/api/v1/company/productCategoryGlAccount/deleteProductCategoryGlAccount',
  updateProductCategoryGlAccount: '/phyCorp/control/api/v1/company/productCategoryGlAccount/updateProductCategoryGiAccount',
  getFinAccountTypeGiAccount: '/phyCorp/control/api/v1/company/finAccountTypeGiAccount',
  getFinAccountTypeGiAccountInitialData: '/phyCorp/control/api/v1/company/finAccountTypeGiAccount/finAccountTypeId',
  createFinAccountTypeGiAccount: '/phyCorp/control/api/v1/company/finAccountTypeGiAccount/createFinAccountTypeGlAccount',
  removeFinAccountTypeGiAccount: '/phyCorp/control/api/v1/company/finAccountTypeGiAccount/deleteFinAccountTypeGlAccount',
  updateFinAccountTypeGiAccount: '/phyCorp/control/api/v1/company/finAccountTypeGiAccount/updateFinAccountTypeGlAccount',
  getGlAccountSalInvoice: '/phyCorp/control/api/v1/company/saleInvoice',
  getpaymentType: '/phyCorp/control/api/v1/company/paymentType',
  deletepaymentType: '/phyCorp/control/api/v1/company/paymentType/removePaymentTypeGlAssignments',
  getpaymentTypeInitialData: '/phyCorp/control/api/v1/company/paymentType/paymentTypeInitialData',
  postpaymentType: '/phyCorp/control/api/v1/company/paymentType/addPaymentTypeGlAssignment',
  getGlAccountMethodTypePaymentType: '/phyCorp/control/api/v1/company/paymentMethodType',
  removeGlAccountMethodTypePaymentType: '/phyCorp/control/api/v1/company/paymentMethodType/removePaymentMethodTypeGlAssignments',
  postGlAccountMethodTypePaymentType: '/phyCorp/control/api/v1/company/paymentMethodType/addPaymentMethodTypeGlAssignments',
  getVarianceReasonGlAccounts: '/phyCorp/control/api/v1/company/varianceReasonGlAccount',
  deleteVarianceReasonGlAccounts: '/phyCorp/control/api/v1/company/varianceReasonGlAccount/deleteVarianceReasonGlAccounts',
  getVarianceReasonGlAccountsInitialData: '/phyCorp/control/api/v1/company/varianceReasonGlAccount/allVarianceReason',
  postVarianceReasonGlAccounts: '/phyCorp/control/api/v1/company/varianceReasonGlAccount/createVarianceReasonGlAccounts',
  updateVarianceReasonGlAccounts: '/phyCorp/control/api/v1/company/varianceReasonGlAccount/updateVarianceReasonGlAccounts',
  getCreditCardTypeGlAccounts: '/phyCorp/control/api/v1/company/creditCardTypeGlAccounts',
  deleteCreditCardTypeGlAccounts: '/phyCorp/control/api/v1/company/creditCardTypeGlAccounts/deleteCreditCardTypeGlAccounts',
  getCardTypeAccounts: '/phyCorp/control/api/v1/crm/paymentMethods/creditCard/types',
  createCreditCardTypeGlAccounts: '/phyCorp/control/api/v1/company/creditCardTypeGlAccounts/createCreditCardTypeGlAccounts',
  updateCreditCardTypeGlAccounts: '/phyCorp/control/api/v1/company/creditCardTypeGlAccounts/updateCreditCardTypeGlAccounts',
  getTaxAuthorityGlAccount: '/phyCorp/control/api/v1/company/taxAuthorityGlAccount',
  deleteOrganizationTaxAuthorityGlAccounts: '/phyCorp/control/api/v1/company/taxAuthorityGlAccount/deleteOrganizationTaxAuthorityGlAccounts',
  taxAuthorityGlAccountInitialFormData: '/phyCorp/control/api/v1/company/taxAuthorityGlAccount/taxAuthorityGlAccountInitialFormData',
  createOrganizationTaxAuthorityGlAccount: '/phyCorp/control/api/v1/company/taxAuthorityGlAccount/createOrganizationTaxAuthorityGlAccounts',
  updateOrganizationTaxAuthorityGlAccounts: '/phyCorp/control/api/v1/company/taxAuthorityGlAccount/updateOrganizationTaxAuthorityGlAccounts',
  getPartyGlAccount: '/phyCorp/control/api/v1/company/partyGlAccount',
  deletePartyGlAccount: '/phyCorp/control/api/v1/company/partyGlAccount/deletePartyGlAccounts',
  allPartyGlAccountFormData: '/phyCorp/control/api/v1/company/partyGlAccount/allPartyGlAccountFormData',
  createPartyGlAccounts: '/phyCorp/control/api/v1/company/partyGlAccount/createPartyGlAccounts',
  updatePartyGlAccounts: '/phyCorp/control/api/v1/company/partyGlAccount/updatePartyGlAccounts',
  fixedAssetTypeGlAccount: '/phyCorp/control/api/v1/company/fixedAssetTypeGlAccount',
  deleteFixedAssetTypeGlAccounts: '/phyCorp/control/api/v1/company/fixedAssetTypeGlAccount/deleteFixedAssetTypeGlAccounts',
  allOpenCustomTimePeriodId: '/phyCorp/control/api/v1/company/timePeriod/allOpenCustomTimePeriodId',
  allCloseCustomTimePeriodId: '/phyCorp/control/api/v1/company/timePeriod/allCloseCustomTimePeriodId',
  closeCustomTimePeriods: '/phyCorp/control/api/v1/company/timePeriod/closeCustomTimePeriods',
  initialDataTimePeriod: '/phyCorp/control/api/v1/company/timePeriod/initialDataTimePeriod',
  createCustomTimePeriods: '/phyCorp/control/api/v1/company/timePeriod/createCustomTimePeriods',
  removeInvoiceItemTypeGlAssignment: '/phyCorp/control/api/v1/company/saleInvoice/invoiceItemTypeGlAssignment',
  getInvoiceItemTypeListApiData: '/phyCorp/control/api/v1/company/saleInvoice/invoiceItemTypes',
  getInvoiceItemTypeInitialData: '/phyCorp/control/api/v1/company/saleInvoice/glAccountId',
  addSalInvoiceItemTypeGlAssignments: '/phyCorp/control/api/v1/company/saleInvoice/addSalInvoiceItemTypeGlAssignments',
  getSalPurchaseItemTypeGlAssignment: '/phyCorp/control/api/v1/company/purchaseInvoice',
  addPurInvoiceItemTypeGlAssignments: '/phyCorp/control/api/v1/company/purchaseInvoice/addPurInvoiceItemTypeGlAssignments',
  getInitialDataFixedData: '/phyCorp/control/api/v1/company/fixedAssetTypeGlAccount/initialData',
  createFixedAssetTypeGlAccounts: '/phyCorp/control/api/v1/company/fixedAssetTypeGlAccount/createFixedAssetTypeGlAccounts',
  findReconciliationsAccount: '/phyCorp/control/api/v1/orgGlSettings/accounting/reconcialation',
  PostfindReconciliationsAccount: '/phyCorp/control/api/v1/orgGlSettings/accounting/reconcialations',
  initialTransData: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans/initial',
  findTransactionDetails: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans/find',
  createAccountTransaction: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans',
  generateTransactionsPdf: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans/generatePdf',
  quickCreateAccountTransaction: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans/quick',
  updateAccountTransaction: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans',
  getUnPostedAcctgTransactions: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans/unposted',
  findAccountTransactionsEntries: '/phyCorp/control/api/v1/orgGlSettings/accounting/transactionEntries/lookup',
  generateTransactionDetailsPdf: '/phyCorp/control/api/v1/orgGlSettings/accounting/acctgTransDetail/generatePdf',
  getCustomTimePeriodListDetail: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/customTimePeriod',
  getTrialBalance: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/trialBalance',
  getGenerateCsvTrialBalance: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/generateCsv',
  posttransactionEntries: '/phyCorp/control/api/v1/orgGlSettings/accounting/transactionEntries',
  deletetransactionEntries: '/phyCorp/control/api/v1/orgGlSettings/accounting/transactionEntries',
  getTransDetailById: '/phyCorp/control/api/v1/orgGlSettings/accounting/transactionEntries',
  gettransactionTotals: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/transactionTotals',
  getGenerateCsvtransactionTotals: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/generateCsv',
  getIncomeStatements: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/incomeStatements',
  getgenerateCsvIncomeStatements: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/incomeStatements/generateCsv',
  getcashflowStatements: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/cashflowStatements',
  getgenerateCsvcashflowStatements: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/cashflowStatements/generateCsv',
  getbalanceSheet: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/balanceSheet',
  getCSvbalanceSheet: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/balanceSheet/generateCsv',
  getcomparativeIncomeStatements: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/comparativeIncomeStatements',
  getCSvcomparativeIncomeStatements: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/comparativeIncomeStatements/generateCsv',
  getcomparativeCashFlowStatement: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/comparativeCashFlowStatement',
  getgenerateCsvcomparativeCashFlowStatement: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/comparativeCashFlowStatement/generateCsv',
  getcomparativeBalanceSheet: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/comparativeBalanceSheet',
  getcomparativeGenerateCSVBalanceSheet: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/comparativeBalanceSheet/generateCsv',
  getglAccountTrialBalance: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/glAccountTrialBalance',
  getgeneratePdfglAccountTrialBalance: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/glAccountTrialBalance/generatePdf',
  getinventoryValuation: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/inventoryValuation',
  getgenerateCsvinventoryValuation: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/inventoryValuation/generateCsv',
  getcostCenterAcc: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/costCenter',
  getgeneratePdfcostCenterAcc: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/costCenter/generatePdf',
  getCheckDetails: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/checks',
  postreconcialation: '/phyCorp/control/api/v1/orgGlSettings/accounting/reconcialation',
  getreconcialationDetail: '/phyCorp/control/api/v1/orgGlSettings/accounting/reconcialationDetail',
  sendmarkChecksSent: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/markChecksSent',
  generatePdfmarkChecksSent: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/checks/generatePdf',
  getTransacDetails: '/phyCorp/control/api/v1/orgGlSettings/accounting/accountingTrans',
  authOrderPreference: '/phyCorp/control/api/v1/accounting/transactions/authOrderPreference',
  captureOrderPreference: '/phyCorp/control/api/v1/accounting/transactions/captureOrderPreference',
  getGatewayResponse: '/phyCorp/control/api/v1/accounting/transactions/search',
  getGatewayResponseById: '/phyCorp/control/api/v1/accounting/transactions/gatewayResponse',
  getEenumByType: '/phyCorp/control/api/v1/resources/enumByType',
  processManualTransaction: '/phyCorp/control/api/v1/accounting/transactions/processManualTransaction',
  processRefundTransaction: '/phyCorp/control/api/v1/accounting/transactions/processRefundTransaction',
  processReleaseTransaction: '/phyCorp/control/api/v1/accounting/transactions/processReleaseTransaction',
  getTaxAuthorities: '/phyCorp/control/api/v1/accounting/taxAuthorities',
  getgeoByType: '/phyCorp/control/api/v1/resources/geoByType',
  postTaxAuthorities: '/phyCorp/control/api/v1/accounting/taxAuthorities',
  taxAuthorityDetail: '/phyCorp/control/api/v1/accounting/taxAuthorities/taxAuthorityDetail',
  taxCategoryList: '/phyCorp/control/api/v1/accounting/taxAuthorities/category/list',
  taxCategoryListDelete: '/phyCorp/control/api/v1/accounting/taxAuthorities/category/delete',
  getCategorySearch: '/phyCorp/control/api/v1/catalog/categories/search',
  postTaxCategory: '/phyCorp/control/api/v1/accounting/taxAuthorities/category',
  postAssociationList: '/phyCorp/control/api/v1/accounting/taxAuthorities/association/list',
  deleteAssociationList: '/phyCorp/control/api/v1/accounting/taxAuthorities/association/delete',
  getAssocTypes: '/phyCorp/control/api/v1/accounting/taxAuthorities/association/assocTypes',
  saveAssociation: '/phyCorp/control/api/v1/accounting/taxAuthorities/association/createTaxAuthorityAssoc',
  updateTaxAuthorityAssoc: '/phyCorp/control/api/v1/accounting/taxAuthorities/association/updateTaxAuthorityAssoc',
  getAssociationById: '/phyCorp/control/api/v1/accounting/taxAuthorities/association/associationDetail',
  getTaxGlAccounts: '/phyCorp/control/api/v1/accounting/taxAuthorities/glAccounts',
  deleteTaxGlAccounts: '/phyCorp/control/api/v1/accounting/taxAuthorities/glAccounts/delete',
  postglAccountsTax: '/phyCorp/control/api/v1/accounting/taxAuthorities/glAccounts',
  getProductRate: '/phyCorp/control/api/v1/accounting/taxAuthorities/productRates',
  deleteProductRate: '/phyCorp/control/api/v1/accounting/taxAuthorities/productRates',
  getRateTypes: '/phyCorp/control/api/v1/accounting/taxAuthorities/productRates/rateTypes',
  getStoreId: '/phyCorp/control/api/v1/order/listItems',
  postproductRates: '/phyCorp/control/api/v1/accounting/taxAuthorities/productRates',
  getproductRatesDetail: '/phyCorp/control/api/v1/accounting/taxAuthorities/productRates/productRatesDetail',
  getTaxParty: '/phyCorp/control/api/v1/accounting/taxAuthorities/parties/find',
  deleteTaxParty: '/phyCorp/control/api/v1/accounting/taxAuthorities/parties',
  postTaxParty: '/phyCorp/control/api/v1/accounting/taxAuthorities/parties',
  getPartyDetail: '/phyCorp/control/api/v1/accounting/taxAuthorities/parties/detail',
  getReportTax: '/phyCorp/control/api/v1/accounting/taxAuthorities/report',
  getChartPdf: '/phyCorp/control/api/v1/company/coa/pdf',
  getBudgetType: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgets/budgetType',
  getBudgetList: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgets/find',
  postBudget: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgets',
  getBudgetOverview: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgets/overview',
  getBudgetItem: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetsItem',
  deleteBudgetItem: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetsItem',
  getBudgetItemTypeId: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetsItem/itemType',
  postBudgetItem: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetsItem',
  getbudgetsItemEdit: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetsItem/edit',
  getbudgetRole: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetRole',
  removebudgetRole: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetRole/remove',
  postbudgetRole: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetRole',
  budgetReviewResultTypes: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetReview/budgetReviewResultTypes',
  postbudgetReview: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetReview',
  getbudgetReview: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetReview',
  removebudgetReview: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgetReview/remove',
  updateBudgetStatus: '/phyCorp/control/api/v1/accounting/taxAuthorities/budgets/updateBudgetStatus',
  getRoutingList: '/phyCorp/control/api/v1/mnf/routing/find',
  getstatusByType: '/phyCorp/control/api/v1/resources/statusByType',
  getRoutingListById: '/phyCorp/control/api/v1/mnf/routing',
  postRouting: '/phyCorp/control/api/v1/mnf/routing',
  gettaskAssoc: '/phyCorp/control/api/v1/mnf/routing/taskAssoc',
  getproductLink: '/phyCorp/control/api/v1/mnf/routing/productLink',
  deleteproductLink: '/phyCorp/control/api/v1/mnf/routing/productLink',
  postproductLink: '/phyCorp/control/api/v1/mnf/routing/productLink',
  getproductLinkById: '/phyCorp/control/api/v1/mnf/routing/productLink',
  removeRoutingTaskAssoc: '/phyCorp/control/api/v1/mnf/routing/taskAssoc/removeRoutingTaskAssoc',
  postaddExistingRoutingTask: '/phyCorp/control/api/v1/mnf/routing/taskAssoc/addExistingRoutingTask',
  getJobShopInitialData: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/initialData',
  getJobShopProductionList: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/lookup',
  postProductionRun: '/phyCorp/control/api/v1/mnf/jobShop/productionRun',
  getroutingTaskList: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/tasks/routingTaskList',
  getProductionPDF: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/printPdf',
  getProductiontasks: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/tasks',
  postProductiontasks: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/tasks',
  getProductiontasksEditById: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/tasks/edit',
  getProductionMaterial: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/materials',
  getProductionMaterialRoutingTaskIds: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/materials/routingTaskIds',
  getProductionFixedAsset: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/fixedAsset',
  getProductionFixedAssetById: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/fixedAsset/edit',
  getProductionParty: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/parties',
  getProductionJobassoc: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/assoc',
  getProductionproductionRunId: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/assoc/productionRunId',
  getProductionRunDetail: '/phyCorp/control/api/v1/mnf/jobShop/productionRun',
  changeProductionStatusCancel: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/cancel',
  changeProductionStatusConfirm: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/confirm',
  changeProductionStatusQuick: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/quickChangeProductionRunStatus',
  getProductionJobContent: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/content',
  getProductionJobProductId: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/content/product',
  quickStartAllProductionRunTasks: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/quickStartAllProductionRunTasks',
  getProductionJobActualCosts: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/actualCosts',
  postproductionRunDeclareAndProduce: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/productionRunDeclareAndProduce',
  getRoutingTasks: '/phyCorp/control/api/v1/mnf/routingTask/lookup',
  getRoutingTasksinitialData: '/phyCorp/control/api/v1/mnf/routingTask/initialData',
  postRoutingTasks: '/phyCorp/control/api/v1/mnf/routingTask',
  getRoutingTaskDetails: '/phyCorp/control/api/v1/mnf/routingTask',
  getRoutingTaskCost: '/phyCorp/control/api/v1/mnf/routingTask/cost/initialData',
  postRoutingTaskCost: '/phyCorp/control/api/v1/mnf/routingTask/cost',
  getRoutingTaskDelievarbleProduct: '/phyCorp/control/api/v1/mnf/routingTask/deliverableProduct',
  getProdCost: '/phyCorp/control/api/v1/mnf/cost/find',
  postProdCost: '/phyCorp/control/api/v1/mnf/cost',
  getProdfixedAsset: '/phyCorp/control/api/v1/mnf/routingTask/fixedAsset',
  getProdParty: '/phyCorp/control/api/v1/mnf/routingTask/party',
  getProdSkill: '/phyCorp/control/api/v1/mnf/routingTask/skill',
  getProdCalender: '/phyCorp/control/api/v1/mnf/calendar',
  getProdCalenderWeekId: '/phyCorp/control/api/v1/mnf/calendar/weekList',
  getExceptionDay: '/phyCorp/control/api/v1/mnf/calendar/exceptionDay',
  getProdWeek: '/phyCorp/control/api/v1/mnf/calendar/week',
  getProdWeekById: '/phyCorp/control/api/v1/mnf/calendar/week/edit',
  getExceptionWeek: '/phyCorp/control/api/v1/mnf/calendar/exceptionWeek',
  getActualMaterial: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/actualMaterial',
  postProdMrp: '/phyCorp/control/api/v1/mnf/mrp',
  getMrpFacilities: '/phyCorp/control/api/v1/mnf/mrp/facilityList',
  getBOMInitialData: '/phyCorp/control/api/v1/mnf/bom/initialData',
  postBOMData: '/phyCorp/control/api/v1/mnf/bom',
  getBOMData: '/phyCorp/control/api/v1/mnf/bom/lookup',
  getProductBOMData: '/phyCorp/control/api/v1/mnf/bom/bomDetail',
  getProductBOMDataDetailById: '/phyCorp/control/api/v1/mnf/bom/edit',
  getProductFeatureIds: '/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/productFeatureIds',
  postManufacturingRule: '/phyCorp/control/api/v1/mnf/bom/manufacturingRule',
  getproductInIds: '/phyCorp/control/api/v1/mnf/bom/manufacturingRule/productInIds',
  getInitailBomSimualtionType: '/phyCorp/control/api/v1/mnf/bom/simultion/Type',
  runBomSimulation: '/phyCorp/control/api/v1/mnf/bom/simultion',
  productionRunTaskProduce: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/productionRunTaskProduce',
  startProductionRunTask: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/startProductionRunTask',
  declareProductProduceQty: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/declareProductProduceQty',
  issueProductionRunRoutingTask: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/issueProductionRunRoutingTask',
  mrpSearch: '/phyCorp/control/api/v1/mnf/mrp/search',
  productionRunTaskReturnMaterial: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/productionRunTaskReturnMaterial',
  getshipmentPlans: '/phyCorp/control/api/v1/mnf/shipmentPlans',
  packageLabelPDF: '/phyCorp/control/api/v1/mnf/shipmentPlans/generatePdf/packageLabel',
  shipmentPlanStockPDF: '/phyCorp/control/api/v1/mnf/shipmentPlans/generatePdf/shipmentPlanStock',
  createProductionRunForShipment: '/phyCorp/control/api/v1/mnf/shipmentPlans',
  submitUnusedMaterials: '/phyCorp/control/api/v1/mnf/jobShop/productionRun/unusedMaterial',
  mrpReport: '/phyCorp/control/api/v1/mnf/mrp/report',
  addVaraints: '/phyCorp/control/api/v1/catalog/product/variants/add',
  postFindParty:'/phyCorp/control/api/v1/party',
  postCreateEmployee:'/phyCorp/control/api/v1/party/createEmployee',
  postCreatePartyGroup:'/phyCorp/control/api/v1/party/createPartyGroup',
  postCreateNewPerson:'/phyCorp/control/api/v1/party/createPerson', 
  getMaritalStatus:'/phyCorp/control/api/v1/resources/enumByType',
  identificationList:'/phyCorp/control/api/v1/party/getIdentificationTypeId',
  createPartyIdentifications:'/phyCorp/control/api/v1/party/createPartyIdentifications',
  createProfileUserLogin:'/phyCorp/control/api/v1/party/createProfileUserLogin',
  setPartyLink:'/phyCorp/control/api/v1/party/setPartyLink',
  createCustomer:'/phyCorp/control/api/v1/party/createCustomer',
  postCreateNewProspect:'/phyCorp/control/api/v1/party/createProspect',
  getAllResidentStatusEnumId:'/phyCorp/control/api/v1/party/getAllResidentStatusEnumId',
  getAllEmploymentEnumId:'/phyCorp/control/api/v1/party/getAllEmploymentEnumId',
  getIdentificationList:'/phyCorp/control/api/v1/party/identificationList',
  deletePartyIdentification:'/phyCorp/control/api/v1/party/deletePartyIdentification',
  updatePartyIdentifications:'/phyCorp/control/api/v1/party/updatePartyIdentifications',
  getPartyResume:'/phyCorp/control/api/v1/party/getPartyResume',
  deletePartyResume:'/phyCorp/control/api/v1/party/deletePartyResume',
  createPartyResume:'/phyCorp/control/api/v1/party/createPartyResume',
  updatePartyResume:'/phyCorp/control/api/v1/party/updatePartyResume',
  getSegmentRole:'/phyCorp/control/api/v1/party/getSegmentRole',
  deletePartySegment:'/phyCorp/control/api/v1/party/deletePartySegment',
  createPartySegmentRole:'/phyCorp/control/api/v1/party/createPartySegmentRole',
  getSegmentGroup:'/phyCorp/control/api/v1/party/getSegmentGroup',
  getPartyClassification:'/phyCorp/control/api/v1/party/getPartyClassification',
  getPartyClassificationByPartyId:'/phyCorp/control/api/v1/party/getPartyClassificationByPartyId',
  deletePartyClassification:'/phyCorp/control/api/v1/party/deletePartyClassification',
  getClassificationGroupId:'/phyCorp/control/api/v1/party/getClassificationGroupId',
  createPartyClassification:'/phyCorp/control/api/v1/party/createPartyClassification',
  updatePartyClassification:'/phyCorp/control/api/v1/party/updatePartyClassification',
  updateProfileUserLogin:'/phyCorp/control/api/v1/party/updateProfileUserLogin',
  getPartyRate:'/phyCorp/control/api/v1/party/getPartyRate',
  createPartyRate:'/phyCorp/control/api/v1/party/createPartyRate',
  ListAddUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/ListAddUserLoginToSecurityGroup',
  expireAddUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/expireAddUserLoginToSecurityGroup',
  profileAddUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/profileAddUserLoginToSecurityGroup',
  updateAddUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/updateAddUserLoginToSecurityGroup',
  getUserLoginData:'/phyCorp/control/api/v1/party/getUserLogin',
  getPartyAttribute:'/phyCorp/control/api/v1/party/getPartyAttribute',
  deletePartyAttribute:'/phyCorp/control/api/v1/party/deletePartyAttribute',
  createPartyAttribute:'/phyCorp/control/api/v1/party/createPartyAttribute',
  updatePartyAttribute:'/phyCorp/control/api/v1/party/updatePartyAttribute',
  getPartyContactList:'/phyCorp/control/api/v1/party/getPartyContactList',
  getAllPartyContactListPartyStatus:'/phyCorp/control/api/v1/party/getAllPartyContactListPartyStatus',
  deleteContactList:'/phyCorp/control/api/v1/party/deleteContactList',
  getPartyInvitationStatusList:'/phyCorp/control/api/v1/party/getPartyInvitationStatusList',
  createContactList:'/phyCorp/control/api/v1/party/createContactList',
  deleteOtherRelationship:'/phyCorp/control/api/v1/party/deleteOtherRelationship',
  updateContactList:'/phyCorp/control/api/v1/party/updateContactList',
  getPreferredContactMechId:'/phyCorp/control/api/v1/party/getPreferredContactMechId',
  deletePartRate:'/phyCorp/control/api/v1/party/deletePartRate',
  createNewRelationshipType:'/phyCorp/control/api/v1/party/createNewRelationshipType',
  getAllProductStoreRole:'/phyCorp/control/api/v1/party/getAllProductStoreRole',
  deleteStoreRole:'/phyCorp/control/api/v1/party/deleteStoreRole',
  createStoreRole:'/phyCorp/control/api/v1/party/createStoreRole',
  updateStoreRole:'/phyCorp/control/api/v1/party/updateStoreRole',
  partyInvitations:'/phyCorp/control/api/v1/party/partyInvitations',
  createPartyInvitations:'/phyCorp/control/api/v1/party/createPartyInvitations',
  deletePartyInvitations:'/phyCorp/control/api/v1/party/deletePartyInvitations',
  editPartyInvitations:'/phyCorp/control/api/v1/party/editPartyInvitations',
  updatePartyInvitations:'/phyCorp/control/api/v1/party/updatePartyInvitations',
  PartyInvitationGroupAssocs:'/phyCorp/control/api/v1/party/PartyInvitationGroupAssocs',
  partyInvitationRoleAssociation:'/phyCorp/control/api/v1/party/partyInvitationRoleAssociation',
  deletePartyInvitationGroupAssoci:'/phyCorp/control/api/v1/party/deletePartyInvitationGroupAssoci',
  deletePartyInvitationRoleAssociation:'/phyCorp/control/api/v1/party/deletePartyInvitationRoleAssociation',
  createPartyInvitationGroupAssoci:'/phyCorp/control/api/v1/party/createPartyInvitationGroupAssoci',
  createPartyInvitationRoleAssociation:'/phyCorp/control/api/v1/party/createPartyInvitationRoleAssociation',
  getClassificationGroup:'/phyCorp/control/api/v1/party/getClassificationGroup',
  deletePartyClassificationGroup:'/phyCorp/control/api/v1/party/deletePartyClassificationGroup',
  getVisitors:'/phyCorp/control/api/v1/party/getVisitors',
  getPartyLoyaltyPoints:'/phyCorp/control/api/v1/party/getPartyLoyaltyPoints',
  getPerson:'/phyCorp/control/api/v1/party/getPerson',
  getProductStore:'/phyCorp/control/api/v1/party/getProductStore',
  getClassificationGroupTypeId:'/phyCorp/control/api/v1/party/getClassificationGroupTypeId',
  createPartyClassificationGroup:'/phyCorp/control/api/v1/party/createPartyClassificationGroup',
  getAddressMatchMap:'/phyCorp/control/api/v1/party/getAddressMatchMap',
  removeAddressMatchMap:'/phyCorp/control/api/v1/party/removeAddressMatchMap',
  clearAddressMatchMap:'/phyCorp/control/api/v1/party/clearAddressMatchMap',
  createAddressMatchMap:'/phyCorp/control/api/v1/party/createAddressMatchMap',
  FindUserLogin:'/phyCorp/control/api/v1/party/FindUserLogin',
  getParentTypeIdParty:'/phyCorp/control/api/v1/party/getParentTypeId',
  getPartyClassificationGroupById:'/phyCorp/control/api/v1/party/getPartyClassificationGroupById',
  updatePartyClassificationTypeId:'/phyCorp/control/api/v1/party/updatePartyClassification',
  getUnknownPartyComms:'/phyCorp/control/api/v1/party/getUnknownPartyComms',
  listLoggedInUsers:'/phyCorp/control/api/v1/party/listLoggedInUsers',
  getPreferences:'/phyCorp/control/api/v1/party/getPreferences',
  EditSecurityGroupProtectedViews:'/phyCorp/control/api/v1/party/EditSecurityGroupProtectedViews',
  removeProtectedViewFromSecurityGroup:'/phyCorp/control/api/v1/party/removeProtectedViewFromSecurityGroup',
  addProtectedViewToSecurityGroup:'/phyCorp/control/api/v1/party/addProtectedViewToSecurityGroup',
  updateProtectedViewToSecurityGroup:'/phyCorp/control/api/v1/party/updateProtectedViewToSecurityGroup',
  createOtherRelationship:'/phyCorp/control/api/v1/party/createOtherRelationship',
  EditSecurityGroupUserLogins:'/phyCorp/control/api/v1/party/EditSecurityGroupUserLogins',
  getRelationshipPartyIdTo:'/phyCorp/control/api/v1/party/getRelationshipPartyIdTo',
  getRelationshipPartyIdFrom:'/phyCorp/control/api/v1/party/getRelationshipPartyIdFrom',
  expireUserLoginFromSecurityGroup:'/phyCorp/control/api/v1/party/expireUserLoginFromSecurityGroup',
  updateOtherRelationship:'/phyCorp/control/api/v1/party/updateOtherRelationship',
  addUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/addUserLoginToSecurityGroup',
  updateUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/updateUserLoginToSecurityGroup',
  getVisitorsDetails:'/phyCorp/control/api/v1/party/getVisitorsDetails',
  uploadCsvMatch:'/phyCorp/control/api/v1/party/uploadCsv',
  getPartyClassificationById:'/phyCorp/control/api/v1/party/getPartyClassification',
  getPartyGroup:'/phyCorp/control/api/v1/party/getPartyGroup',
  getAllFinanceHistory:'/phyCorp/control/api/v1/party/getAllFinanceHistory',
  createPartyEmptyShopping:'/phyCorp/control/api/v1/party/createPartyEmptyShopping',
  getShoppingList:'/phyCorp/control/api/v1/party/getShoppingList',
  getShoppingListById:'/phyCorp/control/api/v1/party/getShoppingListById',
  updateShoppingList:'/phyCorp/control/api/v1/party/updateShoppingList',
  getShoppingListTypeIdList:'/phyCorp/control/api/v1/party/getShoppingListTypeIdList',
  lookupProduct:'/phyCorp/control/api/v1/party/lookupProduct',
  addItemToShoppingList:'/phyCorp/control/api/v1/party/addItemToShoppingList',
  getItemToShoppingList:'/phyCorp/control/api/v1/party/getItemToShoppingList',
  removeShoppingListItem:'/phyCorp/control/api/v1/party/removeShoppingListItem',
  createPartyCustomRequest:'/phyCorp/control/api/v1/party/createPartyCustomRequest',
  updateShoppingListItem:'/phyCorp/control/api/v1/party/updateShoppingListItem',
  createQuoteFromShoppingList:'/phyCorp/control/api/v1/party/createQuoteFromShoppingList',
  getTaxInfo:'/phyCorp/control/api/v1/party/getTaxInfo',
  deleteTaxInfo:'/phyCorp/control/api/v1/party/deleteTaxInfo',
  createTaxInfo:'/phyCorp/control/api/v1/party/createTaxInfo',
  updateTaxInfo:'/phyCorp/control/api/v1/party/updateTaxInfo',
  getPartyIcsAvsOverRide:'/phyCorp/control/api/v1/party/getPartyIcsAvsOverRide',
  resetAvsOverride:'/phyCorp/control/api/v1/party/resetAvsOverride',
  updateAvsOverride:'/phyCorp/control/api/v1/party/updateAvsOverride',
  deleteAllSystemNotes:'/phyCorp/control/api/v1/myPortal/deleteAllSystemNotes',
  createSystemInfoNote:'/phyCorp/control/api/v1/myPortal/createSystemInfoNote',
  getSystemInfoNote:'/phyCorp/control/api/v1/myPortal/getSystemInfoNote',
  getSystemInfoStatus:'/phyCorp/control/api/v1/myPortal/getSystemInfoStatus',
  deleteSystemInfoNote:'/phyCorp/control/api/v1/myPortal/deleteSystemInfoNote',
  getPayment:'/phyCorp/control/api/v1/myPortal/getPayment',
  createUserLogin:'/phyCorp/control/api/v1/party/createUserLogin',
  updatePassword:'/phyCorp/control/api/v1/party/updatePassword',
  updateUserLoginSecurity:'/phyCorp/control/api/v1/party/updateUserLoginSecurity',
  getMyTask:'/phyCorp/control/api/v1/myPortal/getMyTask',
  removeMyTasks:'/phyCorp/control/api/v1/myPortal/removeMyTasks',
  getRoleById:'/phyCorp/control/api/v1/myPortal/getRole',
  getTrainingData:'/phyCorp/control/api/v1/myPortal/getTraining',
  getScope:'/phyCorp/control/api/v1/myPortal/getScope',
  getSkillType:'/phyCorp/control/api/v1/myPortal/getSkillType',
  getTaskName:'/phyCorp/control/api/v1/myPortal/getTaskName',
  createMyTasks:'/phyCorp/control/api/v1/myPortal/createMyTasks',
  addTaskAssignment:'/phyCorp/control/api/v1/myPortal/addTaskAssignment',
  getParentPhase:'/phyCorp/control/api/v1/myPortal/getParentPhase',
  updateTaskAssignment:'/phyCorp/control/api/v1/myPortal/updateTaskAssignment',
  ManagePortalPages:'/phyCorp/control/api/v1/myPortal/ManagePortalPages',
  createPortalPageAdm:'/phyCorp/control/api/v1/myPortal/createPortalPageAdm',
  communicationEventsPortal:'/phyCorp/control/api/v1/sfa/communicationEvents/lookup',
  communicationEventsDelete:'/phyCorp/control/api/v1/sfa/communicationEvents',
  getMyLeave:'/phyCorp/control/api/v1/myPortal/getMyLeave',
  findPortalPage:'/phyCorp/control/api/v1/myPortal/findPortalPage',
  findVisitors:'/phyCorp/control/api/v1/party/findVisitors',
  findAddressMatch:'/phyCorp/control/api/v1/party/findAddressMatch',
  uploadEmployeePersonalImage:'/phyCorp/control/api/v1/imageUpload/api/v1/uploadEmployeePersonalImage',
  getDataCategoryIdList:'/phyCorp/control/api/v1/party/getDataCategoryIdList',
  getPartyContentTypeIdList:'/phyCorp/control/api/v1/party/getPartyContentTypeIdList',
  getPartyStatusIdList:'/phyCorp/control/api/v1/party/getPartyStatusIdList',
  getPartyContent:'/phyCorp/control/api/v1/party/getPartyContent',
  createPartyContent:'/phyCorp/control/api/v1/party/createPartyContent',
  deletePartyContentData:'/phyCorp/control/api/v1/party/deletePartyContent',
  updatePartyContent:'/phyCorp/control/api/v1/party/updatePartyContent',
  getEmployeePersonalImage:'/phyCorp/control/api/v1/imageUpload/api/v1/getEmployeePersonalImage',
  uploadCustomerPersonalImage:'/phyCorp/control/api/v1/imageUpload/api/v1/uploadCustomerPersonalImage',
  getCustomerPersonalImage:'/phyCorp/control/api/v1/imageUpload/api/v1/getCustomerPersonalImage',
  getTimeSheetRateTypeId:'/phyCorp/control/api/v1/myPortal/getTimeSheetRateTypeId',
  getTimeSheetTaskName:'/phyCorp/control/api/v1/myPortal/getTimeSheetTaskName',
  myStatusToComplete:'/phyCorp/control/api/v1/myPortal/updateTimesheet?statusId=TIMESHEET_IN_PROCESS&timesheetId=10000',
  getExportPartyId:'/phyCorp/control/api/v1/party/exportByPartyId',
  getApAccountInvoice:'/phyCorp/control/api/v1/accountingPayable/apAccountInvoice/getApAccountInvoice',
  getAR_AccountInvoice:'/phyCorp/control/api/v1/accountingPayable/apAccountInvoice/getAR_AccountInvoice',
  getAPAccountPastDueInvoice:'/phyCorp/control/api/v1/accountingPayable/apAccountInvoice/getAPAccountPastDueInvoice',
  getARAccountPastDueInvoice:'/phyCorp/control/api/v1/accountingPayable/apAccountInvoice/getARAccountPastDueInvoice',
  getInternalOrganization:'/phyCorp/control/api/v1/employment/getInternalOrganization',
  getPeriodTypeId:'/phyCorp/control/api/v1/employment/getPeriodTypeId',
  getTerminationReasonID:'/phyCorp/control/api/v1/employment/getTerminationReasonID',
  getTerminationTypeID:'/phyCorp/control/api/v1/employment/getTerminationTypeID',
  createEmployment:'/phyCorp/control/api/v1/employment/createEmployment',

  getFindPayrollPreferenceList:'/phyCorp/control/api/v1/employment/getFindPayrollPreferenceList',
  createPayrollPreference:'/phyCorp/control/api/v1/employment/createPayrollPreference',
  payrollprefrenceService:'/phyCorp/control/api/v1/employment/payrollprefrenceService',
  getDeductionTypeID:'/phyCorp/control/api/v1/employment/getDeductionTypeID',
  getPayrollPeriodTypeId:'/phyCorp/control/api/v1/employment/getPeriodTypeId',
  deletePayrollPreference:'/phyCorp/control/api/v1/employment/deletePayrollPreference',
  getPayGradeID:'/phyCorp/control/api/v1/employment/getPayGradeID',
  getSalaryStepSequenceID:'/phyCorp/control/api/v1/employment/getSalaryStepSequenceID',
  getBenefitTypeList:'/phyCorp/control/api/v1/employment/partyBenefitService',
  getBenefitTypeID:'/phyCorp/control/api/v1/employment/getBenefitTypeID',
  createPartyBenefit:'/phyCorp/control/api/v1/employment/createPartyBenefit',
  deletePartyBenefit:'/phyCorp/control/api/v1/employment/deletePartyBenefit',
  getPayrollPreferenceSequenceId:'/phyCorp/control/api/v1/employment/getPayrollPreferenceSequenceId',
  getPayHistoryList:'/phyCorp/control/api/v1/employment/getPayHistoryList',
  deletePayHistory:'/phyCorp/control/api/v1/employment/deletePayHistory',
  getUnemploymentClaim:'/phyCorp/control/api/v1/employment/getUnemploymentClaim',
  getStatusItem:'/phyCorp/control/api/v1/employment/getStatusItem',
  deleteUnemploymentClaim:'/phyCorp/control/api/v1/employment/deleteUnemploymentClaim',
  getUnemploymentClaimList:'/phyCorp/control/api/v1/employment/unemploymentClaimService',
  getPerfReview:'/phyCorp/control/api/v1/employment/getPerfReview',
  createPerfReview:'/phyCorp/control/api/v1/employment/createPerfReview',
  getPerfRatingTypeIDList:'/phyCorp/control/api/v1/employment/getPerfRatingTypeIDList',
  getPerfItemTypeIDList:'/phyCorp/control/api/v1/employment/getPerfItemTypeIDList',
  findPerfReview:'/phyCorp/control/api/v1/employment/findPerfReview',
  createPerfReviewItem:'/phyCorp/control/api/v1/employment/createPerfReviewItem',
  createUnemploymentClaim:'/phyCorp/control/api/v1/employment/createUnemploymentClaim',
  AgreementItemSeqId:'/phyCorp/control/api/v1/employment/AgreementItemSeqId',
  getRoleTypeIdFrom:'/phyCorp/control/api/v1/employment/getRoleTypeIdFrom',
  getRoleTypeIdTo:'/phyCorp/control/api/v1/employment/getRoleTypeIdTo',
  getPartyID:'/phyCorp/control/api/v1/employment/getPartyID',
  getAgreementID:'/phyCorp/control/api/v1/employment/Agreement',
  getAgreementEmploymentApplList:'/phyCorp/control/api/v1/employment/getAgreementEmploymentApplList',
  deleteAgreementEmploymentAppl:'/phyCorp/control/api/v1/employment/deleteAgreementEmploymentAppl',
  createAgreementEmploymentAppl:'/phyCorp/control/api/v1/employment/createAgreementEmploymentAppl',
  getPaymentID:'/phyCorp/control/api/v1/employment/getPaymentID',
  getPartyQualType:'/phyCorp/control/api/v1/employment/getPartyQualType',
  createPartyQual:'/phyCorp/control/api/v1/employment/createPartyQual',
  findPerfReviewData:'/phyCorp/control/api/v1/employment/findPerfReview',
  getContentIdList:'/phyCorp/control/api/v1/employment/getContentIdList',
  getListPartyResume:'/phyCorp/control/api/v1/party/getPartyResume',
  updatePartyHistory:'/phyCorp/control/api/v1/employment/updatePartyHistory',
  findItemPreview:'/phyCorp/control/api/v1/employment/getPerfReviewItemList',
  deleteFindItemPreview:'/phyCorp/control/api/v1/employment/deletePerfReviewItem',
  createTerminationTypes:'/phyCorp/control/api/v1/employment/createTerminationTypes',
  deleteTerminationType:'/phyCorp/control/api/v1/employment/deleteTerminationType',
  getTerminationTypeList:'/phyCorp/control/api/v1/employment/glHRSettingsTerminationTypesService',
  getStatusVerifId:'/phyCorp/control/api/v1/employment/getStatusVerifId',
  getPartyQualList:'/phyCorp/control/api/v1/employment/getPartyQualList',
  deletePartyQual:'/phyCorp/control/api/v1/employment/deletePartyQual',
  updateEmployment:'/phyCorp/control/api/v1/employment/updateEmployment',
  getFindEmployment:'/phyCorp/control/api/v1/employment/getFindEmployment',
  updatePerfReview:'/phyCorp/control/api/v1/employment/updatePerfReview',
  updatePartyQual:'/phyCorp/control/api/v1/employment/updatePartyQual',
  getTerminationReasonList:'/phyCorp/control/api/v1/employment/getTerminationReasonList',
  deleteTerminationReason:'/phyCorp/control/api/v1/employment/deleteTerminationReason',
  createTerminationReason:'/phyCorp/control/api/v1/employment/gltr',
  updateTerminationType:'/phyCorp/control/api/v1/employment/updateTerminationType',
  updateTerminationReason:'/phyCorp/control/api/v1/employment/updateTerminationReason',
  getFindPartyResume:'/phyCorp/control/api/v1/employment/getFindPartyResume', 
  createPayGrade:'/phyCorp/control/api/v1/employment/createPayGrade',
  deletePayGrade:'/phyCorp/control/api/v1/employment/deletePayGrade',
  getFindPayGrade:'/phyCorp/control/api/v1/employment/getFindPayGrade',
  updatePayGrade:'/phyCorp/control/api/v1/employment/updatePayGrade',
  getSalaryStepList:'/phyCorp/control/api/v1/employment/getSalaryStepList',
  createSalaryStep:'/phyCorp/control/api/v1/employment/createSalaryStep',
  deleteSalaryStep:'/phyCorp/control/api/v1/employment/deleteSalaryStep',
  updateSalaryStep:'/phyCorp/control/api/v1/employment/updateSalaryStep',
  getShipmentGatewayConfigTypeIdList:'/phyCorp/control/api/v1/employment/getShipmentGatewayConfigTypeIdList',
  getFindShipmentGatewayConfig:'/phyCorp/control/api/v1/employment/getFindShipmentGatewayConfig',
  getFindShipmentGatewayConfigTypes:'/phyCorp/control/api/v1/employment/getFindShipmentGatewayConfigTypes',
  getParentTypeID:'/phyCorp/control/api/v1/employment/getParentTypeID',
  UpdateShipmentGatewayConfigType:'/phyCorp/control/api/v1/employment/UpdateShipmentGatewayConfigType',
  UpdateShipmentGatewayConfig:'/phyCorp/control/api/v1/employment/UpdateShipmentGatewayConfig',
  getLabelImageFormat:'/phyCorp/control/api/v1/employment/getLabelImageFormat',
  getHeadActionAttribute:'/phyCorp/control/api/v1/employment/getHeadActionAttribute',
  getRateEstimateAPISchema:'/phyCorp/control/api/v1/employment/getRateEstimateAPISchema',
  getShipmentGatewayDhlConfig:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlConfig',
  updateShipmentGatewayDhl:'/phyCorp/control/api/v1/employment/updateShipmentGatewayDhl',
  getShipmentGatewayDhlConfigFedexDropoffType:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlConfigFedexDropoffType',
  getShipmentGatewayDhlConfigFedexPackingType:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlConfigFedexPackingType',
  getShipmentGatewayDhlConfigFedexLabelImageType:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlConfigFedexLabelImageType',
  getShipmentGatewayDhlUspsConfigTestMode:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlUspsConfigTestMode',
  getShipmentGatewayDhlUspConfigSaveCertInfo:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlUspConfigSaveCertInfo',
  getShipmentGatewayDhlUspConfigCustomerClassification:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlUspConfigCustomerClassification',
  getShipmentGatewayDhlConfigFedex:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlConfigFedex',
  getShipmentGatewayDhlUspsConfig:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlUspsConfig',
  getShipmentGatewayDhlUpsConfig:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlUpsConfig',
  getShipmentGatewayDhlUspConfigFundsCod:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlUspConfigFundsCod',
  getShipmentGatewayDhlUspConfigSurchargeApply:'/phyCorp/control/api/v1/employment/getShipmentGatewayDhlUspConfigSurchargeApply',
  updateShipmentGatewayFedex:'/phyCorp/control/api/v1/employment/updateShipmentGatewayFedex',
  updateShipmentGatewayUsps:'/phyCorp/control/api/v1/employment/updateShipmentGatewayUsps',
  updateShipmentGatewayUps:'/phyCorp/control/api/v1/employment/updateShipmentGatewayUps',
  getEditPayHistory:'/phyCorp/control/api/v1/employment/getEditPayHistory',
  getAvailabilty:'/phyCorp/control/api/v1/employment/getAvailabilty',
  createApplicationEmployment:'/phyCorp/control/api/v1/employment/createApplicationEmployment',
  findApplicationEmploymentId:'/phyCorp/control/api/v1/employment/findApplicationEmploymentId',
  findApplicationEmployment:'/phyCorp/control/api/v1/employment/findApplicationEmployment',
  createBalanceSheet: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/createBalanceSheetDetails',
  getBalanceSheet: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/getBalanceSheetDetails',
  createCashFlow: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/createCashFlow',
  createTrialBalanceDetails: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/createTrialDetails',
  getDetailsByPaymentId:'/phyCorp/control/api/v1/myPortal/getDetailsByPaymentId',
  createAccountGroupDetail: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/createAccountGroupsDetails',
  CustomerDataToCSV: '/phyCorp/control/api/v1/import/CustomerDataToCSV',
  CustomerSitesDataToCSV: '/phyCorp/control/api/v1/import/CustomerSitesDataToCSV',
  SecurityGroupDataToCSV: '/phyCorp/control/api/v1/import/SecurityGroupDataToCSV',
  getSecurityGroupDataToCSV: '/phyCorp/control/api/v1/import/getSecurityGroupDataToCSV',
  getCustomerDataToCSV: '/phyCorp/control/api/v1/import/getCustomerDataToCSV',
  getCustomerSitesDataToCSV: '/phyCorp/control/api/v1/import/getCustomerSitesDataToCSV',
  attachFile: '/phyCorp/control/api/v1/employment/attachFile',
  deleteApplicationEmployment: '/phyCorp/control/api/v1/employment/deleteApplicationEmployment',
  updateApplicationEmployment: '/phyCorp/control/api/v1/employment/updateApplicationEmployment',
  createPayCheck: '/phyCorp/control/api/v1/employment/createPayCheck',
  getPaymentMethod: '/phyCorp/control/api/v1/employment/getPaymentMethod',
  getPaycheckType: '/phyCorp/control/api/v1/employment/payCheckService',
  getFindPayCheck: '/phyCorp/control/api/v1/employment/getFindPayCheck',
  getConfigPagePaycheck: '/phyCorp/control/api/v1/employment/getConfigPagePaycheck',
  getPaycheckAndWithHoldings: '/phyCorp/control/api/v1/employment/paycheckAndWithHoldings',
  getBalanceSheetImportList:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/getBalanceSheetImportList',
  importBalanceSheetDetailsAccounting:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/importBalanceSheetDetailsAccounting',
  getTrialBalanceHeadingDetailsNew:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/getTrialBalanceHeadingDetailsNew',
  updatePaycheckHolding:'/phyCorp/control/api/v1/employment/updatePaycheckHolding',
  getPaycheckExpenses:'/phyCorp/control/api/v1/employment/getPaycheckExpenses',
  updatePaycheckExpenses:'/phyCorp/control/api/v1/employment/updatePaycheckExpenses',
  updatePayCheck:'/phyCorp/control/api/v1/employment/updatePayCheck',
  createTrialBalanceHeadingDetailsImportNew:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/createTrialBalanceHeadingDetailsImportNew',
  getBalanceSheetDetails:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/getBalanceSheetDetails',
  createBalanceSheetDetails:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/createBalanceSheetDetails',
  getGlAccountDetailsListImport:'/phyCorp/control/api/v1/accounting/globalGLSetting/getGlAccountDetailsListImport',
  importGlSettingAccountDetails:'/phyCorp/control/api/v1/accounting/globalGLSetting/importGlSettingAccountDetails',
  getTrialBalanceDetailsListImport:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/getTrialBalanceDetailsListImport',
  createTrialBalanceDetailsImport:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/createTrialBalanceDetailsImport',
  getCashFlowListImport:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/getCashFlowListImport',
  creatingCashFlowDetailsImport:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/creatingCashFlowDetailsImport',
  getInventoryCountListImport:'/phyCorp/control/api/v1/facility/inventory/getInventoryCountListImport',
  createInventoryCountImport:'/phyCorp/control/api/v1/facility/inventory/createInventoryCountImport',
  getPagePaycheckEdit:'/phyCorp/control/api/v1/employment/getPagePaycheckEdit',
  editPayCheck:'/phyCorp/control/api/v1/employment/editPayCheck',
  StatusToSend:'/phyCorp/control/api/v1/employment/StatusToSendPaycheck',
  getStatusSendDetails:'/phyCorp/control/api/v1/employment/getStatusSendDetails',
  HTMLDATANEW_TESTING_NEW:'/phyCorp/control/api/v1/employment/htmlDataNew',
  getLocalizationByLocalization:'/phyCorp/control/api/v1/employment/getLocalization',
  createLocalization:'/phyCorp/control/api/v1/employment/createLocalization',
  getShipmentImportList:'/phyCorp/control/api/v1/catalog/carrierShipmentMethod/getShipmentImportList',
  createShipmentImport:'/phyCorp/control/api/v1/catalog/carrierShipmentMethod/createShipmentImport',
  getVendorImportList:'/phyCorp/control/api/v1/accounting/vendor/getVendorImportList',
  createVendorImport:'/phyCorp/control/api/v1/accounting/vendor/createVendorImport',
  getWareHouseLocationImportList:'/phyCorp/control/api/v1/facility/location/getWareHouseLocationImportList',
  createWareHousesLocationImport:'/phyCorp/control/api/v1/facility/location/createWareHousesLocationImport',
  importWareHouseOnly:'/phyCorp/control/api/v1/facility/location/importWareHouseOnly',
  createFiscalYearDetailsImport:'/phyCorp/control/api/v1/import/createFiscalYearDetailsImport',
  getFiscalMonthsDetailsList:'/phyCorp/control/api/v1/import/getFiscalMonthsDetailsList',
  getWareHouseOnlyImportList:'/phyCorp/control/api/v1/facility/location/getWareHouseOnlyImportList',
  getCustomerInvoiceImportList:'/phyCorp/control/api/v1/party/getCustomerInvoiceImportList',
  getVendorOpenInvoiceImportList:'/phyCorp/control/api/v1/accounting/vendor/getVendorOpenInvoiceImportList',
  createVendorOpenInvoiceImport:'/phyCorp/control/api/v1/accounting/vendor/createVendorOpenInvoiceImport',
  createPaycheckExpenses:'/phyCorp/control/api/v1/employment/createPaycheckExpenses',
  createPaycheckHolding:'/phyCorp/control/api/v1/employment/createPaycheckHolding',
  getSetupUserImportList:'/phyCorp/control/api/v1/accounting/vendor/getSetupUserImportList',
  createSetupUserImport:'/phyCorp/control/api/v1/accounting/vendor/createSetupUserImport',
  getDepartment:'/phyCorp/control/api/v1/employment/getDepartment',
  getEmployee:'/phyCorp/control/api/v1/employment/getEmployee',
  getCompany:'/phyCorp/control/api/v1/employment/getCompany',
  getJobPosition:'/phyCorp/control/api/v1/employment/getJobPosition',
  getContractType:'/phyCorp/control/api/v1/employment/getContractType',
  getPayCheck:'/phyCorp/control/api/v1/employment/getPayCheck',
  getNewContractDocumentTemplate:'/phyCorp/control/api/v1/employment/getNewContractDocumentTemplate',
  getHRResponsible:'/phyCorp/control/api/v1/employment/getHRResponsible',
  getContractUpdateDocumentTemplate:'/phyCorp/control/api/v1/employment/getContractUpdateDocumentTemplate',
  getAnalyticAccount:'/phyCorp/control/api/v1/employment/getAnalyticAccount',
  getContractTemplate:'/phyCorp/control/api/v1/employment/getContractTemplate',
  createContract:'/phyCorp/control/api/v1/employment/createContract',
  createContractDetails:'/phyCorp/control/api/v1/employment/createContractDetails',
  getWorkingSchedule:'/phyCorp/control/api/v1/employment/getWorkingSchedule',
  createWorkingHours:'/phyCorp/control/api/v1/employment/createWorkingHours',
  createWorkingSchedule:'/phyCorp/control/api/v1/employment/createWorkingSchedule',
  getWorkingScheduleList:'/phyCorp/control/api/v1/employment/getWorkingScheduleList',
  getWorkingHoursList:'/phyCorp/control/api/v1/employment/getWorkingHoursList',
  getWorkEntryType:'/phyCorp/control/api/v1/employment/getWorkEntryType',
  getDayOfWeek:'/phyCorp/control/api/v1/employment/getDayOfWeek',
  getDayPeriod:'/phyCorp/control/api/v1/employment/getDayPeriod',
  updateWorkingHours:'/phyCorp/control/api/v1/employment/updateWorkingHours',
  updateWorkingSchedule:'/phyCorp/control/api/v1/employment/updateWorkingSchedule',
  getEmployeePageDetails:'/phyCorp/control/api/v1/employment/getEmployeePageDetails',
  getWorkSchedulePage:'/phyCorp/control/api/v1/employment/getWorkSchedulePage',
  deleteWorkingHours:'/phyCorp/control/api/v1/employment/deleteWorkingHours',
  ReportDaysHoursToXLSX:'/phyCorp/control/api/v1/employment/ReportDaysHoursToXLSX',
  getLineChartNumberOfDays:'/phyCorp/control/api/v1/employment/getLineChartNumberOfDays',
  getLineChartNumberOfHours:'/phyCorp/control/api/v1/employment/getLineChartNumberOfHours',
  getTotalHours:'/phyCorp/control/api/v1/employment/getTotalHours',
  getDropDowns:'/phyCorp/control/api/v1/employment/getDropDowns',
  getPieChart:'/phyCorp/control/api/v1/employment/getPieChart',
  getPieChartData:'/phyCorp/control/api/v1/employment/getPieChartData',
  getProductBacklogsListScrum:'/phyCorp/control/api/v1/scrumNewController/getProductBacklogsListScrum',
  editorAddProductBacklogs:'/phyCorp/control/api/v1/scrumNewController/editorAddProductBacklogs',
  getFindProduct:'/phyCorp/control/api/v1/scrumNewController/getFindProduct',
  getProductStatus:'/phyCorp/control/api/v1/scrumNewController/getProductStatus',
  getProductOverviewMemberList:'/phyCorp/control/api/v1/scrumNewController/getProductOverviewMemberList',
  getProductOverviewProductContent:'/phyCorp/control/api/v1/scrumNewController/getProductOverviewProductContent',
  getProductOverviewProductInfo:'/phyCorp/control/api/v1/scrumNewController/getProductOverviewProductInfo',
  getCompanyAndProductOwnerList:'/phyCorp/control/api/v1/scrumNewController/getCompanyAndProductOwnerList',
  getEmailsProductBacklogs:'/phyCorp/control/api/v1/scrumNewController/getEmailsProductBacklogs',
  getProjectAndSprintList:'/phyCorp/control/api/v1/scrumNewController/getProjectAndSprintList',
  productUpdate:'/phyCorp/control/api/v1/scrumNewController/updateProduct',
  addProductMember:'/phyCorp/control/api/v1/scrumNewController/addProductMember',
  removeProductMemberListBySingle:'/phyCorp/control/api/v1/scrumNewController/removeProductMemberListBySingle',
  getRoleTypeIdList:'/phyCorp/control/api/v1/scrumNewController/getRoleTypeIdList',
  addCompanyAndProductOwner:'/phyCorp/control/api/v1/scrumNewController/addCompanyAndProductOwner',
  getFindTotalBacklog:'/phyCorp/control/api/v1/scrumNewController/getFindTotalBacklog',
  getEditBacklogsProductItemList:'/phyCorp/control/api/v1/scrumNewController/getEditBacklogsProductItemList',
  productBackLogsItemSubmit:'/phyCorp/control/api/v1/scrumNewController/productBacklogItemListSubmit',
  deleteProductBackLogsItem:'/phyCorp/control/api/v1/scrumNewController/deleteProductBacklogItem',
  addNewProductBacklogsItem:'/phyCorp/control/api/v1/scrumNewController/addNewProductBacklogsItem',
  getFindBilling:'/phyCorp/control/api/v1/scrumNewController/getFindBilling',
  updateNotBilledBacklog:'/phyCorp/control/api/v1/scrumNewController/updateNotBilledBacklog',
  addProductTimeToNewInvoice:'/phyCorp/control/api/v1/scrumNewController/addProductTimeToNewInvoice',
  getForBillingPage:'/phyCorp/control/api/v1/scrumNewController/getForBillingPage',
  getCompanyAndProductList:'/phyCorp/control/api/v1/scrumNewController/getCompanyAndProductList  ',
  deleteProductMember:'/phyCorp/control/api/v1/scrumNewController/deleteProductMember',
  getProductMemberParty:'/phyCorp/control/api/v1/scrumNewController/getProductMemberParty',
  getProductMemberRole:'/phyCorp/control/api/v1/scrumNewController/getProductMemberRole  ',
  getProductBacklogsItemList:'/phyCorp/control/api/v1/scrumNewController/getProductBacklogsItemList  ',
  deleteBacklogItem:'/phyCorp/control/api/v1/scrumNewController/deleteBacklogItem',
  getBacklogItemCategory:'/phyCorp/control/api/v1/scrumNewController/getBacklogItemCategory',
  getSprintOverview:'/phyCorp/control/api/v1/scrumNewController/getSprintOverview',
  getDefaultTaskTypeList:'/phyCorp/control/api/v1/scrumNewController/getDefaultTaskTypeList',
  createDefaultTask:'/phyCorp/control/api/v1/scrumNewController/createDefaultTask',
  getAssignToList:'/phyCorp/control/api/v1/scrumNewController/getAssignToList',
  getTaskList:'/phyCorp/control/api/v1/scrumNewController/getTaskList',
  getFromPartyIdRequesterName:'/phyCorp/control/api/v1/scrumNewController/getFromPartyIdRequesterName',
  deleteDefaultTask:'/phyCorp/control/api/v1/scrumNewController/deleteDefaultTask',
  updateDefaultTask:'/phyCorp/control/api/v1/scrumNewController/updateDefaultTask',
  createCategory_productBacklog:'/phyCorp/control/api/v1/scrumNewController/createCategory_productBacklog',
  getCategoryListData:'/phyCorp/control/api/v1/scrumNewController/getCategoryListData',
  getContentTypeId:'/phyCorp/control/api/v1/scrumNewController/getContentTypeId',
  getMimeTypeId:'/phyCorp/control/api/v1/scrumNewController/getMimeTypeId',
  getScrumProductContentListData:'/phyCorp/control/api/v1/scrumNewController/getScrumProductContentListData',
  deleteScrumProductContent:'/phyCorp/control/api/v1/scrumNewController/deleteScrumProductContent',
  createScrumProductContent:'/phyCorp/control/api/v1/scrumNewController/createScrumProductContent',
  getDownloadImageProductContent:'/phyCorp/control/api/v1/scrumNewController/getDownloadImageProductContent',
  getCommunicationList:'/phyCorp/control/api/v1/scrumNewController/getCommunicationList',
  uploadEmailContent:'/phyCorp/control/api/v1/scrumNewController/uploadEmailContent',
  updateEmailForProduct:'/phyCorp/control/api/v1/scrumNewController/updateEmailForProduct',
  getNewProjectProductName:'/phyCorp/control/api/v1/scrumNewController/getNewProjectProductName',
  getEmailContentData:'/phyCorp/control/api/v1/scrumNewController/getEmailContentData',
  getEmailContentDownload:'/phyCorp/control/api/v1/scrumNewController/getEmailContentDataDownload',
  getProductUpdateEmail:'/phyCorp/control/api/v1/scrumNewController/getProductUpdateEmail',
  createScrumProject:'/phyCorp/control/api/v1/scrumNewController/getProjectSprint',
  getChildCommunicationOverviewEvent:'/phyCorp/control/api/v1/scrumNewController/getChildCommunicationOverviewEvent',
  getCommunicationOverviewEventRoles:'/phyCorp/control/api/v1/scrumNewController/getCommunicationOverviewEventRoles',
  getCommunicationOverviewEvent:'/phyCorp/control/api/v1/scrumNewController/getCommunicationOverviewEvent',
  createSprint:'/phyCorp/control/api/v1/scrumNewController/createSprint',
  getProjectSprintFrontPage:'/phyCorp/control/api/v1/scrumNewController/getProjectSprintFrontPage',
  findBacklog:'/phyCorp/control/api/v1/scrumNewController/findBacklog',
  getProductName:'/phyCorp/control/api/v1/scrumNewController/getProductName',
  FindTaskRevision:'/phyCorp/control/api/v1/scrumNewController/FindTaskRevision',
  getProjectSprintProductName:'/phyCorp/control/api/v1/scrumNewController/getProjectSprintProductName',
  updateScrumProject:'/phyCorp/control/api/v1/scrumNewController/updateScrumProject',
  updateSprintClose:'/phyCorp/control/api/v1/scrumNewController/updateSprintClose',
  getProjectSprintOverview:'/phyCorp/control/api/v1/scrumNewController/getProjectSprintOverview',
  getProjectSprintProductNameUpdate:'/phyCorp/control/api/v1/scrumNewController/getProjectSprintProductNameUpdate',
  getMoveToProduct:'/phyCorp/control/api/v1/scrumNewController/getMoveToProduct',
  getSprintLists:'/phyCorp/control/api/v1/scrumNewController/getSprintLists',
  createProjectScrum:'/phyCorp/control/api/v1/scrumNewController/createScrumProject',
  closeProduct:'/phyCorp/control/api/v1/scrumNewController/closeProduct',
  getListofSprintBacklogs:'/phyCorp/control/api/v1/scrumNewController/getListofSprintBacklogs',
  getProductBacklogItemStatusList:'/phyCorp/control/api/v1/scrumNewController/getProductBacklogItemStatusList',
  updateProductBacklogs:'/phyCorp/control/api/v1/scrumNewController/updateProductBacklogs',
  createProdBacklogNote:'/phyCorp/control/api/v1/scrumNewController/createProdBacklogNote',
  getBacklogItemNoteList:'/phyCorp/control/api/v1/scrumNewController/getBacklogItemNoteList',
  deleteProdBacklogNote:'/phyCorp/control/api/v1/scrumNewController/deleteProdBacklogNote',
  findOpenTestBacklog:'/phyCorp/control/api/v1/scrumNewController/findBacklog',
  createTaskForBacklog:'/phyCorp/control/api/v1/scrumNewController/createTaskForBacklog',
  getAssignTo:'/phyCorp/control/api/v1/scrumNewController/getAssignTo',
  getProjectID:'/phyCorp/control/api/v1/scrumNewController/getProjectID',
  getRoleTypeList:'/phyCorp/control/api/v1/scrumNewController/getRoleTypeList',
  getStatusTaskList:'/phyCorp/control/api/v1/scrumNewController/getStatusList',
  getUnplannedList:'/phyCorp/control/api/v1/scrumNewController/getUnplannedList',
  updateProductBacklog:'/phyCorp/control/api/v1/scrumNewController/updateProductBacklog',
  getSprintBacklogList:'/phyCorp/control/api/v1/scrumNewController/getSprintBacklogList',
  findTask:'/phyCorp/control/api/v1/scrumNewController/findTask',
  getProjectMembers:'/phyCorp/control/api/v1/scrumNewController/getProjectMembers',
  addMemberTask:'/phyCorp/control/api/v1/scrumNewController/addMemberTask',
  getNoteOverview:'/phyCorp/control/api/v1/scrumNewController/getNoteOverview',
  getUpdatePage:'/phyCorp/control/api/v1/scrumNewController/getUpdatePage',
  ProjectMembersRole:'/phyCorp/control/api/v1/scrumNewController/ProjectMembersRole',
  updateTask:'/phyCorp/control/api/v1/scrumNewController/updateTask',
  setTaskStatus:'/phyCorp/control/api/v1/scrumNewController/setTaskStatus',
  removeMemberTask:'/phyCorp/control/api/v1/scrumNewController/removeMemberTask',
  createTaskNote:'/phyCorp/control/api/v1/scrumNewController/createTaskNote',
  deleteTaskNote:'/phyCorp/control/api/v1/scrumNewController/deleteTaskNote',
  getTaskAttachments:'/phyCorp/control/api/v1/scrumNewController/getTaskAttachments',
  getTaskOverview:'/phyCorp/control/api/v1/scrumNewController/getTaskOverview',
  FindTimeSheet:'/phyCorp/control/api/v1/scrumNewController/FindTimeSheet',
  getScrumPerson:'/phyCorp/control/api/v1/scrumNewController/getPerson',
  getScrumMembersList:'/phyCorp/control/api/v1/scrumNewController/getScrumMembersList',
  getRateTypeList:'/phyCorp/control/api/v1/scrumNewController/getRateTypeList',
  addScrumMember:'/phyCorp/control/api/v1/scrumNewController/addScrumMember',
  createNewParty:'/phyCorp/control/api/v1/scrumNewController/createNewParty',
  createPartyRelationship:'/phyCorp/control/api/v1/scrumNewController/createPartyRelationship',
  getGroupIdList:'/phyCorp/control/api/v1/scrumNewController/getGroupIdList',
  getDefaultTaskList:'/phyCorp/control/api/v1/scrumNewController/getDefaultTaskList',
  getParty:'/phyCorp/control/api/v1/scrumNewController/getParty',
  removeScrumMember:'/phyCorp/control/api/v1/scrumNewController/removeScrumMember',
  updateDefaultTasks:'/phyCorp/control/api/v1/scrumNewController/updateDefaultTasks',
  getDefaultTask:'/phyCorp/control/api/v1/scrumNewController/getDefaultTask',
  createDefaultTasks:'/phyCorp/control/api/v1/scrumNewController/createDefaultTasks',
  QuickAddBacklog:'/phyCorp/control/api/v1/scrumNewController/QuickAddBacklog',
  deleteDefaultTasks:'/phyCorp/control/api/v1/scrumNewController/deleteDefaultTasks',
  GetUnplanBacklogList:'/phyCorp/control/api/v1/scrumNewController/GetUnplanBacklogList',
  DeleteUnplanBacklog:'/phyCorp/control/api/v1/scrumNewController/DeleteUnplanBacklog',
  getFindBillingAdministration:'/phyCorp/control/api/v1/scrumNewController/getFindBillingAdministration',
  getFindTotalBacklogAdministration:'/phyCorp/control/api/v1/scrumNewController/getFindTotalBacklogAdministration',
  updateQuickAddPartyRate:'/phyCorp/control/api/v1/scrumNewController/updateQuickAddPartyRate',
  setCompleteFromUnplanBacklog:'/phyCorp/control/api/v1/scrumNewController/setCompleteFromUnplanBacklog',
  addTask:'/phyCorp/control/api/v1/scrumNewController/addTask',
  wasChanged:'/phyCorp/control/api/v1/scrumProfileController/wasChanged',
  getLoyaltyPoints:'/phyCorp/control/api/v1/scrumProfileController/getLoyaltyPoints',
  getIdentificationNumbersNew:'/phyCorp/control/api/v1/party/identificationList',
  updateProfilePerson:'/phyCorp/control/api/v1/hr/employee/:partyId',
  getVisits:'/phyCorp/control/api/v1/scrumProfileController/getVisits',
  getUserName:'/phyCorp/control/api/v1/party/getUserLogin',
  getAttributeParty:'/phyCorp/control/api/v1/party/getPartyAttribute',
  getPartyNotes:'/phyCorp/control/api/v1/crm/contacts/partyNote/:partyId',
  getPartyContentProfileList: '/phyCorp/control/api/v1/crm/partyContent/:partyId',
  getTaskListData:'/phyCorp/control/api/v1/workEffort/getAssignedTaskList',
  getSummaryById:'/phyCorp/control/api/v1/workEffort/getSummaryById/:workEffortId',
  getWorkEffortPartyAssignments:'/phyCorp/control/api/v1/workEffort/getWorkEffortPartyAssignments',
  getPartyVisitors:'/phyCorp/control/api/v1/party/getVisitors',
  getStores:'/phyCorp/control/api/v1/party/getProductStore',
  getPartyIcsAvsOver:'/phyCorp/control/api/v1/scrumProfileController/getPartyIcsAvsOverRide',
  getProfileAccountsList: '/phyCorp/control/api/v1/crm/accounts',
  RelatedAccount: '/phyCorp/control/api/v1/crm/contactRelatedAccount',
  resetProfileAvsOverride:'/phyCorp/control/api/v1/party/resetAvsOverride',
  getPartyAvsOverRide:'/phyCorp/control/api/v1/party/getPartyIcsAvsOverRide',
  updateProfileOverride:'/phyCorp/control/api/v1/party/updateAvsOverride',
  getWorkEffortEventReminderById:'/phyCorp/control/api/v1/workEffort/getWorkEffortEventReminderById',
  deleteWorkEffortEventReminder:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortEventReminder',
  getContactMechId:'/phyCorp/control/api/v1/workEffort/getContactMechId',
  createWorkEffortEventReminder:'/phyCorp/control/api/v1/workEffort/createWorkEffortEventReminder',
  updateWorkEffortEventReminder:'/phyCorp/control/api/v1/workEffort/updateWorkEffortEventReminder',
  getProfileStatus: '/phyCorp/control/api/v1/resources/CRMStatus',
  getProjectName:'/phyCorp/control/api/v1/project/getProjectName',
  contactTypes:'/phyCorp/control/api/v1/scrumProfileController/contactTypes',
  createElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap:'/phyCorp/control/api/v1/scrumProfileController/internetIpAddress',
  createPostalAddress:'/phyCorp/control/api/v1/scrumProfileController/postalAddress',
  createEmailAddress:'/phyCorp/control/api/v1/scrumProfileController/createEmailAddress',
  createTelecomNumber:'/phyCorp/control/api/v1/scrumProfileController/createTelecomNumber',
  createprofileUserLogin:'/phyCorp/control/api/v1/party/createProfileUserLogin',
  updatePartyProfileIdentifications:'/phyCorp/control/api/v1/party/updatePartyIdentifications',
  createProfileIdentifications:'/phyCorp/control/api/v1/party/createPartyIdentifications',
  identificationProfileList:'/phyCorp/control/api/v1/party/getIdentificationTypeId',
  updatePartyProfileAttribute:'/phyCorp/control/api/v1/party/updatePartyAttribute',
  getWorkEffortAttributeById:'/phyCorp/control/api/v1/workEffort/getWorkEffortAttributeById',
  deleteWorkEffortAttribute:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortAttribute',
  createWorkEffortAttribute:'/phyCorp/control/api/v1/workEffort/createWorkEffortAttribute',
  updateWorkEffortAttribute:'/phyCorp/control/api/v1/workEffort/updateWorkEffortAttribute',
  createWorkEffortKeyword:'/phyCorp/control/api/v1/workEffort/createWorkEffortKeyword',
  getWorkEffortKeyword:'/phyCorp/control/api/v1/workEffort/getWorkEffortKeyword',
  deleteWorkEffortKeyword:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortKeyword',
  getWorkEffortAgreementById:'/phyCorp/control/api/v1/workEffort/getWorkEffortAgreementById',
  deleteAgreementWorkEffortApplic:'/phyCorp/control/api/v1/workEffort/deleteAgreementWorkEffortApplic',
  getAgreementId:'/phyCorp/control/api/v1/employment/Agreement ID',
  getAgreementItemSeqId:'/phyCorp/control/api/v1/employment/AgreementItemSeqId',
  createAgreementWorkEffortApplic:'/phyCorp/control/api/v1/workEffort/createAgreementWorkEffortApplic',
  getWorkEffortContactById:'/phyCorp/control/api/v1/workEffort/getWorkEffortContactById',
  deleteWorkEffortContactMech:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortContactMech',
  getContactMechType:'/phyCorp/control/api/v1/workEffort/geContactMechType',
  createWorkEffortContactMech:'/phyCorp/control/api/v1/workEffort/createWorkEffortContactMech',
  getPriorityList:'/phyCorp/control/api/v1/projects/getPriorityList',
  getScopeList:'/phyCorp/control/api/v1/projects/getScopeList',
  getParentProjectNameList:'/phyCorp/control/api/v1/projects/getParentProjectNameList',
  getInternalOrganizationFindEmployment:'/phyCorp/control/api/v1/employment/getInternalOrganizationFindEmployment',
  getTemplateId:'/phyCorp/control/api/v1/project/getTemplateId',
  deleteProfileIdentification:'/phyCorp/control/api/v1/scrumProfileController/deletePartyIdentification',
  createCheckAccount:'/phyCorp/control/api/v1/scrumProfileController/createCheckAccount',
  createEFTAccount:'/phyCorp/control/api/v1/scrumProfileController/createEFTAccount',
  deleteAddUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/expireAddUserLoginToSecurityGroup',
  getListAddUserLoginToSecurityGroup:'/phyCorp/control/api/v1/party/ListAddUserLoginToSecurityGroup',
  getSecurityList:'/phyCorp/control/api/v1/securityGroup/lookup',
  profileAddUserLoginSecurityGroup:'/phyCorp/control/api/v1/party/profileAddUserLoginToSecurityGroup',
  updateAddUserLoginSecurityGroup:'/phyCorp/control/api/v1/party/updateAddUserLoginToSecurityGroup',
  createGiftCard:'/phyCorp/control/api/v1/scrumProfileController/createGiftCard',
  getWorkEffortFixedAssetAssign:'/phyCorp/control/api/v1/workEffort/getWorkEffortFixedAssetAssign',
  deleteWorkEffortFixedAssetAssign:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortFixedAssetAssign',
  getFixedAssetStatusList:'/phyCorp/control/api/v1/workEffort/getFixedAssetStatusList',
  getFixedAssetList:'/phyCorp/control/api/v1/workEffort/getFixedAssetList',
  createWorkEffortFixedAssetAssign:'/phyCorp/control/api/v1/workEffort/createWorkEffortFixedAssetAssign',
  updateWorkEffortFixedAssetAssign:'/phyCorp/control/api/v1/workEffort/updateWorkEffortFixedAssetAssign',
  getWorkEffortGoodStandardId:'/phyCorp/control/api/v1/workEffort/getWorkEffortGoodStandardId',
  removeWorkEffortGoodStandard:'/phyCorp/control/api/v1/workEffort/removeWorkEffortGoodStandard',
  getProductId:'/phyCorp/control/api/v1/workEffort/getProductId',
  getWorkEffortGoodStdTypeID:'/phyCorp/control/api/v1/workEffort/getWorkEffortGoodStdTypeID',
  createWorkEffortGoodStandard:'/phyCorp/control/api/v1/workEffort/createWorkEffortGoodStandard',
  updateWorkEffortGoodStandard:'/phyCorp/control/api/v1/workEffort/updateWorkEffortGoodStandard',
  getVisitPersonsPartyId: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  createPostRoleTypeData:'/phyCorp/control/api/v1/partyRoles/roleTypes',
  partyContentProfile: '/phyCorp/control/api/v1/resources/contentPurposeRoleType',
  findVisitorsList:'/phyCorp/control/api/v1/party/findVisitors',
  deleteProfileContent: '/phyCorp/control/api/v1/crm/partyContent',
  downloadProfileContent: '/phyCorp/control/api/v1/crm/partyContent/view',
  deleteRoleType:'/phyCorp/control/api/v1/partyRoles',
  getVisitorsList:'/phyCorp/control/api/v1/party/getVisitors',
  getPartyRoleList:'/phyCorp/control/api/v1/partyRoles',
  attachProfileContent: '/phyCorp/control/api/v1/crm/partyContent',
  createProfileGiftCard:'/phyCorp/control/api/v1/crm/paymentMethods/:partyId',
  createPartyNote:'/phyCorp/control/api/v1/scrumProfileController/createPartyNote',
  getWorkEffortReviewById:'/phyCorp/control/api/v1/workEffort/getWorkEffortReviewById',
  deleteWorkEffortReview:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortReview',
  getUserLoginId:'/phyCorp/control/api/v1/workEffort/getUserLoginId',
  createWorkEffortReview:'/phyCorp/control/api/v1/workEffort/createWorkEffortReview',
  updateWorkEffortReview:'/phyCorp/control/api/v1/workEffort/updateWorkEffortReview',
  createBillingAccount:'/phyCorp/control/api/v1/scrumProfileController',
  updateProfileContent:'/phyCorp/control/api/v1/party/updatePartyContent',
  createProfileContent:'/phyCorp/control/api/v1/party/createPartyContent',
  updateBillingAccount:'/phyCorp/control/api/v1/updateBillingAccount',
  getpartyContentDropdown: '/phyCorp/control/api/v1/resources/contentPurposeRoleType',
  deleteProfileContentData:'/phyCorp/control/api/v1/party/deletePartyContent',
  getDataCategoryIdTypeList:'/phyCorp/control/api/v1/party/getDataCategoryIdList',
  getProfileStatusIdList:'/phyCorp/control/api/v1/party/getPartyStatusIdList',
  getProfileContentTypeIdList:'/phyCorp/control/api/v1/party/getPartyContentTypeIdList',
  getProfileContentList:'/phyCorp/control/api/v1/party/getPartyContent',
  updateElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap:'/phyCorp/control/api/v1/scrumProfileController/internetIpAddress',
  getRevisions:'/phyCorp/control/api/v1/scrumProfileController/getRevisions',
  getResidenceStatusEnumID:'/phyCorp/control/api/v1/scrumProfileController/getResidenceStatusEnumID',
  getEmploymentStatusEnumId:'/phyCorp/control/api/v1/scrumProfileController/getEmploymentStatusEnumId',
  getProjectSummary:'/phyCorp/control/api/v1/project/getProjectSummary',
  getRateTypeId:'/phyCorp/control/api/v1/party/getRateTypeId',
  getWorkEffortName:'/phyCorp/control/api/v1/project/getWorkEffortName',
  orderIdList:'/phyCorp/control/api/v1/project/orderIdList',
  createAddNewWeeklyTimesheet:'/phyCorp/control/api/v1/scrumNewController/createAddNewWeeklyTimesheet',
  createWeeklyTimesheet:'/phyCorp/control/api/v1/scrumNewController/createWeeklyTimesheet',
  getRoleTypeList_profile:'/phyCorp/control/api/v1/scrumNewController/getRoleTypeList_profile',
  addWorkEffortPartyAssign:'/phyCorp/control/api/v1/scrumNewController/addWorkEffortPartyAssign',
  listResourcesProject:'/phyCorp/control/api/v1/scrumNewController/listResourcesProject',
  deleterole:'/phyCorp/control/api/v1/scrumNewController/deleterole',
  createProject:'/phyCorp/control/api/v1/projects/createProject',
  getContactListTypeId:'/phyCorp/control/api/v1/marketingContactListController/getContactListTypeId',
  getContactMechanismTypeID:'/phyCorp/control/api/v1/marketingContactListController/getContactMechanismTypeID',
  getMarketingCampaign:'/phyCorp/control/api/v1/marketingDataSourceController/getMarketingCampaign',
  getVerifyEmailWebsiteId:'/phyCorp/control/api/v1/marketingContactListController/getVerifyEmailWebsiteId',
  getDataSourceTypeId:'/phyCorp/control/api/v1/marketingCampaignController/getDataSourceTypeId',
  getMarketCampaignId:'/phyCorp/control/api/v1/marketingDataSourceController/getMarketCampaignId',
  getStatusLists:'/phyCorp/control/api/v1/marketingDataSourceController/getStatusList',
  getCurrencyList:'/phyCorp/control/api/v1/marketingDataSourceController/getCurrencyList',
  getTrackingCodeTypeId:'/phyCorp/control/api/v1/marketingTrackingController/getTrackingCodeType',
  getMarketingCampaignIdsList:'/phyCorp/control/api/v1/marketingTrackingController/getMarketingCampaignIdsList',
  getCurrencyDataList: '/phyCorp/control/api/v1/resources/currency',
  getRatesData:'/phyCorp/control/api/v1/accounting/rateAmount/initialData',
  updatePartyRate:'/phyCorp/control/api/v1/scrumNewController/updatePartyRate',
  getRates:'/phyCorp/control/api/v1/scrumNewController/getRatesList',
  getProfileRoleList:'/phyCorp/control/api/v1/scrumNewController/getRoleTypeList_profile',
  deleteProfileRole:'/phyCorp/control/api/v1/partyRoles',
  deleteRoleProfile:'/phyCorp/control/api/v1/scrumNewController/deleterole',
  viewroles:'/phyCorp/control/api/v1/scrumNewController/viewroles',
  addRole:'/phyCorp/control/api/v1/scrumNewController/addRole',
  defaultProductCatalogId:'/phyCorp/control/api/v1/marketingTrackingController/defaultProductCatalogId',
  getSegmentGroupTypeID:'/phyCorp/control/api/v1/marketingCampaignController/getSegmentGroupTypeID',
  getProductStoreID:'/phyCorp/control/api/v1/marketingCampaignController/getProductStoreID',
  getDataSourceTypeIds:'/phyCorp/control/api/v1/marketingCampaignController/getDataSourceTypeId',
  getDataSourceType:'/phyCorp/control/api/v1/marketingCampaignController/getDataSourceTypeId',
  getMarketCampaignIds:'/phyCorp/control/api/v1/marketingDataSourceController/getMarketCampaignId',
  getStatusListss:'/phyCorp/control/api/v1/marketingDataSourceController/getStatusList',
  getCurrencyLists:'/phyCorp/control/api/v1/marketingDataSourceController/getCurrencyList',
  getSegmentGroupTypeIDs:'/phyCorp/control/api/v1/marketingCampaignController/getSegmentGroupTypeID',
  getProductStoreIDs:'/phyCorp/control/api/v1/marketingCampaignController/getProductStoreID',
  getTrackingCodeTypeIds:'/phyCorp/control/api/v1/marketingTrackingController/getTrackingCodeType',
  getMarketingCampaignIdsLists:'/phyCorp/control/api/v1/marketingTrackingController/getMarketingCampaignIdsList',
  defaultProductCatalogIds:'/phyCorp/control/api/v1/marketingTrackingController/defaultProductCatalogId',
  getTrackingCodeTypesIds:'/phyCorp/control/api/v1/marketingTrackingController/getTrackingCodeType',
  getMarketingCampaignsIdsLists:'/phyCorp/control/api/v1/marketingTrackingController/getMarketingCampaignIdsList',
  defaultProductCatalogsIds:'/phyCorp/control/api/v1/marketingTrackingController/defaultProductCatalogId',
  getRoleTypeId:'/phyCorp/control/api/v1/marketingDataSourceController/getRoleTypeId',
  getTrackingCode:'/phyCorp/control/api/v1/marketingContactListController/getTrackingCode',
  AddSubProject:'/phyCorp/control/api/v1/project/AddSubProject',
  addProjectTimeToNewInvoice:'/phyCorp/control/api/v1/project/addProjectTimeToNewInvoice',
  addProjectTimeToInvoice:'/phyCorp/control/api/v1/project/addProjectTimeToInvoice',
  getInvoiceId:'/phyCorp/control/api/v1/workEffort/getInvoiceId',
  getFindProject:'/phyCorp/control/api/v1/project/getFindProject',
  getorderId:'/phyCorp/control/api/v1/project/orderIdList',
  createOrderId:'/phyCorp/control/api/v1/project/createOrderId',
  updateProject:'/phyCorp/control/api/v1/projects/updateProject',
  getEmailStatusReport:'/phyCorp/control/api/v1/marketingContactListController/getEmailStatusReport',
  getPartyStatusReport:'/phyCorp/control/api/v1/marketingContactListController/getPartyStatusReport',
  updatePostalAddressProfile:'/phyCorp/control/api/v1/scrumProfileController/updatePostalAddress',
  updateTeleComNumber:'/phyCorp/control/api/v1/scrumProfileController/updateTelecomNumber',
  getFindScrumMembersList:'/phyCorp/control/api/v1/scrumNewController/getFindScrumMembersList',
  getFindScrumMembers:'/phyCorp/control/api/v1/scrumNewController/getFindScrumMembersList',
  getContactListName:'/phyCorp/control/api/v1/marketingContactListController/getContactListName',
  getType:'/phyCorp/control/api/v1/workEffort/getType',
  getPurpose:'/phyCorp/control/api/v1/workEffort/getPurpose',
  getTemporalExpression:'/phyCorp/control/api/v1/workEffort/getTemporalExpression',
  getFacility:'/phyCorp/control/api/v1/workEffort/getFacility',
  getMyRates:'/phyCorp/control/api/v1/workEffort/getMyRates',
  getWorkEffortAssocType:'/phyCorp/control/api/v1/workEffort/getWorkEffortAssocType',
  findMailing:'/phyCorp/control/api/v1/project/findMailing',
  findMailingList:'/phyCorp/control/api/v1/project/findMailing',
  getResourceParty:'/phyCorp/control/api/v1/scrumNewController/getProductMemberParty',
  getRoleResourceList:'/phyCorp/control/api/v1/project/getRoleList',
  addResource:'/phyCorp/control/api/v1/project/addResource',
  getResourcePage:'/phyCorp/control/api/v1/project/getResourcePage',
  removeResource:'/phyCorp/control/api/v1/project/removeResource',
  getDataSource:'/phyCorp/control/api/v1/marketingCampaignController/getDataSource',
  deleteDataSource:'/phyCorp/control/api/v1/marketingCampaignController/deleteDataSource',
  createDataSource:'/phyCorp/control/api/v1/marketingCampaignController/createDataSource',
  getDataSourceTypeValue:'/phyCorp/control/api/v1/marketingCampaignController/getDataSourceType',
  deleteDataSourceType:'/phyCorp/control/api/v1/marketingCampaignController/deleteDataSourceType',
  createDataSourceType:'/phyCorp/control/api/v1/marketingCampaignController/createDataSourceType',
  updateDataSourceType:'/phyCorp/control/api/v1/marketingCampaignController/updateDataSourceType',
  updateDataSource:'/phyCorp/control/api/v1/marketingCampaignController/updateDataSource',
  findPartyRevision:'/phyCorp/control/api/v1/scrumNewController/findPartyRevision',
  findPartyRevisionList:'/phyCorp/control/api/v1/scrumNewController/findPartyRevision',
  getSkillTypeIdList:'/phyCorp/control/api/v1/project/getSkillTypeIdList',
  getPartyIdList:'/phyCorp/control/api/v1/project/getPartyIdLIst',
  addAssignAndExistingTask:'/phyCorp/control/api/v1/project/assignAndExistingTask',
  getRoleTaskList:'/phyCorp/control/api/v1/project/getRoleList',
  createNewTask:'/phyCorp/control/api/v1/project/createNewTask',
  getParentPhaseList:'/phyCorp/control/api/v1/project/getParentPhaseList',
  getTaskScopeList:'/phyCorp/control/api/v1/project/getScopeList',
  getTasksNames:'/phyCorp/control/api/v1/project/getTasksNames  ',
  getSkillTypeId:'/phyCorp/control/api/v1/project/getSkillTypeIdList',
  getTypeList:'/phyCorp/control/api/v1/project/getTypeList',
  getTaskNameList:'/phyCorp/control/api/v1/project/getTaskNameList',
  createSkillType:'/phyCorp/control/api/v1/hr/skills/skillType',
  deleteSkillListType:'/phyCorp/control/api/v1/hr/skills/deleteSkillType',
  getMarketingCampaignDetailData:'/phyCorp/control/api/v1/marketingDataSourceController/getMarketingCampaign',
  createMarketingCampaign:'/phyCorp/control/api/v1/marketingDataSourceController/createMarketingCampaign',
  getStatusNewWorkEffort:'/phyCorp/control/api/v1/workEffort/getStatusNewWorkEffort',
  getCommunicationEventId:'/phyCorp/control/api/v1/workEffort/getCommunicationEventId',
  getFacilityIdList:'/phyCorp/control/api/v1/workEffort/getFacilityIdList',
  getRequirementList:'/phyCorp/control/api/v1/workEffort/getRequirementList',
  deleteProjectPartyAssign:'/phyCorp/control/api/v1/scrumNewController/deleteProjectPartyAssign',
  getPartyList:'/phyCorp/control/api/v1/project/getPartyIdLIst',
  deleteprofileRole:'/phyCorp/control/api/v1/scrumNewController/deleterole',
  deleteNewTask:'/phyCorp/control/api/v1/project/deleteTaskNew',
  statusToComplete:'/phyCorp/control/api/v1/project/statusToComplete',
  getMarketingCampaignById:'/phyCorp/control/api/v1/marketingDataSourceController/getMarketingCampaignById/:id',
  updateMarketingCampaign:'/phyCorp/control/api/v1/marketingDataSourceController/updateMarketingCampaign',
  createMarketingCampaignRole:'/phyCorp/control/api/v1/marketingDataSourceController/createMarketingCampaignRole',
  getMarketingCampaignRole:'/phyCorp/control/api/v1/marketingDataSourceController/getMarketingCampaignRole',
  expireMarketingCampaignRole:'/phyCorp/control/api/v1/marketingDataSourceController/expireMarketingCampaignRole',
  getStatusListsss:'/phyCorp/control/api/v1/workEffort/getStatusList',
  getAvailabilityList:'/phyCorp/control/api/v1/workEffort/getAvailabilityList',
  getExpectationList:'/phyCorp/control/api/v1/workEffort/getExpectationList',
  getDelegateReasonList:'/phyCorp/control/api/v1/workEffort/getDelegateReasonList',
  getWorkEffortRate:'/phyCorp/control/api/v1/workEffort/getWorkEffortRate',
  getFindTask:'/phyCorp/control/api/v1/project/getFindTask',
  getFindTaskList:'/phyCorp/control/api/v1/project/getFindTask',
  getDependencyTo:'/phyCorp/control/api/v1/project/getDependency',
  getDependencyFrom:'/phyCorp/control/api/v1/project/getDependencyFrom',
  getFindTaskInformationById:'/phyCorp/control/api/v1/project/getFindTaskInformationById',
  getTaskNoteList:'/phyCorp/control/api/v1/project/getTaskNoteList',
  getTrackingCodeList:'/phyCorp/control/api/v1/marketingTrackingController/getTrackingCodeList',
  createTrackingCode:'/phyCorp/control/api/v1/marketingTrackingController/createTrackingCode',
  deleteTrackingCode:'/phyCorp/control/api/v1/marketingTrackingController/deleteTrackingCode',
  getTrackCodeTypeList:'/phyCorp/control/api/v1/marketingTrackingController/getTrackCodeTypeList',
  deleteTrackCodeTypeById:'/phyCorp/control/api/v1/marketingTrackingController/deleteTrackCodeTypeById',
  createTrackingCodeType:'/phyCorp/control/api/v1/marketingTrackingController/createTrackingCodeType',
  updateTrackingCodeType:'/phyCorp/control/api/v1/marketingTrackingController/updateTrackingCodeType',
  updateTrackingCode:'/phyCorp/control/api/v1/marketingTrackingController/updateTrackingCode',
  getSurveyList:'/phyCorp/control/api/v1/project/getSurveyList',
  makePrivate:'/phyCorp/control/api/v1/project/addTaskNote',
  getPhaseList:'/phyCorp/control/api/v1/project/getParentPhaseList',
  getSurveyData:'/phyCorp/control/api/v1/project/getSurveyData',
  getRelatedTask:'/phyCorp/control/api/v1/project/getRelatedTask',
  createNewTasks:'/phyCorp/control/api/v1/project/createNewTask',
  removeDependencyAfter:'/phyCorp/control/api/v1/project/removeDependency',
  deleteSkillTypes: '/phyCorp/control/api/v1/hr/skills/skillType/:skillTypeId',
  addDependencyTo:'/phyCorp/control/api/v1/project/addDependencyTo',
  removeDependencyBefore:'/phyCorp/control/api/v1/project/removeDependencyBefore',
  removeTaskPartyAssign:'/phyCorp/control/api/v1/project/removeTaskPartyAssign',
  updateTaskPartyAssign:'/phyCorp/control/api/v1/project/updateTaskPartyAssign',
  addDependencyFrom:'/phyCorp/control/api/v1/project/addDependencyFrom',
  getResourceList:'/phyCorp/control/api/v1/project/getResourceList',
  getRelated:'/phyCorp/control/api/v1/project/getRelated',
  setCompleteTask:'/phyCorp/control/api/v1/project/setCompleteTask',
  updateTaskSave:'/phyCorp/control/api/v1/project/updateTaskSave',
  getTaskTeam:'/phyCorp/control/api/v1/project/getTaskTeam',
  getRequestList:'/phyCorp/control/api/v1/project/getRequestList',
  getCommunicationTypeId:'/phyCorp/control/api/v1/workEffort/getCommunicationTypeId',
  geContactMechType:'/phyCorp/control/api/v1/workEffort/geContactMechType',
  getRoleTypeIds:'/phyCorp/control/api/v1/workEffort/getRoleTypeId',
  getShoppingListId:'/phyCorp/control/api/v1/workEffort/getShoppingListId',
  getCustRequestIds:'/phyCorp/control/api/v1/workEffort/getCustRequestIds',
  getCustRequestItem:'/phyCorp/control/api/v1/workEffort/getCustRequestItem',
  getWorkReqFulfTypeId:'/phyCorp/control/api/v1/workEffort/getWorkReqFulfTypeId',
  getRequirementTypeList:'/phyCorp/control/api/v1/workEffort/getRequirementTypeList',
  getFindTaskInformation:'/phyCorp/control/api/v1/project/getFindTaskInformationById',
  updateMyTask:'/phyCorp/control/api/v1/project/updateTask',
  getTaskTeams:'/phyCorp/control/api/v1/project/getTaskTeam',
  createTaskPartyAssign:'/phyCorp/control/api/v1/project/createTaskPartyAssign',
  getSkillList:'/phyCorp/control/api/v1/project/getSkillListAAAAAAAAA',
  createTaskSkillStandard:'/phyCorp/control/api/v1/project/createTaskSkillStandard',
  updateTaskSkillStandard:'/phyCorp/control/api/v1/project/updateTaskSkillStandard',
  getQuoteIdList:'/phyCorp/control/api/v1/workEffort/getQuoteIdList',
  getQuoteItemList:'/phyCorp/control/api/v1/workEffort/getQuoteItemList',
  getOrderId:'/phyCorp/control/api/v1/workEffort/getOrderId',
  getTimeSheetId:'/phyCorp/control/api/v1/workEffort/getTimeSheetId',
  getContentTypeIds:'/phyCorp/control/api/v1/workEffort/getContentTypeId',
  getProductIdsList:'/phyCorp/control/api/v1/workEffort/getProductIdsList',
  getWorkEffortId:'/phyCorp/control/api/v1/workEffort/getWorkEffortId',
  getMimeTypeIds:'/phyCorp/control/api/v1/scrumNewController/getMimeTypeId',
  getContactListId:'/phyCorp/control/api/v1/workEffort/getContactListId',
  getICalender:'/phyCorp/control/api/v1/workEffort/getICalender',
  getRequestLists:'/phyCorp/control/api/v1/workEffort/getRequestList',
  getPartyAssignmentById:'/phyCorp/control/api/v1/workEffort/getPartyAssignmentById/:workEffortId',
  getEventType:'/phyCorp/control/api/v1/workEffort/getEventType',
  updateWorkEffort:'/phyCorp/control/api/v1/workEffort/updateWorkEffort',
  getWorkEffortDetailsById:'/phyCorp/control/api/v1/workEffort/getWorkEffortDetailsById',
  createTimeEntry:'/phyCorp/control/api/v1/project/createTimeEntry',
  getTimeSheetById:'/phyCorp/control/api/v1/project/getTimeSheetList/:workEffortId',
  getNoteList:'/phyCorp/control/api/v1/project/getTaskNoteList',
  updateTimeEntry:'/phyCorp/control/api/v1/project/updateTimeEntry',
  deleteTimeEntry:'/phyCorp/control/api/v1/project/deleteTimeEntry',
  addTaskNote:'/phyCorp/control/api/v1/project/makePrivate',
  updateTaskNote:'/phyCorp/control/api/v1/project/updateTaskNote',
  createWorkEffortSurveyAppl:'/phyCorp/control/api/v1/project/createWorkEffortSurveyAppl',
  updateWorkEffortSurveyAppl:'/phyCorp/control/api/v1/project/updateWorkEffortSurveyAppl',
  addPhase:'/phyCorp/control/api/v1/project/addPhase',
  updatePhase:'/phyCorp/control/api/v1/project/updatePhase',
  getPhaseById:'/phyCorp/control/api/v1/project/getPhaseById',
  getParticularOrderById:'/phyCorp/control/api/v1/project/getParticularOrderById',
  getProjectInformationById:'/phyCorp/control/api/v1/project/getProjectInformationById',
  getRoles:'/phyCorp/control/api/v1/project/getRole',
  taskById:'/phyCorp/control/api/v1/project/taskById',
  getResourceListByProjectId:'/phyCorp/control/api/v1/project/getResourceListByProjectId',
  deleteWorkEffortSurveyAppl:'/phyCorp/control/api/v1/project/deleteWorkEffortSurveyAppl',
  getSurveyID:'/phyCorp/control/api/v1/project/getSurveyID',
  existingContentIdList:'/phyCorp/control/api/v1/project/existingContentIdList',
  workEffortContentTypeList:'/phyCorp/control/api/v1/project/workEffortContentTypeList ',
  addTaskContent:'/phyCorp/control/api/v1/project/addTaskContent',
  parentContentIdList:'/phyCorp/control/api/v1/project/parentContentIdList',
  getFindTaskContentList:'/phyCorp/control/api/v1/project/getFindTaskContentById',
  deletedTaskContentById:'/phyCorp/control/api/v1/project/deletedTaskContentById',
  getFindTaskContentById:'/phyCorp/control/api/v1/project/getFindTaskContentById',
  getSkillTypeProject:'/phyCorp/control/api/v1/project/getSkillType',
  FindTimeSheetProject:'/phyCorp/control/api/v1/scrumNewController/FindTimeSheet',
  FindTimeSheetProjectList:'/phyCorp/control/api/v1/scrumNewController/FindTimeSheet',
  getQuoteItemSeqId:'/phyCorp/control/api/v1/workEffort/getQuoteItemSeqId',
  getContentId:'/phyCorp/control/api/v1/workEffort/getContentId',
  getWorkEffortRatePage:'/phyCorp/control/api/v1/workEffort/getWorkEffortRatePage',
  getCommunicationEventById:'/phyCorp/control/api/v1/workEffort/getCommunicationEventById',
  getWorkEffortRequestListById:'/phyCorp/control/api/v1/workEffort/getWorkEffortRequestListById/:workEffortId',
  getShoppingListWorkEffortById:'/phyCorp/control/api/v1/workEffort/getShoppingListWorkEffortById/:workEffortId',
  getQuoteById:'/phyCorp/control/api/v1/workEffort/getQuoteById/:workEffortId',
  getOrderListById:'/phyCorp/control/api/v1/workEffort/getOrderListById',
  getrateTypeId:'/phyCorp/control/api/v1/party/getRateTypeId',
  getworkEffortName:'/phyCorp/control/api/v1/project/getWorkEffortName',
  uploadFileTaskContent:'/phyCorp/control/api/v1/project/uploadFileTaskContent',
  createWeeklyProTimesheet:'/phyCorp/control/api/v1/project/createWeeklyTimesheet',
  MyStatusToComplete:'/phyCorp/control/api/v1/myPortal/updateTimesheet',
  timeSheetList:'/phyCorp/control/api/v1/scrumNewController/getTimeSheetList',
  getRateData:'/phyCorp/control/api/v1/accounting/rateAmount/initialData',
  downloadFileTaskContent:'/phyCorp/control/api/v1/project/downloadFileTaskContent',
  getSubProjectsById:'/phyCorp/control/api/v1/project/getSubProjectsById',
  getContentDetailsById:'/phyCorp/control/api/v1/workEffort/getContentDetailsById/:workEffortId',
  getWorkEffortRequestById:'/phyCorp/control/api/v1/workEffort/getWorkEffortRequestById/:workEffortId',
  getNotesByWorkEffort:'/phyCorp/control/api/v1/project/getNotesByWorkEffort',
  getMyTime:'/phyCorp/control/api/v1/workEffort/getMyTime',
  findContactList:'/phyCorp/control/api/v1/marketingContactListController/findContactList',
  getfindContactList:'/phyCorp/control/api/v1/marketingContactListController/findContactList',
  getMainPageRequestListDetails:'/phyCorp/control/api/v1/project/getMainPageRequestListDetails',
  getMainPageSecondRequestListDetails:'/phyCorp/control/api/v1/project/getMainPageSecondRequestListDetails',
  getSegmentGroupFrontPage:'/phyCorp/control/api/v1/marketingCampaignController/getSegmentGroupFrontPage',
  deleteSegmentGroup:'/phyCorp/control/api/v1/marketingCampaignController/deleteSegmentGroup',
  createSegmentGroup:'/phyCorp/control/api/v1/marketingCampaignController/createSegmentGroup',
  getSegmentGroupById:'/phyCorp/control/api/v1/marketingCampaignController/getSegmentGroup',
  updateSegmentGroup:'/phyCorp/control/api/v1/marketingCampaignController/updateSegmentGroup',
  deleteOrderById:'/phyCorp/control/api/v1/project/deleteOrderById',
  getTaskInformation:'/phyCorp/control/api/v1/project/getFindTaskInformationById',
  getPartyId: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  CreateTaskFromRequest:'/phyCorp/control/api/v1/project/CreateTaskFromRequest',
  getCustRequestTypeID:'/phyCorp/control/api/v1/project/getCustRequestTypeID',
  getProjectAndPhaseName:'/phyCorp/control/api/v1/project/getProjectAndPhaseName',
  getParentCustRequestId:'/phyCorp/control/api/v1/project/getParentCustRequestId  ',
  createRequest:'/phyCorp/control/api/v1/project/createRequest ',
  getSegmentGroupClassification:'/phyCorp/control/api/v1/marketingCampaignController/getSegmentGroupClassification',
  deleteSegmentGroupClassification:'/phyCorp/control/api/v1/marketingCampaignController/deleteSegmentGroupClassification',
  getpartyClassificationGroupId:'/phyCorp/control/api/v1/marketingCampaignController/getpartyClassificationGroupId',
  createSegmentGroupClassification:'/phyCorp/control/api/v1/marketingCampaignController/createSegmentGroupClassification',
  getGeoById:'/phyCorp/control/api/v1/marketingCampaignController/getGeoById',
  deleteSegmentGroupGeo:'/phyCorp/control/api/v1/marketingCampaignController/deleteSegmentGroupGeo',
  getGeoId:'/phyCorp/control/api/v1/marketingCampaignController/getGeoId',
  createSegmentGroupGeo:'/phyCorp/control/api/v1/marketingCampaignController/createSegmentGroupGeo',
  getGeoRoleById:'/phyCorp/control/api/v1/marketingCampaignController/getGeoRoleById',
  deleteSegmentGroupRole:'/phyCorp/control/api/v1/marketingCampaignController/deleteSegmentGroupRole',
  createSegmentGroupRole:'/phyCorp/control/api/v1/marketingCampaignController/createSegmentGroupRole',
  getClientContacts:'/phyCorp/control/api/v1/project/getClientContacts',
  getCompanies:'/phyCorp/control/api/v1/project/getCompanies',
  findWorkEffort:'/phyCorp/control/api/v1/workEffort/findWorkEffort',
  findTimesheet:'/phyCorp/control/api/v1/workEffort/findTimesheet',
  getNotesByWorkEffortIdAndNoteId:'/phyCorp/control/api/v1/project/getNotesByWorkEffortIdAndNoteId',
  createTimeSheetData:'/phyCorp/control/api/v1/workEffort/createTimeSheet',
  updaterequest:'/phyCorp/control/api/v1/project/updaterequest  ',
  updateCustRequestParty:'/phyCorp/control/api/v1/project/updateCustRequestParty ',
  createCustRequestParty:'/phyCorp/control/api/v1/project/createCustRequestParty  ',
  getRequestRolesAll:'/phyCorp/control/api/v1/project/getRequestRolesAll',
  getRequestRolesById:'/phyCorp/control/api/v1/project/getRequestRolesById',
  expireCustRequestParty:'/phyCorp/control/api/v1/project/expireCustRequestParty',
  deleteCustRequestParty:'/phyCorp/control/api/v1/project/deleteCustRequestParty',
  getRequestItemData:'/phyCorp/control/api/v1/project/getRequestItemData',
  getRoleTypeIdRequest:'/phyCorp/control/api/v1/project/getRoleTypeIdList',
  getTrackingCodeReport:'/phyCorp/control/api/v1/marketingContactListController/getTrackingCodeReport',
  getEmailReport:'/phyCorp/control/api/v1/marketingContactListController/getEmailReport',
  getPartyReport:'/phyCorp/control/api/v1/marketingContactListController/getPartyReport',
  getMarketCampaignReport:'/phyCorp/control/api/v1/marketingContactListController/getMarketCampaignReport',
  createrequestitem:'/phyCorp/control/api/v1/project/createrequestitem',
  updaterequestitem:'/phyCorp/control/api/v1/project/updaterequestitem',
  getRequestItemsStatusId:'/phyCorp/control/api/v1/project/getRequestItemsStatusId',
  copyCustRequestItem:'/phyCorp/control/api/v1/project/copyCustRequestItem  ',
  getProductProductId:'/phyCorp/control/api/v1/project/getProductProductId ',
  getRequestItemNote:'/phyCorp/control/api/v1/project/getRequestItemNote',
  createRequestItemNote:'/phyCorp/control/api/v1/project/createRequestItemNote ',
  getTaskFind:'/phyCorp/control/api/v1/project/getTaskFind',
  getWorkEfforts:'/phyCorp/control/api/v1/project/getWorkEfforts',
  getRequestInformation:'/phyCorp/control/api/v1/project/getRequestInformation',
  createCustRequestContent:'/phyCorp/control/api/v1/project/createCustRequestContent',
  getCustRequestContentById:'/phyCorp/control/api/v1/project/getCustRequestContentById',
  deleteCustRequestContent:'/phyCorp/control/api/v1/project/deleteCustRequestContent',
  expireCustRequestContent:'/phyCorp/control/api/v1/project/expireCustRequestContent',
  getCommunicationEvents:'/phyCorp/control/api/v1/project/getCommunicationEvents',
  creatingContactList:'/phyCorp/control/api/v1/marketingContactListController/creatingContactList',
  getContactListById:'/phyCorp/control/api/v1/marketingContactListController/getContactListById',
  updateContactListMarket:'/phyCorp/control/api/v1/marketingContactListController/updateContactList',
  getWebSiteContactListById:'/phyCorp/control/api/v1/marketingContactListController/getWebSiteContactListById',
  deleteWebSiteContactList:'/phyCorp/control/api/v1/marketingContactListController/deleteWebSiteContactList',
  createWebSiteContactList:'/phyCorp/control/api/v1/marketingContactListController/createWebSiteContactList',
  updateWebSiteContactList:'/phyCorp/control/api/v1/marketingContactListController/updateWebSiteContactList',
  getTaskNameListExistingTask:'/phyCorp/control/api/v1/project/getTaskNameList_ExistingTask',
  acceptRequestAndConnectToTask:'/phyCorp/control/api/v1/project/acceptRequestAndConnectToTask',
  deleteCustRequestContents:'/phyCorp/control/api/v1/project/deleteCustRequestContent',
  findContactListParties:'/phyCorp/control/api/v1/marketingContactListController/findContactListParties',
  getStatusList_parties:'/phyCorp/control/api/v1/marketingContactListController/getStatusList_parties',
  getCommunicationEventIdDrop:'/phyCorp/control/api/v1/marketingContactListController/getCommunicationEventId',
  getCommEventStatus:'/phyCorp/control/api/v1/marketingContactListController/getCommEventStatus',
  findContactList_commEvents:'/phyCorp/control/api/v1/marketingContactListController/findContactList_commEvents',
  getPreferredContactMechnaism:'/phyCorp/control/api/v1/marketingContactListController/getPreferredContactMechnaism',
  expireContactListParty:'/phyCorp/control/api/v1/marketingContactListController/expireContactListParty',
  getAssignedTaskList:'/phyCorp/control/api/v1/assetMaintenance/getAssignedTaskList',
  getPartyTypeId:'/phyCorp/control/api/v1/marketingContactListController/getPartyTypeId',
  getFindImportParties:'/phyCorp/control/api/v1/marketingContactListController/getFindImportParties',
  createContactListParty:'/phyCorp/control/api/v1/marketingContactListController/createContactListParty',
  updateContactListParty:'/phyCorp/control/api/v1/marketingContactListController/updateContactListParty',
  assetMaintenanceStatus:'/phyCorp/control/api/v1/assetMaintenance/getStatus',
  getMaintenanceType:'/phyCorp/control/api/v1/assetMaintenance/getMaintenanceType',
  getMaintenanceTemplateId:'/phyCorp/control/api/v1/assetMaintenance/getMaintenanceTemplateId',
  getIntervalMeterType:'/phyCorp/control/api/v1/assetMaintenance/getIntervalMeterType',
  getPurchaseOrderId:'/phyCorp/control/api/v1/assetMaintenance/getPurchaseOrderId',
  getIntervalUom:'/phyCorp/control/api/v1/assetMaintenance/getIntervalUom',
  createFixedAsset:'/phyCorp/control/api/v1/assetMaintenance/createFixedAsset',
  getMainQuote:'/phyCorp/control/api/v1/project/getMainQuote',
  generatePdf:'/phyCorp/control/api/v1/project/quote/pdf',
  fixedAssetMaintsPdf:'/phyCorp/control/api/v1/assetMaintenance/fixedAssetMaintsPdf',
  getQuoteRoles:'/phyCorp/control/api/v1/project/getQuoteRoles',
  copyQuote:'/phyCorp/control/api/v1/project/copyQuote',
  existingcontentId:'/phyCorp/control/api/v1/project/existingContentIdList',
  getQuoteAttribute:'/phyCorp/control/api/v1/project/getQuoteAttribute',
  sendQuoteReportMail:'/phyCorp/control/api/v1/project/sendQuoteReportMail',
  getWorkEffort:'/phyCorp/control/api/v1/project/getWorkEfforts',
  createQuotes:'/phyCorp/control/api/v1/project/createQuote',
  updateQuotes:'/phyCorp/control/api/v1/project/update/:quoteId',
  getChannelId:'/phyCorp/control/api/v1/project/getChannelId',
  getRequestMyRates:'/phyCorp/control/api/v1/workEffort/getMyRates',
  getQuoteTypeId:'/phyCorp/control/api/v1/project/getQuoteTypeIdList',
  getQuoteUomId:'/phyCorp/control/api/v1/project/getQuoteUomId',
  getProductProductStore:'/phyCorp/control/api/v1/project/getProductProductStore  ',
  getQuoteItemLists:'/phyCorp/control/api/v1/project/getQuoteItemList/:quoteId',
  getQuoteSkillTypeId:'/phyCorp/control/api/v1/project/getQuoteSkillTypeId  ',
  createFixedAssetMaintUpdateWorkEffort:'/phyCorp/control/api/v1/assetMaintenance/createFixedAssetMaintUpdateWorkEffort',
  findFixedAssetMaints:'/phyCorp/control/api/v1/assetMaintenance/findFixedAssetMaints',
  createContactListCommEvent:'/phyCorp/control/api/v1/marketingContactListController/createContactListCommEvent',
  getContentMimeTypeId:'/phyCorp/control/api/v1/marketingContactListController/getContentMimeTypeId',
  getContactMechanismTypeIDData:'/phyCorp/control/api/v1/marketingContactListController/getContactMechanismTypeID',
  updateContactListCommEvent:'/phyCorp/control/api/v1/marketingContactListController/updateContactListCommEvent',
  createWorkEffort:'/phyCorp/control/api/v1/workEffort/WorkEffortURLMapping',
  createICalendar:'/phyCorp/control/api/v1/workEffort/createICalendar',
  createWorkEffortContent:'/phyCorp/control/api/v1/workEffort/createWorkEffortContent',
  createWorkEffortOrderHeader:'/phyCorp/control/api/v1/workEffort/createWorkEffortOrderHeader',
  createWorkEffortQuote:'/phyCorp/control/api/v1/workEffort/createWorkEffortQuote',
  createWorkEffortQuoteItem:'/phyCorp/control/api/v1/workEffort/createWorkEffortQuoteItem',
  createWorkEffortRequirement:'/phyCorp/control/api/v1/workEffort/createWorkEffortRequirement',
  createWorkEffortRequest:'/phyCorp/control/api/v1/workEffort/createWorkEffortRequest',
  createShoppingListWorkEffort:'/phyCorp/control/api/v1/workEffort/createShoppingListWorkEffort',
  createCommunicationEvent:'/phyCorp/control/api/v1/workEffort/createCommunicationEvent',
  createWorkEffortTimeEntry:'/phyCorp/control/api/v1/workEffort/createWorkEffortTimeEntry',
  updateWorkEffortRate:'/phyCorp/control/api/v1/workEffort/updateWorkEffortRate',
  createPartyAssignment:'/phyCorp/control/api/v1/workEffort/createPartyAssignment',
  createWorkEffortAndAssoc:'/phyCorp/control/api/v1/workEffort/createWorkEffortAndAssoc',
  createWorkEffortAssoc:'/phyCorp/control/api/v1/workEffort/createWorkEffortAssoc',
  updateWorkEffortContent:'/phyCorp/control/api/v1/workEffort/updateWorkEffortContent',
  deleteWorkEffortContent:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortContent',
  deleteWorkEffortOrderHeader:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortOrderHeader',
  deleteWorkEffortRequirement:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortRequirement',
  updateCommunicationEventWorkEff:'/phyCorp/control/api/v1/workEffort/updateCommunicationEventWorkEff',
  deleteCommunicationEventWorkEff:'/phyCorp/control/api/v1/workEffort/deleteCommunicationEventWorkEff',
  deleteWorkEffortRate:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortRate',
  deleteWorkEffortRequest:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortRequest',
  deleteShoppingListWorkEffort:'/phyCorp/control/api/v1/workEffort/deleteShoppingListWorkEffort',
  deleteWorkEffortQuote:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortQuote',
  createQuoteAttribute:'/phyCorp/control/api/v1/project/createQuoteAttribute',
  updateQuoteAttribute:'/phyCorp/control/api/v1/project/updateQuoteAttribute',
  createQuoteCoefficient:'/phyCorp/control/api/v1/project/createQuoteCoefficient',
  removeQuoteCoefficient:'/phyCorp/control/api/v1/project/removeQuoteCoefficient ',
  updateQuoteCoefficient:'/phyCorp/control/api/v1/project/updateQuoteCoefficient ',
  getQuoteCoefficient:'/phyCorp/control/api/v1/project/getQuoteCoefficient',
  removeQuoteAttribute:'/phyCorp/control/api/v1/project/removeQuoteAttribute ',
  createQuoteFromCustRequest:'/phyCorp/control/api/v1/project/createQuoteFromCustRequest ',
  createQuoteItem:'/phyCorp/control/api/v1/project/createQuoteItem ',
  removeQuoteItem:'/phyCorp/control/api/v1/project/removeQuoteItem ',
  updateQuoteItem:'/phyCorp/control/api/v1/project/updateQuoteItem ',
  getProductFeatures:'/phyCorp/control/api/v1/project/getProductFeatures ',
  getTrackingCodeById:'/phyCorp/control/api/v1/marketingTrackingController/getTrackingCodeById',
  createQuoteTerm:'/phyCorp/control/api/v1/project/createQuoteTerm',
  deleteQuoteTerm:'/phyCorp/control/api/v1/project/deleteQuoteTerm',
  removeFixedAssetIdent:'/phyCorp/control/api/v1/assetMaintenance/removeFixedAssetIdent',
  getQuoteTerm:'/phyCorp/control/api/v1/project/getQuoteTerm',
  postfixedAssetProduct:'/phyCorp/control/api/v1/accounting/fixedAssetProduct',
  fixedAssetProduct:'/phyCorp/control/api/v1/accounting/fixedAssetProduct',
  updateFixedAssetStdCost:'/phyCorp/control/api/v1/assetMaintenance/updateFixedAssetStdCost',
  createFixedAssetIdent:'/phyCorp/control/api/v1/assetMaintenance/createFixedAssetIdent',
  updateFixedAssetIdent:'/phyCorp/control/api/v1/assetMaintenance/updateFixedAssetIdent',
  getFixedAssetRegistrationList:'/phyCorp/control/api/v1/assetMaintenance/getFixedAssetRegistrationList',
  deleteFixedAssetRegistration:'/phyCorp/control/api/v1/assetMaintenance/deleteFixedAssetRegistration',
  createFixedAssetRegistration:'/phyCorp/control/api/v1/assetMaintenance/createFixedAssetRegistration',
  updateFixedAssetRegistration:'/phyCorp/control/api/v1/assetMaintenance/updateFixedAssetRegistration',
  getAssignmentList:'/phyCorp/control/api/v1/accounting/assignment',
  deleteAssignmentList:'/phyCorp/control/api/v1/accounting/assignment',
  postAssignmentList:'/phyCorp/control/api/v1/accounting/assignment',
  putAssignmentList:'/phyCorp/control/api/v1/accounting/assignment',
  getMeterReadingList:'/phyCorp/control/api/v1/assetMaintenance/getMeterReadingList',
  deleteFixedAssetMeter:'/phyCorp/control/api/v1/assetMaintenance/deleteFixedAssetMeter',
  createFixedAssetMeter:'/phyCorp/control/api/v1/assetMaintenance/createFixedAssetMeter',
  updateFixedAssetMeter:'/phyCorp/control/api/v1/assetMaintenance/updateFixedAssetMeter',
  getDepreciationCustomMethodList:'/phyCorp/control/api/v1/assetMaintenance/getDepreciationCustomMethodList',
  deleteFixedAssetDepMethod:'/phyCorp/control/api/v1/assetMaintenance/deleteFixedAssetDepMethod',
  createFixedAssetDepMethod:'/phyCorp/control/api/v1/assetMaintenance/createFixedAssetDepMethod',
  getDepreciationCustomMethod:'/phyCorp/control/api/v1/assetMaintenance/getDepreciationCustomMethod',
  getListFixedAssetMaints:'/phyCorp/control/api/v1/assetMaintenance/getListFixedAssetMaints',
  getGeoLocation:'/phyCorp/control/api/v1/assetMaintenance/getGeoLocation',
  getFixedAssetChildren:'/phyCorp/control/api/v1/assetMaintenance/getFixedAssetChildren',
  getWorkEffortNoteById:'/phyCorp/control/api/v1/assetMaintenance/getWorkEffortNoteById',
  createWorkEffortNote:'/phyCorp/control/api/v1/assetMaintenance/createWorkEffortNote',
  getEditFixedAssetMaint:'/phyCorp/control/api/v1/assetMaintenance/getEditFixedAssetMaint',
  updateFixedAssetMaintUpdateWorkEffort:'/phyCorp/control/api/v1/assetMaintenance/updateFixedAssetMaintAndWorkEffort',
  getReturnPartIssuedToFixedAssetMaintById:'/phyCorp/control/api/v1/assetMaintenance/getReturnPartIssuedToFixedAssetMaintById',
  returnPartIssuedToFixedAssetMaint:'/phyCorp/control/api/v1/assetMaintenance/returnPartIssuedToFixedAssetMaint',
  getFacilityID:'/phyCorp/control/api/v1/assetMaintenance/getFacilityID',
  issueInventoryItemToFixedAssetMaint:'/phyCorp/control/api/v1/assetMaintenance/issueInventoryItemToFixedAssetMaint',
  getReturnPartIssuedToFixedAsset:'/phyCorp/control/api/v1/assetMaintenance/getReturnPartIssuedToFixedAsset',
  getTimeEntryList:'/phyCorp/control/api/v1/assetMaintenance/getTimeEntryList',
  deleteWorkEffortTimeEntry:'/phyCorp/control/api/v1/assetMaintenance/deleteWorkEffortTimeEntry',
   deleteWorkEffortsTimeEntry:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortTimeEntry',
  updateWorkEffortTimeEntry:'/phyCorp/control/api/v1/assetMaintenance/updateWorkEffortTimeEntry',
  updateWorkEffortsTimeEntry:'/phyCorp/control/api/v1/workEffort/updateWorkEffortTimeEntry',
  deleteWorkEffortQuoteItem:'/phyCorp/control/api/v1/workEffort/deleteWorkEffortQuoteItem',
  updateWorkEffortPartyAssign:'/phyCorp/control/api/v1/workEffort/updateWorkEffortPartyAssign',
  createQuickTimeEntry:'/phyCorp/control/api/v1/workEffort/createQuickTimeEntry',
  makePrivates:'/phyCorp/control/api/v1/project/makePrivate',
  updateICalendar:'/phyCorp/control/api/v1/workEffort/updateICalendar',
  getCalenderVisibility:'/phyCorp/control/api/v1/workEffort/getCalenderVisibility',
  getCalenderPartiesRole:'/phyCorp/control/api/v1/workEffort/getCalenderPartiesRole',
  geICalendarPartyAssign:'/phyCorp/control/api/v1/workEffort/geICalendarPartyAssign',
  createICalendarPartyAssign:'/phyCorp/control/api/v1/workEffort/createICalendarPartyAssign',
  updateICalendarPartyAssign:'/phyCorp/control/api/v1/workEffort/updateICalendarPartyAssign',
  getFixedAssetIdList:'/phyCorp/control/api/v1/workEffort/getFixedAssetIdList',
  geICalendarFixedAssetAssign:'/phyCorp/control/api/v1/workEffort/geICalendarFixedAssetAssign',
  createICalendarFixedAssetAssign:'/phyCorp/control/api/v1/workEffort/createICalendarFixedAssetAssign',
  updateICalendarData:'/phyCorp/control/api/v1/workEffort/updateICalendarData',
  createTimesheetEntry:'/phyCorp/control/api/v1/workEffort/createTimesheetEntry',
  getTimeSheetEntryById:'/phyCorp/control/api/v1/workEffort/getTimeSheetEntryById',
  timesheetRole:'/phyCorp/control/api/v1/workEffort/timesheetRole/:timesheetId',
  addTimesheetToInvoice:'/phyCorp/control/api/v1/workEffort/addTimesheetToInvoice',
  addTimesheetToNewInvoice:'/phyCorp/control/api/v1/workEffort/addTimesheetToNewInvoice',
  updateTimesheetEntry:'/phyCorp/control/api/v1/workEffort/updateTimesheetEntry',
  getTimeEntryRateType:'/phyCorp/control/api/v1/assetMaintenance/getTimeEntryRateType',
  createWorkEffortTimeEntrySave:'/phyCorp/control/api/v1/assetMaintenance/createWorkEffortTimeEntry',
  getFixedAssetMaintOrderById:'/phyCorp/control/api/v1/assetMaintenance/getFixedAssetMaintOrderById',
  deleteFixedAssetMaintOrder:'/phyCorp/control/api/v1/assetMaintenance/deleteFixedAssetMaintOrder',
  getOrderIdData:'/phyCorp/control/api/v1/assetMaintenance/getOrderId',
  createFixedAssetMaintOrder:'/phyCorp/control/api/v1/assetMaintenance/createFixedAssetMaintOrder',
  getTimeSheetIdData:'/phyCorp/control/api/v1/assetMaintenance/getTimeSheetId',
  getProductMaintById:'/phyCorp/control/api/v1/assetMaintenance/getProductMaintById',
  deleteProductMaint:'/phyCorp/control/api/v1/assetMaintenance/deleteProductMaint',
  getProductMaintType:'/phyCorp/control/api/v1/assetMaintenance/getProductMaintType',
  getProductMeterType:'/phyCorp/control/api/v1/assetMaintenance/getProductMeterType',
  getWorkEffortNameValue:'/phyCorp/control/api/v1/assetMaintenance/getWorkEffortName',
  createProductMaint:'/phyCorp/control/api/v1/assetMaintenance/createProductMaint',
  updateProductMaint:'/phyCorp/control/api/v1/assetMaintenance/updateProductMaint',
  delegateReasonEnumList:'/phyCorp/control/api/v1/assetMaintenance/delegateReasonEnumList',
  getAvailabilityListData:'/phyCorp/control/api/v1/assetMaintenance/getAvailabilityList',
  getStatusIdAsset:'/phyCorp/control/api/v1/assetMaintenance/getStatusIdAsset',
  getRoleTypeAsset:'/phyCorp/control/api/v1/assetMaintenance/getRoleTypeAsset',
  getWorkEffortPartyAssignmentsData:'/phyCorp/control/api/v1/assetMaintenance/getWorkEffortPartyAssignments',
  createWorkEffortPartyAssignSave:'/phyCorp/control/api/v1/assetMaintenance/createWorkEffortPartyAssign',
  updateWorkEffortPartyAssignAsset:'/phyCorp/control/api/v1/assetMaintenance/updateWorkEffortPartyAssignAsset',
  getPurposeList:'/phyCorp/control/api/v1/assetMaintenance/getPurposeList',
  updateICalendarFixedAssetAssign:'/phyCorp/control/api/v1/workEffort/updateICalendarFixedAssetAssign',
  getCalStatusList:'/phyCorp/control/api/v1/assetMaintenance/getCalStatusList',
  getOverheadProjector:'/phyCorp/control/api/v1/assetMaintenance/getOverheadProjector',
  updateWorkEffortAsset:'/phyCorp/control/api/v1/assetMaintenance/updateWorkEffortAsset',
  getFixedAssetByWorkEffortId:'/phyCorp/control/api/v1/assetMaintenance/getFixedAssetByWorkEffortId',
  facilityTypeIds:'/phyCorp/control/api/v1/facility/facilityTypeIds',
  findFacilities:'/phyCorp/control/api/v1/facility/findFacilities',
  findProductPromo:'/phyCorp/control/api/v1/catalog/productPrice/findProductPromo',
  createProductPromo:'/phyCorp/control/api/v1/catalog/productPrice/createProductPromo',
  getProductPromotionList:'/phyCorp/control/api/v1/catalog/productPrice/getProductPromotionList',
  getProductByProductPromoId:'/phyCorp/control/api/v1/catalog/productPrice/getProductByProductPromoId',
  cancelFixedAssetStdCost:'/phyCorp/control/api/v1/assetMaintenance/cancelFixedAssetStdCost',
  getStandardCostType:'/phyCorp/control/api/v1/assetMaintenance/getStandardCostType',
  getIdentificationsList:'/phyCorp/control/api/v1/assetMaintenance/getIdentificationsList',
  createFixedAssetStdCost:'/phyCorp/control/api/v1/assetMaintenance/createFixedAssetStdCost',
  getIdentificationsType:'/phyCorp/control/api/v1/assetMaintenance/getIdentificationsType',
  putfixedAssetProduct:'/phyCorp/control/api/v1/accounting/fixedAssetProduct',
  removefixedAssetProduct:'/phyCorp/control/api/v1/accounting/fixedAssetProduct',
  getStandardCostList:'/phyCorp/control/api/v1/assetMaintenance/getStandardCostList',
  updateQuoteTerm:'/phyCorp/control/api/v1/project/updateQuoteTerm',
  createQuoteRole:'/phyCorp/control/api/v1/project/createQuoteRole',
  updateQuoteRole:'/phyCorp/control/api/v1/project/updateQuoteRole',
  removeQuoteRole:'/phyCorp/control/api/v1/project/removeQuoteRole',
  getQuoteRole:'/phyCorp/control/api/v1/project/getQuoteRole',
  createQuoteNote:'/phyCorp/control/api/v1/crm/quoteNote/:quoteId',
  getQuoteNotes:'/phyCorp/control/api/v1/project/getQuoteNotes',
  updateQuoteNote:'/phyCorp/control/api/v1/crm/quoteNote/:quoteId',
  createQuoteAdjustment:'/phyCorp/control/api/v1/project/createQuoteAdjustment  ',
  getQuoteAdjustmentByIds:'/phyCorp/control/api/v1/project/getQuoteAdjustmentByIds',
  removeQuoteAdjustment:'/phyCorp/control/api/v1/project/removeQuoteAdjustment',
  updateQuoteAdjustment:'/phyCorp/control/api/v1/project/updateQuoteAdjustment',
  getViewQuoteProfit:'/phyCorp/control/api/v1/project/getViewQuoteProfit',
  autoCreateQuoteAdjustments:'/phyCorp/control/api/v1/project/autoCreateQuoteAdjustments',
  getQuoteAdjustmentType:'/phyCorp/control/api/v1/project/getQuoteAdjustmentType',
  getAutoUpdateQuotePrices:'/phyCorp/control/api/v1/project/getAutoUpdateQuotePrices',
  getAutoUpdateQuote:'/phyCorp/control/api/v1/project/getAutoUpdateQuote',
  autoUpdateQuotePrices:'/phyCorp/control/api/v1/project/autoUpdateQuotePrices',
  getViewQuoteProfitSummary:'/phyCorp/control/api/v1/project/getViewQuoteProfit',
  getRequestType:'/phyCorp/control/api/v1/workEffort/getType',
  getRequestPurpose:'/phyCorp/control/api/v1/workEffort/getPurpose',
  getRequestTemporalExpression:'/phyCorp/control/api/v1/workEffort/getTemporalExpression',
  getRequestFacilityIdList:'/phyCorp/control/api/v1/workEffort/getFacilityIdList',
  getRequestRequirementList:'/phyCorp/control/api/v1/workEffort/getRequirementList',
  getQuoteWorkEffort:'/phyCorp/control/api/v1/project/getQuoteWorkEffort',
  createQuoteWorkEffort:'/phyCorp/control/api/v1/project/createQuoteWorkEffort',
  getTimeEntryListById:'/phyCorp/control/api/v1/workEffort/getTimeEntryListById',
  getQuoteItem_By_QuoteItemSeqIdAndQuoteId:'/phyCorp/control/api/v1/project/getQuoteItem_QuoteItemSeqIdAndQuoteId/:quoteItemSeqId/:quoteId',
  getDataSourceTypeById:'/phyCorp/control/api/v1/marketingCampaignController/getDataSourceTypeById/:dataSourceTypeId',
  getDataSourceByDataSourceId:'/phyCorp/control/api/v1/marketingCampaignController/getDataSourceByDataSourceId/:dataSourceId',
  getTrackCodeTypeById:'/phyCorp/control/api/v1/marketingTrackingController/getTrackCodeTypeById',
  copyProjectToTemplate:'/phyCorp/control/api/v1/project/copyProjectToTemplate',
  getTermTypeID:'/phyCorp/control/api/v1/project/getTermTypeID',
  getQuoteItemSeqID:'/phyCorp/control/api/v1/project/getQuoteItemSeqID',
  copyProject:'/phyCorp/control/api/v1/project/copyProject',
  schedule:'/phyCorp/control/api/v1/project/schedule',
  duplicateWorkEffort:'/phyCorp/control/api/v1/workEffort/duplicateWorkEffort',
  closeStatus:'/phyCorp/control/api/v1/project/closeStatus',
  updateProjectTask:'/phyCorp/control/api/v1/project/updateProjectTask ',
  createWorkEffortPartyAssign:'/phyCorp/control/api/v1/project/createWorkEffortPartyAssign',
  deleteWorkEffortPartyAssign:'/phyCorp/control/api/v1/project/deleteWorkEffortPartyAssign ',
  getBillingPage:'/phyCorp/control/api/v1/scrumNewController/getForBillingPage',
  uploadFileProjectContent:'/phyCorp/control/api/v1/project/uploadFileTaskContent',
  addProjectNote:'/phyCorp/control/api/v1/project/makePrivate',
  makeNotePrivate:'/phyCorp/control/api/v1/project/addTaskNote',
  getProjectBilling:'/phyCorp/control/api/v1/project/getProjectBilling',
  setCustRequestStatus:'/phyCorp/control/api/v1/project/setCustRequestStatus',
  getGantChart:'/phyCorp/control/api/v1/project/getGantChart',
  FindSalesForecast:'/phyCorp/control/api/v1/crmPartner/FindSalesForecast',
  getFindSalesForecast:'/phyCorp/control/api/v1/crmPartner/FindSalesForecast',
  getUpdateSalesForecastById:'/phyCorp/control/api/v1/crmPartner/getUpdateSalesForecastById',
  ParentSalesForecastID:'/phyCorp/control/api/v1/crmPartner/ParentSalesForecastID',
  createSalesForecast:'/phyCorp/control/api/v1/crmPartner/createSalesForecast',
  updateSalesForecast:'/phyCorp/control/api/v1/crmPartner/updateSalesForecast',
  getProjectSummaryById:'/phyCorp/control/api/v1/project/getProjectSummaryById/:workEffortId',
  getCreatePortalPageByIds:'/phyCorp/control/api/v1/crmPartner/getCreatePortalPageByIds ',
  revertToOriginal:'/phyCorp/control/api/v1/crmPartner/revertToOriginal ',
  updatePortalPageColumnWidth:'/phyCorp/control/api/v1/crmPartner/updatePortalPageColumnWidth',
  deletePortalPage:'/phyCorp/control/api/v1/crmPartner/deletePortalPage',
  getSalesForecastDetailById:'/phyCorp/control/api/v1/crmPartner/getSalesForecastDetailById',
  createSalesForecastDetail:'/phyCorp/control/api/v1/crmPartner/createSalesForecastDetail',
  deleteSalesForecastDetail:'/phyCorp/control/api/v1/crmPartner/deleteSalesForecastDetail',
  productCategoryId:'/phyCorp/control/api/v1/crmPartner/productCategoryId',
  getProductIdForecast:'/phyCorp/control/api/v1/workEffort/getProductId',
  quantityUomId:'/phyCorp/control/api/v1/accounting/agreementItemProduct/quantityUomId',
  updateSalesForecastDetail:'/phyCorp/control/api/v1/crmPartner/updateSalesForecastDetail',
  getCategoryPortlet:'/phyCorp/control/api/v1/crmPartner/getCategoryPortlet',
  createTeam:'/phyCorp/control/api/v1/crmPartner/createTeam',
  findTeam:'/phyCorp/control/api/v1/crmPartner/findTeam',
  getfindTeam:'/phyCorp/control/api/v1/crmPartner/findTeam',
  getTeamByPartyId:'/phyCorp/control/api/v1/crmPartner/getTeamByPartyId/:partyId',

  getFindCases:'/phyCorp/control/api/v1/crmPartner/getFindCases',
  FindCases:'/phyCorp/control/api/v1/crmPartner/getFindCases',
  getPriority:'/phyCorp/control/api/v1/crmPartner/getPriority',
  getStatusCrm:'/phyCorp/control/api/v1/crmPartner/getStatusCrm',
  getTypeCrm:'/phyCorp/control/api/v1/crmPartner/getTypeCrm',
  createCase:'/phyCorp/control/api/v1/crmPartner/createCase',
  getTeamMemberByPartyId:'/phyCorp/control/api/v1/crmPartner/getTeamMemberByPartyId/:partyId',
  getMyCases:'/phyCorp/control/api/v1/crmPartner/getMyCases',
  getReason:'/phyCorp/control/api/v1/crmPartner/getReason',
  getCasesById:'/phyCorp/control/api/v1/crmPartner/getCasesById',
  updateCase:'/phyCorp/control/api/v1/crmPartner/updateCase',
  CreatePendingActivityEventCrm:'/phyCorp/control/api/v1/crmPartner/CreatePendingActivityEventCrm',
  deletePendingActivityEventCrm:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityEventCrm',
  getPendingActivityEventCrm:'/phyCorp/control/api/v1/crmPartner/getPendingActivityEventCrm',
  UpdatePendingActivityEventCrm:'/phyCorp/control/api/v1/crmPartner/UpdatePendingActivityEventCrm',
  deletePendingActivityTask:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityTask  ',
  createPendingActivityTask:'/phyCorp/control/api/v1/crmPartner/createPendingActivityTask',
  getPendingActivityTaskCrm:'/phyCorp/control/api/v1/crmPartner/getPendingActivityTaskCrm',
  createNoteCRM:'/phyCorp/control/api/v1/crmPartner/createNoteCRM',
  getNoteCRMAll:'/phyCorp/control/api/v1/crmPartner/getNoteCRMAll',
  UpdateNoteCRM:'/phyCorp/control/api/v1/crmPartner/UpdateNoteCRM',
  getNoteCRMBId:'/phyCorp/control/api/v1/crmPartner/getNoteCRMBId',
  deleteNoteCRM:'/phyCorp/control/api/v1/crmPartner/deleteNoteCRM',
  updateBookmarksUpload:'/phyCorp/control/api/v1/crmPartner/updateBookmarksUpload',
  createBookmarksUpload:'/phyCorp/control/api/v1/crmPartner/createBookmarksUpload  ',
  createBookMarksUrl:'/phyCorp/control/api/v1/crmPartner/createBookMarksUrl',
  UpdateBookMarksUrl:'/phyCorp/control/api/v1/crmPartner/UpdateBookMarksUrl',
  deleteBookmarksUpload:'/phyCorp/control/api/v1/crmPartner/deleteBookmarksUpload',
  deleteBookMarksUrl:'/phyCorp/control/api/v1/crmPartner/deleteBookMarksUrl',
  getBookMarksUrlId:'/phyCorp/control/api/v1/crmPartner/getBookMarksUrlId',
  getBookmarksUpload:'/phyCorp/control/api/v1/crmPartner/getBookmarksUpload',
  getClassificationBookmark:'/phyCorp/control/api/v1/crmPartner/getClassificationBookmark',
  getPendingActivity:'/phyCorp/control/api/v1/crmPartner/getPendingActivity',
  getBookMark:'/phyCorp/control/api/v1/crmPartner/getBookMark',
  getActivity:'/phyCorp/control/api/v1/crmPartner/getActivity',
  getSalesOpportunity:'/phyCorp/control/api/v1/crmPartner/getSalesOpportunity',
  createMyPortalCalendarEvent:'/phyCorp/control/api/v1/myPortal/createWorkEffortAndPartyAssign',
  updateMyPortalCalendarEvent:'/phyCorp/control/api/v1/myPortal/updateWorkEffort',
  deleteMyPortalAParticipants:'/phyCorp/control/api/v1/myPortal/deleteWorkEffortPartyAssign',
  createMyPortalParticipants:'/phyCorp/control/api/v1/myPortal/createWorkEffortPartyAssign',
  getCalenderEntries:'/phyCorp/control/api/v1/assetMaintenance/getCalenderEntries',
  cancelCalenderEvent:'/phyCorp/control/api/v1/assetMaintenance/cancelCalenderEvent',
  getCalenderRole:'/phyCorp/control/api/v1/workEffort/getCalenderRole',
  getParticipants:'/phyCorp/control/api/v1/workEffort/getParticipants',
  createLogTaskCallCases:'/phyCorp/control/api/v1/crmPartner/createLogTaskCallCases  ',
  createLogTaskEmailCases:'/phyCorp/control/api/v1/crmPartner/createLogTaskEmailCases',
  deleteLogTaskEmailCases:'/phyCorp/control/api/v1/crmPartner/deleteLogTaskEmailCases',
  deleteLogTaskCallCases:'/phyCorp/control/api/v1/crmPartner/deleteLogTaskCallCases',
  getChildrenWorkeffort:'/phyCorp/control/api/v1/workEffort/getChildrenWorkeffort',
  findByEmailPartner:'/phyCorp/control/api/v1/crmPartner/findByEmailPartner',
  getfindByEmailPartner:'/phyCorp/control/api/v1/crmPartner/findByEmailPartner',
  getfindByNamePartner:'/phyCorp/control/api/v1/crmPartner/findByNamePartner',
  getfindByPhonePartner:'/phyCorp/control/api/v1/crmPartner/findByPhonePartner',
  findByNamePartner:'/phyCorp/control/api/v1/crmPartner/findByNamePartner',
  findByPhonePartner:'/phyCorp/control/api/v1/crmPartner/findByPhonePartner',
  findByAdvancePartner:'/phyCorp/control/api/v1/crmPartner/findByAdvancePartner',
  getLogTaskCallCasesId:'/phyCorp/control/api/v1/crmPartner/getLogTaskCallCasesId',
  getLogTaskEmailCasesId:'/phyCorp/control/api/v1/crmPartner/getLogTaskEmailCasesId',
  createPartner:'/phyCorp/control/api/v1/crmPartner/createPartner',
  getCountryPartner:'/phyCorp/control/api/v1/crmPartner/getCountry',
  getIndustry:'/phyCorp/control/api/v1/crmPartner/getIndustry',
  getOwnerShip:'/phyCorp/control/api/v1/crmPartner/getOwnerShip',
  getPreferredCurrency:'/phyCorp/control/api/v1/crmPartner/getPreferredCurrency',
  getClassificationBookmarkPartner:'/phyCorp/control/api/v1/crmPartner/getClassificationBookmarkPartner',

  getPartnerById:'/phyCorp/control/api/v1/crmPartner/getPartnerById',
  getAgreementsSupplierPartnerById:'/phyCorp/control/api/v1/crmPartner/getAgreementsSupplierPartnerById',
  updatePartner:'/phyCorp/control/api/v1/crmPartner/updatePartner',
  getMyTimesheets:'/phyCorp/control/api/v1/workEffort/getMyTimesheets',
  createAgreementsSupplierPartner:'/phyCorp/control/api/v1/crmPartner/createAgreementsSupplierPartner',
  deleteAgreementsSupplierPartner:'/phyCorp/control/api/v1/crmPartner/deleteAgreementsSupplierPartner',
  updateAgreementsSupplierPartner:'/phyCorp/control/api/v1/crmPartner/updateAgreementsSupplierPartner',
  createBookMarksUrlPartner:'/phyCorp/control/api/v1/crmPartner/createBookMarksUrlPartner',
  deleteBookMarksUrlPartner:'/phyCorp/control/api/v1/crmPartner/deleteBookMarksUrlPartner',
  getBookMarksUrlIdPartner:'/phyCorp/control/api/v1/crmPartner/getBookMarksUrlIdPartner',
  UpdateBookMarksUrlPartner:'/phyCorp/control/api/v1/crmPartner/UpdateBookMarksUrlPartner',
  getBookmarksUploadPartner:'/phyCorp/control/api/v1/crmPartner/getBookmarksUploadPartner',
  updateBookmarksUploadPartner:'/phyCorp/control/api/v1/crmPartner/updateBookmarksUploadPartner',
  deleteBookmarksUploadPartner:'/phyCorp/control/api/v1/crmPartner/deleteBookmarksUploadPartner',
  createBookmarksUploadPartner:'/phyCorp/control/api/v1/crmPartner/createBookmarksUploadPartner',
  getITEMSEQ:'/phyCorp/control/api/v1/assetMaintenance/getITEMSEQ',
  deleteTimesheetEntry:'/phyCorp/control/api/v1/workEffort/deleteTimesheetEntry',
  deleteTimesheetRoleNew:'/phyCorp/control/api/v1/workEffort/deleteTimesheetRoleNew',
  deleteLogTaskCallPartner:'/phyCorp/control/api/v1/crmPartner/deleteLogTaskCallPartner',
  deleteLogTaskEmailPartner:'/phyCorp/control/api/v1/crmPartner/deleteLogTaskEmailPartner',
  getLogTaskCallPartnerId:'/phyCorp/control/api/v1/crmPartner/getLogTaskCallPartnerId',
  UpdateLogTaskCallPartner:'/phyCorp/control/api/v1/crmPartner/UpdateLogTaskCallPartner  ',
  UpdateLogTaskEmailPartner:'/phyCorp/control/api/v1/crmPartner/UpdateLogTaskEmailPartner  ',
  getLogTaskEmailPartnerId:'/phyCorp/control/api/v1/crmPartner/getLogTaskEmailPartnerId',
  createLogTaskCallPartner:'/phyCorp/control/api/v1/crmPartner/createLogTaskCallPartner',
  createLogTaskEmailPartner:'/phyCorp/control/api/v1/crmPartner/createLogTaskEmailPartner',
  getPendingActivityEventPartner:'/phyCorp/control/api/v1/crmPartner/getPendingActivityEventPartner',
  getPendingActivityTaskPartner:'/phyCorp/control/api/v1/crmPartner/getPendingActivityTaskPartner',
  createPendingActivityTaskPartner:'/phyCorp/control/api/v1/crmPartner/createPendingActivityTaskPartner',
  CreatePendingActivityEventPartner:'/phyCorp/control/api/v1/crmPartner/CreatePendingActivityEventPartner',
  getProductStoreGroupAll:'/phyCorp/control/api/v1/catalog/productPrice/getProductStoreGroupAll',
  getProductStoreGroupRollUpById:'/phyCorp/control/api/v1/catalog/productPrice/getProductStoreGroupRollUpById',
  getProductStoreGroupMemberById:'/phyCorp/control/api/v1/catalog/productPrice/getProductStoreGroupMemberById',
  getProductStoreType:'/phyCorp/control/api/v1/catalog/productPrice/getType',
  getPrimaryParentGroupID:'/phyCorp/control/api/v1/catalog/productPrice/getPrimaryParentGroupID',
  getProductStoreId:'/phyCorp/control/api/v1/catalog/productPrice/getProductStoreId',
  updateProductStoreGroup:'/phyCorp/control/api/v1/catalog/productPrice/updateProductStoreGroup',
  getProductStoreGroupById:'/phyCorp/control/api/v1/catalog/productPrice/getProductStoreGroupById',
  createProductStoreGroupMember:'/phyCorp/control/api/v1/catalog/productPrice/createProductStoreGroupMember',
  UpdatePendingActivityEventPartner:'/phyCorp/control/api/v1/crmPartner/UpdatePendingActivityEventPartner',
  UpdatePendingActivityTaskPartner:'/phyCorp/control/api/v1/crmPartner/UpdatePendingActivityTaskPartner',
  deletePendingActivityTaskPartner:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityTaskPartner',
  deletePendingActivityEventPartner:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityEventPartner',
  createWebUrlPartner:'/phyCorp/control/api/v1/crmPartner/createWebUrlPartner',
  createSkypeContactPartner:'/phyCorp/control/api/v1/crmPartner/createSkypeContactPartner',
  createPostalAddressPartner:'/phyCorp/control/api/v1/crmPartner/createPostalAddressPartner',
  createPhoneNumberPartner:'/phyCorp/control/api/v1/crmPartner/createPhoneNumberPartner',
  createEmailAddressPartner:'/phyCorp/control/api/v1/crmPartner/createEmailAddressPartner  ',
  deleteWebUrlPartner:'/phyCorp/control/api/v1/crmPartner/deleteWebUrlPartner  ',
  UpdateWebUrlPartner:'/phyCorp/control/api/v1/crmPartner/UpdateWebUrlPartner  ',
  addWorkEffortTimeToNewInvoice:'/phyCorp/control/api/v1/workEffort/addWorkEffortTimeToNewInvoice',
  addWorkEffortTimeToInvoice:'/phyCorp/control/api/v1/workEffort/addWorkEffortTimeToInvoice',
  deleteEmailAddressPartner:'/phyCorp/control/api/v1/crmPartner/deleteEmailAddressPartner  ',
  deletePartnerTelecomNumber:'/phyCorp/control/api/v1/crmPartner/deletePartnerTelecomNumber  ',
  deleteSkypeContactPartner:'/phyCorp/control/api/v1/crmPartner/deleteSkypeContactPartner',
  UpdatePhoneNumberPartner:'/phyCorp/control/api/v1/crmPartner/UpdatePhoneNumberPartner',
  UpdateSkypeContactPartner:'/phyCorp/control/api/v1/crmPartner/UpdateSkypeContactPartner  ',
  getProductCategory:'/phyCorp/control/api/v1/crmPartner/getProductCategory',
  getPriceRuleCatalogList:'/phyCorp/control/api/v1/catalog/promos/getPriceRuleList',
  createProductStoreGroup:'/phyCorp/control/api/v1/catalog/productPrice/createProductStoreGroup',
  getTypeCatalog:'/phyCorp/control/api/v1/catalog/productPrice/getType',
  getAllData:'/phyCorp/control/api/v1/crmPartner/getAllData',
  updateCloseCase:'/phyCorp/control/api/v1/crmPartner/updateCloseCase',
  getTeamMember:'/phyCorp/control/api/v1/crmPartner/getTeamMember',
  getPhoneNumberPartner:'/phyCorp/control/api/v1/crmPartner/getPhoneNumberPartner',
  getPostalAddressPartner:'/phyCorp/control/api/v1/crmPartner/getPostalAddressPartner',
  getSkypeContactPartner:'/phyCorp/control/api/v1/crmPartner/getSkypeContactPartner',
  getPostalAddressPurpose:'/phyCorp/control/api/v1/crmPartner/getPostalAddressPurpose  ',
  getWebUrlPurpose:'/phyCorp/control/api/v1/crmPartner/getWebUrlPurpose',
  getEmailAddressPartner:'/phyCorp/control/api/v1/crmPartner/getEmailAddressPartner',
  UpdateEmailAddressPartner:'/phyCorp/control/api/v1/crmPartner/UpdateEmailAddressPartner',
  deletePostalAddressPartner:'/phyCorp/control/api/v1/crmPartner/deletePostalAddressPartner',
  getPhoneNumberPurpose:'/phyCorp/control/api/v1/crmPartner/getPhoneNumberPurpose',
  getWebUrlPartner:'/phyCorp/control/api/v1/crmPartner/getWebUrlPartner',
  getEmailAddressPurpose:'/phyCorp/control/api/v1/crmPartner/getEmailAddressPurpose',
  UpdatePostalAddressPartner:'/phyCorp/control/api/v1/crmPartner/UpdatePostalAddressPartner',
  getCreatePortalPageById:'/phyCorp/control/api/v1/crmPartner/getCreatePortalPageById',
  findFinalPartner:'/phyCorp/control/api/v1/crmPartner/findFinalPartner',
  findSupplier:'/phyCorp/control/api/v1/crmPartner/findSupplier',
  createSupplier:'/phyCorp/control/api/v1/crmPartner/createSupplier',
  createOutsourceRoutingTask:'/phyCorp/control/api/v1/crmPartner/createOutsourceRoutingTask',
  updateSupplierForm:'/phyCorp/control/api/v1/crmPartner/updateSupplierForm',
  getSupplierAll:'/phyCorp/control/api/v1/crmPartner/getSupplierAll  ',
  getGlAccountTypeId:'/phyCorp/control/api/v1/crmPartner/getGlAccountTypeId  ',
  getEditSupplierById:'/phyCorp/control/api/v1/crmPartner/getEditSupplierById',
  getNoteSupplierBId:'/phyCorp/control/api/v1/crmPartner/getNoteSupplierBId',
  createNoteSupplier:'/phyCorp/control/api/v1/crmPartner/createNoteSupplier',
  getOpenOrdersSupplierById:'/phyCorp/control/api/v1/crmPartner/getOpenOrdersSupplierById',
  getAgreementsSupplierById:'/phyCorp/control/api/v1/crmPartner/getAgreementsSupplierById',
  createOpenOrdersSupplier:'/phyCorp/control/api/v1/crmPartner/createOpenOrdersSupplier',
  createAgreementsSupplier:'/phyCorp/control/api/v1/crmPartner/createAgreementsSupplier',
  createWebUrlSupplier:'/phyCorp/control/api/v1/crmPartner/createWebUrlSupplier',
  deleteWebUrlSupplier:'/phyCorp/control/api/v1/crmPartner/deleteWebUrlSupplier',
  updateWebUrlSupplier:'/phyCorp/control/api/v1/crmPartner/updateWebUrlSupplier',
  getWebUlSupplier:'/phyCorp/control/api/v1/crmPartner/getWebUlSupplier',
  createAddressSupplier:'/phyCorp/control/api/v1/crmPartner/createAddressSupplier',
  createContactInformationSupplier:'/phyCorp/control/api/v1/crmPartner/createContactInformationSupplier',
  deleteAddressSupplier:'/phyCorp/control/api/v1/crmPartner/deleteAddressSupplier',
  getPhonenumberPurpose:'/phyCorp/control/api/v1/crmPartner/getPhoneNumberPurpose',
  deleteContactInformationSupplier:'/phyCorp/control/api/v1/crmPartner/deleteContactInformationSupplier',
  getContactInformationSupplier:'/phyCorp/control/api/v1/crmPartner/getContactInformationSupplier',
  getAddressSupplier:'/phyCorp/control/api/v1/crmPartner/getAddressSupplier',
  updateContactInformationSupplier:'/phyCorp/control/api/v1/crmPartner/updateContactInformationSupplier',
  updateAddressSupplier:'/phyCorp/control/api/v1/crmPartner/updateAddressSupplier',
  getEmailaddressPurpose:'/phyCorp/control/api/v1/crmPartner/getEmailAddressPurpose',
  createEmailSupplier:'/phyCorp/control/api/v1/crmPartner/createEmailSupplier',
  deleteEmailSupplier:'/phyCorp/control/api/v1/crmPartner/deleteEmailSupplier',
  getEmailSupplier:'/phyCorp/control/api/v1/crmPartner/getEmailSupplier',
  updateEmailSupplier:'/phyCorp/control/api/v1/crmPartner/updateEmailSupplier',
  getFindSupplier:'/phyCorp/control/api/v1/crmPartner/getFindSupplier',
 
  getRequirementPlanningById:'/phyCorp/control/api/v1/crmPartner/getRequirementPlanningById',
  getFacilityId:'/phyCorp/control/api/v1/crmPartner/getFacilityId',
  getRequirementTypeId:'/phyCorp/control/api/v1/crmPartner/getRequirementTypeId',
  getFIndReview:'/phyCorp/control/api/v1/catalog/productPrice/getFIndReview',
  getFIndReviewTable:'/phyCorp/control/api/v1/catalog/productPrice/getFIndReview',
  updateProductReview:'/phyCorp/control/api/v1/catalog/productPrice/updateProductReview',
  getReviewStatus:'/phyCorp/control/api/v1/catalog/productPrice/getReviewStatus',
  updateProductReviewStatus:'/phyCorp/control/api/v1/catalog/productPrice/updateProductReviewStatus  ',
  updateProductReviewStatusApprove:'/phyCorp/control/api/v1/catalog/productPrice/updateProductReviewStatus  ',
  getCarrierShipmentMethod:'/phyCorp/control/api/v1/catalog/shipmentMethodType/getCarrierShipmentMethod ',
  expireShipmentTimeEstimate:'/phyCorp/control/api/v1/catalog/shipmentMethodType/expireShipmentTimeEstimate ',
  createShipmentTimeEstimate:'/phyCorp/control/api/v1/catalog/shipmentMethodType/createShipmentTimeEstimate  ',
  getShipmentTimeEstimate:'/phyCorp/control/api/v1/catalog/shipmentMethodType/getShipmentTimeEstimate',
  getShipmentTimeEstimateById:'/phyCorp/control/api/v1/catalog/shipmentMethodType/getShipmentTimeEstimateById',
  getLeadTimeUomId:'/phyCorp/control/api/v1/catalog/shipmentMethodType/getLeadTimeUomId  ',
  getPrepareCreateShipmentTimeEstimate:'/phyCorp/control/api/v1/catalog/shipmentMethodType/getPrepareCreateShipmentTimeEstimate  ',
  getGeoIds:'/phyCorp/control/api/v1/catalog/shipmentMethodType/getGeoId ',
  updateShipmentTimeEstimate:'/phyCorp/control/api/v1/catalog/shipmentMethodType/updateShipmentTimeEstimate',
  deleteProductPriceRule:'/phyCorp/control/api/v1/catalog/deleteProductPriceRule',
  updateProductPriceRule:'/phyCorp/control/api/v1/catalog/updateProductPriceRule',
  getPriceRuleProductPriceRuleId:'/phyCorp/control/api/v1/catalog/getPriceRuleProductPriceRuleId/:productPriceRuleId',
  getInputList:'/phyCorp/control/api/v1/catalog/getInputList',
  getProductPriceRulesCond:'/phyCorp/control/api/v1/catalog/getProductPriceRulesCond',
  updateProductPriceCond:'/phyCorp/control/api/v1/catalog/updateProductPriceCond',
  createProductPriceCond:'/phyCorp/control/api/v1/catalog/createProductPriceCond',
  getPriceRuleCond:'/phyCorp/control/api/v1/catalog/getPriceRuleCond/:productPriceRuleId',
  deleteProductPriceCond:'/phyCorp/control/api/v1/catalog/deleteProductPriceCond',
  getConditionValues:'/phyCorp/control/api/v1/catalog/getConditionValues',
  getPriceRuleList:'/phyCorp/control/api/v1/catalog/getPriceRuleList',
  createProductPriceRule:'/phyCorp/control/api/v1/catalog/createProductPriceRule  ',
  getProductStoreGroupList:'/phyCorp/control/api/v1/catalog/productPrice/productStoreGroup',
  getLookAndFeelWebBasedStore:'/phyCorp/control/api/v1/catalog/getLookAndFeelWebBasedStore',
  getInventoryFacilityId:'/phyCorp/control/api/v1/catalog/getInventoryFacilityId',
  getRequirementMethodEnumId:'/phyCorp/control/api/v1/catalog/getRequirementMethodEnumId',
  getScoreCreditAccountList:'/phyCorp/control/api/v1/catalog/getScoreCreditAccountList',
  getDefaultSalesChannelEnumList:'/phyCorp/control/api/v1/catalog/getDefaultSalesChannelEnumList',
  getReserveOrderEnumId:'/phyCorp/control/api/v1/catalog/getReserveOrderEnumId',
  getHeaderApprovedStatus:'/phyCorp/control/api/v1/catalog/getHeaderApprovedStatus',
  getItemApprovedStatus:'/phyCorp/control/api/v1/catalog/getItemApprovedStatus',
  createProductStore:'/phyCorp/control/api/v1/catalog/createProductStore',  
  getDigProdUploadCategoryList:'/phyCorp/control/api/v1/catalog/getDigProdUploadCategoryList',
  getFindConfigItem:'/phyCorp/control/api/v1/catalog/productPrice/getFindConfigItem',
  getVatTaxAuthGeoId:'/phyCorp/control/api/v1/catalog/getVatTaxAuthGeoId',
  createProductPriceAction:'/phyCorp/control/api/v1/catalog/createProductPriceAction',
  deleteProductPriceAction:'/phyCorp/control/api/v1/catalog/deleteProductPriceAction',
  getActionList:'/phyCorp/control/api/v1/catalog/getActionList/:productPriceRuleId',
  getActionTypeList:'/phyCorp/control/api/v1/catalog/getActionTypeList',
  updateProductPriceAction:'/phyCorp/control/api/v1/catalog/updateProductPriceAction',
  findSubscription:'/phyCorp/control/api/v1/catalog/subscription/findSubscription',
  getSubscriptionResourceId:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionResourceId',
  getSubscriptionType:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionType',
  getOrderById:'/phyCorp/control/api/v1/assetMaintenance/getOrderId',
  getProductIdAction:'/phyCorp/control/api/v1/crmPartner/getProductId',
  updateProductStore:'/phyCorp/control/api/v1/catalog/updateProductStore',
  getProductStoreById:'/phyCorp/control/api/v1/catalog/getProductStoreById',
  updateSubscriptionResource:'/phyCorp/control/api/v1/catalog/subscription/updateSubscriptionResource  ',
  getSubscriptionResourceById:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionResourceById/:subscriptionResourceId',
  getSubscriptionResourceList:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionResourceList',
  getContentIdResource:'/phyCorp/control/api/v1/catalog/subscription/getContentIdResource  ',
  createSubscriptionResource:'/phyCorp/control/api/v1/catalog/subscription/createSubscriptionResource',
  getWebSiteId:'/phyCorp/control/api/v1/catalog/shipmentMethodType/getWebSiteId',
  getSubscriptionProductList:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionProductList',
  createProductSubscriptionResourceSr:'/phyCorp/control/api/v1/catalog/subscription/createProductSubscriptionResourceSr',
  deleteProductSubscriptionResource:'/phyCorp/control/api/v1/catalog/subscription/deleteProductSubscriptionResourceSr',
  getProductVendorStoreShipmentListById:'/phyCorp/control/api/v1/catalog/getProductVendorStoreShipmentListById',
  deleteProductStoreVendorShipment:'/phyCorp/control/api/v1/catalog/deleteProductStoreVendorShipment',
  getShipmentMethodTypeIdList:'/phyCorp/control/api/v1/catalog/getShipmentMethodTypeIdList',
  createProductStoreVendorShipment:'/phyCorp/control/api/v1/catalog/createProductStoreVendorShipment',
  getProductStoreVendorPaymentListById:'/phyCorp/control/api/v1/catalog/getProductStoreVendorPaymentListById',
  deleteProductStoreVendorPayment:'/phyCorp/control/api/v1/catalog/deleteProductStoreVendorPayment',
  getCreditCardEnumIdList:'/phyCorp/control/api/v1/catalog/getCreditCardEnumIdList',
  createProductStoreVendorPayment:'/phyCorp/control/api/v1/catalog/createProductStoreVendorPayment',
  getPaymentMethodTypeList:'/phyCorp/control/api/v1/catalog/getPaymentMethodTypeList',
  getProductSurveyListById:'/phyCorp/control/api/v1/catalog/getProductSurveyListById',
  deleteProductStoreSurveyAppl:'/phyCorp/control/api/v1/catalog/deleteProductStoreSurveyAppl',
  getProductStoreKeywordOvrdById:'/phyCorp/control/api/v1/catalog/getProductStoreKeywordOvrdById',
  deleteProductStoreKeywordOvrd:'/phyCorp/control/api/v1/catalog/deleteProductStoreKeywordOvrd',
  updateProductSubscriptionResource:'/phyCorp/control/api/v1/catalog/subscription/updateProductSubscriptionResourceSr',
  getOriginatedFromRoleTypeId:'/phyCorp/control/api/v1/catalog/subscription/getOriginatedFromRoleTypeId',
  createSubscription:'/phyCorp/control/api/v1/catalog/subscription/createSubscription',
  getUseTimeUom:'/phyCorp/control/api/v1/catalog/subscription/getUseTimeUom',
  getProductCategorySubscription:'/phyCorp/control/api/v1/crmPartner/getProductCategory',
  getUseRoleTypeId:'/phyCorp/control/api/v1/catalog/subscription/getUseRoleTypeId',
  getSubscriptionById:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionById/:subscriptionId',
  getUseTimeUomId:'/phyCorp/control/api/v1/catalog/subscription/getUseTimeUomId  ',
  updateSubscription:'/phyCorp/control/api/v1/catalog/subscription/updateSubscription  ',
  getTargetTypeEnumIdList:'/phyCorp/control/api/v1/catalog/getTargetTypeEnumIdList',
  createProductStoreKeywordOvrd:'/phyCorp/control/api/v1/catalog/createProductStoreKeywordOvrd',
  updateProductStoreKeywordOvrd:'/phyCorp/control/api/v1/catalog/updateProductStoreKeywordOvrd',
  getSurveyListValue:'/phyCorp/control/api/v1/catalog/getSurveyList',
  getSurveyTypeList:'/phyCorp/control/api/v1/catalog/getSurveyTypeList',
  createProductStoreSurveyAppl:'/phyCorp/control/api/v1/catalog/createProductStoreSurveyAppl',
  getViewProductStoreSegmentsId:'/phyCorp/control/api/v1/marketingCampaignController/getViewProductStoreSegmentsId',
  deleteSegmentGroupById:'/phyCorp/control/api/v1/marketingCampaignController/deleteSegmentGroup',
  getCreateProductGeo:'/phyCorp/control/api/v1/catalogProduct/getCreateProductGeo',
  getProductGeoEnumId:'/phyCorp/control/api/v1/catalogProduct/getProductGeoEnumId',
  createProductGeo:'/phyCorp/control/api/v1/catalogProduct/createProductGeo',
  updateProductGeo:'/phyCorp/control/api/v1/catalogProduct/updateProductGeo',
  getCreateProductGeoById:'/phyCorp/control/api/v1/catalogProduct/getCreateProductGeoById',
  getCommunicationEventIdSubs:'/phyCorp/control/api/v1/workEffort/getCommunicationEventId',
  updateSubscriptionAttribute:'/phyCorp/control/api/v1/catalog/subscription/updateSubscriptionAttribute',
  createSubscriptionAttribute:'/phyCorp/control/api/v1/catalog/subscription/updateSubscriptionAttribute',
  getSubscriptionAttributeById:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionAttributeById/:subscriptionId',
  createTask:'/phyCorp/control/api/v1/crmPartner/createPendingActivityTask',
  getPendingActivityTaskCrmAll:'/phyCorp/control/api/v1/crmPartner/getPendingActivityTaskCrmAll',
  getStoreFacilitySettingById:'/phyCorp/control/api/v1/catalog/getStoreFacilitySettingById',
  deleteProductStoreFacility:'/phyCorp/control/api/v1/catalog/deleteProductStoreFacility',
  getFacilityIdListValue:'/phyCorp/control/api/v1/catalog/getFacilityIdList',
  addProductStoreFacility:'/phyCorp/control/api/v1/catalog/addProductStoreFacility',
  getProductStoreFinAccountSettingsListById:'/phyCorp/control/api/v1/catalog/getProductStoreFinAccountSettingsListById',
  RemoveProductStoreFinAccountSettings:'/phyCorp/control/api/v1/catalog/RemoveProductStoreFinAccountSettings',
  getFinAccountTypeIdList:'/phyCorp/control/api/v1/catalog/getFinAccountTypeIdList',
  getReplenishEnumTypeIdList:'/phyCorp/control/api/v1/catalog/getReplenishEnumTypeIdList',
  CreateProductStoreFinAccountSettings:'/phyCorp/control/api/v1/catalog/CreateProductStoreFinAccountSettings',
  updateProductStoreFinAccountSettings:'/phyCorp/control/api/v1/catalog/updateProductStoreFinAccountSettings',
  getProductStoreEmailSetupById:'/phyCorp/control/api/v1/catalog/getProductStoreEmailSetupById',
  removeProductStoreEmail:'/phyCorp/control/api/v1/catalog/removeProductStoreEmail',
  getGoodIdentificationById:'/phyCorp/control/api/v1/catalogProduct/getGoodIdentificationById',
  getGoodIdentificationTypeId:'/phyCorp/control/api/v1/catalogProduct/getGoodIdentificationTypeId',
  createGoodIdentification:'/phyCorp/control/api/v1/catalogProduct/createGoodIdentification',
  updateGoodIdentification:'/phyCorp/control/api/v1/catalogProduct/updateGoodIdentification',
  deleteGoodIdentification:'/phyCorp/control/api/v1/catalogProduct/deleteGoodIdentification',
  deleteProductGeo:'/phyCorp/control/api/v1/catalogProduct/deleteProductGeo',
  getsupplierById:'/phyCorp/control/api/v1/catalog/productCatalog/supplier',
  getAddProductToCategoryById:'/phyCorp/control/api/v1/catalogProduct/getAddProductToCategoryById',
  addProductToCategory:'/phyCorp/control/api/v1/catalogProduct/addProductToCategory',
  getProductCategoryForStore:'/phyCorp/control/api/v1/catalogProduct/getProductCategory',
  getManagePortalPagesEmailsUnKnown:'/phyCorp/control/api/v1/crmPartner/getManagePortalPagesEmailsUnKnownBy',
  deleteCommunicationEventsBy:'/phyCorp/control/api/v1/crmPartner/deleteCommunicationEventsBy',
  createCommunicationEventRole:'/phyCorp/control/api/v1/sfa/communicationEvents/role/:communicationEventId',
  deleteCommunicationEventRole:'/phyCorp/control/api/v1/sfa/communicationEvents/role/:communicationEventId',
  getEventRoles:'/phyCorp/control/api/v1/sfa/communicationEvents/role/:communicationEventId',
  getEventRoleType:'/phyCorp/control/api/v1/sfa/communicationEvents/role/type',
  getexistingContentIds:'/phyCorp/control/api/v1/crmPartner/existingContentIds',
  sendSaveCommunicationEventSave:'/phyCorp/control/api/v1/sfa/communicationEvents/role/sendSaveCommunicationEvent',
  getparentContentIdList:'/phyCorp/control/api/v1/crmPartner/parentContentIdList',
  getCommunicationCalender:'/phyCorp/control/api/v1/crmPartner/getCommunicationCalender',
  getCommunicationContentUploadById:'/phyCorp/control/api/v1/crmPartner/getCommunicationContentUploadById',
  createUpload:'/phyCorp/control/api/v1/crmPartner/createUpload',
  deleteUpload:'/phyCorp/control/api/v1/crmPartner/deleteUpload',
  sendSaveCommunicationEvent:'/phyCorp/control/api/v1/sfa/communicationEvents/role/sendSaveCommunicationEvent',
  getCreatePortalPage:'/phyCorp/control/api/v1/crmPartner/getCreatePortalPage',
  getRoleTypeIdPre:'/phyCorp/control/api/v1/crmPartner/getRoleTypeId',
  sendCommunicationEvent:'/phyCorp/control/api/v1/sfa/communicationEvents/sendCommunicationEvent',
  updateProductToCategory:'/phyCorp/control/api/v1/catalogProduct/updateProductToCategory',
  removeProductFromCategory:'/phyCorp/control/api/v1/catalogProduct/removeProductFromCategory',
  getproductsKeywordById:'/phyCorp/control/api/v1/catalog/productsKeyword',
  getKeywordTypeIdsKeyword:'/phyCorp/control/api/v1/catalog/productsKeyword/getKeywordTypeIdsKeyword',
  getKeywordTypeIdsTag:'/phyCorp/control/api/v1/catalog/productsKeyword/getKeywordTypeIdsTag',
  keywordTypeIds:'/phyCorp/control/api/v1/catalog/productsKeyword/keywordTypeIds',
  statusIds:'/phyCorp/control/api/v1/catalog/productsKeyword/statusIds',
  getEventCommRoles:'/phyCorp/control/api/v1/sfa/communicationEvents/role/:communicationEventId',
  sendCommunicationEventSave:'/phyCorp/control/api/v1/sfa/communicationEvents/sendCommunicationEvent',
  rejectCustomerReq:'/phyCorp/control/api/v1/project/setCustRequestStatus',
  getCommunicationEvent:'/phyCorp/control/api/v1/project/getCommunicationEvents',
  deletegetCrmEventContentList:'/phyCorp/control/api/v1/sfa/communicationEvents/content/:communicationEventId',
  createPortalPage:'/phyCorp/control/api/v1/crmPartner/createPortalPage  ',
  getChildCommunicationEvent:'/phyCorp/control/api/v1/scrumNewController/getChildCommunicationOverviewEvent',
  getRequestListDetails:'/phyCorp/control/api/v1/project/getMainPageRequestListDetails',
  getCommunicationEventsBYCe:'/phyCorp/control/api/v1/project/getCommunicationEventsBYCe',
  getEventContentList:'/phyCorp/control/api/v1/sfa/communicationEvents/content/:communicationEventId',
  allocateMsgToPartyBy:'/phyCorp/control/api/v1/crmPartner/allocateMsgToPartyBy',
  updatePostCommunicationNew:'/phyCorp/control/api/v1/sfa/communicationEvents',
  getCrmInitialCommunicationData:'/phyCorp/control/api/v1/sfa/communicationEvents/initialData',
  getCalenStatusList:'/phyCorp/control/api/v1/assetMaintenance/getCalStatusList',
  updateCalendarEvent:'/phyCorp/control/api/v1/myPortal/updateWorkEffort',
  createCalendarEvents:'/phyCorp/control/api/v1/myPortal/createWorkEffortAndPartyAssign',
  getEventTypes:'/phyCorp/control/api/v1/workEffort/getEventType',
  getScopesList:'/phyCorp/control/api/v1/projects/getScopeList',
  getCommunicationEventsReq:'/phyCorp/control/api/v1/project/getCommunicationEventsReq',
  createProductKeyword:'/phyCorp/control/api/v1/catalog/productsKeyword',
  updateProductKeyword:'/phyCorp/control/api/v1/catalog/productsKeyword',
  deleteProductKeyword:'/phyCorp/control/api/v1/catalog/productsKeyword',
  getProductAssocById:'/phyCorp/control/api/v1/catalogProduct/getProductAssocById',
  getCatalogProductId:'/phyCorp/control/api/v1/catalogProduct/getProductId',
  getProductAssocType:'/phyCorp/control/api/v1/catalogProduct/getProductAssocType',
  createProductAssoc:'/phyCorp/control/api/v1/catalogProduct/createProductAssoc',
  updateProductAssoc:'/phyCorp/control/api/v1/catalogProduct/updateProductAssoc',
  deleteProductAssoc:'/phyCorp/control/api/v1/catalogProduct/deleteProductAssoc',
  getProductStoreByProductPromoId:'/phyCorp/control/api/v1/catalog/getProductStoreByProductPromoId/:productPromoId',
  getProductstoreId:'/phyCorp/control/api/v1/catalog/getProductStoreId',
  promo_deleteProductStorePromoAppl:'/phyCorp/control/api/v1/catalog/promo_deleteProductStorePromoAppl  ',
  promo_updateProductStorePromoAppl:'/phyCorp/control/api/v1/catalog/promo_updateProductStorePromoAppl',
  createProductPromoCode:'/phyCorp/control/api/v1/catalog/createProductPromoCode',
  updateProductPromoCode:'/phyCorp/control/api/v1/catalog/updateProductPromoCode',
  getProductPromotionCodeByProductPromoId:'/phyCorp/control/api/v1/catalog/getProductPromotionCodeByProductPromoId/:productPromoCodeId',
  getPromotionManualImported:'/phyCorp/control/api/v1/catalog/getPromotionManualImported/:manualOnly/:productPromoId',
  ManualImportedOnly:'/phyCorp/control/api/v1/catalog/getPromotionManualImported/:manualOnly/:productPromoId',
  getPromotionCodeList:'/phyCorp/control/api/v1/catalog/getPromotionCodeList/:productPromoId',
  getProductPromotionCodeById:'/phyCorp/control/api/v1/catalog/getProductPromotionCodeById/:productPromoCodeId',
  deleteProductPromoCode:'/phyCorp/control/api/v1/catalog/deleteProductPromoCode  ',
  updateProductPromo:'/phyCorp/control/api/v1/catalog/updateProductPromo  ',
  createBulkProductPromoCode:'/phyCorp/control/api/v1/catalog/createBulkProductPromoCode',
  getPromoCodeLayout:'/phyCorp/control/api/v1/catalog/getPromoCodeLayout',
  createProductPromoCodeSet:'/phyCorp/control/api/v1/catalog/createProductPromoCodeSet',
  getPromotionCodeListNew:'/phyCorp/control/api/v1/catalog/getPromotionCodeListNew',
  getSelectSalesReps:'/phyCorp/control/api/v1/webPos/getSelectSalesReps',
  promo_createProductStorePromoAppl:'/phyCorp/control/api/v1/catalog/createProductStorePromoAppl',
  createNewProductPromo:'/phyCorp/control/api/v1/catalog/productPrice/createProductPromo',
  updateProductCode:'/phyCorp/control/api/v1/catalog/updateProductPromoCode',
  createProductPromoCodeParty:'/phyCorp/control/api/v1/catalog/createProductPromoCodeParty',
  deleteProductPromoCodeParty:'/phyCorp/control/api/v1/catalog/deleteProductPromoCodeParty',
  getPromoCodePartiesList:'/phyCorp/control/api/v1/catalog/getPromoCodePartiesList',
  createProductPromoRule:'/phyCorp/control/api/v1/catalog/createProductPromoRule',
  getConditionValue:'/phyCorp/control/api/v1/catalog/getConditionValues',
  createPayCash:'/phyCorp/control/api/v1/webPos/PayCash',
  createpayCheck:'/phyCorp/control/api/v1/webPos/payCheck',
  createpayGiftCard:'/phyCorp/control/api/v1/webPos/payGiftCard',
  getProductAttributeById:'/phyCorp/control/api/v1/catalogProduct/getProductAttributeById',
  createProductAttribute:'/phyCorp/control/api/v1/catalogProduct/createProductAttribute',
  updateProductAttribute:'/phyCorp/control/api/v1/catalogProduct/updateProductAttribute',
  deleteProductAttribute:'/phyCorp/control/api/v1/catalogProduct/deleteProductAttribute',
  getEnumCodeId:'/phyCorp/control/api/v1/catalog/getEnumCodeId',
  getProductFacilityById:'/phyCorp/control/api/v1/catalogProduct/getProductFacilityById',
  getProductFacilityId:'/phyCorp/control/api/v1/catalogProduct/getFacilityId',
  createProductFacility:'/phyCorp/control/api/v1/catalogProduct/createProductFacility',
  updateProductFacility:'/phyCorp/control/api/v1/catalogProduct/updateProductFacility',
  deleteProductFacility:'/phyCorp/control/api/v1/catalogProduct/deleteProductFacility',
  getShipmentMethodList:'/phyCorp/control/api/v1/catalog/getShipmentMethodList',
  getProductCategoryLists:'/phyCorp/control/api/v1/catalog/getProductCategoryList',
  deleteProductPromoProduct:'/phyCorp/control/api/v1/catalog/deleteProductPromoProduct',
  getCurrencyValue: '/phyCorp/control/api/v1/resources/currency',
  getPromotionProductList:'/phyCorp/control/api/v1/catalog/getPromotionProductList/:productPromoId',
  getContactmechID:'/phyCorp/control/api/v1/workEffort/getContactMechId',
  createProductPromoProduct:'/phyCorp/control/api/v1/catalog/createProductPromoProduct',
  createProductPromoCategory:'/phyCorp/control/api/v1/catalog/createProductPromoCategory',
  getCatalogProdId:'/phyCorp/control/api/v1/catalogProduct/getProductId',
  getPromotionCategoryList:'/phyCorp/control/api/v1/catalog/getPromotionCategoryList/:productPromoId',
  deleteProductPromoCategory:'/phyCorp/control/api/v1/catalog/deleteProductPromoCategory',
  getActionTypePriceRules:'/phyCorp/control/api/v1/catalog/getActionTypePriceRules',
  getProductFacilityLocationById:'/phyCorp/control/api/v1/catalogProduct/getProductFacilityLocationById',
  getLocationSeqId:'/phyCorp/control/api/v1/catalogProduct/getLocationSeqId',
  createProductFacilityLocation:'/phyCorp/control/api/v1/catalogProduct/createProductFacilityLocation',
  updateProductFacilityLocation:'/phyCorp/control/api/v1/catalogProduct/updateProductFacilityLocation',
  deleteProductFacilityLocation:'/phyCorp/control/api/v1/catalogProduct/deleteProductFacilityLocation',
  getInventoryItemId:'/phyCorp/control/api/v1/catalogProduct/getInventoryItemId',
  getCustomMethodId:'/phyCorp/control/api/v1/catalog/getCustomMethodList',
  deleteProductPromoRule:'/phyCorp/control/api/v1/catalog/deleteProductPromoRule',
  updateProductPromoRule:'/phyCorp/control/api/v1/catalog/updateProductPromoRule',
  getProductPromoRules:'/phyCorp/control/api/v1/catalog/getProductPromoRules/:productPromoId',
  createProductPromoCond:'/phyCorp/control/api/v1/catalog/createProductPromoCond',
  deleteProductActionProduct:'/phyCorp/control/api/v1/catalog/deleteProductPromoProduct',
  updateProductPromoCond:'/phyCorp/control/api/v1/catalog/updateProductPromoCond',
  deleteProductPromoCond:'/phyCorp/control/api/v1/catalog/deleteProductPromoCond',
  removeCommunicationEvent:'/phyCorp/control/api/v1/sfa/communicationEvents/:communicationEventId  ',
  getNewList:'/phyCorp/control/api/v1/sfa/communicationEvents/getNewList',
  createProductPromoAction:'/phyCorp/control/api/v1/catalog/createProductPromoAction',
  deleteProductPromoAction:'/phyCorp/control/api/v1/catalog/deleteProductPromoAction',
  updateProductPromoAction:'/phyCorp/control/api/v1/catalog/updateProductPromoAction',
  createProductActionCategory:'/phyCorp/control/api/v1/catalog/createProductPromoCategory',
  deleteProductActionCategory:'/phyCorp/control/api/v1/catalog/deleteProductPromoCategory',
  getPreNewSecond:'/phyCorp/control/api/v1/crmPartner/getPreNewSecond',
  createProductActionProduct:'/phyCorp/control/api/v1/catalog/createProductPromoProduct',
  getPreNew:'/phyCorp/control/api/v1/crmPartner/getPreNew',
  getEditProductMetersByID:'/phyCorp/control/api/v1/catalogProduct/getEditProductMetersByID',
  getProductMeterTypeId:'/phyCorp/control/api/v1/catalogProduct/getProductMeterTypeId',
  createProductMeter:'/phyCorp/control/api/v1/catalogProduct/createProductMeter',
  updateProductMeter:'/phyCorp/control/api/v1/catalogProduct/updateProductMeter',
  deleteProductMeter:'/phyCorp/control/api/v1/catalogProduct/deleteProductMeter',
  getEmailTypeList:'/phyCorp/control/api/v1/catalog/getEmailTypeList',
  createProductStoreEmail:'/phyCorp/control/api/v1/catalog/createProductStoreEmail',
  createProductPaymentMethodType:'/phyCorp/control/api/v1/catalogProduct/createProductPaymentMethodType',
  updateProductPaymentMethodType:'/phyCorp/control/api/v1/catalogProduct/updateProductPaymentMethodType',
  deleteProductPaymentMethodType:'/phyCorp/control/api/v1/catalogProduct/deleteProductPaymentMethodType',
  getProductGlAccountDetail:'/phyCorp/control/api/v1/company/productGlAccount/detailProductGlAccount',
  getAccountType:'/phyCorp/control/api/v1/company/productGlAccount/getAccountType',
  getOrganization:'/phyCorp/control/api/v1/company/productGlAccount/getOrganization',
  getGLAccount:'/phyCorp/control/api/v1/company/productGlAccount/getGLAccount',
  createProductGlAccount:'/phyCorp/control/api/v1/company/productGlAccount/createProductGlAccount',
  updateGlProductAccount:'/phyCorp/control/api/v1/company/productGlAccount/updateProductGlAccount',
  removeGlProductAccount:'/phyCorp/control/api/v1/company/productGlAccount/removeProductGlAccount',
  getProductPaymentMethodAll:'/phyCorp/control/api/v1/catalogProduct/getProductPaymentMethodAll',
  createCategoryAction:'/phyCorp/control/api/v1/catalog/createProductPromoProduct',
  addActionCategory:'/phyCorp/control/api/v1/catalog/createProductPromoCategory',
  getProductPromoEnumTypeIdList:'/phyCorp/control/api/v1/catalog/getProductPromoEnumTypeIdList',
  paymentMethodType:'/phyCorp/control/api/v1/catalogProduct/paymentMethodType',
  productPricePurpose:'/phyCorp/control/api/v1/catalogProduct/productPricePurpose',
  deleteProductCategory:'/phyCorp/control/api/v1/catalog/deleteProductPromoCategory',
  updateProductStoreEmail:'/phyCorp/control/api/v1/catalog/updateProductStoreEmail',
  getProductStorePaymentListById:'/phyCorp/control/api/v1/catalog/getProductStorePaymentListById',
  storeRemovePaySetting:'/phyCorp/control/api/v1/catalog/storeRemovePaySetting',
  getServiceTypeList:'/phyCorp/control/api/v1/catalog/getServiceTypeList',
  getCustomMethodListPayment:'/phyCorp/control/api/v1/catalog/getCustomMethodListPayment',
  getPaymentGatewayConfigIdList:'/phyCorp/control/api/v1/catalog/getPaymentGatewayConfigIdList',
  storeCreatePaySetting:'/phyCorp/control/api/v1/catalog/storeCreatePaySetting',
  storeUpdatePaySetting:'/phyCorp/control/api/v1/catalog/storeUpdatePaySetting',
  findProductStore:'/phyCorp/control/api/v1/catalog/findProductStore',
  storeCreateRole:'/phyCorp/control/api/v1/catalog/storeCreateRole',
  storeRemoveRole:'/phyCorp/control/api/v1/catalog/storeRemoveRole',
  storeUpdateRole:'/phyCorp/control/api/v1/catalog/storeUpdateRole',
  findOrderRequest:'/phyCorp/control/api/v1/order/findOrderRequest',
  getFindOrderRequest:'/phyCorp/control/api/v1/order/findOrderRequest',
  getFindOrderList:'/phyCorp/control/api/v1/order/findOrderList',
  getCustRequestTypeId:'/phyCorp/control/api/v1/project/getCustRequestTypeID',
  getProjectandPhaseName:'/phyCorp/control/api/v1/project/getProjectAndPhaseName',
  createRequirement:'/phyCorp/control/api/v1/order/createRequirement',
  deleteRequirement:'/phyCorp/control/api/v1/order/deleteRequirement',
  findRequirements:'/phyCorp/control/api/v1/order/findRequirement',
  getFindRequirements:'/phyCorp/control/api/v1/order/findRequirement',
  getProductStoreCatalog:'/phyCorp/control/api/v1/catalog/getProductStoreCatalog',
  deleteProductStoreCatalog:'/phyCorp/control/api/v1/catalog/deleteProductStoreCatalog',
  getCatalogList:'/phyCorp/control/api/v1/catalog/getCatalogList',
  createProductStoreCatalog:'/phyCorp/control/api/v1/catalog/createProductStoreCatalog',
  updateProductStoreCatalog:'/phyCorp/control/api/v1/catalog/updateProductStoreCatalog',
  getFacilityRequirement:'/phyCorp/control/api/v1/order/getFacilityRequirement',
  getVendorProductByID:'/phyCorp/control/api/v1/catalogProduct/getVendorProductByID',
  getProductStoreGroupID:'/phyCorp/control/api/v1/catalogProduct/getProductStoreGroupID',
  deleteVendorProduct:'/phyCorp/control/api/v1/catalogProduct/deleteVendorProduct',
  getFixedAssetIdRequirement:'/phyCorp/control/api/v1/order/getFixedAssetIdRequirement  ',
  getRequirementType:'/phyCorp/control/api/v1/order/getRequirementType',
  createVendorProduct:'/phyCorp/control/api/v1/catalogProduct/createVendorProduct',
  getRequirementById:'/phyCorp/control/api/v1/order/getRequirementById/:requirementId',
  updateRequirement:'/phyCorp/control/api/v1/order/updateRequirement',
  getRequirementRoleListById:'/phyCorp/control/api/v1/order/getRequirementRoleListById/:requirementId',
  createRequirementRole:'/phyCorp/control/api/v1/order/createRequirementRole',
  getRoleTypeIdOrder:'/phyCorp/control/api/v1/marketingDataSourceController/getRoleTypeId',
  deleteRequirementRole:'/phyCorp/control/api/v1/order/deleteRequirementRole',
  getfindNotApprovedRequirement:'/phyCorp/control/api/v1/order/findNotApprovedRequirement',
  getRequirementRoleByPartyId:'/phyCorp/control/api/v1/order/getRequirementRoleByPartyId/:partyId',
  updateRequirementRole:'/phyCorp/control/api/v1/order/updateRequirementRole',
  findNotApprovedRequirement:'/phyCorp/control/api/v1/order/findNotApprovedRequirement',
  approveRequirement:'/phyCorp/control/api/v1/order/approveRequirement/:requirementId',
  billToCustomerPartyId:'/phyCorp/control/api/v1/order/billToCustomerPartyId',
  getSupplierList:'/phyCorp/control/api/v1/order/getSupplierList',
  autoAssignRequirementToSupplier:'/phyCorp/control/api/v1/order/autoAssignRequirementToSupplier',
  getPartyToProductById:'/phyCorp/control/api/v1/catalogProduct/getPartyToProductById',
  addPartyToProduct:'/phyCorp/control/api/v1/catalogProduct/addPartyToProduct',
  updatePartyToProduct:'/phyCorp/control/api/v1/catalogProduct/updatePartyToProduct',
  removePartyFromProduct:'/phyCorp/control/api/v1/catalogProduct/removePartyFromProduct',
  findApprovedRequirementsBySupplier:'/phyCorp/control/api/v1/order/findApprovedRequirementsBySupplier',
  approvedProductRequirementsReport:'/phyCorp/control/api/v1/order/approvedProductRequirementsReport',
  getFacilityIdListOrder:'/phyCorp/control/api/v1/order/getFacilityIdList',
  getSalesChannel:'/phyCorp/control/api/v1/order/getSalesChannel',
  getProductStoreIdReport:'/phyCorp/control/api/v1/order/getProductStoreIdReport',
  getOriginFacilityIdReport:'/phyCorp/control/api/v1/order/getOriginFacilityIdReport',
  generateSalesByStoreReportPdf:'/phyCorp/control/api/v1/order/generateSalesByStoreReportPdf',
  generatePurchaseByProductReportPdf:'/phyCorp/control/api/v1/order/generatePurchaseByProductReportPdf',
  getEditProductWorkEfforts:'/phyCorp/control/api/v1/catalogProduct/getEditProductWorkEfforts',
  getProWorkEffortId:'/phyCorp/control/api/v1/catalogProduct/getWorkEffortId',
  getWorkEffortGoodStdTypeIdDrop:'/phyCorp/control/api/v1/catalogProduct/getWorkEffortGoodStdTypeIdDrop',
  createProWorkEffortGoodStandard:'/phyCorp/control/api/v1/catalogProduct/createWorkEffortGoodStandard',
  updateProWorkEffortGoodStandard:'/phyCorp/control/api/v1/catalogProduct/updateWorkEffortGoodStandard',
  removeProWorkEffortGoodStandard:'/phyCorp/control/api/v1/catalogProduct/removeWorkEffortGoodStandard',
  getOpenOrderItemsReport:'/phyCorp/control/api/v1/order/getOpenOrderItemsReport  ',
  generatePurchasesByPaymentMethodReportPdf:'/phyCorp/control/api/v1/order/generatePurchasesByPaymentMethodReportPdf',
  generatePurchasesByOrganizationReportPdf:'/phyCorp/control/api/v1/order/generatePurchasesByOrganizationReportPdf',
  getOrderStatusIdReport:'/phyCorp/control/api/v1/order/getOrderStatusIdReport ',
  generateSalesOrderByChannelPdf:'/phyCorp/control/api/v1/order/generateSalesOrderByChannelPdf',
  generateLastThreeMonthsSalesReport:'/phyCorp/control/api/v1/order/generateLastThreeMonthsSalesReport',
  generateSalesOrderDiscountCode:'/phyCorp/control/api/v1/order/generateSalesOrderDiscountCode  ',
  getProductGroupOrderById:'/phyCorp/control/api/v1/catalogProduct/getProductGroupOrderById',
  createProductGroupOrder:'/phyCorp/control/api/v1/catalogProduct/createProductGroupOrder',
  updateProductGroupOrder:'/phyCorp/control/api/v1/catalogProduct/updateProductGroupOrder',
  deleteProductGroupOrder:'/phyCorp/control/api/v1/catalogProduct/deleteProductGroupOrder',
  generateSalesReport:'/phyCorp/control/api/v1/order/generateSalesReport',
  generateProductDemandReport:'/phyCorp/control/api/v1/order/generateProductDemandReport',
  createrequest:'/phyCorp/control/api/v1/project/createRequest ',
  setCustRequestOrderStatus:'/phyCorp/control/api/v1/project/setCustRequestStatus',
  generateCouponSalesReport:'/phyCorp/control/api/v1/order/generateCouponSalesReport',
  getOrderStats:'/phyCorp/control/api/v1/order/getOrderStats  ',
  findOrderList:'/phyCorp/control/api/v1/order/findOrderList',
  findAllocationPlan:'/phyCorp/control/api/v1/order/findAllocationPlan',
  getfindAllocationPlan:'/phyCorp/control/api/v1/order/findAllocationPlan',
  getCustRequestCategoryId:'/phyCorp/control/api/v1/workEffort/getCustRequestCategoryId ',
  deleteAllocationPlanItem:'/phyCorp/control/api/v1/order/deleteAllocationPlanItem',
  viewAllocationPlan:'/phyCorp/control/api/v1/order/viewAllocationPlan/:planId',
  changeAllocationPlanStatus:'/phyCorp/control/api/v1/order/changeAllocationPlanStatus/:planId/:statusId',
  getCreateCommunicationEventById:'/phyCorp/control/api/v1/crmPartner/getCreateCommunicationEventById',
  getCatalogCommunicationEventId:'/phyCorp/control/api/v1/crmPartner/getCommunicationEventId',
  getCataCommEventStatus:'/phyCorp/control/api/v1/crmPartner/getCommEventStatus',
  getCommTypeID:'/phyCorp/control/api/v1/crmPartner/getCommTypeID',
  getContactMechTypeID:'/phyCorp/control/api/v1/crmPartner/getContactMechTypeID',
  getFromContactMechList:'/phyCorp/control/api/v1/crmPartner/getFromContactMechList',
  getRoleTypeIdType:'/phyCorp/control/api/v1/crmPartner/getRoleTypeIdType',
  getCatalogContentMimeTypeId:'/phyCorp/control/api/v1/crmPartner/getContentMimeTypeId',
  getReasonEnumIds:'/phyCorp/control/api/v1/facility/inventoryItem/reasonEnumIds',
  createCatalogCommunicationEvent:'/phyCorp/control/api/v1/crmPartner/createCommunicationEvent',
  getProductOrderById:'/phyCorp/control/api/v1/crmPartner/getProductOrderById',
  getActiveOrInActive_All:'/phyCorp/control/api/v1/catalog/getActiveOrInActive_All',
  getStorePromoList:'/phyCorp/control/api/v1/catalog/getStorePromoList',
  createProductStorePromoAppl:'/phyCorp/control/api/v1/catalog/createProductStorePromoAppl',
  updateProductStorePromoAppl:'/phyCorp/control/api/v1/catalog/updateProductStorePromoAppl',
  copyQuoteOrders:'/phyCorp/control/api/v1/project/copyQuote',
  getProductStoreShipmentEstiById:'/phyCorp/control/api/v1/catalog/getProductStoreShipmentEstiById',
  getQuoteTypeIdList:'/phyCorp/control/api/v1/project/getQuoteTypeIdList',
  storeRemoveShipRate:'/phyCorp/control/api/v1/catalog/storeRemoveShipRate',
  getSubscriptionProductListBYId:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionProductListBYId',
  getEditProductMaints:'/phyCorp/control/api/v1/assetMaintenance/getEditProductMaints',
  getSubscriptionResourceIdNew:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionResourceIdNew',
  sendQuoteOrderReportMail:'/phyCorp/control/api/v1/project/sendQuoteReportMail',
  getQuoteItem:'/phyCorp/control/api/v1/project/getQuoteItem',
  createQuoteOrder:'/phyCorp/control/api/v1/project/createQuote',
  getMainQuoteOrder:'/phyCorp/control/api/v1/project/getMainQuote',
  productBarCode:'/phyCorp/control/api/v1/catalogProduct/productBarCode',
  commissionAgreements:'/phyCorp/control/api/v1/workEffort/commissionAgreements',
  getSalesAgreements:'/phyCorp/control/api/v1/workEffort/getSalesAgreements',
  productAgreements:'/phyCorp/control/api/v1/workEffort/productAgreements',
  SupplierProduct:'/phyCorp/control/api/v1/workEffort/SupplierProduct',
  createSalesAgreement:'/phyCorp/control/api/v1/workEffort/createSalesAgreement',
  addProductVariants:'/phyCorp/control/api/v1/catalog/product/variants/add',
  updateQuoteOrderItem:'/phyCorp/control/api/v1/project/updateQuoteItem ',
  removeQuoteOrderItem:'/phyCorp/control/api/v1/project/removeQuoteItem ',
  getQuoteSkillType:'/phyCorp/control/api/v1/project/getQuoteSkillTypeId  ',
  getProductFeature:'/phyCorp/control/api/v1/project/getProductFeatures ',
  getQuoteOrderItemList:'/phyCorp/control/api/v1/project/getQuoteItemList/:quoteId',
  generatePdfQuote:'/phyCorp/control/api/v1/project/quote/pdf',
  getQuoteOrderAttribute:'/phyCorp/control/api/v1/project/getQuoteAttribute',
  createQuoteOrderItem:'/phyCorp/control/api/v1/project/createQuoteItem ',
  removeQuoteOrderAttribute:'/phyCorp/control/api/v1/project/removeQuoteAttribute ',
  updateQuoteOrderAttribute:'/phyCorp/control/api/v1/project/updateQuoteAttribute',
  createQuoteOrderAttribute:'/phyCorp/control/api/v1/project/createQuoteAttribute',
  getImageUploadList:'/phyCorp/control/api/v1/catalog/imageManagement/getImageUploadList/:productId',
  getResizeDataList:'/phyCorp/control/api/v1/catalog/imageManagement/getCheckAction/:productId/:contentId/:dataResourceId',
  getManageList:'/phyCorp/control/api/v1/catalog/imageManagement/getManageList/:productId',
  setDefaultImage:'/phyCorp/control/api/v1/catalog/imageManagement/setDefaultImage',
  removeManageImage:'/phyCorp/control/api/v1/catalog/imageManagement/removeManageImage/:id',
  submitManage:'/phyCorp/control/api/v1/catalog/imageManagement/submitManage',
  getQuoteOrderCoefficient:'/phyCorp/control/api/v1/project/getQuoteCoefficient',
  createQuoteOrderCoefficient:'/phyCorp/control/api/v1/project/createQuoteCoefficient',
  getImageResize:'/phyCorp/control/api/v1/catalog/imageManagement/getImageResize',
  addToUploadImageCustom:'/phyCorp/control/api/v1/catalog/imageManagement/addToUploadImageCustom',
  removeQuoteOrderCoefficient:'/phyCorp/control/api/v1/project/removeQuoteCoefficient ',
  updateQuoteOrderCoefficient:'/phyCorp/control/api/v1/project/updateQuoteCoefficient ', 
  getManagedViewImage:'/phyCorp/control/api/v1/catalog/imageManagement/getManagedViewImage/:id',
  getViewProductParentById:'/phyCorp/control/api/v1/catalogProduct/getViewProductManufacturingThird',
  getCostComponentByProductId:'/phyCorp/control/api/v1/catalogProduct/getCostComponentByProductId',
  getProductCostComponentCalcById:'/phyCorp/control/api/v1/catalogProduct/getProductCostComponentCalcById',
  costComponentTypeIdFirst:'/phyCorp/control/api/v1/catalogProduct/costComponentTypeIdFirst',
  costComponentTypeIdComponentCalc:'/phyCorp/control/api/v1/catalogProduct/costComponentTypeIdComponentCalc',
  productFeatureId:'/phyCorp/control/api/v1/catalogProduct/productFeatureId',
  getCatalogGeoId:'/phyCorp/control/api/v1/catalogProduct/getGeoId',
  getWorkEffortIds:'/phyCorp/control/api/v1/catalogProduct/getWorkEffortIds',
  getFixedAssetIds:'/phyCorp/control/api/v1/catalogProduct/getFixedAssetIds',
  getCostComponentCalcId:'/phyCorp/control/api/v1/catalogProduct/getCostComponentCalcId',
  createCostComponent:'/phyCorp/control/api/v1/catalogProduct/createCostComponent',
  createProductCostComponentCalc:'/phyCorp/control/api/v1/catalogProduct/createProductCostComponentCalc',
  deleteCostComponent:'/phyCorp/control/api/v1/catalogProduct/deleteCostComponent',
  deleteProductCostComponentCalc:'/phyCorp/control/api/v1/catalogProduct/deleteProductCostComponentCalc',
  calculateProductCosts:'/phyCorp/control/api/v1/catalogProduct/calculateProductCosts',
  getWeightBreakIdList:'/phyCorp/control/api/v1/catalog/getWeightBreakIdList',
  getProductStoreShipmentSetupById:'/phyCorp/control/api/v1/catalog/getProductStoreShipmentSetupById',
  getProductStoreShipMethId:'/phyCorp/control/api/v1/catalog/getProductStoreShipMethId',
  getFixedAssetProductById:'/phyCorp/control/api/v1/catalogProduct/getFixedAssetProductById',
  getFixedAssetId:'/phyCorp/control/api/v1/catalogProduct/getFixedAssetId',
  addFixedAssetProduct:'/phyCorp/control/api/v1/catalogProduct',
  updateFixedAssetProduct:'/phyCorp/control/api/v1/catalogProduct',
  removeFixedAssetProduct:'/phyCorp/control/api/v1/catalogProduct/removeFixedAssetProduct',
  updateProductAssetUsage:'/phyCorp/control/api/v1/catalogProduct/updateProductAssetUsage',
  ImageApproveList :'/phyCorp/control/api/v1/catalog/imageManagement/ImageApproveList ',
  listPeopleRejected:'/phyCorp/control/api/v1/catalog/imageManagement/listPeopleRejected',
  ImageRejectedList:'/phyCorp/control/api/v1/catalog/imageManagement/ImageRejectedList',
  getFrameList:'/phyCorp/control/api/v1/catalog/imageManagement/getFrameList',
  getViewProductComponentsById:'/phyCorp/control/api/v1/catalogProduct/getViewProductManufacturingSecond',
  getRoutingsById:'/phyCorp/control/api/v1/catalogProduct/getViewProductManufacturing',
  createProductConfigItem:'/phyCorp/control/api/v1/catalog/productPrice/createProductConfigItem',
  getEditProductConfigItemContent:'/phyCorp/control/api/v1/catalog/productPrice/getEditProductConfigItemContent',
  getProductConfigItemContentTypeID:'/phyCorp/control/api/v1/catalog/productPrice/getProductConfigItemContentTypeID',
  getEditProductQuickAdmin:'/phyCorp/control/api/v1/catalogProduct/getEditProductQuickAdmin',
  getSubscriptionResource:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionResource',
  updateProductConfigItem :'/phyCorp/control/api/v1/catalog/productPrice/updateProductConfigItem',
  getProductConfigOption :'/phyCorp/control/api/v1/catalog/productPrice/getProductConfigOption',
  createProductConfigOption :'/phyCorp/control/api/v1/catalog/productPrice/createProductConfigOption',
  deleteProductConfigOption :'/phyCorp/control/api/v1/catalog/productPrice/deleteProductConfigOption',
  getProductConfigItemArticle :'/phyCorp/control/api/v1/catalog/productPrice/getProductConfigItemArticle',
  createProductConfigProduct :'/phyCorp/control/api/v1/catalog/productPrice/createProductConfigProduct',
  updateProductConfigOption :'/phyCorp/control/api/v1/catalog/productPrice/updateProductConfigOption',
  updateProductConfigProduct :'/phyCorp/control/api/v1/catalog/productPrice/updateProductConfigProduct',
  getProductConfigProductById :'/phyCorp/control/api/v1/catalog/productPrice/getProductConfigProductById',
  deleteProductConfigProduct :'/phyCorp/control/api/v1/catalog/productPrice/deleteProductConfigProduct  ',
  ContentId :'/phyCorp/control/api/v1/catalog/productPrice/ContentId ',
  prepareAddContentToProductConfigItem :'/phyCorp/control/api/v1/catalog/productPrice/prepareAddContentToProductConfigItem',
  createSimpleTextContentForProductConfigItem :'/phyCorp/control/api/v1/catalog/productPrice/createSimpleTextContentForProductConfigItem',
  getSimpleTextContentForProductConfigItemById :'/phyCorp/control/api/v1/catalog/productPrice/getSimpleTextContentForProductConfigItemById',
  removeContentFromProductConfigItem :'/phyCorp/control/api/v1/catalog/productPrice/removeContentFromProductConfigItem',
  addContentToProductConfigItem :'/phyCorp/control/api/v1/catalog/productPrice/addContentToProductConfigItem',
  UploadProductConfigItemImage :'/phyCorp/control/api/v1/catalog/productPrice/UploadProductConfigItemImage',
  updateProductConfigItemContent :'/phyCorp/control/api/v1/catalog/productPrice/updateProductConfigItemContent',
  getProductConfigItem :'/phyCorp/control/api/v1/catalog/productPrice/getProductConfigItem',
  getAutoUpdateQuoteOrderPrices:'/phyCorp/control/api/v1/project/getAutoUpdateQuotePrices',
  getAutoUpdateOrderQuote:'/phyCorp/control/api/v1/project/getAutoUpdateQuote',
  getProductCatalogConfiguration: '/phyCorp/control/api/v1/catalog/productPrice/getProductConfigItemArticle?configItemId=:id',
  autoUpdateQuoteOrderPrices:'/phyCorp/control/api/v1/project/autoUpdateQuotePrices',
  EditProductConfigItemContentContent :'/phyCorp/control/api/v1/catalog/productPrice/EditProductConfigItemContentContent',
  removeQuoteOrderAdjustment:'/phyCorp/control/api/v1/project/removeQuoteAdjustment',
  createQuoteOrderAdjustment:'/phyCorp/control/api/v1/project/createQuoteAdjustment  ',
  updateQuoteOrderAdjustment:'/phyCorp/control/api/v1/project/updateQuoteAdjustment',
  getQuoteAdjustmentByIdAndAll:'/phyCorp/control/api/v1/project/getQuoteAdjustmentByIdAndAll',
  updateSimpleTextContentForProductConfigItem :'/phyCorp/control/api/v1/catalog/productPrice/updateSimpleTextContentForProductConfigItem',
  getViewQuoteOrderProfitSummary:'/phyCorp/control/api/v1/project/getViewQuoteProfit',
  getQuoteOrderAdjustmentType:'/phyCorp/control/api/v1/project/getQuoteAdjustmentType',
  getViewQuoteOrderProfit:'/phyCorp/control/api/v1/project/getViewQuoteProfit',
  getWorkEffortOrder:'/phyCorp/control/api/v1/project/getWorkEfforts',
  getQuoteOrderWorkEffort:'/phyCorp/control/api/v1/project/getQuoteWorkEffort',
  getQuoteTemporalExpression:'/phyCorp/control/api/v1/workEffort/getTemporalExpression',
  createQuoteOrderWorkEffort:'/phyCorp/control/api/v1/project/createQuoteWorkEffort',
  updateQuoteWorkEffort:'/phyCorp/control/api/v1/project/updateQuoteWorkEffort',
  deleteQuoteWorkEffort :'/phyCorp/control/api/v1/project/deleteQuoteWorkEffort',
  getFsmPriorities: '/phyCorp/control/api/v1/fmsModule/getFsmPriorities',
  createFsmPriorities: '/phyCorp/control/api/v1/fmsModule/createFsmPriorities',
  updateFsmPriorities: '/phyCorp/control/api/v1/fmsModule/updateFsmPriorities',
  deleteFsmPriorities: '/phyCorp/control/api/v1/fmsModule/deleteFsmPriorities',
  getProductConfigsById: '/phyCorp/control/api/v1/workEffort/getProductConfigsById',
  getConfigItemId: '/phyCorp/control/api/v1/workEffort/getConfigItemId',
  createProductConfig: '/phyCorp/control/api/v1/workEffort/createProductConfig',
  updateProductConfig: '/phyCorp/control/api/v1/workEffort/updateProductConfig',
  deleteProductConfig: '/phyCorp/control/api/v1/workEffort/deleteProductConfig',
  getFsmSkill: '/phyCorp/control/api/v1/fmsModule/getFsmSkill',
  createFsmSkill: '/phyCorp/control/api/v1/fmsModule/createFsmSkill',
  updateFsmSkill: '/phyCorp/control/api/v1/fmsModule/updateFsmSkill',
  deleteFsmSkill: '/phyCorp/control/api/v1/fmsModule/deleteFsmSkill',
  getProductAssocByIdSec: '/phyCorp/control/api/v1/catalogProduct/getProductAssocByIdSec',
  removeProductFromCategoryNew: '/phyCorp/control/api/v1/catalogProduct/removeProductFromCategoryNew',
  getProductFeatureIactn: '/phyCorp/control/api/v1/catalogProduct/getProductFeatureIactn',
  AddProductFeatureIactn: '/phyCorp/control/api/v1/catalogProduct/AddProductFeatureIactn',
  removeProductFeatureIactn: '/phyCorp/control/api/v1/catalogProduct/removeProductFeatureIactn',
  getProductFeatureId: '/phyCorp/control/api/v1/catalogProduct/getProductFeatureId',
  getEditProductFeaturesById: '/phyCorp/control/api/v1/catalogProduct/getEditProductFeaturesById',
  createProductFeatureApplAttr: '/phyCorp/control/api/v1/catalogProduct/createProductFeatureApplAttr',
  updateProductFeatureApplAttr: '/phyCorp/control/api/v1/catalogProduct/updateProductFeatureApplAttr',
  deleteProductFeatureApplAttr: '/phyCorp/control/api/v1/catalogProduct/deleteProductFeatureApplAttr',
  getEditProductFeatures: '/phyCorp/control/api/v1/catalogProduct/getEditProductFeatures',
  ProductFeatureCategory: '/phyCorp/control/api/v1/catalogProduct/ProductFeatureCategory',
  productFeatureGroupId: '/phyCorp/control/api/v1/catalogProduct/productFeatureGroupId',
  productFeatureApplTypeId: '/phyCorp/control/api/v1/catalogProduct/productFeatureApplTypeId',
  productFeatureTypeIds: '/phyCorp/control/api/v1/catalogProduct/productFeatureTypeIds',
  applyFeatureToProductFromTypeAndCode: '/phyCorp/control/api/v1/catalogProduct/applyFeatureToProductFromTypeAndCode',
  applyFeatureToProduct: '/phyCorp/control/api/v1/catalogProduct/applyFeatureToProduct',
  updateFeatureToProductApplication: '/phyCorp/control/api/v1/catalogProduct/updateFeatureToProductApplication',
  RemoveFeatureFromProduct: '/phyCorp/control/api/v1/catalogProduct/RemoveFeatureFromProduct',
  getProductFeatureTypeID: '/phyCorp/control/api/v1/catalogProduct/getProductFeatureTypeID',
  getQuoteOrderRole:'/phyCorp/control/api/v1/project/getQuoteRole',
  createQuoteOrderNote:'/phyCorp/control/api/v1/crm/quoteNote/:quoteId',
  getQuoteOrderNotes:'/phyCorp/control/api/v1/project/getQuoteNotes',
  updateQuoteOrderNote:'/phyCorp/control/api/v1/crm/quoteNote/:quoteId',
  updateQuoteOrderTerm:'/phyCorp/control/api/v1/project/updateQuoteTerm',
  createQuoteOrderRole:'/phyCorp/control/api/v1/project/createQuoteRole',
  updateQuoteOrderRole:'/phyCorp/control/api/v1/project/updateQuoteRole',
  removeQuoteOrderRole:'/phyCorp/control/api/v1/project/removeQuoteRole',
  deleteQuoteOrderTerm:'/phyCorp/control/api/v1/project/deleteQuoteTerm',
  getQuoteOrderTerm:'/phyCorp/control/api/v1/project/getQuoteTerm',
  getQuoteOrderItemSeqID:'/phyCorp/control/api/v1/project/getQuoteItemSeqID',
  createQuoteOrderTerm:'/phyCorp/control/api/v1/project/createQuoteTerm',
  FindApprovedProductRequirements:'/phyCorp/control/api/v1/crmPartner/FindApprovedProductRequirements',
  updateQuoteNoteNew: '/phyCorp/control/api/v1/workEffort/updateQuoteNote',
  getFindApprovedProductRequirements:'/phyCorp/control/api/v1/crmPartner/FindApprovedProductRequirements',
  createCancelResponse:'/phyCorp/control/api/v1/fmsModule/createCancelResponse',
  deleteCancelResponse:'/phyCorp/control/api/v1/fmsModule/deleteCancelResponse',
  getFsmCancelResponseById:'/phyCorp/control/api/v1/fmsModule/getFsmCancelResponseById',
  updateCancelResponse:'/phyCorp/control/api/v1/fmsModule/updateCancelResponse',
  getFsmSCancelResponse:'/phyCorp/control/api/v1/fmsModule/getFsmSCancelResponse',
  createOnHoldReasons:'/phyCorp/control/api/v1/fmsModule/createOnHoldReasons',
  deleteOnHoldReasons:'/phyCorp/control/api/v1/fmsModule/deleteOnHoldReasons  ',
  getFsmOnHoldResponseById:'/phyCorp/control/api/v1/fmsModule/getFsmOnHoldResponseById',
  getFsmOnHoldResponse:'/phyCorp/control/api/v1/fmsModule/getFsmOnHoldResponse',
  updateOnHoldReasons:'/phyCorp/control/api/v1/fmsModule/updateOnHoldReasons',
  createFlagReasons:'/phyCorp/control/api/v1/fmsModule/createFlagReasons  ',
  deleteFlagReasons:'/phyCorp/control/api/v1/fmsModule/deleteFlagReasons',
  updateFlagReasons:'/phyCorp/control/api/v1/fmsModule/updateFlagReasons',
  getFsmFlagResponse:'/phyCorp/control/api/v1/fmsModule/getFsmFlagResponse',
  getFsmFlagResponseById:'/phyCorp/control/api/v1/fmsModule/getFsmFlagResponseById',
  createBasicWorkOrderType:'/phyCorp/control/api/v1/fmsModule/createBasicWorkOrderType',
  deleteBasicWorkOrderType:'/phyCorp/control/api/v1/fmsModule/deleteBasicWorkOrderType',
  getBasicWorkOrderType:'/phyCorp/control/api/v1/fmsModule/getBasicWorkOrderType',
  getBasicWorkOrderTypeById:'/phyCorp/control/api/v1/fmsModule/getBasicWorkOrderTypeById',
  updateBasicWorkOrderType:'/phyCorp/control/api/v1/fmsModule/updateBasicWorkOrderType',
  getCustomWorkOrderTypeById:'/phyCorp/control/api/v1/fmsModule/getCustomWorkOrderTypeById',
  getCustomWorkOrderType:'/phyCorp/control/api/v1/fmsModule/getCustomWorkOrderType',
  deleteCustomWorkOrderType:'/phyCorp/control/api/v1/fmsModule/deleteCustomWorkOrderType',
  createCustomWorkOrderType:'/phyCorp/control/api/v1/fmsModule/createCustomWorkOrderType',
  updateCustomWorkOrderType:'/phyCorp/control/api/v1/fmsModule/updateCustomWorkOrderType',
  EditProductPrices:'/phyCorp/control/api/v1/workEffort/EditProductPrices',
  customPriceCalcService:'/phyCorp/control/api/v1/workEffort/customPriceCalcService',
  createProductPrice:'/phyCorp/control/api/v1/workEffort/createProductPrice',
  updateProductPrice:'/phyCorp/control/api/v1/workEffort/updateProductPrice',
  deleteProductPrice:'/phyCorp/control/api/v1/workEffort/deleteProductPrice',
  updateProductQuickAdminName:'/phyCorp/control/api/v1/catalogProduct/updateProductQuickAdminName',
  quickAdminAddCategories:'/phyCorp/control/api/v1/catalogProduct/quickAdminAddCategories',
  getCategoryById:'/phyCorp/control/api/v1/catalogProduct/getCategoryById',
  quickAdminRemoveProductFromCategory:'/phyCorp/control/api/v1/catalogProduct/quickAdminRemoveProductFromCategory',
  createRepair:'/phyCorp/control/api/v1/fmsModule/createRepair',
  updateRepair:'/phyCorp/control/api/v1/fmsModule/updateRepair',
  deleteRepair:'/phyCorp/control/api/v1/fmsModule/deleteRepair',
  getFsmRepair:'/phyCorp/control/api/v1/fmsModule/getFsmRepair',
  getFsmRepairById:'/phyCorp/control/api/v1/fmsModule/getFsmRepairById',
  getRepairCodeAll:'/phyCorp/control/api/v1/fmsModule/getRepairCodeAll',
  deleteRepairCode:'/phyCorp/control/api/v1/fmsModule/deleteRepairCode',
  getRepairCodeById:'/phyCorp/control/api/v1/fmsModule/getRepairCodeById',
  updateRepairCode:'/phyCorp/control/api/v1/fmsModule/updateRepairCode',
  createRepairCode:'/phyCorp/control/api/v1/fmsModule/createRepairCode',
  createDispatchBoardSettings:'/phyCorp/control/api/v1/fmsModule/createDispatchBoardSettings',
  deleteDispatchBoardSettings:'/phyCorp/control/api/v1/fmsModule/deleteDispatchBoardSettings',
  getDispatchBoardSettings:'/phyCorp/control/api/v1/fmsModule/getDispatchBoardSettings',
  getDispatchBoardSettingsById:'/phyCorp/control/api/v1/fmsModule/getDispatchBoardSettingsById',
  getDispatchBoardSettingsByYes:'/phyCorp/control/api/v1/fmsModule/getDispatchBoardSettingsByYes ',
  updateDispatchBoardSettings:'/phyCorp/control/api/v1/fmsModule/updateDispatchBoardSettings',
  createFsmWorkZoneService:'/phyCorp/control/api/v1/fmsModule/createFsmWorkZoneService',
  deleteFsmWorkZoneService:'/phyCorp/control/api/v1/fmsModule/deleteFsmWorkZoneService',
  getFsmWorkZoneService:'/phyCorp/control/api/v1/fmsModule/getFsmWorkZoneService',
  getFsmWorkZoneServiceById:'/phyCorp/control/api/v1/fmsModule/getFsmWorkZoneServiceById',
  updateFsmWorkZoneService:'/phyCorp/control/api/v1/fmsModule/updateFsmWorkZoneService',
  createFSMDispatchCenterWorkZone:'/phyCorp/control/api/v1/fmsModule/createFSMDispatchCenterWorkZone',
  deleteFSMDispatchCenterWorkZone:'/phyCorp/control/api/v1/fmsModule/deleteFSMDispatchCenterWorkZone',
  getFSMDispatchCenterWorkZone:'/phyCorp/control/api/v1/fmsModule/getFSMDispatchCenterWorkZone',
  getFSMDispatchCenterWorkZoneById:'/phyCorp/control/api/v1/fmsModule/getFSMDispatchCenterWorkZoneById',
  updateFSMDispatchCenterWorkZone:'/phyCorp/control/api/v1/fmsModule/updateFSMDispatchCenterWorkZone',
  deleteFsmWorkZone:'/phyCorp/control/api/v1/fmsModule/deleteFsmWorkZone',
  createFsmWorkZone:'/phyCorp/control/api/v1/fmsModule/createFsmWorkZone',
  getFsmWorkZone:'/phyCorp/control/api/v1/fmsModule/getFsmWorkZone',
  getTimeZone:'/phyCorp/control/api/v1/fmsModule/getTimeZone',
  updateFsmWorkZone:'/phyCorp/control/api/v1/fmsModule/updateFsmWorkZone',
  getFsmWorkZoneById:'/phyCorp/control/api/v1/fmsModule/getFsmWorkZoneById',
  getWorkZoneId:'/phyCorp/control/api/v1/fmsModule/getFsmWorkZone',
  createFsmEquipment:'/phyCorp/control/api/v1/fmsModule/createFsmEquipment',
  DeleteFsmEquipment:'/phyCorp/control/api/v1/fmsModule/DeleteFsmEquipment',
  getFsmEquipment:'/phyCorp/control/api/v1/fmsModule/getFsmEquipment',
  UpdateFsmEquipment:'/phyCorp/control/api/v1/fmsModule/UpdateFsmEquipment',
  getFsmEquipmentById:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentById',
  getStandardFeatures:'/phyCorp/control/api/v1/catalogProduct/getStandardFeatures',
  getStandardFeaturesSecond:'/phyCorp/control/api/v1/catalogProduct/getStandardFeaturesSecond',
  deleteFeature:'/phyCorp/control/api/v1/catalogProduct/deleteFeature',
  updateProductQuickAdminShipping:'/phyCorp/control/api/v1/catalogProduct/updateProductQuickAdminShipping',
  EditProductTag:'/phyCorp/control/api/v1/catalogProduct/EditProductTag',
  updateProductTag:'/phyCorp/control/api/v1/catalogProduct/updateProductTag',
  getFsmPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/getFsmPrimaryWorkOrderDetailsMS',
  createFsmPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/createFsmPrimaryWorkOrderDetailsMS',
  updateFsmPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/updateFsmPrimaryWorkOrderDetailsMS',
  deleteFsmPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/deleteFsmPrimaryWorkOrderDetailsMS',
  getFsmNonPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/getFsmNonPrimaryWorkOrderDetailsMS',
  createFsmNonPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/createFsmNonPrimaryWorkOrderDetailsMS',
  updateFsmNonPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/updateFsmNonPrimaryWorkOrderDetailsMS',
  deleteFsmNonPrimaryWorkOrderDetailsMS:'/phyCorp/control/api/v1/fmsModule/deleteFsmNonPrimaryWorkOrderDetailsMS',
  createFsmEquipmentService:'/phyCorp/control/api/v1/fmsModule/createFsmEquipmentService',
  deleteFsmEquipmentService:'/phyCorp/control/api/v1/fmsModule/deleteFsmEquipmentService',
  getFsmEquipmentService:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentService',
  getFsmEquipmentServiceById:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentServiceById',
  updateFsmEquipmentService:'/phyCorp/control/api/v1/fmsModule/updateFsmEquipmentService',
  createFsmWorkZoneServiceMember:'/phyCorp/control/api/v1/fmsModule/createFsmWorkZoneServiceMember',
  deleteFsmWorkZoneServiceMember:'/phyCorp/control/api/v1/fmsModule/deleteFsmWorkZoneServiceMember',
  getFsmWorkZoneServiceMember:'/phyCorp/control/api/v1/fmsModule/getFsmWorkZoneServiceMember',
  getFsmWorkZoneServiceMemberById:'/phyCorp/control/api/v1/fmsModule/getFsmWorkZoneServiceMemberById',
  updateFsmWorkZoneServiceMember:'/phyCorp/control/api/v1/fmsModule/updateFsmWorkZoneServiceMember',
  createFsmEquipmentTrack:'/phyCorp/control/api/v1/fmsModule/createFsmEquipmentTrack',
  deleteFsmEquipmentTrack:'/phyCorp/control/api/v1/fmsModule/deleteFsmEquipmentTrack',
  getFsmEquipmentTrack:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentTrack',
  getFsmEquipmentTrackById:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentTrackById',
  getSkill:'/phyCorp/control/api/v1/fmsModule/getSkill',
  updateFsmEquipmentTrack:'/phyCorp/control/api/v1/fmsModule/updateFsmEquipmentTrack',
  createFsmEquipmentPart:'/phyCorp/control/api/v1/fmsModule/createFsmEquipmentPart',
  deleteFsmEquipmentPart:'/phyCorp/control/api/v1/fmsModule/deleteFsmEquipmentPart',
  getFsmEquipmentPart:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentPart',
  getFsmEquipmentPartById:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentPartById',
  updateFsmEquipmentPart:'/phyCorp/control/api/v1/fmsModule/updateFsmEquipmentPart',
  getFsmEquipmentId:'/phyCorp/control/api/v1/fmsModule/getFsmEquipment',
  updateFsmEquipmentAttribute:'/phyCorp/control/api/v1/fmsModule/updateFsmEquipmentAttribute',
  getFsmEquipmentAttributeById:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentAttributeById',
  getFsmEquipmentAttribute:'/phyCorp/control/api/v1/fmsModule/getFsmEquipmentAttribute',
  deleteFsmEquipmentAttribute:'/phyCorp/control/api/v1/fmsModule/deleteFsmEquipmentAttribute',
  createFsmEquipmentAttribute:'/phyCorp/control/api/v1/fmsModule/createFsmEquipmentAttribute',
  createTechnicianRegister:'/phyCorp/control/api/v1/fmsModule/createTechnicianRegister',
  updateTechnician:'/phyCorp/control/api/v1/fmsModule/updateTechnician',
  deleteFsmTechnician:'/phyCorp/control/api/v1/fmsModule/deleteFsmTechnician',
  findFsmTechnician:'/phyCorp/control/api/v1/fmsModule/findFsmTechnician',
  uploadSocialSecurityNumber:'/phyCorp/control/api/v1/fmsModule/uploadSocialSecurityNumber',
  getCountryGeoId:'/phyCorp/control/api/v1/fmsModule/getCountryGeoId',
  getStateProvId:'/phyCorp/control/api/v1/fmsModule/getStateProvId',
  findFsmCustomer:'/phyCorp/control/api/v1/fmsModule/findFsmCustomer',
  createFsmCustomerResidential:'/phyCorp/control/api/v1/fmsModule/createFsmCustomerResidential',
  updateFsmCustomerResidential:'/phyCorp/control/api/v1/fmsModule/updateFsmCustomerResidential',
  deleteFsmCustomer:'/phyCorp/control/api/v1/fmsModule/deleteFsmCustomer',
  customerNotification:'/phyCorp/control/api/v1/fmsModule/customerNotification',
  createFsmMyWorkSettingsMS:'/phyCorp/control/api/v1/fmsModule/createFsmMyWorkSettingsMS',
  deleteFsmMyWorkSettingsMS:'/phyCorp/control/api/v1/fmsModule/deleteFsmMyWorkSettingsMS',
  getFsmMyWorkSettingsMS:'/phyCorp/control/api/v1/fmsModule/getFsmMyWorkSettingsMS',
  getFsmMyWorkSettingsMSById:'/phyCorp/control/api/v1/fmsModule/getFsmMyWorkSettingsMSById',
  updateFsmMyWorkSettingsMS:'/phyCorp/control/api/v1/fmsModule/updateFsmMyWorkSettingsMS',
  createFinanceSalesTaxItems:'/phyCorp/control/api/v1/fmsModule/createFinanceSalesTaxItems',
  updateFinanceSalesTaxItems:'/phyCorp/control/api/v1/fmsModule/updateFinanceSalesTaxItems',
  deleteFinanceSalesTaxItems:'/phyCorp/control/api/v1/fmsModule/deleteFinanceSalesTaxItems',
  getFinanceSalesTaxItems:'/phyCorp/control/api/v1/fmsModule/getFinanceSalesTaxItems',
  getFinanceSalesTaxItemsById:'/phyCorp/control/api/v1/fmsModule/getFinanceSalesTaxItemsById',
  createFinanceBasicInvoice:'/phyCorp/control/api/v1/fmsModule/createFinanceBasicInvoice',
  createFinanceCustomInvoice:'/phyCorp/control/api/v1/fmsModule/createFinanceCustomInvoice',
  getFinanceBasicInvoice:'/phyCorp/control/api/v1/fmsModule/getFinanceBasicInvoice',
  DeleteFinanceCustomInvoice:'/phyCorp/control/api/v1/fmsModule/DeleteFinanceCustomInvoice',
  deleteFinanceBasicInvoice:'/phyCorp/control/api/v1/fmsModule/deleteFinanceBasicInvoice',
  getFinanceBasicInvoiceById:'/phyCorp/control/api/v1/fmsModule/getFinanceBasicInvoiceById',
  getFinanceCustomInvoice:'/phyCorp/control/api/v1/fmsModule/getFinanceCustomInvoice',
  getFinanceCustomInvoiceById:'/phyCorp/control/api/v1/fmsModule/getFinanceCustomInvoiceById',
  updateFinanceBasicInvoice:'/phyCorp/control/api/v1/fmsModule/updateFinanceBasicInvoice',
  UpdateFinanceCustomInvoice:'/phyCorp/control/api/v1/fmsModule/UpdateFinanceCustomInvoice',
  deleteFinancePriceListCategories:'/phyCorp/control/api/v1/fmsModule/deleteFinancePriceListCategories',
  getFinancePriceListCategories:'/phyCorp/control/api/v1/fmsModule/getFinancePriceListCategories',
  updateFinancePriceListCategories:'/phyCorp/control/api/v1/fmsModule/updateFinancePriceListCategories',
  getFinancePriceListCategoriesById:'/phyCorp/control/api/v1/fmsModule/getFinancePriceListCategoriesById',
  createFinancePriceListCategories:'/phyCorp/control/api/v1/fmsModule/createFinancePriceListCategories',
  createFinancePriceListItem:'/phyCorp/control/api/v1/fmsModule/createFinancePriceListItem',
  updateFinancePriceListItem:'/phyCorp/control/api/v1/fmsModule/updateFinancePriceListItem',
  deleteFinancePriceListItem:'/phyCorp/control/api/v1/fmsModule/deleteFinancePriceListItem',
  getFinancePriceListItem:'/phyCorp/control/api/v1/fmsModule/getFinancePriceListItem',
  getFinancePriceListItemById:'/phyCorp/control/api/v1/fmsModule/getFinancePriceListItemById',
  createPaymentProcessSetting:'/phyCorp/control/api/v1/fmsModule/createPaymentProcessSetting',
  updatePaymentProcessSetting:'/phyCorp/control/api/v1/fmsModule/updatePaymentProcessSetting',
  deletePaymentProcessSetting:'/phyCorp/control/api/v1/fmsModule/deletePaymentProcessSetting',
  getPaymentProcessSetting:'/phyCorp/control/api/v1/fmsModule/getPaymentProcessSetting',
  getPaymentProcessSettingById:'/phyCorp/control/api/v1/fmsModule/getPaymentProcessSettingById',
  createPriceListGroup:'/phyCorp/control/api/v1/fmsModule/createPriceListGroup',
  updatePriceListGroup:'/phyCorp/control/api/v1/fmsModule/updatePriceListGroup',
  deletePriceListGroup:'/phyCorp/control/api/v1/fmsModule/deletePriceListGroup',
  getPriceListGroupById:'/phyCorp/control/api/v1/fmsModule/getPriceListGroupById',
  getPriceListGroup:'/phyCorp/control/api/v1/fmsModule/getPriceListGroup',
  createFsmInvoice:'/phyCorp/control/api/v1/fmsModule/createFsmInvoice',
  updateFsmInvoice:'/phyCorp/control/api/v1/fmsModule/updateFsmInvoice',
  getInvoiceById:'/phyCorp/control/api/v1/fmsModule/getInvoiceById',
  getInvoice:'/phyCorp/control/api/v1/fmsModule/getInvoice',
  fsmInvoiceGenerate:'/phyCorp/control/api/v1/fmsModule/fsmInvoiceGenerate',
  getBacklogTypeChartSecond:'/phyCorp/control/api/v1/scrumNewController/getBacklogTypeChartSecond',
  deleteFsmTimeCard:'/phyCorp/control/api/v1/fmsModule/deleteFsmTimeCard',
  getFsmTimeCard:'/phyCorp/control/api/v1/fmsModule/getFsmTimeCard',
  createFsmTimeCard:'/phyCorp/control/api/v1/fmsModule/createFsmTimeCard',
  getFsmTimeCardById:'/phyCorp/control/api/v1/fmsModule/getFsmTimeCardById',
  getFsmTechnicalById:'/phyCorp/control/api/v1/fmsModule/getFsmTechnicalById',
  updateFsmTimeCard:'/phyCorp/control/api/v1/fmsModule/updateFsmTimeCard',
  getFsmTechnicalAll:'/phyCorp/control/api/v1/fmsModule/getFsmTechnicalAll',
  getAllDetailsByTechnicianId:'/phyCorp/control/api/v1/fmsModule/getAllDetailsByTechnicianId',
  technicianCreateCustomer:'/phyCorp/control/api/v1/fmsModule/technicianCreateCustomer',
  updateTechnicianPersonal:'/phyCorp/control/api/v1/fmsModule/updateTechnicianPersonal',
  getFsmProblem:'/phyCorp/control/api/v1/fmsModule/getFsmProblem',
  createFsmProblem:'/phyCorp/control/api/v1/fmsModule/createFsmProblem',
  updateFsmProblem:'/phyCorp/control/api/v1/fmsModule/updateFsmProblem',
  deleteFsmProblem:'/phyCorp/control/api/v1/fmsModule/deleteFsmProblem',
  createFsmAssignment:'/phyCorp/control/api/v1/fmsModule/createFsmAssignment',
  getFsmAssignmentById:'/phyCorp/control/api/v1/fmsModule/getFsmAssignmentById',
  updateFsmAssignment:'/phyCorp/control/api/v1/fmsModule/updateFsmAssignment',
  deleteFsmAssignment:'/phyCorp/control/api/v1/fmsModule/deleteFsmAssignment',
  getFsmCustomerCommercialById:'/phyCorp/control/api/v1/fmsModule/getFsmCustomerCommercialById',
  getFsmMap:'/phyCorp/control/api/v1/fmsModule/getFsmMap',
  getBacklogTypePieChart:'/phyCorp/control/api/v1/scrumNewController/getBacklogTypeChartSecond',
  getAllDetailsByInvoiceId:'/phyCorp/control/api/v1/fmsModule/getAllDetailsByInvoiceId',
  deleteFsmInvoice:'/phyCorp/control/api/v1/fmsModule/deleteFsmInvoice',
  getSchedule:'/phyCorp/control/api/v1/fmsModule/getSchedule',
  getNonSchedule:'/phyCorp/control/api/v1/fmsModule/getNonSchedule',
  createSubscriptionCommEvent:'/phyCorp/control/api/v1/catalog/subscription/createSubscriptionCommEvent',
  getSubscriptionCommEventByCommunicationId:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionCommEventByCommunicationId',
  getSubscriptionCommEvent:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionCommEvent',
  removeSubscriptionCommEvent:'/phyCorp/control/api/v1/catalog/subscription/removeSubscriptionCommEvent',
  getSubscriptionCommEventBySubId:'/phyCorp/control/api/v1/catalog/subscription/getSubscriptionCommEventBySubId',
  getBacklogTypeChart:'/phyCorp/control/api/v1/scrumNewController/getBacklogTypeChart',
  getFsmAssignment:'/phyCorp/control/api/v1/fmsModule/getFsmAssignment',
  findContent:'/phyCorp/control/api/v1/catalog/catalogContent/findContent',
  getCalatalogContentTypeId:'/phyCorp/control/api/v1/catalog/catalogContent/getContentTypeId',
  getCalatalogMimeTypeId:'/phyCorp/control/api/v1/catalog/catalogContent/getMimeTypeId',
  getDataResourceId:'/phyCorp/control/api/v1/catalog/catalogContent/getDataResourceId',
  getCatalogContentId:'/phyCorp/control/api/v1/catalog/catalogContent/getContentId',
  createContent:'/phyCorp/control/api/v1/catalog/catalogContent/createContent',
  getCharacterSet:'/phyCorp/control/api/v1/catalog/catalogContent/getCharacterSet',
  getEditContentById:'/phyCorp/control/api/v1/catalog/catalogContent/getEditContent',
  updateContent:'/phyCorp/control/api/v1/catalog/catalogContent/updateContent',
  getContentAssocById:'/phyCorp/control/api/v1/catalog/catalogContent/getContentAssocById',
  createContentAssoc:'/phyCorp/control/api/v1/catalog/catalogContent/createContentAssoc',
  updateContentAssoc:'/phyCorp/control/api/v1/catalog/catalogContent/updateContentAssoc',
  removeContentAssoc:'/phyCorp/control/api/v1/catalog/catalogContent/removeContentAssoc',
  getContentAssocTypeId:'/phyCorp/control/api/v1/catalog/catalogContent/getContentAssocTypeId',
  getMetaDataPredicateID:'/phyCorp/control/api/v1/catalog/catalogContent/getMetaDataPredicateID',
  createDataResource:'/phyCorp/control/api/v1/catalog/catalogContent/createDataResource',
  getDataCategory:'/phyCorp/control/api/v1/catalog/catalogContent/getDataCategory',
  getDataResourceType:'/phyCorp/control/api/v1/catalog/catalogContent/getDataResourceType',
  getDataResourceStatusId:'/phyCorp/control/api/v1/catalog/catalogContent/getDataResourceStatusId',
  getMimeTypeIdData:'/phyCorp/control/api/v1/catalog/catalogContent/getMimeTypeId',
  resetPasswordFSM:'/phyCorp/control/api/v1/fmsModule/resetPassword',
  getFsmProblemByProblemId:'/phyCorp/control/api/v1/fmsModule/getFsmProblemByProblemId',
  getFsmProblemByCustomerId:'/phyCorp/control/api/v1/fmsModule/getFsmProblemByCustomerId',
  getContentRoleTypeId:'/phyCorp/control/api/v1/catalog/catalogContent/getRoleTypeId',
  addContentPurposeOperation:'/phyCorp/control/api/v1/content/addContentPurposeOperation',
  getContentOperations:'/phyCorp/control/api/v1/content/getContentOperations',
  getContentPurposeOperation:'/phyCorp/control/api/v1/content/getContentPurposeOperation',
  updateContentPurposeOperation:'/phyCorp/control/api/v1/content/updateContentPurposeOperation',
  removeContentPurposeOperation:'/phyCorp/control/api/v1/content/removeContentPurposeOperation',
  getAddContentOperationById:'/phyCorp/control/api/v1/content/getAddContentOperationById',
  removeContentOperation:'/phyCorp/control/api/v1/content/removeContentOperation' ,
  updateContentOperation:'/phyCorp/control/api/v1/content/updateContentOperation' ,
  getContentOperation:'/phyCorp/control/api/v1/content/getContentOperation',
  addContentOperation:'/phyCorp/control/api/v1/content/addContentOperation',
  getAddContentOperation:'/phyCorp/control/api/v1/content/getContentOperation',
  addContentAssocPredicate:'/phyCorp/control/api/v1/content/addContentAssocPredicate',
  getContentAssocPredicate:'/phyCorp/control/api/v1/content/getContentAssocPredicate',
  getContentAssocPredicateById:'/phyCorp/control/api/v1/content/getContentAssocPredicateById',
  removeContentAssocPredicate:'/phyCorp/control/api/v1/content/removeContentAssocPredicate',
  updateContentAssocPredicate:'/phyCorp/control/api/v1/content/updateContentAssocPredicate',
  getProductFeatureIdList:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/productFeatureIds',
  getRoleTypeIdContent:'/phyCorp/control/api/v1/catalog/catalogContent/getRoleTypeId',
  getContentPurposeType:'/phyCorp/control/api/v1/catalog/catalogContent/getContentPurposeType',
  getContentTypeAttrById:'/phyCorp/control/api/v1/content/getContentTypeAttrById',
  removeContentTypeAttr:'/phyCorp/control/api/v1/content/removeContentTypeAttr',
  getContentTypeAttr:'/phyCorp/control/api/v1/content/getContentTypeAttr',
  addContentTypeAttr:'/phyCorp/control/api/v1/content/addContentTypeAttr',
  updateContentPurposeType:'/phyCorp/control/api/v1/content/updateContentPurposeType',
  removeContentPurposeType:'/phyCorp/control/api/v1/content/removeContentPurposeType',
  getContentPurposeTypes:'/phyCorp/control/api/v1/content/getContentPurposeTypes',
  getContentPurposeTypeById:'/phyCorp/control/api/v1/content/getContentPurposeTypeById',
  addContentPurposeType:'/phyCorp/control/api/v1/content/addContentPurposeType',
  getExpertiseArea:'/phyCorp/control/api/v1/fmsModule/getExpertiseArea',
  getContentRoleById:'/phyCorp/control/api/v1/catalog/catalogContent/getContentRoleById',
  addContentRole:'/phyCorp/control/api/v1/catalog/catalogContent/addContentRole',
  updateContentRole:'/phyCorp/control/api/v1/catalog/catalogContent/updateContentRole',
  removeContentRole:'/phyCorp/control/api/v1/catalog/catalogContent/removeContentRole',
  getContentPurposeById:'/phyCorp/control/api/v1/catalog/catalogContent/getContentPurposeById',
  addContentPurpose:'/phyCorp/control/api/v1/catalog/catalogContent/addContentPurpose',
  updateContentPurpose:'/phyCorp/control/api/v1/catalog/catalogContent/updateContentPurpose',
  removeContentPurpose:'/phyCorp/control/api/v1/catalog/catalogContent/removeContentPurpose',
  findDataResource:'/phyCorp/control/api/v1/catalog/catalogContent/findDataResource',
  getLocalString:'/phyCorp/control/api/v1/catalog/catalogContent/getLocalString',
  getDataTemplateType:'/phyCorp/control/api/v1/catalog/catalogContent/getDataTemplateType',
  getCharacterSetData:'/phyCorp/control/api/v1/catalog/catalogContent/getCharacterSet',
  getContentAttributeById:'/phyCorp/control/api/v1/catalog/catalogContent/getContentAttributeById',
  addContentAttribute:'/phyCorp/control/api/v1/catalog/catalogContent/addContentAttribute',
  updateContentAttribute:'/phyCorp/control/api/v1/catalog/catalogContent/updateContentAttribute',
  removeContentAttribute:'/phyCorp/control/api/v1/catalog/catalogContent/removeContentAttribute',
  getContentMetaDataById:'/phyCorp/control/api/v1/catalog/catalogContent/getContentMetaDataById',
  getMetaDataPredicateIDNew:'/phyCorp/control/api/v1/catalog/catalogContent/getMetaDataPredicateIDNew',
  addContentMetaData:'/phyCorp/control/api/v1/catalog/catalogContent/addContentMetaData',
  updateContentMetaData:'/phyCorp/control/api/v1/catalog/catalogContent/updateContentMetaData',
  removeContentMetaData:'/phyCorp/control/api/v1/catalog/catalogContent/removeContentMetaData',
  getWorkEffortContentById:'/phyCorp/control/api/v1/catalog/catalogContent/getWorkEffortContentById',
  createWorkEffortContentNew:'/phyCorp/control/api/v1/catalog/catalogContent/createWorkEffortContent',
  updateWorkEffortContentNew:'/phyCorp/control/api/v1/catalog/catalogContent/updateWorkEffortContent',
  deleteWorkEffortContentNew:'/phyCorp/control/api/v1/catalog/catalogContent/deleteWorkEffortContent',
  getExpertiseAreaNew:'/phyCorp/control/api/v1/fmsModule/getExpertiseArea',
  getExpertiseAreaByTechnicianId:'/phyCorp/control/api/v1/fmsModule/getExpertiseAreaByTechnicianId',
  createFsmExpertiseArea:'/phyCorp/control/api/v1/fmsModule/createFsmExpertiseArea',
  getContentKeywordById:'/phyCorp/control/api/v1/catalog/catalogContent/getContentKeywordById',
  getListWebSiteById:'/phyCorp/control/api/v1/catalog/catalogContent/getListWebSiteById',
  getFindParties:'/phyCorp/control/api/v1/webPos/getFindParties',
  getFindProductNew:'/phyCorp/control/api/v1/webPos/getFindProduct',
  UpdateCartItem:'/phyCorp/control/api/v1/webPos/UpdateCartItem',
  deleteCartItem:'/phyCorp/control/api/v1/webPos/deleteCartItem',
  getBrowseCategoriesFirst:'/phyCorp/control/api/v1/webPos/getBrowseCategoriesFirst',
  getBrowseCategoriesSecond:'/phyCorp/control/api/v1/webPos/getBrowseCategoriesSecond',
  openTerminal:'/phyCorp/control/api/v1/webPos/openTerminal',
  CloseTerminal:'/phyCorp/control/api/v1/webPos/CloseTerminal',
  PaidOutAndIn:'/phyCorp/control/api/v1/webPos/PaidOutAndIn',
  PromoCode:'/phyCorp/control/api/v1/webPos/PromoCode',
  VoidOrder:'/phyCorp/control/api/v1/webPos/VoidOrder',
  LoginWebPos:'/phyCorp/control/api/v1/webPos/LoginWebPos',
  AddToCart:'/phyCorp/control/api/v1/webPos/AddToCart',
  getCart:'/phyCorp/control/api/v1/webPos/getCart',
  getAddDataResourceAttributeAll:'/phyCorp/control/api/v1/catalog/catalogContent/getAddDataResourceAttributeAll',
  getAddDataResourceAttributeById:'/phyCorp/control/api/v1/catalog/catalogContent/getAddDataResourceAttributeById',
  AddDataResourceAttribute:'/phyCorp/control/api/v1/catalog/catalogContent/AddDataResourceAttribute',
  removeDataResourceAttribute:'/phyCorp/control/api/v1/catalog/catalogContent/removeDataResourceAttribute',
  updateDataResourceAttribute:'/phyCorp/control/api/v1/catalog/catalogContent/updateDataResourceAttribute',
  getAddDataResourceRoleById:'/phyCorp/control/api/v1/catalog/catalogContent/getAddDataResourceRoleById',
  getAddDataResourceRoleAll:'/phyCorp/control/api/v1/catalog/catalogContent/getAddDataResourceRoleAll',
  getDataResourceById:'/phyCorp/control/api/v1/catalog/catalogContent/getDataResourceById',
  updateAddDataResourceRole:'/phyCorp/control/api/v1/catalog/catalogContent/updateAddDataResourceRole ',
  removeAddDataResourceRole:'/phyCorp/control/api/v1/catalog/catalogContent/removeAddDataResourceRole ',
  addDataResourceRole:'/phyCorp/control/api/v1/catalog/catalogContent/addDataResourceRole',
  updateDataResource:'/phyCorp/control/api/v1/catalog/catalogContent/updateDataResource',
  createDataResourceProductFeature:'/phyCorp/control/api/v1/catalog/catalogContent/createDataResourceProductFeature',
  productFeatureIds:'/phyCorp/control/api/v1/catalog/catalogContent/productFeatureIds',
  removeDataResourceProductFeature:'/phyCorp/control/api/v1/catalog/catalogContent/removeDataResourceProductFeature',
  getDataResourceProductFeatureAll:'/phyCorp/control/api/v1/catalog/catalogContent/getDataResourceProductFeatureAll',
  getDataResourceProductFeature:'/phyCorp/control/api/v1/catalog/catalogContent/getDataResourceProductFeature',
  getElectronicTextById:'/phyCorp/control/api/v1/catalog/catalogContent/getElectronicTextById',
  updateElectronicText:'/phyCorp/control/api/v1/catalog/catalogContent/updateElectronicText',
  getBrowseCategoriesData:'/phyCorp/control/api/v1/webPos/getBrowseCategoriesData',
  createContentKeyword:'/phyCorp/control/api/v1/catalog/catalogContent/createContentKeyword',
  deleteContentKeyword:'/phyCorp/control/api/v1/catalog/catalogContent/deleteContentKeyword',
  getVerifyToken:'/phyCorp/control/api/v1/webPos/getVerifyToken',
  getUploadImage:'/phyCorp/control/api/v1/catalog/catalogContent/getUploadImage',
  uploadImage:'/phyCorp/control/api/v1/catalog/catalogContent/uploadImage',
  getContentType:'/phyCorp/control/api/v1/content/getContentType',
  removeContentType:'/phyCorp/control/api/v1/content/removeContentType',
  updateContentType:'/phyCorp/control/api/v1/content/updateContentType',
  addContentType:'/phyCorp/control/api/v1/content/addContentType',
  removeContentAssocType:'/phyCorp/control/api/v1/content/removeContentAssocType',
  getContentTypeById:'/phyCorp/control/api/v1/content/getContentTypeById',
  updateContentAssocType:'/phyCorp/control/api/v1/content/updateContentAssocType',
  getContentAssocTypeById:'/phyCorp/control/api/v1/content/getContentAssocTypeById',
  getContentAssocType:'/phyCorp/control/api/v1/content/getContentAssocType',
  addContentAssocType:'/phyCorp/control/api/v1/content/addContentAssocType',
  updateProfileUserLoginPassword:'/phyCorp/control/api/v1/party/updateProfileUserLoginPassword',
  navigateDataResource:'/phyCorp/control/api/v1/catalog/catalogContent/navigateDataResource',
  navigateDataResourceC:'/phyCorp/control/api/v1/catalog/catalogContent/navigateDataResourceC',
  ContentSearchResults:'/phyCorp/control/api/v1/catalog/catalogContent/ContentSearchResults',
  ContentSearchOptions:'/phyCorp/control/api/v1/catalog/catalogContent/ContentSearchOptions',
  removeDocumentFromTree:'/phyCorp/control/api/v1/catalog/catalogContent/removeDocumentFromTree',
  updateAllContentKeywords:'/phyCorp/control/api/v1/catalog/catalogContent/updateAllContentKeywords',
  navigateContent:'/phyCorp/control/api/v1/catalog/catalogContent/navigateContent',
  navigateContentC:'/phyCorp/control/api/v1/catalog/catalogContent/navigateContentC',
  FindWebSite:'/phyCorp/control/api/v1/content/FindWebSite',
  createWebSite:'/phyCorp/control/api/v1/content/createWebSite',
  FindWebSiteAll:'/phyCorp/control/api/v1/content/FindWebSiteAll',
  updateWebSite:'/phyCorp/control/api/v1/content/updateWebSite',
  getPrivilegeEnumID:'/phyCorp/control/api/v1/content/getPrivilegeEnumID',
  getPrivilegeEnumIDStatusId:'/phyCorp/control/api/v1/content/getPrivilegeEnumIDStatusId',
  getContentIdContent:'/phyCorp/control/api/v1/workEffort/getContentId',
  createWebSiteContact:'/phyCorp/control/api/v1/content/createWebSiteContactList',
  getWebSiteContactList:'/phyCorp/control/api/v1/content/getWebSiteContactList',
  deleteWebSiteContact:'/phyCorp/control/api/v1/content/deleteWebSiteContactList',
  getContactWebsite:'/phyCorp/control/api/v1/content/getContactList',
  updateWebSiteContact:'/phyCorp/control/api/v1/content/updateWebSiteContactList',
  createForumGroup:'/phyCorp/control/api/v1/content/createForumGroup',
  getForumGroup:'/phyCorp/control/api/v1/content/getForumGroup',
  getFindForums:'/phyCorp/control/api/v1/content/getFindForums',
  getforumGroupPurposes:'/phyCorp/control/api/v1/content/forumGroupPurposes',
  getforumGroupRole:'/phyCorp/control/api/v1/content/forumGroupRole',
  getcontentPurposeTypeId:'/phyCorp/control/api/v1/content/contentPurposeTypeId',
  createForum:'/phyCorp/control/api/v1/content/createForum',
  createForumGroupPurpose:'/phyCorp/control/api/v1/content/createForumGroupPurpose',
  createForumGroupRole:'/phyCorp/control/api/v1/content/createForumGroupRole',
  updateForumGroup:'/phyCorp/control/api/v1/content/updateForumGroup',
  updateForum:'/phyCorp/control/api/v1/content/updateForum',
  deleteForumGroupRole:'/phyCorp/control/api/v1/content/deleteForumGroupRole',
  deleteForumGroupPurpose:'/phyCorp/control/api/v1/content/deleteForumGroupPurpose',
  updateContentRoles:'/phyCorp/control/api/v1/content/updateContentRole',
  createForumMessage:'/phyCorp/control/api/v1/content/createForumMessage',
  updateForumMessage:'/phyCorp/control/api/v1/content/updateForumMessage',
  updateForumThreadMessage:'/phyCorp/control/api/v1/content/updateForumThreadMessage',
  CMSContentFind:'/phyCorp/control/api/v1/content/CMSContentFind',
  FindForumMessagesAll:'/phyCorp/control/api/v1/content/FindForumMessagesAll',
  ListComputerHardware:'/phyCorp/control/api/v1/content/ListComputerHardware',
  FindForumMessages:'/phyCorp/control/api/v1/content/FindForumMessages',
  getWebAnalytics:'/phyCorp/control/api/v1/content/getWebAnalytics',
  deleteWebAnalyticsConfig:'/phyCorp/control/api/v1/content/deleteWebAnalyticsConfig',
  createWebAnalyticsConfig:'/phyCorp/control/api/v1/content/createWebAnalyticsConfig',
  updateWebAnalyticsConfig:'/phyCorp/control/api/v1/content/updateWebAnalyticsConfig',
  updateWebSiteRole:'/phyCorp/control/api/v1/content/updateWebSiteRole',
  removeWebSiteRole:'/phyCorp/control/api/v1/content/removeWebSiteRole',
  createWebSiteRole:'/phyCorp/control/api/v1/content/createWebSiteRole',
  WebSiteRole:'/phyCorp/control/api/v1/content/WebSiteRole',
  WebSiteRoleAll:'/phyCorp/control/api/v1/content/WebSiteRoleAll',
  generateMissingSeoUrlForWebsite:'/phyCorp/control/api/v1/content/generateMissingSeoUrlForWebsite',
  FindWebSitePathAlias:'/phyCorp/control/api/v1/content/FindWebSitePathAlias',
  dataResourceTypeId:'/phyCorp/control/api/v1/content/dataResourceTypeId',
  createWebSitePathAlias:'/phyCorp/control/api/v1/content/createWebSitePathAlias',
  WebSiteCMS:'/phyCorp/control/api/v1/content/WebSiteCMS',
  updateWebSitePathAlias:'/phyCorp/control/api/v1/content/updateWebSitePathAlias',
  removeWebSitePathAlias:'/phyCorp/control/api/v1/content/removeWebSitePathAlias',
  WebSitePathAliasAll:'/phyCorp/control/api/v1/content/WebSitePathAliasAll',
  WebSitePathAliasAllBYId:'/phyCorp/control/api/v1/content/WebSitePathAliasAllBYId',
  getDataResourceByIdS:'/phyCorp/control/api/v1/catalog/catalogContent/getDataResourceByIdS',
  findSurvey:'/phyCorp/control/api/v1/content/findSurvey',
  createSurvey:'/phyCorp/control/api/v1/content/createSurvey',
  updateSurvey:'/phyCorp/control/api/v1/content/updateSurvey',
  getSurveyById:'/phyCorp/control/api/v1/content/getSurveyById',
  createSurveyMultiResp:'/phyCorp/control/api/v1/content/createSurveyMultiResp',
  updateSurveyMultiResp:'/phyCorp/control/api/v1/content/updateSurveyMultiResp',
  EditSurveyMultiResps:'/phyCorp/control/api/v1/content/EditSurveyMultiResps',
  createSurveyMultiRespColumn:'/phyCorp/control/api/v1/content/createSurveyMultiRespColumn',
  updateSurveyMultiRespColumn:'/phyCorp/control/api/v1/content/updateSurveyMultiRespColumn',
  getSurveyMultiResponseColumn:'/phyCorp/control/api/v1/content/getSurveyMultiResponseColumn',
  FindSurveyResponse:'/phyCorp/control/api/v1/catalog/catalogContent/FindSurveyResponse',
  getSurveyPageById:'/phyCorp/control/api/v1/catalog/catalogContent/getSurveyPageById',
  createSurveyPage:'/phyCorp/control/api/v1/catalog/catalogContent/createSurveyPage',
  updateSurveyPage:'/phyCorp/control/api/v1/catalog/catalogContent/updateSurveyPage',
  getApplyQuestionList:'/phyCorp/control/api/v1/catalog/catalogContent/getApplyQuestionList',
  getSurveyQuestionTypeIdList:'/phyCorp/control/api/v1/catalog/catalogContent/getSurveyQuestionTypeIdList',
  createSurveyQuestion:'/phyCorp/control/api/v1/catalog/catalogContent/createSurveyQuestion',
  updateSurveyQuestion:'/phyCorp/control/api/v1/catalog/catalogContent/updateSurveyQuestion',
  getSurveyListBySurveyQuestionCategoryId:'/phyCorp/control/api/v1/catalog/catalogContent/getSurveyListBySurveyQuestionCategoryId',
  createSurveyQuestionCategory:'/phyCorp/control/api/v1/catalog/catalogContent/createSurveyQuestionCategory',
  buildSurveyResponseFromPdf:'/phyCorp/control/api/v1/catalog/catalogContent/buildSurveyResponseFromPdf',
  BlogMain:'/phyCorp/control/api/v1/content/BlogMain',
  createnewBlog:'/phyCorp/control/api/v1/content/newBlog',
  getCreateSurveyQuestionById:'/phyCorp/control/api/v1/catalog/catalogContent/getCreateSurveyQuestionById',
  removeSurveyQuestionAppl:'/phyCorp/control/api/v1/catalog/catalogContent/removeSurveyQuestionAppl',
  createSurveyQuestionAppl:'/phyCorp/control/api/v1/catalog/catalogContent/createSurveyQuestionAppl',
  updateBlog:'/phyCorp/control/api/v1/content/updateBlog',
  EditBlog:'/phyCorp/control/api/v1/content/EditBlog',
  createBlogArticle:'/phyCorp/control/api/v1/content/createBlogArticle',
  updateBlogArticle:'/phyCorp/control/api/v1/content/updateBlogArticle',
  blogContent:'/phyCorp/control/api/v1/content/blogContent',
  FindLayout:'/phyCorp/control/api/v1/content/FindLayout',
  ListLayout:'/phyCorp/control/api/v1/content/ListLayout',
  UpdateWebSiteContent:'/phyCorp/control/api/v1/catalog/catalogContent/UpdateWebSiteContent',
  RemoveWebSiteContent:'/phyCorp/control/api/v1/catalog/catalogContent/RemoveWebSiteContent',
  createWebSiteContent:'/phyCorp/control/api/v1/catalog/catalogContent/createWebSiteContent',
  autoCreateWebSiteContent:'/phyCorp/control/api/v1/catalog/catalogContent/autoCreateWebSiteContent',
  getAutoCreateWebSiteContent:'/phyCorp/control/api/v1/catalog/catalogContent/getAutoCreateWebSiteContent',
  getListWebSiteId:'/phyCorp/control/api/v1/catalog/catalogContent/getListWebSiteById',
  WebSiteContentType:'/phyCorp/control/api/v1/catalog/catalogContent/WebSiteContentType',
  ListWebSiteContentAll:'/phyCorp/control/api/v1/catalog/catalogContent/ListWebSiteContentAll',
  ListWebSiteContent:'/phyCorp/control/api/v1/catalog/catalogContent/ListWebSiteContent',
  addCharacterSet:'/phyCorp/control/api/v1/content/addCharacterSet',
  CharacterSet:'/phyCorp/control/api/v1/content/CharacterSet',
  CharacterSetById:'/phyCorp/control/api/v1/content/CharacterSetById',
  removeCharacterSet:'/phyCorp/control/api/v1/content/removeCharacterSet',
  updateCharacterSet:'/phyCorp/control/api/v1/content/updateCharacterSet',
  addDataResourceType:'/phyCorp/control/api/v1/content/addDataResourceType',
  DataResourceType:'/phyCorp/control/api/v1/content/DataResourceType',
  DataResourceTypeById:'/phyCorp/control/api/v1/content/DataResourceTypeById',
  removeDataResourceType:'/phyCorp/control/api/v1/content/removeDataResourceType',
  updateDataResourceType:'/phyCorp/control/api/v1/content/updateDataResourceType',
  dataResourceTypeIdDropDown:'/phyCorp/control/api/v1/content/dataResourceTypeIdDropDown',
  DataResourceTypeAttrById:'/phyCorp/control/api/v1/content/DataResourceTypeAttrById',
  DataResourceTypeAttr:'/phyCorp/control/api/v1/content/DataResourceTypeAttr',
  addDataResourceTypeAttr:'/phyCorp/control/api/v1/content/addDataResourceTypeAttr',
  removeDataResourceTypeAttr:'/phyCorp/control/api/v1/content/removeDataResourceTypeAttr',
  addDataCategory:'/phyCorp/control/api/v1/content/addDataCategory',
  DataCategory:'/phyCorp/control/api/v1/content/DataCategory',
  DataCategoryById:'/phyCorp/control/api/v1/content/DataCategoryById',
  parentCategoryId:'/phyCorp/control/api/v1/content/parentCategoryId',
  removeDataCategory:'/phyCorp/control/api/v1/content/removeDataCategory',
  updateDataCategory:'/phyCorp/control/api/v1/content/updateDataCategory',
  updateMimeType:'/phyCorp/control/api/v1/content/updateMimeType',
  removeMimeType:'/phyCorp/control/api/v1/content/removeMimeType',
  MimeTypeById:'/phyCorp/control/api/v1/content/MimeTypeById',
  addMimeType:'/phyCorp/control/api/v1/content/addMimeType',
  MimeTypeSecond:'/phyCorp/control/api/v1/content/MimeTypeSecond',
  addFileExtension:'/phyCorp/control/api/v1/content/addFileExtension',
  FileExtension:'/phyCorp/control/api/v1/content/FileExtension',
  FileExtensionById:'/phyCorp/control/api/v1/content/FileExtensionById',
  removeFileExtension:'/phyCorp/control/api/v1/content/removeFileExtension',
  updateFileExtension:'/phyCorp/control/api/v1/content/updateFileExtension',
  createLayoutSubContent:'/phyCorp/control/api/v1/content/createLayoutSubContent',
  updateLayoutSubContent:'/phyCorp/control/api/v1/content/updateLayoutSubContent',
  detailPage:'/phyCorp/control/api/v1/content/detailPage',
  cloneLayout:'/phyCorp/control/api/v1/content/cloneLayout',
  FindCompDoc:'/phyCorp/control/api/v1/content/FindCompDoc',
  createRootCompDocTemplate:'/phyCorp/control/api/v1/content/createRootCompDocTemplate',
  updateRootCompDocTemplate:'/phyCorp/control/api/v1/content/updateRootCompDocTemplate',
  genCompDocInstance:'/phyCorp/control/api/v1/content/genCompDocInstance',
  ListContentApproval:'/phyCorp/control/api/v1/content/ListContentApproval',
  createContentApproval:'/phyCorp/control/api/v1/content/createContentApproval',
  ListContentRevisions:'/phyCorp/control/api/v1/content/ListContentRevisions',
  ViewCompDocTemplateTree:'/phyCorp/control/api/v1/content/ViewCompDocTemplateTree',
  updateContentApproval:'/phyCorp/control/api/v1/content/updateContentApproval',
  removeContentApproval:'/phyCorp/control/api/v1/content/removeContentApproval',
  updateContentRevision:'/phyCorp/control/api/v1/content/updateContentRevision',
  updateContentRevisionItem:'/phyCorp/control/api/v1/content/updateContentRevisionItem',
  createChildCompDocTemplate:'/phyCorp/control/api/v1/content/createChildCompDocTemplate',
  createMimeTypeHtmlTemplate:'/phyCorp/control/api/v1/content/createMimeTypeHtmlTemplate',
  MimeTypeHtmlTemplate:'/phyCorp/control/api/v1/content/MimeTypeHtmlTemplate',
  updateMimeTypeHtmlTemplate:'/phyCorp/control/api/v1/content/updateMimeTypeHtmlTemplate',
  removeMimeTypeHtmlTemplate:'/phyCorp/control/api/v1/content/removeMimeTypeHtmlTemplate',
  MimeTypeHtmlTemplateById:'/phyCorp/control/api/v1/content/MimeTypeHtmlTemplateById',
  metaDataPredicateById:'/phyCorp/control/api/v1/content/metaDataPredicateById',
  addMetaDataPredicate:'/phyCorp/control/api/v1/content/addMetaDataPredicate',
  metaDataPredicateData:'/phyCorp/control/api/v1/content/metaDataPredicateData',
  removeMetaDataPredicate:'/phyCorp/control/api/v1/content/removeMetaDataPredicate',
  updateMetaDataPredicate:'/phyCorp/control/api/v1/content/updateMetaDataPredicate',
  removeSite:'/phyCorp/control/api/v1/content/removeSite',
  indexContentTree:'/phyCorp/control/api/v1/content/indexContentTree',
  CMSSites:'/phyCorp/control/api/v1/content/CMSSites',
  addSubSite:'/phyCorp/control/api/v1/content/addSubSite',
  ProductSearch:'/phyCorp/control/api/v1/content/ProductSearch',
  getProductSearch:'/phyCorp/control/api/v1/content/ProductSearch',
  indexProducts:'/phyCorp/control/api/v1/content/indexProducts',
  DataResourceTypeDROP:'/phyCorp/control/api/v1/content/DataResourceTypeDROP',
  contentTypeIds:'/phyCorp/control/api/v1/content/contentTypeIds',
  ContentAssocType:'/phyCorp/control/api/v1/content/ContentAssocType',
  DataTemplateType:'/phyCorp/control/api/v1/content/DataTemplateType',
  EditAddContent:'/phyCorp/control/api/v1/content/EditAddContent',
  metaDataPredicate:'/phyCorp/control/api/v1/content/metaDataPredicate',
  postNewSubSite:'/phyCorp/control/api/v1/content/postNewSubSite',
  updateSiteRoles:'/phyCorp/control/api/v1/content/updateSiteRoles',
  persistContentStuff:'/phyCorp/control/api/v1/content/persistContentStuff',
  forumFilter:'/phyCorp/control/api/v1/content/forumFilter',
  FixedAssetParentIDSoftware:'/phyCorp/control/api/v1/content/FixedAssetParentIDSoftware',
  createComputerSoftware:'/phyCorp/control/api/v1/content/createComputerSoftware',
  ListInstalledSoftware:'/phyCorp/control/api/v1/content/ListInstalledSoftware',
  ListComputerSoftware:'/phyCorp/control/api/v1/content/ListComputerSoftware',
  IsManagerSummary:'/phyCorp/control/api/v1/content/IsManagerSummary',
  LookupComputerSoftwareClassEnumId:'/phyCorp/control/api/v1/content/LookupComputerSoftwareClassEnumId',
  updateComputerSoftware:'/phyCorp/control/api/v1/content/updateComputerSoftware',
  ViewBlogArticle:'/phyCorp/control/api/v1/content/ViewBlogArticle',
  EditChildCompDoc:'/phyCorp/control/api/v1/content/EditChildCompDoc',
  ListFlexibleReport:'/phyCorp/control/api/v1/content/ListFlexibleReport',
  ReportChoice:'/phyCorp/control/api/v1/content/ReportChoice',
  EditFlexibleReport:'/phyCorp/control/api/v1/content/EditFlexibleReport',
  DeleteFlexibleReport:'/phyCorp/control/api/v1/content/DeleteFlexibleReport',
  CreateFlexibleReport:'/phyCorp/control/api/v1/content/CreateFlexibleReport',
  UpdateFlexibleReport:'/phyCorp/control/api/v1/content/UpdateFlexibleReport',
  sendBirtMail:'/phyCorp/control/api/v1/content/sendBirtMail',
  birtContentType:'/phyCorp/control/api/v1/content/birtContentType',
  UpdateFlexibleReportS:'/phyCorp/control/api/v1/content/UpdateFlexibleReportS',
  UploadRptDesign:'/phyCorp/control/api/v1/content/UploadRptDesign',
  DisplayFlexibleReportSearch:'/phyCorp/control/api/v1/content/DisplayFlexibleReportSearch',
  DisplayFlexibleReportSearchs:'/phyCorp/control/api/v1/content/DisplayFlexibleReportSearch',
  DisplayFlexibleReportSearchSecond:'/phyCorp/control/api/v1/content/DisplayFlexibleReportSearchSecond',
  chartViewHandler:'/phyCorp/control/api/v1/content/chartViewHandler',
  chartPie:'/phyCorp/control/api/v1/content/chartPie',
  flexible:'/phyCorp/control/api/v1/content/flexible',
  updateComputerHardware:'/phyCorp/control/api/v1/content/updateComputerHardware',
  LookupComputerHardwareClassEnumId:'/phyCorp/control/api/v1/content/LookupComputerHardwareClassEnumId',
  createComputerHardware:'/phyCorp/control/api/v1/content/createComputerHardware',
  FixedAssetParentIDHardware:'/phyCorp/control/api/v1/content/FixedAssetParentIDHardware',
  ListHardwareAccessories:'/phyCorp/control/api/v1/content/ListHardwareAccessories',
  getUmo:'/phyCorp/control/api/v1/content/Uom',
  AllContent:'/phyCorp/control/api/v1/content/AllContent',
  uploadImageUpdate:'/phyCorp/control/api/v1/content/uploadImageUpdate',
  storeCreateShipRate:'/phyCorp/control/api/v1/catalog/storeCreateShipRate',
  
  getCarrierShipmentMethodList:'/phyCorp/control/api/v1/catalog/getCarrierShipmentMethodList',
  getShipmentCustomMethod:'/phyCorp/control/api/v1/catalog/getShipmentCustomMethod',
  getShipmentGatewayConfig:'/phyCorp/control/api/v1/catalog/getShipmentGatewayConfig',
  getFsmAssignmentByIdNew:'/phyCorp/control/api/v1/catalog/getShipmentGatewayConfig',
  ReportBuilderSelectStarSchemaFields:'/phyCorp/control/api/v1/content/ReportBuilderSelectStarSchemaFields',
  ReportBuilderRenderStarSchemaReport:'/phyCorp/control/api/v1/content/ReportBuilderRenderStarSchemaReport',
  getContentAssocSecondById:'/phyCorp/control/api/v1/catalog/catalogContent/getContentAssocSecondById',
  updateContentAssocBottom:'/phyCorp/control/api/v1/catalog/catalogContent/updateContentAssocBottom',
  dataResourceId:'/phyCorp/control/api/v1/content/dataResourceId',
  deleteContentAssocNew:'/phyCorp/control/api/v1/content/deleteContentAssocNew',
  deleteProdCatalogStore:'/phyCorp/control/api/v1/content/deleteProdCatalogStore',
  updateProdCatalogStore:'/phyCorp/control/api/v1/content/updateProdCatalogStore',
  createProdCatalogStore:'/phyCorp/control/api/v1/content/createProdCatalogStore',
  findAdvanceSearch:'/phyCorp/control/api/v1/catalog/catalogContent/findAdvanceSearch',
  buildSurveyFromPdf:'/phyCorp/control/api/v1/content/buildSurveyFromPdf',
  getFsmAssignmentFilter:'/phyCorp/control/api/v1/fmsModule/getFsmAssignmentFilter',
  generatePieChart:'/phyCorp/control/api/v1/content/ViewHandler',
  store:'/phyCorp/control/api/v1/content/store',
  EditProdCatalogStores:'/phyCorp/control/api/v1/content/EditProdCatalogStores',
  getProdCatalogToPartyById:'/phyCorp/control/api/v1/catalog/prodCatalogToParty/getProdCatalogToParty',
  addProdCatalogToParty:'/phyCorp/control/api/v1/catalog/prodCatalogToParty/addProdCatalogToParty',
  updateProdCatalogToParty:'/phyCorp/control/api/v1/catalog/prodCatalogToParty/updateProdCatalogToParty',
  deleteProdCatalogToParty:'/phyCorp/control/api/v1/catalog/prodCatalogToParty/deleteProdCatalogToParty',
  getRoleTypeListNew:'/phyCorp/control/api/v1/catalog/getRoleTypeList',
  getProductCategoryToProdCatalogById:'/phyCorp/control/api/v1/catalog/productCatalog/getProductCategoryToProdCatalogById',
  getProductCategoryTypeList:'/phyCorp/control/api/v1/catalog/productCatalog/getProductCategoryTypeList',
  createProductCategoryToProdCatalog:'/phyCorp/control/api/v1/catalog/productCatalog/createProductCategoryToProdCatalog',
  updateProductCategoryToProdCatalog:'/phyCorp/control/api/v1/catalog/productCatalog/updateProductCategoryToProdCatalog',
  removeProductCategoryToProdCatalog:'/phyCorp/control/api/v1/catalog/productCatalog/removeProductCategoryToProdCatalog',
  getCategoryId:'/phyCorp/control/api/v1/catalog/productCatalog/getCategoryId',
  productCatalog:'/phyCorp/control/api/v1/catalog/productCatalog',
  createProductCatalogNew:'/phyCorp/control/api/v1/catalog/productCatalog', 
  updateProductCatalogNew:'/phyCorp/control/api/v1/catalog/productCatalog',
  category_removeProductCategoryFromProdCatalog:'/phyCorp/control/api/v1/content/category_removeProductCategoryFromProdCatalog',
  category_updateProductCategoryToProdCatalog:'/phyCorp/control/api/v1/content/category_updateProductCategoryToProdCatalog',
  category_addProductCategoryToProdCatalog:'/phyCorp/control/api/v1/content/category_addProductCategoryToProdCatalog',
  EditCategoryProdCatalogs:'/phyCorp/control/api/v1/content/EditCategoryProdCatalogs',
  prodCatalogCategoryTypeId:'/phyCorp/control/api/v1/content/prodCatalogCategoryTypeId',
  prodCatalogId:'/phyCorp/control/api/v1/content/prodCatalogId',
  getProductCategoryId:'/phyCorp/control/partyToCategoryController/getProductCategoryById/:productCategoryId',
  getAllProductCategory:'/phyCorp/control/partyToCategoryController/getAllProductCategory',
  deletePartyFromCategory:'/phyCorp/control/partyToCategoryController/deletePartyFromCategory',
  addPartyToCategory:'/phyCorp/control/partyToCategoryController/addPartyToCategory',
  updatePartyToCategory:'/phyCorp/control/partyToCategoryController/updatePartyToCategory',
  getProductCategoryToCategory:'/phyCorp/control/api/v1/content/getProductCategoryToCategory',
  addProductCategoryToCategory:'/phyCorp/control/api/v1/content/addProductCategoryToCategory',
  updateProductCategoryToCategory:'/phyCorp/control/api/v1/content/updateProductCategoryToCategory',
  removeProductCategoryFromCategory:'/phyCorp/control/api/v1/content/removeProductCategoryFromCategory',
  getProductCategoryToCategorySecond:'/phyCorp/control/api/v1/content/getProductCategoryToCategorySecond',
  addProductCategoryToCategoryS:'/phyCorp/control/api/v1/content/addProductCategoryToCategoryS',
  updateProductCategoryToCategoryS:'/phyCorp/control/api/v1/content/updateProductCategoryToCategoryS',
  findProductFeatureIactnByIds:'/phyCorp/control/api/v1/catalogProduct/findProductFeatureIactnByIds/{productFeatureId}/{productFeatureIdTo}',
  getFeaturePrice:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price',
  productPriceType:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/productPriceType',
  createFeaturePrice:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/{productfeatureId}',
  updateFeaturePrice:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/{productfeatureId}',
  findRequirement:'/phyCorp/control/api/v1/crmPartner/findRequirement',
  getApprovedInternalRequirements:'/phyCorp/control/api/v1/crmPartner/getApprovedInternalRequirements',
  getApprovedProductRequirementsByVendor:'/phyCorp/control/api/v1/crmPartner/getApprovedProductRequirementsByVendor',
  getOpenRequirement:'/phyCorp/control/api/v1/crmPartner/getOpenRequirement',
  getRequirementStatusId:'/phyCorp/control/api/v1/crmPartner/getRequirementStatusId',
  getProductIds:'/phyCorp/control/api/v1/crmPartner/getProductId',
  deleteRequirementPlanning:'/phyCorp/control/api/v1/crmPartner/deleteRequirementPlanningForm',
  createRequirementPlanning:'/phyCorp/control/api/v1/crmPartner/createRequirementPlanning',
  UpdateRequirementPlanning:'/phyCorp/control/api/v1/crmPartner/UpdateRequirementPlanning',
  getAllContactRelatedAccount:'/phyCorp/control/api/v1/crm/getAllContactRelatedAccount',
  // getAgreementsSupplierPartnerAll:'/phyCorp/control/api/v1/crmPartner/getAgreementsSupplierPartnerAll',
  getApprovedTransferRequirements:'/phyCorp/control/api/v1/crmPartner/getApprovedTransferRequirements',
  getInitialManager:'/phyCorp/control/api/v1/crmPartner/getInitialManager',
  createPlanningWareHousesExisting:'/phyCorp/control/api/v1/crmPartner/createPlanningWareHousesExisting',
  deletePlanningWareHousesExisting:'/phyCorp/control/api/v1/crmPartner/deletePlanningWareHousesExisting',
  getPlanningWareHousesExistingAll:'/phyCorp/control/api/v1/crmPartner/getPlanningWareHousesExistingAll',
  getPrimaryWarehouseById: '/phyCorp/control/api/v1/crmPartner/getPrimaryWarehouseById',
  getPlanningWareHousesExistingById:'/phyCorp/control/api/v1/crmPartner/getPlanningWareHousesExistingById',
  UpdatePlanningWareHousesExisting:'/phyCorp/control/api/v1/crmPartner/UpdatePlanningWareHousesExisting',
  getFacilityIdTo:'/phyCorp/control/api/v1/crmPartner/getFacilityIdTo',
  getPlanningWareHousesById:'/phyCorp/control/api/v1/crmPartner/getPlanningWareHousesById',
  getPlanningWareHousesExistingSummaryPage:'/phyCorp/control/api/v1/crmPartner/getPlanningWareHousesExistingSummaryPage',
  createPlanningWareHouse: '/phyCorp/control/api/v1/crmPartner/createPlanningWareHouse',
  deletePlanningWareHouse:'/phyCorp/control/api/v1/crmPartner/deletePlanningWareHouse',
  UpdatePlanningWareHouse: '/phyCorp/control/api/v1/crmPartner/UpdatePlanningWareHouse',
  getPrimaryWarehouse:'/phyCorp/control/api/v1/crmPartner/getPrimaryWarehouse',
  deleteFeaturePrice:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/price/deleteFeaturePrice',
  findSupplierProductFeatureById:'/phyCorp/control/api/v1/catalog/productCatalog/supplierProductFeature/getSupplierProductFeatureById',
  updateSupplierProductFeature:'/phyCorp/control/api/v1/catalog/productCatalog/supplierProductFeature/updateSupplierProductFeature',
  deleteTransferRequirementPlanning:'/phyCorp/control/api/v1/crmPartner/deleteRequirementPlanningForm',
  addSupplierProductFeature:'/phyCorp/control/api/v1/catalog/productCatalog/supplierProductFeature/addSupplierProductFeature',
  deleteSupplierProductFeature:'/phyCorp/control/api/v1/catalog/productCatalog/supplierProductFeature/deleteSupplierProductFeature',
  getProductFeatureById:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature',
  deleteInternalRequirementPlanning:'/phyCorp/control/api/v1/crmPartner/deleteRequirementPlanningForm',
  updateProductFeature:'/phyCorp/control/api/v1/catalog/productFeatureCategory/feature/{productFeatureId}',
  getProductFeatureCategoryIdList:'/phyCorp/control/api/v1/order/getProductFeatureCategoryIdList',
  deleteProductRequirementPlanning:'/phyCorp/control/api/v1/crmPartner/deleteRequirementPlanningForm',
  getProductFeatureTypeIdList:'/phyCorp/control/api/v1/order/getProductFeatureTypeIdList',
  runMrp:'/phyCorp/control/api/v1/mnf/mrp',
  getviewMRPList:'/phyCorp/control/api/v1/crmPartner/viewMRP',
  viewMRP:'/phyCorp/control/api/v1/crmPartner/viewMRP',
  createRunMrp:'/phyCorp/control/api/v1/crmPartner/createRunMrp',
  MrpEventType:'/phyCorp/control/api/v1/crmPartner/MrpEventType',
  storeUpdateWebSite:'/phyCorp/control/api/v1/catalog/storeUpdateWebSite',
  getStoreWebsiteList:'/phyCorp/control/api/v1/catalog/getStoreWebsiteList',
  prepareCreateShipMeth:'/phyCorp/control/api/v1/catalog/prepareCreateShipMeth',
  storeCreateShipMeth:'/phyCorp/control/api/v1/catalog/storeCreateShipMeth',
  storeRemoveShipMeth:'/phyCorp/control/api/v1/catalog/storeRemoveShipMeth',
  getUnitOfMeasure:'/phyCorp/control/api/v1/catalog/getUnitOfMeasure',
  getProductStoreList:'/phyCorp/control/api/v1/catalog/getProductStoreList',
  getProductStoreWebsiteById:'/phyCorp/control/api/v1/catalog/getProductStoreWebsiteById/:productStoreId',
  shipmentCostEstimatedUom:'/phyCorp/controlapi/v1/catalog/shipmentCostEstimatedUom',
  storeUpdateShipMeth:'/phyCorp/control/api/v1/catalog/storeUpdateShipMeth',
  removeStoreUpdateWebSite:'/phyCorp/control/api/v1/catalog/storeUpdateWebSite',
  orderItemType:'/phyCorp/control/api/v1/crmPartner/orderItemType',
  removeLayout:'/phyCorp/control/api/v1/content/removeLayout',
  createBlogArticles:'/phyCorp/control/api/v1/content/createBlogArticles',
  updateBlogArticles:'/phyCorp/control/api/v1/content/updateBlogArticle',
  contactRelatedAccount:'/phyCorp/control/api/v1/crm/contactRelatedAccount',
  payFinish:'/phyCorp/control/api/v1/webPos/payFinish',
  payCreditCard:'/phyCorp/control/api/v1/webPos/payCreditCard',
  cardType:'/phyCorp/control/api/v1/webPos/cardType',
  month:'/phyCorp/control/api/v1/webPos/month',
  getDepartmentById:'/phyCorp/control/api/v1/hr/department/:departmentId',
  findVDistributor:'/phyCorp/control/api/v1/accounting/vendor/findVDistributor',
  findDistributorByTypeDistributor:'/phyCorp/control/api/v1/accounting/vendor/findDistributorByTypeDistributor',
  deleteVendor:'/phyCorp/control/api/v1/accounting/vendor/deleteVendor',
  getOrderPageDetails:'/phyCorp/control/api/v1/webPos/getOrderPageDetails',
  getSecondCart:'/phyCorp/control/api/v1/webPos/getSecondCart',
  updateProductIdS:'/phyCorp/control/api/v1/webPos/updateProductIdS',
  refresh:'/phyCorp/control/api/v1/webPos/refresh',
  EmptyCart:'/phyCorp/control/api/v1/webPos/EmptyCart',
  remove:'/phyCorp/control/api/v1/webPos/remove',
  createNoteHrms: '/phyCorp/control/api/v1/crm/contacts/partyNote/:partyId',
  updatePartyGroup: '/phyCorp/control/api/v1/crm/lead/partyGroup/:partyId',
  EditDepartment: '/phyCorp/control/api/v1/hr/employeeLeave/EditDepartment',
  getDepartmentEmployee: '/phyCorp/control/api/v1/employment/getDepartment',
  editPartyGroup: '/phyCorp/control/api/v1/hr/employeeLeave/editPartyGroup',
  EditPerfReview: '/phyCorp/control/api/v1/hr/employeeLeave/EditPerfReview',
  EditEmployeeSkills: '/phyCorp/control/api/v1/hr/employeeLeave/EditEmployeeSkills',
  EditEmployeePosition: '/phyCorp/control/api/v1/hr/employeeLeave/EditEmployeePosition',
  editTimeSheet: '/phyCorp/control/api/v1/hr/employeeLeave/editTimeSheet',
  EditPartyQual: '/phyCorp/control/api/v1/hr/employeeLeave/EditPartyQual',
  EditEmploymentApp: '/phyCorp/control/api/v1/hr/employeeLeave/EditEmploymentApp',
  EditEmployeeLeaves: '/phyCorp/control/api/v1/hr/employeeLeave/EditEmployeeLeaves',
  EditListRelatedAccounts: '/phyCorp/control/api/v1/hr/employeeLeave/EditListRelatedAccounts',
  getEmployments: '/phyCorp/control/api/v1/hr/employee/employment/:partyId',
  updateAgreementEmploymentAppl: '/phyCorp/control/api/v1/employment/updateAgreementEmploymentAppl',
  getPayHistoryListById: '/phyCorp/control/api/v1/employment/getPayHistoryListById',
  getFindPayrollPreferenceListP: '/phyCorp/control/api/v1/employment/getFindPayrollPreferenceListP',
  getAgreementIDNew: '/phyCorp/control/api/v1/employment/getAgreementIDNew',
  updatePartyHistoryNew: '/phyCorp/control/api/v1/employment/updatePartyHistoryNew',
  findPurchaseInvoices: '/phyCorp/control/api/v1/ar/invoice/findInvoices',
  getAgreementIdSeqId: '/phyCorp/control/api/v1/assetMaintenance/getAgreementIdSeqId',
  findReconciliations: '/phyCorp/control/api/v1/accounting/financialAccount/reconciliation/search',
  findPaymentGroupById: '/phyCorp/control/api/v1/ar/paymentGroup/getPaymentGroupById',
  FindFinAccountTrans: '/phyCorp/control/api/v1/ar/paymentGroup/FindFinAccountTrans',
  findDepositOrWithdrawNew: '/phyCorp/control/api/v1/accounting/financialAccount/depositOrWithdraw/findDepositOrWithdrawNew',
  deleteProdCatalogToPartyNew: '/phyCorp/control/api/v1/ar/paymentGroup/deleteProdCatalogToParty',
  getreasonEnumIds: '/phyCorp/control/api/v1/facility/inventoryItem/reasonEnumIds',
  getOrderID: '/phyCorp/control/api/v1/sfa/communicationEvents/order/getOrderId',
  getCommunicationEventOrders: '/phyCorp/control/api/v1/sfa/communicationEvents/order/:communicationEventId',
  deleteCrmEventOrderLists: '/phyCorp/control/api/v1/sfa/communicationEvents/order',
  createCommunicationEventOrder: '/phyCorp/control/api/v1/sfa/communicationEvents/order',
  getFindLeads: '/phyCorp/control/api/v1/crm/getFindLeads',
  deleteCommunicationEventProduct: '/phyCorp/control/api/v1/sfa/communicationEvents/product',
  deleteCommunicationEventRoles: '/phyCorp/control/api/v1/sfa/communicationEvents/role/deleteCommunicationEventRole',
  uploadAttachFile: '/phyCorp/control/api/v1/content/uploadAttachFile',
  purposeType: '/phyCorp/control/api/v1/sfa/communicationEvents/purposeType',
  getListCommContent: '/phyCorp/control/api/v1/content/ListCommContent',
  parentContentIdLists: '/phyCorp/control/api/v1/content/parentContentIdList',
  deleteUploadFile: '/phyCorp/control/api/v1/content/deleteUploadFile',
  FindCommunicationByReturn: '/phyCorp/control/api/v1/content/FindCommunicationByReturn',
  assignLead: '/phyCorp/control/api/v1/crm/assignLead',
  getMyFindLeads: '/phyCorp/control/api/v1/crm/getMyFindLeads',
  getCrmEventReturnLists:'/phyCorp/control/api/v1/sfa/communicationEvents/return/{communicationEventId}',
  findFixedAssetsNew:'/phyCorp/control/api/v1/accounting/fixedAssets/findFixedAssetsNew',
  createCommissionInvoice:'/phyCorp/control/api/v1/accountingPayable/commissionInvoice',
  getCustRequestIdAndSeq:'/phyCorp/control/api/v1/project/getCustRequestIdAndSeq',
  custRequestResolutionId:'/phyCorp/control/api/v1/project/custRequestResolutionId',
  deleteContent:'/phyCorp/control/api/v1/content/delete',
  getPartyRoles:'/phyCorp/control/api/v1/project/getPartyRole',
  getPreferencesScrum:'/phyCorp/control/api/v1/scrumProfileController/getPreferences',
  //deleteContent:'/phyCorp/control/api/v1/content/delete',
  getproductionRunId:'/phyCorp/control/api/v1/mnf/jobShop/productionRun/content',
  communicationEvents:'/phyCorp/control/api/v1/sfa/communicationEvents',
  productMaint:'/phyCorp/control/api/v1/assetMaintenance/productMaint',
  getWorkEffortCalender:'/phyCorp/control/api/v1/assetMaintenance/getWorkEffortCalender',
  getInvoiceTimesheetToInvoice:'/phyCorp/control/api/v1/workEffort/getInvoiceTimesheetToInvoice',
  getCurrentTimesheetNbr:'/phyCorp/control/api/v1/myPortal/getCurrentTimesheetNbr',
  getCurrentTimesheet:'/phyCorp/control/api/v1/myPortal/getCurrentTimesheet',
  getAllTimesheet:'/phyCorp/control/api/v1/myPortal/getTimeSheet',
  getPermission:'/phyCorp/control/api/v1/catalog/catalogContent/getPermission',
  updateTimesheet:'/phyCorp/control/api/v1/myPortal/updateTimesheet',
  getTaskNew:'/phyCorp/control/api/v1/project/getTaskNew',
  createInventoryItem:'/phyCorp/control/api/v1/facility/inventoryItem',
  createFacilityLocation:'/phyCorp/control/api/v1/facility/location',
  generatePdfs:'/phyCorp/control/api/v1/facility/inventory/generatePdf',
  ViewInventoryItemDetail:'/phyCorp/control/api/v1/facility/inventoryItem/ViewInventoryItemDetail',
  EditInventoryItemLabels:'/phyCorp/control/api/v1/facility/inventoryItem/EditInventoryItemLabels',
  createInventoryItemLabelApplFromItem:'/phyCorp/control/api/v1/facility/inventoryItem/createInventoryItemLabelApplFromItem',
  deleteInventoryItemLabelApplFromItem:'/phyCorp/control/api/v1/facility/inventoryItem/deleteInventoryItemLabelApplFromItem',
  updateInventoryItemLabelApplFromItem:'/phyCorp/control/api/v1/facility/inventoryItem/updateInventoryItemLabelApplFromItem',
  inventoryItemLabelId:'/phyCorp/control/api/v1/facility/inventoryItem/inventoryItemLabelId',
  getInventorySummaryPage:'/phyCorp/control/api/v1/facility/inventoryItem/getInventorySummaryPage',
  updatePayment:'/phyCorp/control/api/v1/myPortal/myPaymentService',
  StatusToCancelled:'/phyCorp/control/api/v1/myPortal/StatusToCancelled',
  StatusToSendPay:'/phyCorp/control/api/v1/myPortal/StatusToSend',
  voidPayment:'/phyCorp/control/api/v1/myPortal/voidPayment',
  setCustRequestStatusMyPortal: '/phyCorp/control/api/v1/project/setCustRequestStatus',
  setCustRequestStatusList: '/phyCorp/control/api/v1/catalogProduct/setCustRequestStatusList',
  deleteCommunicationEvent: '/phyCorp/control/api/v1/catalogProduct/deleteCommunicationEvent',
 
  createInvoiceTerm: '/phyCorp/control/api/v1/ar/invoice/terms/invoice/:invoiceId',
  deleteInvoiceTerm: '/phyCorp/control/api/v1/ar/invoice/terms/:invoiceTermId',
  getInvoiceTermById: '/phyCorp/control/api/v1/ar/invoice/terms/:invoiceTermId',
  getTermTypeIds: '/phyCorp/control/api/v1/ar/invoice/terms/types',
  getInvoiceTerms: '/phyCorp/control/api/v1/ar/invoice/terms/invoice/:invoiceId',
  updateInvoiceTerm: '/phyCorp/control/api/v1/ar/invoice/terms/invoice/:invoiceId',
  getRoleListMyPayment: '/phyCorp/control/api/v1/ar/invoice/role/:invoiceId',
  createInvoiceRole: '/phyCorp/control/api/v1/ar/invoice/role/:invoiceId',
  deleteInvoiceRole: '/phyCorp/control/api/v1/ar/invoice/role/:invoiceId/:partyId/:roleTypeId',
  getInvoiceTypeAndStatus: '/phyCorp/control/api/v1/ar/invoice/invoiceTypeStatus',
  setInvoiceStatus: '/phyCorp/control/api/v1/ar/invoice/invoiceStatus',
  copyInvoice: '/phyCorp/control/api/v1/ar/invoice/copyInvoice',
  copyInvoiceToTemplate: '/phyCorp/control/api/v1/ar/invoice/copyInvoiceToTemplate',
  sendInvoicePerMail: '/phyCorp/control/api/v1/ar/invoice/:invoiceId',

  EditInvoice: '/phyCorp/control/api/v1/ar/invoice/EditInvoice',
  findInvoices: '/phyCorp/control/api/v1/ar/invoice/findInvoices',
  getInvoiceSummary: '/phyCorp/control/api/v1/ar/invoice/:invoiceId',
  EditBillingAccountDrop: '/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/EditBillingAccountDrop',
  ViewInventoryItemDetailSecond:'/phyCorp/control/api/v1/facility/inventoryItem/ViewInventoryItemDetailSecond',
  SearchInventoryItemsByLabels:'/phyCorp/control/api/v1/facility/inventoryItem/SearchInventoryItemsByLabels',
  SearchInventoryItemsByLabelsDropDownValue:'/phyCorp/control/api/v1/facility/inventoryItem/SearchInventoryItemsByLabelsDropDownValue',
  getShipmentItem:'/phyCorp/control/api/v1/catalogProduct/getShipmentItem',
  createShipmentItem:'/phyCorp/control/api/v1/catalogProduct/createShipmentItem',
  updateShipmentItem:'/phyCorp/control/api/v1/catalogProduct/updateShipmentItem',
  deleteShipmentItem:'/phyCorp/control/api/v1/catalogProduct/deleteShipmentItem',
  EditShipmentPlan:'/phyCorp/control/api/v1/catalogProduct/EditShipmentPlan',
  addToShipmentPlan:'/phyCorp/control/api/v1/catalogProduct/addToShipmentPlan',
  EditShipmentPackages:'/phyCorp/control/api/v1/catalogProduct/EditShipmentPackages',
  deleteShipmentPackageContent:'/phyCorp/control/api/v1/catalogProduct/deleteShipmentPackageContent',
  createShipmentPackageContent:'/phyCorp/control/api/v1/catalogProduct/createShipmentPackageContent',
  AddItemsFromOrder:'/phyCorp/control/api/v1/catalogProduct/AddItemsFromOrder',
  ReceiveInventoryAgainstPurchaseOrder:'/phyCorp/control/api/v1/catalogProduct/ReceiveInventoryAgainstPurchaseOrder',
  issueOrderItemShipGrpInvResToShipment:'/phyCorp/control/api/v1/catalogProduct/issueOrderItemShipGrpInvResToShipment',
  carrierPartyId:'/phyCorp/control/api/v1/catalogProduct/carrierPartyId',
  billingWeightUomId:'/phyCorp/control/api/v1/catalogProduct/billingWeightUomId',
  carrierServiceStatusId:'/phyCorp/control/api/v1/catalogProduct/carrierServiceStatusId',
  createShipmentRouteSegment:'/phyCorp/control/api/v1/catalogProduct/createShipmentRouteSegment',
  deleteShipmentRouteSegment:'/phyCorp/control/api/v1/catalogProduct/deleteShipmentRouteSegment',
  duplicateShipmentRouteSegment:'/phyCorp/control/api/v1/catalogProduct/duplicateShipmentRouteSegment',
  EditShipmentRouteSegments:'/phyCorp/control/api/v1/catalogProduct/EditShipmentRouteSegments',
  originFacilityId:'/phyCorp/control/api/v1/catalogProduct/originFacilityId',
  ShipmentMethodType:'/phyCorp/control/api/v1/catalogProduct/ShipmentMethodType',
  updateShipmentRouteSegment:'/phyCorp/control/api/v1/catalogProduct/updateShipmentRouteSegment',
  upsShipmentConfirm:'/phyCorp/control/api/v1/catalogProduct/upsShipmentConfirm',
  deleteShipmentPackage:'/phyCorp/control/api/v1/catalogProduct/deleteShipmentPackage',
  updateShipmentPackageRouteSeg:'/phyCorp/control/api/v1/catalogProduct/updateShipmentPackageRouteSeg',
  deleteShipmentPackageRouteSeg:'/phyCorp/control/api/v1/catalogProduct/deleteShipmentPackageRouteSeg',
  updateSalesInvoiceHeader:'/phyCorp/control/api/v1/ar/invoice/:invoiceId',
  createPaymentIncoming:'/phyCorp/control/api/v1/accounting/payments',
  InvoiceAcctgTransEntriesPdf:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/InvoiceAcctgTransEntriesPdf',
  editInvoiceTimeEntries:'/phyCorp/control/api/v1/ar/invoice/editInvoiceTimeEntries',
  unlinkInvoiceFromTimeEntry:'/phyCorp/control/api/v1/ar/invoice/unlinkInvoiceFromTimeEntry',

  applyPaymentToInvoice:'/phyCorp/control/api/v1/ar/invoice/payment/:invoiceId',
  updatePaymentToInvoice:'/phyCorp/control/api/v1/ar/invoice/payment/:invoiceId/:paymentApplicationId',
  deleteInvoiceItem:'/phyCorp/control/api/v1/ar/invoice/invoiceItem/:invoiceId/:invoiceItemSeqId',
  updateInvoiceItem:'/phyCorp/control/api/v1/ar/invoice/invoiceItem/:invoiceId/:invoiceItemSeqId',
  createInvoiceItem:'/phyCorp/control/api/v1/ar/invoice/invoiceItem/:invoiceId',
  getInvoiceItems:'/phyCorp/control/api/v1/ar/invoice/invoiceItem/:invoiceId',
  getInvoiceItemById:'/phyCorp/control/api/v1/ar/invoice/invoiceItemById/:invoiceId/:invoiceItemSeqId',
  addWorkEffortTimeToInvoiceFormSTATUS:'/phyCorp/control/api/v1/workEffort/addWorkEffortTimeToInvoiceFormSTATUS',
  ReportBuilderRenderStarSchemaReports:'/phyCorp/control/api/v1/content/ReportBuilderRenderStarSchemaReport',
  getSprintTask:'/phyCorp/control/api/v1/scrumNewController/getSprintTask',
  BacklogItemContentList:'/phyCorp/control/api/v1/scrumNewController/BacklogItemContentList',
  shipmentDropDown:'/phyCorp/control/api/v1/catalogProduct/shipmentDropDown',
  shipmentboxTypeDropDown:'/phyCorp/control/api/v1/catalogProduct/shipmentboxTypeDropDown',
  getShipmentReceipts:'/phyCorp/control/api/v1/facility/shipment/shipment/receipt/view',
  getAllEvent:'/phyCorp/control/api/v1/sfa/communicationEvents/role/getAllEvent',
  createBillingAccountRole:'/phyCorp/control/api/v1/accounting/billingAccount/createBillingAccountRole',
  updateBillingAccountRoles:'/phyCorp/control/api/v1/accounting/billingAccount/updateBillingAccountRole',
  deleteBillingAccountRole:'/phyCorp/control/api/v1/accounting/billingAccount/deleteBillingAccountRole',
  getAllBillingAccountRole:'/phyCorp/control/api/v1/accounting/billingAccount/getAllBillingAccountRoles',
  deleteBillingAccountTerm:'/phyCorp/control/api/v1/accounting/billingAccount/deleteBillingAccountTerm',
  updateBillingAccountTerm:'/phyCorp/control/api/v1/accounting/billingAccount/updateBillingAccountTerm',
  getBillingAccountTerms:'/phyCorp/control/api/v1/accounting/billingAccount/getBillingAccountTerms',
  getAllBillingAccountTerms:'/phyCorp/control/api/v1/accounting/billingAccount/getAllBillingAccountTerms',
  createBillingAccountTerms:'/phyCorp/control/api/v1/accounting/billingAccount/createBillingAccountTerms',
  getInvoiceCountPieChart:'/phyCorp/control/api/v1/dashboard/invoiceCountPieChart',
  findUserDetails:'http://34.196.47.126:8085/api/v1/saas/user/user-details',
  findInactiveUser:'/phyCorp/control/api/v1/sfa/communicationEvents/contactMechData/filter',
  getSalesOrderCount:'/phyCorp/control/api/v1/dashboard/orders/:orderType',
  getPendingOrderCount:'/phyCorp/control/api/v1/dashboard/orders/:orderType',
  getPurchaseOrderCount:'/phyCorp/control/api/v1/dashboard/orders/:orderType',
  generateBudgetPdf:'/phyCorp/control/api/v1/accounting/billingAccount/generateBudgetPdf',
  generateBillingPdf:'/phyCorp/control/api/v1/accounting/billingAccount/generateBillingPdf',
  generateInvoicePdf:'/api/v1/accounting/billingAccount/generateInvoicePdf',
  gethrmsDashboardChart:'/phyCorp/control/api/v1/dashboard/hrmsDashboardChart',
  getcrmDashboardChart:'/phyCorp/control/api/v1/dashboard/crmDashboardChart',
  getproductInventoryDetailsChart:'/phyCorp/control/api/v1/dashboard/productInventoryDetailsChart',
  getcatalogDashboardChart:'/phyCorp/control/api/v1/dashboard/catalogDashboardChart',
  getuserChart:'/phyCorp/control/api/v1/dashboard/userChart',
  userDetailsSaas:'http://34.196.47.126:8085/api/v1/saas/user/user-details',
  newTaskById:'/phyCorp/control/api/v1/project/newTaskById',
  getTaskById:'/phyCorp/control/api/v1/project/getTaskById',
  getdownloadTaskById:'/phyCorp/control/api/v1/project/downloadTaskById',
  generatePDFUpdateSalesForecast:'/phyCorp/control/api/v1/crmPartner/generatePDFUpdateSalesForecast',
  deleteLogTaskEmailPartnerLead:'/phyCorp/control/api/v1/crmPartner/deleteLogTaskEmailPartnerLead',
  deleteLogTaskCallPartnerLead:'/phyCorp/control/api/v1/crmPartner/deleteLogTaskCallPartnerLead',
  createLogTaskCallPartnerLead:'/phyCorp/control/api/v1/crmPartner/createLogTaskCallPartnerLead',
  createLogTaskEmailPartnerLead:'/phyCorp/control/api/v1/crmPartner/createLogTaskEmailPartnerLead',
  UpdateLogTaskEmailPartnerLead:'/phyCorp/control/api/v1/crmPartner/UpdateLogTaskEmailPartnerLead',
  UpdateLogTaskCallPartnerLead:'/phyCorp/control/api/v1/crmPartner/UpdateLogTaskCallPartnerLead',
  getLogTaskCallPartnerIdLead:'/phyCorp/control/api/v1/crmPartner/getLogTaskCallPartnerIdLead',
  getLogTaskCallPartnerAllLead:'/phyCorp/control/api/v1/crmPartner/getLogTaskCallPartnerAllLead',
  getLogTaskEmailPartnerAllLead:'/phyCorp/control/api/v1/crmPartner/getLogTaskEmailPartnerAllLead',
  getLogTaskEmailPartnerIdLead:'/phyCorp/control/api/v1/crmPartner/getLogTaskEmailPartnerIdLead',
  getNotePartnerBIdLead:'/phyCorp/control/api/v1/crmPartner/getNotePartnerBIdLead',
  UpdateNotePartnerLead:'/phyCorp/control/api/v1/crmPartner/UpdateNotePartnerLead',
  getNotePartnerAllLead:'/phyCorp/control/api/v1/crmPartner/getNotePartnerAllLead',
  deleteNotePartnerLead:'/phyCorp/control/api/v1/crmPartner/deleteNotePartnerLead',
  createNotePartnerLead:'/phyCorp/control/api/v1/crmPartner/createNotePartnerLead',
  getBookMarksUrlIdPartnerLead:'/phyCorp/control/api/v1/crmPartner/getBookMarksUrlIdPartnerLead',
  UpdateBookMarksUrlPartnerLead:'/phyCorp/control/api/v1/crmPartner/UpdateBookMarksUrlPartnerLead',
  getBookMarksUrlPartnerLead:'/phyCorp/control/api/v1/crmPartner/getBookMarksUrlPartnerLead',
  deleteBookMarksUrlPartnerLead:'/phyCorp/control/api/v1/crmPartner/deleteBookMarksUrlPartnerLead',
  createBookMarksUrlPartnerLead:'/phyCorp/control/api/v1/crmPartner/createBookMarksUrlPartnerLead',
  createOrUpdateOrderDeliverySchedule:'/phyCorp/control/api/v1/order/findOrder/deliveryScheduleInfo',
  editOrderItemDetails:'/phyCorp/control/api/v1/order/findOrder/orderItems',
  getCubicMeasurementList:'/phyCorp/control/api/v1/order/findOrder/cubicMeasurement',
  getWeightMeasurementList:'/phyCorp/control/api/v1/order/findOrder/weightMeasurement',
  updateBookmarksUploadPartnerLead:'/phyCorp/control/api/v1/crmPartner/updateBookmarksUploadPartnerLead',
  getBookmarksUploadPartnerLead:'/phyCorp/control/api/v1/crmPartner/getBookmarksUploadPartnerLead',
  deleteBookmarksUploadPartnerLead:'/phyCorp/control/api/v1/crmPartner/deleteBookmarksUploadPartnerLead',
  createBookmarksUploadPartnerLead:'/phyCorp/control/api/v1/crmPartner/createBookmarksUploadPartnerLead',
  getCatalogRequestAllLead:'/phyCorp/control/api/v1/crmPartner/getCatalogRequestAllLead',
  getCatalogRequestByIdLead:'/phyCorp/control/api/v1/crmPartner/getCatalogRequestByIdLead',
  deleteCatalogRequestLead:'/phyCorp/control/api/v1/crmPartner/deleteCatalogRequestLead',
  deleteCatalogRequest:'/phyCorp/control/api/v1/crmPartner/deleteCatalogRequestLead',
  createCatalogRequestLead:'/phyCorp/control/api/v1/crmPartner/createCatalogRequestLead',
  UpdateCatalogRequestLead:'/phyCorp/control/api/v1/crmPartner/UpdateCatalogRequestLead',
  createPendingActivityTaskPartnerLead:'/phyCorp/control/api/v1/crmPartner/createPendingActivityTaskPartnerLead',
  deletePendingActivityTaskPartnerLead:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityTaskPartnerLead',
  getPendingActivityTaskPartnerLead:'/phyCorp/control/api/v1/crmPartner/getPendingActivityTaskPartnerLead',
  UpdatePendingActivityTaskPartnerLead:'/phyCorp/control/api/v1/crmPartner/UpdatePendingActivityTaskPartnerLead',
  UpdatePendingActivityEventPartnerLead:'/phyCorp/control/api/v1/crmPartner/UpdatePendingActivityEventPartnerLead',
  getPendingActivityEventPartnerLead:'/phyCorp/control/api/v1/crmPartner/getPendingActivityEventPartnerLead',
  deletePendingActivityEventPartnerLead:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityEventPartnerLead',
  CreatePendingActivityEventPartnerLead:'/phyCorp/control/api/v1/crmPartner/CreatePendingActivityEventPartnerLead',
  addOrderItemShipGroup:'/phyCorp/control/api/v1/order/findOrder/addOrderItemShipGroup',
  updateAccounts:'/phyCorp/control/api/v1/crmPartner/updateAccount',
  getAccount:'/phyCorp/control/api/v1/crmPartner/getAccount',
  deleteAccount:'/phyCorp/control/api/v1/crmPartner/deleteAccount',
  createAccounts:'/phyCorp/control/api/v1/crmPartner/createAccount',
  getOpportunity:'/phyCorp/control/api/v1/crmPartner/getOpportunityByPartyId',
  updateOpportunity:'/phyCorp/control/api/v1/crmPartner/updateOpportunity',
  deleteOpportunity:'/phyCorp/control/api/v1/crmPartner/deleteOpportunity',
  createOpportunities:'/phyCorp/control/api/v1/crmPartner/createOpportunity',
  createNotes:'/phyCorp/control/api/v1/crmPartner/createNotes',
  deleteNotes:'/phyCorp/control/api/v1/crmPartner/deleteNotes',
  getNotesByNoteId:'/phyCorp/control/api/v1/crmPartner/getNotesByNoteId',
  getNotesByPartyId:'/phyCorp/control/api/v1/crmPartner/getNotesByPartyId',
  updateNotes:'/phyCorp/control/api/v1/crmPartner/updateNotes',
  getAccountByAccountId:'/phyCorp/control/api/v1/crmPartner/getAccountByAccountId',
  createOrder:'/phyCorp/control/api/v1/crmPartner/createOrder',
  updateOrder:'/phyCorp/control/api/v1/crmPartner/updateOrder',
  deleteOrder:'/phyCorp/control/api/v1/crmPartner/deleteOrder',
  getOrderByPartyId:'/phyCorp/control/api/v1/crmPartner/getOrderByPartyId',

  createCasess:'/phyCorp/control/api/v1/crmPartner/createCasess',
  updateCasess:'/phyCorp/control/api/v1/crmPartner/updateCasess',
  deleteCasess:'/phyCorp/control/api/v1/crmPartner/deleteCasess',
  getCasessByPartyId:'/phyCorp/control/api/v1/crmPartner/getCasessByPartyId',
  getPendingActivityNewTaskByTaskId:'/phyCorp/control/api/v1/crmPartner/getPendingActivityNewTaskByPartyId',
  createPendingActivityNewTask:'/phyCorp/control/api/v1/crmPartner/createPendingActivityNewTask',
  updatePendingActivityNewTask:'/phyCorp/control/api/v1/crmPartner/updatePendingActivityNewTask',
  deletePendingActivityTaskPartnerNew:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityTaskPartner',
  getNotesAll:'/phyCorp/control/api/v1/crmPartner/getNotesAll',
  getCases:'/phyCorp/control/api/v1/crmPartner/getCases',
  updateTeam:'/phyCorp/control/api/v1/crmPartner/updateTeam',
  deactivatedTeamByPartyId:'/phyCorp/control/api/v1/crmPartner/deactivatedTeamByPartyId/:partyId',
  getRoleTeam:'/phyCorp/control/api/v1/crmPartner/getRole',
  deleteTeamMemberInviewTeamByAutoId:'/phyCorp/control/api/v1/crmPartner/deleteTeamMemberInviewTeamByAutoId/:autoId',
  updateTeamMemberInviewTeam:'/phyCorp/control/api/v1/crmPartner/updateTeamMemberInviewTeam',
  addTeamMemberInviewTeam:'/phyCorp/control/api/v1/crmPartner/addTeamMemberInviewTeam', 
  deletePendingActivityTasks:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityTaskPartner',
  deletePendingActivityEvent:'/phyCorp/control/api/v1/crmPartner/deletePendingActivityEventPartner',
  CreatePendingActivityEvent:'/phyCorp/control/api/v1/crmPartner/CreatePendingActivityEventPartner',
  UpdatePendingActivityEvent:'/phyCorp/control/api/v1/crmPartner/UpdatePendingActivityEventPartner',
  UpdatePendingActivityTask:'/phyCorp/control/api/v1/crmPartner/UpdatePendingActivityTaskPartner',
  createPendingActivityTasks:'/phyCorp/control/api/v1/crmPartner/createPendingActivityTaskPartner',
  getPendingActivityEvent:'/phyCorp/control/api/v1/crmPartner/getPendingActivityEventPartner',
  getPendingActivityTask:'/phyCorp/control/api/v1/crmPartner/getPendingActivityTaskPartner',
  getActivityHistoryLogCallByPartyId:'/phyCorp/control/api/v1/crmPartner/getActivityHistoryLogCallByPartyId',
  createActivityHistoryLogCall:'/phyCorp/control/api/v1/crmPartner/createActivityHistoryLogCall',
  updateActivityHistoryLogCall:'/phyCorp/control/api/v1/crmPartner/updateActivityHistoryLogCall',
  deleteActivityHistoryLogCall:'/phyCorp/control/api/v1/crmPartner/deleteActivityHistoryLogCall',
  getBookMarkUrlByPartyId:'/phyCorp/control/api/v1/crmPartner/getBookMarkUrlByPartyId',
  createBookMarkUrl:'/phyCorp/control/api/v1/crmPartner/createBookMarkUrl',
  updateBookMarkUrl:'/phyCorp/control/api/v1/crmPartner/updateBookMarkUrl',
  deleteBookMarkUrl:'/phyCorp/control/api/v1/crmPartner/deleteBookMarkUrl',
  getNotePartnerBId:'/phyCorp/control/api/v1/crmPartner/getNotePartnerBId',
  deleteNotePartner:'/phyCorp/control/api/v1/crmPartner/deleteNotePartner',
  createNotePartner:'/phyCorp/control/api/v1/crmPartner/createNotePartner',
  UpdateNotePartner:'/phyCorp/control/api/v1/crmPartner/UpdateNotePartner',

  createCasesCatalogRequest:'/phyCorp/control/api/v1/crmPartner/createCasesCatalogRequest',
  updateCasesCatalogRequest:'/phyCorp/control/api/v1/crmPartner/updateCasesCatalogRequest',
  deleteCasesCatalogRequest:'/phyCorp/control/api/v1/crmPartner/deleteCasesCatalogRequest',
  getCasesCatalogRequestByCustRequestId:'/phyCorp/control/api/v1/crmPartner/getCasesCatalogRequestByCustRequestId',

  createCasesOrder:'/phyCorp/control/api/v1/crmPartner/createCasesOrder',
  updateCasesOrder:'/phyCorp/control/api/v1/crmPartner/updateCasesOrder',
  deleteCasesOrder:'/phyCorp/control/api/v1/crmPartner/deleteCasesOrder',
  getCasesOrderByCustRequestId:'/phyCorp/control/api/v1/crmPartner/getCasesOrderByCustRequestId',

  createCasesBookMarkUrl:'/phyCorp/control/api/v1/crmPartner/createCasesBookMarkUrl',
  deleteCasesBookMarkUrl:'/phyCorp/control/api/v1/crmPartner/deleteCasesBookMarkUrl',
  getCasesBookMarkUrlByCustRequestId:'/phyCorp/control/api/v1/crmPartner/getCasesBookMarkUrlByCustRequestId',
  updateCasesBookMarkUrl:'/phyCorp/control/api/v1/crmPartner/updateCasesBookMarkUrl',

  createOpportunityCases:'/phyCorp/control/api/v1/crmPartner/createOpportunityCases',
  deleteOpportunityCases:'/phyCorp/control/api/v1/crmPartner/deleteOpportunityCases',
  updateOpportunityCases:'/phyCorp/control/api/v1/crmPartner/updateOpportunityCases',
  getOpportunityCasesByCustReqId:'/phyCorp/control/api/v1/crmPartner/getOpportunityCasesByCustReqId',

  createCasesAccount:'/phyCorp/control/api/v1/crmPartner/createCasesAccount',
  updateCasesAccount:'/phyCorp/control/api/v1/crmPartner/updateCasesAccount',
  deleteCasesAccount:'/phyCorp/control/api/v1/crmPartner/deleteCasesAccount',
  getCasesAccountByCustReqId:'/phyCorp/control/api/v1/crmPartner/getCasesAccountByCustReqId',

  getOpportunityContact:'/phyCorp/control/api/v1/crmPartner/getOpportunityByPartyId',
  updateOpportunityContact:'/phyCorp/control/api/v1/crmPartner/updateOpportunity',
  deleteOpportunityContact:'/phyCorp/control/api/v1/crmPartner/deleteOpportunity',
  createOpportunitiesContact:'/phyCorp/control/api/v1/crmPartner/createOpportunity',
  createOrderContact:'/phyCorp/control/api/v1/crmPartner/createOrder',
  updateOrderContact:'/phyCorp/control/api/v1/crmPartner/updateOrder',
  deleteOrderContact:'/phyCorp/control/api/v1/crmPartner/deleteOrder',
  getOrderByPartyIdContact:'/phyCorp/control/api/v1/crmPartner/getOrderByPartyId',
  createCatalogRequest:'/phyCorp/control/api/v1/crmPartner/createCatalogRequest',
  UpdateCatalogRequest:'/phyCorp/control/api/v1/crmPartner/UpdateCatalogRequest',
  getCatalogRequestByPartyId:'/phyCorp/control/api/v1/crmPartner/getCatalogRequestByPartyId',
  getTaskNoteById:'/phyCorp/control/api/v1/project/getTaskNoteById/nodeId',
  generatePDFDataResource:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFDataResource',
  generatePDFContent:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFContent',
  generatePDFTemplate:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFTemplate',
  generatePDFSurvey:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFSurvey',
  generatePDFCMS:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFCMS',
  generatePDFBlog:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFBlog',
  generatePDFWebsite:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFWebsite',
  generatePDFWorkeffort:'/phyCorp/control/api/v1/catalog/catalogContent/generatePDFWorkeffort',
  getcontacts:'/phyCorp/control/api/v1/crm/contacts',
  getAttachDocumentByPartyId:'/phyCorp/control/api/v1/crmPartner/getAttachDocumentByPartyId',
  attachDocument:'/phyCorp/control/api/v1/crmPartner/attachDocument',
  createAgreements:'/phyCorp/control/api/v1/accounting/agreement/createAgreements',
  cancelAgreements:'/phyCorp/control/api/v1/accounting/agreement/cancelAgreements',
  getAgreements:'/phyCorp/control/api/v1/accounting/agreement/getAgreements',
  updateAgreements:'/phyCorp/control/api/v1/accounting/agreement/updateAgreements',
  downloadDocument:'/phyCorp/control/api/v1/crmPartner/downloadDocument',
  getAgreementByPartyId:'/phyCorp/control/api/v1/accounting/agreement/getAgreementByPartyId',
  updateFsmWorkOrder:'/phyCorp/control/api/v1/fmsModule/updateFsmAssignment',
  generateTrialBalancePDF:'/phyCorp/control/api/v1/orgGlSettings/accounting/report/generateTrialBalancePDF',
  getAgreementTypeList: '/phyCorp/control/api/v1/accounting/agreement/agreementType',
  getAgreementList: '/phyCorp/control/api/v1/accounting/agreement/search',
  getAgreementById: '/phyCorp/control/api/v1/accounting/agreement',
  setPaymentStatus:'/phyCorp/control/api/v1/accountingPayable/outgoingPayment/setPaymentStatus',
  getPaymentsById: '/phyCorp/control/api/v1/accounting/payments/:paymentId',
  createOutgoingPayment: '/phyCorp/control/api/v1/accountingPayable/outgoingPayment',
  getPaymentApplicationList: '/phyCorp/control/api/v1/accounting/paymentApplication/:paymentId',
  getInvoiceApplicationList: '/phyCorp/control/api/v1/accounting/paymentApplication/invoice/:invoiceId',
  generateChecksPDFNew: '/phyCorp/control/api/v1/orgGlSettings/accounting/report/generateChecksPDFNew',
  generatePdfMyPortal: '/phyCorp/control/api/v1/ar/invoice/generatePdf',
  getapplyApplicationPayment: '/phyCorp/control/api/v1/accounting/paymentApplication',
  updateBillingAccountMypayments: '/phyCorp/control/api/v1/crm/paymentMethods/billingAccount/:partyId/:billingAccountId',
  EditBillingAccount: '/phyCorp/control/api/v1/crm/paymentMethods/billingAccount',
  updateInvoiceApplication: '/phyCorp/control/api/v1/accounting/paymentApplication',
  removePaymentApplication: '/phyCorp/control/api/v1/accounting/paymentApplication/delete',
  applyApplicationPayment: '/phyCorp/control/api/v1/accounting/paymentApplication',
  getPaymentTypeOutgoing: '/phyCorp/control/api/v1/myPortal/getPaymentTypeOutgoing',
  getPaymentType: '/phyCorp/control/api/v1/accounting/payments/paymentType', 
  getFinAccountId: '/phyCorp/control/api/v1/myPortal/getFinAccountId',
  getPaymentMethodId: '/phyCorp/control/api/v1/myPortal/getPaymentMethodId',
  getPaymentsList: '/phyCorp/control/api/v1/accountingPayable/outgoingPayment/search',
  FindPurchaseInvoicesByDueDate: '/phyCorp/control/api/v1/ar/invoice/FindPurchaseInvoicesByDueDate',
  getOrganizationPartyId: '/phyCorp/control/api/v1/ar/invoice/getOrganizationPartyId',
  FindSalesInvoicesByDueDate: '/phyCorp/control/api/v1/ar/invoice/FindSalesInvoicesByDueDate',
  deletePaymentToInvoice:'/phyCorp/control/api/v1/ar/invoice/payment/:paymentApplicationId',
  getAppliedApplications:'/phyCorp/control/api/v1/ar/invoice/payment/:invoiceId',
  getPaymentMethodTypeListData:'/phyCorp/control/api/v1/catalog/getPaymentMethodTypeList',
  getPersonsAndPartyGroups: '/phyCorp/control/api/v1/resources/personsPartyGroups',
  generatePdfInvoice: '/phyCorp/control/api/v1/ar/invoice/generatePdf',
  createSalesInvoice: '/phyCorp/control/api/v1/ar/invoice',

}