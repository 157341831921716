<div class="container-fluid main-container-wrapper">
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Transactions</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
    
    <div class=" bg-white color-grey">
      
        <div class="w3-card-4 classCard classCardType" >
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="Authorize()"><a>
                    Authorize</a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="Capture()"><a>
                    Capture</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="GatewayResponses()"><a>
                        Gateway Responses</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="ManualElectronicTransaction()"><a>
                        Manual Electronic Transaction</a></li>
               
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==2">
                                       
                                       
                                        <div class="panel-collapse">
                                            <br>
                                           
                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form  class="w-100" [formGroup]="createAuth">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Order ID </label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="email"   class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Order ID"
                                                                            formControlName="orderId" >
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Order Payment Preference ID <span
                                                                                style="color:red">*</span> </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email"   class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Order Payment Preference ID"
                                                                            formControlName="paymentPrefrenece" >
                                                                       
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">
                                                                          
                                                                                Payment Method Type <span
                                                                                style="color:red">*</span></label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown  placeholder="Enter Payment Method Type" filter="true"
                                                                            formControlName="paymentMethod"
                                                                            [options]="getPaymentMethodTypeArray" optionlabel="label" >
                                                                                            
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">
                                                                         
                                                                                Payment  Type</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown  placeholder="Enter Payment  Type" filter="true"
                                                                        formControlName="paymentType"
                                                                        [options]="paymentType" optionlabel="label" >
                                                                                        
                                                                        </p-dropdown>
                                                                       
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Amount  <span
                                                                                style="color:red">*</span></label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="email"   class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Amount"
                                                                            formControlName="amount" >
                                                                        </div>
                                                                     
                                                                    </div>
                                                                </div>
                                                             
                                                            
                                                             
                                                             
                                                            
                                                               
                                                            </div>
                                                            
                                    
                                                        </form>
                                                        <div class="main-submit-button" style="margin-left: 66%;">
                                                           
                                                            <button type="submit" (click)="onSubmit()"   class="btn btn-secondary submit-btn">Capture</button>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                            </div>
                                        </div>
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
 </div>
 <ngx-spinner></ngx-spinner> 