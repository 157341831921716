<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="w3-card-4 classCard">
                <header class="w3-container w3-blue">
                  <div class="header-tabs">
                    <h4 class=" common-styling h4Margin">
                      Shipment Method History 
                    </h4>
                 
                  </div>
                </header>
            <div class="all-users-infomation font-13 font-medium own-user-select">
                <div class="col-lg-12 col-12">
                    <div class="form-group">
                      <div class="col-lg-12 col-12 main-submit-button">
                        <button  type="submit" class="btn btn-secondary submit-btn" routerLinkActive="active"  routerLink="/order/sales-order-view-page">Go Back</button>
                      </div>
                      <div class="card return-history-table own-account-tables">
                      <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows"
                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="offerShowingLineData"
                      [scrollable]="true" styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                      <ng-template pTemplate="colgroup" let-columns>
                          <colgroup>
                              <col *ngFor="let col of offerShowingLineColoumns">
                          </colgroup>
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr class="activity-rows">
                          <th id="tableHead" *ngFor="let col of offerShowingLineColoumns">
                            <ng-container>
                                {{col.header}}
                            </ng-container>
                        </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                        <tr class="activity-data">
                            <td *ngFor="let col of offerShowingLineColoumns">
                              <ng-container *ngIf="col.field === 'status'">
                                <span>{{activity.status}}</span> 
                              </ng-container>  
                              <ng-container *ngIf="col.field === 'name'">
                                <span class="d-flex red-border align-items-center">
                                <div class="name_text">
                                  <a>
                                    {{activity.name}}
                                  </a>
                                </div>
                              </span>
                              </ng-container>
                              <ng-container *ngIf="col.field === 'email'">
                                <span  class="d-block align-items-center">
                                 <div  class="name_text">
                                   {{activity.email}}
                               </div>
                             </span>
                               </ng-container>  
                             </td>
                          </tr>
                      </ng-template>
                  </p-table>
                  </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="w3-card-4 classCard">
          <header class="w3-container w3-blue">
            <div class="header-tabs">
              <h4 class=" common-styling h4Margin">
                Item Unit Price History
              </h4>
           
            </div>
          </header>
      <div class="all-users-infomation font-13 font-medium own-user-select">
          <div class="col-lg-12 col-12">
            <div class="row">
              <form class="w-100">
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">History not available</p>
                </div>
              </form>
            </div>
          </div>
      </div>
  </div>
  <br>
  <div class="w3-card-4 classCard">
    <header class="w3-container w3-blue">
      <div class="header-tabs">
        <h4 class=" common-styling h4Margin">
          Item Quantity History
        </h4>
     
      </div>
    </header>
<div class="all-users-infomation font-13 font-medium own-user-select">
    <div class="col-lg-12 col-12">
      <div class="row">
        <form class="w-100">
          <div class="all-users-infomation font-13 font-medium own-user-select">
            <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">History not available</p>
          </div>
        </form>
      </div>
    </div>
</div>
</div>

         



 </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    </div>
