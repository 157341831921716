import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manager-detail',
  templateUrl: './manager-detail.component.html',
  styleUrls: ['./manager-detail.component.css']
})
export class ManagerDetailComponent implements OnInit {
  
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton17') closebutton17;
  @ViewChild('closebutton18') closebutton18;
  @ViewChild('closebutton5') closebutton5;





  activeCategory = 1;
  activeCat = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 5;
  pageSize = 100;
  pageNo = 1;
  addGeo: any;
  addGoodID: any;
  addCategory: any;
  addKeyword: any;
  addAssoc: any;
  addCost: any;
  addCostCalc: any;
  addAutoCalc: any;
  addAttr: any;
  addFeatureGroup: any;
  addFeatureTypeID: any;
  addFeatureID: any;
  addFeatureInteraction: any;
  addProductFeatureAttr: any;
  addFacility: any;
  addLocation: any;
  addAgreement: any;
  addSalesAgreement: any;
  addGL: any;
  addPaymentType: any;
  addMaintenance: any;
  addMeter: any;
  addSubsResource: any;
  addVendor: any;
  addConfig: any;
  addWE: any;
  addAssocParty: any;
  addComm: any;
  addGroupOrder: any;
  addPublish: any;
  addByName: any;
  addByProductSearch: any;
  cardItemSelected: any;
  SelectSalesReps: any;
  payCash: any;
  payCheck: any;
  payGiftCard: FormGroup;
  payByCC: FormGroup;
  openTerminal: FormGroup;
  closeTerminal: FormGroup;
  paidIn: FormGroup;
  paidOut: FormGroup;
  promo: FormGroup;
  voidOrder: FormGroup;
  searchByNameArray: { label: string; value: string; }[];
  searchByProductArray: { label: string; value: string; }[];
  FindPartiesAll: any;
  FindProductNew: any;
  BrowseCategoriesFirst: any;
  BrowseCategoriesSecond: any;
  CategoriesFirstId: any;
  paidInArray: { label: string; value: string; }[];
  paidOutArray: { label: string; value: string; }[];
  BrowseCategoriesData: any;
  previousToken: string;
  posTerminalId: any;
  productStoreId: any;
  productIdValue: any;
  getCartres: any;
  showCCForm: boolean;
  monthDrop: any;
  monthDropArray: any[]=[];
  cardTypeDropArray: any[]=[];
  cardTypeDrop: any[]=[];
  getSecondCartData: any;
  cartTableData: any;
  imageId: any;
  checkOutActive: boolean;
  cartTableDataAll: any;
  productIdVal: any;
  userLoginIdVal: any;
  partyInfo: boolean;
  partyAllData: any;
  productIdData: any;
  updateTableRes: any;
  updateTable: boolean;
  removeTableRes: any;
  onloadTable: boolean;
  removeTable: boolean;
  lastRemoveItem: any;
  lastupdateItem: any;
  proIdVal: any;
 

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly router: Router,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.searchByNameArray = 
    [
      {
        label: 'First name',
        value: 'firstName'
      },
      {
        label: 'Last name',
        value: 'lastName'
      },
      {
        label: 'Identification Number',
        value: 'idValue'
      },
    ];
    this.paidOutArray = 
    [
      {
        label: 'Banking during the day',
        value: 'BANKING_OUT_REASON'
      },
      {
        label: 'Petty Cash out',
        value: 'CASH_OUT_REASON'
      },
    ];
    this.paidInArray = 
    [
      {
        label: 'Banking during the day',
        value: 'BANKING_IN_REASON'
      },
      {
        label: "Change added to Till's float",
        value: 'CHANGE_IN_REASON'
      },
      {
        label: "Petty Cash Paid into Till",
        value: 'CASH_IN_REASON'
      },
    ];
    this.searchByProductArray = 
    [
      {
        label: 'Product Name',
        value: 'productName'
      },
      {
        label: 'Product Description',
        value: 'productDescription'
      },
      {
        label: 'Good Identification',
        value: 'idValue'
      },
    ];
    this.voidOrder = this._FormBuilder.group({
      numToVoid:[''],
    });
    this.promo = this._FormBuilder.group({
      pormoCode:[''],
    });
    this.paidOut = this._FormBuilder.group({
      withdrawAmt:[''],
      reason:[''],
      insertcmt:[''],
    });
    this.paidIn = this._FormBuilder.group({
      paidIn:[''],
      reason:[''],
      insertcmt:[''],
    });
    this.closeTerminal = this._FormBuilder.group({
      cashAmt:[''],
      checkAmt:[''],
      creditCardAmt:[''],
      giftCardAmt:[''],
      otherpayAmt:[''],
    });
    this.openTerminal = this._FormBuilder.group({
      drawerAmt:['']
    });
    this.payByCC = this._FormBuilder.group({
      swipeData:[""],
      cardType:[""],
      securityCode:[""],
      amountCreditCard:[""],
      postalCode:[""],
      creditrefNum:[""],
      firstName:[""],
      lastName:[""],
      cCardNo:[""],
      expMonth:[""],
      expYear:[""],

    })
    this.payGiftCard = this._FormBuilder.group({
      amountGiftCard:[''],
      refNum:['']
    });
    this.payCheck = this._FormBuilder.group({
      amountCheck:[''],
      refNum:['']
    });
    this.payCash = this._FormBuilder.group({
      amountCash:['']
    });
    this.cardItemSelected = this._FormBuilder.group({
      Quantity:[''],
      newprice:['']
    });
    this.addByName = this._FormBuilder.group({
      searchById:[''],
      nameValue:['']
    });
    this.addByProductSearch = this._FormBuilder.group({
      searchByProductSearch:[''],
      productSearchValue:['']
    });
    
    this.addGeo = this._FormBuilder.group({
      geoID: [''],
      enumID: [''],
      desc: [''],
    });
    this.addGoodID = this._FormBuilder.group({
      idType: [''],
      idValue: [''],
    });
  
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(res =>{
      this.posTerminalId = res["posTerminalId"];
      this.productStoreId = res["productStoreId"];
    });
    this.onloadTable=true;
    this.removeTable=false;
    this.updateTable=false;
    this.checkOutActive=false;
    this.showCCForm=true;
    this.getSelectSalesReps();
    this.getBrowseCategoriesFirst();
    this.getBrowseCategoriesData();
    this.cardType();
    this.month();
    this.refresh();

  }



backWindow(){
  this._location.back();
}
  toReports()
{
  this.router.navigate(['/webpos/control/main'],{queryParams:{ posTerminalId : this.posTerminalId , productStoreId:this.productStoreId}});
}
  getPartyInformation(product){
    this.partyAllData=product;
  }
  cardType() {
    this.spinner.show();
    this.accountsService.cardType().subscribe(res => {
      this.cardTypeDrop = res.data[0].data;
      this.spinner.hide();
      for (const value of this.cardTypeDrop) {
        this.cardTypeDropArray.push({
          label: value.description,
          value: value.cardType
        })
      }
    })
  }
  month() {
    this.spinner.show();
    this.accountsService.month().subscribe(res => {
      this.monthDrop = res.data[0].data;
      this.spinner.hide();
      for (const value of this.monthDrop) {
        this.monthDropArray.push({
          label: value.expMonth,
          value: value.expMonthId
        })
      }
    })
  }
  shutDownCode(){
    this.router.navigate(["/login"],{queryParams:{ webPos :  "webPos"}});
    localStorage.removeItem('token');
    localStorage.removeItem('previousToken');
    localStorage.removeItem('posToken');
    localStorage.removeItem('productStoreId');
    localStorage.removeItem('posTerminalId');

  }
  PaidOut(): void {
    this.spinner.show();
    const requestData ={
      "amountInOut": this.paidOut.value.withdrawAmt,
      "reasonCommentInOut": this.paidOut.value.insertcmt,
      "reasonIn": "BANKING_IN_REASON",
      "reasonOut": "BANKING_OUT_REASON",
      "type": "OUT"
      }
     
    this.accountsService.PaidOutAndIn(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.paidOut.reset();
        this.closebutton16.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  PaidIn(): void {
    this.spinner.show();
    const requestData ={
      "amountInOut": this.paidIn.value.paidIn,
      "reasonCommentInOut": this.paidIn.value.insertcmt,
      "reasonIn": "BANKING_IN_REASON",
      "reasonOut": "BANKING_OUT_REASON",
      "type": "IN"
      }
     
    this.accountsService.PaidOutAndIn(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.paidIn.reset();
        this.closebutton15.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  VoidOrder(): void {
    this.spinner.show();
    const requestData ={
      "orderId": this.voidOrder.value.numToVoid
      }
     
    this.accountsService.VoidOrder(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.voidOrder.reset();
        this.closebutton18.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  PromoCode(): void {
    this.spinner.show();
    const requestData ={
      "promoCode": this.promo.value.pormoCode
      }
     
    this.accountsService.PromoCode(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.promo.reset();
        this.closebutton17.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  CloseTerminal(): void {
    this.spinner.show();
    const requestData ={
        "endingDrawerCashAmount": this.closeTerminal.value.cashAmt,
        "endingDrawerCcAmount": this.closeTerminal.value.creditCardAmt,
        "endingDrawerCheckAmount": this.closeTerminal.value.checkAmt,
        "endingDrawerGcAmount": this.closeTerminal.value.giftCardAmt,
        "endingDrawerOtherAmount": this.closeTerminal.value.otherpayAmt,
      }
     
    this.accountsService.CloseTerminal(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.closeTerminal.reset();
        this.closebutton14.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  createOpenTerminal(): void {
    this.spinner.show();
    const requestData ={
      "posTerminalId": this.posTerminalId,
      "startingDrawerAmount": this.openTerminal.value.drawerAmt
      }
     
    this.accountsService.openTerminal(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.openTerminal.reset();
        this.closebutton13.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  productIdCart(product){
    this.productIdData=product
    this.productIdValue = product.productId;
    localStorage.setItem('productIdValue',(this.productIdValue));
    this.AddToCart();
    this.closebutton5.nativeElement.click();
  }
  getProductID(id){
      this.imageId=id;
      this.AddToCartSecond();
  }
  AddToCartSecond(): void {
    this.spinner.show();
    const requestData ={
      "add_product_id": this.imageId,
      "posTerminalId": this.posTerminalId,
      "productStoreId": this.productStoreId,
      "quantity": "1"
      }
    this.accountsService.AddToCart(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.cartTableData=res.data.cartitem.admin;
     
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  emptyCart(){
    this.checkOutActive=true;
    this.refresh();
  }
  AddToCart(): void {
    this.spinner.show();
    this.productIdValue = localStorage.getItem('productIdValue');
    const requestData ={
      "add_product_id": this.productIdValue,
      "posTerminalId": this.posTerminalId,
      "productStoreId": this.productStoreId,
      "quantity": "1"
      }
    this.accountsService.AddToCart(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.cartTableDataAll=res.data;
        this.cartTableData=res.data.cartitem.admin;
        this.onloadTable=true;
        this.removeTable=false;
        this.updateTable=false;
        this.getCart();
     
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  AddToCartBlank(): void {
    this.spinner.show();
    this.productIdValue = localStorage.getItem('productIdValue');
    const requestData ={
      "add_product_id": '',
      "posTerminalId": this.posTerminalId,
      "productStoreId": this.productStoreId,
      "quantity": "1"
      }
    this.accountsService.AddToCart(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getCart(): void {
    this.spinner.show();
    this.productIdValue = localStorage.getItem('productIdValue');
   
    this.accountsService.getCart(this.posTerminalId,this.productIdValue).subscribe((res: any) => { 
    this.spinner.hide();
      if (res.success) {
        this.getCartres = res.data;
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getSecondCart() {
    this.spinner.show();
    this.accountsService.getSecondCart(this.productIdValue).subscribe(res => {
      this.getSecondCartData = res.data;
      this.spinner.hide();
    })
  }
  getCategoriesFirstId(product){
    this.CategoriesFirstId = product.categoryId;
    this.getBrowseCategoriesSecond();

  }
  getBrowseCategoriesSecond() {
    this.spinner.show();
    const requestData ={
      "pageNo": 0,
      "pageSize": 20,
      "productCategoryId": this.CategoriesFirstId
      }
    this.accountsService.getBrowseCategoriesSecond(requestData).subscribe(res => {
      this.BrowseCategoriesSecond = res.data.productNameImage;
      this.spinner.hide();
    })
  }
  getBrowseCategoriesData() {
    this.spinner.show();
    this.accountsService.getBrowseCategoriesData().subscribe(res => {
      this.BrowseCategoriesData = res.data;
      this.spinner.hide();
    })
  }
  getBrowseCategoriesFirst() {
    this.spinner.show();
    const requestData ={
      "productCategoryId": '',
      }
    this.accountsService.getBrowseCategoriesFirst(requestData).subscribe(res => {
      this.BrowseCategoriesFirst = res.data;
      this.spinner.hide();
    })
  }
  remove(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      this.userLoginIdVal = localStorage.getItem('posUserId');
        
        this.accountsService.remove(product.productId,this.userLoginIdVal).subscribe((res: any) => {
          if (res.success) {
            this.getCart();
            this.onloadTable=false;
            this.removeTable=true;
            this.updateTable=false;
            this.removeTableRes=res.data.itemsUpdated
            this.lastRemoveItem = this.removeTableRes[this.removeTableRes.length-1];
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteCartItem() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        if(!this.onloadTable && this.removeTable && !this.updateTable){
          this.proIdVal=this.lastRemoveItem.productId
        }
        if(!this.onloadTable && !this.removeTable && this.updateTable){
          this.proIdVal=this.lastupdateItem.productId
        }
        if(this.onloadTable && !this.removeTable && !this.updateTable){
          this.proIdVal=this.productIdData.productId
        }
        this.accountsService.remove(this.proIdVal,this.userLoginIdVal).subscribe((res: any) => {
          if (res.success) {
            this.getCart();
            this.onloadTable=false;
            this.removeTable=true;
            this.updateTable=false;
            this.removeTableRes=res.data.itemsUpdated
            this.lastRemoveItem = this.removeTableRes[this.removeTableRes.length-1];

          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  refresh(): void {
    this.spinner.show();
    this.userLoginIdVal = localStorage.getItem('posUserId');
    this.accountsService.refresh(this.userLoginIdVal).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.EmptyCart();
        this.AddToCartBlank();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductIdS(): void {
    this.spinner.show();
    this.productIdVal=this.cartTableDataAll.cartitem.admin[0].productId
    this.userLoginIdVal=this.cartTableDataAll.userLoginId;
  
    if(!this.onloadTable && this.removeTable && !this.updateTable){
      this.proIdVal=this.lastRemoveItem.productId
    }
    if(!this.onloadTable && !this.removeTable && this.updateTable){
      this.proIdVal=this.lastupdateItem.productId
    }
    if(this.onloadTable && !this.removeTable && !this.updateTable){
      this.proIdVal=this.productIdData.productId
    }

    const requestData = {
      "productId": this.proIdVal,
      "quantity":  this.cardItemSelected.value.Quantity,
      "userLoginId": this.userLoginIdVal
    }
    this.accountsService.updateProductIdS(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.getCart();
        this.spinner.hide();
        this.onloadTable=false;
        this.removeTable=false;
        this.updateTable=true;
        this.updateTableRes=res.data.item
        this.lastupdateItem = this.updateTableRes[this.updateTableRes.length-1];
        this.cardItemSelected.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateCartItem(): void {
    this.spinner.show();
    const requestData = {
      "cartLineIndex": '',
      "quantity": this.cardItemSelected.value.Quantity
    }
    this.accountsService.UpdateCartItem(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.cardItemSelected.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFindProductNew(): void {
    this.spinner.show();
    const requestData ={
      "description": this.addByProductSearch.value.productSearchValue,
      "goodIdentificationTypeId": '',
      "productName": this.addByProductSearch.value.searchByProductSearch,
      }
    this.accountsService.getFindProductNew(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.checkOutActive=false;
        this.addByProductSearch.reset();
        this.FindProductNew = res.data.productsList;
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getFindParties(): void {
    this.spinner.show();
    const requestData ={
      "firstName": this.addByName.value.searchById,
      "lastName": this.addByName.value.nameValue,
      "partyIdentificationTypeId": ''
      }
    this.accountsService.getFindParties(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this.addByName.reset();
        this.FindPartiesAll = res.data.partiesList;
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  onChange(e){
    if(e.target.checked == true){
      this.showCCForm=false;
    }
    else{
      this.showCCForm=true;
    }
  }
  payCreditCard(): void {
    this.spinner.show();
    const requestData ={
      "amountCreditCard": this.payByCC.value.amountCreditCard,
      "cardNum": this.payByCC.value.cCardNo,
      "cardType": this.payByCC.value.cardType,
      "expMonth": this.payByCC.value.expMonth,
      "expYear": this.payByCC.value.expYear,
      "firstName": this.payByCC.value.firstName,
      "lastName": this.payByCC.value.lastName,
      "postalCode": this.payByCC.value.postalCode,
      "refNum": this.payByCC.value.creditrefNum,
      "securityCode": this.payByCC.value.securityCode,
      "swipeCard": '',
      "swipeData": this.payByCC.value.swipeData,
      "track2": '',
      }
     
    this.accountsService.payCreditCard(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.payByCC.reset();
        this.closebutton4.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  createpayGiftCard(): void {
    this.spinner.show();
    const requestData ={
      "amountGiftCard": this.payGiftCard.value.amountGiftCard,
      "refNum": this.payGiftCard.value.refNum
      }
     
    this.accountsService.createpayGiftCard(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.payGiftCard.reset();
        this.closebutton3.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  EmptyCart(): void {
    this.spinner.show();  
    this.accountsService.EmptyCart().subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Cart Empty Successfully");
        this.getCart();

      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  payFinish(): void {
    this.spinner.show();
    this.userLoginIdVal = localStorage.getItem('posUserId');
    const requestData ={
      "payFinish": "Y",
      "posTerminalId": this.posTerminalId,
      "userLoginId": this.userLoginIdVal
      }
     
    this.accountsService.payFinish(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Checkout Successfully");
        this.checkOutActive=true;
        this.refresh();
        this.emptyCart();
        this.getCart();
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  createpayCheck(): void {
    this.spinner.show();
    const requestData ={
      "amountCheck": this.payCheck.value.amountCheck,
      "refNum": this.payCheck.value.refNum
      }
     
    this.accountsService.createpayCheck(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.payCheck.reset();
        this.closebutton2.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  createPayCash(): void {
    this.spinner.show();
    const requestData ={
      "amountCash": this.payCash.value.amountCash,
      }
     
    this.accountsService.createPayCash(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCart();
        this.payCash.reset();
        this.closebutton.nativeElement.click();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  getSelectSalesReps() {
    this.spinner.show();
    this.accountsService.getSelectSalesReps().subscribe(res => {
      this.SelectSalesReps = res.data[0].getSelectSalesReps;
      this.spinner.hide();
    })
  }
  subs(uservalue:any){
    uservalue = uservalue.value--;
  }
  add(uservalue:any){
    uservalue = uservalue.value++; 
  }
  fouc_click1(){
    document.querySelector<HTMLElement>('.webpos_billing_input1')?.focus(); 
  }
  fouc_click2(){
    document.querySelector<HTMLElement>('.webpos_billing_input2')?.focus(); 
  }
  fouc_click3(){
    document.querySelector<HTMLElement>('.webpos_billing_input3')?.focus(); 
  }
  fouc_click4(){
    document.querySelector<HTMLElement>('.webpos_billing_input3')?.focus(); 
  }

  fouc_click5(){
    document.querySelector<HTMLElement>('.webpos_billing_input3')?.focus(); 
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/webpos/control/main'],{queryParams:{ posTerminalId : this.posTerminalId , productStoreId:this.productStoreId}});
  }
  managerOpen(){
    this.router.navigate(['/webpos/control/manager'],{queryParams:{ posTerminalId : this.posTerminalId , productStoreId:this.productStoreId}});
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }




}
