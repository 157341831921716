import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

@Component({
  selector: 'app-create-product-variant',
  templateUrl: './create-product-variant.component.html',
  styleUrls: ['./create-product-variant.component.css']
})
export class CreateProductVariantComponent implements OnInit {

  activeCategory=1;
  editMode=false;
  show=false;
  productId: any;
  imageForm:FormGroup;
  url: string;
  @Output() variantsModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,

    readonly _ToastrService: ToastrService,) { 
      this.url="/facilities/catalog/products/detail-product-summary/"
      this.imageForm = this._FormBuilder.group({
        productVariant: [''],
     
        
      });
    
  }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.productId = params["productId"];
    });
  }
  
  onUpdate(){
  }
 

  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.variantsModalClosed.emit();
  }

  cancelSubmit(){
    this._Router.navigate([this.url,this.productId])
  }
}
