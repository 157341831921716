import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';

@Component({
    selector: 'app-create-new-item-facility',
    templateUrl: './create-new-item-facility.component.html'
})
export class CreateNewItemFacilityComponent implements OnInit {
    activeCategory=1;
    agreement: {
        agreementId: string,
        agreementItemSeqId: string
    };
    createItemFacilityForm: FormGroup;
    agreementType: string;
    facilityIds: any[];
    constructor(
        readonly  _Router: Router,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner: NgxSpinnerService,
        readonly _AccountingApService: AccountingApService,
        readonly _FormBuilder: FormBuilder,
        readonly _WarehouseService: WarehouseService,
        readonly _ToastrService: ToastrService
    ) {
        this.createItemFacilityForm = this._FormBuilder.group({
            agreementId: [''],
            agreementItemSeqId: [''],
            facilityId: ['', [Validators.required]]
        });
        this.agreement = {
            agreementId: '',
            agreementItemSeqId: ''
        };
        this.agreementType = '';
        this.facilityIds = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this._ActivatedRoute.queryParams.subscribe(data => {
            this.agreement = {
                agreementId: data.agreementId,
                agreementItemSeqId: data.agreementItemSeqId
            };
        });
        this.getFacilityIds();
    }
    getFacilityIds(): void {
        this.spinner.show();
        this._WarehouseService.getFacility()
            .then(data => {
                this.spinner.hide();
                this.facilityIds = data.data.map(value => {
                    return {
                        label: value.facilityId,
                        value: value.facilityId
                    };
                });
            });
           
    }
    submit(): void {
        this.spinner.show();
        if (this.createItemFacilityForm.valid) {
            this._AccountingApService.createAgreementItemFacility({
                ...this.createItemFacilityForm.value, ...this.agreement
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.createItemFacilityForm.reset();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                    }
                });
        }
   
    }
    reset(): void {
        this.createItemFacilityForm.reset();
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}