import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import {LeadNotesFormService} from "../lead-notes-service";
import {LeadNotesService} from "../lead-notes/lead-notes.service";
import { ToastrService } from 'ngx-toastr';
import {Router,ActivatedRoute} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-lead-notes',
  templateUrl: './lead-notes.component.html',
  styleUrls: ['./lead-notes.component.css']
})
export class LeadNotesComponent implements OnInit {
  partyId: any;
  activeCategory=1;
  partyIdForCreate:any;
  noteId:any;
  showUpdateBtn=false;
  showCreateBtn:Boolean=false;
  noteName='';
  note='';

  constructor(
    public leadNotesFormService:LeadNotesFormService,
    readonly leadNotesServices:LeadNotesService,
    readonly toastr:ToastrService,
    readonly router:Router,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute:ActivatedRoute,
    ) { }

   
    ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["partyId"];
      let partyId=this.partyIdForCreate
      if(partyId)
      {
        this.showCreateBtn=true;   
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"]; 
      this.partyId = params["leadId"]; 
      this.noteName = params["noteName"];
      this.note= params["note"];
      if(this.noteId && this.partyId){
        this.showUpdateBtn=true;
      }
    });

    this.leadNotesFormService.lead_notes_form=null;
    this.leadNotesFormService.createEventForm(this.leadNotesFormService.lead_notes_form);
    }

  onCreate(){
    this.spinner.show();
    const form = { ...this.leadNotesFormService.lead_notes_form.value}
    this.leadNotesServices.createContactNote(form,this.partyIdForCreate).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/leads/details-lead'], { queryParams: { leadId:this.partyIdForCreate } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  onCancel(){
    this.router.navigate(['/crm/leads/details-lead'], { queryParams: { leadId:this.partyIdForCreate } })
  }

  onUpdate()
  {
    this.spinner.show();
    const form = { ...this.leadNotesFormService.lead_notes_form.value}
    this.leadNotesServices.updateContactNote(form,this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/leads/details-lead'], { queryParams: { leadId:this.partyId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();

  }
}

