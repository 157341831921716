import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { WarehouseShipmentService } from './warehouse-shipment.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup, Form } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
  import { Location } from '@angular/common';
  import { DatePipe } from '@angular/common';
import{NewWarehouseShipmentService} from '../new-warehouse-shipment/new-warehouse-shipment.service';

@Component({
  selector: 'app-warehouse-shipment',
  templateUrl: './warehouse-shipment.component.html',
  styleUrls: ['./warehouse-shipment.component.css']
})
export class WarehouseShipmentComponent implements OnInit {
  products3: any;
  total=0;
  @ViewChild('closebutton') closebutton;
  activeCategory=4;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  activeCat = 2;
  ShipmentTypeIdList: any = [];
  FacilityShipmentsLists: any = [];
  StatusId: any = [];
  editMode: boolean;

  facilities: any[];
  facilityId: string;
  opportunityIdArray: any = [];
  estimatedShipDateFromSearchType: any = [];
  estimatedShipDateToSearchType: any = [];
  entryDateFromSearchType: any = [];
  entryDateToSearchType: any = [];

  warehouseShipment: FormGroup;
  statusArray: any[];
  newWarehouseShipment:FormGroup;
  OrderIdsList: any = [];
  personsAndPartyGroupsList = [];
  getCurrencyList: any = [];
  StatusIdList: any = [];

  estimatedReadyDate: string;
  estimatedShipDate: string;
  estimatedArrivalDate: string;
  latestCancelDate: string;
  eventDate: string;
  url: string;
  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
  private _location: Location,
   
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
  
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly WarehouseShipmentService: WarehouseShipmentService,
    
    readonly datePipe: DatePipe,
    readonly _FormBuilder: FormBuilder) {
      this.newWarehouseShipment = this._FormBuilder.group({
        statusId: [''],
        primaryOrderId: [''],
        primaryReturnId: [''],
        primaryShipGroupSeqId: [''],
        picklistBinId: [''],
        estimatedReadyDate: [''],
        estimatedShipDate: [''],
        estimatedArrivalDate: [''],
        latestCancelDate: [''],
        estimatedShipCost: [''],
        currencyUomId: [''],
        handlingInstructions: [''],
        originFacilityId: [''],
        originFacility: [''],
        originTelecomNumberId: [''],
        destinationFacilityId: [''],
        destinationTelecomNumberId: [''],
        partyIdTo: [''],
        partyIdFrom: [''],
        additionalShippingCharge: [''],
        addtlShippingChargeDesc: [''],
        eventDate: [''],
        shipmentTypeId: [''],
        adress:['']
      });
    this.warehouseShipment = this._FormBuilder.group({
      destinationFacility: [''],
      entryDateFrom: [''],
      entryDateFromSearchType: ['Equals'],
      entryDateTo: [''],
      entryDateToSearchType: ['LessThan'],
      estimatedShipDateFrom: [''],
      estimatedShipDateFromSearchType: ['Equals'],
      estimatedShipDateTo: [''],
      estimatedShipDateToSearchType: ['LessThan'],
      originFacility: [''],
      shipmentId: [''],
      shipmentIdSearchType: ['Contains'],
      shipmentType: [''],
      statusId: [
        ['']
      ]

    });
    this.editMode = false;

    this.facilities = [];
    this.facilityId = '';

    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];

    this.estimatedShipDateFromSearchType = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equals',
        value: ' GreaterThanEqualTo'
      },

    ];
    this.entryDateFromSearchType = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equals',
        value: ' GreaterThanEqualTo'
      },

    ];
    this.estimatedShipDateToSearchType = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      },
      {
        label: 'Less Than Equals',
        value: ' LessThanEqualTo'
      },

    ];
    this.entryDateToSearchType = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      },
      {
        label: 'Less Than Equals',
        value: ' LessThanEqualTo'
      },

    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
    this.getFacility();
    this.getStatusId();
    this.getFacilityData();
    this.getStatusIdData();
    this.getShipmentTypeId();
    this.getData();
    this.getOrderIds();
    this.getPersonsAndPartyGroups();
    this.getCurrency();
  
    this.getShipmentTypeIdData();
  }
  getOrderIds() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getOrderIds().subscribe((responce) => {
      this.OrderIdsList = responce.data;
      this.spinner.hide();
    }, (error) => {
      return;
    });
   
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      const partyArray = responce.data.PersonsGroups
      this.personsAndPartyGroupsList = partyArray;
    }, (error) => {
      return;
    });
  
  }

  getCurrency() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getCurrency().subscribe((responce) => {
      const currencyArray = responce.data.currencyList;
      this.getCurrencyList = currencyArray;
      this.spinner.hide();
    }, (error) => {
      return;
    });
   
  }

  getStatusIdData() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getStatusId().subscribe((responce) => {
      this.StatusIdList = responce.data;
      this.spinner.hide();
    }, (error) => {
      return;
    });
   
  }

  getFacilityData(): void {
    this.spinner.show();
    this.WarehouseShipmentService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getShipmentTypeIdData() {
    this.spinner.show();
    this.WarehouseShipmentService.getShipmentTypeIdList().subscribe((responce) => {
      this.ShipmentTypeIdList = responce.data;
      this.spinner.hide();
    }, (error) => {
      return;
    });
    
  }

  submit(): void {
    this.spinner.show();
    this.estimatedReadyDate=this.datePipe.transform(this.newWarehouseShipment.value.estimatedReadyDate,"yyyy-MM-dd hh:mm:ss"),
    this.estimatedShipDate=this.datePipe.transform(this.newWarehouseShipment.value.estimatedShipDate,"yyyy-MM-dd hh:mm:ss"),
    this.estimatedArrivalDate=this.datePipe.transform(this.newWarehouseShipment.value.estimatedArrivalDate,"yyyy-MM-dd hh:mm:ss"),
    this.latestCancelDate=this.datePipe.transform(this.newWarehouseShipment.value.latestCancelDate,"yyyy-MM-dd hh:mm:ss"),
    this.eventDate=this.datePipe.transform(this.newWarehouseShipment.value.eventDate,"yyyy-MM-dd hh:mm:ss")
    const requestData = {
      statusId: this.newWarehouseShipment.value.statusId.statusId,
      primaryOrderId: this.newWarehouseShipment.value.primaryOrderId.orderId,
      primaryReturnId: this.newWarehouseShipment.value.primaryReturnId,
      primaryShipGroupSeqId: this.newWarehouseShipment.value.primaryShipGroupSeqId,
      picklistBinId: this.newWarehouseShipment.value.picklistBinId,
      estimatedReadyDate:this.estimatedReadyDate,
      estimatedShipDate:this.estimatedShipDate,
      estimatedArrivalDate:this.estimatedArrivalDate,
      latestCancelDate: this.latestCancelDate,
      estimatedShipCost: this.newWarehouseShipment.value.estimatedShipCost,
      currencyUomId: this.newWarehouseShipment.value.currencyUomId.uomId,
      handlingInstructions: this.newWarehouseShipment.value.handlingInstructions,
      originFacilityId:this.newWarehouseShipment.value.originFacilityId.value,
     
      originContactMechId: this.newWarehouseShipment.value.adress,
      originTelecomNumberId: this.newWarehouseShipment.value.originTelecomNumberId,
      destinationContactMechId:this.newWarehouseShipment.value.destinationFacilityId,
      destinationFacilityId: this.newWarehouseShipment.value.originFacility.value,
      destinationTelecomNumberId: this.newWarehouseShipment.value.destinationTelecomNumberId,
      partyIdTo: this.newWarehouseShipment.value.partyIdTo.partyId,
      partyIdFrom: this.newWarehouseShipment.value.partyIdFrom.partyId,
      additionalShippingCharge: this.newWarehouseShipment.value.additionalShippingCharge,
      addtlShippingChargeDesc: this.newWarehouseShipment.value.addtlShippingChargeDesc,
      eventDate: this.eventDate ,
      shipmentTypeId: this.newWarehouseShipment.value.shipmentTypeId.shipmentTypeId,
    };
    this.NewWarehouseShipmentService.createFacilityShipment(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.newWarehouseShipment.reset();
        this.getData();
        this.closebutton.nativeElement.click();
        
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  Main(){
    this.router.navigate(["/facilities/warehouse"])
  }
  Facility(){
    this.router.navigate(["/facilities/facility-group"])
  }
  Inventory(){
    this.router.navigate(["/facilities/inventory-labels"])
  }
  Shipments(){
    this.router.navigate(["/facilities/warehouse-shipment"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  getFacility(): void {
    this.spinner.show();
    this.WarehouseShipmentService.getFacility()
      .then(data => {
        this.spinner.hide();

        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }

  getShipmentTypeId() {
    this.spinner.show();
    this.WarehouseShipmentService.getShipmentTypeIdList().subscribe((responce) => {

      this.ShipmentTypeIdList = responce.data;
      this.spinner.hide();
    });
   
  }

  getStatusId() {
    this.spinner.show();
    this.WarehouseShipmentService.getStatusId().subscribe((responce) => {

      this.StatusId = responce.data;
      this.spinner.hide();
    });
  
  }

  onSubmit(): void {
    this.spinner.show();
    this.FacilityShipmentsLists = [];
    let req = {
      destinationFacility: this.warehouseShipment.value.destinationFacility,
      entryDateFrom: this.warehouseShipment.value.entryDateFrom,
      entryDateFromSearchType: this.warehouseShipment.value.entryDateFromSearchType,
      entryDateTo: this.warehouseShipment.value.entryDateTo,
      entryDateToSearchType: this.warehouseShipment.value.entryDateToSearchType,
      estimatedShipDateFrom: this.warehouseShipment.value.estimatedShipDateFrom,
      estimatedShipDateFromSearchType: this.warehouseShipment.value.estimatedShipDateFromSearchType,
      estimatedShipDateTo: this.warehouseShipment.value.estimatedShipDateTo,
      estimatedShipDateToSearchType: this.warehouseShipment.value.estimatedShipDateToSearchType,
      originFacility: this.warehouseShipment.value.originFacility,
      shipmentId: this.warehouseShipment.value.shipmentId,
      shipmentIdSearchType: this.warehouseShipment.value.shipmentIdSearchType,
      shipmentType: this.warehouseShipment.value.shipmentType.shipmentTypeId,
      statusId: this.statusArray
    }
    this.WarehouseShipmentService.getFacilityShipments(req).subscribe((res: any) => {
     
      if (res.success) {
        this.FacilityShipmentsLists = res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  getData(): void {
    this.spinner.show();
    this.FacilityShipmentsLists = [];

    this.WarehouseShipmentService.getFacilityShipments(this.warehouseShipment.value).subscribe((res: any) => {
     
      if (res.success) {
        this.FacilityShipmentsLists = res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
 
  }
  shipmentGatewayOpen(){
    this.router.navigate(['/facilities/shipment-config']);
  }

  shipmentsOpen(){
    this.router.navigate(['/facilities/warehouse-shipment']);
  }
  update(data) {
    this.router.navigate(['/facilities/warehouse-shipment/update-warehouse-shipment'], { queryParams: { data: JSON.stringify(data) } });
  }

  summary(data,status) {
    this.router.navigate(['/facilities/warehouse-shipment/warehouse-shipment-summary'], { queryParams: { data: JSON.stringify(data),status:status } });
  }
  onChange(value) {
    this.statusArray = []
    this.statusArray.push(value.statusId);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
