<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">Reconcile Financial Accounting Transaction</span>
      
      </div>
      <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
          <div class="suppliers-wrapper">


          </div>
        </div>
        <div class="create-new-leade mt-2">
          <div class="container-fluid">
            <div class="row">
              <form class="w-100">
                <div class="w3-card-4 classCard">
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <div class="col-lg-12">
                    <div class="row">

                        <div class="col-lg-3 form-group classInput">
                          <label for="exampleInputEmail1">Fin Account Trans Type ID</label>
                        </div>
                     
                        <div class="col-lg-2">
                          <p-dropdown filter="true" [(ngModel)]="advanceSearchA.finAccountTransTypeId"
                          [ngModelOptions]="{standalone:true}" name="finAccountTransTypeId" [options]="finTypeArray"
                          optionlabel="label" placeholder="Fin Account Trans Type ID">
                        </p-dropdown>
                        </div>
                        <div class="col-lg-3 form-group classInputA">
                          <label for="exampleInputEmail1">Gl Reconciliation ID</label>
                        </div>
                        <div class="col-lg-2">
                          <p-dropdown filter="true" [(ngModel)]="advanceSearchA.glReconciliationId"
                          [ngModelOptions]="{standalone:true}" name="glReconciliationId" [options]="giTransArray"
                          optionlabel="label" placeholder="Gl Reconciliation ID">
                        </p-dropdown>
                       
                        </div>

                       

                    </div>
                </div>
                <div class="col-lg-12">
                  <div class="row">

                      <div class="col-lg-3 form-group classInput">
                        <label for="exampleInputEmail1">Status</label>
                      </div>
                   
                      <div class="col-lg-2">
                        <label>Created</label>
                      </div>
                      <div class="col-lg-3 form-group classInputA">
                        <label for="exampleInputEmail1">From Transaction Date</label>
                      </div>
                      <div class="col-lg-2">
                        <input type="date" [(ngModel)]="advanceSearchA.fromTransactionDate"
                            [ngModelOptions]="{standalone:true}" name="fromTransactionDate" style="width: 55%;"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Enter Transaction Date">
                     
                      </div>

                     

                  </div>
              </div>
              <div class="col-lg-12">
                <div class="row">

                    <div class="col-lg-3 form-group classInput">
                      <label for="exampleInputEmail1">Thru Transaction Date</label>
                    </div>
                 
                    <div class="col-lg-2">
                      <input type="date" [(ngModel)]="advanceSearchA.thruTransactionDate"
                            [ngModelOptions]="{standalone:true}" name="thruTransactionDate" style="width: 55%;"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Enter Transaction Date">
                    </div>
                    <div class="col-lg-3 form-group classInputA">
                      <label for="exampleInputEmail1">From Entry Date</label>
                    </div>
                    <div class="col-lg-2">
                      <input type="date" style="width: 55%;" [(ngModel)]="advanceSearchA.fromEntryDate"
                      [ngModelOptions]="{standalone:true}" name="fromEntryDate" class="form-control"
                      id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Transaction Date">
                   
                    </div>

                   

                </div>
            </div>
            <div class="col-lg-12">
              <div class="row">

                  <div class="col-lg-3 form-group classInput">
                    <label for="exampleInputEmail1">Thru Entry Date</label>
                  </div>
               
                  <div class="col-lg-2">
                    <input type="date" [(ngModel)]="advanceSearchA.thruEntryDate"
                            [ngModelOptions]="{standalone:true}" name="thruEntryDate" style="width: 55%;"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            placeholder="Enter Transaction Date">
                  </div>
                 

              </div>
          </div>

                </div>
                <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -21%;"><button _ngcontent-cta-c128="" type="submit"
                    class="btn btn-secondary submit-btn" (click)="advanceSearchTransition(false)">Find</button>&nbsp;
                  <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2"
                    (click)="resetTransaction();">Reset</button></div></div><br>
                    <div class="w3-card-4 classCard">
                      <header class="w3-container w3-blue">
                        <div class="header-tabs">
                          <h4 class=" common-styling h4Margin">
                              Financial List
                          </h4>
                       
                        </div>
                      </header>
                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                  <div class="col-lg-12 col-12">
                    <div class="form-group">
                     

                      <div class="card own-account-table">
                        <p-table [value]="FinancialTransaction" [paginator]="true" [rows]="rows" scrollWidth="100%"
                          [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                          (sortFunction)="customSort($event)" [customSort]="true">
                          <ng-template pTemplate="header">
                            <tr>
                              <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                <div class="checkbox-align">
                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                      <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined"
                                          value="undefined"></div>
                                      <div role="checkbox"
                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                          class="ui-chkbox-icon ui-clickable"></span></div>
                                    </div>
                                    <!---->
                                  </p-checkbox>

                                  Fin Account Trans ID<p-sortIcon field="code"></p-sortIcon>
                                </div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">Fin Account Trans Type ID
                                <p-sortIcon field="name"></p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">Created At<p-sortIcon
                                  field="name"></p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"> Party <p-sortIcon field="name">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"> Gl Reconciliation Name
                                <p-sortIcon field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"> Transaction Date <p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Entry Date<p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Amount <p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Payment ID <p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Payment Type<p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Payment Method Type
                                <p-sortIcon field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Status<p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Comments<p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Cancel Transaction Status
                                <p-sortIcon field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Action<p-sortIcon
                                  field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>

                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-product>
                            <tr>
                              <td [ngStyle]="{'width':'170px'}">
                                <div class="checkbox-align">
                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                      <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined"
                                          value="undefined"></div>
                                      <div role="checkbox"
                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                          class="ui-chkbox-icon ui-clickable"></span></div>
                                    </div>
                                  </p-checkbox>
                                  {{product.finAccountTransId}}
                                </div>
                              </td>
                              <td [ngStyle]="{'width':'170px'}"> {{product.finAccountTransTypeId}} </td>
                              <td [ngStyle]="{'width':'170px'}"> {{product.createdStamp|date:'yyyy-MM-dd hh:mm:ss'}}
                              </td>
                              <td [ngStyle]="{'width':'170px'}">{{product.partyId}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.glReconciliationId}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.transactionDate|date:'yyyy-MM-dd hh:mm:ss'}}
                              </td>
                              <td [ngStyle]="{'width':'170px'}">{{product.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.amount}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.paymentId}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.finAccountTransType}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.paymentMethodTypeDescription}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.statusDescription}}</td>
                              <td [ngStyle]="{'width':'170px'}">{{product.comments}}</td>
                              <td [ngStyle]="{'width':'170px'}"
                                (click)="cancelFinancialAccountTransaction(product.finAccountTransId,product.statusId)">
                                <a style="cursor: pointer;color: #0d3769 ;">Cancel</a></td>


                            </tr>
                          </ng-template>
                        </p-table>
                        <p class="paginate_data">
                          View per page </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </form>

            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner> 