<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey cursor-pointer" routerLink="/financial/payable/invoices/credit-memo">Credit Memo</span>
                <span class="color-black pl-1"> > Create Credit Memo</span>
                <button class="btn btn-danger buttonclass" 
                (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Create Credit Memo</a></li>
                
                    </ul>
                  </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createCreditMemoForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Customer Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="partyIds"
                                                    formControlName="partyIdFrom" optionlabel="label"
                                                    placeholder="Select Party Id">
                                                </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Currency <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="currencyList"
                                                    formControlName="currencyUomId" placeholder="Select Currency"
                                                    optionlabel="label">
                                                </p-dropdown>
                                                <small class="validate-field"
                                                    *ngIf="!!createCreditMemoForm.controls.currencyUomId.invalid && !!createCreditMemoForm.controls.currencyUomId.touched">
                                                    Currency is required!</small>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Invoice Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-calendar formControlName="invoiceDate"></p-calendar>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Due Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-calendar formControlName="dueDate"></p-calendar>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Ref Num</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                aria-describedby="emailHelp" formControlName="referenceNumber"
                                                placeholder="Ref Num">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="description">Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="description"
                                                placeholder="Description">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Message</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Message" class="form-control"
                                                formControlName="invoiceMessage">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Organization Party
                                                    ID <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="array"
                                                    formControlName="partyId" optionlabel="label" placeholder="select">
                                                </p-dropdown>
                                                <small class="validate-field"
                                                    *ngIf="!!createCreditMemoForm.controls.partyId.invalid && !!createCreditMemoForm.controls.partyId.touched">
                                                    Organization Party ID is required!</small>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="editMode">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="shippingAddress">Shipping Address</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="postalAddress"
                                                        placeholder="Select Address" optionlabel="label">
                                                    </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="shippingAddress">Billing Address</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" placeholder="Select Address"
                                                        optionlabel="label" [options]="postalAddress">
                                                    </p-dropdown>
                                            </div>


                                        </div>
                                    </div>
                                   
                                    
                                    <div class="col-lg-12 col-12 main-submit-button " style="
                                    margin-left: -31%; ">
                                        <button type="submit" class="btn btn-secondary submit-btn"
                                            (click)="submit()">{{editMode ?'Update':'Create'}}</button>
                                       
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 