import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-create-agreement-item',
    templateUrl: './create-new-agreement-item.component.html'
})
export class CreateNewAgreementItemComponent implements OnInit {
    createAgreementItem: FormGroup;
    activeCategory = 2;
    agreementItemSeqId: string;
    agreementId: string;
    agreementItemTypes: any[];
    currencyList: any[];
    editMode: boolean;
    agreementItem: any;
    agreementType: string;
    constructor(
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router,
        readonly _ToastrService: ToastrService,
        readonly _AccountingApService: AccountingApService,
        readonly _GlobalResourceService: GlobalResourceService
    ) {
        this.agreementItemTypes = [];
        this.currencyList = [];
        this.createAgreementItem = this._FormBuilder.group({
            agreementId: [''],
            agreementImage: [''],
            agreementItemTypeId: [''],
            agreementText: [''],
            currencyUomId: ['']
        });
        this.agreementId = '';
        this.editMode = false;
        this.agreementType = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.agreementId = this._ActivatedRoute.snapshot.queryParams.agreementId;
        this.agreementItemSeqId = this._ActivatedRoute.snapshot.queryParams.agreementItemSeqId;
        if (this.agreementItemSeqId) {
            this.editMode = true;
            this._AccountingApService.getAgreementItemById(this.agreementId, this.agreementItemSeqId)
                .then(data => {
                    this.agreementItem = data.data;
                    this.createAgreementItem.patchValue(data.data);
                });
        }
        this.getCurrencyList();
        this.getAgreementItemTypes();
    }
    getCurrencyList(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyList = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getAgreementItemTypes(): void {
        this.spinner.show();
        this._AccountingApService.getAgreementItemTypeList()
            .then(data => {
                this.spinner.hide();
                this.agreementItemTypes = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.agreementItemTypeId
                    };
                });
            });
          
    }
    reset(): void {
        this.createAgreementItem.reset();
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateAgreementItem(this.agreementItemSeqId, {
            ...this.agreementItem, ...this.createAgreementItem.value

        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.createAgreementItem.reset();
                    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
                }
            });
          
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createAgreementItem({
                ...this.createAgreementItem.value, ...{
                    agreementId: this.agreementId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.createAgreementItem.reset();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });
                    }
                });
        }
      
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}