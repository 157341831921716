import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-content-operation',
  templateUrl: './edit-content-operation.component.html',
  styleUrls: ['./edit-content-operation.component.css']
})
export class EditContentOperationComponent implements OnInit {
  activeCategory=2;

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  contentOperationForm: FormGroup;
  addContentOperation: any;
  product: any;
  show: boolean;
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) 
  {
    this.contentOperationForm = this._FormBuilder.group({
      description:"",
      contentOperationID:"",
  })
   }
  ngOnInit(): void {
    this.getAddContentOperation();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  removeContentOperation(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "contentOperationId": product.contentOperationId ,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeContentOperation(req).subscribe(res => {
          this.getAddContentOperation();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getAddContentOperation() {
    this.spinner.show();
    this.myContactsService.getAddContentOperation().subscribe(res => {
      this.addContentOperation = res.data.getAddContentOperation;
      this.spinner.hide();

    })
  }
  cancelSubmit() {
    this._Router.navigate(["/fsm/fsmSetting/work-order"])
  }

  createContentOperation() {
    this.spinner.show();
    const requestData = {
      "contentOperationId": this.contentOperationForm.value.contentOperationID,
      "description":  this.contentOperationForm.value.description
    }

    this.myContactsService.addContentOperation(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.contentOperationForm.reset();
        this.getAddContentOperation();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  reset(){
        this.contentOperationForm.reset();
        this.show = false;
  }
  update(product) {
    this.show = true;
    this.product = product;
    setTimeout(() => {
      const formValue = this.contentOperationForm;
      formValue.patchValue({
        contentOperationID: product.contentOperationId,
        description: product.description,
      })
    }, 2000);

  }
  updateContentOperation() {
    this.spinner.show();
    const requestData = {
      "contentOperationId": this.contentOperationForm.value.contentOperationID,
      "description":  this.contentOperationForm.value.description
    }
    this.myContactsService.updateContentOperation(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.contentOperationForm.reset();
        this.getAddContentOperation();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  openPurposeOperation()
  {
   this._Router.navigate(['/content/content-setup/edit-content-purpose-operation']);
   
  }

  openContentOperation()
  {
   this._Router.navigate(['/content/content-setup/edit-content-operation']);

  }

  openAssociationPredicate()
  {
   this._Router.navigate(['/content/content-setup/edit-content-assoication-predicate']);

  }

  openTypeAttribute()
  {
   this._Router.navigate(['/content/content-setup/edit-content-type-attribute']);

  }

  openPurposeType()
  {
   this._Router.navigate(['/content/content-setup/edit-content-purpose-type']);

  }

  openAssociationType()
  {
   this._Router.navigate(['/content/content-setup/edit-content-association-type']);

  }

  openPermission()
  {
   this._Router.navigate(['/content/content-setup/permission']);

  }

  openContentType()
  {
   this._Router.navigate(['/content/content-setup']);

  }


 customSort(event: SortEvent): void {
   event.data.sort((data1, data2) => {
       const value1 = data1[event.field];
       const value2 = data2[event.field];
       let result = null;

       if (value1 == null && value2 != null) {
           result = -1;
       }
       else if (value1 != null && value2 == null) {
           result = 1;
       }
       else if (value1 == null && value2 == null) {
           result = 0;
       }
       else if (typeof value1 === 'string' && typeof value2 === 'string') {
           result = value1.localeCompare(value2);
       }
       else {
           result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
       }
       return (event.order * result);
   });

   
}

ngOnDestroy(): void {
 this.spinner.hide();
   }

 changeactiveCategory(tab: number)
 {
   this.activeCategory = tab;
 
 }


}
