import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pos-dashboard',
  templateUrl: './pos-dashboard.component.html',
  styleUrls: ['./pos-dashboard.component.css']
})
export class POSDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
