import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-routing-task-parties',
  templateUrl: './create-routing-task-parties.component.html',
  styleUrls: ['./create-routing-task-parties.component.css']
})
export class CreateRoutingTaskPartiesComponent implements OnInit {
  createParty:FormGroup;
  activeCategory=2;
  routingTaskId: any;
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  throughDate: string;
  fromDate: string;
  partyId: any;
  roleTypeId: any;
  fromDateEdit: any;
  thruDateEdit: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createParty = this._FormBuilder.group({
        PartyID: [''],
        RoleTypeID:[''],
        fromDate: [''],
        throughDate:[''],
       
       
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingTaskId = params["routingTaskId"];
      this.partyId = params["partyId"];
      this.roleTypeId = params["roleTypeId"];
      this.fromDateEdit = params["fromDate"];
      this.thruDateEdit = params["thruDate"];  
    });
    this.getRoleAllType();
    this.getPartyId();
    if(this.partyId) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createParty;
      formValue.patchValue({
        PartyID:this.partyId,
        RoleTypeID: this.roleTypeId,
        fromDate:this.datePipe.transform(this.fromDateEdit,"yyyy-MM-dd"),
        throughDate:this.datePipe.transform(this.thruDateEdit,"yyyy-MM-dd")  
      })
      }, 3000);
    }
    else {
      this.show = false;
    }
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        } })
     
     }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    }) 
  }
  
  onSubmit() {
    this.spinner.show();
    const startDate = this.createParty.get('throughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createParty.get('fromDate').value;
    this.fromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.fromDate,
      "partyId": this.createParty.value.PartyID,
      "roleTypeId": this.createParty.value.RoleTypeID,
      "thruDate":this.throughDate
    }
    this.productionService.postProdParty(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const startDate = this.createParty.get('throughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.fromDateEdit,
      "partyId": this.createParty.value.PartyID,
      "roleTypeId": this.createParty.value.RoleTypeID,
      "thruDate":this.throughDate
    }
    this.productionService.updateProdParty(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });
  }
  cancelSubmit(){
    this._Router.navigate(['/production/detail-routing-task-summary'],{ queryParams: { routingTaskId :this.routingTaskId}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}