import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-holiday-summary',
  templateUrl: './detail-holiday-summary.component.html',
  styleUrls: ['./detail-holiday-summary.component.css']
})
export class DetailHolidaySummaryComponent implements OnInit {
  activeCategory=1;
  skillType: any;
  Des: any;
  date: any;
  id: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,    private _location: Location,
    readonly router: Router,
  ) { }
 
  ngOnInit(): void {
   
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.skillType = params["holiday"],
      this.Des=params["des"],
      this.date=params["date"],
    
    
    ])
   
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])

  }
  cancelSubmit(){
    this._location.back();
  }
  
  

 
}
