<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;"> Communication Event

                </span>
               
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass"  >Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
               
              
                <div>
                    <div class="container-fluid">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                  
                              <li [ngClass]="activeCategory==2?'active':''" ><a>
                                Edit Communication Event</a></li>
                              
                           
                  
                            </ul>
                          </div>
                          <div class="row">
                            <form class="w-100" [formGroup]="createCommEvent">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                              
                              <div class="col-lg-12" >
                                <div class="row">
                                    <div class="col-lg-3 form-group rightForm">
                                      <label for="exampleInputEmail1">
    
                                        Parent Comm Event ID</label>
                                    </div>
                                    <div class="col-lg-2">
                                      <p-dropdown  [options]="parentCommEventArray" optionlabel="label"
                                    placeholder="Parent Comm Event ID" filter="true"
                                      formControlName="parCommEventID">
    
                                    </p-dropdown>
    
                                    </div>
                                    <div class="col-lg-3 form-group rightFormA">
                                      <label for="exampleInputEmail1">
      
                                        Comm.Type ID</label>
                                    </div>
                                    <div class="col-lg-2">
                                      <p-dropdown placeholder="Comm.Type ID" 
                                      [options]="comtypeIdArray" optionlabel="label" filter="true"
                                       formControlName="commTypeId">
      
                                      </p-dropdown>
      
                                    </div>
                                    
                                   
                                </div>
                            </div>
                         
                          <div class="col-lg-12" >
                            <div class="row">
                                <div class="col-lg-3 form-group rightForm">
                                  <label for="exampleInputEmail1">
    
                                    Status</label>
                                </div>
                                <div class="col-lg-2">
                                  <p-dropdown [options]="stausIdArray" optionlabel="label"
                                    placeholder="Status" filter="true" formControlName="status">
    
                                    </p-dropdown>
    
                                </div>
                                <div class="col-lg-3 form-group rightFormA">
                                  <label for="exampleInputEmail1">
      
                                    Party From</label>
                                </div>
                                <div class="col-lg-2">
                                  <p-dropdown  (onChange)="contactEventMech($event.value)"
                                       [options]="partyIdArray" optionlabel="label"
                                       placeholder="Party From" filter="true" formControlName="partyFrom">
      
                                      </p-dropdown>
      
                                </div>
                               
                            </div>
                        </div>
                      
                      <div class="col-lg-12" >
                        <div class="row">
                            <div class="col-lg-3 form-group rightForm">
                              <label for="exampleInputEmail1">
    
                                Party To</label>
                            </div>
                            <div class="col-lg-2">
                              <p-dropdown (onChange)="contactEventMechTo($event.value)" [options]="partyIdArray" optionlabel="label"
                                     placeholder="Party To" filter="true" formControlName="partyTo">
    
                                    </p-dropdown>
    
                            </div>
                            <div class="col-lg-3 form-group rightFormA">
                              <label for="exampleInputEmail1">
      
                                Contact Mech Type ID</label>
                            </div>
                            <div class="col-lg-2">
                              <p-dropdown  [options]="contactMechTypeArray" optionlabel="label"
                              placeholder="Contact Mech Type ID" filter="true"
                                formControlName="conMechId">
      
                              </p-dropdown>
      
      
                            </div>
                           
                        </div>
                    </div>
                  
                  <div class="col-lg-12" >
                    <div class="row">
                        <div class="col-lg-3 form-group rightForm">
                          <label for="exampleInputEmail1">
    
                            From Contact Mech</label>
                        </div>
                        <div class="col-lg-2">
                          <p-dropdown [options]="contactMechArray" optionlabel="label"
                                    placeholder="From Contact Mech" filter="true"
                                      formControlName="fromContactMechId">
    
                                    </p-dropdown>
    
    
                        </div>
                        <div class="col-lg-3 form-group rightFormA">
                          <label for="exampleInputEmail1">
      
                            To Contact Mech</label>
                        </div>
                        <div class="col-lg-2">
                          <p-dropdown [options]="contactMechToArray" optionlabel="label"
                          placeholder="To Contact Mech" filter="true"
                           formControlName="toContactMechId">
      
                         </p-dropdown>
      
      
                        </div>
                       
                    </div>
                </div>
              
              <div class="col-lg-12" >
                <div class="row">
                    <div class="col-lg-3 form-group rightForm">
                      <label for="exampleInputEmail1">
    
                        Role Type Id From</label>
                    </div>
                    <div class="col-lg-2">
                      <p-dropdown  [options]="roleTypeIdArray" optionlabel="label"
                                     placeholder="Role Type Id From" filter="true"
                                      formControlName="roleTypeFrom">
    
                                    </p-dropdown>
    
    
                    </div>
                    
                    <div class="col-lg-3 form-group rightFormA">
                      <label for="exampleInputEmail1">
      
                        Role Type Id To</label>
                    </div>
                    <div class="col-lg-2">
                      <p-dropdown  [options]="roleTypeIdArray" optionlabel="label"
                      placeholder="Role Type Id To" filter="true" formControlName="roleTypeTo">
      
                     </p-dropdown>
      
      
                    </div>
                </div>
            </div>
         
          <div class="col-lg-12" >
            <div class="row">
                <div class="col-lg-3 form-group rightForm">
                  <label for="exampleInputEmail1">
    
                    Contact List ID</label>
                </div>
                <div class="col-lg-2">
                  <p-dropdown [options]="contactListIdArray" optionlabel="label"
                                     placeholder="Contact List ID" filter="true" formControlName="contactListId">
    
                                    </p-dropdown>
    
    
                </div>
                <div class="col-lg-3 form-group rightFormA">
                  <label for="exampleInputEmail1">Start Date
      
                  </label>
                </div>
                <div class="col-lg-2">
                  <input type="date" class="form-control" id="exampleInputEmail1"
                  aria-describedby="emailHelp" placeholder="Start Date" formControlName="startdate">
      
      
                </div>
               
            </div>
        </div>
      
      <div class="col-lg-12" >
        <div class="row">
            <div class="col-lg-3 form-group rightForm">
              <label for="exampleInputEmail1"> Finish Date/Time
    
              </label>
            </div>
            <div class="col-lg-2">
              <input type="date" class="form-control" id="exampleInputEmail1"
              aria-describedby="emailHelp" placeholder="Finish Date/Time"
              formControlName="finishDate">
    
            </div>
            <div class="col-lg-3 form-group rightFormA">
              <label for="exampleInputEmail1">Subject
      
              </label>
            </div>
            <div class="col-lg-2">
              <input type="email" class="form-control" id="exampleInputEmail1"
              aria-describedby="emailHelp" placeholder="Subject" formControlName="subject">
      
            </div>
           
        </div>
    </div>
   
    <div class="col-lg-12" >
      <div class="row">
          <div class="col-lg-3 form-group rightForm">
            <label for="exampleInputEmail1">
    
              Reason Enum ID</label>
          </div>
          <div class="col-lg-2">
            <p-dropdown [options]="reasonenumArray" optionlabel="label"
            placeholder="Reason Enum ID" filter="true" formControlName="reasonEnumId">
    
            </p-dropdown>
          </div>
          <div class="col-lg-3 form-group rightFormA">
            <label for="exampleInputEmail1">
    
              Content Mime Type ID</label>
          </div>
          <div class="col-lg-2">
            <p-dropdown placeholder="Content Mime Type ID" filter="true"
                                    [options]="contentMMArray" optionlabel="label" formControlName="contentMimeTypeId">
    
                                    </p-dropdown>
          </div>
         
      </div>
    </div>
  
    <div class="col-lg-12" >
      <div class="row">
          <div class="col-lg-3 form-group rightForm">
            <label for="exampleInputEmail1">Content
    
            </label>
          </div>
          <div class="col-lg-2">
            <input type="email" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Content" formControlName="content">
          </div>
          <div class="col-lg-3 form-group rightFormA">
            <label for="exampleInputEmail1">Note
    
            </label>
          </div>
          <div class="col-lg-2">
            <input type="email" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Note" formControlName="note">
          </div>
         
      </div>
    </div>
    
                            
                              </div>
    
    
                            </form>
                            <div class="main-submit-button" style="margin-left: 85%;">
                              <button (click)="onSubmit();" type="submit" class="btn btn-secondary submit-btn">Save</button>
    
    
                            </div>
                          </div>
                          <br><br><br>
                       
                    </div>

                </div>
               
            </div>
            </div>

           
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
