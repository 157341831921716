import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class FinancialService {

    constructor(
        private _ApiService: ApiService,
        private _HttpClient: HttpClient
    ) {
    }
    getRoleTypeIds(roleType: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/partyRoles/roleTypes?roleType=${roleType}`);
    }
    createFixAsset(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssets`, formData);
    }
    createFixAssetMaint(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/assetMaintenance/createFixedAsset`, formData);
    }
    getInitialAssetData(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssets/initialData`);
    }
    getAssets(pagination: { pageNo: number, pageSize: number }, searchFilter: {
        assetType: string,
        fixedAssetId: string,
        fixedAssetIdSearchType: string,
        fixedAssetName: string,
        fixedAssetNameSearchType: string
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssets/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
    }
    getFixAssetById(fixAssetId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssets/${fixAssetId}`);
    }
    updateFixAsset(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssets`, formData);
    }
    createProduct(fixedAssetId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssetProduct/${fixedAssetId}`, formData);
    }
    getProductInitialData(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssetProduct/initialData`);
    }
    updateProduct(fixedAssetId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssetProduct/${fixedAssetId}`, formData);
    }
    getProductById(formData: {
        fixedAssetId: string,
        fixedAssetProductTypeId: string,
        fromDate: string,
        productId: string
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssetProduct/summary`, formData);
    }
    getAssetProducts(fixedAssetId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssetProduct/${fixedAssetId}`);
    }
    removeProduct(formData: {
        fixedAssetId: string,
        fixedAssetProductTypeId: string,
        fromDate: string,
        productId: string
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/fixedAssetProduct`, formData);
    }

    //Assignment 

    getAssetAssignments(fixedAssetId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/assignment/${fixedAssetId}`);
    }
    createFixedAssetAssignment(fixedAssetId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/assignment/${fixedAssetId}`, formData);
    }
    updateFixedAssetAssignment(fixedAssetId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/assignment/${fixedAssetId}`, formData);
    }
    getInitialAssignmentData(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/assignment/initialData`);
    }
    getAssignmentById(formData: {
        fixedAssetId: string,
        fromDate: string,
        partyId: string,
        roleTypeId: string
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/assignment/summary`, formData);
    }
    removeAssignment(formData: {
        fixedAssetId: string,
        fromDate: string,
        partyId: string,
        roleTypeId: string
    }): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/assignment`, formData);
    }
    // Global GL setting

    getGlAccounts(pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/globalGLSetting?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    getGlInitialData(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/globalGLSetting/initialData`);
    }
    createGlAccount(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/globalGLSetting`, formData);
    }

}