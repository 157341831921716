<div class="container-fluid main-container-wrapper">
    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-top: 1%;">
       <span class="color-black pl-1 titlePanels">Feature Group Summary </span>
       <span class="d-flex">
     
        <button type="submit" (click)="homeButton();"
            class=" btn btn-outline-secondary mr-2">Home</button>

        <button type="submit" (click)="cancelSubmit();"
            class="btn btn-danger buttonclass ">Back</button>
    </span>
    </div>
    <div class="create-new-leade mt-2">
        <div class="container-fluid">
            <div class="w-100">

                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                        <div class="panel-group">
                            <div class="divA">
                                <div class="w3-card-4 classCardView">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs" >
                                            <h4 class=" common-styling h4Margin">
                                                Feature Group Information
                                            </h4>
                                            <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate">
                
                                              <a (click)="edit();"  data-toggle="modal" 
                                              data-target="#exampleModalCenter">Update</a>
                                            </span>
                                        </div>
                                    </header>

                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <div class="row">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6 col-12"> Feature Group ID</p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.featureGroupId}}</span>
                                                        </div>
                                                        <div class="article">
                                                            <p class="col-lg-6 col-12">Description </p>
                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.des}}</span>

                                                        </div>


                                                    </div>




                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br>
                                <div class="w3-card-4 classCardView">
                                    <div class="header-tabs">

                                        <h4 class=" common-styling hCard">
                                            Product Feature Group Applications
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                            <div class="card own-account-table borderTable">
                                                <p-table [value]="featureGroupApplication" [paginator]="false" [rows]="rows"   scrollHeight="150px"   scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                    </p-checkbox>
                                                                    Id
                                                                    <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;"> Feature
                                                                    <p-sortIcon field="name">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                <div style="color: white;"> Type</div>
                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                <div style="color: white;"> Feature Category</div>
                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                <div style="color: white;"> Sequence Number</div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                <div style="color: white;"> Action
                                                                </div>
                                                                <div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
                                                        <tr>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.productFeatureId}}</td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.productFeatureDesc }}</td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.ProductFeatureTypeDesc}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.productFeatureCategory}}
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                {{product.sequenceNum}}
                                                            </td>
                                                            <td>
                                                                <div *ngIf="!this.Hidebtn">
                                                                    <svg (click)="create(product.productFeatureId,product.fromDate,product.productFeatureDesc,product.ProductFeatureTypeDesc,product.sequenceNum);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="delete(product.fromDate,product.productFeatureId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }
    
                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br>

                            </div>

                            <div class="divB">
                                <div class="w3-card-4 classCardView">
                                    <div class="header-tabs">

                                        <h4 class=" common-styling hCard">
                                            Quick Apply Product Feature
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="d-flex flex-column allUserCardDetail">
                                            <div class="form-group">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-4 form-group classInputA">
                                                            <label for="featureId" class="text-black">Feature Id</label>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <p-dropdown filter="true" [options]="featureIdArray" [(ngModel)]="createWithFeatureId" [ngModelOptions]="{standalone:true}" placeholder="Feature Id">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="button-toolbar" style="
                                            margin-left: 23%;">
                                                <button class="btn btn-secondary submit-btn" (click)="submitFormWithFeatureId()">Create</button>
                                                <button *ngIf="!this.Hidebtn" class="btn btn-danger cursor-pointer ml-2" (click)="resetFormWithFeatureId()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br>
                                <div class="w3-card-4 classCardView">
                                    <div class="header-tabs">

                                        <h4 class=" common-styling hCard">
                                            Apply Features From Category
                                        </h4>
                                    </div>
                                    <div class="panel-body">
                                        <div class="d-flex flex-column mt-4 allUserCardDetail">
                                            <form>
                                                <div class="row">

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-4 form-group classInput">
                                                                <label for="featureId">Product Feature Category
                                                    ID</label>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <p-dropdown filter="true" [options]="categoriesArray" [(ngModel)]="categoryId" [ngModelOptions]="{standalone:true}" placeholder="Category">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div style="margin-right: 37%;" class="button-toolbar d-flex justify-content-end">
                                                    <button class="btn btn-secondary submit-btn" (click)="getFeatureFromCategory()">Continue</button>
                                                </div>
                                            </form>
                                            <ng-container *ngIf="featureFromCategory.length;else noData">
                                                <div class="allUserCardDetail">
                                                    <table class="table mt-2 borderTable">
                                                        <thead>
                                                            <tr style="background: #0d3769;">
                                                                <th scope="col">
                                                                    <div style="color: white;"> ID</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div style="color: white;">Type</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div style="color: white;">Feature</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div style="color: white;">ID Code</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div style="color: white;">Abbrev</div>
                                                                </th>
                                                                <th scope="col">
                                                                    <div class="form-check">
                                                                        <label class="form-check-label" for="selectAll"><div style="color: white;">Select
                                                                        All</div></label>
                                                                        <input type="checkbox" class="form-check-input" style="margin-left: 9px;" id="selectAll" (click)="selectAll($event)">
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-template ngFor let-feature [ngForOf]="featureFromCategory" let-i="index">
                                                                <tr>
                                                                    <td>{{feature.productFeatureId}}</td>
                                                                    <td>{{feature.productFeatureType}}</td>
                                                                    <td>{{feature.productFeature}}</td>
                                                                    <td>{{feature.idCode}}</td>
                                                                    <td>{{feature.abbrev}}</td>
                                                                    <td>
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" [(ngModel)]="featureArray[i].selected" (change)="inputChecked($event,i)" [id]="'defaultCheck'+i">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ng-container>
                                            <ng-template #noData>
                                                <span class="text-danger">No data</span>
                                            </ng-template>
                                            <div class="button-toolbar mt-2 float-right" *ngIf="featureFromCategory.length">
                                                <button class="btn btn-secondary submit-btn" (click)="submitFeatureFromCategory()">Submit</button>
                                                <button class="btn btn-danger cursor-pointer ml-2" (click)="resetFeatureFormCategory()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br>








                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Feature Group</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                            Update Feature Group</a></li>


                        </ul>
                    </div>

                    <div class="panel panel-default">

                        <div aria-labelledby="headingOne" role="tabpanel">
                            <div class="panel-body">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="color-black container">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputS">
                                                        <label for="description">Description</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control w-100" [(ngModel)]="description" [ngModelOptions]="{standalone:true}" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12 col-12 main-submit-button float-right" style="    margin-left: -64%;">
                                                <button _ngcontent-cta-c128="" type="submit" (click)="submit()" class="btn btn-secondary submit-btn">{{editMode? 'Update' : 'Create'}}</button>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>