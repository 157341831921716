import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { faWeight } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-new-person',
  templateUrl: './create-new-person.component.html',
  styleUrls: ['./create-new-person.component.css']
})
export class CreateNewPersonComponent implements OnInit {

  show=false;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  createNewPerson: FormGroup;

  maritalStatus:any[]=[];
  currencyArray:any[]=[];
  

  gender = [
    {
      label:'Male',
      value:'M'
    },
    {
      label:'Female',
      value:'F'
    },
    {
      label: 'Transgender',
      value: 'T'
    }
  ];

  maritalArray =[
    {
      label:'Yes',
      value:'Y'
    },
    {
      label:'No',
      value:'N'
    }
  ];

  customerArray=[
    {
      label:'Yes' ,
     value:'Y'
    },
    {
      label:'No' ,
     value:'N'
    }
  ];

  statusIdArray = [
    {
      label:'Enabled' ,
     value:'PARTY_ENABLED'
    },
    {
      label:'Disabled' ,
     value:'PARTY_DISABLED'
    }
  ];
  AllResidentStatusEnumIdArray: any[]=[];
  AllEmploymentEnumIdArray: any[]=[];
  birthDate: string;
  deceasedDate: string;
  passportExpire: string;
  

  constructor(readonly router: Router,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService, 
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    ) {
    this.createNewPerson = this._FormBuilder.group({

      partyId: [''],
      saluatation: [''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      personalTitle: [''],
      suffix: [''],
      nickname: [''],
      firstNameLocal: [''],
      middleNameLocal: [''],
      lastNameLocal: [''],
      otherLocal: [''],
      memberId: [''],
      gender:[''],
      birthDate: [''],
      deceasedDate: [''],
      countryCode: [''],
      height: [''],
      weight: [''],
      mothersMaidenName: [''],
      socialSecurity: [''],
      maritalStatusEnumId: [''],
      passportNumber: [''],
      passportExpire: [''],
      totalYears: [''],
      preferredCurrency:[''],
      comments: [''],
      occupation: [''],
      yearsWith: [''],
      monthsWith: [''],
      cardId: [''],
      usCitizen: [''],
      customerArray:[''],
      ethnicity: [''],
      accountNo: [''],
      billingRate: [''],
      oldMaritalStatus:[''],
      description: [''],
      externalId: [''],
      statusId: ['PARTY_ENABLED'],

    });

    this.maritalStatus = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.getMaritalStatus();
    this.getCurrency();
    this.getAllResidentStatusEnumId();
    this.getAllEmploymentEnumId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/partyQuickLink/party-dashboard']);

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  getMaritalStatus(): void {
    this.spinner.show();
    this.accountsService.getMaritalStatus()
      .subscribe(data => {
        this.spinner.hide();
        this.maritalStatus = data.data.map(value => {
          return {
            label: value.description,
            value: value.enumId
          }
        });

      });
  }
  
  
  getAllResidentStatusEnumId() {
    this.spinner.show();
    this.accountsService.getAllResidentStatusEnumId().subscribe(res => {
      const AllResidentStatusEnumId = res.data;
      this.spinner.hide();
      for (const value of AllResidentStatusEnumId) {
        this.AllResidentStatusEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
   
  }
  getAllEmploymentEnumId() {
    this.spinner.show();
    this.accountsService.getAllEmploymentEnumId().subscribe(res => {
      const AllEmploymentEnumId = res.data;
      this.spinner.hide();
      for (const value of AllEmploymentEnumId) {
        this.AllEmploymentEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
   
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this.router.navigate(['/partyQuickLink']);
  }

  onSubmit(): void {
    this.spinner.show();
    const fromActualdate=this.createNewPerson.get('birthDate').value;
    this.birthDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createNewPerson.get('deceasedDate').value;
    this.deceasedDate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const passportExpire=this.createNewPerson.get('passportExpire').value;
    this.passportExpire=this.datePipe.transform(passportExpire,"yyyy-MM-dd hh:mm:ss");
    const requestData = {

        "accountNo": this.createNewPerson.value.accountNo,
        "billingRateLevel": "",
        "birthDate":  this.birthDate,
        "birthDate_i18n": "",
        "cardId": this.createNewPerson.value.cardId ,
        "comments": this.createNewPerson.value.comments ,
        "deceasedDate":  this.deceasedDate,
        "deceasedDate_i18n": "",
        "description": this.createNewPerson.value.description,
        "employmentStatusEnumId": "",
        "ethnicity": this.createNewPerson.value.ethnicity,
        "existingCustomer": this.createNewPerson.value.customerArray,
        "externalId": this.createNewPerson.value.externalId ,
        "firstName": this.createNewPerson.value.firstName ,
        "firstNameLocal": this.createNewPerson.value.firstNameLocal ,
        "gender":  this.createNewPerson.value.gender ,
        "height": this.createNewPerson.value.height ,
        "lastName": this.createNewPerson.value.lastName ,
        "lastNameLocal": this.createNewPerson.value.lastNameLocal ,
        "maritalStatusEnumId": this.createNewPerson.value.maritalStatusEnumId,
        "memberId": this.createNewPerson.value.memberId ,
        "middleName": this.createNewPerson.value.middleName ,
        "middleNameLocal": this.createNewPerson.value.middleNameLocal ,
        "monthsWithEmployer": this.createNewPerson.value.monthsWith ,
        "mothersMaidenName": this.createNewPerson.value.mothersMaiden ,
        "nickname": this.createNewPerson.value.nickname,
        "occupation": this.createNewPerson.value.nickname ,
        "oldMaritalStatus": this.createNewPerson.value.oldMaritalStatus,
        "otherLocal": this.createNewPerson.value.otherLocal ,
        "partyId": this.createNewPerson.value.partyId ,
        "passportExpireDate":this.passportExpire,
        "passportExpireDate_i18n": "",
        "passportNumber": this.createNewPerson.value.passportNumber ,
        "personalTitle": this.createNewPerson.value.personalTitle ,
        "preferredCurrencyUomId": this.createNewPerson.value.preferredCurrency ,
        "residenceStatusEnumId": "" ,
        "salutation": this.createNewPerson.value.saluatation ,
        "socialSecurityNumber": this.createNewPerson.value.socialSecurity ,
        "statusId": this.createNewPerson.value.statusId,
        "suffix": this.createNewPerson.value.suffix ,
        "totalYearsWorkExperience": this.createNewPerson.value.totalYears ,
        "usCitizen": this.createNewPerson.value.usCitizen ,
        "weight": this.createNewPerson.value.weight,
        "yearsWithEmployer": this.createNewPerson.value.yearsWith
    
    }

    this.accountsService.postCreateNewPerson(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/partyQuickLink/find-party']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}    