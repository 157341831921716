import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-create-check-account',
    templateUrl: './create-check-account.component.html',
    styles: [`
                .border-card{
                    border: 1px solid;
                    border-color: #0d3769 !important;
                    /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
                }
                .ml-30{
                    margin-left: 30px;
                }
                
    `]
})
export class CreateCheckAccountComponent implements OnInit {
    partyId: string;
    eftAccountForm: FormGroup;
    editMode: boolean;
    accountData: any;
    contactMechId: string;
    accountTypes = [
        {
            label: 'Checking',
            value: 'Checking'
        },
        {
            label: 'Savings',
            value: 'Savings'
        }];
    paymentMethodTypes: any[];
    paymentMethodId: string;
    paymentMethodTypeId: string;
    paymentMethodValue: any;
    @ViewChild('closebutton') closebutton;
    constructor(
        readonly _ToastrService: ToastrService,private _location: Location,
        readonly _FormBuilder: FormBuilder,
        readonly _CRMAccountService: CRMAccountService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner:NgxSpinnerService,
        readonly  _Router: Router,
        public bsModalRef: BsModalRef
    ) {
        this.partyId = '';
        this.eftAccountForm = this._FormBuilder.group({
            accountNumber: ['', [Validators.required]],
            accountType: ['', [Validators.required]],
            bankName: ['', [Validators.required]],
            companyNameOnAccount: [''],
            contactMechId:['', [Validators.required]],
            description: [''],
            paymentMethodTypeId: ['', [Validators.required]],
            nameOnAccount: ['', [Validators.required]],
            routingNumber: ['', [Validators.required]]
        });
        this.editMode = false;
        this.contactMechId = '';
        this.paymentMethodTypes = [];
        this.paymentMethodId = '';
        this.paymentMethodTypeId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
        this.paymentMethodTypeId = this._ActivatedRoute.snapshot.queryParams.paymentMethodTypeId;
        this.getAccountDetails();
        this.getPaymentMethodTypes();
        if (this.paymentMethodId) {
            this.getPaymentMethodById();
        }
    }
    getPaymentMethodById(): void {
        this.spinner.show();
        this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CHECK_ACCOUNT')
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.paymentMethodValue = data.data.checkAccountData;
                    if (data.data.checkAccountData.postalAddressList[0]) {
                        this.contactMechId = data.data.checkAccountData.postalAddressList[0].contactMech.contactMechId;
                    } else {
                        this.contactMechId = data.data.checkAccountData.currentPostalAddress.contactMechId;
                    }
                   
                    this.eftAccountForm.patchValue({
                        ...data.data.checkAccountData, ...{
                            paymentMethodTypeId: this.paymentMethodTypeId, ...{
                                description: data.data.paymentMethodData.description
                            }
                        }
                    });
                   
                    this.editMode = true;
                }
            });
           
    }
    getPaymentMethodTypes(): void {
        this.spinner.show();
        this._CRMAccountService.getPaymentMethodTypes()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.paymentMethodTypes = data.data.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentMethodTypeId
                        };
                    });
                }
            });
           
    }
    getAccountDetails(): void {
        this.spinner.show();
        this._CRMAccountService.getAccountById(this.partyId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.accountData = data.data;
                }
            });
           
    }
    getAddress(event: any, contactMechId: string): void {
        this.contactMechId = (event.target.checked) ? contactMechId : '';
    }

    submit(): void {
        this.spinner.show();
        if (this.eftAccountForm.valid) {
            this._CRMAccountService.createCheckAccount(this.partyId, {
                ...this.eftAccountForm.value, ...{
                   
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
    
    }
    update(): void {
        this.spinner.show();
        if (this.eftAccountForm.valid) {
            this._CRMAccountService.updateCheckAccount(this.partyId, this.paymentMethodId, {
                ...this.paymentMethodValue, ...this.eftAccountForm.value, ...{
                    contactMechId: this.contactMechId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                        this.editMode = false;
                    }
                });
        }
       
    }
    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.bsModalRef.hide();
      }
    cancelSubmit(): void {
        this.eftAccountForm.reset();
        this._location.back();
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}