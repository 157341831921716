import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeadModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      address1:(data && data.address1) ? data.address1 : null,
      address2:(data && data.address2) ? data.address2 : null,
      areaCode:(data && data.areaCode) ? data.areaCode : null,
      city:(data && data.city) ? data.city : null,
      contactList:(data && data.contactList) ? data.contactList : null,
      country:(data && data.country) ? data.country : null,
     
      countryCode:(data && data.countryCode) ? data.countryCode : null,
      emailId:(data && data.emailId) ? data.emailId : null,
      ext:(data && data.ext) ? data.ext : null,
      firstName:(data && data.firstName) ? data.firstName : null,  
      groupName:(data && data.groupName) ? data.groupName : null,
    
      lastName:(data && data.lastName) ? data.lastName : null,
      leadSource:(data && data.leadSource) ? data.leadSource : null,
      numberOfEmployees:(data && data.numberOfEmployees) ? data.numberOfEmployees : null,
      officeSiteName:(data && data.officeSiteName) ? data.officeSiteName : null,
      phoneNumber:(data && data.phoneNumber) ? data.phoneNumber : null,
      postalCode:(data && data.postalCode) ? data.postalCode : null,
      state:(data && data.state) ? data.state : null,
      suffix:(data && data.suffix) ? data.suffix : null,
      title:(data && data.title) ? data.title : null,
      gender:(data && data.gender) ? data.gender : null,
      comments:(data && data.gender) ? data.gender : null,
      statusId:(data && data.statusId) ? data.statusId : null,
      externalId:(data && data.externalId) ? data.externalId : null
     
    });

    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    comments:new FormControl(data.comments),
    gender:new FormControl(data.gender),
    statusId:new FormControl(data.statusId),
    externalId:new FormControl(data.externalId),
    address1: new FormControl(data.address1,[Validators.required]),
    address2: new FormControl(data.address2),
    areaCode: new FormControl(data.areaCode),
    city: new FormControl(data.city,[Validators.required]),
    contactList: new FormControl(data.contactList),
    country: new FormControl(data.country,[Validators.required]),

    countryCode: new FormControl(data.countryCode,[Validators.required]),
    ext: new FormControl(data.ext),
    firstName: new FormControl(data.firstName,[Validators.required]),
    groupName: new FormControl(data.groupName,[Validators.required]),
    lastName: new FormControl(data.lastName,[Validators.required]),
    leadSource: new FormControl(data.leadSource,[Validators.required]),
    numberOfEmployees: new FormControl(data.numberOfEmployees,[Validators.required]),
    officeSiteName: new FormControl(data.officeSiteName),
    phoneNumber: new FormControl(data.phoneNumber),
    postalCode: new FormControl(data.postalCode,[Validators.required]),
    state: new FormControl(data.state,[Validators.required]),
    suffix: new FormControl(data.suffix),
    title: new FormControl(data.title),
    emailId: new FormControl(data.emailId,[Validators.required]),

   
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
