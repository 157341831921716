import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobInterviewModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        gradeSecuredEnum:(data && data.gradeSecuredEnum) ? data.gradeSecuredEnum : null,
        interviewDate:(data && data.interviewDate) ? data.interviewDate : null,
        interviewResult:(data && data.interviewResult) ? data.interviewResult : null,
        interviewTypeId:(data && data.interviewTypeId) ? data.interviewTypeId : null,
        intervieweePartyId:(data && data.intervieweePartyId) ? data.intervieweePartyId : null,
        interviewerPartyId:(data && data.interviewerPartyId) ? data.interviewerPartyId : null,
        jobRequisitionId:(data && data.jobRequisitionId) ? data.jobRequisitionId : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    gradeSecuredEnum: new FormControl(data.gradeSecuredEnum),
    interviewDate: new FormControl(data.interviewDate),
    interviewResult: new FormControl(data.interviewResult),
    interviewTypeId: new FormControl(data.interviewTypeId),
    intervieweePartyId: new FormControl(data.intervieweePartyId,[Validators.required]),
    interviewerPartyId: new FormControl(data.interviewerPartyId,[Validators.required]),
    jobRequisitionId: new FormControl(data.jobRequisitionId,[Validators.required]),

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
