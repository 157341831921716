import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-routing-task-fixed-asset',
  templateUrl: './create-routing-task-fixed-asset.component.html',
  styleUrls: ['./create-routing-task-fixed-asset.component.css']
})
export class CreateRoutingTaskFixedAssetComponent implements OnInit {
  createFixed:FormGroup;
  activeCategory=2;
  fixedAssetsInitialDataArray: any[]=[];
  routingTaskId: any;
  fixedAssetTypeId: any;
  estimatedQuantity: any;
  estimatedCost: any;
  estimatedDuration: any;
  show: boolean;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
   
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createFixed = this._FormBuilder.group({
        FixedAssetTypeID: [''],
        EstimatedQuantity:[''],
        EstimatedDuration: [''],
        EstimatedCost:['']
      
       
       
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingTaskId = params["routingTaskId"];
      this.fixedAssetTypeId = params["fixedAssetTypeId"];
      this.estimatedQuantity = params["estimatedQuantity"];
      this.estimatedDuration = params["estimatedDuration"];
      this.estimatedCost = params["estimatedCost"]; 
    }); 
    this.fixedAssetsInitialData();
    if(this.fixedAssetTypeId) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.createFixed;
      formValue.patchValue({
        FixedAssetTypeID: this.fixedAssetTypeId,
        EstimatedQuantity:this.estimatedQuantity,
        EstimatedDuration:  this.estimatedDuration,
        EstimatedCost: this.estimatedCost
       
      })
      }, 3000);
    }
    else {
      this.show = false;
    }
  }
  fixedAssetsInitialData() {
    this.spinner.show();
    this.accountsService.fixedAssetsInitialData().subscribe(res => {
      let fixedAssetTypes = res.data.fixedAssetTypes;
      this.spinner.hide();
      for (const value of fixedAssetTypes) {
        this.fixedAssetsInitialDataArray.push({
          label: value.description,
          value: value.fixedAssetTypeId
        })
      }
    })
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":  this.createFixed.value.EstimatedCost,
      "estimatedDuration": this.createFixed.value.EstimatedDuration,
      "estimatedQuantity": this.createFixed.value.EstimatedQuantity,
      "fixedAssetTypeId": this.createFixed.value.FixedAssetTypeID,
    }
    this.productionService.postProdfixedAsset(this.routingTaskId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":  this.createFixed.value.EstimatedCost,
      "estimatedDuration": this.createFixed.value.EstimatedDuration,
      "estimatedQuantity": this.createFixed.value.EstimatedQuantity,
      "fixedAssetTypeId": this.createFixed.value.FixedAssetTypeID,
    }
    this.productionService.updateProdfixedAsset(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-routing-task-summary"], { queryParams: {  routingTaskId: this.routingTaskId } })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  cancelSubmit(){
    this._Router.navigate(['/production/detail-routing-task-summary'],{ queryParams: { routingTaskId :this.routingTaskId}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


