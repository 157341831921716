<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
       
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">{{this.editValue.contactListName}} {{this.contactID}}</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class=" bg-white color-grey w-100">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Summary</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Contact List</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Parties</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Comm Events</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                <a>Import Parties</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                <a>Websites</a>
                            </li>
                           
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div *ngIf="activeCategory==1" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                    <button type="submit" style="margin: 0 !important;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                     data-target="#updateContactpopup">Update</button>
                                            </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Contact List ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.contactListId}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Contact List Name</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.contactListName}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Contact List type ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.contactListTypeId}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Is Public ? </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.isPublic}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Is Single Use ? </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.singleUse}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Contact Mechanism Type ID </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.contactMechTypeId}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Marketing Campaign ID </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.marketingCampaignId}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Owner Party ID </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.ownerPartyId}}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Verify Email From </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.verifyEmailFrom}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Verify Email Screen </p>
                                                                        <span class="color-grey pl-20 font-weight-bold" style="width: 250px; overflow: hidden; text-overflow: ellipsis; ">
                                                                            {{this.editValue.verifyEmailScreen}}&nbsp;
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Verify Email Subject </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.verifyEmailSubject}}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Verify Email Website ID </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.verifyEmailWebSiteId}}
                                                                    
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Opt Out Screen </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.editValue.optOutScreen}}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="activeCategory==2" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Contact List</p>
                                                    <!--                                           <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newContact">Create New Contact List</button> -->
                                                </div>
                                                <form class="w-100" [formGroup]="editContactListForm">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="ContactListID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID" readonly>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List Name
                                                                        <span style="color: red;">*</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="ContactListName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List Type ID
                                                                        
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Is Public ?
                                                                        
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="IsPublic" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Public ?">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Is Single Use ?
                                                                        
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="IsSingleUse" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Single Use ?">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                                        
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="ContactMechanismTypeID" optionlabel="label" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Marketing Campaign ID
                                                                        
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Owner Party ID		
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="OwnerPartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Owner Party ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Verify Email From	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="VerifyEmailFrom" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email From">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Verify Email Screen	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="VerifyEmailScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Screen">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Verify Email Subject	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="VerifyEmailSubject" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Subject">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Verify Email WebSite ID
                                                                        
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="VerifyEmailWebSiteID" optionlabel="label" [options]="VerifyEmailWebsiteIdArray" placeholder="Enter Verify Email WebSite ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Opt Out Screen	
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="OptOutScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Opt Out Screen">
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-10 col-10 main-submit-button">
                                                        <button (click)="creatingContactList()" type="submit" class="btn btn-secondary submit-btn">Save</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="activeCategory==3" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Find Contact List Parties</p>
                                                    <!-- <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newContact">Create New Contact List</button> -->
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" (click)="addEffortEventReminder()" data-target="#editParty">Create New Party</button>
                                                </div>
                                                <form class="w-100">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" [(ngModel)]="partySearch.contactID" name="contactID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Party ID</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="partyIdArray" [(ngModel)]="partySearch.contactTyepID" name="contactTyepID" [ngModelOptions]="{standalone:true}" optionlabel="label" placeholder="Enter Contact List Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="partySearch.fromDate" name="fromDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="partySearch.thruDate" name="thruDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Status</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="partySearch.status" name="status" [ngModelOptions]="{standalone:true}" [options]="StatusList_partiesArray" optionlabel="label" placeholder="Enter Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Preferred Contact Mechanism</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [(ngModel)]="partySearch.contactMech" name="contactMech" [ngModelOptions]="{standalone:true}" [options]="PreferredContactMechnaismArray" optionlabel="label" placeholder="Enter Preferred Contact Mechanism">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Hide Expired
                                                                        </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-checkbox _ngcontent-egj-c329="" (click)="selectAllDepositWith($event)" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                            </div>
                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-10 col-10 main-submit-button">
                                                            <button type="submit" (click)="findContactListParties()" class="btn btn-secondary submit-btn">Find</button>
                                                            <button type="button" (click)="resetContact()" class="btn btn-danger ml-2">Reset</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Search Results</p>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ContactListParties" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Party ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Preferred Contact Mechanism
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Edit Link
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Expire Link
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span></span> {{product.node2.partyId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.node2.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.node2.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.node2.statusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.preferredContacMechnaism}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#editParty" (click)="getEditContactListPartyId(product)">Edit</button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="expireContactListParty(product)">Expire</button>

                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="activeCategory==4" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Find Associated CommEvents</p>
                                                    <!--  <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newContact">Create New Contact List</button> -->
                                                    <button (click)="partyReset()" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#editContactComm">Create New Contact List Comm Events</button>
                                                </div>
                                                <form class="w-100">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact List ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" [(ngModel)]="commSearch.contactID" name="contactID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Communication Event ID</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="CommunicationEventIdDropArray" [(ngModel)]="commSearch.commID" name="commID" [ngModelOptions]="{standalone:true}" optionlabel="label" placeholder="Enter Communication Event ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Status</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="CommEventStatusArray" [(ngModel)]="commSearch.status" name="status" [ngModelOptions]="{standalone:true}" optionlabel="label" placeholder="Enter Status">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="commSearch.fromDate" name="fromDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control" [(ngModel)]="commSearch.thruDate" name="thruDate" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-10 col-10 main-submit-button">
                                                            <button (click)="findContactList_commEvents();" type="submit" class="btn btn-secondary submit-btn">Find</button>
                                                            <button type="button" (click)="resetcommEvents()" class="btn btn-danger ml-2">Reset</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Contact List For contactListId={{this.contactID}}</p>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ContactList_commEvents" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Communication Event ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party From
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party To
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Contact Mechanism Type ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party Role Type ID From (NEW)
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party Role Type ID To (NEW)
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Cust Request ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Start Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Finish Date/Time
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party Comm Event Subject
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span data-toggle="modal" data-target="#editContactComm" (click)="updateCommunication(product)">{{product.communicationEventId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.partyIdFrom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.partyIdTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.statusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.contactMechTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.roleTypeIdFrom}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.roleTypeIdTo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.datetimeStarted|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.subject}}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="activeCategory==5" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Find Import Contact List Parties</p>
                                                    <!--  <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newContact">Create New Contact List</button> -->
                                                </div>
                                                <form class="w-100">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Party ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="partyIdArray" [(ngModel)]="importContact.partyID" name="partyType" [ngModelOptions]="{standalone:true}" optionlabel="label" placeholder="Enter Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Party Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="PartyTypeIdArray" [(ngModel)]="importContact.partyType" name="partyType" [ngModelOptions]="{standalone:true}" optionlabel="label" placeholder="Enter Party Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Role Type</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="allroleArray" [(ngModel)]="importContact.roleType" name="roleType" [ngModelOptions]="{standalone:true}" optionlabel="label" placeholder="Enter Role Type">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact Mech Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" class="form-control" [(ngModel)]="importContact.mechID" name="mechID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact Mech Type ID">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-10 col-10 main-submit-button">
                                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="getFindImportParties()">Find</button>
                                                            <button type="button" class="btn btn-danger  ml-2" (click)="resetImportParties()">Reset</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Search Result</p>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="FindImportParties" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    Party ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    To Name
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Attn Name
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Address 1
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Address 2
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    City
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Abbreviation
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Postal Code
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Country Geo ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Country Code
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Area Code
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Contact Number
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Extension
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Info String
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"   (click)="referToParty(product.jb.partyId)">
                                                                                        {{product.jb.partyId}}
                                                                                    </span> 
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.toName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.attnName}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.address1}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.address2}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.city}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.postalCode}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.countryGeoId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.extension}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.jb.infoString}}
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="activeCategory==6" class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">WebSites View of contactListId {{this.contactID}}</p>
                                                    <!--                         <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newContact">Create New Contact List</button> -->
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#newWeb" (click)="website();">Create Website</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="WebSiteContactListById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    Site Name
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span></span> {{product.finalSiteName}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.node1.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <span  class="account-button"  >
                                                                                    <a (click)="updateEventType(product)"  class="account-button"  data-toggle="modal" data-target="#newWebB">Update</a>&nbsp;
                                                                                    <a (click)="deleteWebSiteContactList(product)"  class="account-button" >Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updateContactpopup" tabindex="-1" role="dialog" aria-labelledby="updateContactpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Contact List</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton9>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Update Contact List</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editContactListForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ContactListID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List ID" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ContactListName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List Type ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Public ?
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsPublic" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Public ?">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Single Use ?
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsSingleUse" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Single Use ?">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ContactMechanismTypeID" optionlabel="label" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Owner Party ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OwnerPartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Owner Party ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email From	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailFrom" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email From">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email Screen	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Screen">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email Subject	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailSubject" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Subject">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email WebSite ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="VerifyEmailWebSiteID" optionlabel="label" [options]="VerifyEmailWebsiteIdArray" placeholder="Enter Verify Email WebSite ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Opt Out Screen	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OptOutScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Opt Out Screen">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="creatingContactList()" type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newContact" tabindex="-1" role="dialog" aria-labelledby="newContactTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Contact List</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Add Contact List</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateNewContactListForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ContactListName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact List Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List Type ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ContactListTypeID" optionlabel="label" [options]="ContactListTypeIdArray" placeholder="Enter Contact List Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Public ?
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsPublic" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Public ?">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Single Use ?
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsSingleUse" optionlabel="label" [options]="yesNOArray" placeholder="Enter Is Single Use ?">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact Mechanism Type ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ContactMechanismTypeID" optionlabel="label" [options]="ContactMechanismTypeIDArray" placeholder="Enter Contact Mechanism Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignArray" placeholder="Enter Marketing Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Owner Party ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OwnerPartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Owner Party ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email From	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailFrom" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email From">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email Screen	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Screen">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email Subject	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="VerifyEmailSubject" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Verify Email Subject">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Verify Email WebSite ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="VerifyEmailWebSiteID" optionlabel="label" [options]="VerifyEmailWebsiteIdArray" placeholder="Enter Verify Email WebSite ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Opt Out Screen	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="OptOutScreen" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Opt Out Screen">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newWeb" tabindex="-1" role="dialog" aria-labelledby="newWebTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Website</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Create Website</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWebsite">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Contact List ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{this.contactID}}
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Contact List Name</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{this.editValue.contactListName}}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Website ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="webID" [options]="VerifyEmailWebsiteIdArray" optionlabel="label" placeholder="Enter Website ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 col-5 main-submit-button" style="margin-left: 37%;" >
                                                <button (click)="createWebSiteContactList()" type="submit" class="btn btn-secondary submit-btn">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="newWebB" tabindex="-1" role="dialog" aria-labelledby="newWebBTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Website</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100 w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Update Website</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createWebsiteEdit">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Site Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{this.siteNameEdit}}
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{this.fromDateEdit}}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput1">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" formControlName="thruDate">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 col-5 main-submit-button" style="margin-left: 37%;">
                                                <button (click)="updateWebSiteContactList()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editParty" tabindex="-1" role="dialog" aria-labelledby="editPartyTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Contact List Party</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonBB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Edit Contact List Party</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" formControlName="partyID" placeholder="Enter Contact List Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fromDate" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="thruDate" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="StatusList_partiesArray" formControlName="status" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Opt-in Verify Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="verifyCode" placeholder="Enter Opt-in Verify Code">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Preferred Contact Mechanism</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="PreferredContactMechnaismArray" optionlabel="label" formControlName="contactMech" placeholder="Enter Preferred Contact Mechanism">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 col-10 main-submit-button">
                                                <button *ngIf="!this.show" (click)="createContactListParty()" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                <button *ngIf="this.show" (click)="updateContactListParty()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="editContactComm" tabindex="-1" role="dialog" aria-labelledby="editContactCommTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Contact List Communication Event</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonC>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Edit Contact List Communication Event</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createComm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput" *ngIf="this.showComm">
                                                        <label for="exampleInputEmail1">Communication Event Id</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group" *ngIf="this.showComm">
                                                        {{this.CommunicationEventId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party From</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" formControlName="partyFrom" placeholder="Enter Party From">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="StatusList_partiesArray" formControlName="status" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact Mechanism Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ContactMechanismTypeIDDataArray" optionlabel="label" formControlName="contactMechID" placeholder="Enter Contact Mechanism Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Contact Mech</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" optionlabel="label" formControlName="contactMechFrom" placeholder="Enter From Contact Mech">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact List ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="contactListID" placeholder="Enter Contact List ID">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="startDate" placeholder="Enter Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Finish Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="finishDate" placeholder="Enter Finish Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Subject
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="subject" placeholder="Enter Subject">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Content Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="ContentMimeTypeIdArray" optionlabel="label" formControlName="mimeID" placeholder="Enter Content Mime Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Content -- File transfer
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fileTransfer" placeholder="Enter Content File transfer">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Note
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="note" placeholder="Enter Note">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 col-10 main-submit-button">
                                                <button *ngIf="!this.showComm" (click)="createContactListCommEvent()" type="submit" class="btn btn-secondary submit-btn">Save</button>
                                                <button *ngIf="this.showComm" (click)="updateContactListCommEvent()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>