import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PayablePaymentService } from './payables-payment.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-payables-payment',
  templateUrl: './payables-payment.component.html',
  styleUrls: ['./payables-payment.component.css']
})
export class PayablesPaymentComponent implements OnInit {
  total=0;
  @ViewChild('closebutton') closebutton;
  rowNumber=0;
  isShown = false; 
  pageSize = 10;
  activeCategory=2;
  rows = 50;
  pageNo = 1;
  faEdit = faEdit;
  opportunityIdArray: any = [
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  paymentTypeList: any[];
  statusList: any[];
  paymentList: any[];
  partyIds: any[];
  searchPaymentForm: FormGroup;
  agreementType: string;

  array = [
    {
      label: 'Your Company Name',
      value: 'Company'
  },
  {
      label: 'Widget Business Unit',
      value: 'widget_div'
  },
  ];

  payablePaymentForm: FormGroup;
  editMode: boolean;
  paymentId: string;
  paymentMethods: any[];
  GIAccounts: any[];
  currencyUomID: any[];
  paymentValue: any;
  constructor(
    readonly _PayablePaymentService: PayablePaymentService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly  _Router: Router,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
  ) {
    this.paymentTypeList = [];
    this.statusList = [];
    this.paymentList = [];
    this.partyIds = [];
    this.searchPaymentForm = this._FormBuilder.group({
      amount: [''],
      comments: [''],
      commentsSearchType: ['Contains'],
      fromPartyId: [''],
      paymentId: [''],
      paymentIdSearchType: ['Contains'],
      paymentType: [''],
      status: [''],
      toPartyId: ['']
    });
    this.partyIds = [];
    this.paymentTypeList = [];
    this.payablePaymentForm = this._FormBuilder.group({
        amount: ['', [Validators.required]],
        comments: [''],
        overrideGlAccountId: [''],
        partyIdFrom: ['Company'],
        partyIdTo: ['', [Validators.required]],
        paymentMethodId: [''],
        paymentRefNum: [''],
        paymentTypeId: [''],
        actualCurrencyAmount: [''],
        actualCurrencyUomId: [''],
        currencyUomId: [''],
        effectiveDate: [''],
        finAccountTransId: [''],
        statusId: ['']
    });
    this.paymentMethods = [];
    this.GIAccounts = [];
    this.paymentId = '';
    this.editMode = false;
    this.currencyUomID = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getPaymentType();
    this.getStatus();
    this.getAllPaymentList();
    this.getPartyIds();
   
    this.getPaymentTypeData();
    this.getGIAccountsAndPaymentMethodIds();
    this.getCurrency();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
        .then(data => {
            this.spinner.hide();
            this.currencyUomID = data.data.currencyList.map(value => {
                return {
                    label: value.description,
                    value: value.uomId
                };
            });
        });
       
}
getGIAccountsAndPaymentMethodIds(): void {
    this.spinner.show();
    this._PayablePaymentService.getGIAccountAndPaymentMethod()
        .then(data => {
            if (data) {
                this.spinner.hide();
                this.paymentMethods = data.data.paymentMethodId.map(value => {
                    return {
                        label: value.description,
                        value: value.paymentMethodId
                    };
                });
                this.GIAccounts = data.data.glAccounts.map(value => {
                    return {
                        label: value.glAccountId,
                        value: value.glAccountId
                    };
                });
            }
        });
       
}

getPaymentTypeData(): void {
    this.spinner.show();
    this._PayablePaymentService.getPaymentType()
        .then(data => {
            if (data) {
                this.spinner.hide();
                this.paymentTypeList = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.paymentTypeId
                    };
                });
            }
        });
       
}


submit(): void {
    this.spinner.show();
    if (this.editMode) {
       
    } else {
        if (this.payablePaymentForm.valid) {
            const {
                amount, comments,
                overrideGlAccountId, partyIdFrom,
                partyIdTo, paymentMethodId,
                paymentRefNum, paymentTypeId
            } = this.payablePaymentForm.value;
            this._PayablePaymentService.createOutgoingPayment({
                amount, comments,
                overrideGlAccountId, partyIdFrom,
                partyIdTo, paymentMethodId,
                paymentRefNum, paymentTypeId
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.payablePaymentForm.reset();
                        this.closebutton.nativeElement.click();
                        this.getAllPaymentList();
                    }
                });

        }
    }
   
}
  main(){
    this._Router.navigate(["/financial/main-payable"])
  }
  enterBills(){
    this._Router.navigate(["/financial/payable/invoices"])
  }
  payableMakePayment(){
    this._Router.navigate(["/financial/payable/payment-payables"])
  }
  payableMakeGroupPayment(){
    this._Router.navigate(["/financial/payable/payment-group"])
  }
  salesInvoice(){
    this._Router.navigate(["/financial/receiveable/invoices"])
  }
  receivePayment(){
    this._Router.navigate(["/financial/receiveable/payments"])
  }
  receiveGroupPayments(){
    this._Router.navigate(["/financial/receiveable/payment-group"])
  }
  invoiceType(){
    this.isShown = !this.isShown;
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
      this.spinner.hide();
  }
  getPaymentType(): void {
    this.spinner.show();
    this._PayablePaymentService.getPaymentType()
      .then(data => {
        if (data) {
          this.paymentTypeList = data.data.paymentType.map(value => {
            return {
              label: value.description,
              value: value.paymentTypeId
            };
          });
        }
      });
      this.spinner.hide();
  }
  getStatus(): void {
    this.spinner.show();
    this._PayablePaymentService.getStatus()
      .then(data => {
        if (data) {
          this.statusList = data.data.status.map(value => {
            return {
              label: value.description,
              value: value.statusId
            };
          });
        }
      });
      this.spinner.hide();
  }
  // resetForm(){
  //   this.searchPaymentForm.reset();
  // }
  resetForm(){
    this.searchPaymentForm.value.amount='';
    this.searchPaymentForm.value.comments='';
    this.searchPaymentForm.value.commentsSearchType='Contains';
    this.searchPaymentForm.value.fromPartyId='';
    this.searchPaymentForm.value.paymentId='';
    this.searchPaymentForm.value.paymentIdSearchType='Contains';
    this.searchPaymentForm.value.paymentType='';
    this.searchPaymentForm.value.status='';
    this.searchPaymentForm.value.toPartyId='';

    // this.searchPaymentForm.reset();
}
  reset(){

    this.searchPaymentForm.controls['paymentId'].reset();
    this.searchPaymentForm.controls['comments'].reset();
    this.searchPaymentForm.controls['paymentType'].reset();
    this.searchPaymentForm.controls['fromPartyId'].reset();
    this.searchPaymentForm.controls['toPartyId'].reset();
    this.searchPaymentForm.controls['amount'].reset();
    this.searchPaymentForm.controls['status'].reset();

    this.getAllPaymentList();
  }

  getAllPaymentList(): void {
    this.spinner.show();
    this._PayablePaymentService.getPaymentList({ pageNo: 1, pageSize: 200 }, this.searchPaymentForm.value).then(data => {
      if (data.success) {
        this.paymentList = data.data;
        this.spinner.hide();
      }
    });
  
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

