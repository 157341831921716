<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-3">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Configure Work Order Details Screen</span>

                    </div>                    

                    
                    <div class="col-lg-12">
                        <div class="row mt-3" style="margin-top: -7px !important;margin-left: 0px !important; ">
                            <p>Hide any fields that you do not want to display on the device's Work Order details 
                                screen.
                            </p>
                        </div>

                    </div>

                    <div class="col-lg-12">
                        <div class="row mt-2"  style="margin-left:0px !important">
                            <p>
                                Recorder the fields with up and down icons.
                            </p>

                        </div>

                    </div>

                    <div class="col-lg-12">
                        <div class="row " style="margin-bottom: -1% !important;margin-left: -1% !important; margin-top: 1% !important; ">
                            <a class="btn btn-link" data-target="#addNewWorkDetailsPopup" data-toggle="modal" 
                            style=" font-size: 14px!important; font-weight: bold;">Add a Work Order Details </a>
                        </div>
                        
                    </div>

            
                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                    <label style="background-color:rgb(206, 214, 218);color: black !important; border: 1px solid lightcyan !important;
                                    margin: 0px !important ; padding: 3px !important; font-size: 15px ; font-weight: bold;
                                    padding-left: 7px !important;">Work Order Details  </label>
                                    <p-table [value]="FsmPrimaryWorkOrderDetailsMSAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background:rgb(206, 214, 218);">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        isHide 
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Available Fields
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Panel
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.isHide}}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    {{product.availableFields}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    {{product.panel}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">

                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                      data-toggle="modal" data-target="#addNewWorkDetailsPopup" (click)="updateFsmPrimaryWorkOrderDetailsMSPatch(product)">Update</small>
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                         pointer; " (click)="deleteFsmPrimaryWorkOrderDetailsMS(product)">Delete</small>

                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        <div class="modal fade" id="addNewWorkDetailsPopup" tabindex="-1" role="dialog" aria-labelledby="addNewWorkDetailsPopup" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content cssModelLength">
                    <div class="modal-header">
                        <span *ngIf="this.show" class="color-black pl-1">Add New Work Details</span>
                        <span *ngIf="!this.show" class="color-black pl-1">Update New Work Details</span>

                        <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" #closebutton>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body d-flex">
                        <div class=" bg-black color-grey w-100">
                            <div class="w3-card-4 classCardPopUp">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                        <li *ngIf="this.show">
                                            <a>Add New Work Details</a>
                                        </li>
                                        <li *ngIf="!this.show">
                                            <a>Update New Work Details</a>
                                        </li>
                                    </ul>
                                    
                                </div>
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="addNewWorkDetailsForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">isHide</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="rank" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="isHide">
                                                               
                                                            </div>

                                                            <div class="col-lg-3 form-group rightFormA">
                                                                <label for="exampleInputEmail1">Available Fields</label>
                                                            </div>

                                                            <div class="col-lg-2">
                                                                <input type="email"formControlName="availableFields" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="Enter Available Fields">
                                                               
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group rightForm">
                                                                <label for="exampleInputEmail1">Pannel</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"formControlName="pannel" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" placeholder="Enter Pannel">
                                                               
                                                            </div>

                                                            
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                        <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createFsmPrimaryWorkOrderDetailsMS()">Add</button>
                                                        <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateFsmPrimaryWorkOrderDetailsMS()">Update</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!-- <ngx-spinner></ngx-spinner> -->




