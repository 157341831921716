<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Supplier </span>
                <span class="color-black pl-1"> > New</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Supplier Details
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid"> 
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Party ID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Supplier Name</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Tax Id</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Requires 1099?</label>
                                            <div class="">
                                                <p-dropdown filter="true"
                                                    optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Incorporated?</label>
                                            <div class="">
                                                <p-dropdown filter="true"
                                                    optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 background-grey text-white mb-2" data-toggle="collapse" href="#collapseone">
                                   <span class="">
                                    Contact Information
                                   </span>
                                   <span class="right-arrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.541" height="9.27" viewBox="0 0 18.541 9.27"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(0 9.27) rotate(-90)"><g transform="translate(0 0)"><path class="a" d="M9.163,9.537l-8.5,8.884A.386.386,0,0,1,0,18.154V16.609a.386.386,0,0,1,.106-.266l6.7-7.073L.106,2.2A.386.386,0,0,1,0,1.931V.386A.386.386,0,0,1,.665.119L9.163,9A.387.387,0,0,1,9.163,9.537Z"/></g></g></svg>
                                   </span>
                                    
                                </div>
                                <div class="collapse show" id="collapseone">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Phone Number</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Fax number</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">E-Mail Address</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Web URL</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 background-grey text-white mb-2"  data-toggle="collapse" href="#collapstwo">
                                   <span class="">
                                    Address
                                   </span>
                                  <span class="right-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.541" height="9.27" viewBox="0 0 18.541 9.27"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(0 9.27) rotate(-90)"><g transform="translate(0 0)"><path class="a" d="M9.163,9.537l-8.5,8.884A.386.386,0,0,1,0,18.154V16.609a.386.386,0,0,1,.106-.266l6.7-7.073L.106,2.2A.386.386,0,0,1,0,1.931V.386A.386.386,0,0,1,.665.119L9.163,9A.387.387,0,0,1,9.163,9.537Z"/></g></g></svg>
                                       </span>
                                </div>
                                <div class="collapse" id="collapstwo">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">To Name</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Attention Name</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Address Line 1</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Address Line 2</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">City</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">State/Province</label>
                                                <div class="">
                                                    <p-dropdown filter="true"
                                                        optionLabel="label">
                                                        <ng-template let-item pTemplate="selectedItem">
                                                            <span class="label-info"
                                                                style="margin-left: .5em">{{item.label}}</span>
                                                        </ng-template>
                                                        <ng-template let-car pTemplate="item">
                                                            <div class="ui-helper-clearfix"
                                                                style="position: relative;height: 25px;">
                                                                <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">City</label>
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Country</label>
                                                <div class="">
                                                    <p-dropdown filter="true"
                                                        optionLabel="label">
                                                        <ng-template let-item pTemplate="selectedItem">
                                                            <span class="label-info"
                                                                style="margin-left: .5em">{{item.label}}</span>
                                                        </ng-template>
                                                        <ng-template let-car pTemplate="item">
                                                            <div class="ui-helper-clearfix"
                                                                style="position: relative;height: 25px;">
                                                                <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
          
            </div>
            <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
            </div>
        </div>
    </div>
</div>