<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Invoice Item Type : [{{ this.invoiceItemTypeId}}]</span> <span></span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>


                <div>
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">



                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select " style="display: block!important;">

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="w3-card-4 classCard" style="width: 100%;">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling Hleft">
                                                                        Invoice Item Type Information
                                                                    </h4>
                                                                    <span class="edit-buttons  hCreate">
                               
                                          <a  data-toggle="modal"
                                          data-target="#exampleModalCenter" (click)="edit()">Update</a>
                                        </span>
                                                                </div>
                                                            </header>

                                                            <div class="w3-container">
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="color-black container">
                                                                            <div class="row">
                                                                                <div class="article-container">
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Invoice Item Type</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.invoiceItemTypeId}}</span>
                                                                                    </div>
                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Description</p>
                                                                                        <span style="display: contents;" class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>

                                                                                    </div>



                                                                                    <div class="article">
                                                                                        <p class="col-lg-6 col-12">Default Gl Account ID</p>
                                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.defaultGlAccountId}}</span>
                                                                                    </div>







                                                                                </div>




                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>



                                                </div>
                                            </div>



                                        </div>

                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Invoice Method Type</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                            Update Invoice Method Type</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateInvoice">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Gl Account ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Default Gl Account ID" filter="true" formControlName="defaultGlAccountId" [options]="parentGlAccountArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                   
                                                        Invoice Item Type</label>
                                                    </div>


                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter   Invoice Item Type" formControlName="invoiceItemTypeId" readonly>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                   
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description" readonly>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 82%;">

                                        <button type="submit" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>