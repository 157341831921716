import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forums-page',
  templateUrl: './forums-page.component.html',
  styleUrls: ['./forums-page.component.css']
})
export class ForumsPageComponent implements OnInit {
  activeCategory=2;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addForumForm: FormGroup;
  FindForums: any;
  contentId: any;
  forumGroupId: any;
  updateForumForm: FormGroup;
  show: boolean;
  updateForumGroupPat: any;
  updateForumPat: any;
  fromDateCate: string;
  throughDateCate: string;
  product: any;
  fromDateCatePatch: string;
  throughDateCatePatch: string;
  ownerContentId: any;
  caContentId: any;
  caContentAssocTypeId: any;
  contentTypeId: any;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  )
  {
    this.addForumForm=this._FormBuilder.group({
      forumName:"",
      forumDescription:"",
      fromDateTime:"",
  })

  this.updateForumForm=this._FormBuilder.group({
    forumName:"",
    forumDescription:"",
    throughDateTime:"",
  })

}

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.forumGroupId = params["forumGroupId"];
      this.contentTypeId = params["contentTypeId"];

    });
    this.getFindForums();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }

  getFindForums() {
    this.spinner.show();
    this.accountsService.getFindForums(this.forumGroupId).subscribe(res => {
      this.FindForums = res.data;
      this.spinner.hide();
    })
  }

  createForum(): void {
    this.spinner.show();
    const requestData ={
      "caContentAssocTypeId":"SUBSITE",
      "caContentId":this.forumGroupId,
      "caFromDate":this.datePipe.transform(this.addForumForm.value.fromDateTime, "yyyy-MM-dd hh:mm:ss"),
      "contentName":this.addForumForm.value.forumName,
      "contentTypeId":this.contentTypeId,
      "description":this.addForumForm.value.forumDescription,
      "forumGroupId":this.forumGroupId,
      "ownerContentId":this.forumGroupId,
    }
     this.accountsService.createForum(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getFindForums();
        this.closebutton.nativeElement.click();
        this.addForumForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  updateForum(): void {
    this.spinner.show();
    const requestData = {
      "caContentAssocTypeId": this.updateForumPat.caContentAssocTypeId,
      "caContentId": this.updateForumPat.caContentId,
      "caContentIdTo":this.updateForumPat.caContentIdTo,
      "caFromDate":this.updateForumPat.fromDateTime,
      "contentId": this.updateForumPat.contentId,
      "contentName": this.updateForumForm.value.forumName,
      "contentTypeId": this.updateForumPat.contentTypeId,
      "deleteButton":'' ,
      "description":this.updateForumForm.value.forumDescription,
      "forumGroupId":this.forumGroupId,
      "nbrOfMessages":this.updateForumPat.msgCount ,
      "thruDate":this.datePipe.transform(this.updateForumForm.value.throughDateTime, "yyyy-MM-dd hh:mm:ss"), 
    }
    this.accountsService.updateForum(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getFindForums();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateForumPatch(product) {
    this.show=false;
    this.updateForumPat=product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateForumForm;
        formValue.patchValue({
          forumName: this.updateForumPat.dataContentSecond.contentName,
          forumDescription:this.updateForumPat.dataContentSecond.description,
        })
      }, 2000);
      this.spinner.hide();
  }

  // deleteForumGroupPurpose(product) {
  //     Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You will not be able to recover this data.!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'No, keep it'
  //   }).then((result) => {
  //     if (result.value) {
  //       let req = {
  //        "contentPurposeTypeId": product.partyId,
  //         "sequenceNum": product.roleTypeId,
  //       }
  //       this.accountsService.deleteForumGroupPurpose(req).subscribe((res: any) => {
  //         if (res.success) {
  //           this.getFindForums();
  //         }
  //         else {
  //           this.toastr.error("Error");
  //         }
  //       })
  //       Swal.fire(
  //         'Deleted!',
  //         'Deleted Successfully.',
  //         'success'
  //       )
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Your data is safe :)',
  //         'error'
  //       )
  //     }
  //   })
  // }


 messageDetailpage(contentId,id,ownerContentId,caContentAssocTypeId,contentName){
    this._Router.navigate(['/content/forum/msg-detail-page'], { queryParams: {forumId:contentId,forumGroupId: id,ownerContentId:ownerContentId,caContentAssocTypeId:caContentAssocTypeId,contentName : contentName } });
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

openForumGroup()
  {
    this._Router.navigate(['/content/forum']);

  }
  openForums()
  {
    this._Router.navigate(['/content/forum/forums'], { queryParams: { forumGroupId: this.forumGroupId } });

  }
  openPurposes()
  {
    this._Router.navigate(['/content/forum/forum-purposes'], { queryParams: { forumGroupId: this.forumGroupId } });

  }
  openRoles()
  {
    this._Router.navigate(['/content/forum/forum-roles'], { queryParams: { forumGroupId: this.forumGroupId } });

  }

ngOnDestroy(): void {
  this.spinner.hide();
    }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}

}
