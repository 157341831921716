import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportToCsv } from 'export-to-csv';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-budget-list',
  templateUrl: './budget-list.component.html',
  styleUrls: ['./budget-list.component.css']
})
export class BudgetListComponent implements OnInit {
  total = 0;
  createbudget:FormGroup;
  activeCategory=2;
  @ViewChild('closebutton') closebutton;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  varianceReasonId: any;
  varianceReasonIdArray: any[]=[];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  id: string;
  BudgetType: any;
  BudgetTypeArray: any[]=[];
  timePerod: any;
  timePerodArray: any[]=[];
  budgetId: any;
 
  opportunityIdArray: any = [
      {
          label: 'Equals',
          value: 'Equals'
      },
      {
          label: 'Contains',
          value: 'Contains'
      },
      {
          label: 'Is Empty',
          value: 'Is Empty'
      },
      {
          label: 'Begins With',
          value: 'Begins With'
      },
  ];
  budgetForm: FormGroup;
  billingAccounts=[
  {
    "product":"product"
  }];

  agreementType: string;

  budgetList: any;
 
  constructor( readonly accountsService: AccountsService,
   
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly  _Router: Router,
   
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService
  ) { 
    this.createbudget = this._FormBuilder.group({
      budgetId: [''],
      customTime:[''],
      comment:['']
     
      
    });
      this.budgetForm = this._FormBuilder.group({
        budgetId:  [''],
        budgetIdSearchType: ['Equals'],
        budgetTypeId:  [''],
        customTimePeriodIdSearchType: ['Equals'],
        customTimePeriodId: [''],
        comments:  [''],
        commentsSearchType: ['Equals'],
       
  
    });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getBudgetType();
    this.getBudgetList();
    this.getCustomTimePeriodListDetail();
    this.getBudgetType();
    this.getCustomTimePeriodListDetail();
  }
 
 
 

  onSubmit(): void {

    const requestData = {
      "budgetId": "",
      "budgetTypeId": this.createbudget.value.budgetId,
      "comments": this.createbudget.value.comment,
      "customTimePeriodId": this.createbudget.value.customTime,
  
           
    }

    this.accountsService.postBudget(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getBudgetList();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  getBudgetType() {
    this.spinner.show();
    this.accountsService.getBudgetType().subscribe((res: any) => {
      this.BudgetType = res.data;
      this.spinner.hide();
      for (const value of this.BudgetType) {
        this.BudgetTypeArray.push({
          label: value.description,
          value: value.budgetTypeId
        })
      }
    })
  
  }
  getCustomTimePeriodListDetail() {
    this.spinner.show();
   this.id=""
    this.accountsService.getCustomTimePeriodListDetail(this.id).subscribe((res: any) => {

      this.timePerod = res.data;
      this.spinner.hide();
      for (const value of this.timePerod) {
        this.timePerodArray.push({
          label:  value.customTimePeriodId,
          value: value.customTimePeriodId
        })
      }


    })
   

  }
  updatebudget(id){
    this.router.navigate(['/financial/create-budget'],{ queryParams: { budgetId:id}});
  }
  detailsumm(id){
    this.router.navigate(['/financial/detail-budget-summary'],{ queryParams: { budgetId:id}});
  }
  reset(){
    this.budgetForm.reset();
    this.getBudgetList();
  }
  advanceSearch(){
   
    this.spinner.show();
    const req={
      "budgetId": this.budgetForm.value.budgetId,
      "budgetIdSearchType": this.budgetForm.value.budgetIdSearchType,
      "budgetTypeId":this.budgetForm.value.budgetTypeId,
      "comments":this.budgetForm.value.comments,
      "commentsSearchType": this.budgetForm.value.commentsSearchType,
      "customTimePeriodId": this.budgetForm.value.customTimePeriodId,
      "customTimePeriodIdSearchType": this.budgetForm.value.customTimePeriodIdSearchType,
  
    }
    this.accountsService.getBudgetList(this.finSize,req).subscribe((res: any) => {
      this.budgetList = res.data;
      this.spinner.hide();
    })
  }
  getBudgetList() {
    this.spinner.show();
    const req={
      "budgetId": "",
      "budgetIdSearchType": "Contains",
      "budgetTypeId": "",
      "comments": "",
      "commentsSearchType": "Contains",
      "customTimePeriodId": "",
      "customTimePeriodIdSearchType": "Contains"
    
    }
    this.accountsService.getBudgetList(this.finSize,req).subscribe((res: any) => {
      this.budgetList = res.data;
      this.spinner.hide();
    })
  
  }

  customSort(event: SortEvent) {
   
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
