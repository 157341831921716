import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
	
@Component({
    selector: 'app-create-credit-card',
    templateUrl: './create-credit-card.component.html',
    styles: [`
    .border-card{
        border: 1px solid;
        border-color: #0d3769 !important;
        /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
    }
`]
})
export class CreateCreditCardComponent implements OnInit {
    accountData: any;
    partyId: string;
    editMode: boolean;
    creditCardForm: FormGroup;
    cardTypes: any[];
    prefixArray = [
        {
            label: 'Mr.',
            value: 'Mr.'
        },
        {
            label: 'Mrs.',
            value: 'Mrs.'
        },
        {
            label: 'Ms.',
            value: 'Ms.'
        },
        {
            label: 'Dr.',
            value: 'Dr.'
        }];
    suffixArray = [
        {
            label: 'Jr.',
            value: 'Jr.'
        },
        {
            label: 'Sr.',
            value: 'Sr.'
        },
        {
            label: 'I',
            value: 'I'
        },
        {
            label: 'II',
            value: 'II'
        },
        {
            label: 'III',
            value: 'III'
        },
        {
            label: 'IV',
            value: 'IV'
        },
        {
            label: 'V',
            value: 'V'
        },
    ];
    expirationMonth = [
        {
            label: '1 (Jan)',
            value: '1'
        },
        {
            label: '2 (Feb)',
            value: '2'
        },
        {
            label: '3 (Mar)',
            value: '3'
        },
        {
            label: '4 (Apr)',
            value: '4'
        },
        {
            label: '5 (May)',
            value: '5'
        },
        {
            label: '6 (Jun)',
            value: '6'
        },
        {
            label: '7 (Jul)',
            value: '7'
        },
        {
            label: '8 (Aug)',
            value: '8'
        },
        {
            label: '9 (Sep)',
            value: '9'
        },
        {
            label: '10 (Oct)',
            value: '10'
        },
        {
            label: '11 (Nov)',
            value: '11'
        },
        {
            label: '12 (Dec)',
            value: '12'
        }];
    expirationYear = [


        {
            label: '2020',
            value: '2020'
        },
        {
            label: '2021',
            value: '2021'
        },
        {
            label: '2022',
            value: '2022'
        },
        {
            label: '2023',
            value: '2023'
        },

        {
            label: '2024',
            value: '2024'
        },
        {
            label: '2025',
            value: '2025'
        },
        {
            label: '2026',
            value: '2026'
        },
        {
            label: '2027',
            value: '2027'
        },
        {
            label: '2028',
            value: '2028'
        },
        {
            label: '2029',
            value: '2029'
        },
        {
            label: '2030',
            value: '2030'
        }];
    contactMechId: string;
    paymentMethodId: string;
    creditCardValue: any;
    @Output() creditModalClosed: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('closebutton') closebutton;
	
    constructor(
        readonly _CRMAccountService: CRMAccountService,
        readonly  _Router: Router,private _location: Location,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _FormBuilder: FormBuilder,
        readonly spinner:NgxSpinnerService,
        readonly _ToastrService: ToastrService,
        public bsModalRef: BsModalRef
    ) {
        this.partyId = '';
        this.editMode = false;
        this.creditCardForm = this._FormBuilder.group({
            cardNumber: ['', [Validators.required]],
            cardType: ['', [Validators.required]],
            companyNameOnCard: [''],
            contactMechId: [''],
            description: [''],
            expMonth: ['', [Validators.required]],
            expYear: ['', [Validators.required]],
            firstNameOnCard: ['', [Validators.required]],
            lastNameOnCard: ['', [Validators.required]],
            middleNameOnCard: ['', [Validators.required]],
            suffixOnCard: [''],
            titleOnCard: ['']
        });
        this.cardTypes = [];
        this.contactMechId = '';
        this.paymentMethodId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
        if (this.paymentMethodId) {
            this.spinner.show();
            this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CREDIT_CARD')
                .then(data => {
                    if (data.success) {
                        this.spinner.hide();
                        this.creditCardValue = data.data;
                        this.contactMechId = data.data.creditCardData.currentPostalAddress.contactMechId;
                        this.creditCardForm.patchValue({
                            ...data.data.creditCardData, ...{
                                description: data.data.paymentMethodData.description
                            }
                        });
                        this.editMode = true;
                       
                    }
                });
                
        }
        this.getCardTypes();
        this.getAccountDetails();
    }
    getAccountDetails(): void {
        this.spinner.show();
        this._CRMAccountService.getAccountById(this.partyId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.accountData = data.data;
                }
            });
           
    }
    getCardTypes(): void {
        this.spinner.show();
        this._CRMAccountService.getCreditCardTypes()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.cardTypes = data.data.map(value => {
                        return {
                            label: value.enumCode,
                            value: value.enumId
                        };
                    });
                }
            });
          
    }

    getAddress(event: any, contactMechId: string): void {
        this.contactMechId = (event.target.checked) ? contactMechId : '';
    }
    submit(): void {
        this.spinner.show();
        if (this.creditCardForm.valid) {
            this._CRMAccountService.createCreditCard(this.partyId, {
                ...this.creditCardForm.value, ...{
                    contactMechId: this.contactMechId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
      
    }
    update(): void {
        this.spinner.show();
        if (this.creditCardForm.valid) {
            this._CRMAccountService.updateCreditCard(this.partyId, this.paymentMethodId, {
                ...this.creditCardValue.creditCardData, ...this.creditCardForm.value, ...{
                    contactMechId: this.contactMechId,
                    suffixOnCard: 'I'
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
       
    }

    cancelSubmit(): void {
        this.creditCardForm.reset();
        this._location.back();
    }

    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.creditModalClosed.emit();
      }

    ngOnDestroy(): void {
        this.spinner.hide();
          }
}