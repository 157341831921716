<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Catalog</span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="customerCategoryPopup==1?'active':''" ><a>
                                Product Store</a></li>
                            <li [ngClass]="customerCategoryPopup==2?'active':''" ><a>
                                Inventory</a></li>
                            <li [ngClass]="customerCategoryPopup==3?'active':''"><a>
                                Shopping Cart</a></li>
                            <li [ngClass]="customerCategoryPopup==4?'active':''" ><a>
                                Shipping</a></li>
            
                            <li [ngClass]="customerCategoryPopup==5?'active':''"><a>
                                Payments</a></li>
            
                            <li [ngClass]="customerCategoryPopup==6?'active':''" ><a>
                                Orders</a></li>
                                        
                            <li [ngClass]="customerCategoryPopup==7?'active':''"><a>
                                Localization</a></li>
                                        
                            <li [ngClass]="customerCategoryPopup==8?'active':''" ><a>
                                Orders Status
                            </a></li>
            
                            <li [ngClass]="customerCategoryPopup==9?'active':''"><a>
                                Messages</a></li>

                            <li [ngClass]="customerCategoryPopup==10?'active':''"><a>
                                Tax</a></li>

                            <li [ngClass]="customerCategoryPopup==11?'active':''"><a>
                                Visitors</a></li>

                            <li [ngClass]="customerCategoryPopup==12?'active':''"><a>
                                Upload</a></li>

                            
                        </ul>
                    </div>
                    <div class="">
                        <div class="container-fluid">
                            <div class="row">
                                <form [formGroup]="editProductStore" class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div *ngIf="customerCategoryPopup==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Product Store
                                                        </h4>


                                                    </div>
                                                        <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Product Store ID<span style="color: red;">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="productStoreID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Store ID">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Primary Store Group ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="ProductStoreGroupListArray" formControlName="primaryStoreGroupID" optionlabel="label" placeholder="Enter Primary Store Group ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Store Name<span style="color: red;">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="storeName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Store Name">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Title</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="title" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Sub-Title</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="subTitle" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub-Title">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Company Name</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="companyName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Company Name">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Is Demo Store
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="isDemoStore" optionlabel="label" placeholder="Enter Is Demo Store">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">For controlling the look and feel of the web-based store
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="LookAndFeelWebBasedStoreArray" formControlName="controlling" optionlabel="label" placeholder="Enter For controlling the look and feel of the web-based store">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            

                                                        </div>
                                                        <div class="main-submit-button" style="margin-left: 72% !important;">
                                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(2)">Continue</button>

                                                        </div>
                                                    </div>

                                                        </div>
                                                </div>
                                                <div *ngIf="customerCategoryPopup==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Inventory
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Inventory Facility ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="InventoryFacilityIdArray" formControlName="inventoryFacilityID" optionlabel="label" placeholder="Enter Inventory Facility ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">One Inventory Facility
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="oneInventoryFacility" optionlabel="label" placeholder="Enter One Inventory Facility">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Is Immediately Fulfilled
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="isFulfil" optionlabel="label" placeholder="Enter Is Immediately Fulfilled">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Check Inventory
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="checkInventory" optionlabel="label" placeholder="Enter Check Inventory">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Require Inventory
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reqInventory" optionlabel="label" placeholder="Enter Require Inventory">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Requirement Method Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="RequirementMethodEnumIdArray" formControlName="reqEnumID" optionlabel="label" placeholder="Enter Requirement Method Enum ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Reserve Inventory
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reserveInventory" optionlabel="label" placeholder="Enter Reserve Inventory">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Reserve Order Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ReserveOrderEnumIdArray" formControlName="reserveEnumID" optionlabel="label" placeholder="Enter Reserve Order Enum ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Balance Res On Order Creation
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="resOrderCreation" optionlabel="label" placeholder="Enter Balance Res On Order Creation">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Out of Stock Products
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="showStockProducts" optionlabel="label" placeholder="Enter Show Out of Stock Products">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Lots Managing
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="lotsManaging" optionlabel="label" placeholder="Enter Lots Managing">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:9%;">
                                                            <button type="submit" (click)="customerActiveCategoryFunction(1)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(3)" >Continue</button>

                                                        </div>
                                                    </div>

                                                </div>
                                                

                                                <div *ngIf="customerCategoryPopup==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Shopping Cart
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">View Cart on Add
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="cartOnAdd" optionlabel="label" placeholder="Enter View Cart on Add">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Save Cart
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="autoSaveCart" optionlabel="label" placeholder="Enter Auto Save Cart">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Add to Cart Replace Upsell
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="replaceUpsell" optionlabel="label" placeholder="Enter Add to Cart Replace Upsell">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Add to Cart Remove Incompat
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="removeIncompat" optionlabel="label" placeholder="Enter Add to Cart Remove Incompat">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Checkout Gift Options
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="giftOptions" optionlabel="label" placeholder="Enter Show Checkout Gift Options">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Prod Search Exclude Variants
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="excludeVariants" optionlabel="label" placeholder="Enter Prod Search Exclude Variants">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Allow Order Decimal Quantity
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="decimalQuantity" optionlabel="label" placeholder="Enter Allow Order Decimal Quantity">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 9%;">
                                                            <button type="submit" (click)="customerActiveCategoryFunction(2)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(4)">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="customerCategoryPopup==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Shipping
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Prorate Shipping
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="prorateShipping" optionlabel="label" placeholder="Enter Prorate Shipping">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Req. Ship Addr. for Dig Items
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="addrDigItems" optionlabel="label" placeholder="Enter Req. Ship Addr. for Dig Items">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Select Payment Type Per Item
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="paymentType" optionlabel="label" placeholder="Enter Select Payment Type Per Item">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Ship if Capture Fails
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="captureFails" optionlabel="label" placeholder="Enter Ship if Capture Fails">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Split Pay Pref Per Ship Grp
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="splitPay" optionlabel="label" placeholder="Enter Split Pay Pref Per Ship Grp">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 9%;">
                                                            <button type="submit" (click)="customerActiveCategoryFunction(3)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(5)">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="customerCategoryPopup==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Payments 
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Pay to Party ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="partyIdArray" formControlName="payPartyID" optionlabel="label" placeholder="Enter Pay to Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Store Credit Account Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="ScoreCreditAccountListArray" formControlName="storeEnumID" optionlabel="label" placeholder="Enter Store Credit Account Enum ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Manual Audio Is Capture
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="manualAudioCapture" optionlabel="label" placeholder="Enter Manual Audio Is Capture">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Retry Failed Auths
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="retryAuths" optionlabel="label" placeholder="Enter Retry Failed Auths">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Days To Cancel Non Pay</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="daystoCancelnonPay" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Days To Cancel Non Pay">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Exp
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccTryExp" optionlabel="label" placeholder="Enter Auto Order CC Try Exp">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Other Cards
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccOtherCards" optionlabel="label" placeholder="Enter Auto Order CC Try Other Cards">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Later NSF
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccLaterNSF" optionlabel="label" placeholder="Enter Auto Order CC Try Later NSF">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Auto Order CC Try Later Max</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="ccLaterMax" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auto Order CC Try Later Max">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Show Credit Valid Days</label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="creditValidDays" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Show Credit Valid Days">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Set Owner upon Issuance
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" [options]="yesNoArray"  formControlName="setIssuance" optionlabel="label" placeholder="Enter Set Owner upon Issuance">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 9%;">
                                                            <button type="submit" (click)="customerActiveCategoryFunction(4)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(6)">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="customerCategoryPopup==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Orders 
                                                        </h4>
                                                    </div>
                                                    
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Order Number Prefix</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="orderNumberPrefix" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Number Prefix">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Default Sales Channel Enum ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="DefaultSalesChannelEnumListArray" formControlName="channelEnumID" optionlabel="label" placeholder="Enter Default Sales Channel Enum ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Explore Order Items
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="exploreItems" optionlabel="label" placeholder="Enter Explore Order Items">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Check GC Balance
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="gcBalance" optionlabel="label" placeholder="Enter Check GC Balance">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Auto Invoice Digital Items
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="invoiceDigitalItems" optionlabel="label" placeholder="Enter Auto Invoice Digital Items">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Auto Approve Invoice
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveInvoice" optionlabel="label" placeholder="Enter Auto Approve Invoice">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Auto Approve Order
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveOrder" optionlabel="label" placeholder="Enter Auto Approve Order">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Req. Return Inventory Recieve
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="returnInventoryRecieve" optionlabel="label" placeholder="Enter Req. Return Inventory Recieve">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(5)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(7)">Continue</button>
    
                                                            </div>
                                                        </div>
                                                
                                                </div>

                                                <div *ngIf="customerCategoryPopup==7">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Localization 
                                                        </h4>
                                                    </div>
                                                    
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Default Locale Storage</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="localeStorage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Locale Storage">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Default Currency UOM ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="currencyArray" formControlName="currencyUomID" optionlabel="label" placeholder="Enter Default Currency UOM ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Default Time Zone</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="timeZone" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Time Zone">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(6)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(8)">Continue</button>
    
                                                            </div>

                                                        </div>
                                                
                                                </div>

                                                <div *ngIf="customerCategoryPopup==8">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Orders Status
 
                                                        </h4>
                                                    </div>
                                                    
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Header Approved Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerApprovedStatus" optionlabel="label" placeholder="Enter Header Approved Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Item Approved Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemStatus" optionlabel="label" placeholder="Enter Item Approved Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Digital Item Approved Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="digitalItemStatus" optionlabel="label" placeholder="Enter Digital Item Approved Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Header Declined Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerDeclinedStatus" optionlabel="label" placeholder="Enter Header Declined Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Item Declined Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemDeclinedStatus" optionlabel="label" placeholder="Enter Item Declined Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Header Cancel Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerCancelStatus" optionlabel="label" placeholder="Enter Header Cancel Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Item Cancel Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemCancelStatus" optionlabel="label" placeholder="Enter Item Cancel Status">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(7)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(9)">Continue</button>
    
                                                            </div>


                                                        </div>
                                                
                                                </div>


                                                <div *ngIf="customerCategoryPopup==9">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Messages 
                                                        </h4>
                                                    </div>
                                                    
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Auth Declined Message</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="authDeclinedMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Declined Message">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Auth Fraud Message</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="authFraudMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Fraud Message">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Auth Error Message</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="authErrorMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Error Message">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(8)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(10)">Continue</button>
    
                                                            </div>

                                                        </div>
                                                
                                                </div>


                                                <div *ngIf="customerCategoryPopup==10">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Tax 
                                                        </h4>
                                                    </div>
                                                    
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Prorate Taxes
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="prorateTax" optionlabel="label" placeholder="Enter Prorate Taxes">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Show Prices with VAT Tax Included
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="pricesVATIncluded" optionlabel="label" placeholder="Enter Show Prices with VAT Tax Included">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Show Tax is Exempt
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="taxExempt" optionlabel="label" placeholder="Enter Show Tax is Exempt">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">VAT Tax Auth Geo ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="VatTaxAuthGeoIdArray" formControlName="vatGeoID" optionlabel="label" placeholder="Enter VAT Tax Auth Geo ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">VAT Tax Auth Party ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="vatPartyID" optionlabel="label" placeholder="Enter VAT Tax Auth Party ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(9)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(11)">Continue</button>
    
                                                            </div>

                                                        </div>
                                                
                                                </div>


                                                <div *ngIf="customerCategoryPopup==11">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Visitors 
                                                        </h4>
                                                    </div>
                                                    
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Auth Approve Reviews
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveReviews" optionlabel="label" placeholder="Enter Auth Approve Reviews">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Allow Password
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="allowPassword" optionlabel="label" placeholder="Enter Allow Password">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Default Password</label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="text" class="form-control" formControlName="defaultPassword" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Password">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Use Primary Email Username
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="usePrimaryUsername" optionlabel="label" placeholder="Enter Use Primary Email Username">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Require Customer Role
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="reqCustomerRole" optionlabel="label" placeholder="Enter Require Customer Role">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Enable Auto Suggestion List
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="enableSuggestion" optionlabel="label" placeholder="Enter Enable Auto Suggestion List">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(10)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit"  class="btn btn-secondary submit-btn" (click)="customerActiveCategoryFunction(12)">Continue</button>
    
                                                            </div>

                                                        </div>
                                                
                                                </div>

                                                <div *ngIf="customerCategoryPopup==12">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Upload 
                                                        </h4>
                                                    </div>
                                                    
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Enable Digital Product Upload
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="enableProductUpload" optionlabel="label" placeholder="Enter Enable Digital Product Upload">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Digital Product Upload Category ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="DigProdUploadCategoryListArray" formControlName="productUploadCategoryID" optionlabel="label" placeholder="Enter Digital Product Upload Category ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Allow Comment
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="allowComment" optionlabel="label" placeholder="Enter Allow Comment">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Allocate Inventory
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2 form-group">
                                                                        <p-dropdown filter="true" [options]="yesNoArray" formControlName="allocateInventory" optionlabel="label" placeholder="Enter Allocate Inventory">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 9%;">
                                                                <button type="submit" (click)="customerActiveCategoryFunction(11)" class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                                <button type="submit" (click)="createProductStore()" class="btn btn-secondary submit-btn">Create</button>

    
                                                            </div>

                                                        </div>
                                                
                                                </div>

                                              
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" style="padding-bottom: 70px;">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Product Store
                                </div>
                                <form class="w-100" [formGroup]="editProductStore">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Product Store ID<span style="color: red;">*</span></label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="productStoreID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Store ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Primary Store Group ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="ProductStoreGroupListArray" formControlName="primaryStoreGroupID" optionlabel="label" placeholder="Enter Primary Store Group ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Store Name<span style="color: red;">*</span></label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="storeName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Store Name">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Title</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="title" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Sub-Title</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="subTitle" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub-Title">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Company Name</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="companyName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Company Name">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Is Demo Store
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="isDemoStore" optionlabel="label" placeholder="Enter Is Demo Store">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">For controlling the look and feel of the web-based store
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="LookAndFeelWebBasedStoreArray" formControlName="controlling" optionlabel="label" placeholder="Enter For controlling the look and feel of the web-based store">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Inventory
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Inventory Facility ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="InventoryFacilityIdArray" formControlName="inventoryFacilityID" optionlabel="label" placeholder="Enter Inventory Facility ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">One Inventory Facility
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="oneInventoryFacility" optionlabel="label" placeholder="Enter One Inventory Facility">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Is Immediately Fulfilled
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="isFulfil" optionlabel="label" placeholder="Enter Is Immediately Fulfilled">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Check Inventory
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="checkInventory" optionlabel="label" placeholder="Enter Check Inventory">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Require Inventory
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reqInventory" optionlabel="label" placeholder="Enter Require Inventory">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Requirement Method Enum ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="RequirementMethodEnumIdArray" formControlName="reqEnumID" optionlabel="label" placeholder="Enter Requirement Method Enum ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Reserve Inventory
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reserveInventory" optionlabel="label" placeholder="Enter Reserve Inventory">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Reserve Order Enum ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="ReserveOrderEnumIdArray" formControlName="reserveEnumID" optionlabel="label" placeholder="Enter Reserve Order Enum ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Balance Res On Order Creation
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="resOrderCreation" optionlabel="label" placeholder="Enter Balance Res On Order Creation">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Show Out of Stock Products
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="showStockProducts" optionlabel="label" placeholder="Enter Show Out of Stock Products">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Lots Managing
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="lotsManaging" optionlabel="label" placeholder="Enter Lots Managing">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Shopping Cart
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">View Cart on Add
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="cartOnAdd" optionlabel="label" placeholder="Enter View Cart on Add">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Save Cart
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="autoSaveCart" optionlabel="label" placeholder="Enter Auto Save Cart">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Add to Cart Replace Upsell
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="replaceUpsell" optionlabel="label" placeholder="Enter Add to Cart Replace Upsell">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Add to Cart Remove Incompat
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="removeIncompat" optionlabel="label" placeholder="Enter Add to Cart Remove Incompat">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Show Checkout Gift Options
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="giftOptions" optionlabel="label" placeholder="Enter Show Checkout Gift Options">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Prod Search Exclude Variants
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="excludeVariants" optionlabel="label" placeholder="Enter Prod Search Exclude Variants">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Allow Order Decimal Quantity
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="decimalQuantity" optionlabel="label" placeholder="Enter Allow Order Decimal Quantity">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Shipping
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Prorate Shipping
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="prorateShipping" optionlabel="label" placeholder="Enter Prorate Shipping">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Req. Ship Addr. for Dig Items
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="addrDigItems" optionlabel="label" placeholder="Enter Req. Ship Addr. for Dig Items">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Select Payment Type Per Item
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="paymentType" optionlabel="label" placeholder="Enter Select Payment Type Per Item">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Ship if Capture Fails
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="captureFails" optionlabel="label" placeholder="Enter Ship if Capture Fails">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Split Pay Pref Per Ship Grp
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="splitPay" optionlabel="label" placeholder="Enter Split Pay Pref Per Ship Grp">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Payments
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Pay to Party ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="partyIdArray" formControlName="payPartyID" optionlabel="label" placeholder="Enter Pay to Party ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Store Credit Account Enum ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="ScoreCreditAccountListArray" formControlName="storeEnumID" optionlabel="label" placeholder="Enter Store Credit Account Enum ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Manual Audio Is Capture
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="manualAudioCapture" optionlabel="label" placeholder="Enter Manual Audio Is Capture">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Retry Failed Auths
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="retryAuths" optionlabel="label" placeholder="Enter Retry Failed Auths">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Days To Cancel Non Pay</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="daystoCancelnonPay" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Days To Cancel Non Pay">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Order CC Try Exp
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccTryExp" optionlabel="label" placeholder="Enter Auto Order CC Try Exp">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Order CC Try Other Cards
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccOtherCards" optionlabel="label" placeholder="Enter Auto Order CC Try Other Cards">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Order CC Try Later NSF
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="ccLaterNSF" optionlabel="label" placeholder="Enter Auto Order CC Try Later NSF">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Order CC Try Later Max</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="ccLaterMax" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auto Order CC Try Later Max">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Show Credit Valid Days</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="creditValidDays" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Show Credit Valid Days">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Set Owner upon Issuance
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray"  formControlName="setIssuance" optionlabel="label" placeholder="Enter Set Owner upon Issuance">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Orders
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Order Number Prefix</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="orderNumberPrefix" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Number Prefix">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Default Sales Channel Enum ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="DefaultSalesChannelEnumListArray" formControlName="channelEnumID" optionlabel="label" placeholder="Enter Default Sales Channel Enum ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Explore Order Items
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="exploreItems" optionlabel="label" placeholder="Enter Explore Order Items">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Check GC Balance
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="gcBalance" optionlabel="label" placeholder="Enter Check GC Balance">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Invoice Digital Items
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="invoiceDigitalItems" optionlabel="label" placeholder="Enter Auto Invoice Digital Items">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Approve Invoice
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveInvoice" optionlabel="label" placeholder="Enter Auto Approve Invoice">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auto Approve Order
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveOrder" optionlabel="label" placeholder="Enter Auto Approve Order">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Req. Return Inventory Recieve
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="returnInventoryRecieve" optionlabel="label" placeholder="Enter Req. Return Inventory Recieve">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Localization
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Default Locale Storage</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="localeStorage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Locale Storage">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Default Currency UOM ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="currencyArray" formControlName="currencyUomID" optionlabel="label" placeholder="Enter Default Currency UOM ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Default Time Zone</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="timeZone" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Time Zone">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Orders Status
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Header Approved Status
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerApprovedStatus" optionlabel="label" placeholder="Enter Header Approved Status">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Item Approved Status
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemStatus" optionlabel="label" placeholder="Enter Item Approved Status">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Digital Item Approved Status
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="digitalItemStatus" optionlabel="label" placeholder="Enter Digital Item Approved Status">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Header Declined Status
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerDeclinedStatus" optionlabel="label" placeholder="Enter Header Declined Status">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Item Declined Status
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemDeclinedStatus" optionlabel="label" placeholder="Enter Item Declined Status">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Header Cancel Status
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="HeaderApprovedStatusArray" formControlName="headerCancelStatus" optionlabel="label" placeholder="Enter Header Cancel Status">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Item Cancel Status
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="ItemApprovedStatusArray" formControlName="itemCancelStatus" optionlabel="label" placeholder="Enter Item Cancel Status">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Messages
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auth Declined Message</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="authDeclinedMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Declined Message">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auth Fraud Message</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="authFraudMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Fraud Message">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auth Error Message</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="authErrorMessage" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Auth Error Message">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Tax
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Prorate Taxes
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="prorateTax" optionlabel="label" placeholder="Enter Prorate Taxes">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Show Prices with VAT Tax Included
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="pricesVATIncluded" optionlabel="label" placeholder="Enter Show Prices with VAT Tax Included">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Show Tax is Exempt
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="taxExempt" optionlabel="label" placeholder="Enter Show Tax is Exempt">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">VAT Tax Auth Geo ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="VatTaxAuthGeoIdArray" formControlName="vatGeoID" optionlabel="label" placeholder="Enter VAT Tax Auth Geo ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">VAT Tax Auth Party ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="partyIdArray" formControlName="vatPartyID" optionlabel="label" placeholder="Enter VAT Tax Auth Party ID">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Visitors
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Auth Approve Reviews
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="approveReviews" optionlabel="label" placeholder="Enter Auth Approve Reviews">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Allow Password
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="allowPassword" optionlabel="label" placeholder="Enter Allow Password">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Default Password</label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <input type="text" class="form-control" formControlName="defaultPassword" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Password">
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Use Primary Email Username
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="usePrimaryUsername" optionlabel="label" placeholder="Enter Use Primary Email Username">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Require Customer Role
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="reqCustomerRole" optionlabel="label" placeholder="Enter Require Customer Role">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Enable Auto Suggestion List
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="enableSuggestion" optionlabel="label" placeholder="Enter Enable Auto Suggestion List">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12 form-group classInput">
                                                    <label for="exampleInputEmail1">Upload
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Enable Digital Product Upload
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="enableProductUpload" optionlabel="label" placeholder="Enter Enable Digital Product Upload">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Digital Product Upload Category ID
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="DigProdUploadCategoryListArray" formControlName="productUploadCategoryID" optionlabel="label" placeholder="Enter Digital Product Upload Category ID">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Allow Comment
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="allowComment" optionlabel="label" placeholder="Enter Allow Comment">
                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Allocate Inventory
                                                    </label>
                                                </div>
                                                <div class="col-lg-2 form-group">
                                                    <p-dropdown filter="true" [options]="yesNoArray" formControlName="allocateInventory" optionlabel="label" placeholder="Enter Allocate Inventory">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-10 col-10 main-submit-button">
                                        <button type="submit" (click)="createProductStore()" class="btn btn-secondary submit-btn">Add</button>
                                    </div>
                                </form>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->