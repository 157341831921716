import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MainCatalogService } from '../../../main-catalog-mgmt/catalog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { StoryService } from '../stories/stories-service';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { CatalogService } from '../../new-product-catalog-service';


@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.css'],
  providers: [DatePipe]
})
export class StoriesComponent implements OnInit {
  catalogId: any;
  activeCategory=1;
  @ViewChild('closebutton') closebutton;
  show= false;
  catalogArray: any = [];
  productCatalogId: any;
  catalogForm: FormGroup;
  editMode: boolean;
  catalog: any;
  detail: any;
  total=0;
  rowNumber=0;
  pageNo = 1;
  pageSize= 100;
  rows = 50;
  storeList: any;
  storeDropdown: any;
  storeArray: any = [];

  storyForm: FormGroup;

  fromDateEdit: any;
  updatefromdate: any;
  constructor(
    readonly mainCatalogService: MainCatalogService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,    private _location: Location,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    @Inject(DOCUMENT) readonly document: Document,
    public storyService: StoryService,
    readonly _FormBuilder: FormBuilder,
  
    public catalogService: CatalogService,
   
  ) {
    this.storyForm = this._FormBuilder.group({
      fromDate: [new Date()],
      productStoreId: [''],
      sequenceNum: [''],
      thruDate: [new Date()]
    });
    this.editMode = false;
    this.catalogForm = this._FormBuilder.group({
      catalogName: ['', [Validators.required]],
      contentPathPrefix: [''],
      headerLogo: [''],
      prodCatalogId: [''],
      purchaseAllowPermReqd: ['N'],
      styleSheet: [''],
      templatePathPrefix: [''],
      useQuickAdd: ['Y'],
      viewAllowPermReqd: ['N']
    });
    this.catalogArray = [{
      label: 'Yes',
      value: 'Y'
    },
    {
      label: 'No',
      value: 'N'
    }];
  
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.catalogId = params['prodCatalogId'];
    });
    this.catalogService.catalog_form = null;
    this.catalogService.catalogForm(this.catalogService.catalog_form);
    this.productCatalogId =this.catalogId ;
    if (this.productCatalogId) {
      this.editMode = true;
  
      this.catalogForm.controls.prodCatalogId.disable();
      this.catalogForm.controls.prodCatalogId.updateValueAndValidity();
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.catalogId = params['prodCatalogId'];
    });
    if (this.productCatalogId) {
      this.show = true;
    }
    else {
      this.show = false;
    }
    this.getCatalogList();
    this.getCatalogDetail();
    this.storesList();
    this.storesDropdown();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getCatalogList(): void {
   
    if (this.editMode) {
      this.mainCatalogService.getCatalogById(this.productCatalogId).subscribe(res => {
        this.catalog = res.data;
       
        this.catalogForm.patchValue({
          catalogName: res.data.catalogName,
          prodCatalogId: res.data.prodCatalogId,
          styleSheet: res.data.styleSheet,
          templatePathPrefix: res.data.templatePathPrefix,
          contentPathPrefix: res.data.contentPathPrefix,
          useQuickAdd: res.data.useQuickAdd,
          viewAllowPermReqd: res.data.viewAllowPermReqd,
          purchaseAllowPermReqd: res.data.purchaseAllowPermReqd,
          headerLogo: res.data.headerLogo
        });
      });
    }
  
  }
  onSubmitData(): void {
    this.spinner.show();
    if (this.editMode) {
      this.mainCatalogService.updateCatalog({ ...this.catalog, ...this.catalogForm.value }).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.getCatalogList();
          this.getCatalogDetail();
          this.closebutton.nativeElement.click();
         
        }

      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     } else {
      this.mainCatalogService.createCatalog(this.catalogForm.value).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.getCatalogList();
          this.getCatalogDetail();
          this.closebutton.nativeElement.click();
          
        
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });} }
  getCatalogDetail(): void {
    this.spinner.show();
    this.mainCatalogService.getCatalogById(this.catalogId).subscribe(res => {
      this.spinner.hide();
      this.detail = res.data;
    });
   
  }
  onUpdate(storeName, fromDate, thruDate, sequenceNum: number): void {
    this.show = true;
    this.editMode = true;
    this.updatefromdate=fromDate
    this.storyForm.controls.fromDate.disable();
    this.storyForm.controls.fromDate.updateValueAndValidity();
    this.storyForm.patchValue({
      productStoreId: storeName,
      fromDate: new Date(fromDate),
      thruDate: new Date(thruDate),
      sequenceNum
    });
  }
 
  formUpdate(): void {
    this.spinner.show();
   
    this.mainCatalogService.updateCatalogStore({
      ...this.storyForm.value, ...{
     
       fromDate: this.updatefromdate,
      thruDate: moment(this.storyForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
      }
    }, this.catalogId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.storyForm.reset();
        this.storyForm.patchValue({
          fromDate: new Date(),
          thruDate: new Date()
        });
        this.editMode = false;
        this.storesList();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  onDelete(productStoreId, fromDate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const date = this.datePipe.transform(fromDate, 'yyyy-MM-dd HH:mm:ss');
    this.mainCatalogService.deleteCatalogStore(this.catalogId, productStoreId, date).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        ) } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})
  }
  storesDropdown() {
    this.spinner.show();
    this.mainCatalogService.storeDropdown().subscribe(res => {
      this.storeDropdown = res.data.productStores;
      this.spinner.hide();
      for (const value of this.storeDropdown) {
        this.storeArray.push({
          label: value.storeName,
          value: value.productStoreId
        });
      }
    });
   
  }
  storesList(): void {
    this.spinner.show();
    this.mainCatalogService.getStoreList(this.catalogId).subscribe(res => {
      this.storeList = res.data;
      this.spinner.hide();
    });
   
  }
  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.formUpdate();
    } else {
      this.mainCatalogService.createStories({
        ...this.storyForm.value, ...{
          fromDate: moment(this.storyForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
          thruDate: moment(this.storyForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
        }
      }, this.catalogId).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.storyForm.reset();
          this.storyForm.patchValue({
            fromDate: new Date(),
            thruDate: new Date()
          });
          this.storesList();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
    
  }
  reset(): void {
    this.storyForm.reset();
    this.editMode = false;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
