<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Planing</span>
        
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>    </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openInventory()"><a>
                                Inventory</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openFindRequirements()" ><a>
                                Find Requirements</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openRequirements()" ><a>
                                Open Requirements</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openProductRequirements()"><a>
                                Approved Product Requirements</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openInternalRequirements()"><a>
                                Approved Internal Requirements</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="openTransferRequirements()"><a>
                                Approved Transfer Requirements</a></li>
                                        
                            <!-- <li [ngClass]="activeCategory==7?'active':''" (click)="openSalesOrderHistory()"><a>
                                Upload Sales Order History</a></li> -->
                                        
                            <li [ngClass]="activeCategory==8?'active':''" (click)="openRunMRP()" ><a>
                                Run MRP</a></li>

                            <li [ngClass]="activeCategory==9?'active':''" (click)="openViewMRP()" ><a>
                                View MRP</a></li>

                            <li [ngClass]="activeCategory==10?'active':''" (click)="openConfigureWarehouses()" ><a>
                                Configure Warehouses</a></li>
                                
                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==4">

                                        <!-- <div class="w3-card-4 classCardView">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                Find Requirements
   
                                                <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                    data-target="#createSurveyPopup" data-toggle="modal">Create Survey</button>
                                         
                                            </div>
                                            <form [formGroup]="findRequirementsForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Requirement Id
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="requirementId" class="form-control" id="exampleInputEmail1" 
                                                                    aria-describedby="emailHelp" placeholder="Enter Requirement Id">
                                                    
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Requirement Type Id
                                                                </label>
                                                            </div>
                    
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="requirementTypeId" 
                                                                placeholder="Choose Requirement Type Id" >
                    
                                                                </p-dropdown>

                                                            </div>
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Facility Id
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="facilityId" 
                                                                placeholder="Choose Facility Id" >
                    
                                                                </p-dropdown>
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product Id                                                            </label>
                                                            </div>
                                                            <div class="col-lg-2">

                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="productId" 
                                                                placeholder="Choose Product Id">
                    
                                                                </p-dropdown>

                                                            </div>
                    
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Supplier</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="supplier" 
                                                                placeholder="Choose Supplier">
                    
                                                                </p-dropdown>
                                                                
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Status</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                formControlName="status" 
                                                                placeholder="Choose Status">
                    
                                                                </p-dropdown>
                                                            </div>
                    
                                                        
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Description</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                                    aria-describedby="emailHelp" placeholder="Enter Description">
                                                    
                                                            </div>
                    
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">From Date
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date"  formControlName="fromDate" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" >
                                                
                                                            </div>
                    
                                                        
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Thru Date

                                                                </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="date"  formControlName="thruDate" class="form-control" id="exampleInputEmail1" 
                                                                aria-describedby="emailHelp" >
                                                
                                                            </div>
                    
                                                        </div>
                                                    </div>
                    
                                                </div>
                                            </form>
                                            <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                <button type="submit"  class="btn btn-secondary submit-btn" >Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2" >Reset</button>&nbsp;
                                            </div>
                                        </div> -->
                                        <div class="w3-card-4 classCardView mt-2">
                                            <div class="header-tabs" >
                                                <h4 class="common-styling h4Margin">
                                                    Approved Requirements

                                                </h4>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                
                                                        <div class="card own-account-table">
                                                            <p-table [value]="approvedProductRequirementsByVendor" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                
                                                                                Supplier 

                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                # Products 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Facility
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Consolidate Requirements 
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                
                                                                        
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span  class="account-button"  >
                                                                                {{product.requirementId}}
                                                                                </span>
                
                                                                            </div>
                                                                        </td>
                
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.quantity}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.facilityId}}
                                                                        </td>
                                                                      
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            {{product.submitCaption}}
                                                                        </td>

                                                                       
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button type="button" class="btn btn-secondary ml-2" (click)="deleteProductRequirementPlanning(product)" >Delete</button>&nbsp;
                                                                            </td>
                                                                            <!-- <button type="button" class="btn btn-secondary ml-2" data-target="#" data-toggle="modal" >Order All</button>&nbsp;
                                                                            <button type="button" class="btn btn-danger ml-2" >Cancel All</button>&nbsp; -->
                                                   

                                                                    </tr>
                                                                </ng-template>
                
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 
