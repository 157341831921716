<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Personal Information
                </span>
                <span class="color-black pl-1" > >Edit Personal Information
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Edit Personal Information
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createJournal">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Part ID</label>
                                                <p-dropdown filter="true"
                                                placeholder="Enter Part ID"
                                                name="Enter Party ID">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Salutation</label>
                                                <input type="email"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Salutation">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                First Name</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter First name">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Middle name</label>
                                                <input type="email"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Middle Name">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Last Name</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Last Name">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Personal Title</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Personal Title">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Suffix</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Suffix">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Nickname</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Nickname">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                First Name Local</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter First Name Local">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Middle Name Local</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Middle Name Local">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Last Name Local</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Last Name Local">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Other Local</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Other Local">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Member ID</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Member ID">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Gender</label>
                                                <p-dropdown filter="true"
                                                placeholder="Enter Gender"
                                                name="Parent Type ID">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Birth Date</label>
                                                <input type="date"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Birth Date">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Deceased Date</label>
                                                <input type="date"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Deceased Date">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Height</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Height">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Weight</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Weight">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Mothers Maiden Name</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Mothers Maiden Name">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Old Marital Status</label>
                                                <p-dropdown filter="true"
                                                placeholder="Enter Old Marital Status"
                                                name="Parent Type ID">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Marital Status Enum ID</label>
                                                <p-dropdown filter="true"
                                                placeholder="Enter Marital Status Enum ID"
                                                name="Parent Type ID">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Social Security Number</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Social Security Number">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Passport Number</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Passport Number">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Passport Expire Date</label>
                                                <input type="date"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Passport Expire Date">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Total Years Work Experience</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Total Years Work Experience">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Comments</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Comments">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Eployment Status Enum ID</label>
                                                <p-dropdown filter="true"
                                                placeholder="Enter Eployment Status Enum ID"
                                                name="Parent Type ID">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Residence Status Enum ID</label>
                                                <p-dropdown filter="true"
                                                placeholder="Enter Residence Status Enum ID"
                                                name="Parent Type ID">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Occupation</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Occupation">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Years With Employer</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Years With Employer">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Months With Employer</label>
                                                <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Months With Employer">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">
                                                Existing Customer</label>
                                                <p-dropdown filter="true"
                                                placeholder="Enter Existing Customer"
                                                name="Parent Type ID">
                                                </p-dropdown>
                                        </div>
                                    </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Card ID</label>
                                                    <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Card ID">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    US Citizen</label>
                                                    <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter US Citizen">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Ethnicity</label>
                                                    <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Ethnicity">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Account Number</label>
                                                    <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Account Number">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Billing Rate Level</label>
                                                    <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Billing Rate Level">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Preferred Currency Uom ID</label>
                                                    <p-dropdown filter="true"
                                                    placeholder="Enter Preferred Currency Uom ID"
                                                    name="Parent Type ID">
                                                    </p-dropdown>
                                            </div>
                                          </div>
                                          <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Description</label>
                                                    <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    External ID</label>
                                                    <input type="text"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter External ID">
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Status ID</label>
                                                    <p-dropdown filter="true"
                                                    placeholder="Enter Status ID"
                                                    name="Parent Type ID">
                                                    </p-dropdown>
                                            </div>
                                        </div>  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 80%;">
                               
                                <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Save</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>




