import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../../../accounting-ap.service';
import { PayablePaymentService } from '../../../payables-payment/payables-payment.service';

@Component({
    selector: 'app-create-credit-item',
    templateUrl: './create-credit-item.component.html'
})
export class CreateCreditItemComponent implements OnInit {
    decisionArray = [
        {
            label: 'Yes',
            value: 1
        },
        {
            label: 'No',
            value: 0
        }
    ];
    invoiceId: string;
    invoiceItemForm: FormGroup;
    editMode: boolean;
    invoiceItemTypes: any[];
    productIds: any[];
    uomIds: any[];
    invoiceType: string;
    invoiceItemSeqId: string;
    featureIds: any[];
    GIAccounts: any[];
    inventoryItems: any[];
    itemValue: any;
    constructor(
        readonly _ActivatedRoute: ActivatedRoute,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,
        readonly _AccountingApService: AccountingApService,
        readonly _MainCatalogService: MainCatalogService,
        readonly _ToastrService: ToastrService,
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _GlobalResourceService: GlobalResourceService
    ) {
        this.invoiceItemTypes = [];
        this.editMode = false;
        this.invoiceId = '';
        this.invoiceItemForm = this._FormBuilder.group({
            amount: [''],
            description: [''],
            inventoryItemId: [''],
            invoiceItemTypeId: [''],
            overrideGlAccountId: [''],
            productFeatureId: [''],
            productId: [''],
            quantity: [''],
            taxableFlag: [1],
            uomId: ['']
        });
        this.uomIds = [];
        this.invoiceType = 'payable';
        this.invoiceItemSeqId = '';
        this.featureIds = [];
        this.GIAccounts = [];
        this.inventoryItems = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.invoiceItemSeqId = this._ActivatedRoute.snapshot.queryParams.invoiceItemSeqId;
        if (this.invoiceItemSeqId) {
            this.editMode = true;
            this._AccountingApService.getInvoiceItemById(this.invoiceId, this.invoiceItemSeqId)
                .then(data => {
                    this.itemValue = data.data;
                    this.invoiceItemForm.patchValue(data.data);
                });
        }
        this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getInvoiceItemType();
        this.getProducts();
        this.getFeatureIds();
        this.getGIAccounts();
        this.getUomIds();
        this.getInventoryItem();
    }
    getInventoryItem(): void {
        this.spinner.show();
        this._AccountingApService.getInventoryItem()
            .then(data => {
                this.inventoryItems = data.data.map(value => {
                    return {
                        label: value.inventoryItemId,
                        value: value.inventoryItemId
                    };
                });
            });
            this.spinner.hide();
    }
    getInvoiceItemType(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceItemTypes()
            .then(data => {
                this.invoiceItemTypes = data.data.invoiceItemType.map(value => {
                    return {
                        label: value.description,
                        value: value.invoiceItemTypeId
                    };
                });
            });
            this.spinner.hide();
    }
    getFeatureIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getFeatureIds()
            .then(data => {
                this.featureIds = data.data.map(value => {
                    return {
                        label: value.productFeatureId,
                        value: value.productFeatureId
                    };
                });
            });
            this.spinner.hide();
    }
    getGIAccounts(): void {
        this.spinner.show();
        this._PayablePaymentService.getGIAccountAndPaymentMethod()
            .then(data => {
                if (data) {
                    this.GIAccounts = data.data.glAccounts.map(value => {
                        return {
                            label: value.glAccountId,
                            value: value.glAccountId
                        };
                    });
                }
            });
            this.spinner.hide();
    }
    getUomIds(): void {
        this.spinner.show();
        this._AccountingApService.getTermType()
            .then(data => {
                this.uomIds = data.data.uom.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
            this.spinner.hide();
    }
    getProducts(): void {
        this.spinner.show();
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
            .then(data => {
                this.productIds = data.data.map(value => {
                    return {
                        label: value.productId,
                        value: value.productId
                    };
                });
            });
            this.spinner.hide();
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoiceItem(this.invoiceId, this.invoiceItemSeqId, {
            ...this.itemValue, ...this.invoiceItemForm.value
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.invoiceItemForm.reset();
                    this._Router.navigate([`/financial/${this.invoiceType}/credit-memo/summary-credit-memo`], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
            
    }
    reset(): void {
        this.invoiceItemForm.reset();
        this._Router.navigate([`/financial/${this.invoiceType}/credit-memo/summary-credit-memo`], { queryParams: { invoiceId: this.invoiceId } });
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createInvoiceItem(this.invoiceId, this.invoiceItemForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.invoiceItemForm.reset();
                        this._Router.navigate([`/financial/${this.invoiceType}/credit-memo/summary-credit-memo`], { queryParams: { invoiceId: this.invoiceId } });
                    }
                });
        }
       
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}