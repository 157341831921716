import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductService } from './product.service';

@Injectable()
export class NewProductsResolver implements Resolve<[any, any]>{
    constructor(
        readonly _ProductService: ProductService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<[any, any]> {
        return Promise.all([
            this._ProductService.getProductMenu().then(data => data.data),
            (route.queryParams.productId) ? this._ProductService.getProductById(route.queryParams.productId).then(data => data.data) : null
        ]);
    }
}