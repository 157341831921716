import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentGroupService } from '../payment-group.service';

@Component({ 
  selector: 'app-create-payment-group',
  templateUrl: './create-payment-group.component.html',
  styleUrls: ['./create-payment-group.component.css']
})
export class CreatePaymentGroupComponent implements OnInit {
  paymentGroupTypes: any[];
  activeCategory = 2;

  paymentType: string;
  paymentGroupForm: FormGroup;
  paymentGroupId: string;
  editMode: boolean;
  paymentGroup: any;
  constructor(
    readonly _PaymentGroupService: PaymentGroupService,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute
  ) {
    this.paymentType = '';
    this.paymentGroupId = '';
    this.paymentGroupTypes = [];
    this.paymentGroupForm = this._FormBuilder.group({
      paymentGroupName: ['', [Validators.required]],
      paymentGroupTypeId: ['CHECK_RUN']
    });
    this.editMode = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.paymentGroupId = this._ActivatedRoute.snapshot.queryParams.paymentGroupId;
    if (this.paymentGroupId) {
      this.editMode = true;
      this._PaymentGroupService.getPayablePaymentById(this.paymentGroupId)
        .then(data => {
          if (data) {
            this.paymentGroup = {
              paymentGroupName: data.data.paymentGroupName,
              paymentGroupTypeId: data.data.paymentGroupTypeId
            };
            this.paymentGroupForm.patchValue({
              paymentGroupName: data.data.paymentGroupName,
              paymentGroupTypeId: data.data.paymentGroupTypeId
            });
          }
        });
      this.paymentGroupForm.controls.paymentGroupTypeId.disable();
      this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();

    }
    this.paymentType = window.location.pathname.includes('receiveable') ? '/financial/receiveable/payment-group' : '/financial/payable/payment-group';
    this.getPaymentTypes();
  }
  getPaymentTypes(): void {
    this.spinner.show();
    this._PaymentGroupService.getPaymentGroupTypes()
      .then(data => {
        this.spinner.hide();
        this.paymentGroupTypes = data.data.map(value => {
          return {
            label: value.description,
            value: value.paymentGroupTypeId
          };
        });
      });
     
  }
  update(): void {
    this.spinner.show();
    if (this.paymentGroupForm.valid) {
      this._PaymentGroupService.updatePaymentGroup(this.paymentGroupId, {
        ...this.paymentGroup, ...this.paymentGroupForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.paymentGroupForm.reset();
            this.paymentGroupForm.controls.paymentGroupTypeId.enable();
            this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();
            this._Router.navigate([this.paymentType]);
          }
        });
    }
    this.spinner.hide();
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.paymentGroupForm.valid) {
        this._PaymentGroupService.createPaymentGroup(this.paymentGroupForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.paymentGroupForm.reset();
              this.spinner.hide();
              this._Router.navigate([this.paymentType]);
            }
          });
      }
    }
    this.spinner.hide();
  }
  reset(): void {
    this.paymentGroupForm.reset();
    this._Router.navigate([this.paymentType]);

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
