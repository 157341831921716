<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Account Summary ></span>
                <span class="color-black pl-1"> {{editMode? 'Update':'Create'}} Billing Account</span>
                <button type="submit" style="margin-left: 63%;" (click)="cancelSubmit();" routerLinkActive="active"
                class="btn btn-danger text-white">Cancel</button>
            </div>
            <div class="w3-card-4" style="border:1px solid;
            border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
            <header class="w3-container w3-blue">
              <div class="header-tabs">
                <h4 class=" common-styling" style="margin-left: 7px;">
                    {{editMode? 'Update':'Create'}} Billing Account
                </h4>
             
              </div>
            </header>
            <div class=" bg-white color-grey">
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="billingAccountForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -9%;">
                                                <label for="exampleInputEmail1">Account Limit</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Account Limit"
                                                formControlName="accountLimit">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                           ">
                                                <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Account Currency Uom ID" filter="true"
                                                [options]="currencyUomIds" formControlName="accountCurrencyUomId">
                                            </p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -9%;">
                                                <label for="exampleInputEmail1">Contact Mech ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Contact Mech ID"
                                                formControlName="contactMechId">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" placeholder="From Date"
                                                formControlName="fromDate">
                                            </div>
                                           
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -9%;">
                                                <label for="exampleInputEmail1">Through Date </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" placeholder="Through Date"
                                                formControlName="thruDate">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                ">
                                                <label for="exampleInputEmail1">Description
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Description"
                                                    formControlName="description">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -9%;">
                                                <label for="exampleInputEmail1">External Account ID
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control"
                                                    placeholder="External Account ID"
                                                    formControlName="externalAccountId">
                                            </div>
                                          
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">Party ID <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Party ID" filter="true" [options]="partyIds"
                                                    formControlName="partyId">
                                                </p-dropdown>
                                                <small class="text-danger"
                                                    *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                                                    ID is Required</small>
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -9%;">
                                                <label for="exampleInputEmail1">Available Balance</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p> ${{availableBalance}} <span> This is the amount which can be used
                                                    for new orders.</span></p>
                                            </div>
                                          
                                            
                                           
                                        </div>
                                    </div>
                                   
                               
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 64%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                               
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>

        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">{{editMode? 'Update':'Create'}} Billing Account</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
 
 <div class="row p-15">
    <div class="col-12">
        <div class=" bg-white color-grey">
            <div class="w3-card-4 classCard border-card">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
        
                    <li class="active" ><a>
                        {{editMode? 'Update':'Create'}} Billing Account
                </a></li>
                    
                
                </ul>
                </div>
            
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="billingAccountForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-4 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -15%;">
                                                <label for="exampleInputEmail1">Account Limit</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control"
                                                    placeholder="Account Limit" formControlName="accountLimit">
                                            </div>

                                            <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                           ">
                                                <label for="exampleInputEmail1">Account Currency Uom ID</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Account Currency Uom ID" filter="true"
                                                    [options]="currencyUomIds"
                                                    formControlName="accountCurrencyUomId">
                                                </p-dropdown>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-4 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -15%;">
                                                <label for="exampleInputEmail1">Contact Mech ID</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control"
                                                    placeholder="Contact Mech ID"
                                                    formControlName="contactMechId">
                                            </div>

                                            <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">From Date</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="date" class="form-control" placeholder="From Date"
                                                    formControlName="fromDate">
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-4 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -15%;">
                                                <label for="exampleInputEmail1">Through Date </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="date" class="form-control"
                                                    placeholder="Through Date" formControlName="thruDate">
                                            </div>

                                            <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                ">
                                                <label for="exampleInputEmail1">Description
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control"
                                                    placeholder="Description" formControlName="description">
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-4 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -15%;">
                                                <label for="exampleInputEmail1">External Account ID
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control"
                                                    placeholder="External Account ID"
                                                    formControlName="externalAccountId">
                                            </div>

                                            <div class="col-lg-3 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            ">
                                                <label for="exampleInputEmail1">Party ID <span
                                                        class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Party ID" filter="true"
                                                    [options]="partyIds" formControlName="partyId">
                                                </p-dropdown>
                                                <small class="text-danger"
                                                    *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                                                    ID is Required</small>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-4 form-group rightForm" style="text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -15%;">
                                                <label for="exampleInputEmail1">Available Balance</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p> ${{availableBalance}} <span> This is the amount which can be
                                                        used
                                                        for new orders.</span></p>
                                            </div>



                                        </div>
                                    </div>


                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: -8%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                               
                            </div>
                        </div>
                    </div>
                </div>
                

           
            </div>

        </div>
        
    </div>
</div>