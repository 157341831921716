import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';

@Component({
  selector: 'app-create-crm-product',
  templateUrl: './create-crm-product.component.html',
  styleUrls: ['./create-crm-product.component.css']
})
export class CreateCrmProductComponent implements OnInit {
  activeCategory=2;
  createProduct:FormGroup
  Products: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search = '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 50;

  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  ProductsArray: any[]=[];
  commEventId: any;
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    
    readonly spinner: NgxSpinnerService,) { 
    this.createProduct = this._FormBuilder.group({
      productId: ['']
    
     
 
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.commEventId = params['commEventId'];
     
    });
    this.getProducts();
  }
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
     

    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productId,
          value: value.productId
        })
      }


    })
  

  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "productId": this.createProduct.value.productId,
     
    }

    this.accountsService.postCrmEventProductList(this.commEventId,requestData.productId).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  cancelSubmit(){
    this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
  }
  ngOnDestroy(): void {

    this.spinner.hide();
      } 
}
