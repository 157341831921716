import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShippingService } from '../shipping/shipping.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-shipment-summary',
  templateUrl: './detail-shipment-summary.component.html',
  styleUrls: ['./detail-shipment-summary.component.css']
})
export class DetailShipmentSummaryComponent implements OnInit {

  id: any;
  groupid: any;
  parentId: any;
  fGroupName: any;
  des: any;
  seq: any;
  activeCategory = 2;
  activeCategorytab = 2;
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  shipmentMethodType: any[];
  shipmentMethodTypeForm: FormGroup;
  editMode: boolean;
  shipmentMethodTypeId: string;
  shipmentTypeIds: any[];
  isShown=false;
  
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
  
    readonly _ShippingService: ShippingService,
    readonly _FormBuilder: FormBuilder,
      private _location: Location,
  
    readonly _ToastrService: ToastrService
  ) { 
    this.shipmentMethodType = [];
    this.shipmentMethodTypeForm = this._FormBuilder.group({
      description: [''],
      sequenceNum: [0],
      shipmentMethodTypeId: ['', [Validators.required]]
    });
    this.editMode = false;
    this.shipmentMethodTypeId = '';
    this.shipmentTypeIds = [];
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
   
    
this.getShipmentMethodType();
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.id = params["id"],
      this.seq=params["seq"],
      
      this.des=params["des"],
    ])
    
  }
  getShipmentMethodType(): void {
    this.spinner.show();
    this._ShippingService.getShipmentMethodType({ pageNo: 1, pageSize: 200 })
      .then(data => {
        if (data.success) {
          this.shipmentMethodType = data.data;
          this.spinner.hide();
        }
      });
     
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  edit(): void {
    this.editMode = true;
    this.shipmentMethodTypeId =  this.id;
    this.shipmentMethodTypeForm.patchValue({
      description:  this.des,
      sequenceNum:  this.seq,
      shipmentMethodTypeId: this.id
     
    });
  /*   this.shipmentMethodTypeForm.patchValue({
      shipmentMethodTypeId, description, sequenceNum
    }); */
    this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.disable();
    this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.updateValueAndValidity();
  }
 

  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.shipmentMethodTypeForm.valid) {
        this._ShippingService.updateShipmentMethodType({
          shipmentMethodTypeId: this.shipmentMethodTypeId,
          ...this.shipmentMethodTypeForm.value
        }).then(data => {
            if (data.success) {
              this._ToastrService.success('Updated');
              this.closebutton.nativeElement.click();
              this.router.navigate(["facilities/catalog/shipment"])
              this.spinner.hide();
             
              this.getShipmentMethodType();
              this.editMode = false;
              this.shipmentMethodTypeForm.reset();
              this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.enable();
              this.shipmentMethodTypeForm.controls.shipmentMethodTypeId.updateValueAndValidity();
              document.getElementById('eventForm').scrollIntoView({ behavior: 'smooth' });
            
             
            }
          });}
    } else {
      if (this.shipmentMethodTypeForm.valid) {
        this._ShippingService.createShipmentMethodType(this.shipmentMethodTypeForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.closebutton.nativeElement.click();
              this.router.navigate(["facilities/catalog/shipment"])
              this.spinner.hide();
            
              this.shipmentMethodTypeForm.reset();
              this.getShipmentMethodType();
              document.getElementById('eventForm').scrollIntoView({ behavior: 'smooth' });
             
             
            }});
      }} }

}
