import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-portal-page-admin',
  templateUrl: './detail-portal-page-admin.component.html',
  styleUrls: ['./detail-portal-page-admin.component.css']
})
export class DetailPortalPageAdminComponent implements OnInit {
  portalPageId: any;
  portalPageName: any;
  parentPortalPageId: any;
  sequenceNum: any;
  ownerUserLoginId: any;
  originalPortalPageId: any;
  securityGroupId: any;
  description: any;
  activeCategory=1;

  constructor(
    readonly activatedRoute:ActivatedRoute,
private _location: Location,
readonly spinner: NgxSpinnerService,
    readonly router: Router) { }
   
  ngOnInit(): void {
  
    this.activatedRoute.queryParams.subscribe(params=> [
      this.portalPageId = params["portalPageId"],
      this.portalPageName=params["portalPageName"],
      this.parentPortalPageId=params["parentPortalPageId"],
      this.sequenceNum = params["sequenceNum"],
      this.originalPortalPageId=params["originalPortalPageId"],
      this.ownerUserLoginId=params["ownerUserLoginId"],
      this.securityGroupId=params["securityGroupId"],
      this.description=params["description"],
    
    ])
   
  }
  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

}