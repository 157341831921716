<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey breadcrumbb" (click)="cancel()">User</span>
                <span *ngIf="!this.show" class="color-black pl-1"> > Create-User</span>
                <span *ngIf="this.show" class="color-black pl-1"> > Update-User</span>
                <button type="submit" (click)="cancel()" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li *ngIf="!this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Create User</a></li>
                            <li *ngIf="this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                    Update User</a></li>

                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createUser">
                                    <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">First Name<span
                                                            style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control address" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter First Name"
                                                        formControlName="firstName">
                                                   <small class="text-danger"
                                                *ngIf="!!createUser.controls.firstName.invalid && !!createUser.controls.firstName.touched"> First Name is required!</small>
                                                       
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Last Name<span
                                                            style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control address" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Last Name"
                                                        formControlName="lastName">
                                                        <small class="text-danger"
                                                *ngIf="!!createUser.controls.lastName.invalid && !!createUser.controls.lastName.touched"> Last Name is required!</small>
                                                       
                                                    
                                                </div>
                                               
                                               
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm" >
                                                    <label for="exampleInputEmail1">E-mail<span
                                                            style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text" class="form-control address"
                                                        pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Email" formControlName="email">
                                                    <small
                                                        *ngIf="createUser.controls['email'].invalid && (createUser.controls['email'].dirty || createUser.controls['email'].touched)">
                                                        <p class="validate-field"
                                                            *ngIf="createUser.controls['email'].errors.required">
                                                            E-mail is required!</p>
                                                        <p class="validate-field"
                                                            *ngIf="createUser.controls['email'].errors.pattern">
                                                            E-mail is not valid!</p>
                                                </small>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA" *ngIf="!this.show">
                                                    <label for="exampleInputEmail1" >Password<span
                                                            style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA" *ngIf="this.show">
                                                <label for="exampleInputEmail1" >Password<span
                                                        style="color:red">*</span></label>
                                        </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Password"
                                                        formControlName="password">
                                                        <small class="text-danger"
                                                        *ngIf="!!createUser.controls.password.invalid && !!createUser.controls.password.touched"> Password is required!</small>
                                                               
                                                        
                                            </div>
                                                <div class="col-lg-2"></div>
                                               
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1" >Group Id<span
                                                                style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Group Id"
                                                            formControlName="groupId" filter="true"
                                                            [options]="arraySecurity" optionlabel="label">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                        *ngIf="!!createUser.controls.groupId.invalid && !!createUser.controls.groupId.touched"> Group Id is required!</small>
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin: 0px 620px;">
                                    <button *ngIf="!this.show" type="submit" (click)="submit()"
                                        class="btn btn-secondary submit-btn update-button">Submit</button>
                                    <button *ngIf="this.show" type="submit" (click)="update()"
                                        class="btn btn-secondary submit-btn update-button">Update</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>