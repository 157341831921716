import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddLeaveService } from '../add-new-leaves/add-new-leaves-service';
import { AddLeaveEmpService } from '../add-new-leaves/add-new-leaves.service';
import { EmployeeLeaveService } from '../employee-leave/employee-leave.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-leave-summary',
  templateUrl: './leave-summary.component.html',
  styleUrls: ['./leave-summary.component.css'],
  providers: [DatePipe]
})
export class LeaveSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  leaveId: any[]=[];
  employeeLeave: any;
  total=0;
  activeCategory=1;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  public leaveSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  isApproval: boolean;
  partyIdEdit: any;
  leaveTypeId: any;
  fromDate: any;
  thruDate: any;
  AppId: any;
  Description: any;
  Reasonid: any;
  statusId: any;
  leave: boolean;
  show: boolean;
  leaveStatusReoort: any;
  leaveStatusReoortArray: any[]=[];
  leaveTypeIds: any;
  leaveReason: any;
  leaveReasonArray: any[]=[];
  partyId: any;
  partyIdArray: any[]=[];
  updateefromdate: string;
  updateetodate: string;
  leaveIdNew: any;
  // leaveIdarray: any[]=[];
  constructor(  
      private _location: Location,
      readonly activatedRoute:ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly employeeLeaveService: EmployeeLeaveService,
    public addLeaveService: AddLeaveService,
    readonly toastr: ToastrService,
    readonly spinner:NgxSpinnerService,
    readonly addLeaveEmpService: AddLeaveEmpService,
    public datePipe: DatePipe,
    ) { 
      this.isApproval = false;
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.addLeaveService.add_leave_form = null;
    this.addLeaveService.addLeaveForm(this.addLeaveService.add_leave_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdEdit = params["partyId"];
      this.leaveTypeId = params["leaveTypeId"];
      this.fromDate = params["fromDate"];
      this.thruDate = params["thruDate"];
      this.AppId = params["AppId"]
      this.Description = params["Description"]
      this.Reasonid = params["Reasonid"];
      this.statusId=params["leaveStatus"]
      this.isApproval = params.approval === 'true' ? true : false;
    })
    
        if(this.isApproval){ 
           this.leave=true
        }else{
          this.leave=false
        }
    if (this.partyIdEdit) {
      this.show = true;
      let fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd')
      let toDate = this.datePipe.transform(this.thruDate, 'yyyy-MM-dd')
      setTimeout(() => {

        const formValue = this.addLeaveService.add_leave_form;
        formValue.patchValue({
          partyId: this.partyIdEdit,
          leaveTypeId: this.leaveTypeId,
          emplLeaveReasonTypeId: this.Reasonid,
          approverPartyId: this.AppId,
          description: this.Description,
          thruDate: toDate,
          fromDate: fromDate,
          leaveStatus:this.statusId
           

        })
      }, 3000);

    }
    else {
      this.show = false
    }
    this.getLeaveReasonId();
    this.getPartyId();
    this.leavereason();
    this.leaveStatus();
    this.activatedRoute.queryParams.subscribe(params=> {
      this.leaveIdNew = params["partyId"];
    });
    this.getLeaveList();
  }homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  leaveStatus() {
    this.spinner.show();
    this.employeeLeaveService.getLeaveStatus().subscribe(res => {
      this.leaveStatusReoort = res.data;
      this.spinner.hide();
      for (const value of this.leaveStatusReoort) {
        this.leaveStatusReoortArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }
  getLeaveReasonId() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
      this.leaveTypeIds = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveTypeIds) {
        this.leaveId.push({
          label: value.description,
          value: value.leaveTypeId
        })
      }
    })
 
  }
  leavereason() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReason().subscribe(res => {
      this.leaveReason = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveReason) {
        this.leaveReasonArray.push({
          label: value.description,
          value: value.emplLeaveReasonTypeId
        })
      }
    })
 
  }

  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }

  getLeaveList() {
    this.spinner.show();
    const searchEmployrrLeaveForm =
    {
      approverPartyId: "",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      leaveReasonTypeId: "",
      leaveStatusID: [
        ""
      ],
      leaveTypeId: "",
      partyId: this.leaveIdNew,
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains"
    }

    this.employeeLeaveService.employeeLeaveList(this.leaveSize, searchEmployrrLeaveForm).subscribe(res => {
      this.employeeLeave = res.data[0];
      this.spinner.hide();
    })
   
  }
  onUpdate() {
    this.spinner.show();
    const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
      description: this.addLeaveService.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
      partyId: this.addLeaveService.add_leave_form.value.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.addLeaveService.add_leave_form.value.leaveStatus,
    }
  
    this.addLeaveEmpService.update(formDate, this.partyIdEdit, this.leaveTypeId, this.fromDate).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.successMessage);
        this.addLeaveService.add_leave_form.reset();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/hr/employee-leave'])
       
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   

  }
  onUpdateApproval() {
    this.spinner.show();
    const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
      description: this.addLeaveService.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
      partyId: this.addLeaveService.add_leave_form.value.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.addLeaveService.add_leave_form.value.leaveStatus,
    }
  
    this.addLeaveEmpService.postLeaveStatus(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.addLeaveService.add_leave_form.reset();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/hr/employee-leave/leave-approval'])
       
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();

      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
