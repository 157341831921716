import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2'
import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-classifications',
  templateUrl: './classifications.component.html',
  styleUrls: ['./classifications.component.css']
})
export class ClassificationsComponent implements OnInit {
  products3: any;
  total = 0;
  @ViewChild('closebutton') closebutton;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  pageNo = 1;
  activeCategory=1;
  createClassification:FormGroup;
 
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  ClassificationGroup: any;
  ClassificationGroupTypeId: any;
  ClassificationGroupTypeIdArray: any[]=[];
  classId: any;
  searchArray:any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService
    ) {
      this.contentIds = [];
      this.createClassification = this._FormBuilder.group({
        Description: [''],
        ParentGroupID: [''],
        PartyClassificationTypeID:['']
       
        
      });
     }

  ngOnInit(): void {
    
 this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
 if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.getClassificationGroup();
    this.getClassificationGroupData();
    this.getClassificationGroupTypeId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/security/security-dashboard']);

  }

  getClassificationGroupTypeId(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroupTypeId().subscribe((res:any)=> {
        this.ClassificationGroupTypeId = res.data;
        this.spinner.hide();
        for(const value of this.ClassificationGroupTypeId){
          this.ClassificationGroupTypeIdArray.push({
            label:value.partyClassificationTypeId.description,
            value:value.partyClassificationTypeId.partyClassificationTypeId
          })
        }
      
      })
     
     }
  getClassificationGroupData(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroup().subscribe((res:any)=> {
        this.ClassificationGroup = res.data;
        this.spinner.hide();
        for(const value of this.ClassificationGroup){
          this.ClassificationGroupIdArray.push({
            label:value.description.description,
            value:value.partyClassificationGroupData.partyClassificationGroupId
          })
        }
      
      })
     
     }
     
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "description": this.createClassification.value.Description,
      "parentGroupId": this.createClassification.value.ParentGroupID,
      "partyClassificationTypeId": this.createClassification.value.PartyClassificationTypeID,

     
    }
    this.accountsService.createPartyClassificationGroup(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getClassificationGroup();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getClassificationGroup(){
    this.spinner.show();
   
      this.accountsService.getClassificationGroup().subscribe((res:any)=> {
        this.ClassificationGroup = res.data;
        this.spinner.hide();
      
      
      })
     
     }
     deletePartyClassificationGroup(partyTypeId) {
      if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this data.!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.accountsService.deletePartyClassificationGroup(partyTypeId).subscribe(res => {
              this.getClassificationGroup(); 
            })
            Swal.fire(
              'Deleted!',
              'Deleted Successfully.',
              'success'
            )
         
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your data is safe :)',
              'error'
            )
          }
        })
      }
      else if(this.userPermissions.PARTYMGR_VIEW){
        this._ToastrService.error("Permission Denied"); 
      }
     
    
  
    }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
 
  overView(id){
    this._Router.navigate(['/security/create-new-party-classification-group'],{ queryParams: { classId:id}});
  }
  goToCreateNewClassificationGroup(){
    this._Router.navigate(['/security/create-group-classification']);
  }

}
