import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-routing-task-assoc',
  templateUrl: './create-routing-task-assoc.component.html',
  styleUrls: ['./create-routing-task-assoc.component.css']
})
export class CreateRoutingTaskAssocComponent implements OnInit {
  createRouteAssoc:FormGroup;
  activeCategory=2;
  routingId: any;
  actualfromdate: string;
  actualthroughdate: string;
  taskList: any;
  taskListArray: any[]=[];

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly productionService:ProductionService,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createRouteAssoc = this._FormBuilder.group({
        RoutingTaskID: [''],
        FromDate:[''],
        ThroughDate:[''],
        SequenceNum: [''],
       
      });
    
  }
 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingId = params["routingId"];
    
    });
    this.getroutingTaskList();
  }
  getroutingTaskList() {
    this.spinner.show();
    
    this.productionService.getroutingTaskList().subscribe(res => {
      this.taskList = res.data.lookupResult;
      this.spinner.hide();
      for (const value of this.taskList) {
        this.taskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }

  onSubmitExisting() {
    this.spinner.show();
    const fromActualdate=this.createRouteAssoc.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRouteAssoc.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "copyTask": "N",
      "fromDate":  this.actualfromdate,
      "routingTaskId": this.createRouteAssoc.value.RoutingTaskID,
      "sequenceNum": this.createRouteAssoc.value.SequenceNum,
      "thruDate":this.actualthroughdate
    }
    this.productionService.postaddExistingRoutingTask(this.routingId,requestData).subscribe((res: any) => {  
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(["production/detail-routing-summary"],{ queryParams: { routingId : this.routingId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }}); }
  onSubmitCopy() {
    this.spinner.show();
    const fromActualdate=this.createRouteAssoc.get('FromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRouteAssoc.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "copyTask": "Y",
      "fromDate":  this.actualfromdate,
      "routingTaskId": this.createRouteAssoc.value.RoutingTaskID,
      "sequenceNum": this.createRouteAssoc.value.SequenceNum,
      "thruDate":this.actualthroughdate
    }
    this.productionService.postaddExistingRoutingTask(this.routingId,requestData).subscribe((res: any) => {   
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(["production/detail-routing-summary"],{ queryParams: { routingId : this.routingId}})
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);
        }
    }); }
  cancelSubmit(){
    this._Router.navigate(["production/detail-routing-summary"],{ queryParams: { routingId : this.routingId}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

