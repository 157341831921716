<div>
    <div> Sales Order
    </div>
    <div style="padding-top:100px">
        <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
        <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
        <script src="https://cdn.amcharts.com/lib/4/plugins/forceDirected.js"></script>
        <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
        <div id="chartdiv"></div>
    </div>
</div>
<div class="mt-5">
    <div>
        Purchase Order
    </div>
    <div style="padding-top:100px">
        <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
        <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
        <script src="https://cdn.amcharts.com/lib/4/plugins/forceDirected.js"></script>
        <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
        <div id="chartdiv1"></div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 