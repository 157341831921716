import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
// import * as _ from 'lodash';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
    import { Location } from '@angular/common';
    import { SortEvent } from 'primeng/api';
import { dataLoader } from '@amcharts/amcharts4/core';
@Component({
  selector: 'app-detail-employement-summary',
  templateUrl: './detail-employement-summary.component.html',
  styleUrls: ['./detail-employement-summary.component.css']
})
export class DetailEmployementSummaryComponent implements OnInit {
  activeCategory = 0;
  products3: any;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  paymentPartyGroup: any;
  createClaim: FormGroup;
  downloading: boolean;
  total = 0;
  activeCategoryMain = 2;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  pageNo = 1;
  createEmployement: FormGroup;
  show: boolean;
  partyBenfitList: any;
  showBenfit: boolean;
  createPartyBenefitform: FormGroup;
  createPartyHistory: FormGroup;
  createPayrollPrefernceform: FormGroup;
  showHistory: boolean;
  showPayroll: boolean;
  showClaim: boolean;
  showAppl: boolean;
  createEmpAppl: FormGroup;

  TerminationTypeIDArray: any[] = [];
  TerminationReasonID: any;
  TerminationReasonIDArray: any[] = [];
  PreferenceList: any;
  payrollPreferenceSeqId: any;
  actualfromdate: string;
  actualThroughDate: string;
  payrollprefrenceServiceArray: any[] = [];
  DeductionTypeID: any;
  PaymentMethodType: any[] = [];
  deductionTypeIDArray: any[] = [];
  PeriodTypeID: any;
  PeriodTypeIDArray: any[] = [];
  partyID: any;
  fromdate: any;
  BenefitTypeIDArray: any[] = [];
  BenefitTypeID: any;
  benefitTypeId: any;
  PayrollPreferenceSequenceIdArray: any[] = [];
  PayrollPreferenceSequenceId: any;
  PayHistoriesList: any;
  StatusIDArray: any[] = [];
  StatusID: any;
  UnemploymentClaimID: any;
  UnemploymentClaimIDArray: any[] = [];
  unemployementClaimsList: any;
  AgreementItemSeqID: any;
  AgreementItemSeqIDArray: any[] = [];
  RoleTypeIdFrom: any;
  RoleTypeIdFromArray: any[] = [];
  RoleTypeIdTo: any;
  RoleTypeIdToArray: any[] = [];
  PartyIDFrom: any;
  PartyIDFromArray: any[] = [];
  AgreementIDArray: any[] = [];
  AgreementID: any;
  AgreementEmploymentList: any;
  payHistoryListById: any;
  PartyIDToArray: any[] = [];
  PartyIDTo: any;
  advanceSearch: {

    EmployeePartyID: string;
    PerfReviewID: string;
    ManagerPartyID: string;
    PaymentID: string;
    fromdateFrom: string;
    fromDateTo: string;
    throughDateFrom: string,
    throughDateTo: string,
    EmplPositionID: string,
    Comments: string,
  };
  roleTypeIdFrom: any;
  roleTypeIdTo: any;
  partyIdFrom: any;
  editPayHistory: any;
  Data: any;
  PayGradeID: any;
  PayGradeIDArray: any[] = [];
  SalarySepSequenceIDArray: any[] = [];
  SalaryStepSequenceID: any;
  SalaryStepSequenceIDArray: any[] = [];
  actualFromDate: string;
  check: boolean;
  TerminationTypeIDValue: any;
  fDate: any;
  FromDate: any;
  agreementIDNew: any;
  agreementIDNewArray: any[] = [];
  pro: any;
  FindPayrollPreferenceListP: any;
  agreementIdSeqIdArray: any[] = [];
  agreementIdSeqId: any;
  agreementId: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,   private _location: Location,
    
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,


    readonly router: Router) {
    this.createEmployement = this._FormBuilder.group({
      throughDate: [''],
      TerminationReasonID: [''],
      // fromDate: [''],
      TerminationTypeID: [''],


    });
    this.createPartyBenefitform = this._FormBuilder.group({
      BenefitTypeID: [''],
      throughDate: [''],
      PeriodTypeID: [''],
      Cost: [''],
      ActualEmployerPaidPercent: [''],
      AvailableTime: [''],


    });
    this.createPartyHistory = this._FormBuilder.group({
      changeDate: [''],
      Amount: [''],
      PeriodTypeID: [''],
      Comments: [''],
      PayGradeID: [''],
      SalaryStepSequenceID: [''],


    });
    this.createPayrollPrefernceform = this._FormBuilder.group({
      PayrollPreferenceSequenceId: [''],
      DeductionTypeID: [''],
      PaymentMethodType: [''],
      PeriodTypeID: [''],
      ThroughDate: [''],
      Percentage: [''],
      FlatAmount: [''],
      RoutingNumber: [''],
      AccountNumber: [''],
      BankName: [''],


    });
    this.createClaim = this._FormBuilder.group({
      UnemploymentClaimID: [''],
      UnemploymentClaimDate: [''],
      Description: [''],
      StatusID: [''],
      ThroughDate: [''],

    });
    this.createEmpAppl = this._FormBuilder.group({
      AgreementID: [''],
      PartyIDTo: [''],
      AgreementItemSeqID: [''],
      PartyIDFrom: [''],
      RoleTypeIdFrom: [''],
      RoleTypeIdTo: [''],
      FromDate: [''],
      AgreementDate: [''],
      ThroughDate: [''],

    });
  }

  ngOnInit(): void {
    this.getPayGradeID();
    this.getSalaryStepSequenceID();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyID = params["partyID"];
      this.fromdate = params["fromdate"];
      this.fDate = this.fromdate.slice(0, 10)
      this.partyIdFrom = params["partyIdFrom"];
      this.roleTypeIdFrom = params["roleTypeIdFrom"];
      this.roleTypeIdTo = params["roleTypeIdTo"];

    })

    setTimeout(() => {
      const form = this.createEmpAppl;
      form.patchValue({
        PartyIDTo: this.partyID,
        PartyIDFrom: this.partyIdFrom,
        RoleTypeIdFrom: this.roleTypeIdFrom,
        RoleTypeIdTo: this.roleTypeIdTo,
        FromDate: this.datePipe.transform(this.fromdate, "yyyy-MM-dd")
      })
    }, 1000);
    this.show = true;
    this.showBenfit = false;
    this.showHistory = false;
    this.showPayroll = false;
    this.showClaim = false;
    this.showAppl = false;
    this.getTerminationTypeID();
    this.getTerminationReasonID();

    this.getFindPayrollPreferenceList();
    this.payrollprefrenceService();
    this.getDeductionTypeID();
    this.getPayrollPeriodTypeId();
    this.getBenefitTypeList();
    this.getBenefitTypeID();
    this.getPayrollPreferenceSequenceId();
    this.getPayHistoryList();
    this.getUnemploymentClaim();
    this.getStatusItem();
    this.getUnemploymentClaimList();
    this.getRoleTypeIdFrom();
    this.getRoleTypeIdTo();
    this.getPartyID();
    this.getPartyId();
    // this.updateEmployment();
    this.getPeriodTypeId();
    // this.updatePartyHistory();
    this.getAgreementEmploymentApplList();
    this.AgreementItemSeqId();
    this.getAgreementID();
    this.getPayHistoryListById();
    this.getEditPayHistory();
    this.getFindPayrollPreferenceListP();
    this.getAgreementIDNew();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])

  }
  cancelSubmit(){
    this._location.back();
  }
  
   
  onChange(event) {
    
    this.agreementIdSeqIdArray = [];
    this.agreementId = event;
    if (event) {
      this.getAgreementIdSeqId();
    }
  }
  getAgreementIdSeqId() {
    this.spinner.show();
    this.accountsService.getAgreementIdSeqId(this.agreementId).subscribe(res => {
      this.agreementIdSeqId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.agreementIdSeqId) {
        this.agreementIdSeqIdArray.push({
          label: value.agreementItemSeqId,
          value: value.agreementItemSeqId
        })
      }
    })
  }

  getAgreementIDNew() {
    this.spinner.show();
    this.accountsService.getAgreementIDNew().subscribe(res => {
      this.agreementIDNew = res.data;
      this.spinner.hide();
      for (const value of this.agreementIDNew) {
        this.agreementIDNewArray.push({
          label: value.description,
          value: value.agreementId
        })
      }
    })
  }

  getFindPayrollPreferenceListP() {
    this.spinner.show();
    this.accountsService.getFindPayrollPreferenceListP(this.partyID).subscribe((res: any) => {
      this.FindPayrollPreferenceListP = res.data;
      this.spinner.hide();
    })
    }
  resetPre() {
    this.showAppl = false;
    this.createEmpAppl.reset();
    setTimeout(() => {
      const form = this.createEmpAppl;
      form.patchValue({
        PartyIDTo: this.partyID,
        PartyIDFrom: this.partyIdFrom,
        RoleTypeIdFrom: this.roleTypeIdFrom,
        RoleTypeIdTo: this.roleTypeIdTo,
        FromDate: this.datePipe.transform(this.fromdate, "yyyy-MM-dd")
      })
    }, 1000);
  }
  updatePreference(product) {
    this.showAppl = true;
    this.pro = product;
    setTimeout(() => {
      const form = this.createEmpAppl;
      form.patchValue({
        AgreementID: this.pro.Data.agreementId,
        PartyIDTo: this.pro.Data.partyIdTo,
        AgreementItemSeqID: this.pro.Data.agreementItemSeqId,
        PartyIDFrom: this.pro.Data.agreementId,
        RoleTypeIdFrom: this.roleTypeIdFrom,
        RoleTypeIdTo: this.roleTypeIdTo,
        FromDate: this.datePipe.transform(this.pro.fromDate, "yyyy-MM-dd"),
        AgreementDate: this.datePipe.transform(this.pro.Data.agreementDate, "yyyy-MM-dd"),
        ThroughDate: this.datePipe.transform(this.pro.thruDate, "yyyy-MM-dd")
      })
    }, 1000);
  }
  getEditPayHistory() {
    this.spinner.show();

    this.accountsService.getEditPayHistory(this.partyIdFrom, this.partyID, "INTERNAL_ORGANIZATIO", "EMPLOYEE").subscribe((res: any) => {
      this.editPayHistory = res.data;

      this.spinner.hide();
    })
  }


  editPay(product) {

    setTimeout(() => {


      const formValue = this.createPartyHistory;
      formValue.patchValue({
        changeDate: '',
        Amount: product.Data.amount,
        PeriodTypeID: product.Data.periodTypeId,
        Comments: product.Data.comments,
        PayGradeID: product.Data.payGradeId,
        SalaryStepSequenceID: product.Data.salaryStepSeqId,
      })

    }, 1000);

  }
  updatePartyHistory() {
    this.spinner.show();
    this.actualFromDate = this.datePipe.transform(this.createPartyHistory.value.changeDate, "yyyy-MM-dd hh:mm:ss");
    this.FromDate = this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss");


    const formdata = {
      "amount": this.createPartyHistory.value.Amount,
      "changeDate": this.actualFromDate,
      "comments": this.createPartyHistory.value.Comments,
      "emplFromDate": this.actualFromDate,
      "fromDate": this.FromDate,
      "partyIdFrom": this.partyIdFrom,
      "partyIdTo": this.partyID,
      "periodTypeId": this.createPartyHistory.value.PeriodTypeID,
      "roleTypeIdFrom": "INTERNAL_ORGANIZATIO",
      "roleTypeIdTo": "EMPLOYEE",
      "salaryStepSeqId": this.createPartyHistory.value.SalaryStepSequenceID,
    }

    this.accountsService.updatePartyHistory(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getPayHistoryList();
        this.closebutton8.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }




  updateEmployment() {
    this.spinner.show();
    // let fromActualdate = this.createEmployement.get('fromDate').value;
    // this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    let thDate = this.createEmployement.get('throughDate').value;
    this.actualThroughDate = this.datePipe.transform(thDate, "yyyy-MM-dd hh:mm:ss");
    const formdata = {
      "fromDate": this.fromdate,
      "partyIdFrom": this.partyIdFrom,
      "partyIdTo": this.partyID,
      "roleTypeIdFrom": "INTERNAL_ORGANIZATIO",
      "roleTypeIdTo": "EMPLOYEE",
      "terminationReasonId": this.createEmployement.value.TerminationReasonID,
      "terminationTypeId": this.createEmployement.value.TerminationTypeID,
      "thruDate": this.actualThroughDate,
      "thruDate_i18n": this.actualThroughDate,
    }

    this.accountsService.updateEmployment(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton2.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  getFindPayrollPreferenceList() {


    this.accountsService.getFindPayrollPreferenceList(this.createPayrollPrefernceform.value.PayrollPreferenceSequenceId).subscribe((res: any) => {
      this.spinner.show();
      this.PreferenceList = res.data;
      this.spinner.hide();
    })
  }


  getBenefitTypeList() {


    this.accountsService.getBenefitTypeList(this.partyID, 'INTERNAL_ORGANIZATIO').subscribe((res: any) => {
      this.spinner.show();
      this.partyBenfitList = res.data;
      this.spinner.hide();
    })
  }

  getPayHistoryList() {


    this.accountsService.getPayHistoryList().subscribe((res: any) => {
      this.spinner.show();
      this.PayHistoriesList = res.data;
      this.spinner.hide();
    })
  }

  getUnemploymentClaimList() {


    this.accountsService.getUnemploymentClaimList(this.partyID).subscribe((res: any) => {
      this.spinner.show();
      this.unemployementClaimsList = res.data;
      this.spinner.hide();
    })
  }
  getPayHistoryListById() {
    this.accountsService.getPayHistoryListById(this.partyID).subscribe((res: any) => {
      this.spinner.show();
      this.payHistoryListById = res.data;
      this.spinner.hide();
    })
  }
  getAgreementEmploymentApplList() {


    this.accountsService.getAgreementEmploymentApplList(this.partyID).subscribe((res: any) => {
      this.spinner.show();
      this.AgreementEmploymentList = res.data;
      this.spinner.hide();
    })
  }
  submitBenefit() {
    this.spinner.show();
    let fromActualdate = this.createPartyBenefitform.get('throughDate').value;
    this.actualThroughDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const formdata = {
      "actualEmployerPaidPercent": this.createPartyBenefitform.value.ActualEmployerPaidPercent,
      "availableTime": this.createPartyBenefitform.value.AvailableTime,
      "benefitTypeId": this.createPartyBenefitform.value.BenefitTypeID,
      "cost": this.createPartyBenefitform.value.Cost,
      "fromDate": this.fromdate,
      "partyIdFrom": this.partyIdFrom,
      "partyIdTo": this.partyID,
      "periodTypeId": this.createPartyBenefitform.value.PeriodTypeID,
      "roleTypeIdFrom": "INTERNAL_ORGANIZATIO",
      "roleTypeIdTo": "EMPLOYEE",
      "thruDate": this.actualThroughDate,
      "thruDate_i18n": ""
    }

    this.accountsService.createPartyBenefit(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getBenefitTypeList();
        this.closebutton3.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  onSubmitPre() {
    this.spinner.show();
    let fromActualdate = this.createPayrollPrefernceform.get('ThroughDate').value;
    this.actualThroughDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    // this.check = _.includes(this.PayrollPreferenceSequenceIdArray, this.createPayrollPrefernceform.value.PayrollPreferenceSequenceId)

    const formdata = {
      "accountNumber": this.createPayrollPrefernceform.value.AccountNumber,
      "bankName": this.createPayrollPrefernceform.value.BankName,
      "deductionTypeId": this.createPayrollPrefernceform.value.DeductionTypeID,
      "flatAmount": this.createPayrollPrefernceform.value.FlatAmount,
      "fromDate": this.fromdate,
      "partyId": this.partyID,
      "paymentMethodTypeId": this.createPayrollPrefernceform.value.PaymentMethodType,
      "payrollPreferenceSeqId": this.createPayrollPrefernceform.value.PayrollPreferenceSequenceId,
      "percentage": this.createPayrollPrefernceform.value.Percentage,
      "periodTypeId": this.createPayrollPrefernceform.value.PeriodTypeID,
      "roleTypeId": "EMPLOYEE",
      "routingNumber": this.createPayrollPrefernceform.value.RoutingNumber,
      "thruDate": this.actualThroughDate,
      "thruDate_i18n": ""
    }

    this.accountsService.createPayrollPreference(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton5.nativeElement.click();

        this.getFindPayrollPreferenceListP();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  onSubmitClaims() {
    this.spinner.show();
    let fromActualdate = this.createClaim.get('ThroughDate').value;
    this.actualThroughDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const formdata = {
      "description": this.createClaim.value.Description,
      "fromDate": this.fromdate,
      "partyIdFrom": this.partyIdFrom,
      "partyIdTo": this.partyID,
      "roleTypeIdFrom": "INTERNAL_ORGANIZATIO",
      "roleTypeIdTo": "PACKER",
      "statusId": this.createClaim.value.StatusID,
      "thruDate": this.createClaim.value.ThroughDate,
      "thruDate_i18n": "",
      "unemploymentClaimDate": this.createClaim.value.UnemploymentClaimDate,
      "unemploymentClaimDate_i18n": "",
      "unemploymentClaimId": this.createClaim.value.UnemploymentClaimID
    }

    this.accountsService.createUnemploymentClaim(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.getUnemploymentClaimList();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  updateAgreementEmploymentAppl() {
    this.spinner.show();
    let fromActualdate = this.createEmpAppl.get('FromDate').value;
    this.actualThroughDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const formdata = {
      "agreementDate": this.datePipe.transform(this.createEmpAppl.value.AgreementDate, "yyyy-MM-dd HH:mm:ss"),
      "agreementId": this.createEmpAppl.value.AgreementID,
      "agreementItemSeqId": this.createEmpAppl.value.AgreementItemSeqID,
      "fromDate":  this.fromdate,
      "partyIdFrom": this.partyIdFrom,
      "partyIdTo": this.partyID,
      "roleTypeIdFrom": this.roleTypeIdFrom,
      "roleTypeIdTo": this.roleTypeIdTo,
      "thruDate": this.datePipe.transform(this.createEmpAppl.value.ThroughDate, "yyyy-MM-dd HH:mm:ss"),
      "_rowSubmit": "",
      "deleteLink": "",
      "submitButton": "",
    }

    this.accountsService.updateAgreementEmploymentAppl(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getBenefitTypeList();
        this.closebutton7.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }
  onSubmitAgreement() {
    this.spinner.show();
    let fromActualdate = this.createEmpAppl.get('ThroughDate').value;
    this.actualThroughDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const formdata = {
      "agreementDate": this.datePipe.transform(this.createEmpAppl.value.AgreementDate, "yyyy-MM-dd HH:mm:ss"),
      "agreementId": this.createEmpAppl.value.AgreementID,
      "agreementItemSeqId": this.createEmpAppl.value.AgreementItemSeqID,
      "fromDate": this.fromdate,
      "partyIdFrom": this.partyIdFrom,
      "partyIdTo": this.partyID,
      "roleTypeIdFrom": this.roleTypeIdFrom,
      "roleTypeIdTo": this.roleTypeIdTo,
      "thruDate": this.datePipe.transform(this.createEmpAppl.value.ThroughDate, "yyyy-MM-dd HH:mm:ss"),
    }

    this.accountsService.createAgreementEmploymentAppl(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getBenefitTypeList();
        this.closebutton7.nativeElement.click();


      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  getTerminationTypeID() {
    this.spinner.show();
    this.accountsService.getTerminationTypeID().subscribe(res => {
      this.TerminationTypeIDValue = res.data;
      this.spinner.hide();
      for (const value of this.TerminationTypeIDValue) {
        this.TerminationTypeIDArray.push({
          label: value.description,
          value: value.terminationTypeId
        })
      }
    })
  }

  payrollprefrenceService() {
    this.spinner.show();
    this.accountsService.payrollprefrenceService().subscribe(res => {
      this.DeductionTypeID = res.data;
      this.spinner.hide();
      for (const value of this.DeductionTypeID) {
        this.payrollprefrenceServiceArray.push({
          label: value.description,
          value: value.deductionTypeId
        })
      }
    })
  }

  getDeductionTypeID() {
    this.spinner.show();
    this.accountsService.getDeductionTypeID().subscribe(res => {
      this.PaymentMethodType = res.data;
      this.spinner.hide();
      for (const value of this.PaymentMethodType) {
        this.deductionTypeIDArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
  }
  getPayrollPeriodTypeId() {
    this.spinner.show();
    this.accountsService.getPayrollPeriodTypeId().subscribe(res => {
      this.PeriodTypeID = res.data;
      this.spinner.hide();
      for (const value of this.PeriodTypeID) {
        this.PeriodTypeIDArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
    })
  }

  getPeriodTypeId() {
    this.spinner.show();
    this.accountsService.getPeriodTypeId().subscribe(res => {
      this.PeriodTypeID = res.data;
      this.spinner.hide();
      for (const value of this.PeriodTypeID) {
        this.PeriodTypeIDArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
    })
  }
  getPayGradeID() {

    this.spinner.show();
    this.accountsService.getPayGradeID().subscribe(res => {
      this.SalaryStepSequenceID = res.data;
      this.spinner.hide();
      for (const value of this.SalaryStepSequenceID) {
        this.PayGradeIDArray.push({
          label: value.salaryStepSeqId,
          value: value.salaryStepSeqId
        })
      }
    })
  }


  getSalaryStepSequenceID() {
    this.spinner.show();
    this.accountsService.getSalaryStepSequenceID().subscribe(res => {
      this.PayGradeID = res.data;
      this.spinner.hide();
      for (const value of this.PayGradeID) {
        this.SalaryStepSequenceIDArray.push({
          label: value.payGradeId,
          value: value.payGradeId
        })
      }
    })
  }


  getBenefitTypeID() {
    this.spinner.show();
    this.accountsService.getBenefitTypeID().subscribe(res => {
      this.BenefitTypeID = res.data;
      this.spinner.hide();
      for (const value of this.BenefitTypeID) {
        this.BenefitTypeIDArray.push({
          label: value.benefitTypeId,
          value: value.benefitTypeId
        })
      }
    })
  }
  getTerminationReasonID() {
    this.spinner.show();
    this.accountsService.getTerminationReasonID().subscribe(res => {
      this.TerminationReasonID = res.data;
      this.spinner.hide();
      for (const value of this.TerminationReasonID) {
        this.TerminationReasonIDArray.push({
          label: value.terminationReasonId,
          value: value.terminationReasonId
        })
      }
    })
  }


  getPayrollPreferenceSequenceId() {
    this.spinner.show();
    this.accountsService.getPayrollPreferenceSequenceId().subscribe(res => {
      this.PayrollPreferenceSequenceId = res.data;
      this.spinner.hide();
      for (const value of this.PayrollPreferenceSequenceId) {
        this.PayrollPreferenceSequenceIdArray.push({
          label: value.payrollPreferenceSeqId,
          value: value.payrollPreferenceSeqId
        })
      }
    })
  }
  getStatusItem() {
    this.spinner.show();
    this.accountsService.getStatusItem().subscribe(res => {
      this.StatusID = res.data;
      this.spinner.hide();
      for (const value of this.StatusID) {
        this.StatusIDArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }
  getUnemploymentClaim() {
    this.spinner.show();
    this.accountsService.getUnemploymentClaim().subscribe(res => {
      this.UnemploymentClaimID = res.data;
      this.spinner.hide();
      for (const value of this.UnemploymentClaimID) {
        this.UnemploymentClaimIDArray.push({
          label: value.unemploymentClaimId,
          value: value.unemploymentClaimId
        })
      }
    })
  }

  AgreementItemSeqId() {
    this.spinner.show();
    this.accountsService.AgreementItemSeqId().subscribe(res => {
      this.AgreementItemSeqID = res.data;
      this.spinner.hide();
      for (const value of this.AgreementItemSeqID) {
        this.AgreementItemSeqIDArray.push({
          label: value.agreementItemSeqId,
          value: value.agreementItemSeqId
        })
      }
    })
  }

  getRoleTypeIdFrom() {
    this.spinner.show();
    this.accountsService.getRoleTypeIdFrom().subscribe(res => {
      this.RoleTypeIdFrom = res.data;
      this.spinner.hide();
      for (const value of this.RoleTypeIdFrom) {
        this.RoleTypeIdFromArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }

  getRoleTypeIdTo() {
    this.spinner.show();
    this.accountsService.getRoleTypeIdTo().subscribe(res => {
      this.RoleTypeIdTo = res.data;
      this.spinner.hide();
      for (const value of this.RoleTypeIdTo) {
        this.RoleTypeIdToArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }

  getPartyID() {
    this.spinner.show();
    this.accountsService.getPartyID().subscribe(res => {
      this.PartyIDFrom = res.data;
      this.spinner.hide();
      for (const value of this.PartyIDFrom) {
        this.PartyIDFromArray.push({
          label: value.PartyIDFrom.partyTypeId,
          value: value.PartyIDFrom.partyTypeId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.PartyIDTo = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyIDTo) {
        this.PartyIDToArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }

  getAgreementID() {
    this.spinner.show();
    this.accountsService.getAgreementID().subscribe(res => {
      this.AgreementID = res.data;
      this.spinner.hide();
      for (const value of this.AgreementID) {
        this.AgreementIDArray.push({
          label: value.agreementId,
          value: value.agreementId
        })
      }
    })
  }


  deletePayrollPreference(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formdata = {
          "partyId": product.data.partyId,
          "payrollPreferenceSeqId": product.data.payrollPreferenceSeqId,
          "roleTypeId": product.data.roleTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deletePayrollPreference(formdata).subscribe((res: any) => {

          this.getFindPayrollPreferenceListP();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  deletePartyBenefit(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "benefitTypeId": product.BenefitTypeList.benefitTypeId,
          "fromDate": product.fromDate,
          "partyIdFrom": this.partyIdFrom,
          "partyIdTo": this.partyID,
          "roleTypeIdFrom": "INTERNAL_ORGANIZATIO",
          "roleTypeIdTo": "EMPLOYEE",
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deletePartyBenefit(req).subscribe((res: any) => {

          this.getBenefitTypeList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePayHistory(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formdata = {
          "amount": product.Data.amount,
          "comments": product.Data.comments,
          "emplFromDate": product.emplFromDate,
          "fromDate": product.fromDate,
          "partyIdFrom": this.partyIdFrom,
          "partyIdTo": this.partyID,
          "periodTypeId": product.Data.periodTypeId,
          "roleTypeIdFrom": "INTERNAL_ORGANIZATIO",
          "roleTypeIdTo": "EMPLOYEE",
          "salaryStepSeqId": product.Data.salaryStepSeqId,
          "thruDate": product.thruDate
        }
        this.accountsService.deletePayHistory(formdata).subscribe((res: any) => {

          this.getPayHistoryList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  deleteUnemploymentClaim(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formdata = {
          "fromDate": product.fromDate,
          "partyIdFrom": this.partyIdFrom,
          "partyIdTo": this.partyID,
          "roleTypeIdFrom": "INTERNAL_ORGANIZATIO",
          "roleTypeIdTo": "EMPLOYEE",
          "unemploymentClaimId": product.UnemploymentClaimList.unemploymentClaimId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteUnemploymentClaim(formdata).subscribe((res: any) => {

          this.getUnemploymentClaimList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteAgreementEmploymentAppl(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formdata = {
          "agreementId": product.Data.agreementId,
          "agreementItemSeqId": product.Data.agreementItemSeqId,
          "fromDate": product.fromDate,
          "partyIdFrom": product.Data.partyIdFrom,
          "partyIdTo": this.partyID,
          "roleTypeIdFrom": this.roleTypeIdFrom,
          "roleTypeIdTo": this.roleTypeIdTo,
        }
        this.accountsService.deleteAgreementEmploymentAppl(formdata).subscribe((res: any) => {

          this.getAgreementEmploymentApplList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
