import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-equipment-service',
  templateUrl: './equipment-service.component.html',
  styleUrls: ['./equipment-service.component.css']
})
export class EquipmentServiceComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 9;
  activeCategories = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  equipmentType: any;
  addServiceDescriptionForm: FormGroup;
  addServiceTrackForm: FormGroup;
  equipmentId: any;
  fsmEquipmentServiceList: any;
  show: boolean;
  equipmentServiceId: any;
  Priority: any;
  PriorityArray: any[] = [];
  equipmentTrack: any;
  showTrack: boolean;
  FsmEquipmentTrackList: any;
  skillId: any;
  skillIdArray: any[] = [];
  yesNoArray: { label: string; value: string; }[];
  skillID: any;
  priorityArray: { label: string; value: string; }[];
  pro: any;
  proTrack: any;
  addServiceCheckListForm: FormGroup;
  skillDropdown: any;
  skillDropdownArray: any[]=[];
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    this.addServiceCheckListForm = this._FormBuilder.group({
      typeName: "",
      priority: "",
      duration: "",
      skill: "",
      Description: ""
    });
    this.addServiceDescriptionForm = this._FormBuilder.group({
      name: "",
      description: "",
      duration: "",
      priority: "",
      skill: "",
    });
    this.addServiceTrackForm = this._FormBuilder.group({
      keepHistory: "",
      required: "",
      track: "",
      type: "",
      name: ""
    });
    this.yesNoArray =
      [{
        label: "Y",
        value: "Y",
      },
      {
        label: "N",
        value: "N",
      },
      ]
      this.priorityArray =
      [{
        label: "Urgent",
        value: "Urgent",
      },
      {
        label: "Non-Urgent",
        value: "Non-Urgent",
      },
      ]
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.equipmentId = params.equipmentId;
    })
    this.show = false;
    this.showTrack = false;
    this.getFsmEquipmentService();
    this.getFsmEquipmentTrack();
    this.getPriority();
    this.getSkill();
  }
  getSkill() {
    this.spinner.show();
    this.myContactsService.getSkill().subscribe(res => {
      this.skillDropdown = res.data[0].data;
      this.spinner.hide();
      for (const value of this.skillDropdown) {
        this.skillDropdownArray.push({
          label: value.skillId,
          value: value.skillId
        })
      }
    })
  }
  resetTrack() {
    this.showTrack = false;
    this.addServiceTrackForm.reset();

  }
  getFsmEquipmentTrackById(product) {
    this.showTrack = true;
    this.proTrack = product;
      setTimeout(() => {
        const formValue = this.addServiceTrackForm;
        formValue.patchValue({
          keepHistory: this.proTrack.trackKeepHistory,
          required: this.proTrack.trackRequired,
          track: this.proTrack.track,
          type: this.proTrack.trackType,
          name: this.proTrack.trackName,
        })
      }, 2000);
  }
  updateFsmEquipmentTrack() {
    this.spinner.show();
    const requestData = {
      "equipmentId": this.equipmentId,
      "track": this.addServiceTrackForm.value.track,
      "trackId": this.proTrack.trackId,
      "trackKeepHistory": this.addServiceTrackForm.value.keepHistory,
      "trackName": this.addServiceTrackForm.value.name,
      "trackRequired": this.addServiceTrackForm.value.required,
      "trackType": this.addServiceTrackForm.value.type
    }
    this.myContactsService.updateFsmEquipmentTrack(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addServiceTrackForm.reset();
        this.getFsmEquipmentTrack();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  createFsmEquipmentTrack() {
    this.spinner.show();

    const requestData = {
      "equipmentId": this.equipmentId,
      "track": this.addServiceTrackForm.value.track,
      "trackId": "",
      "trackKeepHistory": this.addServiceTrackForm.value.keepHistory,
      "trackName": this.addServiceTrackForm.value.name,
      "trackRequired": this.addServiceTrackForm.value.required,
      "trackType": this.addServiceTrackForm.value.type

    }
    this.myContactsService.createFsmEquipmentTrack(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addServiceTrackForm.reset();
        this.getFsmEquipmentTrack();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmEquipmentTrack() {
    this.spinner.show();
    this.myContactsService.getFsmEquipmentTrackById(this.equipmentId).subscribe(res => {
      this.FsmEquipmentTrackList = res.data[0].getFsmEquipmentTrackById;
      this.spinner.hide();
    })
  }
  deleteFsmEquipmentTrack(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "trackId": product.trackId,
        }
        this.myContactsService.deleteFsmEquipmentTrack(req).subscribe(res => {
          this.getFsmEquipmentTrack();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getPriority() {
    this.spinner.show();
    this.myContactsService.getPriority().subscribe(res => {
      this.Priority = res.data[0].data;
      this.spinner.hide();
      for (const value of this.Priority) {
        this.PriorityArray.push({
          label: value.description,
          value: value.priority
        })
      }
    })
  }
  reset() {
    this.show = false;
    this.addServiceCheckListForm.reset();
  }
  getFsmEquipmentServiceById(product) {
    this.show = true;
    this.pro = product ;
  
      setTimeout(() => {
        const formValue = this.addServiceCheckListForm;
        formValue.patchValue({
          typeName: this.pro.serviceName,
          priority: this.pro.priority,
          duration: this.pro.serviceDuration,
          skill: this.pro.serviceSkill,
          Description: this.pro.description
        })
      }, 2000);
 
  }
  updateFsmEquipmentService() {
    this.spinner.show();
    const requestData = {
      "description": this.addServiceCheckListForm.value.Description,
      "equipmentId": this.equipmentId,
      "priority": this.addServiceCheckListForm.value.priority,
      "serviceDuration": this.addServiceCheckListForm.value.duration,
      "serviceName": this.addServiceCheckListForm.value.typeName,
      "serviceSkill": this.addServiceCheckListForm.value.skill,
      "equipmentServiceId": this.pro.equipmentServiceId
    }
    this.myContactsService.updateFsmEquipmentService(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addServiceCheckListForm.reset();
        this.getFsmEquipmentService();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  createFsmEquipmentService() {
    this.spinner.show();
    const requestData = {
      "description":  this.addServiceCheckListForm.value.Description,
      "equipmentId": this.equipmentId,
      "equipmentServiceId": "",
      "priority":this.addServiceCheckListForm.value.priority,
      "serviceDuration": this.addServiceCheckListForm.value.duration,
      "serviceName": this.addServiceCheckListForm.value.typeName,
      "serviceSkill": this.addServiceCheckListForm.value.skill,
    }
    this.myContactsService.createFsmEquipmentService(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addServiceCheckListForm.reset();
        this.getFsmEquipmentService();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmEquipmentService() {
    this.spinner.show();
    this.myContactsService.getFsmEquipmentServiceById(this.equipmentId).subscribe(res => {
      this.fsmEquipmentServiceList = res.data[0].getFsmEquipmentServiceById;
      this.spinner.hide();
    })
  }
  deleteFsmEquipmentService(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "equipmentServiceId": product.equipmentServiceId,
        }
        this.myContactsService.deleteFsmEquipmentService(req).subscribe(res => {
          this.getFsmEquipmentService();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  toBack()
  {
    this._Router.navigate(['/fsm/fsmSetting/equipment-types']);
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }



  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
