import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddSkillService } from '../add-skills/add-skill.service';
import { ApprovalService } from '../approval/approval.service';
import { CreateInternalService } from '../recuitment/create-internal-job-posting/create-internal-job.service';
import { InternalJobService } from '../recuitment/internal-job-posting/internal-job-posting.service';
import { JobRequistionService } from '../recuitment/job-requisition/job-requisition.service';
import { NewJobRequistionsService } from '../recuitment/new-job-requisition/new-job-requisition.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-approval-summary',
  templateUrl: './approval-summary.component.html',
  styleUrls: ['./approval-summary.component.css']
})
export class ApprovalSummaryComponent implements OnInit {
  interviewId: any;
  activeCategory=1;
  approvalId: any;
  pageNo=1;
  total=0;
  rows = 50;
  pageSize=100;
  search:any="";
  public size = {
    pageNo:this.pageNo,
    pageSize:this.pageSize
  }

  constructor(readonly approvalService:ApprovalService ,
    public addSkillService:AddSkillService,
    readonly router: Router,
    readonly jobRequistionService:JobRequistionService,
      private _location: Location,
      readonly spinner:NgxSpinnerService,
    readonly activatedRoute:ActivatedRoute,
    readonly newJobRequistionsService:NewJobRequistionsService, readonly internalJobService:InternalJobService,
    readonly createInternalService:CreateInternalService,) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.interviewId = params["interviewId"];
    });
    this.getApprovalList();
  }
 homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getApprovalList(){
    this.spinner.show();
    const data= {
      applicationDateFrom: "",
        applicationDateFromSearchType: "Equals",
        applicationDateTo: "",
        applicationDateToSearchType: "Equals",
        applicationId:  this.interviewId,
        applyingPartyId: "",
    
        approverPartyId: "",
   
        jobRequisitionId: "",
        statusId: "",
    
    }

    
    this.approvalService.getInternalJobList(this.size,data).subscribe((res:any)=> {
      this.approvalId = res.data[0];
      this.spinner.hide();
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
