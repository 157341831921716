import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';


declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-preferences-internal-note',
  templateUrl: './preferences-internal-note.component.html',
  styleUrls: ['./preferences-internal-note.component.css']
})
export class PreferencesInternalNoteComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  createPortalPageList: any;
  delete: boolean;
  editCommEvent: any;
  addRole: any;
  addContent: any;
  communicationEventId: any;
  communicationContentUploadById: any;
  search="";

  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  eventRoles: any;
  existingContentIds: any;
  existingContentIdsArray:any[]=[];
  roleTypeIdPre: any;
  roleTypeIdPreArray: any[]=[];
  eventRoleType: any;
  eventRoleTypeArray:any[]=[];
  parentContentId: any;
  parentContentIdArray:any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  party: { label: string; value: string; }[];
  fileData: File;
  showPortal: boolean;
  widthProduct: any;
  parentPortalPageId: any;
  createPortal: any;
  estCompletion: any;
  partyId: string;
  estStart: any;
  calendarEventForm1: FormGroup;
  EventType: any;
  EventTypeArray: any[]=[];
  ScopeList: any;
  ScopeListArray: any[]=[];
  CalStatusList: any;
  workEffortId: any;
  CalStatusListArray: any[]=[];
  isForm: boolean;
  isButton: boolean;
  isUpdate: boolean;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  calendarValue: any;
  partyIds: any[];
  date: {
    startDate: string,
    endDate: string
  };
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,    
    readonly trainingApprovalService: TrainingApprovalService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
  ) {
    this.editCommEvent = this._FormBuilder.group({
      partyTo: [''],
      subject: [''],
      content: [''],
    });
    this.addRole = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
    });
    this.addContent = this._FormBuilder.group({
      contentID: [''],
      file: [''],
      parentContent: [''],
    });
    this.party = [
      {
        label:'THE PRIVILEGED ADMINISTRATOR ',
        value: 'admin'
      }
    ]
    this.createPortal = this._FormBuilder.group({
      portalPageName: [''],
      description: [''],
    });
    
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.communicationEventId = params.communicationEventId;
      this.parentPortalPageId = params.parentPortalPageId;
    })
    this.getCreatePortalPage();
    this.getCommunicationContentUploadById();
    this.getEventRoles();
    this.getexistingContentIds();
    this.getRoleTypeIdPre();
    this.getPartyId();
    this.getEvents();
    this.parentContentIdList();
  }
   
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }

  ];

  getEvents(): void {
    this.spinner.show();

    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('yyyy-MM-dd HH:mm:ss'),
        endDate: moment().endOf('month').format('yyyy-MM-dd HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('yyyy-MM-dd HH:mm:ss'),
        endDate: moment().endOf('day').format('yyyy-MM-dd HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('yyyy-MM-dd HH:mm:ss'),
        endDate: moment().endOf('week').format('yyyy-MM-dd HH:mm:ss')
      };
        break;
    }
    this.trainingApprovalService.getCommunicationCalender()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.events = data.data.map(value => {
            return {
              ...value, ...{
                start: new Date(value.fromDate),
                end: "",
                title: value.getWorkEffortName[0].workEffortName,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });

  }
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {


    this.workEffortId = event.event.getWorkEffortName[0].workEffortId,
    this.calendarValue = {
      eventName: event.getWorkEffortName[0].workEffortName,
      // desc: "",
      /// eventType:  "",
      // status:  event.,
      // scope: event.,
      startDate: event.fromDate ? moment(event.fromDate).format('yyyy-MM-ddTHH:mm') : '',
     // compDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-ddTHH:mm') : '',
    };
    this.isForm = true;
    this.isUpdate = true;

    this.calendarEventForm1.patchValue({
      eventName: event.getWorkEffortName[0].workEffortName,
      // desc: "",
      /// eventType:  "",
      // status:  event.,
      // scope: event.,
      startDate: event.fromDate ? moment(event.fromDate).format('yyyy-MM-ddTHH:mm') : '',
     // compDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-ddTHH:mm') : '',


    });
  //  this.getParticipants();

  }

  cancelCalenderEvent(): void {
    this.spinner.show();

    const requestData = {

      "currentStatusId": "CAL_CANCELLED",
      "workEffortId": this.workEffortId

    }

    this.myContactsService.cancelCalenderEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  addEvent(): void {
   this._Router.navigate(["/crm/Preferences/mailPreferences"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  addNew(): void {
    this.isForm = true;
    this.isButton = false;
    this.isUpdate = false;
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  activeDayIsOpen: boolean = true;
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  createPortalPage(): void {
    this.spinner.show();

    const requestData = {
      "description": this.createPortal.value.description,
      "parentPortalPageId": "SFA_MAIN",
      "portalPageName":this.createPortal.value.portalPageName,
      "sequenceNum": "1"
    }

    this.myContactsService.createPortalPage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCreatePortalPage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  allPage(){
    this._Router.navigate(["/crm/Preferences/preferencesall"],
    {queryParams : {communicationEventId : this.communicationEventId}})
  }
  emailPage(){
    this._Router.navigate(["/crm/Preferences/emailPreferences"],
    {queryParams : {communicationEventId : this.communicationEventId}})
  }
  notePage(){
    this._Router.navigate(["/crm/Preferences/notePreferences"],
    {queryParams : {communicationEventId : this.communicationEventId}})
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  sendSaveCommunicationEventSave(): void {
    this.spinner.show();

    const requestData = {
      "communicationEventId": this.communicationEventId,
      "communicationEventTypeId": "COMMENT_NOTE",
      "content":this.editCommEvent.value.content,
      "contentMimeTypeId": "text/plain",
      "datetimeStarted": this.datePipe.transform(new Date(),'yyyy-MM-dd hh:mm:ss'),
      "dummy": "",
      "form": "list",
      "my": "My",
      "parentCommEventId": "",
      "partyIdFrom": this.editCommEvent.value.partyTo,
      "partyIdTo": this.editCommEvent.value.partyTo,
      "portalPageId": "",
      "save": "",
      "send": "",
      "statusId": "COM_PENDING",
      "subject": this.editCommEvent.value.subject
    }

    this.myContactsService.sendSaveCommunicationEventSave(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Saved Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  sendSaveCommunicationEvent(): void {
    this.spinner.show();

    const requestData = {
      "communicationEventId": this.communicationEventId,
      "communicationEventTypeId": "COMMENT_NOTE",
      "content":this.editCommEvent.value.content,
      "contentMimeTypeId": "text/plain",
      "datetimeStarted": this.datePipe.transform(new Date(),'yyyy-MM-dd hh:mm:ss'),
      "dummy": "",
      "form": "list",
      "my": "My",
      "parentCommEventId": "",
      "partyIdFrom": this.editCommEvent.value.partyTo,
      "partyIdTo": this.editCommEvent.value.partyTo,
      "portalPageId": "",
      "save": "",
      "send": "",
      "statusId": "COM_ENTERED",
      "subject": this.editCommEvent.value.subject,

      

    }

    this.myContactsService.sendSaveCommunicationEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createUpload() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.createUpload(formData, this.addContent.value.contentID, this.addContent.value.parentContent).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getCommunicationContentUploadById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  createCommunicationEventRole(): void {
    this.spinner.show();

    const requestData = {
      "partyId":  this.addRole.value.partyID,
      "roleTypeId":   this.addRole.value.roleTypeID,
    }

    this.myContactsService.createCommunicationEventRole(requestData,this.communicationEventId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.getCreatePortalPage();
        this.getEventRoles();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteCommunicationEventRole(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.myContactsService.deleteCommunicationEventRole(this.communicationEventId,product.partyId,product.roleTypeId).subscribe(res => {
          this.getCommunicationContentUploadById(); 
          this.getEventRoles();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  PortletPage(parentPortalPageId){
    this._Router.navigate(["/crm/Preferences/viewPreferences"],
    {queryParams : {parentPortalPageId : parentPortalPageId}})
  }
  deleteUpload(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.myContactsService.deleteUpload(product.communicationContentUploadId).subscribe(res => {
          this.getCommunicationContentUploadById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updatePortal(product) {
    this.widthProduct = product ;
    this.spinner.show();

    this.spinner.hide();
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getexistingContentIds(){
    this.spinner.show();
    this.myContactsService.getexistingContentIds().subscribe(res=>{
      this.existingContentIds = res.data;
      this.spinner.hide();
      for (const value of this.existingContentIds) {
        this.existingContentIdsArray.push({
          label: value.description,
          value: value.contentId
        })
      }
    })
  }
  getRoleTypeIdPre(){
    this.spinner.show();
    this.myContactsService.getRoleTypeIdPre().subscribe(res=>{
      this.roleTypeIdPre = res.data[0].node;
      this.spinner.hide();
      for (const value of this.roleTypeIdPre) {
        this.roleTypeIdPreArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getEventRoleType(){
    this.spinner.show();
    this.myContactsService.getEventRoleType().subscribe(res=>{
      this.eventRoleType = res.data;
      this.spinner.hide();
      for (const value of this.eventRoleType) {
        this.eventRoleTypeArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  parentContentIdList(){
    this.spinner.show();
    this.myContactsService.getparentContentIdList().subscribe(res=>{
      this.parentContentId = res.data;
      this.spinner.hide();
      for (const value of this.parentContentId) {
        this.parentContentIdArray.push({
          label: value.contentIdTo,
          value: value.contentId
        })
      }
    })
  }
  getCommunicationContentUploadById() {
    this.spinner.show();
    this.myContactsService.getCommunicationContentUploadById(this.communicationEventId).subscribe(res => {
      this.communicationContentUploadById = res.data[0].getCommunicationContentUploadById;
      this.spinner.hide();
    })
  }
  getEventRoles(){
    this.spinner.show();
      this.myContactsService.getEventRoles(this.user,this.communicationEventId).subscribe((res:any)=> {
        this.eventRoles = res.data[0].getCommunicationEventOrders;
        this.spinner.hide();
      
      })
     
     }
  getCreatePortalPage() {
    this.spinner.show();
    this.myContactsService.getCreatePortalPage().subscribe(res => {
      this.createPortalPageList = res.data[0].getCreatePortalPage;
      if(this.createPortalPageList.originalPortalPageId==null){
        this.delete = true;
      }
      else{
        this.delete = false;
      }
      this.spinner.hide();

    })
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
