<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Approval ></span>
                <span class="color-black pl-1"> Update Approval </span>
                <button type="submit" (click)="cancelSubmit();"
                class="btn btn-danger" style="margin-left: 74%;">Cancel</button>
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">
          
                      <li  [ngClass]="activeCategory==2?'active':''" ><a>
                        Update Approval</a></li>
                       
                     
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="updateApprovalForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -2%; ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Application Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" placeholder="Application Id"
                                                [value]="updateValue.applicationId" readonly>
                                            </div>
                                            <div class="col-lg-2 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -2%; ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Approver Party Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" readonly class="form-control"
                                                placeholder="Approver Party Id"
                                                [value]="updateValue.approverPartyId ?updateValue.approverPartyId : 'N/A'">
 

                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group " style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -2%; ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Application Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" readonly
                                                placeholder="Application Date"
                                                [value]="updateValue.applicationDate ? updateValue.applicationDate : 'N/A'">
                                            </div>
                                            <div class="col-lg-2 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -2%; ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Job Requisition Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" readonly
                                                placeholder="Job Requisition Id"
                                                [value]="updateValue.jobRequisitionId ? updateValue.jobRequisitionId :'N/A'">
 

                                            </div>
                                         
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group" style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: -2%; ">
                                                <label for="exampleInputEmail1" style=" color:black !important;
                                                font-size: 13px!important; font-weight:600!important;">Status <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Status" filter="true" [options]="statusIds"
                                                    formControlName="statusId">
                                                </p-dropdown>
                                                <small class="text-danger"
                                                    *ngIf="!!updateApprovalForm.controls.statusId.invalid && !!updateApprovalForm.controls.statusId.touched">Status
                                                    is required.</small>
                                            </div>
                                            <div class="col-lg-2"></div>
                                           
                                        </div>
                                    </div>
                                   
                                
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 22%;">
                                <button type="submit" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                              
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 