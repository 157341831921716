<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Payment Overview -[ID NO] </span>
                <button type="button" class="btn btn-outline-secondary">Show Old</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                        <button type="button" class="btn btn-outline-secondary">Find</button>
                        <button type="button" class="btn btn-outline-secondary">Overview</button>
                        <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active" routerLink="/accounting/payments/not-paid-header">Header</button>
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active" routerLink="/accounting/payments/not-paid-application">Applications</button>
                    </div>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"  data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                          <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="headingOne">
                                              <h4 class="panel-title">
                                              <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Payment Header
                                                <!-- <span class="detail-created">Update</span> -->
                                              </a>       
                                            </h4>
                                            </div>
                                            <div id="collapseOne" class="panel-collapse collapse in show" role="tabpanel" aria-labelledby="headingOne">
                                              <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                   <div class="color-black container">
                                                       <div class="row">
                                                        <p class="col-lg-2 col-12">Payment Type ID</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">Customer Deposit</span>
                                                        <p class="col-lg-2 col-12">Status</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">Voided</span>
                                                        <p class="col-lg-2 col-12">From Party</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">vhij, dih [10000]</span>
                                                        <p class="col-lg-2 col-12">Reference No </p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                        <p class="col-lg-2 col-12">Amount</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">US$2,222.00</span>
                                                        <p class="col-lg-2 col-12">Effective Date </p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">27/08/20</span>
                                                        <p class="col-lg-2 col-12">Override Gl Account ID</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                        <p class="col-lg-2 col-12">Payment Method Type </p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">Credit Card</span>
                                                        <p class="col-lg-2 col-12">Payment Method ID</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">AmEx 0005 [AMEX_01]</span>
                                                        <p class="col-lg-2 col-12">To Party </p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12">Your Company Name Here [Company]</span>
                                                        <p class="col-lg-2 col-12">Payment Preference ID</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                        <p class="col-lg-2 col-12">Actual Currency Amount </p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                        <p class="col-lg-2 col-12">Comments	</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                        <p class="col-lg-2 col-12">Payment Gateway Response ID	</p>
                                                        <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                    </div>     
                                                   </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="headingThree">
                                              <h4 class="panel-title">
                                              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Payments Applied US$0.00 Open US$2,222.00
                                                <!-- <span class="detail-created">Create</span> -->
                                              </a>
                                            </h4>
                                            </div>
                                            <div id="collapseThree" class="panel-collapse show collapse" role="tabpanel" aria-labelledby="headingThree">
                                              <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        No Records Found
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
            </div> -->
        </div>
    </div>
</div>
