import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
@Component({
  selector: 'app-edit-content-type-attribute',
  templateUrl: './edit-content-type-attribute.component.html',
  styleUrls: ['./edit-content-type-attribute.component.css']
})
export class EditContentTypeAttributeComponent implements OnInit {
  activeCategory=4;

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  contentAttributeForm: FormGroup;
  contentTypeAttr: any;
  CalatalogContentTypeId: any;
  CalatalogContentTypeIdArray: any[]=[];

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,

  ) 
  {
    this.contentAttributeForm=this._FormBuilder.group({
      description:"",
      contentTypeID:"",
      attrName:"",
  })
   }

  ngOnInit(): void {
    this.getContentTypeAttr();
    this.getCalatalogContentTypeId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  getCalatalogContentTypeId() {
    this.spinner.show();
    this.accountsService.getCalatalogContentTypeId().subscribe(res => {
      this.CalatalogContentTypeId = res.data.contentTypeId;
      this.spinner.hide();
      for (const value of this.CalatalogContentTypeId) {
        this.CalatalogContentTypeIdArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }
  addContentTypeAttr() {
    this.spinner.show();
    const requestData = {
      "attrName":  this.contentAttributeForm.value.attrName ,
  "contentTypeId": this.contentAttributeForm.value.contentTypeID ,
  "description":this.contentAttributeForm.value.description ,
    }

    this.myContactsService.addContentTypeAttr(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.contentAttributeForm.reset();
        this.getContentTypeAttr();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  removeContentTypeAttr(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "attrName": product.attrName ,
          "contentTypeId": product.contentTypeId ,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeContentTypeAttr(req).subscribe(res => {
          this.getContentTypeAttr();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getContentTypeAttr() {
    this.spinner.show();
    this.myContactsService.getContentTypeAttr().subscribe(res => {
      this.contentTypeAttr = res.data.getContentTypeAttr;
      this.spinner.hide();

    })
  }
  openPurposeOperation()
  {
   this._Router.navigate(['/content/content-setup/edit-content-purpose-operation']);
   
  }

  openContentOperation()
  {
   this._Router.navigate(['/content/content-setup/edit-content-operation']);

  }

  openAssociationPredicate()
  {
   this._Router.navigate(['/content/content-setup/edit-content-assoication-predicate']);

  }

  openTypeAttribute()
  {
   this._Router.navigate(['/content/content-setup/edit-content-type-attribute']);

  }

  openPurposeType()
  {
   this._Router.navigate(['/content/content-setup/edit-content-purpose-type']);

  }

  openAssociationType()
  {
   this._Router.navigate(['/content/content-setup/edit-content-association-type']);

  }

  openPermission()
  {
   this._Router.navigate(['/content/content-setup/permission']);

  }

  openContentType()
  {
   this._Router.navigate(['/content/content-setup']);

  }


 customSort(event: SortEvent): void {
   event.data.sort((data1, data2) => {
       const value1 = data1[event.field];
       const value2 = data2[event.field];
       let result = null;

       if (value1 == null && value2 != null) {
           result = -1;
       }
       else if (value1 != null && value2 == null) {
           result = 1;
       }
       else if (value1 == null && value2 == null) {
           result = 0;
       }
       else if (typeof value1 === 'string' && typeof value2 === 'string') {
           result = value1.localeCompare(value2);
       }
       else {
           result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
       }
       return (event.order * result);
   });

   
}

ngOnDestroy(): void {
 this.spinner.hide();
   }

 changeactiveCategory(tab: number)
 {
   this.activeCategory = tab;
 
 }

}
