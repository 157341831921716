<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Revisions</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openViewTree()"><a>
                                View Tree</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openEdit()" ><a>
                                Edit</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openApprovals()" ><a>
                                Approvals</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openRevisions()"><a>
                                Revisions</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openCreateNewRoot()"><a>
                                Create New Root CompDoc Template</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="openApprovals()"><a>
                                View Waiting Approvals</a></li>
                                        
                                
                           

                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==4">
                                            <div class="create-new-leade">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="bg-white color-grey w-100 ">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form *ngIf="this.valShow" class="w-100">
                                                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                    Revisions List Page

                                                                                    <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:72%!important;"
                                                                                    data-toggle="modal" data-target="#addEditContentTypePopup">Create Content Type
                                                                                    </button> -->
                                                                                   
                                                                                </div>
                                                                               
                                                                                
                                                
                                                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">
                                                
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="ListContentRevisionsAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                
                                                                                                                    Content ID		

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Content Revision Seq ID						
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Committed By Party ID						
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Comments					
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Action		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    <span class=""
                                                                                                                 >
                                                                                                                  {{product.contentId}}
                                                                                                                    </span>
                                                
                                                                                                                </div>
                                                                                                            </td>
                                                
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.contentRevisionSeqId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.committedByPartyId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.comments}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                <span>
                                                                                                                    <small style="margin-right:12px;color: #0d3769 !important;font-weight: 600;cursor:pointer;"  data-target="#revisionListPopup" data-toggle="modal" (click)="revisionListPatch()">Edit</small>
                                                                                                                    <small style="margin-right:12px;color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="changeTable()">Items</small>
                                                                                                                    <!-- <small style="margin-right:12px;color: #0d3769 !important;font-weight: 600;cursor:pointer;">Create</small> -->
                                                                                                                    <small style="margin-right:12px;color: #0d3769 !important;font-weight: 600;cursor:pointer;" (click)="openViewTree()">Tree</small>
                                                                                                                </span>  
                                                                                                               
                                                                                                                
                                                
                                                                                                            </td>
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No Record
                                                                                                                Found</h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>  
                                                                            </div>
                                                                            
                                                                        </form>
                                                                        <div *ngIf="!this.valShow" class="mt-4 pb-5">
                                                                            <form class="w-100">
                                                                                <div class="w3-card-4 classCardView mt-2 w-100">
                                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                        Revisions List Page
    
                                                                                        <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:72%!important;"
                                                                                        data-toggle="modal" data-target="#addEditContentTypePopup">Create Content Type
                                                                                        </button> -->
                                                                                       
                                                                                    </div>
                                                                                   
                                                                                    
                                                    
                                                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                        <div class="col-lg-12 col-12">
                                                                                            <div class="form-group">
                                                    
                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="ListContentRevisionsAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                    
                                                                                                                        Content ID		
    
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                    
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Content Revision Seq ID						
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Item Content ID						
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                    
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Old Data Resource ID				
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        New Data Resource ID				
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
    
                                                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Action		
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                
                                                    
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </p-checkbox>
                                                                                                                        <span class=""
                                                                                                                     >
                                                                                                                      {{product.contentId}}
                                                                                                                        </span>
                                                    
                                                                                                                    </div>
                                                                                                                </td>
                                                    
                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                    {{product.contentRevisionSeqId}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                    {{product.contentId}}
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                  
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                 
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                    <span>
                                                                                                                        <small style="margin-right:12px;color: #0d3769 !important;font-weight: 600;cursor:pointer;"  data-target="#revisionListItemPopup" data-toggle="modal" (click)="revisionListPatchNew()">Edit</small>
                                                                                                                    </span>  
                                                                                                                   
                                                                                                                    
                                                    
                                                                                                                </td>
                                                    
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                    
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No Record
                                                                                                                    Found</h4>
                                                                                                            </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>
                                                    
                                                                                                    <p class="paginate_data">
                                                                                                        View per page </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>  
                                                                                </div>
                                                                                
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                            </div>
                                            
                                            
                                                
                                               
                                            
                                        </div>

                                        

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="revisionListItemPopup" tabindex="-1" role="dialog" aria-labelledby="createNewInstancesTemplatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
              
                <span class="color-black pl-1">Update List Item
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                               
                                <li>
                                    <a>Update List Item </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="revisionListItemForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Content ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content ID"
                                                        formControlName="contentID"   [readonly]="surveyIdValue">
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Content Revision Seq ID	
		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content Revision Seq ID	"
                                                        formControlName="contentRevisionSeqID"   [readonly]="surveyIdValue">
                                                       
                                                    
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Item Content ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Item Content ID"
                                                        formControlName="itemContentID">
                                                      
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Old Data Resource ID	
		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Old Data Resource ID"
                                                        formControlName="oldDataResourceID">
                                                    
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            New Data Resource ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter  New Data Resource ID"
                                                        formControlName="newDataResourceID">
                                                      
                                                    </div>
                                                  
                                                   
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button  type="submit" class="btn btn-secondary submit-btn"  (click)="updateContentRevisionItem()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="revisionListPopup" tabindex="-1" role="dialog" aria-labelledby="createNewInstancesTemplatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
              
                <span class="color-black pl-1">Update Instance Template
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                               
                                <li>
                                    <a>Update Instance Template </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="revisionListForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Content ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content ID"
                                                        formControlName="contentID"   [readonly]="surveyIdValue">
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Content Revision Seq ID	
		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content Revision Seq ID	"
                                                        formControlName="contentRevisionSeqID"   [readonly]="surveyIdValue">
                                                       
                                                    
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Committed By Party ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="committedByPartyID" 
                                                        placeholder="Committed By Party ID" [options]="partyIdArray">
                                                        </p-dropdown>
                                                      
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Comments	
		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Comments"
                                                        formControlName="Comments">
                                                    
                                                    </div>
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button  type="submit" class="btn btn-secondary submit-btn"  (click)="updateContentRevision()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
 <ngx-spinner></ngx-spinner> 

