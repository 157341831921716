<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Employee </span>
                <span class="color-grey pl-1 cursor-pointer"> > Employee Summary</span>
                <span class="color-black pl-1"> > Create Contact Information</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <ng-container [ngSwitch]="contactInformationTab">
                                                    <ng-container *ngSwitchCase="'initial'">
                                                        <div class="d-flex flex-row">
                                                            <div class="form-group">
                                                                <div class="d-flex flex-row">
                                                                    <label for="contactType" class="mr-5">Select Contact
                                                                        Type</label>
                                                                    <p-dropdown filter="true" [options]="contactTypes"
                                                                        [(ngModel)]="contactInformationValue"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        optionlabel="label"
                                                                        placeholder="Select Contact">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                            <button class="btn btn-secondary cursor-pointer ml-4"
                                                                (click)="prepareContactForm()">Create</button>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'ELECTRONIC_ADDRESS'">
                                                        <form [formGroup]="commonElectronicAddressForm">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="contactPurposes">Contact
                                                                                Purposes</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="contactPurposeIds"
                                                                                optionlabel="label"
                                                                                formControlName="contactMechPurposeTypeId"
                                                                                placeholder="Select Contact">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">
                                                                            {{contactInformationValue==='IP_ADDRESS' ? 'Internet IP Address': contactInformationValue==='DOMAIN_NAME' ? 'Internet Domain Name':contactInformationValue==='WEB_ADDRESS' ? 'Web URL/Address' : contactInformationValue==='INTERNAL_PARTYID' ? 'Internal Note via partyId': contactInformationValue==='FTP_ADDRESS' ? 'Ftp server connection' : contactInformationValue==='LDAP_ADDRESS' ? 'LDAP URL': 'Electronic Address'}}</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="infoString">
                                                                        <p class="validate-field"
                                                                            *ngIf="!!commonElectronicAddressForm.controls.infoString.invalid && !!commonElectronicAddressForm.controls.infoString.touched">
                                                                            {{contactInformationValue==='IP_ADDRESS' ? 'Internet IP Address': contactInformationValue==='DOMAIN_NAME' ? 'Internet Domain Name':contactInformationValue==='WEB_ADDRESS' ? 'Web URL/Address' : contactInformationValue==='INTERNAL_PARTYID' ? 'Internal Note via partyId': contactInformationValue==='FTP_ADDRESS' ? 'Ftp server connection' : contactInformationValue==='LDAP_ADDRESS' ? 'LDAP URL': 'Electronic Address'}}
                                                                            is required!</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="submitCommonElectronicAddress()">Save</button>
                                                            <button type="submit" class="btn btn-danger ml-2"
                                                                (click)="contactInformationTab='initial'">Cancel</button>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'EMAIL_ADDRESS'">
                                                        <form [formGroup]="emailAddressForm">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="contactPurposes">Contact
                                                                                Purposes</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="contactPurposeIds"
                                                                                optionlabel="label"
                                                                                formControlName="contactMechPurposeTypeId"
                                                                                placeholder="Select Contact">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Email
                                                                            Address</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="emailAddress">
                                                                        <p class="validate-field"
                                                                            *ngIf="!!emailAddressForm.controls.emailAddress.invalid && !!emailAddressForm.controls.emailAddress.touched">
                                                                            Email Address is required!</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="submitEmailAddress()">Save</button>
                                                            <button type="submit" class="btn btn-danger ml-2"
                                                                (click)="contactInformationTab='initial'">Cancel</button>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'TELECOM_NUMBER'">
                                                        <form [formGroup]="telecomNumberForm">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="contactPurposes">Contact
                                                                                Purposes</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="contactPurposeIds"
                                                                                optionlabel="label"
                                                                                formControlName="contactMechPurposeTypeId"
                                                                                placeholder="Select Contact">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Area Code</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="areaCode">

                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Contact
                                                                            Number</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="contactNumber">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Extension</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="extension">
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </form>
                                                        <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="submitTelecomNumber()">Save</button>
                                                            <button type="submit" class="btn btn-danger ml-2"
                                                                (click)="contactInformationTab='initial'">Cancel</button>

                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'POSTAL_ADDRESS'">
                                                        <form [formGroup]="postalAddressForm">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="contactPurposes">Contact
                                                                                Purposes</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="contactPurposeIds"
                                                                                optionlabel="label"
                                                                                formControlName="contactMechPurposeTypeId"
                                                                                placeholder="Select Contact">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">To Name</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="toName">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Attention
                                                                            Name</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="attnName">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Address Line
                                                                            1</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="address1">
                                                                        <p class="validate-field"
                                                                            *ngIf="!!postalAddressForm.controls.address1.invalid && !!postalAddressForm.controls.address1.touched">
                                                                            Address Line 1 is required!</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Address Line
                                                                            2</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="address2">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">City</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="city">
                                                                        <p class="validate-field"
                                                                            *ngIf="!!postalAddressForm.controls.city.invalid && !!postalAddressForm.controls.city.touched">
                                                                            City is required!</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <label for="electronicAddress">Zip/Postal
                                                                            Code</label>
                                                                        <input type="text" class="form-control"
                                                                            formControlName="postalCode">
                                                                        <p class="validate-field"
                                                                            *ngIf="!!postalAddressForm.controls.postalCode.invalid && !!postalAddressForm.controls.postalCode.touched">
                                                                            Zip/Postal Code is required!</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="country">Country</label>
                                                                            <p-dropdown filter="true"
                                                                                [options]="countryList"
                                                                                optionlabel="label"
                                                                                formControlName="countryGeoId"
                                                                                placeholder="Select Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="country">State</label>
                                                                            <p-dropdown filter="true" [options]="states"
                                                                                optionlabel="label"
                                                                                formControlName="stateProvinceGeoId"
                                                                                placeholder="Select State">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="submitEmailAddress()">Save</button>
                                                            <button type="submit" class="btn btn-danger ml-2"
                                                                (click)="contactInformationTab='initial'">Cancel</button>

                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 