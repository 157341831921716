<div class="container-fluid main-container-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
         <span class="color-black pl-1 titlePanels">For: Your Company Name Here [{{this.partyId}}] </span> <span></span>
         
        </div>
        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium submargin">
          <div class="submenu">
              <div>
                  <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn buttonClass"
                      routerLinkActive="active" (click)="setup()"> Setup</button>&nbsp;
                  <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn buttonClass"
                      routerLinkActive="active" (click)="accounting()"> Accounting</button>&nbsp;

              </div>

          </div>
      </div>
        <div class=" bg-white color-grey">
           
            <br>
        
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">

                  <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="w3-card-4 classCardView">
                          <div class="header-tabs">
                            
                          
                              <h4 class=" common-styling hCard">
                                  View Transaction
                              </h4>
                              <span class="edit-buttons " style="margin-right: 1%;">
                                <a (click)="downloadPDF()">PDF</a>
                              </span>
                             
    
                            </div>
                            <div class="panel-body" *ngIf="this.dataView">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <div class="article-container">
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Acctg Trans ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12">{{this.acctran}}</span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Transaction Type</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.acctgTransType}}</span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Gl Journal ID	</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12"></span>
    
                                    </div>
    
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Fin Account Trans ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Party ID</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12"></span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Transaction Date</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.transactionDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Is Posted	</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.isPosted}}</span>
    
                                    </div>
                                  
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Receipt ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Shipment ID</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.shipmentId}}</span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Their Acctg Trans ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Voucher Ref	</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12"></span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Description</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.description}}</span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Created Date</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12"></span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Last Modified Date</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Fiscal Gl Type	</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.fiscalGlType}}</span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Group Status</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Role Type ID</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12"></span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">	Scheduled Posting Date</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Posted Date</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.postedDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Physical Inventory ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Payment ID	</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.paymentId}}</span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Work Effort ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12">{{this.dataView.workEffortId}}</span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Fixed Asset ID	</p>
                                      <span
                                        class="color-grey pl-20 col-lg-10 col-12"></span>
    
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Voucher Date</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                    </div>
                                   
    
                                 
    
                                   
    
    
    
                                  </div>
    
                                </div>
                              </div>
                            </div>
                       
                      </div>
                      <br>
                        <div class="w3-card-4 classCardView">
                          <div class="header-tabs">
                            
                           
                              <h4 class=" common-styling hCard">
                                  View Transaction Entries
                                </h4>
                                <span *ngIf="this.dataView.isPosted!='Y'" class="edit-buttons " style="margin-right: 1%;">
                                  <a  (click)="redirectPage()">Create</a>
                                </span>
                                
                              
                            </div>
                              <div class="panel-body" *ngIf="this.dataView">
                                <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                  <div class="form-group" style="    width: 100%;">
                                    <div class="card own-account-table borderTable">
                                      <p-table [value]="accountTransaction" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                        (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                          <tr style="background: #0d3769;">
                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                              <div class="checkbox-align" style="color: white;">
                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                  binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div class="custom-checkbox ui-chkbox ui-widget">
                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                        name="undefined" value="undefined"></div>
                                                    <div role="checkbox"
                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                        class="ui-chkbox-icon ui-clickable"></span></div>
                                                  </div>
                                                  <!---->
                                                </p-checkbox>
                                              
                                        
Acctg Trans Entry Seq ID <p-sortIcon field="code"></p-sortIcon>
                                              </div>
                                              <input id="j_idt727:j_idt728:j_idt731:filter"
                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                value="" autocomplete="off"
                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;">	Gl Account Type <p-sortIcon field="name"></p-sortIcon></div>
                                              <div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                  aria-disabled="false" aria-readonly="false">
                                              </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Gl Account ID <p-sortIcon field="category">
                                              </p-sortIcon></div>
                                              <div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                  aria-disabled="false" aria-readonly="false">
                                              </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">Description <p-sortIcon field="category">
                                          </p-sortIcon></div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">Voucher Ref <p-sortIcon field="category">
                                      </p-sortIcon></div>
                                      <div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </div>
                                    </th>
                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Party ID <p-sortIcon field="category">
                                  </p-sortIcon></div>
                                  <div>
                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                      name="j_idt727:j_idt728:j_idt731:filter"
                                      class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                      value="" autocomplete="off"
                                      aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                      aria-disabled="false" aria-readonly="false">
                                  </div>
                                </th>
                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Product ID <p-sortIcon field="category">
                              </p-sortIcon></div>
                              <div>
                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                  name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off"
                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                  aria-disabled="false" aria-readonly="false">
                              </div>
                            </th>
                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Reconcile Status <p-sortIcon field="category">
                          </p-sortIcon></div>
                          <div>
                            <input id="j_idt727:j_idt728:j_idt731:filter"
                              name="j_idt727:j_idt728:j_idt731:filter"
                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                              value="" autocomplete="off"
                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                              aria-disabled="false" aria-readonly="false">
                          </div>
                        </th>
                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Summary ? <p-sortIcon field="category">
                      </p-sortIcon></div>
                      <div>
                        <input id="j_idt727:j_idt728:j_idt731:filter"
                          name="j_idt727:j_idt728:j_idt731:filter"
                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                          value="" autocomplete="off"
                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                          aria-disabled="false" aria-readonly="false">
                      </div>
                    </th>
                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Debit Credit Flag <p-sortIcon field="category">
                  </p-sortIcon></div>
                  <div>
                    <input id="j_idt727:j_idt728:j_idt731:filter"
                      name="j_idt727:j_idt728:j_idt731:filter"
                      class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                      value="" autocomplete="off"
                      aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                      aria-disabled="false" aria-readonly="false">
                  </div>
                </th>
                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">		Orig Amount <p-sortIcon field="category">
              </p-sortIcon></div>
              <div>
                <input id="j_idt727:j_idt728:j_idt731:filter"
                  name="j_idt727:j_idt728:j_idt731:filter"
                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                  value="" autocomplete="off"
                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                  aria-disabled="false" aria-readonly="false">
              </div>
            </th>
            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Amount <p-sortIcon field="category">
          </p-sortIcon></div>
          <div>
            <input id="j_idt727:j_idt728:j_idt731:filter"
              name="j_idt727:j_idt728:j_idt731:filter"
              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
              value="" autocomplete="off"
              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
              aria-disabled="false" aria-readonly="false">
          </div>
        </th>
        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;">	Action <p-sortIcon field="category">
        </p-sortIcon></div>
        <div>
          <input id="j_idt727:j_idt728:j_idt731:filter"
            name="j_idt727:j_idt728:j_idt731:filter"
            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
            value="" autocomplete="off"
            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
            aria-disabled="false" aria-readonly="false">
        </div>
      </th>
    
                                          </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                          <tr>
                                            <td  [ngStyle]="{'width':'170px'}">
                                              <div class="checkbox-align">
                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                  binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div class="custom-checkbox ui-chkbox ui-widget">
                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                        name="undefined" value="undefined"></div>
                                                    <div role="checkbox"
                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                        class="ui-chkbox-icon ui-clickable"></span></div>
                                                  </div>
                                                </p-checkbox>
                                                {{product.acctgTransEntrySeqId}}
                                               
                                              </div>
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.accountName}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.description}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.partyId}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.productId}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.reconcileStatusId}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.debitCreditFlag}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.origAmount}}</td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.amount}}</td>
                                            <td [ngStyle]="{'width':'170px'}"  *ngIf="this.dataView.isPosted!='Y'">
                                              <svg class="hover"
                                              (click)="updateTransaction(product.acctgTransEntrySeqId)"
                                               xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                               viewBox="0 0 21.223 21.222">
                                               <g transform="translate(0 -0.004)">
                                                 <g transform="translate(0 1.52)">
                                                   <path
                                                     d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                     transform="translate(0 -34.137)" />
                                                 </g>
                                                 <g transform="translate(4.548 0.004)">
                                                   <g transform="translate(0 0)">
                                                     <path
                                                       d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                       transform="translate(-102.409 -0.004)" />
                                                   </g>
                                                 </g>
                                               </g>
                                             </svg>
                                              <svg xmlns="http://www.w3.org/2000/svg"
                                              (click)="deletetransactionEntries(product.acctgTransEntrySeqId)"
                                              class="ml-20" width="16"
                                              height="16" viewBox="0 0 26 26">
                                              <defs>
                                                  <style>
                                                      .a {
                                                          fill: #f44336;
                                                      }

                                                      .b {
                                                          fill: #fafafa;
                                                          margin-left: 20px;
                                                      }
                                                  </style>
                                              </defs>
                                              <path class="a fill-color"
                                                  d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                  d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                  transform="translate(-147.576 -147.576)" />
                                          </svg>
                                            </td>
                                          
                                         
                                          </tr>
                                        </ng-template>
                                      </p-table>
                                      <p class="paginate_data">
                                        View per page
                                       
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        
                        </div>
                        <br>
                        
                       
               
                      
                 
                       
                    
                       
                     
                     
                       
                      
                       
                      
                      
                      </div>
                       
                     
                        
                      </div>
                    </div>
                
  
                </form>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>

  
  