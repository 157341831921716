<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/{{agreementType}}/customer-agreeement">Agreements
                </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/summary-customer-agreeement"
                    [queryParams]="{agreementID:agreementId}">>Agreements Summary </span>
                <span class="color-black pl-1"> > Agreement Role </span>
                <button class="btn btn-danger" style="margin-left: 55%;"
                (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid" >
                    <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Agreement Role</a></li>
                
                    </ul>
                  </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                               
                                                <div 
                                                    aria-labelledby="headingOne">
                                                    <div class="panel-body">


                                                        <form [formGroup]="roleFrom">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group " style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -16%;
                                                                        font-size: 13px;">
                                                                            <label for="partyId">Party Id</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            formControlName="partyId"
                                                                            [options]="partyIds" optionlabel="label"
                                                                            placeholder="select Party ID">
                                                                        </p-dropdown>
                                                                        <small class="text-danger"
                                                                            *ngIf="!!roleFrom.controls.partyId.invalid && !!roleFrom.controls.partyId.touched">Party
                                                                            Id is required</small>
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 13px;">
                                                                            <label for="partyId">Role Type Id</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <p-dropdown filter="true"
                                                                                formControlName="roleTypeId"
                                                                                [options]="roleTypeIds"
                                                                                optionlabel="label"
                                                                                placeholder="select Role Type ID ">
                                                                            </p-dropdown>
                                                                            <small class="text-danger"
                                                                                *ngIf="!!roleFrom.controls.roleTypeId.invalid && !!roleFrom.controls.roleTypeId.touched">Role
                                                                                Type Id is required</small>
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </form>
                                                        <div class="main-submit-button" style="margin-right: 34%;">
                                                            <button type="submit" (click)="submitRole()"
                                                                class="btn btn-secondary submit-btn cursor-pointer">Add</button>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 