import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-commission-agreement',
  templateUrl: './commission-agreement.component.html',
  styleUrls: ['./commission-agreement.component.css']
})
export class CommissionAgreementComponent implements OnInit {

  constructor(  readonly router: Router) { }

  ngOnInit(): void {
    
  }
}
