<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Communication Work
                    Efforts</span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger"
                    style="margin-left: 73%;">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">

                        <li [ngClass]="activeCategory==2?'active':''"><a>
                                Communication Work Efforts</a></li>



                    </ul>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="form-group">
                            <div class="row">
                                <form class="w-100" [formGroup]="createWorkEffort">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Communication Event ID
    
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Communication Event ID"
                                                    formControlName="commEventId">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Work Effort ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Work Effort ID" filter="true"
                                                    formControlName="workEffortId">

                                                </p-dropdown>
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Quick Assign Party ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Quick Assign Party ID" filter="true"
                                                    formControlName="quickAssignParty">

                                                </p-dropdown>
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Name
                                                     </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Name"
                                                        formControlName="name">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Description
    
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Description"
                                                    formControlName="description">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Type</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Type" filter="true"
                                                    formControlName="type">

                                                </p-dropdown>
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Status</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Status" filter="true"
                                                    formControlName="status">

                                                </p-dropdown>
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Purpose</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Purpose" filter="true"
                                                    formControlName="purpose">

                                                </p-dropdown>
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Priority</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Priority" filter="true"
                                                    formControlName="priority">

                                                </p-dropdown>
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Scope</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Scope" filter="true"
                                                    formControlName="scope">

                                                </p-dropdown>
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Percent Complete</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Percent Complete"
                                                        formControlName="percentComplete">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                       Estimated Start Date
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Content"
                                                    formControlName="estimStartDate">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Actual Start Date
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Content"
                                                    formControlName="actualStartDate">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Estimated Completion Date
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Content"
                                                    formControlName="estComleteDate">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Actual Completion Date
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Content"
                                                        formControlName="actualCompleteDate">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Reason
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Reason"
                                                        formControlName="reason">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Web Site ID
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Web Site ID"
                                                    formControlName="websiteId">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Work Effort Parent ID
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Work Effort Parent ID"
                                                        formControlName="workEffortParentID">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Show As Enum ID
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Show As Enum ID"
                                                        formControlName="showAsEnumID">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">

                                                        Send Notification Email
                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Show As Enum ID"
                                                        formControlName="showAsEnumID">
    
                                                </div>
                                                
                                               
                                            </div>
                                        </div>
                                        
                                       

                                      
                                       
                                      
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Reason

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Reason"
                                                        formControlName="reason">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Web Site ID

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Web Site ID"
                                                        formControlName="websiteId">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Work Effort Parent ID

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Work Effort Parent ID"
                                                        formControlName="workEffortParentID">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Show As Enum ID

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Show As Enum ID"
                                                        formControlName="showAsEnumID">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">



                                                    Send Notification Email</label>
                                                <br>
                                                <p-dropdown placeholder="Send Notification Email" filter="true"
                                                    formControlName="sendNotificationEmail">

                                                </p-dropdown>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Location Desc

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Location Desc"
                                                        formControlName="locationDesc">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Estimated Milli Seconds

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Estimated Milli Seconds"
                                                        formControlName="estimatedMillSeconds">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Estimated Setup Millis

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Estimated Setup Millis"
                                                        formControlName="estimatedSetupMillis">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Estimate Calc Method

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Estimate Calc Method"
                                                        formControlName="estimateCalcMethod">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Actual Milli Seconds

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Actual Milli Seconds"
                                                        formControlName="actualMilliSeconds">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Actual Setup Millis

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Actual Setup Millis"
                                                        formControlName="actualSetupMillis">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Total Milli Seconds Allowed

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Total Milli Seconds Allowed"
                                                        formControlName="totalMilliSecond">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Total Money Allowed

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Total Money Allowed"
                                                        formControlName="totalMoney">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">



                                                    Currency</label>
                                                <br>
                                                <p-dropdown placeholder="Currency" filter="true"
                                                    formControlName="currency">

                                                </p-dropdown>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Special Terms

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Special Terms"
                                                        formControlName="specialTerms">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Time Transparency

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Time Transparency"
                                                        formControlName="timeTransparency">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Universal ID

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Universal ID"
                                                        formControlName="universalID">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Source Reference ID

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Source Reference ID"
                                                        formControlName="sourceReferenceID">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Fixed Asset ID

                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Fixed Asset ID"
                                                        formControlName="fixedAssetID">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">



                                                    Facility ID</label>
                                                <br>
                                                <p-dropdown placeholder="Facility ID" filter="true"
                                                    formControlName="facilityID">

                                                </p-dropdown>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Info Url
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Info Url"
                                                        formControlName="infoURL">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">



                                                    Temporal Expression</label>
                                                <br>
                                                <p-dropdown placeholder="Temporal Expression" filter="true"
                                                    formControlName="temporalExpression">

                                                </p-dropdown>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Service Loader Name
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Service Loader Name"
                                                        formControlName="serviceLoader">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Quantity To Produce
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Quantity To Produce"
                                                        formControlName="quantityToProduce">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Quantity Produced
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Quantity Produced"
                                                        formControlName="quantityProduced">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Quantity Rejected
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Quantity Rejected"
                                                        formControlName="quantityRejected">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Reserv Persons
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Reserv Persons"
                                                        formControlName="reservPersons">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Reserv2nd P P Perc
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Reserv2nd P P Perc"
                                                        formControlName="reserv2nPPPerc">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Reserv Nth P P Perc
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Reserv Nth P P Perc"
                                                        formControlName="reservNthPPPerc">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Accommodation Map ID
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Accommodation Map ID"
                                                        formControlName="accommodationMapID">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Accommodation Spot ID
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Accommodation Spot ID"
                                                        formControlName="spotId">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">
                                                    Sequence Num
                                                </label>
                                                <div class="">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Sequence Num"
                                                        formControlName="sequenceNum">
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">



                                                    Requirement ID</label>
                                                <br>
                                                <p-dropdown placeholder="Requirement ID" filter="true"
                                                    formControlName="requirementID">

                                                </p-dropdown>

                                            </div>

                                        </div>
                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 85%;">
                                    <button type="submit" class="btn btn-secondary submit-btn">Save</button>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>