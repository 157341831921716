import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import{InventoryLabelsService} from 'src/app/warehouse/inventory-labels/inventory-labels.service';

@Component({
  selector: 'app-detail-inventory-label-summary',
  templateUrl: './detail-inventory-label-summary.component.html',
  styleUrls: ['./detail-inventory-label-summary.component.css']
})
export class DetailInventoryLabelSummaryComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  activeCategory=1;
  id: any;
  groupid: any;
  parentId: any;
  fGroupName: any;
  des: any;
  hTable: any;
  date: any;
  invenId: any;
  editMode: boolean;
  inventoryLabel:FormGroup;
  detailLabelid: any;

  inventoryItemLabelList: any=[];
  inventoryItemLabelTypeId:any=[];
  inventoryItemLabelTypeIdArray: any[]=[];
  constructor(
    readonly activatedRoute:ActivatedRoute,
  
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly InventoryLabelsService: InventoryLabelsService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder) {
      this.inventoryLabel = this._FormBuilder.group({
        inventoryItemLabelId: ['', [Validators.required]],
        inventoryItemLabelTypeId: [null],
        description: [''],
      });}

  ngOnInit(): void {
    this.editMode=true;
   
    this.getfacilityGroupTypeList();
    this.getInventoryItemLabelTypeId();
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.detailLabelid = params["id"],
      this.invenId=params["invenId"],
      this.des=params["des"],
      this.date=params["date"],
    ])
   
  }
  getfacilityGroupTypeList() {
    this.spinner.show();
    this.inventoryItemLabelList = [];
    this.InventoryLabelsService.getinventoryItemLabelList().subscribe((responce) => {
      this.spinner.hide();
      this.inventoryItemLabelList = responce.data;
    });
   
  }

  getInventoryItemLabelTypeId() {
    this.spinner.show();
    this.InventoryLabelsService.getInventoryItemLabelTypeId().subscribe((responce) => {
      this.spinner.hide();
      this.inventoryItemLabelTypeId = responce.data;
      for(const value of this.inventoryItemLabelTypeId) {
        this.inventoryItemLabelTypeIdArray.push({
          label:value.inventoryItemLabelTypeId,
          value:value.inventoryItemLabelTypeId
        })
      }
    });

  }

  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
    
      this.InventoryLabelsService.updateInventoryItemLabel({...this.inventoryLabel.value }).subscribe((res: any) => {
    
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.getfacilityGroupTypeList();
          this.inventoryLabel.reset();
         
          this.closebutton.nativeElement.click();
          this.router.navigate(["facilities/inventory-labels"])
        }

      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.error) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    } else {
      this.InventoryLabelsService.createInventoryItemLabel(this.inventoryLabel.value).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.getfacilityGroupTypeList();
          this.inventoryLabel.reset();
          this.closebutton.nativeElement.click();
          this.router.navigate(["facilities/inventory-labels"])
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
  
  }

  getData(){
    this.editMode = true;
    this.inventoryLabel.patchValue({
      inventoryItemLabelTypeId:this.invenId,
      inventoryItemLabelId: this.detailLabelid,
      description:  this.des,
    });
}
  cancelSubmit(){
    this.router.navigate(["facilities/inventory-labels"])
  }

}
