

<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Cases</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey " style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                      
                            <li [ngClass]="activeCategories==8?'active':''" (click)="toLead()"><a>
                                Leads</a></li>
                        <li [ngClass]="activeCategories==2?'active':''" (click)="toContact()"><a>
                                Contact</a></li>
                        <li [ngClass]="activeCategories==1?'active':''" (click)="toAccount()"><a>
                                Account</a></li>
                        <li [ngClass]="activeCategories==3?'active':''" (click)="toOpportunity()"><a>
                                Opportunity</a></li>
                        <li [ngClass]="activeCategories==9?'active':''" (click)="toTasks()"><a>
                                Tasks</a></li>
                        <li [ngClass]="activeCategories==7?'active':''" (click)="toCases()"><a>
                                Cases</a></li>



                        <li [ngClass]="activeCategories==4?'active':''" (click)="toActivities()"><a>
                                Activities Campaign</a></li>

                        <li [ngClass]="activeCategories==10?'active':''" (click)="toForecast()"><a>
                                Forecast </a></li>
                        <li [ngClass]="activeCategories==11?'active':''" (click)="toDocuments()"><a>
                                Documents </a></li>
                        <li [ngClass]="activeCategories==12?'active':''" (click)="toNotes()"><a>
                                Notes </a></li>
                        <li [ngClass]="activeCategories==13?'active':''" (click)="toCalendar()"><a>
                                Calendar </a></li>
                                <li [ngClass]="activeCategories==14?'active':''" (click)="toEmails()"><a>
                                    Emails </a></li>

                        <li [ngClass]="activeCategories==5?'active':''" (click)="toQuotes()"><a>
                                Quotes </a></li>

                        <li [ngClass]="activeCategories==6?'active':''" (click)="toSalesOrder()"><a>
                                Sales order</a></li> 


                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12 pad_0 ">
                                    <div class=" bg-white color-grey" style="padding-bottom: 70px;">
                                        <div class="w3-card-4 classCard border_wdth_1">
                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                    style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                                                    <li [ngClass]="activeCategory==1?'active':''"
                                                        (click)="changeactiveCategory(1)">
                                                        <a>My Team's Cases</a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==2?'active':''"
                                                        (click)="changeactiveCategory(2)">
                                                        <a>My Cases</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div
                                                                class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                <div *ngIf="activeCategory==1" class="w-100">
                                                                    <div class="w3-card-4 classCard w-100"
                                                                        style="margin-left:-1%!important ;">
                                                                        <div
                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                            <p class="h-text">Find My Team's Cases</p>
                                                                            <button type="button"
                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#createCasePopup">Create New
                                                                                Case</button>
                                                                        </div>

                                                                        <div
                                                                            class="port-header-contact create-lead font-medium mb-0 w-100">

                                                                            <div class="w3-card-4 w-100">
                                                                                <div class="accordion classCard"
                                                                                    id="myAccordion">
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header"
                                                                                            id="headingOne">
                                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 
                                                                                    create-lead font-medium mb-0 suppliers-wrapper"
                                                                                                style="background:#F5F7FA!important; padding: 4px !important;">
                                                                                                <span
                                                                                                    style="margin-left: 12px!important;">
                                                                                                    Advanced</span>
                                                                                                <button type="button"
                                                                                                    class="accordion-button  collapsed"
                                                                                                    data-bs-toggle="collapse"
                                                                                                    data-bs-target="#collapseOne"
                                                                                                    style="margin-left: 85%!important; padding-top: 11px !important;
                                                                                    padding-bottom: 11px !important;"></button>

                                                                                            </div>

                                                                                        </h2>
                                                                                        <div id="collapseOne"
                                                                                            class="accordion-collapse collapse"
                                                                                            data-bs-parent="#myAccordion">
                                                                                            <div class="card-body">
                                                                                                <div
                                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                    <div
                                                                                                        class="col-lg-12">
                                                                                                        <div
                                                                                                            class="row">
                                                                                                            <div
                                                                                                                class="col-lg-2 form-group classInput">
                                                                                                                <label
                                                                                                                    for="exampleInputEmail1">Subject</label>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-2">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    class="form-control"
                                                                                                                    [(ngModel)]="findMycases.subject"
                                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                                    id="exampleInputEmail1"
                                                                                                                    name="subject"
                                                                                                                    aria-describedby="emailHelp"
                                                                                                                    placeholder="Enter Subject">
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-2 form-group classInput">
                                                                                                                <label
                                                                                                                    for="exampleInputEmail1">Priority</label>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-2 dropdowns">
                                                                                                                <p-dropdown
                                                                                                                    filter="true"
                                                                                                                    optionlabel="label"
                                                                                                                    [options]="PriorityArray"
                                                                                                                    [(ngModel)]="findMycases.priority"
                                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                                    name="priority"
                                                                                                                    id="exampleInputEmail1"
                                                                                                                    aria-describedby="emailHelp"
                                                                                                                    placeholder="Enter Priority">
                                                                                                                </p-dropdown>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-12">
                                                                                                        <div
                                                                                                            class="row">
                                                                                                            <div
                                                                                                                class="col-lg-2 form-group classInput">
                                                                                                                <label
                                                                                                                    for="exampleInputEmail1">Status</label>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-2 dropdowns">
                                                                                                                <p-dropdown
                                                                                                                    filter="true"
                                                                                                                    optionlabel="label"
                                                                                                                    [(ngModel)]="findMycases.status"
                                                                                                                    [options]="StatusCrmArray"
                                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                                    name="status"
                                                                                                                    id="exampleInputEmail1"
                                                                                                                    aria-describedby="emailHelp"
                                                                                                                    placeholder="Enter Status">
                                                                                                                </p-dropdown>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-2 form-group classInput">
                                                                                                                <label
                                                                                                                    for="exampleInputEmail1">Type</label>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="col-lg-2 dropdowns">
                                                                                                                <p-dropdown
                                                                                                                    filter="true"
                                                                                                                    optionlabel="label"
                                                                                                                    [options]="TypeCrmArray"
                                                                                                                    [(ngModel)]="findMycases.type"
                                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                                    name="type"
                                                                                                                    id="exampleInputEmail1"
                                                                                                                    aria-describedby="emailHelp"
                                                                                                                    placeholder="Enter Type">
                                                                                                                </p-dropdown>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div
                                                                                                        class="col-lg-8 main-submit-button">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-secondary submit-btn"
                                                                                                            (click)="FindCases()">Find</button>
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            class="btn btn-danger ml-2"
                                                                                                            (click)="reset()">Reset</button>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="w3-card-4 classCardView">
                                                                            <div class="header-tabs" >
                                                                                <h4 class=" common-styling hleft">
                                                                                    Advanced
                                                                                </h4>
                                                                            </div>
                                                                            <form>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Subject</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="text" class="form-control" [(ngModel)]="findMycases.subject" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="subject" aria-describedby="emailHelp" placeholder="Enter Subject">
                                                                                            </div>
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Priority</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2 dropdowns" >
                                                                                                <p-dropdown filter="true" optionlabel="label" [options]="PriorityArray" [(ngModel)]="findMycases.priority" [ngModelOptions]="{standalone:true}" name="priority" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Priority">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Status</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2 dropdowns">
                                                                                                <p-dropdown filter="true" optionlabel="label" [(ngModel)]="findMycases.status" [options]="StatusCrmArray" [ngModelOptions]="{standalone:true}" name="status" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Type</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2 dropdowns">
                                                                                                <p-dropdown filter="true" optionlabel="label" [options]="TypeCrmArray" [(ngModel)]="findMycases.type" [ngModelOptions]="{standalone:true}" name="type" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Type">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                
                                                                                    <div class="col-lg-8 main-submit-button">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="FindCases()">Find</button>
                                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div> -->
                                                                        </div>

                                                                        <!-- <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                        <div class="w3-card-4 classCardView">
                                                                            <div class="header-tabs" >
                                                                                <h4 class=" common-styling hleft">
                                                                                    Advanced
                                                                                </h4>
                                                                            </div>
                                                                            <form>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Subject</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="text" class="form-control" [(ngModel)]="findMycases.subject" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" name="subject" aria-describedby="emailHelp" placeholder="Enter Subject">
                                                                                            </div>
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Priority</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2 dropdowns" >
                                                                                                <p-dropdown filter="true" optionlabel="label" [options]="PriorityArray" [(ngModel)]="findMycases.priority" [ngModelOptions]="{standalone:true}" name="priority" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Priority">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Status</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2 dropdowns">
                                                                                                <p-dropdown filter="true" optionlabel="label" [(ngModel)]="findMycases.status" [options]="StatusCrmArray" [ngModelOptions]="{standalone:true}" name="status" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-2 form-group classInput">
                                                                                                <label for="exampleInputEmail1">Type</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2 dropdowns">
                                                                                                <p-dropdown filter="true" optionlabel="label" [options]="TypeCrmArray" [(ngModel)]="findMycases.type" [ngModelOptions]="{standalone:true}" name="type" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Type">
                                                                                                </p-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                
                                                                                    <div class="col-lg-8 main-submit-button">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="FindCases()">Find</button>
                                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div> -->

                                                                        <div
                                                                            class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                            <div class="w3-card-4 classCard w-100">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">
                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table
                                                                                                    [value]="findCasesList"
                                                                                                    [paginator]="true"
                                                                                                    [rows]="rows"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Priotity
                                                                                                                    <p-sortIcon
                                                                                                                        field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Case
                                                                                                                    ID
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Subject
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Status
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Type
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Reason
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span>
                                                                                                                        {{product.cases.priority}}
                                                                                                                    </span>

                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                                         
                                                                                                              >
                                                                                                                <span class="account-button"   (click)="detailPage(product.cases.custRequestId)">
                                                                                                                    {{product.cases.custRequestId}}
                                                                                                                </span>
                                                                                                              
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                                          >
                                                                                                                <span   class="account-second-button"
                                                                                                                (click)="detailPage(product.cases.custRequestId)">
                                                                                                                    {{product.cases.custRequestName}}
                                                                                                                </span>
                                                                                                               
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.cases.statusId}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.cases.custRequestTypeId}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.cases.custRequestCategoryId}}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p
                                                                                                    class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="activeCategory==2" class="w-100">
                                                                    <div class="w3-card-4 classCard w-100">
                                                                        <div
                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                            <p class="h-text">Find My Cases</p>
                                                                            <button type="button"
                                                                                class="btn btn-secondary submit-btn ml-2"
                                                                                data-toggle="modal"
                                                                                data-target="#createCasePopup">Create New
                                                                                Case</button>
                                                                        </div>
                                                                        <!-- <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                    <div class="w3-card-4 classCardView">
                                                                        <div class="header-tabs" >
                                                                            <h4 class=" common-styling hleft">
                                                                                Advanced
                                                                            </h4>
                                                                        </div>
                                                                        <form>
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Subject</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="text" class="form-control"
                                                                                                [(ngModel)]="findMyTeamcases.subject"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                id="exampleInputEmail1" name="subject"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Subject">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Priority</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                optionlabel="label"
                                                                                                [(ngModel)]="findMyTeamcases.priority"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                name="priority" id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Priority">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                optionlabel="label"
                                                                                                [(ngModel)]="findMyTeamcases.status"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                name="status" id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-2 form-group classInput">
                                                                                            <label for="exampleInputEmail1">Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                optionlabel="label"
                                                                                                [(ngModel)]="findMyTeamcases.type"
                                                                                                [ngModelOptions]="{standalone:true}"
                                                                                                name="type" id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Type">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-4 main-submit-button">
                                                                                    <button type="submit"
                                                                                        class="btn btn-secondary submit-btn">Find</button>
                                                                                    <button type="submit"
                                                                                        class="btn btn-danger ml-2">Reset</button>
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div> -->
                                                                        <div
                                                                            class="port-header-contact create-lead font-medium mb-0 w-100">
                                                                            <div class="w3-card-4 classCard w-100">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">
                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table
                                                                                                    [value]="MyCasesList"
                                                                                                    [paginator]="true"
                                                                                                    [rows]="rows"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Priotity
                                                                                                                    <p-sortIcon
                                                                                                                        field="code">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Case
                                                                                                                    ID
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Subject
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Status
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Type
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Reason
                                                                                                                    <p-sortIcon
                                                                                                                        field="name">
                                                                                                                    </p-sortIcon>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                         class="account-button"  >
                                                                                                                        {{product.priority}}
                                                                                                                    </span>

                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                                                 class="account-button"  
                                                                                                                (click)="detailPage(product.cases.custRequestId)">
                                                                                                                {{product.custRequestId}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}"
                                                                                                                 class="account-button"  
                                                                                                                (click)="detailPage(product.cases.custRequestId)">
                                                                                                                {{product.custRequestName}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.statusId}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.custRequestTypeId}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.custRequestCategoryId}}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                                <p
                                                                                                    class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createCasePopup" tabindex="-1" role="dialog" aria-labelledby="createCasePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create New Case</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Case</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCases">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Initial Account</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="PartyIdArray" formControlName="initialAccount"
                                                            placeholder="Enter Initial Account">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Initial Contact</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="PartyIdArray" formControlName="initialContact"
                                                            placeholder="Enter Initial Contact">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="PriorityArray" formControlName="priority"
                                                            placeholder="Enter Priority">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="TypeCrmArray" formControlName="type"
                                                            placeholder="Enter Type">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Reason</label>
                                                    </div>
                                                    <div class="col-lg-2 ">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ReasonArray" formControlName="Reasons"
                                                            placeholder="Enter Reason">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Note</label>
                                                    </div>
                                                    <div class="col-lg-2 ">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="internalNote"
                                                            placeholder="Enter Internal Note">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">

                                                        <label for="exampleInputEmail1"> Subject
                                                            <span style="color: red;">*</span> </label>

                                                    </div>
                                                    <div class="col-lg-2 ">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="subject"
                                                            placeholder="Enter Subject">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4  main-submit-button" style="margin-left: 33%;">
                                                <button type="submit" (click)="createCase()"
                                                    class="btn btn-secondary submit-btn">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>