import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-account-postal-address',
    templateUrl: './account-postal-address.component.html',
    styles: [`
        .border-card{
            border: 1px solid;
            border-color: #0d3769 !important;
            /* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */
        }
    `]
})
export class AccountPostalAddressComponent implements OnInit {
    editMode: boolean;
    partyId: string;
    addressForm: FormGroup;
    solicitationArray = [
        {
            label: 'Y',
            value: 'Y'
        },
        {
            label: 'N',
            value: 'N'
        }
    ];
    contactMechTypeId: any[];
    countryArray: any[];
    stateArray: any[];
    accountInfo: any;
    contactMechId: string;
    @Output() addrModalClosed: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('closebutton') closebutton;
	
    constructor(
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _CRMAccountService: CRMAccountService,
        readonly _FormBuilder: FormBuilder,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly  _Router: Router,
        private _location: Location,
        readonly spinner:NgxSpinnerService,
        readonly _ToastrService: ToastrService,
        public bsModalRef: BsModalRef
    ) {

        this.addressForm = this._FormBuilder.group({
            address1: [''],
            address2: [''],
            allowSolicitation: [''],
            attnName: [''],
            city: [''],
            contactMechId: [''],
            contactMechPurposeTypeId: ['', [Validators.required]],
            contactMechTypeId: [''],
            countryGeoId: [''],
            directions: [''],
            partyId: [''],
            postalCode: [''],
            preContactMechTypeId: ['POSTAL_ADDRESS'],
            stateProvinceGeoId: [''],
            toName: ['']
        });
        this.editMode = false;
        this.partyId = '';
        this.countryArray = [];
        this.stateArray = [];
        this.contactMechTypeId = [];
        this.contactMechId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
        if (this.contactMechId) {
            this.getAccountDetails();
        }
        this.addressForm.controls.countryGeoId.valueChanges.subscribe(data => {
            if (data) {
                this.getStates(data);
            }
        });
        this.getCountries();
        this.getPreContactMechIds();
    }
    getAccountDetails(): void {
        this.spinner.show();
        this._CRMAccountService.getAccountById(this.partyId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.accountInfo = data.data.contactInformation.filter(value => {
                        if (value.contactMech && (value.contactMech.contactMechId === this.contactMechId)) {
                            this.addressForm.patchValue({
                                ...value.postalAddress, ...{
                                    contactMechPurposeTypeId: value.partyContactMechPurposes[0].contactMechPurposeTypeId
                                }
                            })
                            return value.postalAddress;
                        }
                    });
                    this.addressForm.controls.contactMechPurposeTypeId.disable();
                    this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
                  
                }
            });
           
    }
    getPreContactMechIds(): void {
        this.spinner.show();
        this._CRMAccountService.getContactPurposes(this.partyId, 'POSTAL_ADDRESS')
            .then(data => {
                if (data.success) {
                    this.contactMechTypeId = data.data.purposeTypes.map(value => {
                        return {
                            label: value.description,
                            value: value.contactMechPurposeTypeId
                        };
                    });
                }
            });
            this.spinner.hide();

    }
    getCountries(): void {
        this.spinner.show();
        this._GlobalResourceService.getCountryList()
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.countryArray = data.data.countryList.map(value => {
                        return {
                            label: value.split(':')[0].trim(),
                            value: value.split(':')[1].trim()
                        };
                    });
                }
            });
           
    }
    getStates(countryId: string): void {
        this.spinner.show();
        this._GlobalResourceService.getStateList(countryId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.stateArray = data.data.stateList.map(value => {
                        return {
                            label: value.split(':')[0].trim(),
                            value: value.split(':')[1].trim()
                        };
                    });
                }
            });
          
    }



    submit(): void {
        this.spinner.show();
        if (this.addressForm.valid) {
            this._CRMAccountService.createPostalAddress({
                ...this.addressForm.value, ...{
                    partyId: this.partyId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.modalClose();
                        this.spinner.hide();
                        this.cancelSubmit();
                    }
                });
        }
       
    }
    update(): void {
        this.spinner.show();
        if (this.addressForm.valid) {
            this._CRMAccountService.updatePostalAddress({
                ...this.accountInfo[0], ...this.addressForm.value, ...{
                    partyId: this.partyId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.modalClose();
                        this.spinner.hide();
                        this.addressForm.controls.contactMechPurposeTypeId.enable();
                        this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
                        this.cancelSubmit();
                    }
                });
        }
       
    }

    cancelSubmit(): void {
        this.addressForm.reset();
        this.modalClose();
        this._location.back();
    }

    modalClose(): void {
        this.closebutton.nativeElement.click();
        this.addrModalClosed.emit();
      }

    ngOnDestroy(): void {
        this.spinner.hide();
          }
}