import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../../app/app.constant';
@Injectable({
	providedIn: 'root'
})
export class MergeLeadService {
	constructor(private http: HttpClient) {
	}
	getLeadsList(data, formData): Observable<any> {
		const getLeadsList = URLS.getLeads + '?pageSize=' + data.pageSize + '&pageNo=' + data.pageNo + '&isMyLead=' + data.isMyLead;
		return this.http.post(getLeadsList, formData);
	}
	getMergeContacts(partyIdFrom, partyIdTo): Observable<any> {
		const getMergeContacts = URLS.getMergeContacts + '?partyIdFrom=' + partyIdFrom + '&partyIdTo=' + partyIdTo;
		return this.http.get(getMergeContacts);
	}
	submitMergeLead(formData): Observable<any> {
		const merge = URLS.mergeContacts;
		return this.http.post(merge, formData);
	}
}
