import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import {DetaledLeadService} from "../../crm/detaled-lead/detaled-lead.service";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';


@Component({
  selector: 'app-convert-lead',
  templateUrl: './convert-lead.component.html',
  styleUrls: ['./convert-lead.component.css']
})
export class ConvertLeadComponent implements OnInit {
  partyGroupId: any;
  partyId: any;
  activeCategory=1;
  constructor(readonly activatedRoute:ActivatedRoute,
    readonly detaledLeadService:DetaledLeadService,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router:Router,
    private _location: Location,
    ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyGroupId = params["partyGroupId"];
      this.partyId = params["partyId"];
    });
  
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  onSubmit(){
    this.spinner.show();
    this.detaledLeadService.convertedlead(this.partyGroupId,this.partyId).subscribe((res:any)=> {
     
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(["/crm/leads/details-lead"],{queryParams:{leadId:this.partyId}})
    
      }    },(err)=> {
        this.spinner.hide();
        this.toastr.error(err.error.message)
      }
      )
      this.spinner.hide();
  }
  navigate(){
    this.router.navigate(["/crm/leads/details-lead"],{queryParams:{leadId:this.partyId}})

  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}
