<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;" (click)="cancelSubmit()">Employee </span>
                <span class="color-black pl-1"> > {{editMode?'Update':'Create'}}</span>
                <button type="submit"  class="btn btn-danger buttonclassA"  (click)="cancelSubmit()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    {{editMode?'Update':'Create'}} Employee
                </div>
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                        Legal Name </a></li>
                      <li [ngClass]="activeCategory==3?'active':''" (click)="statusInfo(employeeForm.value.firstName,employeeForm.value.lastName)"><a>
                        Status</a></li>
          
                      <li [ngClass]="activeCategory==4?'active':''" (click)="addressValid(employeeForm.value.statusId)"><a>
                        Address </a></li>
                      <li [ngClass]="activeCategory==5?'active':''" (click)="contcatValid(employeeForm.value.address1,employeeForm.value.city,employeeForm.value.postalCode)" ><a>
                        Contact  </a></li>
                      <li [ngClass]="activeCategory==6?'active':''" (click)="additionalValida(employeeForm.value.contactNumber)"><a>
                        Additional Info  </a></li>
                     
          
          
                    </ul>
                  </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form [formGroup]="employeeForm" class="w-100">
                                <div *ngIf="activeCategory==2">
                                    <div class="header-tabs">
                                      <h4 class=" common-styling hRight" >
                                        Legal Name
                                      </h4>
                                    
            
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Mr./Ms</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="title" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        formControlName="title" placeholder="Title">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">First Name <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="firstName" class="form-control"
                                                        formControlName="firstName" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="First Name">
                                                        <small class="text-danger"
                                                        *ngIf="!!employeeForm.controls.firstName.invalid && !!employeeForm.controls.firstName.touched">First
                                                        Name is required</small>
                                                            
        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Middle initial</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="middleInitial" class="form-control"
                                                        formControlName="middleInitial" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Middle initial">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Last Name <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="lastName" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        formControlName="lastName" placeholder="Last Name">
                                                    <small class="text-danger"
                                                        *ngIf="!!employeeForm.controls.lastName.invalid && !!employeeForm.controls.lastName.touched">Last
                                                        Name is required</small>
                                                            
        
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">SS No.</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="lastName" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        formControlName="socialSecurityNumber" placeholder="Enter SS No.">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Gender</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Gender" [options]="gender"
                                                            formControlName="gender" optionlabel="label">
                                                        </p-dropdown>
                                                            
        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Date of Birth</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" aria-describedby="emailHelp"
                                                        formControlName="birthDate" placeholder="Date of Birth">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Marital Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Select Maritial Status"
                                                        [options]="maritalStatus" formControlName="maritalStatusEnumId"
                                                        optionlabel="label">
                                                    </p-dropdown>
                                                            
        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">U.S. Citizen</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Select" [options]="array"
                                                        formControlName="usCitizen">
                                                    </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Ethnicity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Enter Ethnicity"
                                                        formControlName="ethnicity">
                                                            
        
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                         
                                            <div class="main-submit-button buttonclass" style="margin-right: 32%;">
                                                <button type="submit" (click)="legalName(employeeForm.value.firstName,employeeForm.value.lastName)"
                                                    class="btn btn-secondary submit-btn">Continue</button>
                                              
                                            </div>
                                        </div>
                                    
                                    </div>
            
                                  </div>
                                  <div *ngIf="activeCategory==3"> 
                                    <div class="header-tabs">
                                      <h4 class=" common-styling hRight">
                                        Status
                                      </h4>
                                    
            
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="statusId" [options]="status" placeholder="Status">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                        *ngIf="!!employeeForm.controls.statusId.invalid && !!employeeForm.controls.statusId.touched">
                                                        Status is required</small>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                   
                                                </div>
                                            </div>
                                          
                                        </div>
                                      <div class="main-submit-button" style="    margin-right: 76%;">
                                          <button type="submit" (click)="selectStatus(employeeForm.value.statusId)"
                                              class="btn btn-secondary submit-btn">Continue</button>
                                        
                                      </div>
                                    </div>
            
                                  </div>
                                  <div *ngIf="activeCategory==4"> 
                                    <div class="header-tabs">
                                      <h4 class=" common-styling hRight">
                                        Address
                                      </h4>
                                    
            
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Address 1 <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="address1" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Address 1">
                                                    <small class="text-danger"
                                                        *ngIf="!!employeeForm.controls.address1.invalid && !!employeeForm.controls.address1.touched">Address
                                                        1 is
                                                        required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Address 2</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="address2" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Address 2">
                                                            
        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">City <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="city" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="City">
                                                    <small class="text-danger"
                                                        *ngIf="!!employeeForm.controls.city.invalid && !!employeeForm.controls.city.touched">City
                                                        is
                                                        required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Country</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Country" [options]="countryArray"
                                                            formControlName="countryGeoId" filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                            
        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">State</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="State" [options]="stateArray"
                                                            formControlName="stateProvinceGeoId" filter="true"
                                                            optionlabel="label">
        
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Zip/Postal Code <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="postalCode" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Postal Code">
                                                    <small class="text-danger"
                                                        *ngIf="!!employeeForm.controls.postalCode.invalid && !!employeeForm.controls.postalCode.touched">Postal
                                                        is
                                                        required</small>
                                                            
        
                                                    </div>
                                                    
                                                  
                                                </div>
                                            </div>
           
                                        </div>
                                      <div class="main-submit-button" style="
                                      margin-right: 32%; ">
                                          <button type="submit" (click)="selectAddress(employeeForm.value.address1,employeeForm.value.city,employeeForm.value.postalCode)"
                                              class="btn btn-secondary submit-btn">Continue</button>
                                        
                                      </div>
                                    </div>
            
                                  </div>
                                  <div *ngIf="activeCategory==5">
                                    <div class="header-tabs">
                                      <h4 class=" common-styling hRight">
                                        Contact
                                      </h4>
                                    
            
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="areaCode" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Area Code">
                                                    </div>

                                                    <div class="col-lg-2 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Contact Number <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" formControlName="contactNumber" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Contact Number">
                                                    <small class="text-danger"
                                                        *ngIf="!!employeeForm.controls.contactNumber.invalid && !!employeeForm.controls.contactNumber.touched">Contact
                                                        Number is
                                                        required</small>
                                                            
        
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Extension </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="extension" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Extension">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Email</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="emailAddress" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Email">
                                                            
        
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        
                                          
                                           
                                        </div>
                                      <div class="main-submit-button" style="
                                      margin-right: 33%;">
                                          <button type="submit" (click)="selectContact(employeeForm.value.contactNumber)"
                                              class="btn btn-secondary submit-btn">Continue</button>
                                        
                                      </div>
                                    </div>
            
                                  </div>
                                  <div *ngIf="activeCategory==6">
                                    <div class="header-tabs">
                                      <h4 class=" common-styling hRight">
                                        Additional Info 
                                      </h4>
                                    
            
                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Account Number/Employee ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="emailAddress" class="form-control"
                                                        formControlName="accountNo" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Account Number/Employee ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes" >
                                                        <label for="billing">Billing Rate Level</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Billing Rate Level"
                                                        formControlName="billingRateLevel">
                                                            
        
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                            

                                        
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 21%;">
                                            <button type="submit" *ngIf="editMode" (click)="onUpdate()"
                                                class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!editMode" (click)="onSubmit()"
                                                class="btn btn-secondary submit-btn">Submit</button>
                                           
                                        </div>
                                    </div>
            
                                  </div>
                               
                                
                            </form>
                        </div>
                    </div>

                </div>
                </div>

            </div>
            
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>