<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12" style="padding: 0;">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
                <span class="color-black pl-1 titlePanels">Current Project: {{projectID}}</span>
                <span class="">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>
 
            </div>

            <div class=" bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard" style="border-width: 0px; box-shadow: none;">
                    <div class="container-fluid" style="border: 1px solid #0d3769; border-left-width: 0px;">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Project Summary</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Edit Project</a>
                            </li>
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Gantt Chart</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Mailing List</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Phases</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Orders</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Content</a>
                            </li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategory(10)">
                                <a>Notes</a>
                            </li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="changeactiveCategory(11)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Sub Projects</a>
                            </li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="changeactiveCategory(12)"
                                *ngIf="this.statusProject=='PRJ_ACTIVE'">
                                <a>Billing</a>
                            </li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="changeactiveCategory(13)"
                               >
                                <a>Files</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2" style="min-height: auto !important;">
                        <div class="container-fluid" style="max-height: initial !important;">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100 pb-3">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Current Project: <span
                                                        *ngIf="projectSummaryflag">{{projectSummaryById.data1.taskName}}</span>
                                                    [{{projectID}}]
                                                </p>
                                                <button *ngIf="!this.Hidebtn" type="submit"
                                                    class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                    data-target="#exampleNewProject">New
                                                    Project</button>
                                                <button *ngIf="!this.Hidebtn" type="submit"
                                                    class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                    data-target="#exampleModalCenter" (click)="copyProject()">Copy
                                                    Project</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    data-toggle="modal" data-target="#exampleModalCenter"
                                                    (click)="schedule()"
                                                    *ngIf="this.statusProject=='PRJ_ACTIVE' || !this.Hidebtn">Schedule</button>
                                                <button *ngIf="!this.Hidebtn" type="submit"
                                                    class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                                    data-target="#exampleModalCenter"
                                                    (click)="copyProjectToTemplate()">Save As Template</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                    data-toggle="modal" data-target="#exampleModalCenter"
                                                    *ngIf="this.statusProject=='PRJ_ACTIVE' || !this.Hidebtn"
                                                    (click)="closeStatus()">
                                                    Close</button>
                                            </div>
                                            <div class="port-header-contact create-lead font-medium mb-0 w-100"
                                                style="padding-bottom: 0;">
                                                <div *ngIf="activeCategory==2" class="w-100">
                                                    <div style="width: 49%; float: left;">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Project Information
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">Project
                                                                                        Name </p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                        {{projectSummaryById.data1.taskName}}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Status</p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.currentStatusId}}
                                                                                    </span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Priority</p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.priority}}
                                                                                    </span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Parent Project Name
                                                                                    </p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.phaseName}}
                                                                                        {{projectSummaryById.data1.taskId}}

                                                                                        <button
                                                                                            (click)="detailedPage(projectSummaryById.data1.taskId)"
                                                                                            class="btn btn-secondary submit-btn">
                                                                                            {{projectSummaryById.data1.taskId}}
                                                                                        </button>
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Estimated Start Date
                                                                                    </p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.estimatedStartDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Estimated Completion Date </p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Actual Start Date
                                                                                    </p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.actualStartDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Actual Completion Date </p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.actualCompletionDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12"
                                                                                        *ngIf="projectSummaryById">
                                                                                        Planned Hours </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.plannedHours}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Original Actual Hours </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Actual Hours </p>
                                                                                    <span *ngIf="projectSummaryById"
                                                                                        class="color-grey pl-20 col-lg-10 col-12">
                                                                                        {{projectSummaryById.data1.actualHours}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-8 col-12">
                                                                                        Mailing List E-mail
                                                                                    </p>
                                                                                    <span
                                                                                        class="color-grey pl-20 col-lg-10 col-12">

                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Sub Project
                                                                </h4>
                                                            </div>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="subprojectList"
                                                                                [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width': '150px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Project Name
                                                                                                <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Status
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Priority
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Estimated Start Date
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Estimated Completion
                                                                                                Date
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Actual Start Date
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Actual Completion Date
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Planned Hours
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Actual Hours
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Created Stamp
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>




                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                {{product?.projectName[0].workEffortName}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product?.projectName[0].currentStatusId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product?.projectName[0].priority}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product?.projectName[0].estimatedStartDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product?.projectName[0].estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product?.projectName[0].actualStartDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product?.projectName[0].actualCompletionDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            <!-- {{product.planHours}} -->
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            <!-- {{product?.actualHours}} -->
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{timestamp|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">
                                                                                                    No
                                                                                                    Record
                                                                                                    Found
                                                                                                </h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Phases
                                                                </h4>
                                                            </div>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="phaseData"
                                                                                [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{ 'width': '150px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Wbs ID
                                                                                                <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Phase
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Current Status ID
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Priority
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Start Date
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Completion Date
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Planned Hours
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Actual Hours
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                <!-- {{product.node1.currentStatusId}} -->
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product.data?.workEffortName}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product.data?.currentStatusId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product.data?.priority}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product.data?.actualStartDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '170px'}">
                                                                                            {{product.data?.actualCompletionDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">
                                                                                                    No
                                                                                                    Record
                                                                                                    Found
                                                                                                </h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="width: 49.8%; float: right;">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Resources
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <h4 class=" common-styling"
                                                                    style="margin: 0 !important;">Providers</h4>
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="resourceList" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{ 'width': '170px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Name
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{ 'width': '170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{ 'width': '170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        From Date</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{ 'width': '170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Through Date</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{ 'width': '170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Actual Hours</div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{ 'width': '170px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Non Billed Hours
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{ 'width': '170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.partyId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{ 'width': '170px'}">
                                                                                    {{product.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{ 'width': '170px'}">
                                                                                    {{product.fromDate | date:'yyyy-MM-d
                                                                                    hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{ 'width': '170px'}">
                                                                                    {{product.thruDate | date:'yyyy-MM-d
                                                                                    hh:mm:ss' }}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">
                                                                                            No Record
                                                                                            Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                    </p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Client Contacts
                                                                </h4>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="clientData" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Name
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Role Type ID
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            From Date</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Through Date</div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.groupName}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.roleTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.fromDate |
                                                                                        date:'yyyy-MM-d hh:mm:ss' }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.thruDate |
                                                                                        date:'yyyy-MM-d hh:mm:ss' }}
                                                                                    </td>


                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Companies
                                                                </h4>


                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="companiesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Name
                                                                                        </div>

                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Role Type ID
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            From Date
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Through Date
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Currency
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <!-- {{product.genericValue}} -->
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.genericValue.roleTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.genericValue.fromDate}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.genericValue.thruDate}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- {{product.genericValue}} -->
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Notes
                                                                </h4>

                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="" [rows]="rows"
                                                                            scrollWidth="100%" [scrollable]="true"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '200px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>

                                                                                        </div>

                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                        </div>
                                                                                        <div>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">

                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">


                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <span><button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            data-toggle="modal"
                                                                                            data-target="#exampleModalCenter">Make
                                                                                            Public

                                                                                        </button></span>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Project Content List
                                                                </h4>


                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="" [rows]="rows"
                                                                            scrollWidth="100%" [scrollable]="true"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Content Name
                                                                                        </div>

                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="price">
                                                                                        <div style="color: white;">
                                                                                            Content Type ID
                                                                                        </div>
                                                                                        <div>

                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Work Effort Content Type ID
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            From Date
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Dr Mime Type ID
                                                                                        </div>
                                                                                    </th>



                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <span></span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.fromDate
                                                                                            |
                                                                                            date:'yyyy-MM-d
                                                                                            hh:mm:ss' }}
                                                                                        </span>


                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">

                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft">
                                                                    List Order
                                                                </h4>


                                                            </div>
                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select allStyleUser">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="orderIdListData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Order ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Order Name
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Order Type ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Status ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Order Date
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Entry Date
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span>{{product.orderId}}</span>
                                                                                            <!-- <span>{{product?.node1?.orderId}}</span> -->
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.orderName}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.orderTypeId}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.statusId}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.orderDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.entryDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No
                                                                                                Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- </div> -->
                                                    <div style="width: 100%; float: left;">
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs"
                                                                    style="background:#F5F7FA !important;">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Tasks
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="taskData"
                                                                                [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{ 'width': '150px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Wbs ID
                                                                                                <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Task
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Phase Name
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                First Resource
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Current Status ID
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Priority
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Start Date
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Est.Compl.
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Act.Compl.
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Planned Hours
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Actual Hours
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span>{{this.projectID}}</span>

                                                                                            </div>
                                                                                        </td>

                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            {{product.description.workEffortName}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            <!-- {{product.description.}} -->
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            <!-- {{product.description.}} -->
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            {{product.description.currentStatusId}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            {{product.description.priority}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            {{product.startDate}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            {{product.description.estimatedCompletionDate}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            <!-- {{product.description.}} -->
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            <!-- {{product.description.}} -->
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{ 'width': '180px'}">
                                                                                            <!-- {{product.description.}} -->
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">
                                                                                                    No
                                                                                                    Record
                                                                                                    Found
                                                                                                </h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Edit Project Information
                                                            </h4>
                                                        </div>
                                                        <form [formGroup]="editProject">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Project Name
                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="projectName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Project Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Description
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="description"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Description">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Status
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                readonly="disabled"
                                                                                formControlName="status"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Status">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Client
                                                                                Billing Party ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="partyIdArray"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="clientBillingPartyID"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Client Billing Party ID"
                                                                                filter="true" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Organization
                                                                                Party ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown
                                                                                [options]="OrganizationFindEmploymentArray"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="organizationPartyID"
                                                                                placeholder="Enter Organization Party ID"
                                                                                filter="true" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label
                                                                                for="exampleInputEmail1">Priority</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown name="Priority"
                                                                                id="exampleInputEmail1"
                                                                                [options]="PriorityListArray"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Priority"
                                                                                formControlName="priority" filter="true"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label
                                                                                for="exampleInputEmail1">Scope</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown name="Scope"
                                                                                id="exampleInputEmail1"
                                                                                [options]="ScopeListArray"
                                                                                formControlName="scope"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Scope" filter="true"
                                                                                optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Parent
                                                                                Project Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown name="ParentProjectName"
                                                                                id="exampleInputEmail1"
                                                                                [options]="ParentProjectNameArray"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="parentProjectName"
                                                                                placeholder="Enter ParentProjectName"
                                                                                filter="true" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Estimated Start Date
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="estimatedStartDate"
                                                                                placeholder="Enter Estimated Start Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Estimated Completion
                                                                                Date
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="estimatedCompletionDate"
                                                                                placeholder="Enter Estimated Completion Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Actual Start Date
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="actualStartDate"
                                                                                placeholder="Enter Actual Start Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Actual Completion
                                                                                Date
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="actualCompletionDate"
                                                                                placeholder="Enter Actual Completion Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Mailing
                                                                                List E-mail
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                name="MailingListE-mail"
                                                                                formControlName="mailingemail"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Mailing List E-mail">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-10 main-submit-button">
                                                                    <button type="submit" (click)="updateProject()"
                                                                        class="btn btn-secondary submit-btn">Save</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==4" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Search Options
                                                            </h4>
                                                        </div>
                                                        <form>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Communication Event
                                                                                ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                [(ngModel)]="searchOptions.CommunicationEventID"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="CommunicationEventID"
                                                                                placeholder="Enter Communication Event ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Party ID From
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                [options]="PartyIDToArray"
                                                                                [(ngModel)]="searchOptions.PartyIDFrom"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="PartyIDFrom"
                                                                                placeholder="Enter Party ID From">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Subject
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                [(ngModel)]="searchOptions.Subject"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="Subject"
                                                                                placeholder="Enter Subject">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Entry Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                [(ngModel)]="searchOptions.EntryDate"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="EntryDate"
                                                                                placeholder="Enter Entry Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Role Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                optionlabel="label"
                                                                                [options]="RoleTypeIdToArray"
                                                                                [(ngModel)]="searchOptions.RoleTypeID"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                name="RoleTypeID"
                                                                                placeholder="Enter Role Type ID">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-10 main-submit-button">
                                                                    <button type="submit" (click)="getFindMailing()"
                                                                        class="btn btn-secondary submit-btn">Find</button>
                                                                    <button type="reset" (click)="resetFindMailing()"
                                                                        class="btn btn-danger ml-2">Reset</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling h4Margin">
                                                                Search Results
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="mailingListData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Communication Event Id
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Party ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Role Type ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Role Status ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Created
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>



                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '150px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                                class="account-button">
                                                                                                {{product.des.communicationEventId}}</span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                                                        {{product.des.partyIdTo}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '180px'}"
                                                                                        (click)="detailPageShipment(product.FindShipmentGatewayConfig.shipmentGatewayConfTypeId, product.FindShipmentGatewayConfig.shipmentGatewayConfigId,
                                                                                        product.FindShipmentGatewayConfig.description)">
                                                                                        {{product.des.roleTypeIdTo}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                                                        {{product.des.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                                                        {{product.des.datetimeStarted
                                                                                        |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==5" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    data-toggle="modal" data-target="#phasepopup">Add
                                                                    Phase</button>
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="phaseData" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Sequence Num
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Name
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Update
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Priority
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Planned Hours
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Actual Hours
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Scope
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Estimated Start Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '200px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Estimated Completion Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Actual Start Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Actual Completion Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                    ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <!-- {{product.node1.currentStatusId}} -->
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.data?.workEffortName}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- {{product.node1.currentStatusId}} -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.data?.currentStatusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.data?.priority}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- {{product.node1.currentStatusId}} -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- {{product.node1.currentStatusId}} -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.data?.scopeEnumId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.data?.estimatedStartDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '200px'}">
                                                                                        {{product.data?.estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.data?.actualStartDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.data?.actualCompletionDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            data-toggle="modal"
                                                                                            data-target="#updatePhasepopup"
                                                                                            (click)="updatePHASE(product)">
                                                                                            Update</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No
                                                                                                Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="w3-card-4 classCardView mt-2">


                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==6" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling h4Margin">
                                                                Edit Task
                                                                <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#taskPopup"> Add Task</button>
                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#phasepopup"> Add Phase</button> -->
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="taskData" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Parent Phase
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortab leColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Task Seq Num
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Name
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Planned Hours
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Priority
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Estimated Start Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '200px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Estimated Completion Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Update
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Type
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">      
                                                                                        </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"    aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Actual Hours
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                    ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span>
                                                                                                {{product?.description?.workEffortParentId}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product?.description?.sequenceNum}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product?.description?.workEffortName}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- <span>{{product?.description?.workEffortName}}</span> -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product?.description?.priority}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product?.description?.estimatedStartDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '200px'}">
                                                                                        <span>{{product?.description?.estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            data-toggle="modal"
                                                                                            data-target="#taskPopup"
                                                                                            (click)="updateTask(product)">
                                                                                            Update</button>


                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product?.description?.workEffortTypeId}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- <span>{{product?.description?.workEffortName}}</span> -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- <span>{{product?.description?.workEffortName}}</span> -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="taskPage(product.description.workEffortId)">
                                                                                            Edit</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No
                                                                                                Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==7" class="w-100">

                                                    <div class="w3-card-4 classCard mt-2">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling h4Margin">
                                                                List Order
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    data-toggle="modal" data-target="#orderPopup">Create
                                                                    New Order
                                                                </button>
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="ordersList" [paginator]="true"
                                                                            [rows]="rows" scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Order ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Order Name
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Order Type ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Status ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Order Date
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Entry Date
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '250px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Delete
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                    ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span>{{product.orderId}}</span>
                                                                                            <!-- <span>{{product?.node1?.orderId}}</span> -->
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.orderName}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.orderTypeId}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.statusId}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.orderDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <span>{{product.entryDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '250px'}">
                                                                                        <button type="button"
                                                                                            class="btn btn-secondary submit-btn "
                                                                                            (click)="deleteOrderById(product?.node1)">Remove
                                                                                            Link From The
                                                                                            Project</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>

                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No
                                                                                                Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==8" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Add Resource Assignment
                                                            </h4>
                                                        </div>


                                                        <form [formGroup]="addResource">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Party ID

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown name="PartyID"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="partyID"
                                                                                [options]="PartyIDToArray"
                                                                                placeholder="Enter PartyID"
                                                                                filter="true" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Role Type

                                                                            </label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <p-dropdown name="PartyID"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="roleTypeId"
                                                                                [options]="RoleResourceListArray"
                                                                                placeholder="Enter Role Type"
                                                                                filter="true" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">From Date
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="FromDate"
                                                                                placeholder="Enter From Date">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="productId">Through Date</label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="ThroughDate"
                                                                                placeholder="Enter Through Date">
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-5 main-submit-button"
                                                                    style="margin-left: 41%;">
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn"
                                                                        (click)="createWorkEffortPartyAssign()">Add</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="w3-card-4 classCardView mt-2">

                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="resourceList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Party ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Role Type
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            From Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Through Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Expire
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                                style="color: #0d3769;"
                                                                                                (click)="myProfile(product.partyId)">{{product.partyId}}</span>

                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.roleTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.fromDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.thruDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            (click)="deleteWorkEffortPartyAssign(product)">Expire</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==9" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling h4Margin">
                                                                Project Content List
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-3"
                                                                    data-toggle="modal"
                                                                    data-target="#contentPopup">Create New Project Content</button>
                                                            </h4>

                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">


                                                                    <div class="card own-account-table borderTable">
                                                                        <p-table [value]="FindTaskContentById"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Content ID

                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Work Effort Content Type ID
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            From Date
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'100px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Delete

                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'100px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Download

                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span
                                                                                                class="account-button">
                                                                                                {{product.id}}</span>

                                                                                        </div>
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.workEffortContentTypeId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">


                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                        <button
                                                                                            (click)="deletedTaskContentById(product.id)"
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2">
                                                                                            Delete
                                                                                        </button>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                        <button
                                                                                            (click)="downloadFileTaskContent(product.fileUrl) "
                                                                                            type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2">
                                                                                            Download
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No
                                                                                                Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_dataa">
                                                                            <!--  <ng-container *ngIf="openNoRecordFoundNote">
                      <span style="margin-left: 450px;">No Record Found!....</span>
                    </ng-container> -->
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==10" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Add Project Notes

                                                            </h4>
                                                            <button type="submit" style="margin-right: 1%;"
                                                                class="btn btn-secondary submit-btn ml-2"
                                                                data-toggle="modal"
                                                                data-target="#notePopUp">Create New Project Notes</button>
                                                        </div>


                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="NotesByWorkEffortdata">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="name">
                                                                                        <div
                                                                                            style="color: white; padding-left: 5px !important;">
                                                                                            Note ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Work Effort ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Note
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            By
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Private / Public
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                                        <span>{{product.noteData.noteId}}</span>
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                                        <span></span>
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                                        <span>{{product.noteData.noteParty}}</span>
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                                        <span>{{product.noteData.noteInfo}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            *ngIf="this.public || !this.Hidebtn"
                                                                                            (click)="makeNotePublic(product)">Make
                                                                                            Public
                                                                                        </button>
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                            *ngIf="!this.public || !this.Hidebtn"
                                                                                            (click)="makeNotePrivate(product)">Make
                                                                                            Private
                                                                                        </button>
                                                                                        <a  class="account-button" ></a>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td colspan="5">
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==11" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Add Sub Project
                                                            </h4>
                                                        </div>
                                                        <form [formGroup]="addSubProject">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Project
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown name="ProjectName"
                                                                                id="exampleInputEmail1"
                                                                                formControlName="projectName"
                                                                                aria-describedby="emailHelp"
                                                                                [options]="ProjectNameArray"
                                                                                placeholder="Enter ProjectName"
                                                                                filter="true" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-5 main-submit-button"
                                                                    style="margin-left: -14%;">
                                                                    <button type="submit" (click)="AddSubProject()"
                                                                        class="btn btn-secondary submit-btn">Add</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="subprojectList"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Project Name
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Priority
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Estimated Start Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Estimated Completion Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Actual Start Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Actual Completion Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Planned Hours
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Actual Hours
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Created Stamp
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product?.projectName[0].workEffortName}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product?.projectName[0].currentStatusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product?.projectName[0].priority}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product?.projectName[0].estimatedStartDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product?.projectName[0].estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product?.projectName[0].actualStartDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product?.projectName[0].actualCompletionDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- {{product.planHours}} -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <!-- {{product?.actualHours}} -->
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{timestamp|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No
                                                                                                Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==12" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Add all reported/approved task time to a new invoice
                                                            </h4>
                                                        </div>
                                                        <form [formGroup]="newBilling">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Bill From
                                                                                Party</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="fromParty"
                                                                                placeholder="Enter Bill From Party">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Bill To
                                                                                Party</label>
                                                                        </div>

                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="toParty"
                                                                                placeholder="Enter Bill To Party">
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Through
                                                                                Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                ia-describedby="emailHelp"
                                                                                formControlName="throughDate"
                                                                                placeholder="Enter Through Date">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-5 main-submit-button"
                                                                    style="margin-left: 41%;">
                                                                    <button type="submit"
                                                                        (click)="addProjectTimeToNewInvoice()"
                                                                        class="btn btn-secondary submit-btn">Add</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Add all reported/approved task time to an existing
                                                                invoice
                                                            </h4>
                                                        </div>
                                                        <form [formGroup]="existBilling">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Bill To
                                                                                Party</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="billtoParty"
                                                                                placeholder="Enter Bill To Party">
                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Invoice
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown id="exampleInputEmail1"
                                                                                formControlName="invoiceID"
                                                                                [options]="invoiceIDArray"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Invoice ID"
                                                                                filter="true" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Through
                                                                                Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="throughDate"
                                                                                placeholder="Enter Through Date">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-5 main-submit-button"
                                                                    style="margin-left: 41%;">
                                                                    <button type="submit"
                                                                        (click)="addProjectTimeToInvoice()"
                                                                        class="btn btn-secondary submit-btn">Add</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div class="w3-card-4 classCardView mt-2">
                                                        <div class="header-tabs" >
                                                            <h4 class="common-styling h4Margin">Hours not yet billed
                                                                Information
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="projectBilling"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{ 'width': '150px'}"
                                                                                        pSortableColumn="code">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Timesheet ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">

                                                                                            Phase Name
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>

                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Hours
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Task Name
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Timesheet Party ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Rate Type ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '170px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            From Date
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value=""
                                                                                            autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>





                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.timeEntryParty.timesheetId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.projectName[0].currentStatusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.timeEntryParty.hours}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.workeffort[0].workEffortName}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.person[0].partyId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.timeEntryParty.rateTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '170px'}">
                                                                                        {{product.timeEntryParty.fromDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}}
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>

                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">
                                                                                                No
                                                                                                Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==1" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Project Gantt Chart
                                                            </h4>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row gant">
                                                                    <gantt-chart [dayStart]="'1'" [dayEnd]="'30'"
                                                                        [tasks]="this.tasks"
                                                                        [theme]="'gradient'"></gantt-chart>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==13" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs" >
                                                            <h4 class=" common-styling hleft">
                                                                Files

                                                            </h4>
                                                            <button type="submit" style="margin-right: 1%;"
                                                                class="btn btn-secondary submit-btn ml-2"
                                                                data-toggle="modal"
                                                                data-target="#filePopup">Create New File</button>
                                                        </div>


                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getTaskByIdList">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="name">
                                                                                        <div
                                                                                            style="color: white; padding-left: 5px !important;">
                                                                                            Content Id
                                                                                          
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div
                                                                                            style="color: white; padding-left: 5px !important;">
                                                                                           Image URL
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                           Download
                                                                                        </div>
                                                                                    </th>

                 
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                                        <span>{{product.contentId}}</span>
                                                                                    </td>
                                                                                    <td
                                                                                        [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                                        <span>{{product.imageURL}}</span>
                                                                                    </td>
                                                                                  
                                                                                  
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button type="submit"
                                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                                          
                                                                                            (click)="getdownloadTaskById(product.imageURL)">Download
                                                                                            
                                                                                        </button>
                                                                                      
                                                                                        
                                                                                    </td>
                                                                                  

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td colspan="5">
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>

                                                                      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleNewProject" tabindex="-1" role="dialog" aria-labelledby="exampleNewProjectTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Project Information
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li>
                                    <a>Edit Project Information
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newprojectForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Template ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="TemplateIdArray" formControlName="projTempid">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Project Name<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="projectName"
                                                            placeholder="Enter Project Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Client Billing Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="partyIdArray"
                                                            optionlabel="label" formControlName="clientBillingID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Organization Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="OrganizationFindEmploymentArray"
                                                            optionlabel="label" formControlName="organizationID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="PriorityListArray"
                                                            optionlabel="label" formControlName="priority">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Scope
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="ScopeListArray"
                                                            optionlabel="label" formControlName="scope">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Project Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="ParentProjectNameArray"
                                                            optionlabel="label" formControlName="parentProjectName">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="estimatedStartDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Completion Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="estimatedCompletionDate"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Completion Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Mailing List E-mail</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="mailingEmail"
                                                            placeholder="Enter Mailing List E-mail">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createProject()">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="phasepopup" tabindex="-1" role="dialog" aria-labelledby="phasepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Phase</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Phase</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addPhase">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="SequenceNum"
                                                            placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Phase Name<span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="PhaseName" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            formControlName="PhaseName" placeholder="Enter Phase Name">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="Description" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            formControlName="Description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 41%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="addPhases()">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updatePhasepopup" tabindex="-1" role="dialog" aria-labelledby="updatePhasepopupTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Update Phase</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit Phase</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updatePhaseForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="productId">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="SequenceNum"
                                                            placeholder="Enter Sequence Num">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Phase Name<span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="PhaseName" class="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            formControlName="PhaseName" placeholder="Enter Phase Name">
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-9 main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="updatePhase()">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade" id="taskPopup" tabindex="-1" role="dialog" aria-labelledby="taskPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Task </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Task </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newTaskProject">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Task Name<span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="taskName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Task Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Phase</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="parentPhase" [options]="ParentPhaseArray" optionlabel="label" placeholder="Enter Parent Phase">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="sequenceNum" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">My Role for This Week</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="roleWeek" [options]="RoleResourceListArray" optionlabel="label" placeholder="Enter Role for This Week">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Hours</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="estimatedHours" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Hours">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Skill Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="skillTypeID" [options]="SkillTypeIdArray" optionlabel="label" placeholder="Enter Skill Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="priority" [options]="PriorityListArray" optionlabel="label" placeholder="Enter Priority">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="type" [options]="TypeListArray" optionlabel="label" placeholder="Enter Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Scope</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="scope" [options]="ScopeListArray" optionlabel="label" placeholder="Enter Scope">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="startDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Start Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Completion
                                                            Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="endDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Completion Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="createNewTask()">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="taskPopup" tabindex="-1" role="dialog" aria-labelledby="taskPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Task </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Task </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newTaskProject">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Phase</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="parentPhase"
                                                            [options]="ParentPhaseArray" optionlabel="label"
                                                            placeholder="Enter Parent Phase">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Task Seq Num</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="sequenceNum" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num">
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Name<span
                                                                style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="taskName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Task Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Planned Hours</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="estimatedHours" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Hours">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="priority"
                                                            [options]="PriorityListArray" optionlabel="label"
                                                            placeholder="Enter Priority">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="startDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Start Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Completion
                                                            Date</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control"
                                                            formControlName="endDate" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Completion Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateProjectTask()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="orderPopup" tabindex="-1" role="dialog" aria-labelledby="orderPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Order </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Order </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="addOrderID">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">
                                                            Order ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-5">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="orderIDArray" formControlName="OrderID"
                                                            placeholder="Enter Order ID">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-8 main-submit-button">
                                                <button type="submit" (click)="createOrderId()"
                                                    class="btn btn-secondary submit-btn">Add</button>
                                            </div>



                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="contentPopup" tabindex="-1" role="dialog" aria-labelledby="contentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Task Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Task Content</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="AddTaskContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Existing Content
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="existingContentIdListArray"
                                                            formControlName="ExistingContent" optionlabel="label"
                                                            placeholder=" Enter Existing Content ID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort
                                                            Content Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="workEffortContentTypeArray"
                                                            formControlName="WorkEffortContentTypeID"
                                                            optionlabel="label"
                                                            placeholder="Choose Work Effort Content Type ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Mime Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="mimeTypeIdArray"
                                                            formControlName="MimeTypeID" optionlabel="label"
                                                            placeholder="Choose Mime Type ID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Upload
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="file" class="form-control" id="exampleInputEmail1"
                                                            (change)="fileProgressCom($event)" formControlName="Upload"
                                                            aria-describedby="emailHelp" placeholder=" Enter Upload">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Content ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="parentContentIdArray"
                                                            formControlName="ParentContent" optionlabel="label"
                                                            placeholder="Choose Parent Content ID  ">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                               margin-left: -17%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="uploadFormContent()">Create</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="notePopUp" tabindex="-1" role="dialog" aria-labelledby="notePopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Task Notes</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Task Notes</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="AddTaskNotesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Note
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            formControlName="Note" aria-describedby="emailHelp"
                                                            placeholder=" Enter Note">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Internal Note
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="InternalNote"
                                                            [options]="yesNOArray" optionlabel="label"
                                                            placeholder="Choose Internal Note">
                                                        </p-dropdown>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12  main-submit-button" style="
                                            margin-left: -17%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="addTaskNote()">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="filePopup" tabindex="-1" role="dialog" aria-labelledby="filePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Files</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton11>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Files </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="filesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                        

                                            <div class="col-lg-12">
                                                <div class="row">
                                     
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Upload
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="file" class="form-control" id="exampleInputEmail1"
                                                            (change)="fileProgress($event)" formControlName="Upload"
                                                            aria-describedby="emailHelp" placeholder=" Enter Upload">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                               margin-left: -17%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="onCreateFile()">Create</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>