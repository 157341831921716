import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {HeaderService} from './../../../header/header.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService} from '../../../crm/accounts/accounts.service';
import {DropdownModule} from 'primeng/dropdown';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
@Component({
  selector: 'app-return-items',
  templateUrl: './return-items.component.html',
  styleUrls: ['./return-items.component.css']
})
export class ReturnItemsComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  createReturn: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  personId: any;
  partyIdArray: any[]=[];
  facilities: any;
  dateIdArray: { label: string; value: string; }[];
  headerArray: any[]=[];
  statusIdArray: any[]=[];
  currencyListArray: any[]=[];
  facilityArray: any[]=[];
  estimatedReadyDate: string;
  updateId: any;
  show: boolean;
  pageNo:number = 1;
  
  Date: string;
  createdBy: any;
  activeCategory = 0;
 
 
  returnId: any;
  orderHistory: any;
  downloading: boolean;

  reasonType: any;
  itemStts: any;
  returnTypes: any;
  orderDropdown: any;
  dataTable: any[]=[];
  returnItemAdjustmentMap: any[]=[];
  returnTotal: any;
  needsInventoryReceive: string;
  statusId: string;
  statusDescription: any;
  resReturn: any;
  constructor(
    readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    public addSkillService:AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly router: Router,
    private _location: Location,  ) {
      this.createReturn = this._FormBuilder.group({
        billingAccountId: [''],
        currencyUomId: [''],
        destinationFacilityId: [''],
        entryDate: [''],
        finAccountId: [''],
        fromPartyId: [''],
        needsInventoryReceive: [''],
        originContactMechId: [''],
        paymentMethodId: [''],
        returnHeaderTypeId: [''],
        statusId: [''],
        supplierRmaId: [''],
        toPartyId: [''],
        
      });
     }
     offerShowingLineColoumns = [
      { field: 'des', header: 'Order Items' },
      { field: 'order_qty', header: 'Product' },
      { field: 'return_qty', header: 'Description' },
      { field: 'unit_price', header: 'Quantity' },
      { field: 'return_price', header: 'Price' },
      { field: 'reason', header: '	Sub Total' },
       { field: 'type', header: 'Reason' },
      { field: 'item_status', header: 'Item Status' },
      { field: 'includes', header: 'Type' }, 
      { field: 'action', header: 'Action' }, 
    ];
    offerShowingLineColoumnsShow = [
      { field: 'des', header: 'Order Items' },
      { field: 'order_qty', header: 'Product' },
      { field: 'return_qty', header: 'Description' },
      { field: 'unit_price', header: 'Quantity' },
      { field: 'return_price', header: 'Price' },
      { field: 'total', header: 'Sub total' },
      
       { field: 'type', header: 'Reason' },
      { field: 'item_status', header: 'Item Status' },
      { field: 'includes', header: 'Type' }, 
      { field: 'action', header: 'Response' }, 
    ];
    offerShowing = [
      { field: 'com', header: 'Return Adjustment Comments' },
      { field: 'am', header: 'Return Adjustment Amount' },
      { field: 'type', header: 'Return Type' },
     
      { field: 'action', header: 'Action' }, 
    ];
    offerShowingData = [
      { field: 'com', header: 'Return Adjustment Comments' },
      { field: 'am', header: 'Return Adjustment Amount' },
      { field: 'type', header: 'Return Type' },
     
     
    ];
    							
    offerShowingLineData=[{
      des:'GZ-1006-1 ',
      order_qty:'1',
      return_qty:'Requested',
      unit_price:'US$1.99',
      return_price:'admin',
      reason_type:'2020-11-30 19:59:15.084	',
      item_status:'admin',
      includes:'2020-11-30 19:59:15.084	',
    }  ,
  
 ];
 ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.returnId = params["returnId"],
      
    ])
    this.show=true;
    this.getOrderReturnHistory();
    this.getReturnStatusId();
    this.getReasonItemStatus();
    this.getOrderDropdown();
    this.getReturnOrderValue();
    this.getReturnHeader();
 
    this.getPartyId();
    this.getCurrency();
    this.getDestinationFacility();
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/order/main-order']);
  }
  getReturnOrderValueEdit() {
    this.spinner.show();
    let req = {
    "pageNo":this.pageNo,
    "pageSize":this.pageSize
    }
    let form ={
      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "Contains",
      entryDateTo: "",
      entryDateToSearchType: "Contains",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: this.returnId,
      returnIdSearchType: "Contains",
      statusIds: [""],
    }
  
    
    this.accountsService.getOrderReturn(req,form).subscribe(res=> {
      this.Date=this.datePipe.transform(res.data[0].entryDate,"yyyy-MM-dd")
      this.createdBy=res.data[0].createdBy;
       setTimeout(() => {
        const formValue = this.createReturn;
      formValue.patchValue({
        billingAccountId:res.data[0].billingAccountId,
        currencyUomId: res.data[0].currencyUomId,
        destinationFacilityId:res.data[0].destinationFacilityId,
        entryDate:this.Date,
        finAccountId:res.data[0].finAccountId,
        fromPartyId: res.data[0].fromPartyId,
        needsInventoryReceive: res.data[0].needsInventoryReceive,
        originContactMechId:res.data[0].originContactMechId,
        paymentMethodId:res.data[0].paymentMethodId,
        returnHeaderTypeId: res.data[0].returnHeaderTypeId,
        statusId: res.data[0].statusId,
        supplierRmaId: res.data[0].supplierRmaId,
        toPartyId: res.data[0].toPartyId
       
      })
      }, 3000); 
    })
    this.spinner.hide();
  }
  getDestinationFacility(): void {
    this.spinner.show();
    this.accountsService.getDestinationFacility().subscribe((res) => {
      let facility = res.data;
      this.spinner.hide();
    for (const value of facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId,
        });
      } 
    });
   
  }
  getReturnHeader(): void {
    this.spinner.show();
    this.accountsService.getReturnHeader().subscribe((res) => {
      let header = res.data;
      this.spinner.hide();
    for (const value of header) {
        this.headerArray.push({
          label: value.description,
          value: value.returnHeaderTypeId,
        });
      } 
    });
   
  }
  getReturnStatusId(): void {
    this.spinner.show();
    this.accountsService.getReturnStatusId().subscribe((res) => {
      let statusId = res.data;
      this.spinner.hide();
      for (const value of statusId) {
        this.statusIdArray.push({
          label: value.description,
          value: value.statusId,
        });
      } 
    });
  
  }
  getCurrency(): void {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe((res) => {
      let currencyList = res.data.currencyList;
      this.spinner.hide();
       for (const value of currencyList) {
        this.currencyListArray.push({
          label: value.description,
          value: value.uomId,
        });
      } 
    });
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }

 
  update(): void {
    this.spinner.show();
    this.estimatedReadyDate=this.datePipe.transform(this.createReturn.value.entryDate,"yyyy-MM-dd hh:mm:ss")
     const requestData ={
     
         "billingAccountId":this.createReturn.value.billingAccountId,
         "currencyUomId": this.createReturn.value.currencyUomId,
         "destinationFacilityId": this.createReturn.value.destinationFacilityId,
         "entryDate": this.estimatedReadyDate,
         "finAccountId": this.createReturn.value.finAccountId,
         "fromPartyId": this.createReturn.value.fromPartyId,
         "needsInventoryReceive": this.createReturn.value.needsInventoryReceive,
         "originContactMechId": this.createReturn.value.originContactMechId,
         "paymentMethodId": this.createReturn.value.paymentMethodId,
         "returnHeaderTypeId": this.createReturn.value.returnHeaderTypeId,
         "statusId":this.createReturn.value.statusId,
         "supplierRmaId": this.createReturn.value.supplierRmaId,
         "toPartyId": this.createReturn.value.toPartyId
    
     }
     this.accountsService.updateReturn(requestData,this.returnId,this.createdBy).subscribe((res: any) => {
    
       if (res.success) {
        this.spinner.hide();
         this.toastr.success("Updated Successfully");
         this.closebutton.nativeElement.click();
         this.getReturnOrderValue();
       }
     }, (err) => {
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
     this.spinner.hide();
   }
  getReturnOrderValue() {
    this.spinner.show();
    let req = {
    "pageNo":this.pageNo,
    "pageSize":this.pageSize
    }
    let form ={
      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "Contains",
      entryDateTo: "",
      entryDateToSearchType: "Contains",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: this.returnId,
      returnIdSearchType: "Contains",
      statusIds: [""],
    }
  
    
    this.accountsService.getOrderReturn(req,form).subscribe(res=> {
     this.resReturn=res.data[0];
     this.spinner.hide();
    })
   
  }
  getReasonItemStatus(): void {
    this.spinner.show();
    this.accountsService.getReasonItemStatus().subscribe((res) => {
      this.reasonType = res.data.returnReasons;
      this.itemStts = res.data.itemStts;
      this.returnTypes = res.data.returnTypes;
      this.spinner.hide();
    });
    
  }
 

  getOrderReturnHistory(): void {
    this.spinner.show();
    this.accountsService.getOrderReturnHistory( this.returnId).subscribe((res) => {
      this.orderHistory= res.data.returnStatusHistory;
      this.spinner.hide();
    });
   
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getOrderDropdown(): void {
    this.spinner.show();
    this.accountsService.getOrderDropdown( this.returnId).subscribe((res) => {
      this.orderDropdown= res.data.orderIdList;
      this.dataTable= res.data.returnItemsMap;
      this.returnItemAdjustmentMap=res.data.returnItemAdjustmentMap;
      this.returnTotal=res.data.returnTotal
      this.statusDescription=res.data.returnItemsMap[0].statusDescription
      this.spinner.hide();
    });
    
  }
  downloadPDF(): void {
    this.spinner.show();
    this.downloading = true;
    this.accountsService.generateOrderPDF(this.returnId)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            link.download = `${this.returnId}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
        this.spinner.hide();
}

viewOrderPage(value){
 
  this.router.navigate(["/order/return-view-order-accept"],{queryParams:{orderid:value.orderId,returnID: this.returnId}})
}

returnAccepted(){
  this.spinner.show();
  this.needsInventoryReceive="N";
  this.statusId="RETURN_ACCEPTED"
    this.accountsService.returnAccepted(this.needsInventoryReceive,this.returnId, this.statusId).subscribe((res:any)=> {
    
      if (res.success) {
        this.getOrderDropdown();
        this.spinner.hide();
        this.toastr.success("Successfully");
     
      }
      
  },(err) => {
    this.toastr.error(err.error.message);
 })
 this.spinner.hide();
}
onDelete(id){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
     
    this.accountsService.deleteAdjustmentId(id,this.returnId).subscribe((res:any)=> {
      
      this.getOrderDropdown()
      
  },(err) => {
    this.toastr.error(err.error.message);
 })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
   
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
 


}

deleteRemoveItem(id){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
     
    this.accountsService.deleteRemoveItem(id,this.returnId).subscribe((res:any)=> {
    
      this.getOrderDropdown()
      
  },(err) => {
    this.toastr.error(err.error.message);
 })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
   
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
 
 
}

}
