
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
@Component({
  selector: 'app-emp-contact-information',
  templateUrl: './emp-contact-information.component.html',
  styleUrls: ['./emp-contact-information.component.css']
})
export class EmpContactInformationComponent implements OnInit {
  facilityId: string;
  contactTypes: any[];
  contactInformationTab: string;
  contactInformationValue: string;
  contactMechTypeId: string;
  commonElectronicAddressForm: FormGroup;
  contactPurposeIds: any[];
  emailAddressForm: FormGroup;
  postalAddressForm: FormGroup;
  countryList: any[];
  states: any[];
  telecomNumberForm: FormGroup;
  contactMechId: string;
  editMode: boolean;
  contactData: any;
  constructor(
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly spinner:NgxSpinnerService,
  ) {
    this.commonElectronicAddressForm = this._FormBuilder.group({
      contactMechPurposeTypeId: [''],
      contactMechTypeId: [''],
      facilityId: [''],
      infoString: ['', [Validators.required]]
  });
  this.facilityId = '';
  this.contactTypes = [];
  this.contactInformationTab = 'initial';
  this.contactInformationValue = 'ELECTRONIC_ADDRESS';
  this.contactPurposeIds = [];
  this.emailAddressForm = this._FormBuilder.group({
      contactMechPurposeTypeId: [''],
      contactMechTypeId: [''],
      emailAddress: ['', [Validators.required]],
      facilityId: ['']
  });
  this.constructorExt();
   }
   constructorExt(): void {
    this.postalAddressForm = this._FormBuilder.group({
        address1: ['', [Validators.required]],
        address2: [''],
        attnName: [''],
        city: ['', [Validators.required]],
        contactMechPurposeTypeId: [''],
        contactMechTypeId: [''],
        countryGeoId: ['IND'],
        facilityId: [''],
        postalCode: ['', [Validators.required]],
        stateProvinceGeoId: [''],
        toName: ['']
    });
    this.telecomNumberForm = this._FormBuilder.group({
        areaCode: [''],
        contactMechPurposeTypeId: [''],
        contactMechTypeId: [''],
        contactNumber: [''],
        countryCode: [''],
        extension: [''],
        facilityId: ['']
    });
    this.contactMechId = '';
    this.editMode = false;
}
ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCountryList();
    this.postalAddressForm.controls.countryGeoId.valueChanges.subscribe(value => {
      if (value) {
          this.getStateList(value.trim());
      }
  });
  }
  getCountryList(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountryList()
        .then(data => {
            this.spinner.hide();
            this.countryList = data.data.countryList.map(value => {
                return {
                    label: value,
                    value: value.split(':')[1]
                };
            });
        });
       
}
getStateList(countryId: string): void {
    this.spinner.show();
    this._GlobalResourceService.getStateList(countryId)
        .then(data => {
            this.spinner.hide();
            this.states = data.data.stateList.map(value => {
                return {
                    label: value,
                    value
                };
            });
        });
       
}
ngOnDestroy(): void {
    this.spinner.hide();
      }
}

