import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

    import { Location } from '@angular/common';


@Component({
  selector: 'app-rates-my-profile',
  templateUrl: './rates-my-profile.component.html',
  styleUrls: ['./rates-my-profile.component.css']
})
export class RatesMyProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 8;
  activeCategoryB = 2;
  activeCategoryA = 6;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  sprintTable:any;
  roleForm:FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  partyId:any
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  createParty: FormGroup;
  PartyRate: any;
  taxAuthPartyId: any;
  rateTypes: any;
  periodTypes: any;
  periodTypesArray: any[]=[];
  rateTypesArray: any[]=[];
  currencyArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  Person: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly myContactsService: MyContactsService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
      private _location: Location,
      readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
        readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    this.createParty = this._FormBuilder.group({
      rateType: [''],
      PeriodTypeID: [''],
      rateAmount: [''],
      currency: [''],
      percentageUsed: [''],
      defaultRate: ['']


    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
   }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = params["taxAuthPartyId"];
    });
    this.getRates();
    this.getCurrencyRate();
    this.getRatesInitialData();
    this.getPerson();

  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
      
  getRates() {
    this.spinner.show();
    this.myContactsService.getRates(this.taxAuthPartyId).subscribe(res => {
      this.PartyRate = res.data[0];
      this.spinner.hide();
    })

  }
  getRatesInitialData() {
    this.spinner.show();
    this.myContactsService.getRatesData().subscribe(res => {

      this.periodTypes = res.data.periodTypes;
      this.rateTypes = res.data.rateTypes;

      this.spinner.hide();

      for (const value of this.periodTypes) {
        this.periodTypesArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }
      for (const value of this.rateTypes) {
        this.rateTypesArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
    })
  }
  onSubmitRate() {
    this.spinner.show();
    let requestData = {
      "partyId": this.taxAuthPartyId,
      "defaultRate": this.createParty.value.defaultRate,
      "percentageUsed": this.createParty.value.percentageUsed,
      "periodTypeId": "RATE_HOUR",
      "rateAmount": this.createParty.value.rateAmount,
      "rateCurrencyUomId": this.createParty.value.currency,
      "rateTypeId": this.createParty.value.rateType,
 
    }

    this.myContactsService.updatePartyRate(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getRates();
        this.createParty.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.debugMessage);
      }
    });

  }
  getPerson() {
    this.spinner.show();
    this.accountsService.getPerson(this.taxAuthPartyId).subscribe(res => {
      this.Person = res.data;
      this.spinner.hide();
    })

  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  MyProfile() {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Preferences() {
    this._Router.navigate(["/psa/preferences-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Roles() {
    this._Router.navigate(["/psa/roles"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Project() {
    this._Router.navigate(["/psa/project-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  TasksProfile() {
    this._Router.navigate(["/psa/tasks-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Rates() {
    this._Router.navigate(["/psa/rates-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }
  Revisions() {
    this._Router.navigate(["/psa/revisions-my-profile"], { queryParams: { taxAuthPartyId: this.taxAuthPartyId } })
  }


  Backlog(){
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint(){
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork(){
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks(){
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest(){
    this._Router.navigate(["/psa/open-test"])
  }
  Revision(){
    this._Router.navigate(["/psa/find-task-revision"])
  }
}
