import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-work-effort-contents',
  templateUrl: './work-effort-contents.component.html',
  styleUrls: ['./work-effort-contents.component.css']
})
export class WorkEffortContentsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 14;
  addContent: FormGroup;
  updateContent: any;
  ContentTypeIds: any;
  ContentTypeIdsArray: any[]=[];
  ContentId: any;
  ContentIdArray: any[]=[];
  workEffortId: any;
  ContentDetailsByIddata: any;
  FromDate: string;
  ThruDate: string;
  show: boolean;
  Fromdate: string;
  ThroughDate: string;
  updateThroughDate: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  pageNo=1;
  search="";
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
    
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
        readonly _FormBuilder: FormBuilder,
    private _Router : Router

  ) {
    this.addContent = this._FormBuilder.group({
      contentID: [''],
      contentTypeID: [''],
      fromDate: [''],
      throughDate: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
    })
    this.getContentTypeIds();
    this.getContentId();
    this.getContentDetailsById();
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  deleteWorkEffortContent(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
  "contentId": product.list.contentId,
  "fromDate": product.fromDate,
  "workEffortContentTypeId": product.list.workEffortContentTypeId,
  "workEffortId": this.workEffortId
  
        }
        this.myContactsService.deleteWorkEffortContent(req).subscribe(res => {
          this.getContentDetailsById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  createWorkEffortContent(): void {
    this.spinner.show();
    const fromDate=this.addContent.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addContent.get('throughDate').value;
    this.ThruDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
   
      "contentId": this.addContent.value.contentID,
      "fromDate": this.FromDate,
      "thruDate": this.ThruDate,
      "workEffortContentTypeId": this.addContent.value.contentTypeID,
      "workEffortId": this.workEffortId
  
       
         
     }
     this.myContactsService.createWorkEffortContent(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addContent.reset();
         this.getContentDetailsById();
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   updateWorkEffortContent(): void {
    this.spinner.show();
    const fromDate=this.addContent.get('fromDate').value;
    this.Fromdate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addContent.get('throughDate').value;
    this.ThroughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  "contentId": this.addContent.value.contentID,
  "fromDate": this.Fromdate,
  "thruDate": this.ThroughDate,
  "workEffortContentTypeId": this.addContent.value.contentTypeID,
  "workEffortId": this.workEffortId
  
   
      }
     
    this.myContactsService.updateWorkEffortContent(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getContentDetailsById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  updateEvent(product){
    this.show=true;
    this.addContent.reset();
    this.spinner.show();
   
      this.updateThroughDate=product.throughDate;
      setTimeout(() => {
        const fromdate=product.list.fromDate;
        this.FromDate=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const thrudate=product.list.throughDate;
        this.ThroughDate=this.datePipe.transform(thrudate,"yyyy-MM-dd");
        const formValue = this.addContent;
        formValue.patchValue({
  "contentID": product.list.contentId,
  "fromDate": this.FromDate,
  "throughDate": this.ThroughDate,
  "contentTypeID": product.list.workEffortContentTypeId,
  "workEffortId": this.workEffortId
  
        })
      
      }, 2000);
  
  }
  getContentDetailsById(){
    this.spinner.show();
    this.myContactsService.getContentDetailsById(this.workEffortId).subscribe((res:any)=> {
      this.ContentDetailsByIddata = res.data;
      this.spinner.hide();
    
    })   
   }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"]),{ queryParams: { workEffortId: this.workEffortId} }
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  getContentTypeIds() {
    this.spinner.show();
    this.addSkillService.getContentTypeIds().subscribe(res => {
      this.ContentTypeIds = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContentTypeIds) {
        this.ContentTypeIdsArray.push({
          label: value.description,
          value: value.workEffortContentTypeId
        })
      }
    })
  }
  getContentId() {
    this.spinner.show();
    this.addSkillService.getContentId().subscribe(res => {
      this.ContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContentId) {
        this.ContentIdArray.push({
          label: value.contentId,
          value: value.contentId
        })
      }
    })
  }
  
}
