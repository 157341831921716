import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-edit-reviews-catalog',
  templateUrl: './edit-reviews-catalog.component.html',
  styleUrls: ['./edit-reviews-catalog.component.css']
})
export class EditReviewsCatalogComponent implements OnInit {
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addGeo: any;
  addGoodID: any;
  addCategory: any;
  addKeyword: any;
  addAssoc: any;
  addCost: any;
  addCostCalc: any;
  addAutoCalc: any;
  addAttr: any;
  addFeatureGroup: any;
  addFeatureTypeID: any;
  addFeatureID: any;
  addFeatureInteraction: any;
  addProductFeatureAttr: any;
  addFacility: any;
  addLocation: any;
  addAgreement: any;
  addSalesAgreement: any;
  addGL: any;
  addPaymentType: any;
  addMaintenance: any;
  addMeter: any;
  addSubsResource: any;
  addVendor: any;
  addConfig: any;
  addWE: any;
  addAssocParty: any;
  addComm: any;
  addGroupOrder: any;
  addPublish: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) { 
    this.addGeo = this._FormBuilder.group({
      geoID: [''],
      enumID: [''],
      desc: [''],
    });
    this.addGoodID = this._FormBuilder.group({
      idType: [''],
      idValue: [''],
    });
    this.addCategory = this._FormBuilder.group({
      categoryID: [''],
      fromDate: [''],
      throughDate: [''],
      comments: [''],
      seqNum: [''],
      qty: [''],
    });
    this.addKeyword = this._FormBuilder.group({
      keyword: [''],
      typeID: [''],
      relWeight: [''],
      statusID: [''],
    });
    this.addAssoc = this._FormBuilder.group({
      proID: [''],
      proIdTo: [''],
      assocType: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
      reason: [''],
      instruction: [''],
      qty: [''],
    });
    this.addCost = this._FormBuilder.group({
      typeID: [''],
      featureID: [''],
      partyID: [''],
      geoID: [''],
      workEffortID: [''],
      fixedAssetID: [''],
      calcID: [''],
      fromDate: [''],
      throughDate: [''],
      cost: [''],
      currency: [''],
    });
    this.addCostCalc = this._FormBuilder.group({
      typeID: [''],
      fromDate: [''],
      calcID: [''],
      seqNum: [''],
      throughDate: [''],
    });
    this.addAutoCalc = this._FormBuilder.group({
      currency: [''],
      prefix: [''],
    });
    this.addAttr = this._FormBuilder.group({
      name: [''],
      value: [''],
      type: [''],
      desc: [''],
    });
    this.addFeatureGroup = this._FormBuilder.group({
      category: [''],
      group: [''],
      appType: [''],
    });
    this.addFeatureTypeID = this._FormBuilder.group({
      featureType: [''],
      idCode: [''],
      appType: [''],
      fromDate: [''],
      throughDate: [''],
      seq: [''],
    });
    this.addFeatureID = this._FormBuilder.group({
      ID: [''],
      appType: [''],
      fromDate: [''],
      throughDate: [''],
      seq: [''],
    });
    this.addFeatureInteraction = this._FormBuilder.group({
      featureID: [''],
      featureIdTo: [''],
      interactionType: [''],
    });
    this.addProductFeatureAttr = this._FormBuilder.group({
      featureID: [''],
      fromDate: [''],
      attrName: [''],
      attrValue: [''],
    });
    this.addFacility = this._FormBuilder.group({
      facilityID: [''],
      minStock: [''],
      reorderQuantity: [''],
      dayToShip: [''],
      replenishEnumID: [''],
      reqEnumID: [''],
    });
    this.addLocation = this._FormBuilder.group({
      facilityID: [''],
      locSeqID: [''],
      minStock: [''],
      moveQty: [''],
    });
    this.addAgreement = this._FormBuilder.group({
      proID: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      roleTypeIDFrom: [''],
      roleTypeIDTo: [''],
      agreementTypeID: [''],
      agreementDate: [''],
      fromDate: [''],
      throughDate: [''],
      desc: [''],
      txtData: [''],
    });
    this.addSalesAgreement = this._FormBuilder.group({
      partyIDFrom: [''],
      partyIDTo: [''],
      fromDate: [''],
      throughDate: [''],
      desc: [''],
      price: [''],
      currency: [''],
    });
    this.addGL = this._FormBuilder.group({
      accType: [''],
      organization: [''],
      glAcc: [''],
    });
    this.addPaymentType = this._FormBuilder.group({
      paymentType: [''],
      purpose: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
    });
    this.addMaintenance = this._FormBuilder.group({
      typeID: [''],
      name: [''],
      workEffortID: [''],
      intQty: [''],
      intUomID: [''],
      meterTypeID: [''],
      repeatCount: [''],
    });
    this.addMeter = this._FormBuilder.group({
      meterTypeID: [''],
      meterUomID: [''],
      meterName: [''],
    });
    this.addSubsResource = this._FormBuilder.group({
      susResourceID: [''],
      fromDate: [''],
      throughDate: [''],
      purchaseFromDate: [''],
      purchaseThroughDate: [''],
      maxLifeTime: [''],
      lifeTimeUomID: [''],
      availTime: [''],
      availUomID: [''],
      countLimit: [''],
      useTime: [''],
      useUomID: [''],
      useRoleTypeID: [''],
      extendPeriod: [''],
      autoExtension: [''],
      measureUnit: [''],
      graceExpiry: [''],
      graceExpiryUomID: [''],
    });
    this.addPublish = this._FormBuilder.group({
      fromDate: [''],
    });
    this.addVendor = this._FormBuilder.group({
      partyID: [''],
      groupID: [''],
    });
    this.addConfig = this._FormBuilder.group({
      configItem: [''],
      seqNum: [''],
      fromDate: [''],
      desc: [''],
      longDesc: [''],
      configTypeID: [''],
      optionID: [''],
      throughDate: [''],
      isMandatory: [''],
    });
    this.addWE = this._FormBuilder.group({
      workEffortID: [''],
      weGoodID: [''],
      fromDate: [''],
      throughDate: [''],
      status: [''],
      estimatedQty: [''],
      estimatedCost: [''],
    });
    this.addAssocParty = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      seqNum: [''],
      comments: [''],
    });
    this.addComm = this._FormBuilder.group({
      commEventID: [''],
      commTypeID: [''],
      status: [''],
      partyFrom: [''],
      roleTypeID: [''],
      mechTypeID: [''],
      fromMech: [''],
      toMech: [''],
      roleTypeIDFrom: [''],
      roleTypeIDTo: [''],
      contactListID: [''],
      startDate: [''],
      finishDate: [''],
      subject: [''],
      reasonEnumID: [''],
      contentMimeTypeID: [''],
      content: [''],
      note: [''],
      msgID: [''],
    });
    this.addGroupOrder = this._FormBuilder.group({
      dealQty: [''],
      dealStartDate: [''],
      dealEndDate: [''],
    });
    
  }

  ngOnInit(): void {
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
