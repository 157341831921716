<div class="container-fluid main-container-wrapper">

    <br>
    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">Find Budgets </span>
    </div>
    <div class=" bg-white color-grey">

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
                <form class="w-100">
                    <div class="">

                        <div class="w3-card-4  w-100">
                            <div class="accordion classCard" id="myAccordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                            <button data-toggle="modal" data-target="#exampleModalCenter marginHeader" type="button" class="btn btn-outline-secondary">Create New Budget
                                            </button>
       
                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 78%!important; padding-top: 11px !important;
                                                padding-bottom: 11px !important;">
                                            </button>									

                                        </div>
    
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                            <form class="w-100" [formGroup]="budgetForm">
                                                <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Budget ID</label>
                                                            </div>
                
                                                            <div class="col-lg-2" style="display: none;">
                                                                <p-dropdown filter="true" formControlName="budgetIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" name="partyId" formControlName="budgetId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                            </div>
                
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Budget Type</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <p-dropdown filter="true" formControlName="budgetTypeId" [options]="BudgetTypeArray" optionlabel="label" placeholder="Enter Budget Type">
                                                                </p-dropdown>
                
                                                            </div>
                
                
                
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="row">
                
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Custom Time Period ID</label>
                                                            </div>
                
                                                            <div class="col-lg-2" style="display: none;">
                                                                <p-dropdown filter="true" formControlName="customTimePeriodIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" class="form-control" formControlName="customTimePeriodId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Custom Time Period ID">
                                                            </div>
                
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Comments</label>
                                                            </div>
                                                            <div class="col-lg-2" style="display: none;">
                                                                <p-dropdown formControlName="commentsSearchType" filter="true" [options]="opportunityIdArray" optionlabel="label">
                
                                                                </p-dropdown>
                
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="email" formControlName="comments" id="exampleInputEmail1" class="form-control" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                            </div>
                
                
                
                                                        </div>
                                                    </div>
                
                
                                                </div>
                                            </form>

                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -24%;">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearch()">Find</button>&nbsp;
                                                <button type="button" (click)="reset();" class="btn btn-danger">Reset</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        
                        <!-- <div class="w3-card-4 classCardA">

                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">

                                <button data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-outline-secondary">Create New Budget
                                     </button>

                            </div>
                            <br>
                            <form class="w-100" [formGroup]="budgetForm">
                                <div class="all-users-infomation font-13 font-medium  own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Budget ID</label>
                                            </div>

                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown filter="true" formControlName="budgetIdSearchType" [options]="opportunityIdArray" optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" name="partyId" formControlName="budgetId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Budget Type</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" formControlName="budgetTypeId" [options]="BudgetTypeArray" optionlabel="label" placeholder="Enter Budget Type">
                                                </p-dropdown>

                                            </div>



                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Custom Time Period ID</label>
                                            </div>

                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown filter="true" formControlName="customTimePeriodIdSearchType" [options]="opportunityIdArray" optionlabel="label">

                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" formControlName="customTimePeriodId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Custom Time Period ID">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown formControlName="commentsSearchType" filter="true" [options]="opportunityIdArray" optionlabel="label">

                                                </p-dropdown>

                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="comments" id="exampleInputEmail1" class="form-control" aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>



                                        </div>
                                    </div>


                                </div>
                            </form>
                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -24%;">
                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearch()">Find</button>&nbsp;
                                <button type="button" (click)="reset();" class="btn btn-danger">Reset</button>
                            </div>
                        </div> -->
                        <br>
                        
                        <div class="w3-card-4 classCard">
                            <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                    <h4 class=" common-styling Hleft">
                                        Search Results
                                    </h4>

                                </div>
                            </header>
                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group">

                                        <div class="card own-account-table">
                                            <p-table [value]="budgetList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th pSortableColumn="code">
                                                            <div class="checkbox-align" style="color: white;">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                Budget Id
                                                                <p-sortIcon field="code"></p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="name">
                                                            <div style="color: white;">Budget Type Id
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="category">
                                                            <div style="color: white;"> Custom Time Period Id
                                                                <p-sortIcon field="category">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="price">
                                                            <div style="color: white;">Comment</div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>


                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                    <tr>
                                                        <td>
                                                            <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                <span  class="account-button"  (click)="detailsumm(product.budgetId)">{{product.budgetId}}</span>
                                                            </div>
                                                        </td>
                                                        <td>{{product.budgetType}}</td>
                                                        <td>{{product.customTimePeriodId}}</td>
                                                        <td>{{product.comments}}</td>


                                                    </tr>
                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                                 <h4 class="ml-2">No Record Found</h4>
                                                             </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </ng-template>
                                            </p-table>
                                            <p class="paginate_data">
                                                View per page </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Budget</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                            Add Budget
                        </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                            Update Budget
                        </a></li>

                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createbudget">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                              
                                                        Budget Type ID<span
                                                        style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Budget Type ID" filter="true" formControlName="budgetId" [options]="BudgetTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                  
                                                        Budget ID</label>
                                                    </div>

                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <label>{{this.budgetId}}</label>

                                                    </div>




                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                  
                                                        Custom Time Period ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Custom Time Period ID" filter="true" formControlName="customTime" [options]="timePerodArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments" formControlName="comment">
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 84%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>