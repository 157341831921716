<div class="container-fluid main-container-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
         <span class="color-black pl-1 titlePanels">Gl Reconciliation For : {{this.finAccountId}} </span> <span></span>
         
        </div>
        <div class=" bg-white color-grey">
          <div class="port-header-contact create-lead font-medium mb-0">
            <div class="suppliers-wrapper">
            
            </div>
          </div>
          <div class="container-fluid">
          <ul class="tabbing-section tabbing-accordians">
          
                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                    Current Bank Reconciliation</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                        Previous Bank Reconciliation</a></li>

                       <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                        Fin Account Trans Associated To Gl Reconciliation</a></li>
                        
                                        
           
          </ul>
        </div>
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">
                  <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        
                        <div  *ngIf="activeCategory==2">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                                Current Bank Reconciliation Information
                              </h4>
                             
                            
                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                    <div class="row">
                                        <p class="col-lg-2 col-12">
                                            Gl Reconciliation Name</p>
                                        <span
                                            class="color-grey pl-20 col-lg-10 col-12">{{this.currentGlReconciliation.glReconciliationName}}</span>
                                        <p class="col-lg-2 col-12">Status</p>
                                        <span
                                            class="color-grey pl-20 col-lg-10 col-12">{{this.currentGlReconciliation.status}}</span>
                                        <p class="col-lg-2 col-12">Reconciled Date</p>
                                        <span
                                            class="color-grey pl-20 col-lg-10 col-12">{{this.currentGlReconciliation.reconciledDate|date:"yyyy-MM-dd hh:mm:ss"}}</span>
                                        <p class="col-lg-2 col-12">Opening Balance</p>
                                        <span
                                            class="color-grey pl-20 col-lg-10 col-12">{{this.currentGlReconciliation.openingBalance}}</span>
                                        <p class="col-lg-2 col-12">Reconciled Balance</p>
                                        <span
                                            class="color-grey pl-20 col-lg-10 col-12">{{this.currentGlReconciliation.reconciledBalance}}</span>
                                        <p class="col-lg-2 col-12">Closing Balance</p>
                                        <span
                                            class="color-grey pl-20 col-lg-10 col-12">{{this.currentGlReconciliation.reconciledBalance}}</span>
                                        

                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        </div>
                        <div  *ngIf="activeCategory==3">
                            <div class="header-tabs">
                              <h4 class=" common-styling">
                                Previous Bank Reconciliation
                                </h4>
                                
                              
                            </div>
                            <div class="panel-body">
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                  <div class="color-black container">
                                      <div class="row">
                                          <p class="col-lg-2 col-12">
                                              Gl Reconciliation Name</p>
                                          <span
                                              class="color-grey pl-20 col-lg-10 col-12">{{this.previousGlReconciliation.glReconciliationName}}</span>
                                          <p class="col-lg-2 col-12">Status</p>
                                          <span
                                              class="color-grey pl-20 col-lg-10 col-12">{{this.previousGlReconciliation.status}}</span>
                                          <p class="col-lg-2 col-12">Reconciled Date</p>
                                          <span
                                              class="color-grey pl-20 col-lg-10 col-12">{{this.previousGlReconciliation.reconciledDate|date:"yyyy-MM-dd hh:mm:ss"}}</span>
                                          <p class="col-lg-2 col-12">Opening Balance</p>
                                          <span
                                              class="color-grey pl-20 col-lg-10 col-12">{{this.previousGlReconciliation.openingBalance}}</span>
                                          <p class="col-lg-2 col-12">Reconciled Balance</p>
                                          <span
                                              class="color-grey pl-20 col-lg-10 col-12">{{this.previousGlReconciliation.reconciledBalance}}</span>
                                          <p class="col-lg-2 col-12">Closing Balance</p>
                                          <span
                                              class="color-grey pl-20 col-lg-10 col-12">{{this.previousGlReconciliation.reconciledBalance}}</span>
                                          
  
                                            
                                      </div>
                                  </div>
                              </div>
                          </div>
                              
                          </div>
                          <div  *ngIf="activeCategory==4">
                            <div class="header-tabs">
                              <h4 class=" common-styling">
                                Fin Account Trans Associated To Gl Reconciliation
                                </h4>
                               
                              
                            </div>
                              <div class="panel-body">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                   
                                  <div class="form-group">
                                    <div class="card own-account-table">
                                      <p-table [value]="finAccountTransactions" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                        (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                          <tr>
                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                              <div class="checkbox-align">
                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                  binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div class="custom-checkbox ui-chkbox ui-widget">
                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                        name="undefined" value="undefined"></div>
                                                    <div role="checkbox"
                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                        class="ui-chkbox-icon ui-clickable"></span></div>
                                                  </div>
                                                  <!---->
                                                </p-checkbox>
                                              
                                               
                                          Fin Account Trans ID<p-sortIcon field="code"></p-sortIcon>
                                              </div>
                                              <input id="j_idt727:j_idt728:j_idt731:filter"
                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                value="" autocomplete="off"
                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                aria-disabled="false" aria-readonly="false">
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">Fin Account Trans Type <p-sortIcon field="name"></p-sortIcon>
                                                <div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                    aria-disabled="false" aria-readonly="false">
                                                </div>
                                              </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">	Party <p-sortIcon field="name"></p-sortIcon>
                                              <div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                  aria-disabled="false" aria-readonly="false">
                                              </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Transaction Date<p-sortIcon field="category">
                                              </p-sortIcon>
                                              <div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                  aria-disabled="false" aria-readonly="false">
                                              </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">		Entry Date <p-sortIcon field="category">
                                          </p-sortIcon>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Amount<p-sortIcon field="category">
                                        </p-sortIcon>
                                        <div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </div>
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Payment ID <p-sortIcon field="category">
                                    </p-sortIcon>
                                    <div>
                                      <input id="j_idt727:j_idt728:j_idt731:filter"
                                        name="j_idt727:j_idt728:j_idt731:filter"
                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                        value="" autocomplete="off"
                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                        aria-disabled="false" aria-readonly="false">
                                    </div>
                                  </th>
                                  <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Payment Type <p-sortIcon field="category">
                                </p-sortIcon>
                                <div>
                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                    name="j_idt727:j_idt728:j_idt731:filter"
                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                    value="" autocomplete="off"
                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                    aria-disabled="false" aria-readonly="false">
                                </div>
                              </th>
                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">Payment Method Type<p-sortIcon field="category">
                            </p-sortIcon>
                            <div>
                              <input id="j_idt727:j_idt728:j_idt731:filter"
                                name="j_idt727:j_idt728:j_idt731:filter"
                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                value="" autocomplete="off"
                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                aria-disabled="false" aria-readonly="false">
                            </div>
                          </th>
                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">	Status<p-sortIcon field="category">
                        </p-sortIcon>
                        <div>
                          <input id="j_idt727:j_idt728:j_idt731:filter"
                            name="j_idt727:j_idt728:j_idt731:filter"
                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                            value="" autocomplete="off"
                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                            aria-disabled="false" aria-readonly="false">
                        </div>
                      </th>
                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">	Comments<p-sortIcon field="category">
                    </p-sortIcon>
                    <div>
                      <input id="j_idt727:j_idt728:j_idt731:filter"
                        name="j_idt727:j_idt728:j_idt731:filter"
                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                        value="" autocomplete="off"
                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                        aria-disabled="false" aria-readonly="false">
                    </div>
                  </th>
               
    
                                          </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                          <tr>
                                            <td [ngStyle]="{'width':'170px'}">
                                              <div class="checkbox-align">
                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                  binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div class="custom-checkbox ui-chkbox ui-widget">
                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                        name="undefined" value="undefined"></div>
                                                    <div role="checkbox"
                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                        class="ui-chkbox-icon ui-clickable"></span></div>
                                                  </div>
                                                </p-checkbox>
                                                {{product.finAccountTransId}} 
                                              </div>
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.finAccountTransTypeId}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.party}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.transactionDate | date:"yyyy-MM-dd hh:mm:ss"}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.entryDate| date:"yyyy-MM-dd hh:mm:ss"}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.amount}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.paymentId}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.paymentType}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.paymentMethodType}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.statusId}} </td>
                                            <td [ngStyle]="{'width':'170px'}"> {{product.comments}} </td>
                                         
                                          </tr>
                                        </ng-template>
                                      </p-table>
                                      <p class="paginate_data">
                                        View per page
                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                          <span style="margin-left: 400px;">No Record Found!....</span>
                                        </ng-container>
                                      </p>
                                      <br>
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <label style="margin-left: 5%;
                              color: black;">TOTAL ${{this.grandTotal}}</label>
                              <button *ngIf="this.currentGlReconciliation.status!='Reconciled'" type="submit"  (click)="reconcileAll()" class="btn btn-secondary submit-btn">Reconcile</button>
                                
                          </div>
                       
                      </div>
                    </div>
                  </div>
  
                </form>
              
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  
  <ngx-spinner></ngx-spinner>