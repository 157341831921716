import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-logged-user',
  templateUrl: './detail-logged-user.component.html',
  styleUrls: ['./detail-logged-user.component.css']
})
export class DetailLoggedUserComponent implements OnInit {
  userLoginId: any;
  partyId: any;
  clientIpAddress: any;
  activeCategory=1;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    private _location: Location,) { }
 
  ngOnInit(): void {
 
    this.activatedRoute.queryParams.subscribe(params=> [
      this.userLoginId = params["userLoginId"],
      this.partyId=params["partyId"],
      this.clientIpAddress=params["clientIpAddress"],
    
    ])
   
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/hub/Dashboard']);
  }
  cancelSubmit(){
    this.router.navigate(["/security/address-match-map"])
  }

}
