import { Component, OnInit } from '@angular/core';
import {LeadPersonalFormService} from "../lead-personal-group/lead-personal-group-service";
import {ActivatedRoute,Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import {LeadPersonalServices} from "../lead-personal-group/lead-personal-group.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadPersonalLeadServices } from 'src/app/leadPersonal/lead-personal/lead-personal.service';

@Component({
  selector: 'app-lead-personal-group',
  templateUrl: './lead-personal-group.component.html',
  styleUrls: ['./lead-personal-group.component.css']
})
export class LeadPersonalGroupComponent implements OnInit {
  partyId: any;
  partyIdValue: any;
  getCurrencyList: any;
  currencyArray: any=[];
  crmStatus: any;
  crmArray: any=[];
  partyGroupId:any;

  constructor(public leadPersonalFormService:LeadPersonalFormService,
    readonly activatedRoute:ActivatedRoute,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly router:Router,
    readonly leadPersonalLeadServices: LeadPersonalLeadServices,
    readonly leadPersonalServices:LeadPersonalServices
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
     
      this.partyGroupId = params["partyGroupId"];
      this.partyId = params["partyId"];
     
    });

    if(this.partyId){
      this.partyIdValue=this.partyId;
    }
    this.getCrmStatus();
    this.getLeadDataByPartyId();
    this.leadPersonalFormService.lead_group_form=null;
    this.leadPersonalFormService.leadPersonaltGroup(this.leadPersonalFormService.lead_group_form);
    this.getCurrency();
  }
  getCurrency() {
    this.spinner.show();
    this.leadPersonalServices.getCurrency().subscribe(res=> {
      this.getCurrencyList = res.data.currencyList;
      this.spinner.hide();
     this.getCurrencyList.forEach(currencyList=> {
       this.currencyArray.push({
         label:currencyList.description + "-" +  currencyList.uomId,
         value:currencyList.uomId
       })
     })
      })
     
    }
  onUpdate(){
    this.spinner.show();
    const formData = {...this.leadPersonalFormService.lead_group_form.value};
    this.leadPersonalServices.updatePartyGroup(this.partyGroupId,formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
       this.router.navigate(['/crm/leads/details-lead'],{queryParams:{leadId:this.partyId}})
      }    },
      (err)=> {
        this.spinner.hide();
      
      })
      this.spinner.hide();
  }
  onCancel(){
    this.router.navigate(['/crm/leads/details-lead'],{queryParams:{leadId:this.partyId}})

  }
  getLeadDataByPartyId() {
    this.spinner.show();
    this.leadPersonalLeadServices.getLeadByPartyId(this.partyId).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
        
      
         const formData = this.leadPersonalFormService.lead_group_form;
        formData.patchValue({
          groupName: res.data.partyGrpInformation[0].company,
          groupLocalName: res.data.partyGrpInformation[0].groupNameLocal,
          officeSiteName: res.data.partyGrpInformation[0].officeSiteName,
          annualRevenue: res.data.partyGrpInformation[0].annualRevenue,
          noOfEmployees: res.data.partyGrpInformation[0].numEmployees,
          tickerSymbol: res.data.partyGrpInformation[0].tickerSymbol,
          comments: res.data.partyGrpInformation[0].comments,
  
        })
      
      }, 3000);
   
    })
   
  }

  getCrmStatus(){
    this.spinner.show();
    this.leadPersonalServices.getCrmStatus().subscribe(res=> {
      this.crmStatus = res.data;
      this.spinner.hide();
      for(const value of this.crmStatus){
        this.crmArray.push({
          label:value.description,
          value:value.statusId
        })
      }
    })
  
  }

}
