import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.css']
})
export class VisitsComponent implements OnInit {
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  pageNo = 1;
  activeCategory = 1;
  search = "";
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  greaterArray = [
    {
      label: 'Equals',
      value: 'equals'
    },
    {
      label: 'Same Day',
      value: 'sameDay'
    },
    {
      label: 'Greater Than From Day Start',
      value: 'greaterThanFromDayStart'
    },
    {
      label: 'Greater Than',
      value: 'greaterThan'
    }
  ];
  smallerArray = [
    {
      label: 'Less Than',
      value: 'opLessThan'
    },
    {
      label: 'Up To Day',
      value: 'upToDay'
    },
    {
      label: 'Up Through Day',
      value: 'upThruDay'
    },
    {
      label: 'Is Empty',
      value: 'empty'
    }
  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  searchArray:any[]=[];
  Visitors: any;
  advanceSearch: {

    clientIpAddress: string,
    clientIpAddress_ic: string,
    clientIpAddress_op: string,
    noConditionFind: string,
    partyId: string,
    userCreated_fld0_op: string,
    userCreated_fld0_value: string,
    userCreated_fld1_op: string,
    userCreated_fld1_value: string,
    userLoginId: string,
    userLoginId_ic: string,
    userLoginId_op:string,
    visitId:string,
    visitId_ic: string,
    visitId_op: string,
    visitorId: string,
    visitorId_ic: string,
    visitorId_op: string,
    webappName: string,
    webappName_ic: string,
    webappName_op: string,

  };
  personId: any;
  partyIdArray: any[]=[];
  activeOnly='N';
  constructor(private accountsService: AccountsService,
    private headerService: HeaderService,
    readonly datePipe: DatePipe,
    readonly _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    private addSkillService: AddSkillService,
    readonly router: Router,
    private _location: Location,
    ) {
      this.advanceSearch= {

        clientIpAddress: '',
        clientIpAddress_ic:'Y',
        clientIpAddress_op: "contains",
        noConditionFind:'Y',
        partyId: '',
        userCreated_fld0_op: "equals",
        userCreated_fld0_value: '',
        userCreated_fld1_op:"opLessThan",
        userCreated_fld1_value: '',
        userLoginId: '',
        userLoginId_ic: 'Y',
        userLoginId_op: "contains",
        visitId:'',
        visitId_ic:'Y',
        visitId_op:  "contains",
        visitorId: '',
        visitorId_ic: 'Y',
        visitorId_op:  "contains",
        webappName: '',
        webappName_ic: 'Y',
        webappName_op: "contains",
    
      };
     }

  ngOnInit(): void {
    this.getVisitors();
    this.getPartyId();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/security/security-dashboard']);

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  selectAllDepositWith(e) {
   
    if (e.target.ariaChecked === "true") {
      this.activeOnly='Y'
    }
    else {
      this.activeOnly='N'
    }
  }
  getVisitors() {
    this.spinner.show();
   
    this.accountsService.getVisitors(this.finSize).subscribe(res => {
      this.Visitors = res.data;
      this.spinner.hide();
    })
  }
  reset() {
    this.spinner.show();
    this.advanceSearch= {

      clientIpAddress: '',
      clientIpAddress_ic:'Y',
      clientIpAddress_op: "contains",
      noConditionFind:'Y',
      partyId: '',
      userCreated_fld0_op: "equals",
      userCreated_fld0_value: '',
      userCreated_fld1_op:"opLessThan",
      userCreated_fld1_value: '',
      userLoginId: '',
      userLoginId_ic: 'Y',
      userLoginId_op: "contains",
      visitId:'',
      visitId_ic:'Y',
      visitId_op:  "contains",
      visitorId: '',
      visitorId_ic: 'Y',
      visitorId_op:  "contains",
      webappName: '',
      webappName_ic: 'Y',
      webappName_op: "contains",
  
    };
    this.accountsService.getVisitors(this.finSize).subscribe(res => {
      this.Visitors = res.data;
      this.spinner.hide();
    })
  }
  
  
  advanceSearchDeposit(isPopUp: boolean): void {
    this.spinner.show();
    this.Visitors = [];
    const req = {
      clientIpAddress:this.advanceSearch.clientIpAddress ? this.advanceSearch.clientIpAddress : '',
      clientIpAddress_ic:'Y',
      clientIpAddress_op: this.advanceSearch.clientIpAddress_op ? this.advanceSearch.clientIpAddress_op : '',
      noConditionFind:'Y',
      partyId:this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      userCreated_fld0_op: this.advanceSearch.userCreated_fld0_op ? this.advanceSearch.userCreated_fld0_op : '',
      userCreated_fld0_value: this.datePipe.transform(this.advanceSearch.userCreated_fld0_value, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.userCreated_fld0_value, "yyyy-MM-dd hh:mm:ss") : '',
      userCreated_fld1_op:this.advanceSearch.userCreated_fld1_op ? this.advanceSearch.userCreated_fld1_op : '',
      userCreated_fld1_value:this.datePipe.transform(this.advanceSearch.userCreated_fld1_value, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.userCreated_fld1_value, "yyyy-MM-dd hh:mm:ss") : '',
      userLoginId:this.advanceSearch.userLoginId ? this.advanceSearch.userLoginId : '',
      userLoginId_ic: 'Y',
      userLoginId_op: this.advanceSearch.userLoginId_op ? this.advanceSearch.userLoginId_op : '',
      visitId:this.advanceSearch.visitId ? this.advanceSearch.visitId : '',
      visitId_ic:'Y',
      visitId_op: this.advanceSearch.visitId_op ? this.advanceSearch.visitId_op : '',
      visitorId: this.advanceSearch.visitorId ? this.advanceSearch.visitorId : '',
      visitorId_ic: 'Y',
      visitorId_op: this.advanceSearch.visitorId_op ? this.advanceSearch.visitorId_op : '',
      webappName: this.advanceSearch.webappName ? this.advanceSearch.webappName : '',
      webappName_ic: 'Y',
      webappName_op: this.advanceSearch.webappName_op ? this.advanceSearch.webappName_op : '',

     

    }
    this.accountsService.findVisitors(this.finSize, this.activeOnly, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.Visitors = resp.data;
        } else {
        
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          
        }
      });
     
  }
  detailSecurity(id){
    this.router.navigate(["/security/visitor-summary"],{queryParams:{visitId:id}})
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

}
