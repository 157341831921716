<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/invoices">Invoices </span>
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/invoices/detailed-summary"
                    [queryParams]="{invoiceId:invoiceId}">>Invoice Summary </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update':'Create'}} Invoice Item </span>
                <button class="btn btn-danger" style="margin-left: 57%;"
                (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                  background: #F5F7FA!important;">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        {{editMode ? 'Update':'Create'}} Invoice Item</a></li>
                       
                   
          
                    </ul>
                  </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                               
                                                <div  role="tabpanel"
                                                    aria-labelledby="headingOne">
                                                    <div class="panel-body">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 