import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-financial-role',
  templateUrl: './create-financial-role.component.html',
  styleUrls: ['./create-financial-role.component.css']
})
export class CreateFinancialRoleComponent implements OnInit {
  show: boolean;
  createRole: FormGroup;
  actualfromdate: string;
  actualthroughdate: string;
  finAccountId: any;
  personId: any;
  partyIdArray: any[]=[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  rid: any;
  activeCategory = 2;
  pid: any;
  fromdate: any;
  throughdate: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,) {
      this.createRole = this._FormBuilder.group({

        fromDate: ['',[Validators.required]],
        partyId: ['',[Validators.required]],
        roleTypeId: ['',[Validators.required]],
        thruDate: [''],
        
  
  
      });
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.finAccountId = params["finAccountId"];
      this.rid = params["rid"];
      this.pid = params["pid"];
      this.fromdate = params["fromdate"];
      this.throughdate = params["throughdate"];
    });
    if(this.rid){
      this.show=true;
      setTimeout(() => {
        const fromdate= this.fromdate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate= this.throughdate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      
        const formValue = this.createRole;
        formValue.patchValue({
          roleTypeId: this.rid,
          fromDate: this.getEstimateFrom,
          partyId: this.pid,
          thruDate:this.getEstimateTo
        })}, 3000);
    } else{
      this.show=false;
    }
    this.getPartyId();
    this.getRoleAllType();
  }
  
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }

      
      })
    
     }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  onSubmit(): void {
    this.spinner.show();
    const fromActualdate=this.createRole.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRole.get('thruDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "finAccountId": this.finAccountId,
      "partyId": this.createRole.value.partyId,
      "roleTypeId":this.createRole.value.roleTypeId,
      "fromDate": this.actualfromdate,
      "thruDate":this.actualthroughdate     
    }

    this.accountsService.postFinancialRoleAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"],{queryParams:{finAccountId:this.finAccountId}})
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  onUpdate(): void {
    this.spinner.show();
    const fromActualdate=this.createRole.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createRole.get('thruDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "finAccountId": this.finAccountId,
      "partyId": this.createRole.value.partyId,
      "roleTypeId":this.createRole.value.roleTypeId,
      "fromDate": this.actualfromdate,
      "thruDate":this.actualthroughdate
    }

    this.accountsService.updateFinancialRoleAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"],{queryParams:{finAccountId:this.finAccountId}})
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  cancelSubmit(){
    this.router.navigate(["financial/detail-financial-summary"],{queryParams:{finAccountId:this.finAccountId}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
