import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { format } from "highcharts";
import { Observable } from "rxjs";
import { ApiService } from "src/app/api.service";
import { RequestListEffortComponent } from "src/app/work-effort/request-list-effort/request-list-effort.component";
import { URLS } from "../../app.constant";
@Injectable({
  providedIn: "root",
})
export class AccountsService {
  constructor(readonly http: HttpClient,private _ApiService: ApiService) { }

  createSalesOrderData: any = {};
  createPurchaseOrderData: any = {};

  getContactNotes(id): Observable<any> {
    const getContactNotes = URLS.getContactNotes.replace(":partyId", id);
    return this.http.get(getContactNotes);
  }
  downloadView(id): Observable<any> {
    const getDownload = URLS.downloadPartyContent + "?contentId=" + id;
    return this.http.get(getDownload, { responseType: "blob" });
  }
  
  getARAccountPastDueInvoice(): Observable<any> {
    const getARAccountPastDueInvoice = URLS.getARAccountPastDueInvoice;
    return this.http.get(getARAccountPastDueInvoice);
  }
  
  getPerfReview(): Observable<any> {
    const getPerfReview = URLS.getPerfReview;
    return this.http.get(getPerfReview);
  }
  getTerminationTypeID(): Observable<any> {
    const getTerminationTypeID = URLS.getTerminationTypeID;
    return this.http.get(getTerminationTypeID);
  }
  
getPaycheckType(): Observable<any> {
    const getPaycheckType = URLS.getPaycheckType;
    return this.http.get(getPaycheckType);
  }
  getPaymentMethod(): Observable<any> {
    const getPaymentMethod = URLS.getPaymentMethod;
    return this.http.get(getPaymentMethod);
  }
  
  
  AgreementItemSeqId(): Observable<any> {
    const AgreementItemSeqId = URLS.AgreementItemSeqId;
    return this.http.get(AgreementItemSeqId);
  }
  getRoleTypeIdFrom(): Observable<any> {
    const getRoleTypeIdFrom = URLS.getRoleTypeIdFrom;
    return this.http.get(getRoleTypeIdFrom);
  }
  getRoleTypeIdTo(): Observable<any> {
    const getRoleTypeIdTo = URLS.getRoleTypeIdTo;
    return this.http.get(getRoleTypeIdTo);
  }
  getPartyID(): Observable<any> {
    const getPartyID = URLS.getPartyID;
    return this.http.get(getPartyID);
  }
  getAgreementEmploymentApplList(id): Observable<any> {
    const getAgreementEmploymentApplList = URLS.getAgreementEmploymentApplList + "?partyIdTo=" + id ;
    return this.http.get(getAgreementEmploymentApplList);
  }
  getPayHistoryListById(id): Observable<any> {
    const getPayHistoryListById = URLS.getPayHistoryListById + "?partyIdTo=" + id ;
    return this.http.get(getPayHistoryListById);
  }
  getAgreementID(): Observable<any> {
    const getAgreementID = URLS.getAgreementID;
    return this.http.get(getAgreementID);
  }
  getPeriodTypeId(): Observable<any> {
    const getPeriodTypeId = URLS.getPeriodTypeId;
    return this.http.get(getPeriodTypeId);
  }
  getPayrollPeriodTypeId(): Observable<any> {
    const getPayrollPeriodTypeId = URLS.getPayrollPeriodTypeId;
    return this.http.get(getPayrollPeriodTypeId);
  }
  getTerminationReasonID(): Observable<any> {
    const getTerminationReasonID = URLS.getTerminationReasonID;
    return this.http.get(getTerminationReasonID);
  }
  
  getInternalOrganization(): Observable<any> {
    const getInternalOrganization = URLS.getInternalOrganization;
    return this.http.get(getInternalOrganization);
  }
  
  getAPAccountPastDueInvoice(): Observable<any> {
    const getAPAccountPastDueInvoice = URLS.getAPAccountPastDueInvoice;
    return this.http.get(getAPAccountPastDueInvoice);
  }
  getPaymentID(): Observable<any> {
    const getPaymentID = URLS.getPaymentID;
    return this.http.get(getPaymentID);
  }
  getEmployee(): Observable<any> {
    const getEmployee = URLS.getEmployee;
    return this.http.get(getEmployee);
  }
  getDepartment(): Observable<any> {
    const getDepartment = URLS.getDepartment;
    return this.http.get(getDepartment);
  }
  getCompany(): Observable<any> {
    const getCompany = URLS.getCompany;
    return this.http.get(getCompany);
  }
  getPayCheck(): Observable<any> {
    const getPayCheck = URLS.getPayCheck;
    return this.http.get(getPayCheck);
  }
  getJobPosition(): Observable<any> {
    const getJobPosition = URLS.getJobPosition;
    return this.http.get(getJobPosition);
  }
  getContractType(): Observable<any> {
    const getContractType = URLS.getContractType;
    return this.http.get(getContractType);
  }

  getHRResponsible(): Observable<any> {
    const getHRResponsible = URLS.getHRResponsible;
    return this.http.get(getHRResponsible);
  }

  getNewContractDocumentTemplate(): Observable<any> {
    const getNewContractDocumentTemplate = URLS.getNewContractDocumentTemplate;
    return this.http.get(getNewContractDocumentTemplate);
  }

  getContractUpdateDocumentTemplate(): Observable<any> {
    const getContractUpdateDocumentTemplate = URLS.getContractUpdateDocumentTemplate;
    return this.http.get(getContractUpdateDocumentTemplate);
  }

  getAnalyticAccount(): Observable<any> {
    const getAnalyticAccount = URLS.getAnalyticAccount;
    return this.http.get(getAnalyticAccount);
  }

  getWorkingSchedule(): Observable<any> {
    const getWorkingSchedule = URLS.getWorkingSchedule;
    return this.http.get(getWorkingSchedule);
  }

  getWorkingScheduleList(): Observable<any> {
    const getWorkingScheduleList = URLS.getWorkingScheduleList;
    return this.http.get(getWorkingScheduleList);
  }

  getWorkingHoursList(): Observable<any> {
    const getWorkingHoursList = URLS.getWorkingHoursList;
    return this.http.get(getWorkingHoursList);
  }

  getDayOfWeek(): Observable<any> {
    const getDayOfWeek = URLS.getDayOfWeek;
    return this.http.get(getDayOfWeek);
  }
  getDropDowns(): Observable<any> {
    const getDropDowns = URLS.getDropDowns;
    return this.http.get(getDropDowns);
  }
  getEmployeePageDetails(): Observable<any> {
    const getEmployeePageDetails = URLS.getEmployeePageDetails;
    return this.http.get(getEmployeePageDetails);
  }

  getTotalHours(): Observable<any> {
    const getTotalHours = URLS.getTotalHours;
    return this.http.get(getTotalHours);
  }

  ReportDaysHoursToXLSX(): Observable<any> {
    const ReportDaysHoursToXLSX = URLS.ReportDaysHoursToXLSX;
    return this.http.get(ReportDaysHoursToXLSX, { responseType: "blob" });
  }
  getPieChart(): Observable<any> {
    const getPieChart = URLS.getPieChart;
    return this.http.get(getPieChart);
  }


  getPieChartData(): Observable<any> {
    const getPieChartData = URLS.getPieChartData;
    return this.http.get(getPieChartData);
  }

  getLineChartNumberOfDays(): Observable<any> {
    const getLineChartNumberOfDays = URLS.getLineChartNumberOfDays;
    return this.http.get(getLineChartNumberOfDays);
  }

  getLineChartNumberOfHours(): Observable<any> {
    const getLineChartNumberOfHours = URLS.getLineChartNumberOfHours;
    return this.http.get(getLineChartNumberOfHours);
  }
  findUserDetails(email): Observable<any> {
    const findUserDetails = URLS.findUserDetails + "?email=" + email ;
    return this.http.get(findUserDetails);
  }


  getWorkSchedulePage(workingSchedule): Observable<any> {
    const getWorkSchedulePage = URLS.getWorkSchedulePage + "?workingSchedule=" + workingSchedule ;
    return this.http.get(getWorkSchedulePage);
  }

  getDayPeriod(): Observable<any> {
    const getDayPeriod = URLS.getDayPeriod;
    return this.http.get(getDayPeriod);
  }
  getWorkEntryType(): Observable<any> {
    const getWorkEntryType = URLS.getWorkEntryType;
    return this.http.get(getWorkEntryType);
  }


  getContractTemplate(): Observable<any> {
    const getContractTemplate = URLS.getContractTemplate;
    return this.http.get(getContractTemplate);
  }
  
  getAR_AccountInvoice(): Observable<any> {
    const getAR_AccountInvoice = URLS.getAR_AccountInvoice;
    return this.http.get(getAR_AccountInvoice);
  }
  getPerfRatingTypeIDList(): Observable<any> {
    const getPerfRatingTypeIDList = URLS.getPerfRatingTypeIDList;
    return this.http.get(getPerfRatingTypeIDList);
  }
  
  getPerfItemTypeIDList(): Observable<any> {
    const getPerfItemTypeIDList = URLS.getPerfItemTypeIDList;
    return this.http.get(getPerfItemTypeIDList);
  }
  
  getPartyStatusIdList(): Observable<any> {
    const getPartyStatusIdList = URLS.getPartyStatusIdList;
    return this.http.get(getPartyStatusIdList);
  }
  getApAccountInvoice(): Observable<any> {
    const getApAccountInvoice = URLS.getApAccountInvoice;
    return this.http.get(getApAccountInvoice);
  }
  
  getPartyContentTypeIdList(): Observable<any> {
    const getPartyContentTypeIdList = URLS.getPartyContentTypeIdList;
    return this.http.get(getPartyContentTypeIdList);
  }
  getParty(): Observable<any> {
    const getPartyContent = URLS.partyContentDropdown;
    return this.http.get(getPartyContent);
  }
   getPartyTimesheet(): Observable<any> {
    const getParty = URLS.getParty;
    return this.http.get(getParty);
  }
  getDataCategoryIdList(): Observable<any> {
    const getDataCategoryIdList = URLS.getDataCategoryIdList;
    return this.http.get(getDataCategoryIdList);
  }
  
  getContactList():Observable<any>{
    const getContactList = URLS.getContactList;
    return this.http.get(getContactList);
  }
  attachPartyContent(formData, partyContentTypeId, partyId): Observable<any> {
    const getPartyContent = URLS.attachPartyContent + "?partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.post(getPartyContent, formData);
  }
  exportAccount(): Observable<any> {
    const exportAccount = URLS.exportAccounts;
    return this.http.get(exportAccount, { responseType: "blob" });
  }
  getPartyContentList(id): Observable<any> {
    const getPartyContent = URLS.getPartyContentLists.replace(":partyId", id);
    return this.http.get(getPartyContent);
  }
  getEmployementById(id): Observable<any> {
    const getEmployementById = URLS.getEmployementById.replace(":partyId", id);
    return this.http.get(getEmployementById);
  } 
  
  importAccount(formData): Observable<any> {
    const importAccount = URLS.importAccount;
    return this.http.post(importAccount, formData);
  }
  
    
  expireMarketingCampaignRole(formData): Observable<any> {
    const expireMarketingCampaignRole = URLS.expireMarketingCampaignRole;
    return this.http.post(expireMarketingCampaignRole, formData);
  }
  createPerfReview(formData): Observable<any> {
    const createPerfReview = URLS.createPerfReview;
    return this.http.post(createPerfReview, formData);
  }
  
  createPerfReviewItem(formData): Observable<any> {
    const createPerfReviewItem = URLS.createPerfReviewItem;
    return this.http.post(createPerfReviewItem, formData);
  }
  createEmployment(formData): Observable<any> {
    const createEmployment = URLS.createEmployment;
    return this.http.post(createEmployment, formData);
  }
  createApplicationEmployment(formData): Observable<any> {
    const createApplicationEmployment = URLS.createApplicationEmployment;
    return this.http.post(createApplicationEmployment, formData);
  }

  
  
  uploadCsvMatch(formData): Observable<any> {
    const uploadCsvMatch = URLS.uploadCsvMatch;
    return this.http.post(uploadCsvMatch, formData);
  }
 
  accountList(allReq, searchAccountForm): Observable<any> {
    const getAccountList =
      URLS.getAccountsList +
      "?pageNo=" +
      allReq.pageNo +
      "&pageSize=" +
      allReq.pageSize +
      "&all=" +
      allReq.all;
    return this.http.post(getAccountList, searchAccountForm);
  }

  getAccountList(searchAccountForm, all): Observable<any> {
    const getAccountList = URLS.getAccountsList + "?all=" + all;
    return this.http.post(getAccountList, searchAccountForm);
  }

  


  getAccountById(Id): Observable<any> {
    const getAccountDetail = URLS.getAccountById.replace(":partyId", Id);
    return this.http.get(`/phyCorp/control/api/v1/crm/accounts/${Id}`);
  }
  getAccountRelatedById(Id): Observable<any> {
    const getAccountByRelatedId = URLS.getAccountByRelatedId.replace(":partyId", Id);
    return this.http.get(`/phyCorp/control/api/v1/crm/contacts/${Id}`);
  }
 
  getLeadSourceList(id): Observable<any> {
    const getLeadSourceList = URLS.getLeadSourceList+"?partyId="+id;
    return this.http.get(getLeadSourceList);
  }
  getLeadSourceData(): Observable<any> {
    const getLeadSource = URLS.getLeadSource;
    return this.http.get(getLeadSource);
  }
  getCustomTimePeriodDropdownValue(): Observable<any> {
    const getCustomTimePeriodDropdownValue = URLS.getCustomTimePeriodDropdownValue;
    return this.http.get(getCustomTimePeriodDropdownValue);
  }
  getGlAccountInitialData(): Observable<any> {
    const getGlAccountInitialData = URLS.getGlAccountInitialData;
    return this.http.get(getGlAccountInitialData);
  }

  postCustomTimePeriod(formData): Observable<any> {
   
    const postCustomTimePeriod = URLS.postCustomTimePeriod;
    return this.http.post(postCustomTimePeriod, formData);
  }
  updateCustomTimePeriod(formData): Observable<any> {
    
    const postCustomTimePeriod = URLS.postCustomTimePeriod;
    return this.http.put(postCustomTimePeriod, formData);
  }
  updateDefaultTasks(formData): Observable<any> {
    const updateDefaultTasks = URLS.updateDefaultTasks;
    return this.http.put(updateDefaultTasks, formData);
  }
 
  updateContactNote(formData,id): Observable<any> {
 
    const updateContactNote = URLS.updateContactNote + "?partyId=" + id;
    return this.http.post(updateContactNote, formData);
  }
  createNoteHrms(formData,id): Observable<any> {
 
    const createNoteHrms = URLS.createNoteHrms + "?partyId=" + id;
    return this.http.post(createNoteHrms, formData);
  }
  postGlAccountMethodTypePaymentType(formData): Observable<any> {
 
    const postGlAccountMethodTypePaymentType = URLS.postGlAccountMethodTypePaymentType;
    return this.http.post(postGlAccountMethodTypePaymentType, formData);
  }
  createLeadSource(formData): Observable<any> {
 
    const createLeadSource = URLS.createLeadSource;
    return this.http.post(createLeadSource, formData);
  }
  removeGlAccountMethodTypePaymentType(formData): Observable<any> {
 
    const removeGlAccountMethodTypePaymentType = URLS.removeGlAccountMethodTypePaymentType;
    return this.http.post(removeGlAccountMethodTypePaymentType, formData);
  }
  
  deleteNote(noteId): Observable<any> {
    const deleteNote = URLS.deleteContactNote.replace(":noteId", noteId);
    return this.http.delete(deleteNote);
  }
  getGlAccountSummary(id): Observable<any> {
    const getGlAccountSummary = URLS.getGlAccountSummary.replace(":glAccountId", id);
    return this.http.get(getGlAccountSummary);
  }

  getListPartyResume(): Observable<any> {
    const getListPartyResume = URLS.getListPartyResume ;
    return this.http.get(getListPartyResume);
  }

  

  deleteGlCost(id): Observable<any> {
    const deleteGlCost = URLS.deleteGlCost.replace(":costComponentCalcId", id);
    return this.http.delete(deleteGlCost);
  }
  getGlCostById(id): Observable<any> {
    const deleteGlCost = URLS.deleteGlCost.replace(":costComponentCalcId", id);
    return this.http.get(deleteGlCost);
  }
  deleteCustomTimePeriod(id): Observable<any> {
    const deleteCustomTimePeriod = URLS.deleteCustomTimePeriod.replace(":customTimePeriodId", id);
    return this.http.delete(deleteCustomTimePeriod);
  }
  getEditTimePeriod(id): Observable<any> {
    const deleteCustomTimePeriod = URLS.deleteCustomTimePeriod.replace(":customTimePeriodId", id);
    return this.http.get(deleteCustomTimePeriod);
  }
  getInitialDataCost(): Observable<any> {
    const getInitialDataCost = URLS.getInitialDataCost;
    return this.http.get(getInitialDataCost);
  }
  
  customerDetails() {
    return [
      {
        id: "1000",
        code: "f230fh0g3",
        name: "Bamboo Watch",
        description: "Product Description",
        image: "bamboo-watch.jpg",
        price: 65,
        category: "Voided",
        quantity: 24,
        inventoryStatus: "INSTOCK",
        rating: 5,
        inventoryStatus1: "INSTOCK",
        rating1: 5,
      },
      {
        id: "1001",
        code: "nvklal433",
        name: "Black Watch",
        description: "Product Description",
        image: "black-watch.jpg",
        price: 72,
        category: "Received",
        quantity: 61,
        inventoryStatus: "INSTOCK",
        rating: 4,
        inventoryStatus1: "INSTOCK",
        rating1: 4,
      },
      {
        id: "1002",
        code: "zz21cz3c1",
        name: "Blue Band",
        description: "Product Description",
        image: "blue-band.jpg",
        price: 79,
        category: "Sent",
        quantity: 2,
        inventoryStatus: "LOWSTOCK",
        rating: 3,
      },
      {
        id: "1003",
        code: "244wgerg2",
        name: "Blue T-Shirt",
        description: "Product Description",
        image: "blue-t-shirt.jpg",
        price: 29,
        category: "Not Paid",
        quantity: 25,
        inventoryStatus: "INSTOCK",
        rating: 5,
      },
      {
        id: "1004",
        code: "h456wer53",
        name: "Bracelet",
        description: "Product Description",
        image: "bracelet.jpg",
        price: 15,
        category: "Accessories",
        quantity: 73,
        inventoryStatus: "INSTOCK",
        rating: 4,
      },
      {
        id: "1005",
        code: "av2231fwg",
        name: "Brown Purse",
        description: "Product Description",
        image: "brown-purse.jpg",
        price: 120,
        category: "Accessories",
        quantity: 0,
        inventoryStatus: "OUTOFSTOCK",
        rating: 4,
      },
    ];
  }

  getProductsList(data): Observable<any> {
    const getProductsList = URLS.getProductsList;
    return this.http.post(getProductsList, data);
  }

  getOrderList(): Observable<any> {
    const getOrderList = URLS.getOrderList;
    return this.http.get(getOrderList);
  }

  getPartyIdList(): Observable<any> {
    const getPartyId = URLS.getPartyIdData;
    return this.http.get(getPartyId);
  }

  getOrderType() {
    return [
      {
        value: "PURCHASE_ORDER",
        label: "Purchase Order",
      },
      {
        value: "SALES_ORDER",
        label: "Sales Order",
      },
    ];
  }

  


  getBillingAccount(data): Observable<any> {
    const getBillingAccountList = URLS.getBillingAccountData;
    return this.http.post(getBillingAccountList, data);
  }

  getSalesOrderList(): Observable<any> {
    const getSalesOrderList = URLS.getSalesOrderList;
    return this.http.get(getSalesOrderList);
  }

  getProductCatalogStoresList(): Observable<any> {
    const getProductCatalogStoresList = URLS.getProductCatalogStoresList;
    return this.http.get(getProductCatalogStoresList);
  }

  getStatus(statusTypes): Observable<any> {
    const getStatusList = URLS.getStatusList + "?statusTypes=" + statusTypes;
    return this.http.get(getStatusList);
  }

  containsBackOrder() {
    return [
      {
        value: "",
        label: "Show All",
      },
      {
        value: "Y",
        label: "Only",
      },
    ];
  }

  getUserLogin(): Observable<any> {
    const getUserLoginList = URLS.getUserLogin;
    return this.http.get(getUserLoginList);
  }

  viewed() {
    return [
      {
        value: "Y",
        label: "Yes",
      },
      {
        value: "N",
        label: "No",
      },
    ];
  }

  moreFilters() {
    return [
      {
        label: "Inventory problems",
        value: { id: "Inventory problems" }
      },
      {
        label: "Purchase orders partially received",
        value: { id: "Purchase orders partially received" },
      },
      {
        label: "Purchase orders open past their ETA",
        value: { id: "Purchase orders open past their ETA" },
      },
      {
        label: "Purchase orders with rejected items",
        value: { id: "Purchase orders with rejected items" },
      },
    ];
  }

  getShipToCountry(): Observable<any> {
    const getCountryList = URLS.getCountryList;
    return this.http.get(getCountryList);
  }

  getShippingMethods(): Observable<any> {
    const getShippingMethods = URLS.getShippingMethods;
    return this.http.get(getShippingMethods);
  }

  findOrder(data): Observable<any> {
    const orderList = URLS.findOrdersList;
    return this.http.post(orderList, data);
  }
  
  getFindImportParties(data): Observable<any> {
    const getFindImportParties = URLS.getFindImportParties;
    return this.http.post(getFindImportParties, data);
  }

  getCustomerList(customer): Observable<any> {
    const getCustomerList = URLS.getCustomerList + "?roleTypeId=" + customer;
    return this.http.get(getCustomerList);
  }
  getNavigateAccount(): Observable<any> {
    const getNavigateAccount = URLS.getNavigateAccount;
    return this.http.get(getNavigateAccount);
  }


  setSalesOrderData(data): void {
    this.createSalesOrderData = data;
  }

  setPurchaseOrderData(data): void {
    this.createPurchaseOrderData = data;
  }
  getPurchaseOrderData(): any {
    return this.createPurchaseOrderData;
  }
  getOrganisationList(): Observable<any> {
    const getOrganisations = URLS.getProductCatalogStoresList;
    return this.http.get(getOrganisations);
  }
  
  getDataSource(): Observable<any> {
    const getDataSource = URLS.getDataSource;
    return this.http.get(getDataSource);
  }

  getCurrency(): Observable<any> {
    const getProductsList = URLS.getCurrencyData;
    return this.http.get(getProductsList);
  }

  getCatalogue(data): Observable<any> {
    const getProductsList = URLS.getCatalogue;
    return this.http.post(getProductsList, data);
  }
  postFinancialAccount(data): Observable<any> {
    const postFinancialAccount = URLS.postFinancialAccount;
    return this.http.post(postFinancialAccount, data);
  }
  updateFinancailAccount(formData, id): Observable<any> {
    const updateFinancailAccount = URLS.updateFinancailAccount.replace(":finAccountId", id);
    return this.http.put(updateFinancailAccount, formData);
  }
  updateContactNoteHrms(formData, id): Observable<any> {
    const updateContactNoteHrms = URLS.updateContactNote.replace(":noteId", id);
    return this.http.put(updateContactNoteHrms, formData);
  }
  

  getWorkEfforts(): Observable<any> {
    const getWorkEffort = URLS.getWorkEffortList;
    return this.http.get(getWorkEffort);
  }
  
  getSegmentGroupFrontPage(): Observable<any> {
    const getSegmentGroupFrontPage = URLS.getSegmentGroupFrontPage;
    return this.http.get(getSegmentGroupFrontPage);
  }

  clearCart(): Observable<any> {
    const orderInit = URLS.clearCart;
    return this.http.post(orderInit, {});
  }
 

  updateSegmentGroup(data): Observable<any> {
    const updateSegmentGroup = URLS.updateSegmentGroup;
    return this.http.post(updateSegmentGroup, data);
  }
  initorderentry(data): Observable<any> {
    const orderInit = URLS.initorderentry;
    return this.http.post(orderInit, data);
  }
  postReturnItemList(data): Observable<any> {
    const postReturnItemList = URLS.postReturnItemList;
    return this.http.post(postReturnItemList, data);
  }
  
  createSegmentGroup(data): Observable<any> {
    const createSegmentGroup = URLS.createSegmentGroup;
    return this.http.post(createSegmentGroup, data);
  }
  
  deleteSegmentGroup(data): Observable<any> {
    const deleteSegmentGroup = URLS.deleteSegmentGroup;
    return this.http.post(deleteSegmentGroup, data);
  }


  addToOrder(data): Observable<any> {
    const addToOrder = URLS.addToOrder;
    return this.http.post(addToOrder, data);
  }

  createOrderReturn(data): Observable<any> {
    const createOrderReturn = URLS.createOrderReturn;
    return this.http.post(createOrderReturn, data);
  }
  
  createDataSource(data): Observable<any> {
    const createDataSource = URLS.createDataSource;
    return this.http.post(createDataSource, data);
  }
  createMarketingCampaign(data): Observable<any> {
    const createMarketingCampaign = URLS.createMarketingCampaign;
    return this.http.post(createMarketingCampaign, data);
  }
  
  deleteProjectPartyAssign(data): Observable<any> {
    const deleteProjectPartyAssign = URLS.deleteProjectPartyAssign;
    return this.http.post(deleteProjectPartyAssign, data);
  }
  deleteprofileRole(data): Observable<any> {
    const deleteprofileRole = URLS.deleteprofileRole;
    return this.http.post(deleteprofileRole, data);
  }
  deleteNewTask(data): Observable<any> {
    const deleteNewTask = URLS.deleteNewTask;
    return this.http.post(deleteNewTask, data);
  }
 
  createMarketingCampaignRole(data): Observable<any> {
    const createMarketingCampaignRole = URLS.createMarketingCampaignRole;
    return this.http.post(createMarketingCampaignRole, data);
  }
  updateMarketingCampaign(data): Observable<any> {
    const updateMarketingCampaign = URLS.updateMarketingCampaign;
    return this.http.post(updateMarketingCampaign, data);
  }
  
  deleteTrackingCode(data): Observable<any> {
    const deleteTrackingCode = URLS.deleteTrackingCode;
    return this.http.post(deleteTrackingCode, data);
  }
  
  deleteTrackCodeTypeById(data): Observable<any> {
    const deleteTrackCodeTypeById = URLS.deleteTrackCodeTypeById;
    return this.http.post(deleteTrackCodeTypeById, data);
  }
  
  createDataSourceType(data): Observable<any> {
    const createDataSourceType = URLS.createDataSourceType;
    return this.http.post(createDataSourceType, data);
  }
  
  createTrackingCode(data): Observable<any> {
    const createTrackingCode = URLS.createTrackingCode;
    return this.http.post(createTrackingCode, data);
  }
  
  updateTrackingCode(data): Observable<any> {
    const updateTrackingCode = URLS.updateTrackingCode;
    return this.http.put(updateTrackingCode, data);
  }
  updateTrackingCodeType(data): Observable<any> {
    const updateTrackingCodeType = URLS.updateTrackingCodeType;
    return this.http.post(updateTrackingCodeType, data);
  }
  createTrackingCodeType(data): Observable<any> {
    const createTrackingCodeType = URLS.createTrackingCodeType;
    return this.http.post(createTrackingCodeType, data);
  }
  
  updateDataSource(data): Observable<any> {
    const updateDataSource = URLS.updateDataSource;
    return this.http.post(updateDataSource, data);
  }
  
  updateDataSourceType(data): Observable<any> {
    const updateDataSourceType = URLS.updateDataSourceType;
    return this.http.put(updateDataSourceType, data);
  }

  createUser(data): Observable<any> {
    const createUser = URLS.createUser;
    return this.http.post(createUser, data);
  }
  
  createContactListParty(data): Observable<any> {
    const createContactListParty = URLS.createContactListParty;
    return this.http.post(createContactListParty, data);
  }
  
  updateContactListParty(data): Observable<any> {
    const updateContactListParty = URLS.updateContactListParty;
    return this.http.put(updateContactListParty, data);
  }
  updateUser(data): Observable<any> {
    const createUser = URLS.createUser;
    return this.http.put(createUser, data);
  }
  getUserId(partyId,userLoginId): Observable<any> {
    const createUser = URLS.createUser+ "?partyId=" + partyId + "&userLoginId=" + userLoginId;
    return this.http.get(createUser);
  }
  getReturnHeader(): Observable<any> {
    const getReturnHeader = URLS.getReturnHeader;
    return this.http.get(getReturnHeader);
  }
  
  getMaintenanceType(): Observable<any> {
    const getMaintenanceType = URLS.getMaintenanceType;
    return this.http.get(getMaintenanceType);
  }
  
  
  createFixedAsset(req): Observable<any> {
    const createFixedAsset = URLS.createFixedAsset;
    return this.http.post(createFixedAsset,req);
  }
  
  getIntervalMeterType(): Observable<any> {
    const getIntervalMeterType = URLS.getIntervalMeterType;
    return this.http.get(getIntervalMeterType);
  }
  
  getIntervalUom(): Observable<any> {
    const getIntervalUom = URLS.getIntervalUom;
    return this.http.get(getIntervalUom);
  }
  
  getContentMimeTypeId(): Observable<any> {
    const getContentMimeTypeId = URLS.getContentMimeTypeId;
    return this.http.get(getContentMimeTypeId);
  }
  
  getContactMechanismTypeIDData(): Observable<any> {
    const getContactMechanismTypeIDData = URLS.getContactMechanismTypeIDData;
    return this.http.get(getContactMechanismTypeIDData);
  }
  getPurchaseOrderId(): Observable<any> {
    const getPurchaseOrderId = URLS.getPurchaseOrderId;
    return this.http.get(getPurchaseOrderId);
  }
  
  getMaintenanceTemplateId(): Observable<any> {
    const getMaintenanceTemplateId = URLS.getMaintenanceTemplateId;
    return this.http.get(getMaintenanceTemplateId);
  }
  assetMaintenanceStatus(): Observable<any> {
    const assetMaintenanceStatus = URLS.assetMaintenanceStatus;
    return this.http.get(assetMaintenanceStatus);
  }
  
  getDataSourceTypeValue(): Observable<any> {
    const getDataSourceTypeValue = URLS.getDataSourceTypeValue;
    return this.http.get(getDataSourceTypeValue);
  }
  getReturnStatusId(): Observable<any> {
    const getReturnStatusId = URLS.getReturnStatusId;
    return this.http.get(getReturnStatusId);
  }
  getDestinationFacility(): Observable<any> {
    const getDestinationFacility = URLS.getDestinationFacility;
    return this.http.get(getDestinationFacility);
  }
  getOrderReturn(allReq, form): Observable<any> {
    const getOrderReturn = URLS.getOrderReturn + "?pageNo=" + allReq.pageNo + "&pageSize=" + allReq.pageSize;
    return this.http.post(getOrderReturn, form);
  }
  
  communicationEventsPortal(allReq, form): Observable<any> {
    const communicationEventsPortal = URLS.communicationEventsPortal + "?pageNo=" + allReq.pageNo + "&pageSize=" + allReq.pageSize;
    return this.http.post(communicationEventsPortal, form);
  }

  findGroupId(allReq, form): Observable<any> {
    const findGroupId = URLS.findGroupId + "?pageNo=" + allReq.pageNo + "&pageSize=" + allReq.pageSize;
    return this.http.post(findGroupId, form);
  }
  updateReturn(formData, id, createdBy): Observable<any> {
    const updateReturn = URLS.updateReturnOrder.replace("returnId", id) + "?createdBy=" + createdBy + "&returnId=" + id;
    return this.http.put(updateReturn, formData);
  }
  getTypeCatalog(): Observable<any> {
    const getTypeCatalog = URLS.getTypeCatalog ;
    return this.http.get(getTypeCatalog);
  }
  getProductStoreType(): Observable<any> {
    const getProductStoreType = URLS.getProductStoreType ;
    return this.http.get(getProductStoreType);
  }
  getPrimaryParentGroupID(): Observable<any> {
    const getPrimaryParentGroupID = URLS.getPrimaryParentGroupID ;
    return this.http.get(getPrimaryParentGroupID);
  }
  getProductStoreId(): Observable<any> {
    const getProductStoreId = URLS.getProductStoreId ;
    return this.http.get(getProductStoreId);
  }
  getProductStoreGroupById(id): Observable<any> {
    const getProductStoreGroupById = URLS.getProductStoreGroupById + "?productStoreGroupId=" + id;
    return this.http.get(getProductStoreGroupById);
  }
  updateProductStoreGroup(req): Observable<any> {
    const updateProductStoreGroup = URLS.updateProductStoreGroup;
    return this.http.post(updateProductStoreGroup, req);

  }
  createProductStoreGroupMember(req): Observable<any> {
    const createProductStoreGroupMember = URLS.createProductStoreGroupMember;
    return this.http.post(createProductStoreGroupMember, req);

  }
  getPriceRuleCatalogList(): Observable<any> {
    const getPriceRuleCatalogList = URLS.getPriceRuleCatalogList ;
    return this.http.get(getPriceRuleCatalogList);
  }
  getOrderReturnHistory(id): Observable<any> {
    const getOrderReturnHistory = URLS.getOrderReturnHistory + "?returnId=" + id;
    return this.http.get(getOrderReturnHistory);
  }
  createProductStoreGroup(req): Observable<any> {
    const createProductStoreGroup = URLS.createProductStoreGroup;
    return this.http.post(createProductStoreGroup, req);

  }

  getOrderDropdown(id): Observable<any> {
    const getOrderDropdown = URLS.getOrderDropdown + "?returnId=" + id;
    return this.http.get(getOrderDropdown);
  }
  
  getPartyClassificationById(id): Observable<any> {
    const getPartyClassificationById = URLS.getPartyClassificationById + "?partyClassificationGroupId=" + id;
    return this.http.get(getPartyClassificationById);
  }

  getOrderAnnualReturnHistory(oid, id): Observable<any> {
    const getOrderAnnualReturnHistory = URLS.getOrderAnnualReturnHistory + "?orderId=" + oid + "&returnId=" + id;
    return this.http.get(getOrderAnnualReturnHistory);
  }
  
  addVaraints(pid, id): Observable<any> {
    const addVaraints = URLS.addVaraints + "?productFeatureIdOne=" + pid + "&variantProductIdsBag=" + id;
    return this.http.post(addVaraints,'');
  }
  
  getWorkEffortEventReminderById(id): Observable<any> {
    const getWorkEffortEventReminderById = URLS.getWorkEffortEventReminderById + "?workEffortId=" + id;
    return this.http.get(getWorkEffortEventReminderById);
  }
  
  getWorkEffortAttributeById(id): Observable<any> {
    const getWorkEffortAttributeById = URLS.getWorkEffortAttributeById + "?workEffortId=" + id;
    return this.http.get(getWorkEffortAttributeById);
  }
  
  getWorkEffortContactById(id): Observable<any> {
    const getWorkEffortContactById = URLS.getWorkEffortContactById + "?workEffortId=" + id;
    return this.http.get(getWorkEffortContactById);
  }
  
  getWorkEffortFixedAssetAssign(id): Observable<any> {
    const getWorkEffortFixedAssetAssign = URLS.getWorkEffortFixedAssetAssign + "?workEffortId=" + id;
    return this.http.get(getWorkEffortFixedAssetAssign);
  }
  getWorkEffortGoodStandardId(id): Observable<any> {
    const getWorkEffortGoodStandardId = URLS.getWorkEffortGoodStandardId + "?workEffortId=" + id;
    return this.http.get(getWorkEffortGoodStandardId);
  }
  
  getWorkEffortAgreementById(id): Observable<any> {
    const getWorkEffortAgreementById = URLS.getWorkEffortAgreementById + "?workEffortId=" + id;
    return this.http.get(getWorkEffortAgreementById);
  }
  getReasonItemStatus(): Observable<any> {
    const getReasonItemStatus = URLS.getReasonItemStatus;
    return this.http.get(getReasonItemStatus);
  }
  
  getAgreementId(): Observable<any> {
    const getAgreementId = URLS.getAgreementId;
    return this.http.get(getAgreementId);
  }
  
  
  getContactMechType(): Observable<any> {
    const getContactMechType = URLS.getContactMechType;
    return this.http.get(getContactMechType);
  }
  
  getAgreementItemSeqId(): Observable<any> {
    const getAgreementItemSeqId = URLS.getAgreementItemSeqId;
    return this.http.get(getAgreementItemSeqId);
  }
  
  getWorkEffortKeyword(id): Observable<any> {
    const getWorkEffortKeyword = URLS.getWorkEffortKeyword + "?workEffortId=" + id;
    return this.http.get(getWorkEffortKeyword);
  }
  
  getWorkEffortReviewById(id): Observable<any> {
    const getWorkEffortReviewById = URLS.getWorkEffortReviewById + "?workEffortId=" + id;
    return this.http.get(getWorkEffortReviewById);
  }
  
  getSegmentGroupClassification(id): Observable<any> {
    const getSegmentGroupClassification = URLS.getSegmentGroupClassification + "?segmentGroupId=" + id;
    return this.http.get(getSegmentGroupClassification);
  }
  getGeoRoleById(id): Observable<any> {
    const getGeoRoleById = URLS.getGeoRoleById + "?segmentGroupId=" + id;
    return this.http.get(getGeoRoleById);
  }
  getTaskById(id): Observable<any> {
    const getTaskById = URLS.getTaskById + "?taskId=" + id;
    return this.http.get(getTaskById);
  }
  getGeoById(id): Observable<any> {
    const getGeoById = URLS.getGeoById + "?segmentGroupId=" + id;
    return this.http.get(getGeoById);
  }
  
  getSegmentGroupById(id): Observable<any> {
    const getSegmentGroupById = URLS.getSegmentGroupById + "?segmentGroupId=" + id;
    return this.http.get(getSegmentGroupById);
  }
  
  getClassificationGroup(): Observable<any> {
    const getClassificationGroup = URLS.getClassificationGroup;
    return this.http.get(getClassificationGroup);
  }
  
  getClassificationGroupTypeId(): Observable<any> {
    const getClassificationGroupTypeId = URLS.getClassificationGroupTypeId;
    return this.http.get(getClassificationGroupTypeId);
  }
  getAllPermision(): Observable<any> {
    const getAllPermision = URLS.getAllPermision;
    return this.http.get(getAllPermision);
  }
  getRateTypes(): Observable<any> {
    const getRateTypes = URLS.getRateTypes;
    return this.http.get(getRateTypes);
  }
  getStoreId(): Observable<any> {
    const getStoreId = URLS.getStoreId;
    return this.http.get(getStoreId);
  }
  
  getTrackingCodeList(): Observable<any> {
    const getTrackingCodeList = URLS.getTrackingCodeList;
    return this.http.get(getTrackingCodeList);
  }
  
  getTrackCodeTypeList(): Observable<any> {
    const getTrackCodeTypeList = URLS.getTrackCodeTypeList;
    return this.http.get(getTrackCodeTypeList);
  }
  
  
  getAddressMatchMap(): Observable<any> {
    const getAddressMatchMap = URLS.getAddressMatchMap;
    return this.http.get(getAddressMatchMap);
  }

  generateOrderPDF(returnId: string): Observable<any> {
    return this.http.get(`/phyCorp/control/api/v1/order/return/generatePdf?returnId=${returnId}`, { responseType: 'blob' });
  }
  downloadPDFChartAccountData(): Observable<any> {
    return this.http.get(`/phyCorp/control/api/v1/accounting/globalGLSetting/generatePdf`, { responseType: 'blob' });
  }


  deleteAdjustmentId(returnAdjustmentId, id): Observable<any> {
    const deleteAdjustmentId = URLS.deleteAdjustmentId + "?returnAdjustmentId=" + returnAdjustmentId + "&returnId=" + id;
    return this.http.delete(deleteAdjustmentId);
  }
  
  deletePartyClassificationGroup(id): Observable<any> {
    const deletePartyClassificationGroup = URLS.deletePartyClassificationGroup + "?PartyClassificationGroupId=" + id;
    return this.http.delete(deletePartyClassificationGroup);
  }
  
  deleteDataSource(form): Observable<any> {
    const deleteDataSource = URLS.deleteDataSource ;
    return this.http.post(deleteDataSource,form);
  }
  
  deleteSegmentGroupClassification(form): Observable<any> {
    const deleteSegmentGroupClassification = URLS.deleteSegmentGroupClassification ;
    return this.http.post(deleteSegmentGroupClassification,form);
  }
  
  deleteSegmentGroupGeo(form): Observable<any> {
    const deleteSegmentGroupGeo = URLS.deleteSegmentGroupGeo ;
    return this.http.post(deleteSegmentGroupGeo,form);
  }
  
  deleteSegmentGroupRole(form): Observable<any> {
    const deleteSegmentGroupRole = URLS.deleteSegmentGroupRole ;
    return this.http.post(deleteSegmentGroupRole,form);
  }
  deleteDataSourceType(form): Observable<any> {
    const deleteDataSourceType = URLS.deleteDataSourceType ;
    return this.http.post(deleteDataSourceType,form);
  }

  deleteRemoveItem(returnItemSeqId, id): Observable<any> {
    const deleteRemoveItem = URLS.deleteRemoveItem + "?returnId=" + id + "&returnItemSeqId=" + returnItemSeqId;
    return this.http.delete(deleteRemoveItem);
  }
  deletePartyContentData(contentId, fromDate,partyContentTypeId,partyId): Observable<any> {
    const deletePartyContentData = URLS.deletePartyContentData + "?contentId=" + contentId + "&fromDate=" + fromDate + "&partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.delete(deletePartyContentData);
  }
  newTaskById(formdata,taskId): Observable<any> {
    const newTaskById = URLS.newTaskById + "?taskId=" + taskId ;
    return this.http.post(newTaskById,formdata);
  }

  deleteRoleTypeId(partyId, role): Observable<any> {
    const deleteRoleTypeId = URLS.deleteRoleTypeId + "?partyId=" + partyId + "&roleTypeId=" + role;
    return this.http.delete(deleteRoleTypeId);
  }
  
  createPartyInvitationRoleAssociation(partyId, role): Observable<any> {
    const createPartyInvitationRoleAssociation = URLS.createPartyInvitationRoleAssociation + "?partyInvitationId=" + partyId + "&roleTypeId=" + role;
    return this.http.post(createPartyInvitationRoleAssociation,'');
  }
  deleteProductRate(id,geo,party): Observable<any> {
    const deleteProductRate = URLS.deleteProductRate + "?taxAuthGeoId=" + geo + "&taxAuthPartyId=" + party + "&taxAuthorityRateSeqId=" + id;
    return this.http.delete(deleteProductRate);
  }
  

  returnAccepted(needsInventoryReceive, returnId, statusId): Observable<any> {
    const returnAccepted = URLS.returnAccepted + "?needsInventoryReceive=" + needsInventoryReceive + "&returnId=" + returnId + "&statusId=" + statusId;
    return this.http.put(returnAccepted, '');
  }
  getFindPayrollPreferenceListP(id): Observable<any> {
    const getFindPayrollPreferenceListP = URLS.getFindPayrollPreferenceListP + "?partyId=" + id ;
    return this.http.get(getFindPayrollPreferenceListP);
  }
  getVisitors(data): Observable<any> {
    const getVisitors = URLS.getVisitors + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getVisitors);
  }
  
  getContactMechId(): Observable<any> {
    const getContactMechId = URLS.getContactMechId;
    return this.http.get(getContactMechId);
  }
  
  getMarketingCampaignDetailData(): Observable<any> {
    const getMarketingCampaignDetailData = URLS.getMarketingCampaignDetailData;
    return this.http.get(getMarketingCampaignDetailData);
  }
  
  getFixedAssetStatusList(): Observable<any> {
    const getFixedAssetStatusList = URLS.getFixedAssetStatusList;
    return this.http.get(getFixedAssetStatusList);
  }
  
  getFixedAssetList(): Observable<any> {
    const getFixedAssetList = URLS.getFixedAssetList;
    return this.http.get(getFixedAssetList);
  }
  
  getProductId(): Observable<any> {
    const getProductId = URLS.getProductId;
    return this.http.get(getProductId);
  }
  
  getUserLoginId(): Observable<any> {
    const getUserLoginId = URLS.getUserLoginId;
    return this.http.get(getUserLoginId);
  }
  
  getpartyClassificationGroupId(): Observable<any> {
    const getpartyClassificationGroupId = URLS.getpartyClassificationGroupId;
    return this.http.get(getpartyClassificationGroupId);
  }
  
  getGeoId(): Observable<any> {
    const getGeoId = URLS.getGeoId;
    return this.http.get(getGeoId);
  }
  getWorkEffortGoodStdTypeID(): Observable<any> {
    const getWorkEffortGoodStdTypeID = URLS.getWorkEffortGoodStdTypeID;
    return this.http.get(getWorkEffortGoodStdTypeID);
  }
  getUserDetails(data, req): Observable<any> {
    const getUserDetails = URLS.getUserDetails + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getUserDetails, req);
  }
  
  updateWorkEffortEventReminder(req): Observable<any> {
    const updateWorkEffortEventReminder = URLS.updateWorkEffortEventReminder;
    return this.http.post(updateWorkEffortEventReminder, req);

  }
  createWorkEffortEventReminder(req): Observable<any> {
    const createWorkEffortEventReminder = URLS.createWorkEffortEventReminder;
    return this.http.post(createWorkEffortEventReminder, req);

  }
  
  createWorkEffortAttribute(req): Observable<any> {
    const createWorkEffortAttribute = URLS.createWorkEffortAttribute;
    return this.http.post(createWorkEffortAttribute, req);

  }
  
  createWorkEffortKeyword(req): Observable<any> {
    const createWorkEffortKeyword = URLS.createWorkEffortKeyword;
    return this.http.post(createWorkEffortKeyword, req);

  }
  
  createAgreementWorkEffortApplic(req): Observable<any> {
    const createAgreementWorkEffortApplic = URLS.createAgreementWorkEffortApplic;
    return this.http.post(createAgreementWorkEffortApplic, req);

  }
  
  createWorkEffortContactMech(req): Observable<any> {
    const createWorkEffortContactMech = URLS.createWorkEffortContactMech;
    return this.http.post(createWorkEffortContactMech, req);

  }
  
  createWorkEffortFixedAssetAssign(req): Observable<any> {
    const createWorkEffortFixedAssetAssign = URLS.createWorkEffortFixedAssetAssign;
    return this.http.post(createWorkEffortFixedAssetAssign, req);

  }
  
  createWorkEffortGoodStandard(req): Observable<any> {
    const createWorkEffortGoodStandard = URLS.createWorkEffortGoodStandard;
    return this.http.post(createWorkEffortGoodStandard, req);

  }
  
  createWorkEffortReview(req): Observable<any> {
    const createWorkEffortReview = URLS.createWorkEffortReview;
    return this.http.post(createWorkEffortReview, req);

  }
  
  updateWorkEffortReview(req): Observable<any> {
    const updateWorkEffortReview = URLS.updateWorkEffortReview;
    return this.http.post(updateWorkEffortReview, req);

  }
  updateWorkEffortFixedAssetAssign(req): Observable<any> {
    const updateWorkEffortFixedAssetAssign = URLS.updateWorkEffortFixedAssetAssign;
    return this.http.post(updateWorkEffortFixedAssetAssign, req);

  }
  updateWorkEffortGoodStandard(req): Observable<any> {
    const updateWorkEffortGoodStandard = URLS.updateWorkEffortGoodStandard;
    return this.http.post(updateWorkEffortGoodStandard, req);

  }
  
  createSegmentGroupClassification(req): Observable<any> {
    const createSegmentGroupClassification = URLS.createSegmentGroupClassification;
    return this.http.post(createSegmentGroupClassification, req);

  }
  
  createSegmentGroupGeo(req): Observable<any> {
    const createSegmentGroupGeo = URLS.createSegmentGroupGeo;
    return this.http.post(createSegmentGroupGeo, req);

  }
  
   
  createSegmentGroupRole(req): Observable<any> {
    const createSegmentGroupRole = URLS.createSegmentGroupRole;
    return this.http.post(createSegmentGroupRole, req);

  }
  
  updateWorkEffortAttribute(req): Observable<any> {
    const updateWorkEffortAttribute = URLS.updateWorkEffortAttribute;
    return this.http.post(updateWorkEffortAttribute, req);

  }
  getReportTax(data, req): Observable<any> {
    const getReportTax = URLS.getReportTax + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getReportTax, req);
  }
  
  postRoleTypeSave(partyId, roleTypeId): Observable<any> {
    const getUserDetails = URLS.postRoleTypeSave + "?partyId=" + partyId + "&roleTypeId=" + roleTypeId;
    return this.http.post(getUserDetails, '');
  }
  userEnableDisable(enabled, userLoginId): Observable<any> {
    const getUserDetails = URLS.userEnableDisable + "?enabled=" + enabled + "&userLoginId=" + userLoginId;
    return this.http.post(getUserDetails, '');
  }
  

  getSecurityGroupList(data, req): Observable<any> {
    const getSecurityGroupList = URLS.getSecurityGroupList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getSecurityGroupList, req);
  }

  importUserData(formData): Observable<any> {
    const importUserData = URLS.importUserData;
    return this.http.post(importUserData, formData);
  }

  saveSecurityGroup(formData): Observable<any> {
    const saveSecurityGroup = URLS.saveSecurityGroup;
    return this.http.post(saveSecurityGroup, formData);
  }
  
  deleteWorkEffortEventReminder(formData): Observable<any> {
    const deleteWorkEffortEventReminder = URLS.deleteWorkEffortEventReminder;
    return this.http.post(deleteWorkEffortEventReminder, formData);
  }
  deleteWorkEffortAttribute(formData): Observable<any> {
    const deleteWorkEffortAttribute = URLS.deleteWorkEffortAttribute;
    return this.http.post(deleteWorkEffortAttribute, formData);
  }
  deleteWorkEffortKeyword(formData): Observable<any> {
    const deleteWorkEffortKeyword = URLS.deleteWorkEffortKeyword;
    return this.http.post(deleteWorkEffortKeyword, formData);
  }
  
  deleteAgreementWorkEffortApplic(formData): Observable<any> {
    const deleteAgreementWorkEffortApplic = URLS.deleteAgreementWorkEffortApplic;
    return this.http.post(deleteAgreementWorkEffortApplic, formData);
  }
  
  deleteWorkEffortContactMech(formData): Observable<any> {
    const deleteWorkEffortContactMech = URLS.deleteWorkEffortContactMech;
    return this.http.post(deleteWorkEffortContactMech, formData);
  }
  
  deleteWorkEffortFixedAssetAssign(formData): Observable<any> {
    const deleteWorkEffortFixedAssetAssign = URLS.deleteWorkEffortFixedAssetAssign;
    return this.http.post(deleteWorkEffortFixedAssetAssign, formData);
  }
  
   
  removeWorkEffortGoodStandard(formData): Observable<any> {
    const removeWorkEffortGoodStandard = URLS.removeWorkEffortGoodStandard;
    return this.http.post(removeWorkEffortGoodStandard, formData);
  }
  
  updateWorkEffort(formData): Observable<any> {
    const updateWorkEffort = URLS.updateWorkEffort;
    return this.http.post(updateWorkEffort, formData);
  }
  
  deleteWorkEffortReview(formData): Observable<any> {
    const deleteWorkEffortReview = URLS.deleteWorkEffortReview;
    return this.http.post(deleteWorkEffortReview, formData);
  }
  createPartyClassificationGroup(formData): Observable<any> {
    const createPartyClassificationGroup = URLS.createPartyClassificationGroup;
    return this.http.post(createPartyClassificationGroup, formData);
  }
  putSecurityGroup(formData): Observable<any> {
    const saveSecurityGroup = URLS.saveSecurityGroup;
    return this.http.put(saveSecurityGroup, formData);
  }
  postRoleTypeData(formData, id): Observable<any> {
    const postRoleTypeData = URLS.postRoleTypeData + "?partyId=" + id;
    return this.http.post(postRoleTypeData, formData);
  }
  
  postBudgetItem(formData): Observable<any> {
    const postBudgetItem = URLS.postBudgetItem;
    return this.http.post(postBudgetItem, formData);
  }
  updateBudgetItem(formData): Observable<any> {
    const postBudgetItem = URLS.postBudgetItem;
    return this.http.put(postBudgetItem, formData);
  }
  
  createSystemInfoNote(formData): Observable<any> {
    const createSystemInfoNote = URLS.createSystemInfoNote;
    return this.http.post(createSystemInfoNote, formData);
  }

  getPartyRole(data, id): Observable<any> {
    const getPartyRole = URLS.getPartyRole + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&partyId=" + id;
    return this.http.get(getPartyRole);
  }
  
  getItemToShoppingList(data, id,party): Observable<any> {
    const getItemToShoppingList = URLS.getItemToShoppingList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&shoppingListId=" + id + "&partyId=" + party;
    return this.http.get(getItemToShoppingList);
  }
  getAllFinanceHistory(data, id): Observable<any> {
    const getAllFinanceHistory = URLS.getAllFinanceHistory + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&partyId=" + id;
    return this.http.get(getAllFinanceHistory);
  }
  
  getTaxInfo(data, id): Observable<any> {
    const getTaxInfo = URLS.getTaxInfo + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&partyId=" + id;
    return this.http.get(getTaxInfo);
  }
  
  getFacilityShipments(data,req): Observable<any> {
    const getFacilityShipments = URLS.getFacilityShipments + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getFacilityShipments,req);
  }
  findAccountTransactionsEntries(data,req): Observable<any> {
    const findAccountTransactionsEntries = URLS.findAccountTransactionsEntries + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(findAccountTransactionsEntries,req);
  }
  
  
  getUnPostedAcctgTransactions(data): Observable<any> {
    const getUnPostedAcctgTransactions = URLS.getUnPostedAcctgTransactions + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getUnPostedAcctgTransactions);
  }
  generateTransactionsPdf(orgId,acc): Observable<any> {
    const generateTransactionsPdf = URLS.generateTransactionsPdf + "?acctgTransId=" + acc + "&organizationPartyId=" + orgId;
    return this.http.get(generateTransactionsPdf, { responseType: "blob" });
  }
  
  
  getChartPdf(orgId): Observable<any> {
    const getChartPdf = URLS.getChartPdf + "?organizationPartyId=" + orgId;
    return this.http.get(getChartPdf, { responseType: "blob" });
  }
  
  generateTransactionDetailsPdf(orgId,acc): Observable<any> {
    const generateTransactionDetailsPdf = URLS.generateTransactionDetailsPdf + "?acctgTransId=" + acc + "&organizationPartyId=" + orgId;
    return this.http.get(generateTransactionDetailsPdf, { responseType: "blob" });
  }
  generateTrialBalancePDF(orgId,acc): Observable<any> {
    const generateTrialBalancePDF = URLS.generateTrialBalancePDF + "?customTimePeriodId=" + acc + "&organizationPartyId=" + orgId;
    return this.http.get(generateTrialBalancePDF, { responseType: "blob" });
  }
  getGenerateCsvTrialBalance(orgId,acc): Observable<any> {
    const getGenerateCsvTrialBalance = URLS.getGenerateCsvTrialBalance + "?customTimePeriodId=" + acc + "&organizationPartyId=" + orgId;
    return this.http.get(getGenerateCsvTrialBalance, { responseType: "blob" });
  }
  getGenerateCsvtransactionTotals(orgId,data): Observable<any> {
    const getGenerateCsvtransactionTotals = URLS.getGenerateCsvtransactionTotals + "?organizationPartyId=" + orgId ;
    return this.http.post(getGenerateCsvtransactionTotals,data, { responseType: "blob" });
  }
  getgenerateCsvIncomeStatements(orgId,data): Observable<any> {
    const getgenerateCsvIncomeStatements = URLS.getgenerateCsvIncomeStatements + "?organizationPartyId=" + orgId ;
    return this.http.post(getgenerateCsvIncomeStatements,data, { responseType: "blob" });
  }
  
  getgenerateCsvcashflowStatements(orgId,data): Observable<any> {
    const getgenerateCsvcashflowStatements = URLS.getgenerateCsvcashflowStatements + "?organizationPartyId=" + orgId ;
    return this.http.post(getgenerateCsvcashflowStatements,data, { responseType: "blob" });
  }
  
  
  getCSvbalanceSheet(orgId,data): Observable<any> {
    const getCSvbalanceSheet = URLS.getCSvbalanceSheet + "?organizationPartyId=" + orgId ;
    return this.http.post(getCSvbalanceSheet,data, { responseType: "blob" });
  }
  getCSvcomparativeIncomeStatements(orgId,data): Observable<any> {
    const getCSvcomparativeIncomeStatements = URLS.getCSvcomparativeIncomeStatements + "?organizationPartyId=" + orgId ;
    return this.http.post(getCSvcomparativeIncomeStatements,data, { responseType: "blob" });
  }
  getgenerateCsvcomparativeCashFlowStatement(orgId,data): Observable<any> {
    const getgenerateCsvcomparativeCashFlowStatement = URLS.getgenerateCsvcomparativeCashFlowStatement + "?organizationPartyId=" + orgId ;
    return this.http.post(getgenerateCsvcomparativeCashFlowStatement,data, { responseType: "blob" });
  }
  getcomparativeGenerateCSVBalanceSheet(orgId,data): Observable<any> {
    const getcomparativeGenerateCSVBalanceSheet = URLS.getcomparativeGenerateCSVBalanceSheet + "?organizationPartyId=" + orgId ;
    return this.http.post(getcomparativeGenerateCSVBalanceSheet,data, { responseType: "blob" });
  }
  getgeneratePdfglAccountTrialBalance(orgId,data): Observable<any> {
    const getgeneratePdfglAccountTrialBalance = URLS.getgeneratePdfglAccountTrialBalance + "?organizationPartyId=" + orgId ;
    return this.http.post(getgeneratePdfglAccountTrialBalance,data, { responseType: "blob" });
  }
  getgenerateCsvinventoryValuation(data): Observable<any> {
    const getgenerateCsvinventoryValuation = URLS.getgenerateCsvinventoryValuation ;
    return this.http.post(getgenerateCsvinventoryValuation,data, { responseType: "blob" });
  }
  
  getgeneratePdfcostCenterAcc(orgId,data): Observable<any> {
    const getgeneratePdfcostCenterAcc = URLS.getgeneratePdfcostCenterAcc + "?organizationPartyId=" + orgId ;
    return this.http.post(getgeneratePdfcostCenterAcc,data, { responseType: "blob" });
  }
  generatePdfmarkChecksSent(id,data): Observable<any> {
    const generatePdfmarkChecksSent = URLS.generatePdfmarkChecksSent + "?organizationPartyId=" + id
    return this.http.post(generatePdfmarkChecksSent, data, { responseType: "blob" });
  }
  
  getExportPartyId(id): Observable<any> {
    const getExportPartyId = URLS.getExportPartyId + "?partyId=" + id
    return this.http.get(getExportPartyId, { responseType: "blob" });
  }
    
  getBalanceSheet(id): Observable<any> {
    const getBalanceSheet = URLS.getBalanceSheet + "?codeId=" + id
    return this.http.get(getBalanceSheet, { responseType: "blob" });
  }

  getCustomerDataToCSV(): Observable<any> {
    const getCustomerDataToCSV = URLS.getCustomerDataToCSV ;
    return this.http.get(getCustomerDataToCSV);
  }
  getCustomerSitesDataToCSV(): Observable<any> {
    const getCustomerSitesDataToCSV = URLS.getCustomerSitesDataToCSV ;
    return this.http.get(getCustomerSitesDataToCSV);
  }
  
  
  
  
  getBudgetType(): Observable<any> {
    const getBudgetType = URLS.getBudgetType;
    return this.http.get(getBudgetType);
  }
  
  getTargetTypeEnumIdList(): Observable<any> {
    const getTargetTypeEnumIdList = URLS.getTargetTypeEnumIdList;
    return this.http.get(getTargetTypeEnumIdList);
  }
  
  getreconcialationDetail(id,glReconciliationId): Observable<any> {
    const getreconcialationDetail = URLS.getreconcialationDetail + '/' + id + "?glReconciliationId=" + glReconciliationId ;
    return this.http.get(getreconcialationDetail);
  }
  
  getViewProductStoreSegmentsId(id): Observable<any> {
    const getViewProductStoreSegmentsId = URLS.getViewProductStoreSegmentsId +  "?productStoreId=" + id ;
    return this.http.get(getViewProductStoreSegmentsId);
  }
  
  findProductPromo(id): Observable<any> {
    const findProductPromo = URLS.findProductPromo + '/' + id;
    return this.http.get(findProductPromo);
  }
  getGatewayResponseById(id): Observable<any> {
    const getGatewayResponseById = URLS.getGatewayResponseById + "?paymentGatewayResponseId=" + id ;
    return this.http.get(getGatewayResponseById);
  }
  
  EditSecurityGroupUserLogins(id): Observable<any> {
    const EditSecurityGroupUserLogins = URLS.EditSecurityGroupUserLogins + "?groupId=" + id ;
    return this.http.get(EditSecurityGroupUserLogins);
  }
  getproductRatesDetail(id): Observable<any> {
    const getproductRatesDetail = URLS.getproductRatesDetail + "?taxAuthorityRateSeqId=" + id ;
    return this.http.get(getproductRatesDetail);
  }
  
  getEenumByType(id): Observable<any> {
    const getEenumByType = URLS.getEenumByType + "?enumTypes=" + id ;
    return this.http.get(getEenumByType);
  }
  getRelationshipPartyIdTo(id): Observable<any> {
    const getRelationshipPartyIdTo = URLS.getRelationshipPartyIdTo + "?partyIdTo=" + id ;
    return this.http.get(getRelationshipPartyIdTo);
  }
  getRelationshipPartyIdFrom(id): Observable<any> {
    const getRelationshipPartyIdFrom = URLS.getRelationshipPartyIdFrom + "?partyId=" + id ;
    return this.http.get(getRelationshipPartyIdFrom);
  }
  
   
  getTransacDetails(id,acc): Observable<any> {
    const getTransacDetails = URLS.getTransacDetails + '/' + id + "?acctgTransId=" + acc ;
    return this.http.get(getTransacDetails);
  }
  
  PartyInvitationGroupAssocs(id): Observable<any> {
    const PartyInvitationGroupAssocs = URLS.PartyInvitationGroupAssocs + '/' + id;
    return this.http.get(PartyInvitationGroupAssocs);
  }
  
  EditSecurityGroupProtectedViews(id): Observable<any> {
    const EditSecurityGroupProtectedViews = URLS.EditSecurityGroupProtectedViews + '/' + id;
    return this.http.get(EditSecurityGroupProtectedViews);
  }
  
  partyInvitationRoleAssociation(id): Observable<any> {
    const partyInvitationRoleAssociation = URLS.partyInvitationRoleAssociation + '/' + id;
    return this.http.get(partyInvitationRoleAssociation);
  }
  
  getContactListById(id): Observable<any> {
    const getContactListById = URLS.getContactListById + '/' + id;
    return this.http.get(getContactListById);
  }
  
  getWebSiteContactListById(id): Observable<any> {
    const getWebSiteContactListById = URLS.getWebSiteContactListById + '/' + id;
    return this.http.get(getWebSiteContactListById);
  }
  
  getStatusList_parties(): Observable<any> {
    const getStatusList_parties = URLS.getStatusList_parties;
    return this.http.get(getStatusList_parties);
  }
  
  getPreferredContactMechnaism(): Observable<any> {
    const getPreferredContactMechnaism = URLS.getPreferredContactMechnaism;
    return this.http.get(getPreferredContactMechnaism);
  }
  
  getPartyTypeId(): Observable<any> {
    const getPartyTypeId = URLS.getPartyTypeId;
    return this.http.get(getPartyTypeId);
  }
  getAssignedTaskList(): Observable<any> {
    const getAssignedTaskList = URLS.getAssignedTaskList;
    return this.http.get(getAssignedTaskList);
  }
  
  getCommunicationEventIdDrop(): Observable<any> {
    const getCommunicationEventIdDrop = URLS.getCommunicationEventIdDrop;
    return this.http.get(getCommunicationEventIdDrop);
  }
  
  getCommEventStatus(): Observable<any> {
    const getCommEventStatus = URLS.getCommEventStatus;
    return this.http.get(getCommEventStatus);
  }
  getBudgetItemTypeId(): Observable<any> {
    const getBudgetItemTypeId = URLS.getBudgetItemTypeId;
    return this.http.get(getBudgetItemTypeId);
  }

  getStatusVerifId(): Observable<any> {
    const getStatusVerifId = URLS.getStatusVerifId;
    return this.http.get(getStatusVerifId);
  }
  
  
  getBudgetOverview(id): Observable<any> {
    const getBudgetOverview = URLS.getBudgetOverview + '/' + id ;
    return this.http.get(getBudgetOverview);
  }
  editPartyInvitations(id): Observable<any> {
    const editPartyInvitations = URLS.editPartyInvitations + '/' + id ;
    return this.http.get(editPartyInvitations);
  }
  
  getBudgetItem(id,data): Observable<any> {
    const getBudgetItem = URLS.getBudgetItem + '/' + id + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getBudgetItem);
  }
  getbudgetRole(id,data): Observable<any> {
    const getbudgetRole = URLS.getbudgetRole + '/' + id + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getbudgetRole);
  }
  getbudgetReview(id,data): Observable<any> {
    const getbudgetReview = URLS.getbudgetReview + '/' + id + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getbudgetReview);
  }
  
  getEditPayHistory(partyIdFrom,partyIdTo,roleTypeIdFrom,roleTypeIdTo): Observable<any> {
    const getEditPayHistory = URLS.getEditPayHistory + "?partyIdFrom=" + partyIdFrom + "&partyIdTo=" + partyIdTo
    +"&roleTypeIdFrom=" + roleTypeIdFrom  +"&roleTypeIdTo=" +roleTypeIdTo;
    return this.http.get(getEditPayHistory);
  }
  
  deleteBudgetItem(id,seqId): Observable<any> {
    const deleteBudgetItem = URLS.deleteBudgetItem + '/' + id + "?budgetItemSeqId=" + seqId;
    return this.http.delete(deleteBudgetItem);
  }
  
  getWorkEffortDetailsById(id): Observable<any> {
    const getWorkEffortDetailsById = URLS.getWorkEffortDetailsById + '/' + id;
    return this.http.get(getWorkEffortDetailsById);
  }
  deleteAllSystemNotes(): Observable<any> {
    const deleteAllSystemNotes = URLS.deleteAllSystemNotes;
    return this.http.delete(deleteAllSystemNotes);
  }
  
  removebudgetRole(id,pid,rid): Observable<any> {
    const removebudgetRole = URLS.removebudgetRole + '/' + id + "?partyId=" + pid + "&roleTypeId=" + rid;
    return this.http.delete(removebudgetRole);
  }
  
  createQuoteFromShoppingList(applyStorePromotions,shoppingListId): Observable<any> {
    const createQuoteFromShoppingList = URLS.createQuoteFromShoppingList  + "?applyStorePromotions=" + applyStorePromotions + "&shoppingListId=" + shoppingListId;
    return this.http.post(createQuoteFromShoppingList,'');
  }
  
  removeShoppingListItem(shoppingListId,shoppingListItemSeqId): Observable<any> {
    const removeShoppingListItem = URLS.removeShoppingListItem + "?shoppingListId=" + shoppingListId + "&shoppingListItemSeqId=" + shoppingListItemSeqId;
    return this.http.delete(removeShoppingListItem);
  }
  removebudgetReview(id,budgetReviewId,budgetReviewResultTypeId,partyId): Observable<any> {
    const removebudgetReview = URLS.removebudgetReview + '/' + id + "?budgetReviewId=" + budgetReviewId + "&budgetReviewResultTypeId=" + budgetReviewResultTypeId + "&partyId=" + partyId;
    return this.http.delete(removebudgetReview);
  }
 
  
  
  
  getbudgetsItemEdit(id,seqId): Observable<any> {
    const getbudgetsItemEdit = URLS.getbudgetsItemEdit + '/' + id + "?budgetItemSeqId=" + seqId;
    return this.http.get(getbudgetsItemEdit);
  }
  
  createPartyCustomRequest(shoppingListId): Observable<any> {
    const createPartyCustomRequest = URLS.createPartyCustomRequest + "?shoppingListId=" + shoppingListId;
    return this.http.post(createPartyCustomRequest,'');
  }
  
  updateBudgetStatus(id,statusId): Observable<any> {
    const updateBudgetStatus = URLS.updateBudgetStatus + '/' + id + "?statusId=" + statusId;
    return this.http.post(updateBudgetStatus,'');
  }
  budgetReviewResultTypes(): Observable<any> {
    const budgetReviewResultTypes = URLS.budgetReviewResultTypes;
    return this.http.get(budgetReviewResultTypes);
  }
  
  
  updateproductRates(req): Observable<any> {
    const postproductRates = URLS.postproductRates;
    return this.http.put(postproductRates,req);
  }
  updateShoppingListItem(req): Observable<any> {
    const updateShoppingListItem = URLS.updateShoppingListItem;
    return this.http.put(updateShoppingListItem,req);
  }
  
  expireUserLoginFromSecurityGroup(req): Observable<any> {
    const expireUserLoginFromSecurityGroup = URLS.expireUserLoginFromSecurityGroup;
    return this.http.post(expireUserLoginFromSecurityGroup,req);
  }
  
  createProductStoreKeywordOvrd(req): Observable<any> {
    const createProductStoreKeywordOvrd = URLS.createProductStoreKeywordOvrd;
    return this.http.post(createProductStoreKeywordOvrd,req);
  }
  
  addProductStoreFacility(req): Observable<any> {
    const addProductStoreFacility = URLS.addProductStoreFacility;
    return this.http.post(addProductStoreFacility,req);
  }
  
  CreateProductStoreFinAccountSettings(req): Observable<any> {
    const CreateProductStoreFinAccountSettings = URLS.CreateProductStoreFinAccountSettings;
    return this.http.post(CreateProductStoreFinAccountSettings,req);
  }
  
  updateProductStoreFinAccountSettings(req): Observable<any> {
    const updateProductStoreFinAccountSettings = URLS.updateProductStoreFinAccountSettings;
    return this.http.post(updateProductStoreFinAccountSettings,req);
  }
  
  createProductStoreSurveyAppl(req): Observable<any> {
    const createProductStoreSurveyAppl = URLS.createProductStoreSurveyAppl;
    return this.http.post(createProductStoreSurveyAppl,req);
  }
  
  updateProductStoreKeywordOvrd(req): Observable<any> {
    const updateProductStoreKeywordOvrd = URLS.updateProductStoreKeywordOvrd;
    return this.http.post(updateProductStoreKeywordOvrd,req);
  }

  updatePartyHistory(req): Observable<any> {
    const updatePartyHistory = URLS.updatePartyHistory;
    return this.http.post(updatePartyHistory,req);
  }

  updatePerfReview(req): Observable<any> {
    const updatePerfReview = URLS.updatePerfReview;
    return this.http.post(updatePerfReview,req);
  }


  
UpdateShipmentGatewayConfigType(req): Observable<any> {
    const UpdateShipmentGatewayConfigType = URLS.UpdateShipmentGatewayConfigType;
    return this.http.post(UpdateShipmentGatewayConfigType,req);
  }

  
updateShipmentGatewayDhl(req): Observable<any> {
    const updateShipmentGatewayDhl = URLS.updateShipmentGatewayDhl;
    return this.http.post(updateShipmentGatewayDhl,req);
  }

  
updateShipmentGatewayUsps(req): Observable<any> {
    const updateShipmentGatewayUsps = URLS.updateShipmentGatewayUsps;
    return this.http.post(updateShipmentGatewayUsps,req);
  }
  

  updateShipmentGatewayUps(req): Observable<any> {
    const updateShipmentGatewayUps = URLS.updateShipmentGatewayUps;
    return this.http.post(updateShipmentGatewayUps,req);
  }
  

  
updateShipmentGatewayFedex(req): Observable<any> {
    const updateShipmentGatewayFedex = URLS.updateShipmentGatewayFedex;
    return this.http.post(updateShipmentGatewayFedex,req);
  }
  
  
UpdateShipmentGatewayConfig(req): Observable<any> {
    const UpdateShipmentGatewayConfig = URLS.UpdateShipmentGatewayConfig;
    return this.http.post(UpdateShipmentGatewayConfig,req);
  }
  getLabelImageFormat():Observable<any>{
    const getLabelImageFormat = URLS.getLabelImageFormat;
    return this.http.get(getLabelImageFormat);
  }

  getShipmentGatewayDhlConfigFedexPackingType():Observable<any>{
    const getShipmentGatewayDhlConfigFedexPackingType = URLS.getShipmentGatewayDhlConfigFedexPackingType;
    return this.http.get(getShipmentGatewayDhlConfigFedexPackingType);
  }

  getShipmentGatewayDhlConfigFedexDropoffType():Observable<any>{
    const getShipmentGatewayDhlConfigFedexDropoffType = URLS.getShipmentGatewayDhlConfigFedexDropoffType;
    return this.http.get(getShipmentGatewayDhlConfigFedexDropoffType);
  }


  
getShipmentGatewayDhlUspConfigFundsCod():Observable<any>{
    const getShipmentGatewayDhlUspConfigFundsCod = URLS.getShipmentGatewayDhlUspConfigFundsCod;
    return this.http.get(getShipmentGatewayDhlUspConfigFundsCod);
  }

  getRateEstimateAPISchema():Observable<any>{
    const getRateEstimateAPISchema = URLS.getRateEstimateAPISchema;
    return this.http.get(getRateEstimateAPISchema);
  }

  getSecurityGroupDataToCSV():Observable<any>{
    const getSecurityGroupDataToCSV = URLS.getSecurityGroupDataToCSV;
    return this.http.get(getSecurityGroupDataToCSV);
  }

  
getShipmentGatewayDhlConfigFedexLabelImageType():Observable<any>{
    const getShipmentGatewayDhlConfigFedexLabelImageType = URLS.getShipmentGatewayDhlConfigFedexLabelImageType;
    return this.http.get(getShipmentGatewayDhlConfigFedexLabelImageType);
  }

  getShipmentGatewayDhlConfigFedex(shipmentGatewayConfigId):Observable<any>{
    const getShipmentGatewayDhlConfigFedex = URLS.getShipmentGatewayDhlConfigFedex + "?shipmentGatewayConfigId=" + shipmentGatewayConfigId    ;
    return this.http.get(getShipmentGatewayDhlConfigFedex);
  }

  getShipmentGatewayDhlUspsConfig(shipmentGatewayConfigId):Observable<any>{
    const getShipmentGatewayDhlUspsConfig = URLS.getShipmentGatewayDhlUspsConfig+ "?shipmentGatewayConfigId=" + shipmentGatewayConfigId    ;
    return this.http.get(getShipmentGatewayDhlUspsConfig);
  }

  

getShipmentGatewayDhlUpsConfig(shipmentGatewayConfigId):Observable<any>{
    const getShipmentGatewayDhlUpsConfig = URLS.getShipmentGatewayDhlUpsConfig + "?shipmentGatewayConfigId=" + shipmentGatewayConfigId    ;
    return this.http.get(getShipmentGatewayDhlUpsConfig);
  }

  getShipmentGatewayDhlUspsConfigTestMode():Observable<any>{
    const getShipmentGatewayDhlUspsConfigTestMode = URLS.getShipmentGatewayDhlUspsConfigTestMode;
    return this.http.get(getShipmentGatewayDhlUspsConfigTestMode);
  }

  getShipmentGatewayDhlUspConfigSaveCertInfo():Observable<any>{
    const getShipmentGatewayDhlUspConfigSaveCertInfo = URLS.getShipmentGatewayDhlUspConfigSaveCertInfo;
    return this.http.get(getShipmentGatewayDhlUspConfigSaveCertInfo);
  }

  getShipmentGatewayDhlUspConfigCustomerClassification():Observable<any>{
    const getShipmentGatewayDhlUspConfigCustomerClassification = URLS.getShipmentGatewayDhlUspConfigCustomerClassification;
    return this.http.get(getShipmentGatewayDhlUspConfigCustomerClassification);
  }

  
getAvailabilty():Observable<any>{
    const getAvailabilty = URLS.getAvailabilty;
    return this.http.get(getAvailabilty);
  }
  attachFile(formData):Observable<any>{
    const attachFile = URLS.attachFile  ;
    return this.http.post(attachFile,formData);
  }
  
  
getShipmentGatewayDhlConfig(shipmentGatewayConfigId):Observable<any>{
    const getShipmentGatewayDhlConfig = URLS.getShipmentGatewayDhlConfig + "?shipmentGatewayConfigId=" + shipmentGatewayConfigId ;
    return this.http.get(getShipmentGatewayDhlConfig);
  }
  
findApplicationEmploymentIdSearch(empTypeId ,name , socialSecurityNumber , driverLicenseNumber , position ,availability ,departments):Observable<any>{
    const findApplicationEmploymentId = URLS.findApplicationEmploymentId + "?empTypeId=" + empTypeId  + "?name=" + name  + "?socialSecurityNumber=" + socialSecurityNumber 
    + "?driverLicenseNumber=" + driverLicenseNumber  + "?position=" + position  + "?availability=" + availability  + "?departments=" + departments ;
    return this.http.get(findApplicationEmploymentId);
  }
  findApplicationEmploymentId(empTypeId):Observable<any>{
    const findApplicationEmploymentId = URLS.findApplicationEmploymentId+ "?empTypeId=" + empTypeId ;
    return this.http.get(findApplicationEmploymentId);
  }
    
    
  findApplicationEmployment():Observable<any>{
  const findApplicationEmployment = URLS.findApplicationEmployment;
  return this.http.get(findApplicationEmployment);
}
  
  
getHeadActionAttribute():Observable<any>{
    const getHeadActionAttribute = URLS.getHeadActionAttribute;
    return this.http.get(getHeadActionAttribute);
  }
  

  getShipmentGatewayDhlUspConfigSurchargeApply():Observable<any>{
    const getShipmentGatewayDhlUspConfigSurchargeApply = URLS.getShipmentGatewayDhlUspConfigSurchargeApply;
    return this.http.get(getShipmentGatewayDhlUspConfigSurchargeApply);
  }
  
  updatePartyQual(req): Observable<any> {
    const updatePartyQual = URLS.updatePartyQual;
    return this.http.post(updatePartyQual,req);
  }

  updateEmployment(req): Observable<any> {
    const updateEmployment = URLS.updateEmployment;
    return this.http.post(updateEmployment,req);
  }
  
  updatePartyInvitations(req): Observable<any> {
    const updatePartyInvitations = URLS.updatePartyInvitations;
    return this.http.put(updatePartyInvitations,req);
  }
  
  postbudgetRole(req): Observable<any> {
    const postbudgetRole = URLS.postbudgetRole;
    return this.http.post(postbudgetRole,req);
  }
  postbudgetReview(req): Observable<any> {
    const postbudgetReview = URLS.postbudgetReview;
    return this.http.post(postbudgetReview,req);
  }
  
  
  
  
  createAccountTransaction(id,req): Observable<any> {
    const createAccountTransaction = URLS.createAccountTransaction + '/' + id;
    return this.http.post(createAccountTransaction,req);
  }
  postproductRates(req): Observable<any> {
    const postproductRates = URLS.postproductRates;
    return this.http.post(postproductRates,req);
  }

 
  
  postreconcialation(id,req): Observable<any> {
    const postreconcialation = URLS.postreconcialation + '/' + id;
    return this.http.post(postreconcialation,req);
  }
  processManualTransaction(req): Observable<any> {
    const processManualTransaction = URLS.processManualTransaction;
    return this.http.post(processManualTransaction,req);
  }
  processRefundTransaction(req): Observable<any> {
    const processRefundTransaction = URLS.processRefundTransaction;
    return this.http.post(processRefundTransaction,req);
  }
  processReleaseTransaction(req): Observable<any> {
    const processReleaseTransaction = URLS.processReleaseTransaction;
    return this.http.post(processReleaseTransaction,req);
  }
  
  
  updatereconcialation(id,req): Observable<any> {
    const postreconcialation = URLS.postreconcialation + '/' + id;
    return this.http.put(postreconcialation,req);
  }
  authOrderPreference(req): Observable<any> {
    const authOrderPreference = URLS.authOrderPreference;
    return this.http.post(authOrderPreference,req);
  }
  captureOrderPreference(req): Observable<any> {
    const captureOrderPreference = URLS.captureOrderPreference;
    return this.http.post(captureOrderPreference,req);
  }
  
  
  

  updateAccountTransaction(id,acc,req): Observable<any> {
    const updateAccountTransaction = URLS.updateAccountTransaction + '/' + id + "?acctgTransId=" + acc;
    return this.http.put(updateAccountTransaction,req);
  }
  
  quickCreateAccountTransaction(id,req): Observable<any> {
    const quickCreateAccountTransaction = URLS.quickCreateAccountTransaction + '/' + id;
    return this.http.post(quickCreateAccountTransaction,req);
  }
  
  
  communicationEventsDelete(id): Observable<any> {
    const communicationEventsDelete = URLS.communicationEventsDelete + '/' + id;
    return this.http.delete(communicationEventsDelete);
  }
  
  
  getWorkEffortPartyAssignmentsData(workEffortId,fixedId): Observable<any> {
    const getWorkEffortPartyAssignmentsData = URLS.getWorkEffortPartyAssignmentsData + '/' + workEffortId + '/' + fixedId;
    return this.http.get(getWorkEffortPartyAssignmentsData);
  }
  
  getOverheadProjector(workEffortId,fixedId): Observable<any> {
    const getOverheadProjector = URLS.getOverheadProjector + '/' + workEffortId + '/' + fixedId;
    return this.http.get(getOverheadProjector);
  }

  
  
  postTaxAuthorities(req): Observable<any> {
    const postTaxAuthorities = URLS.postTaxAuthorities;
    return this.http.post(postTaxAuthorities,req);
  }
 
  
  updateTaxAuthorities(req): Observable<any> {
    const postTaxAuthorities = URLS.postTaxAuthorities;
    return this.http.put(postTaxAuthorities,req);
  }
  
  taxCategoryListDelete(req): Observable<any> {
    const taxCategoryListDelete = URLS.taxCategoryListDelete;
    return this.http.post(taxCategoryListDelete,req);
  }
  deleteAssociationList(req): Observable<any> {
    const deleteAssociationList = URLS.deleteAssociationList;
    return this.http.post(deleteAssociationList,req); 
  }
  
  FindPurchaseInvoicesByDueDate(req): Observable<any> {
    const FindPurchaseInvoicesByDueDate = URLS.FindPurchaseInvoicesByDueDate;
    return this.http.post(FindPurchaseInvoicesByDueDate,req);
  } 
  FindSalesInvoicesByDueDate(req): Observable<any> {
    const FindSalesInvoicesByDueDate = URLS.FindSalesInvoicesByDueDate;
    return this.http.post(FindSalesInvoicesByDueDate,req);
  }
  
  postTaxCategory(req): Observable<any> {
    const postTaxCategory = URLS.postTaxCategory;
    return this.http.post(postTaxCategory,req);
  }
  
  getPaymentsList(data,req): Observable<any> {
    const getPaymentsList = URLS.getPaymentsList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getPaymentsList,req);
  }
  getBudgetList(data,req): Observable<any> {
    const getBudgetList = URLS.getBudgetList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getBudgetList,req);
  }
  
  postAssociationList(data,req): Observable<any> {
    const postAssociationList = URLS.postAssociationList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(postAssociationList,req);
  }
  
  getCategorySearch(data,req): Observable<any> {
    const getCategorySearch = URLS.getCategorySearch + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getCategorySearch,req);
  }
  
  taxCategoryList(data,req): Observable<any> {
    const taxCategoryList = URLS.taxCategoryList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(taxCategoryList,req);
  }
 
  
  getTaxGlAccounts(data,geo,party): Observable<any> {
    const getTaxGlAccounts = URLS.getTaxGlAccounts + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&taxAuthGeoId=" + geo + "&taxAuthPartyId=" + party;
    return this.http.get(getTaxGlAccounts);
  }
  findPerfReview(emplPositionId,employeePartyId,managerPartyId,paymentId,perfReviewId): Observable<any> {
    const findPerfReview = URLS.findPerfReview + "?emplPositionId=" + emplPositionId + "&employeePartyId=" + employeePartyId + "&managerPartyId=" + managerPartyId + "&paymentId=" + paymentId + "&perfReviewId=" + perfReviewId;
    return this.http.get(findPerfReview);
  }
  
  getTaxParty(data,geo,party,req): Observable<any> {
    const getTaxParty = URLS.getTaxParty + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&taxAuthGeoId=" + geo + "&taxAuthPartyId=" + party;
    return this.http.post(getTaxParty,req);
  }
  
  getProductRate(data,geo,party): Observable<any> {
    const getProductRate = URLS.getProductRate + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&taxAuthGeoId=" + geo + "&taxAuthPartyId=" + party;
    return this.http.get(getProductRate);
  }
  
  
  saveAssociation(req): Observable<any> {
    const saveAssociation = URLS.saveAssociation;
    return this.http.post(saveAssociation,req);
  }
  
  getParentTypeIdParty(): Observable<any> {
    const getParentTypeIdParty = URLS.getParentTypeIdParty;
    return this.http.get(getParentTypeIdParty);
  }
  
  deleteTaxGlAccounts(req): Observable<any> {
    const deleteTaxGlAccounts = URLS.deleteTaxGlAccounts;
    return this.http.post(deleteTaxGlAccounts,req);
  }
  updateTaskAssignment(req): Observable<any> {
    const updateTaskAssignment = URLS.updateTaskAssignment;
    return this.http.post(updateTaskAssignment,req);
  }
  
  updateAssociation(req): Observable<any> {
    const saveAssociation = URLS.saveAssociation;
    return this.http.put(saveAssociation,req);
  }
  updateTaxAuthorityAssoc(req): Observable<any> {
    const updateTaxAuthorityAssoc = URLS.updateTaxAuthorityAssoc;
    return this.http.post(updateTaxAuthorityAssoc,req);
  }
  getAssociationById(req): Observable<any> {
    const getAssociationById = URLS.getAssociationById;
    return this.http.post(getAssociationById,req);
  }
  postglAccountsTax(req): Observable<any> {
    const postglAccountsTax = URLS.postglAccountsTax;
    return this.http.post(postglAccountsTax,req);
  }
  
  removefixedAssetProduct(req): Observable<any> {
    const removefixedAssetProduct = URLS.removefixedAssetProduct;
    return this.http.post(removefixedAssetProduct,req);
  }
  
  
  getTaxAuthorities(data): Observable<any> {
    const getTaxAuthorities = URLS.getTaxAuthorities + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getTaxAuthorities);
  }
  
  getMyLeave(data): Observable<any> {
    const getMyLeave = URLS.getMyLeave + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getMyLeave);
  }
  getAssocTypes(): Observable<any> {
    const getAssocTypes = URLS.getAssocTypes;
    return this.http.get(getAssocTypes);
  }
  
  getgeoByType(): Observable<any> {
    const getgeoByType = URLS.getgeoByType;
    return this.http.get(getgeoByType);
  }
   
  getScope(): Observable<any> {
    const getScope = URLS.getScope;
    return this.http.get(getScope);
  }
  
  getSkillType(): Observable<any> {
    const getSkillType = URLS.getSkillType;
    return this.http.get(getSkillType);
  }
  
  getTaskName(): Observable<any> {
    const getTaskName = URLS.getTaskName;
    return this.http.get(getTaskName);
  }
  
  getParentPhase(): Observable<any> {
    const getParentPhase = URLS.getParentPhase;
    return this.http.get(getParentPhase);
  }
  getGatewayResponse(data,req): Observable<any> {
    const getGatewayResponse = URLS.getGatewayResponse + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getGatewayResponse,req);
  }
  getTaskNew(): Observable<any> {
    const getTaskNew = URLS.getTaskNew;
    return this.http.get(getTaskNew);
  }
  findPortalPage(data,req): Observable<any> {
    const findPortalPage = URLS.findPortalPage + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(findPortalPage,req);
  }
  
  removeProtectedViewFromSecurityGroup(gid,vid): Observable<any> {
    const removeProtectedViewFromSecurityGroup = URLS.removeProtectedViewFromSecurityGroup + "?groupId=" + gid + "&viewNameId=" + vid;
    return this.http.delete(removeProtectedViewFromSecurityGroup);
  }

  getinventoryValuation(data,req): Observable<any> {
    const getinventoryValuation = URLS.getinventoryValuation + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getinventoryValuation,req);
  }
  
  findVisitors(data,filterByDate,req): Observable<any> {
    const findVisitors = URLS.findVisitors + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&filterByDate=" + filterByDate;
    return this.http.post(findVisitors,req);
  }
  
  findTransactionDetails(data,req): Observable<any> {
    const findTransactionDetails = URLS.findTransactionDetails + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(findTransactionDetails,req);
  }

  
  getInvoices(data,req): Observable<any> {
    const getInvoices = URLS.getInvoices + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getInvoices,req);
  }
  createUserLogin(req): Observable<any> {
    const createUserLogin = URLS.createUserLogin;
    return this.http.post(createUserLogin,req);
  }
  
  createMyTasks(req): Observable<any> {
    const createMyTasks = URLS.createMyTasks;
    return this.http.post(createMyTasks,req);
  }
  addTaskAssignment(req): Observable<any> {
    const addTaskAssignment = URLS.addTaskAssignment;
    return this.http.post(addTaskAssignment,req);
  }
  
  updatePassword(req): Observable<any> {
    const updatePassword = URLS.updatePassword;
    return this.http.put(updatePassword,req);
  }
  updateUserLoginSecurity(req): Observable<any> {
    const updateUserLoginSecurity = URLS.updateUserLoginSecurity;
    return this.http.put(updateUserLoginSecurity,req);
  }
  
  getPayment(data): Observable<any> {
    const getPayment = URLS.getPayment + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getPayment);
  }
  
  getTrainingData(data): Observable<any> {
    const getTrainingData = URLS.getTrainingData + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getTrainingData);
  }
  
  getSecurityGroupPermissionApi(data, id): Observable<any> {
    const getSecurityGroupPermissionApi = URLS.getSecurityGroupPermissionApi.replace(":securityGroupId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getSecurityGroupPermissionApi);
  }
  deleteSecurityGroupPermissionApi(fromdate, pid, sid): Observable<any> {
    const deleteSecurityGroupPermissionApi = URLS.getSecurityGroupPermissionApi.replace(":securityGroupId", sid) + "?fromDate=" + fromdate + "&permissionId=" + pid;
    return this.http.delete(deleteSecurityGroupPermissionApi);
  }
  postPermisionDate(fromData, sid): Observable<any> {
    const postPermisionDate = URLS.getSecurityGroupPermissionApi.replace(":securityGroupId", sid);
    return this.http.post(postPermisionDate, fromData);
  }
  createReconciliation(fromData, sid): Observable<any> {
    const createReconciliation = URLS.createReconciliation.replace(":finAccountId", sid);
    return this.http.post(createReconciliation, fromData);
  }
  postCostCenters(fromData): Observable<any> {
    const postCostCenters = URLS.postCostCenters;
    return this.http.post(postCostCenters, fromData);
  }

  updateReconciliation(fromData, sid): Observable<any> {
    const updateReconciliation = URLS.createReconciliation.replace(":finAccountId", sid);
    return this.http.put(updateReconciliation, fromData);
  }
  getReconciliationSummary(sid, gid): Observable<any> {
    const getReconciliationSummary = (URLS.getReconciliationSummary) + sid + "/" + gid;

    return this.http.get(getReconciliationSummary);
   
  }


  getRoleMainType(id): Observable<any> {
    const getRoleMainType = URLS.getRoleMainType + "?roleType=" + id;
    return this.http.get(getRoleMainType);
  }
  
  ManagePortalPages(id): Observable<any> {
    const ManagePortalPages = URLS.ManagePortalPages + "?parentPortalPageId=" + id;
    return this.http.get(ManagePortalPages);
  }
  
  getUserDataAdminSubs(id): Observable<any> {
    const getRoleMainType = ':81/api/v1/saas/subscriptionDetail' + "?tenantName=" + id;
    return this.http.get(getRoleMainType);
  }
  
  

  getRoleParentTypeID(id): Observable<any> {
    const getRoleParentTypeID = URLS.getRoleParentTypeID.replace(":parentTypeId", id);
    return this.http.get(getRoleParentTypeID);
  }
  getUserProfile(): Observable<any> {
    const getUserProfile = URLS.getUserProfile;
    return this.http.get(getUserProfile);
  }

  getExportUser(): Observable<any> {
    return this.http.get(URLS.getExportUser, { responseType: 'blob' })
  }
  getFinancailAccount(data, req): Observable<any> {
    const getFinancailAccount = URLS.getFinancailAccount + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getFinancailAccount, req);
  }
  
  
  getTaskListData(): Observable<any> {
    const getTaskListData = URLS.getTaskListData;
    return this.http.get(getTaskListData);
  }

  findAddressMatch(match, req): Observable<any> {
    const findAddressMatch = URLS.findAddressMatch + "?match=" + match;
    return this.http.post(findAddressMatch, req);
  }
  getFinancialAccountReconciliations(data, req): Observable<any> {
    const getFinancialAccountReconciliations = URLS.getFinancialAccountReconciliations + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getFinancialAccountReconciliations, req);
  }
  cancelFinancialAccountTransaction(id, finAccountId, statusId): Observable<any> {
    const cancelFinancialAccountTransaction = URLS.cancelFinancialAccountTransaction.replace(":finAccountTransId", id) + "?finAccountId=" + finAccountId + "&statusId=" + statusId;
    return this.http.put(cancelFinancialAccountTransaction, '');
  }
  glAccountCategoryMemberSetting(id, data): Observable<any> {
    const glAccountCategoryMember = URLS.glAccountCategoryMember.replace(":glAccountCategoryId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(glAccountCategoryMember);
  }
  
  getSummaryById(id): Observable<any> {
    const getSummaryById = URLS.getSummaryById.replace(":workEffortId", id);
    return this.http.get(getSummaryById);
  }
  getAssignedAccountGlSetting(id, data): Observable<any> {
    const getAssignedAccount = URLS.getAssignedAccount.replace(":organizationPartyId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getAssignedAccount);
  }
  crmMainPageData(form, data): Observable<any> {
    const crmMainPageData = URLS.crmMainPageData + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(crmMainPageData,form);
  }
  getWorkEffortPartyAssignments(id): Observable<any> {
    const getWorkEffortPartyAssignments = URLS.getWorkEffortPartyAssignments + "?workEffortId=" + id;
    return this.http.get(getWorkEffortPartyAssignments);
  }
  
  getCrmEventPurposeListData(id, data): Observable<any> {
    const getCrmEventPurposeListData = URLS.getCrmEventPurposeListData.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCrmEventPurposeListData);
  }
  getCrmEventRole(id, data): Observable<any> {
    const getCrmEventRole = URLS.getCrmEventRole.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCrmEventRole);
  }
  getCrmEventProductList(id, data): Observable<any> {
    const getCrmEventProductList = URLS.getCrmEventProductList.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCrmEventProductList);
  }
  getCrmEventReturnList(id, data): Observable<any> {
    const getCrmEventReturnList = URLS.getCrmEventReturnList.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCrmEventReturnList);
  }
  getCrmEventOrderList(id, data): Observable<any> {
    const getCrmEventOrderList = URLS.getCrmEventOrderList.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCrmEventOrderList);
  }
  postCrmEventOrderList(id, pid): Observable<any> {
    const getCrmEventOrderList = URLS.getCrmEventOrderList.replace(":communicationEventId", id) + "?orderId=" + pid;
    return this.http.post(getCrmEventOrderList,'');
  }
  getCrmEventContentList(id, data): Observable<any> {
    const getCrmEventContentList = URLS.getCrmEventContentList.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCrmEventContentList);
  }
  deleteCrmEventContentList(id, cid,fromDate): Observable<any> {
    const getCrmEventContentList = URLS.getCrmEventContentList.replace(":communicationEventId", id) + "?contentId=" + cid + "&fromDate=" + fromDate;
    return this.http.delete(getCrmEventContentList);
  }
  
  deleteCrmEventOrderList(id, orderId): Observable<any> {
    const getCrmEventOrderList = URLS.getCrmEventOrderList.replace(":communicationEventId", id) + "?orderId=" + orderId;
    return this.http.delete(getCrmEventOrderList);
  }
  
  postCrmEventReturnList(id, pid): Observable<any> {
    const getCrmEventReturnList = URLS.getCrmEventReturnList.replace(":communicationEventId", id) + "?returnId=" + pid;
    return this.http.post(getCrmEventReturnList,'');
  }
  deleteCrmEventReturnList(id, returnId): Observable<any> {
    const getCrmEventReturnList = URLS.getCrmEventReturnList.replace(":communicationEventId", id) + "?returnId=" + returnId;
    return this.http.delete(getCrmEventReturnList);
  }
  
  postCrmEventProductList(id, pid): Observable<any> {
    const getCrmEventProductList = URLS.getCrmEventProductList.replace(":communicationEventId", id) + "?productId=" + pid;
    return this.http.post(getCrmEventProductList,'');
  }

  deleteCrmEventProductList(id,pid): Observable<any> {
    const getCrmEventProductList = URLS.getCrmEventProductList.replace(":communicationEventId", id)  + "?productId=" + pid;
    return this.http.delete(getCrmEventProductList);
  }
  
  getCrmEventRoleTypeList(): Observable<any> {
    const getCrmEventRoleTypeList = URLS.getCrmEventRoleTypeList;
    return this.http.get(getCrmEventRoleTypeList);
  }
  existingContentIds(): Observable<any> {
    const existingContentIds = URLS.existingContentIds;
    return this.http.get(existingContentIds);
  }
  
  postCommunicationNew(data): Observable<any> {
    const postCommunicationNew = URLS.postCommunicationNew;
    return this.http.post(postCommunicationNew,data);
  }
  createPortalPageAdm(data): Observable<any> {
    const createPortalPageAdm = URLS.createPortalPageAdm;
    return this.http.post(createPortalPageAdm,data);
  }
  
  updateCommunicationNew(data): Observable<any> {
    const postCommunicationNew = URLS.postCommunicationNew;
    return this.http.put(postCommunicationNew,data);
  }
  postCrmEventRole(id, data): Observable<any> {
    const getCrmEventRole = URLS.getCrmEventRole.replace(":communicationEventId", id);
    return this.http.post(getCrmEventRole,data);
  }
 
  postCrmEventPurposeListData(id, data): Observable<any> {
    const getCrmEventPurposeListData = URLS.getCrmEventPurposeListData.replace(":communicationEventId", id);
    return this.http.post(getCrmEventPurposeListData,data);
  }
  deleteCrmEventPurposeListData(id, cid): Observable<any> {
    const deleteCrmEventPurposeListData = URLS.getCrmEventPurposeListData.replace(":communicationEventId", id) + "?communicationEventPrpTypId=" + cid;
    return this.http.delete(deleteCrmEventPurposeListData);
  }
  deleteEventRoleList(id,pid,rid){
    const getCrmEventRole = URLS.getCrmEventRole.replace(":communicationEventId", id) + "?partyId=" + pid + "&roleTypeId=" + rid;
    return this.http.delete(getCrmEventRole);
  }
  
  findOrdersList(data, req): Observable<any> {
    const findOrdersList = URLS.findOrdersList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(findOrdersList, req);
  }
  getVendorListById(id): Observable<any> {
    const getVendorListById = URLS.getVendorListById.replace(":partyId", id);
    return this.http.get(getVendorListById);
  }
  getVendorPartyIdListById(id): Observable<any> {
    const getVendorPartyIdListById = URLS.getVendorPartyIdListById.replace(":partyId", id);
    return this.http.get(getVendorPartyIdListById);
  }
  getCrmOverViewDetail(id): Observable<any> {
    const getCrmOverViewDetail = URLS.getCrmOverViewDetail.replace(":communicationEventId", id);
    return this.http.get(getCrmOverViewDetail);
  }
  
  
  getCrmInitialCommData(): Observable<any> {
    const getCrmInitialCommData = URLS.getCrmInitialCommData;
    return this.http.get(getCrmInitialCommData);
  }
  getCrmEventPurpose(): Observable<any> {
    const getCrmEventPurpose = URLS.getCrmEventPurpose;
    return this.http.get(getCrmEventPurpose);
  }
  

  
  postAccountCategoryMemberSetting(id, data): Observable<any> {
    const glAccountCategoryMember = URLS.glAccountCategoryMember.replace(":glAccountCategoryId", id);
    return this.http.post(glAccountCategoryMember, data);
  }
  getCommunicationEventByOrder(data,form): Observable<any> {
    const getCommunicationEventByOrder = URLS.getCommunicationEventByOrder + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getCommunicationEventByOrder, form);
  }
  getCommunicationByOrder(data,form): Observable<any> {
    const getCommunicationByOrder = URLS.getCommunicationByOrder + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getCommunicationByOrder, form);
  }
  
  updateAccountCategoryMemberSetting(id, data): Observable<any> {
    const updateAccountCategoryMemberSetting = URLS.glAccountCategoryMember.replace(":glAccountCategoryId", id);
    return this.http.put(updateAccountCategoryMemberSetting, data);
  }

  postDepositOrWithdraw(data, id): Observable<any> {
    const postDepositOrWithdraw = URLS.postDepositOrWithdraw.replace(":finAccountId", id)
    return this.http.post(postDepositOrWithdraw, data);
  }
  searchDepositSlip(data, id): Observable<any> {
    const searchDepositSlip = URLS.searchDepositSlip + "?finAccountId=" + id
    return this.http.post(searchDepositSlip, data);
  }
  gettransactionTotals(id,data): Observable<any> {
    const gettransactionTotals = URLS.gettransactionTotals + "?organizationPartyId=" + id
    return this.http.post(gettransactionTotals, data);
  }
  sendmarkChecksSent(id,data): Observable<any> {
    const sendmarkChecksSent = URLS.sendmarkChecksSent + "?organizationPartyId=" + id
    return this.http.post(sendmarkChecksSent, data);
  }

  
  getbalanceSheet(id,data): Observable<any> {
    const getbalanceSheet = URLS.getbalanceSheet + "?organizationPartyId=" + id
    return this.http.post(getbalanceSheet, data);
  }
  updatePartyClassificationTypeId(data): Observable<any> {
    const updatePartyClassificationTypeId = URLS.updatePartyClassificationTypeId;
    return this.http.put(updatePartyClassificationTypeId, data);
  }
  
  getCheckDetails(id): Observable<any> {
    const getCheckDetails = URLS.getCheckDetails + "?organizationPartyId=" + id
    return this.http.get(getCheckDetails);
  }
  
  listLoggedInUsers(): Observable<any> {
    const listLoggedInUsers = URLS.listLoggedInUsers;
    return this.http.get(listLoggedInUsers);
  }
  getUnknownPartyComms(): Observable<any> {
    const getUnknownPartyComms = URLS.getUnknownPartyComms;
    return this.http.get(getUnknownPartyComms);
  }
  
  getcostCenterAcc(id,data): Observable<any> {
    const getcostCenterAcc = URLS.getcostCenterAcc + "?organizationPartyId=" + id
    return this.http.post(getcostCenterAcc, data);
  }
  
  getglAccountTrialBalance(id,data): Observable<any> {
    const getglAccountTrialBalance = URLS.getglAccountTrialBalance + "?organizationPartyId=" + id
    return this.http.post(getglAccountTrialBalance, data);
  }
  
  getcomparativeBalanceSheet(id,data): Observable<any> {
    const getcomparativeBalanceSheet = URLS.getcomparativeBalanceSheet + "?organizationPartyId=" + id
    return this.http.post(getcomparativeBalanceSheet, data);
  }
  
  getcomparativeCashFlowStatement(id,data): Observable<any> {
    const getcomparativeCashFlowStatement = URLS.getcomparativeCashFlowStatement + "?organizationPartyId=" + id
    return this.http.post(getcomparativeCashFlowStatement, data);
  }
  
  getcomparativeIncomeStatements(id,data): Observable<any> {
    const getcomparativeIncomeStatements = URLS.getcomparativeIncomeStatements + "?organizationPartyId=" + id
    return this.http.post(getcomparativeIncomeStatements, data);
  }
  
  
  getIncomeStatements(id,data): Observable<any> {
    const getIncomeStatements = URLS.getIncomeStatements + "?organizationPartyId=" + id
    return this.http.post(getIncomeStatements, data);
  }
  getcashflowStatements(id,data): Observable<any> {
    const getcashflowStatements = URLS.getcashflowStatements + "?organizationPartyId=" + id
    return this.http.post(getcashflowStatements, data);
  }
  
  
  
  contactEventMech(id): Observable<any> {
    const contactEventMech = URLS.contactEventMech + "?partyId=" + id
    return this.http.get(contactEventMech);
  }
  

  deleteFinancailAccount(id): Observable<any> {
    const deleteFinancailAccount = URLS.deleteFinancailAccount.replace(":finAccountId", id);
    return this.http.delete(deleteFinancailAccount);
  }
  getFinAccountTypeList(): Observable<any> {
    const getFinAccountTypeList = URLS.getFinAccountTypeList;
    return this.http.get(getFinAccountTypeList);
  }
  getFinancialAccountIntialData(): Observable<any> {
    const getFinancialAccountIntialData = URLS.getFinancialAccountIntialData;
    return this.http.get(getFinancialAccountIntialData);
  }

  getFinAccountStatusList(): Observable<any> {
    const getFinAccountStatusList = URLS.getFinAccountStatusList;
    return this.http.get(getFinAccountStatusList);
  }

  getVendorsInitialdata(): Observable<any> {
    const getVendorsInitialdata = URLS.getVendorsInitialdata;
    return this.http.get(getVendorsInitialdata);
  }
  getGioAccountId(): Observable<any> {
    const getGioAccountId = URLS.getGioAccountId;
    return this.http.get(getGioAccountId);
  }
  financialTransactionStatus(): Observable<any> {
    const financialTransactionStatus = URLS.financialTransactionStatus;
    return this.http.get(financialTransactionStatus);
  }
  finAccountTransTypeId(): Observable<any> {
    const finAccountTransTypeId = URLS.finAccountTransTypeId;
    return this.http.get(finAccountTransTypeId);
  }
  depositOrWithdrawInitailData(): Observable<any> {
    const depositOrWithdrawInitailData = URLS.depositOrWithdrawInitailData;
    return this.http.get(depositOrWithdrawInitailData);
  }
  fixedAssetsInitialData(): Observable<any> {
    const fixedAssetsInitialData = URLS.fixedAssetsInitialData;
    return this.http.get(fixedAssetsInitialData);
  }
  getRatesInitialData(): Observable<any> {
    const getRatesInitialData = URLS.getRatesInitialData;
    return this.http.get(getRatesInitialData);
  }
  getGlAccountCategoriesInitialData(): Observable<any> {
    const getGlAccountCategoriesInitialData = URLS.getGlAccountCategoriesInitialData;
    return this.http.get(getGlAccountCategoriesInitialData);
  }
  internalOrganization(): Observable<any> {
    const internalOrganization = URLS.internalOrganization;
    return this.http.get(internalOrganization);
  }
  
  FindUserLogin(): Observable<any> {
    const FindUserLogin = URLS.FindUserLogin;
    return this.http.get(FindUserLogin);
  }
  getCostCenters(): Observable<any> {
    const getCostCenters = URLS.getCostCenters;
    return this.http.get(getCostCenters);
  }
  initialDataTimePeriod(): Observable<any> {
    const initialDataTimePeriod = URLS.initialDataTimePeriod;
    return this.http.get(initialDataTimePeriod);
  }
  


  getglobalGLSetting(data): Observable<any> {
    const getglobalGLSetting = URLS.getglobalGLSetting + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getglobalGLSetting);
  }
  getFinancialRoleAccount(data, id): Observable<any> {
    const getFinancialRoleAccount = URLS.getFinancialRoleAccount + "?finAccountId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getFinancialRoleAccount);
  }
  
  deletePartyInvitationGroupAssoci(inviteid, id): Observable<any> {
    const deletePartyInvitationGroupAssoci = URLS.deletePartyInvitationGroupAssoci + "?partyInvitationId=" + inviteid + "&partyIdTo=" + id;
    return this.http.delete(deletePartyInvitationGroupAssoci);
  }
  
  deleteOtherRelationship(data): Observable<any> {
    const deleteOtherRelationship = URLS.deleteOtherRelationship;
    return this.http.post(deleteOtherRelationship,data);
  }
  
  updateOtherRelationship(data): Observable<any> {
    const updateOtherRelationship = URLS.updateOtherRelationship;
    return this.http.put(updateOtherRelationship,data);
  }
  
  updateUserLoginToSecurityGroup(data): Observable<any> {
    const updateUserLoginToSecurityGroup = URLS.updateUserLoginToSecurityGroup;
    return this.http.put(updateUserLoginToSecurityGroup,data);
  }
  addUserLoginToSecurityGroup(data): Observable<any> {
    const addUserLoginToSecurityGroup = URLS.addUserLoginToSecurityGroup;
    return this.http.post(addUserLoginToSecurityGroup,data);
  }
  
  createPartyInvitationGroupAssoci(inviteid, id): Observable<any> {
    const createPartyInvitationGroupAssoci = URLS.createPartyInvitationGroupAssoci + "?partyInvitationId=" + inviteid + "&partyIdTo=" + id;
    return this.http.post(createPartyInvitationGroupAssoci,'');
  }
  
  deletePartyInvitationRoleAssociation(inviteid, id): Observable<any> {
    const deletePartyInvitationRoleAssociation = URLS.deletePartyInvitationRoleAssociation + "?partyInvitationId=" + inviteid + "&roleTypeId=" + id;
    return this.http.delete(deletePartyInvitationRoleAssociation);
  }
  
  getJournal(data, id): Observable<any> {
    const getJournal = URLS.getJournal + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getJournal);
  }
  getSalPurchaseItemTypeGlAssignment(data, id): Observable<any> {
    const getSalPurchaseItemTypeGlAssignment = URLS.getSalPurchaseItemTypeGlAssignment + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getSalPurchaseItemTypeGlAssignment);
  }
  
  closeCustomTimePeriods(id,cid): Observable<any> {
    const closeCustomTimePeriods = URLS.closeCustomTimePeriods + "?organizationPartyId=" + id + "&customTimePeriodId=" + cid;
    return this.http.post(closeCustomTimePeriods,'');
  }

  getTrialBalance(id,cid): Observable<any> {
    const getTrialBalance = URLS.getTrialBalance + "?organizationPartyId=" + id + "&customTimePeriodId=" + cid;
    return this.http.get(getTrialBalance);
  }
  taxAuthorityDetail(id,cid): Observable<any> {
    const taxAuthorityDetail = URLS.taxAuthorityDetail + "?taxAuthGeoId=" + id + "&taxAuthPartyId=" + cid;
    return this.http.get(taxAuthorityDetail);
  }
 
  allOpenCustomTimePeriodId(id): Observable<any> {
    const allOpenCustomTimePeriodId = URLS.allOpenCustomTimePeriodId + "?organizationPartyId=" + id ;
    return this.http.get(allOpenCustomTimePeriodId);
  }
  allCloseCustomTimePeriodId(id): Observable<any> {
    const allCloseCustomTimePeriodId = URLS.allCloseCustomTimePeriodId + "?organizationPartyId=" + id ;
    return this.http.get(allCloseCustomTimePeriodId);
  }
  getCustomTimePeriodListDetail(id): Observable<any> {
    const getCustomTimePeriodListDetail = URLS.getCustomTimePeriodListDetail + "?organizationPartyId=" + id ;
    return this.http.get(getCustomTimePeriodListDetail);
  }
  
  
  
  fixedAssetTypeGlAccount(data, id): Observable<any> {
    const fixedAssetTypeGlAccount = URLS.fixedAssetTypeGlAccount + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(fixedAssetTypeGlAccount);
  }
  
  getPartyGlAccount(data, id): Observable<any> {
    const getPartyGlAccount = URLS.getPartyGlAccount + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getPartyGlAccount);
  }
  getVarianceReasonGlAccounts(data, id): Observable<any> {
    const getVarianceReasonGlAccounts = URLS.getVarianceReasonGlAccounts + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getVarianceReasonGlAccounts);
  }
  
  getVisitorsDetails(data, id): Observable<any> {
    const getVisitorsDetails = URLS.getVisitorsDetails + "?visitId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getVisitorsDetails);
  }

  getCreditCardTypeGlAccounts(data, id): Observable<any> {
    const getCreditCardTypeGlAccounts = URLS.getCreditCardTypeGlAccounts + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCreditCardTypeGlAccounts);
  }
  
  
  getOrganizationMainChartOfAccount(data, id): Observable<any> {
    const getOrganizationMainChartOfAccount = URLS.getOrganizationMainChartOfAccount + "?organisationId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getOrganizationMainChartOfAccount);
  }
  getTaxAuthorityGlAccount(data, id): Observable<any> {
    const getTaxAuthorityGlAccount = URLS.getTaxAuthorityGlAccount + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getTaxAuthorityGlAccount);
  }
  
  createAddressMatchMap(form): Observable<any> {
    const createAddressMatchMap = URLS.createAddressMatchMap;
    return this.http.post(createAddressMatchMap,form);
  }
  addProtectedViewToSecurityGroup(form): Observable<any> {
    const addProtectedViewToSecurityGroup = URLS.addProtectedViewToSecurityGroup;
    return this.http.post(addProtectedViewToSecurityGroup,form);
  }
  
  getProductGlAccount(data, id): Observable<any> {
    const getProductGlAccount = URLS.getProductGlAccount + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProductGlAccount);
  }
  getProductCategoryGlAccount(data, id): Observable<any> {
    const getProductCategoryGlAccount = URLS.getProductCategoryGlAccount + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getProductCategoryGlAccount);
  }
  getGlAccountMethodTypePaymentType(data, id): Observable<any> {
    const getGlAccountMethodTypePaymentType = URLS.getGlAccountMethodTypePaymentType + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getGlAccountMethodTypePaymentType);
  }
  
  getAgreementIdSeqId(id): Observable<any> {
    const getAgreementIdSeqId = URLS.getAgreementIdSeqId + "?agreementId=" + id ;
    return this.http.get(getAgreementIdSeqId);
  }
  
  getListGlAccountDefault(data, id): Observable<any> {
    const getListGlAccountDefault = URLS.getListGlAccountDefault + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getListGlAccountDefault);
  }
  getFinAccountTypeGiAccount(data, id): Observable<any> {
    const getFinAccountTypeGiAccount = URLS.getFinAccountTypeGiAccount + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getFinAccountTypeGiAccount);
  }
  getGlAccountSalInvoice(data, id): Observable<any> {
    const getGlAccountSalInvoice = URLS.getGlAccountSalInvoice + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getGlAccountSalInvoice);
  }
  getpaymentType(data, id): Observable<any> {
    const getpaymentType = URLS.getpaymentType + "?organizationPartyId=" + id + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getpaymentType);
  }
  getTransDetailById(acctgTransEntrySeqId, acctgTransId): Observable<any> {
    const getTransDetailById = URLS.getTransDetailById + "?acctgTransEntrySeqId=" + acctgTransEntrySeqId + "&acctgTransId=" + acctgTransId;
    return this.http.get(getTransDetailById);
  }
  getBenefitTypeList(partyIdTo, roleTypeIdFrom): Observable<any> {
    const getBenefitTypeList = URLS.getBenefitTypeList + "?partyIdTo=" + partyIdTo + "&roleTypeIdFrom=" + roleTypeIdFrom;
    return this.http.get(getBenefitTypeList);
  }
  getUnemploymentClaimList(partyIdTo): Observable<any> {
    const getUnemploymentClaimList = URLS.getUnemploymentClaimList + "?partyIdTo=" + partyIdTo ;
    return this.http.get(getUnemploymentClaimList);
  }
  getUnemploymentClaim(): Observable<any> {
    const getUnemploymentClaim = URLS.getUnemploymentClaim;
    return this.http.get(getUnemploymentClaim);
  }
  getStatusItem(): Observable<any> {
    const getStatusItem = URLS.getStatusItem;
    return this.http.get(getStatusItem);
  }
  getPayGradeID(): Observable<any> {
    const getPayGradeID = URLS.getPayGradeID;
    return this.http.get(getPayGradeID);
  }
  getAgreementIDNew(): Observable<any> {
    const getAgreementIDNew = URLS.getAgreementIDNew;
    return this.http.get(getAgreementIDNew);
  }
  
  getSalaryStepSequenceID(): Observable<any> {
    const getSalaryStepSequenceID = URLS.getSalaryStepSequenceID;
    return this.http.get(getSalaryStepSequenceID);
  }
  
  getFinAccountTypeGiAccountInitialData(): Observable<any> {
    const getFinAccountTypeGiAccountInitialData = URLS.getFinAccountTypeGiAccountInitialData;
    return this.http.get(getFinAccountTypeGiAccountInitialData);
  }
  getInitialDataAccountTypeDefault(): Observable<any> {
    const getInitialDataAccountTypeDefault = URLS.getInitialDataAccountTypeDefault;
    return this.http.get(getInitialDataAccountTypeDefault);
  }
  getAccountCompanyInitialData(id): Observable<any> {
    const getAccountCompanyInitialData = URLS.getAccountCompanyInitialData+ "?organizationPartyId=" + id;
    return this.http.get(getAccountCompanyInitialData);
  }
  getInitialDataFixedData(id): Observable<any> {
    const getInitialDataFixedData = URLS.getInitialDataFixedData+ "?organizationPartyId=" + id;
    return this.http.get(getInitialDataFixedData);
  }
  getPartyClassificationGroupById(id): Observable<any> {
    const getPartyClassificationGroupById = URLS.getPartyClassificationGroupById+ "?classificationGroupId=" + id;
    return this.http.get(getPartyClassificationGroupById);
  }
  
  getAccountCompanyDeatilById(id): Observable<any> {
    const getAccountCompanyDeatilById = URLS.getAccountCompanyDeatilById+ "?partyId=" + id;
    return this.http.get(getAccountCompanyDeatilById);
  }
  
  
  
  
  financialOrderId(data, req): Observable<any> {
    const financialOrderId = URLS.financialOrderId + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(financialOrderId, req);
  }
  taxAuthorityGlAccountInitialFormData(data): Observable<any> {
    const taxAuthorityGlAccountInitialFormData = URLS.taxAuthorityGlAccountInitialFormData + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(taxAuthorityGlAccountInitialFormData);
  }
  
  removeGlAccountTypeDefault(req): Observable<any> {
    const removeGlAccountTypeDefault = URLS.removeGlAccountTypeDefault;
    return this.http.post(removeGlAccountTypeDefault, req);
  }
  createFixedAssetTypeGlAccounts(req): Observable<any> {
    const createFixedAssetTypeGlAccounts = URLS.createFixedAssetTypeGlAccounts;
    return this.http.post(createFixedAssetTypeGlAccounts, req);
  }
  postBudget(req): Observable<any> {
    const postBudget = URLS.postBudget;
    return this.http.post(postBudget,req);
  }
  
  createOtherRelationship(req): Observable<any> {
    const createOtherRelationship = URLS.createOtherRelationship;
    return this.http.post(createOtherRelationship,req);
  }
  updatetBudget(req): Observable<any> {
    const postBudget = URLS.postBudget;
    return this.http.put(postBudget,req);
  }
  updateProtectedViewToSecurityGroup(req): Observable<any> {
    const updateProtectedViewToSecurityGroup = URLS.updateProtectedViewToSecurityGroup;
    return this.http.put(updateProtectedViewToSecurityGroup,req);
  }
  
  posttransactionEntries(req): Observable<any> {
    const posttransactionEntries = URLS.posttransactionEntries;
    return this.http.post(posttransactionEntries, req);
  }
  puttransactionEntries(req): Observable<any> {
    const puttransactionEntries = URLS.posttransactionEntries;
    return this.http.put(puttransactionEntries, req);
  }
  
  
  updateOrganizationTaxAuthorityGlAccounts(req): Observable<any> {
    const updateOrganizationTaxAuthorityGlAccounts = URLS.updateOrganizationTaxAuthorityGlAccounts;
    return this.http.put(updateOrganizationTaxAuthorityGlAccounts, req);
  }
  
  createOrganizationTaxAuthorityGlAccount(req): Observable<any> {
    const createOrganizationTaxAuthorityGlAccount = URLS.createOrganizationTaxAuthorityGlAccount;
    return this.http.post(createOrganizationTaxAuthorityGlAccount, req);
  }
  addPurInvoiceItemTypeGlAssignments(req): Observable<any> {
    const addPurInvoiceItemTypeGlAssignments = URLS.addPurInvoiceItemTypeGlAssignments;
    return this.http.post(addPurInvoiceItemTypeGlAssignments, req);
  }
  
  
  deleteOrganizationTaxAuthorityGlAccounts(req): Observable<any> {
    const deleteOrganizationTaxAuthorityGlAccounts = URLS.deleteOrganizationTaxAuthorityGlAccounts;
    return this.http.post(deleteOrganizationTaxAuthorityGlAccounts, req);
  }
  deletePartyGlAccount(req): Observable<any> {
    const deletePartyGlAccount = URLS.deletePartyGlAccount;
    return this.http.post(deletePartyGlAccount, req);
  }
  deleteFixedAssetTypeGlAccounts(req): Observable<any> {
    const deleteFixedAssetTypeGlAccounts = URLS.deleteFixedAssetTypeGlAccounts;
    return this.http.post(deleteFixedAssetTypeGlAccounts, req);
  }
  createCustomTimePeriods(req): Observable<any> {
    const createCustomTimePeriods = URLS.createCustomTimePeriods;
    return this.http.post(createCustomTimePeriods, req);
  }
  
  
  
  createPartyGlAccounts(req): Observable<any> {
    const createPartyGlAccounts = URLS.createPartyGlAccounts;
    return this.http.post(createPartyGlAccounts, req);
  }
  updatePartyGlAccounts(req): Observable<any> {
    const updatePartyGlAccounts = URLS.updatePartyGlAccounts;
    return this.http.put(updatePartyGlAccounts, req);
  }
  
  
  allPartyGlAccountFormData(): Observable<any> {
    const allPartyGlAccountFormData = URLS.allPartyGlAccountFormData;
    return this.http.get(allPartyGlAccountFormData);
  }
  
  
  
  createGlAccountTypeDefault(req): Observable<any> {
    const createGlAccountTypeDefault = URLS.createGlAccountTypeDefault;
    return this.http.post(createGlAccountTypeDefault, req);
  }
  deletepaymentType(req): Observable<any> {
    const deletepaymentType = URLS.deletepaymentType;
    return this.http.post(deletepaymentType, req);
  }
  
  createFixedAssetMaintUpdateWorkEffort(req): Observable<any> {
    const createFixedAssetMaintUpdateWorkEffort = URLS.createFixedAssetMaintUpdateWorkEffort;
    return this.http.post(createFixedAssetMaintUpdateWorkEffort, req);
  }
  updateFixedAssetMaintUpdateWorkEffort(req): Observable<any> {
    const updateFixedAssetMaintUpdateWorkEffort = URLS.updateFixedAssetMaintUpdateWorkEffort;
    return this.http.post(updateFixedAssetMaintUpdateWorkEffort, req);
  }
  
  returnPartIssuedToFixedAssetMaint(req): Observable<any> {
    const returnPartIssuedToFixedAssetMaint = URLS.returnPartIssuedToFixedAssetMaint;
    return this.http.post(returnPartIssuedToFixedAssetMaint, req);
  }
  
  deleteWorkEffortTimeEntry(req): Observable<any> {
    const deleteWorkEffortTimeEntry = URLS.deleteWorkEffortTimeEntry;
    return this.http.post(deleteWorkEffortTimeEntry, req);
  }
  
  createWorkEffortTimeEntrySave(req): Observable<any> {
    const createWorkEffortTimeEntrySave = URLS.createWorkEffortTimeEntrySave;
    return this.http.post(createWorkEffortTimeEntrySave, req);
  }
  
  createFixedAssetMaintOrder(req): Observable<any> {
    const createFixedAssetMaintOrder = URLS.createFixedAssetMaintOrder;
    return this.http.post(createFixedAssetMaintOrder, req);
  }
  
  deleteFixedAssetMaintOrder(req): Observable<any> {
    const deleteFixedAssetMaintOrder = URLS.deleteFixedAssetMaintOrder;
    return this.http.post(deleteFixedAssetMaintOrder, req);
  }
  
  getFindConfigItem(req): Observable<any> {
    const getFindConfigItem = URLS.getFindConfigItem;
    return this.http.post(getFindConfigItem, req);
  }
  
  deleteProductMaint(req): Observable<any> {
    const deleteProductMaint = URLS.deleteProductMaint;
    return this.http.post(deleteProductMaint, req);
  }
  
  updateProductMaint(req): Observable<any> {
    const updateProductMaint = URLS.updateProductMaint;
    return this.http.post(updateProductMaint, req);
  }
  createProductMaint(req): Observable<any> {
    const createProductMaint = URLS.createProductMaint;
    return this.http.post(createProductMaint, req);
  }
  
  createWorkEffortPartyAssignSave(req): Observable<any> {
    const createWorkEffortPartyAssignSave = URLS.createWorkEffortPartyAssignSave;
    return this.http.post(createWorkEffortPartyAssignSave, req);
  }
  
  updateWorkEffortPartyAssignAsset(req): Observable<any> {
    const updateWorkEffortPartyAssignAsset = URLS.updateWorkEffortPartyAssignAsset;
    return this.http.post(updateWorkEffortPartyAssignAsset, req);
  }
  
  updateWorkEffortTimeEntry(req): Observable<any> {
    const updateWorkEffortTimeEntry = URLS.updateWorkEffortTimeEntry;
    return this.http.post(updateWorkEffortTimeEntry, req);
  }
  
  updateWorkEffortAsset(req): Observable<any> {
    const updateWorkEffortAsset = URLS.updateWorkEffortAsset;
    return this.http.post(updateWorkEffortAsset, req);
  }
  
  createProductPromo(req): Observable<any> {
    const createProductPromo = URLS.createProductPromo;
    return this.http.post(createProductPromo, req);
  }
  

  updateICalendarFixedAssetAssign(req): Observable<any> {
    const updateICalendarFixedAssetAssign = URLS.updateICalendarFixedAssetAssign;
    return this.http.post(updateICalendarFixedAssetAssign, req);
  }
  
  createProductPriceRule(req): Observable<any> {
    const createProductPriceRule = URLS.createProductPriceRule;
    return this.http.post(createProductPriceRule, req);
  }
  
  updateProductPromo(req): Observable<any> {
    const updateProductPromo = URLS.updateProductPromo;
    return this.http.post(updateProductPromo, req);
  }
  
  findFacilities(req): Observable<any> {
    const findFacilities = URLS.findFacilities;
    return this.http.post(findFacilities, req);
  }
  
  issueInventoryItemToFixedAssetMaint(req): Observable<any> {
    const issueInventoryItemToFixedAssetMaint = URLS.issueInventoryItemToFixedAssetMaint;
    return this.http.post(issueInventoryItemToFixedAssetMaint, req);
  }
  
  findFixedAssetMaints(req): Observable<any> {
    const findFixedAssetMaints = URLS.findFixedAssetMaints;
    return this.http.post(findFixedAssetMaints, req);
  }
  
  createContactListCommEvent(req): Observable<any> {
    const createContactListCommEvent = URLS.createContactListCommEvent;
    return this.http.post(createContactListCommEvent, req);
  }
  
  updateContactListCommEvent(req): Observable<any> {
    const updateContactListCommEvent = URLS.updateContactListCommEvent;
    return this.http.post(updateContactListCommEvent, req);
  }
  deleteCreditCardTypeGlAccounts(req): Observable<any> {
    const deleteCreditCardTypeGlAccounts = URLS.deleteCreditCardTypeGlAccounts;
    return this.http.post(deleteCreditCardTypeGlAccounts, req);
  }
  
  
  updateFinAccountTypeGiAccount(req): Observable<any> {
    const updateFinAccountTypeGiAccount = URLS.updateFinAccountTypeGiAccount;
    return this.http.put(updateFinAccountTypeGiAccount, req);
  }
  removeFinAccountTypeGiAccount(req): Observable<any> {
    const removeFinAccountTypeGiAccount = URLS.removeFinAccountTypeGiAccount;
    return this.http.post(removeFinAccountTypeGiAccount, req);
  }
  deleteVarianceReasonGlAccounts(req): Observable<any> {
    const deleteVarianceReasonGlAccounts = URLS.deleteVarianceReasonGlAccounts;
    return this.http.post(deleteVarianceReasonGlAccounts, req);
  }
  
  deleteFixedAssetRegistration(req): Observable<any> {
    const deleteFixedAssetRegistration = URLS.deleteFixedAssetRegistration;
    return this.http.post(deleteFixedAssetRegistration, req);
  }
  
  deleteAssignmentList(req): Observable<any> {
    const deleteAssignmentList = URLS.deleteAssignmentList;
    return this.http.post(deleteAssignmentList, req);
  }
  
  
  createFinAccountTypeGiAccount(req): Observable<any> {
    const createFinAccountTypeGiAccount = URLS.createFinAccountTypeGiAccount;
    return this.http.post(createFinAccountTypeGiAccount, req);
  }
  
  updateFixedAssetMeter(req): Observable<any> {
    const updateFixedAssetMeter = URLS.updateFixedAssetMeter;
    return this.http.post(updateFixedAssetMeter, req);
  }
  
  deleteFixedAssetDepMethod(req): Observable<any> {
    const deleteFixedAssetDepMethod = URLS.deleteFixedAssetDepMethod;
    return this.http.post(deleteFixedAssetDepMethod, req);
  }
  createFixedAssetMeter(req): Observable<any> {
    const createFixedAssetMeter = URLS.createFixedAssetMeter;
    return this.http.post(createFixedAssetMeter, req);
  }
  
  createFixedAssetDepMethod(req): Observable<any> {
    const createFixedAssetDepMethod = URLS.createFixedAssetDepMethod;
    return this.http.post(createFixedAssetDepMethod, req);
  }
  
  createWorkEffortNote(req): Observable<any> {
    const createWorkEffortNote = URLS.createWorkEffortNote;
    return this.http.post(createWorkEffortNote, req);
  }
  deleteFixedAssetMeter(req): Observable<any> {
    const deleteFixedAssetMeter = URLS.deleteFixedAssetMeter;
    return this.http.post(deleteFixedAssetMeter, req);
  }
  
  
  getGlAccountCategories(data, req): Observable<any> {
    const getGlAccountCategories = URLS.getGlAccountCategories + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getGlAccountCategories, req);
  }

  searchDepositOrWithdraw(data, req): Observable<any> {
    const searchDepositOrWithdraw = URLS.searchDepositOrWithdraw + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(searchDepositOrWithdraw, req);
  }
  searchFixedAssets(data, req): Observable<any> {
    const searchFixedAssets = URLS.searchFixedAssets + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(searchFixedAssets, req);
  }
  getProducts(data, req): Observable<any> {
    const getProducts = URLS.getProducts + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getProducts, req);
  }
  getVendors(data, req): Observable<any> {
    const getVendors = URLS.getVendors + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getVendors, req);
  }

  getCustomTimePeriod(data): Observable<any> {
    const getCustomTimePeriod = URLS.getCustomTimePeriod + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getCustomTimePeriod);
  }
  initialTransData(): Observable<any> {
    const initialTransData = URLS.initialTransData;
    return this.http.get(initialTransData);
  }
  
  getSetCurrentCustomTimePeriod(data, id): Observable<any> {
    const getCustomTimePeriod = URLS.getCustomTimePeriod + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&parentPeriodId=" + id;
    return this.http.get(getCustomTimePeriod);
  }
  findReconciliationsAccount(data, id,party): Observable<any> {
    const findReconciliationsAccount = URLS.findReconciliationsAccount + "/" + party + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&glAccountId=" + id;
    return this.http.get(findReconciliationsAccount);
  }
  
  getTrackingCodeById(party): Observable<any> {
    const getTrackingCodeById = URLS.getTrackingCodeById + "/" + party;
    return this.http.get(getTrackingCodeById);
  }
  
  getStandardCostList(party): Observable<any> {
    const getStandardCostList = URLS.getStandardCostList + "/" + party;
    return this.http.get(getStandardCostList);
  }
  
  getIdentificationsType(): Observable<any> {
    const getIdentificationsType = URLS.getIdentificationsType;
    return this.http.get(getIdentificationsType);
  }
  
  getIdentificationsList(party): Observable<any> {
    const getIdentificationsList = URLS.getIdentificationsList + "/" + party;
    return this.http.get(getIdentificationsList);
  }
  
  getTimeEntryList(facilityid,maintHistSeqId,workEffortId): Observable<any> {
    const getTimeEntryList = URLS.getTimeEntryList + "/" + facilityid + "/" + maintHistSeqId + "/" + workEffortId;
    return this.http.get(getTimeEntryList);
  }
  
  getAssignmentList(party): Observable<any> {
    const getAssignmentList = URLS.getAssignmentList + "/" + party;
    return this.http.get(getAssignmentList);
  }
  
  getActiveOrInActive_All(party): Observable<any> {
    const getActiveOrInActive_All = URLS.getActiveOrInActive_All + "/" + party;
    return this.http.get(getActiveOrInActive_All);
  }
  
  getProductStoreShipmentSetupById(party): Observable<any> {
    const getProductStoreShipmentSetupById = URLS.getProductStoreShipmentSetupById + "/" + party;
    return this.http.get(getProductStoreShipmentSetupById);
  }
  
  getProductStoreShipMethId(party): Observable<any> {
    const getProductStoreShipMethId = URLS.getProductStoreShipMethId + "/" + party;
    return this.http.get(getProductStoreShipMethId);
  }
  
  getProductStoreShipmentEstiById(party): Observable<any> {
    const getProductStoreShipmentEstiById = URLS.getProductStoreShipmentEstiById + "/" + party;
    return this.http.get(getProductStoreShipmentEstiById);
  }
  
  getStorePromoList(): Observable<any> {
    const getStorePromoList = URLS.getStorePromoList;
    return this.http.get(getStorePromoList);
  }
  
  getMeterReadingList(party): Observable<any> {
    const getMeterReadingList = URLS.getMeterReadingList + "/" + party;
    return this.http.get(getMeterReadingList);
  }
  
  getDepreciationCustomMethodList(party): Observable<any> {
    const getDepreciationCustomMethodList = URLS.getDepreciationCustomMethodList + "/" + party;
    return this.http.get(getDepreciationCustomMethodList);
  }
  

  
  getGeoLocation(party): Observable<any> {
    const getGeoLocation = URLS.getGeoLocation + "/" + party;
    return this.http.get(getGeoLocation);
  }
  
  getFixedAssetChildren(party): Observable<any> {
    const getFixedAssetChildren = URLS.getFixedAssetChildren + "/" + party;
    return this.http.get(getFixedAssetChildren);
  }
  
  getProductVendorStoreShipmentListById(party): Observable<any> {
    const getProductVendorStoreShipmentListById = URLS.getProductVendorStoreShipmentListById + "/" + party;
    return this.http.get(getProductVendorStoreShipmentListById);
  }
  
  getProductSurveyListById(party): Observable<any> {
    const getProductSurveyListById = URLS.getProductSurveyListById + "/" + party;
    return this.http.get(getProductSurveyListById);
  }
  
    
  getStoreFacilitySettingById(party): Observable<any> {
    const getStoreFacilitySettingById = URLS.getStoreFacilitySettingById + "/" + party;
    return this.http.get(getStoreFacilitySettingById);
  }
  
  getProductStoreFinAccountSettingsListById(party): Observable<any> {
    const getProductStoreFinAccountSettingsListById = URLS.getProductStoreFinAccountSettingsListById + "/" + party;
    return this.http.get(getProductStoreFinAccountSettingsListById);
  }
  
  getProductStoreEmailSetupById(party): Observable<any> {
    const getProductStoreEmailSetupById = URLS.getProductStoreEmailSetupById + "/" + party;
    return this.http.get(getProductStoreEmailSetupById);
  }
  
  getProductStorePaymentListById(party): Observable<any> {
    const getProductStorePaymentListById = URLS.getProductStorePaymentListById + "/" + party;
    return this.http.get(getProductStorePaymentListById);
  }
  
  getProductStoreCatalog(party): Observable<any> {
    const getProductStoreCatalog = URLS.getProductStoreCatalog + "/" + party;
    return this.http.get(getProductStoreCatalog);
  }
  
  getProductStoreKeywordOvrdById(party): Observable<any> {
    const getProductStoreKeywordOvrdById = URLS.getProductStoreKeywordOvrdById + "/" + party;
    return this.http.get(getProductStoreKeywordOvrdById);
  }
  
  getProductStoreVendorPaymentListById(party): Observable<any> {
    const getProductStoreVendorPaymentListById = URLS.getProductStoreVendorPaymentListById + "/" + party;
    return this.http.get(getProductStoreVendorPaymentListById);
  }
  getListFixedAssetMaints(party): Observable<any> {
    const getListFixedAssetMaints = URLS.getListFixedAssetMaints + "?fixedAssetId=" + party;
    return this.http.get(getListFixedAssetMaints);
  }
  
  
  getDepreciationCustomMethod(): Observable<any> {
    const getDepreciationCustomMethod = URLS.getDepreciationCustomMethod;
    return this.http.get(getDepreciationCustomMethod);
  }
  
  getShipmentMethodTypeIdList(): Observable<any> {
    const getShipmentMethodTypeIdList = URLS.getShipmentMethodTypeIdList;
    return this.http.get(getShipmentMethodTypeIdList);
  }
  
  getCreditCardEnumIdList(): Observable<any> {
    const getCreditCardEnumIdList = URLS.getCreditCardEnumIdList;
    return this.http.get(getCreditCardEnumIdList);
  }
  
  getPaymentMethodTypeList(): Observable<any> {
    const getPaymentMethodTypeList = URLS.getPaymentMethodTypeList;
    return this.http.get(getPaymentMethodTypeList);
  }

  
  getSurveyListValue(): Observable<any> {
    const getSurveyListValue = URLS.getSurveyListValue;
    return this.http.get(getSurveyListValue);
  }
  
  getSurveyTypeList(): Observable<any> {
    const getSurveyTypeList = URLS.getSurveyTypeList;
    return this.http.get(getSurveyTypeList);
  }
  
  getFacilityIdListValue(): Observable<any> {
    const getFacilityIdListValue = URLS.getFacilityIdListValue;
    return this.http.get(getFacilityIdListValue);
  }
  
  getFinAccountTypeIdList(): Observable<any> {
    const getFinAccountTypeIdList = URLS.getFinAccountTypeIdList;
    return this.http.get(getFinAccountTypeIdList);
  }
  
  getReplenishEnumTypeIdList(): Observable<any> {
    const getReplenishEnumTypeIdList = URLS.getReplenishEnumTypeIdList;
    return this.http.get(getReplenishEnumTypeIdList);
  }
  getEmailTypeList(): Observable<any> {
    const getEmailTypeList = URLS.getEmailTypeList;
    return this.http.get(getEmailTypeList);
  }
  
  getPaymentMethodTypeListData(): Observable<any> {
    const getPaymentMethodTypeListData = URLS.getPaymentMethodTypeListData;
    return this.http.get(getPaymentMethodTypeListData);
  }
  
  getServiceTypeList(): Observable<any> {
    const getServiceTypeList = URLS.getServiceTypeList;
    return this.http.get(getServiceTypeList);
  }
  
  getCatalogList(): Observable<any> {
    const getCatalogList = URLS.getCatalogList;
    return this.http.get(getCatalogList);
  }
  
  getPaymentGatewayConfigIdList(): Observable<any> {
    const getPaymentGatewayConfigIdList = URLS.getPaymentGatewayConfigIdList;
    return this.http.get(getPaymentGatewayConfigIdList);
  }
  getCustomMethodListPayment(): Observable<any> {
    const getCustomMethodListPayment = URLS.getCustomMethodListPayment;
    return this.http.get(getCustomMethodListPayment);
  }
  
  postAssignmentList(party,req): Observable<any> {
    const postAssignmentList = URLS.postAssignmentList + "/" + party;
    return this.http.get(postAssignmentList,req);
  }
  
  deleteProductStoreVendorShipment(req): Observable<any> {
    const deleteProductStoreVendorShipment = URLS.deleteProductStoreVendorShipment;
    return this.http.post(deleteProductStoreVendorShipment,req);
  }
  
   
  createProductStorePromoAppl(req): Observable<any> {
    const createProductStorePromoAppl = URLS.createProductStorePromoAppl;
    return this.http.post(createProductStorePromoAppl,req);
  }
  
  updateProductStorePromoAppl(req): Observable<any> {
    const updateProductStorePromoAppl = URLS.updateProductStorePromoAppl;
    return this.http.post(updateProductStorePromoAppl,req);
  }
  
  storeRemoveShipRate(req): Observable<any> {
    const storeRemoveShipRate = URLS.storeRemoveShipRate;
    return this.http.post(storeRemoveShipRate,req);
  }
  
  storeRemovePaySetting(req): Observable<any> {
    const storeRemovePaySetting = URLS.storeRemovePaySetting;
    return this.http.post(storeRemovePaySetting,req);
  }
  
  deleteProductStoreVendorPayment(req): Observable<any> {
    const deleteProductStoreVendorPayment = URLS.deleteProductStoreVendorPayment;
    return this.http.post(deleteProductStoreVendorPayment,req);
  }
  
  updateProductStoreEmail(req): Observable<any> {
    const updateProductStoreEmail = URLS.updateProductStoreEmail;
    return this.http.post(updateProductStoreEmail,req);
  }
  createProductStoreEmail(req): Observable<any> {
    const createProductStoreEmail = URLS.createProductStoreEmail;
    return this.http.post(createProductStoreEmail,req);
  }
  
  storeCreatePaySetting(req): Observable<any> {
    const storeCreatePaySetting = URLS.storeCreatePaySetting;
    return this.http.post(storeCreatePaySetting,req);
  }
  
  deleteProductStoreCatalog(req): Observable<any> {
    const deleteProductStoreCatalog = URLS.deleteProductStoreCatalog;
    return this.http.post(deleteProductStoreCatalog,req);
  }
  
  storeCreateRole(req): Observable<any> {
    const storeCreateRole = URLS.storeCreateRole;
    return this.http.post(storeCreateRole,req);
  }
  
  storeUpdateRole(req): Observable<any> {
    const storeUpdateRole = URLS.storeUpdateRole;
    return this.http.post(storeUpdateRole,req);
  }
  
  storeRemoveRole(req): Observable<any> {
    const storeRemoveRole = URLS.storeRemoveRole;
    return this.http.post(storeRemoveRole,req);
  }
  
  findProductStore(req): Observable<any> {
    const findProductStore = URLS.findProductStore;
    return this.http.post(findProductStore,req);
  }
  storeUpdatePaySetting(req): Observable<any> {
    const storeUpdatePaySetting = URLS.storeUpdatePaySetting;
    return this.http.post(storeUpdatePaySetting,req);
  }
  
  createProductStoreCatalog(req): Observable<any> {
    const createProductStoreCatalog = URLS.createProductStoreCatalog;
    return this.http.post(createProductStoreCatalog,req);
  }
  
  updateProductStoreCatalog(req): Observable<any> {
    const updateProductStoreCatalog = URLS.updateProductStoreCatalog;
    return this.http.post(updateProductStoreCatalog,req);
  }
  deleteProductStoreSurveyAppl(req): Observable<any> {
    const deleteProductStoreSurveyAppl = URLS.deleteProductStoreSurveyAppl;
    return this.http.post(deleteProductStoreSurveyAppl,req);
  }
  
  deleteProductStoreKeywordOvrd(req): Observable<any> {
    const deleteProductStoreKeywordOvrd = URLS.deleteProductStoreKeywordOvrd;
    return this.http.post(deleteProductStoreKeywordOvrd,req);
  }
  
  deleteSegmentGroupById(req): Observable<any> {
    const deleteSegmentGroupById = URLS.deleteSegmentGroupById;
    return this.http.post(deleteSegmentGroupById,req);
  }
  
  deleteProductStoreFacility(req): Observable<any> {
    const deleteProductStoreFacility = URLS.deleteProductStoreFacility;
    return this.http.post(deleteProductStoreFacility,req);
  }
  
  RemoveProductStoreFinAccountSettings(req): Observable<any> {
    const RemoveProductStoreFinAccountSettings = URLS.RemoveProductStoreFinAccountSettings;
    return this.http.post(RemoveProductStoreFinAccountSettings,req);
  }
  
  removeProductStoreEmail(req): Observable<any> {
    const removeProductStoreEmail = URLS.removeProductStoreEmail;
    return this.http.post(removeProductStoreEmail,req);
  }
  
  createProductStoreVendorPayment(req): Observable<any> {
    const createProductStoreVendorPayment = URLS.createProductStoreVendorPayment;
    return this.http.post(createProductStoreVendorPayment,req);
  }
  createProductStoreVendorShipment(req): Observable<any> {
    const createProductStoreVendorShipment = URLS.createProductStoreVendorShipment;
    return this.http.post(createProductStoreVendorShipment,req);
  }
  
  putAssignmentList(party,req): Observable<any> {
    const putAssignmentList = URLS.putAssignmentList + "/" + party;
    return this.http.put(putAssignmentList,req);
  }
  
  getFixedAssetByWorkEffortId(party): Observable<any> {
    const getFixedAssetByWorkEffortId = URLS.getFixedAssetByWorkEffortId + "/" + party;
    return this.http.get(getFixedAssetByWorkEffortId);
  }
  
  getProductPromotionList(): Observable<any> {
    const getProductPromotionList = URLS.getProductPromotionList;
    return this.http.get(getProductPromotionList);
  }
  
  getPriceRuleList(): Observable<any> {
    const getPriceRuleList = URLS.getPriceRuleList;
    return this.http.get(getPriceRuleList);
  }
  
  getProductByProductPromoId(party): Observable<any> {
    const getProductByProductPromoId = URLS.getProductByProductPromoId + "/" + party;
    return this.http.get(getProductByProductPromoId);
  }
  getFixedAssetRegistrationList(party): Observable<any> {
    const getFixedAssetRegistrationList = URLS.getFixedAssetRegistrationList + "/" + party;
    return this.http.get(getFixedAssetRegistrationList);
  }
  fixedAssetProduct(party): Observable<any> {
    const fixedAssetProduct = URLS.fixedAssetProduct + "/" + party;
    return this.http.get(fixedAssetProduct);
  }
  
  postfixedAssetProduct(party,req): Observable<any> {
    const postfixedAssetProduct = URLS.postfixedAssetProduct + "/" + party;
    return this.http.post(postfixedAssetProduct,req);
  }
  
  updateFixedAssetStdCost(req): Observable<any> {
    const updateFixedAssetStdCost = URLS.updateFixedAssetStdCost;
    return this.http.post(updateFixedAssetStdCost,req);
  }
  
  createFixedAssetIdent(req): Observable<any> {
    const createFixedAssetIdent = URLS.createFixedAssetIdent;
    return this.http.post(createFixedAssetIdent,req);
  }
  
  updateFixedAssetIdent(req): Observable<any> {
    const updateFixedAssetIdent = URLS.updateFixedAssetIdent;
    return this.http.post(updateFixedAssetIdent,req);
  }
  
  updateFixedAssetRegistration(req): Observable<any> {
    const updateFixedAssetRegistration = URLS.updateFixedAssetRegistration;
    return this.http.post(updateFixedAssetRegistration,req);
  }
  createFixedAssetRegistration(req): Observable<any> {
    const createFixedAssetRegistration = URLS.createFixedAssetRegistration;
    return this.http.post(createFixedAssetRegistration,req);
  }
  createFixedAssetStdCost(req): Observable<any> {
    const createFixedAssetStdCost = URLS.createFixedAssetStdCost;
    return this.http.post(createFixedAssetStdCost,req);
  }
  getStandardCostType(): Observable<any> {
    const getStandardCostType = URLS.getStandardCostType;
    return this.http.get(getStandardCostType);
  }
  cancelFixedAssetStdCost(req): Observable<any> {
    const cancelFixedAssetStdCost = URLS.cancelFixedAssetStdCost;
    return this.http.post(cancelFixedAssetStdCost,req);
  }
  
  putfixedAssetProduct(party,req): Observable<any> {
    const putfixedAssetProduct = URLS.putfixedAssetProduct + "/" + party;
    return this.http.put(putfixedAssetProduct,req);
  }
  
  removeFixedAssetIdent(req): Observable<any> {
    const removeFixedAssetIdent = URLS.removeFixedAssetIdent;
    return this.http.post(removeFixedAssetIdent,req);
  }
  PostfindReconciliationsAccount(data, id,party,req): Observable<any> {
    const PostfindReconciliationsAccount = URLS.PostfindReconciliationsAccount + "/" + party + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&glAccountId=" + id;
    return this.http.post(PostfindReconciliationsAccount,req);
  }
  
  
  getAllDocTypeTemplate(data, id): Observable<any> {
    const getAllDocTypeTemplate = URLS.getAllDocTypeTemplate + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&partyId=" + id;
    return this.http.get(getAllDocTypeTemplate);
  }
  
  getShoppingList(data,id): Observable<any> {
    const getShoppingList = URLS.getShoppingList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&partyId=" + id;;
    return this.http.get(getShoppingList);
  }
  
  getEditFixedAssetMaint(data,id): Observable<any> {
    const getEditFixedAssetMaint = URLS.getEditFixedAssetMaint + "?fixedAssetId=" + data + "&maintHistSeqId=" + id ;
    return this.http.get(getEditFixedAssetMaint);
  }
  
  getShoppingListById(id): Observable<any> {
    const getShoppingListById = URLS.getShoppingListById + "?ShoppingListId=" + id;
    return this.http.get(getShoppingListById);
  }
  
  getPaymentMethodTypeGlSetting(data): Observable<any> {
    const getPaymentMethodTypeGlSetting = URLS.getPaymentMethodTypeGlSetting + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getPaymentMethodTypeGlSetting);
  }

  getInvoiceItemType(data): Observable<any> {
    const getInvoiceItemType = URLS.getInvoiceItemType + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getInvoiceItemType);
  }
  getGlCost(data): Observable<any> {
    const getGlCost = URLS.getGlCost + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getGlCost);
  }
  getGlSetingsRateList(data): Observable<any> {
    const getGlSetingsRateList = URLS.getGlSetingsRateList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getGlSetingsRateList);
  }
  deleteglAccountCategoryMember(glAccountCategoryId, glAccountId, fromDate): Observable<any> {
    const deleteglAccountCategoryMember = URLS.deleteglAccountCategoryMember.replace(":glAccountCategoryId", glAccountCategoryId) + "/" + glAccountId + "?fromDate=" + fromDate;
    return this.http.delete(deleteglAccountCategoryMember);
  }


  getForeignExchangeRates(data): Observable<any> {
    const getForeignExchangeRates = URLS.getForeignExchangeRates + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getForeignExchangeRates);
  }
  
  getWorkEffortNoteById(id,workId): Observable<any> {
    const getWorkEffortNoteById = URLS.getWorkEffortNoteById + "?fixedAssetId=" + id + "&workEffortId=" + workId;
    return this.http.get(getWorkEffortNoteById);
  }
  
  lookupProduct(data): Observable<any> {
    const lookupProduct = URLS.lookupProduct + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(lookupProduct);
  }
  updateForeignExchangeRates(data): Observable<any> {
    const getForeignExchangeRates = URLS.getForeignExchangeRates;
    return this.http.post(getForeignExchangeRates, data);
  }
  
  addItemToShoppingList(data): Observable<any> {
    const addItemToShoppingList = URLS.addItemToShoppingList;
    return this.http.post(addItemToShoppingList, data);
  }
  postVendors(data): Observable<any> {
    const postVendors = URLS.postVendors;
    return this.http.post(postVendors, data);
  }
  updateVendors(data): Observable<any> {
    const postVendors = URLS.postVendors;
    return this.http.post(postVendors, data);
  }
  
  getShoppingListTypeIdList(): Observable<any> {
    const getShoppingListTypeIdList = URLS.getShoppingListTypeIdList;
    return this.http.get(getShoppingListTypeIdList);
  }
  updateShoppingList(data): Observable<any> {
    const updateShoppingList = URLS.updateShoppingList;
    return this.http.put(updateShoppingList, data);
  }


  postGlAccountCategories(data): Observable<any> {
    const postGlAccountCategories = URLS.postGlAccountCategories;
    return this.http.post(postGlAccountCategories, data);
  }
  updateGlAccountCategories(data): Observable<any> {
    const postGlAccountCategories = URLS.postGlAccountCategories;
    return this.http.put(postGlAccountCategories, data);
  }

  postGlSetingsRateList(data): Observable<any> {
    const getGlSetingsRateList = URLS.getGlSetingsRateList;
    return this.http.post(getGlSetingsRateList, data);
  }
  batchDepositWithdrawPayments(data): Observable<any> {
    const batchDepositWithdrawPayments = URLS.batchDepositWithdrawPayments;
    return this.http.post(batchDepositWithdrawPayments, data);
  }

  deleteGlSetingsRateList(data): Observable<any> {
    const deleteGlSetingsRateList = URLS.deleteGlSetingsRateList;
    return this.http.post(deleteGlSetingsRateList, data);
  }
  postGlCost(data): Observable<any> {
    const getGlCost = URLS.getGlCost;
    return this.http.post(getGlCost, data);
  }
  createPartyBenefit(data): Observable<any> {
    const createPartyBenefit = URLS.createPartyBenefit;
    return this.http.post(createPartyBenefit, data);
  }

  createCreditCardTypeGlAccounts(data): Observable<any> {
    const createCreditCardTypeGlAccounts = URLS.createCreditCardTypeGlAccounts;
    return this.http.post(createCreditCardTypeGlAccounts, data);
  }
  
  updateInvoiceItemType(data): Observable<any> {
    const getInvoiceItemType = URLS.getInvoiceItemType;
    return this.http.put(getInvoiceItemType, data);
  }
  updateCreditCardTypeGlAccounts(data): Observable<any> {
    const updateCreditCardTypeGlAccounts = URLS.updateCreditCardTypeGlAccounts;
    return this.http.put(updateCreditCardTypeGlAccounts, data);
  }
  
  updateGlCost(data): Observable<any> {
    const getGlCost = URLS.getGlCost;
    return this.http.put(getGlCost, data);
  }
  updatePaymentMethodTypeGlSetting(data): Observable<any> {
    const updatePaymentMethodTypeGlSetting = URLS.updatePaymentMethodTypeGlSetting;
    return this.http.put(updatePaymentMethodTypeGlSetting, data);
  }

  
  getCardTypeAccounts(): Observable<any> {
    const getCardTypeAccounts = URLS.getCardTypeAccounts;
    return this.http.get(getCardTypeAccounts);
  }
  

  getAllResidentStatusEnumId(): Observable<any> {
    const getAllResidentStatusEnumId = URLS.getAllResidentStatusEnumId;
    return this.http.get(getAllResidentStatusEnumId);
  }
  getAllEmploymentEnumId(): Observable<any> {
    const getAllEmploymentEnumId = URLS.getAllEmploymentEnumId;
    return this.http.get(getAllEmploymentEnumId);
  }
  

  getTransglReconciliationID(id): Observable<any> {
    const getTransglReconciliationID = URLS.getTransglReconciliationID + "?finAccountId=" + id;
    return this.http.get(getTransglReconciliationID);
  }

  getFindPayrollPreferenceList(id): Observable<any> {
    const getFindPayrollPreferenceList = URLS.getFindPayrollPreferenceList + "?payrollPreferenceSeqId=" + id;
    return this.http.get(getFindPayrollPreferenceList);
  } 

  
  getBenefitTypeID(): Observable<any> {
    const getBenefitTypeID = URLS.getBenefitTypeID;
    return this.http.get(getBenefitTypeID);
  } 
  
  payrollprefrenceService(): Observable<any> {
    const payrollprefrenceService = URLS.payrollprefrenceService;
    return this.http.get(payrollprefrenceService);
  }

  getDeductionTypeID(): Observable<any> {
    const getDeductionTypeID = URLS.getDeductionTypeID ;
    return this.http.get(getDeductionTypeID);
  }
  
  getAllPartyContactListPartyStatus(id): Observable<any> {
    const getAllPartyContactListPartyStatus = URLS.getAllPartyContactListPartyStatus + "?partyId=" + id;
    return this.http.get(getAllPartyContactListPartyStatus);
  }
  getIdentificationList(id): Observable<any> {
    const getIdentificationList = URLS.getIdentificationList + "?partyId=" + id;
    return this.http.get(getIdentificationList);
  }
  getSystemInfoStatus(id): Observable<any> {
    const getSystemInfoStatus = URLS.getSystemInfoStatus + "?partyId=" + id;
    return this.http.get(getSystemInfoStatus);
  }
  
  getSystemInfoNote(id): Observable<any> {
    const getSystemInfoNote = URLS.getSystemInfoNote + "?partyId=" + id;
    return this.http.get(getSystemInfoNote);
  }
  resetAvsOverride(id): Observable<any> {
    const resetAvsOverride = URLS.resetAvsOverride + "?partyId=" + id;
    return this.http.delete(resetAvsOverride);
  }
  
  getPartyIcsAvsOverRide(id): Observable<any> {
    const getPartyIcsAvsOverRide = URLS.getPartyIcsAvsOverRide + "?partyId=" + id;
    return this.http.get(getPartyIcsAvsOverRide);
  }
  
  getPartyGroup(id): Observable<any> {
    const getPartyGroup = URLS.getPartyGroup + "?partyId=" + id;
    return this.http.get(getPartyGroup);
  }
  getPreferences(id): Observable<any> {
    const getPreferences = URLS.getPreferences + "?partyId=" + id;
    return this.http.get(getPreferences);
  }
  
  getProductStore(id): Observable<any> {
    const getProductStore = URLS.getProductStore + "?partyId=" + id;
    return this.http.get(getProductStore);
  }
  getPreferencesScrum(id): Observable<any> {
    const getPreferencesScrum = URLS.getPreferencesScrum + "?partyId=" + id;
    return this.http.get(getPreferencesScrum);
  }
  
  getInvoiceCountPieChart(): Observable<any> {
    const getInvoiceCountPieChart = URLS.getInvoiceCountPieChart ;
    return this.http.get(getInvoiceCountPieChart);
  }
  getPartyLoyaltyPoints(id): Observable<any> {
    const getPartyLoyaltyPoints = URLS.getPartyLoyaltyPoints + "?partyId=" + id;
    return this.http.get(getPartyLoyaltyPoints);
  }
  getPerson(id): Observable<any> {
    const getPerson = URLS.getPerson + "?partyId=" + id;
    return this.http.get(getPerson);
  }
  deletePartyInvitations(id): Observable<any> {
    const deletePartyInvitations = URLS.deletePartyInvitations + "?partyInvitationId=" + id;
    return this.http.delete(deletePartyInvitations);
  }
  
  getAllProductStoreRole(id): Observable<any> {
    const getAllProductStoreRole = URLS.getAllProductStoreRole + "?partyId=" + id;
    return this.http.get(getAllProductStoreRole);
  }
  
  getPreferredContactMechId(id): Observable<any> {
    const getPreferredContactMechId = URLS.getPreferredContactMechId + "?partyId=" + id;
    return this.http.get(getPreferredContactMechId);
  }
  
  getPartyInvitationStatusList(id): Observable<any> {
    const getPartyInvitationStatusList = URLS.getPartyInvitationStatusList + "?statusTypeId=" + id;
    return this.http.get(getPartyInvitationStatusList);
  }
  getPartyContactList(id): Observable<any> {
    const getPartyContactList = URLS.getPartyContactList + "?partyId=" + id;
    return this.http.get(getPartyContactList);
  }
  
  getPartyAttribute(id): Observable<any> {
    const getPartyAttribute = URLS.getPartyAttribute + "?partyId=" + id;
    return this.http.get(getPartyAttribute);
  }
  
  getUserLoginData(id): Observable<any> {
    const getUserLoginData = URLS.getUserLoginData + "?partyId=" + id;
    return this.http.get(getUserLoginData);
  }
  
  getSegmentRole(id): Observable<any> {
    const getSegmentRole = URLS.getSegmentRole + "?partyId=" + id;
    return this.http.get(getSegmentRole);
  }
  
  getPartyResume(id): Observable<any> {
    const getPartyResume = URLS.getPartyResume + "?partyId=" + id;
    return this.http.get(getPartyResume);
  }
  
  getPartyClassificationByPartyId(id): Observable<any> {
    const getPartyClassificationByPartyId = URLS.getPartyClassificationByPartyId + "?partyId=" + id;
    return this.http.get(getPartyClassificationByPartyId);
  }
  
  getPartyRate(id): Observable<any> {
    const getPartyRate = URLS.getPartyRate + "?partyId=" + id;
    return this.http.get(getPartyRate);
  }
  
  getMyTask(id): Observable<any> {
    const getMyTask = URLS.getMyTask + "?partyId=" + id;
    return this.http.get(getMyTask);
  }
  ListAddUserLoginToSecurityGroup(id,userLoginId): Observable<any> {
    const ListAddUserLoginToSecurityGroup = URLS.ListAddUserLoginToSecurityGroup + "?partyId=" + id + "&userLoginId=" + userLoginId;
    return this.http.get(ListAddUserLoginToSecurityGroup);
  }
  getAllDocTypeTemplateInitialData(id): Observable<any> {
    const getAllDocTypeTemplateInitialData = URLS.getAllDocTypeTemplateInitialData + "?docTypeEntity=" + id;
    return this.http.get(getAllDocTypeTemplateInitialData);
  }
  
  HTMLDATANEW_TESTING_NEW(netPayAmount,paycheckType,paymentId): Observable<any> {
    const HTMLDATANEW_TESTING_NEW = URLS.HTMLDATANEW_TESTING_NEW + "?netPayAmount=" + netPayAmount + "&paycheckType=" + paycheckType + "&paymentId=" + paymentId;
    return this.http.get(HTMLDATANEW_TESTING_NEW, { responseType: "blob" });
  }
  
  fixedAssetMaintsPdf(netPayAmount,paycheckType): Observable<any> {
    const fixedAssetMaintsPdf = URLS.fixedAssetMaintsPdf + "?fixedAssetId=" + netPayAmount + "&maintHistSeqId=" + paycheckType;
    return this.http.get(fixedAssetMaintsPdf, { responseType: "blob" });
  }
  
  getProductMaintById(fixedAssetId,productId): Observable<any> {
    const getProductMaintById = URLS.getProductMaintById + "?fixedAssetId=" + fixedAssetId + "&productId=" + productId;
    return this.http.get(getProductMaintById);
  }
  getReturnPartIssuedToFixedAssetMaintById(fixedAssetId,maintHistSeqId): Observable<any> {
    const getReturnPartIssuedToFixedAssetMaintById = URLS.getReturnPartIssuedToFixedAssetMaintById + "?fixedAssetId=" + fixedAssetId + "&maintHistSeqId=" + maintHistSeqId;
    return this.http.get(getReturnPartIssuedToFixedAssetMaintById);
  }
  getStatusSendDetails(paycheckType,paymentId): Observable<any> {
    const getStatusSendDetails = URLS.getStatusSendDetails + "?paycheckType=" + paycheckType + "&paymentId=" + paymentId;
    return this.http.get(getStatusSendDetails);
  }
  
  getFixedAssetMaintOrderById(fixedAssetId,maintHistSeqId): Observable<any> {
    const getFixedAssetMaintOrderById = URLS.getFixedAssetMaintOrderById + "?fixedAssetId=" + fixedAssetId + "&maintHistSeqId=" + maintHistSeqId;
    return this.http.get(getFixedAssetMaintOrderById);
  }
  getVarianceReasonGlAccountsInitialData(): Observable<any> {
    const getVarianceReasonGlAccountsInitialData = URLS.getVarianceReasonGlAccountsInitialData;
    return this.http.get(getVarianceReasonGlAccountsInitialData);
  }
  
  getFacilityID(): Observable<any> {
    const getFacilityID = URLS.getFacilityID;
    return this.http.get(getFacilityID);
  }
  
  getTimeEntryRateType(): Observable<any> {
    const getTimeEntryRateType = URLS.getTimeEntryRateType;
    return this.http.get(getTimeEntryRateType);
  }
  
  getOrderIdData(): Observable<any> {
    const getOrderIdData = URLS.getOrderIdData;
    return this.http.get(getOrderIdData);
  }
  
  
  getTimeSheetIdData(): Observable<any> {
    const getTimeSheetIdData = URLS.getTimeSheetIdData;
    return this.http.get(getTimeSheetIdData);
  }
  
  getProductMaintType(): Observable<any> {
    const getProductMaintType = URLS.getProductMaintType;
    return this.http.get(getProductMaintType);
  }
  
  getProductMeterType(): Observable<any> {
    const getProductMeterType = URLS.getProductMeterType;
    return this.http.get(getProductMeterType);
  }
  
  delegateReasonEnumList(): Observable<any> {
    const delegateReasonEnumList = URLS.delegateReasonEnumList;
    return this.http.get(delegateReasonEnumList);
  }
  
  getRoleTypeAsset(): Observable<any> {
    const getRoleTypeAsset = URLS.getRoleTypeAsset;
    return this.http.get(getRoleTypeAsset);
  }
  
   
  facilityTypeIds(): Observable<any> {
    const facilityTypeIds = URLS.facilityTypeIds;
    return this.http.get(facilityTypeIds);
  }
  getCalStatusList(): Observable<any> {
    const getCalStatusList = URLS.getCalStatusList;
    return this.http.get(getCalStatusList);
  }
  
  getPurposeList(): Observable<any> {
    const getPurposeList = URLS.getPurposeList;
    return this.http.get(getPurposeList);
  }
  getStatusIdAsset(): Observable<any> {
    const getStatusIdAsset = URLS.getStatusIdAsset;
    return this.http.get(getStatusIdAsset);
  }
  getAvailabilityListData(): Observable<any> {
    const getAvailabilityListData = URLS.getAvailabilityListData;
    return this.http.get(getAvailabilityListData);
  }
  
  getWorkEffortNameValue(): Observable<any> {
    const getWorkEffortNameValue = URLS.getWorkEffortNameValue;
    return this.http.get(getWorkEffortNameValue);
  }
  getReturnPartIssuedToFixedAsset(): Observable<any> {
    const getReturnPartIssuedToFixedAsset = URLS.getReturnPartIssuedToFixedAsset;
    return this.http.get(getReturnPartIssuedToFixedAsset);
  }
  getSegmentGroup(): Observable<any> {
    const getSegmentGroup = URLS.getSegmentGroup;
    return this.http.get(getSegmentGroup);
  }
  
  getClassificationGroupId(): Observable<any> {
    const getClassificationGroupId = URLS.getClassificationGroupId;
    return this.http.get(getClassificationGroupId);
  }
  
  clearAddressMatchMap(): Observable<any> {
    const clearAddressMatchMap = URLS.clearAddressMatchMap;
    return this.http.get(clearAddressMatchMap);
  }
  getRoleById(): Observable<any> {
    const getRoleById = URLS.getRoleById;
    return this.http.get(getRoleById);
  }
  
  createPartyRate(fromData): Observable<any> {
    const createPartyRate = URLS.createPartyRate;
    return this.http.post(createPartyRate, fromData);
  }
  createPayrollPreference(fromData): Observable<any> {
    const createPayrollPreference = URLS.createPayrollPreference;
    return this.http.post(createPayrollPreference, fromData);
  }
  createPartyInvitations(fromData): Observable<any> {
    const createPartyInvitations = URLS.createPartyInvitations;
    return this.http.post(createPartyInvitations, fromData);
  }
  
  removeMyTasks(fromData): Observable<any> {
    const removeMyTasks = URLS.removeMyTasks;
    return this.http.post(removeMyTasks, fromData);
  }
  
  createPartyEmptyShopping(id): Observable<any> {
    const createPartyEmptyShopping = URLS.createPartyEmptyShopping+ "?partyId=" + id;
    return this.http.post(createPartyEmptyShopping, '');
  }
  
  partyInvitations(fromData): Observable<any> {
    const partyInvitations = URLS.partyInvitations;
    return this.http.post(partyInvitations, fromData);
  }
  updateStoreRole(fromData): Observable<any> {
    const updateStoreRole = URLS.updateStoreRole;
    return this.http.post(updateStoreRole, fromData);
  }
  createStoreRole(fromData): Observable<any> {
    const createStoreRole = URLS.createStoreRole;
    return this.http.post(createStoreRole, fromData);
  }
  deleteStoreRole(fromData): Observable<any> {
    const deleteStoreRole = URLS.deleteStoreRole;
    return this.http.delete(deleteStoreRole, fromData);
  }
  
  createNewRelationshipType(fromData): Observable<any> {
    const createNewRelationshipType = URLS.createNewRelationshipType;
    return this.http.post(createNewRelationshipType, fromData);
  }
  
  deletePartRate(fromData): Observable<any> {
    const deletePartRate = URLS.deletePartRate;
    return this.http.post(deletePartRate, fromData);
  }
  
  createPartyAttribute(fromData): Observable<any> {
    const createPartyAttribute = URLS.createPartyAttribute;
    return this.http.post(createPartyAttribute, fromData);
  }
  deleteContactList(fromData): Observable<any> {
    const deleteContactList = URLS.deleteContactList;
    return this.http.post(deleteContactList, fromData);
  }
  
  
  updatePartyAttribute(fromData): Observable<any> {
    const updatePartyAttribute = URLS.updatePartyAttribute;
    return this.http.put(updatePartyAttribute, fromData);
  }
  expireAddUserLoginToSecurityGroup(fromData,id): Observable<any> {
    const expireAddUserLoginToSecurityGroup = URLS.expireAddUserLoginToSecurityGroup + "?userLoginId=" + id;
    return this.http.post(expireAddUserLoginToSecurityGroup, fromData);
  }
  
  deletePartyClassification(fromData): Observable<any> {
    const deletePartyClassification = URLS.deletePartyClassification;
    return this.http.post(deletePartyClassification, fromData);
  }
  
  deletePartyAttribute(fromData): Observable<any> {
    const deletePartyAttribute = URLS.deletePartyAttribute;
    return this.http.delete(deletePartyAttribute, fromData);
  }
  updateProfileUserLogin(fromData): Observable<any> {
    const updateProfileUserLogin = URLS.updateProfileUserLogin;
    return this.http.put(updateProfileUserLogin, fromData);
  }
  
  createContactList(fromData): Observable<any> {
    const createContactList = URLS.createContactList;
    return this.http.post(createContactList, fromData);
  }
  updateContactList(fromData): Observable<any> {
    const updateContactList = URLS.updateContactList;
    return this.http.post(updateContactList, fromData);
  }
  
 
  
  
  createPartyClassification(fromData): Observable<any> {
    const createPartyClassification = URLS.createPartyClassification;
    return this.http.post(createPartyClassification, fromData);
  }
  
  updatePartyClassification(fromData): Observable<any> {
    const updatePartyClassification = URLS.updatePartyClassification;
    return this.http.post(updatePartyClassification, fromData);
  }
  updateAddUserLoginToSecurityGroup(fromData,id): Observable<any> {
    const updateAddUserLoginToSecurityGroup = URLS.updateAddUserLoginToSecurityGroup+ "?userLoginId=" + id;;
    return this.http.post(updateAddUserLoginToSecurityGroup, fromData);
  }
  updatePaycheckHolding(fromData,amount): Observable<any> {
    const updatePaycheckHolding = URLS.updatePaycheckHolding+ "?amount=" + amount;;
    return this.http.post(updatePaycheckHolding, fromData);
  }

  StatusToSend(fromData): Observable<any> {
    const StatusToSend = URLS.StatusToSend;
    return this.http.post(StatusToSend, fromData);
  }

  updatePaycheckExpenses(fromData): Observable<any> {
    const updatePaycheckExpenses = URLS.updatePaycheckExpenses;
    return this.http.post(updatePaycheckExpenses, fromData);
  }
  updatePayCheck(fromData): Observable<any> {
    const updatePayCheck = URLS.updatePayCheck;
    return this.http.post(updatePayCheck, fromData);
  }
  
  profileAddUserLoginToSecurityGroup(fromData,id): Observable<any> {
    const profileAddUserLoginToSecurityGroup = URLS.profileAddUserLoginToSecurityGroup+ "?userLoginId=" + id;;
    return this.http.post(profileAddUserLoginToSecurityGroup, fromData);
  }
  postFinancialRoleAccount(fromData): Observable<any> {
    const getFinancialRoleAccount = URLS.getFinancialRoleAccount;
    return this.http.post(getFinancialRoleAccount, fromData);
  }
 
  

  postVarianceReasonGlAccounts(fromData): Observable<any> {
    const postVarianceReasonGlAccounts = URLS.postVarianceReasonGlAccounts;
    return this.http.post(postVarianceReasonGlAccounts, fromData);
  }
  
  createPartySegmentRole(fromData): Observable<any> {
    const createPartySegmentRole = URLS.createPartySegmentRole;
    return this.http.post(createPartySegmentRole, fromData);
  }
  
  deletePartySegment(fromData): Observable<any> {
    const deletePartySegment = URLS.deletePartySegment;
    return this.http.post(deletePartySegment, fromData);
  }
  

  assignGLAccount(fromData): Observable<any> {
    const assignGLAccount = URLS.assignGLAccount;
    return this.http.post(assignGLAccount, fromData);
  }
  postChartAccountData(fromData): Observable<any> {
    const postChartAccountData = URLS.postChartAccountData;
    return this.http.post(postChartAccountData, fromData);
  }
  updateChartAccountData(fromData): Observable<any> {
    const postChartAccountData = URLS.postChartAccountData;
    return this.http.put(postChartAccountData, fromData);
  }
  updateVarianceReasonGlAccounts(fromData): Observable<any> {
    const updateVarianceReasonGlAccounts= URLS.updateVarianceReasonGlAccounts;
    return this.http.put(updateVarianceReasonGlAccounts, fromData);
  }
  

  createFinancialAccountTransaction(fromData): Observable<any> {
    const createFinancialAccountTransaction = URLS.createFinancialAccountTransaction;
    return this.http.post(createFinancialAccountTransaction, fromData);
  }

  reconcileAll(fromData): Observable<any> {
    const reconcileAll = URLS.reconcileAll;
    return this.http.post(reconcileAll, fromData);
  }
  postTaxParty(fromData): Observable<any> {
    const postTaxParty = URLS.postTaxParty;
    return this.http.post(postTaxParty, fromData);
  }
  deletePartyResume(fromData): Observable<any> {
    const deletePartyResume = URLS.deletePartyResume;
    return this.http.post(deletePartyResume, fromData);
  }
  deletePartyQual(fromData): Observable<any> {
    const deletePartyQual = URLS.deletePartyQual;
    return this.http.post(deletePartyQual, fromData);
  }
  
  createTaxInfo(fromData): Observable<any> {
    const createTaxInfo = URLS.createTaxInfo;
    return this.http.post(createTaxInfo, fromData);
  }
  
   
  updateTaxInfo(fromData): Observable<any> {
    const updateTaxInfo = URLS.updateTaxInfo;
    return this.http.put(updateTaxInfo, fromData);
  }
  deleteTaxInfo(fromData): Observable<any> {
    const deleteTaxInfo = URLS.deleteTaxInfo;
    return this.http.post(deleteTaxInfo, fromData);
  }
  createPartyResume(fromData): Observable<any> {
    const createPartyResume = URLS.createPartyResume;
    return this.http.post(createPartyResume, fromData);
  }
  
  updatePartyResume(fromData): Observable<any> {
    const updatePartyResume = URLS.updatePartyResume;
    return this.http.post(updatePartyResume, fromData);
  }

  updateApplicationEmployment(fromData): Observable<any> {
    const updateApplicationEmployment = URLS.updateApplicationEmployment;
    return this.http.post(updateApplicationEmployment, fromData);
  }
  updateTaxParty(fromData): Observable<any> {
    const postTaxParty = URLS.postTaxParty;
    return this.http.put(postTaxParty, fromData);
  }

  updateFinancialRoleAccount(fromData): Observable<any> {
    const getFinancialRoleAccount = URLS.getFinancialRoleAccount;
    return this.http.put(getFinancialRoleAccount, fromData);
  }
  deleteFinancialRoleAccount(finAccountId, fromDate, partyId, roleTypeId): Observable<any> {
    const getFinancialRoleAccount = URLS.getFinancialRoleAccount + "?finAccountId=" + finAccountId + "&fromDate=" + fromDate + "&partyId=" + partyId + "&roleTypeId=" + roleTypeId;
    return this.http.delete(getFinancialRoleAccount);
  }
  removeTransFromReconciliation(finAccountId, finAccountTransId, glReconciliationId): Observable<any> {
    const removeTransFromReconciliation = URLS.removeTransFromReconciliation + "?finAccountId=" + finAccountId + "&finAccountTransId=" + finAccountTransId + "&glReconciliationId=" + glReconciliationId;
    return this.http.delete(removeTransFromReconciliation);
  }
  deletetransactionEntries(acctgTransEntrySeqId, acctgTransId, organizationPartyId): Observable<any> {
    const deletetransactionEntries = URLS.deletetransactionEntries + "?acctgTransEntrySeqId=" + acctgTransEntrySeqId + "&acctgTransId=" + acctgTransId + "&organizationPartyId=" + organizationPartyId;
    return this.http.delete(deletetransactionEntries);
  }
  deleteTaxParty(fromDate, partyId, taxAuthGeoId,taxAuthPartyId): Observable<any> {
    const deleteTaxParty = URLS.deleteTaxParty + "?fromDate=" + fromDate + "&partyId=" + partyId + "&taxAuthGeoId=" + taxAuthGeoId + "&taxAuthPartyId=" + taxAuthPartyId;
    return this.http.delete(deleteTaxParty);
  }
  deletePartyIdentification(partyId,partyIdentificationTypeId): Observable<any> {
    const deletePartyIdentification = URLS.deletePartyIdentification + "?partyId=" + partyId + "&partyIdentificationTypeId=" + partyIdentificationTypeId;
    return this.http.delete(deletePartyIdentification);
  }
  
  removeAddressMatchMap(mapKey,mapValue): Observable<any> {
    const removeAddressMatchMap = URLS.removeAddressMatchMap + "?mapKey=" + mapKey + "&mapValue=" + mapValue;
    return this.http.delete(removeAddressMatchMap);
  }
  
  deleteSystemInfoNote(noteId,portalPageId): Observable<any> {
    const deleteSystemInfoNote = URLS.deleteSystemInfoNote + "?noteId=" + noteId + "&portalPageId=" + portalPageId;
    return this.http.delete(deleteSystemInfoNote);
  }
  
  getPartyDetail(fromDate, partyId, taxAuthGeoId,taxAuthPartyId): Observable<any> {
    const getPartyDetail = URLS.getPartyDetail + "?fromDate=" + fromDate + "&partyId=" + partyId + "&taxAuthGeoId=" + taxAuthGeoId + "&taxAuthPartyId=" + taxAuthPartyId;
    return this.http.get(getPartyDetail);
  }
  
  
 
  
  cancelReconciliation(finAccountId, glReconciliationId): Observable<any> {
    const cancelReconciliation = URLS.cancelReconciliation + "?finAccountId=" + finAccountId + "&glReconciliationId=" + glReconciliationId;
    return this.http.post(cancelReconciliation, '');
  }
  removeInvoiceItemTypeGlAssignment(Id, itemId): Observable<any> {
    const removeInvoiceItemTypeGlAssignment = URLS.removeInvoiceItemTypeGlAssignment + "?invoiceItemTypeId=" + itemId + "&organizationPartyId=" + Id;
    return this.http.delete(removeInvoiceItemTypeGlAssignment);
  }
  getInvoiceItemTypeInitialData(Id, itemId): Observable<any> {
    const getInvoiceItemTypeInitialData = URLS.getInvoiceItemTypeInitialData + "?invoiceType=" + itemId + "&organizationPartyId=" + Id;
    return this.http.get(getInvoiceItemTypeInitialData);
  }
  
  updateAvsOverride(avsDeclineString, partyId): Observable<any> {
    const updateAvsOverride = URLS.updateAvsOverride + "?avsDeclineString=" + avsDeclineString + "&partyId=" + partyId;
    return this.http.put(updateAvsOverride,'');
  }
  
  getInvoiceItemTypeListApiData(Id): Observable<any> {
    const getInvoiceItemTypeListApiData = URLS.getInvoiceItemTypeListApiData + "?invoiceType=" + Id;
    return this.http.get(getInvoiceItemTypeListApiData);
  }
  
  
  removeJournal(id): Observable<any> {
    const removeJournal = URLS.removeJournal + "?glJournalId=" + id;
    return this.http.post(removeJournal, '');
  }
  searchFinancialTransaction(fromData): Observable<any> {
    const searchFinancialTransaction = URLS.searchFinancialTransaction;
    return this.http.post(searchFinancialTransaction, fromData);
  }
  postJournal(fromData): Observable<any> {
    const postJournal = URLS.postJournal;
    return this.http.post(postJournal, fromData);
  }
  addSalInvoiceItemTypeGlAssignments(fromData): Observable<any> {
    const addSalInvoiceItemTypeGlAssignments = URLS.addSalInvoiceItemTypeGlAssignments;
    return this.http.post(addSalInvoiceItemTypeGlAssignments, fromData);
  }
  
  updateJournal(fromData): Observable<any> {
    const updateJournal = URLS.updateJournal;
    return this.http.put(updateJournal, fromData);
  }
  
  updatePartyIdentifications(fromData): Observable<any> {
    const updatePartyIdentifications = URLS.updatePartyIdentifications;
    return this.http.put(updatePartyIdentifications, fromData);
  }
  
  
  postOrganization(id, formData): Observable<any> {
    const postOrganization = URLS.postOrganization + "?partyId=" + id;
    return this.http.post(postOrganization,formData);
  }
  postAssignGLAccount(formData): Observable<any> {
    const postAssignGLAccount = URLS.postAssignGLAccount;
    return this.http.post(postAssignGLAccount,formData);
  }
  postpaymentType(formData): Observable<any> {
    const postpaymentType = URLS.postpaymentType;
    return this.http.post(postpaymentType,formData);
  }
  
  updateOrganization(id, formData): Observable<any> {
    const updateOrganization = URLS.updateOrganization + "?partyId=" + id;
    return this.http.put(updateOrganization,formData);
  }
  
  getProductGlAccountById(id): Observable<any> {
    const getProductGlAccountById = URLS.getProductGlAccountById + "?productId=" + id;
    return this.http.get(getProductGlAccountById);
  }
  getpaymentTypeInitialData(): Observable<any> {
    const getpaymentTypeInitialData = URLS.getpaymentTypeInitialData;
    return this.http.get(getpaymentTypeInitialData);
  }
  
  getPayrollPreferenceSequenceId(): Observable<any> {
    const getPayrollPreferenceSequenceId = URLS.getPayrollPreferenceSequenceId;
    return this.http.get(getPayrollPreferenceSequenceId);
  }

  getPayHistoryList(): Observable<any> {
    const getPayHistoryList = URLS.getPayHistoryList;
    return this.http.get(getPayHistoryList);
  }
  getContentIdList(): Observable<any> {
    const getContentIdList = URLS.getContentIdList;
    return this.http.get(getContentIdList);
  }

  postAllDocTypeTemplate(formData): Observable<any> {
    const postAllDocTypeTemplate = URLS.postAllDocTypeTemplate;
    return this.http.post(postAllDocTypeTemplate,formData);
  }
  expirePartyPrefDocTypeTp(formData): Observable<any> {
    const expirePartyPrefDocTypeTp = URLS.expirePartyPrefDocTypeTp;
    return this.http.post(expirePartyPrefDocTypeTp,formData);
  }
  postProductGlAccount(formData): Observable<any> {
    const postProductGlAccount = URLS.postProductGlAccount;
    return this.http.post(postProductGlAccount,formData);
  }
  removeProductGlAccount(formData): Observable<any> {
    const removeProductGlAccount = URLS.removeProductGlAccount;
    return this.http.post(removeProductGlAccount,formData);
  }
  updateProductGlAccount(formData): Observable<any> {
    const updateProductGlAccount = URLS.updateProductGlAccount;
    return this.http.put(updateProductGlAccount,formData);
  }
  postProductCategoryGlAccount(formData): Observable<any> {
    const postProductCategoryGlAccount = URLS.postProductCategoryGlAccount;
    return this.http.post(postProductCategoryGlAccount,formData);
  }
  removeProductCategoryGlAccount(formData): Observable<any> {
    const removeProductCategoryGlAccount = URLS.removeProductCategoryGlAccount;
    return this.http.post(removeProductCategoryGlAccount,formData);
  }
  updateProductCategoryGlAccount(formData): Observable<any> {
    const updateProductCategoryGlAccount = URLS.updateProductCategoryGlAccount;
    return this.http.put(updateProductCategoryGlAccount,formData);
  }
  
  createPartyQual(formData): Observable<any> {
    const createPartyQual = URLS.createPartyQual;
    return this.http.post(createPartyQual,formData);
  }
  
  getOrganizationDropdown(): Observable<any> {
    const getOrganizationDropdown = URLS.getOrganizationDropdown;
    return this.http.get(getOrganizationDropdown);
  }
  getOrganizationMain(): Observable<any> {
    const getOrganizationMain = URLS.getOrganizationMain;
    return this.http.get(getOrganizationMain);
  }
  getPartyQualType(): Observable<any> {
    const getPartyQualType = URLS.getPartyQualType;
    return this.http.get(getPartyQualType);
  }
 
  
  identificationList(): Observable<any> {
    const identificationList = URLS.identificationList;
    return this.http.get(identificationList);
  }

  postCreateEmployee(formData): Observable<any> {
    const postCreateEmployee = URLS.postCreateEmployee;
    return this.http.post(postCreateEmployee,formData);
  }
  findPerfReviewData(comments,employeePartyId,fromDate,managerPartyId,paymentId,perfReviewId,thruDate): Observable<any> {
    const findPerfReviewData = URLS.findPerfReviewData + "?comments=" + comments + "&employeePartyId=" + employeePartyId + "&fromDate=" + fromDate + "&managerPartyId=" + managerPartyId + "&paymentId=" + paymentId + "&perfReviewId=" + perfReviewId + "&thruDate=" + thruDate;
    return this.http.post(findPerfReviewData,"");
  }
  findPerfReviewDataList(): Observable<any> {
    const findPerfReviewData = URLS.findPerfReviewData;
    return this.http.post(findPerfReviewData,"");
  }
  // getFindEmployment(firstName,fromDate,lastName,partyId,partyIdTo,terminationReasonId,terminationTypeId,thruDate): Observable<any> {
  //   const getFindEmployment = URLS.getFindEmployment+ "?firstName=" + firstName + "&fromDate=" + fromDate  + "&lastName=" + lastName + "&partyId=" + partyId + "&partyIdTo=" + partyIdTo + "&terminationReasonId=" + terminationReasonId + "&terminationTypeId=" + terminationTypeId + "&thruDate=" + thruDate;
  //   return this.http.post(getFindEmployment,"");
  // }
  getFindEmployment(firstName,fromDate,lastName,partyId,partyIdTo,terminationReasonId,terminationTypeId,thruDate): Observable<any> {
    const getFindEmployment = URLS.getFindEmployment+ "?firstName=" + firstName + "&fromDate=" + fromDate  + "&lastName=" + lastName + "&partyIdTo=" + partyIdTo + "&partyId=" + partyId + "&terminationReasonId=" + terminationReasonId + "&terminationTypeId=" + terminationTypeId + "&thruDate=" + thruDate;
    return this.http.post(getFindEmployment,"");
  }

  getFindEmploymentList(): Observable<any> {
    const getFindEmployment = URLS.getFindEmployment;
    return this.http.post(getFindEmployment,"");
  }
  
  getFindPartyQual(partyId,partyQualTypeId,qualificationDesc,title,statusId,verifStatusId,thruDate,fromDate): Observable<any> {
    const getPartyQualList = URLS.getPartyQualList +"?partyId=" + partyId + "&partyQualTypeId=" + partyQualTypeId + "&qualificationDesc=" + qualificationDesc + "&title=" + title + 
    "&statusId=" +  statusId + "&verifStatusId=" + verifStatusId + "&thruDate=" + thruDate;;
    return this.http.post(getPartyQualList,"");
  }

  getPartyQualList(): Observable<any> {
    const getPartyQualList = URLS.getPartyQualList;
    return this.http.post(getPartyQualList,"");
  }

  
getFindShipmentGatewayConfig(): Observable<any> {
    const getFindShipmentGatewayConfig = URLS.getFindShipmentGatewayConfig;
    return this.http.post(getFindShipmentGatewayConfig,"");
  }

  
getFindShipmentGatewayConfigTypes(): Observable<any> {
    const getFindShipmentGatewayConfigTypes = URLS.getFindShipmentGatewayConfigTypes;
    return this.http.post(getFindShipmentGatewayConfigTypes,"");
  }
  FindShipmentGatewayConfigTypes(shipmentGatewayConfTypeId,description): Observable<any> {
    const getFindShipmentGatewayConfigTypes = URLS.getFindShipmentGatewayConfigTypes +"?description=" + description + 
    "&shipmentGatewayConfTypeId=" + shipmentGatewayConfTypeId;
    return this.http.post(getFindShipmentGatewayConfigTypes,"");
  }
  getPaycheckAndWithHoldings(paycheckType): Observable<any> {
    const getPaycheckAndWithHoldings = URLS.getPaycheckAndWithHoldings +"?paycheckType=" + paycheckType;
    return this.http.get(getPaycheckAndWithHoldings);
  }

  getPaycheckExpenses(paycheckType): Observable<any> {
    const getPaycheckExpenses = URLS.getPaycheckExpenses +"?paycheckType=" + paycheckType;
    return this.http.get(getPaycheckExpenses);
  }
 
  getConfigPagePaycheck(paymentId): Observable<any> {
    const getConfigPagePaycheck = URLS.getConfigPagePaycheck+"?paymentId=" + paymentId;
    return this.http.get(getConfigPagePaycheck);
  }
  getConfigPaycheck(): Observable<any> {
    const getConfigPagePaycheck = URLS.getConfigPagePaycheck;
    return this.http.get(getConfigPagePaycheck);
  }
  getFindPayCheck(): Observable<any> {
    const getFindPayCheck = URLS.getFindPayCheck;
    return this.http.get(getFindPayCheck);
  }
  
  FindPayCheck(paymentId,paycheckType,partyIdTo, statusId , effectiveDate , paymentMethodId , paymentRefNum): Observable<any> {
    const getFindPayCheck = URLS.getFindPayCheck +"?effectiveDate=" + effectiveDate + "&partyIdTo=" + partyIdTo+
    "&paycheckType=" + paycheckType + "&paymentId=" + paymentId + "&paymentMethodId=" + paymentMethodId + "&paymentRefNum=" + paymentRefNum  + "&statusId=" + statusId;
    return this.http.get(getFindPayCheck);
  }


  FindShipmentGatewayConfig(shipmentGatewayConfigId,shipmentGatewayConfTypeId,description): Observable<any> {
    const getFindShipmentGatewayConfig = URLS.getFindShipmentGatewayConfig +"?description=" + description + 
    "&shipmentGatewayConfTypeId=" + shipmentGatewayConfTypeId
     + "&shipmentGatewayConfigId=" + shipmentGatewayConfigId;
    return this.http.post(getFindShipmentGatewayConfig,"");
  }

  getFindPartyResume(): Observable<any> {
    const getFindPartyResume = URLS.getFindPartyResume;
    return this.http.post(getFindPartyResume,"");
  }

  getFindResume(resumeId,partyId,contentId,resumeText,resumeDate): Observable<any> {
    const getFindPartyResume = URLS.getFindPartyResume +"?partyId=" + partyId + "&contentId=" + contentId   
  +  "&resumeId=" +  resumeId + "&resumeDate=" + resumeDate + "&resumeText=" + resumeText;;
    return this.http.post(getFindPartyResume,"");
  }
  
  
  uploadEmployeePersonalImage(formData,EmployeeId,userType): Observable<any> {
    const uploadEmployeePersonalImage = URLS.uploadEmployeePersonalImage + "?EmployeeId=" + EmployeeId + "&userType=" + userType;
    return this.http.post(uploadEmployeePersonalImage, formData);
  }
  
  uploadCustomerPersonalImage(formData,customerId,userType): Observable<any> {
    const uploadCustomerPersonalImage = URLS.uploadCustomerPersonalImage + "?customerId=" + customerId + "&userType=" + userType;
    return this.http.post(uploadCustomerPersonalImage, formData);
  }
  setPartyLink(partyTo,partyFrom): Observable<any> {
    const setPartyLink = URLS.setPartyLink+ "?partyId=" + partyTo + "&partyIdTo=" + partyFrom;
    return this.http.post(setPartyLink,'');
  }
  getPartyContent(partyId): Observable<any> {
    const getPartyContent = URLS.getPartyContent+ "?partyId=" + partyId;
    return this.http.get(getPartyContent);
  }
  findItemPreview(partyId): Observable<any> {
    const findItemPreview = URLS.findItemPreview+ "?perfReviewId=" + partyId;
    return this.http.get(findItemPreview);
  }
  getParentTypeID(): Observable<any> {
    const getParentTypeID = URLS.getParentTypeID;
    return this.http.get(getParentTypeID);
  }
  
  createPartyContent(formData,data): Observable<any> {
    const createPartyContent = URLS.createPartyContent + "?json=" + data;
    return this.http.post(createPartyContent, formData);
  }
  updatePartyContent(formData,data,contentId,dataResourceId): Observable<any> {
    const updatePartyContent = URLS.updatePartyContent + "?json=" + data + "&contentId=" + contentId + "&dataResourceId=" + dataResourceId;
    return this.http.post(updatePartyContent, formData);
  }
  
  
  createProfileUserLogin(formData): Observable<any> {
    const createProfileUserLogin = URLS.createProfileUserLogin;
    return this.http.post(createProfileUserLogin,formData);
  }
  
  createPartyIdentifications(formData): Observable<any> {
    const createPartyIdentifications = URLS.createPartyIdentifications;
    return this.http.post(createPartyIdentifications,formData);
  }
  
  createCustomer(formData): Observable<any> {
    const createCustomer = URLS.createCustomer;
    return this.http.post(createCustomer,formData);
  }
  deleteFindItemPreview(formData): Observable<any> {
    const deleteFindItemPreview = URLS.deleteFindItemPreview;
    return this.http.post(deleteFindItemPreview,formData);
  }
  deleteApplicationEmployment(formData): Observable<any> {
    const deleteApplicationEmployment = URLS.deleteApplicationEmployment;
    return this.http.post(deleteApplicationEmployment,formData);
  }
  

  getCountry(): Observable<any> {
    return this.http.get(URLS.getCountry)
  }

  getState(data): Observable<any> {
    const getStateList = URLS.getState;
    const getStateLists = getStateList.replace('accState', data.trim())
    return this.http.get(getStateLists)
  }

  postCreatePartyGroup(formData): Observable<any> {
    const postCreatePartyGroup = URLS.postCreatePartyGroup;
    return this.http.post(postCreatePartyGroup,formData);
  }
  postCreateNewPerson(formData): Observable<any> {
    const postCreateNewPerson = URLS.postCreateNewPerson;
    return this.http.post(postCreateNewPerson,formData);
  }
  getMaritalStatus(): Observable<any> {
    const getMaritalStatus = URLS.getMaritalStatus + "?enumTypes=" + 'MARITAL_STATUS';
    return this.http.get(getMaritalStatus);
  }
  getEmployeePersonalImage(id): Observable<any> {
    const getEmployeePersonalImage = URLS.getEmployeePersonalImage + "?employeeId=" + id;
    return this.http.get(getEmployeePersonalImage);
  }
  getCustomerPersonalImage(id): Observable<any> {
    const getCustomerPersonalImage = URLS.getCustomerPersonalImage + "?customerId=" + id;
    return this.http.get(getCustomerPersonalImage);
  }
  
  postCreateNewProspect(formData): Observable<any> {
    const postCreateNewProspect = URLS.postCreateNewProspect;
    return this.http.post(postCreateNewProspect,formData);
  }
  createUnemploymentClaim(formData): Observable<any> {
    const createUnemploymentClaim = URLS.createUnemploymentClaim;
    return this.http.post(createUnemploymentClaim,formData);
  }
  createAgreementEmploymentAppl(formData): Observable<any> {
    const createAgreementEmploymentAppl = URLS.createAgreementEmploymentAppl;
    return this.http.post(createAgreementEmploymentAppl,formData);
  }
  updateAgreementEmploymentAppl(formData): Observable<any> {
    const updateAgreementEmploymentAppl = URLS.updateAgreementEmploymentAppl;
    return this.http.post(updateAgreementEmploymentAppl,formData);
  }
  createPayCheck(formData): Observable<any> {
    const createPayCheck = URLS.createPayCheck;
    return this.http.post(createPayCheck,formData);
  }
  deletePayrollPreference(formData): Observable<any> {
    const deletePayrollPreference = URLS.deletePayrollPreference;
    return this.http.post(deletePayrollPreference,formData);
  }
  deletePartyBenefit(fromData): Observable<any> {
    const deletePartyBenefit = URLS.deletePartyBenefit;
    return this.http.post(deletePartyBenefit,fromData);
  }
  deletePayHistory(formData): Observable<any> {
    const deletePayHistory = URLS.deletePayHistory;
    return this.http.post(deletePayHistory,formData);
  }
  deleteUnemploymentClaim(formData): Observable<any> {
    const deleteUnemploymentClaim = URLS.deleteUnemploymentClaim;
    return this.http.post(deleteUnemploymentClaim,formData);
  }

  deleteAgreementEmploymentAppl(formData): Observable<any> {
    const deleteAgreementEmploymentAppl = URLS.deleteAgreementEmploymentAppl;
    return this.http.post(deleteAgreementEmploymentAppl,formData);
  }

  getTimeSheetRateTypeId(): Observable<any> {
    const getTimeSheetRateTypeId = URLS.getTimeSheetRateTypeId;
    return this.http.get(getTimeSheetRateTypeId);
  }
  
getShipmentGatewayConfigTypeIdList(): Observable<any> {
    const getShipmentGatewayConfigTypeIdList = URLS.getShipmentGatewayConfigTypeIdList;
    return this.http.get(getShipmentGatewayConfigTypeIdList);
  }
  getTimeSheetTaskName(partyId,workEffortId): Observable<any> {
    const getTimeSheetTaskName = URLS.getTimeSheetTaskName +"?partyId=" + partyId + "&workEffortId=" + workEffortId ;
    return this.http.get(getTimeSheetTaskName);
  }
  myStatusToComplete(): Observable<any>{
    const myStatusToComplete = URLS.myStatusToComplete;
    return this.http.post(myStatusToComplete,"");
  }
  getDetailsByPaymentId(paymentId: any) {
    const getDetailsByPaymentId = URLS.getDetailsByPaymentId + "?paymentId=" + paymentId;
    return this.http.post(getDetailsByPaymentId,"");
  }
  
  getBalanceSheetImportList(){
    const getBalanceSheetImportList = URLS.getBalanceSheetImportList;
    return this.http.get(getBalanceSheetImportList);
  }
  importBalanceSheetDetailsAccounting(formData):Observable<any>{
    const importBalanceSheetDetailsAccounting = URLS.importBalanceSheetDetailsAccounting;
    return this.http.post(importBalanceSheetDetailsAccounting,formData);
  }
  getTrialBalanceHeadingDetailsNew(){
    const getTrialBalanceHeadingDetailsNew = URLS.getTrialBalanceHeadingDetailsNew;
    return this.http.get(getTrialBalanceHeadingDetailsNew);
  }
  getBalanceSheetDetails(){
    const getBalanceSheetDetails = URLS.getBalanceSheetDetails;
    return this.http.get(getBalanceSheetDetails);
  }
  createBalanceSheetDetails(formData):Observable<any>{
    const createBalanceSheetDetails = URLS.createBalanceSheetDetails;
    return this.http.post(createBalanceSheetDetails,formData);
  }
  getGlAccountDetailsListImport(){
    const getGlAccountDetailsListImport = URLS.getGlAccountDetailsListImport;
    return this.http.get(getGlAccountDetailsListImport);
  }
  importGlSettingAccountDetails(formData):Observable<any>{
    const importGlSettingAccountDetails = URLS.importGlSettingAccountDetails;
    return this.http.post(importGlSettingAccountDetails,formData);
  }
  getTrialBalanceDetailsListImport(){
    const getTrialBalanceDetailsListImport = URLS.getTrialBalanceDetailsListImport;
    return this.http.get(getTrialBalanceDetailsListImport);
  }
  createTrialBalanceDetailsImport(formData){
    const createTrialBalanceDetailsImport = URLS.createTrialBalanceDetailsImport;
    return this.http.post(createTrialBalanceDetailsImport,formData);
  }
  getCashFlowListImport(){
    const getCashFlowListImport = URLS.getCashFlowListImport;
    return this.http.get(getCashFlowListImport);
  }
  creatingCashFlowDetailsImport(formData){
    const creatingCashFlowDetailsImport = URLS.creatingCashFlowDetailsImport;
    return this.http.post(creatingCashFlowDetailsImport,formData);
  }
  getInventoryCountListImport(){
    const getInventoryCountListImport = URLS.getInventoryCountListImport;
    return this.http.get(getInventoryCountListImport);
  }
  createInventoryCountImport(formData){
    const createInventoryCountImport = URLS.createInventoryCountImport;
    return this.http.post(createInventoryCountImport,formData);
  }
  getPagePaycheckEdit(paymentId){
    const getPagePaycheckEdit = URLS.getPagePaycheckEdit+ "?paymentId=" + paymentId;
    return this.http.get(getPagePaycheckEdit);
  }
  editPayCheck(formData):Observable<any>{
    const editPayCheck = URLS.editPayCheck;
    return this.http.post(editPayCheck,formData);
  }
  getLocalizationByLocalization(paycheckType){
    const getLocalizationByLocalization = URLS.getLocalizationByLocalization+ "?paycheckType=" + paycheckType;
    return this.http.get(getLocalizationByLocalization);
  }
  createLocalization(formData){
    const createLocalization = URLS.createLocalization;
    return this.http.post(createLocalization,formData);
  }
  getShipmentImportList(){
    const getShipmentImportList = URLS.getShipmentImportList;
    return this.http.get(getShipmentImportList);
  }
  createShipmentImport(formData){
    const createShipmentImport = URLS.createShipmentImport;
    return this.http.post(createShipmentImport,formData);
  }
  createVendorImport(formData){
    const createVendorImport = URLS.createVendorImport;
    return this.http.post(createVendorImport,formData);
  }
  getVendorImportList(){
    const getVendorImportList = URLS.getVendorImportList;
    return this.http.get(getVendorImportList);
  }
  getWareHouseLocationImportList(){
    const getWareHouseLocationImportList = URLS.getWareHouseLocationImportList;
    return this.http.get(getWareHouseLocationImportList);
  }
  createWareHousesLocationImport(formData){
    const createWareHousesLocationImport = URLS.createWareHousesLocationImport;
    return this.http.post(createWareHousesLocationImport,formData);
  }
  importWareHouseOnly(formData){
    const importWareHouseOnly = URLS.importWareHouseOnly;
    return this.http.post(importWareHouseOnly,formData);
  }
  createFiscalYearDetailsImport(formData){
    const createFiscalYearDetailsImport = URLS.createFiscalYearDetailsImport;
    return this.http.post(createFiscalYearDetailsImport,formData);
  }
  getFiscalMonthsDetailsList(){
    const getFiscalMonthsDetailsList = URLS.getFiscalMonthsDetailsList;
    return this.http.get(getFiscalMonthsDetailsList);
  }
  getWareHouseOnlyImportList(){
    const getWareHouseOnlyImportList = URLS.getWareHouseOnlyImportList;
    return this.http.get(getWareHouseOnlyImportList);
  }
  getCustomerInvoiceImportList(){
    const getCustomerInvoiceImportList = URLS.getCustomerInvoiceImportList;
    return this.http.get(getCustomerInvoiceImportList);
  }
  getVendorOpenInvoiceImportList(){
    const getVendorOpenInvoiceImportList = URLS.getVendorOpenInvoiceImportList;
    return this.http.get(getVendorOpenInvoiceImportList);
  }
  createVendorOpenInvoiceImport(formData){
    const createVendorOpenInvoiceImport = URLS.createVendorOpenInvoiceImport;
    return this.http.post(createVendorOpenInvoiceImport,formData);
  }
  createPaycheckHolding(formData){
    const createPaycheckHolding = URLS.createPaycheckHolding;
    return this.http.post(createPaycheckHolding,formData);
  }
  createPaycheckExpenses(formData){
    const createPaycheckExpenses = URLS.createPaycheckExpenses;
    return this.http.post(createPaycheckExpenses,formData);
  }
  getSetupUserImportList(){
    const getSetupUserImportList = URLS.getSetupUserImportList;
    return this.http.get(getSetupUserImportList);
  }
  createSetupUserImport(formData){
    const createSetupUserImport = URLS.createSetupUserImport;
    return this.http.post(createSetupUserImport,formData);
  }

  editorAddProductBacklogs(formData){
    const editorAddProductBacklogs = URLS.editorAddProductBacklogs;
    return this.http.post(editorAddProductBacklogs,formData);
  }


  createContract(formData){
    const createContract = URLS.createContract;
    return this.http.post(createContract,formData);
  }
 
  createContractDetails(formData){
    const createContractDetails = URLS.createContractDetails;
    return this.http.post(createContractDetails,formData);
  }

  createWorkingSchedule(formData){
    const createWorkingSchedule = URLS.createWorkingSchedule;
    return this.http.post(createWorkingSchedule,formData);
  }

  createWorkingHours(formData){
    const createWorkingHours = URLS.createWorkingHours;
    return this.http.post(createWorkingHours,formData);
  }

  deleteWorkingHours(req){
    const deleteWorkingHours = URLS.deleteWorkingHours;
    return this.http.post(deleteWorkingHours,req);
  }

  updateWorkingHours(formData){
    const updateWorkingHours = URLS.updateWorkingHours;
    return this.http.post(updateWorkingHours,formData);
  }

  updateWorkingSchedule(formData){
    const updateWorkingSchedule = URLS.updateWorkingSchedule;
    return this.http.post(updateWorkingSchedule,formData);
  }
  getProductBacklogsListScrum(): Observable<any> {
    const getProductBacklogsListScrum = URLS.getProductBacklogsListScrum;
    return this.http.get(getProductBacklogsListScrum);
  }
  getFindProduct(productId,internalName,statusID): Observable<any> {
    const getFindProduct = URLS.getFindProduct + "?productId=" + productId + "&internalName=" + internalName + "&statusID=" + statusID;
    return this.http.get(getFindProduct);
  }
  getProductList(): Observable<any> {
    const getFindProduct = URLS.getFindProduct ;
    return this.http.get(getFindProduct);
  }

 
  getProductStatus(): Observable<any> {
    const getProductStatus = URLS.getProductStatus ;
    return this.http.get(getProductStatus);
  }
  getProductOverviewProductInfo(productId): Observable<any> {
    const getProductOverviewProductInfo = URLS.getProductOverviewProductInfo + "?productId=" + productId  ;
    return this.http.get(getProductOverviewProductInfo);
  }
  getProductOverviewMemberList(productId): Observable<any> {
    const getProductOverviewMemberList = URLS.getProductOverviewMemberList + "?productId=" + productId  ;
    return this.http.get(getProductOverviewMemberList);
  }
  getCompanyAndProductOwnerList(): Observable<any> {
    const getCompanyAndProductOwnerList = URLS.getCompanyAndProductOwnerList ;
    return this.http.get(getCompanyAndProductOwnerList);
  }
  getProductOverviewProductContent(productId): Observable<any> {
    const getProductOverviewProductContent = URLS.getProductOverviewProductContent  + "?productId=" + productId ;
    return this.http.get(getProductOverviewProductContent);
  }
  getEmailsProductBacklogs(): Observable<any> {
    const getEmailsProductBacklogs = URLS.getEmailsProductBacklogs ;
    return this.http.get(getEmailsProductBacklogs);
  }
  getProjectAndSprintList(): Observable<any> {
    const getProjectAndSprintList = URLS.getProjectAndSprintList ;
    return this.http.get(getProjectAndSprintList);
  }
  productUpdate(formData){
    const productUpdate = URLS.productUpdate;
    return this.http.post(productUpdate,formData);
  }
  addProductMember(formData){
    const addProductMember = URLS.addProductMember;
    return this.http.post(addProductMember,formData);
  }
  deleteBacklogItem(formData){
    const deleteBacklogItem = URLS.deleteBacklogItem;
    return this.http.post(deleteBacklogItem,formData);
  }

  removeProductMemberListBySingle(formData){
    const removeProductMemberListBySingle = URLS.removeProductMemberListBySingle;
    return this.http.post(removeProductMemberListBySingle,formData);
  }
  addCompanyAndProductOwner(formData){
    const addCompanyAndProductOwner = URLS.addCompanyAndProductOwner;
    return this.http.post(addCompanyAndProductOwner,formData);
  }
  getRoleTypeIdList(): Observable<any> {
    const getRoleTypeIdList = URLS.getRoleTypeIdList ;
    return this.http.get(getRoleTypeIdList);
  }
  getTotalBacklog(): Observable<any> {
    const getFindTotalBacklog = URLS.getFindTotalBacklog ;
    return this.http.get(getFindTotalBacklog);
  }
  getFindTotalBacklog(billed , custEstimatedMilliSeconds , custRequestDate, custRequestId, description , fromPartyId, parentCustRequestId ,statusId   ): Observable<any> {
    const getFindTotalBacklog = URLS.getFindTotalBacklog + "?billed=" + billed + "&custEstimatedMilliSeconds=" + custEstimatedMilliSeconds +"&custRequestDate=" + custRequestDate +
    "&custRequestId=" + custRequestId +"&description=" + description +"&fromPartyId=" + fromPartyId +"&parentCustRequestId=" + parentCustRequestId  +"&statusId=" + statusId    ;
    return this.http.get(getFindTotalBacklog);
  }
  getEditBacklogsProductItemList(productId): Observable<any> {
    const getEditBacklogsProductItemList = URLS.getEditBacklogsProductItemList+ "?productId=" + productId  ;
    return this.http.get(getEditBacklogsProductItemList);
  }
  deleteProductBackLogsItem(custRequestId, productId,statusId): Observable<any> {
    const deleteProductBackLogsItem = URLS.deleteProductBackLogsItem + "?custRequestId=" + custRequestId + "&productId=" + productId +"&statusId=" + statusId ;
    return this.http.delete(deleteProductBackLogsItem);
  }

  deleteProductMember(productId): Observable<any> {
    const deleteProductMember = URLS.deleteProductMember + "?productId=" + productId  ;
    return this.http.delete(deleteProductMember);
  }

  
  productBackLogsItemSubmit(formData){
    const productBackLogsItemSubmit = URLS.productBackLogsItemSubmit;
    return this.http.post(productBackLogsItemSubmit,formData);
  }
  deleteDefaultTasks(formData){
    const deleteDefaultTasks = URLS.deleteDefaultTasks;
    return this.http.delete(deleteDefaultTasks,formData);
  }
  addNewProductBacklogsItem(formData){
    const addNewProductBacklogsItem = URLS.addNewProductBacklogsItem;
    return this.http.post(addNewProductBacklogsItem,formData);
  }
  getFindBillingList(): Observable<any> {
    const getFindBilling = URLS.getFindBilling ;
    return this.http.get(getFindBilling);
  }
  getDefaultTask(): Observable<any> {
    const getDefaultTask = URLS.getDefaultTask ;
    return this.http.get(getDefaultTask);
  }
  getDefaultTaskTypeList(): Observable<any> {
    const getDefaultTaskTypeList = URLS.getDefaultTaskTypeList ;
    return this.http.get(getDefaultTaskTypeList);
  }
  getFindBilling(fromDate,includeMeeting,thruDate): Observable<any> {
    const getFindBilling = URLS.getFindBilling + "?fromDate=" + fromDate + "&includeMeeting=" + includeMeeting +"&thruDate=" + thruDate ;
    return this.http.get(getFindBilling);
  }
  getForBillingPage(productId): Observable<any> {
    const getForBillingPage = URLS.getForBillingPage + "?productId=" + productId ;
    return this.http.get(getForBillingPage);
  }
  getTaskList(productId): Observable<any> {
    const getTaskList = URLS.getTaskList + "?productId=" + productId ;
    return this.http.get(getTaskList);
  }
  getScrumProductContentListData(productId): Observable<any> {
    const getScrumProductContentListData = URLS.getScrumProductContentListData + "?productId=" + productId ;
    return this.http.get(getScrumProductContentListData);
  }
  getSprintTask(productId,custRequestId): Observable<any> {
    const getSprintTask = URLS.getSprintTask + "?productId=" + productId + "&custRequestId=" + custRequestId ;
    return this.http.get(getSprintTask);
  }
  getCategoryListData(productId): Observable<any> {
    const getCategoryListData = URLS.getCategoryListData + "?productId=" + productId ;
    return this.http.get(getCategoryListData);
  }
  updateNotBilledBacklog(formData){
    const updateNotBilledBacklog = URLS.updateNotBilledBacklog;
    return this.http.post(updateNotBilledBacklog,formData);
  }
  createDefaultTasks(formData){
    const createDefaultTasks = URLS.createDefaultTasks;
    return this.http.post(createDefaultTasks,formData);
  }
  DeleteUnplanBacklog(formData){
    const DeleteUnplanBacklog = URLS.DeleteUnplanBacklog;
    return this.http.post(DeleteUnplanBacklog,formData);
  }
  setCompleteFromUnplanBacklog(formData){
    const setCompleteFromUnplanBacklog = URLS.setCompleteFromUnplanBacklog;
    return this.http.post(setCompleteFromUnplanBacklog,formData);
  }
  addTask(formData){
    const addTask = URLS.addTask;
    return this.http.post(addTask,formData);
  }
  QuickAddBacklog(formData){
    const QuickAddBacklog = URLS.QuickAddBacklog;
    return this.http.post(QuickAddBacklog,formData);
  }
  createCategory_productBacklog(formData){
    const createCategory_productBacklog = URLS.createCategory_productBacklog;
    return this.http.post(createCategory_productBacklog,formData);
  }
  updateProfilePerson(id, formData): Observable<any> {
    const updateProfilePerson = URLS.updateProfilePerson.replace(":partyId", id);
    return this.http.put(updateProfilePerson, formData)
  }
 
  addProductTimeToNewInvoice(formData){
    const addProductTimeToNewInvoice = URLS.addProductTimeToNewInvoice;
    return this.http.post(addProductTimeToNewInvoice,formData);
  }
  GetUnplanBacklogList(): Observable<any> {
    const GetUnplanBacklogList = URLS.GetUnplanBacklogList   ;
    return this.http.get(GetUnplanBacklogList);
  }
  getIdentificationNumbersNew(partyId): Observable<any> {
    const getIdentificationNumbersNew = URLS.getIdentificationNumbersNew + "?partyId=" + partyId  ;
    return this.http.get(getIdentificationNumbersNew);
  }
  getUserName(partyId): Observable<any> {
    const getUserName = URLS.getUserName + "?partyId=" + partyId  ;
    return this.http.get(getUserName);
  }

  getVisits(partyId): Observable<any> {
    const getVisits = URLS.getVisits + "?partyId=" + partyId  ;
    return this.http.get(getVisits);
  }
  wasChanged(partyId): Observable<any> {
    const wasChanged = URLS.wasChanged + "?partyId=" + partyId  ;
    return this.http.get(wasChanged);
  }

  getCompanyAndProductList(): Observable<any> {
    const getCompanyAndProductList = URLS.getCompanyAndProductList   ;
    return this.http.get(getCompanyAndProductList);
  }
  getLoyaltyPoints(partyId): Observable<any> {
    const getLoyaltyPoints = URLS.getLoyaltyPoints  + "?partyId=" + partyId  ;
    return this.http.get(getLoyaltyPoints);
  }
  getFromPartyIdRequesterName(): Observable<any> {
    const getFromPartyIdRequesterName = URLS.getFromPartyIdRequesterName;
    return this.http.get(getFromPartyIdRequesterName);
  }
 
  getProductMemberRole(): Observable<any> {
    const getProductMemberRole = URLS.getProductMemberRole   ;
    return this.http.get(getProductMemberRole);
  }

  getProductMemberParty(): Observable<any> {
    const getProductMemberParty = URLS.getProductMemberParty   ;
    return this.http.get(getProductMemberParty);
  }
  getBacklogItemCategory(): Observable<any> {
    const getBacklogItemCategory = URLS.getBacklogItemCategory   ;
    return this.http.get(getBacklogItemCategory);
  }
  getContentTypeId(): Observable<any> {
    const getContentTypeId = URLS.getContentTypeId   ;
    return this.http.get(getContentTypeId);
  }
  getMimeTypeId(): Observable<any> {
    const getMimeTypeId = URLS.getMimeTypeId   ;
    return this.http.get(getMimeTypeId);
  }
  getAssignToList(): Observable<any> {
    const getAssignToList = URLS.getAssignToList   ;
    return this.http.get(getAssignToList);
  }

  getProductBacklogsItemList(productId): Observable<any> {
    const getProductBacklogsItemList = URLS.getProductBacklogsItemList + "?productId=" + productId ;
    return this.http.get(getProductBacklogsItemList);
  }
 

  getSprintOverview(productId,custRequestId): Observable<any> {
    const getSprintOverview = URLS.getSprintOverview + "?productId=" + productId+ "&custRequestId=" + custRequestId ;
    return this.http.get(getSprintOverview);
  }
  
  getTrackingCodeReport(fromDate,thruDate,trackingCodeId): Observable<any> {
    const getTrackingCodeReport = URLS.getTrackingCodeReport + "?fromDate=" + fromDate+ "&thruDate=" + thruDate + "&trackingCodeId=" + trackingCodeId ;
    return this.http.post(getTrackingCodeReport,'');
  }
  getTrackingCodeReportWith(): Observable<any> {
    const getTrackingCodeReport = URLS.getTrackingCodeReport;
    return this.http.post(getTrackingCodeReport,'');
  }
  
  getEmailReport(fromDate,thruDate,partyIdFrom,partyIdTo,roleStatusId,statusId): Observable<any> {
    const getEmailReport = URLS.getEmailReport + "?fromDate=" + fromDate+ "&thruDate=" + thruDate + "&partyIdFrom=" + partyIdFrom + "&partyIdTo=" + partyIdTo + "&roleStatusId=" + roleStatusId + "&statusId=" + statusId ;
    return this.http.post(getEmailReport,'');
  }
  creatingContactList(req): Observable<any> {
    const creatingContactList = URLS.creatingContactList;
    return this.http.post(creatingContactList, req);

  }
  
  updateContactListMarket(req): Observable<any> {
    const updateContactListMarket = URLS.updateContactListMarket;
    return this.http.post(updateContactListMarket, req);

  }
  
  createWebSiteContactList(req): Observable<any> {
    const createWebSiteContactList = URLS.createWebSiteContactList;
    return this.http.post(createWebSiteContactList, req);

  }
  
  findContactListParties(req): Observable<any> {
    const findContactListParties = URLS.findContactListParties;
    return this.http.post(findContactListParties, req);

  }
  
  findContactList_commEvents(req): Observable<any> {
    const findContactList_commEvents = URLS.findContactList_commEvents;
    return this.http.post(findContactList_commEvents, req);

  }
  
  expireContactListParty(req): Observable<any> {
    const expireContactListParty = URLS.expireContactListParty;
    return this.http.post(expireContactListParty, req);

  }
  
  updateWebSiteContactList(req): Observable<any> {
    const updateWebSiteContactList = URLS.updateWebSiteContactList;
    return this.http.put(updateWebSiteContactList, req);

  }
  deleteWebSiteContactList(req): Observable<any> {
    const deleteWebSiteContactList = URLS.deleteWebSiteContactList;
    return this.http.post(deleteWebSiteContactList, req);

  }
  getEmailReportWith(): Observable<any> {
    const getEmailReport = URLS.getEmailReport;
    return this.http.post(getEmailReport,'');
  }
  getMarketCampaignReport(fromDate,thruDate,marketingCampaignId): Observable<any> {
    const getMarketCampaignReport = URLS.getMarketCampaignReport + "?fromDate=" + fromDate+ "&thruDate=" + thruDate + "&marketingCampaignId=" + marketingCampaignId;
    return this.http.post(getMarketCampaignReport,'');
  }
  getMarketCampaignReportWith(): Observable<any> {
    const getMarketCampaignReport = URLS.getMarketCampaignReport;
    return this.http.post(getMarketCampaignReport,'');
  }
  
  getPartyReport(fromDate,thruDate,contactListId,statusDate,statusId): Observable<any> {
    const getPartyReport = URLS.getPartyReport + "?fromDate=" + fromDate+ "&thruDate=" + thruDate + "&contactListId=" + contactListId + "&statusDate=" + statusDate + "&statusId=" + statusId;
    return this.http.post(getPartyReport,'');
  }
  getPartyReportWith(): Observable<any> {
    const getPartyReport = URLS.getPartyReport ;
    return this.http.post(getPartyReport,'');
  }
  createDefaultTask(formData){
    const createDefaultTask = URLS.createDefaultTask;
    return this.http.post(createDefaultTask,formData);
  }
  updateDefaultTask(formData): Observable<any> {
    
    const updateDefaultTask = URLS.updateDefaultTask;
    return this.http.put(updateDefaultTask, formData);
  }

  deleteScrumProductContent(contentId): Observable<any> {
    const deleteScrumProductContent = URLS.deleteScrumProductContent + "?contentId=" + contentId  ;
    return this.http.delete(deleteScrumProductContent);
  }
  deleteDefaultTask(formData): Observable<any> {
    const deleteDefaultTask = URLS.deleteDefaultTask  ;
    return this.http.delete(deleteDefaultTask,formData);
  }
  createScrumProductContent(formData,contentTypeId,mimeTypeId,productId): Observable<any> {
    const createScrumProductContent = URLS.createScrumProductContent + "?contentTypeId=" + contentTypeId + "&mimeTypeId=" + mimeTypeId + "&productId=" + productId;
    return this.http.post(createScrumProductContent, formData);
  }
  updateEmailForProduct(formData): Observable<any> {
    const updateEmailForProduct = URLS.updateEmailForProduct ;
    return this.http.post(updateEmailForProduct, formData);
  }
  uploadEmailContent(formData,productId): Observable<any> {
    const uploadEmailContent = URLS.uploadEmailContent + "?productId=" + productId ;
    return this.http.post(uploadEmailContent, formData);
  }
  getCommunicationList(productId): Observable<any> {
    const getCommunicationList = URLS.getCommunicationList + "?productId=" + productId ;
    return this.http.get(getCommunicationList);
  }
  getEmailContentData(productId): Observable<any> {
    const getEmailContentData = URLS.getEmailContentData + "?productId=" + productId ;
    return this.http.get(getEmailContentData);
  }
  getProductUpdateEmail(productId): Observable<any> {
    const getProductUpdateEmail = URLS.getProductUpdateEmail + "?productId=" + productId ;
    return this.http.get(getProductUpdateEmail);
  }
  getNewProjectProductName(productId): Observable<any> {
    const getNewProjectProductName = URLS.getNewProjectProductName + "?productId=" + productId ;
    return this.http.get(getNewProjectProductName);
  }
  downloadTaskById(imageUrl): Observable<any> {
    const downloadTaskById = URLS.getdownloadTaskById + "?imageUrl=" + imageUrl ;
    return this.http.get(downloadTaskById, { responseType: "blob" });
  }
  getDownloadImageProductContent(imageUrl): Observable<any> {
    const getDownloadImageProductContent = URLS.getDownloadImageProductContent + "?imageUrl=" + imageUrl ;
    return this.http.get(getDownloadImageProductContent, { responseType: "blob" });
  }
  getEmailContentDownload(imageUrl): Observable<any> {
    const getEmailContentDownload = URLS.getEmailContentDownload + "?imageUrl=" + imageUrl ;
    return this.http.get(getEmailContentDownload, { responseType: "blob" });
  }
  generatePDFUpdateSalesForecast(salesForecastId): Observable<any> {
    const generatePDFUpdateSalesForecast = URLS.generatePDFUpdateSalesForecast + "?salesForecastId=" + salesForecastId ;
    return this.http.get(generatePDFUpdateSalesForecast, { responseType: "blob" });
  }
  createScrumProject(formData): Observable<any> {
    const createScrumProject = URLS.createScrumProject ;
    return this.http.post(createScrumProject, formData);
  }
  getChildCommunicationOverviewEvent(productId,communicationEventId): Observable<any> {
    const getChildCommunicationOverviewEvent = URLS.getChildCommunicationOverviewEvent + "?productId=" + productId + "&communicationEventId=" + communicationEventId  ;
    return this.http.get(getChildCommunicationOverviewEvent);
  }
  getCommunicationOverviewEventRoles(productId,communicationEventId): Observable<any> {
    const getCommunicationOverviewEventRoles = URLS.getCommunicationOverviewEventRoles + "?productId=" + productId + "&communicationEventId=" + communicationEventId  ;
    return this.http.get(getCommunicationOverviewEventRoles);
  }
  getCommunicationOverviewEvent(productId,communicationEventId): Observable<any> {
    const getCommunicationOverviewEvent = URLS.getCommunicationOverviewEvent + "?productId=" + productId + "&communicationEventId=" + communicationEventId  ;
    return this.http.get(getCommunicationOverviewEvent);
  }
  getProjectSprintFrontPage(): Observable<any> {
    const getProjectSprintFrontPage = URLS.getProjectSprintFrontPage  ;
    return this.http.get(getProjectSprintFrontPage);
  }
  getProjectSprintProductName(): Observable<any> {
    const getProjectSprintProductName = URLS.getProjectSprintProductName  ;
    return this.http.get(getProjectSprintProductName);
  }
  createSprint(formData): Observable<any> {
    const createSprint = URLS.createSprint ;
    return this.http.post(createSprint, formData);
  }
  closeProduct(formData): Observable<any> {
    const closeProduct = URLS.closeProduct ;
    return this.http.post(closeProduct, formData);
  }
  getProjectSprintOverview(productId): Observable<any> {
    const getProjectSprintOverview = URLS.getProjectSprintOverview + "?productId=" + productId  ;
    return this.http.get(getProjectSprintOverview);
  }
  getProjectSprintProductNameUpdate(productId): Observable<any> {
    const getProjectSprintProductNameUpdate = URLS.getProjectSprintProductNameUpdate + "?productId=" + productId  ;
    return this.http.get(getProjectSprintProductNameUpdate);
  }
  getMoveToProduct(): Observable<any> {
    const getMoveToProduct = URLS.getMoveToProduct   ;
    return this.http.get(getMoveToProduct);
  }
  getSprintLists(productId): Observable<any> {
    const getSprintLists = URLS.getSprintLists +  "?productId=" + productId  ;
    return this.http.get(getSprintLists);
  }
  updateScrumProject(formData): Observable<any> {
    const updateScrumProject = URLS.updateScrumProject ;
    return this.http.post(updateScrumProject, formData);
  }
  updateSprintClose(formData): Observable<any> {
    const updateSprintClose = URLS.updateSprintClose ;
    return this.http.post(updateSprintClose, formData);
  }
  createProjectScrum(formData): Observable<any> {
    const createProjectScrum = URLS.createProjectScrum ;
    return this.http.post(createProjectScrum, formData);
  }
  getListofSprintBacklogs(): Observable<any> {
    const getListofSprintBacklogs = URLS.getListofSprintBacklogs  ;
    return this.http.get(getListofSprintBacklogs);
  }
  getProductBacklogItemStatusList(productId,custRequestId): Observable<any> {
    const getProductBacklogItemStatusList = URLS.getProductBacklogItemStatusList +  "?productId=" + productId +  "&custRequestId=" + custRequestId  ;
    return this.http.get(getProductBacklogItemStatusList);
  }
  updateProductBacklogs(formData): Observable<any> {
    const updateProductBacklogs = URLS.updateProductBacklogs ;
    return this.http.post(updateProductBacklogs, formData);
  }
  createTaskNote(formData): Observable<any> {
    const createTaskNote = URLS.createTaskNote ;
    return this.http.post(createTaskNote, formData);
  }
  createProdBacklogNote(formData): Observable<any> {
    const createProdBacklogNote = URLS.createProdBacklogNote ;
    return this.http.post(createProdBacklogNote, formData);
  }
  BacklogItemContentList(custRequestId): Observable<any> {
    const BacklogItemContentList = URLS.BacklogItemContentList  +  "?custRequestId=" + custRequestId  ;
    return this.http.get(BacklogItemContentList);
  }
  getBacklogItemNoteList(custRequestId): Observable<any> {
    const getBacklogItemNoteList = URLS.getBacklogItemNoteList  +  "?custRequestId=" + custRequestId  ;
    return this.http.get(getBacklogItemNoteList);
  }
  deleteProdBacklogNote(formData): Observable<any> {
    const deleteProdBacklogNote = URLS.deleteProdBacklogNote ;
    return this.http.post(deleteProdBacklogNote, formData);
  }
 
  getProductName(): Observable<any> {
    const getProductName = URLS.getProductName  ;
    return this.http.get(getProductName);
  }
 
  findOpenTestBacklog(productId): Observable<any> {
    const findOpenTestBacklog = URLS.findOpenTestBacklog +  "?productId=" + productId  ;
    return this.http.get(findOpenTestBacklog);
  }
  findBacklog(): Observable<any> {
    const findBacklog = URLS.findBacklog  ;
    return this.http.get(findBacklog);
  }
  FindTaskRevision(custRequestId,productId,workEffortId): Observable<any> {
    const FindTaskRevision = URLS.FindTaskRevision  +  "?custRequestId=" + custRequestId +  "&productId=" + productId +  "&workEffortId=" + workEffortId  ;
    return this.http.get(FindTaskRevision);
  }
  FindTaskRevisionList(): Observable<any> {
    const FindTaskRevision = URLS.FindTaskRevision  ;
    return this.http.get(FindTaskRevision);
  }
  createTaskForBacklog(formData): Observable<any> {
    const createTaskForBacklog = URLS.createTaskForBacklog ;
    return this.http.post(createTaskForBacklog, formData);
  }
  getAssignTo(): Observable<any> {
    const getAssignTo = URLS.getAssignTo  ;
    return this.http.get(getAssignTo);
  }
  getRoleTypeList(): Observable<any> {
    const getRoleTypeList = URLS.getRoleTypeList  ;
    return this.http.get(getRoleTypeList);
  }
  getSprintBacklogList(): Observable<any> {
    const getSprintBacklogList = URLS.getSprintBacklogList  ;
    return this.http.get(getSprintBacklogList);
  }
  getProjectID(): Observable<any> {
    const getProjectID = URLS.getProjectID  ;
    return this.http.get(getProjectID);
  }
  getStatusTaskList(): Observable<any> {
    const getStatusTaskList = URLS.getStatusTaskList  ;
    return this.http.get(getStatusTaskList);
  }
  getStatusList(): Observable<any> {
    const getStatusList = URLS.getStatusList  ;
    return this.http.get(getStatusList);
  }
  ProjectMembersRole(partyId): Observable<any> {
    const ProjectMembersRole = URLS.ProjectMembersRole   +  "?partyId=" + partyId  ;
    return this.http.get(ProjectMembersRole);
  }
  getNoteOverview(workEffortId): Observable<any> {
    const getNoteOverview = URLS.getNoteOverview   +  "?workEffortId=" + workEffortId  ;
    return this.http.get(getNoteOverview);
  }

  getUpdatePage(workEffortId): Observable<any> {
    const getUpdatePage = URLS.getUpdatePage   +  "?workEffortId=" + workEffortId  ;
    return this.http.get(getUpdatePage);
  }

  getTaskAttachments(workEffortId): Observable<any> {
    const getTaskAttachments = URLS.getTaskAttachments   +  "?workEffortId=" + workEffortId  ;
    return this.http.get(getTaskAttachments);
  }
  getScrumMembersList(): Observable<any> {
    const getScrumMembersList = URLS.getScrumMembersList  ;
    return this.http.get(getScrumMembersList);
  }

  getScrumPerson(): Observable<any> {
    const getScrumPerson = URLS.getScrumPerson   ;
    return this.http.get(getScrumPerson);
  }
  getTaskOverview(workEffortId): Observable<any> {
    const getTaskOverview = URLS.getTaskOverview   +  "?workEffortId=" + workEffortId  ;
    return this.http.get(getTaskOverview);
  }
  getProjectMembers(partyId): Observable<any> {
    const getProjectMembers = URLS.getProjectMembers   +  "?partyId=" + partyId  ;
    return this.http.get(getProjectMembers);
  }
  getFindTimeSheet(comments , fromDate , partyId, statusId, timesheetId): Observable<any> {
    const FindTimeSheet = URLS.FindTimeSheet + "?comments=" + comments + "&fromDate=" + fromDate +"&partyId=" + partyId +
    "&statusId=" + statusId +"&timesheetId=" + timesheetId     ;
    return this.http.get(FindTimeSheet);
  }

  FindTimeSheet(): Observable<any> {
    const FindTimeSheet = URLS.FindTimeSheet;
    return this.http.get(FindTimeSheet);
  }
  findTask(req): Observable<any> {
    const findTask = URLS.findTask ;
    return this.http.post(findTask,req);
  }
  
  getUnplannedList(): Observable<any> {
    const getUnplannedList = URLS.getUnplannedList  ;
    return this.http.get(getUnplannedList);
  }
  deleteTaskNote(formData): Observable<any> {
    const deleteTaskNote = URLS.deleteTaskNote ;
    return this.http.post(deleteTaskNote, formData);
  }
  updateProductBacklog(formData): Observable<any> {
    const updateProductBacklog = URLS.updateProductBacklog ;
    return this.http.post(updateProductBacklog, formData);
  }
  updateTask(formData): Observable<any> {
    const updateTask = URLS.updateTask ;
    return this.http.post(updateTask, formData);
  }
  addMemberTask(formData): Observable<any> {
    const addMemberTask = URLS.addMemberTask ;
    return this.http.post(addMemberTask, formData);
  }
  setTaskStatus(formData): Observable<any> {
    const setTaskStatus = URLS.setTaskStatus ;
    return this.http.post(setTaskStatus, formData);
  }
  removeMemberTask(formData): Observable<any> {
    const removeMemberTask = URLS.removeMemberTask ;
    return this.http.post(removeMemberTask, formData);
  }
  createPartyRelationship(formData): Observable<any> {
    const createPartyRelationship = URLS.createPartyRelationship ;
    return this.http.post(createPartyRelationship, formData);
  }
  createNewParty(formData): Observable<any> {
    const createNewParty = URLS.createNewParty ;
    return this.http.post(createNewParty, formData);
  }
  getRateTypeList(): Observable<any> {
    const getRateTypeList = URLS.getRateTypeList  ;
    return this.http.get(getRateTypeList);
  }
  getGroupIdList(): Observable<any> {
    const getGroupIdList = URLS.getGroupIdList  ;
    return this.http.get(getGroupIdList);
  }
  getDefaultTaskList(): Observable<any> {
    const getDefaultTaskList = URLS.getDefaultTaskList  ;
    return this.http.get(getDefaultTaskList);
  }
  removeScrumMember(formData): Observable<any> {
    const removeScrumMember = URLS.removeScrumMember ;
    return this.http.delete(removeScrumMember, formData);
  }
  addScrumMember(formData): Observable<any> {
    const addScrumMember = URLS.addScrumMember ;
    return this.http.post(addScrumMember, formData);
  }
  getFindBillingAdministration(): Observable<any> {
    const getFindBillingAdministration = URLS.getFindBillingAdministration  ;
    return this.http.get(getFindBillingAdministration);
  }

  getBillingAdministration( includeMeeting , productId): Observable<any> {
    const getFindBillingAdministration = URLS.getFindBillingAdministration + "?includeMeeting=" + includeMeeting  +"&productId=" + productId   ;
    return this.http.get(getFindBillingAdministration);
  }
  getFindTotalBacklogAdministration(): Observable<any> {
    const getFindTotalBacklogAdministration = URLS.getFindTotalBacklogAdministration  ;
    return this.http.get(getFindTotalBacklogAdministration);
  }

  getFindAllBacklogAdministration(billed  , custRequestDate, custRequestId, description, fromPartyId, parentCustRequestId, productId ,statusId): Observable<any> {
    const getFindTotalBacklogAdministration = URLS.getFindTotalBacklogAdministration + "?billed=" + billed  +"&custRequestDate=" + custRequestDate +
    "&custRequestId=" + custRequestId +"&description=" + description + "&fromPartyId=" + fromPartyId +"&parentCustRequestId=" + parentCustRequestId  + "&productId=" + productId +"&statusId=" + statusId     ;
    return this.http.get(getFindTotalBacklogAdministration);
  }

  updateQuickAddPartyRate(formData): Observable<any> {
    
    const updateQuickAddPartyRate = URLS.updateQuickAddPartyRate;
    return this.http.put(updateQuickAddPartyRate, formData);
  }

  getAttributeParty(id): Observable<any> {
    const getAttributeParty = URLS.getAttributeParty + "?partyId=" + id;
    return this.http.get(getAttributeParty);
  }
  getProductStoreGroupAll(): Observable<any> {
    const getProductStoreGroupAll = URLS.getProductStoreGroupAll;
    return this.http.get(getProductStoreGroupAll);
  }
  getProductStoreGroupRollUpById(id): Observable<any> {
    const getProductStoreGroupRollUpById = URLS.getProductStoreGroupRollUpById + "?productStoreGroupId=" + id;
    return this.http.get(getProductStoreGroupRollUpById);
  }
  getProductStoreGroupMemberById(id): Observable<any> {
    const getProductStoreGroupMemberById = URLS.getProductStoreGroupMemberById + "?productStoreGroupId=" + id;
    return this.http.get(getProductStoreGroupMemberById);
  }

  getPartyNotes(id): Observable<any> {
    const getPartyNotes = URLS.getPartyNotes.replace(":partyId", id);
    return this.http.get(getPartyNotes);
  }
  getPartyContentProfileList(id): Observable<any> {
    const getPartyContentProfileList = URLS.getPartyContentProfileList.replace(":partyId", id);
    return this.http.get(getPartyContentProfileList);
  }
  
  getMarketingCampaignById(id): Observable<any> {
    const getMarketingCampaignById = URLS.getMarketingCampaignById.replace(":id", id);
    return this.http.get(getMarketingCampaignById);
  }

  getPartyVisitors(data): Observable<any> {
    const getPartyVisitors = URLS.getPartyVisitors + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getPartyVisitors);
  }
   
  getStores(id): Observable<any> {
    const getStores = URLS.getStores + "?partyId=" + id;
    return this.http.get(getStores);
  }
  getPartyAvsOverRide(id): Observable<any> {
    const getPartyAvsOverRide = URLS.getPartyAvsOverRide + "?partyId=" + id;
    return this.http.get(getPartyAvsOverRide);
  }
  
  getMarketingCampaignRole(id): Observable<any> {
    const getMarketingCampaignRole = URLS.getMarketingCampaignRole + "?marketingCampaignId=" + id;
    return this.http.get(getMarketingCampaignRole);
  }
  updatePartyProfileAttribute(fromData): Observable<any> {
    const updatePartyProfileAttribute = URLS.updatePartyProfileAttribute;
    return this.http.put(updatePartyProfileAttribute, fromData);
  }
   
  createprofileUserLogin(formData): Observable<any> {
    const createprofileUserLogin = URLS.createprofileUserLogin;
    return this.http.post(createprofileUserLogin,formData);
  }

  getProductStoreGroupList(): Observable<any> {
    const getProductStoreGroupList = URLS.getProductStoreGroupList ;
    return this.http.get(getProductStoreGroupList);
  }
  getLookAndFeelWebBasedStore(): Observable<any> {
    const getLookAndFeelWebBasedStore = URLS.getLookAndFeelWebBasedStore ;
    return this.http.get(getLookAndFeelWebBasedStore);
  }
  getInventoryFacilityId(): Observable<any> {
    const getInventoryFacilityId = URLS.getInventoryFacilityId ;
    return this.http.get(getInventoryFacilityId);
  }
  getRequirementMethodEnumId(): Observable<any> {
    const getRequirementMethodEnumId = URLS.getRequirementMethodEnumId ;
    return this.http.get(getRequirementMethodEnumId);
  }
  getScoreCreditAccountList(): Observable<any> {
    const getScoreCreditAccountList = URLS.getScoreCreditAccountList ;
    return this.http.get(getScoreCreditAccountList);
  }
  getDefaultSalesChannelEnumList(): Observable<any> {
    const getDefaultSalesChannelEnumList = URLS.getDefaultSalesChannelEnumList ;
    return this.http.get(getDefaultSalesChannelEnumList);
  }
  getReserveOrderEnumId(): Observable<any> {
    const getReserveOrderEnumId = URLS.getReserveOrderEnumId ;
    return this.http.get(getReserveOrderEnumId);
  }
  getHeaderApprovedStatus(): Observable<any> {
    const getHeaderApprovedStatus = URLS.getHeaderApprovedStatus ;
    return this.http.get(getHeaderApprovedStatus);
  }
  getItemApprovedStatus(): Observable<any> {
    const getItemApprovedStatus = URLS.getItemApprovedStatus ;
    return this.http.get(getItemApprovedStatus);
  }
  getDigProdUploadCategoryList(): Observable<any> {
    const getDigProdUploadCategoryList = URLS.getDigProdUploadCategoryList ;
    return this.http.get(getDigProdUploadCategoryList);
  }
  getVatTaxAuthGeoId(): Observable<any> {
    const getVatTaxAuthGeoId = URLS.getVatTaxAuthGeoId ;
    return this.http.get(getVatTaxAuthGeoId);
  }
  createProductStore(req): Observable<any> {
    const createProductStore = URLS.createProductStore;
    return this.http.post(createProductStore, req);

  }
  getProductStoreList(): Observable<any> {
    const getProductStoreList = URLS.getProductStoreList ;
    return this.http.get(getProductStoreList);
  }
  updateProductStore(req): Observable<any> {
    const updateProductStore = URLS.updateProductStore;
    return this.http.post(updateProductStore, req);
  }
  getProductStoreById(id): Observable<any> {
    const getProductStoreById = URLS.getProductStoreById + "/" + id;
    return this.http.get(getProductStoreById);
  }
  getCreateProductGeo(): Observable<any> {
    const getCreateProductGeo = URLS.getCreateProductGeo ;
    return this.http.get(getCreateProductGeo);
  }
  getProductGeoEnumId(): Observable<any> {
    const getProductGeoEnumId = URLS.getProductGeoEnumId ;
    return this.http.get(getProductGeoEnumId);
  }
  createProductGeo(req): Observable<any> {
    const createProductGeo = URLS.createProductGeo;
    return this.http.post(createProductGeo, req);

  }
  updateProductGeo(req): Observable<any> {
    const updateProductGeo = URLS.updateProductGeo;
    return this.http.post(updateProductGeo, req);
  }
  getCreateProductGeoById(id): Observable<any> {
    const getCreateProductGeoById = URLS.getCreateProductGeoById + "?productId=" + id;
    return this.http.get(getCreateProductGeoById);
  }
  getGoodIdentificationById(id): Observable<any> {
    const getGoodIdentificationById = URLS.getGoodIdentificationById + "?productId=" + id;
    return this.http.get(getGoodIdentificationById);
  }
  getGoodIdentificationTypeId(): Observable<any> {
    const getGoodIdentificationTypeId = URLS.getGoodIdentificationTypeId;
    return this.http.get(getGoodIdentificationTypeId);
  }
  createGoodIdentification(req): Observable<any> {
    const createGoodIdentification = URLS.createGoodIdentification;
    return this.http.post(createGoodIdentification, req);
  }
  updateGoodIdentification(req): Observable<any> {
    const updateGoodIdentification = URLS.updateGoodIdentification;
    return this.http.post(updateGoodIdentification, req);
  }
  deleteGoodIdentification(req): Observable<any> {
    const deleteGoodIdentification = URLS.deleteGoodIdentification;
    return this.http.post(deleteGoodIdentification,req);
  }
  deleteProductGeo(req): Observable<any> {
    const deleteProductGeo = URLS.deleteProductGeo;
    return this.http.post(deleteProductGeo,req);
  }
  getsupplierById(id): Observable<any> {
    const getsupplierById = URLS.getsupplierById + "/" + id;
    return this.http.get(getsupplierById);
  }
  getAddProductToCategoryById(id): Observable<any> {
    const getAddProductToCategoryById = URLS.getAddProductToCategoryById + "?productId=" + id;
    return this.http.get(getAddProductToCategoryById);
  }
  addProductToCategory(req): Observable<any> {
    const addProductToCategory = URLS.addProductToCategory;
    return this.http.post(addProductToCategory, req);
  }
  getProductCategoryForStore(): Observable<any> {
    const getProductCategoryForStore = URLS.getProductCategoryForStore;
    return this.http.get(getProductCategoryForStore);
  }
  updateProductToCategory(req): Observable<any> {
    const updateProductToCategory = URLS.updateProductToCategory;
    return this.http.post(updateProductToCategory, req);
  }
  removeProductFromCategory(req): Observable<any> {
    const removeProductFromCategory = URLS.removeProductFromCategory;
    return this.http.post(removeProductFromCategory,req);
  }
  getproductsKeywordById(id): Observable<any> {
    const getproductsKeywordById = URLS.getproductsKeywordById + "?productId=" + id;
    return this.http.get(getproductsKeywordById);
  }
  getKeywordTypeIdsKeyword(id): Observable<any> {
    const getKeywordTypeIdsKeyword = URLS.getKeywordTypeIdsKeyword + "?productId=" + id;
    return this.http.get(getKeywordTypeIdsKeyword);
  }
  getKeywordTypeIdsTag(id): Observable<any> {
    const getKeywordTypeIdsTag = URLS.getKeywordTypeIdsTag + "?productId=" + id;
    return this.http.get(getKeywordTypeIdsTag);
  }
  keywordTypeIds(): Observable<any> {
    const keywordTypeIds = URLS.keywordTypeIds;
    return this.http.get(keywordTypeIds);
  }
  statusIds(): Observable<any> {
    const statusIds = URLS.statusIds;
    return this.http.get(statusIds);
  }
  createProductKeyword(req): Observable<any> {
    const createProductKeyword = URLS.createProductKeyword;
    return this.http.post(createProductKeyword, req);
  }
  updateProductKeyword(req): Observable<any> {
    const updateProductKeyword = URLS.updateProductKeyword;
    return this.http.put(updateProductKeyword, req);
  }
  deleteProductKeyword(productId,keywordTypeId,keyword): Observable<any> {
    const deleteProductKeyword = URLS.deleteProductKeyword + "/" + productId + "/" + keyword + "/" + keywordTypeId;
    return this.http.delete(deleteProductKeyword);
  }
  getProductAssocById(id): Observable<any> {
    const getProductAssocById = URLS.getProductAssocById + "?productId=" + id;
    return this.http.get(getProductAssocById);
  }
  getCatalogProductId(): Observable<any> {
    const getCatalogProductId = URLS.getCatalogProductId;
    return this.http.get(getCatalogProductId);
  }
  getProductAssocType(): Observable<any> {
    const getProductAssocType = URLS.getProductAssocType;
    return this.http.get(getProductAssocType);
  }
  createProductAssoc(req): Observable<any> {
    const createProductAssoc = URLS.createProductAssoc;
    return this.http.post(createProductAssoc, req);
  }
  updateProductAssoc(req): Observable<any> {
    const updateProductAssoc = URLS.updateProductAssoc;
    return this.http.post(updateProductAssoc, req);
  }
  deleteProductAssoc(req): Observable<any> {
    const deleteProductAssoc = URLS.deleteProductAssoc;
    return this.http.post(deleteProductAssoc,req);
  }
  getSelectSalesReps(): Observable<any> {
    const getSelectSalesReps = URLS.getSelectSalesReps;
    return this.http.get(getSelectSalesReps);
  }
  createPayCash(req): Observable<any> {
    const createPayCash = URLS.createPayCash;
    return this.http.post(createPayCash, req);
  }
  createpayCheck(req): Observable<any> {
    const createpayCheck = URLS.createpayCheck;
    return this.http.post(createpayCheck, req);
  }
  createpayGiftCard(req): Observable<any> {
    const createpayGiftCard = URLS.createpayGiftCard;
    return this.http.post(createpayGiftCard, req);
  }
  getProductAttributeById(id): Observable<any> {
    const getProductAttributeById = URLS.getProductAttributeById + "?productId=" + id;
    return this.http.get(getProductAttributeById);
  }
  createProductAttribute(req): Observable<any> {
    const createProductAttribute = URLS.createProductAttribute;
    return this.http.post(createProductAttribute, req);
  }
  updateProductAttribute(req): Observable<any> {
    const updateProductAttribute = URLS.updateProductAttribute;
    return this.http.post(updateProductAttribute, req);
  }
  deleteProductAttribute(req): Observable<any> {
    const deleteProductAttribute = URLS.deleteProductAttribute;
    return this.http.post(deleteProductAttribute,req);
  }
  getProductFacilityById(id): Observable<any> {
    const getProductFacilityById = URLS.getProductFacilityById + "?productId=" + id;
    return this.http.get(getProductFacilityById);
  }
  getProductFacilityId(): Observable<any> {
    const getProductFacilityId = URLS.getProductFacilityId;
    return this.http.get(getProductFacilityId);
  }
  createProductFacility(req): Observable<any> {
    const createProductFacility = URLS.createProductFacility;
    return this.http.post(createProductFacility, req);
  }
  updateProductFacility(req): Observable<any> {
    const updateProductFacility = URLS.updateProductFacility;
    return this.http.post(updateProductFacility, req);
  }
  deleteProductFacility(req): Observable<any> {
    const deleteProductFacility = URLS.deleteProductFacility;
    return this.http.post(deleteProductFacility,req);
  }
  getProductFacilityLocationById(id): Observable<any> {
    const getProductFacilityLocationById = URLS.getProductFacilityLocationById + "?productId=" + id;
    return this.http.get(getProductFacilityLocationById);
  }
  getLocationSeqId(): Observable<any> {
    const getLocationSeqId = URLS.getLocationSeqId;
    return this.http.get(getLocationSeqId);
  }
  createProductFacilityLocation(req): Observable<any> {
    const createProductFacilityLocation = URLS.createProductFacilityLocation;
    return this.http.post(createProductFacilityLocation, req);
  }
  updateProductFacilityLocation(req): Observable<any> {
    const updateProductFacilityLocation = URLS.updateProductFacilityLocation;
    return this.http.post(updateProductFacilityLocation, req);
  }
  deleteProductFacilityLocation(req): Observable<any> {
    const deleteProductFacilityLocation = URLS.deleteProductFacilityLocation;
    return this.http.post(deleteProductFacilityLocation,req);
  }
  getProductGlAccountDetail(id): Observable<any> {
    const getProductGlAccountDetail = URLS.getProductGlAccountDetail + "?productId=" + id;
    return this.http.get(getProductGlAccountDetail);
  }
  getAccountType(): Observable<any> {
    const getAccountType = URLS.getAccountType;
    return this.http.get(getAccountType);
  }
  getOrganization(): Observable<any> {
    const getOrganization = URLS.getOrganization;
    return this.http.get(getOrganization);
  }
  getGLAccount(): Observable<any> {
    const getGLAccount = URLS.getGLAccount;
    return this.http.get(getGLAccount);
  }
  createProductGlAccount(req): Observable<any> {
    const createProductGlAccount = URLS.createProductGlAccount;
    return this.http.post(createProductGlAccount, req);
  }
  updateGlProductAccount(req): Observable<any> {
    const updateGlProductAccount = URLS.updateGlProductAccount;
    return this.http.put(updateGlProductAccount, req);
  }
  removeGlProductAccount(req): Observable<any> {
    const removeGlProductAccount = URLS.removeGlProductAccount;
    return this.http.post(removeGlProductAccount,req);
  }
  getProductPaymentMethodAll(id): Observable<any> {
    const getProductPaymentMethodAll = URLS.getProductPaymentMethodAll + "?productId=" + id;
    return this.http.get(getProductPaymentMethodAll);
  }
  paymentMethodType(): Observable<any> {
    const paymentMethodType = URLS.paymentMethodType;
    return this.http.get(paymentMethodType);
  }
  productPricePurpose(): Observable<any> {
    const productPricePurpose = URLS.productPricePurpose;
    return this.http.get(productPricePurpose);
  }
  createProductPaymentMethodType(req): Observable<any> {
    const createProductPaymentMethodType = URLS.createProductPaymentMethodType;
    return this.http.post(createProductPaymentMethodType, req);
  }
  updateProductPaymentMethodType(req): Observable<any> {
    const updateProductPaymentMethodType = URLS.updateProductPaymentMethodType;
    return this.http.post(updateProductPaymentMethodType, req);
  }
  deleteProductPaymentMethodType(req): Observable<any> {
    const deleteProductPaymentMethodType = URLS.deleteProductPaymentMethodType;
    return this.http.post(deleteProductPaymentMethodType,req);
  }
  getEditProductMetersByID(id): Observable<any> {
    const getEditProductMetersByID = URLS.getEditProductMetersByID + "?productId=" + id;
    return this.http.get(getEditProductMetersByID);
  }
  getProductMeterTypeId(): Observable<any> {
    const getProductMeterTypeId = URLS.getProductMeterTypeId;
    return this.http.get(getProductMeterTypeId);
  }
  createProductMeter(req): Observable<any> {
    const createProductMeter = URLS.createProductMeter;
    return this.http.post(createProductMeter, req);
  }
  updateProductMeter(req): Observable<any> {
    const updateProductMeter = URLS.updateProductMeter;
    return this.http.post(updateProductMeter, req);
  }
  deleteProductMeter(req): Observable<any> {
    const deleteProductMeter = URLS.deleteProductMeter;
    return this.http.post(deleteProductMeter,req);
  }
  getVendorProductByID(id): Observable<any> {
    const getVendorProductByID = URLS.getVendorProductByID + "?productId=" + id;
    return this.http.get(getVendorProductByID);
  }
  getProductStoreGroupID(): Observable<any> {
    const getProductStoreGroupID = URLS.getProductStoreGroupID;
    return this.http.get(getProductStoreGroupID);
  }
  createVendorProduct(req): Observable<any> {
    const createVendorProduct = URLS.createVendorProduct;
    return this.http.post(createVendorProduct, req);
  }
  deleteVendorProduct(req): Observable<any> {
    const deleteVendorProduct = URLS.deleteVendorProduct;
    return this.http.post(deleteVendorProduct,req);
  }
  getPartyToProductById(id): Observable<any> {
    const getPartyToProductById = URLS.getPartyToProductById + "?productId=" + id;
    return this.http.get(getPartyToProductById);
  }
  getRoleTypeId(): Observable<any> {
    const getRoleTypeId = URLS.getRoleTypeId;
    return this.http.get(getRoleTypeId);
  }
  addPartyToProduct(req): Observable<any> {
    const addPartyToProduct = URLS.addPartyToProduct;
    return this.http.post(addPartyToProduct, req);
  }
  updatePartyToProduct(req): Observable<any> {
    const updatePartyToProduct = URLS.updatePartyToProduct;
    return this.http.post(updatePartyToProduct, req);
  }
  removePartyFromProduct(req): Observable<any> {
    const removePartyFromProduct = URLS.removePartyFromProduct;
    return this.http.post(removePartyFromProduct,req);
  }

  getEditProductWorkEfforts(id): Observable<any> {
    const getEditProductWorkEfforts = URLS.getEditProductWorkEfforts + "?productId=" + id;
    return this.http.get(getEditProductWorkEfforts);
  }
  getProWorkEffortId(): Observable<any> {
    const getProWorkEffortId = URLS.getProWorkEffortId;
    return this.http.get(getProWorkEffortId);
  }
  getWorkEffortGoodStdTypeIdDrop(): Observable<any> {
    const getWorkEffortGoodStdTypeIdDrop = URLS.getWorkEffortGoodStdTypeIdDrop;
    return this.http.get(getWorkEffortGoodStdTypeIdDrop);
  }
  createProWorkEffortGoodStandard(req): Observable<any> {
    const createProWorkEffortGoodStandard = URLS.createProWorkEffortGoodStandard;
    return this.http.post(createProWorkEffortGoodStandard, req);
  }
  updateProWorkEffortGoodStandard(req): Observable<any> {
    const updateProWorkEffortGoodStandard = URLS.updateProWorkEffortGoodStandard;
    return this.http.post(updateProWorkEffortGoodStandard, req);
  }
  removeProWorkEffortGoodStandard(req): Observable<any> {
    const removeProWorkEffortGoodStandard = URLS.removeProWorkEffortGoodStandard;
    return this.http.post(removeProWorkEffortGoodStandard,req);
  }
  getProductGroupOrderById(id): Observable<any> {
    const getProductGroupOrderById = URLS.getProductGroupOrderById + "?productId=" + id;
    return this.http.get(getProductGroupOrderById);
  }
  createProductGroupOrder(req): Observable<any> {
    const createProductGroupOrder = URLS.createProductGroupOrder;
    return this.http.post(createProductGroupOrder, req);
  }
  updateProductGroupOrder(req): Observable<any> {
    const updateProductGroupOrder = URLS.updateProductGroupOrder;
    return this.http.post(updateProductGroupOrder, req);
  }
  deleteProductGroupOrder(req): Observable<any> {
    const deleteProductGroupOrder = URLS.deleteProductGroupOrder;
    return this.http.post(deleteProductGroupOrder,req);
  }
  getCreateCommunicationEventById(id): Observable<any> {
    const getCreateCommunicationEventById = URLS.getCreateCommunicationEventById + "?productId=" + id;
    return this.http.get(getCreateCommunicationEventById);
  }
  getCatalogCommunicationEventId(): Observable<any>{
    const getCatalogCommunicationEventId = URLS.getCatalogCommunicationEventId;
    return this.http.get(getCatalogCommunicationEventId);
  }
  getCataCommEventStatus(): Observable<any> {
    const getCataCommEventStatus = URLS.getCataCommEventStatus;
    return this.http.get(getCataCommEventStatus);
  }
  getCommTypeID(): Observable<any> {
    const getCommTypeID = URLS.getCommTypeID;
    return this.http.get(getCommTypeID);
  }
  getContactMechTypeID(): Observable<any> {
    const getContactMechTypeID = URLS.getContactMechTypeID;
    return this.http.get(getContactMechTypeID);
  }
  getFromContactMechList(): Observable<any> {
    const getFromContactMechList = URLS.getFromContactMechList;
    return this.http.get(getFromContactMechList);
  }
  getRoleTypeIdType(): Observable<any> {
    const getRoleTypeIdType = URLS.getRoleTypeIdType;
    return this.http.get(getRoleTypeIdType);
  }
  getUnitOfMeasure(): Observable<any> {
    const getUnitOfMeasure = URLS.getUnitOfMeasure;
    return this.http.get(getUnitOfMeasure);
  }
  storeCreateShipRate(formData): Observable<any> {
    const storeCreateShipRate = URLS.storeCreateShipRate;
    return this.http.post(storeCreateShipRate,formData);
  }
  
  storeRemoveShipMeth(formData): Observable<any> {
    const storeRemoveShipMeth = URLS.storeRemoveShipMeth;
    return this.http.post(storeRemoveShipMeth,formData);
  }
  
  getWeightBreakIdList(): Observable<any> {
    const getWeightBreakIdList = URLS.getWeightBreakIdList;
    return this.http.get(getWeightBreakIdList);
  }

  getCatalogContentMimeTypeId(): Observable<any> {
    const getCatalogContentMimeTypeId = URLS.getCatalogContentMimeTypeId;
    return this.http.get(getCatalogContentMimeTypeId);
  }
  getReasonEnumIds(): Observable<any> {
    const getReasonEnumIds = URLS.getReasonEnumIds;
    return this.http.get(getReasonEnumIds);
  }
  createCatalogCommunicationEvent(req): Observable<any> {
    const createCatalogCommunicationEvent = URLS.createCatalogCommunicationEvent;
    return this.http.post(createCatalogCommunicationEvent, req);
  }
  getProductOrderById(id): Observable<any> {
    const getProductOrderById = URLS.getProductOrderById + "?productId=" + id;
    return this.http.get(getProductOrderById);
  }
  getSubscriptionProductListBYId(id): Observable<any> {
    const getSubscriptionProductListBYId = URLS.getSubscriptionProductListBYId + "?productId=" + id;;
    return this.http.get(getSubscriptionProductListBYId);
  }
  getEditProductMaints(id): Observable<any> {
    const getEditProductMaints = URLS.getEditProductMaints + "?productId=" + id;
    return this.http.get(getEditProductMaints);
  }
  getSubscriptionResourceIdNew(): Observable<any> {
    const getSubscriptionResourceIdNew = URLS.getSubscriptionResourceIdNew;
    return this.http.get(getSubscriptionResourceIdNew);
  }
  productBarCode(id): Observable<any> {
    const productBarCode = URLS.productBarCode + "?productId=" + id;
    return this.http.get(productBarCode, { responseType: "blob" });
  }
  createSalesAgreement(req): Observable<any> {
    const createSalesAgreement = URLS.createSalesAgreement;
    return this.http.post(createSalesAgreement, req);
  }
  commissionAgreements(id): Observable<any> {
    const commissionAgreements = URLS.commissionAgreements + "?productId=" + id;
    return this.http.get(commissionAgreements);
  }
  getSalesAgreements(id): Observable<any> {
    const getSalesAgreements = URLS.getSalesAgreements + "?productId=" + id;
    return this.http.get(getSalesAgreements);
  }
  productAgreements(id): Observable<any> {
    const productAgreements = URLS.productAgreements + "?productId=" + id;
    return this.http.get(productAgreements);
  }
  SupplierProduct(id): Observable<any> {
    const SupplierProduct = URLS.SupplierProduct + "?productId=" + id;
    return this.http.get(SupplierProduct);
  }
  addProductVariants(productFeatureIdOne,variantProductIdsBag):Observable<any>{
    const addProductVariants = URLS.addProductVariants + "?productFeatureIdOne=" + productFeatureIdOne + "&variantProductIdsBag=" + variantProductIdsBag;
    return this.http.post(addProductVariants,productFeatureIdOne,variantProductIdsBag);
  }
  getProductIds(): Observable<any> {
    const getProductIds = URLS.getProductIds;
    return this.http.get(getProductIds);
  }
  getRoutingsById(id): Observable<any> {
    const getRoutingsById = URLS.getRoutingsById + "?productId=" + id;
    return this.http.get(getRoutingsById);
  }
  getViewProductComponentsById(id): Observable<any> {
    const getViewProductComponentsById = URLS.getViewProductComponentsById + "?productId=" + id;
    return this.http.get(getViewProductComponentsById);
  }
  getViewProductParentById(id): Observable<any> {
    const getViewProductParentById = URLS.getViewProductParentById + "?productId=" + id;
    return this.http.get(getViewProductParentById);
  }
  
  getCostComponentByProductId(id): Observable<any> {
    const getCostComponentByProductId = URLS.getCostComponentByProductId + "?productId=" + id;
    return this.http.get(getCostComponentByProductId);
  }
  getProductCostComponentCalcById(id): Observable<any> {
    const getProductCostComponentCalcById = URLS.getProductCostComponentCalcById + "?productId=" + id;
    return this.http.get(getProductCostComponentCalcById);
  }
  costComponentTypeIdFirst(): Observable<any> {
    const costComponentTypeIdFirst = URLS.costComponentTypeIdFirst;
    return this.http.get(costComponentTypeIdFirst);
  }
  costComponentTypeIdComponentCalc(): Observable<any> {
    const costComponentTypeIdComponentCalc = URLS.costComponentTypeIdComponentCalc;
    return this.http.get(costComponentTypeIdComponentCalc);
  }
  productFeatureId(): Observable<any> {
    const productFeatureId = URLS.productFeatureId;
    return this.http.get(productFeatureId);
  }
  getCatalogGeoId(): Observable<any> {
    const getCatalogGeoId = URLS.getCatalogGeoId;
    return this.http.get(getCatalogGeoId);
  }
  getWorkEffortIds(): Observable<any> {
    const getWorkEffortIds = URLS.getWorkEffortIds;
    return this.http.get(getWorkEffortIds);
  }
  getFixedAssetIds(): Observable<any> {
    const getFixedAssetIds = URLS.getFixedAssetIds;
    return this.http.get(getFixedAssetIds);
  }
  getCostComponentCalcId(): Observable<any> {
    const getCostComponentCalcId = URLS.getCostComponentCalcId;
    return this.http.get(getCostComponentCalcId);
  }
  createCostComponent(req): Observable<any>{
    const createCostComponent = URLS.createCostComponent;
    return this.http.post(createCostComponent, req);
  }
  createProductCostComponentCalc(req): Observable<any>{
    const createProductCostComponentCalc = URLS.createProductCostComponentCalc;
    return this.http.post(createProductCostComponentCalc, req);
  }
  deleteCostComponent(req): Observable<any> {
    const deleteCostComponent = URLS.deleteCostComponent;
    return this.http.post(deleteCostComponent,req);
  }
  deleteProductCostComponentCalc(req): Observable<any> {
    const deleteProductCostComponentCalc = URLS.deleteProductCostComponentCalc;
    return this.http.post(deleteProductCostComponentCalc,req);
  }
  calculateProductCosts(req): Observable<any>{
    const calculateProductCosts = URLS.calculateProductCosts;
    return this.http.post(calculateProductCosts, req);
  }
  getFixedAssetProductById(id): Observable<any> {
    const getFixedAssetProductById = URLS.getFixedAssetProductById + "?productId=" + id;
    return this.http.get(getFixedAssetProductById);
  }
  getFixedAssetId(): Observable<any> {
    const getFixedAssetId = URLS.getFixedAssetId;
    return this.http.get(getFixedAssetId);
  }
  addFixedAssetProduct(req,fixedAssetId): Observable<any>{
    const addFixedAssetProduct = URLS.addFixedAssetProduct + "/" + fixedAssetId;
    return this.http.post(addFixedAssetProduct,req,fixedAssetId);
  }
  updateFixedAssetProduct(req,fixedAssetId): Observable<any> {
    const updateFixedAssetProduct = URLS.updateFixedAssetProduct + "/" + fixedAssetId;;
    return this.http.put(updateFixedAssetProduct,req);
  }
  removeFixedAssetProduct(req): Observable<any> {
    const removeFixedAssetProduct = URLS.removeFixedAssetProduct;
    return this.http.post(removeFixedAssetProduct,req);
  }
  updateProductAssetUsage(req): Observable<any> {
    const updateProductAssetUsage = URLS.updateProductAssetUsage;
    return this.http.post(updateProductAssetUsage,req);
  }

  getEditProductQuickAdmin(id): Observable<any> {
    const getEditProductQuickAdmin = URLS.getEditProductQuickAdmin + "?productId=" + id;
    return this.http.get(getEditProductQuickAdmin);
  }
  getSubscriptionResource(): Observable<any> {
    const getSubscriptionResource = URLS.getSubscriptionResource;
    return this.http.get(getSubscriptionResource);
  }
  getFsmPriorities(): Observable<any> {
    const getFsmPriorities = URLS.getFsmPriorities;
    return this.http.get(getFsmPriorities);
  }
  createFsmPriorities(req): Observable<any>{
    const createFsmPriorities = URLS.createFsmPriorities;
    return this.http.post(createFsmPriorities,req);
  }
  updateFsmPriorities(req): Observable<any> {
    const updateFsmPriorities = URLS.updateFsmPriorities;
    return this.http.post(updateFsmPriorities,req);
  }
  deleteFsmPriorities(req): Observable<any> {
    const deleteFsmPriorities = URLS.deleteFsmPriorities;
    return this.http.post(deleteFsmPriorities,req);
  }
  getProductConfigsById(id): Observable<any> {
    const getProductConfigsById = URLS.getProductConfigsById + "?productId=" + id;
    return this.http.get(getProductConfigsById);
  }
  getConfigItemId(): Observable<any> {
    const getConfigItemId = URLS.getConfigItemId;
    return this.http.get(getConfigItemId);
  }
  createProductConfig(req): Observable<any>{
    const createProductConfig = URLS.createProductConfig;
    return this.http.post(createProductConfig,req);
  }
  updateProductConfig(req): Observable<any> {
    const updateProductConfig = URLS.updateProductConfig;
    return this.http.post(updateProductConfig,req);
  }
  deleteProductConfig(req): Observable<any> {
    const deleteProductConfig = URLS.deleteProductConfig;
    return this.http.post(deleteProductConfig,req);
  }
  getFsmSkill(): Observable<any> {
    const getFsmSkill = URLS.getFsmSkill;
    return this.http.get(getFsmSkill);
  }
  createFsmSkill(req): Observable<any>{
    const createFsmSkill = URLS.createFsmSkill;
    return this.http.post(createFsmSkill,req);
  }
  updateFsmSkill(req): Observable<any> {
    const updateFsmSkill = URLS.updateFsmSkill;
    return this.http.post(updateFsmSkill,req);
  }
  deleteFsmSkill(req): Observable<any> {
    const deleteFsmSkill = URLS.deleteFsmSkill;
    return this.http.post(deleteFsmSkill,req);
  }
  getProductAssocByIdSec(id): Observable<any> {
    const getProductAssocByIdSec = URLS.getProductAssocByIdSec + "?productIdTo=" + id;
    return this.http.get(getProductAssocByIdSec)
  }
  removeProductFromCategoryNew(req): Observable<any> {
    const removeProductFromCategoryNew = URLS.removeProductFromCategoryNew;
    return this.http.post(removeProductFromCategoryNew,req);
  }

  getProductFeatureIactn(id): Observable<any> {
    const getProductFeatureIactn = URLS.getProductFeatureIactn + "?productId=" + id;
    return this.http.get(getProductFeatureIactn)
  }
  AddProductFeatureIactn(req): Observable<any>{
    const AddProductFeatureIactn = URLS.AddProductFeatureIactn;
    return this.http.post(AddProductFeatureIactn,req);
  }
  removeProductFeatureIactn(req): Observable<any> {
    const removeProductFeatureIactn = URLS.removeProductFeatureIactn;
    return this.http.post(removeProductFeatureIactn,req);
  }

  getEditProductFeaturesById(id): Observable<any> {
    const getEditProductFeaturesById = URLS.getEditProductFeaturesById + "?productId=" + id;
    return this.http.get(getEditProductFeaturesById)
  }
  createProductFeatureApplAttr(req): Observable<any>{
    const createProductFeatureApplAttr = URLS.createProductFeatureApplAttr;
    return this.http.post(createProductFeatureApplAttr,req);
  }
  updateProductFeatureApplAttr(req): Observable<any> {
    const updateProductFeatureApplAttr = URLS.updateProductFeatureApplAttr;
    return this.http.post(updateProductFeatureApplAttr,req);
  }
  deleteProductFeatureApplAttr(req): Observable<any> {
    const deleteProductFeatureApplAttr = URLS.deleteProductFeatureApplAttr;
    return this.http.post(deleteProductFeatureApplAttr,req);
  }
  getEditProductFeatures(id): Observable<any> {
    const getEditProductFeatures = URLS.getEditProductFeatures + "?productId=" + id;
    return this.http.get(getEditProductFeatures)
  }
  getProductFeatureId(id): Observable<any> {
    const getProductFeatureId = URLS.getProductFeatureId + "?productId=" + id;
    return this.http.get(getProductFeatureId)
  }

  ProductFeatureCategory(): Observable<any> {
    const ProductFeatureCategory = URLS.ProductFeatureCategory;
    return this.http.get(ProductFeatureCategory);
  }
  productFeatureGroupId(): Observable<any> {
    const productFeatureGroupId = URLS.productFeatureGroupId;
    return this.http.get(productFeatureGroupId);
  }
  productFeatureApplTypeId(): Observable<any> {
    const productFeatureApplTypeId = URLS.productFeatureApplTypeId;
    return this.http.get(productFeatureApplTypeId);
  }
  productFeatureTypeIds(): Observable<any> {
    const productFeatureTypeIds = URLS.productFeatureTypeIds;
    return this.http.get(productFeatureTypeIds);
  }
  applyFeatureToProductFromTypeAndCode(req): Observable<any>{
    const applyFeatureToProductFromTypeAndCode = URLS.applyFeatureToProductFromTypeAndCode;
    return this.http.post(applyFeatureToProductFromTypeAndCode,req);
  }

  applyFeatureToProduct(req): Observable<any>{
    const applyFeatureToProduct = URLS.applyFeatureToProduct;
    return this.http.post(applyFeatureToProduct,req);
  }
  updateFeatureToProductApplication(req): Observable<any> {
    const updateFeatureToProductApplication = URLS.updateFeatureToProductApplication;
    return this.http.post(updateFeatureToProductApplication,req);
  }
  RemoveFeatureFromProduct(req): Observable<any> {
    const RemoveFeatureFromProduct = URLS.RemoveFeatureFromProduct;
    return this.http.post(RemoveFeatureFromProduct,req);
  }
  getProductFeatureTypeID(): Observable<any> {
    const getProductFeatureTypeID = URLS.getProductFeatureTypeID;
    return this.http.get(getProductFeatureTypeID);
  }
  
  EditProductPrices(id): Observable<any> {
    const EditProductPrices = URLS.EditProductPrices + "?productId=" + id;
    return this.http.get(EditProductPrices)
  }
  customPriceCalcService(id): Observable<any> {
    const customPriceCalcService = URLS.customPriceCalcService + "?productId=" + id;
    return this.http.get(customPriceCalcService)
  }
  createProductPrice(req): Observable<any>{
    const createProductPrice = URLS.createProductPrice;
    return this.http.post(createProductPrice,req);
  }
  updateProductPrice(req): Observable<any> {
    const updateProductPrice = URLS.updateProductPrice;
    return this.http.post(updateProductPrice,req);
  }
  deleteProductPrice(req): Observable<any> {
    const deleteProductPrice = URLS.deleteProductPrice;
    return this.http.post(deleteProductPrice,req);
  }
  updateProductQuickAdminName(req): Observable<any> {
    const updateProductQuickAdminName = URLS.updateProductQuickAdminName;
    return this.http.post(updateProductQuickAdminName,req);
  }
  quickAdminAddCategories(req): Observable<any> {
    const quickAdminAddCategories = URLS.quickAdminAddCategories;
    return this.http.post(quickAdminAddCategories,req);
  }
  getCategoryById(id): Observable<any> {
    const getCategoryById = URLS.getCategoryById + "?productId=" + id;
    return this.http.get(getCategoryById);
  }
  quickAdminRemoveProductFromCategory(req): Observable<any> {
    const quickAdminRemoveProductFromCategory = URLS.quickAdminRemoveProductFromCategory;
    return this.http.post(quickAdminRemoveProductFromCategory,req);
  }

  getStandardFeatures(id): Observable<any> {
    const getStandardFeatures = URLS.getStandardFeatures + "?productFeatureTypeId=" + id;
    return this.http.get(getStandardFeatures);
  }
  getStandardFeaturesSecond(id): Observable<any> {
    const getStandardFeaturesSecond = URLS.getStandardFeaturesSecond + "?productId=" + id;
    return this.http.get(getStandardFeaturesSecond);
  }
  deleteFeature(req): Observable<any> {
    const deleteFeature = URLS.deleteFeature;
    return this.http.post(deleteFeature,req);
  }
  updateProductQuickAdminShipping(req): Observable<any> {
    const updateProductQuickAdminShipping = URLS.updateProductQuickAdminShipping;
    return this.http.post(updateProductQuickAdminShipping,req);
  }
  EditProductTag(): Observable<any> {
    const EditProductTag = URLS.EditProductTag;
    return this.http.get(EditProductTag);
  }
  updateProductTag(req): Observable<any> {
    const updateProductTag = URLS.updateProductTag;
    return this.http.post(updateProductTag,req);
  }

  getFsmMyWorkSettingsMS(): Observable<any> {
    const getFsmMyWorkSettingsMS = URLS.getFsmMyWorkSettingsMS;
    return this.http.get(getFsmMyWorkSettingsMS)
  }
  createFsmMyWorkSettingsMS(req): Observable<any>{
    const createFsmMyWorkSettingsMS = URLS.createFsmMyWorkSettingsMS;
    return this.http.post(createFsmMyWorkSettingsMS,req);
  }
  updateFsmMyWorkSettingsMS(req): Observable<any> {
    const updateFsmMyWorkSettingsMS = URLS.updateFsmMyWorkSettingsMS;
    return this.http.post(updateFsmMyWorkSettingsMS,req);
  }
  deleteFsmMyWorkSettingsMS(id): Observable<any> {
    const deleteFsmMyWorkSettingsMS = URLS.deleteFsmMyWorkSettingsMS + "?myWorkSettingsMSId=" + id;
    return this.http.post(deleteFsmMyWorkSettingsMS,'');
  }

  getFsmPrimaryWorkOrderDetailsMS(): Observable<any> {
    const getFsmPrimaryWorkOrderDetailsMS = URLS.getFsmPrimaryWorkOrderDetailsMS;
    return this.http.get(getFsmPrimaryWorkOrderDetailsMS)
  }
  createFsmPrimaryWorkOrderDetailsMS(req): Observable<any>{
    const createFsmPrimaryWorkOrderDetailsMS = URLS.createFsmPrimaryWorkOrderDetailsMS;
    return this.http.post(createFsmPrimaryWorkOrderDetailsMS,req);
  }
  updateFsmPrimaryWorkOrderDetailsMS(req): Observable<any> {
    const updateFsmPrimaryWorkOrderDetailsMS = URLS.updateFsmPrimaryWorkOrderDetailsMS;
    return this.http.post(updateFsmPrimaryWorkOrderDetailsMS,req);
  }
  deleteFsmPrimaryWorkOrderDetailsMS(req): Observable<any> {
    const deleteFsmPrimaryWorkOrderDetailsMS = URLS.deleteFsmPrimaryWorkOrderDetailsMS;
    return this.http.post(deleteFsmPrimaryWorkOrderDetailsMS,req);
  }

  getFsmNonPrimaryWorkOrderDetailsMS(): Observable<any> {
    const getFsmNonPrimaryWorkOrderDetailsMS = URLS.getFsmNonPrimaryWorkOrderDetailsMS;
    return this.http.get(getFsmNonPrimaryWorkOrderDetailsMS)
  }
  createFsmNonPrimaryWorkOrderDetailsMS(req): Observable<any>{
    const createFsmNonPrimaryWorkOrderDetailsMS = URLS.createFsmNonPrimaryWorkOrderDetailsMS;
    return this.http.post(createFsmNonPrimaryWorkOrderDetailsMS,req);
  }
  updateFsmNonPrimaryWorkOrderDetailsMS(req): Observable<any> {
    const updateFsmNonPrimaryWorkOrderDetailsMS = URLS.updateFsmNonPrimaryWorkOrderDetailsMS;
    return this.http.post(updateFsmNonPrimaryWorkOrderDetailsMS,req);
  }
  deleteFsmNonPrimaryWorkOrderDetailsMS(req): Observable<any> {
    const deleteFsmNonPrimaryWorkOrderDetailsMS = URLS.deleteFsmNonPrimaryWorkOrderDetailsMS;
    return this.http.post(deleteFsmNonPrimaryWorkOrderDetailsMS,req);
  }

  getFsmTechnicalAll(): Observable<any> {
    const getFsmTechnicalAll = URLS.getFsmTechnicalAll;
    return this.http.get(getFsmTechnicalAll)
  }
  createTechnicianRegister(req): Observable<any>{
    const createTechnicianRegister = URLS.createTechnicianRegister;
    return this.http.post(createTechnicianRegister,req);
  }
  updateTechnician(req): Observable<any> {
    const updateTechnician = URLS.updateTechnician;
    return this.http.post(updateTechnician,req);
  }
  deleteFsmTechnician(req): Observable<any> {
    const deleteFsmTechnician = URLS.deleteFsmTechnician;
    return this.http.post(deleteFsmTechnician,req);
  }
  uploadSocialSecurityNumber(req,id): Observable<any> {
    const uploadSocialSecurityNumber = URLS.uploadSocialSecurityNumber+"?technicianId="+id;
    return this.http.post(uploadSocialSecurityNumber,req);
  }
  findFsmTechnician(req): Observable<any> {
    const findFsmTechnician = URLS.findFsmTechnician;
    return this.http.post(findFsmTechnician,req);
  }
  getCountryGeoId(): Observable<any> {
    const getCountryGeoId = URLS.getCountryGeoId;
    return this.http.get(getCountryGeoId)
  }
  getStateProvId(): Observable<any> {
    const getStateProvId = URLS.getStateProvId;
    return this.http.get(getStateProvId)
  }

  findFsmCustomer(req): Observable<any> {
    const findFsmCustomer = URLS.findFsmCustomer;
    return this.http.post(findFsmCustomer,req);
  }
  createFsmCustomerResidential(req): Observable<any>{
    const createFsmCustomerResidential = URLS.createFsmCustomerResidential;
    return this.http.post(createFsmCustomerResidential,req);
  }
  updateFsmCustomerResidential(req): Observable<any> {
    const updateFsmCustomerResidential = URLS.updateFsmCustomerResidential;
    return this.http.post(updateFsmCustomerResidential,req);
  }
  deleteFsmCustomer(req): Observable<any> {
    const deleteFsmCustomer = URLS.deleteFsmCustomer;
    return this.http.post(deleteFsmCustomer,req);
  }

  getFsmProblemByCustomerId(id): Observable<any> {
    const getFsmProblemByCustomerId = URLS.getFsmProblemByCustomerId+"?customerId="+id;
    return this.http.get(getFsmProblemByCustomerId);
  }
  getFsmProblemByProblemId(id): Observable<any> {
    const getFsmProblemByProblemId = URLS.getFsmProblemByProblemId+"?problemId="+id;
    return this.http.get(getFsmProblemByProblemId);
  }

  getFsmProblem(): Observable<any> {
    const getFsmProblem = URLS.getFsmProblem;
    return this.http.get(getFsmProblem)
  }
  createFsmProblem(req): Observable<any>{
    const createFsmProblem = URLS.createFsmProblem;
    return this.http.post(createFsmProblem,req);
  }
  updateFsmProblem(req): Observable<any> {
    const updateFsmProblem = URLS.updateFsmProblem;
    return this.http.post(updateFsmProblem,req);
  }
  deleteFsmProblem(req): Observable<any> {
    const deleteFsmProblem = URLS.deleteFsmProblem;
    return this.http.post(deleteFsmProblem,req);
  }
  createFsmAssignment(req): Observable<any>{
    const createFsmAssignment = URLS.createFsmAssignment;
    return this.http.post(createFsmAssignment,req);
  }
  getFsmAssignmentById(id): Observable<any> {
    const getFsmAssignmentById = URLS.getFsmAssignmentById+"?workOrderId="+id;
    return this.http.get(getFsmAssignmentById);
  }
  updateFsmAssignment(req): Observable<any> {
    const updateFsmAssignment = URLS.updateFsmAssignment;
    return this.http.post(updateFsmAssignment,req);
  }
  deleteFsmAssignment(req): Observable<any> {
    const deleteFsmAssignment = URLS.deleteFsmAssignment;
    return this.http.post(deleteFsmAssignment,req);
  }
  getFsmCustomerCommercialById(id): Observable<any> {
    const getFsmCustomerCommercialById = URLS.getFsmCustomerCommercialById+"?customerId="+id;
    return this.http.get(getFsmCustomerCommercialById);
  }
  getFsmMap(): Observable<any> {
    const getFsmMap = URLS.getFsmMap;
    return this.http.get(getFsmMap);
  }
  deleteFsmInvoice(req): Observable<any> {
    const deleteFsmInvoice = URLS.deleteFsmInvoice;
    return this.http.post(deleteFsmInvoice,req);
  }
  getSchedule(): Observable<any> {
    const getSchedule = URLS.getSchedule;
    return this.http.get(getSchedule);
  }
  getNonSchedule(): Observable<any> {
    const getNonSchedule = URLS.getNonSchedule;
    return this.http.get(getNonSchedule);
  }
  getFsmAssignment(): Observable<any> {
    const getFsmAssignment = URLS.getFsmAssignment;
    return this.http.get(getFsmAssignment);
  }

  findContent(req): Observable<any> {
    const findContent = URLS.findContent;
    return this.http.post(findContent,req);
  }
  getCalatalogContentTypeId(): Observable<any> {
    const getCalatalogContentTypeId = URLS.getCalatalogContentTypeId;
    return this.http.get(getCalatalogContentTypeId);
  }
  getCalatalogMimeTypeId(): Observable<any> {
    const getCalatalogMimeTypeId = URLS.getCalatalogMimeTypeId;
    return this.http.get(getCalatalogMimeTypeId);
  }
  getDataResourceId(): Observable<any> {
    const getDataResourceId = URLS.getDataResourceId;
    return this.http.get(getDataResourceId);
  }
  getCatalogContentId(): Observable<any> {
    const getCatalogContentId = URLS.getCatalogContentId;
    return this.http.get(getCatalogContentId);
  }
  getCharacterSet(): Observable<any> {
    const getCharacterSet = URLS.getCharacterSet;
    return this.http.get(getCharacterSet);
  }
  createContent(req): Observable<any>{
    const createContent = URLS.createContent;
    return this.http.post(createContent,req);
  }

  
  getEditContentById(id): Observable<any> {
    const getEditContentById = URLS.getEditContentById+"?contentId="+id;
    return this.http.get(getEditContentById);
  }
  updateContent(req): Observable<any> {
    const updateContent = URLS.updateContent;
    return this.http.post(updateContent,req);
  }
  getContentAssocById(id): Observable<any> {
    const getContentAssocById = URLS.getContentAssocById+"?contentId="+id;
    return this.http.get(getContentAssocById);
  }
  createContentAssoc(req): Observable<any>{
    const createContentAssoc = URLS.createContentAssoc;
    return this.http.post(createContentAssoc,req);
  }
  updateContentAssoc(req): Observable<any> {
    const updateContentAssoc = URLS.updateContentAssoc;
    return this.http.post(updateContentAssoc,req);
  }
  removeContentAssoc(req): Observable<any> {
    const removeContentAssoc = URLS.removeContentAssoc;
    return this.http.post(removeContentAssoc,req);
  }
  getContentAssocTypeId(): Observable<any> {
    const getContentAssocTypeId = URLS.getContentAssocTypeId;
    return this.http.get(getContentAssocTypeId);
  }
  getMetaDataPredicateID(): Observable<any> {
    const getMetaDataPredicateID = URLS.getMetaDataPredicateID;
    return this.http.get(getMetaDataPredicateID);
  }
  getContentRoleTypeId(): Observable<any> {
    const getContentRoleTypeId = URLS.getContentRoleTypeId;
    return this.http.get(getContentRoleTypeId);
  }
  getContentRoleById(id): Observable<any> {
    const getContentRoleById = URLS.getContentRoleById+"?contentId="+id;
    return this.http.get(getContentRoleById);
  }
  addContentRole(req): Observable<any>{
    const addContentRole = URLS.addContentRole;
    return this.http.post(addContentRole,req);
  }
  updateContentRole(req): Observable<any> {
    const updateContentRole = URLS.updateContentRole;
    return this.http.post(updateContentRole,req);
  }
  removeContentRole(req): Observable<any> {
    const removeContentRole = URLS.removeContentRole;
    return this.http.post(removeContentRole,req);
  }
  getContentPurposeById(id): Observable<any> {
    const getContentPurposeById = URLS.getContentPurposeById+"?contentId="+id;
    return this.http.get(getContentPurposeById);
  }
  getContentPurposeType(): Observable<any> {
    const getContentPurposeType = URLS.getContentPurposeType;
    return this.http.get(getContentPurposeType);
  }
  addContentPurpose(req): Observable<any>{
    const addContentPurpose = URLS.addContentPurpose;
    return this.http.post(addContentPurpose,req);
  }
  updateContentPurpose(req): Observable<any> {
    const updateContentPurpose = URLS.updateContentPurpose;
    return this.http.post(updateContentPurpose,req);
  }
  removeContentPurpose(req): Observable<any> {
    const removeContentPurpose = URLS.removeContentPurpose;
    return this.http.post(removeContentPurpose,req);
  }

  getContentAttributeById(id): Observable<any> {
    const getContentAttributeById = URLS.getContentAttributeById+"?contentId="+id;
    return this.http.get(getContentAttributeById);
  }
  addContentAttribute(req): Observable<any>{
    const addContentAttribute = URLS.addContentAttribute;
    return this.http.post(addContentAttribute,req);
  }
  updateContentAttribute(req): Observable<any> {
    const updateContentAttribute = URLS.updateContentAttribute;
    return this.http.post(updateContentAttribute,req);
  }
  removeContentAttribute(req): Observable<any> {
    const removeContentAttribute = URLS.removeContentAttribute;
    return this.http.post(removeContentAttribute,req);
  }

  getContentMetaDataById(id): Observable<any> {
    const getContentMetaDataById = URLS.getContentMetaDataById+"?contentId="+id;
    return this.http.get(getContentMetaDataById);
  }
  getMetaDataPredicateIDNew(): Observable<any> {
    const getMetaDataPredicateIDNew = URLS.getMetaDataPredicateIDNew;
    return this.http.get(getMetaDataPredicateIDNew);
  }
  addContentMetaData(req): Observable<any>{
    const addContentMetaData = URLS.addContentMetaData;
    return this.http.post(addContentMetaData,req);
  }
  updateContentMetaData(req): Observable<any> {
    const updateContentMetaData = URLS.updateContentMetaData;
    return this.http.post(updateContentMetaData,req);
  }
  removeContentMetaData(req): Observable<any> {
    const removeContentMetaData = URLS.removeContentMetaData;
    return this.http.post(removeContentMetaData,req);
  }

  getWorkEffortContentById(id): Observable<any> {
    const getWorkEffortContentById = URLS.getWorkEffortContentById+"?contentId="+id;
    return this.http.get(getWorkEffortContentById);
  }
  createWorkEffortContentNew(req): Observable<any>{
    const createWorkEffortContentNew = URLS.createWorkEffortContentNew;
    return this.http.post(createWorkEffortContentNew,req);
  }
  updateWorkEffortContentNew(req): Observable<any> {
    const updateWorkEffortContentNew = URLS.updateWorkEffortContentNew;
    return this.http.post(updateWorkEffortContentNew,req);
  }
  deleteWorkEffortContentNew(req): Observable<any> {
    const deleteWorkEffortContentNew = URLS.deleteWorkEffortContentNew;
    return this.http.post(deleteWorkEffortContentNew,req);
  }
  getExpertiseAreaNew(): Observable<any> {
    const getExpertiseAreaNew = URLS.getExpertiseAreaNew;
    return this.http.get(getExpertiseAreaNew);
  }
  getExpertiseAreaByTechnicianId(id): Observable<any> {
    const getExpertiseAreaByTechnicianId = URLS.getExpertiseAreaByTechnicianId+"?expertiseArea="+id;
    return this.http.get(getExpertiseAreaByTechnicianId);
  }
  createFsmExpertiseArea(req): Observable<any>{
    const createFsmExpertiseArea = URLS.createFsmExpertiseArea;
    return this.http.post(createFsmExpertiseArea,req);
  }

  getProductFeatureIdList(): Observable<any> {
    const getProductFeatureIdList = URLS.getProductFeatureIdList;
    return this.http.get(getProductFeatureIdList);
  }
  getFindParties(req): Observable<any> {
    const getFindParties = URLS.getFindParties;
    return this.http.post(getFindParties,req);
  }
  getFindProductNew(req): Observable<any> {
    const getFindProductNew = URLS.getFindProductNew;
    return this.http.post(getFindProductNew,req);
  }
  UpdateCartItem(req): Observable<any> {
    const UpdateCartItem = URLS.UpdateCartItem;
    return this.http.post(UpdateCartItem,req);
  }
  deleteCartItem(req): Observable<any> {
    const deleteCartItem = URLS.deleteCartItem;
    return this.http.post(deleteCartItem,req);
  }
  getBrowseCategoriesFirst(req): Observable<any>{
    const getBrowseCategoriesFirst = URLS.getBrowseCategoriesFirst;
    return this.http.post(getBrowseCategoriesFirst,req);
  }
  getBrowseCategoriesSecond(requestData): Observable<any>{
    const getBrowseCategoriesSecond = URLS.getBrowseCategoriesSecond + "?pageNo=" + requestData.pageNo + "&pageSize=" + requestData.pageSize + "&productCategoryId=" + requestData.productCategoryId;
    return this.http.get(getBrowseCategoriesSecond);
  }

  openTerminal(req): Observable<any>{
    const openTerminal = URLS.openTerminal;
    return this.http.post(openTerminal,req);
  }
  CloseTerminal(req): Observable<any>{
    const CloseTerminal = URLS.CloseTerminal;
    return this.http.post(CloseTerminal,req);
  }
  PaidOutAndIn(req): Observable<any>{
    const PaidOutAndIn = URLS.PaidOutAndIn;
    return this.http.post(PaidOutAndIn,req);
  }
  PromoCode(req): Observable<any>{
    const PromoCode = URLS.PromoCode;
    return this.http.post(PromoCode,req);
  }
  VoidOrder(req): Observable<any>{
    const VoidOrder = URLS.VoidOrder;
    return this.http.post(VoidOrder,req);
  }

  getContentKeywordById(id): Observable<any> {
    const getContentKeywordById = URLS.getContentKeywordById+"?contentId="+id;
    return this.http.get(getContentKeywordById);
  }

  getListWebSiteById(id): Observable<any> {
    const getListWebSiteById = URLS.getListWebSiteById+"?contentId="+id;
    return this.http.get(getListWebSiteById);
  }
  LoginWebPos(req): Observable<any> {
    const LoginWebPos = URLS.LoginWebPos;
    return this.http.post(LoginWebPos,req);
  }
  AddToCart(req): Observable<any> {
    const AddToCart = URLS.AddToCart;
    return this.http.post(AddToCart,req);
  }
  getCart(posTerminalId,id): Observable<any> {
    const getCart = URLS.getCart+"?posTerminalId="+posTerminalId+"&productId="+id ;
    return this.http.get(getCart);
  }
  getBrowseCategoriesData(): Observable<any> {
    const getBrowseCategoriesData = URLS.getBrowseCategoriesData;
    return this.http.get(getBrowseCategoriesData);
  }
  createContentKeyword(req): Observable<any> {
    const createContentKeyword = URLS.createContentKeyword;
    return this.http.post(createContentKeyword,req);
  }
  deleteContentKeyword(req): Observable<any> {
    const deleteContentKeyword = URLS.deleteContentKeyword;
    return this.http.post(deleteContentKeyword,req);
  }
  getVerifyToken(req): Observable<any> {
    const getVerifyToken = URLS.getVerifyToken;
    return this.http.post(getVerifyToken,req);
  }
  findSurvey(req): Observable<any> {
    const findSurvey = URLS.findSurvey;
    return this.http.post(findSurvey,req);
  }
  createSurvey(req): Observable<any> {
    const createSurvey = URLS.createSurvey;
    return this.http.post(createSurvey,req);
  }
  updateSurvey(req): Observable<any> {
    const updateSurvey = URLS.updateSurvey;
    return this.http.post(updateSurvey,req);
  }
  getSurveyById(id): Observable<any> {
    const getSurveyById = URLS.getSurveyById+"?surveyId="+id;
    return this.http.get(getSurveyById);
  }
  FindSurveyResponse(req): Observable<any> {
    const FindSurveyResponse = URLS.FindSurveyResponse;
    return this.http.post(FindSurveyResponse,req);
  }

  createSurveyMultiResp(req): Observable<any> {
    const createSurveyMultiResp = URLS.createSurveyMultiResp;
    return this.http.post(createSurveyMultiResp,req);
  }
  updateSurveyMultiResp(req): Observable<any> {
    const updateSurveyMultiResp = URLS.updateSurveyMultiResp;
    return this.http.post(updateSurveyMultiResp,req);
  }
  EditSurveyMultiResps(id): Observable<any> {
    const EditSurveyMultiResps = URLS.EditSurveyMultiResps+"?surveyId="+id;
    return this.http.post(EditSurveyMultiResps,'');
  }
  createSurveyMultiRespColumn(req): Observable<any> {
    const createSurveyMultiRespColumn = URLS.createSurveyMultiRespColumn;
    return this.http.post(createSurveyMultiRespColumn,req);
  }
  updateSurveyMultiRespColumn(req): Observable<any> {
    const updateSurveyMultiRespColumn = URLS.updateSurveyMultiRespColumn;
    return this.http.post(updateSurveyMultiRespColumn,req);
  }
  getSurveyMultiResponseColumn(id): Observable<any> {
    const getSurveyMultiResponseColumn = URLS.getSurveyMultiResponseColumn+ "/" +id;
    return this.http.get(getSurveyMultiResponseColumn);
  }

  createSurveyPage(req): Observable<any> {
    const createSurveyPage = URLS.createSurveyPage;
    return this.http.post(createSurveyPage,req);
  }
  updateSurveyPage(req): Observable<any> {
    const updateSurveyPage = URLS.updateSurveyPage;
    return this.http.post(updateSurveyPage,req);
  }
  getSurveyPageById(id): Observable<any> {
    const getSurveyPageById = URLS.getSurveyPageById+"/"+id;
    return this.http.get(getSurveyPageById);
  }

  createSurveyQuestion(req): Observable<any> {
    const createSurveyQuestion = URLS.createSurveyQuestion;
    return this.http.post(createSurveyQuestion,req);
  }
  updateSurveyQuestion(req): Observable<any> {
    const updateSurveyQuestion = URLS.updateSurveyQuestion;
    return this.http.post(updateSurveyQuestion,req);
  }
  getApplyQuestionList(): Observable<any> {
    const getApplyQuestionList = URLS.getApplyQuestionList;
    return this.http.get(getApplyQuestionList);
  }
  getSurveyQuestionTypeIdList(): Observable<any> {
    const getSurveyQuestionTypeIdList = URLS.getSurveyQuestionTypeIdList;
    return this.http.get(getSurveyQuestionTypeIdList);
  }
  getSurveyListBySurveyQuestionCategoryId(id): Observable<any> {
    const getSurveyListBySurveyQuestionCategoryId = URLS.getSurveyListBySurveyQuestionCategoryId+"/"+id;
    return this.http.get(getSurveyListBySurveyQuestionCategoryId);
  }
  createSurveyQuestionCategory(req): Observable<any> {
    const createSurveyQuestionCategory = URLS.createSurveyQuestionCategory;
    return this.http.post(createSurveyQuestionCategory,req);
  }
  buildSurveyResponseFromPdf(req): Observable<any> {
    const buildSurveyResponseFromPdf = URLS.buildSurveyResponseFromPdf;
    return this.http.post(buildSurveyResponseFromPdf,req);
  }
  getCreateSurveyQuestionById(id): Observable<any> {
    const getCreateSurveyQuestionById = URLS.getCreateSurveyQuestionById+"?surveyId="+id;
    return this.http.get(getCreateSurveyQuestionById);
  }
  BlogMain(): Observable<any> {
    const BlogMain = URLS.BlogMain;
    return this.http.get(BlogMain);
  }
  createnewBlog(req): Observable<any> {
    const createnewBlog = URLS.createnewBlog;
    return this.http.post(createnewBlog,req);
  }
  removeSurveyQuestionAppl(req): Observable<any> {
    const removeSurveyQuestionAppl = URLS.removeSurveyQuestionAppl;
    return this.http.post(removeSurveyQuestionAppl,req);
  }
  createSurveyQuestionAppl(req): Observable<any> {
    const createSurveyQuestionAppl = URLS.createSurveyQuestionAppl;
    return this.http.post(createSurveyQuestionAppl,req);
  }
   updateBlog(req): Observable<any> {
    const updateBlog = URLS.updateBlog;
    return this.http.post(updateBlog,req);
  }
  createBlogArticle(formData,req): Observable<any> {
    const createBlogArticle = URLS.createBlogArticle;
    return this.http.post(createBlogArticle,req);
  }
  updateBlogArticle(req): Observable<any> {
    const updateBlogArticle = URLS.updateBlogArticle;
    return this.http.post(updateBlogArticle,req);
  }
  blogContent(id): Observable<any> {
    const blogContent = URLS.blogContent+"?contentIdStart="+id;
    return this.http.get(blogContent);
  }
  EditBlog(id): Observable<any> {
    const EditBlog = URLS.EditBlog+"?contentId="+id;
    return this.http.get(EditBlog);
  }
  FindLayout(req): Observable<any> {
    const FindLayout = URLS.FindLayout;
    return this.http.post(FindLayout,req);
  }
  ListLayout(): Observable<any> {
    const ListLayout = URLS.ListLayout;
    return this.http.post(ListLayout,'');
  }
  
  getcontentPurposeTypeId(): Observable<any> {
    const getcontentPurposeTypeId = URLS.getcontentPurposeTypeId;
    return this.http.get(getcontentPurposeTypeId);
  }

  getForumGroup(): Observable<any> {
    const getForumGroup = URLS.getForumGroup;
    return this.http.get(getForumGroup);
  }

getFindForums(id): Observable<any> {
  const getFindForums = URLS.getFindForums+"?contentId="+id;
  return this.http.get(getFindForums);
}
getforumGroupPurposes(id): Observable<any> {
  const getforumGroupPurposes = URLS.getforumGroupPurposes+"?contentId="+id;
  return this.http.get(getforumGroupPurposes);
}

getforumGroupRole(id): Observable<any> {
  const getforumGroupRole = URLS.getforumGroupRole+"?contentId="+id;
  return this.http.get(getforumGroupRole);
  }

  FindForumMessagesAll(): Observable<any> {
    const FindForumMessagesAll = URLS.FindForumMessagesAll;
    return this.http.get(FindForumMessagesAll);
    }

  FindForumMessages(id,idTo): Observable<any> {
    const FindForumMessages = URLS.FindForumMessages+"?contentId="+id+"&contentIdTo="+idTo;
    return this.http.get(FindForumMessages);
    }

  createLayoutSubContent(req): Observable<any> {
    const createLayoutSubContent = URLS.createLayoutSubContent;
    return this.http.post(createLayoutSubContent,req);
  }
  updateLayoutSubContent(req): Observable<any> {
    const updateLayoutSubContent = URLS.updateLayoutSubContent;
    return this.http.post(updateLayoutSubContent,req);
  }
  detailPage(id): Observable<any> {
    const detailPage = URLS.detailPage+"?contentId="+id;
    return this.http.get(detailPage);
  }
  cloneLayout(req): Observable<any> {
    const cloneLayout = URLS.cloneLayout;
    return this.http.post(cloneLayout,req);
  }

createForumGroup(req): Observable<any> {
  const createForumGroup = URLS.createForumGroup;
  return this.http.post(createForumGroup,req);
}
createForum(req): Observable<any> {
  const createForum = URLS.createForum;
  return this.http.post(createForum,req);
}

createForumGroupPurpose(req): Observable<any> {
  const createForumGroupPurpose = URLS.createForumGroupPurpose;
  return this.http.post(createForumGroupPurpose,req);
}
createForumGroupRole(req): Observable<any> {
  const createForumGroupRole = URLS.createForumGroupRole;
  return this.http.post(createForumGroupRole,req);
}

updateForumGroup(req): Observable<any> {
  const updateForumGroup = URLS.updateForumGroup;
  return this.http.post(updateForumGroup,req);
}

updateForum(req): Observable<any> {
  const updateForum = URLS.updateForum;
  return this.http.post(updateForum,req);
}

updateContentRoles(req): Observable<any> {
  const updateContentRoles = URLS.updateContentRoles;
  return this.http.post(updateContentRoles,req);
}

createForumMessage(req): Observable<any> {
  const createForumMessage = URLS.createForumMessage;
  return this.http.post(createForumMessage,req);
}

updateForumMessage(req): Observable<any> {
  const updateForumMessage = URLS.updateForumMessage;
  return this.http.post(updateForumMessage,req);
}

updateForumThreadMessage(req): Observable<any> {
  const updateForumThreadMessage = URLS.updateForumThreadMessage;
  return this.http.post(updateForumThreadMessage,req);
}

deleteForumGroupRole(req): Observable<any> {
  const deleteForumGroupRole = URLS.deleteForumGroupRole;
  return this.http.post(deleteForumGroupRole,req);
}
deleteForumGroupPurpose(req): Observable<any> {
  const deleteForumGroupPurpose = URLS.deleteForumGroupPurpose;
  return this.http.post(deleteForumGroupPurpose,req);
}
FindCompDoc(req): Observable<any> {
  const FindCompDoc = URLS.FindCompDoc;
  return this.http.post(FindCompDoc,req);
}
createRootCompDocTemplate(req): Observable<any> {
  const createRootCompDocTemplate = URLS.createRootCompDocTemplate;
  return this.http.post(createRootCompDocTemplate,req);
}
updateRootCompDocTemplate(req): Observable<any> {
  const updateRootCompDocTemplate = URLS.updateRootCompDocTemplate;
  return this.http.post(updateRootCompDocTemplate,req);
}
genCompDocInstance(req): Observable<any> {
  const genCompDocInstance = URLS.genCompDocInstance;
  return this.http.post(genCompDocInstance,req);
}
ListContentApproval(id,contentId): Observable<any> {
  const ListContentApproval = URLS.ListContentApproval+"?contentRevisionSeqId="+id+"&rootContentId="+contentId;
  return this.http.post(ListContentApproval,'');
}
createContentApproval(req): Observable<any> {
  const createContentApproval = URLS.createContentApproval;
  return this.http.post(createContentApproval,req);
}
ListContentRevisions(id,contentSeqId): Observable<any> {
  const ListContentRevisions = URLS.ListContentRevisions+"?contentId="+id+"&contentRevisionSeqId="+contentSeqId;
  return this.http.post(ListContentRevisions,'');
}
ViewCompDocTemplateTree(id): Observable<any> {
  const ViewCompDocTemplateTree = URLS.ViewCompDocTemplateTree+"?contentId="+id;
  return this.http.post(ViewCompDocTemplateTree,'');
}
updateContentApproval(req): Observable<any> {
  const updateContentApproval = URLS.updateContentApproval;
  return this.http.post(updateContentApproval,req);
}
removeContentApproval(req): Observable<any> {
  const removeContentApproval = URLS.removeContentApproval;
  return this.http.post(removeContentApproval,req);
}
updateContentRevision(req): Observable<any> {
  const updateContentRevision = URLS.updateContentRevision;
  return this.http.post(updateContentRevision,req);
}
updateContentRevisionItem(req): Observable<any> {
  const updateContentRevisionItem = URLS.updateContentRevisionItem;
  return this.http.post(updateContentRevisionItem,req);
}
createChildCompDocTemplate(req): Observable<any> {
  const createChildCompDocTemplate = URLS.createChildCompDocTemplate;
  return this.http.post(createChildCompDocTemplate,req);
}

CMSContentFind(req): Observable<any> {
  const CMSContentFind = URLS.CMSContentFind;
  return this.http.post(CMSContentFind,req);
}

ListComputerHardware(req): Observable<any> {
  const ListComputerHardware = URLS.ListComputerHardware;
  return this.http.post(ListComputerHardware,req);
}
ViewBlogArticle(id,ownerContentId): Observable<any> {
  const ViewBlogArticle = URLS.ViewBlogArticle+"?contentId="+id+"&ownerContentId="+ownerContentId;
  return this.http.get(ViewBlogArticle);
}
EditChildCompDoc(id): Observable<any> {
  const EditChildCompDoc = URLS.EditChildCompDoc+"?contentId="+id;
  return this.http.post(EditChildCompDoc,'');
}

ListFlexibleReport(): Observable<any> {
  const ListFlexibleReport = URLS.ListFlexibleReport;
  return this.http.get(ListFlexibleReport);
  }

  ReportChoice(): Observable<any> {
    const ReportChoice = URLS.ReportChoice;
    return this.http.get(ReportChoice);
    }

   EditFlexibleReport(id): Observable<any> {
      const EditFlexibleReport = URLS.EditFlexibleReport+"?contentId="+id;
      return this.http.get(EditFlexibleReport);
    }
  
birtContentType(): Observable<any> {
    const birtContentType = URLS.birtContentType;
    return this.http.get(birtContentType);
    }

DeleteFlexibleReport(req): Observable<any> {
    const DeleteFlexibleReport = URLS.DeleteFlexibleReport;
    return this.http.post(DeleteFlexibleReport,req);
  }

  CreateFlexibleReport(req): Observable<any> {
    const CreateFlexibleReport = URLS.CreateFlexibleReport;
    return this.http.post(CreateFlexibleReport,req);
  }

  UpdateFlexibleReport(req): Observable<any> {
    const UpdateFlexibleReport = URLS.UpdateFlexibleReport;
    return this.http.post(UpdateFlexibleReport,req);
  }
  getContentAssocSecondById(id): Observable<any> {
    const getContentAssocSecondById = URLS.getContentAssocSecondById+"?contentIdTo="+id;
    return this.http.get(getContentAssocSecondById);
  }
  updateContentAssocBottom(req): Observable<any> {
    const updateContentAssocBottom = URLS.updateContentAssocBottom;
    return this.http.post(updateContentAssocBottom,req);
  }
  dataResourceId(): Observable<any> {
    const dataResourceId = URLS.dataResourceId;
    return this.http.get(dataResourceId);
  }
  deleteContentAssocNew(req): Observable<any> {
    const deleteContentAssocNew = URLS.deleteContentAssocNew;
    return this.http.post(deleteContentAssocNew,req);
  }

  UpdateFlexibleReportS(req): Observable<any> {
    const UpdateFlexibleReportS = URLS.UpdateFlexibleReportS;
    return this.http.post(UpdateFlexibleReportS,req);
  }
  UploadRptDesign(req,id): Observable<any> {
    const UploadRptDesign = URLS.UploadRptDesign+"?dataResourceId="+id;
    return this.http.post(UploadRptDesign,req);
  }

sendBirtMail(req): Observable<any> {
    const sendBirtMail = URLS.sendBirtMail;
    return this.http.post(sendBirtMail,req);
  }

  flexible(req): Observable<any> {
    const flexible = URLS.flexible;
    return this.http.post(flexible,req);
  }

  DisplayFlexibleReportSearch(requestData): Observable<any> {
    const DisplayFlexibleReportSearch = URLS.DisplayFlexibleReportSearch ;
    return this.http.post(DisplayFlexibleReportSearch,requestData, { responseType: "blob" });
  }
  
  DisplayFlexibleReportSearchs(requestData): Observable<any> {
    const DisplayFlexibleReportSearchs = URLS.DisplayFlexibleReportSearchs ;
    return this.http.post(DisplayFlexibleReportSearchs,requestData, { responseType: "blob" });
  }

  DisplayFlexibleReportSearchSecond(requestData): Observable<any> {
    const DisplayFlexibleReportSearchSecond = URLS.DisplayFlexibleReportSearchSecond ;
    return this.http.post(DisplayFlexibleReportSearchSecond,requestData, { responseType: "blob" });
  }
  buildSurveyFromPdf(req): Observable<any> {
    const buildSurveyFromPdf = URLS.buildSurveyFromPdf;
    return this.http.post(buildSurveyFromPdf,req);
  }
  getFsmAssignmentFilter(req): Observable<any> {
    const getFsmAssignmentFilter = URLS.getFsmAssignmentFilter;
    return this.http.post(getFsmAssignmentFilter,req);
  }
  store(): Observable<any> {
    const store = URLS.store;
    return this.http.get(store);
  }
  EditProdCatalogStores(id): Observable<any> {
    const EditProdCatalogStores = URLS.EditProdCatalogStores+"?prodCatalogId="+id;
    return this.http.get(EditProdCatalogStores);
  }

  getProdCatalogToPartyById(id): Observable<any> {
    const getProdCatalogToPartyById = URLS.getProdCatalogToPartyById+"/"+id;
    return this.http.get(getProdCatalogToPartyById);
  }
  addProdCatalogToParty(req): Observable<any> {
    const addProdCatalogToParty = URLS.addProdCatalogToParty;
    return this.http.post(addProdCatalogToParty,req);
  }
  updateProdCatalogToParty(req): Observable<any> {
    const updateProdCatalogToParty = URLS.updateProdCatalogToParty;
    return this.http.post(updateProdCatalogToParty,req);
  }
  deleteProdCatalogToParty(req): Observable<any> {
    const deleteProdCatalogToParty = URLS.deleteProdCatalogToParty;
    return this.http.post(deleteProdCatalogToParty,req);
  }
  getRoleTypeListNew(): Observable<any> {
    const getRoleTypeListNew = URLS.getRoleTypeListNew;
    return this.http.get(getRoleTypeListNew);
  }

  getProductCategoryToProdCatalogById(id): Observable<any> {
    const getProductCategoryToProdCatalogById = URLS.getProductCategoryToProdCatalogById+"?prodCatalogId="+id;
    return this.http.get(getProductCategoryToProdCatalogById);
  }
  getProductCategoryTypeList(): Observable<any> {
    const getProductCategoryTypeList = URLS.getProductCategoryTypeList;
    return this.http.get(getProductCategoryTypeList);
  }
  getCategoryId(): Observable<any> {
    const getCategoryId = URLS.getCategoryId;
    return this.http.get(getCategoryId);
  }
  createProductCategoryToProdCatalog(req): Observable<any> {
    const createProductCategoryToProdCatalog = URLS.createProductCategoryToProdCatalog;
    return this.http.post(createProductCategoryToProdCatalog,req);
  }
  updateProductCategoryToProdCatalog(req): Observable<any> {
    const updateProductCategoryToProdCatalog = URLS.updateProductCategoryToProdCatalog;
    return this.http.post(updateProductCategoryToProdCatalog,req);
  }
  removeProductCategoryToProdCatalog(req): Observable<any> {
    const removeProductCategoryToProdCatalog = URLS.removeProductCategoryToProdCatalog;
    return this.http.post(removeProductCategoryToProdCatalog,req);
  }

  productCatalog(id): Observable<any> {
    const productCatalog = URLS.productCatalog+"/"+id;
    return this.http.get(productCatalog);
  }
  createProductCatalogNew(req): Observable<any> {
    const createProductCatalogNew = URLS.createProductCatalogNew;
    return this.http.post(createProductCatalogNew,req);
  }
  updateProductCatalogNew(req): Observable<any> {
    const updateProductCatalogNew = URLS.updateProductCatalogNew;
    return this.http.put(updateProductCatalogNew,req);
  }
  
  getProductCategoryToCategory(id): Observable<any> {
    const getProductCategoryToCategory = URLS.getProductCategoryToCategory+"?productCategoryId="+id;
    return this.http.get(getProductCategoryToCategory);
  }
  getProductCategoryToCategorySecond(id): Observable<any> {
    const getProductCategoryToCategorySecond = URLS.getProductCategoryToCategorySecond+"?parentProductCategoryId="+id;
    return this.http.get(getProductCategoryToCategorySecond);
  }
  addProductCategoryToCategory(req): Observable<any> {
    const addProductCategoryToCategory = URLS.addProductCategoryToCategory;
    return this.http.post(addProductCategoryToCategory,req);
  }  
  updateProductCategoryToCategory(req): Observable<any> {
    const updateProductCategoryToCategory = URLS.updateProductCategoryToCategory;
    return this.http.post(updateProductCategoryToCategory,req);
  }  
  removeProductCategoryFromCategory(req): Observable<any> {
    const removeProductCategoryFromCategory = URLS.removeProductCategoryFromCategory;
    return this.http.post(removeProductCategoryFromCategory,req);
  }
  updateProductCategoryToCategoryS(req): Observable<any> {
    const updateProductCategoryToCategoryS = URLS.updateProductCategoryToCategoryS;
    return this.http.post(updateProductCategoryToCategoryS,req);
  }
  addProductCategoryToCategoryS(req): Observable<any> {
    const addProductCategoryToCategoryS = URLS.addProductCategoryToCategoryS;
    return this.http.post(addProductCategoryToCategoryS,req);
  }
  findProductFeatureIactnByIds(req): Observable<any> {
    const findProductFeatureIactnByIds = URLS.findProductFeatureIactnByIds;
    return this.http.post(findProductFeatureIactnByIds,req);
  }
  getFeaturePrice(id): Observable<any> {
    const getFeaturePrice = URLS.getFeaturePrice+"/"+id;
    return this.http.get(getFeaturePrice);
  }
  productPriceType(): Observable<any> {
    const productPriceType = URLS.productPriceType;
    return this.http.get(productPriceType);
  }
  createFeaturePrice(req): Observable<any> {
    const createFeaturePrice = URLS.createFeaturePrice;
    return this.http.post(createFeaturePrice,req);
  }
  updateFeaturePrice(req): Observable<any> {
    const updateFeaturePrice = URLS.updateFeaturePrice;
    return this.http.put(updateFeaturePrice,req);
  }
  deleteFeaturePrice(req): Observable<any> {
    const deleteFeaturePrice = URLS.deleteFeaturePrice;
    return this.http.post(deleteFeaturePrice,req);
  }
  findSupplierProductFeatureById(id): Observable<any> {
    const findSupplierProductFeatureById = URLS.findSupplierProductFeatureById+"/"+id;
    return this.http.get(findSupplierProductFeatureById);
  }
  addSupplierProductFeature(req): Observable<any> {
    const addSupplierProductFeature = URLS.addSupplierProductFeature;
    return this.http.post(addSupplierProductFeature,req);
  }
  updateSupplierProductFeature(req): Observable<any> {
    const updateSupplierProductFeature = URLS.updateSupplierProductFeature;
    return this.http.put(updateSupplierProductFeature,req);
  }
  deleteSupplierProductFeature(req): Observable<any> {
    const deleteSupplierProductFeature = URLS.deleteSupplierProductFeature;
    return this.http.post(deleteSupplierProductFeature,req);
  }
  getProductFeatureById(id): Observable<any> {
    const getProductFeatureById = URLS.getProductFeatureById+"/"+id;
    return this.http.get(getProductFeatureById);
  }
  updateProductFeature(req): Observable<any> {
    const updateProductFeature = URLS.updateProductFeature;
    return this.http.put(updateProductFeature,req);
  }
  getProductFeatureCategoryIdList(): Observable<any> {
    const getProductFeatureCategoryIdList = URLS.getProductFeatureCategoryIdList;
    return this.http.get(getProductFeatureCategoryIdList);
  }
  getProductFeatureTypeIdList(): Observable<any> {
    const getProductFeatureTypeIdList = URLS.getProductFeatureTypeIdList;
    return this.http.get(getProductFeatureTypeIdList);
  }

  getAllContactRelatedAccount(): Observable<any> {
    const getAllContactRelatedAccount = URLS.getAllContactRelatedAccount;
    return this.http.get(getAllContactRelatedAccount);
  }
  removeLayout(req): Observable<any> {
    const removeLayout = URLS.removeLayout;
    return this.http.post(removeLayout,req);
  }
  payFinish(req): Observable<any> {
    const payFinish = URLS.payFinish;
    return this.http.post(payFinish, req);
  }
  payCreditCard(req): Observable<any> {
    const payCreditCard = URLS.payCreditCard;
    return this.http.post(payCreditCard, req);
  }
  cardType(): Observable<any> {
    const cardType = URLS.cardType;
    return this.http.get(cardType);
  }
  month(): Observable<any> {
    const month = URLS.month;
    return this.http.get(month);
  }
  getOrderPageDetails(date): Observable<any> {
    const getOrderPageDetails = URLS.getOrderPageDetails+"?promisedDate="+date;
    return this.http.get(getOrderPageDetails);
  }
  getSecondCart(date): Observable<any> {
    const getSecondCart = URLS.getSecondCart+"?productId="+date;
    return this.http.get(getSecondCart);
  }
  updateProductIdS(req): Observable<any> {
    const updateProductIdS = URLS.updateProductIdS;
    return this.http.post(updateProductIdS, req);
  }
  refresh(id): Observable<any> {
    const refresh = URLS.refresh+"?userLoginId="+id;
    return this.http.post(refresh, '');
  }
  EmptyCart(): Observable<any> {
    const EmptyCart = URLS.EmptyCart;
    return this.http.post(EmptyCart, '');
  }
  remove(id,user): Observable<any> {
    const remove = URLS.remove+"?productId="+id+"&userLoginId="+user;
    return this.http.post(remove, '');
  }
  findPurchaseInvoices(req): Observable<any> {
    const findPurchaseInvoices = URLS.findPurchaseInvoices;
    return this.http.post(findPurchaseInvoices, req);
  }
  findReconciliations(req): Observable<any> {
    const findReconciliations = URLS.findReconciliations;
    return this.http.post(findReconciliations, req);
  }
  findPaymentGroupById(req): Observable<any> {
    const findPaymentGroupById = URLS.findPaymentGroupById;
    return this.http.post(findPaymentGroupById, req);
  }
  FindFinAccountTrans(req): Observable<any> {
    const FindFinAccountTrans = URLS.FindFinAccountTrans;
    return this.http.post(FindFinAccountTrans, req);
  }
  findDepositOrWithdrawNew(req): Observable<any> {
    const findDepositOrWithdrawNew = URLS.findDepositOrWithdrawNew;
    return this.http.post(findDepositOrWithdrawNew, req);
  }
  deleteProdCatalogToPartyNew(id): Observable<any> {
    const deleteProdCatalogToPartyNew = URLS.deleteProdCatalogToPartyNew+"?paymentGroupId="+id;
    return this.http.post(deleteProdCatalogToPartyNew, '');
  }

  // contactRelatedAccount(req): Observable<any> {
  //   const contactRelatedAccount = URLS.contactRelatedAccount;
  //   return this.http.post(contactRelatedAccount,req);
  // }

  getreasonEnumIds(): Observable<any> {
    const getreasonEnumIds = URLS.getreasonEnumIds;
    return this.http.get(getreasonEnumIds);
  }

  purposeType(): Observable<any> {
    const purposeType = URLS.purposeType;
    return this.http.get(purposeType);
  }

  parentContentIdLists(): Observable<any> {
    const parentContentIdLists = URLS.parentContentIdLists;
    return this.http.get(parentContentIdLists);
  }

  

  getOrderID(): Observable<any> {
    const getOrderID = URLS.getOrderID;
    return this.http.get(getOrderID);
  }
  getCommunicationEventOrders(id): Observable<any> {
    const getCommunicationEventOrders = URLS.getCommunicationEventOrders.replace(":communicationEventId", id);
    return this.http.get(getCommunicationEventOrders);
  }

  
  getListCommContent(id): Observable<any> {
    const getListCommContent = URLS.getListCommContent+ "?communicationEventId=" + id;
    return this.http.get(getListCommContent);
  }

  getFindLeads(req): Observable<any> {
    const getFindLeads = URLS.getFindLeads;
    return this.http.post(getFindLeads,req);
  }

  

  

  FindCommunicationByReturn(req): Observable<any> {
    const FindCommunicationByReturn = URLS.FindCommunicationByReturn;
    return this.http.post(FindCommunicationByReturn,req);
  }


uploadAttachFile(req,communicationEventId, contentId,contentParentId): Observable<any> {
    const uploadAttachFile = URLS.uploadAttachFile+"?communicationEventId="+communicationEventId+"&contentId="+contentId+"&contentParentId="+contentParentId;
    return this.http.post(uploadAttachFile,req);
  }

  
  getproductionRunId(productionRunId, contentLocale,pageNo,pageSize,partyId,productId): Observable<any> {
    const getproductionRunId = URLS.getproductionRunId+"/"+productionRunId+"?contentLocale="+contentLocale+"&pageNo="+pageNo+"&pageSize="+pageSize+"&partyId="+partyId+"&productId="+productId;
    return this.http.get(getproductionRunId);
  }

  deleteCrmEventOrderLists(communicationEventId, orderId): Observable<any> {
    const deleteCrmEventOrderLists = URLS.deleteCrmEventOrderLists+"/"+communicationEventId+"?orderId="+orderId;
  return this.http.delete(deleteCrmEventOrderLists);
  }

  deleteUploadFile(UploadAttachFileId): Observable<any> {
    const deleteUploadFile = URLS.deleteUploadFile+"?UploadAttachFileId="+UploadAttachFileId;
  return this.http.post(deleteUploadFile,'');
  }

  

  deleteCommunicationEventProduct(communicationEventId, productId): Observable<any> {
    const deleteCommunicationEventProduct = URLS.deleteCommunicationEventProduct+"/"+communicationEventId+"?productId="+productId;
  return this.http.delete(deleteCommunicationEventProduct);
  }

  deleteCommunicationEventRoles(req): Observable<any> {
    const deleteCommunicationEventRoles = URLS.deleteCommunicationEventRoles;
    return this.http.post(deleteCommunicationEventRoles,req);
  }

  
  getCrmEventReturnLists(id): Observable<any> {
    const getCrmEventReturnLists = URLS.getCrmEventReturnLists+ "?communicationEventId=" + id;
    return this.http.get(getCrmEventReturnLists);
  }
  
createCommunicationEventOrder(communicationEventId,orderId): Observable<any> {
    const createCommunicationEventOrder = URLS.createCommunicationEventOrder+"/"+communicationEventId+"?orderId="+orderId;
    return this.http.post(createCommunicationEventOrder,'');
  }

  assignLead(partyIdFrom,partyIdTo): Observable<any> {
    const assignLead = URLS.assignLead+"?partyIdFrom="+partyIdFrom+"&partyIdTo="+partyIdTo;
     return this.http.post(assignLead,'');
  }
  findFixedAssetsNew(req): Observable<any> {
    const findFixedAssetsNew = URLS.findFixedAssetsNew;
     return this.http.post(findFixedAssetsNew,req);
  }
  createCommissionInvoice(req): Observable<any> {
    const createCommissionInvoice = URLS.createCommissionInvoice;
     return this.http.post(createCommissionInvoice,req);
  }
  communicationEvents(id): Observable<any> {
    const communicationEvents = URLS.communicationEvents+"/"+id;
     return this.http.delete(communicationEvents);
  }
  productMaint(id): Observable<any> {
    const productMaint = URLS.productMaint+"?fixedAssetId="+id;
     return this.http.post(productMaint,'');
  }
  getPermission(id): Observable<any> {
    const getPermission = URLS.getPermission+"?userLoginId="+id;
    return this.http.get(getPermission);
  }
  createInventoryItem(req): Observable<any> {
    const createInventoryItem = URLS.createInventoryItem;
    return this.http.post(createInventoryItem,req);
  }

  createFacilityLocation(facilityId,requestData): Observable<any> {
    const createFacilityLocation = URLS.createFacilityLocation+"/"+facilityId;
    return this.http.post(createFacilityLocation,requestData);
  }

  generatePdfs(action,facilityId): Observable<any> {
    const generatePdfs = URLS.generatePdfs+"?action="+action+"&facilityId="+facilityId;
    return this.http.get(generatePdfs, { responseType: "blob" });
  }

  ViewInventoryItemDetail(facilityId,inventoryItemId): Observable<any> {
    const ViewInventoryItemDetail = URLS.ViewInventoryItemDetail+"?facilityId="+facilityId+"&inventoryItemId="+inventoryItemId;
     return this.http.get(ViewInventoryItemDetail);
  }

  ViewInventoryItemDetailSecond(facilityId,inventoryItemId): Observable<any> {
    const ViewInventoryItemDetailSecond = URLS.ViewInventoryItemDetailSecond+"?facilityId="+facilityId+"&inventoryItemId="+inventoryItemId;
     return this.http.get(ViewInventoryItemDetailSecond);
  }

  

  EditInventoryItemLabels(inventoryItemId): Observable<any> {
    const EditInventoryItemLabels = URLS.EditInventoryItemLabels+"?&inventoryItemId="+inventoryItemId;
     return this.http.get(EditInventoryItemLabels);
  }

  createInventoryItemLabelApplFromItem(requestData): Observable<any> {
    const createInventoryItemLabelApplFromItem = URLS.createInventoryItemLabelApplFromItem;
    return this.http.post(createInventoryItemLabelApplFromItem,requestData);
  }

  updateInventoryItemLabelApplFromItem(requestData): Observable<any> {
    const updateInventoryItemLabelApplFromItem = URLS.updateInventoryItemLabelApplFromItem;
    return this.http.post(updateInventoryItemLabelApplFromItem,requestData);
  }

  deleteInventoryItemLabelApplFromItem(req): Observable<any> {
    const deleteInventoryItemLabelApplFromItem = URLS.deleteInventoryItemLabelApplFromItem;
    return this.http.post(deleteInventoryItemLabelApplFromItem,req);
  }
  
  inventoryItemLabelId(): Observable<any> {
    const inventoryItemLabelId = URLS.inventoryItemLabelId;
    return this.http.get(inventoryItemLabelId);
  }

  SearchInventoryItemsByLabelsDropDownValue(): Observable<any> {
    const SearchInventoryItemsByLabelsDropDownValue = URLS.SearchInventoryItemsByLabelsDropDownValue;
    return this.http.get(SearchInventoryItemsByLabelsDropDownValue);
  }

   getInventorySummaryPage(inventoryItemId): Observable<any> {
    const getInventorySummaryPage = URLS.getInventorySummaryPage+"?inventoryItemId="+inventoryItemId;
    return this.http.get(getInventorySummaryPage);
  }

  SearchInventoryItemsByLabels(inventoryItemLabelId): Observable<any> {
    const SearchInventoryItemsByLabels = URLS.SearchInventoryItemsByLabels+"?inventoryItemLabelId="+inventoryItemLabelId;
    return this.http.get(SearchInventoryItemsByLabels);
  }

  getShipmentItem(shipmentId): Observable<any> {
    const getShipmentItem = URLS.getShipmentItem+"?shipmentId="+shipmentId;
    return this.http.get(getShipmentItem);
  }

  createShipmentItem(req): Observable<any> {
    const createShipmentItem = URLS.createShipmentItem;
    return this.http.post(createShipmentItem,req);
  }

  updateShipmentItem(req): Observable<any> {
    const updateShipmentItem = URLS.updateShipmentItem;
    return this.http.post(updateShipmentItem,req);
  }

  deleteShipmentItem(req): Observable<any> {
    const deleteShipmentItem = URLS.deleteShipmentItem;
    return this.http.post(deleteShipmentItem,req);
  }

  EditShipmentPlan(req): Observable<any> {
    const EditShipmentPlan = URLS.EditShipmentPlan;
    return this.http.post(EditShipmentPlan,req);
  }

  addToShipmentPlan(req): Observable<any> {
    const addToShipmentPlan = URLS.addToShipmentPlan;
    return this.http.post(addToShipmentPlan,req);
  }

  deleteShipmentPackageContent(req): Observable<any> {
    const deleteShipmentPackageContent = URLS.deleteShipmentPackageContent;
    return this.http.post(deleteShipmentPackageContent,req);
  }

  createShipmentPackageContent(req): Observable<any> {
    const createShipmentPackageContent = URLS.createShipmentPackageContent;
    return this.http.post(createShipmentPackageContent,req);
  }

  EditShipmentPackages(shipmentId): Observable<any> {
    const EditShipmentPackages = URLS.EditShipmentPackages+"?shipmentId="+shipmentId;
    return this.http.get(EditShipmentPackages);
  }
  
  AddItemsFromOrder(orderId,shipGroupSeqId,shipmentId): Observable<any> {
    const AddItemsFromOrder = URLS.AddItemsFromOrder+"?orderId="+orderId+"&shipGroupSeqId="+shipGroupSeqId+"&shipmentId="+shipmentId;
    return this.http.get(AddItemsFromOrder);
  }

  ReceiveInventoryAgainstPurchaseOrder(orderId,shipGroupSeqId,shipmentId): Observable<any> {
    const ReceiveInventoryAgainstPurchaseOrder = URLS.ReceiveInventoryAgainstPurchaseOrder+"?orderId="+orderId+"&shipGroupSeqId="+shipGroupSeqId+"&shipmentId="+shipmentId;
    return this.http.get(ReceiveInventoryAgainstPurchaseOrder);
  }
  issueOrderItemShipGrpInvResToShipment(req): Observable<any> {
    const issueOrderItemShipGrpInvResToShipment = URLS.issueOrderItemShipGrpInvResToShipment;
    return this.http.post(issueOrderItemShipGrpInvResToShipment,req);
  }
  
  billingWeightUomId(): Observable<any> {
    const billingWeightUomId = URLS.billingWeightUomId;
    return this.http.get(billingWeightUomId);
  }

  carrierPartyId(): Observable<any> {
    const carrierPartyId = URLS.carrierPartyId;
    return this.http.get(carrierPartyId);
  }

  carrierServiceStatusId(): Observable<any> {
    const carrierServiceStatusId = URLS.carrierServiceStatusId;
    return this.http.get(carrierServiceStatusId);
  }

  ShipmentMethodType(): Observable<any> {
    const ShipmentMethodType = URLS.ShipmentMethodType;
    return this.http.get(ShipmentMethodType);
  }

  deleteShipmentPackage(req): Observable<any> {
    const deleteShipmentPackage = URLS.deleteShipmentPackage;
    return this.http.post(deleteShipmentPackage,req);
  }

  updateShipmentPackageRouteSeg(req): Observable<any> {
    const updateShipmentPackageRouteSeg = URLS.updateShipmentPackageRouteSeg;
    return this.http.post(updateShipmentPackageRouteSeg,req);
  }

  deleteShipmentPackageRouteSeg(req): Observable<any> {
    const deleteShipmentPackageRouteSeg = URLS.deleteShipmentPackageRouteSeg;
    return this.http.post(deleteShipmentPackageRouteSeg,req);
  }

  deleteShipmentRouteSegment(req): Observable<any> {
    const deleteShipmentRouteSegment = URLS.deleteShipmentRouteSegment;
    return this.http.post(deleteShipmentRouteSegment,req);
  }

  duplicateShipmentRouteSegment(req): Observable<any> {
    const duplicateShipmentRouteSegment = URLS.duplicateShipmentRouteSegment;
    return this.http.post(duplicateShipmentRouteSegment,req);
  }

  upsShipmentConfirm(req): Observable<any> {
    const upsShipmentConfirm = URLS.upsShipmentConfirm;
    return this.http.post(upsShipmentConfirm,req);
  }

  updateShipmentRouteSegment(req): Observable<any> {
    const updateShipmentRouteSegment = URLS.updateShipmentRouteSegment;
    return this.http.post(updateShipmentRouteSegment,req);
  }

  shipmentDropDown(): Observable<any> {
    const shipmentDropDown = URLS.shipmentDropDown;
    return this.http.get(shipmentDropDown);
  }
  
  shipmentboxTypeDropDown(): Observable<any> {
    const shipmentboxTypeDropDown = URLS.shipmentboxTypeDropDown;
    return this.http.get(shipmentboxTypeDropDown);
  }

  EditShipmentRouteSegments(shipmentId): Observable<any> {
    const EditShipmentRouteSegments = URLS.EditShipmentRouteSegments+"?shipmentId="+shipmentId;
    return this.http.get(EditShipmentRouteSegments);
  }

  getShipmentReceipts(shipmentId): Observable<any> {
    const getShipmentReceipts = URLS.getShipmentReceipts+"?shipmentId="+shipmentId;
    return this.http.get(getShipmentReceipts);
  }

  getAllEvent(): Observable<any> {
    const getAllEvent = URLS.getAllEvent;
    return this.http.get(getAllEvent);
  }
  createShipmentRouteSegment(req): Observable<any> {
    const createShipmentRouteSegment = URLS.createShipmentRouteSegment;
    return this.http.post(createShipmentRouteSegment,req);
  }
  findInactiveUser(id): Observable<any> {
    const findInactiveUser = URLS.findInactiveUser+"?status="+id;
    return this.http.get(findInactiveUser);
  }
  generateBudgetPdf(id): Observable<any> {
    const generateBudgetPdf = URLS.generateBudgetPdf+"?budgetId="+id;
    return this.http.get(generateBudgetPdf, { responseType: "blob" });
  }
  generateBillingPdf(id): Observable<any> {
    const generateBillingPdf = URLS.generateBillingPdf+"?billingAccountId="+id;
    return this.http.get(generateBillingPdf, { responseType: "blob" });
  }
  generateInvoicePdf(id): Observable<any> {
    const generateInvoicePdf = URLS.generateInvoicePdf+"?invoiceId="+id;
    return this.http.get(generateInvoicePdf, { responseType: "blob" });
  }
  userDetailsSaas(id): Observable<any> {
    const userDetailsSaas = ':8085/api/v1/saas/user/user-details'  + "?email=" + id ;
    return this.http.get(userDetailsSaas);
  }
  updateUserDetails(form): Observable<any> {
    const updateUserDetails = ':8085/api/v1/saas/user/updateUser' ;
    return this.http.put(updateUserDetails,form);
  }
  createOrUpdateOrderDeliverySchedule(req): Observable<any> {
    const createOrUpdateOrderDeliverySchedule = URLS.createOrUpdateOrderDeliverySchedule;
    return this.http.post(createOrUpdateOrderDeliverySchedule,req);
  }
  editOrderItemDetails(id): Observable<any> {
    const editOrderItemDetails = URLS.editOrderItemDetails+"/"+id;
    return this.http.get(editOrderItemDetails);
  }
  getCubicMeasurementList(): Observable<any> {
    const getCubicMeasurementList = URLS.getCubicMeasurementList;
    return this.http.get(getCubicMeasurementList);
  }
  getWeightMeasurementList(): Observable<any> {
    const getWeightMeasurementList = URLS.getWeightMeasurementList;
    return this.http.get(getWeightMeasurementList);
  }
  addOrderItemShipGroup(id): Observable<any>{
    const addOrderItemShipGroup= URLS.addOrderItemShipGroup+'?orderId='+id;
    return this.http.post(addOrderItemShipGroup,id)
  }
  getAccountByAccountId(id): Observable<any> {
    const getAccountByAccountId = URLS.getAccountByAccountId+'?accountId='+id;
    return this.http.get(getAccountByAccountId);
  }
  createOrder(req): Observable<any> {
    const createOrder = URLS.createOrder;
    return this.http.post(createOrder,req);
  }
  updateOrder(req): Observable<any> {
    const updateOrder = URLS.updateOrder;
    return this.http.post(updateOrder,req);
  }
  deleteOrder(req): Observable<any> {
    const deleteOrder = URLS.deleteOrder;
    return this.http.post(deleteOrder,req);
  }
  getOrderByPartyId(id): Observable<any> {
    const getOrderByPartyId = URLS.getOrderByPartyId+'?partyId='+id;
    return this.http.get(getOrderByPartyId);
  }
  getCatalogRequestByPartyId(id): Observable<any> {
    const getCatalogRequestByPartyId = URLS.getCatalogRequestByPartyId+'?partyId='+id;
    return this.http.get(getCatalogRequestByPartyId);
  }
  createCatalogRequest(req): Observable<any> {
    const createCatalogRequest = URLS.createCatalogRequest;
    return this.http.post(createCatalogRequest,req);
  }
  UpdateCatalogRequest(req): Observable<any> {
    const UpdateCatalogRequest = URLS.UpdateCatalogRequest;
    return this.http.post(UpdateCatalogRequest,req);
  }
  deleteCatalogRequest(req): Observable<any> {
    const deleteCatalogRequest = URLS.deleteCatalogRequest;
    return this.http.post(deleteCatalogRequest,req);
  }
  getCasessByPartyId(id): Observable<any> {
    const getCasessByPartyId = URLS.getCasessByPartyId+'?partyId='+id;
    return this.http.get(getCasessByPartyId);
  }
  createCasess(req): Observable<any> {
    const createCasess = URLS.createCasess;
    return this.http.post(createCasess,req);
  }
  updateCasess(req): Observable<any> {
    const updateCasess = URLS.updateCasess;
    return this.http.post(updateCasess,req);
  }
  deleteCasess(req): Observable<any> {
    const deleteCasess = URLS.deleteCasess;
    return this.http.post(deleteCasess,req);
  }
  getPendingActivityNewTaskByTaskId(id): Observable<any> {
    const getPendingActivityNewTaskByTaskId = URLS.getPendingActivityNewTaskByTaskId+'?partyId='+id;
    return this.http.get(getPendingActivityNewTaskByTaskId);
  }
  createPendingActivityNewTask(req): Observable<any> {
    const createPendingActivityNewTask = URLS.createPendingActivityNewTask;
    return this.http.post(createPendingActivityNewTask,req);
  }
  updatePendingActivityNewTask(req): Observable<any> {
    const updatePendingActivityNewTask = URLS.updatePendingActivityNewTask;
    return this.http.post(updatePendingActivityNewTask,req);
  }
  deletePendingActivityTaskPartnerNew(req): Observable<any> {
    const deletePendingActivityTaskPartnerNew = URLS.deletePendingActivityTaskPartnerNew;
    return this.http.post(deletePendingActivityTaskPartnerNew,req);
  }
  getActivityHistoryLogCallByPartyId(id): Observable<any> {
    const getActivityHistoryLogCallByPartyId = URLS.getActivityHistoryLogCallByPartyId+'?partyId='+id;
    return this.http.get(getActivityHistoryLogCallByPartyId);
  }
  createActivityHistoryLogCall(req): Observable<any> {
    const createActivityHistoryLogCall = URLS.createActivityHistoryLogCall;
    return this.http.post(createActivityHistoryLogCall,req);
  }
  updateActivityHistoryLogCall(req): Observable<any> {
    const updateActivityHistoryLogCall = URLS.updateActivityHistoryLogCall;
    return this.http.post(updateActivityHistoryLogCall,req);
  }
  deleteActivityHistoryLogCall(req): Observable<any> {
    const deleteActivityHistoryLogCall = URLS.deleteActivityHistoryLogCall;
    return this.http.post(deleteActivityHistoryLogCall,req);
  }
  
  getBookMarkUrlByPartyId(id): Observable<any> {
    const getBookMarkUrlByPartyId = URLS.getBookMarkUrlByPartyId+'?partyId='+id;
    return this.http.get(getBookMarkUrlByPartyId);
  }
  createBookMarkUrl(req): Observable<any> {
    const createBookMarkUrl = URLS.createBookMarkUrl;
    return this.http.post(createBookMarkUrl,req);
  }
  updateBookMarkUrl(req): Observable<any> {
    const updateBookMarkUrl = URLS.updateBookMarkUrl;
    return this.http.post(updateBookMarkUrl,req);
  }
  deleteBookMarkUrl(req): Observable<any> {
    const deleteBookMarkUrl = URLS.deleteBookMarkUrl;
    return this.http.post(deleteBookMarkUrl,req);
  }
  getCasesCatalogRequestByCustRequestId(id): Observable<any> {
    const getCasesCatalogRequestByCustRequestId = URLS.getCasesCatalogRequestByCustRequestId+'?custRequestId='+id;
    return this.http.get(getCasesCatalogRequestByCustRequestId);
  }
  createCasesCatalogRequest(req): Observable<any> {
    const createCasesCatalogRequest = URLS.createCasesCatalogRequest;
    return this.http.post(createCasesCatalogRequest,req);
  }
  updateCasesCatalogRequest(req): Observable<any> {
    const updateCasesCatalogRequest = URLS.updateCasesCatalogRequest;
    return this.http.post(updateCasesCatalogRequest,req);
  }
  deleteCasesCatalogRequest(req): Observable<any> {
    const deleteCasesCatalogRequest = URLS.deleteCasesCatalogRequest;
    return this.http.post(deleteCasesCatalogRequest,req);
  }

  getCasesOrderByCustRequestId(id): Observable<any> {
    const getCasesOrderByCustRequestId = URLS.getCasesOrderByCustRequestId+'?custRequestId='+id;
    return this.http.get(getCasesOrderByCustRequestId);
  }
  createCasesOrder(req): Observable<any> {
    const createCasesOrder = URLS.createCasesOrder;
    return this.http.post(createCasesOrder,req);
  }
  updateCasesOrder(req): Observable<any> {
    const updateCasesOrder = URLS.updateCasesOrder;
    return this.http.post(updateCasesOrder,req);
  }
  deleteCasesOrder(req): Observable<any> {
    const deleteCasesOrder = URLS.deleteCasesOrder;
    return this.http.post(deleteCasesOrder,req);
  }
  getCasesBookMarkUrlByCustRequestId(id): Observable<any> {
    const getCasesBookMarkUrlByCustRequestId = URLS.getCasesBookMarkUrlByCustRequestId+'?custRequestId='+id;
    return this.http.get(getCasesBookMarkUrlByCustRequestId);
  }
  createCasesBookMarkUrl(req): Observable<any> {
    const createCasesBookMarkUrl = URLS.createCasesBookMarkUrl;
    return this.http.post(createCasesBookMarkUrl,req);
  }
  updateCasesBookMarkUrl(req): Observable<any> {
    const updateCasesBookMarkUrl = URLS.updateCasesBookMarkUrl;
    return this.http.post(updateCasesBookMarkUrl,req);
  }
  deleteCasesBookMarkUrl(req): Observable<any> {
    const deleteCasesBookMarkUrl = URLS.deleteCasesBookMarkUrl;
    return this.http.post(deleteCasesBookMarkUrl,req);
  }
  getOpportunityCasesByCustReqId(id): Observable<any> {
    const getOpportunityCasesByCustReqId = URLS.getOpportunityCasesByCustReqId+'?custRequestId='+id;
    return this.http.get(getOpportunityCasesByCustReqId);
  }
  createOpportunityCases(req): Observable<any> {
    const createOpportunityCases = URLS.createOpportunityCases;
    return this.http.post(createOpportunityCases,req);
  }
  updateOpportunityCases(req): Observable<any> {
    const updateOpportunityCases = URLS.updateOpportunityCases;
    return this.http.post(updateOpportunityCases,req);
  }
  deleteOpportunityCases(req): Observable<any> {
    const deleteOpportunityCases = URLS.deleteOpportunityCases;
    return this.http.post(deleteOpportunityCases,req);
  }
  
  getCasesAccountByCustReqId(id): Observable<any> {
    const getCasesAccountByCustReqId = URLS.getCasesAccountByCustReqId+'?custRequestId='+id;
    return this.http.get(getCasesAccountByCustReqId);
  }
  createCasesAccount(req): Observable<any> {
    const createCasesAccount = URLS.createCasesAccount;
    return this.http.post(createCasesAccount,req);
  }
  updateCasesAccount(req): Observable<any> {
    const updateCasesAccount = URLS.updateCasesAccount;
    return this.http.post(updateCasesAccount,req);
  }
  deleteCasesAccount(req): Observable<any> {
    const deleteCasesAccount = URLS.deleteCasesAccount;
    return this.http.post(deleteCasesAccount,req);
  }

  updateFsmWorkOrder(req): Observable<any> {
    const updateFsmWorkOrder = URLS.updateFsmWorkOrder;
    return this.http.post(updateFsmWorkOrder,req);
  }

}
