<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color" >Events</span>
                <span class="color-black pl-1"> > New</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Edit Contact Information

                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Contact Purposes	</label>
                                            <p-dropdown type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Id"></p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">To Name</label>
                                            <div class="">
                                                <input type="text" placeholder="Enter To Name"   
                                                >
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Event Name <span class="color:red">*</span></label>
                                            <input type="email"  estimatedStartDate id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Event name">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <input type="email" class="form-control"  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Priority</label>
                                            <p-dropdown filter="true"
                                            optionlabel="label">
                                        </p-dropdown>                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Estimated Start Date</label>
                                            <div class="">
                                                
                                                    <div class="row">
                                                        <div class="form-group col-lg-6 col-12">
                                                            <input class="form-control" #datepickerFull="bsDatepicker"
                                                                bsDatepicker >
                                                        </div>
                                                       
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Estimated Completion Date</label>
                                            <div class="">
                                                
                                                    <div class="row">
                                                        <div class="form-group col-lg-6 col-12">
                                                            <input class="form-control"  #datepickerFull="bsDatepicker"
                                                                bsDatepicker >
                                                        </div>
                                                      
                                                    </div>
                                                
                                            </div>

                                        </div>
                                    </div>
                            
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
          
            </div>
            <div class="main-submit-button">
                <button type="submit"  class="btn btn-secondary submit-btn">Update </button>

                <button type="submit" routerLink="/crm/events" routerLinkActive="active" class="btn btn-degault cncl-btn">Cancel</button>

            </div>
        </div>
    </div>
    
</div>
