import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
    import { Location } from '@angular/common';
    import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-add-route-task',
  templateUrl: './add-route-task.component.html',
  styleUrls: ['./add-route-task.component.css']
})
export class AddRouteTaskComponent implements OnInit {
  createroute: FormGroup;
  activeCategory = 2;
  fixedAssetId: any;
  estimateCalcMethod: any;
  routingTaskType: any;
  fixedAssetIdArray: any[]=[];
  estimateCalcMethodArray: any[]=[];
  routingTaskTypeArray: any[]=[];
  routingTaskId: any;
  show: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,  private _location: Location,
    
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createroute = this._FormBuilder.group({
      RoutingTaskName: [''],
      TaskType: [''],
      Description: [''],
      FixedAssetID: [''],
      EstimatedSetupTime: [''],
      EstimatedUnitRunTime: [''],
      EstimateCalcMethod: [''],
      ReservPersons: [''],



    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingTaskId = params["routingTaskId"];
    
    
    });
  this.getRoutingTasksinitialData();
  if(this.routingTaskId){
    this.show=true;
    this.getRoutingTasks();
  }else{
    this.show=false;
  }
  }
  getRoutingTasks() {
    this.spinner.show();
    const req={
      "fixedAssetId": "",
      "workEffortId": this.routingTaskId,
      "workEffortIdSearchType": "Contains",
      "workEffortName": "",
      "workEffortNameSearchType": "Contains"
    }
    this.productionService.getRoutingTasks(this.finSize,req).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.createroute;
        formValue.patchValue({
          RoutingTaskName: res.data[0].workEffortName,
          TaskType:  res.data[0].workEffortPurposeTypeId,
          Description:  res.data[0].description,
          FixedAssetID:  res.data[0].fixedAssetId,
          EstimatedSetupTime: res.data[0].estimatedSetupMillis,
          EstimatedUnitRunTime:  res.data[0].estimatedMilliSeconds,
          EstimateCalcMethod:  res.data[0].estimateCalcMethod,
          ReservPersons:  res.data[0].reservPersons,
        })
      }, 2000);
    })
  }
  getRoutingTasksinitialData() {
    this.spinner.show();
    this.productionService.getRoutingTasksinitialData().subscribe(res => {
      this.fixedAssetId = res.data.fixedAssetId;
      this.estimateCalcMethod = res.data.estimateCalcMethod;
      this.routingTaskType = res.data.routingTaskType;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
      for (const value of this.estimateCalcMethod) {
        this.estimateCalcMethodArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
      for (const value of this.routingTaskType) {
        this.routingTaskTypeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
    
  }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "currentStatusId": "",
      "description": this.createroute.value.Description,
      "estimateCalcMethod": this.createroute.value.EstimateCalcMethod,
      "estimatedMilliSeconds": this.createroute.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createroute.value.EstimatedSetupTime,
      "fixedAssetId": this.createroute.value.FixedAssetID,
      "reservPersons": this.createroute.value.ReservPersons,
      "workEffortId":"",
      "workEffortName": this.createroute.value.RoutingTaskName,
      "workEffortPurposeTypeId": this.createroute.value.TaskType,
      "workEffortTypeId": "",
    }
    this.productionService.postRoutingTasks(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(['/production/routing-task']);
      }}, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });}
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "currentStatusId": "",
      "description": this.createroute.value.Description,
      "estimateCalcMethod": this.createroute.value.EstimateCalcMethod,
      "estimatedMilliSeconds": this.createroute.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createroute.value.EstimatedSetupTime,
      "fixedAssetId": this.createroute.value.FixedAssetID,
      "reservPersons": this.createroute.value.ReservPersons,
      "workEffortId":this.routingTaskId,
      "workEffortName": this.createroute.value.RoutingTaskName,
      "workEffortPurposeTypeId": this.createroute.value.TaskType,
      "workEffortTypeId": "",
    }
    this.productionService.updateRoutingTasks(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(['/production/routing-task']);
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this._ToastrService.error(err.error.message);  }
    });}
  // cancelSubmit() {
  //   this._Router.navigate(['/production/routing-task']);
  // }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
