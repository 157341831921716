<div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12" style="padding: 0;">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Shipping </span>

               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="mb-5" style="border-left-width: 0;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="w3-card-4 classCard">
                            <div class="container-fluid">

                                <ul class="tabbing-section tabbing-accordians tabClass">

                                    <li [ngClass]="activeCategory==2?'active':''"><a>
                                            Shipment Method Types</a></li>
                                    <li routerLinkActive="active" [ngClass]="activeCategory==3?'active':''" routerLink="/facilities/catalog/shipment/quantity-breaks"><a>
                                            Quantity Breaks</a></li>

                                    <li routerLinkActive="active" [ngClass]="activeCategory==4?'active':''" routerLink="/facilities/catalog/shipment/carrier-shipment"><a>
                                            Carrier Shipment Methods </a></li>
                                    <li routerLinkActive="active" [ngClass]="activeCategory==5?'active':''" routerLink="/facilities/catalog/shipment/shipment-time-estimate"><a>
                                            Shipment Time Estimates
                                        </a></li>

                                </ul>
                            </div>


                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" style="    padding: 15px 15px;">
                                            <div class="w3-card-4 classCard">
                                                <header class="w3-container w3-blue">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling h4Margin">
                                                            List Shipment Method Types
                                                        </h4>
                                                        <span *ngIf="!this.Hidebtn" class="edit-buttons hCreate">
                                                            <a data-toggle="modal" data-target="#exampleModalCenter">Create New Shipment Method Type</a>
                                                        </span>
                                                    </div>
                                                </header>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table" id="eventForm">
                                                                <p-table [value]="shipmentMethodType" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Shipment Method Type ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="name">
                                                                                <div style="color: white;"> Description
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="category">
                                                                                <div style="color: white;"> Sequence Num
                                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th pSortableColumn="price">
                                                                                <div style="color: white;"> Action</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"   (click)="detailPage(product.shipmentMethodTypeId,
                                                                            product.description,product.sequenceNum)">
                                                                                        {{product.shipmentMethodTypeId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td>{{product.description}}</td>
                                                                            <td>{{product.sequenceNum}}</td>
                                                                            <td>
                                                                                <div>
                                                                                    <!--      <svg class="hover"
                                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                (click)="edit(product.shipmentMethodTypeId,product.description,product.sequenceNum)"
                                                                                height="16" viewBox="0 0 21.223 21.222">
                                                                                <g transform="translate(0 -0.004)">
                                                                                    <g transform="translate(0 1.52)">
                                                                                        <path
                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                            transform="translate(0 -34.137)" />
                                                                                    </g>
                                                                                    <g transform="translate(4.548 0.004)">
                                                                                        <g transform="translate(0 0)">
                                                                                            <path
                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </svg> -->
                                                                                    <svg *ngIf="!this.Hidebtn" xmlns="http://www.w3.org/2000/svg" (click)="delete(product.shipmentMethodTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                    cursor: pointer;
                                                                                                }
        
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                    cursor: pointer;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><br>
                                            <br><br><br>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Shipment Method Types</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategorytab==2?'active':''"><a>
                                    {{editMode ? 'Update':'Create'}} Shipment Method Types</a></li>


                        </ul>
                    </div>

                    <div>

                        <div class="row">
                            <form class="w-100" [formGroup]="shipmentMethodTypeForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Shipment Method Type ID <span
                                                        style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipment Method Type ID" formControlName="shipmentMethodTypeId">
                                                <small class="text-danger" *ngIf="!!shipmentMethodTypeForm.controls.shipmentMethodTypeId.invalid && !!shipmentMethodTypeForm.controls.shipmentMethodTypeId.touched">Required</small>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Sequence Num</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num" formControlName="sequenceNum">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="description" rows="1" placeholder="Enter Description"></textarea>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="
                            margin-left: -4%;">
                                    <button _ngcontent-cta-c128="" type="button" class="btn btn-secondary submit-btn" (click)="submit()">Submit</button>

                                </div>
                            </form>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>