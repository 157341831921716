<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <span class="color-black pl-1 titlePanels">Calendar</span>
                    <span class="d-flex">
     
                     <button type="submit" (click)="homeButton();"
                         class=" btn btn-outline-secondary mr-2">Home</button>
             
                     <button type="submit" (click)="cancelSubmit();"
                         class="btn btn-danger buttonclass ">Back</button>
                 </span>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Calendar</a></li>
                            <!-- <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
                                Main</a></li> -->

                                <button class="btn btn-primary float-right mt-1" style="margin-left:82% !important;" data-toggle="modal" data-target="#exampleModalCal" 
                                (click)="addEvent()">
                                    Add new
                                  </button>



                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="panel-body">

                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        System info note for user: {{ this.partyId}}
                                                                    </h4>
                                                                    <div class="float-right">

                                                                        <span class="edit-buttons" style="cursor: pointer;" data-toggle="modal" data-target="#exampleModalCenter">Create
                                                                            Public
                                                                            Message |</span>
                                                                        <span class="edit-buttons" style="cursor: pointer;" (click)="deleteALL()">Delete
                                                                            All</span>
                                                                    </div>
                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="SystemInfoNote" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Date Info Created
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                System Info Note
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span style="cursor: pointer;" (click)="detailMainPage(product.noteId,product.noteDateTime,product.noteInfo)" 
                                                                                                class="account-button">
                                                                                                {{product.noteDateTime|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.noteInfo}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <svg (click)="deleteSystemInfoNote(product.noteId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                                <defs>
                                                                                                    <style>
                                                                                                        .a {
                                                                                                            fill: #f44336;
                                                                                                        }

                                                                                                        .b {
                                                                                                            fill: #fafafa;
                                                                                                            margin-left: 20px;
                                                                                                        }
                                                                                                    </style>
                                                                                                </defs>
                                                                                                <path
                                                                                                    class="a fill-color"
                                                                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                <path class="b"
                                                                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                    transform="translate(-147.576 -147.576)" />
                                                                                            </svg>
                                                                                        </td>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-body">

                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        System info status for user: {{ this.partyId}}
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="SystemInfoStatus" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Date Last Changed
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                System Info Status
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span class="account-button">
                                                                                                    {{product.noteDateTime|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.noteInfo}}
                                                                                        </td>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==2">
                                                    <div class="calendar-full" *ngIf="activeTabbing==1" style="padding-bottom:60px">
                                                        <div class="w3-card-4 ">
                                                            <div class="row text-center d-flex align-items-center" style="padding-top:20px">
                                                                <div class="col-md-4 d-flex" style="padding-left:30px">
                                                                    <div class="btn-group">
                                                                        <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                                                            Previous
                                                                        </div>
                                                                        <div class="btn btn-outlinengMo-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                                                                            Today
                                                                        </div>
                                                                        <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                                                            Next
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <h3 class="mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                                                </div>
                                                                <div class="col-md-4 d-flex justify-content-end" style=" padding-right:40px">
                                                                    <div class="btn-group">
                                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                                                                            Month
                                                                        </div>
                                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                                                                            Week
                                                                        </div>
                                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                                                                            Day
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div [ngSwitch]="view">
                                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                                </mwl-calendar-month-view>
                                                                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                                </mwl-calendar-week-view>
                                                                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                                </mwl-calendar-day-view>
                                                            </div>

                                                            <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

                                                            <br /><br /><br />

                                                            <h3>
                                                                <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span>
                                                                <!-- <button class="btn btn-primary float-right" (click)="addEvent()">
                                                            Add new
                                                          </button> -->
                                                                <div class="clearfix"></div>
                                                            </h3>
                                                            <hr />
                                                            <ng-container *ngIf="isForm">
                                                                <div class="mt-5 mb-5" id="eventForm" #eventForm>
                                                                    <form [formGroup]="calendarEventForm">
                                                                        <div class="row">
                                                                            <div class="col-md-6">
                                                                                <div class="form-group">
                                                                                    <div class="d-flex flex-column">
                                                                                        <label for="training">Training Name</label>
                                                                                        <p-dropdown [options]="trainingClassTypes" filter="true" 
                                                                                        formControlName="workEffortName" placeholder="Training Name"> </p-dropdown>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <div class="form-group">
                                                                                    <label for="description">Description <span class="text-danger">*</span></label>
                                                                                    <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                                                                    <small class="text-danger" *ngIf="!!calendarEventForm.controls.description.invalid && !!calendarEventForm.controls.touched">Description
                                                                      is required.</small>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <div class="form-group">
                                                                                    <label for="fromDate">From Date</label>
                                                                                    <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time" formControlName="estimatedStartDate" placeholder="From Date">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-6">
                                                                                <div class="form-group">
                                                                                    <label for="fromDate">Through Date</label>
                                                                                    <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time" formControlName="estimatedCompletionDate" placeholder="Through Date">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-12">
                                                                                <div class="d-flex flex-row-reverse bd-highlight">
                                                                                    <button class="btn btn-danger cursor-pointer ml-2 p-2 bd-highlight" (click)="cancel()">Cancel</button>
                                                                                    <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight" (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </form>
                                                                    <ng-container *ngIf="isUpdate">
                                                                        <div class="mt-5 mb-5">
                                                                            <h4 class="text-black">Participants</h4>
                                                                            <hr />
                                                                            <form [formGroup]="ParticipantsForm">
                                                                                <div class="row">
                                                                                    <div class="col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="d-flex flex-column">
                                                                                                <label for="partyId">Party Id</label>
                                                                                                <div class="">
                                                                                                    <p-dropdown [options]="partyIds" filter="true"
                                                                                                formControlName="partyId" 
                                                                                                     placeholder="Party Id"></p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        <div class="form-group">
                                                                                            <div class="d-flex flex-column">
                                                                                                <label for="partyId">Training Class Types</label>
                                                                                                <div class="d-flex justify-content-between">
                                                                                                    <p-dropdown [options]="trainingClassTypes" filter="true" 
                                                                                                    formControlName="trainingClassTypeId" 
                                                                                                     placeholder="Training Class Types"></p-dropdown>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        <div class="form-group mt-4">
                                                                                            <div class="d-flex flex-column">
                                                                                                <div class="d-flex justify-content-between">
                                                                                                    <button class="btn btn-primary cursor-pointer" (click)="assignEvent()">Assign</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>


                                                            <ng-container *ngIf="isUpdate">
                                                                <div class="table-responsive">
                                                                    <table class="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Employee Party ID</th>
                                                                                <th> Training Request ID</th>
                                                                                <th>Training Class Type ID</th>
                                                                                <th> Approver Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let event of assignList">
                                                                                <td>
                                                                                    {{event.partyId}}
                                                                                </td>
                                                                                <td>
                                                                                    {{event.trainingRequestId}}
                                                                                </td>
                                                                                <td>
                                                                                    {{event.trainingClassTypeId}}
                                                                                </td>
                                                                                <td>{{event.approvalStatus}}
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngIf="!assignList.length">
                                                                                <td>No Data</td>
                                                                                <td>No Data</td>
                                                                                <td>No Data</td>
                                                                                <td>No Data</td>

                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </ng-container>

                                                            <ng-template #modalContent let-close="close">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title">Event action occurred</h5>
                                                                    <button type="button" class="close" (click)="close()">
                                                              <span aria-hidden="true">&times;</span>
                                                            </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div>
                                                                        Action:
                                                                        <pre>{{ modalData?.action }}</pre>
                                                                    </div>
                                                                    <div>
                                                                        Event:
                                                                        <pre>{{ modalData?.event | json }}</pre>
                                                                    </div>
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-outline-secondary" (click)="close()">
                                                              OK
                                                            </button>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <br>


            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Public Message
              </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Add Public Message to PartyId: {{this.partyId}}
                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPage">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div *ngIf="activeCategory==1">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">
                                                                        Note Party</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Note Party" formControlName="noteParty">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">More Info Url</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter More Info Url" formControlName="moreInfoUrl">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Note Info</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Note Info" formControlName="noteInfo">
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 16%;">
                                                            <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>&nbsp;&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add a calendar event

              </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Add a calendar event

                                </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="calendarEventForm">
                                        <div class="row">
                                            <div class="col-md-2">
                                                <div class="form-group" style="text-align:right;">
                                                    <div class="d-flex flex-column">
                                                        <label for="training">Training Name</label>
                                                        
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <div class="d-flex flex-column">
                                                      
                                                        
                                                        <p-dropdown [options]="trainingClassTypes" filter="true" 
                                                        formControlName="workEffortName" placeholder="Training Name"> </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group" style="text-align:right;">
                                                    <label for="description" >Description <span class="text-danger">*</span></label>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Description"
                                                     formControlName="description">
                                                    <small class="text-danger" *ngIf="!!calendarEventForm.controls.description.invalid 
                                                    && !!calendarEventForm.controls.touched">Description
                                      is required.</small>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group" style="text-align:right;">
                                                    <label for="fromDate">From Date</label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <input type="datetime-local" class="form-control" id="meeting-time"
                                                     name="meeting-time" formControlName="estimatedStartDate" placeholder="From Date">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group" style="text-align:right;">
                                                    <label for="fromDate">Through Date</label>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <input type="datetime-local" class="form-control" id="meeting-time"
                                                     name="meeting-time" formControlName="estimatedCompletionDate" placeholder="Through Date">
                                                </div>
                                            </div>
                                            <div class="col-md-11">
                                                <div class="d-flex flex-row-reverse bd-highlight">
                                                    <button class="btn btn-danger cursor-pointer ml-2 p-2 bd-highlight" (click)="cancel()">Cancel</button>
                                                    <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight" (click)="submit()">Add</button>
                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>