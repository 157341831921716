<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Related Accounts
                </span>
                <span class="color-black pl-1" > >Add Related Account
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add Related Account
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="fileUploadForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Account Party ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="accountPartyId" optionlabel="label" filter="true"
                                                placeholder="Enter Account Party ID"
                                                name="Parent Type ID" [options]="accountArray">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Comments</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="comments"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                  
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 65%;">
                               
                                <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Add</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->


<div class="modal-header">
    
    <span class="color-black pl-1">  Add Text Content</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden" #closebutton1 >&times;</span>
      </button>
</div>
<div class="modal-body d-flex">
    <div class=" bg-white color-grey w-100" >
        <div class="w3-card-4 classCardPopUp" style="width: 100%;">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">

                    <li class="active"><a>
                        Add Text Content</a></li>
                </ul>
            </div>
            
            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" [formGroup]="TextContentForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Through Date	
                                            </label>
                                        </div>
                                        <div class="col-lg-2 form-group">
                                            <input type="date" class="form-control"
                                                formControlName="ThroughDate" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Through Date	 ">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Product Description		
                                            </label>
                                        </div>
                                        <div class="col-lg-2 form-group">
                                            <input type="email" class="form-control"
                                                formControlName="ProductDescription" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Product Description		">
                                        </div>
                                       
   
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Locale String			
                                            </label>
                                        </div>
                                        <div class="col-lg-2 form-group">
                                            <input type="email" class="form-control"
                                                formControlName="LocaleString" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Locale String			">
                                        </div>
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Text		
                                            </label>
                                        </div>
                                        <div class="col-lg-2 form-group">
                                            <input type="email" class="form-control"
                                                formControlName="Text" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Text">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">From Date	
                                            </label>
                                        </div>
                                        <div class="col-lg-2 form-group">
                                            <input type="date" class="form-control"  
                                                formControlName="FromDate" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date	">
                                        </div>
                                        <!-- <div class="col-lg-3 form-group classInputA"  *ngIf="this.show" >
                                            <label for="exampleInputEmail1">Content ID		

                                            </label>
                                        </div>
                                        <div class="col-lg-2 form-group"  *ngIf="this.show" >
                                            <input type="email" class="form-control" disabled
                                                formControlName="ContentID" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Content ID	">
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-10 col-10 main-submit-button">
                                <button type="submit" class="btn btn-secondary submit-btn"
                                  (click)="createSimpleTextContentForProductConfigItem()">Create</button>
                              
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
    </div>
</div>
      




