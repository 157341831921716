import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-gl-account-category',
  templateUrl: './create-gl-account-category.component.html',
  styleUrls: ['./create-gl-account-category.component.css']
})
export class CreateGlAccountCategoryComponent implements OnInit {
  show: boolean;
  glAccountCategoryType: any;
  activeCategory = 2;
  glAccountCategoryTypeArray: any[]=[];
  createAccountCategory: FormGroup;
  glAccountCategoryId: any;
  glAccountCategoryTypeId: any;
  description: any;
  constructor(readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    ) {
      this.createAccountCategory = this._FormBuilder.group({
     
        description: [''],
        glAccountCategoryId:  [''],
        glAccountCategoryTypeId:  ['',[Validators.required]],
  
  
      });
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getGlAccountCategoriesInitialData();
   
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.glAccountCategoryId = params["glAccountCategoryId"];
      this.glAccountCategoryTypeId = params["glAccountCategoryTypeId"];
      this.description = params["description"];
     
    });
    if(this.glAccountCategoryId){
      this.show=true;
      setTimeout(() => {
      
      
        const formValue = this.createAccountCategory;
        formValue.patchValue({
          description:  this.description,
        
          glAccountCategoryTypeId:  this.glAccountCategoryTypeId,
    
  
  
  
        })
      
      }, 3000);
    }
    else{
      this.show=false;
    }
  }
  getGlAccountCategoriesInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountCategoriesInitialData().subscribe(res => {
      
      this.glAccountCategoryType = res.data.glAccountCategoryType;
      this.spinner.hide();
      for (const value of this.glAccountCategoryType) {
        this.glAccountCategoryTypeArray.push({
          label: value.description,
          value: value.glAccountCategoryTypeId
        })
      }
    })
  
  }
  cancelSubmit(){
    this.router.navigate(["financial/gl-account-category-list"])
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      description:this.createAccountCategory.value.description,
      glAccountCategoryId:"",
      glAccountCategoryTypeId:  this.createAccountCategory.value.glAccountCategoryTypeId,
      
     
    }

    this.accountsService.postGlAccountCategories(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/gl-account-category-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      description:this.createAccountCategory.value.description,
      glAccountCategoryId:this.glAccountCategoryId,
      glAccountCategoryTypeId:  this.createAccountCategory.value.glAccountCategoryTypeId,
      
     
    }

    this.accountsService.updateGlAccountCategories(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/gl-account-category-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
