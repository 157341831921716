<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey breadcrumbb"  >Company Profile</span>
               
                <button type="submit" routerLink="/users/user-list"  class="btn btn-danger" style="margin-left: 80%;">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                         Company Profile</a></li>
                  
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createUser">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                
                                    <div class="col-lg-4 col-12" >
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Owner Name<span
                                                style="color:red">*</span></label>
                                            <div>
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Owner Name" formControlName="ownerName" >
                                            </div>
                                        </div>
                                        <ng-container *ngIf="createUser.controls['ownerName'].invalid && (createUser.controls['ownerName'].dirty || createUser.controls['ownerName'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['ownerName'].errors.required">
                                               Owner Name is required!</p></ng-container>
                                    </div>
                                   
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Tenant Name<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tenant Name" formControlName="tenantName" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['tenantName'].invalid && (createUser.controls['tenantName'].dirty || createUser.controls['tenantName'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['tenantName'].errors.required">
                                                Tenant Name is required!</p></ng-container>
                                    </div>
                                </div>
                                    <br>
                                    <span>Contact Person Name</span>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Title<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title" formControlName="title" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['title'].invalid && (createUser.controls['title'].dirty || createUser.controls['title'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['title'].errors.required">
                                            Title is required!</p></ng-container>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">First Name<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name" formControlName="firstname" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['firstname'].invalid && (createUser.controls['firstname'].dirty || createUser.controls['firstname'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['firstname'].errors.required">
                                                First Name is required!</p></ng-container>
                                    </div>
                                    
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Middle Name</label>
                                            <br>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Middle Name" formControlName="middlename" >

                                        </div>
                                      
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name" formControlName="lastname" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['lastname'].invalid && (createUser.controls['lastname'].dirty || createUser.controls['lastname'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['lastname'].errors.required">
                                                Last Name is required!</p></ng-container>
                                    </div>
                                   
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Phone<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone" formControlName="phone" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['phone'].invalid && (createUser.controls['phone'].dirty || createUser.controls['phone'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['phone'].errors.required">
                                                Phone is required!</p></ng-container>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Mail" formControlName="mail" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['mail'].invalid && (createUser.controls['mail'].dirty || createUser.controls['mail'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['mail'].errors.required">
                                                E-Mail is required!</p></ng-container>
                                    </div>
                                </div>
                                    <br>
                                    <span>Alt Contact Person Name</span>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Title</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Alt Title" formControlName="titleAlt" >
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">First Name</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Alt First Name" formControlName="firstnameAlt" >
                                        </div>
                                        
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Middle Name</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Alt Middle Name" formControlName="middlenameAlt" >
                                        </div>
                                        
                                    </div>
                                   
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last Name</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Alt Last Name" formControlName="lastnameAlt" >
                                        </div>
                                       
                                    </div>
                                   
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Phone</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Alt Phone" formControlName="phoneAlt" >
                                        </div>
                                    
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Alt Mail" formControlName="mailAlt" >
                                        </div>
                                       
                                    </div>
                                </div>
                                <br>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Type of Business/Industries</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Type Of Business" formControlName="typeOfBusiness" >
                                        </div>
                                      
                                    </div>
                                   
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Sell online</label>
                                            <p-dropdown placeholder="Enter Sell Online" formControlName="sellOnline" filter="true" [options]="sellOnlineArray" optionlabel="label">
                                                   
                                            </p-dropdown>
                 
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Professional/service Industry website</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Professional Website" formControlName="professionalWebsite" >
                                        </div>
                                      
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Number of Employee/User</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter No Of Employees" formControlName="noOfEmployees" >
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">How Many User Interested In</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter No Of Users" formControlName="noOfUsers" >
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Additional User</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Additional Users" formControlName="additionalUsers" >
                                        </div>
                                     
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">IRS Tax ID Number<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Irs TaxId No" formControlName="irsTaxIdNo" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['irsTaxIdNo'].invalid && (createUser.controls['irsTaxIdNo'].dirty || createUser.controls['irsTaxIdNo'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['irsTaxIdNo'].errors.required">
                                                Irs TaxId No is required!</p></ng-container>
                                    </div>

                                  <!--   <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Upload Proof of Tax ID/Documents
                                                <span
                                                style="color:red">*</span></label>
                                                <input class="upload"  type="file" name="fileDoc" (change)="onFileSelectDocument($event)"
                                                #fileUploadDoc>
                                        </div>
                                       
                                    </div> -->
                                </div>
                                <br>
                                <span>Billing Address</span>
                                <br>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address 1
                                                <span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 1" formControlName="addressLine1Bill" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['addressLine1Bill'].invalid && (createUser.controls['addressLine1Bill'].dirty || createUser.controls['addressLine1Bill'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['addressLine1Bill'].errors.required">
                                                Address 1 is required!</p></ng-container>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address 2
                                              </label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 2" formControlName="addressLine2Bill" >
                                        </div>
                                     
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address 3
                                              </label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 3" formControlName="addressLine3Bill" >
                                        </div>
                                     
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country<span
                                                style="color:red">*</span></label>
                                                <p-dropdown filter="true" [options]="array"
                                                formControlName="countryGeoIdBill" placeholder="Select Country"
                                                (change)="onCountryChange($event)">
                                            </p-dropdown>
                                          
                                        </div>
                                        <ng-container *ngIf="createUser.controls['countryGeoIdBill'].invalid && (createUser.controls['countryGeoIdBill'].dirty || createUser.controls['countryGeoIdBill'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['countryGeoIdBill'].errors.required">
                                                Country is required!</p></ng-container>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">State<span
                                                style="color:red">*</span></label>
                                                <p-dropdown filter="true" formControlName="stateGeoIdBill"
                                                appendTo="body" [options]="stateArray"
                                               
                                                placeholder="Select State" optionlabel="label">
                                            </p-dropdown>

                                        </div>
                                        <ng-container *ngIf="createUser.controls['stateGeoIdBill'].invalid && (createUser.controls['stateGeoIdBill'].dirty || createUser.controls['stateGeoIdBill'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['stateGeoIdBill'].errors.required">
                                                State is required!</p></ng-container>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">City<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City" formControlName="cityBill" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['cityBill'].invalid && (createUser.controls['cityBill'].dirty || createUser.controls['cityBill'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['cityBill'].errors.required">
                                                City is required!</p></ng-container>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Zip Code<span
                                                style="color:red">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Zip Code" formControlName="zipcodeBill" >
                                        </div>
                                        <ng-container *ngIf="createUser.controls['zipcodeBill'].invalid && (createUser.controls['zipcodeBill'].dirty || createUser.controls['zipcodeBill'].touched)">
                                            <p class="validate-field" *ngIf="createUser.controls['zipcodeBill'].errors.required">
                                                Zip code is required!</p></ng-container>
                                    </div>
                                </div>
                                <br>
                                <span>Shipping Address</span>
                                <br>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address 1
                                               </label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 1" formControlName="addressLine1" >
                                        </div>
                                     
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address 2
                                                </label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 2" formControlName="addressLine2" >
                                        </div>
                                     
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address 3
                                               </label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 3" formControlName="addressLine3" >
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country</label>
                                            <p-dropdown filter="true" [options]="arrayShip"
                                            formControlName="countryGeoId" placeholder="Select Country"
                                            (change)="onCountryChangeBill($event)">
                                        </p-dropdown>
                                            
                                        </div>
                                      
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">State</label>
                                            <p-dropdown filter="true" formControlName="stateGeoId"
                                            appendTo="body" [options]="stateArrayShip"
                                            
                                            placeholder="Select State" optionlabel="label">
                                        </p-dropdown>
                                           
                                        </div>
                                      
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">City</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City" formControlName="city" >
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Zip Code</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Zip Code" formControlName="zipcode" >
                                        </div>
                                       
                                    </div>
                                </div>
                                <br>
                                <span>Payment Info</span>
                                <br>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Credit Card Number</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Credit CardNo" formControlName="creditCardNo" >
                                        </div>
                                       
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Exp Date</label>
                                            <div class="input-group" >
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Month" formControlName="expDate" >
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Year" formControlName="expMonth" >
                                             
                                               </div>
                                          
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Name of Card</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name On Card" formControlName="nameOnCard" >
                                        </div>
                                       
                                    </div>
                                </div>
                                <br>
                              <!--   <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Add Logo
                                                <span
                                                style="color:red">*</span></label>
                                                <input class="upload"  type="file" name="file" (change)="onFileSelect($event)" accept="image/png, image/jpg, image/jpeg"
                                                #fileUpload>
                                        </div>
                      
                                    </div>
                                </div>
 -->


                              
                                 <div  >
                                    <p>Note: To update the subscription details please click on the given<a style="color: blue;cursor: pointer;" target="_blank" href="http://34.196.47.126:81/login"> link.</a></p>
                                   
                                  
                                </div> 
                                <br>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 