<div class="container-fluid main-container-wrapper">
    

    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Billing Accounts</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
    
    <div class=" bg-white color-grey">

        <div class="create-new-leade mt-2">
            <div class="">
                <form class="w-100" [formGroup]="searchBillingAccountForm">
                    <div class="">

                        <div class="w3-card-4 w-100">
                            <div class="accordion classCard" id="myAccordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <div
                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                        <span style="margin-left: 4px!important;"> Search Options</span>

                                        <button data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-outline-secondary ml-2">Create New Billing Account
                                        </button>

                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 63%!important; padding-top: 11px !important;
                                        padding-bottom: 11px !important;"></button>									

                                    </div>

                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                            
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">
                                                            Billing Account ID</label>
                                                        </div>
                
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="billingAccountIdSearchType">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Billing Account Id" formControlName="billingAccountId">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Description</label>
                                                        </div>
                
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="descriptionSearchType">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="description" placeholder="Description">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="accountLimit">Account Limit</label>
                                                        </div>
                
                
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" formControlName="accountLimit" placeholder="Account Limit">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -32%;">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getBillingAccounts()">Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger" (click)="resetForm()">Reset</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>

                        <!-- <div class="w3-card-4 classCardA">

                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">

                                <button data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-outline-secondary">Create New Billing Account
                                    </button>

                                <a role="button" data-toggle="collapse" href="#collapseItemOpenOne" aria-expanded="true" aria-controls="collapseItemOpenOne">

                                </a>

                            </div>
                                
                              <div id="collapseItemOpenOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                Billing Account ID</label>
                                            </div>
    
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="billingAccountIdSearchType">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Billing Account Id" formControlName="billingAccountId">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
    
                                            <div class="col-lg-2" style="display: none;">
                                                <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="descriptionSearchType">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="description" placeholder="Description">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="accountLimit">Account Limit</label>
                                            </div>
    
    
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="accountLimit" placeholder="Account Limit">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -32%;">
                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getBillingAccounts()">Find</button>&nbsp;
                                    <button type="button" class="btn btn-danger" (click)="resetForm()">Reset</button>
                                </div>
                              </div>
                        </div> -->
                        
                        <div class="w3-card-4 classCardB">
                            <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                    <h4 class=" common-styling hmargin">
                                        Billing Account List
                                    </h4>

                                </div>
                            </header>
                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                <div class="col-lg-12 col-12">
                                    <div class="form-group">

                                        <div class="card own-account-table">
                                            <p-table [value]="billingAccounts" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr style="background: #0d3769;">
                                                        <th pSortableColumn="code">
                                                            <div class="checkbox-align" style="color: white;">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                Billing Account Id
                                                                <p-sortIcon field="code"></p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="name">
                                                            <div style="color: white;">Account Limit
                                                                <p-sortIcon field="name">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="category">
                                                            <div style="color: white;"> Description
                                                                <p-sortIcon field="category">
                                                                </p-sortIcon>
                                                            </div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="price">
                                                            <div style="color: white;">From Date</div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="price">
                                                            <div style="color: white;">Through Date</div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>
                                                        <th pSortableColumn="price">
                                                            <div style="color: white;">Created Date</div>
                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                        </th>

                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-product>
                                                    <tr>
                                                        <td>
                                                            <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                <span class="account-button" routerLink="/financial/payable/billing-account/summary-billing-account" [queryParams]="{billingAccountId:product.billingAccountId}">{{product.billingAccountId}}</span>
                                                            </div>
                                                        </td>
                                                        <td>{{product.accountLimit}}</td>
                                                        <td>{{product.description}}</td>
                                                        <td>{{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                        <td>{{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                        <td>{{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}</td>

                                                    </tr>
                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                                <h4 class="ml-2">No Record Found</h4>
                                                            </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </ng-template>
                                            </p-table>
                                            <p class="paginate_data">
                                                View per page </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Billing Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        {{editMode ?'Update':'Create'}} Billing Account</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="billingAccountForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="name" placeholder="Enter Name">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Account Type :</label>
                                                    </div>
                                                    <div class="col-lg-3" style="display: flex;">
                                                    
                                                        <input type="radio" style="width: 14px !important;margin-left: 5px;cursor: pointer;" name="accountType" value="Commercial" id="exampleInputEmail1" formControlName="accountType" aria-describedby="emailHelp">
                                                        <span style="margin-left: 5px;">Commercial</span> 
                                                        <input type="radio" style="width: 14px !important;margin-left: 5px;cursor: pointer;"  name="accountType" value="Individual" id="exampleInputEmail1" formControlName="accountType" aria-describedby="emailHelp">
                                                        <span style="margin-left: 5px;">Individual</span> 

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Account Limit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="accountLimit" placeholder="Account Limit">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA" *ngIf="editMode">
                                                        <label for="billingAccountId">Billing Account Id :
                                                          </label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="editMode">
                                                        <span>{{billingAccountId}}</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Account Currency Uom ID <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="currencyUomIds" formControlName="accountCurrencyUomId" optionlabel="label" placeholder="Select Currency">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Contact Mech ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="contactMechIds" formControlName="contactMechId" optionlabel="label" placeholder="Contact Mech Id"></p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">From Date <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-calendar id="exampleInputEmail1" formControlName="fromDate" placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-calendar id="exampleInputEmail1" formControlName="thruDate" placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Description
                                                            <span class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description" formControlName="description">
                                                        <small class="text-danger" *ngIf="!!billingAccountForm.controls.description.invalid && !!billingAccountForm.controls.description.touched">Description
                                                            is required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">External Account ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="externalAccountId" aria-describedby="emailHelp" placeholder="External Account Id">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Party Billed To <span
                                                                class="text-danger">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIds" optionlabel="label" formControlName="partyId" placeholder="Select Party">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!billingAccountForm.controls.partyId.invalid && !!billingAccountForm.controls.partyId.touched">Party
                                                            is required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Available Balance</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="availableBalance" aria-describedby="emailHelp" placeholder="Available Balance">
                                                    </div>


                                                </div>
                                            </div>
                                            


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -9%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="submit()">{{editMode ?'Update':'Create'}}</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>