import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-timesheet-effort',
  templateUrl: './timesheet-effort.component.html',
  styleUrls: ['./timesheet-effort.component.css']
})
export class TimesheetEffortComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 7;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  advanceSearch: { TimesheetID: string; PartyID: string; ClientPartyID: string; FromDate: string; fromDateTo: string; ThroughDate: string; ThroughDateTo: string; StatusID: string; Approved: string; Comments: string; };
  addTimesheet: FormGroup;
  timesheetidArray: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  statusArray: { label: string; value: string; }[];
  TimesheetDataValue: any;
  actualfromdate: string;
  actualthroughdate: string;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor( public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    private _location: Location,
    
    readonly headerService: HeaderService,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {

      this.timesheetidArray = [

        {
          label: 'Equals',
          value: 'equals'
        },
        {
          label: 'Begins With',
          value: 'like'
        },
        {
          label: 'Contains',
          value: 'contains'
        },
        {
          label: 'Is Empty',
          value: 'empty'
        },
        {
          label: 'Not Equal',
          value: 'notEqual'
        },
      ]; 
      this.statusArray = [

        {
          label: 'Approved',
          value: 'TIMESHEET_APPROVED'
        },
        {
          label: 'Completed',
          value: 'TIMESHEET_COMPLETED'
        },
        {
          label: 'In-Process',
          value: 'TIMESHEET_IN_PROCESS'
        },
        
      ];
      this.advanceSearch = {
        TimesheetID: "",
        PartyID: "",
        ClientPartyID: "",
        FromDate: "",
        fromDateTo: "",
        ThroughDate: "",
        ThroughDateTo: "",
        StatusID: "",
        Approved: "",
        Comments: "",
    };
    this.addTimesheet = this._FormBuilder.group({
      party: "",
      clientPartyID: "",
      fromDate: "",
      throughDate: "",
      status: "",
      approvedByUserLoginID: "",
      comments: "",
    });
     }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this.getPartyId();
    this.findTimesheet();
  }
  detailedPage(timesheetId) {
  this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheet"],{ queryParams: { timesheetId: timesheetId} })
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  advanceSearchTimesheetValue() {
    this.spinner.show();
    const req = {
    
      "approvedByUserLoginId":  this.advanceSearch.Approved ? this.advanceSearch.Approved : '',
      "clientPartyId":  this.advanceSearch.ClientPartyID ? this.advanceSearch.ClientPartyID : '',
      "comments":  this.advanceSearch.Comments ? this.advanceSearch.Comments : '',
      "fromDate":  this.advanceSearch.FromDate ? this.advanceSearch.FromDate : '',
      "partyId":  this.advanceSearch.PartyID ? this.advanceSearch.PartyID : '',
      "statusId":  this.advanceSearch.StatusID ? this.advanceSearch.StatusID : '',
      "thruDate": this.advanceSearch.ThroughDate ? this.advanceSearch.ThroughDate : '',
      "timesheetId":  this.advanceSearch.TimesheetID ? this.advanceSearch.TimesheetID : '',
    }
    this.myContactsService.findTimesheet(req).subscribe(res => {
      this.TimesheetDataValue = res.data;
      this.spinner.hide();
    })
  }
  
  createTimeSheetData(): void {
    this.spinner.show();
    const fromActualdate=this.addTimesheet.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.addTimesheet.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
     const requestData ={
   "approvedByUserLoginId": this.addTimesheet.value.approvedByUserLoginID,
  "clientPartyId": this.addTimesheet.value.clientPartyID,
  "comments":this.addTimesheet.value.comments,
  "fromDate":  this.actualfromdate,
  "partyId": this.addTimesheet.value.party,
  "statusId": this.addTimesheet.value.status,
  "thruDate":this.actualthroughdate
       
         
     }
     this.myContactsService.createTimeSheetData(requestData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.findTimesheet();
         this.addTimesheet.reset();
         this.closebutton.nativeElement.click();
      
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  findTimesheet() {
    this.spinner.show();
    const req = {
      "approvedByUserLoginId": "",
      "clientPartyId": "",
      "comments": "",
      "fromDate": "",
      "partyId": "",
      "statusId": "",
      "thruDate": "",
      "timesheetId": ""
    }
    this.myContactsService.findTimesheet(req).subscribe(res => {
      this.TimesheetDataValue = res.data;
      this.spinner.hide();
    })
  }
  resetData() {
    this.spinner.show();
    
    this.advanceSearch.TimesheetID= "",
    this.advanceSearch.PartyID= "",
    this.advanceSearch.ClientPartyID= "",
    this.advanceSearch.FromDate= "",
    this.advanceSearch.fromDateTo= "",
    this.advanceSearch.ThroughDate= "",
    this.advanceSearch.ThroughDateTo= "",
    this.advanceSearch.StatusID= "",
    this.advanceSearch.Approved= "",
    this.advanceSearch.Comments= ""
    const req = {
      "approvedByUserLoginId": "",
      "clientPartyId": "",
      "comments": "",
      "fromDate": "",
      "partyId": "",
      "statusId": "",
      "thruDate": "",
      "timesheetId": ""
    }
    this.myContactsService.findTimesheet(req).subscribe(res => {
      this.TimesheetDataValue = res.data;
      this.spinner.hide();
    })
  }
  TaskList(){
    this._Router.navigate(["/psa/work-effort/task-list"])
  }
  Calendar(){
    this._Router.navigate(["/psa/work-effort/calendar"])
  }
  MyTime(){
    this._Router.navigate(["/psa/work-effort/my-time-effort"])
  }
  RequestList(){
    this._Router.navigate(["/psa/work-effort/request-list-effort"])
  }
  WorkEffort(){
    this._Router.navigate(["/psa/work-effort/work-effort-effort"])
  }
  Timesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-effort"])
  }
  SubmittedJobs(){
    this._Router.navigate(["/psa/work-effort/submitted-jobs"])
  }
  iCalendar(){
    this._Router.navigate(["/psa/work-effort/icalendar"])
  }
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
}

