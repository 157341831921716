import { Component, OnInit } from '@angular/core';
declare var $: any;

import { NgxSpinnerService } from "ngx-spinner";
import { EmployeeService } from '../employees/employess.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from "@angular/router";
import { PostalEmployeeFormService } from './update-postal-service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-update-postal-address',
  templateUrl: './update-postal-address.component.html',
  styleUrls: ['./update-postal-address.component.css']
})
export class UpdatePostalAddressComponent implements OnInit {
  updateId: any;
  show= false;
  countryId: any;
  array: any = [];
  stateData: any;
  stateArray: any = [];
  internalOrganization: any;
  organizationArray: any = [];
  solicitation: any = [];
  contactMechId: string;
  constructor(public addEmployeeFormService: PostalEmployeeFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly toastr: ToastrService,
    readonly employeeService: EmployeeService,
    private _location: Location) {
      
    this.solicitation = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }

    ];
    this.contactMechId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCountryList();
    this.getInternalOrganization();
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateId = params["employeeDetailId"];
      this.contactMechId = params.contactMechId;
    });
    if (this.contactMechId) {
      this.show = true;
      this.getEmployeeDetail();
    }
    else {
      this.show = false;
    }
   
    this.addEmployeeFormService.postal_employee_form = null;
    this.addEmployeeFormService.addEmployeeForm(this.addEmployeeFormService.postal_employee_form);

  }

  onSubmit() {
    this.spinner.show();
    let formData = { ...this.addEmployeeFormService.postal_employee_form.value }

    this.employeeService.creatPostalAddress({
      ...formData, ...{
        partyId: this.updateId
      }
    }).then((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this._location.back();

       // this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.updateId } });
      }
      else if (res.success == false) {
        this.toastr.error(res.message);
      }
    });
    this.spinner.hide();
  }

  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.updateId).subscribe(res => {
      this.spinner.hide();
      res.data.contactInformation.pop();
      res.data.contactInformation.filter(value => {
        if (value.contactMech.contactMechId === this.contactMechId) {
          this.countryId = value.postalAddress.countryGeoId;
          this.getStateList();
          const formValue = this.addEmployeeFormService.postal_employee_form;
          formValue.patchValue({
            address1: value.postalAddress.address1,
            address2: value.postalAddress.address2,
            partyId: this.updateId,
            countryGeoId: value.postalAddress.countryGeoId,
            postalCode: value.postalAddress.postalCode,
            countryCode: value.postalAddress.countryGeoId,
            city: value.postalAddress.city,
            stateProvinceGeoId: value.postalAddress.stateProvinceGeoId,
            contactMechId: this.contactMechId,
          });
        }

      });
     
    })
  }



  getCountryList() {
    this.spinner.show();
    this.employeeService.getCountry().subscribe(res => {
      this.spinner.hide();
      var country = res.data.countryList;
      country.forEach(element => {
        this.array.push({
          label: element,
          value: element.split(":").pop().trim()
        })

      })

    })
   

  }



  onCountryChange(event) {
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }

  getStateList() {
    this.spinner.show();
    this.employeeService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
       
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  getInternalOrganization() {
    this.spinner.show();
    this.employeeService.internalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.organizationArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
   
  }
  cancelSubmit() {
    this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.updateId } });
  }

  onUpdate() {
    this.spinner.show();
    const formData = { ... this.addEmployeeFormService.postal_employee_form.value };
    this.employeeService.updatePostalAddress({
      ...formData, ...{
        partyId: this.updateId
      }
    }).then((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this._location.back();
        // this.router.navigate(["/hr/employees/employee-details"], { queryParams: { employeeDetailId: this.updateId } });
      } else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message);
      }
    });
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}


