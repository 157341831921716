


<div class="container-fluid main-container-wrapper">
    <br>
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
      <span class="color-black pl-1 titlePanels">Banking</span>
      <span>
           <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
           <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
       </span> 
   </div>
    <div class=" bg-white color-grey">
       
        <div class="w3-card-4 classCard" >
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="financialUrl()"><a>
                  Banking</a></li>
                <li [ngClass]="activeCategoryMain==2?'active':''" (click)="CheckURL()"><a>
                   Check</a></li>
                   
               
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategoryMain==2">
                                        <div class="panel-collapse">
                                            <br>
                                            <div class="panel-body">
                                                <div class="w3-card-4 classCard">
                                                  <div class="header-tabs">
                                                     
                                                          <h4 class=" common-styling hCard">
                                                            Available Internal Accounting Organizations
                                                          </h4>
                                                         
                                                        </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                  <div class="form-group">
                                                    <div class="card own-account-table">
                                                      <p-table [value]="getMainOrg" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                          <tr>
                                                            <th pSortableColumn="code">
                                                              <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                  <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                                        name="undefined" value="undefined"></div>
                                                                    <div role="checkbox"
                                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                                        class="ui-chkbox-icon ui-clickable"></span></div>
                                                                  </div>
                                                                  <!---->
                                                                </p-checkbox>
                      
                                                               
                                                        Companies<p-sortIcon field="code"></p-sortIcon>
                                                              </div>
                                                              <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                                aria-disabled="false" aria-readonly="false">
                                                            </th>
                                                            
                                                            <th pSortableColumn="name">
                                                              
                                                            </th>
                                                          
                      
                                                          </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-activity>
                                                          <tr>
                                                            <td>
                                                              <div class="checkbox-align">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                  <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                                        name="undefined" value="undefined"></div>
                                                                    <div role="checkbox"
                                                                      class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                                        class="ui-chkbox-icon ui-clickable"></span></div>
                                                                  </div>
                                                                </p-checkbox>
                                                                <span  >
                                                                  {{activity.partyDesc}}
                                                                </span>
                                                              
                                                              </div>
                                                            </td>
                                                            
                                                            <td (click)="glOrganizationAccountingSummary(activity.partyId)">
                                                              <span class="account-button"> Check Detail </span></td>
                                                           
                                                          </tr>
                                                        </ng-template>
                                                      </p-table>
                                                      <p class="paginate_data">
                                                        View per page
                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                          <span style="margin-left: 400px;">No Record Found!....</span>
                                                        </ng-container>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                

                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 