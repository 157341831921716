<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/facilities/catalog/products">Product </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update': 'Create'}} New Variant</span>
                <button type="button" class="btn btn-danger buttonclass" (click)="cancelSubmit()">Back
                </button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" *ngIf="!show"><a>
                                    Add Variant Products</a></li>
                            <li [ngClass]="activeCategory==1?'active':''" *ngIf="show"><a>
                                    Update Variant Products</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="imageForm">
                                    <div *ngIf="activeCategory==1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Variant Product IDs</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <textarea  formControlName="productVariant"
                                                    name="productVariant" id="" cols="30" rows="10"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                         
                                        </div>
                                        <div class="main-submit-button" style=" margin-right: 63%; ">
                                            <button style="margin-left: 54%;" type="submit" *ngIf="show" (click)="onUpdate();"
                                                class="btn btn-secondary submit-btn">Update</button>&nbsp;
                                            <button style="margin-left: 54%;" type="submit" (click)="onSubmit()" *ngIf="!show"
                                                class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                            <button (click)="cancelSubmit()"  class="btn btn-danger buttonclass" type="submit">Cancel</button>&nbsp;
                                        </div>
                                    </div>
                                </form>
                                
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div> -->

<ngx-spinner></ngx-spinner>