<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-2">
                <div class="container-fluid">
                    <div class="row">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight justify-content-between port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                   Existing Backup Warehouse

                                   <span class=""> 
 
                                    <button type="submit" (click)="homeButton();"
                                        class=" btn btn-outline-secondary mr-2">Home</button>
                            
                                    <button type="submit" (click)="cancelSubmit();"
                                        class="btn btn-danger buttonclass ">Back</button>
                                </span> 
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4 classCard w-100 mt-2">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Summary</p>
                                                        <span>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                style="margin: 0;" data-toggle="modal"
                                                                data-target="#addExistingPopup">Update</button>
                                                            
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                        <div class="color-black container">
                                                            <div class="row" *ngIf="planningWareHousesExistingById">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Existing Backup Warehouse:</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{planningWareHouseExistingId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Backup Warehouse: 
                                                                            </p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{this.primaryWarehouseId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Priority
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">{{this.planningWareHousesExistingById.priority}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">From
                                                                                :</p>
                                                                                {{this.planningWareHousesExistingById.fromDate | date:"yyyy-MM-dd"}}
                                                                                <span class="color-grey pl-10 font-weight-bold">
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Thru
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">
                                                                                {{this.planningWareHousesExistingById.thruDate | date:"yyyy-MM-dd"}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addExistingPopup" tabindex="-1" role="dialog" aria-labelledby="addExistingPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Existing Backup Warehouse

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Existing Backup Warehouse
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addExistingForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                           Existing Backup Warehouse<span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                       {{this.primaryWarehouseId}}
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Backup Warehouse
                                                            <span style="color: red;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="backupWarehouse" [options]="FacilityIdToArray"
                                                        placeholder="Choose Backup Warehouse">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Priority<span style="color: red;">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text"  formControlName="Priority" class="form-control"
                                                         id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Priority">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From<span style="color: red;">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="From" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Thru</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="Thru" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"  
                                                (click)="UpdatePlanningWareHousesExisting()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner> 
