<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Fixed Asset</span>
                <span class="color-black pl-1"> >{{editMode ? 'Update':'Create'}} Fixed Asset </span>
                <button type="submit" (click)="cancelSubmit()"
                class="btn btn-danger buttonclass"  >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        {{editMode ? 'Update':'Create'}} Fixed Asset</a></li>
                      
            
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createFixAssetForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Fixed Asset ID
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="fixedAssetId" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Fixed Asset ID">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Fixed Asset Type ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="fixedAssetTypeId" [options]="fixedAssetTypes"
                                                placeholder="Enter Fixed Asset Type ID" filter="true">
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Fixed Asset Parent ID </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="parentFixedAssetId" [options]="fixAssetIds"
                                                    placeholder="Enter Fixed Asset Parent ID" filter="true">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Instance Of Product ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="instanceOfProductId" [options]="productIds"
                                                    placeholder="Enter Instance Of Product ID" filter="true">
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Class Enum ID
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="classEnumId" [options]="classEnumId"
                                                    placeholder="Enter Class Enum ID" filter="true">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Customer ID
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="partyId" placeholder="Enter Customer ID"
                                                    filter="true" [options]="partyIds">
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Role Type ID
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="roleTypeIds" formControlName="roleTypeId"
                                                    placeholder="Enter RoleType ID" filter="true">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Fixed Asset Name </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="fixedAssetName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Fixed Asset Name">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Acquire Order ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="acquireOrderId" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Acquire Order ID">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Acquire Order Item Seq ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" formControlName="acquireOrderItemSeqId"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="Enter Acquire Order Item Seq ID">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Date Acquired</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="dateAcquired" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Date Acquired">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Date Last Serviced
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="dateLastServiced" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Date Last Serviced">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Date Next Service</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="dateNextService" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Date Next Service">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Expected End Of Life </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="expectedEndOfLife" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Expected End Of Life">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Actual End Of Life</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="actualEndOfLife" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Actual End Of Life">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Production Capacity</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="productionCapacity"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Production Capacity">

                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">UOM</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="uomId" [options]="uomIds"
                                                placeholder="Enter UOM" filter="true">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Calendar</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="calendarId" [options]="calendar"
                                                placeholder="Enter Calendar" filter="true">
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Located At Facility ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="locatedAtFacilityId" [options]="facilityId"
                                                placeholder="Enter Located At Facility ID" filter="true">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Located At Location Seq ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="locatedAtLocationSeqId"
                                                [options]="facilityLocation"
                                                placeholder="Enter Located At Location Seq ID" filter="true">
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Salvage Value</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="salvageValue" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Salvage Value">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Depreciation</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="depreciation" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Depreciation">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Purchase Cost</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="purchaseCost" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Purchase Cost">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Purchase Cost Uom ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="partyId" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Purchase Cost Uom ID">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                   
                                
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 66%;">
                                <button type="submit" *ngIf="editMode" (click)="onUpdate()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="onSubmit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>