<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Update Shipment Gateway Config</span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
                            Update Shipment Gateway Config Information</a></li>



                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Update Shipment Gateway Config

                                                        </h4>
                                                        <span class="edit-buttons">
                                                        <a  (click)="edit()"  data-toggle="modal" 
                                                        data-target="#exampleModalCenter">Update</a></span>

                                                    </div>
                                                    <br>
                                                    <div>
                                                        <!--  <button type="button" style="width: 132px;"
                                                        class="btn btn-outline-secondary bg-brown ">Export as CSV</button>
                                                    &nbsp; <button type="button" style="width: 132px;"
                                                        class="btn btn-danger" routerLinkActive="active">Export as PDF</button> -->
                                                    </div>
                                                    <div class="w3-container">
                                                        <div class="panel-body">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-7 col-12">
                                                                                    Shipment Gateway Config Type Description</p>

                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-5 col-12">Parent Type ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.shipmentGatewayConfTypeId}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Has Table</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.hasTable}}</span>

                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Shipment Gateway Config Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                            Create Update Shipment Gateway Config Type </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                Update Shipment Gateway Config Type</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createShipmentConfigType" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">



                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                        Shipment Gateway Config Type Description</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ShipmentDescription" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Qualification Desc">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">
                                                        Parent Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="ParentTypeID" [options]="ParentTypeIDArray" aria-disabled="true" placeholder="Enter Parent Type ID">
                                                        </p-dropdown>
                                                    </div>


                                                    <div hidden class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">
                                                        Shipment GatewayConfig Type Id</label>
                                                    </div>
                                                    <div hidden class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="ShipmentGatewayConfigTypeId" [options]="ParentTypeIDArray" aria-disabled="true" placeholder="Enter Parent Type ID">
                                                        </p-dropdown>
                                                    </div>


                                                    <div class="col-lg-3 form-group classInputA mt-2">
                                                        <label for="exampleInputEmail1">
                                                        Has Table</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="HasTable" [options]="HasTableArray" aria-disabled="true" placeholder="Enter Has Table">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 4%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="UpdateShipmentGatewayConfigType()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>