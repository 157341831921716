import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillsService } from 'src/app/human-resource/add-skills/add-skill-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { SkillService } from 'src/app/human-resource/skills/skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-add-party-skill',
  templateUrl: './add-party-skill.component.html',
  styleUrls: ['./add-party-skill.component.css']
})
export class AddPartySkillComponent implements OnInit {
  getSkillLists: any;
  activeCategory = 2;
  skillArray: any =[];
  personId: any;
  partyIdArray: any = [];
  updateId: any;
  show = false;
  total=0;
  rowNumber=0;
  pageNo = 1;
  pageSize= 100;
  rows = 50;
  skillList: any;
  Skillid: any;
  rate: any;
  skill: any;
  year: any;
  partyId: any;
  @ViewChild('closebutton') closebutton;

  constructor(readonly addSkillService:AddSkillService,
    readonly spinner:NgxSpinnerService,
    private _location: Location,
    readonly activatedRoute:ActivatedRoute,
    public addSkillsService:AddSkillsService,
    readonly toastr:ToastrService,
    readonly router:Router,
    readonly skillService:SkillService,
    public bsModalRef: BsModalRef
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.addSkillsService.add_skill_form = null;
    this.addSkillsService.addSkillForm(this.addSkillsService.add_skill_form);
    this.activatedRoute.queryParams.subscribe(params=> [
      this.partyId = params["party"],
      this.Skillid=params["skid"],
      this.rate=params["rate"],
      this.skill=params["skill"],
      this.year=params["year"]
    ])
    if(this.Skillid) {
      this.show=true;
      setTimeout(() => {
        const formValue = this.addSkillsService.add_skill_form;
      formValue.patchValue({
        partyId:this.partyId,skillTypeId: this.Skillid,yearsExperience: this.year,rating: this.rate,skillLevel: this.skill, 
      })
      }, 3000);
    }
    else {
        const formValue = this.addSkillsService.add_skill_form;
      formValue.patchValue({
        partyId:this.partyId,
      })
      this.show = false;
    }
    this.getPartyId();
    this.getSkillList();
  }
  
  getSkillList() {
    this.spinner.show();
    this.addSkillService.getSkillTypeList().subscribe(res=> {
      this.getSkillLists = res.data;
      this.spinner.hide();
      for(const value of this.getSkillLists) {
        this.skillArray.push({
          label:value.description,
          value:value.skillTypeId
        })
      }
     
    })
   
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const formData = {...this.addSkillsService.add_skill_form.value};
    this.addSkillService.createSkills(formData).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Subbmitted Successfully");
        this.closebutton.nativeElement.click();
        this._location.back();
      }

      else if(res.success === false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  }
  cancelSubmit(){
    this._location.back();
  }
  updateSkills() {
    this.spinner.show();
    const updateFormData ={...this.addSkillsService.add_skill_form.value};
    this.addSkillService.updateSkills(updateFormData,this.partyId).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Updated Successfully");
        this.closebutton.nativeElement.click();
        this._location.back();
      }

      else if(res.success === false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
