<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Timesheet
                  </span>
                  <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="projectMain()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="myTasks()">
                                <a>My Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="myTime()">
                                <a>My Time</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="projects()">
                                <a>Projects</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="tasks()">
                                <a>Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="resources()">
                                <a>Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="timesheet()">
                                <a>Timesheet</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="skillType()">
                                <a>Skill Type</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requestList()">
                                <a>Request List</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords"> Find TimeSheet</span>
                                                           
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create New Timesheet</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">Timesheet
                                                                                    ID
                                                                                </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.TimesheetID" id="exampleInputEmail1" name="TimesheetID" aria-describedby="emailHelp" placeholder="Enter Timesheet ID">
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">Comments
                                                                                </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.comments" id="exampleInputEmail1" name="comments" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                                                                
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">Party
                                                                                </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" name="Party" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Party" [options]="PartyIdArray" optionlabel="label" placeholder="Enter Party">
                                                                            </p-dropdown>
                
                
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">Status
                                                                                </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" name="status" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.status" [options]="statusArray" optionlabel="label" placeholder="Choose Status">
                                                                            </p-dropdown>
                
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightFormG">
                                                                            <label for="exampleInputEmail1">From Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.fromDate" name="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group rightFormH">
                                                                            <label for="exampleInputEmail1">To Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.toDate" name="toDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter To Date">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="col-lg-6  main-submit-button" style="margin-left: 36%;">
                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="FindTimeSheetProject()">Find</button>
                                                                    <button type="submit" class="btn btn-danger ml-2 " (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Find TimeSheet</p>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">New</button>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightFormG">
                                                            <label for="exampleInputEmail1">Timesheet
                                                                    ID
                                                                </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.TimesheetID" id="exampleInputEmail1" name="TimesheetID" aria-describedby="emailHelp" placeholder="Enter Timesheet ID">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormH">
                                                            <label for="exampleInputEmail1">Comments
                                                                </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.comments" id="exampleInputEmail1" name="comments" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                        </div>
                                                    </div>
                                                </div>

                                                

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightFormG">
                                                            <label for="exampleInputEmail1">Party
                                                                </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" name="Party" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.Party" [options]="PartyIdArray" optionlabel="label" placeholder="Enter Party">
                                                            </p-dropdown>


                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormH">
                                                            <label for="exampleInputEmail1">Status
                                                                </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" name="status" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.status" [options]="statusArray" optionlabel="label" placeholder="Choose Status">
                                                            </p-dropdown>

                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group rightFormG">
                                                            <label for="exampleInputEmail1">From Date</label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.fromDate" name="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                        </div>

                                                        <div class="col-lg-3 form-group rightFormH">
                                                            <label for="exampleInputEmail1">To Date</label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.toDate" name="toDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter To Date">
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6  main-submit-button" style="margin-left: 36%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="FindTimeSheetProject()">Find</button>
                                                    <button type="submit" class="btn btn-danger ml-2 " (click)="reset()">Reset</button>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Timesheet list</p>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="TimeSheetProjectList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>

                                                                                Timesheet ID
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Party
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Actual Hours</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Status</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                From Date
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Through Date
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Status to 'complete'
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>



                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                {{product.TimeSheethours.timesheetId}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.TimeSheethours.partyId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <span></span>

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.TimeSheethours.statusId}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.TimeSheethours.fromDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.TimeSheethours.thruDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'250px'}">
                                                                            <button type="submit" class="btn btn-secondary " (click)="MyStatusToComplete(product.TimeSheethours.statusId,product.TimeSheethours.timesheetId)">Status To Complete</button>
                                                                        </td>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                    <h4 class="ml-2">No
                                                                                        Record Found
                                                                                    </h4>
                                                                                </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Timesheet</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Timesheet</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="timesheetForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormI">
                                                        <label for="exampleInputEmail1">Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="PartyIdArray" placeholder="Enter Party" formControlName="Party">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormJ">
                                                        <label for="productId">Select Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SelectDate" placeholder="Enter Select Date">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -16%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createWeeklyProTimesheet()">Add</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>