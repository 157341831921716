import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import {CreateContactsService} from "../create-contacts/create-contacts.service";
import { ToastrService } from 'ngx-toastr';
import {Router,ActivatedRoute} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-import-contacts',
  templateUrl: './import-contacts.component.html',
  styleUrls: ['./import-contacts.component.css']
})
export class ImportContactsComponent implements OnInit {
  fileData: File;
  Editform: FormGroup;
  activeCategory=1;
  fromWhere;
  constructor(
    readonly createService:CreateContactsService,
    readonly router:Router,
    readonly toastr:ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly spinner:NgxSpinnerService,
    private _location: Location,
    ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.fromWhere = params["through"];
  
    });

    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
    });
    $("#accordion").each(function(){
      var trigger= $(this).find('a')
      var acc_text = $(this).find('.collapse')
          $(trigger).click(function(){      
              $(acc_text).slideToggle();      
          });
      });
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];    
  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/crm/main-overview']);
}

  // onSubmit(){}
  onSubmit(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file",this.fileData);

    this.createService.importContact(formData).subscribe((res:any)=>{
      if(res.success) { 
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);

        if(this.fromWhere=="AllContact")
        {
          this.router.navigate(["/crm/my-contacts"]);
        }else
        {
          this.router.navigate(["/crm/my-contacts/contact"]);
        }

      }     },(err)=>{
        this.toastr.error(err.message);
        this.spinner.hide();
     })
    
  }
  onCancel(){
    this.router.navigate(["/crm/my-contacts"])
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}
