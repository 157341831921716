import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductService } from '../new-products/product.service';

@Injectable()
export class DetailProductSummaryResolver implements Resolve<any>{
    constructor(readonly _ProductService: ProductService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return this._ProductService.getProductById(route.params.productId).then(data => data.data);
    }
}