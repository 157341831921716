<div class="container-fluid main-container-wrapper pad_0 ">
    <br>
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Content</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div> 
    <div class=" bg-white color-grey">

        <div class="w3-card-4 classCard" style="margin-left:0%!important;">
            <div class="container-fluid">

                <ul class="tabbing-section tabbing-accordians tabClass"
                    style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                    <li [ngClass]="activeCategoriess==9?'active':''" (click)="toLead()"><a>
                            Leads </a></li>
                    <!-- <li [ngClass]="activeCategoriess==10?'active':''" (click)="openMarketingCampaign()"><a>
                            Convert Lead</a></li> -->
                    <li [ngClass]="activeCategoriess==11?'active':''" (click)="toContact()"><a>
                            Contacts </a></li>
                    <li [ngClass]="activeCategoriess==12?'active':''" (click)="toAccount()"><a>
                            Accounts</a></li>
                    <li [ngClass]="activeCategoriess==1?'active':''" (click)="openDataResource()"><a>
                            Data Resource </a></li>
                    <li [ngClass]="activeCategoriess==2?'active':''" (click)="openMarketingCampaign()"><a>
                            Marketing Campaign</a></li>
                    <li [ngClass]="activeCategoriess==3?'active':''" (click)="openTracking()"><a>
                            Tracking</a></li>
                    <li [ngClass]="activeCategoriess==4?'active':''" (click)="openSegment()"><a>
                            Segment</a></li>
                    <li [ngClass]="activeCategoriess==5?'active':''" (click)="openContactList()"><a>
                            Contact List</a></li>
                     <li [ngClass]="activeCategoriess==6?'active':''" (click)="createcontent()"><a>
                            Create Content</a></li> 
                    <li [ngClass]="activeCategoriess==13?'active':''" (click)="toOpportunity()"><a>
                            Opportunities</a></li>
                    <li [ngClass]="activeCategoriess==14?'active':''" (click)="toTasks()"><a>
                            Tasks</a></li>
                    <li [ngClass]="activeCategoriess==15?'active':''" (click)="toCases()"><a>
                            Cases</a></li>
                    <li [ngClass]="activeCategoriess==16?'active':''" (click)="toForecast()"><a>
                            Forecast</a></li>
                    <li [ngClass]="activeCategoriess==17?'active':''" (click)="toDocuments()"><a>
                            Documents</a></li>
                    <li [ngClass]="activeCategoriess==18?'active':''" (click)="toNotes()"><a>
                            Notes</a></li>
                    <li [ngClass]="activeCategoriess==19?'active':''" (click)="toCalendar()"><a>
                            Calendar</a></li>
                    <li [ngClass]="activeCategoriess==20?'active':''" (click)="openReports()"><a>
                            Reports</a></li>
                    <li [ngClass]="activeCategoriess==21?'active':''" (click)="toEmail()"><a>
                            Emails marketing</a></li>
                            <li [ngClass]="activeCategoriess==22?'active':''" (click)="MobileMessaging()"><a>
                                Mobile Messaging</a></li>
                        <li [ngClass]="activeCategoriess==23?'active':''" (click)="SocialMedia()"><a>
                                Social Media Marketing </a></li>

                    <!-- <li [ngClass]="activeCategoriess==7?'active':''" (click)="openCustomer()"><a>
                            Customer </a></li>
                    <li [ngClass]="activeCategoriess==8?'active':''" (click)="openActivities()"><a>
                            Activities</a></li> -->
                </ul>
            </div>
        
        <div class="w3-card-4 classCard classCardType border_wdth_1" >
                <div class="container-fluid" >
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                            Find </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                            Navigate </a></li>
                        
                    </ul>
                </div>
            

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                    
                        
                        <div class="panel-group">
                            <div *ngIf="activeCategory==1">
                                
                                
                                <div class="panel-collapse">
                                    <br>
                                    <div class="w3-card-4 classCard classCardType" style="margin-top: -4px;">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="changeactiveCategoryMain(1)"><a>
                                                    Advance Search</a></li>
                                          <li (click)="updateAllContentKeywords()"><a>
                                            Auto Create Keywords </a></li>
                                            
                                                    
                                            </ul>
                                        </div>
                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid" *ngIf="this.show">

                                                        <div class="row" style="padding: 7px;">
                                                                <form class="w-100" [formGroup]="contentForm">

                                                                    <div class="w3-card-4  w-100">
                                                                        <div class="accordion classCard" id="myAccordion">
                                                                            <div class="accordion-item">
                                                                                <h2 class="accordion-header" id="headingOne">
                                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                        <!-- <span class="headingAccords"> Path Alias</span> -->
                                                                                        <button type="button" class="btn btn-outline-secondary active marginHeader"   routerLinkActive="active"
                                                                                        data-target="#creatContentPopup" data-toggle="modal">Create New Content</button>
                                                                            
                                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 80%!important; padding-top: 11px !important;
                                                                                            padding-bottom: 11px !important;">
                                                                                        </button>									
                                            
                                                                                    </div>
                                                
                                                                                </h2>
                                                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                    <div class="card-body">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Content ID</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                        
                                                                                                            aria-describedby="emailHelp" placeholder="Content ID"
                                                                                                                formControlName="contentId">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Content Name</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                        
                                                                                                            formControlName="contentName" aria-describedby="emailHelp"
                                                                                                        placeholder="Content Name">
                                                                                                    </div>
                                                                            
                                                                                                    
                                                                            
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Description	</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                        
                                                                                                        formControlName="description" aria-describedby="emailHelp"
                                                                                                        placeholder="Description">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Content Type ID	</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" 
                                                                                                        
                                                                                                        formControlName="contentTypeId" optionlabel="label"
                                                                                                        placeholder="Select Content Type ID" [options]="CalatalogContentTypeIdArray">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                            
                                                                                                    
                                                                            
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                                                                    </div>
                                                                                                     
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true"
                                                                                                        
                                                                                                            formControlName="mimeTypeId" optionlabel="label"
                                                                                                            placeholder="Select Mime Type ID" [options]="CalatalogMimeTypeIdArray">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Data Resource ID	
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true"  
                                                                                                        
                                                                                                            formControlName="dataResourceId" optionlabel="label"
                                                                                                            placeholder="Select Data Resource ID" [options]="DataResourceIdArray">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                            
                                                                                                    
                                                                            
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Owner Content ID	
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true"  placeholder="Owner Content ID"
                                                                                                        
                                                                                                            formControlName="ownerContentID" optionlabel="label" [options]="CatalogContentIdArray">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                
                                                                                                    <div class="col-lg-3 form-group classInputA" >
                                                                                                        <label for="exampleInputEmail1">Status ID		
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true"  placeholder="Enter Status ID"
                                                                                                        
                                                                                                        formControlName="statusID" optionlabel="label"
                                                                                                        [options]="statusArray">
                                                                                                    </p-dropdown>
                
                                                                                                
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Created By User	
                
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" 
                                                                                                        formControlName="createdByUser" 
                                                                                                        optionlabel="label" 
                                                                                                        placeholder="Created By User" 
                                                                                                        [options]="partyIdArray">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                
                                                                                                    <div class="col-lg-3 form-group classInputA" >
                                                                                                        <label for="exampleInputEmail1">Locale String	
                
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true"
                                                                                                        [options]="localStringArray"
                                                                                                            formControlName="localeString" optionlabel="label" 
                                                                                                            placeholder="Locale String">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                    
                                                        
                                                                                        </div>
                                    
                                                                                        <div style=" margin-left: -25%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                                                            type="submit" class="btn btn-secondary submit-btn"
                                                                                            (click)="findContent()">Find</button>&nbsp;
                                                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetContent()">Reset</button>
                                                                                        </div>
                                    
                                    
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>

                                                                    <!-- <div class="w3-card-4 classCard">

                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                            
                                                                                    <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                                                        data-target="#creatContentPopup" data-toggle="modal">Create Content</button>
                                                                            
                                                                        </div>

                                                                    
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Content ID</label>
                                                                                    </div>
                                                                                    
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        
                                                                                            aria-describedby="emailHelp" placeholder="Content ID"
                                                                                                formControlName="contentId">
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Content Name</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        
                                                                                            formControlName="contentName" aria-describedby="emailHelp"
                                                                                        placeholder="Content Name">
                                                                                    </div>
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Description	</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        
                                                                                        formControlName="description" aria-describedby="emailHelp"
                                                                                        placeholder="Description">
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Content Type ID	</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" 
                                                                                        
                                                                                        formControlName="contentTypeId" optionlabel="label"
                                                                                        placeholder="Select Content Type ID" [options]="CalatalogContentTypeIdArray">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"
                                                                                        
                                                                                            formControlName="mimeTypeId" optionlabel="label"
                                                                                            placeholder="Select Mime Type ID" [options]="CalatalogMimeTypeIdArray">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Data Resource ID	
                                                                                        </label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"  
                                                                                        
                                                                                            formControlName="dataResourceId" optionlabel="label"
                                                                                            placeholder="Select Data Resource ID" [options]="DataResourceIdArray">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Owner Content ID	
                                                                                        </label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"  placeholder="Owner Content ID"
                                                                                        
                                                                                            formControlName="ownerContentID" optionlabel="label" [options]="CatalogContentIdArray">
                                                                                        </p-dropdown>
                                                                                    </div>

                                                                                    <div class="col-lg-3 form-group classInputA" >
                                                                                        <label for="exampleInputEmail1">Status ID		
                                                                                        </label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"  placeholder="Enter Status ID"
                                                                                        
                                                                                        formControlName="statusID" optionlabel="label"
                                                                                        [options]="statusArray">
                                                                                    </p-dropdown>

                                                                                
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Created By User	

                                                                                        </label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" 
                                                                                        formControlName="createdByUser" 
                                                                                        optionlabel="label" 
                                                                                        placeholder="Created By User" 
                                                                                        [options]="partyIdArray">
                                                                                        </p-dropdown>
                                                                                    </div>

                                                                                    <div class="col-lg-3 form-group classInputA" >
                                                                                        <label for="exampleInputEmail1">Locale String	

                                                                                        </label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true"
                                                                                        [options]="localStringArray"
                                                                                            formControlName="localeString" optionlabel="label" 
                                                                                            placeholder="Locale String">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    
                                                                                    
                                                                                    
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                    
                                        
                                                                        </div>
                                                                        <div style=" margin-left: -25%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                                                type="submit" class="btn btn-secondary submit-btn"
                                                                            (click)="findContent()">Find</button>&nbsp;
                                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetContent()">Reset</button>
                                                                        </div>
                                                                    </div> -->
                                                                    
                                                                    <br>
                                                                    
                                                                    
                                                                    <div class="w3-card-4 classCard">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                            <h4 class=" common-styling h4Margin">
                                                                                Invoice List
                                                                            </h4>
                                                                            
                                                                            </div>
                                                                        </header>
                                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                                
                                                                                <div class="card own-account-table">
                                                                                    <p-table [value]="findContentFilterData" [paginator]="true" [rows]="rows"
                                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                    [responsive]="true" [totalRecords]="total" [scrollable]="true"
                                                                                    styleClass="p-datatable-customers"
                                                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined"
                                                                                                                    value="undefined"></div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    Content Id<p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">Content Name<p-sortIcon field="name">
                                                                                                </p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">Description<p-sortIcon field="name">
                                                                                                </p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="category">  <div style="color: white;"> Status ID
                                                                                                <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'190px'}"
                                                                                                pSortableColumn="quantity">  <div style="color: white;"> Locale String
                                                                                                <p-sortIcon field="quantity"></p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;"> Content Type ID <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}"  >
                                                                                                <div style="color: white;"> Customer Id<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    value="" autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}" >
                                                                                                <div style="color: white;">Mime Type ID</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;">Data Resource ID</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;">Owner Content ID</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                            <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                <div style="color: white;">Created By User</div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                    style="visibility:hidden;" value=""
                                                                                                    autocomplete="off"
                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false"
                                                                                                    aria-readonly="false">
                                                                                            </th>
                                                                                                                                                
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox" binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined"
                                                                                                                    value="undefined"></div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span class="account-button" (click)="route_detail_page(product.dataNodes.contentId)">
                                                                                                        {{product.dataNodes.contentId}}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>
                                
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.contentName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.description}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.statusId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.localeString }}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.contentTypeId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.contentId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.mimeTypeId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.dataResourceId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.ownerContentId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                                {{product.dataNodes.createdByUserLogin}}
                                                                                            </td>
                                                                                            <!-- <td [ngStyle]="{'width':'190px'}">
                                                                                                <div>
                                                                                                    <svg class="hover"
                                                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                                    
                                                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                                                        <g transform="translate(0 -0.004)">
                                                                                                            <g transform="translate(0 1.52)">
                                                                                                                <path
                                                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                    transform="translate(0 -34.137)" />
                                                                                                            </g>
                                                                                                            <g transform="translate(4.548 0.004)">
                                                                                                                <g transform="translate(0 0)">
                                                                                                                    <path
                                                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg>
                                
                                                                                                </div>
                                                                                            </td> -->
                                
                                                                                        </tr>
                                
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                    
                                                                                    <p class="paginate_data">
                                                                                        View per page </p>
                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </form>
                                                            
                                                        </div>

                                                    
                                                    
                                                </div>
                                            
                                            </div>

                                        

                                            
                                    </div>
                                        
                                            
                                </div>
                            </div>

                            <div *ngIf="activeCategory==2">

                                <div class="w3-card-4 classCard mt-3">
                
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            Navigate Content
                                            
                                        </div>
                                          <form [formGroup]="navigateForm" >
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                    
                                                
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Navigate Content	
                                                    </label>
                                                </div>
                                                
                                                <div class="col-lg-2">

                                                    <p-dropdown filter="true" optionlabel="label"
                                                    [options]="navigateContentDataArray"   formControlName="navigateContent"  placeholder="Navigate Content"></p-dropdown> 

                                                </div>
                                    
                                            </div>
                                        </div>
                                       
                                        
                                    </div>
                                </form>
                                    <div style=" margin-left: -67%;" class="col-lg-12 col-12 main-submit-button ">
                                        <button type="submit" class="btn btn-secondary submit-btn"
                                        (click)="navigateContentC()"    >Apply</button>&nbsp;
                                        
                                    </div>
                                </div><br>

                                <div class="w3-card-4 classCard">
                                                                
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        
                                                                        <div class="card own-account-table">
                                                                              <p-table [value]="navigateContentTable" [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [responsive]="true" [totalRecords]="total" [scrollable]="true"
                                                                        styleClass="p-datatable-customers"
                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined"
                                                                                                        value="undefined"></div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Content ID <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <!-- <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">View<p-sortIcon field="name">
                                                                                    </p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th> -->
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="category">  <div style="color: white;"> Content Type ID	
                                                                                    <p-sortIcon field="category"></p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="quantity">  <div style="color: white;"> Mime Type ID	
                                                                                    <p-sortIcon field="quantity"></p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;"> Status ID	 <p-sortIcon field="price"></p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}"  >
                                                                                    <div style="color: white;"> ca Created Date<p-sortIcon field="price"></p-sortIcon></div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}" >
                                                                                    <div style="color: white;">Delete
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        style="visibility:hidden;" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                
                                                                                
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined"
                                                                                                        value="undefined"></div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"
                                                                                         >{{product.navigateContentNode[0].contentId}}</span>
                                                                                    </div>
                                                                                </td>
                    
                    
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.navigateContentNode[0].contentTypeId }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.navigateContentNode[0].mimeTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">{{product.navigateContentNode[0].statusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.data[0].fromDate|date:'yyyy-MM-d hh:mm:ss'}}
                                                                                </td>
                                                                               
                                                                               
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <div >
                                                                                        <svg class="hover" (click)="removeDocumentFromTree(product)"
                                                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                            
                                                                                            height="16" viewBox="0 0 21.223 21.222">
                                                                                            <g transform="translate(0 -0.004)">
                                                                                                <g transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g transform="translate(4.548 0.004)">
                                                                                                    <g transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                    
                                                                                    </div>
                                                                                </td>
                    
                                                                            </tr>
                    
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                            
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                        
                                        
                                </div>
                          
                            


                            </div>

                            <!-- new code edit -->
                            <div *ngIf="activeCategoryMain==1">
                                
                                
                                <div class="panel-collapse">
                                    <br>
                                    <div class="w3-card-4 classCard classCardType" style="margin-top: -4px;">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="changeactiveCategoryMain(1)"><a>
                                                    Advance Search</a></li>
                                                <li (click)="updateAllContentKeywords()"><a>
                                            Auto Create Keywords </a></li>
                                            
                                                    
                                            </ul>
                                        </div>
                                            <div class="create-new-leade mt-2">
                                            
                                                <div>

                                                    <div class="create-new-leade mt-2">
                                                        <div class="container-fluid" *ngIf="!this.show">
                                                        
                                                                <div class="row" style="padding: 7px;">
                                                                        <form class="w-100" [formGroup]="advanceSearchForm">


                                                                            <div class="w3-card-4  w-100">
                                                                                <div class="accordion classCard" id="myAccordion">
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header" id="headingOne">
                                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                                <span class="headingAccords"> Advance Search</span>
                                                                                                <!-- <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                                                                data-target="#creatContentPopup" data-toggle="modal">Create Content</button>
                                                                                     -->
                                                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 81%!important; padding-top: 11px !important;
                                                                                                    padding-bottom: 11px !important;">
                                                                                                </button>									
                                                    
                                                                                            </div>
                                                        
                                                                                        </h2>
                                                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                            <div class="card-body">
                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                    <div class="col-lg-12">
                                                                                                        <div class="row">
                                                                                                
                                                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                                                <label for="exampleInputEmail1">Keywords	
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div class="col-lg-2">
                                                                                                                <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                                
                                                                                                                    aria-describedby="emailHelp" placeholder="Keywords"
                                                                                                                        formControlName="keywords">
                                                                                                            </div>
                                                                                                            <div class="col-lg-3 form-group classInputAA">
                                                                                                                <label for="exampleInputEmail1">Content ID	
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div class="col-lg-2">
            
                                                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                                                  [options]="ContentIdArray"  formControlName="contentID" 
                                                                                                                    placeholder="Content ID">
                                                                                                                </p-dropdown> 
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-lg-12">
                                                                                                        <div class="row">
                                                                                                
                                                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                                                <label for="exampleInputEmail1">Content Assoc Type ID	
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div class="col-lg-2">
            
                                                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                                                   [options]="contentSearchOptionsArray" formControlName="contentAssocTypeID" 
                                                                                                                    placeholder="Select Content Assoc Type ID">
                                                                                                                </p-dropdown> 
                                                                                                            
                                                                                                            </div>
                                                                                                            <div class="col-lg-3 form-group classInputAA">
                                                                                                                <label for="exampleInputEmail1">RoleType ID	
                                                                                                    
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div class="col-lg-2">
            
                                                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                                                [options]="roleTypesArray"      formControlName="roleTypeID" 
                                                                                                                    placeholder="RoleType ID">
            
                                                                                                                </p-dropdown> 
                                                                                                                
                                                                                                            </div>
                                                                                                
                                                                                                            
                                                                                                
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-lg-12">
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                                <label for="exampleInputEmail1">Party ID	
                                                                                                    
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div class="col-lg-2">
            
                                                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                                                 [options]="partyIdArray"   formControlName="partyID" 
                                                                                                                    placeholder="Party ID">
            
                                                                                                                </p-dropdown> 
                                                                                                                
                                                                                                            </div>
            
                                                                                                            <div class="col-lg-3 form-group classInputAA" >
                                                                                                                <label for="exampleInputEmail1">Last Updated Date Filter</label>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div class="col-lg-2">
                                                                                                                <input type="date" id="exampleInputEmail1" class="form-control"
                                                                                                                
                                                                                                                    aria-describedby="emailHelp" 
                                                                                                                        formControlName="lastUpdatedDateFilter">
                                                                                                            </div>
                                                                                                
                                                                                                        </div>
                                                                                                    </div>
            
                                                                                                    <div class="col-lg-12">
                                                                                                        <div class="row">
                                                                                                            <div class="col-lg-3 form-group classInput">
                                                                                                                <label for="exampleInputEmail1">	Through Date
                                                                                                    
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div class="col-lg-2">
            
                                                                                                                <input type="date" id="exampleInputEmail1" class="form-control"
            
                                                                                                                
                                                                                                                aria-describedby="emailHelp" placeholder="Choose Through Date"
                                                                                                                  formControlName="throughDate">
                                                                                                                
                                                                                                            </div>
                                                                                                
                                                                                                            <div class="col-lg-3 form-group classInputAA">
                                                                                                                <label for="exampleInputEmail1">Sorted By	
                                                                                                    
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            
                                                                                                            <div class="col-lg-2">
            
                                                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                                                   [options]="sortOption" formControlName="sortedBy" 
                                                                                                                    placeholder="Sorted By">
            
                                                                                                                </p-dropdown> 
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                
                                                                                                            
                                                                                                
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                
                                                                                                
                                                                                                </div>
                                            
                                                                                                <div style=" margin-left: -18%;" class="col-lg-12 col-12 main-submit-button ">
                                                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                                                    (click)="findAdvanceSearch()"     >Find</button>&nbsp;
                                                                                                    <button type="button" class="btn btn-danger ml-2" (click)="reset()" >Reset</button>
                                                                                                </div>
                                            
                                            
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>

                                                                            <!-- <div class="w3-card-4 classCard">
                
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                    Advance Search
                                                                                    </div>
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                    
                                                                                                <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="exampleInputEmail1">Keywords	
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                    
                                                                                                        aria-describedby="emailHelp" placeholder="Keywords"
                                                                                                            formControlName="keywords">
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputAA">
                                                                                                    <label for="exampleInputEmail1">Content ID	
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">

                                                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                                                      [options]="ContentIdArray"  formControlName="contentID" 
                                                                                                        placeholder="Content ID">
                                                                                                    </p-dropdown> 
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                    
                                                                                                <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="exampleInputEmail1">Content Assoc Type ID	
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">

                                                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                                                       [options]="contentSearchOptionsArray" formControlName="contentAssocTypeID" 
                                                                                                        placeholder="Select Content Assoc Type ID">
                                                                                                    </p-dropdown> 
                                                                                                
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputAA">
                                                                                                    <label for="exampleInputEmail1">RoleType ID	
                                                                                        
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">

                                                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                                                    [options]="roleTypesArray"      formControlName="roleTypeID" 
                                                                                                        placeholder="RoleType ID">

                                                                                                    </p-dropdown> 
                                                                                                    
                                                                                                </div>
                                                                                    
                                                                                                
                                                                                    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Party ID	
                                                                                        
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">

                                                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                                                     [options]="partyIdArray"   formControlName="partyID" 
                                                                                                        placeholder="Party ID">

                                                                                                    </p-dropdown> 
                                                                                                    
                                                                                                </div>

                                                                                                <div class="col-lg-3 form-group classInputAA" >
                                                                                                    <label for="exampleInputEmail1">Last Updated Date Filter</label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="date" id="exampleInputEmail1" class="form-control"
                                                                                                    
                                                                                                        aria-describedby="emailHelp" 
                                                                                                            formControlName="lastUpdatedDateFilter">
                                                                                                </div>
                                                                                    
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">	Through Date
                                                                                        
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">

                                                                                                    <input type="date" id="exampleInputEmail1" class="form-control"

                                                                                                    
                                                                                                    aria-describedby="emailHelp" placeholder="Choose Through Date"
                                                                                                      formControlName="throughDate">
                                                                                                    
                                                                                                </div>
                                                                                    
                                                                                                <div class="col-lg-3 form-group classInputAA">
                                                                                                    <label for="exampleInputEmail1">Sorted By	
                                                                                        
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">

                                                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                                                       [options]="sortOption" formControlName="sortedBy" 
                                                                                                        placeholder="Sorted By">

                                                                                                    </p-dropdown> 
                                                                                                    
                                                                                                    
                                                                                                </div>
                                                                                    
                                                                                                
                                                                                    
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    
                                                                                    
                                                                                    </div>
                                                                            <div style=" margin-left: -18%;" class="col-lg-12 col-12 main-submit-button ">
                                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                           (click)="findAdvanceSearch()"     >Find</button>&nbsp;
                                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()" >Reset</button>
                                                                            </div>
                                                                            </div> -->
                                                                        
                                                                        <br>
<!-- 
                                                                        <div class="w3-card-4 classCard">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    Advance Search List
                                                                                </h4>
                                                                                
                                                                                </div>
                                                                            </header>
                                                                        <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">
                                                                                    
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="" [paginator]="true" [rows]="rows"
                                                                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [responsive]="true" [totalRecords]="total" [scrollable]="true"
                                                                                            styleClass="p-datatable-customers"
                                                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined"
                                                                                                                            value="undefined"></div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            Keywords	 <p-sortIcon field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">Content ID	
                                                                                                            <p-sortIcon field="name">
                                                                                                        </p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="category">  <div style="color: white;"> Party ID	

                                                                                                        <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="quantity">  <div style="color: white;"> Locale String
                                                                                                        <p-sortIcon field="quantity"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"> Content Type ID <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}"  >
                                                                                                        <div style="color: white;"> Customer Id<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}" >
                                                                                                        <div style="color: white;">Mime Type ID</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">Data Resource ID</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">Owner Content ID</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">Created By User</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined"
                                                                                                                            value="undefined"></div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                 class="account-button">{{product.invoiceId}}</span>
                                                                                                        </div>
                                                                                                    </td>
                                        
                                        
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.invoiceTypeId}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.invoiceDate |date :'yyyy-MM-d hh:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.status }}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.description}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.partyTo}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.total |currency :'USD'}}</td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.outstanding |currency :'USD'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        <div>
                                                                                                            <svg class="hover"
                                                                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                                            
                                                                                                                height="16" viewBox="0 0 21.223 21.222">
                                                                                                                <g transform="translate(0 -0.004)">
                                                                                                                    <g transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g transform="translate(4.548 0.004)">
                                                                                                                        <g transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                        
                                                                                                        </div>
                                                                                                    </td>
                                        
                                                                                                </tr>
                                        
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                            <h4 class="ml-2">No Record Found</h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        
                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                        
                                                                        </form>
                                                                    
                                                                </div>
                                                            
                                                        </div>
                                                    
                                                    </div>
                
                                                </div> 
                                            </div>

                                        

                                            
                                    </div>
                                        
                                            
                                </div>
                            </div>
                            <div *ngIf="activeCategoryMain==2">
                                
                                
                                <div class="panel-collapse">
                                    <br>
                                    <div class="w3-card-4 classCard classCardType" style="margin-top: -4px;">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                                <li [ngClass]="activeCategoryA==1?'active':''" (click)="changeactiveCategoryMain(1)"><a>
                                                    Advance Search</a></li>
                                                 <li (click)="updateAllContentKeywords()"><a>
                                            Auto Create Keywords </a></li>
                                            
                                                    
                                            </ul>
                                        </div>
                                            <div class="create-new-leade mt-2">
                                            
                                                <div>

                                                <h1>Auto Create Keywords section</h1>
                
                                                </div> 
                                            </div>

                                        

                                            
                                    </div>
                                        
                                            
                                </div>
                            </div>
                                
                            <!-- end code edit -->
                    
                        </div>
                    </div>
                </div>
        </div>
    </div> 
</div> 


<div class="modal fade" id="creatContentPopup" tabindex="-1" role="dialog" aria-labelledby="creatContentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Content</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Type ID	</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentTypeId" 
                                                        placeholder="Choose Content Type ID" [options]="CalatalogContentTypeIdArray">

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Owner Content ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="ownerContentID" 
                                                        placeholder="Choose Owner Content ID" [options]="CatalogContentIdArray">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Decorator Content ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="decoratorContentID" 
                                                        placeholder="Choose Decorator Content ID" [options]="CatalogContentIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Instance Of Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="instanceContentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Instance Of Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataResourceId" 
                                                        placeholder="Choose Data Resource ID" [options]="DataResourceIdArray">
                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Template Data Resource ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="templateDataResourceID" 
                                                        placeholder="Choose Template Data Resource ID	
                                                        " [options]="DataResourceIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Source ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataSourceID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Data Source ID">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Privilege Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="privilegeEnumID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Privilege Enum ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Service Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="serviceName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Service Name">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Custom Method ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="customMethodID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Custom Method ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Content Name">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description	</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Description">
                                           
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Locale String	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="localeString" 
                                                        placeholder="Locale String	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="mimeTypeID" 
                                                        placeholder="Mime Type ID" [options]="CalatalogMimeTypeIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Character Set ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="characterSetID" 
                                                        placeholder="Character Set ID" [options]="CharacterSetIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Child Leaf Count</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="childLeafCount" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Child Leaf Count">
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Child Branch Count</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="childBranchCount" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Child Branch Count">
                                           
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="createdDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Created Date">
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created By User	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="createdByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Created By User">
                                           
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="lastModifiedDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Last Modified Date">
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="lastModifiedByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Last Modified By User">
                                                        
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createContent()">Create</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="advanceSearchPopup" tabindex="-1" role="dialog" aria-labelledby="advanceSearchPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Advance Search</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Advance Search</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="advanceSearchForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                        
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">Keywords	
                                                        </label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                        
                                                            aria-describedby="emailHelp" placeholder="Enter Keywords"
                                                                formControlName="keywords">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content ID	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                        
                                                        <p-dropdown filter="true"
                                                        
                                                        formControlName="contentID" optionlabel="label"
                                                            placeholder="Select Content ID">
                                                        </p-dropdown>
                                                       
                                                    </div>
                                        
                                                    
                                        
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                        
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">Content Assoc Type ID	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"  
                                                        
                                                        formControlName="contentAssocTypeID" optionlabel="label"
                                                            placeholder="Select Content Assoc Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">RoleType ID	
                                            
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" 
                                                        
                                                        formControlName="roleTypeID" optionlabel="label"
                                                            placeholder="Select Role Type ID	
                                                            ">
                                                        </p-dropdown>
                                                    </div>
                                        
                                                    
                                        
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                        
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">Last Updated Date Filter</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" id="exampleInputEmail1" class="form-control"
                                                        
                                                            aria-describedby="emailHelp" placeholder="Enter Last Updated Date Filter"
                                                                formControlName="lastUpdatedDateFilter">
                                                    </div>
                                        
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">	
                                            
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" id="exampleInputEmail1" class="form-control"
                                                        
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                        
                                                    
                                        
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Sorted By	
                                            
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" 
                                                        
                                                            formControlName="sortedBy" optionlabel="label"
                                                            placeholder="Select Sorted By">
                                                        </p-dropdown>
                                                    </div>
                                        
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>

                                            
                                        
                                        
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 
