import { FormControl, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateAccountModel {

  constructor(

  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      address2: (data && data.address2) ? data.address2 : null,
      address1: (data && data.address1) ? data.address1 : null,
      areaCode: (data && data.areaCode) ? data.areaCode : null,
      city: (data && data.city) ? data.city : null,
      contactNumber: (data && data.contactNumber) ? data.contactNumber : null,
      countryCode: (data && data.countryCode) ? data.countryCode : null,
      countryGeoId: (data && data.countryGeoId) ? data.countryGeoId : null,
      emailAddress: (data && data.emailAddress) ? data.emailAddress : null,
      extension: (data && data.extension) ? data.extension : null,
      officeSiteName: (data && data.officeSiteName) ? data.officeSiteName : null,
      numEmployees: (data && data.numEmployees) ? data.numEmployees : null,
      postalCode: (data && data.postalCode) ? data.postalCode : null,
      stateProvinceGeoId: (data && data.stateProvinceGeoId) ? data.stateProvinceGeoId : null,
      groupName: (data && data.groupName) ? data.groupName : null,

    });


    return this.getFormGroup(form_data);
  }


  getFormGroup(data: any): any {
    return ({
      address1: new FormControl(data.address1, [Validators.required]),
      address2: new FormControl(data.address2),
      areaCode: new FormControl(data.areaCode),
      city: new FormControl(data.city, [Validators.required]),
      contactListId: new FormControl(data.contactListId),
      contactNumber: new FormControl(data.contactNumber, [Validators.required]),
      countryCode: new FormControl(data.countryCode),
      countryGeoId: new FormControl(data.countryGeoId, [Validators.required]),
      emailAddress: new FormControl(data.emailAddress, [Validators.required]),
      extension: new FormControl(data.extension),
      groupName: new FormControl(data.groupName, [Validators.required]),
      numEmployees: new FormControl(data.numEmployees),
      postalCode: new FormControl(data.postalCode, [Validators.required]),
      stateProvinceGeoId: new FormControl(data.stateProvinceGeoId, [Validators.required]),
      officeSiteName: new FormControl(data.officeSiteName),

    });
  }

  preparePayload(payload: any): any {
    return payload;
  }
}
