import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-defaults-accounts-import',
  templateUrl: './defaults-accounts-import.component.html',
  styleUrls: ['./defaults-accounts-import.component.css']
})
export class DefaultsAccountsImportComponent implements OnInit {

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  constructor(readonly router: Router) { }

  ngOnInit(): void {
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  goToAccountsGroup(){
    this.router.navigate(['/users/gl-accounts-import']);
  }
  nextPage(){
    this.router.navigate(['/users/accounts-by-part-family-import']);
  }

}
