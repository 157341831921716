import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';


@Injectable({
    providedIn: 'root'
})

export class BillingAccountService {
    constructor(readonly _ApiService: ApiService) { }

    getBillingAccounts(pagination: { pageNo: number, pageSize: number }, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, formData);
    }
    createBillingAccount(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount`, formData);
    }
    updateBillingAccount(billingAccountId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount?billingAccountId=${billingAccountId}`, formData);
    }
    getBillingAccountById(billingAccountId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/${billingAccountId}`);
    }
    getBillingAccountOrderList(billingAccountId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/orders?billingAccountId=${billingAccountId}`);
    }



    getBillingAccountInvoiceList(billingAccountId: string, statusId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/invoices?billingAccountId=${billingAccountId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&statusId=${statusId}`);
    }
    getBillingAccountPaymentList(billingAccountId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/payment?billingAccountId=${billingAccountId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createBillingAccountPayment(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/payment`, formData);
    }
    getBillingAccountContactMechIdList(billingAccountId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/contactMechId?billingAccountId=${billingAccountId}`);
    }
    getBillingAccountPaymentMethodTypes(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/billingAccount/payment/paymentMethodType`);
    }
}