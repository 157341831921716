
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PositionTypeModel } from './positionType-model';

@Injectable({
  providedIn: 'root'
})


export class PositionTypeService {
  position_type__form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public positionTypeModel: PositionTypeModel
  ) { }

  positionTypeForm(data: any): void {
    if (!this.position_type__form) {
      this.position_type__form = this._formBuilder.group(this.positionTypeModel.mapDataToModel(data));

    }

  }

}









//   prepareTransactionRefferalForm(data:any): void {
//     if (!this.referral_information_form) {
//       this.referral_information_form = this._formBuilder.group(data);
//     }
//   }









//   updateAllTransactionData(data) {
//     this.allTransactionData = data;
//     this.prepareTransactionDetail(this.transacDetailFormModel.mapDataToModel(this.allTransactionData["transaction_details"]));
//     this.prepareCompanyDetailsPropertyForm(this.transacCompanyDetailsFormModel.mapDataToModel(this.allTransactionData["commission_details"]));
//     this.prepareBuyerTenantForm(this.transacBuyerTenantFormModel.mapDataToModel(this.allTransactionData["buyer_tenant_information"][0]));
//     this.prepareSellerLandlordForm(this.sellerLandlordFormModel.mapDataToModel(this.allTransactionData["seller_landlord_information"][0]));
//     this.prepareListingFirmForm(this.ltistingFirmFormModel.mapDataToModel(this.allTransactionData["listing_firm"][0]));
//     this.prepareSellingForm(this.sellingFirmFormModel.mapDataToModel(this.allTransactionData["selling_firm"][0]));
//     this.prepareTitleCompanyForm(this.titleCompanyFormModel.mapDataToModel(this.allTransactionData["title_company"]));
//     this.prepareEscrowCompanyForm(this.escrowCompanyFormModel.mapDataToModel(this.allTransactionData["escrow_company_attorney"]));
//     this.prepareTermiteCompanyForm(this.transactionTermitecompanyFormModel.mapDataToModel(this.allTransactionData["pest_control_company"]));
//     this.prepareTransactionRefferalForm(this.transactionRefferalInformationFormModel.mapDataToModel(this.allTransactionData["referral_information"]));
//     this.prepareTransactionCoordinatorForm(this.transactionCoordinatorFormModel.mapDataToModel(this.allTransactionData["transaction_coordination_company"]));
//     this.prepareCommissionAdvanceCompanyForm(this.commissionAdvanceCompanyFormMOdel.mapDataToModel(this.allTransactionData["Commission_advance_company"]));
//     this.prepareAddTransactionForm(this.transacAddToTransactionFormModel.mapDataToModel(this.allTransactionData["add_transaction"]));
//   }

//   prepareCompanyInfoList(data) {
//     if(this.companyInfo.length === 0) {
//       data.forEach(item => {
//         if(item.company_name)
//           this.companyInfo.push({ 
//             label : `${ item.company_name }`, value : item.company_name,address: item.address_line1,city: item.city,country: item.country_code,
//             state: item.state, email: item.email, zip_code: item.zip_code, phone: item.phone, policy: item.policy_membership,
//             license: item.license, fee: item.fee_dollar, notes: item.comments.map(obj => obj.comment).join(", ")
//         });
//       });
//     }
//   }

//   appendCompanyInfoList(item) {
//     if(item.company_name) {
//       this.companyInfo.push({ 
//         label : item.company_name, value : item.company_name,address: item.address_line1,city: item.city,country: item.country_code,
//         state: item.state, email: item.email, zip_code: item.zip_code, phone: item.phone, policy: item.policy_membership,
//         license: item.license, fee: item.fee_dollar, notes: item.comments.map(obj => obj.comment).join(", ")
//       });
//     }
//   }

//   prepareAutoFillCompanyInfo(event, form_name) {
//     const selectedValue = this.companyInfo.filter(obj => obj.value === event.value)[0];
//     if(selectedValue) {
//       this[form_name].patchValue({
//         company_address: selectedValue.address, city: selectedValue.city, country: selectedValue.country, state: selectedValue.state,
//         email: selectedValue.email, policy: selectedValue.policy, zip_code: selectedValue.zip_code, company_phone: selectedValue.phone,
//         license: selectedValue.license, fee: selectedValue.fee, notes: selectedValue.notes, contact_license: selectedValue.license,
//         tc_company_address: selectedValue.address, tc_license: selectedValue.license,firm_address: selectedValue.address,
//         office_phone: selectedValue.phone
//       });
//     }
//   }

//   resetFromData() {
//     this.allTransactionData = null;

//     this.add_to_transaction_form = null;
//   }





