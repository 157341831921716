import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-skill-type',
  templateUrl: './skill-type.component.html',
  styleUrls: ['./skill-type.component.css']
})
export class SkillTypeComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 8;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  SkillTypeIdList: any;
  skillTypeForm: FormGroup;
  SkillTypeId: any;
  SkillTypeIdArray: any[] = [];
  SkillTypeIdLists: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.skillTypeForm = this._FormBuilder.group({
      skillTypeID: [''],
      Description: [''],

    });
  }


  ngOnInit(): void {
    this.getSkillTypeIdList();
    this.getSkillTypeId();
    this.getSkillTypeProject();
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getSkillTypeProject() {
    this.spinner.show();
    this.myContactsService.getSkillTypeProject().subscribe(res => {
      this.SkillTypeIdLists = res.data[0].node;
      this.spinner.hide();
    })
  }

  createSkillType() {
    this.spinner.show();
    const requestData = {
      "description": this.skillTypeForm.value.Description,
      "skillTypeId": this.skillTypeForm.value.skillTypeID,
    }

    this.myContactsService.createSkillType(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getSkillTypeProject();
        this.skillTypeForm.reset();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  onDelete(product) {
    let skillTypeId = product.skillTypeId;
    console.log("product", product)
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.myContactsService.deleteSkillType(skillTypeId).subscribe((res: any) => {
          if (res.success) {
            this.getSkillTypeProject();
          }
          else if (res.success == false) {
            this.toastr.success(res.message);
          }
          else {
            this.toastr.error(res.message);
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getSkillTypeIdList() {
    this.spinner.show();
    this.myContactsService.getSkillTypeIdList().subscribe(res => {
      this.SkillTypeIdList = res.data;
      this.spinner.hide();
    })
  }
  getSkillTypeId() {
    this.spinner.show();
    this.myContactsService.getSkillTypeId().subscribe(res => {
      this.SkillTypeId = res.data;
      this.spinner.hide();
      for (const value of this.SkillTypeId) {
        this.SkillTypeIdArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
  }
  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }

  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
