<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100" style="height:870px;" >
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 7px!important;">Subscription Details</span>

                    </div>                    

                    
                    

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%; height: 430px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black;">
                                       Company Profile  </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="companyProfileForm">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>Company Name </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="comapnyName">
                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>Address </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="address">
                                                            </div>

                                                            <div class="col-lg-6 classInput bluefont">
                                                                <label>This address will be displayed 
                                                                    as the company address in your Customer 
                                                                    invoices
                                                                </label>
                                                            </div>

                                                            

                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>City </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="city">
                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>State</label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="state">
                                                            </div>

                                                            <div class="col-lg-3 classInput">
                                                        
                                                                    <a class="" style="font: size 12px !important; ">Help me pick a State/Province</a>
                                                                

                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>Zip </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="zip">
                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>Contact Person </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="contactPerson">
                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>Phone </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="phone">
                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>Email </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="email">
                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row formgroupMargin">
                                                            <div class="col-lg-2 form-group classInput">
                                                                <label>Website </label>
                                                            </div>
                                                            <div class="col-lg-2">
                                                                <input type="text" class="form-control"
                                                                formControlName="website">
                                                            </div>

                                                            

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex classInput">
                                                            <div class="checkbox-align" style="color: white; font-size: 12px; font-weight: bold;margin-left: 18% !important; ">
                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                        </div>
                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </p-checkbox>
                                    
                                                                
                                                            </div>

                                                            <div  style="margin-left: 1% !important;">
                                                                <label>
                                                                    Yes, I would like to get periodical about updates
                                                                    about ............. any my industry.
                                                                </label>
                                                            </div>


                                                                
                                                            
                                                        </div>

                                                    </div> 

                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                        
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%; height: 150px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black;">
                                       Licensing  </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container" style="margin-left:1%!important;">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Mobile Licensing</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Unused</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Desktop Licensing</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Unused</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Non-Login Licenses</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Unused</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row classInput mt-1">
                                                            <label>Please <a style="text-decoration:underline;">Click here </a>if you would like to purchase additionaal licenses</label>
    
                                                        </div>
    
                                                    </div>

                                                
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                        
                    </div>

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%; height: 200px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black;">
                                       Modules</p>
                                    
                                </div>
                                 <!-- <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row " >
                                                            <div class="col-lg-2">
                                                                <label>Purchased Modules</label> 


                                                            </div>
                                                            <div class="col-lg-2">
                                                                <label>Flat Rate Pricing</label>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row " >
                                                            <div class="col-lg-2">


                                                            </div>
                                                            <div class="col-lg-2">
                                                                <label>Payments</label>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    

                                                
                                                
                                                <div class="col-lg-12">
                                                    <div class="row classInput">
                                                        <label>Please Click here if you would like to purchase additionaal licenses</label>

                                                    </div>

                                                </div>


                                                

                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>  -->
                            </div>

                            


                        </div>

                        
                    </div>
                    

            
                    

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        

<!-- <ngx-spinner></ngx-spinner> -->







