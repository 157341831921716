<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Template</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                List own created Templates</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)" ><a>
                                Find</a></li>

                            <li *ngIf="!this.editContent" [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)" ><a>
                                Edit</a></li>

                            <li *ngIf="!this.subContent" [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)" ><a>
                               Sub Content</a></li>
                            
                            <button type="submit" class="btn btn-secondary submit-btn ml-2 mt-2" style="margin: 0;" data-toggle="modal" data-target="#createTemplatePopup"  (click)="createLayoutSubContentPatch()">Create New Template</button>

                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        
                    
                                        <div *ngIf="activeCategory==1">
                                            <div class="create-new-leade">
                                                <div class="">
                                                    <div class="">
                                                        <div class="col-12">
                                                            <div class="bg-white color-grey w-100 ">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                                    List Layout



                                                
                                                                                    <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2"  style="margin-left:72%!important;"
                                                                                    data-toggle="modal" data-target="#addEditContentTypePopup">




                                                                                    </button> -->
                                                                                   
                                                                                </div>
                                                                               
                                                                                
                                                
                                                                                <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">
                                                
                                                                                            <div class="card own-account-table">
                                                                                                <p-table [value]="ListLayoutAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                    <ng-template pTemplate="header">
                                                                                                        <tr style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                
                                                                                                                    Content ID		

                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Content Name						
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Description						
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>
                                                
                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Template File			
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th>

                                                                                                         <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                <div style="color: white;">
                                                                                                                    Action		
                                                                                                                </div>
                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                            </th> 
                                                                                                            
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                        <tr>
                                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                                <div class="checkbox-align">
                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </p-checkbox>
                                                                                                                    <span class="account-button"   (click)="redirctToEdit(product.contentId)">
                                                                                                                  {{product.contentId}}
                                                                                                                    </span>
                                                
                                                                                                                </div>
                                                                                                            </td>
                                                
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.contentName}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                {{product.description}}
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                            
                                                                                                            </td>
                                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                                <button type="submit" class="btn btn-danger ml-2" 
                                                                                                                (click)="deleteContent(product)">Delete</button>
            
                                                
                                                                                                            </td> 
                                                
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                
                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No Record
                                                                                                                Found</h4>
                                                                                                        </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>
                                                
                                                                                                <p class="paginate_data">
                                                                                                    View per page </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>  
                                                                            </div>
                                                                            
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                            </div>
                                            
                                            
                                                
                                               
                                            
                                        </div>


                                        <div *ngIf="activeCategory==2">

                                            <div class="create-new-leade mt-2">
                                                <div class="">
                                                    <div class="">
                                                        <form class="w-100">

                                                            <div class="w3-card-4  w-100">
                                                                <div class="accordion classCard" id="myAccordion">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingOne">
                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                <span class="headingAccords"> Template</span>
                                                                                <!-- <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                                                data-target="#creatContentPopup" data-toggle="modal">Create Content</button>
                                                                     -->
                                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 85%!important; padding-top: 11px !important;
                                                                                    padding-bottom: 11px !important;">
                                                                                </button>									
                                    
                                                                            </div>
                                        
                                                                        </h2>
                                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                            <div class="card-body">
                                                                                <form class="w-100" [formGroup]="findLayoutForm">

                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                        
                                                                                                <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="exampleInputEmail1">
                                                                                                        Content ID</label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2" >
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" placeholder="Enter Content ID"
                                                                                                    formControlName="contentID">
                    
                                                                                                    
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Content Name			
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2" >
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" placeholder="Enter Content Name"
                                                                                                    formControlName="contentName">
                                                                                                
                                                                                                </div>
                                                                        
                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                        
                                                                                                <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="accountLimit">Description</label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" placeholder="Enter Description"
                                                                                                    formControlName="description">
                                                                                                
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Created By User	
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" placeholder="Select Created By User"
                                                                                                    optionlabel="label"  formControlName="createdByUser">
                                                                                                    </p-dropdown>
                    
                                                                                                </div>
                                                                        
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                        
                                                                                                <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="exampleInputEmail1">Created Date	
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" 
                                                                                                    formControlName="createdDate">
                                                                                                </div>
                    
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Last Modified By User	
                        
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown filter="true" placeholder="Select Last Modified By User"
                                                                                                    optionlabel="label"  formControlName="lastModifiedByUser">
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                
                                                                        
                                                                                            </div>
                                                                                        </div>
                    
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
                                                                        
                                                                                                <div class="col-lg-3 form-group classInput" >
                                                                                                    <label for="exampleInputEmail1">Last Modified Date		
                                                                                                    </label>
                                                                                                </div>
                                                                                                
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                                    aria-describedby="emailHelp" 
                                                                                                    formControlName="lastModifiedDate">
                    
                                                                                                    
                                                                                                </div>
                    
                                                                                                
                                                                                                
                                                                        
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                        
                                                                                    </div>
                                                                                </form>
                            
                                                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -25%;">
                                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindLayout()">Find</button>&nbsp;
                                                                                    <button type="button" class="btn btn-danger ml-2" (click)="restLayout()">Reset</button>
                                                                                </div>
                            
                            
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>

                                                            <!-- <div class="w3-card-4 classCard">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" 
                                                                    data-target="#createTemplatePopup">Template</button> 
                                                                    Template
                                                                </div><br>
                                                            <form class="w-100" [formGroup]="findLayoutForm">

                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">
                                                                                    Content ID</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Enter Content ID"
                                                                                formControlName="contentID">

                                                                                
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Content Name			
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Enter Content Name"
                                                                                formControlName="contentName">
                                                                            
                                                                            </div>
                                                    
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="accountLimit">Description</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" placeholder="Enter Description"
                                                                                formControlName="description">
                                                                            
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Created By User	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Select Created By User"
                                                                                optionlabel="label"  formControlName="createdByUser">
                                                                                </p-dropdown>

                                                                            </div>
                                                    
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Created Date	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" 
                                                                                formControlName="createdDate">
                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Last Modified By User	
    
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Select Last Modified By User"
                                                                                optionlabel="label"  formControlName="lastModifiedByUser">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            
                                                    
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Last Modified Date		
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" 
                                                                                formControlName="lastModifiedDate">

                                                                                
                                                                            </div>

                                                                            
                                                                            
                                                    
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    
                                                                    
                                                                </div>
                                                            </form>
                                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -25%;">
                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindLayout()">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2" (click)="restLayout()">Reset</button>
                                                                </div>
                                                            </div> -->
                                                            
                                                            <br>
                    
                                                            <div class="w3-card-4 classCardB" style="    width: 100%;">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                    <h4 class=" common-styling h4M">
                                                                        Template
                                                                    </h4>
                                                                    
                                                                    </div>
                                                                </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="FindLayoutAll" [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total" [scrollable]="true"
                                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                
                                                                                                Edit	 <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">Clip<p-sortIcon field="name">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;"> Content Name<p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">Description</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">Template File

                                                                                        </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                <span class="account-button"  (click)="redirctToEdit(product.dataNodes.contentId)">
                                                                                                    {{product.dataNodes.contentId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.dataNodes.dataResourceId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.dataNodes.contentName}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.dataNodes.description}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                        </td>
                                           
                
                                                                                        
                                                                                    </tr>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </form>
                    
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            
                                            
                                                
                                                
                                            
                                        </div>
                                            
                                        <div *ngIf="activeCategory==3">

                                            
                        
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="generatePDFTemplate()">Download</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" (click)="cloneLayout()">Clone Layout</button>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal" 
                                                            data-target="#createTemplatePopup"  (click)="updateSurveyPagePatch()">Update</button>
                                                        <!--<button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                                    </span>
                                                </div>
                                                <div   class="all-users-infomation font-13 font-medium own-user-select">
                                                    
                                                    <div class="color-black container">
                                                    
                                                        <div  *ngIf="this.detailPageAll" class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAll.contentId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content Type</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAll.contentTypeId}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Parent Content	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                          
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">MapKey		
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                     

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Data Resource ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAll.dataResourceId}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content Name		
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAll.contentName}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Description	
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAll.description}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Dr Data Resource Type ID			</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Dr Mime Type ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAll.mimeTypeId}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">File Path</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                           
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div *ngIf="activeCategory==4">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#createTemplatePopup"  (click)="updateSurveyPagePatchCre()">Update</button>
                                                        <!--<button type="submit" class="btn btn-secondary submit-btn ml-2" style="margin: 0;" data-toggle="modal" data-target="#newpopup">New Product Promo</button> -->
                                                    </span>
                                                </div>
                                                <div   class="all-users-infomation font-13 font-medium own-user-select">
                                                    
                                                    <div  class="color-black container">
                                                        <div    *ngIf="this.detailPageAllCre" class="row">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                        {{detailPageAllCre.contentId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content Type</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.contentTypeId}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Data Resource ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.dataSourceId}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content Name		
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.contentName}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                              
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Description	
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.description}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Content ID To</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            TEMPLATE_MASTER
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">MapKey		
                                                                        </p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                         

                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                          
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Dr Data Resource Type ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Dr Data Template Type ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                         
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Dr Mime Type ID	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.mimeTypeId}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">File Path</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                          
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Text</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                           
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Created By User	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.createdByUserLogin}}
                                                                       
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Created Date</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.createdDate|date}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Last Modified By User</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.lastModifiedByUserLogin}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Last Modified Date	</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{detailPageAllCre.lastModifiedDate|date}}
                                                                        
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            	
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                        
                                            
                                            
                                        </div>
                                            
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
 
 
 
 

 <div class="modal fade" id="createTemplatePopup" tabindex="-1" role="dialog" aria-labelledby="createTemplatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="this.show" class="color-black pl-1">Create Layout Sub Content
                </span>
                <span *ngIf="!this.show" class="color-black pl-1">Edit Layout Sub Content
                </span>
                
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li *ngIf="this.show">
                                    <a>Create Layout Sub Content
                                    </a>
                                </li>
                                <li *ngIf="!this.show">
                                    <a>Edit Layout Sub Content
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTemplateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content ID" [readonly]="surveyIdValue">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentType" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Content Type" [readonly]="surveyIdValue">

                                                       
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Resource ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataResourceID" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Select Data Resource ID" [readonly]="surveyIdValue">
                                                        
                                                     
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Select Content Name">
                                                        
                                                     
                       
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content ID To	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentIDTo" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content ID To"  [readonly]="surveyIdValue">
                                               
                                                        
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Map Key		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="mapKey" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Map Key">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Dr Data Resource Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="drDataResourceTypeID" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Select dr DataResource Type ID" [readonly]="surveyIdValueNew">
                                           
                        
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Dr Data Template Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="drDataTemplateTypeID" 
                                                        placeholder="Select Dr Data Template Type ID"   [options]="drDataTemplateTypeIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Dr Mime Type ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="drMimeTypeID" 
                                                        placeholder="Select Dr Mime Type ID"    [options]="drMimeTypeIdArray">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">File Path</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="filePath" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter File Path	">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Text</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="text" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Text">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="createdByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Select Created By User"    [readonly]="surveyIdValueNew">
                                           
                                                        <!-- <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="createdByUser" 
                                                        placeholder="Select Created By User"    [readonly]="surveyIdValueNew">

                                                        </p-dropdown> -->
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created Date		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="createdDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp"  [readonly]="surveyIdValueNew">
                                           
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="lastModifiedByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Select Created By User"    placeholder="Select Last Modified By User"  [readonly]="surveyIdValueNew">
                                                        <!-- <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="lastModifiedByUser" 
                                                        placeholder="Select Last Modified By User"  [readonly]="surveyIdValueNew">

                                                        </p-dropdown> -->
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="lastModifiedDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp"  [readonly]="surveyIdValueNew">
                                           
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                           
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn" (click)="createLayoutSubContent()">Create</button>
                                                <button *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn" (click)="updateLayoutSubContent()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="summaryUpdatePopup" tabindex="-1" role="dialog" aria-labelledby="summaryUpdatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Template
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Template
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addTemplateForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Type</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentType" 
                                                        placeholder="Select Content Type">

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Data Resource ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataResourceID" 
                                                        placeholder="Select Data Resource ID">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentName" 
                                                        placeholder="Select Content Name">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Description">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Dr Data Resource Type ID		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="drDataResourceTypeID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Dr Data Resource Type ID">
                                               
                                                        
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Dr Mime Type ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="drMimeTypeID" 
                                                        placeholder="Select Dr Mime Type ID">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">File Path</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="filePath" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter File Path	">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">MapKey</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="mapKey" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter MapKey">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Parent Content</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="parentContent" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Parent Content">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>


                                           
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



 <ngx-spinner></ngx-spinner> 

