import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-gl-cost-center',
  templateUrl: './gl-cost-center.component.html',
  styleUrls: ['./gl-cost-center.component.css']
})
export class GlCostCenterComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= "";
  CostCenters: any;
  glAccountCategories: any;
  amountArray: any[] = [];
  giArray: any[] = [];
  postData: any[] = [];
  activeCategory=9;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    
    readonly _FormBuilder: FormBuilder,
    ) { }
  getCost = [{
    value: 'GEN_COST_CALC'
  }]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activeCategory=9;
    this.getCostCenters();
  }
  chartAccount(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this.router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this.router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this.router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this.router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this.router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this.router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this.router.navigate(["/financial/gl-cost-center"])
  } 
  getCostCenters() {
    this.spinner.show();
    this.accountsService.getCostCenters().subscribe(res => {
      this.CostCenters = res.data.costCenters;
      this.glAccountCategories = res.data.glAccountCategories;
      this.spinner.hide();


    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  submit(product) {
    this.spinner.show();
    this.postData=[]
    for (let r of product) {
      for (let s of r.glCategory) {
        const value = {
          "key":s.glAccountCategoryId,
          "value":s.percentage
        }
        this.amountArray.push(value);
      }
      var result = {};
      for (var i = 0; i < this.amountArray.length; i++) {
        result[this.amountArray[i].key] = this.amountArray[i].value;
      }
      const saveData = {
        glAccountId: r.glAccountId,
        amountPercentageMap: result
      }
      this.postData.push(saveData);
      this.amountArray = []
    }
    this.accountsService.postCostCenters(this.postData).subscribe((res: any) => {  
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getCostCenters();
}}, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.message);
      
    });}
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
