import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { OppourtunitiesService } from '../oppourtunities.service'
import * as moment from 'moment';
import { AccountsService } from '../../accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CreateOppourtunityFormService } from '../create-opportunity/create-oppourtunity-service';
declare var $: any;
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';



@Component({
  selector: 'app-detailed-opportunity-summary',
  templateUrl: './detailed-opportunity-summary.component.html',
  styleUrls: ['./detailed-opportunity-summary.component.css']
})
export class DetailedOpportunitySummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  oppourtunityId: any;
  oppourtunityDetail: any;
  nextStepDate;
  estimatedCloseDate;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  oppourtunityLists: any;
  opportunityIdArray: any = [];
  opportunityNameArray: any = [];
  marketCompaign: any;
  source: any;
  initialStageArray: any = [];
  public initialArray: any = [];
  public initialTypeArray: any = [];
  mainData: any;
  public idSearchType: any;
  public initialStage: any;
  public leadPartyId: any;
  public nameSearchType: any;
  public opportunityName: any;
  public oppurtunityId: any;
  public type: any;
  currencyArray: any = [];
  getCurrencyList: any;
  activeCategory = 2;
  show = false;
  test = [new Date(), new Date()];
  marketCompaignArray: any = [];
  sourceArray: any = [];
  pageNo = 1;
  search = ''
  accountsList: any;
  contactMechId = '';
  firstNameSearchType = "Contains";
  firstName = "";
  lastName = "";
  lastNameSearchType = "Contains";
  public groupName = "";
  public groupNameSearchType = "Contains";
  public partyId = "";
  public partyIdSearchType = "Contains";
  contactMechTypeId = "";
  initialAccount: any;
  initialAccountArray: any = [];
  leadsLists: any;
  preSelectedPriority;
  leadPartyIdd: any = [];
  closeDate;
  workEffortTypeId: any;
  createCasesForm: FormGroup;
  createAccountsForm: FormGroup;
  createNotesForm: FormGroup;
  teamMemberForm: FormGroup;

  constructor(readonly activatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly oppourtunitiesService: OppourtunitiesService,
    readonly router: Router,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    public createOppourtunityFormService: CreateOppourtunityFormService,
    public datepipe: DatePipe,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,

  ) {
    this.createCasesForm=this._FormBuilder.group({
      opportunityName:"",
      stage:"",
      opportunityAmount:"",
      estimatedCloseDate:"",
    })

    this.createAccountsForm=this._FormBuilder.group({
      accountName:"",
      city:"",
      email:"",
      primaryPhone:"",
    })

    this.createNotesForm=this._FormBuilder.group({
      noteInfo:"",
      createdBy:"",
      noteDateTime:"",
    })

    this.teamMemberForm=this._FormBuilder.group({
      newTeamMember:"",
      role:"",
    })

    this.idSearchType = 'Contains';
    this.nameSearchType = 'Contains';
    this.initialStage = null;
    this.leadPartyId = '';
    this.oppurtunityId = '';
    this.type = null;
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];

    
  }

  
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getOppourtunityList();
    this.getCurrency();
    this.getDropdown();
    this.getLeadList();
    this.getAccountList();
    this.activatedRoute.queryParams.subscribe(params => {
      this.oppourtunityId = params['oppourtunityId'];

    })

    this.getOppourtunitySummary();

    this.createOppourtunityFormService.create_oppourtunity_form = null;
    this.createOppourtunityFormService.prepareOppourtunityForm(this.createOppourtunityFormService.create_oppourtunity_form);
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    if (this.oppourtunityId) {
      this.show = true;
      this.getOpportunityDetails();
    }
    else {
      this.show = false;
      throw "invalid Id"
    }

    $(document).ready(() => {
      $("[data-toggle='tooltip']").tooltip();
    });



    this.oppourtunitiesService.getInitialStage().subscribe(res => {
      this.mainData = res.data;
      this.initialStageArray = res.data.initialStage.map(value => {
        return {
          label: value.description,
          value: value.opportunityStageId
        };
      });
      this.initialTypeArray = res.data.type.map(value => {
        return {
          label: value.description,
          value: value.enumId
        };
      });

    });
  }

backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this.router.navigate(['/crm/main-overview']);
}

  cancelSubmit() {
    this.router.navigate(['/crm/opportunities'])
  }
  getOppourtunitySummary() {
    this.spinner.show();
    this.oppourtunitiesService.getOppourtunityDetails(this.oppourtunityId).subscribe(res => {
      this.spinner.hide();
      this.oppourtunityDetail = res.data;
      console.log(this.oppourtunityDetail);
      this.nextStepDate = this.oppourtunityDetail.nextStepDate ? moment(this.oppourtunityDetail.nextStepDate).format('YYYY-MM-DD') : 'NA'
      this.estimatedCloseDate = this.oppourtunityDetail.estimatedCloseDate ? moment(this.oppourtunityDetail.estimatedCloseDate).format('YYYY-MM-DD') : 'NA'
    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getOppourtunityList() {
    this.spinner.show();
    this.oppourtunitiesService.getOpportunityLists().subscribe(res => {

      this.oppourtunityLists = res.data;
      this.spinner.hide();
    });

  }
  getCurrency() {
    this.spinner.show();
    this.oppourtunitiesService.getCurrency().subscribe(res => {
      this.getCurrencyList = res.data.currencyList;
      this.getCurrencyList.forEach(currencyList => {
        this.currencyArray.push({
          label: currencyList.description + "-" + currencyList.uomId,
          value: currencyList.uomId


        })
      })
    })
    this.spinner.hide();
  }

  getDropdown() {
    this.spinner.show();
    this.oppourtunitiesService.getInitialStage().subscribe(res => {
      this.mainData = res.data;
      for (const value of this.mainData.initialStage) {
        this.initialArray.push({
          label: value.description,
          value: value.opportunityStageId
        })
      }

      for (const value of this.mainData.type) {
        this.initialTypeArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.mainData.marketingCampaign) {
        this.marketCompaignArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId
        })
      }
      for (const value of this.mainData.source) {
        this.sourceArray.push({
          label: value.description,
          value: value.dataSourceId
        })
      }
    })
    this.spinner.hide();
  }
  navigate() {
    this.router.navigate(["/crm/opportunities"]);
  }
  getOpportunityDetails() {
    this.spinner.show();
    this.oppourtunitiesService.getOppourtunityDetails(this.oppourtunityId).subscribe(res => {

      this.nextStepDate = res.data.nextStepDate;
      this.closeDate = res.data.estimatedCloseDate;

      const formValue = this.createOppourtunityFormService.create_oppourtunity_form;
      this.spinner.show();
      setTimeout(() => {
      formValue.patchValue({
        oppurtunityId: res.data.salesOpportunityId,
        opportunityName: res.data.opportunityName,
        accountPartyId: res.data.initialAccount.partyId.trim(),
        leadPartyId: res.data.leadName.partyId.trim(),
        estimatedamount: res.data.estimatedAmount,
        probablity: res.data.estimatedProbability,
        initialStage: res.data.opportunityStageId,
        nextStep: res.data.nextStep,
        type: res.data.typeEnumId,
        marketing_campaign: res.data.marketingCampaignId,
        currencyId: res.data.currencyUomId,
        source: res.data.dataSourceId,
        description: res.data.description,
      })
    }, 3000);
      this.spinner.hide();
    })

  }

  public getLeadList() {
    this.spinner.show();
    const leadAdvancedSearch = {
      contactMechId: this.contactMechId,
      firstName: this.firstName,
      firstNameSearchType: this.firstNameSearchType,
      lastName: this.lastName,
      lastNameSearchType: this.lastNameSearchType,
      partyId: this.partyId,
      partyIdSearchType: this.partyIdSearchType
    }
    const contacts = {
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      isMyLead: "false"
    }
    this.oppourtunitiesService.getLeadsList(contacts, leadAdvancedSearch).subscribe(res => {
      this.leadsLists = res.data;
      this.spinner.hide();
      for (const value of this.leadsLists) {
        this.leadPartyIdd.push({
          label: value.partyId.trim(),
          value: value.partyId.trim()
        })
      }
    })

  }

  getAccountList() {
    this.spinner.show();
    const allReq = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize,
      "all": "true"
    }
    const searchAccountForm = {
      contactMechTypeId: this.contactMechTypeId,
      groupName: this.groupName,
      groupNameSearchType: this.groupNameSearchType,
      partyId: this.partyId,
      partyIdSearchType: this.partyIdSearchType
    }
    this.oppourtunitiesService.getAccountList(allReq, searchAccountForm).subscribe(res => {

      this.initialAccount = res.data;
      this.spinner.hide();
      for (const value of this.initialAccount) {
        this.initialAccountArray.push({
          label: value.partyId.trim(),
          value: value.partyId.trim()
        })
      }
    })

  }
  update(id) {
    this.router.navigate(['/crm/opportunities/create-opportunity'], { queryParams: { oppourtunityId: id } });
  }

  onUpdate() {
    this.spinner.show();
    let formValues = { ...this.createOppourtunityFormService.create_oppourtunity_form.value }

    this.oppourtunitiesService.updateOppourtunity(formValues, this.oppourtunityId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        this.router.navigate(['/crm/opportunities'])
      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })

  }
  eventDetailEditData() {
    
    this.spinner.show();
    this.oppourtunitiesService.closeoppourtunity(this.oppourtunityId).subscribe(res => {
   
     this.preSelectedPriority=res.data[0].priority;
     this.workEffortTypeId=res.data[0].workEffortTypeId
     
      const patchData = this.createOppourtunityFormService.create_oppourtunity_form;
      this.spinner.show();
      setTimeout(() => {
        patchData.patchValue({
          partyId: res.data[0].toPartyId,
          workEffortName: res.data[0].workEffortName,
        
          description: res.data[0].description,
          workEffortPurposeTypeId: res.data[0].workEffortPurposeTypeId,
          estimatedStartDate: res.data[0].estimatedStartDate ? new Date(res.data[0].estimatedStartDate) : '',
          estimatedCompletionDate: res.data[0].estimatedCompletionDate ? new Date(res.data[0].estimatedCompletionDate) : ''
  
        })
      
      }, 3000);
      this.spinner.hide();
    
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
