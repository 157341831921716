import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CategoryService } from '../new-catagory/category.service';

@Injectable()
export class DetailCategorySummaryResolver implements Resolve<[any, any, any, any, any, any, any]>{

    constructor(readonly _CategoryService: CategoryService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<[any, any, any, any, any, any, any]> {
        return Promise.all([
            this._CategoryService.getCategoryById(route.params.categoryId).toPromise().then(data => data.data),
            this._CategoryService.getCategoryAttribute(route.params.categoryId).then(data => data.data),
            this._CategoryService.getLinkTypeEnum().then(data => data.data),
            this._CategoryService.getCategoryLinks({ pageNo: 1, pageSize: 200 }, route.params.categoryId).then(data => data.data),
            this._CategoryService.getProdCatContentType().then(data => data.data),
            this._CategoryService.getCategoryContent(route.params.categoryId).then(data => data.data),
            this._CategoryService.getProducts(false, route.params.categoryId).then(data => data.data)
        ]);
    }
}