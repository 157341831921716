import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms'; 
import { Location } from '@angular/common';

@Component({
  selector: 'app-week-list',
  templateUrl: './week-list.component.html',
  styleUrls: ['./week-list.component.css']
})
export class WeekListComponent implements OnInit {
  activeCategoryMain=2;
  products3: any;
  total=0;
  rowNumber=0;
  @ViewChild('closebutton') closebutton;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= "";
  createWeek: FormGroup;
  activeCategory = 2;
  calendarWeekId: any;
  show: boolean;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  
  ProdWeek: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService, readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,
    readonly router: Router) {
      this.createWeek = this._FormBuilder.group({
        CalendarWeekID: [''],
        Description: [''],
        MondayStartTime: [''],
        MondayCapacity: [''],
        TuesdayStartTime: [''],
        TuesdayCapacity: [''],
        WednesdayStartTime: [''],
        WednesdayCapacity: [''],
        ThursdayStartTime: [''],
        ThursdayCapacity: [''],
        FridayStartTime: [''],
        FridayCapacity: [''],
        SaturdayStartTime: [''],
        SaturdayCapacity: [''],
        SundayStartTime: [''],
        SundayCapacity: [''],
  
      });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getProdWeek();
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "calendarWeekId":  this.createWeek.value.CalendarWeekID,
      "description":  this.createWeek.value.Description,
      "fridayCapacity": this.createWeek.value.FridayCapacity,
      "fridayStartTime":  this.createWeek.value.FridayStartTime,
      "mondayCapacity":  this.createWeek.value.MondayCapacity,
      "mondayStartTime":  this.createWeek.value.MondayStartTime,
      "saturdayCapacity":  this.createWeek.value.SaturdayCapacity,
      "saturdayStartTime":  this.createWeek.value.SaturdayStartTime,
      "sundayCapacity":  this.createWeek.value.SundayCapacity,
      "sundayStartTime":  this.createWeek.value.SundayStartTime,
      "thursdayCapacity":  this.createWeek.value.ThursdayCapacity,
      "thursdayStartTime":  this.createWeek.value.ThursdayStartTime,
      "tuesdayCapacity":  this.createWeek.value.TuesdayCapacity,
      "tuesdayStartTime":  this.createWeek.value.TuesdayStartTime,
      "wednesdayCapacity":  this.createWeek.value.WednesdayCapacity,
      "wednesdayStartTime":  this.createWeek.value.WednesdayStartTime,
    }
    this.productionService.postProdWeek(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.getProdWeek();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    }); }
  getProdWeek() {
    this.spinner.show();
  
    this.productionService.getProdWeek(this.finSize).subscribe((res: any) => {
      this.ProdWeek = res.data;
     
      this.spinner.hide();
    })
  
  }
  Calenders() {
    this.router.navigate(["/production/calender-list"])

  }
  Week() {
    this.router.navigate(["/production/week-list"])

  }
  editInfo(calendarWeekId,description){
    this.router.navigate(["production/detail-week-list"],{ queryParams: { calendarWeekId : calendarWeekId,description:description}})

  }
/*   createWeek() {
    this.router.navigate(["production/create-new-week"])

  } */
  updateWeek(ecalendarWeekId) {
    this.router.navigate(["production/create-new-week"],{ queryParams: { calendarWeekId : ecalendarWeekId}})

  }
  
  deleteProdWeek(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deleteProdWeek(id).subscribe((res: any) => { 
          this.getProdWeek();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}



