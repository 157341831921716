<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Distribution </span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>

            </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text"> Distribution Summary</p>
                                                    <span>
                                                    <button type="submit" style="margin: 0 !important;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">Update</button>
                                                    </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="article-container">
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">Distributor ID</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.partyId}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">Manifest Company Name</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.manifestCompanyName}}</span>
                                                                 
                                                                </div>
                                
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">Manifest Company Title</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.manifestCompanyTitle}}</span>
                                                                </div>
                                
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">Manifest Logo Url</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.manifestLogoUrl}}</span>
                                                                </div>
                                
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Manifest Policies</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.manifestPolicies}}</span>
                                                                </div>



                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Distributor Type</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.typeVendor}}</span>
                                                                </div>
                                                               
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Distributor Name
                                                                    </p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.vendorName}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Distributor Phone</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.VendorPhone}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Distributor Fax</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.VendorFax}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Distributor Email</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.VendorEmail}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Company Website
                                                                    </p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.CompanyWebsite}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Distributor is Active</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.isActive}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Date Created</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.createdStamp | date:"yyyy-MM-dd hh:mm:ss"}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Distributor Forms</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.VendorForms}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Map</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.Map}}</span>
                                                                </div>

                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Company</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.company}}</span>
                                                                </div>
                                                               
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Individual</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{listDistributors.individual}}</span>
                                                                </div>
                                                               
                                                              
                                                               
                                                                
                                                              </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Create-New-Vender</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategory==2?'active':''"
                                                ><a>
                                                    General</a></li>
                                            <li [ngClass]="activeCategory==3?'active':''"
                                               ><a>
                                                    Contact</a></li>
                                            <!-- <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                                    Address</a></li> -->
                                            <li [ngClass]="activeCategory==5?'active':''"
                                               ><a>
                                                    Financial</a></li>



                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createVendor">
                                                    <div *ngIf="activeCategory==2">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            ID<span style="color:red">*</span></label>
                                                                    </div>
                                                                  
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown formControlName="partyId"
                                                                            placeholder="Choose Distributor ID" filter="true"
                                                                            [options]="partyIdArray"
                                                                            optionlabel="label">

                                                                        </p-dropdown>
                                                                        <!-- <input type="email" formControlName="Name"
                                                                        formControlName="Name" class="form-control"
                                                                        placeholder="Enter Name"> -->

                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Name

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="Name"
                                                                            formControlName="Name" class="form-control"
                                                                            placeholder="Enter Name">
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Type of Product
                                                                            or Service Offered

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="type"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Type of Product or Service Offered"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Distributor Name

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorName"
                                                                            placeholder="Enter Distributor Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Email

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorEmail"
                                                                            placeholder="Enter Distributor Email"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Company Website

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="CompanyWebsite"
                                                                            id="exampleInputEmail1" placeholder="Enter Company Website "
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor is
                                                                            Active

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorisActive"
                                                                            id="exampleInputEmail1" placeholder="Enter Distributor is Active"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Individual

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="text" formControlName="individual"
                                                                            id="exampleInputEmail1" placeholder="Enter Individual"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Forms

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorForms"
                                                                            placeholder="Enter Distributor Forms" id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Map

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="Map"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Map"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>



                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Manifest Company
                                                                            Title

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestCompanyTitle"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Company Title">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Manifest
                                                                            Policies

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestPolicies"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Policies">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Manifest Company
                                                                            Name

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestCompanyName"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Company Name">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Manifest Logo
                                                                            Url

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="manifestLogoUrl"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Manifest Logo Url">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">



                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Phone

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" class="form-control"
                                                                            formControlName="Phone"
                                                                            placeholder="Enter Phone"
                                                                            id="exampleInputEmail1">
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:11%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==3">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputP">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Phone

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorPhone"
                                                                            placeholder="Enter Distributor Phone"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Distributor Fax

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="DistributorFax"
                                                                            placeholder="Enter Distributor Fax"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                  



                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(2)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(5)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <!-- <div *ngIf="activeCategory==4">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputD">
                                                                        <label for="exampleInputEmail1">Address

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" disabled [(ngModel)]="this.addressVendor" [ngModelOptions]="{standalone:true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                                                    </div>




                                                                </div>
                                                            </div>






                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:64%;">
                                                            <button type="submit" (click)="changeactiveCategoryFunction(5)" class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div> -->
                                                    <div *ngIf="activeCategory==5">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Credit Limit

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="CreditLimit"
                                                                            placeholder="Enter Credit Limit"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Billing Rate
                                                                            Level


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="billingRateLevel"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter Billing Rate Level">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Company


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">

                                                                        <input type="email" formControlName="company"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Company Tax ID

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="CompanyTaxID"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company Tax ID"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Distributor
                                                                            Eligible
                                                                           

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Distributor Eligible"
                                                                            formControlName="EligibleFor"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Terms of
                                                                            Agreements

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Terms of Agreements"
                                                                            formControlName="agreement"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12">
                                                                <div class="row">


                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Default Payables
                                                                            Account


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Default Payables Account"
                                                                            formControlName="accountNo"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Print Check As


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Print Check As"
                                                                            formControlName="PrintCheckAs"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="onSubmit()"
                                                                class="btn btn-secondary submit-btn">Save</button>

                                                        </div>
                                                    </div>



                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>