<div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-grey fontheader">Category</span>

                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="mb-5">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="create-new-leade mt-2">
                            <div class="">
                                <div class="">
                                    <form class="w-100" [formGroup]="categoryFindForm">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <!-- <span class="headingAccords"> Search Option</span> -->
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-outline-secondary active marginHeader" data-toggle="modal" data-target="#exampleModalCenter">Create New Category</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 77%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Category ID </label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" formControlName="categoryId" [options]="opportunityIdArray" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input formControlName="categoryId"  type='text' class="form-control" placeholder="Enter Category ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Category Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" formControlName="categoryName"  [options]="opportunityIdArray" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input formControlName="categoryName" type='text' class="form-control" placeholder="Enter Category Name">
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                
                                                            </div>
    
                                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -30%;">
                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getCategorySearch()">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#exampleModalCenter">Create New Category</button>
                                            </div><br>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Category ID </label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" formControlName="categoryId" [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input formControlName="categoryId"  type='text' class="form-control" placeholder="Enter Category ID">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Category Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" formControlName="categoryName"  [options]="opportunityIdArray" optionlabel="label">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input formControlName="categoryName" type='text' class="form-control" placeholder="Enter Category Name">
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -30%;">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getCategorySearch()">Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>

                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Category Name List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">

                                                        <div class="card own-account-table">
                                                            <p-table [value]="categoriesNew"   [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Product Category ID
                                                                                <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Product Category Type ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> Primary Parent Category ID
                                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Category Name
                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span  class="account-button"  
                                                                                routerLink="/product-management/catagories/detail-category-summary/{{product.productCategoryId}}"  
                                                                                class="account-button">{{product.productCategoryId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.productCategoryTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.primaryParentCategoryId}}</td>
                                                                        <td [ngStyle]="{'width':'200px'}">{{product.categoryName}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">{{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            {{product.createdStamp | date :'yyyy-MM-dd HH:mm:ss'}}
                                                                        </td>


                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <!-- <p class="paginate_data">
                                                                View per page </p> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Product Category</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" (click)="activeTab(2)"><a>
                                Create Product Category</a></li>
                                <ng-container *ngIf="editMode">
                                    <li [ngClass]="activeCategory==1?'active':''" (click)="activeTab(1)"><a>
                                    Upload Category Image</a></li>
                                </ng-container>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <ng-container *ngIf="activeCategory==2">
                                        <form class="w-100" [formGroup]="categoryForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputD">
                                                            <label for="exampleInputEmail1">Product Category ID</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="productCategoryId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Category ID">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Product Category Name</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="categoryName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Category Name">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputD">
                                                            <label for="exampleInputEmail1">Product Category Type<span
                                                        style="color:red">*</span></label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <p-dropdown filter="true" formControlName="productCategoryTypeId" [options]="categoryArray" optionlabel="label" placeholder="Product Category Type">
                                                            </p-dropdown>
                                                            <small class="text-danger" *ngIf="!!categoryForm.controls.productCategoryTypeId.invalid && !!categoryForm.controls.productCategoryTypeId.touched"></small>
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Product Category Description</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1" rows="1"></textarea>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputD">
                                                            <label for="exampleInputEmail1">Category Image URL </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="categoryImageUrl" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Category Image URL">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Link One Image URL </label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="linkOneImageUrl" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Link One Image URL">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputD">
                                                            <label for="exampleInputEmail1">Link Two Image URL</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="linkTwoImageUrl" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Link Two Image URL">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Detail Screen</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="email" formControlName="detailScreen" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Detail Screen">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputD">
                                                            <label for="exampleInputEmail1">Primary Parent Category</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <ng-select [items]="categories" plcaeholder="Primary Parent Category" formControlName="primaryParentCategoryId" bindLabel="categoryName" bindValue="productCategoryId" class="ng-select-width"></ng-select>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <div class="main-submit-button" style="    margin-right: 9%;">
                                                <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">{{editMode ?'Update':'Submit'}}</button>


                                            </div>
                                        </form>
                                    </ng-container>
                                    <ng-container *ngIf="activeCategory==1 && editMode">
                                        <form class="w-100">
                                            <div class="container row">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="">Category Image File</label>
                                                        </div>

                                                        <div class="col-lg-3">
                                                            <input type="file" class="custom-file-input" (change)="setImage($event)" id="inputGroupFile01">
                                                            <label class="custom-file-label" for="inputGroupFile01">Choose
                                                            file</label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="exampleRadios" (click)="setCategoryImageType('category')" id="exampleRadio1">
                                                        <label class="form-check-label" for="exampleRadio1">
                                                    Category Image URL
                                                </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="exampleRadios" (click)="setCategoryImageType('linkOne')" id="exampleRadio2">
                                                        <label class="form-check-label" for="exampleRadio2">
                                                    Link One Image URL
                                                </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="exampleRadios" (click)="setCategoryImageType('linkTwo')" id="exampleRadios1" value="option1">
                                                        <label class="form-check-label" for="exampleRadios1">
                                                    Link Two Image URL
                                                </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="d-flex justify-content-end">
                                                        <button class="btn btn-primary cursor-pointer ml-2" (click)="uploadImage()">Upload
                                                    Image</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>