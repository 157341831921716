import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

import { TrainingApprovalService } from "./training-approval.service";
import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-training-approvals',
  templateUrl: './training-approvals.component.html',
  styleUrls: ['./training-approvals.component.css']
})
export class TrainingApprovalsComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  total=0;
  activeTabbing = 1;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  options: any;
  public trainingSize = {
    "pageNo": this.pageNo,
    "pageSize": this.pageSize
  };
 

  trainingApprovalForm: FormGroup;
  dateIdArray = [
    {
      label: 'Less Than',
      value: 'LessThan'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },

    {
      label: 'Greater Than',
      value: 'GreaterThan'
    },
    {
      label: 'Greater Than Equal To',
      value: 'GreaterThanEqualTo'
    },
    {
      label: 'Less Than Equal To',
      value: 'LessThanEqualTo'
    },
    {
      label: 'Is Empty',
      value: 'IsEmpty'
    }

  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];






  calendarValue: any;
  trainingApproval: any;
  trainingApprovalList: any[];
  trainingClassTypes: any[];
  isForm: boolean;
  isUpdate: boolean;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  calendarEventForm: FormGroup;
  partyIds: any[];
  workEffortId: string;
  partyId: string;
  date: {
    startDate: string,
    endDate: string
  };
  assignList: any[];
  constructor(
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly modal: NgbModal,
    readonly accountsService: AccountsService,
      private _location: Location,
      readonly router: Router,
    readonly spinner:NgxSpinnerService,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly _GlobalResourceService: GlobalResourceService
  ) {
    this.date = {
      startDate: '',
      endDate: ''
    };
    this.assignList = [];
    this.trainingApprovalList = [];
    this.trainingClassTypes = [];
    this.isForm = false;
    this.isUpdate = false;
    this.partyIds = [];
    this.calendarEventForm = this._FormBuilder.group({
      description: ['', [Validators.required]],
      estimatedCompletionDate: [''],
      estimatedStartDate: [''],
      workEffortName: ['']
    });
    this.partyId = '';
    this.workEffortId = '';
    this.trainingApprovalForm = this._FormBuilder.group({
      approvalStatus: [''],
      approvalStatusSearchType: ['Contains'],
      approverId: [''],
      approverIdSearchType: ['Contains'],
      fromDateFrom: [''],
      fromDateFromSearchType: ['Equals'],
      fromDateTo: [''],
      fromDateToSearchType: ['Equals'],
      partyId: [''],
      reason: [''],
      reasonSearchType: ['Contains'],
      thruDateFrom: [''],
      thruDateFromSearchType: ['Equals'],
      thruDateTo: [''],
      thruDateToSearchType: ['Equals'],
      trainingClassTypeId: [''],
      trainingRequestId: [''],
      trainingRequestIdSearchType: ['Contains'],
      workEffortId: [''],
      workEffortIdSearchType: ['Contains'],
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getEvents();
    this.getTrainingApprovalList();
    this.options = {

      defaultDate: '2017-02-01',
      plugins: [dayGridPlugin],
      initialView: 'dayGridMonth',

      header: {
        left: 'prev,next',
        center: 'title',
        right: 'timeGridMonth,timeGridWeek,timeGridDay'
      }
    }
    this.getPartyIds();
    this.getTrainingClass();
  }

  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  detailPage(id) {
    this.router.navigate(["/hr/training-approvals/training-summary"], { queryParams: { trainingId: id } })
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  getTrainingClass(): void {
    this.spinner.show();
    this.trainingApprovalService.getTrainingClassTypeList({ pageNo: 1, pageSize: 200 })
      .then(data => {
        this.spinner.hide();
        if (data.success) {
          this.trainingClassTypes = data.data.map(value => {
            return { label: value.description, value: value.trainingClassTypeId };
          });
        }
      });
     
  }
  getEvents(): void {
    this.spinner.show();
    
    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
    }
    this.trainingApprovalService.getTrainingCalendar({ pageNo: 1, pageSize: 100 }, { startDate: this.date.startDate, endDate: this.date.endDate })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.events = data.data.map(value => {
            return {
              ...value, ...{
                start: new Date(value.estimatedStartDate),
                end: new Date(value.estimatedCompletionDate),
                title: value.description,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });
     
  }




 
  cancel(): void {
    this.calendarEventForm.reset();
    this.isForm = false;
    this.isUpdate = false;
  }

  assignEvent(): void {
    this.spinner.show();
    this.trainingApprovalService.assignTraining({
      fromDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : '',
      partyId: this.partyId,
      thruDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
      trainingClassTypeId: this.calendarEventForm.value.workEffortName,
      workEffortId: this.workEffortId
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Assigned');
          this.calendarEventForm.reset();
          this.isForm = false;
          this.isUpdate = false;
          this.spinner.hide();
          this.trainingApprovalForm.patchValue({ workEffortId: this.workEffortId });
          this.getTrainingApprovalList();
          this.activeTabbing = 2;
        }
      });
     
  }
  update(): void {
    this.spinner.show();
    this.trainingApprovalService.updateTrainingCalendar(this.workEffortId, {
      ...this.calendarEventForm.value, ...{
        estimatedCompletionDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
        estimatedStartDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Event Updated Successfully');
          this.spinner.hide();
          this.calendarEventForm.reset();
          this.isForm = false;
          this.isUpdate = false;
          this.getEvents();
        }
      });
     
  }
  submit(): void {
    this.spinner.show();
    if (this.isUpdate) {
      this.update();
    } else {

      if (this.calendarEventForm.valid) {
        this.trainingApprovalService.createTrainingCalendar({
          ...this.calendarEventForm.value, ...{
            estimatedCompletionDate: this.calendarEventForm.value.estimatedCompletionDate ? moment(this.calendarEventForm.value.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
            estimatedStartDate: this.calendarEventForm.value.estimatedStartDate ? moment(this.calendarEventForm.value.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Event Created Successfully');
              this.spinner.hide();
              this.calendarEventForm.reset();
              this.isForm = false;
              this.getEvents();
            }
          });
      }
      
    }
  
  }



  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }
   
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];
  
  

  activeDayIsOpen: boolean = true;


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {

    this.workEffortId = event.workEffortId
    this.calendarValue = {
      description: event.description,
      estimatedCompletionDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('YYYY-MM-DD HH:mm:ss') : '',
      estimatedStartDate: event.estimatedStartDate ? moment(event.estimatedStartDate).format('YYYY-MM-DD HH:mm:ss') : '',
      workEffortName: event.workEffortName
    };
    this.isForm = true;
    this.isUpdate = true;
   
    this.calendarEventForm.patchValue({
      description: event.description,
      estimatedCompletionDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',
      estimatedStartDate: event.estimatedStartDate ? moment(event.estimatedStartDate).format('yyyy-MM-DDTHH:mm') : '',
      workEffortName: event.workEffortName
    });
    this.getEventById();
   
  }

  getEventById(): void {
    this.spinner.show();
    this.trainingApprovalService.getCalendarEventById(this.workEffortId)
      .then(data => {
        if (data.success) {
          this.assignList = data.data.participantsList;
        }
      });
      this.spinner.hide();
  }
  addEvent(): void {
    this.isForm = true;
  
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  addNewLeaves(): void {
    this.router.navigateByUrl('/add-new-leaves');
  }
  getTrainingApprovalList(): void {
    this.spinner.show();
    this.trainingApprovalService.getApprovedTrainingList({ pageNo: 1, pageSize: 200 }, {
      ...this.trainingApprovalForm.value, ...{
        fromDateFrom: this.trainingApprovalForm.value.fromDateFrom ? moment(this.trainingApprovalForm.value.fromDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
        fromDateTo: this.trainingApprovalForm.value.fromDateTo ? moment(this.trainingApprovalForm.value.fromDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDateFrom: this.trainingApprovalForm.value.thruDateFrom ? moment(this.trainingApprovalForm.value.thruDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDateTo: this.trainingApprovalForm.value.thruDateTo ? moment(this.trainingApprovalForm.value.thruDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.trainingApprovalList = data.data;
        }
      })
    
  }
  tabbingFunction(tab: number) {
    this.activeTabbing = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
