import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeadSourceModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        comments:(data && data.comments) ? data.comments : null,
        dataSourceId:(data && data.dataSourceId) ? data.dataSourceId : null,
        fromDate:(data && data.fromDate) ? data.fromDate : null,
        isCreate:(data && data.isCreate) ? data.isCreate : null,
        partyId:(data && data.partyId) ? data.partyId : null,
        roleTypeId:(data && data.roleTypeId) ? data.roleTypeId : null,
        visitId:(data && data.visitId) ? data.visitId : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    comments:new FormControl(data.comments),
    dataSourceId:new FormControl(data.dataSourceId),
    fromDate:new FormControl(data.fromDate),
    isCreate:new FormControl(data.isCreate),
    partyId: new FormControl(data.partyId,[Validators.required]),
    roleTypeId: new FormControl(data.roleTypeId),
    visitId: new FormControl(data.visitId),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
