import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreatePaymentServices } from 'src/app/accounting/payments/create-payments/create-payment.service';
import { SortEvent } from 'primeng/api';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { BillingAccountService } from 'src/app/accounting/accounting-ap/billing-account/billing-account.service';
import * as moment from 'moment';
import { AddLeaveEmpService } from 'src/app/human-resource/leave/add-new-leaves/add-new-leaves.service';
import { ToastrService } from 'ngx-toastr';
import { PayablePaymentService } from 'src/app/accounting/accounting-ap/payables-payment/payables-payment.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';

    
    import { Location } from '@angular/common';

@Component({
  selector: 'app-my-portal-create-invoice',
  templateUrl: './my-portal-create-invoice.component.html',
  styleUrls: ['./my-portal-create-invoice.component.css']
})
export class MyPortalCreateInvoiceComponent implements OnInit {

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  amount: any;
  effectiveDate: any;
  partyIdTo: any;
  partyIdFrom: any; PartyRoles: any;
  comments: any;
  statusIdWithDescription: any;
  description: any;
  paymentId: any;
  activeCategory = 1;
  activeTab: number = 2;
  activepayTab: number = 1;
  activeCategories = 2;
  outgoingPaymentForm: FormGroup;
  incomingPaymentForm: FormGroup;
  paymentDetail: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = ""
  updateHeadeForm: FormGroup;
  applicationsForm: FormGroup;
  applicationForm: FormGroup;
  updateBillingAccountForm: FormGroup;
  updateItemsForm: FormGroup;
  addTermsForm: FormGroup;
  addRolesForm: FormGroup;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  finAccountIdrray: any[] = [];
  sendPerEmailForm: FormGroup;
  allStatusList: any = []
  findForm: FormGroup;
  newSalesInvoiceForm: FormGroup;
  updateHeadeOverViewForm: FormGroup;
  updateFinancialAccountTransForm: FormGroup;
  partyId: any;
  finAccountId: any;
  partyIdArray: any[] = [];
  GIAccounts: any[] = [];
  paymentMethodId: any;
  paymentMethodIdrray: any[] = [];
  statusIds: any[];
  invoiceTypes: any[];

  getPurchaseInvoicesData: any;
  paymentTypeList: any[] = [];
  invoices: any;
  PaymentDetailsById: any;
  invoiceDetailsById: any;
  newInvoiceId: any;
  PaymentsList: any;
  getPaymentsListArray: any;
  getProductFeatureIdArray: any[] = [];
  getProductFeatureId: any;
  allPaymentTypeList: any = [];
  allFinAccountIdList: any = [];
  PaymentIDArray: any[] = [];
  PaymentApplication: any;
  PaymentID: any;
  invoiceID: any;
  actualfromdate: string;
  invoiceTerms: any;
  throughtDATE: string;
  allpaymentMethodId: any = [];
  paymentTypeArray: any[] = [];
  PaymentTypeOutgoingList: any[] = [];
  invoiceIDArray: any[] = [];
  CatalogGeoIdArray: any[] = [];
  currencyUomIds: any[];
  PaymentApplicationList: any;
  createBillingAccount: FormGroup;
  CatalogGeoId: any;
  invoiceRoles: any;
  invoiceApplicationList: any;
  billingAccount: any;
  paymentApplicationList: any;
  createRoleForm: FormGroup;
  invoiceId: any;
  proItem: any;
  personId: any;
  searchInvoiceForm: FormGroup;
  showTerm: boolean;
  invoiceTimeEntries: any;
  financialAccountTransaction: any;
  itemTypeIds: any[];
  transactionList: any;
  transactionListStatusTable:any;
  itemsList: any
  PartyRolesArray: any[] = [];
  uomIds: any[];
  invoiceDetailsapplyPayment: any;
  invoiceDetailsRole: any;
  yesNoArray: { label: string; value: string; }[];
  invoiceDetailsStatus: any;
  invoiceTypeAndStatus: any;
  newInvoice: any;
  invoiceTypeAndStatusArray: any[] = [];
  salesInvoiceTypeArray: any[] = [];
  salesInvoiceType: any;
  invoiceItemTypeArray: any[] = [];
  invoiceItemType: any;
  ProductIdArray: any[] = [];
  ProductId: any;
  showItem: boolean;
  appliedApplications: any;
  invoiceItems: any;
  constructor(
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly activatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly mergeContactsService: MergeContactsService,
    readonly _ToastrService: ToastrService,
      private _location: Location,
      readonly datePipe: DatePipe,
    readonly router: Router,
    readonly _AccountingApService: AccountingApService,
    readonly _PayablePaymentService: PayablePaymentService,
    readonly _BillingAccountService: BillingAccountService,
    readonly createPaymentService: CreatePaymentServices,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly myContactsService: MyContactsService,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService) {
    this.invoiceTypes = [];
    this.statusIds = [];

    this.incomingPaymentForm = this._FormBuilder.group({
      orgPartyId: [''],
      fromPartyID: [''],
      paymentType: [''],
      paymentMethodId: [''],
      refNo: [''],
      overrideAccountId: [''],
      Amount: [''],
      Comments: [''],
      FinAccountId: [''],
    })

    this.outgoingPaymentForm = this._FormBuilder.group({
      OutorgPartyId: [''],
      OutfromPartyID: [''],
      OutpaymentType: [''],
      OutpaymentMethodId: [''],
      OutrefNo: [''],
      OutoverrideAccountId: [''],
      OutAmount: [''],
      OutComments: [''],
      OutFinAccountId: [''],
    })

    this.updateHeadeForm = this._FormBuilder.group({
      paymentType: [''],
      statusID: [''],
      paymentMethodID: [''],
      fromPartyID: [''],
      toPartyID: [''],
      amount: [''],
      currency: [''],
      actualCurrencyAmount: [''],
      actualCurrencyUomID: [''],
      effectiveDate: [''],
      referenceNo: [''],
      comments: [''],
      finAccountTransID: [''],
      overrideGlAccountID: [''],

    })
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
    this.applicationsForm = this._FormBuilder.group({
      PaymentID: [''],
      outstandingAmount: [''],
    })
    this.applicationForm = this._FormBuilder.group({
      PaymentID: [''],
      outstandingAmount: [''],
    })
    this.updateBillingAccountForm = this._FormBuilder.group({
      billingAccountID: [''],
      accountLimit: [''],
      accountCurrencyUomID: [''],
      contactMechID: [''],
      fromDate: [''],
      throughDate: [''],
      description: [''],
      externalAccountID: [''],
      partyID: [''],
      availableBalance: [''],
    });

    this.updateItemsForm = this._FormBuilder.group({
      invoiceItemType: [''],
      description: [''],
      overrideGlAccountID: [''],
      inventoryItemID: [''],
      productID: [''],
      productFeatureID: [''],
      quantity: [''],
      UOMs: [''],
      unitPrice: [''],
      taxableFlag: [''],
    });

    this.addRolesForm = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
      dateTimePerformed: [''],
      percentage: [''],


    });

    this.addTermsForm = this._FormBuilder.group({
      invoiceTermID: [''],
      termTypeID: [''],
      itemNo: [''],
      termValue: [''],
      termDays: [''],
      textValue: [''],
      description: [''],
      Uom: [''],

    });

    this.sendPerEmailForm = this._FormBuilder.group({
      fromEmailAddress: [''],
      toEmailAddress: [''],
      copyEmailAddress: [''],
      subject: [''],
      otherCurrency: [''],
      emailBody: [''],

    });

    this.findForm = this._FormBuilder.group({
      fromPartyId: [''],
      toPartyId: [''],
      amount: [''],
      status: [''],
      comments: [''],
      paymentId: [''],
      paymentType: [''],

    });

    this.newSalesInvoiceForm = this._FormBuilder.group({
      invoiceType: [''],
      organizationPartyID: [''],
      toPartyID: [''],
    });

    this.updateHeadeOverViewForm = this._FormBuilder.group({
      invoiceType: [''],
      status: [''],
      description: [''],
      invoiceMessage: [''],
      fromPartyID: [''],
      toPartyID: [''],
      roleTypeID: [''],
      billingAccountID: [''],
      invoiceDate: [''],
      RecurrenceInfoID: [''],
      dueDate: [''],
      ReferenceNumber: [''],
      datePaid: [''],
      referenceNum: [''],
      currencyUomId: ['']

    })

    this.updateFinancialAccountTransForm = this._FormBuilder.group({
      finAccountTransID: [''],
      finAccountTransType: [''],
      amount: [''],
      GIReconciliationID: [''],
      transactionDate: [''],
      status: [''],
    })
    this.createBillingAccount = this._FormBuilder.group({
      accountLimit: [''],
      accountCurrencyUomId: [''],
      contactMechId: [''],
      fromDate: [''],
      thruDate: [''],
      description: [''],
      externalAccountId: [''],
      partyId: [''],
      AvailableBalance: [''],
    });
    this.createRoleForm = this._FormBuilder.group({
      datetimePerformed: [new Date()],
      partyId: [''],
      percentage: [''],
      roleTypeId: ['']
    });
    this.searchInvoiceForm = this._FormBuilder.group({
      billingAccountId: [''],
      description: [''],
      invoiceDate: [''],
      invoiceId: [''],
      invoiceTypeId: [''],
      partyId: [''],
      partyIdFrom: [''],
      fromPartyId: [''],
      referenceNumber: [''],
      statusId: [''],
      toPartyId: [''],

    });
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => [
      this.invoiceId = params["invoiceId"],
    ])
    this.getPartyId();
    //  this.findPurchaseInvoices();
    this.getInvoiceById();
    //  this.getInvoiceTypeAndStatus();
    this.getPaymentType();
    this.getPaymentTypes();
    this.getPaymentTypeOutgoing();
    this.getFinAccountId();
    this.getGIAccounts();
    this.getPaymentMethodId();
    this.getCurrency();
    this.getPaymentApplicationList();
    this.getInvoiceApplicationList();
    this.getInvoiceId();
    this.getCatalogGeoId();
    this.getPaymentID();
    this.getInvoiceRoles();
    this.getInvoiceTerms();
    this.getItemTypeIds();
    this.getUomIds();
    this.getfindPurchaseInvoices();
    this.getItemTypeIds();
    this.getfindPurchaseInvoices();
    this.getInvoiceTypeAndStatus();
    this.getInvoiceTypeAndStatuss();
    this.editInvoiceTimeEntries();
    this.getInvoiceItems();
    this.getAppliedApplications();
    this.getInvoiceItemType();
    this.getProductId();
    this.productFeatureId();
  }
  allApi() {
    this.getInvoiceById();
    this.getPaymentType();
    this.getPaymentTypes();
    this.getPaymentTypeOutgoing();
    this.getPaymentApplicationList();
    this.getInvoiceApplicationList();
    this.getInvoiceRoles();
    this.getInvoiceTerms();
    this.getItemTypeIds();
    this.getfindPurchaseInvoices();
    this.editInvoiceTimeEntries();
    this.getInvoiceItems();
    this.getAppliedApplications();
    this.getAppliedApplications();
  }
  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  productFeatureId() {
    this.spinner.show();
    this.accountsService.productFeatureId().subscribe(res => {
      this.getProductFeatureId = res.data.productFeatureId;
      this.spinner.hide();
      for (const value of this.getProductFeatureId) {
        this.getProductFeatureIdArray.push({
          label: value.productFeatureId,
          value: value.productFeatureId
        })
      }
    })
  }

  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  getInvoiceItemType() {
    this.spinner.show();
    this.createPaymentService.getInvoiceItemType().subscribe(res => {
      this.invoiceItemType = res.data;
      this.spinner.hide();
      for (const value of this.invoiceItemType) {
        this.invoiceItemTypeArray.push({
          label: value.description,
          value: value.invoiceItemTypeId
        })
      }
    })
  }
  getInvoiceItems() {
    this.spinner.show();
    this.createPaymentService.getInvoiceItems(this.invoiceId).subscribe(res => {
      this.invoiceItems = res.data;
      this.spinner.hide();

    })
  }
  deleteInvoiceItem(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.createPaymentService.deleteInvoiceItem(product.invoiceId, product.invoiceItemSeqId).subscribe(res => {
          this.getInvoiceItems();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  updateInvoiceItem() {
    this.spinner.show();
    const requestData = {
      "amount": this.updateItemsForm.value.unitPrice,
      "description": this.updateItemsForm.value.description,
      "inventoryItemId": this.updateItemsForm.value.inventoryItemID,
      "invoiceItemTypeId": this.updateItemsForm.value.invoiceItemType,
      "overrideGlAccountId": this.updateItemsForm.value.overrideGlAccountID,
      "productFeatureId": this.updateItemsForm.value.productFeatureID,
      "productId": this.updateItemsForm.value.productID,
      "quantity": this.updateItemsForm.value.quantity,
      "taxableFlag": this.updateItemsForm.value.taxableFlag,
      "uomId": this.updateItemsForm.value.UOMs,

    }
    this.createPaymentService.updateInvoiceItem(requestData, this.invoiceId, this.proItem.invoiceItemSeqId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.getInvoiceItems();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  resetItem() {
    this.showItem = false;
    this.updateItemsForm.reset();
  }
  updateItem(product) {
    this.showItem = true;
    this.proItem = product;
    setTimeout(() => {
      const formValue = this.updateItemsForm;
      formValue.patchValue({
        invoiceItemType: product.invoiceItemTypeId,
        description: product.description,
        overrideGlAccountID: product.overrideGlAccountId,
        inventoryItemID: product.inventoryItemId,
        productID: product.productId,
        productFeatureID: product.productFeatureId,
        quantity: product.quantity,
        UOMs: product.uomId,
        unitPrice: product.amount,
        taxableFlag: product.taxableFlag,
      })

    }, 2000);
  }
  createInvoiceItem() {
    this.spinner.show();
    const requestData = {
      "amount": this.updateItemsForm.value.unitPrice,
      "description": this.updateItemsForm.value.description,
      "inventoryItemId": this.updateItemsForm.value.inventoryItemID,
      "invoiceItemTypeId": this.updateItemsForm.value.invoiceItemType,
      "overrideGlAccountId": this.updateItemsForm.value.overrideGlAccountID,
      "productFeatureId": this.updateItemsForm.value.productFeatureID,
      "productId": this.updateItemsForm.value.productID,
      "quantity": this.updateItemsForm.value.quantity,
      "taxableFlag": this.updateItemsForm.value.taxableFlag,
      "uomId": this.updateItemsForm.value.UOMs,

    }
    this.createPaymentService.createInvoiceItem(requestData, this.invoiceId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.updateItemsForm.reset();
        this.getInvoiceItems();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onChange(event) {
    console.log(event, "evnt")
    console.log(event.partyId, "evnt party id")
    this.PartyRolesArray = [];
    this.partyId = event;
    if (event) {
      this.getPartyRoles();
    }
    console.log(this.partyId, "par id")

  }
  getPartyRoles() {
    this.spinner.show();
    this.myContactsService.getPartyRoles(this.partyId).subscribe(res => {
      this.PartyRoles = res.data[0].entityQuery;
      this.spinner.hide();
      for (const value of this.PartyRoles) {
        this.PartyRolesArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }
  updateInvoice(product){
    setTimeout(() => {
      const formValue = this.applicationForm;
      formValue.patchValue({
        outstandingAmount: product.amountApplied,
        PaymentID: product.paymentId,
      })

    }, 2000);
  }
  updatePaymentToInvoice() {
    this.spinner.show();
    const requestData = {
      "paymentId": this.applicationForm.value.PaymentID,
      "amountApplied": this.applicationForm.value.outstandingAmount,
    }
    this.createPaymentService.applyPaymentToInvoice(requestData, this.invoiceId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.getAppliedApplications();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
 
  applyPaymentToInvoice() {
    this.spinner.show();
    const requestData = {
      "paymentId": this.applicationsForm.value.PaymentID,
      "amountApplied": this.applicationsForm.value.outstandingAmount
    }
    this.createPaymentService.applyPaymentToInvoice(requestData, this.invoiceId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getAppliedApplications();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  deletePaymentToInvoice(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.createPaymentService.deletePaymentToInvoice(product.paymentApplicationId).subscribe(res => {
          this.getAppliedApplications();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getAppliedApplications() {
    this.spinner.show();
    this.createPaymentService.getAppliedApplications(this.invoiceId).subscribe(res => {
      this.appliedApplications = res.data;
      this.spinner.hide();
    })
  }
  editInvoiceTimeEntries() {
    this.spinner.show();
    this.createPaymentService.editInvoiceTimeEntries(this.invoiceId).subscribe(res => {
      this.invoiceTimeEntries = res.data[0].EditInvoiceData;
      this.spinner.hide();
    })
  }
  unlinkInvoiceFromTimeEntry(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "invoiceId": "",
          "invoiceItemSeqId": "",
          "timeEntryId": product.timeEntryId,
          "viewIndex": "0",
          "viewSize": "20",
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.createPaymentService.unlinkInvoiceFromTimeEntrys(formData).subscribe(res => {
          this.editInvoiceTimeEntries();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  copyInvoiceToTemplate() {
    this.spinner.show();
    const requestData = {
      "invoiceId": this.invoiceId,
      "invoiceTypeId": "PURCHASE_INVOICE"
    }
    this.createPaymentService.copyInvoiceToTemplate(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Success");
        this.spinner.hide();
        this.newInvoiceId = res.data.invoiceId;
        this.router.navigate(['/myPortal/my-portal-create-invoice'], { queryParams: { invoiceId: this.newInvoiceId } });
        this.activeCategories = 3;
        this.allApi();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  copyInvoice() {
    this.spinner.show();
    const requestData = {
      "invoiceIdToCopyFrom": this.invoiceId,
    }
    this.createPaymentService.copyInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Success");
        this.spinner.hide();
        this.newInvoice = res.data.invoiceId;
        this.router.navigate(['/myPortal/my-portal-create-invoice'], { queryParams: { invoiceId: this.newInvoice } });
        this.allApi();



      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  getInvoiceTypeAndStatus() {
    this.spinner.show();
    this.createPaymentService.getInvoiceTypeAndStatus().subscribe(res => {
      this.invoiceTypeAndStatus = res.data.invoiceStatus;
      this.spinner.hide();
      for (const value of this.invoiceTypeAndStatus) {
        this.invoiceTypeAndStatusArray.push({
          label: value.description,
          value: value.statusId
        })
      }

      this.salesInvoiceType = res.data.salesInvoiceType;
      this.spinner.hide();
      for (const value of this.salesInvoiceType) {
        this.salesInvoiceTypeArray.push({
          label: value.description,
          value: value.invoiceTypeId
        })
      }
    })
  }
  resetPerInvoice() {
    this.searchInvoiceForm.reset();
    this.getfindPurchaseInvoices();
  }
  getfindPurchaseInvoices() {
    this.spinner.show();
    const req = {
      "billingAccountId": "",
      "description": "",
      "invoiceDate": "",
      "invoiceId": "",
      "invoiceTypeId": "",
      "partyId": "",
      "partyIdFrom": "",
      "referenceNumber": "",
      "statusId": ""
    }
    this.accountsService.findPurchaseInvoices(req).subscribe((res: any) => {
      this.getPurchaseInvoicesData = res.data;
      this.spinner.hide();

    })
  }
  findPurchaseInvoices(): void {
    this.spinner.show();
    const req = {
      "billingAccountId": this.searchInvoiceForm.value.billingAccountId ? this.searchInvoiceForm.value.billingAccountId : '',
      "description": this.searchInvoiceForm.value.description ? this.searchInvoiceForm.value.description : '',
      "invoiceDate": this.searchInvoiceForm.value.invoiceDate ? this.searchInvoiceForm.value.invoiceDate : '',
      "invoiceId": this.searchInvoiceForm.value.invoiceId ? this.searchInvoiceForm.value.invoiceId : '',
      "invoiceTypeId": this.searchInvoiceForm.value.invoiceTypeId ? this.searchInvoiceForm.value.invoiceTypeId : '',
      "partyId": this.searchInvoiceForm.value.toPartyId ? this.searchInvoiceForm.value.toPartyId : '',
      "partyIdFrom": this.searchInvoiceForm.value.fromPartyId ? this.searchInvoiceForm.value.fromPartyId : '',
      "referenceNumber": this.searchInvoiceForm.value.referenceNumber ? this.searchInvoiceForm.value.referenceNumber : '',
      "statusId": this.searchInvoiceForm.value.statusId ? this.searchInvoiceForm.value.statusId : '',
    }
    this.accountsService.findPurchaseInvoices(req).subscribe(res => {
      if (res.success) {
        this.spinner.hide();
        this.getPurchaseInvoicesData = res.data;
      }
    });

  }
  getInvoiceTerms() {
    this.spinner.show();
    this.createPaymentService.getInvoiceTerms(this.invoiceId, 200, 1).subscribe(res => {
      this.invoiceTerms = res.data;
      this.spinner.hide();

    })
  }
  resetTerm() {
    this.showTerm = false;
    this.addTermsForm.reset();
  }
  updateTerm(product) {
    this.showTerm = true;
    setTimeout(() => {
      const formValue = this.addTermsForm;
      formValue.patchValue({
        invoiceTermID: product.invoiceTermId,
        termTypeID: product.termTypeId,
        itemNo: product.itemNo,
        termValue: product.termValue,
        termDays: product.termDays,
        textValue: product.textValue,
        description: product.description,
        Uom: product.uomId,
      })

    }, 2000);
  }
  updateInvoiceTerm() {
    this.spinner.show();
    const requestData = {
      "description": this.addTermsForm.value.description,
      "invoiceItemSeqId": this.addTermsForm.value.itemNo,
      "invoiceTermId": this.addTermsForm.value.invoiceTermID,
      "termDays": this.addTermsForm.value.termDays,
      "termTypeId": this.addTermsForm.value.termTypeID,
      "termValue": this.addTermsForm.value.termValue,
      "textValue": this.addTermsForm.value.textValue,
      "uomId": this.addTermsForm.value.Uom,

    }
    this.createPaymentService.updateInvoiceTerm(requestData, this.invoiceId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.addTermsForm.reset();
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.getInvoiceTerms();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }

  createInvoiceTerm() {
    this.spinner.show();
    const requestData = {
      "description": this.addTermsForm.value.description,
      "invoiceItemSeqId": this.addTermsForm.value.itemNo,
      "invoiceTermId": this.addTermsForm.value.invoiceTermID,
      "termDays": this.addTermsForm.value.termDays,
      "termTypeId": this.addTermsForm.value.termTypeID,
      "termValue": this.addTermsForm.value.termValue,
      "textValue": this.addTermsForm.value.textValue,
      "uomId": this.addTermsForm.value.Uom,
    }
    this.createPaymentService.createInvoiceTerm(requestData, this.invoiceId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.addTermsForm.reset();
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.getInvoiceTerms();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  getUomIds(): void {
    this.spinner.show();
    this._AccountingApService.getTermType()
      .then(data => {
        this.spinner.hide();
        this.uomIds = data.data.uom.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });

  }
  getItemTypeIds(): void {
    this.spinner.show();
    this._AccountingApService.getTermTypeIds()
      .then(data => {
        this.spinner.hide();
        this.itemTypeIds = data.data.map(value => {
          return {
            label: value.description,
            value: value.termTypeId
          };
        });
      });

  }

  sendInvoicePerMail() {
    this.spinner.show();
    const requestData = {
      "bodyText": this.sendPerEmailForm.value.emailBody,
      "other": this.sendPerEmailForm.value.otherCurrency,
      "sendCc": this.sendPerEmailForm.value.copyEmailAddress,
      "sendFrom": this.sendPerEmailForm.value.fromEmailAddress,
      "sendTo": this.sendPerEmailForm.value.toEmailAddress,
      "subject": this.sendPerEmailForm.value.subject
    }
    this.createPaymentService.sendInvoicePerMail(requestData, this.invoiceId).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Success");
        this.spinner.hide();
        this.sendPerEmailForm.reset();
        this.spinner.hide();
        this.activeCategories = 2;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
    this.spinner.hide();

  }
  deleteInvoiceTerm(invoiceTermId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._AccountingApService.deleteInvoiceTerm(invoiceTermId)
          .then(data => {
            if (data.success) {

              this.getInvoiceTerms();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }



  submitInvoiceRole() {
    this.spinner.show();
    const fromData = {

      "datetimePerformed": this.datePipe.transform(this.createRoleForm.value.datetimePerformed, "yyyy-MM-dd hh:mm:ss"),
      "partyId": this.createRoleForm.value.partyId,
      "percentage": this.createRoleForm.value.percentage,
      "roleTypeId": this.createRoleForm.value.roleTypeId,
    }
    this.createPaymentService.createInvoiceRole(fromData, this.invoiceId).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.createRoleForm.reset();
        this.getInvoiceRoles();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  getInvoiceRoles(): void {
    this.spinner.show();
    this._AccountingApService.getInvoiceRole(this.invoiceId)
      .then(data => {
        this.invoiceRoles = data.data;
        this.spinner.hide();
      });

  }
  deleteInvoiceRole(partyId: string, roleTypeId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._AccountingApService.deleteInvoiceRole(this.invoiceId, partyId, roleTypeId)
          .then(data => {
            if (data.success) {

              this.getInvoiceRoles();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  applyApplicationPayment() {
    this.spinner.show();
    const requestData = {
      "amountApplied": this.applicationsForm.value.outstandingAmount,
      "billingAccountId": this.applicationsForm.value.billingAccountID,
      "invoiceId": this.applicationsForm.value.invoiceID,
      "paymentId": this.paymentId,
      "taxAuthGeoId": this.applicationsForm.value.taxAuthGeoID,
      "toPaymentId": this.applicationsForm.value.topaymentID,
    }

    this.createPaymentService.applyApplicationPayment(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.applicationsForm.reset();
        this.spinner.hide();
        this.getPaymentApplicationList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  getBillingAccountById(product) {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountById(product.billingAccountId)
      .then(data => {
        this.billingAccount = data.data;
        this.createBillingAccount.patchValue({
          ...data.data, ...{
            fromDate: this.datePipe.transform(data.data.fromDate, "yyyy-MM-dd"),
            thruDate: this.datePipe.transform(data.data.thruDate, "yyyy-MM-dd"),
            accountLimit: data.data.accountLimit,
            accountCurrencyUomId: data.data.accountCurrencyUomId,
            contactMechId: data.data.contactMechId,
            description: data.data.description,
            externalAccountId: "",
            partyId: data.data.partyId,
            AvailableBalance: data.data.availableBalance,
          }
        });
      });

  }

  updateBillingAccount() {
    this.spinner.show();
    const fromActualdate = this.createBillingAccount.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    const actualthruDate = this.createBillingAccount.get('thruDate').value;
    this.throughtDATE = this.datePipe.transform(actualthruDate, "yyyy-MM-dd hh:mm:ss");
    const fromData = {

      "accountCurrencyUomId": this.createBillingAccount.value.accountCurrencyUomId,
      "accountLimit": this.createBillingAccount.value.accountLimit,
      "availableBalance": this.createBillingAccount.value.AvailableBalance,
      "contactMechId": this.createBillingAccount.value.contactMechId,
      "description": this.createBillingAccount.value.description,
      "externalAccountId": this.createBillingAccount.value.externalAccountId,
      "fromDate": this.actualfromdate,
      "partyId": this.paymentId,
      "thruDate": this.throughtDATE
    }
    this.createPaymentService.updateBillingAccount(fromData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  getCatalogGeoId() {
    this.spinner.show();
    this.accountsService.getCatalogGeoId().subscribe(res => {
      this.CatalogGeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogGeoId) {
        this.CatalogGeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  getPaymentID() {
    this.spinner.show();
    this.accountsService.getPaymentID().subscribe(res => {
      this.PaymentID = res.data;
      this.spinner.hide();

      for (const value of this.PaymentID) {
        this.PaymentIDArray.push({
          label: value.data.paymentId,
          value: value.data.paymentId
        })
      }
    })
  }
  getInvoiceId() {
    this.spinner.show();
    this.createPaymentService.getInvoiceId().subscribe(res => {
      this.invoiceID = res.data[0].Invoice;
      this.spinner.hide();
      for (const value of this.invoiceID) {
        this.invoiceIDArray.push({
          label: value.invoiceId,
          value: value.invoiceId
        })
      }
    })
  }
  removePaymentApplication(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "amountApplied": product.amountApplied,
          "invoiceId": product.invoiceId,
          "paymentApplicationId": product.paymentApplicationId,
          "paymentId": this.paymentId
        }
        this.createPaymentService.removePaymentApplication(req).subscribe(res => {
          if (res.success) {

            this.getPaymentApplicationList();
          }
        }, (err) => {
          this._ToastrService.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  paymentPage(paymentId) {
    this.router.navigate(['/myPortal/detail-payment-portal-summary'], { queryParams: { paymentId: paymentId } });
  }


  InvoiceAcctgTransEntriesPdf() {
    this.spinner.show();
    this.createPaymentService.InvoiceAcctgTransEntriesPdf(this.invoiceId).subscribe(res => {
      const blob = new Blob([res], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'InvoiceAcctgTransEntriesPdf';
      this.spinner.hide();
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }
  generatePdfMyPortal() {
    this.spinner.show();
    this.createPaymentService.generatePdfMyPortal(this.invoiceId).subscribe(res => {
      const blob = new Blob([res], { type: 'application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'printChecks';
      this.spinner.hide();
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }
  getPaymentApplicationList() {
    this.spinner.show();
    this.createPaymentService.getPaymentApplicationList(this.paymentId).subscribe(res => {
      this.PaymentApplicationList = res.data.applications;
      this.PaymentApplication = res.data;
      this.spinner.hide();

    })
  }
  getInvoiceApplicationList() {
    this.spinner.show();
    this.createPaymentService.getInvoiceApplicationList(this.paymentId).subscribe(res => {
      this.invoiceApplicationList = res.data.invoiceApplications;
      this.spinner.hide();

    })
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyUomIds = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });

  }
  getPaymentMethodId() {
    this.spinner.show();
    this.createPaymentService.getPaymentMethodId().subscribe(res => {
      this.paymentMethodId = res.data;
      this.spinner.hide();
      for (const value of this.paymentMethodId) {
        this.paymentMethodIdrray.push({
          label: value.paymentMethodId,
          value: value.paymentMethodId
        })
      }
    })
  }
  getGIAccounts(): void {
    this.spinner.show();
    this._PayablePaymentService.getGIAccountAndPaymentMethod()
      .then(data => {
        if (data) {
          this.GIAccounts = data.data.glAccounts.map(value => {
            return {
              label: value.glAccountId,
              value: value.glAccountId
            };
          });
        }
      });
    this.spinner.hide();
  }
  getFinAccountId() {
    this.spinner.show();
    this.createPaymentService.getFinAccountId().subscribe(res => {
      this.finAccountId = res.data;
      this.spinner.hide();
      for (const value of this.finAccountId) {
        this.finAccountIdrray.push({
          label: value.finAccountName,
          value: value.finAccountId
        })
      }
    })
  }

  updateSalesInvoiceHeader() {

    this.spinner.show();
    const req = {
      "billingAccountId": this.updateHeadeOverViewForm.value.billingAccountID,
      "currencyUomId": this.updateHeadeOverViewForm.value.currencyUomId,
      "description": this.updateHeadeOverViewForm.value.description,
      "dueDate": this.datePipe.transform(this.updateHeadeOverViewForm.value.dueDate, "yyyy-MM-dd HH:mm:ss"),
      "invoiceDate": this.datePipe.transform(this.updateHeadeOverViewForm.value.invoiceDate, "yyyy-MM-dd HH:mm:ss"),
      "invoiceMessage": this.updateHeadeOverViewForm.value.invoiceMessage,
      "invoiceTypeId": this.updateHeadeOverViewForm.value.invoiceType,
      "partyId": this.updateHeadeOverViewForm.value.toPartyID,
      "partyIdFrom": this.updateHeadeOverViewForm.value.fromPartyID,
      "recurrenceInfoId": this.updateHeadeOverViewForm.value.RecurrenceInfoID,
      "referenceNumber": this.updateHeadeOverViewForm.value.referenceNum,
      "roleTypeId": this.updateHeadeOverViewForm.value.roleTypeID,
      "statusId": this.updateHeadeOverViewForm.value.status,
    }
    this.createPaymentService.updateSalesInvoiceHeader(req, this.invoiceId).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.getInvoiceById();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });
  }

  getPaymentTypeOutgoing() {
    this.spinner.show();
    this.createPaymentService.getPaymentTypeOutgoing().subscribe(res => {
      if (res.success) {
        const PaymentTypeOutgoing = res.data;
        for (const value of PaymentTypeOutgoing) {
          this.PaymentTypeOutgoingList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }
      }

    }, (err) => {
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  getPaymentTypes() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const allStatusList = res.data.status;
        const finAccountIdList = res.data.finAccountId;
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        }
        for (const value of allStatusList) {
          this.allStatusList.push({
            label: value.description,
            value: value.statusId
          })
        }

      }

    }, (err) => {
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  createPayment() {
    this.router.navigate(["/myPortal/my-portal-create-new-invoice"])
  }
  getPaymentType(): void {
    this.spinner.show();
    this._PayablePaymentService.getPaymentType()
      .then(data => {
        if (data) {
          this.spinner.hide();
          this.paymentTypeList = data.data.map(value => {
            return {
              label: value.description,
              value: value.paymentTypeId
            };
          });
        }
      });

  }


  getInvoiceById() {
    this.spinner.show();
    this.createPaymentService.getInvoiceSummary(this.invoiceId).subscribe(res => {
      this.invoiceDetailsById = res.data;
      this.invoiceDetailsStatus = res.data.statusId;
      this.invoiceDetailsRole = res.data.roles;
      this.transactionList = res.data.transactions;
      this.transactionListStatusTable = res.data.status;
      this.itemsList = res.data.items;
      this.invoiceDetailsapplyPayment = res.data.appliedPayments.invoiceApplications;

      setTimeout(() => {
        const formValue = this.updateHeadeOverViewForm;
        formValue.patchValue({
          invoiceType: this.invoiceDetailsById.invoiceType,
          status: this.invoiceDetailsById.statusId,
          description: this.invoiceDetailsById.description,
          invoiceMessage: this.invoiceDetailsById.invoiceMessage,
          fromPartyID: this.invoiceDetailsById.partyIdFrom,
          toPartyID: this.invoiceDetailsById.partyId,
          roleTypeID: this.invoiceDetailsById.roleTypeId,
          billingAccountID: this.invoiceDetailsById.billingAccountId,
          invoiceDate: this.datePipe.transform(this.invoiceDetailsById.invoiceDate, "yyyy-MM-dd"),
          dueDate: this.datePipe.transform(this.invoiceDetailsById.dueDate, "yyyy-MM-dd"),
          total: this.invoiceDetailsById.total,
          datePaid: this.datePipe.transform(this.invoiceDetailsById.paidDate, "yyyy-MM-dd"),
          referenceNum: this.invoiceDetailsById.referenceNumber,

        })

      }, 2000);

      this.spinner.hide();
    })
  }
  setPaymentStatusInProcess(): void {
    this.spinner.show();
    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_IN_PROCESS").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusPaid(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_PAID").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusApprove(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_APPROVED").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusWriteoff(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_WRITEOFF").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusVoid(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "PMNT_VOID").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusCancel(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_CANCELLED").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusReceived(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_RECEIVED").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusReady(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_READY").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  setPaymentStatusSent(): void {
    this.spinner.show();

    this.createPaymentService.setInvoiceStatus(this.invoiceId, "INVOICE_SENT").subscribe(res => {
      if (res.success) {
        this._ToastrService.success("Status Updated Successfully");
        this.spinner.hide();
        this.getInvoiceById();
      }
    });

  }
  // findPurchaseInvoices(): void {
  //   this.spinner.show();
  //   const req = {
  //     "billingAccountId": "",
  //     "description": this.findForm.value.description ? this.findForm.value.description : '',
  //     "invoiceDate": this.findForm.value.invoiceDate ? this.findForm.value.invoiceDate : '',
  //     "invoiceId": this.findForm.value.invoiceID ? this.findForm.value.invoiceID : '',
  //     "invoiceTypeId": this.findForm.value.invoiceType ? this.findForm.value.invoiceType : '',
  //     "partyId": this.findForm.value.toPartyID ? this.findForm.value.toPartyID : '',
  //     "partyIdFrom": this.findForm.value.fromPartyID ? this.findForm.value.fromPartyID : '',
  //     "referenceNumber": this.findForm.value.referenceNumber ? this.findForm.value.referenceNumber : '',
  //     "statusId": this.findForm.value.statusID ? this.findForm.value.statusID : '',
  //   }
  //   this.accountsService.findPurchaseInvoices(req).subscribe(res => {
  //     if (res.success) {
  //       this.spinner.hide();
  //       this.getPurchaseInvoicesData = res.data[0].invoice;
  //     }
  //   });
  // }


  getInvoiceTypeAndStatuss(): void {
    this.spinner.show();
    this._AccountingApService.getInvoiceTypeAndStatus()
      .then(data => {
        this.spinner.hide();
        this.statusIds = data.data.invoiceStatus.map(value => {
          return {
            label: value.description,
            value: value.statusId
          };
        });
        this.invoiceTypes = data.data.invoiceType.map(value => {
          return {
            label: value.description,
            value: value.invoiceTypeId
          };
        });
      });

  }
  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  // getPaymentDetail() {
  //   this.spinner.show();
  //   this.accountsService.getDetailsByPaymentId(this.paymentId).subscribe((res: any) => {
  //     this.paymentDetail = res.data;
  //     console.log("this.paymentDetail", this.paymentDetail)
  //   })

  // }
 
  changeactiveCategoryFunction(tab: number) {
    this.activeTab = tab;
  }
  changeactivepaymentTab(tab: number) {
    this.activepayTab = tab
  }

  changeactiveCategory(tab: number) {
    this.activeCategories = tab

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}