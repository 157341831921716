import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-response',
  templateUrl: './find-response.component.html',
  styleUrls: ['./find-response.component.css']
})
export class FindResponseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
