<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product Backlog </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="Backlog()"><a>
                                Product Backlog </a></li>
                        <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==2?'active':''" (click)="Sprint()"><a>
                                Project/Sprint
                            </a></li>
                        <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==3?'active':''" (click)="MyWork()"><a>
                                My Work</a></li>
                        <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==4?'active':''" (click)="Tasks()"><a>
                                Tasks
                            </a></li>
                        <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==5?'active':''" (click)="OpenTest()"><a>
                                Open Test
                            </a></li>
                        <li *ngIf="!this.Hidebtn" [ngClass]="activeCategory==6?'active':''" (click)="Revision()"><a>
                                Revision
                            </a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 pt-0 mt-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div class="w3-card-4  w-100 mt-3">
                                                    <div class="accordion classCard" id="myAccordion">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                    <span class="headingAccords"> Find Product 
                                                                    </span>
                                                                   
                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                    data-toggle="modal" data-target="#exampleModalCenter">Create New
                                                                    Product</button>
                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 72%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
                        
                                                                </div>
                            
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                <div class="card-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">


                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Product ID
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.ProductID" name="ProductID" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder=" Enter Product ID">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputAB">
                                                                                    <label for="exampleInputEmail1">Product Name
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                
                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductName" name="ProductName" aria-describedby="emailHelp" placeholder="Enter Product Name">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                
                
                
                                                                        
                
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Status ID
                                                                                    </label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="statusArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.StatusId" name="StatusId" optionlabel="label" placeholder="Choose Status ID">
                                                                                    </p-dropdown>
                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                
                                                                    </div>

                                                                    <div class="col-lg-12  main-submit-button" style="
                                                                                margin-left: -9%;">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="getFindProduct()">Find</button>&nbsp;

                                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <!-- <div class="w3-card-4 classCardA mt-3">
                                                    <div class="container-fluid">
                                                        <ul class="tabbing-section tabbing-accordians tabClass" style="width: 102% !important;
                                    flex-wrap: wrap !important;
                                    display: flex !important;">
                                                            <li><a>
                                                                Find Product
 
                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                        data-toggle="modal" data-target="#exampleModalCenter">Add
                                                                        Product</button>
                                                                </a>
                                                            </li>

                                                        </ul>
                                                    </div>



                                                    <div class="all-users-infomation font-13 font-medium own-user-select">


                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="advanceSearch.ProductID" name="ProductID" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder=" Enter Product ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputAB">
                                                                    <label for="exampleInputEmail1">Product Name
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">

                                                                    <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductName" name="ProductName" aria-describedby="emailHelp" placeholder="Enter Product Name">
                                                                </div>
                                                            </div>
                                                        </div>



                                                        

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Status ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" [options]="statusArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.StatusId" name="StatusId" optionlabel="label" placeholder="Choose Status ID">
                                                                    </p-dropdown>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -9%;">
                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="getFindProduct()">Find</button>&nbsp;

                                                        <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                                    </div>



                                                </div> -->

                                                <div class="w3-card-4 classCard mt-3">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Product List
                                                            </h4>
                                                        </div>
                                                    </header>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="productList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                        </p-checkbox>

                                                                                        Owner Company
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Product Name
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Description
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>





                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'250px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>
                                                                                        <span (click)="myProfilePage(product.productRolePartyIds.partyId)"  class="account-button">
                                                                                            {{product.fromid.groupName + "[" + product.productRolePartyIds.partyId + "]"}}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px','color':'#0d3769 !important','cursor': 'pointer'}" 
                                                                                (click)="detailedPage(product.ProductNames.productId,product.ProductNames.internalName,product.fromid.groupName,product.productRolePartyIds.partyId,product.Status,product.ProductNames.longDescription)">
                                                                                    <span  class="account-second-button">{{product.ProductNames.productId}}</span>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                                    <span>{{product.ProductNames.internalName}}</span>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <span *ngIf="product.ProductNames">{{product.ProductNames.longDescription}}</span>

                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.Status}}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Product</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Product</a></li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="productForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Company and Product Owner
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Company and Product Owner"
                                                        formControlName="CompanyandProductOwner" [options]="CompanyandProductOwnerArray">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId">Product Name
                                                            <span style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                       placeholder="Enter Product Name " aria-describedby="emailHelp" formControlName="ProductName" >

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>

                                                    <!-- <div
                                                        class="col-lg-3 form-group classInputA">
                                                        <label
                                                            for="exampleInputEmail1">Party
                                                            ID
                                                        </label>

                                                    </div>

                                                    <div
                                                        class="col-lg-2">

                                                        <p-dropdown
                                                            filter="true"
                                                            [options]="partyIdArray"
                                                            formControlName="partyId"
                                                            optionlabel="label"
                                                            placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div> -->

                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -11%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="editorAddProductBacklogs()">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>