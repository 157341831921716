import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-create-credit-memo',
    templateUrl: './create-credit-memo.component.html',
    styleUrls: ['./create-credit-memo.component.css']
})
export class CreateCreditMemoComponent implements OnInit {
    partyIds: any[];
    activeCategory = 2;
    createCreditMemoForm: FormGroup;
    currencyList: any[];
    invoiceId: string;
    editMode: boolean;
    creditMemo: any;
    postalAddress: any[];

    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        }
    ];
    constructor(
        readonly _ToastrService: ToastrService,
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _AccountingApService: AccountingApService
    ) {
        this.partyIds = [];
        this.createCreditMemoForm = this._FormBuilder.group({
            currencyUomId: ['', [Validators.required]],
            description: [''],
            dueDate: [new Date()],
            invoiceDate: [new Date()],
            invoiceMessage: [''],
            partyId: ['Company', [Validators.required]],
            partyIdFrom: [''],
            referenceNumber: [''],
            contactMechId: [''],
            shippingContactMechId: ['']
        });
        this.currencyList = [];
        this.invoiceId = '';
        this.editMode = false;
        this.postalAddress = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        if (this.invoiceId) {
            this.editMode = true;
            this.creditMemoSummary();
        }
        this.getPartyIds();
        this.getCurrencyList();
    }
    creditMemoSummary(): void {
        this.spinner.show();
        this._AccountingApService.creditMemoSummary(this.invoiceId)
            .then(data => {
                this.creditMemo = data.data;
                this.spinner.hide();
                this.createCreditMemoForm.patchValue({
                    ...data.data, ...{
                        dueDate: new Date(data.data.dueDate),
                        invoiceDate: new Date(data.data.dueDate)
                    }
                });
                this.getPostalAddress(data.data.partyId);
            });
           
    }
    getPostalAddress(partyId: string): void {
        this.spinner.show();
        this._AccountingApService.creditMemoPostalAddress(partyId)
            .then(data => {
                this.spinner.hide();
                this.postalAddress = data.data.map(value => {
                    return {
                        label: value.address1,
                        value: value.contactMechId
                    };
                });
              
            });
           
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getSupplierList()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
           
    }
    getCurrencyList(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyList = data.data.currencyList.map(value => {
                    return {
                        label: value.uomId,
                        value: value.uomId
                    };
                });
            });
          
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateCreditMemo({
            ...this.creditMemo, ...this.createCreditMemoForm.value, ...{
                dueDate: moment(this.createCreditMemoForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss'),
                invoiceDate: moment(this.createCreditMemoForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss'),
                oldRefNum: this.createCreditMemoForm.value.referenceNumber,
                referenceNumber: this.createCreditMemoForm.value.referenceNumber
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.createCreditMemoForm.reset();
                    this.spinner.hide();
                    this._Router.navigate(['/financial/payable/credit-memo']);
                }
            });
           
    }

    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            delete this.createCreditMemoForm.value.contactMechId;
            delete this.createCreditMemoForm.value.shippingContactMechId;
            this._AccountingApService.createCreditMemo({
                ...this.createCreditMemoForm.value, ...{
                    dueDate: moment(this.createCreditMemoForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss'),
                    invoiceDate: moment(this.createCreditMemoForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.createCreditMemoForm.reset();
                        this._Router.navigate(['/financial/payable/credit-memo']);
                    }
                });
        }
        
    }
    reset(): void {
        this.createCreditMemoForm.reset();
        this._Router.navigate(['/financial/payable/credit-memo']);
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
    
}