import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import{LabelTypesService} from 'src/app/warehouse/label-types/label-types.service'

@Component({
  selector: 'app-detail-label-type-summary',
  templateUrl: './detail-label-type-summary.component.html',
  styleUrls: ['./detail-label-type-summary.component.css']
})
export class DetailLabelTypeSummaryComponent implements OnInit {
  hasTableArray=[{
    'label':'Y',
    'value':'Y'
  },
  {
    'label':'N',
    'value':'N'
  }]
  activeCategory=1;
  id: any;
  @ViewChild('closebutton') closebutton;
  groupid: any;
  parentId: any;
  fGroupName: any;
  des: any;
  hTable: any;
  date: any;
  labelid: any;
  editMode: boolean;
  inventoryLabelTypes:FormGroup;
  InventoryItemLabelTypeList: any = [];
  parentTypeIdList: any = [];

  parentTypeIdListArray: any[]=[];
  

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly accountsService: AccountsService,
    readonly router: Router,
   
    readonly LabelTypesService: LabelTypesService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder) {
    this.inventoryLabelTypes = this._FormBuilder.group({
      inventoryItemLabelTypeId: ['', [Validators.required]],
      parentTypeId: [null],
      hasTable: [null],
      description: [''],
    });}

  ngOnInit(): void {
   this.editMode=true;
    this.activatedRoute.queryParams.subscribe(params=> [
      this.labelid = params["id"],
      this.parentId=params["parentId"],
      this.hTable=params["hTable"],
      this.des=params["des"],
      this.date=params["date"],
    ])
    this.getInventoryItemLabelTypeList();
    this.getParentTypeId();
  }
  getInventoryItemLabelTypeList() {
    this.spinner.show();
    this.InventoryItemLabelTypeList = [];
    this.LabelTypesService.getInventoryItemLabelTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.InventoryItemLabelTypeList = responce.data;
    });
   
  }
 
  getParentTypeId() {
    this.spinner.show();
    this.LabelTypesService.getParentTypeId().subscribe((responce) => {
      this.spinner.hide();
      this.parentTypeIdList = responce.data;
      for(const value of this.parentTypeIdList) {
        this.parentTypeIdListArray.push({
          label:value.inventoryItemLabelTypeId,
          value:value.inventoryItemLabelTypeId
        })
      }
    });

  }

  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      var id;
      this.LabelTypesService.updateInventoryItemLabelType({ ...this.inventoryLabelTypes.value }).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.inventoryLabelTypes.reset();
          this.getInventoryItemLabelTypeList();
          this.closebutton.nativeElement.click();
          this.router.navigate(["facilities/inventory-labels/label-types"])
          // this.router.navigate(['/facilities/facility-group']);
        }

      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.error) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
  
    } else {
      this.LabelTypesService.createInventoryItemLabelType(this.inventoryLabelTypes.value).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.inventoryLabelTypes.reset();
          this.getInventoryItemLabelTypeList();
          this.closebutton.nativeElement.click();

        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
 
  }

  getData() {
    this.editMode = true;

    this.inventoryLabelTypes.patchValue({
      inventoryItemLabelTypeId: this.labelid,
      parentTypeId: this.parentId,
      hasTable: this.hTable,
      description: this.des,
    });
  }
  cancelSubmit(){
    this.router.navigate(["facilities/inventory-labels/label-types"])
  }

}
