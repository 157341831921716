
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
              <span class="color-black pl-1 titlePanels">Catalog</span>
               <span class="color-grey search-icons">
                   <div class="search-option">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.361" height="20.361" viewBox="0 0 20.361 20.361"><defs><style>.a{fill:#65787e;}</style></defs><g transform="translate(-4.02 -4.02)"><g transform="translate(4.02 4.02)"><path class="a" d="M24.179,23,18.83,17.652a8.337,8.337,0,1,0-1.178,1.178L23,24.179A.836.836,0,0,0,24.179,23ZM7.631,17.092a6.645,6.645,0,1,1,4.726,1.974,6.686,6.686,0,0,1-4.726-1.974Z" transform="translate(-4.02 -4.02)"/></g></g></svg>
                    <input class="form-control" type="text" placeholder="Search"> 
                   </div>
                   <div class="search-option filter-bar">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                    <input class="form-control" type="text" placeholder="Filters">
                   </div>
                   <div class="search-option group-by">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.4" height="16.866" viewBox="0 0 18.4 16.866"><defs><style>.a{fill:#65787e;}</style></defs><g transform="translate(0 -17.067)"><g transform="translate(0 17.067)"><g transform="translate(0 0)"><path class="a" d="M17.633,17.067H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z" transform="translate(0 -17.067)"/></g></g><g transform="translate(0 24.734)"><g transform="translate(0 0)"><path class="a" d="M17.633,187.733H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z" transform="translate(0 -187.733)"/></g></g><g transform="translate(0 32.4)"><g transform="translate(0 0)"><path class="a" d="M17.633,358.4H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z" transform="translate(0 -358.4)"/></g></g></g></svg>
                    <input class="form-control" type="text" placeholder="Group By">
                   </div>
                   <div class="search-option Favourites-bar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.588" height="22.416" viewBox="0 0 23.588 22.416"><g transform="translate(-9.8 -11.3)"><g transform="translate(9.8 11.3)"><path d="M25.213,18.684,21.579,11.3l-3.633,7.384L9.8,19.856,15.69,25.6l-1.377,8.117,7.267-3.839,7.3,3.839L27.5,25.6l5.89-5.743Zm2.08,12.893-5.714-3.018-5.714,3.018,1.084-6.388-4.63-4.513,6.388-.938,2.872-5.773,2.872,5.8,6.388.938-4.63,4.483Z" transform="translate(-9.8 -11.3)"/></g></g></svg>
                    <input class="form-control" type="text" placeholder="Favourites">
                   </div>
                </span>
           </div>
           <div class=" bg-white color-grey">
            <div class="port-header-contact create-lead font-medium mb-0">
             Make a Payment
          </div>
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
                <div class="row">
                    <form class="w-100">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-4 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Make a Payment to Party ID</label>
                                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                </div>
                            </div>
                               
                        </div>
                        <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Countinue</button></div>
                        
                     </form>
  
                </div>
            </div>
        </div>
           </div>
        </div>
    </div>
  </div>
  
  
  