
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UpdateEventModel } from './detail-event-model';

@Injectable({
  providedIn: 'root'
})


export class CreateEventFormService {
  update_event_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public updateEventModel: UpdateEventModel
  ) { }

  updateEventForm(data: any): void {
    if (!this.update_event_form) {
      this.update_event_form = this._formBuilder.group(this.updateEventModel.mapDataToModel(data));

    }

  }

}



