<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;"> 
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Summary</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
    <div class="create-new-leade mt-3">
        <div class="" style="padding: 0px 0px; margin-bottom: 5%!important;">
            <div class="col-md-12 p-0" >
                    <div class="row">
                        <form class="w-100">
                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                <div class="w3-card-4 classCard w-100">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                        style="justify-content: space-between;">
                                        <p class="h-text">Summary</p>
                                        <span>
                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                          (click)="generatePDFCMS()">Download</button>

                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                style="margin: 0;" data-toggle="modal"
                                                data-target="#creatContentCMSPopup">Update</button>
                                        </span>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                        <div class="color-black container" *ngIf="editAddContentData">
                                            <div>
                                                <label class="font mt-2">Content Assoc</label>
                                            </div>
                                            <div class="row">

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Content ID To</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.caContentIdTo}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Map Key	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.caMapKey}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Content Assoc Type ID</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.caContentAssocTypeId }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Content Assoc Predicate ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.caContentAssocPredicateId}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">From Date	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.caFromDate| date:"yyyy-MM-dd hh:mm:ss"}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Through Date</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.caThruDate | date:"yyyy-MM-dd hh:mm:ss"}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div>
                                                <label class="font mt-2">Content	</label>

                                            </div>
                                            <div class="row">

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Content ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.caContentIdTo}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Template Data Resource ID	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.templateDataResourceId}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Content Type ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.contentTypeId}} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Owner Content ID	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.ownerContentId}}   </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Content Name		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.contentName}}  </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Description		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.description}}  </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Mime Type ID		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.mimeTypeId}}   </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Character Set ID		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.characterSetID1}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Locale String		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.localeString1}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                	
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Status ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.statusId}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Privilege Enum ID</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.privilegeEnumId}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div>
                                                <label class="font mt-2">Data Resource	</label>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Data Resource ID	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.dataResourceId}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Dr Data Resource Type ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.drDataResourceTypeId}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">File Path		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.drObjectInfo}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Dr Data Template Type ID</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.drDataTemplateTypeId}}    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Dr Mime Type ID		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.drMimeTypeId}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Character Set ID		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.characterSetID}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Locale String		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.localeString}}   </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Data Source</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.drDataSourceId}}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Data Category	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.drDataCategoryId}}      </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <label class="font mt-2">Text</label>
                                            </div>

                                            <div class="row"> 
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Text Data	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.textData}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <label class="font mt-2">Image</label>
                                            </div>

                                            <div class="row"> 
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Image Data	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.imageData}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Created By User	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.createdByUserLogin}}  </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Created Date		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.createdDate| date:"yyyy-MM-dd hh:mm:ss"}}    </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Last Modified By User		</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.lastModifiedByUserLogin}} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Last Modified Date</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.editAddContentData.lastModifiedDate | date:"yyyy-MM-dd hh:mm:ss"}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                              
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

               
             
                </div>
            </div>
        </div>
 </div> 
 <div class="modal fade" id="creatContentCMSPopup" tabindex="-1" role="dialog" aria-labelledby="creatContentCMSPopup"
 aria-hidden="true">
 <div class="modal-dialog" role="document">
     <div class="modal-content cssModelLength">
         <div class="modal-header">

             <span class="color-black pl-1">CMS Content

             </span>
             <!-- <span *ngIf="this.show" class="color-black pl-1"> Update New Technician</span> -->
             <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                 <span aria-hidden="true" #closebutton2>&times;</span>
             </button>
         </div>
         <div class="modal-body d-flex">
             <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                 <div class=" bg-white color-grey" style="width: 100%;">
                     <div class="container-fluid">
                         <ul class="tabbing-section tabbing-accordians tabClass">

                             <li [ngClass]="customerCategoryPopup==1?'active':''">
                                 <a>
                                     Content Assoc</a>
                             </li>
                             <li [ngClass]="customerCategoryPopup==2?'active':''">
                                 <a>
                                     Content </a>
                             </li>

                             <li [ngClass]="customerCategoryPopup==3?'active':''"><a>
                                     Data Resource</a></li>

                             <li [ngClass]="customerCategoryPopup==4?'active':''"><a>
                                     Text</a></li>

                             <li [ngClass]="customerCategoryPopup==5?'active':''"><a>
                                     Image </a></li>




                         </ul>
                     </div>
                     <div class="create-new-leade mt-2">
                         <div class="container-fluid">
                             <div class="row">
                                 <form [formGroup]="cmsContentForm" class="w-100">
                                     <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                         <div class="w-100">
                                             <div class="panel-group" id="accordion" role="tablist"
                                                 aria-multiselectable="true">

                                                 <div *ngIf="customerCategoryPopup==1">
                                                     <div class="header-tabs">
                                                         <h4 class=" common-styling">
                                                             Content Assoc
                                                         </h4>


                                                     </div>
                                                     <div class="panel-body">
                                                         <div
                                                             class="all-users-infomation font-13 font-medium own-user-select">

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Content ID
                                                                             To</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="contentIDTo"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Content ID To">

                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Map
                                                                             Key</label>
                                                                     </div>

                                                                     <div class="col-lg-2">

                                                                         <input type="email" formControlName="mapKey"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Map Key">



                                                                     </div>
                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Content
                                                                             Assoc Type ID
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">

                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             formControlName="contentAssocTypeID"
                                                                             [options]="ContentAssocTypeDataArray"
                                                                             placeholder="Select Content Assoc Type ID">

                                                                         </p-dropdown>


                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Content
                                                                             Assoc Predicate ID
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             [options]="DataPredicateArray"
                                                                             formControlName="contentAssocPredicateID"
                                                                             placeholder="Select Content Assoc Predicate ID">

                                                                         </p-dropdown>
                                                                     </div>


                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">From
                                                                             Date</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="date"
                                                                             formControlName="fromDate"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             >

                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Through Date
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="date"
                                                                             formControlName="throughDate"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             >


                                                                     </div>


                                                                 </div>
                                                             </div>



                                                         </div>
                                                         <div class="main-submit-button" style="margin-right: 10%;">
                                                             <button type="submit"
                                                                 class="btn btn-secondary submit-btn"
                                                                 (click)="customerActiveCategoryFunction(2)">Continue</button>

                                                         </div>
                                                     </div>

                                                 </div>
                                                 <div *ngIf="customerCategoryPopup==2">
                                                     <div class="header-tabs">
                                                         <h4 class=" common-styling">
                                                             Content
                                                         </h4>


                                                     </div>
                                                     <div class="panel-body">
                                                         <div
                                                             class="all-users-infomation font-13 font-medium own-user-select">
                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Content ID
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="contentID"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Content ID">
                                                                     </div>
                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Template
                                                                             Data Resource ID
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             [options]="dataTemplateTypeArray"
                                                                             formControlName="templateDataResourceID"
                                                                             placeholder="Select Template Data Resource ID">

                                                                         </p-dropdown>
                                                                     </div>
                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Content Type
                                                                             ID </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             [options]="contentTypeIdDataArray"
                                                                             formControlName="contentTypeID"
                                                                             placeholder="Select Content Type ID">

                                                                         </p-dropdown>
                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Owner
                                                                             Content ID
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label" 
                                                                             [options]="CatalogContentIdArray"
                                                                             formControlName="ownerContentID"
                                                                             placeholder="Select Owner Content ID">
                                                                         </p-dropdown>
                                                                     </div>
                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Content
                                                                             Name</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="contentName"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Content Name">

                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Description
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                     <input type="email"
                                                                         formControlName="description"
                                                                         class="form-control"
                                                                         id="exampleInputEmail1"
                                                                         aria-describedby="emailHelp"
                                                                         placeholder="Enter description">
                                                                        

                                                                     </div>
                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Mime Type
                                                                             ID</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             [options]="mimeTypeIdDataArray"
                                                                             formControlName="mimeTypeID"
                                                                             placeholder="Select Mime Type ID">
                                                                         </p-dropdown>
                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Character
                                                                             Set ID</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             [options]="CharacterSetIdArray"
                                                                             formControlName="characterSetID"
                                                                             placeholder="Select Character Set ID">
                                                                         </p-dropdown>
                                                                     </div>
                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Locale
                                                                             String
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             formControlName="localeString"
                                                                             placeholder="Select Locale String">
                                                                         </p-dropdown>
                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Status ID
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             formControlName="statusID" placeholder="Select Status ID	
                                                     ">
                                                                         </p-dropdown>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Privilege
                                                                             Enum ID</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label"
                                                                             formControlName="privilegeEnumID"
                                                                             placeholder="Select Privilege Enum ID ">
                                                                         </p-dropdown>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                         <div class="main-submit-button" style="margin-right:9%;">
                                                             <button type="submit"
                                                                 (click)="customerActiveCategoryFunction(1)"
                                                                 class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                             <button type="submit"
                                                                 class="btn btn-secondary submit-btn"
                                                                 (click)="customerActiveCategoryFunction(3)">Continue</button>

                                                         </div>
                                                     </div>

                                                 </div>


                                                 <div *ngIf="customerCategoryPopup==3">
                                                     <div class="header-tabs">
                                                         <h4 class=" common-styling">
                                                             Data Resource
                                                         </h4>


                                                     </div>
                                                     <div class="panel-body">
                                                         <div
                                                             class="all-users-infomation font-13 font-medium own-user-select">

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Data
                                                                             Resource ID
                                                                         </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label" [options]="DataResourceIdArray"
                                                                             formControlName="dataResourceID"
                                                                             placeholder="Select Data Resource ID	
                                                     ">

                                                                         </p-dropdown>
                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Dr Data
                                                                             Resource Type ID</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label" [options]="resourceTypeDROPArray"
                                                                             formControlName="drDataResourceTypeID"
                                                                             placeholder="Select Dr Data Resource Type ID	
                                                     ">

                                                                         </p-dropdown>
                                                                     </div>




                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">File
                                                                             Path</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="text"
                                                                             formControlName="filePath"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter File Path">

                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Dr Data
                                                                             Template Type ID</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label" [options]="dataTemplateTypeArray"
                                                                             formControlName="drDataTemplateTypeID"
                                                                             placeholder="Select Dr Data Template Type ID	
                                                     ">

                                                                         </p-dropdown>

                                                                     </div>




                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Dr Mime Type
                                                                             ID </label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label" [options]="mimeTypeIdDataArray"
                                                                             formControlName="drMimeTypeID"
                                                                             placeholder="Select Dr Mime Type ID">

                                                                         </p-dropdown>
                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Character
                                                                             Set ID</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <p-dropdown filter="true"
                                                                             optionlabel="label" [options]="CharacterSetIdArray"
                                                                             formControlName="characterSetID1"
                                                                             placeholder="Select Character Set ID">

                                                                         </p-dropdown>

                                                                     </div>




                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Locale
                                                                             String</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="localeString1"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Locale String">

                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Data
                                                                             Source</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="dataSource"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Data Source">
                                                                     </div>




                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Data
                                                                             Category</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="dataCategory"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Data Category">

                                                                     </div>





                                                                 </div>
                                                             </div>



                                                         </div>
                                                         <div class="main-submit-button" style="margin-right: 9%;">
                                                             <button type="submit"
                                                                 (click)="customerActiveCategoryFunction(2)"
                                                                 class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                             <button type="submit"
                                                                 class="btn btn-secondary submit-btn"
                                                                 (click)="customerActiveCategoryFunction(4)">Continue</button>

                                                         </div>
                                                     </div>

                                                 </div>

                                                 <div *ngIf="customerCategoryPopup==4">
                                                     <div class="header-tabs">
                                                         <h4 class=" common-styling">
                                                             Text
                                                         </h4>


                                                     </div>
                                                     <div class="panel-body">
                                                         <div
                                                             class="all-users-infomation font-13 font-medium own-user-select">


                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Text
                                                                             Data</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="textData"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Text Data">

                                                                     </div>





                                                                 </div>
                                                             </div>





                                                         </div>
                                                         <div class="main-submit-button" style="margin-right: 9%;">
                                                             <button type="submit"
                                                                 (click)="customerActiveCategoryFunction(3)"
                                                                 class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                             <button type="submit"
                                                                 class="btn btn-secondary submit-btn"
                                                                 (click)="customerActiveCategoryFunction(5)">Continue</button>

                                                         </div>
                                                     </div>

                                                 </div>

                                                 <div *ngIf="customerCategoryPopup==5">
                                                     <div class="header-tabs">
                                                         <h4 class=" common-styling">
                                                             Image
                                                         </h4>


                                                     </div>
                                                     <div class="panel-body">
                                                         <div
                                                             class="all-users-infomation font-13 font-medium own-user-select">




                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Image
                                                                             Data</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="file" class="form-control"
                                                                             id="exampleInputEmail1" 
                                                                             formControlName="imageData"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Image Data">
                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Created By
                                                                             User</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="createdByUser"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Created By User">

                                                                     </div>





                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Created
                                                                             Date</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="date"
                                                                             formControlName="createdDate"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             >

                                                                     </div>

                                                                     <div class="col-lg-3 form-group rightFormA">
                                                                         <label for="exampleInputEmail1">Last
                                                                             Modified By User</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="email"
                                                                             formControlName="lastModifiedByUser"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             placeholder="Enter Last Modified By User">

                                                                     </div>





                                                                 </div>
                                                             </div>

                                                             <div class="col-lg-12">
                                                                 <div class="row">
                                                                     <div class="col-lg-3 form-group rightForm">
                                                                         <label for="exampleInputEmail1">Last
                                                                             Modified Date</label>
                                                                     </div>
                                                                     <div class="col-lg-2">
                                                                         <input type="date"
                                                                             formControlName="lastModifiedDate"
                                                                             class="form-control"
                                                                             id="exampleInputEmail1"
                                                                             aria-describedby="emailHelp"
                                                                             >

                                                                     </div>

                                                                 </div>
                                                             </div>



                                                         </div>
                                                         <div class="main-submit-button" style="margin-right: 9%;">
                                                             <button type="submit"
                                                                 (click)="customerActiveCategoryFunction(4)"
                                                                 class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                             <button type="submit"
                                                                 class="btn btn-secondary submit-btn" (click)="onUpload()">Update</button>

                                                         </div>
                                                     </div>

                                                 </div>


                                             </div>
                                         </div>
                                     </div>
                                 </form>
                             </div>

                         </div>
                     </div>
                 </div>
             </div>
         </div>

     </div>
 </div>
</div>

<ngx-spinner></ngx-spinner> 