<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">JobShop : [{{ this.routingTaskId}}]</span> <span></span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                Routing Task Info</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                 Routing Task Costs</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                  Deliverable Products</a></li>

                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                  Fixed Assets</a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                Parties</a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                    Skills</a></li>




                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">


                                                <div *ngIf="activeCategory==1">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Routing Task Info

                                                        </h4>
                                                        <span class="edit-buttons hCreate">
                               
                              <a  data-toggle="modal"
                              data-target="#exampleModalCenter" (click)="getRoutingTasks()">Update</a>
                            </span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select" *ngIf="RoutingTaskDetails">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Routing Task Id</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.routingTaskId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Routing Task Name</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.workEffortName}}</span>

                                                                        </div>



                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Task Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.workEffortPurposeTypeDesc}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.description}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Setup Time</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.estimatedSetupMillis}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Unit Run Time</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.estimatedMilliSeconds}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimate Calc Method</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.estimateCalcMethod}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Reserv Persons</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.reservPersons}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Fixed Asset ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.RoutingTaskDetails.fixedAssetName}}</span>
                                                                        </div>





                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Routing Task Costs

                                                        </h4>
                                                        <span class="edit-buttons">
                              <a data-toggle="modal" 
                              data-target="#exampleModalCenterCost">Create New Routing Task Costs</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="costData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">

                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Cost Component Type ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;">Cost Component Calc ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{activity.costComponentTypeDesc}}
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{activity.costComponentCalcDesc}} </td>
                                                                                <td> {{activity.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td> {{activity.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePostRoutingTaskCost(activity.costComponentCalcId,activity.costComponentTypeId,activity.fromDate)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                          <defs>
                                              <style>
                                                  .a {
                                                      fill: #f44336;
                                                  }

                                                  .b {
                                                      fill: #fafafa;
                                                      margin-left: 20px;
                                                  }
                                              </style>
                                          </defs>
                                          <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                          <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                      </svg> </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                  <h4 class="ml-2">No Record Found
                                                  </h4>
                                              </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Routing Task Products
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a data-toggle="modal" 
                                data-target="#exampleModalCenterDel">Create New Routing Task Products</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="TaskDelievarbleProduct" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">


                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Product Name
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{activity.productId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{activity.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td>{{activity.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td>
                                                                                    <svg class="hover" (click)="updateDeliverable(activity.fromDate,activity.productId,activity.thruDate);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                               transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteRoutingTaskDelievarbleProduct(activity.fromDate,activity.productId,activity.workEffortGoodStdTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                                <style>
                                                    .a {
                                                        fill: #f44336;
                                                    }
  
                                                    .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                    }
                                                </style>
                                            </defs>
                                            <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Routing Tasks Fixed Assets

                                                        </h4>
                                                        <span class="edit-buttons">
                                <a data-toggle="modal" 
                                data-target="#exampleModalCenterFix">Create New Routing Tasks Fixed Assets</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="ProdfixedAsset" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Fixed Asset Type ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Estimated Quantity
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Estimated Duration
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Estimated cost
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{activity.fixedAssetTypeId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{activity.estimatedQuantity}} </td>
                                                                                <td>{{activity.estimatedDuration}} </td>
                                                                                <td> {{activity.estimatedCost}} </td>
                                                                                <td>
                                                                                    <svg class="hover" (click)="updateFixedAssest(activity.fixedAssetTypeId,activity.estimatedQuantity,activity.estimatedDuration,activity.estimatedCost);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                               transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteProdfixedAsset(activity.fixedAssetTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                                <style>
                                                    .a {
                                                        fill: #f44336;
                                                    }
  
                                                    .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                    }
                                                </style>
                                            </defs>
                                            <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Parties
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a data-toggle="modal" 
                                data-target="#exampleModalCenterParties">Create New Parties</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="prodParty" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">

                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Party ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Role
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{activity.partyId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{activity.roleTypeValueDesc}} </td>
                                                                                <td> {{activity.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td> {{activity.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                <td>
                                                                                    <svg class="hover" (click)="updateParties(activity.partyId,activity.roleTypeId,activity.fromDate,activity.thruDate);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                               transform="translate(-102.409 -0.004)"/></g></g></g></svg> </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div *ngIf="activeCategory==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Skills
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a data-toggle="modal" 
                                data-target="#exampleModalCenterSkill">Create</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="ProdSkill" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">

                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Skill Type ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Estimated Num People
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Estimated Duration
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Estimated cost
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{activity.skillTypeDesc}}
                                                                                    </div>
                                                                                </td>
                                                                                <td> {{activity.estimatedNumPeople}} </td>
                                                                                <td> {{activity.estimatedDuration}}</td>
                                                                                <td> {{activity.estimatedCost}} </td>
                                                                                <td>
                                                                                    <svg class="hover" (click)="updateSkill(activity.skillTypeId,activity.estimatedNumPeople,activity.estimatedDuration,activity.estimatedCost);" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                               transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteProdSkill(activity.skillTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                            <defs>
                                                <style>
                                                    .a {
                                                        fill: #f44336;
                                                    }
  
                                                    .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                    }
                                                </style>
                                            </defs>
                                            <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                        </svg> </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                    <h4 class="ml-2">No Record Found
                                                    </h4>
                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create Lead</button>
                </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Update Routing Task</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="!this.show"><a>
                                        Add Routing Task
    
    
    
                                    </a></li>
                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="this.show"><a>
                                        Update Routing Task
    
    
    
                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createroute">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightcc">
                                                        <label for="exampleInputEmail1">
                                                            Routing Task Name<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Routing Task Name" formControlName="RoutingTaskName">
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Task Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Task Type" filter="true" formControlName="TaskType" [options]="routingTaskTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightcc">
                                                        <label for="exampleInputEmail1">
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Fixed Asset ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Fixed Asset ID" filter="true" formControlName="FixedAssetID" [options]="fixedAssetIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightcc">
                                                        <label for="exampleInputEmail1">
                                                            Estimated Setup Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Setup Time" formControlName="EstimatedSetupTime">
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Estimated Unit Run Time</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Unit Run Time" formControlName="EstimatedUnitRunTime">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightcc">
                                                        <label for="exampleInputEmail1">
                                                            Estimate Calc Method</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Estimate Calc Method" filter="true" formControlName="EstimateCalcMethod" [options]="estimateCalcMethodArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Reserv Persons</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reserv Persons" formControlName="ReservPersons">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -10%!important;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterCost" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterCost" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Add Routing Task Costs</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonB>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                    Add Routing Task Costs

                </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCost">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightAV">
                                                        <label for="exampleInputEmail1">
                                                Cost Component Type ID
                                                <span
                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Cost Component Type ID" filter="true" formControlName="CostComponentTypeID" [options]="costComponentTypeListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                Cost Component Calc ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Cost Component Calc ID" filter="true" formControlName="CostComponentCalcID" [options]="costComponentCalcListArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightAV">
                                                        <label for="exampleInputEmail1">
                                                From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num" formControlName="FromDate">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Routing Task Name" formControlName="ThroughDate">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -20% !important;">

                                        <button type="submit" (click)="onSubmitCost()" class="btn btn-secondary submit-btn">Save</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterDel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Add Routing Task Product</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonC>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" style="width:107% !important ;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="!this.showDel"><a>
                    Add Routing Task Product

                </a></li>
                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="this.showDel"><a>
                    Update Routing Task Product

                </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createDeliverable">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightXYZ">
                                                        <label for="exampleInputEmail1">
                                                Product ID<span
                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showDel">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="ProductID" [options]="productIdArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showDel">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num" formControlName="ProductID" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                From Date</label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showDel">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num" formControlName="FromDate">
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showDel">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence Num" formControlName="FromDate" readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightXYZ">
                                                        <label for="exampleInputEmail1">
                                                Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Routing Task Name" formControlName="ThroughDate">

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -21% !important;">

                                        <button type="submit" *ngIf="!this.showDel" (click)="onSubmitDel()" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showDel" (click)="onUpdateDel()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterFix" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterFix" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">   Add Routing Tasks Fixed Assets</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonD>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="!this.showFix"><a>
                    Add Routing Tasks Fixed Assets


                </a></li>
                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="this.showFix"><a>
                    Update Routing Tasks Fixed Assets


                </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createFixed">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormNb">
                                                        <label for="exampleInputEmail1">
                                                Fixed Asset Type ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showFix">
                                                        <p-dropdown placeholder="Enter Fixed Asset Type ID" filter="true" formControlName="FixedAssetTypeID" [options]="fixedAssetsInitialDataArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showFix">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Quantity" formControlName="FixedAssetTypeID">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Estimated Quantity</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Quantity" formControlName="EstimatedQuantity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormNb">
                                                        <label for="exampleInputEmail1">
                                                Estimated Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Duration" formControlName="EstimatedDuration">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Estimated Cost</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Cost" formControlName="EstimatedCost">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -22% !important;">

                                        <button type="submit" (click)="onSubmitFix()" *ngIf="!this.showFix" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" (click)="onUpdateFix()" *ngIf="this.showFix" class="btn btn-secondary submit-btn">Update</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterParties" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterParties" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">   Add Party</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonE>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="!this.showParty"><a>
                    Add Party
                </a></li>
                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="this.showParty"><a>
                    Update Party
                </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormHH" style="margin-left:-3%!important;">
                                                        <label for="exampleInputEmail1">
                                                Party ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <p-dropdown placeholder="Enter Party ID" filter="true" formControlName="PartyID" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <!-- <div class="col-lg-2" *ngIf="this.showParty">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="PartyID" readonly>

                                                    </div> -->
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Role Type ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <p-dropdown placeholder="Enter Role Type ID" filter="true" formControlName="RoleTypeID" [options]="allroleArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <!-- <div class="col-lg-2" >
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="RoleTypeID" readonly>
                                                    </div> -->
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormHH" style="margin-left:-3%!important ;">
                                                        <label for="exampleInputEmail1">
                                                From Date</label>
                                                    </div>
                                                    <div class="col-lg-2" >
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="fromDate">

                                                    </div>
                                                    <!-- <div class="col-lg-2" >
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="fromDate" readonly>

                                                    </div> -->

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reserv Persons" formControlName="throughDate">
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -21% !important;">

                                        <button type="submit" *ngIf="!this.showParty" (click)="onSubmitParty()" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showParty" (click)="onUpdateParty()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterSkill" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterSkill" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">   Add Skill</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebuttonF>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="!this.showSkill"><a>
                    Add Skill
                </a></li>


                                <li [ngClass]="activeCategoryMain==2?'active':''" *ngIf="this.showSkill"><a>
                    Update Skill
                </a></li>

                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSkill">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightSSS">
                                                        <label for="exampleInputEmail1">
                                                Skill Type ID<span
                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showSkill">
                                                        <p-dropdown placeholder="Enter Skill Type ID" filter="true" formControlName="SkillTypeID" [options]="skillTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showSkill">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Num People" formControlName="SkillTypeID" readonly>

                                                    </div>

                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                Estimated Num People</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Num People" formControlName="EstimatedNumPeople">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightSSS">
                                                        <label for="exampleInputEmail1">
                                                Estimated Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Duration" formControlName="EstimatedDuration">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                Estimated Cost</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated cost" formControlName="Estimatedcost">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -22% !important;">
                                        <button type="submit" *ngIf="!this.showSkill" (click)="onSubmitSkill()" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showSkill" (click)="onUpdateSkill()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>