<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Accounting
                    Transaction Entry
</span>
                <span class="color-black pl-1" *ngIf="!show"> >Add Transaction Entry
                </span>
                <span class="color-black pl-1" *ngIf="show"> >Update  Transaction Entry
                </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                    Add Transaction Entry
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                    Update Transaction Entry
                                </a></li>

                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="accountTransaction">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" *ngIf="show">
                                                    <label for="exampleInputEmail1">Acctg Trans Id</label>
                                                </div>
                                             
                                                <div class="col-lg-2" *ngIf="show">
                                                    <label>{{this.acctran}}</label>
                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">
                                                        Gl Account Type</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Gl Account Type" filter="true"
                                                    formControlName="glAccountType"
                                                    [options]="glAccountTypeArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Gl Account ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Gl Account ID" filter="true"
                                                    formControlName="glAccId"
                                                    [options]="assignGLPartyIdArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Debit Credit Flag</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Debit Credit Flag" filter="true"
                                                    formControlName="DebitCreditFlag"
                                                    [options]="debit" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Party ID</label>
                                                </div>
                                             
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="partyId"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Party ID"
                                                        >

                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Orig Amount</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="OrigAmount"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Orig Amount"
                                                    >

                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Orig Currency Uom ID </label>
                                                </div>
                                             
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Orig Currency Uom ID" filter="true"
                                                        formControlName="OrigCurrencyUomID"
                                                        [options]="currencyArray" optionlabel="label">

                                                    </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Purpose </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Purpose" filter="true"
                                                        formControlName="Purpose"
                                                        [options]="purpose" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Voucher Ref

                                                    </label>
                                                </div>
                                             
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="VoucherRef"
                                                        class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Voucher Ref">

                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Product ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="ProductID"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Product ID">
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Reconcile Status</label>
                                                </div>
                                             
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Reconcile Status" filter="true"
                                                    formControlName="ReconcileStatus"
                                                    [options]="groupStatusArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Settlement</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="Settlement"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Settlement">
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                        
                                                <div class="col-lg-3 form-group classInput" >
                                                    <label for="exampleInputEmail1">Summary ?</label>
                                                </div>
                                             
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="Summary"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Summary ?">

                                                </div>
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="Description" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Description">
                                                </div>
                        
                                               
                        
                                            </div>
                                        </div>
                                        
                              
                                      
                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 80%;">
                                    <button type="submit" (click)="onUpdate();" *ngIf="show"
                                        class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" (click)="onSubmit();" *ngIf="!show"
                                        class="btn btn-secondary submit-btn">Submit</button>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>