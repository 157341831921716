import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { WarehouseService } from '../../warehouse/warehouse.service';

@Component({
    selector: 'app-review-order-not-picked',
    templateUrl: './review-order-not-picked.component.html'
})
export class ReviewOrderNotPickedComponent implements OnInit {
    total=0;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    activeCategory = 2;

    reviewOrderNotPicked: any[];
    facilityId: string;
    constructor(
        readonly _WarehouseService: WarehouseService,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute
    ) {
        this.reviewOrderNotPicked = [];
        this.facilityId = '';
    }
    ngOnInit(): void {
        this.spinner.show();
        this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
        this._WarehouseService.reviewOrderNotPicking(this.facilityId)
            .then(data => {
                this.spinner.hide();
                this.reviewOrderNotPicked = data.data.orders;
            });
           
    }


    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
}