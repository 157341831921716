import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { EmployeeService } from '../../employees/employess.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-emp-import',
  templateUrl: './emp-import.component.html',
  styleUrls: ['./emp-import.component.css']
})
export class EmpImportComponent implements OnInit {
  fileData: File;
  activeCategory=1;
  Editform: FormGroup;
  constructor( readonly router:Router, readonly employeeService:EmployeeService,
    readonly spinner:NgxSpinnerService,
    readonly toastr:ToastrService) { }

  ngOnInit(): void {
    
  
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  onSubmit(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file",this.fileData);
    this.employeeService.importEmployee(formData).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.router.navigate(["/hr/employees"]);

      }     },(err)=>{
        this.toastr.error(err.message);
     })
     this.spinner.hide();
  }
  onCancel(){
    this.router.navigate(["/hr/employees"]);
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}
